import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsVisibilityGroups: true,

  // Set controller params
  sort: 'name',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'description',
    'title',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'classNames': 'wrapcol'
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(visibilitygroup) {
          self.send('edit', visibilitygroup);
        },
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(visibilitygroup) {
          self.send('delete', visibilitygroup);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'description', 'created_ts', 'delete', 'edit'];
    var listOfMobileCols = ['title', 'description', 'delete', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (!session.get('authenticated.resources.v2-visibilitygroups-delete')) {
        colsArray.removeObject(columns.delete);
      }
      if (!session.get('authenticated.resources.v2-visibilitygroups-put')) {
        colsArray.removeObject(columns.edit);
      }
    }
  }),

  // Actions
  actions: {
    edit: function(visibilitygroup) {
      var location = this.get('location');
      this.transitionToRoute('locations.editvisibilitygroup', location.get('id'), visibilitygroup.get('id'));
    },
    delete: function(visibilitygroup) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Visibility Group?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        visibilitygroup.deleteRecord();
        visibilitygroup.save().then(function() {
          noty({
            text: 'Visibility Group deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Visibility Group!' + error,
            type: 'error',
            timeout: 10000
          });
          visibilitygroup.rollbackAttributes();
          self.set('isLoading', false);
          self.send('refreshData');
        });
      });
    }
  }
});
