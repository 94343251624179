import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  attrs: {
    changelogs: {
			serialize: false,
			deserialize: 'records'
		},
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    location: {
      serialize: false,
      deserialize: 'records'
    },
    terminal_application: {
      serialize: false,
      deserialize: 'records'
    },
    terminal_cvm: {
      serialize: false,
      deserialize: 'records'
    },
    terminal_manufacturer: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
