import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsUnsettledTransactions: true,

  // Set controller params
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  created_ts: 'today',
  isRefreshing: true,
  batchCloseStatus: null,
  needsRefresh: '',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'auth_amount',
    'auth_code',
    'batch',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'description',
    'is_accountvault',
    'is_recurring',
    'last_four',
    'needsRefresh',
    'product_transaction_id',
    'status_id',
    'tax',
    'transaction_amount',
    'type_id',
  ],
  columns: Ember.computed('transactions', 'location', 'isMobileSize', function() {
    var self = this;
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Transaction<br>Date',
        'type': 'shortdate',
        'sortable': true,
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      auth_amount: {
        'name': 'auth_amount',
        'title': 'Auth<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      transaction_amount: {
        'name': 'transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        },
        'total': true,
        'totalValue': Ember.Object.extend({
          data: null,
          value: Ember.computed('gridData', function() {
            return '$ ' + String(this.get('gridData.meta.report.pageTotalTransactionAmount')).currency(2) + ' of<br>$ ' + String(this.get('gridData.meta.report.totalTransactionAmount')).currency(2) + ' Total';
          })
        }).create()
      },
      tax: {
        'name': 'tax',
        'title': 'Sales<br>Tax',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      surcharge_amount: {
        'name': 'surcharge.surcharge_amount',
        'title': 'Surcharge<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      batch: {
        'name': 'batch',
        'title': 'Batch',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes'
        }
      },
      type_id: {
        'name': 'type_id',
        'title': 'Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'types'
        }
      },
      status_id: {
        'name': 'status_id',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'statuses'
        }
      },
      auth_code: {
        'name': 'auth_code',
        'title': 'Auth<br>Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      is_recurring: {
        'name': 'is_recurring',
        'title': 'Recurring<br>Billing',
        'type': 'redo',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('locations.recurrings-view', transaction.get('location_id'), transaction.get('recurring_id'));
        },
        'actionpriv': 'v2-recurrings-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      is_accountvault: {
        'name': 'account_vault_id',
        'title': 'Account<br>Vault',
        'type': 'shield',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('locations.accounts-view', transaction.get('location_id'), transaction.get('account_vault_id'));
        },
        'actionpriv': 'v2-accountvaults-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false,
          field: 'is_accountvault'
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.viewproducttransaction', transaction.get('location_id'), transaction.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          notuniqbytitle: true
        }
      },
      show_detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(transaction) {
          self.send('showDetail', transaction);
        },
        'sortable': false,
        'isActionColumn': true
      },
      show_trans: {
        'name': 'id',
        'title': '',
        'type': 'receipt',
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['show_trans', 'show_detail', 'created_ts', 'account_holder_name', 'auth_amount', 'transaction_amount', 'tax', 'surcharge_amount', 'description', 'batch', 'account_type', 'type_id', 'status_id', 'auth_code', 'last_four', 'is_recurring', 'is_accountvault', 'product_transaction_id'];
    var listOfMobileCols = ['created_ts', 'transaction_amount', 'status_id', 'show_trans', 'show_detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      var location = self.get('location');
      if(!location.get('hasSurcharge')){
        colsArray.removeObject(columns.surcharge_amount);
      }
      if(!location.get('hasTax')){
        colsArray.removeObject(columns.tax);
      }
      if (location.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
    }
  }),

  // Actions
  actions: {
    showDetail: function(trans) {
      this.transitionToRoute('transactiondetailfull', trans.get('id'));
    },
    showTrans: function(reportingtransaction) {
      this.send('showTransDetail', reportingtransaction, this.get('location'));
    },
  }
});
