import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties
  canDelete: Ember.computed('session.authenticated.resources.v2-logos-delete', function() {
    return this.get('session.authenticated.resources.v2-logos-delete');
  }),
  canUpload: Ember.computed('session.authenticated.resources.v2-logos-post', function() {
    return this.get('session.authenticated.resources.v2-logos-post');
  }),
  isImage: Ember.computed('model.logo_image', function() {
    return this.get('model.logo_image');
  }),
  uploadMessage: Ember.computed('resource', function() {
    var receipt_logo_message = 'The optimal setting for receipt logos is 150px X 50px. Anything larger than these dimensions will be scaled down to a maximum of 150px wide by 50px tall.';
    var domain_logo_message = 'The optimal setting for branding logos is 210px X 50px. Anything larger than these dimensions will be scaled down to a maximum of 210px wide by 50px tall.';
    return this.get('resource') === 'domain' ? domain_logo_message : receipt_logo_message;
  }),

  // Actions
  actions: {
    delete: function(model) {
      var self = this;
      var session = this.get('session');
      var resource = this.get('resource');
      pretty_confirm('Are you sure you want to delete this Logo?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.ENV.APP.FULL_URL + '/logos?resource=' + resource + '&resource_id=' + model.get('id'),
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          },
        }).then(function() {
          noty({
            text: 'Logo deleted successfully!',
            timeout: '5000'
          });
          var newStoreAttributes = {};
          if (resource === 'domain') {
            model.set('nav_logo', null);
            newStoreAttributes = {nav_logo: null};
          } else {
            model.set('receipt_logo', null);
            newStoreAttributes = {nav_logo: null};
          }
          self.get('store').push({ // need this for accurate dirty checking in willTransition
            data: {
              id: model.get('id'),
              type: model.get('constructor.modelName'),
              attributes: newStoreAttributes
            }
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'There was an error deleting Logo',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        });
      });
    },
    upload: function(addFile) {
      // Bail if we are already loading
      if (this.get('isLoadingFile')) {
        return;
      }
      var self = this;
      var model = this.get('model');

      var tempFile = addFile.blob;
      var logo = this.get('store').createRecord('logo');
      this.set('logo', logo);
      this.set('logo.logo', tempFile);
      var resource = this.get('resource');
      this.set('isLoadingFile', true);

      logo.set('resource', resource);
      logo.set('resource_id', model.get('id'));

      logo.save().then(function(response) {
        noty({
          text: 'Logo uploaded successfully!'
        });

        var logoUrl = response.get('logo_url') + '?' + moment().valueOf();
        var newStoreAttributes = {};
        if (resource === 'domain') {
          model.set('nav_logo', logoUrl);
          newStoreAttributes = {nav_logo: logoUrl};
        } else {
          model.set('receipt_logo', logoUrl);
          newStoreAttributes = {receipt_logo: logoUrl};
        }
        self.get('store').push({ // need this for accurate dirty checking in willTransition
          data: {
            id: model.get('id'),
            type: model.get('constructor.modelName'),
            attributes: newStoreAttributes
          }
        });
        self.set('isLoadingFile', false);
      }, function(data) {
        self.set('isLoadingFile', false);
        var error = '';
        if(data.errors){
          data.errors.forEach(function(err){
            error += '<br>' + err.detail;
          });
        }
        noty({
          text: 'Error uploading image!' + error,
          type: 'error',
          timeout: 10000
        });
        self.set('logo', null);
      });
    }
  }
});
