import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    var showYes = params[0];
    if (showYes === '0') {
      showYes = false;
    }
    return showYes ? 'Yes' : 'No';
  }
});
