import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  tagName: 'li',

  // Computed Properties

  crumbId: Ember.computed('crumb.{link,title}', function() {
    return 'breadcrumb_' + this.get('crumb.link').replace('.', '-');
  }),

  // Actions
  actions: {}
});
