import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({
  store: Ember.inject.service(),
  session: Ember.inject.service(),

  tagName: 'tr',

  // Events
  didInsertElement: function() {
    var productitems = this.get('productitems');
    var item = this.get('item');
    var productitem = productitems.filter(function(i) {
      return i.get('item_id') === item.get('item_id');
    }).get('firstObject');
    this.set('productitem', productitem);
  },

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('productitem.errors.messages', function() {
    this.set('otherBillingItemsErrorMessages', this.get('productitem.errors.messages'));
  }),

  // Computed Properties

  canActOn: Ember.computed('privsA.canActOn', 'privsM.canActOn', 'privsP.canActOn', 'privsS.canActOn', 'item.item_id', function() {
    return this.privCheck('canActOn');
  }),
  canDelete: Ember.computed('privsA.delete', 'privsM.delete', 'privsP.delete', 'privsS.delete', 'item.item_id', function() {
    return this.privCheck('delete');
  }),
  canPost: Ember.computed('privsA.post', 'privsM.post', 'privsP.post', 'privsS.post', 'item.item_id', function() {
    return this.privCheck('post');
  }),
  canPut: Ember.computed('privsA.put', 'privsM.put', 'privsP.put', 'privsS.put', 'item.item_id', function() {
    return this.privCheck('put');
  }),

  cost: Ember.computed('item', function() {
    return this.get('item.cost');
  }),
  fieldId: Ember.computed('item.item_id', function() {
    return this.get('item.item_id') + '-billinginput';
  }),
  isFee: Ember.computed('item', function() {
    return this.idContains('fee');
  }),
  isOverride: Ember.computed('productitem.id', function() {
    return this.get('productitem.id');
  }),
  isRate: Ember.computed('item', function() {
    return this.idContains('rate');
  }),
  isIncluded: Ember.computed('item', function () {
    return this.idContains('included');
  }),
  overrideCost: Ember.computed('productitem.cost', function() {
    return this.get('productitem.cost');
  }),
  title: Ember.computed('item', function() {
    return this.get('item.item.title');
  }),

  // Functions

  privCheck: function(action) {
    var itemType = this.get('item.item_id').charAt(0);
    var privsA = this.get('privsA');
    var privsM = this.get('privsM');
    var privsP = this.get('privsP');
    var privsS = this.get('privsS');
    switch (itemType) {
      case 'a':
        return privsA[action];
      case 'm':
        return privsM[action];
      case 'p':
        return privsP[action];
      case 's':
        return privsS[action];
      default:
        return false;
    }
  },

  idContains: function(txt) {
    var id = this.get('item.item.id').toLowerCase();
    return id.indexOf(txt) !== -1;
  },

  actions: {
    addBillingItem: function() {
      var item = this.get('item');
      var producttransaction_id = this.get('producttransaction.id');
      var productitem = this.get('store').createRecord('productitem', {
        cost: item.get('cost'),
        item_id: item.get('item_id'),
        model: 'ProductTransaction',
        model_id: producttransaction_id,
      });
      this.set('productitem', productitem);
      this.set('isEditing', true);
      this.set('isEditingBillingItem', true);
      var fieldId = this.get('fieldId');
      setTimeout(function() {
        jQuery('#' + fieldId).focus().select();
      }, 1);
    },
    cancelBillingItem: function() {
      var productitem = this.get('productitem');
      productitem.rollbackAttributes();
      this.set('isEditing', false);
      this.set('isEditingBillingItem', false);
    },
    deleteBillingItem: function(productitem) {
      // Bail if we are already loading
      var self = this;
      self.set('isEditingBillingItem', true);
      pretty_confirm('Are you sure want to delete this billing item?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        productitem.deleteRecord();
        productitem.save().then(
          function() {
            noty({
              text: 'Billing Item deleted successfully!'
            });
            self.set('productitem', null);
            self.set('isLoading', false);
            self.set('isEditingBillingItem', false);
          },
          function() {
            productitem.rollbackAttributes();
            noty({
              text: 'Error deleting Billing Item!',
              type: 'error'
            });
            self.set('isLoading', false);
            self.set('isEditingBillingItem', false);
          }
        );
      });
      self.set('isEditingBillingItem', false);
    },
    editBillingItem: function() {
      this.set('isEditing', true);
      this.set('isEditingBillingItem', true);
      var fieldId = this.get('fieldId');
      setTimeout(function() {
        jQuery('#' + fieldId).focus().select();
      }, 1);
    },
    saveBillingItem: function() {
      var productitem = this.get('productitem');
      var self = this;
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      self.set('isEditingBillingItem', true);
      productitem.save().then(function() {
        noty({
          text: 'Billing Item saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.set('isEditingBillingItem', false);
      }, function() {
        noty({
          text: 'Error saving Billing Item!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.set('isEditingBillingItem', true);
      });
    },
  }
});
