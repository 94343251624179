import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Helper.extend({
    compute(params) {
      if (params[0] !== undefined) {
        return params[0];
      }
      return params[1];
    },
  });