import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain',
      }),
      producttransactiondetails: store.queryRecord('producttransactiondetail', {
        id: params.product_id,
        active: '0,1',
        expand: 'surcharge,created_user'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransactiondetails', models.producttransactiondetails);

    if (models.producttransactiondetails.get('surcharge.id')) {
      controller.set('surcharge', models.producttransactiondetails.get('surcharge.content'));
      controller.set('showSurcharge', true);
    } else {
      controller.set('showSurcharge', false);
    }
    controller.set('isEditing', false);
    controller.set('isEditingSurcharge', false);

    controller.set('formPrivs', {
      canDelete: false,
      canPost: false,
      canPut: false
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + models.producttransactiondetails.get('title') + ' ● Surcharge',
      route_name: 'locations.viewproducttransactiondetail-surcharge',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransactiondetails.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: models.producttransactiondetails.get('title'),
      link: 'locations.viewproducttransactiondetail',
      record: models.location.get('id'),
      record_plus: models.producttransactiondetails.get('id'),
    }, {
      title: 'Surcharge',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var surcharge = this.controller.get('surcharge');
      this.get('unsavedInfo').warning(surcharge, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
