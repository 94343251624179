import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/producttransactionform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions,product_accountvault',
      }),
      producttransaction: store.queryRecord('producttransactionmigration', {
        id: params.product_id,
        expand: 'surcharge,product_ach_store,created_user'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransaction', models.producttransaction);
    controller.set('newModel', true); // Need to set this for showing editable fields
    controller.set('isEditing', true); // Need to set this for showing editable fields
    controller.set('isViewingL3', false);
    controller.set('isViewingPayfacData', false);
    controller.set('cauEnabled', models.producttransaction.get('cau_account_number') && models.producttransaction.get('cau_subscribe_type_id')); // initially set checkbox for subscribe for account updates

    var processors = this.get('applicationData.processors').filter(function(processor) {
      return processor.type === 'cc';
    });
    var processor = '';
    var sub_processor = models.producttransaction.get('sub_processor');
    processors.forEach(function(p) {
      if(p.sub_processor === sub_processor) {
        processor = p.id;
      }
    });
    controller.set('processor', processor);

    controller.set('formPrivs', {
      canPut: this.get('session.authenticated.resources.v2-producttransactionmigrations-put')
    });

    if (this.get('session.authenticated.resources.v2-productbillinggroups-get')) {
      this.get('store').query('productbillinggroup', {
        id: models.producttransaction.get('product_billing_group_id')
      }).then(function(res) {
        controller.set('productbillinggroups', res);
      });
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Deposit Account Migration',
      route_name: 'locations.migrateproducttransaction',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransaction.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Deposit Accounts',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: 'Deposit Account Migration',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.currentModel;
      var self = this;
      if (model.producttransaction.get('hasDirtyAttributes') && this.get('session.isAuthenticated')) {
        transition.abort();
        pretty_confirm('You have unsaved information. Are you sure you want to leave this page?', function() {
          self.controller.set('isEditing', false);
          model.producttransaction.rollbackAttributes();
          transition.retry();
        });
        return false;
      } else {
        self.controller.set('isEditing', false);
      }

      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
