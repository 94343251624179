import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsHPPSettings: true,

  areProductsWithHPP: Ember.computed('products.[]', function() {
    return this.get('products.length') > 0;
  }),
  atMaxHPPs: Ember.computed('remainingHPPs', function() {
    var remainingHPPs = this.get('remainingHPPs');
    return remainingHPPs <= 0;
  }),
  products: Ember.computed('activeProduct.id', 'product_transactions.[]', function() {
    var activeProduct = this.get('activeProduct');
    var product_transactions = this.get('product_transactions');
    product_transactions.map(function(product) {
      if (product.get('id') === activeProduct.get('id')) {
        product.set('isActiveProduct', true);
      } else {
        product.set('isActiveProduct', false);
      }
    });
    return product_transactions;
  }),
  remainingHPPs: Ember.computed('activeProduct.hosted_payment_page_max_allowed', 'activeProductHPPs.@each', function() {
    var numHPPs = this.get('activeProductHPPs.length');
    var maxNumHPPs = this.get('activeProduct.hosted_payment_page_max_allowed');
    var value = maxNumHPPs - numHPPs;
    var returnValue = value <= 0 ? 0 : value;
    return returnValue;
  }),

  // All form actions
  actions: {
    addHPP: function(productID) {
      if (this.get('atMaxHPPs')) {
        noty({
          text: 'At Maximum Hosted Payment Pages for this Service!',
          type: 'error'
        });
        return;
      }
      this.transitionToRoute('locations.hpp-add', this.get('location.id'), {
        queryParams: {
          selectedProductID: productID
        }
      });
    },
    deleteHPP: function(hpp) {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var hppId = hpp.get('id');
      var hpps = this.get('activeProductHPPs');
      var hostedpaymentpage;
      hpps.map(function(hosted) {
        if (hppId === hosted.get('id')) {
          hostedpaymentpage = hosted;
        }
      });

      var self = this;

      pretty_confirm('Are you sure you want to delete this Hosted Payment Page?', function() {
        hostedpaymentpage.deleteRecord();
        hostedpaymentpage.save().then(function() {
          hostedpaymentpage.unloadRecord();
          noty({
            text: 'Hosted Payment Page deleted successfully!'
          });
          self.set('isLoading', false);
        }, function() {
          hostedpaymentpage.rollbackAttributes();
          noty({
            text: 'Error deleting Hosted Payment Page!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    makeActiveProduct: function(product) {
      var self = this;
      this.set('isLoading', true);
      var productID = product.get('id');
      var activeProductHPPs = self.get('store').query('hostedpaymentpage', {
        product_transaction_id: productID,
        sort: 'title',
        expand: 'created_user'
      });
      activeProductHPPs.then(function() {
        self.set('activeProductHPPs', activeProductHPPs);
        self.set('isLoading', false);
      });
      this.set('activeProduct', product);
    },
    viewHPP: function(hpp, action) {
      if(action === 'edit') {
        this.transitionToRoute('locations.hpp-view', this.get('location.id'), hpp.get('id'));
      } else if(action === 'use') {
        this.transitionToRoute('locations.hpp-view', this.get('location.id'), hpp.get('id'), {
          queryParams: {
            usePP: true
          }
        });
      } else {
        return;
      }
    }
  }
});
