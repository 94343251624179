import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  transaction_id: DS.attr('string'),
  level3_data: DS.attr('array'),

  /* Computed Properties */

  // Defined model relationships
  created_user: DS.belongsTo('user')
});
