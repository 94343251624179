import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      featureflag: store.queryRecord('featureflag', {
        id: params.view_id,
        expand: 'users,created_user',
      }),
      userTypes: store.findAll('usertype')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var featureflag = models.featureflag;
    controller.set('featureflag', featureflag);
    controller.set('userTypes', models.userTypes.filter(function(type){
      return type.id !== '0';
    }));
    controller.set('isAdding', false);
    controller.set('isCriteria', {
      user: true,
      userType: false,
      developer: false,
      location: false
    });
    controller.set('selectedUsers', []);
    controller.set('selectedUserTypes', []);
    controller.set('selectedDevelopers', []);
    controller.set('selectedLocations', []);
    controller.set('selectedLocationRelationships', []);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-featureflags-delete'),
      canPost: false,
      canPut: this.get('session.authenticated.resources.v2-featureflags-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Feature Flag ● ' + featureflag.get('title'),
      route_name: 'admin.featureflags.view',
      route_id: featureflag.get('id')
    });
    controller.set('pageTitle', 'View Feature Flag');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Feature Flags',
      link: 'admin.featureflags.index'
    }, {
      title: 'View Feature Flag',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.featureflag, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
