import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransactionauthenticationcode.errors.messages', function() {
    this.set('errorMessages', this.get('producttransactionauthenticationcode.errors.messages'));
  }),

  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var session = this.get('session');
      var product = this.get('product');
      var producttransactionauthenticationcode = this.get('producttransactionauthenticationcode');
      var data;
      if (product.get('isSubProcessorTsys')) {
        data = JSON.stringify({
          'producttransaction': {
            'processor_data': {
              'authentication_code': producttransactionauthenticationcode
            }
          }
        });
      } else if (product.get('isSubProcessorNashville')) {
        data = JSON.stringify({
          'producttransaction': {
            'processor_data': {
              'auth_key': producttransactionauthenticationcode
            }
          }
        });
      }
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/producttransactions/' + product.get('id') + '/reregister',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        type: 'POST',
        data: data
      }).then(function() {
        noty({
          text: 'Authentication Code saved successfully!',
          timeout: '5000'
        });
        self.set('isLoading', false);
        self.send('closeModal');
      }, function() {
        noty({
          text: 'Error saving Authentication Code!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
      });
    }
  }
});
