import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        devicelogactions: store.findAll('devicelogaction')
      });
  },

  setupController: function(controller, models) {
    controller.set('devicelogactions', models.devicelogactions);
    // Set data for pager component
    var pagerParams = {
      pageRoute: 'admin.reports.devicelogs',
      pageModelId: null,
      modelName: 'admin_devicelog',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    // Set data for grid params
    var gridParams = {
      expand: 'device_log_action'
    };
    controller.set('gridParams', gridParams);

    this.send('addHistory', {
      title: 'Reports ● Device Logs',
      route_name: 'admin.reports.devicelogs',
      route_id: null
    });
    controller.set('pageTitle', 'Device Logs');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Device Logs',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
