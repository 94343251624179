import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  resetController: function(controller) {
    controller.set('tags', '');
    controller.set('account_type', '');
    controller.set('created_user_id', '');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      types: store.findAll('type'),
      statuses: store.findAll('status')
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    var store = self.get('store');
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfo: store.queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
          models.tagList = data.get('tags').sortBy('title');
        }),
        users: store.query('user', {
          location_id: models.contact.get('location_id'),
          page_size: '50',
          user_type_id: '200,250,300,400,500,600,700,800,900,1000',
          status_id: 1
        }).then(function(data) {
          models.users = data.toArray();
        })
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var product_transactions = [];
    var prods = models.locationinfo.get('product_transactions');
    if (prods) {
      prods.forEach(function(product_transaction) {
        product_transactions.push({
          'id': product_transaction.get('id'),
          'title': product_transaction.get('title')
        });
      });
    }
    if (models.users) {
      models.users.pushObjects([{
        id: 'terminal',
        title: 'terminal',
        username: 'terminal'
      }, {
        id: 'system',
        title: 'system',
        username: 'system'
      }]);
      controller.set('users', models.users);
    }
    controller.set('product_transactions', product_transactions);
    controller.set('contact', models.contact);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);
    controller.set('tagList', models.tagList);
    controller.set('locationinfo', models.locationinfo);
    var pagerParams = {
      pageRoute: 'contacts.transactions',
      pageModelId: models.contact.get('id'),
      modelName: 'transaction',
      showExport: true
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user.id,created_user.username,contact.id,contact.first_name,contact.last_name,contact.account_number,contact.email,surcharge.id,surcharge.surcharge_amount,location.id,location.name,tags,status,type,all_tags,product_transaction,terminal.id,terminal.title,quick_invoice',
      contact_id: models.contact.get('id'),
      show_totals: true
    };
    controller.set('gridParams', gridParams);

    if((controller.get('created_ts_from') || controller.get('created_ts_to')) && controller.get('created_ts') !== 'custom') {
      controller.set('created_ts_from', '');
      controller.set('created_ts_to', '');
    }
    if(controller.get('created_ts') === 'custom' && !controller.get('created_ts_from') && !controller.get('created_ts_to')) {
      controller.set('created_ts', 'today');
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Transactions',
      route_name: 'contacts.transactions',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Transactions',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
