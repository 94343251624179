import Ember from 'ember';
import jQuery from 'jquery';

var get = Ember.get;

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  name: null,
  hideSingle: false,
  contents: Ember.computed('content', function() {
    var content = this.get('content');
    if (typeof content === 'string') {
      return this.get(content);
    } else {
      return content;
    }
  }),
  isDisabled: Ember.computed('disabled', function() {
    return this.get('disabled');
  }),
  promptText: Ember.computed('prompt', function() {
    var prompt = this.get('prompt');
    return prompt ? prompt : 'Select...';
  }),
  thisId: Ember.computed('name', 'dataIndex', function() {
    var name = this.get('name');
    var dataIndex = this.get('dataIndex');
    if(dataIndex || dataIndex === 0) {
      return (name ? name : '') + '_select' + '_' + dataIndex;
    } else {
      return (name ? name : '') + '_select';
    }
  }),

  contentsLength: Ember.observer('contents.length', function() {
    var hide = this.get('hideSingle');
    var itemCount = this.get('contents.length');
    var elementId = this.get('elementId');
    if (hide && itemCount === 1) {
      jQuery('#' + elementId).parent().parent().css('display', 'none');
      // Set the default value
      var path = this.get('optionValuePath');
      this.set('value', get(this.get('content').get('firstObject'), path));
    } else if (itemCount > 1) {
      var parentElement = jQuery('#' + elementId).parent().parent();
      if (parentElement[0].tagName !== 'TR') {
        parentElement.css('display', 'block');
      }
    }
  }),
  disabledObserver: Ember.observer('disabled', function() {
    if (this.get('disabled')) {
      jQuery('#' + this.get('thisId')).parent().attr('disabled', 'disabled');
    } else {
      jQuery('#' + this.get('thisId')).parent().removeAttr('disabled');
    }
  }),

  didRender: function() {
    var value = this.get('value');
    var validValue = value || value === 0 || value === false || value === '';
    if (validValue) {
      this.set('selectedContent', value);
    } else {
      this.set('selectedContent', null);
      jQuery('#' + this.get('thisId')).attr('selected', true);
    }
    if (this.get('disableNull') !== 'false') {
      jQuery('#' + this.get('thisId')).attr('disabled', 'disabled');
    } else {
      jQuery('#' + this.get('thisId')).removeAttr('disabled');
    }
    var hide = this.get('hideSingle');
    var itemCount = this.get('contents.length');

    if (hide && itemCount === 1) {
      var elementId = this.get('elementId');
      jQuery('#' + elementId).parent().parent().css('display', 'none');

      // Set the default value
      var path = this.get('optionValuePath');
      this.set('value', get(this.get('content').get('firstObject'), path));
    }
  },
  fieldId: Ember.computed('name', function() {
    return this.get('name') + '_id';
  }),
  setDefaults: Ember.observer('items', function() {
    if (this.get('has-focus')) {
      this.$().focus();
    }
  }),

  actions: {
    selectValue: function(selected) {
      selected = selected === 'null' ? null : selected;
      if (!this.get('isDestroyed') || !this.get('isDestroying')) {
        if (selected === 'false') {
          selected = !Boolean(selected);
        }
        this.set('value', selected);
      }
    },
  },

  // Arrays
  achIsCompany: Ember.computed(function() {
    return this.get('applicationData.achIsCompany');
  }),
  accountTypes: Ember.computed(function() {
    return this.get('applicationData.accountTypes');
  }),
  ach_trans_actions: Ember.computed(function() {
    return this.get('applicationData.ach_trans_actions');
  }),
  cau_subscribe_options: Ember.computed(function() {
    return this.get('applicationData.cau_subscribe_options');
  }),
  cau_subscription_options: Ember.computed(function() {
    return this.get('applicationData.cau_subscription_options');
  }),
  cc_trans_actions: Ember.computed(function() {
    return this.get('applicationData.cc_trans_actions');
  }),
  dateFiltersPast: Ember.computed(function() {
    return this.get('applicationData.dateFiltersPast');
  }),
  dateFiltersTransaction: Ember.computed(function () {
    return this.get('applicationData.dateFiltersTransaction');
  }),
  domainThemes: Ember.computed(function() {
    return this.get('applicationData.domainThemes');
  }),
  exp_months: Ember.computed(function() {
    return this.get('applicationData.exp_months');
  }),
  exp_years: Ember.computed(function() {
    return this.get('applicationData.exp_years');
  }),
  extra_flags: Ember.computed(function() {
    return this.get('applicationData.extra_flags');
  }),
  featureFlagsEnableTypes: Ember.computed(function() {
    return this.get('applicationData.featureFlagsEnableTypes');
  }),
  industry_types: Ember.computed(function() {
    return this.get('applicationData.industry_types');
  }),
  intervals: Ember.computed(function() {
    return this.get('applicationData.intervals');
  }),
  pageSizes: Ember.computed(function() {
    return this.get('applicationData.pageSizes');
  }),
  postback_resources: Ember.computed(function() {
    return this.get('applicationData.postback_resources');
  }),
  paymentMethods: Ember.computed(function() {
    return this.get('applicationData.paymentMethods');
  }),
  processMethods: Ember.computed(function() {
    return this.get('applicationData.processMethods');
  }),
  producttaxconfigs: Ember.computed(function() {
    return this.get('applicationData.producttaxconfigs');
  }),
  reportExportTypes: Ember.computed(function() {
    return this.get('applicationData.exportFormats');
  }),
  tz: Ember.computed(function() {
    return this.get('applicationData.tz');
  }),
  tzPlusGMT: Ember.computed(function() {
    return this.get('applicationData.tzPlusGMT');
  }),
  unit_codes: Ember.computed(function() {
    return this.get('applicationData.unit_codes');
  }),
  yesNo: Ember.computed(function() {
    return this.get('applicationData.yesNo');
  }),
  yesNoAny: Ember.computed(function() {
    return this.get('applicationData.yesNoAny');
  }),
  yesNoBoolean: Ember.computed(function() {
    return this.get('applicationData.yesNoBoolean');
  }),
});
