import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  export_version: 'v1',
  created_ts: 'today',
  isRefreshing: true,
  needsRefresh: '',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'auth_amount',
    'auth_code',
    'batch',
    'billing_zip',
    'billing_phone',
    'charge_back_date',
    'charge_back_date_from',
    'charge_back_date_to',
    'clerk_number',
    'contact-first_name',
    'contact-last_name',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'description',
    'is_accountvault',
    'is_recurring',
    'last_four',
    'location_id',
    'needsRefresh',
    'order_num',
    'payment_method',
    'product_transaction_id',
    'quick_invoice_id',
    'room_num',
    'settle_date',
    'settle_date_from',
    'settle_date_to',
    'status_id',
    'surcharge-surcharge_amount',
    'tags',
    'tax',
    'terminal_id',
    'transaction_amount',
    'trx_source_id',
    'type_id',
  ],
  terminals: Ember.computed('locations', function() {
    var terminals = Ember.A();
    var locations = this.get('locations');
    locations.forEach(function(location) {
      var terms = location.get('terminals');
      if (terms.get('length') > 0) {
        terms.forEach(function(t) {
          terminals.pushObject({
            id: t.get('id'),
            title: t.get('title')
          });
        });
      }
    });
    return terminals;
  }),

  columns: Ember.computed('transactions', 'locations', 'isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Transaction<br>Date',
        'type': 'shortdate',
        'sortable': true,
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_first_name: {
        'name': 'contact.first_name',
        'title': 'Contact<br>First<br>Name',
        'type': 'string',
        'action': function(transaction) {
          self.transitionToRoute('contacts.dashboard', transaction.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_last_name: {
        'name': 'contact.last_name',
        'title': 'Contact<br>Last<br>Name',
        'type': 'string',
        'action': function(transaction) {
          self.transitionToRoute('contacts.dashboard', transaction.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      auth_amount: {
        'name': 'auth_amount',
        'title': 'Auth<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      transaction_amount: {
        'name': 'transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        },
        'total': true,
        'totalValue': Ember.Object.extend({
          data: null,
          value: Ember.computed('gridData', function() {
            return '$ ' + String(this.get('gridData.meta.report.pageTotalTransactionAmount')).currency(2) + ' of<br>$ ' + String(this.get('gridData.meta.report.totalTransactionAmount')).currency(2) + ' Total';
          })
        }).create()
      },
      tip_amount: {
        'name': 'tip_amount',
        'title': 'Tip<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      surcharge_amount: {
        'name': 'surcharge.surcharge_amount',
        'title': 'Surcharge<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      tax: {
        'name': 'tax',
        'title': 'Sales<br>Tax',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      billing_zip: {
        'name': 'billing_zip',
        'title': 'Billing<br>Zip',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      billing_phone: {
        'name': 'billing_phone',
        'title': 'Billing<br>Phone',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      clerk_number: {
        'name': 'clerk_number',
        'title': 'Clerk<br>Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      room_num: {
        'name': 'room_num',
        'title': 'Room<br>Num',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      tags: {
        'name': 'tags',
        'title': 'Tags',
        'type': 'array',
        'sortable': false,
        'filter': {
          type: 'dropdown',
          items: 'tagList',
          labelKey: 'title',
          multi: true,
          showAllSelect: true
        }
      },
      batch: {
        'name': 'batch',
        'title': 'Batch',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      payment_method: {
        'name': 'payment_method',
        'title': 'Payment<br>Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'paymentMethods',
          multi: true,
          showAllSelect: true
        }
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true
        }
      },
      created_user_id: {
        'name': 'created_user_id',
        'title': 'Created By',
        'type': 'string',
        'sortable': false,
        'action': function(transaction) {
          if(session.get('authenticated.isSupport')) {
            self.transitionToRoute('admin.users.view', transaction.get('created_user.id'));
          } else {
            self.transitionToRoute('locations.users-view', transaction.get('location.id'), transaction.get('created_user.id'));
          }
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-users-get' : ['v2-locations-get', 'v2-users-get'],
        'filter': {
          type: 'dropdown',
          items: 'users',
          multi: true,
          showAllSelect: true
        }
      },
      type_id: {
        'name': 'type_id',
        'title': 'Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'types',
          multi: true,
          showAllSelect: true
        }
      },
      status_id: {
        'name': 'status_id',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'statuses',
          multi: true,
          showAllSelect: true
        }
      },
      auth_code: {
        'name': 'auth_code',
        'title': 'Auth<br>Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      settle_date: {
        'name': 'settle_date',
        'title': 'Settle<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      charge_back_date: {
        'name': 'charge_back_date',
        'title': 'Chargeback<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      is_recurring: {
        'name': 'is_recurring',
        'title': 'Recurring',
        'type': 'redo',
        'action': function(transaction) {
          self.transitionToRoute('recurrings.view', transaction.get('recurring_id'));
        },
        'actionpriv': 'v2-recurrings-get',
        'sortable': true,
        'align': 'text-center',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      is_accountvault: {
        'name': 'is_accountvault',
        'title': 'Account<br>Vault',
        'type': 'shield',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('accounts.view', transaction.get('account_vault_id'));
        },
        'actionpriv': 'v2-accountvaults-get',
        'align': 'text-center',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      order_num: {
        'name': 'order_num',
        'title': 'Order<br>Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      terminal_id: {
        'name': 'terminal_id',
        'title': 'Terminal',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          if(session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) {
            self.transitionToRoute('admin.terminals.view', transaction.get('terminal_id'));
          } else {
            self.transitionToRoute('locations.terminals-view', transaction.get('location_id'), transaction.get('terminal_id'));
          }
        },
        'actionpriv': (session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) ? 'v2admin-terminals-get' : ['v2-locations-get', 'v2-terminals-get'],
        'filter': {
          type: 'dropdown',
          items: 'terminals',
          multi: true,
          showAllSelect: true
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.view', transaction.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'filter': {
          type: 'dropdown',
          items: 'locations',
          multi: true,
          showAllSelect: true
        }
      },
      quick_invoice_id: {
        'name': 'quick_invoice.title',
        'title': 'Quick<br>Invoice',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('quickinvoice.view', transaction.get('quick_invoice_id'));
        },
        'actionpriv': 'v2-quickinvoices-get',
        'filter': {
          type: 'text',
          field: 'quick_invoice_id'
        }
      },
      trx_source_id: {
        'name': 'trx_source_id',
        'title': 'Payment<br>Source',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'trans_sources',
          multi: true,
          showAllSelect: true
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.viewproducttransaction', transaction.get('location_id'), transaction.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          showAllSelect: true,
          notuniqbytitle: true
        }
      },
      show_detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(transaction) {
          self.send('showDetail', transaction);
        },
        'sortable': false,
        'isActionColumn': true,
      },
      show_trans: {
        'name': 'id',
        'title': '',
        'type': 'receipt',
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'sortable': false,
        'isActionColumn': true
      }
    };

    var locations = this.get('locations');
    var tips = locations.filter(function(location) {
      return (location.get('hasProductTip'));
    });
    var surcharges = locations.filter(function(location) {
      return (location.get('hasSurcharge'));
    });
    var salestaxes = locations.filter(function(location) {
      return (location.get('hasTax'));
    });
    var billing_phone = locations.filter(function(location) {
      return (location.get('hasBillingPhone'));
    });
    var clerk_number = locations.filter(function(location) {
      return (location.get('hasClerkNumber'));
    });
    var lodgings = locations.filter(function(l) {
      return l.get('product_cc.account_type') === 'lodging';
    });
    var order_nums = locations.filter(function(location) {
      return (location.get('hasVTOrderNum'));
    });
    var terminals = this.get('terminals');
    var quickinvoices = locations.filter(function(location) {
      return (location.get('hasQuickInvoice'));
    });

    var listOfCols = ['show_trans', 'show_detail', 'created_ts', 'account_holder_name', 'contact_first_name', 'contact_last_name', 'auth_amount', 'transaction_amount', 'tip_amount', 'surcharge_amount', 'tax', 'billing_zip', 'billing_phone', 'clerk_number', 'room_num', 'description', 'tags', 'batch', 'payment_method', 'account_type', 'created_user_id', 'type_id', 'status_id', 'auth_code', 'last_four', 'settle_date', 'charge_back_date', 'is_recurring', 'is_accountvault', 'order_num', 'terminal_id', 'location_id', 'quick_invoice_id', 'trx_source_id', 'product_transaction_id'];
    var listOfMobileCols = ['created_ts', 'transaction_amount', 'status_id', 'show_trans', 'show_detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if ((tips && tips.get('length') < 1) || !tips) {
        colsArray.removeObject(columns.tip_amount);
      }
      if ((surcharges && surcharges.get('length') < 1) || !surcharges) {
        colsArray.removeObject(columns.surcharge_amount);
      }
      if ((salestaxes && salestaxes.get('length') < 1) || !salestaxes) {
        colsArray.removeObject(columns.tax);
        colsArray.removeObject(columns.billing_zip);
      }
      if ((billing_phone && billing_phone.get('length') < 1) || !billing_phone) {
        colsArray.removeObject(columns.billing_phone);
      }
      if ((clerk_number && clerk_number.get('length') < 1) || !clerk_number) {
        colsArray.removeObject(columns.clerk_number);
      }
      if ((lodgings && lodgings.get('length') < 1) || !lodgings) {
        colsArray.removeObject(columns.room_num);
      }
      if ((order_nums && order_nums.get('length') < 1) || !order_nums) {
        colsArray.removeObject(columns.order_num);
      }
      if ((terminals && terminals.get('length') < 1) || !terminals) {
        colsArray.removeObject(columns.terminal_id);
      }
      if ((quickinvoices && quickinvoices.get('length') < 1) || !quickinvoices) {
        colsArray.removeObject(columns.quick_invoice_id);
      }
      if (context.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
    }
  }),

  // Actions
  actions: {
    showDetail: function(trans) {
      this.transitionToRoute('transactiondetailfull', trans.get('id'));
    },
    showTrans: function(transaction) {
      var self = this;
      var store = this.get('store');
      return Ember.RSVP.hash({
        locationinfo: store.findRecord('locationinfo', transaction.get('location_id'))
      }).then(
        function(hash) {
          self.send('showTransDetail', transaction, hash.locationinfo);
        },
        function() {
          noty({
            text: 'Error getting transaction detail!',
            type: 'error',
            timeout: '5000'
          });
        }
      );
    }
  }
});
