import Ember from "ember";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransactionDetail: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "producttransactiondetails.errors.messages",
    function () {
      this.set(
        "errorMessages",
        this.get("producttransactiondetails.errors.messages")
      );
    }
  ),

  // Set controller params
  isLoading: false,
  isEditing: false,

  // Computed Properties
  ach_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.ach_default_trans_types");
  }),
  canHPP: Ember.computed(
    "producttransactiondetails.{payment_method,processor,isSubProcessorPaya}",
    function () {
      if (this.get("producttransactiondetails.payment_method") === "cc") {
        return true;
      } else if (
        this.get("producttransactiondetails.payment_method") === "ach"
      ) {
        if (this.get("producttransactiondetails.isSubProcessorPaya")) {
          var terminals = this.get(
            "producttransactiondetails.processor_data.terminals"
          );
          var hasValidSECCode = false;
          if (!terminals || terminals.get("length") === 0) {
            return false;
          }
          terminals.map(function (terminal) {
            var sec_code = terminal.sec_code;
            if (
              sec_code === "CCD" ||
              sec_code === "PPD" ||
              sec_code === "WEB"
            ) {
              hasValidSECCode = true;
            }
          });
          return hasValidSECCode;
        } else {
          return true;
        }
      }
    }
  ),
  cc_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.cc_default_trans_types");
  }),
  isActive: Ember.computed("producttransactiondetails.active", function () {
    return this.get("producttransactiondetails.active");
  }),
  notEditing: Ember.computed("isEditing", function () {
    return !this.get("isEditing");
  }),
  vtCvvRequired: Ember.computed(
    "producttransactiondetails.vt_cvv",
    "isEditing",
    function () {
      if (
        this.get("isEditing") &&
        this.get("producttransactiondetails.vt_cvv")
      ) {
        return false;
      } else {
        return true;
      }
    }
  ),
  vtStreetRequired: Ember.computed(
    "producttransactiondetails.vt_street",
    "isEditing",
    function () {
      if (
        this.get("isEditing") &&
        this.get("producttransactiondetails.vt_street")
      ) {
        return false;
      } else {
        return true;
      }
    }
  ),
  vtZipRequired: Ember.computed(
    "producttransactiondetails.vt_zip",
    "isEditing",
    function () {
      if (
        this.get("isEditing") &&
        this.get("producttransactiondetails.vt_zip")
      ) {
        return false;
      } else {
        return true;
      }
    }
  ),
  producttransactiondetails_cau_subscribe_type_title: Ember.computed(
    "applicationData.cau_subscribe_options",
    "producttransactiondetails.cau_subscribe_type_id",
    function () {
      var options = this.get("applicationData.cau_subscribe_options");
      var cau_subscribe_type_id = this.get(
        "producttransactiondetails.cau_subscribe_type_id"
      );
      var title = "";
      options.forEach(function (option) {
        if (option.id === cau_subscribe_type_id) {
          title = option.title;
        }
      });
      return title;
    }
  ),

  // Observers

  cauEnabledObserver: Ember.observer("cauEnabled", function () {
    if (!this.get("cauEnabled")) {
      this.set("producttransactiondetails.cau_account_number", null);
      this.set("producttransactiondetails.cau_subscribe_type_id", "0");
    }
  }),
  changedPaymentMethod: Ember.observer(
    "producttransactiondetails.payment_method",
    function () {
      var payment_method =
        this.get("producttransactiondetails.payment_method") || "cc";
      this.set(
        "default_trans_types",
        this.get(payment_method + "_default_trans_types")
      );
    }
  ),
  changedVtCvv: Ember.observer(
    "producttransactiondetails.vt_cvv",
    "isEditing",
    function () {
      var vt_cvv = this.get("producttransactiondetails.vt_cvv");
      if (!vt_cvv) {
        this.set("producttransactiondetails.vt_require_cvv", false);
      }
    }
  ),
  changedVtStreet: Ember.observer(
    "producttransactiondetails.vt_street",
    "isEditing",
    function () {
      var vt_street = this.get("producttransactiondetails.vt_street");
      if (!vt_street) {
        this.set("producttransactiondetails.vt_require_street", false);
      }
    }
  ),
  changedVtZip: Ember.observer(
    "producttransactiondetails.vt_zip",
    "isEditing",
    function () {
      var vt_zip = this.get("producttransactiondetails.vt_zip");
      if (!vt_zip) {
        this.set("producttransactiondetails.vt_require_zip", false);
      }
    }
  ),
  level3_allow_observer: Ember.observer(
    "producttransactiondetails.level3_allow",
    "isEditing",
    function () {
      var isEditing = this.get("isEditing");
      var level3_allow = this.get("producttransactiondetails.level3_allow");
      var level3_default = this.get("producttransactiondetails.level3_default");
      var defaultLevel3 = this.get("applicationData.level3_defaults");
      if (level3_allow && !level3_default) {
        this.set("producttransactiondetails.level3_default", defaultLevel3);
      } else if (!level3_allow) {
        this.set("producttransactiondetails.level3_default", null);
        this.set("isViewingL3", false);
      } else if (level3_allow && level3_default && isEditing) {
        this.set(
          "producttransactiondetails.level3_default",
          this.get("producttransactiondetails.level3_default")
        );
      }
    }
  ),

  // Actions
  actions: {
    edit: function () {
      this.set("isEditing", true);
    },
    reset: function () {
      this.set("isEditing", false);
    },
    save: function () {
      // Bail if we are already loading
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var producttransactiondetails = this.get("producttransactiondetails");
      var level3_default = this.get("producttransactiondetails.level3_default");
      if (
        level3_default !== "" &&
        producttransactiondetails.get("level3_allow")
      ) {
        this.set(
          "producttransactiondetails.level3_default",
          JSON.parse(level3_default)
        );
      } else {
        this.set("producttransactiondetails.level3_default", null);
      }

      producttransactiondetails.save().then(
        function () {
          noty({
            text: "Deposit Account Service saved successfully!",
          });
          self.set("isLoading", false);
          self.set("isEditing", false);
          self.transitionToRoute("locations.products", self.get("location.id"));
        },
        function (errors) {
          if (errors.errors) {
            self.set("defaultlevel3errors", errors.errors);
          }
          window.scrollTo(0, 0);
          self.set(
            "producttransactiondetails.level3_default",
            JSON.stringify(self.get("producttransactiondetails.level3_default"))
          );
          self.set("isLoading", false);
        }
      );
    },
    viewDefaults: function () {
      this.toggleProperty("isViewingL3");
    },
  },
});
