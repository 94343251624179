import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  action: DS.attr('string'),
  created_ts: DS.attr('ts'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),
  user: DS.attr('array'),
  user_id: DS.attr('string'),

  // Defined model relationships
  changelog_details: DS.hasMany('changelogdetail'),
});
