import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),

  created_ts: DS.attr('ts'),
  description: DS.attr('string'),
  is_enabled: DS.attr('string'),

  // Computed Properties
  is_enabled_title: Ember.computed('is_enabled', function() {
    var featureFlagsEnableTypes = this.get('applicationData.featureFlagsEnableTypes');
    var status = '';
    var is_enabled = this.get('is_enabled');
    featureFlagsEnableTypes.forEach(function(type) {
      if (type.id === is_enabled) {
        status = type.title;
      }
    });
    return status;
  }),

  // Defined model relationships
  users: DS.hasMany('user'),
});
