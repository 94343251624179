import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      userreports: store.query('userreport', {
        created_user_id: params.user_id,
        page_size: '500',
        sort: 'title'
      }),
      userreportshares: store.query('sharedreport', {
        user_id: params.user_id,
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }),
      // The user request needs to stay towards the bottom otherwise we get inconsistent
      // results when running checks for isDomainMismatch
      user: store.queryRecord('user', {
        id: params.user_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      })
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authroleusers: store.query('authroleuser', {
        user_id: models.user.get('id'),
        page_size: 500
      }).then(function(data) {
        models.authroleusers = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    controller.set('user', user);
    controller.set('location', models.location);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles
    controller.set('userreports', models.userreports);
    controller.set('userreportshares', models.userreportshares);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + user.get('username') + ' ● Report List',
      route_name: 'locations.users-userreports',
      route_id: models.location.get('id'),
      route_id_plus: user.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Users',
      link: 'locations.users-index',
      record: models.location.get('id')
    }, {
      title: user.get('username'),
      link: 'locations.users-view',
      record: models.location.get('id'),
      record_plus: user.get('id'),
    }, {
      title: 'Report List',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
