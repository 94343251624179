import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sharereportController: Ember.inject.controller('components/modals/sharereport'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Various controller properties
  isLoading: false,
  isCriteriaLocationsArray: [],
  isCriteriaUsersArray: [],
  selectedLocations: Ember.computed('reportSharedWithLocations', function() {
    return this.getSharedReportIds('reportSharedWithLocations');
  }),
  selectedUsers: Ember.computed('reportSharedWithUsers', function() {
    return this.getSharedReportIds('reportSharedWithUsers');
  }),
  reportSharedWithLocations: Ember.computed('reportSharedWith', function() {
    return this.getSharedReports('location');
  }),
  reportSharedWithUsers: Ember.computed('reportSharedWith', function() {
    return this.getSharedReports('user');
  }),
  reportCreatedUserId: Ember.computed('reportCreated', function() {
    return this.get('reportCreated.created_user_id');
  }),
  reportTitle: Ember.computed('reportCreated', function() {
    return 'Share Report: ' + this.get('reportCreated.title');
  }),

  getSharedReports: function(type) {
    var loggedInUserId = this.get('session.authenticated.user_id');
    var reportSharedWith = this.get('reportSharedWith');
    var self = this;
    var tempArr = reportSharedWith.filter(function(report) {
      if (type === 'location') {
        if (loggedInUserId === report.get('user_report.created_user_id')) {
          Ember.set(report, 'isReportOwner', true);
        } else {
          Ember.set(report, 'isReportOwner', false);
        }
      }
      if (report.get('model') === type && !report.get('model_name')) {
        return self.getLocationOrUserName(type, report).then(function(res) {
          return res;
        });
      } else {
        return report.get('model') === type;
      }
    });
    return tempArr;
  },
  getSharedReportIds: function(type) {
    var reportShares = this.get(type);
    var tempArr = reportShares.map(function(report) {
      return report.get('model_id');
    });
    return tempArr;
  },
  getLocationOrUserName: function(type, report) {
    var session = this.get('session');
    return jQuery.ajax({
      url: window.UiENV.APP.FULL_URL + '/' + type + 's/' + report.get('model_id'),
      type: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'developer-id': window.UiENV.APP.DEVELOPER_ID,
        'access-token': session.get('authenticated.token')
      }
    }).then(function(data) {
      if (type === 'location') {
        report.set('model_name', data.location.name);
      } else if (type === 'user') {
        report.set('model_name', data.user.username);
      }
      return report;
    }, function() {
      noty({
        text: 'Error getting location or username!',
        type: 'error'
      });
    });
  },

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('userreportshare.errors.messages', function() {
    this.set('errorMessages', this.get('userreportshare.errors.messages'));
  }),


  // Actions
  actions: {
    isCriteriaLocationsFind: function(data) {
      var self = this;
      var isCriteriaLocationsArray = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10'
      });
      isCriteriaLocationsArray.then(function(result) {
        var selectedLocations = self.get('selectedLocations');
        var filteredArray = result.filter(function(location) {
          if (selectedLocations.indexOf(location.id) === -1) {
            return location;
          }
        });
        self.set('isCriteriaLocationsArray', filteredArray);
      });
    },
    isCriteriaUsersFind: function(data) {
      var self = this;
      var isCriteriaUsersArray = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10'
      });
      var reportCreatedUserId = this.get('reportCreatedUserId');
      isCriteriaUsersArray.then(function(result) {
        var selectedUsers = self.get('selectedUsers');
        var filteredArray = result.filter(function(user) {
          if (selectedUsers.indexOf(user.id) === -1 && user.id !== reportCreatedUserId) {
            return user;
          }
        });
        self.set('isCriteriaUsersArray', filteredArray);
      });
    },
    shareReport: function(reportshare) {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var newuserreportshare = this.get('store').createRecord('userreportshare', {
        'model': reportshare.model,
        'model_id': reportshare.model_id,
        'user_report_id': reportshare.user_report_id,
      });
      newuserreportshare.save().then(
        function() {
          noty({
            text: 'Successfully shared Report!',
            type: 'success',
            timeout: '3000'
          });
          self.set('isLoading', false);
          self.set('reportshare.model_id', null);
          self.send('updateReportSharedWith');
          jQuery('#reportshare_model_id_autocomplete').focus();
        },
        function() {
          noty({
            text: 'Error sharing Report!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        }
      );
    },
    unlinkUserreportshare: function(report) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Shared Report?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        report.deleteRecord();
        report.save().then(function() {
          noty({
            text: 'Shared Report deleted successfully!'
          });
          self.send('updateReportSharedWith');
          self.set('isLoading', false);
        }, function() {
          report.rollbackAttributes();
          noty({
            text: 'Error deleting Shared Report!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    updateReportSharedWith: function() {
      var self = this;
      var userReportId = this.get('reportCreated.id');
      self.set('isLoading', true);
      this.get('store').query('userreportshare', {
        user_report_id: userReportId,
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }).then(function(data) {
        self.set('reportSharedWith', data);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error updating Shared Reports!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
      });
    },
    shareReportWith: function(type) {
      let objectPath = 'isCriteria.' + type;
      this.set('isCriteria', {});
      this.set(objectPath, true);
      this.set('reportshare.model', type);
      Ember.run.later(function() {
        jQuery('#reportshare_model_id_autocomplete').focus();
      }, 0);
    },
    showSharedWith: function(type) {
      let objectPath = 'showSharedWith.' + type;
      this.set(objectPath, !this.get(objectPath));
    },
  }
});
