import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransactioncredential.errors.messages', function() {
    this.set('errorMessages', this.get('producttransactioncredential.errors.messages'));
  }),

  changedProduct: Ember.observer('product', function() {
    var self = this;
    var product = this.get('product');

    if (!product) {
      return;
    }

    var productcredentials = self.get('store').findRecord('admin_producttransactioncredential', product.get('id'));
    productcredentials.then(function(result) {
      self.set('producttransactioncredential', result);
    });
  }),

  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    cancel: function() {
      var prodcred = this.get('producttransactioncredential');
      prodcred.rollbackAttributes();
      this.set('isEditing', false);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var productCredential = this.get('producttransactioncredential');
      productCredential.save().then(
        function() {
          noty({
            text: 'Credentials saved successfully!',
            timeout: '5000'
          });
          self.set('isLoading', false);
          self.set('isEditing', false);
          self.send('closeModal');
        },
        function() {
          noty({
            text: 'Error saving credentials!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        }
      );
    }
  }
});
