import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsChangeEmailsTrxReceipts: true,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('locations.view', this.get('location.id'));
    },
    saveChangeAllEmailTrxRecipts: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/locations/' + this.get('location.id') + '/update-all-contact-email-receipts',
        type: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': self.get('session.authenticated.token')
        },
        data: JSON.stringify({
          "set_all_contact_email_trx_receipt": this.get('changeTrxEmailsRecipts')
        })
      }).then(function() {
        self.set('isLoading', false);
        self.set('changeTrxEmailsRecipts', null);
        noty({
          text: 'Contact email transaction receipts updated successfully!',
          type: 'success'
        });
      }, function() {
        self.set('isLoading', false);
        noty({
          text: 'Contact email transaction receipts update failed!',
          type: 'error'
        });
      });
    },

  }
});
