import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  EntryModeItems: [{
    id: 'B',
    title: 'Bar Code'
  }, {
    id: 'S',
    title: 'Swiped'
  }, {
    id: 'K',
    title: 'Keyed'
  }, {
    id: 'C',
    title: 'Chip Card'
  }, {
    id: 'P',
    title: 'Contactless'
  }, {
    id: 'F',
    title: 'Fallback'
  }, {
    id: '',
    title: 'Unknown'
  }],

  // Set controller params
  isRefreshing: true,
  isEditableGrid: true,
  fieldData: [],
  needsRefresh: '',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'account_vault_id',
    'ach_sec_code',
    'auth_amount',
    'auth_code',
    'batch',
    'billing_city',
    'billing_phone',
    'billing_state',
    'billing_street',
    'billing_zip',
    'charge_back_date',
    'charge_back_date_from',
    'charge_back_date_to',
    'clerk_number',
    'contact_id',
    'contact-id',
    'contact-first_name',
    'contact-last_name',
    'customer_ip',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'description',
    'effective_date',
    'entry_mode_id',
    'first_six',
    'hosted_payment_page_id',
    'id',
    'is_accountvault',
    'is_recurring',
    'last_four',
    'location_id',
    'needsRefresh',
    'order_num',
    'payment_method',
    'product_transaction_id',
    'quick_invoice_id',
    'reason_code_id',
    'recurring_id',
    'room_num',
    'room_rate',
    'settle_date',
    'settle_date_from',
    'settle_date_to',
    'status_id',
    'tags',
    'tax',
    'terminal_id',
    'terminal-serial_number',
    'tip_amount',
    'transaction_amount',
    'transaction_api_id',
    'transaction_batch_id',
    'transaction_c1',
    'transaction_c2',
    'transaction_c3',
    'trx_source_id',
    'type_id',
    'transaction_level3',
    'card_type',
    'bin_type'
  ],
  terminals: Ember.computed('locations', function() {
    var terminals = Ember.A();
    var locations = this.get('locations');
    locations.forEach(function(location) {
      var terms = location.get('terminals');
      if (terms.get('length') > 0) {
        terms.forEach(function(t) {
          terminals.pushObject({
            id: t.get('id'),
            title: t.get('title')
          });
        });
      }
    });
    return terminals;
  }),
  columns: Ember.computed('fieldData.@each', 'actionColumns.@each.title', 'isMobileSize', function() {
    var fieldData = this.get('fieldData');
    return fieldData;
  }),
  actionColumns: Ember.computed('isMobileSize', function() {
    var self = this;
    return [{
      'name': 'id',
      'title': this.get('isMobileSize') ? '' : 'Trx<br>Detail',
      'type': 'detail',
      'action': function(transaction) {
        self.send('showDetail', transaction);
      },
      'actionId': 'transactions_report_fulldetail_link', // grid-view component needs this fields to know if column exists already so it doesn't try adding again
      'sortable': false,
      'isActionColumn': true
    }, {
      'name': 'id',
      'title': this.get('isMobileSize') ? '' : 'Trx<br>Receipt',
      'type': 'receipt',
      'action': function(transaction) {
        self.send('showTrans', transaction);
      },
      'actionId': 'transactions_report_receipt_link', // grid-view component needs this fields to know if column exists already so it doesn't try adding again
      'sortable': false,
      'isActionColumn': true
    }];
  }),

  // Actions
  actions: {
    showDetail: function(trans) {
      this.transitionToRoute('transactiondetailfull', trans.get('id'));
    },
    showUserReport: function(fieldData, filterData, url) {
      this.send('showUserReportName', fieldData, filterData, url);
    },
    showUserreportShareModal: function(report, data) {
      this.send('showShareReport', report, data);
    },
    showTrans: function(transaction) {
      var self = this;
      var store = this.get('store');
      return Ember.RSVP.hash({
        locationinfo: store.findRecord('locationinfo', transaction.get('location_id'))
      }).then(function(hash) {
        self.send('showTransDetail', transaction, hash.locationinfo);
      }, function() {
        noty({
          text: 'Error getting transaction detail!',
          type: 'error',
          timeout: '5000'
        });
      });
    },
  }
});
