import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  modelNameFromPayloadKey: function() {
    return this._super('terminaldevicelogs');
  },
  normalize: function(type, hash, property) {
    // Ember Data use the zone name as the ID.
    if(!hash.id) {
      hash.id = (hash.servername + Math.random().toString(36).slice(2)) + '-' + hash.created_ts;
    }

    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  },
});
