import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/productrecurringform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      productrecurring: store.createRecord('productrecurring', {
        location_id: params.id,
        send_declined_notifications: true,
        declined_notification_per_item_fee: '0.50',
        require_full_payment: true
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('productrecurring', models.productrecurring);
    controller.set('isEditing', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-productrecurrings-delete'),
      canPost: this.get('session.authenticated.resources.v2-productrecurrings-post'),
      canPut: this.get('session.authenticated.resources.v2-productrecurrings-put'),
      canActivate: this.get('session.authenticated.resources.v2-productrecurrings-post-activate')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Recurring Billing Service',
      route_name: 'locations.addproductrecurring',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: 'Add Recurring Billing Service',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.controller.get('productrecurring');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
