import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  apply_to_user_type_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  max_fee_amount: DS.attr('string'),
  max_transaction_amount: DS.attr('string'),
  min_fee_amount: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  producttransaction_id: DS.attr('string'),
  refund_surcharges: DS.attr('boolean'),
  surcharge_amount: DS.attr('string'),
  surcharge_fee: DS.attr('string'),
  surcharge_label: DS.attr('string'),
  surcharge_on_recurring: DS.attr('boolean'),
  surcharge_rate: DS.attr('string'),
  surcharge_refunded: DS.attr('number'), // *** MAYBE ***
  is_surcharge: DS.attr('string'),
  title: DS.attr('string'),

  // Computed Properties
  apply_to_user_type_display: Ember.computed('apply_to_user_type_id', function() {
    switch (this.get('apply_to_user_type_id')) {
      case '100':
        return 'Contact Users';
      case '200':
        return 'Location Users';
      default:
        return 'All Users';
    }
  })
});
