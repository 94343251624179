import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isLoading: false,

  DISPLAYED_MESSAGE:"* Thank you. Please check the email address associated with your account. If you do not receive a password reset email within the next 24 hours, please try again.",

  // Set some default properties
  errorMessages: null,
  errors: [],
  username: null,
  message:null,

  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // All controller actions
  actions: {
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      //'Password reset email has been sent to email address on file!'
      var self = this;
      var username = this.get('username');
      var data = {
        "user_name": username,
        "url": window.UiENV.APP.DOMAIN
      };
      new Ember.RSVP.Promise(function() {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/forgotpassword',
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(function() {
          noty({
            text: self.DISPLAYED_MESSAGE,
            type:'warning',
            timeout: 5000
          });
          self.set('isLoading', false);
          self.transitionToRoute('login');
        }, function(err) {
          noty({
            text: self.DISPLAYED_MESSAGE,
            type:'warning',
            timeout: 5000
          });
            self.set('isLoading', false);
            self.transitionToRoute('login');
        });
      });
    },
  }
});
