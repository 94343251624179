import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by grid-recurrings component
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsRecurrings: true,

  // Set controller params
  sort: '-next_run_date',
  page: 1,
  page_size: '15',
  active: '0,1',
  status: 'active,on hold',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'active',
    'contact_id',
    'created_ts',
    'created_ts_to',
    'created_ts_from',
    'description',
    'end_date',
    'end_date_to',
    'end_date_from',
    'id',
    'installment_amount_total',
    'installment_total_count',
    'interval',
    'interval_type',
    'last_four',
    'needsRefresh',
    'next_run_date',
    'next_run_date_to',
    'next_run_date_from',
    'notification_days',
    'payment_method',
    'product_transaction_id',
    'recurring_type_id',
    'start_date',
    'start_date_to',
    'start_date_from',
    'status',
    'tags',
    'transaction_amount'
  ],

  // Actions
  actions: {}
});
