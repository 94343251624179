import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  country: DS.attr('string'),
  location_id: DS.attr('string'),
  rate: DS.attr('string'),
  zip_code: DS.attr('string'),

  // Defined model relationships
  location: DS.belongsTo('location'),
});
