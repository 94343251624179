import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  device_term_api_id: DS.attr('string'),
  location_id: DS.attr('string'),
  reason_code_id: DS.attr('string'),
  require_signature: DS.attr('boolean'),
  terminal_id: DS.attr('string'),
  terms_conditions: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  reason_code: DS.belongsTo('reasoncode', {
    async: true
  }),
  signature: DS.belongsTo('signature'),
  terminal: DS.belongsTo('terminal'),
});
