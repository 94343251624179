import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {
  activate: function() {
    this._super();
    this.transitionTo('virtualterminal');
  },
  model: function() {},
  setupController: function(controller, model) {
    controller.set('contacts', model);
    controller.set('pageTitle', 'Dashboard');
    controller.set('pageCrumbs', [{
      title: 'Virtual Terminal',
      link: ''
    }]);
  },
  actions: {}
});
