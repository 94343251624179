import Ember from "ember";
import jQuery from "jquery";

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),
  store: Ember.inject.service(),
  transactionCalculations: Ember.inject.service("transaction-calculations"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),

  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  errorMessages: null,
  updateTransMessages: Ember.observer(
    "transaction.errors.messages.[]",
    function () {
      this.set("errorMessages", this.get("transaction.errors.messages"));
    }
  ),
  surchargeAmounts: {},
  transactionErrors: null,
  isDebitSurchargeInfo: null,
  isDebitSurchargable: false,
  DEBIT_INFORMATION: "This card is a debit card and no surcharge will be applied to the transaction.",
  isCardValidated: true,
  isRefundChanged: false,
  card_type: '',
  av_Id:'',

  // Events
  didInsertElement: function () {
    var self = this;
    this.set("isCC", false);
    this.set("isACH", false);
    this.set("isNewCC", false);
    this.set("isNewACH", false);
    this.set("isExistingCC", false);
    this.set("isExistingACH", false);
    this.set("scenario", null);
    this.set("isLoading", false);
    this.set("isDebitSurchargeInfo", false);
    this.notifyPropertyChange("trans_actions_ach");
    this.notifyPropertyChange("trans_actions_cc");
    var contact_id = this.get("contact.id") || null;
    var transaction = this.get("store").createRecord("transaction", {
      contact_id: contact_id,
      exp_month: null,
      exp_year: null,
      routing: null,
      product_transaction_id: null,
      payment_method: null,
    });
    if (this.get("isRouteContactUser")) {
      transaction.set("terms_agree", false);
    }
    this.set("transaction", transaction);
    var locations = this.get("locations");
    var primary_location_id = this.get("isQuickInvoicePayment")
      ? this.get("quickinvoice.location_id")
      : this.get("session.authenticated.primary_location_id");
    var primary_location = locations.filter(function (location) {
      return location.get("id") === primary_location_id;
    });
    if (primary_location.get("length") === 1) {
      this.set("transaction.location_id", primary_location_id);
    } else {
      this.set(
        "transaction.location_id",
        locations.get("firstObject").get("id")
      );
    }
    this.set("errorWarningMessage", false);
    this.set("isLoading", false);
    this.set("calculatingTax", false);
    this.set("processMethodTerminal", false);
    this.set("paymentMethodCreditCard", true);
    this.set("personalIdentityDOBYear", true);
    this.set("currentAccount_is_company", "0");
    this.set("selectedTags", "");
    this.set("showQuickInvoiceDetails", false);
    this.setupLocation();
    this.setupProduct();
    if (!this.get("disabled_card_reader") && !this.get("cardReader")) {
      this.setupCardReader();
    }
    this.setupScenario();

    Ember.run.later(function () {
      // Update sso params if there are any
      // This has to be done after everything else is set
      self.ssoParamsUpdate();
      self.ssoTagsAdd();

      // if passing in transactionData need to do this after everything else
      var transactionData = self.get("transactionData");
      if (transactionData) {
        Object.keys(transactionData).forEach(function (key) {
          if (key === "tags_csv") {
            self.set("selectedTags", transactionData[key]);
            self.ssoTagsAdd();
          } else if (key === "product_transaction_id") {
            var prod = self
              .get("products")
              .filterBy("id", transactionData[key])
              .get("firstObject");
            if (prod && prod.get("active")) {
              self.set(
                "transaction.product_transaction_id",
                transactionData[key]
              );
            } else {
              if (
                transactionData.payment_method === "ach" &&
                self.get("productsACH.length")
              ) {
                let prodACH = self.get("productsACH").get("firstObject");
                if (prodACH) {
                  self.set(
                    "transaction.product_transaction_id",
                    prodACH.get("id")
                  );
                }
              } else if (
                transactionData.payment_method === "cc" &&
                self.get("productsCC.length")
              ) {
                let prodCC = self.get("productsCC").get("firstObject");
                if (prodCC) {
                  self.set(
                    "transaction.product_transaction_id",
                    prodCC.get("id")
                  );
                }
              }
            }
          } else {
            self.set("transaction." + key, transactionData[key]);
          }
        });
      }
      if (
        !transaction.get("isDestroyed") &&
        !transaction.get("isDestroying") &&
        self.get("action")
      ) {
        self.set("transaction.action", self.get("action"));
        self.setTransactionAction();
      }
      if (self.get("isQuickInvoicePayment")) {
        if (!self.get("quickinvoice.allow_partial_pay")) {
          self.set(
            "transaction.subtotal_amount",
            self.get("quickinvoice.amount_due")
          );
          self.calculateTotal();
        }
        if (self.get("parent.target.currentRoute.name") === "contactuser.quickinvoices-index") {
            self.setMultiPayAmounts();
        }
        self.set("transaction.quick_invoice_id", self.get("quickinvoice.id"));
      }
    }, 1);
  },

  setMultiPayAmounts: function () {
    this.set(
      "transaction.multi_subtotal_amount",
      String(this.get("multiPayTotals.subtotal").toFixed(2))
    );
    this.set(
      "transaction.multi_surcharge_amount",
      String(this.get("multiPayTotals.surcharge").toFixed(2))
    );
    this.set(
      "transaction.multi_overall_total",
      String(this.get("multiPayTotals.overall_total").toFixed(2))
    );
  },

  willDestroyElement: function () {
    var model = this.get("transaction");
    if (model.get("hasDirtyAttributes") && model.get("isNew")) {
      model.unloadRecord();
    } else if (model.get("hasDirtyAttributes")) {
      model.rollbackAttributes();
    }
    if (!this.get("disabled_card_reader")) {
      this.destroyCardReader();
    }
  },

  // Properties
  cardReader: null,
  checkinDateMin: null,
  checkinDateMax: null,
  checkoutDateMin: null,
  checkoutDateMax: null,
  isDebitCard:false,
  isAccountVaultedCard:false,

  // Computed Properties
  ach_sec_codes_is_company: Ember.computed(function () {
    return this.get("applicationData.ach_sec_codes_is_company");
  }),
  ach_sec_codes_not_company: Ember.computed(function () {
    return this.get("applicationData.ach_sec_codes_not_company");
  }),
  achSecCodeContents: Ember.computed(
    "currentAccount_is_company",
    "product.id",
    function () {
      var isCompany = String(this.get("currentAccount_is_company")) === "1";
      if (this.get("product.isSubProcessorPaya")) {
        var codes = this.get("product.processor_data.terminals").map(function (
          t
        ) {
          return {
            id: t.sec_code,
            title: t.sec_code,
          };
        });
        codes = codes.filter(function (c) {
          // NOTE: Filtering out C21 until support running in UI is implemented
          return c.id !== "C21";
        });
        if (isCompany) {
          return codes.filter(function (code) {
            return code.id === "CCD";
          });
        } else {
          return codes.filter(function (code) {
            return code.id !== "CCD";
          });
        }
      } else {
        return isCompany
          ? this.get("ach_sec_codes_is_company")
          : this.get("ach_sec_codes_not_company");
      }
    }
  ),

  cannotChangeIsCompany: Ember.computed("product.id", function () {
    if (this.get("product.isSubProcessorPaya")) {
      var codes = this.get("product.processor_data.terminals").map(function (
        t
      ) {
        return t.sec_code;
      });
      var disable = false;
      if (
        codes.indexOf("CCD") === -1 ||
        (codes.length === 1 && codes.indexOf("CCD") !== -1)
      ) {
        disable = true;
      }
      return disable;
    } else {
      return false;
    }
  }),

  canChangeTags: Ember.computed(
    "session.authenticated.user_type_id",
    "session.authenticated.resources.v2-tags-get",
    function () {
      var user_type_id = this.get("session.authenticated.user_type_id");
      if (user_type_id < 200) {
        return false;
      }
      if (this.get("session.authenticated.resources.v2-tags-get")) {
        return true;
      }
      return false;
    }
  ),

  canVT: Ember.computed("product.{payment_method,vt_enable}", function () {
    // On qa virtual terminal is not enabled due to product object being empty.
    // Fetching all the products, then filtering it from the transaction if the product object is empty.
    const products = this.get('products');
    const transaction = this.get('transaction');
    let product = this.get('product');
    if (!product && transaction && products && products.length) {
      const filteredProducts = products.filter((a) => a.id === transaction.product_transaction_id);
      product = filteredProducts && filteredProducts.length ? filteredProducts[0] : null;
    }
    if (product) {
      return (
        product.get("vt_enable") || product.get("payment_method") === "ach"
      );
    }
    return false;
    // return (
    //   this.get("product.vt_enable") ||
    //   this.get("product.payment_method") === "ach"
    // );
  }),

  hasProducts: Ember.computed("products.[]", function () {
    return this.get("products.length") >= 1;
  }),

  isContact: Ember.computed(
    "show_contact_name",
    "transaction.contact_id",
    function () {
      return (
        this.get("transaction.contact_id") !== null &&
        this.get("show_contact_name")
      );
    }
  ),

  isRunagain: Ember.computed("route", function () {
    return this.get("route") === "runagain";
  }),

  isSingleColumn: Ember.computed("single_column", function () {
    return this.get("single_column");
  }),

  isTransactionACH: Ember.computed("product.payment_method", function () {
    return this.get("product.payment_method") === "ach";
  }),

  isTransactionCC: Ember.computed("product.payment_method", function () {
    return this.get("product.payment_method") === "cc";
  }),

  location: Ember.computed("transaction.location_id", function () {
    var location_id = this.get("transaction.location_id");
    return this.get("locations")
      .filter(function (location) {
        return location.get("id") === location_id;
      })
      .get("firstObject");
  }),

  locationTerminals: Ember.computed(
    "terminals.[]",
    "transaction.location_id",
    function () {
      var location_id = this.get("transaction.location_id");
      var terminals = this.get("terminals");
      if (terminals) {
        return this.get("terminals").filter(function (terminal) {
          return terminal.get("location_id") === location_id;
        });
      } else {
        return [];
      }
    }
  ),

  products: Ember.computed(
    "locations.[]",
    "transaction.location_id",
    "location.id",
    "isRouteContactUser",
    "isQuickInvoicePayment",
    function () {
      var products = this.get("location.product_transactions");
      var self = this;
      if (products) {
        if (
          this.get("isRouteContactUser") &&
          this.get("isQuickInvoicePayment")
        ) {
          products = products.filter(function (prod) {
            if (prod.get("active")) {
              return (
                (prod.get("id") ===
                  self.get("quickinvoice.ach_product_transaction_id") &&
                  self.get(
                    "quickinvoice.ach_product_transaction.quick_invoice_allow"
                  )) ||
                (prod.get("id") ===
                  self.get("quickinvoice.cc_product_transaction_id") &&
                  self.get(
                    "quickinvoice.cc_product_transaction.quick_invoice_allow"
                  ))
              );
            }
          });
        } else {
          products = products.filter(function (prod) {
            return prod.get("active");
          });
        }
        return products.sortBy("title");
      } else {
        return [];
      }
    }
  ),

  productsOptions: Ember.computed(
    "transaction.location_id",
    "transaction.product_transaction_id",
    "transaction.payment_method",
    "scenario",
    "is_CC_and_ACH_Products_and_CC_and_ACH_Accounts",
    "is_CC_and_ACH_Products_with_ACH_and_no_CC_Accounts",
    "is_CC_and_ACH_Products_with_CC_and_no_ACH_Accounts",
    "is_only_CC_Products_with_CC_Accounts",
    "is_only_ACH_Products_with_ACH_Accounts",
    function () {
      this.set("errorWarningMessage", false);
      var products = this.get("products");
      products = products.filter(function (prod) {
        return prod.get("active");
      });
      var hasProductCC = this.get("location.hasProductCC");
      var hasProductACH = this.get("location.hasProductACH");
      var productsCC = this.get("productsCC");
      var productsACH = this.get("productsACH");
      var scenario = this.get("scenario");
      if (products) {
        if (scenario === "new_account") {
          if (
            this.get("disabled_account_vault_id") &&
            this.get("transaction.payment_method") === "cc"
          ) {
            products = productsCC;
          } else if (
            this.get("disabled_account_vault_id") &&
            this.get("transaction.payment_method") === "ach"
          ) {
            products = productsACH;
          } else {
            if (hasProductCC && hasProductACH) {
              products = productsCC.concat(productsACH);
            } else if (hasProductCC && !hasProductACH) {
              products = productsCC;
            } else if (hasProductACH && !hasProductCC) {
              products = productsACH;
            }
          }
        } else if (scenario === "stored_account") {
          if (
            this.get("disabled_account_vault_id") &&
            this.get("account.payment_method") === "cc"
          ) {
            products = productsCC;
          } else if (
            this.get("disabled_account_vault_id") &&
            this.get("account.payment_method") === "ach"
          ) {
            products = productsACH;
          } else {
            if (this.get("is_CC_and_ACH_Products_and_CC_and_ACH_Accounts")) {
              // if cc & ach products && cc & ach accounts
              products = productsCC.concat(productsACH);
            } else if (
              this.get("is_CC_and_ACH_Products_with_ACH_and_no_CC_Accounts")
            ) {
              // if cc & ach products && with ach accounts and NO cc accounts
              products = productsACH;
            } else if (
              this.get("is_CC_and_ACH_Products_with_CC_and_no_ACH_Accounts")
            ) {
              // if cc & ach products && with cc accounts and NO ach accounts
              products = productsCC;
            } else if (this.get("is_only_CC_Products_with_CC_Accounts")) {
              // if only cc products && with cc acounts
              products = productsCC;
            } else if (this.get("is_only_ACH_Products_with_ACH_Accounts")) {
              // if only ach products and with ach accounts
              products = productsACH;
            }
          }
        } else {
          products.filter(function (product) {
            return product.get("active");
          });
        }
        return products.sortBy("title");
      } else {
        return [];
      }
    }
  ),

  productsACH: Ember.computed(
    "products.[]",
    "transaction.location_id",
    "isQuickInvoicePayment",
    function () {
      var products = this.get("products").filter(function (product) {
        return product.get("active") && product.get("payment_method") === "ach";
      });
      return products.sortBy("title");
    }
  ),

  productsCC: Ember.computed(
    "products.[]",
    "transaction.location_id",
    "isQuickInvoicePayment",
    function () {
      var products = this.get("products").filter(function (product) {
        return product.get("active") && product.get("payment_method") === "cc";
      });
      return products.sortBy("title");
    }
  ),

  product: Ember.computed(
    "products.[]",
    "transaction.product_transaction_id",
    function () {
      var product_transaction_id = this.get(
        "transaction.product_transaction_id"
      );
      return this.get("products")
        .filter(function (product) {
          return product.get("id") === product_transaction_id;
        })
        .get("firstObject");
    }
  ),

  accountsCC: Ember.computed("accounts.[]", function () {
    var accounts = this.get("accounts");
    if (accounts) {
      var accountsCC = accounts.filter(function (item) {
        // Filter cc accounts
        return (
          item.get("payment_method") === "cc" &&
          item.get("expiring_in_months") >= 0
        );
      });
      return accountsCC.get("length") ? accountsCC : Ember.A();
    } else {
      return Ember.A();
    }
  }),

  available_cc_account_labels: Ember.computed("accountsCC.[]", function () {
    var available_accounts = this.get("accountsCC");
    var object = [];
    var self = this;
    if (available_accounts && available_accounts.length) {
      let firstIndex = available_accounts[0];
      object = available_accounts.map((account) => account.get("select_title"));
       // Fetch the accountvault record.
      this.set('av_Id',firstIndex.id);
      this.get('store')
      .findRecord("accountvault", firstIndex.id)
      .then(function (av) {
        self.debitFilter(av.first_six, self);
      });
      return object;
    } else {
      return [];
    }
  }),

  accountsACH: Ember.computed("accounts.[]", function () {
    var accounts = this.get("accounts");
    if (accounts) {
      var accountsACH = accounts.filter(function (item) {
        // Filter ach accounts
        return item.get("payment_method") === "ach";
      });
      return accountsACH.get("length") ? accountsACH : Ember.A();
    } else {
      return Ember.A();
    }
  }),

  available_ach_account_labels: Ember.computed("accountsACH.[]", function () {
    var available_accounts = this.get("accountsACH");
    if (available_accounts && available_accounts.length) {
      return available_accounts.map(function (account) {
        return account.get("select_title");
      });
    } else {
      return [];
    }
  }),
  accountsAvailable: Ember.computed(
    "accounts.[]",
    "accountsCC.[]",
    "accountsACH.[]",
    "isQuickInvoicePayment",
    function () {
      var hasProductCC = this.get("location.hasProductCC");
      var hasProductACH = this.get("location.hasProductACH");
      var cc_accounts_length = this.get("accountsCC.length");
      var ach_accounts_length = this.get("accountsACH.length");
      var quickinvoice = this.get("quickinvoice");
      var accounts = this.get("accounts")
        ? this.get("accounts").filter(function (account) {
            if (account.get("active")) {
              if (account.get("payment_method") === "cc") {
                return account.get("expiring_in_months") >= 0;
              }
              return true;
            }
          })
        : [];
      if (this.get("isQuickInvoicePayment")) {
        if (
          quickinvoice.get("cc_product_transaction_id") &&
          quickinvoice.get("cc_product_transaction.active") &&
          quickinvoice.get("cc_product_transaction.quick_invoice_allow") &&
          quickinvoice.get("ach_product_transaction_id") &&
          quickinvoice.get("ach_product_transaction.active") &&
          quickinvoice.get("ach_product_transaction.quick_invoice_allow")
        ) {
          return accounts;
        } else if (
          quickinvoice.get("cc_product_transaction_id") &&
          (!quickinvoice.get("ach_product_transaction_id") ||
            !quickinvoice.get("ach_product_transaction.quick_invoice_allow") ||
            !quickinvoice.get("ach_product_transaction.active"))
        ) {
          return this.get("accountsCC");
        } else if (
          (!quickinvoice.get("cc_product_transaction_id") ||
            !quickinvoice.get("cc_product_transaction.quick_invoice_allow") ||
            !quickinvoice.get("cc_product_transaction.active")) &&
          quickinvoice.get("ach_product_transaction_id")
        ) {
          return this.get("accountsACH");
        }
      } else {
        if (
          (!hasProductCC || !cc_accounts_length) &&
          hasProductACH &&
          ach_accounts_length
        ) {
          return this.get("accountsACH");
        }
        if (
          (!hasProductACH || !ach_accounts_length) &&
          hasProductCC &&
          cc_accounts_length
        ) {
          return this.get("accountsCC");
        }
        if (
          hasProductACH &&
          ach_accounts_length &&
          hasProductCC &&
          cc_accounts_length
        ) {
          return accounts;
        }
      }
    }
  ),

  account: Ember.computed(
    "accounts.[]",
    "transaction.account_vault_id",
    "useAccount",
    function () {
      var accounts = this.get("accounts");
      var account_vault_id = this.get("transaction.account_vault_id");
      if (accounts) {
        return accounts
          .filter(function (acct) {
            return acct.get("id") === account_vault_id;
          })
          .get("firstObject");
      } else if (this.get("useAccount")) {
        return this.get("useAccount");
      } else {
        return null;
      }
    }
  ),

  isQuickInvoicePayment: Ember.computed("route", function () {
    return this.get("route") === "contactusermakepaymentquickinvoice";
  }),

  isRouteContactUser: Ember.computed("route", function () {
    return (
      this.get("route") === "contactusermakepayment" ||
      this.get("route") === "virtualterminalcontact_contactuser" ||
      this.get("route") === "contactusermakepaymentquickinvoice"
    );
  }),

  legal: Ember.computed(
    "isRouteContactUser",
    "product.{id,receipt_vt_above_signature}",
    function () {
      return this.get("isRouteContactUser")
        ? this.get("product.receipt_vt_above_signature")
        : false;
    }
  ),

  quickinvoiceFiles: Ember.computed("quickinvoice.files.[]", function () {
    var files = this.get("quickinvoice.files");
    var self = this;
    var session = this.get("session");
    if (files && files.length) {
      files = files.slice().map(function (file) {
        if (!self.get("quickinvoice.isNew")) {
          Ember.set(
            file,
            "link",
            window.ENV.APP.FULL_URL +
              "/files/" +
              file.id +
              "/attachment?access-token=" +
              session.get("authenticated.token") +
              "&developer-id=" +
              window.UiENV.APP.DEVELOPER_ID
          );
          Ember.set(file, "linkid", file.id + file.created_ts);
        }
        return file;
      });
      return files;
    } else {
      return [];
    }
  }),

  quickinvoiceItemListTotal: Ember.computed(
    "quickinvoice.item_list.[]",
    function () {
      var itemList = this.get("quickinvoice.item_list");
      var total = 0;
      if (itemList) {
        for (var prop in itemList) {
          if (itemList.hasOwnProperty(prop)) {
            if (typeof itemList[prop] === "number") {
              total += itemList[prop];
            } else if (typeof itemList[prop] === "string") {
              total += parseFloat(itemList[prop].replace(/,/g, ""));
            }
          }
        }
      }
      return total.toFixed(2);
    }
  ),

  requireCheckNumber: Ember.computed(
    "product.{id}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "check_number"
        );
      }
      return payaRequires;
    }
  ),

  requireCity: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "billing_city"
        );
      }
      return payaRequires;
    }
  ),

  requiredDriversLicense: Ember.computed(
    "product.id",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      if (!product.get("isSubProcessorPaya")) {
        return false;
      } else {
        return this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "driver_license"
        );
      }
    }
  ),

  requireImageBack: Ember.computed(
    "product.{id}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "image_back"
        );
      }
      return payaRequires;
    }
  ),

  requireImageFront: Ember.computed(
    "product.{id}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "image_front"
        );
      }
      return payaRequires;
    }
  ),

  requirePersonalIdentity: Ember.computed(
    "product.id",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      if (!product.get("isSubProcessorPaya")) {
        return false;
      } else {
        return this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "identity_verification"
        );
      }
    }
  ),

  requirePhone: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "billing_phone"
        );
      }
      return payaRequires;
    }
  ),

  requireState: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "billing_state"
        );
      }
      return payaRequires;
    }
  ),

  requireStreet: Ember.computed(
    "product.{vt_require_street,id}",
    "transaction.ach_sec_code",
    function () {
      var product_vt_street = this.get("product.vt_require_street");
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "billing_street"
        );
      }
      return product_vt_street || payaRequires;
    }
  ),

  requireZip: Ember.computed(
    "product.{vt_require_zip,vt_enable_sales_tax,id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      var product = this.get("product");
      var payaRequires = false;
      if (product.get("isSubProcessorPaya")) {
        payaRequires = this.checkPayaTerminalFor(
          product.get("processor_data.terminals"),
          this.get("transaction.ach_sec_code"),
          "billing_zip"
        );
      }
      return (
        this.get("product.vt_require_zip") ||
        this.get("product.vt_enable_sales_tax") ||
        payaRequires
      );
    }
  ),

  scenarios: Ember.computed(
    "location.hasProductCC",
    "location.hasProductACH",
    "accountsACH.[]",
    "accountsCC.[]",
    function () {
      var hasProductCC = this.get("location.hasProductCC");
      var hasProductACH = this.get("location.hasProductACH");
      var cc_accounts_length = this.get("accountsCC.length");
      var ach_accounts_length = this.get("accountsACH.length");
      var productsACH = this.get("productsACH");
      var productsCC = this.get("productsCC");
      var isAccount = this.get("account.id");
      var scenarios = [];
      if (this.get("isQuickInvoicePayment")) {
        if (
          (productsCC && productsCC.get("length") && cc_accounts_length) ||
          (productsACH && productsACH.get("length") && ach_accounts_length)
        ) {
          scenarios.push({
            id: "stored_account",
            title: "Account Vault",
          });
        }
      } else {
        if (
          (hasProductCC && cc_accounts_length > 0) ||
          (hasProductACH && ach_accounts_length > 0) ||
          isAccount
        ) {
          scenarios.push({
            id: "stored_account",
            title: "Account Vault",
          });
        }
      }
      scenarios.push({
        id: "new_account",
        title: "New Account",
      });
      return scenarios;
    }
  ),

  selectedAccountVaultIndex: Ember.computed(
    "transaction.account_vault_id",
    "accountsCC.[]",
    "accountsACH.[]",
    "product.{id,payment_method}",
    function () {

      var account_vault_id = this.get("transaction.account_vault_id");
      var available_accounts = [];

      if (this.get("product.payment_method") === "ach") {
        available_accounts = this.get("accountsACH");
      } else {
        available_accounts = this.get("accountsCC");
      }

      if (account_vault_id && available_accounts) {
        var selectedIndex = null;
        available_accounts.forEach(function (acct, index) {
          if (acct.get("id") === account_vault_id) {
            selectedIndex = index;
          }
        });
        return selectedIndex;
      }
    }
  ),

  showAccountInformation: Ember.computed(
    "route",
    "scenarios",
    "scenario",
    function () {
      return (
        (this.get("route") === "contactusermakepayment" ||
          this.get("route") === "contactusermakepaymentquickinvoice") &&
        this.get("scenarios.length") > 1 &&
        this.get("scenario") === "stored_account"
      );
    }
  ),

  showBilling: Ember.computed(
    "isNewCC",
    "isNewACH",
    "showZip",
    "showPhone",
    "showStreet",
    "showCity",
    "showState",
    function () {
      return (
        this.get("isNewCC") ||
        this.get("isNewACH") ||
        this.get("showZip") ||
        this.get("showStreet") ||
        this.get("showPhone")
      );
    }
  ),

  showBillingAndOrIdentityVerification: Ember.computed(
    "showBilling",
    "showIdentityVerification",
    function () {
      return this.get("showBilling") || this.get("showIdentityVerification");
    }
  ),

  showBusinessPersonal: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "isQuickInvoicePayment",
    function () {
      return this.get("isQuickInvoicePayment")
        ? !this.get("product.isSubProcessorPaya")
        : true;
    }
  ),

  showCheckNumber: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "isQuickInvoicePayment",
    function () {
      return (
        this.get("product.isSubProcessorPaya") &&
        !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showClerkNumber: Ember.computed(
    "product.{id,vt_clerk_number}",
    "isQuickInvoicePayment",
    function () {
      return (
        this.get("product.vt_clerk_number") &&
        !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showCity: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      return this.get("product.isSubProcessorPaya");
    }
  ),

  showCustomField1: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "isQuickInvoicePayment",
    function () {
      return (
        this.get("product.isSubProcessorPaya") &&
        !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showCustomField2: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "isQuickInvoicePayment",
    function () {
      return (
        this.get("product.isSubProcessorPaya") &&
        !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showCustomField3: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "isQuickInvoicePayment",
    function () {
      var product = this.get("product");
      return (
        product.get("isSubProcessorPaya") && !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showDescription: Ember.computed("isQuickInvoicePayment", function () {
    return !this.get("isQuickInvoicePayment");
  }),

  showDriversLicense: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    function () {
      return this.get("product.isSubProcessorPaya");
    }
  ),

  showEmail: Ember.computed("isRunagain", "isQuickInvoicePayment", function () {
    return !this.get("isRunagain") && !this.get("isQuickInvoicePayment");
  }),

  showIdentityVerification: Ember.computed(
    "showDriversLicense",
    "showPersonalIdentity",
    function () {
      return this.get("showDriversLicense") || this.get("showPersonalIdentity");
    }
  ),

  showImageBack: Ember.computed("product.{id,isSubProcessorPaya}", function () {
    return this.get("product.isSubProcessorPaya");
  }),

  showImageFront: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    function () {
      return this.get("product.isSubProcessorPaya");
    }
  ),

  showLocations: Ember.computed("locations.[]", function () {
    return this.get("locations.length") > 1 || this.get("show_location_name");
  }),

  showNameMismatch: Ember.computed(
    "transaction.account_holder_name",
    "contact.last_name",
    "isRouteContactUser",
    function () {
      var account_holder = this.get("transaction.account_holder_name");
      account_holder = account_holder ? account_holder.trim() : "";
      var full_name = this.get("contact.full_name");
      full_name = full_name ? full_name.trim() : "";
      if (
        !Ember.isEmpty(account_holder) &&
        !Ember.isEmpty(full_name) &&
        !this.get("isRouteContactUser")
      ) {
        if (account_holder.toLowerCase() !== full_name.toLowerCase()) {
          return true;
        }
      }
      return false;
    }
  ),

  showOrderNumber: Ember.computed(
    "product.{id,vt_order_num}",
    "isQuickInvoicePayment",
    function () {
      return (
        this.get("product.vt_order_num") && !this.get("isQuickInvoicePayment")
      );
    }
  ),

  showPersonalIdentity: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    function () {
      return this.get("product.isSubProcessorPaya");
    }
  ),

  showPhone: Ember.computed(
    "product.{vt_billing_phone,id,isSubProcessorPaya}",
    function () {
      return (
        this.get("product.vt_billing_phone") ||
        this.get("product.isSubProcessorPaya")
      );
    }
  ),

  showPaymentMethodOptions: Ember.computed(
    "scenario",
    "isRouteContactUser",
    "location.{hasProductCC,hasProductACH}",
    "isQuickInvoicePayment",
    function () {
      if (this.get("isQuickInvoicePayment")) {
        return (
          this.get("scenario") !== "stored_account" &&
          this.get("quickinvoice.cc_product_transaction_id") &&
          this.get("quickinvoice.cc_product_transaction.quick_invoice_allow") &&
          this.get("quickinvoice.cc_product_transaction.active") &&
          this.get("quickinvoice.ach_product_transaction_id") &&
          this.get(
            "quickinvoice.ach_product_transaction.quick_invoice_allow"
          ) &&
          this.get("quickinvoice.ach_product_transaction.active")
        );
      } else {
        return (
          this.get("scenario") !== "stored_account" &&
          this.get("isRouteContactUser") &&
          this.get("location.hasProductCC") &&
          this.get("location.hasProductACH")
        );
      }
    }
  ),

  showProcessMethod: Ember.computed(
    "scenario",
    "locationTerminals.[]",
    "isTransactionCC",
    "product.{isECommerce,isMOTO}",
    function () {
      return (
        this.get("scenario") !== "stored_account" &&
        this.get("locationTerminals.length") > 0 &&
        this.get("isTransactionCC") &&
        !this.get("product.isECommerce") &&
        !this.get("product.isMOTO")
      );
    }
  ),

  showProducts: Ember.computed(
    "productsOptions.[]",
    "disable_deposit_account",
    function () {
      return (
        this.get("productsOptions.length") > 1 &&
        !this.get("disable_deposit_account")
      );
    }
  ),

  showSaveAccount: Ember.computed(
    "location.product_accountvault",
    "route",
    "transaction.{account_vault_id,ach_sec_code}",
    function () {
      var route = this.get("route");
      var sec_code = this.get("transaction.ach_sec_code");
      return (
        this.get("location.product_accountvault.id") &&
        (route === "contactscharge" ||
          this.get("isRouteContactUser") ||
          route === "virtualterminal") &&
        !this.get("transaction.account_vault_id") &&
        sec_code !== "POP" &&
        sec_code !== "C21"
      );
    }
  ),

  showScenarios: Ember.computed("scenarios", "route", function () {
    return (
      this.get("scenarios.length") > 0 &&
      (this.get("route") === "contactscharge" ||
        this.get("route") === "contactusermakepayment" ||
        this.get("route") === "contactusermakepaymentquickinvoice")
    );
  }),

  showSECCode: Ember.computed("route", function () {
    return !this.get("isRouteContactUser");
  }),

  showState: Ember.computed("product.{id,isSubProcessorPaya}", function () {
    return this.get("product.isSubProcessorPaya");
  }),

  showStreet: Ember.computed(
    "product.{vt_street,id,payment_method}",
    "isQuickInvoicePayment",
    function () {
      var product = this.get("product");
      return (
        product.get("vt_street") ||
        product.get("payment_method") === "ach" ||
        this.get("isQuickInvoicePayment")
      );
    }
  ),

  showRemainingBalanceMessage: Ember.computed(
    "isQuickInvoicePayment",
    "quickinvoice.allow_partial_pay",
    function () {
      return (
        this.get("isQuickInvoicePayment") &&
        this.get("quickinvoice.allow_partial_pay")
      );
    }
  ),

  showSubtotal: Ember.computed(
    "showSurcharge",
    "showTax",
    "showTip",
    function () {

      return this.get("showSurcharge") ||
        this.get("showTax") ||
        this.get("showTip")
        ? true
        : false;
    }
  ),

  onMultiPayPage: Ember.computed(
    "isQuickInvoicePayment",
    "parent.target.currentRoute.name",
    function () {
      return this.get("parent.target.currentRoute.name") === "contactuser.quickinvoices-index";
    }
  ),

  disableSubtotalInput: Ember.computed(
    "isQuickInvoicePayment",
    "parent.target.currentRoute.name",
    "quickinvoice.allow_partial_pay",
    function () {
      return (this.get("parent.target.currentRoute.name") === "contactuser.dashboard") &&
      !this.get("quickinvoice.allow_partial_pay");
    }
  ),

  showSurcharge: Ember.computed(
    "product.surcharge.id",
    "transaction.action",
    function () {

      const product_has_surcharge = this.get("product.surcharge.id");
      const apply_to_user_type_id = this.get(
        "product.surcharge.apply_to_user_type_id"
      );
      const applies_to_user =
        (apply_to_user_type_id &&
          this.get("session.authenticated.user_type_id").toString() ===
            apply_to_user_type_id) ||
        !apply_to_user_type_id;
      return (
        product_has_surcharge &&
        applies_to_user &&
        (this.get("transaction.action") !== "refund" ||
          this.get("product.surcharge.refund_surcharges"))
      );
    }
  ),

  showTax: Ember.computed("product.vt_enable_sales_tax", "route", function () {
    return this.get("product.vt_enable_sales_tax") &&
      this.get("route") !== "contactusermakepaymentquickinvoice"
      ? true
      : false;
  }),

  showTip: Ember.computed("product.hasTip", function () {
    return this.get("product.hasTip") &&
      this.get("route") !== "contactusermakepaymentquickinvoice"
      ? true
      : false;
  }),

  showTransactionAmountDisabledOrShowSubtotal: Ember.computed(
    "showSubtotal",
    "isQuickInvoicePayment",
    "quickinvoice",
    function () {
      return (
        this.get("showSubtotal") ||
        (this.get("isQuickInvoicePayment") &&
          !this.get("quickinvoice.allow_partial_pay"))
      );
    }
  ),

  showTransactionAmount: Ember.computed(
    "transaction.action",
    "product.isSubProcessorPaya",
    function () {

      return (
        this.get("transaction.action") !== "avsonly" ||
        this.get("product.isSubProcessorPaya")
      );
    }
  ),

  showZip: Ember.computed(
    "product.{vt_require_zip,vt_enable_sales_tax,vt_zip,id,payment_method}",
    "isQuickInvoicePayment",
    function () {
      var product = this.get("product");
      return (
        product.get("vt_require_zip") ||
        product.get("vt_enable_sales_tax") ||
        product.get("vt_zip") ||
        product.get("payment_method") === "ach" ||
        this.get("isQuickInvoicePayment")
      );
    }
  ),

  termsAgreeRequired: Ember.computed(
    "legal",
    "transaction.terms_agree",
    function () {
      return this.get("legal") && !this.get("transaction.terms_agree");
    }
  ),

  trans_actions_ach: Ember.computed(function () {
    return this.get("applicationData.ach_trans_actions");
  }),

  trans_actions_cc: Ember.computed(function () {
    return this.get("applicationData.cc_trans_actions");
  }),

  trans_actions_list_ach: Ember.computed(
    "product.{id,isSubProcessorPaya}",
    "transaction.ach_sec_code",
    function () {
      var ach_trans_actions = this.get("trans_actions_ach");
      if (this.get("product.isSubProcessorPaya")) {
        var sec_code = this.get("transaction.ach_sec_code");
        var terminal = this.get("product.processor_data.terminals")
          .filter(function (t) {
            return t.sec_code === sec_code;
          })
          .get("firstObject");
        if (terminal) {
          var actions = terminal.actions.map(function (action) {
            return {
              id: action,
              title:
                action === "avsonly"
                  ? "Verification Only"
                  : action.capitalize(),
            };
          });
          return actions.filter(function (a) {
            return a.id !== "refund" && a.id !== "void";
          });
        } else {
          return ach_trans_actions;
        }
      } else {
        return ach_trans_actions;
      }
    }
  ),

  trans_actions_list_cc: Ember.computed(
    "session.authenticated.resources.v2-transactions-post-refund",
    "route","isRefundChanged",
    function () {
      var cc_trans_actions = this.get("trans_actions_cc");
      var canRefund = this.get(
        "session.authenticated.resources.v2-transactions-post-refund"
      );
      var product_transaction = this.get("product");
      if (!product_transaction.enable_blind_refunds) {
        cc_trans_actions = cc_trans_actions.filter(function (item) {
        return item.id !== "refund";
        });
      }
      if (this.get("route") === "runagain") {
        var result = cc_trans_actions.filter(function (item) {
          return (
            item.id === "authonly" ||
            item.id === "sale" ||
            (canRefund && item.id === "refund") ||
            item.id === "avsonly"
          );
        });
        return result;
      } else {
        return cc_trans_actions;
      }
    }
  ),

  multiPayTotals: Ember.computed (
    "isQuickInvoicePayment",
    "parent.target.currentRoute",
    "transaction.payment_method",
    "quickinvoice._selectedQuickInvoices",
    function () {

      const totals = {
        subtotal: 0,
        surcharge: 0,
        overall_total: 0
      };

      // This variable determines whether we are selecting multiple invoices or a single invoice
      const multiPay = this.get('pay_multiple_invoices');
      const selectedInvoices = this.get("quickinvoice._selectedQuickInvoices");

      // Check if multiPay is enabled and there are multiple invoices.
      if (multiPay && selectedInvoices.length) {

        this.get("quickinvoice._selectedQuickInvoices").forEach((invoice) => {
          let currentProduct = null;
          let surcharge_fee = 0;
          let surcharge_rate = 0;

          // Consider only invoices that have not been paid.
          // Earlier paid invoice amounts were also being considered which resulted in incorrect transaction amounts.
          if (!invoice.isPaid) {
            currentProduct = this.getCurrentProduct(invoice);
            surcharge_fee = parseFloat(currentProduct.get('surcharge.surcharge_fee')) || 0;
            surcharge_rate = parseFloat(currentProduct.get('surcharge.surcharge_rate')) || 0;
            this.calculateSurchargeTotals(totals, surcharge_fee, surcharge_rate, invoice);
          }
        });

      } else if (this.get('quickinvoice')) {
        const invoice = this.get('quickinvoice');
        const currentProduct = this.getCurrentProduct(invoice);
        const surcharge_fee = parseFloat(currentProduct.get('surcharge.surcharge_fee')) || 0;
        const surcharge_rate = parseFloat(currentProduct.get('surcharge.surcharge_rate')) || 0;
        this.calculateSurchargeTotals(totals, surcharge_fee, surcharge_rate, this.get('quickinvoice'));
      }

      totals.overall_total = totals.subtotal + totals.surcharge;

      return totals;
    }
  ),

  getCurrentProduct: function(invoice) {
    let currentProduct = null;
    if ( invoice && invoice.cc_product_transaction_id ) {
      currentProduct = this.get('products').filterBy('id', invoice.cc_product_transaction_id).get('firstObject')
    } else if ( invoice && invoice.ach_product_transaction_id ){
      currentProduct = this.get('products').filterBy('id', invoice.ach_product_transaction_id).get('firstObject')
    }
    return currentProduct;
  },


  calculateSurchargeTotals: function(totals, surcharge_fee, surcharge_rate, invoice) {
    // invoice._amount was not being set and was undefined. So check for invoice._amount or invoice.amount.
    const invoiceAmount = invoice._amount || invoice.amount;
    let currentSubtotal = invoiceAmount ? Number.parseFloat(invoiceAmount) : Number.parseFloat(invoice.amount_due);
    totals.subtotal += currentSubtotal;
    let currentSurcharge = 0;
    let surchargeableAmount = 0;
    const transaction = this.get("transaction");
    let taxAmount = transaction.get("tax") || 0;
    if (this.product && this.product.get("isSurchargeAfterTax")) {
      surchargeableAmount = currentSubtotal + parseFloat(taxAmount);
    } else {
      surchargeableAmount = currentSubtotal;
    }
    // Taking the same code as the transaction-calculations file in the services folder.
    currentSurcharge = (surcharge_rate * surchargeableAmount / 100).round(2) + surcharge_fee;

    // The below code calculates surcharge amount and transaction amount incorrectly. Please review carefully before uncommenting.

    // if ( surcharge_fee || surcharge_rate ) {
    //   let currentSurchargeFlatFee = 0, currentSurchargeRateFee = 0;

    //   if ( surcharge_fee ) {
    //     currentSurchargeFlatFee = parseFloat(surcharge_fee);
    //     currentSurcharge += currentSurchargeFlatFee;
    //   }
    //   currentSurchargeRateFee = Number.parseFloat(currentSubtotal) * Number.parseFloat(surcharge_rate);
    //   currentSurcharge += currentSurchargeRateFee;
    // }

    const minSurcharge = parseFloat(this.get("product.surcharge.min_fee_amount"));
    const maxSurcharge = parseFloat(this.get("product.surcharge.max_fee_amount"));
    currentSurcharge = currentSurcharge < minSurcharge ? minSurcharge : currentSurcharge;
    currentSurcharge = currentSurcharge > maxSurcharge ? maxSurcharge : currentSurcharge;

    invoice.set("current_surcharge", currentSurcharge);
    totals.surcharge += currentSurcharge;
  },

  // Observers
  amountsClearProductId: Ember.observer("product.id", function () {
    this.amountsClear("productId");
  }),
  amountsClearTransactionAction: Ember.observer(
    "transaction.action",
    function () {
      this.amountsClear("transactionAction");
    }
  ),
  changedAccountNumber: Ember.observer(
    "transaction.account_number",
    function () {
      var transaction = this.get("transaction");
      if (
        this.get("transaction.payment_method") === "cc" &&
        jQuery("#vt_account_number").val()
      ) {
        jQuery("#vt_account_number").validateCreditCard(function (result) {
          if (result.length_valid === true && result.luhn_valid === true) {
            jQuery("#vt_account_number")
              .parent()
              .parent()
              .addClass("has-success");
            jQuery("#vt_account_number")
              .parent()
              .parent()
              .removeClass("has-error");
          } else {
            jQuery("#vt_account_number")
              .parent()
              .parent()
              .addClass("has-error");
            jQuery("#vt_account_number")
              .parent()
              .parent()
              .removeClass("has-success");
            if (
              !transaction.get("isDestroyed") &&
              !transaction.get("isDestroying")
            ) {
              transaction.set("track_data", null);
            }
          }
        });
      } else {
        jQuery("#vt_account_number").parent().parent().removeClass("has-error");
        jQuery("#vt_account_number")
          .parent()
          .parent()
          .removeClass("has-success");
      }
    }
  ),

  changedAccountVault: Ember.observer(
    "transaction.account_vault_id",
    function () {

      var accv_id = this.get("transaction.account_vault_id");
      var transaction = this.get("transaction");
      var product = this.get("product");
      var location = this.get("location");
      var accounts = this.get("accounts");
      var account = accounts && accounts.get("length") ? accounts.filterBy("id", accv_id).get("firstObject") : null;

      if (accv_id && location && product) {

        var default_ach = location.get("default_ach");
        var default_cc = location.get("default_cc");

        if (account) {

          transaction.set(
            "account_holder_name",
            account.get("account_holder_name") ? account.get("account_holder_name").trim() : ""
          );

          transaction.set(
            "billing_street",
            account.get("billing_address") || null
          );

          transaction.set("billing_city", account.get("billing_city") || null);
          transaction.set("billing_phone", account.get("billing_phone") || null);
          transaction.set("billing_state", account.get("billing_state") || null);
          transaction.set("billing_zip", account.get("billing_zip") || null);
        }

        if (this.get("isRouteContactUser")) {

          if (account) {

            var account_payment_method = account.get("payment_method");

            if (product.get("payment_method") !== account_payment_method) {

              if (account_payment_method === "ach") {

                if (this.get("route") === "contactusermakepaymentquickinvoice") {
                  transaction.set("product_transaction_id", this.get("quickinvoice.ach_product_transaction_id"));
                } else {
                  transaction.set("product_transaction_id", default_ach);
                }

                transaction.set("payment_method", "ach");

              } else if (account_payment_method === "cc") {

                if (this.get("route") === "contactusermakepaymentquickinvoice") {

                  transaction.set("product_transaction_id", this.get("quickinvoice.cc_product_transaction_id"));

                } else {

                  transaction.set("product_transaction_id", default_cc);
                }

                transaction.set("payment_method", "cc");
              }
            }

            transaction.set("billing_street", account.get("billing_address") || null);
            transaction.set("billing_city", account.get("billing_city") || null);
            transaction.set("billing_phone", account.get("billing_phone") || null);
            transaction.set("billing_state", account.get("billing_state") || null);
            transaction.set("billing_zip", account.get("billing_zip") || null);
          }
        }

        this.setupProduct();

      } else {

        transaction.set("account_holder_name", null);
      }

    }
  ),

  changedACHSECCode: Ember.observer(
    "transaction.ach_sec_code",
    "currentAccount_is_company",
    "ignore_ach_sec_code_change",
    "route",
    function () {
      var transaction = this.get("transaction");
      var route = this.get("route");
      if (
        this.get("ignore_ach_sec_code_change") ||
        route.indexOf("virtualterminalcontact") > -1 ||
        route === "runagain" ||
        this.get("transaction.account_vault_id")
      ) {
        return;
      }
      var ach_sec_code = transaction.get("ach_sec_code");
      var payment_method = transaction.get("payment_method");
      var is_company = this.get("currentAccount_is_company") === "1";
      if (!this.get("isRunagain")) {
        if (
          payment_method === "ach" &&
          !this.get("product.isSubProcessorPaya")
        ) {
          if (is_company && ach_sec_code !== "CCD") {
            this.set("transaction.ach_sec_code", "CCD");
          } else if (!is_company && ach_sec_code === "CCD") {
            this.set("transaction.ach_sec_code", "PPD");
          }
        } else if (
          payment_method === "ach" &&
          this.get("product.isSubProcessorPaya")
        ) {
          this.checkACHForPaya();
        } else if (payment_method === "cc") {
          this.set("transaction.ach_sec_code", null);
        }
        if (ach_sec_code === "POP" || ach_sec_code === "C21") {
          this.set("transaction.save_account", false);
        }
        this.setTransactionAction();
      }
    }
  ),

  changedAction: Ember.observer("transaction.isForce", function () {
    if (this.get("transaction.isForce")) {
      this.set("processMethodTerminal", false);
    }
  }),

  changedExtraFlag: Ember.observer(
    "transaction.extra_flag",
    "route",
    function () {
      var one_day = moment.duration(1, "d");
      var two_days = moment.duration(2, "d");

      var transaction = this.get("transaction");
      var today = moment().format("L");
      var yesterday = moment().subtract(one_day).format("L");
      var tomorrow = moment().add(one_day).format("L");
      var twodays = moment().add(two_days).format("L");
      var elseCheckinDateMax = yesterday;
      var elseCheckoutDateMax =
        this.get("route") === "runagain" ? tomorrow : "12/31/2050";
      var elseCheckinDate = yesterday;
      var elseCheckoutDate = today;
      if (transaction.get("extra_flag") === "advance_deposit") {
        this.set("checkinDateMin", tomorrow);
        this.set("checkinDateMax", "12/31/2050");
        this.set("checkoutDateMin", twodays);
        this.set("checkoutDateMax", "12/31/2050");
        transaction.set("checkin_date", tomorrow);
        transaction.set("checkout_date", twodays);
      } else {
        this.set("checkinDateMin", "01/01/1970");
        this.set("checkinDateMax", elseCheckinDateMax);
        this.set("checkoutDateMin", "01/01/1970");
        this.set("checkoutDateMax", elseCheckoutDateMax);
        transaction.set("checkin_date", elseCheckinDate);
        transaction.set("checkout_date", elseCheckoutDate);
      }
    }
  ),

  changedLocation: Ember.observer("transaction.location_id", function () {
    this.setupLocation();
  }),

  changedProduct: Ember.observer(
    "transaction.product_transaction_id",
    "scenario",
    function () {
      // when we switch deposit account it set the surcharge_amount, subtotal_amount, current_surcharge and transaction_amount to zero
      let currentTransaction = this.get("transaction");
      if(currentTransaction) {
        currentTransaction.set("current_surcharge", 0);
        currentTransaction.set("surcharge_amount", 0);
        currentTransaction.set("transaction_amount", "");
        currentTransaction.set("subtotal_amount","");
      }
      this.setupProduct();
    }
  ),

  changedScenario: Ember.observer(
    "scenario",
    "transaction.{product_transaction_id,payment_method}",
    function () {

      var self = this;
      var session = this.get("session");
      var transaction = this.get("transaction");
      let location = null;

      if (this.get('pay_multiple_invoices')) {
        location = this.get("locations");
      } else {
        location = this.get("location");
      }

      if (!transaction) {
        return;
      }

      var product_transactions = this.get("products");

      if (product_transactions.get("length") === 0) {
        return;
      }

      var hasCC = false;
      var hasACH = false;

      product_transactions.forEach(function (prod) {

        if (prod.get("payment_method") === "cc") {
          hasCC = true;
        }

        if (prod.get("payment_method") === "ach") {
          hasACH = true;
        }
      });

      var default_ach = location.get("default_ach");
      var default_cc = location.get("default_cc");

      var cc_products = product_transactions.filter(function (prod) {
        return prod.get("payment_method") === "cc" && prod.get("active");
      });

      var ach_products = product_transactions.filter(function (prod) {
        return prod.get("payment_method") === "ach" && prod.get("active");
      });

      var ach_accounts = this.get("accountsACH");
      var cc_accounts = this.get("accountsCC");
      var ach_accounts_length = ach_accounts.get("length");
      var cc_accounts_length = cc_accounts.get("length");

      this.set("isNewACH", false);
      this.set("isNewCC", false);
      this.set("isExistingCC", false);
      this.set("isExistingACH", false);
      this.set("transaction.account_number", null);
      this.set("transaction.exp_date", null);
      this.set("transaction.save_account", false);

      var disabled_account_vault_id = this.get("disabled_account_vault_id");
      var account = this.get("account");

      var scenario = this.get("scenario");
      switch (scenario) {
        case "stored_account":
          if (
            (transaction.get("payment_method") === "cc" &&
              hasCC &&
              cc_accounts_length) ||
            (disabled_account_vault_id &&
              account &&
              account.get("payment_method") === "cc")
          ) {
            setStoredCCSettings(self);
          } else if (
            (transaction.get("payment_method") === "ach" &&
              hasACH &&
              ach_accounts_length) ||
            (disabled_account_vault_id &&
              account &&
              account.get("payment_method") === "ach")
          ) {
            setStoredACHSettings(self);
          } else if (
            transaction.get("payment_method") === "cc" &&
            !cc_accounts_length
          ) {
            if (self.get("route") === "contactusermakepaymentquickinvoice") {
              transaction.set(
                "product_transaction_id",
                self.get("quickinvoice.ach_product_transaction_id")
              );
            } else {
              transaction.set(
                "product_transaction_id",
                default_ach
                  ? default_ach
                  : ach_products.get("firstObject").get("id")
              );
            }
            setStoredACHSettings(self);
          } else if (
            transaction.get("payment_method") === "ach" &&
            !ach_accounts_length
          ) {
            if (self.get("route") === "contactusermakepaymentquickinvoice") {
              transaction.set(
                "product_transaction_id",
                self.get("quickinvoice.cc_product_transaction_id")
              );
            } else {
              transaction.set(
                "product_transaction_id",
                default_cc
                  ? default_cc
                  : cc_products.get("firstObject").get("id")
              );
            }
            setStoredCCSettings(self);
          }
          break;
        case "new_account":
          transaction.set("account_vault_id", null);
          if (transaction.get("payment_method") === "cc") {
            if (hasCC) {
              this.set("isCC", true);
              this.set("isACH", false);
              this.set("isNewACH", false);
              this.set("isNewCC", true);
              this.set("isExistingCC", false);
              this.set("isExistingACH", false);
              transaction.set("payment_method", "cc");
              transaction.set("ach_sec_code", null);

              var locationTerminals = self.get("terminals");
              if (
                this.get("showProcessMethod") &&
                locationTerminals &&
                locationTerminals.get("length") > 0 &&
                !transaction.get("isForce")
              ) {
                if (
                  session.get("authenticated.ui_prefs.process_method") ===
                  "physical_terminal"
                ) {
                  self.set("processMethodTerminal", true);
                  var default_terminal = locationTerminals.filter(function (t) {
                    return (
                      t.get("id") ===
                      session.get("authenticated.ui_prefs.default_terminal")
                    );
                  });
                  if (default_terminal.get("length") === 1) {
                    self.set(
                      "transaction.terminal_id",
                      default_terminal.get("firstObject").get("id")
                    );
                  } else {
                    self.set(
                      "transaction.terminal_id",
                      locationTerminals.get("firstObject").get("id")
                    );
                  }
                } else {
                  self.set("processMethodTerminal", false);
                  self.set(
                    "transaction.terminal_id",
                    locationTerminals.get("firstObject").get("id")
                  );
                }
              } else {
                self.set("processMethodTerminal", false);
                self.set("transaction.terminal_id", null);
              }
              if (self.get("route") === "contactusermakepayment") {
                transaction.set("product_transaction_id", default_cc);
              } else if (
                self.get("route") === "contactusermakepaymentquickinvoice"
              ) {
                transaction.set(
                  "product_transaction_id",
                  self.get("quickinvoice.cc_product_transaction_id")
                );
              }
              transaction.set("account_vault_id", null);
            }
            this.send("changePaymentMethod", "cc");
          }
          if (transaction.get("payment_method") === "ach") {
            if (hasACH) {
              if (!this.get("isRunagain")) {
                if (this.get("product.isSubProcessorPaya")) {
                  this.checkACHForPaya();
                } else {
                  transaction.set("ach_sec_code", "PPD");
                }
              }
              this.set("isCC", false);
              this.set("isACH", true);
              this.set("isNewACH", true);
              this.set("isNewCC", false);
              this.set("isExistingCC", false);
              this.set("isExistingACH", false);
              transaction.set("payment_method", "ach");
              this.set("processMethodTerminal", false);
              this.set("transaction.terminal_id", null);
              if (self.get("route") === "contactusermakepayment") {
                transaction.set("product_transaction_id", default_ach);
              } else if (
                self.get("route") === "contactusermakepaymentquickinvoice"
              ) {
                transaction.set(
                  "product_transaction_id",
                  self.get("quickinvoice.ach_product_transaction_id")
                );
              }
            }
            this.send("changePaymentMethod", "ach");
          }

          break;
      }

      if (this.get("isRouteContactUser") && this.get("legal")) {
        this.set("transaction.terms_agree", false);
      } else {
        this.set("transaction.terms_agree", true);
      }

      function setStoredCCSettings(context) {
        context.set("isCC", true);
        context.set("isACH", false);
        context.set("isNewACH", false);
        context.set("isNewCC", false);
        context.set("isExistingCC", true);
        context.set("isExistingACH", false);
        context.set("processMethodTerminal", false);
        transaction.set("payment_method", "cc");
        transaction.set("ach_sec_code", null);
        transaction.set("terminal_id", null);
        transaction.set("routing", null);
      }

      function setStoredACHSettings(context) {
        context.set("isCC", false);
        context.set("isACH", true);
        context.set("isNewACH", false);
        context.set("isNewCC", false);
        context.set("isExistingCC", false);
        context.set("isExistingACH", true);
        context.set("processMethodTerminal", false);
        transaction.set("payment_method", "ach");
        transaction.set("ach_sec_code", null);
        transaction.set("terminal_id", null);
        transaction.set("routing", null);
      }

      var product = this.get("product");
      if (!product) {
        return;
      }
      var default_transaction_type = product.get("default_transaction_type");
      if (!default_transaction_type) {
        if (transaction.get("payment_method") === "cc") {
          default_transaction_type = "sale";
        } else {
          default_transaction_type = "debit";
        }
      }

      if (scenario === "stored_account") {
        if (
          this.get("account") &&
          this.get("account.payment_method") ===
            this.get("product.payment_method")
        ) {
          this.set("transaction.account_vault_id", this.get("account.id"));
        } else {
          if (transaction.get("payment_method") === "cc") {
            if (cc_accounts.get("length") >= 1) {
              var cc_account = cc_accounts.get("firstObject");
              this.set("transaction.account_vault_id", cc_account.get("id"));
            }
          } else {
            if (ach_accounts.get("length") >= 1) {
              var ach_account = ach_accounts.get("firstObject");
              this.set("transaction.account_vault_id", ach_account.get("id"));
            }
          }
        }
      } else {
        this.set("transaction.account_vault_id", null);
      }
      this.setTransactionAction();
    }
  ),

  changedSubtotal: Ember.observer(
    "transaction.subtotal_amount",
    "isQuickInvoicePayment",
    async function () {

      if (!this.get("isQuickInvoicePayment")) {

        if (this.get("product.vt_enable_sales_tax")) {
          this.calculateTax("subtotal");

        } else {
          var accountVault = this.get('accounts.modelName') == 'accountvault';
          var first_six = '', id = this.get('av_Id') || '';
          if (accountVault) {
            // this.transactionCalculations.firstSixChar = this.parent.account.first_six.length > 0 ? this.parent.account.first_six : '';
            this.isAccountVaultedCard = this.isAccountVault;
              if (id.length > 0) {
                first_six = await this.getAV(id);
              }
      
          } else if (this.isAccountVault) {
            this.transactionCalculations.firstSixChar = this.parent.account?.first_six?.length > 0 ? this.parent.account.first_six : '';
            this.isAccountVaultedCard = this.isAccountVault
          }
          else {
            this.isAccountVaultedCard = false;
          }

            this.isDebitCard = false;

            var accNum = this.get("transaction.account_number");
            if(this.isDebitSurchargeInfo) {
              this.debitFilter(accNum, this)
            }

            first_six.length>0?
              this.debitFilter(first_six, this)
              : this.calculateTax();
        }

      } else if (this.get("isQuickInvoicePayment")) {
          this.calculateTotal();
      }
    }
  ),

  changedTax: Ember.observer(
    "transaction.tax",
    "isQuickInvoicePayment",
    function () {

      if (!this.get("isQuickInvoicePayment")) {
        this.calculateTotal();
      }
    }
  ),

  changedTip: Ember.observer(
    "transaction.tip_amount",
    "isQuickInvoicePayment",
    function () {
      if (!this.get("isQuickInvoicePayment")) {
        this.calculateTotal();
      }
    }
  ),

  changedZip: Ember.observer(
    "transaction.billing_zip",
    "isQuickInvoicePayment",
    function () {
      const isTaxCalculationEnabled =
        this.get("product.vt_enable_sales_tax") &&
        this.get("product.vt_automatically_calculate_tax");

      if (isTaxCalculationEnabled && !this.get("isQuickInvoicePayment")) {
        this.calculateTax("zip");
      }
    }
  ),

  // Functions

  /////     Calculations     /////
  calculateSurcharge: function () {
    if (!this.get("isQuickInvoicePayment")) {
      return this.get("transactionCalculations").calculateSurcharge(
        this.get("transaction"),
        this.get("product")
      );
    }
  },

  calculateTax: function (cause) {
    var sales_tax = this.get("sales_tax");
    this.get("transactionCalculations").calculateTax(
      this.get("transaction"),
      this.get("product"),
      sales_tax,
      cause,
      this,
      this.isDebitCard,
    );
  },

  calculateTotal: function () {
 
    this.get("transactionCalculations").calculateTotal(
        this.get("transaction"),
        this.get("product"),
        this.get("isLoading"),
        this.isDebitCard,
        this.isAccountVaultedCard
      );
  },
  /////     END     /////

  amountsClear: function (cause) {
    var transaction = this.get("transaction");
    if (!transaction) {
      return;
    }
    var currentTransactionAmount = transaction.get("transaction_amount");
    var currentTransactionSubTotalAmount = transaction.get("subtotal_amount");
    var newProductShowSubTotal = this.checkForSubtotal(this.get("product"));
    var previousProductShowSubTotal = this.get("previousProductShowSubTotal");
    transaction.set("surcharge_amount", "");
    transaction.set("tax", "");
    transaction.set("tip_amount", "");
    transaction.set("subtotal_amount", "");
    transaction.set("transaction_amount", "");
    this.amountsKeep(
      transaction,
      currentTransactionSubTotalAmount,
      currentTransactionAmount,
      previousProductShowSubTotal,
      newProductShowSubTotal,
      cause
    );
  },
  amountsKeep: function (
    trans,
    subtotal,
    amount,
    previousShowSubTotal,
    newShowSubTotal,
    cause
  ) {
    if (previousShowSubTotal && newShowSubTotal) {
      trans.set("subtotal_amount", subtotal);
    } else if (!previousShowSubTotal && newShowSubTotal) {
      if (cause === "transactionAction") {
        trans.set("subtotal_amount", subtotal);
      } else {
        trans.set("subtotal_amount", amount);
      }
    } else if (!previousShowSubTotal && !newShowSubTotal) {
      trans.set("transaction_amount", amount);
    } else if (previousShowSubTotal && !newShowSubTotal) {
      if (cause === "transactionAction") {
        trans.set("transaction_amount", amount);
      } else {
        trans.set("transaction_amount", subtotal);
      }
    }
    if (
      this.get("isQuickInvoicePayment") &&
      !this.get("quickinvoice.allow_partial_pay")
    ) {
      this.set(
        "transaction.transaction_amount",
        this.get("quickinvoice.amount_due")
      );
    }
  },
  checkACHForPaya: function (account) {
    var ach_sec_code = this.get("transaction.ach_sec_code");
    var achSecCodeContents = this.get("achSecCodeContents");
    var seccodeAvailable = false;
    if (this.get("isQuickInvoicePayment")) {
      this.set("currentAccount_is_company", "0");
      this.set("transaction.ach_sec_code", "WEB");
    } else {
      if (account) {
        ach_sec_code = account.get("ach_sec_code");
      }
      achSecCodeContents.map(function (code) {
        if (ach_sec_code === code.id) {
          seccodeAvailable = true;
        }
      });
      var codes = this.get("product.processor_data.terminals").map(function (
        t
      ) {
        return t.sec_code;
      });
      if (!seccodeAvailable) {
        var tempSec = achSecCodeContents.get("firstObject");
        if (tempSec) {
          this.set("transaction.ach_sec_code", tempSec.id);
        } else {
          this.set("transaction.ach_sec_code", codes[0]);
        }
      } else {
        this.set("transaction.ach_sec_code", ach_sec_code);
      }
      if (codes.indexOf("CCD") === -1) {
        this.set("currentAccount_is_company", "0");
      } else if (codes.length === 1 && codes.indexOf("CCD") !== -1) {
        this.set("currentAccount_is_company", "1");
      }
    }
  },
  checkForSubtotal: function (product) {
    if (product) {
      var showTax = product.get("vt_enable_sales_tax") ? true : false;
      var showTip = product.get("hasTip") ? true : false;
      var showSurcharge;
      if (product.get("surcharge.id")) {
        if (this.get("transaction.action") === "refund") {
          if (product.get("surcharge.refund_surcharges")) {
            showSurcharge = true;
          }
        } else {
          showSurcharge = true;
        }
      } else {
        showSurcharge = false;
      }
      return showTax || showTip || showSurcharge;
    } else {
      return false;
    }
  },

  checkPayaTerminalFor: function (terminals, sec_code, field) {
    var payaRequires = false;
    if (terminals) {
      terminals.map(function (terminal) {
        if (terminal.sec_code === sec_code) {
          payaRequires = terminal.required.indexOf(field) !== -1;
        }
      });
    }
    return payaRequires;
  },

  destroyCardReader: function () {
    var reader = this.get("cardReader");
    reader.remove(jQuery(document));
  },

  setIsLoading: function (bool) {
    this.set("isLoading", bool);
    this.get("parent").set("isLoading", bool);
  },

  setTransactionAction: function () {
    var trans_type =
      this.get("product.default_transaction_type") ||
      this.get("transaction.action");
    var trans_type_values =
      this.get("transaction.payment_method") === "cc"
        ? this.get("trans_actions_list_cc")
        : this.get("trans_actions_list_ach");
    var actionIsAvailable = false;
    if (trans_type_values && trans_type_values.get("length")) {
      trans_type_values.map(function (val) {
        if (val.id === trans_type) {
          actionIsAvailable = true;
        }
      });
      if (!actionIsAvailable) {
        this.set("transaction.action", trans_type_values.get("firstObject.id"));
      } else {
        this.set("transaction.action", trans_type);
      }
    }
  },

  setupCardReader: function () {
    var self = this;
    var reader = new CardReader("\u00e9", ["%"], ["?"], 100);
    reader.observe(jQuery(document));
    reader.cardError(function () {
      noty({
        text: "A read error occurred!",
        type: "error",
      });
    });
    reader.cardRead(function (value) {
      var transaction = self.get("transaction");

      // Get track data and assign it to form vars
      var parsed_data = parseTrackData(value);
      var product = self.get("product");
      if (product.get("industry_type") === "retail") {
        transaction.set("track_data", parsed_data.track_data);
      }
      transaction.set("account_number", parsed_data.card_number);
      transaction.set("exp_month", parsed_data.exp_month);
      transaction.set("exp_year", parsed_data.exp_year);
      transaction.set("account_holder_name", parsed_data.cardholder);
    });
    self.set("cardReader", reader);
  },

  setupLocation: function () {
    var session = this.get("session");
    var transaction = this.get("transaction");
    var location_id = transaction.get("location_id");
    var location = this.get("location");
    var quickinvoice = this.get("quickinvoice");

    if (location_id) {
      // tags changes
      var tagList = this.get("tagList");
      if (tagList) {
        tagList.forEach(function (tag) {
          transaction.get("tags").removeObject(tag);
        });
      }
      this.set("tagList", location.get("tags").sortBy("title"));
      this.set("selectedTags", "");

      // products changes
      var products = this.get("products");
      products = products.filter(function (prod) {
        return prod.get("active");
      });
      var productsACH = this.get("productsACH");
      var productsCC = this.get("productsCC");
      var default_ach = location.get("default_ach");
      var default_cc = location.get("default_cc");
      var hasProductCC = location.get("hasProductCC");
      var hasProductACH = location.get("hasProductACH");

      var product_transaction_id = "";

      if (this.get("route") === "contactusermakepaymentquickinvoice") {
        if (
          quickinvoice.get("cc_product_transaction_id") &&
          quickinvoice.get("cc_product_transaction.quick_invoice_allow")
        ) {
          product_transaction_id = quickinvoice.get(
            "cc_product_transaction_id"
          );
        } else if (
          quickinvoice.get("ach_product_transaction_id") &&
          quickinvoice.get("ach_product_transaction.quick_invoice_allow")
        ) {
          product_transaction_id = quickinvoice.get(
            "ach_product_transaction_id"
          );
        } else if (products && products.get("length")) {
          product_transaction_id = products.get("firstObject").get("id");
        } else {
          product_transaction_id = null;
        }
      } else {
        if (
          (!hasProductCC && hasProductACH && productsACH.get("length")) ||
          this.get("account.payment_method") === "ach"
        ) {
          // Check to make sure its not ach only
          product_transaction_id = hasProductACH
            ? default_ach
              ? default_ach
              : productsACH.get("firstObject").get("id")
            : null;
        } else if (hasProductCC && productsCC.get("length")) {
          product_transaction_id = default_cc
            ? default_cc
            : productsCC.get("firstObject").get("id");
        } else {
          if (products && products.get("length")) {
            product_transaction_id = products.get("firstObject").get("id");
          } else {
            product_transaction_id = null;
          }
        }
      }

      transaction.set("product_transaction_id", product_transaction_id);

      // terminals changes
      var terminals = this.get("terminals");
      var locationTerminals = this.get("locationTerminals");
      var default_terminal_process_method = session.get(
        "authenticated.ui_prefs.process_method"
      );
      var default_terminal_id = session.get(
        "authenticated.ui_prefs.default_terminal"
      );

      if (
        this.get("showProcessMethod") &&
        terminals &&
        terminals.get("length") &&
        locationTerminals.get("length") &&
        !transaction.get("isForce")
      ) {
        if (default_terminal_process_method === "physical_terminal") {
          var defaultTerminalAtThisLocation = false;
          locationTerminals.map(function (terminal) {
            if (terminal.get("id") === default_terminal_id) {
              defaultTerminalAtThisLocation = true;
            }
          });
          this.set("processMethodTerminal", true);
          if (defaultTerminalAtThisLocation) {
            transaction.set("terminal_id", default_terminal_id);
          } else {
            transaction.set(
              "terminal_id",
              locationTerminals.get("firstObject").get("id")
            );
          }
        } else {
          this.set("processMethodTerminal", false);
          transaction.set(
            "terminal_id",
            locationTerminals.get("firstObject").get("id")
          );
        }
      } else {
        this.set("processMethodTerminal", false);
        this.set("transaction.terminal_id", null);
      }
    }
  },

  setupProduct: function () {
    var transaction = this.get("transaction");
    var account = this.get("account");
    var previousProductShowSubTotal = this.checkForSubtotal(
      this.get("product")
    );
    this.set("previousProductShowSubTotal", previousProductShowSubTotal);
    var product = this.get("product");
    if (Ember.isEmpty(transaction) || Ember.isEmpty(product)) {
      return;
    }
    if (product.get("isLodging") && !this.get("transaction.extra_flag")) {
      this.set("transaction.extra_flag", "");
    }
    var contact = this.get("contact");
    if (product.get("payment_method") === "cc") {
      transaction.set("routing", "");
      transaction.set("account_type", null);
      transaction.set("payment_method", "cc");
      transaction.set("ach_sec_code", null);
    } else if (product.get("payment_method") === "ach") {
      transaction.set("exp_month", "");
      transaction.set("exp_year", "");
      transaction.set("cvv", "");
      transaction.set("payment_method", "ach");

      this.set(
        "currentAccount_is_company",
        account && account.get("ach_sec_code") === "CCD" ? "1" : "0"
      );
      if (this.get("product.isSubProcessorPaya")) {
        this.checkACHForPaya(account);
      } else if (
        account &&
        account.get("payment_method") === product.get("payment_method")
      ) {
        transaction.set("ach_sec_code", account.get("ach_sec_code") || "WEB");
      } else {
        transaction.set("ach_sec_code", "PPD");
      }
      transaction.set("account_type", "checking");
      this.set("processMethodTerminal", false);
    }
    if (
      contact &&
      !account &&
      this.get("scenario") === "new_account" &&
      this.get("route") !== "runagain"
    ) {
      transaction.set(
        "account_holder_name",
        transaction.get("account_holder_name")
          ? transaction.get("account_holder_name")
          : contact.get("full_name")
          ? contact.get("full_name").trim()
          : ""
      );
      transaction.set(
        "billing_street",
        transaction.get("billing_street")
          ? transaction.get("billing_street")
          : contact.get("address")
      );
      transaction.set(
        "billing_city",
        transaction.get("billing_city")
          ? transaction.get("billing_city")
          : contact.get("city")
      );
      transaction.set(
        "billing_state",
        transaction.get("billing_state")
          ? transaction.get("billing_state")
          : contact.get("state")
      );
      transaction.set(
        "billing_zip",
        transaction.get("billing_zip")
          ? transaction.get("billing_zip")
          : contact.get("zip")
      );
      transaction.set(
        "billing_phone",
        transaction.get("billing_phone")
          ? transaction.get("billing_phone")
          : contact.get("phone") || contact.get("office_phone")
      );
    } else if (
      account &&
      account.get("payment_method") === product.get("payment_method")
    ) {
      transaction.set(
        "account_holder_name",
        account.get("account_holder_name")
          ? account.get("account_holder_name").trim()
          : ""
      );
      transaction.set("billing_street", account.get("billing_address"));
      transaction.set("billing_city", account.get("billing_city"));
      transaction.set("billing_state", account.get("billing_state"));
      transaction.set("billing_zip", account.get("billing_zip"));
      transaction.set("billing_phone", account.get("billing_phone"));
    }
    this.setTransactionAction();
    this.set("transactionErrors", []);
  },

  setupScenario: function () {

    var location = this.get("location");
    var hasProductCC = location.get("hasProductCC");
    var hasProductACH = location.get("hasProductACH");
    var accountsACH = this.get("accountsACH");
    var accountsCC = this.get("accountsCC");
    var accountsACHLength = accountsACH.get("length");
    var accountsCCLength = accountsCC.get("length");
    var productsACH = this.get("productsACH");
    var productsCC = this.get("productsCC");
    var productsACHLength = productsACH.get("length");
    var productsCCLength = productsCC.get("length");
    var isAccount = this.get("account.id");
    var scenario = "";
    if (this.get("isQuickInvoicePayment")) {
      if (
        (productsCC && productsCCLength && accountsCC && accountsCCLength) ||
        (productsACH && productsACHLength && accountsACH && accountsACHLength)
      ) {
        scenario = "stored_account";
      } else {
        scenario = "new_account";
      }
    } else {
      if (
        (hasProductCC && accountsCCLength > 0) ||
        (hasProductACH && accountsACHLength > 0) ||
        isAccount
      ) {
        scenario = "stored_account";
      } else {
        scenario = "new_account";
      }
    }

    if (
      hasProductCC &&
      accountsCC &&
      accountsCCLength &&
      productsCC &&
      productsCCLength &&
      hasProductACH &&
      accountsACH &&
      accountsACHLength &&
      productsACH &&
      productsACHLength
    ) {
      // if cc & ach products && cc & ach accounts
      setStoredAccountScenarios(this, true, false, false, false, false);
    } else if (
      hasProductCC &&
      !accountsCCLength &&
      productsCC &&
      productsCCLength &&
      hasProductACH &&
      accountsACH &&
      accountsACHLength &&
      productsACH &&
      productsACHLength
    ) {
      // if cc & ach products && with ach accounts and NO cc accounts
      setStoredAccountScenarios(this, false, true, false, false, false);
    } else if (
      hasProductCC &&
      accountsCC &&
      accountsCCLength &&
      productsCC &&
      productsCCLength &&
      hasProductACH &&
      !accountsACHLength &&
      productsACH &&
      productsACHLength
    ) {
      // if cc & ach products && with cc accounts and NO ach accounts
      setStoredAccountScenarios(this, false, false, true, false, false);
    } else if (
      hasProductCC &&
      accountsCC &&
      accountsCCLength &&
      productsCC &&
      productsCCLength &&
      !hasProductACH
    ) {
      // if only cc products && with cc acounts
      setStoredAccountScenarios(this, false, false, false, true, false);
    } else if (
      hasProductACH &&
      accountsACH &&
      accountsACHLength &&
      productsACH &&
      productsACHLength &&
      !hasProductCC
    ) {
      // if only ach products and with ach accounts
      setStoredAccountScenarios(this, false, false, false, false, true);
    }
    this.set("scenario", scenario);

    function setStoredAccountScenarios(context, one, two, three, four, five) {
      context.set("is_CC_and_ACH_Products_and_CC_and_ACH_Accounts", one);
      context.set("is_CC_and_ACH_Products_with_ACH_and_no_CC_Accounts", two);
      context.set("is_CC_and_ACH_Products_with_CC_and_no_ACH_Accounts", three);
      context.set("is_only_CC_Products_with_CC_Accounts", four);
      context.set("is_only_ACH_Products_with_ACH_Accounts", five);
    }
  },

  ssoTagsAdd: function () {
    var transaction = this.get("transaction");
    var tagList = this.get("tagList");
    var selectedTags = this.get("selectedTags");
    if (!tagList || !selectedTags) {
      return;
    }
    selectedTags = selectedTags.split(",");

    tagList.forEach(function (tag) {
      if (selectedTags.indexOf(tag.get("title")) > -1) {
        transaction.get("tags").pushObject(tag);
      }
    });
  },

  ssoParamsUpdate: function () {
    var params = this.get("sso_params");

    function getCsvTags(element) {
      tag_csv += element.title + ",";
    }
    if (params) {
      if (
        (this.get("accountsCC") && this.get("accountsCC.length") > 0) ||
        (this.get("accountsACH") && this.get("accountsACH.length") > 0)
      ) {
        this.set("scenario", "stored_account");
      }

      var transaction = this.get("transaction");
      if (transaction) {
        var product = this.get("product");
        for (var k in params) {
          if (k === "tags") {
            var tags = params["tags"];
            var tag_csv = "";
            tags.forEach(getCsvTags);
            this.set("selectedTags", tag_csv.replace(/\,$/, ""));
          } else if (k === "transaction_amount") {
            if (this.checkForSubtotal(product)) {
              transaction.set(
                "subtotal_amount",
                String(params[k]).currency(2).replace(",", "")
              );
            } else {
              transaction.set(
                k,
                String(params[k]).currency(2).replace(",", "")
              );
            }
          } else {
            transaction.set(k, params[k]);
          }
        }
      }
    }
  },

  getAV : function(id){
    return new Promise((res,rej)=>{
      this.get('store')
      .findRecord("accountvault", id.toString())
      .then(function (av) {
        res(av.first_six);
      });
    })
  },

  formatAmount:function(amount){
    var adjustedAmount = amount.replace(/^[, ]+|[, ]+$|[, ]+/g, "").trim();
    return adjustedAmount.length?adjustedAmount:"0";
  },

  debitFilter: function(accountNumber, self){
    this.isAccountVaultedCard = false;
        this.get("store").findRecord("bin", accountNumber).then(function (bin) {
          self.set('isCardValidated', false);
          var cardType = bin ? bin.get('card_type'):'';
          self.set('card_type', cardType);

          if (bin && bin.get('card_type') === 'DEBIT') {
            self.isDebitCard = true;
            self.set("errorWarningMessage", true);

            if (self.showSurcharge) {
              self.calculateTax();
            }

            self.transactionCalculations.isConvenienceFee ? self.set("isDebitSurchargeInfo", false) : self.set("isDebitSurchargeInfo", true);

          } else {

            self.isDebitCard = false;
            self.calculateTax();
            self.set("errorWarningMessage", false);
            self.set("isDebitSurchargeInfo", false);
          }

        }).catch(function (error) {
          self.set("error", error);
        });
  },
  
  // Actions
  actions: {
    // addImage: function(fileArray, prop) {
    //   var self = this;
    //   var reader = new FileReader();
    //   reader.onload = function(readerEvt) {
    //     var binaryString = readerEvt.target.result;
    //     self.set('transaction.' + prop, btoa(binaryString));
    //   };
    //   reader.readAsBinaryString(fileArray);
    // },

    changePersonalIdentity: function (method) {

      if (method === "dob") {
        this.set("personalIdentityDOBYear", true);
        this.set("transaction.ssn4", null);
      } else {
        this.set("personalIdentityDOBYear", false);
        this.set("transaction.dob_year", null);
      }
    },

    changePaymentMethod: function (method, type) {

      var product_refund = this.get('product');
      product_refund.enable_blind_refunds ? this.set('isRefundChanged', true) : this.set('isRefundChanged', false);

      if (method === "cc") {
        this.set("paymentMethodCreditCard", true);
        this.set("transaction.payment_method", "cc");
        if (type === "click") {
          if (this.get("route") === "contactusermakepaymentquickinvoice") {
            this.set(
              "transaction.product_transaction_id",
              this.get("quickinvoice.cc_product_transaction_id")
            );
            if ( this.get("parent.target.currentRoute.name") === "contactuser.quickinvoices-index") {
              this.setMultiPayAmounts();
            } else {
              if ( this.get('transaction.surcharge_amount') && this.get('transaction.surcharge_amount') > 0 ) {
                this.calculateTotal();
              }
            }
          } else {
            this.set(
              "transaction.product_transaction_id",
              this.get("location.default_cc")
            );
          }
        }
      } else {
        this.set("paymentMethodCreditCard", false);
        this.set("transaction.payment_method", "ach");
        if (type === "click") {
          if (this.get("route") === "contactusermakepaymentquickinvoice") {
            this.set(
              "transaction.product_transaction_id",
              this.get("quickinvoice.ach_product_transaction_id")
            );
            if ( this.get("parent.target.currentRoute.name") === "contactuser.quickinvoices-index") {
              this.setMultiPayAmounts();
            } else {
              if ( this.get('transaction.surcharge_amount') && this.get('transaction.surcharge_amount') > 0 ) {
                this.calculateTotal();
              }
            }
          } else {
            this.set(
              "transaction.product_transaction_id",
              this.get("location.default_ach")
            );
          }
        }
      }
    },

    changeProcessMethod: function (method) {
      this.set("errorWarningMessage", false);
      if (method === "terminal") {
        this.set("processMethodTerminal", true);
      } else {
        this.set("processMethodTerminal", false);
      }
      this.notifyPropertyChange("transaction.isForce");
    },

    downloadFile: function (file) {
      if (this.get("isDownloading")) {
        return;
      }
      this.set("isDownloading", true);
      if (this.get("location.product_file.id")) {
        location.href = file.link;
      } else {
        noty({
          text: "Accessing attachments is not enabled for this invoice.<br>Please contact the sender of this invoice for assistance.",
          type: "error",
          timeout: 5000,
        });
      }
      this.set("isDownloading", false);
    },

    selectCCAccountVault: function (index) {
      var av_data = this.get('accountsCC');
      var accountVault = av_data[index].get('id') || '';
      var self = this;
      const available_accounts = this.get("accountsCC") || [];
      const account_vault = available_accounts[index];
      const account_vault_id = account_vault ? account_vault.id : null;

      this.set("transaction.account_vault_id", account_vault_id);

      if (account_vault_id === null) {
        this.set("transaction.account_vault", null);
      }

      if(accountVault.length>0){
        this.get('store').findRecord("accountvault",accountVault).then(function(av){
          self.debitFilter(av.first_six, self);
        })
      }
    },

    selectACHAccountVault: function (index) {
      const available_accounts = this.get("accountsACH") || [];
      const account_vault = available_accounts[index];
      const account_vault_id = account_vault ? account_vault.id : null;
      this.set("transaction.account_vault_id", account_vault_id);
      if (account_vault_id === null) {
        this.set("transaction.account_vault", null);
      }
    },

    save: function () {
      var parent = this.get("parent");
      // Bail if we are already loading
      if (this.get("isLoading") || this.get("calculatingTax")) {
        return;
      }

      jQuery(".dropdown-select.open .dropdown-toggle").dropdown("toggle"); // close any open dropdowns before proceeding. Ex: selecting/deselecting tags - changes only apply to model on dropdown close
      this.setIsLoading(true);

      var self = this;
      self.set("transactionErrors", []);
      var transactionErrors = self.get("transactionErrors");
      var transaction = this.get("transaction");
      var location = this.get("location");
      var product = this.get("product");
      var contact = this.get("contact");
      var processMethodTerminal = this.get("processMethodTerminal");
      var scenario = this.get("scenario");
      var surcharge = this.get('transaction.surcharge') ? this.get('transaction.surcharge') : 'none';
      const invoice = this.get("quickinvoice") || {};
      const { tip_amount } = transaction;
      const tipAmount = !Number.isNaN(parseFloat(tip_amount)) ? parseFloat(tip_amount) : 0.00;
      const salesTax = !Number.isNaN(parseFloat(transaction.tax)) ? parseFloat(transaction.tax) : 0.00;

      let subtotal_amount = transaction.subtotal_amount ? this.formatAmount(transaction.subtotal_amount) : transaction.subtotal_amount;
      let transactionAmount = this.formatAmount(transaction.transaction_amount);
      // Here checking if subtotal amount is less than zero and surcharge amount is greater than zero then display error message.

      if(transaction.action !=="avsonly" && surcharge != 'none' && subtotal_amount && parseFloat(subtotal_amount) <= 0) {
          transactionErrors.push("Subtotal amount cannot be empty or 0.");
          self.setIsLoading(false);
          return;
      }

      // Here checking if transaction amount is not present and invoice amount is not present then display error message.
      if(transaction.action !=="avsonly" && !invoice.amount && (!transactionAmount || parseFloat(transactionAmount) <= 0)) {
        transactionErrors.push("Transaction amount cannot be empty or 0.");
        self.setIsLoading(false);
        return;
      }

      if(transaction.action == 'authonly')sessionStorage.setItem("cardType",this.get('card_type'));
      // When paying a single invoice amountusing the Pay Now button from the invoice details page amount is set in transaction object.
      // When paying invoice amounts from the invoice list page amount is set in the invoice object.
      // When paying via account vaults the amount is directly set to transaction.transaction_amount
      const amountDue = parseFloat(subtotal_amount) || parseFloat(transactionAmount) || parseFloat(invoice.amount);
      // When paying a single invoice amount using the Pay Now button from the invoice details page surcharge is set in transaction object.
      // When paying invoice amounts from the invoice list page surcharge is set in the invoice object.
      let currentSurcharge = transaction.current_surcharge || invoice.current_surcharge || 0;
      // For a single quick invoice payment, the subtotal amount equals to the amount due
      transaction.set("subtotal_amount", amountDue.toFixed(2));

      //Setting surcharge_amount as this is not set
      transaction.set("surcharge_amount",currentSurcharge.toFixed(2));

      // Setting transaction amount here since this value is not set.
      // When admin process the transaction using virtual terminal with salesTax
      // Setting transaction amount if sales tax exists
      transaction.set("transaction_amount", (amountDue + currentSurcharge + salesTax).toFixed(2));
      // remove all ASCII character from billing_street field
      if(transaction.get("billing_street")) {
        const value = transaction.get("billing_street").replace(/(?:\\[rn]|[\r\n]+)+/g, "");
        transaction.set("billing_street", value);
      }
      // Setting sales tax if it is exist and is greater than zero.
      if(typeof(salesTax) === 'number' && salesTax > 0) {
        transaction.set("tax", salesTax);
      }
      // When admin process the transaction using virtual terminal with tip_amount
       if(!currentSurcharge){
        //setting transaction amount if tip_amount exists
        transaction.set("transaction_amount", (amountDue + tipAmount + salesTax).toFixed(2));
      }
      transaction.set('tax', salesTax);
      var isContactUserRoute =
        this.get("route") === "contactusermakepayment" ||
        this.get("route") === "virtualterminalcontact_contactuser";
      var trx_source_id = isContactUserRoute ? "7" : "3";
      transaction.set("trx_source_id", trx_source_id);

      if (this.get("isRunagain")) {
        transaction.set("account_vault_id", null);
        transaction.set("routing", null);
      }
      if (scenario === "new_account") {
        transaction.set("account_vault_id", null);
      } else if (scenario === "stored_account") {
        transaction.set("account_number", null);
        transaction.set("routing", null);
      }

      var transaction_amount = transaction.get("transaction_amount");
      var isVerificationOnly = transaction.get("action") === "avsonly";
      var isPaya = product.get("isSubProcessorPaya");
      if (isVerificationOnly && !isPaya) {
        transaction.set("surcharge_amount", "0");
        transaction.set("tax", "0");
        transaction.set("tip_amount", "0");
        transaction.set("subtotal_amount", "0");
        transaction.set("transaction_amount", "0");
      }

      if (
        (!transaction_amount ||
          parseInt(String(transaction_amount).replace(".", "")) === 0) &&
        (!isVerificationOnly || (isVerificationOnly && isPaya))
      ) {
        transactionErrors.push("Transaction amount must be greater than zero.");
      }
      if (String(transaction_amount).indexOf(".") !== -1) {
        var tempTrxAmount = String(transaction_amount).split(".");
        if (tempTrxAmount[1] && tempTrxAmount[1].length > 2) {
          transactionErrors.push(
            "Transaction amount cannot have more than 2 decimal places."
          );
        }
      }

      if (
        product.get("payment_method") === "cc" &&
        Ember.isEmpty(transaction.get("account_vault_id"))
      ) {
        if (product.get("vt_require_zip")) {
          if (
            Ember.isEmpty(transaction.get("billing_zip")) ||
            transaction.get("billing_zip").length < 5
          ) {
            transactionErrors.push("Valid zip code is required.");
          }
        }

        if (product.get("vt_require_street")) {
          if (
            Ember.isEmpty(transaction.get("billing_street")) ||
            transaction.get("billing_street").length === 0
          ) {
            transactionErrors.push("Street number is required.");
          }
        }
      }

      if (
        !this.get("showCustomField3") &&
        !this.get("sso_params.transaction_c3")
      ) {
        this.set("transaction.transaction_c3", null);
      }

      if (processMethodTerminal) {
        if (transactionErrors.get("length") > 0) {
          self.setIsLoading(false);
          return;
        }

        // Lets check to see if we can reach terminal before we send terminaltransaction to API_URL
        var terminal;
        var terminalsObj = self.get("locationTerminals");
        var terminals = terminalsObj.filter(function (item) {
          return item.get("id") === transaction.get("terminal_id");
        });
        if (terminals.get("length") === 1) {
          terminal = terminals.get("firstObject");
        } else {
          transactionErrors.push("Unable to get terminal properties.");
          self.setIsLoading(false);
          return;
        }
        if (
          (Ember.isEmpty(terminal.get("local_ip_address")) ||
            Ember.isEmpty(terminal.get("port"))) &&
          terminal.get("terminal_manufacturer_id") === 1
        ) {
          transactionErrors.push("This terminal has not yet registered.");
          self.setIsLoading(false);
          return;
        }

        if (terminal.get("terminal_manufacturer_id") > 1) {

          var temptags = transaction.get("tags").map(function (tag) {
            return tag;
          });
          var tempTransaction = transaction.toJSON();
          tempTransaction.tags = [];

          var routertransaction = self
            .get("store")
            .createRecord("routertransaction", tempTransaction);
          routertransaction.set("tags", temptags);
          var changed = transaction.changedAttributes();
          for (var key in changed) {
            routertransaction.set(key, changed[key][1]);
          }

          // Add in the terminal id
          routertransaction.set("terminal_id", terminal.get("id"));


          // Set modal so it can't be cancelled from the UI
          pretty_no_buttons(
            "Prompting terminal for card...<br><br>To cancel, press the cancel button on the terminal."
          );
          var notyId = $(".PrettyNoButtons").attr("id");

          routertransaction.save().then(
            function () {
              $.noty.close(notyId);
              var trans = self.get("store").createRecord("transaction", {
                payment_method: "cc",
                exp_month: null,
                exp_year: null,
              });

              parent.send("showTransDetail", routertransaction, location);
              self.set("transaction", trans);
              self.set("selectedTags", null);
              self.set("scenario", null);
              self.didInsertElement();
              parent.send("refreshData");
              self.notifyPropertyChange("transaction.location_id");
              self.setIsLoading(false);
            },
            function () {
              $.noty.close(notyId);
              var errors = routertransaction.get("errors");
              var transErrors = transaction.get("errors");
              var rtObject = routertransaction.toJSON();
              for (var key in rtObject) {
                if (rtObject.hasOwnProperty(key)) {
                  var errorFor = errors.errorsFor(key);
                  if (errorFor.length > 0) {
                    transErrors.add(key, errorFor[0].message);
                  }
                }
              }
              self.setIsLoading(false);
            }
          );
        }
      } else {
        // keyed transaction
        if (
          (Ember.isEmpty(transaction.get("account_number")) ||
            ((transaction.get("account_number.length") < 13 ||
              transaction.get("account_number.length") > 19) &&
              transaction.get("payment_method") === "cc")) &&
          Ember.isEmpty(transaction.get("account_vault_id")) &&
          Ember.isEmpty(transaction.get("previous_transaction_id"))
        ) {
          transactionErrors.push("Account number is required.");
        }

        if (product.vt_require_cvv && Ember.isEmpty(transaction.get("cvv")) && self.route === "virtualterminal") {
          transactionErrors.push("CVV is required.");
        }

        if (
          product.get("payment_method") === "cc" &&
          Ember.isEmpty(transaction.get("account_vault_id")) &&
          Ember.isEmpty(transaction.get("previous_transaction_id"))
        ) {
          if (transaction.get("exp_month") && transaction.get("exp_year")) {
            transaction.set(
              "exp_date",
              transaction.get("exp_month") + transaction.get("exp_year")
            );
          }
        }

        if (transactionErrors.get("length") > 0) {
          self.setIsLoading(false);
          return;
        }
        transaction.set("terminal_id", null);
        if (transaction.get("account_vault_id")) {
          self.set("ignore_ach_sec_code_change", true);
        }
        transaction.set("no_show", transaction.get("extra_flag") === "no_show");
        transaction.set(
          "advance_deposit",
          transaction.get("extra_flag") === "advance_deposit"
        );

        if (self.get("isQuickInvoicePayment")) {
          self.set("qiErrors", {});
          self.set("qiErrorsMessages", []);
          transaction.set("is_company", self.get("currentAccount_is_company"));
          var data = {
            transaction: self.get("transaction").toJSON(),
          };
          var supportedProperties = [
            "account_holder_name",
            "account_number",
            "account_vault_api_id",
            "account_vault_id",
            "account_type",
            "billing_city",
            "billing_phone",
            "billing_state",
            "billing_street",
            "billing_zip",
            "cvv",
            "dl_number",
            "dl_state",
            "dob_year",
            "exp_date",
            "is_company",
            "payment_method",
            "quick_invoice_id",
            "routing",
            "save_account",
            "save_account_title",
            "ssn4",
            "terms_agree",
            "subtotal_amount",
            "surcharge_amount",
            "transaction_amount",
          ];
          if (
            transaction.get("payment_method") === "ach" &&
            !transaction.get("account_vault_id")
          ) {
            data.transaction.is_company = self.get("currentAccount_is_company");
          }
          for (var prop in data.transaction) {
            if (data.transaction.hasOwnProperty(prop)) {
              if (supportedProperties.indexOf(prop) === -1) {
                delete data.transaction[prop];
              }
            }
          }
          jQuery
            .ajax({
              url:
                window.UiENV.APP.FULL_URL +
                "/public/quickinvoice?id=" +
                self.get("quickinvoice.id"),
              type: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(data),
            })
            .then(
              function (res) {
                transaction.unloadRecord();
                self
                  .get("store")
                  .query("transaction", {
                    quick_invoice_id: res.quickinvoice.quick_invoice_id,
                    expand: "status,type",
                    show_totals: true,
                    sort: "-created_ts",
                    page_size: "2",
                  })
                  .then(function (result) {
                    parent.send(
                      "showTransDetail",
                      result.get("firstObject"),
                      location
                    );
                    var trans = self.get("store").createRecord("transaction", {
                      payment_method: "cc",
                      exp_month: null,
                      exp_year: null,
                    });
                    self.set("transaction", trans);
                    self.set("scenario", null);
                    parent.send("refreshData");
                    self.setIsLoading(false);
                  });
              },
              function (error) {
                var qiErrorsObject = {};
                var errorArray = [];
                var errors = error.responseJSON.errors;
                for (var prop in errors) {
                  var errs = errors[prop];
                  if (errs.length > 1) {
                    qiErrorsObject[prop] = errs;
                    errorArray = errorArray.concat(errs);
                  } else {
                    qiErrorsObject[prop] = errs[0];
                    errorArray.push(errs[0]);
                  }
                }
                self.set("qiErrors", qiErrorsObject);
                self.set("qiErrorsMessages", errorArray);
                self.setIsLoading(false);
              }
            );
        } else {
          self.set("vtError",null);
          transaction.save().then(
            function (data) {
              self.set('isCardValidated',true);
              transaction.unloadRecord();

              if (self.get("contact.id")) {
                transaction.set("contact", contact);
                transaction.set("product_transaction", product);
              }
              self.set("sso_params", null);

              var trans = self.get("store").createRecord("transaction", {
                payment_method: "cc",
                exp_month: null,
                exp_year: null,
              });
              parent.send("showTransDetail", data, location);
              self.set("errorWarningMessage", false);
              self.set("transaction", trans);
              self.set("selectedTags", null);
              self.set("scenario", null);
              if (!self.get("isRunagain")) {
                self.didInsertElement();
              }
              parent.send("refreshData");
              self.notifyPropertyChange("transaction.location_id");
              self.setIsLoading(false);
              self.set("ignore_ach_sec_code_change", false);
            },
            function (err) {
              // Adapter error is thrown by Ember Data to let us know that the data model did not save successfully due to a 422 error.
              // Adding following code to check if the error received has a status key in it. Adapter error does not have a status key.
              if (err.status) {
                transactionErrors.push(err.message);
                self.set("qiErrorsMessages",transactionErrors);
              }
              self.setIsLoading(false);
            }
          );
        }
      }
    },
    saveMultiple: function () {
      // THIS HOLDS ALL TRANSACTION PROMISES
      const quickInvoiceTransactionPromises = [];

      // Get the selected invoices
      const selectedInvoicesInitial = this.parentView
        .get("parent")
        .get("quickinvoice")
        .get("_selectedQuickInvoices");

      const selectedInvoices = [];

      selectedInvoicesInitial.forEach((invoice) => {
        let currentPaymentMethod = this.get('product.payment_method');
        if (currentPaymentMethod === 'cc') {
          if (invoice.cc_product_transaction_id && invoice.cc_product_transaction_id.length > 0) {
            selectedInvoices.push(invoice);
          };
        } else {
          if (invoice.ach_product_transaction_id && invoice.ach_product_transaction_id.length > 0) {
            selectedInvoices.push(invoice);
          };
        }
      });
      for (let i = 0; i < selectedInvoices.length; i++) {
        var parent = this.get("parent");

        jQuery(".dropdown-select.open .dropdown-toggle").dropdown("toggle"); // close any open dropdowns before proceeding. Ex: selecting/deselecting tags - changes only apply to model on dropdown close

        this.setIsLoading(true);

        var self = this;
        var transaction = this.get("transaction");
        var location = this.get("location");
        var product = this.get("product");
        var contact = this.get("contact");
        var processMethodTerminal = this.get("processMethodTerminal");
        var store = this.get("store");
        var scenario = this.get("scenario");

        var isContactUserRoute = this.get("route") === "contactusermakepayment" || this.get("route") === "virtualterminalcontact_contactuser";
        var trx_source_id = isContactUserRoute ? "7" : "3";
        transaction.set("trx_source_id", trx_source_id);

        // If transaction.save_account is enabled an AV will be created
        // Following condition checks if there are multiple invoices.
        // If there are multiple invoices, then let transaction.save_account be set to false from the 2nd invoice onwards. This will disable creation of multiple account vaults.
        if (i >= 1 && transaction.save_account) {
          transaction.set("save_account", false);
        }

        if (this.get("isRunagain")) {
          transaction.set("account_vault_id", null);
          transaction.set("routing", null);
        }

        if (scenario === "new_account") {
          transaction.set("account_vault_id", null);
        } else if (scenario === "stored_account") {
          transaction.set("account_number", null);
          transaction.set("routing", null);
        }
        // checking selected invoices are greater than or equal to one.
        if (selectedInvoices.length >= 1) {
          // set amount from selected invoice grid
          // Setting following variables here since it is not set anywhere in the above code.
          transaction.set("subtotal_amount", parseFloat(selectedInvoices[i].amount).toFixed(2));

          const currentSurcharge = selectedInvoices[i].current_surcharge;
          const surcharge_amount = currentSurcharge;
          const transaction_amount = parseFloat(selectedInvoices[i].amount) + surcharge_amount;
          transaction.set("surcharge_amount", surcharge_amount.toFixed(2));
          transaction.set("transaction_amount", transaction_amount.toFixed(2));
          transaction.set("quick_invoice_id", selectedInvoices[i]._internalModel.id);
        }

        self.set("transactionErrors", []);

        var transactionErrors = self.get("transactionErrors");
        var transaction_amount = transaction.get("transaction_amount");
        var isVerificationOnly = transaction.get("action") === "avsonly";
        var isPaya = product.get("isSubProcessorPaya");

        if (isVerificationOnly && !isPaya) {
          transaction.set("surcharge_amount", "0");
          transaction.set("tax", "0");
          transaction.set("tip_amount", "0");
          transaction.set("subtotal_amount", "0");
          transaction.set("transaction_amount", "0");
        }

        if (
          (!transaction_amount ||
            parseInt(String(transaction_amount).replace(".", "")) === 0) &&
          (!isVerificationOnly || (isVerificationOnly && isPaya))
        ) {
          transactionErrors.push(
            "Transaction amount must be greater than zero."
          );
        }
        if (String(transaction_amount).indexOf(".") !== -1) {
          var tempTrxAmount = String(transaction_amount).split(".");
          if (tempTrxAmount[1] && tempTrxAmount[1].length > 2) {
            transactionErrors.push(
              "Transaction amount cannot have more than 2 decimal places."
            );
          }
        }

        if (
          product.get("payment_method") === "cc" &&
          Ember.isEmpty(transaction.get("account_vault_id"))
        ) {
          if (product.get("vt_require_zip")) {
            if (
              Ember.isEmpty(transaction.get("billing_zip")) ||
              transaction.get("billing_zip").length < 5
            ) {
              transactionErrors.push("Valid zip code is required.");
            }
          }

          if (product.get("vt_require_street")) {
            if (
              Ember.isEmpty(transaction.get("billing_street")) ||
              transaction.get("billing_street").length === 0
            ) {
              transactionErrors.push("Street number is required.");
            }
          }
        }

        if (
          !this.get("showCustomField3") &&
          !this.get("sso_params.transaction_c3")
        ) {
          this.set("transaction.transaction_c3", null);
        }

        if (processMethodTerminal) {
          if (transactionErrors.get("length") > 0) {
            self.setIsLoading(false);
            return;
          }

          // Lets check to see if we can reach terminal before we send terminaltransaction to API_URL
          var terminal;
          var terminalsObj = self.get("locationTerminals");
          var terminals = terminalsObj.filter(function (item) {
            return item.get("id") === transaction.get("terminal_id");
          });
          if (terminals.get("length") === 1) {
            terminal = terminals.get("firstObject");
          } else {
            transactionErrors.push("Unable to get terminal properties.");
            self.setIsLoading(false);
            return;
          }
          if (
            (Ember.isEmpty(terminal.get("local_ip_address")) ||
              Ember.isEmpty(terminal.get("port"))) &&
            terminal.get("terminal_manufacturer_id") === 1
          ) {
            transactionErrors.push("This terminal has not yet registered.");
            self.setIsLoading(false);
            return;
          }

          if (terminal.get("terminal_manufacturer_id") > 1) {

            var temptags = transaction.get("tags").map(function (tag) {
              return tag;
            });
            var tempTransaction = transaction.toJSON();
            tempTransaction.tags = [];

            var routertransaction = self
              .get("store")
              .createRecord("routertransaction", tempTransaction);
            routertransaction.set("tags", temptags);
            var changed = transaction.changedAttributes();
            for (var key in changed) {
              routertransaction.set(key, changed[key][1]);
            }

            // Add in the terminal id
            routertransaction.set("terminal_id", terminal.get("id"));


            // Set modal so it can't be cancelled from the UI
            pretty_no_buttons(
              "Prompting terminal for card...<br><br>To cancel, press the cancel button on the terminal."
            );
            var notyId = $(".PrettyNoButtons").attr("id");

            routertransaction.save().then(
              function () {
                $.noty.close(notyId);
                var trans = self.get("store").createRecord("transaction", {
                  payment_method: "cc",
                  exp_month: null,
                  exp_year: null,
                });

                parent.send("showTransDetail", routertransaction, location);
                self.set("transaction", trans);
                self.set("selectedTags", null);
                self.set("scenario", null);
                self.didInsertElement();
                parent.send("refreshData");
                self.notifyPropertyChange("transaction.location_id");
                self.setIsLoading(false);
              },
              function () {
                $.noty.close(notyId);
                var errors = routertransaction.get("errors");
                var transErrors = transaction.get("errors");
                var rtObject = routertransaction.toJSON();
                for (var key in rtObject) {
                  if (rtObject.hasOwnProperty(key)) {
                    var errorFor = errors.errorsFor(key);
                    if (errorFor.length > 0) {
                      transErrors.add(key, errorFor[0].message);
                    }
                  }
                }
                self.setIsLoading(false);
              }
            );
          }
        } else {
          // keyed transaction
          if (
            (Ember.isEmpty(transaction.get("account_number")) ||
              ((transaction.get("account_number.length") < 13 ||
                transaction.get("account_number.length") > 19) &&
                transaction.get("payment_method") === "cc")) &&
            Ember.isEmpty(transaction.get("account_vault_id")) &&
            Ember.isEmpty(transaction.get("previous_transaction_id"))
          ) {
            transactionErrors.push("Account number is required.");
          }

          if (
            product.get("payment_method") === "cc" &&
            Ember.isEmpty(transaction.get("account_vault_id")) &&
            Ember.isEmpty(transaction.get("previous_transaction_id"))
          ) {
            if (transaction.get("exp_month") && transaction.get("exp_year")) {
              transaction.set(
                "exp_date",
                transaction.get("exp_month") + transaction.get("exp_year")
              );
            }
          }

          if (transactionErrors.get("length") > 0) {
            self.setIsLoading(false);
            return;
          }
          transaction.set("terminal_id", null);
          if (transaction.get("account_vault_id")) {
            self.set("ignore_ach_sec_code_change", true);
          }
          transaction.set(
            "no_show",
            transaction.get("extra_flag") === "no_show"
          );
          transaction.set(
            "advance_deposit",
            transaction.get("extra_flag") === "advance_deposit"
          );

          if (self.get("isQuickInvoicePayment")) {
            self.set("qiErrors", {});
            self.set("qiErrorsMessages", []);
            transaction.set(
              "is_company",
              self.get("currentAccount_is_company")
            );
            var data = {
              transaction: self.get("transaction").toJSON(),
            };
            var supportedProperties = [
              "account_holder_name",
              "account_number",
              "account_vault_api_id",
              "account_vault_id",
              "account_type",
              "billing_city",
              "billing_phone",
              "billing_state",
              "billing_street",
              "billing_zip",
              "cvv",
              "dl_number",
              "dl_state",
              "dob_year",
              "exp_date",
              "is_company",
              "payment_method",
              "quick_invoice_id",
              "routing",
              "save_account",
              "save_account_title",
              "ssn4",
              "terms_agree",
              "subtotal_amount",
              "surcharge_amount",
              "transaction_amount"
            ];
            if (
              transaction.get("payment_method") === "ach" &&
              !transaction.get("account_vault_id")
            ) {
              data.transaction.is_company = self.get(
                "currentAccount_is_company"
              );
            }
            for (var prop in data.transaction) {
              if (data.transaction.hasOwnProperty(prop)) {
                if (supportedProperties.indexOf(prop) === -1) {
                  delete data.transaction[prop];
                }
              }
            }
            const transactionPromise = jQuery.ajax({
              url:
                window.UiENV.APP.FULL_URL +
                "/public/quickinvoice?id=" +
                (selectedInvoices.length > 1
                  ? selectedInvoices[i]._internalModel.id
                  : self.get("quickinvoice.id")),
              type: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(data),
            });
            quickInvoiceTransactionPromises.push(transactionPromise);
          } else {
            transaction.save().then(
              function (data) {
                transaction.unloadRecord();
                if (self.get("contact.id")) {
                  transaction.set("contact", contact);
                  transaction.set("product_transaction", product);
                }
                self.set("sso_params", null);

                var trans = self.get("store").createRecord("transaction", {
                  payment_method: "cc",
                  exp_month: null,
                  exp_year: null,
                });
                parent.send("showTransDetail", data, location);
                self.set("transaction", trans);
                self.set("selectedTags", null);
                self.set("scenario", null);
                if (!self.get("isRunagain")) {
                  self.didInsertElement();
                }
                parent.send("refreshData");
                self.notifyPropertyChange("transaction.location_id");
                self.setIsLoading(false);
                self.set("ignore_ach_sec_code_change", false);
              },
              function () {
                self.setIsLoading(false);
              }
            );
          }
        }
      }

      if (quickInvoiceTransactionPromises.length !== 0) {
        Promise.all(quickInvoiceTransactionPromises).then(
          function (transactionResponses) {
            const transactionQueryPromises = [];
            for(let i = 0; i < transactionResponses.length; i++){
              const qiTransactionResponse = transactionResponses[i].quickinvoice;

              // we need all transaction objects
              transactionQueryPromises.push(self
                .get("store")
                .query("transaction", {
                  quick_invoice_id: qiTransactionResponse.quick_invoice_id,
                  expand: "status,type",
                  show_totals: true,
                  sort: "-created_ts",
                  page_size: "2",
                }))
            }

            Promise.all(transactionQueryPromises).then((transactionQueryResponses) => {
              const res = transactionResponses[0]; // just the first one to not break stuff
              transaction.unloadRecord();
              parent.send(
                "showTransDetail",
                res,
                location,
                undefined,
                transactionQueryResponses
              );
              var trans = self.get("store").createRecord("transaction", {
                payment_method: "cc",
                exp_month: null,
                exp_year: null,
              });
              self.set("transaction", trans);
              self.set("scenario", null);
              parent.send("refreshData");
              self.setIsLoading(false);
              // Commenting below code as it is making an extra transaction status API call for the first quickinvoice in the list.
              // self
              //   .get("store")
              //   .query("transaction", {
              //     quick_invoice_id: res.quickinvoice.quick_invoice_id,
              //     expand: "status,type",
              //     show_totals: true,
              //     sort: "-created_ts",
              //     page_size: "2",
              //   })
              //   .then(function (result) {
              //     parent.send(
              //       "showTransDetail",
              //       result.get("firstObject"),
              //       location,
              //       undefined,
              //       transactionQueryResponses
              //     );
              //     var trans = self.get("store").createRecord("transaction", {
              //       payment_method: "cc",
              //       exp_month: null,
              //       exp_year: null,
              //     });
              //     self.set("transaction", trans);
              //     self.set("scenario", null);
              //     parent.send("refreshData");
              //     self.setIsLoading(false);
              //   });
            })
          },
          function (error) {
            var qiErrorsObject = {};
            var errorArray = [];
            var errors = error.responseJSON.errors;
            for (var prop in errors) {
              var errs = errors[prop];
              if (errs.length > 1) {
                qiErrorsObject[prop] = errs;
                errorArray = errorArray.concat(errs);
              } else {
                qiErrorsObject[prop] = errs[0];
                errorArray.push(errs[0]);
              }
            }
            self.set("qiErrors", qiErrorsObject);
            self.set("qiErrorsMessages", errorArray);
            self.setIsLoading(false);
          }
        );
      }
    },
    showDetails: function () {
      this.toggleProperty("showQuickInvoiceDetails");
    },
    unsetContact: function () {
      var transaction = this.get("transaction");
      transaction.set("contact_id", null);
    },
    updateItems: function (data) {
      var self = this;
      var routings = this.get("store").query("routingnumber", {
        routing: data,
        page_size: 20,
      });
      routings.then(function (result) {
        self.set("routingNumbers", result);
      });
    },
    updateTags: function (tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if (typeof tags !== "object") {
        return;
      }
      if (!tags.filters[0].value) {
        return;
      }

      var transaction = this.get("transaction");
      var tagList = this.get("tagList");
      var selectedTags = tags.filters[0].value.split(",");
      tagList.forEach(function (tag) {
        if (selectedTags.indexOf(tag.get("title")) > -1) {
          transaction.get("tags").pushObject(tag);
        } else {
          transaction.get("tags").removeObject(tag);
        }
      });
    },

    validateAccountNumber: function (accountNumber) {
      var self = this;

      self.set('isCardValidated', true);

      if (accountNumber.length == 16) {
        self.debitFilter(accountNumber,self);
      } else {

        self.isDebitCard = false;
        self.calculateTax();
        self.set("errorWarningMessage", false );
        self.set("isDebitSurchargeInfo",false);
      }
    },
  },
});
