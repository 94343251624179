import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  mailbox_message_status_id: DS.attr('number'),

  // Defined model relationships
  mailbox_message: DS.belongsTo('mailboxmessage'),
  mailbox_message_status: DS.belongsTo('mailboxmessagestatus'),

});
