import Ember from 'ember';

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  accountsview: Ember.inject.controller('accounts/view'),
  contactsaccountsview: Ember.inject.controller('contacts/accounts-view'),
  contactscharge: Ember.inject.controller('contacts/charge'),
  contactsdashboard: Ember.inject.controller('contacts/dashboard'),
  contactsquickinvoicesview: Ember.inject.controller('contacts/quickinvoices-view'),
  contactsrecurringsview: Ember.inject.controller('contacts/recurrings-view'),
  contactstransactions: Ember.inject.controller('contacts/transactions'),
  locationsaccountsview: Ember.inject.controller('locations/accounts-view'),
  locationsquickinvoicesview: Ember.inject.controller('locations/quickinvoices-view'),
  locationsrecurringsview: Ember.inject.controller('locations/recurrings-view'),
  locationstransactions: Ember.inject.controller('locations/transactions'),
  locationsunsettledtransactions: Ember.inject.controller('locations/reports-cc-unsettledtransactions'),
  quickinvoiceview: Ember.inject.controller('quickinvoice/view'),
  recurringsview: Ember.inject.controller('recurrings/view'),
  reportstransactions: Ember.inject.controller('reports/transactions'),
  reportstransactionslegacy: Ember.inject.controller('reports/transactionslegacy'),
  sessionService: Ember.inject.service('session'),
  transactiondetailfull: Ember.inject.controller('transactiondetailfull'),
  virtualterminal: Ember.inject.controller('virtualterminal'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sender: null,

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,

  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),

  // Various controller properties
  tagList: null,
  selectedTags: null,
  pagerParams: {
    pageRoute: 'components.modals.refund'
  },

  checkinDateMin: null,
  checkinDateMax: null,
  checkoutDateMin: null,
  checkoutDateMax: null,

  updateAdvanceDeposit: Ember.observer('transaction.extra_flag', function() {
    var one_day = moment.duration(1, 'd');
    var two_days = moment.duration(2, 'd');

    var transaction = this.get('transaction');
    if(!transaction || transaction.get('isDestroying') || transaction.get('isDetroyed')) {
      return;
    }
    var today = moment().format('L');
    var yesterday = moment().subtract(one_day).format('L');
    var tomorrow = moment().add(one_day).format('L');
    var twodays = moment().add(two_days).format('L');
    if (transaction.get('extra_flag') === 'advance_deposit') {
      this.set('checkinDateMin', tomorrow);
      this.set('checkinDateMax', '12/31/2050');
      this.set('checkoutDateMin', twodays);
      this.set('checkoutDateMax', '12/31/2050');
      transaction.set('checkin_date', tomorrow);
      transaction.set('checkout_date', twodays);
    } else {
      this.set('checkinDateMin', '01/01/1970');
      this.set('checkinDateMax', yesterday);
      this.set('checkoutDateMin', '01/01/1970');
      this.set('checkoutDateMax', today);
      transaction.set('checkin_date', yesterday);
      transaction.set('checkout_date', today);
    }
  }),

  // Define fields that should be shown on the form

  originalTransactionAmount: Ember.computed('transaction', 'transactiondetailfull', function() {
    const fullTransaction = this.get('transactiondetailfull.transaction');
    const transaction = this.get('transaction');
    return transaction.get('original_transaction_amount') || transaction.get('transaction_amount') || fullTransaction.get('transaction_amount');
  }),

  canEditSubtotal: Ember.computed('transaction.isCC', 'showSubtotal', function() {
    return this.get('transaction.isCC') && this.get('showSubtotal');
  }),
  showSubtotal: Ember.computed('showSurcharge', 'transaction.{isCC,isACH}', function() {
    return (this.get('transaction.isCC') || this.get('transaction.isACH')) && ((this.get('showSurcharge')) ? true : false);
  }),

  canEditSurcharge: Ember.computed('transaction.isCC', 'showSurcharge', function() {
    return this.get('transaction.isCC') && this.get('showSurcharge');
  }),
  showSurcharge: Ember.computed('product.surcharge.id', 'product.surcharge.refund_surcharges', 'transaction.{action,isCC,isACH}', 'session.authenticated.user_type_id', function () {
    const product_has_surcharge = this.get('product.surcharge.id');
    const apply_to_user_type_id = this.get('product.surcharge.apply_to_user_type_id');
    const applies_to_user = ( apply_to_user_type_id && this.get('session.authenticated.user_type_id').toString() === apply_to_user_type_id ) || !apply_to_user_type_id;

    return (this.get('transaction.isCC') || this.get('transaction.isACH')) && product_has_surcharge && applies_to_user && (this.get('transaction.action') !== 'refund' || this.get('product.surcharge.refund_surcharges'));
  }),

  getTransaction: function(previousId) {
    return new Promise((resolve, reject) => {
      this.get('store').queryRecord('transaction', {
        id: previousId,
      }).then((data) => {
        resolve(data);
      });
    });
  },


  achCalculations: Ember.observer('transaction.isACH', async function() {
    let transaction = this.get('transaction');
    let product = this.get('product');
    let fullTransaction = this.get('transactiondetailfull.transaction');
    // Check if variables are undefined | NULL | empty  
    if (!transaction) return;
    
    // extract variables only if above if condition is true
    let payment_method = transaction.get('payment_method');
    let subtotalAmount = transaction.get('subtotal_amount');
    let surchargeAmount = transaction.get('surcharge_amount');
    if (transaction.get('action') !== 'refund' || payment_method !== 'ach') return;
    let data = await this.getTransaction(transaction.get('previous_transaction_id'));
    let achSecCode = transaction.get('ach_sec_code') || data.get('ach_sec_code');

    // get ACH SEC CODE from the original transaction
    if (Number.isNaN(parseFloat(subtotalAmount))) {
      subtotalAmount = data.get('subtotal_amount') || fullTransaction.get('subtotal_amount');
    }
    if (!surchargeAmount || Number.isNaN(parseFloat(surchargeAmount))) {
      surchargeAmount = data.get('surcharge_amount') || fullTransaction.get('surcharge_amount');
    }
    if (product && product.get('surcharge') && product.get('surcharge.is_surcharge') === 'Surcharge') {
      transaction.set('surcharge_amount', surchargeAmount);
      this.calculateSurcharge();
    }
    transaction.set('ach_sec_code', achSecCode);
    transaction.set('subtotal_amount', subtotalAmount);
  }),

  subtotalChanged: Ember.observer('transaction.subtotal_amount', function() {
    if (this.get('transaction.isCC')) {
      this.updateTotal();
    }
  }),

  surchargeChanged: Ember.observer('transaction.surcharge_amount', function() {
    if (this.get('transaction.isCC')) {
      this.updateTotal();
    }
  }),

  updateTotal: function() {
    if (this.get('isLoading')) {
      return;
    }

    var transaction = this.get('transaction');
    if (!transaction) {
      return;
    }

    // Do some checks to see if values only include a decimal, if so, number calculations don't work properly
    if ( transaction.get( 'subtotal_amount'  ) === '.' ) {
      return;
    }

    let subtotal_amount = 0;
    if ( parseFloat( transaction.get( 'subtotal_amount' ) ) > 0 ) {
      subtotal_amount = parseFloat( transaction.get( 'subtotal_amount' ) );
    }

    var surcharge = this.get('product.surcharge');

    var surcharge_amount = 0;
    if ( 
        parseFloat( transaction.get( 'surcharge_amount' ) ) > 0 &&
                      surcharge.get( 'refund_surcharges' ) ) {
      surcharge_amount = parseFloat( transaction.get( 'surcharge_amount' ) );
    }

    if ( parseInt( subtotal_amount + surcharge_amount ) === 0 ) {
      transaction.set( 'transaction_amount', '' );
      return;
    }

    transaction.set( 'transaction_amount', String(
      ( parseFloat( subtotal_amount  ) +
        parseFloat( surcharge_amount ) ).toFixed(2) ) );
  },

  calculateSurcharge: function() {
    var surcharge = this.get('product.surcharge');
    var transaction = this.get('transaction');
    if (!surcharge || !transaction) {
      return 0;
    }

    if (transaction.get('action') === 'refund') {
      if (!surcharge.get('refund_surcharges')) {
        transaction.set('surcharge_amount', 0);
        return 0;
      }
    }

    var apply_to_user_type_id = surcharge.get('apply_to_user_type_id');
    var applies_to_user = (apply_to_user_type_id && this.get('session.authenticated.user_type_id').toString() === apply_to_user_type_id) || !apply_to_user_type_id;

    if (!applies_to_user) {
      transaction.set('surcharge_amount', 0);
      return 0;
    }

    var surcharge_amount = 0;
    var transaction_amount = parseFloat( transaction.get('subtotal_amount')) || 0;
    var min_fee_amount     = parseFloat(   surcharge.get('min_fee_amount'))  || 0;
    var max_fee_amount     = parseFloat(   surcharge.get('max_fee_amount'))  || 0;
    var surcharge_fee      = parseFloat(   surcharge.get('surcharge_fee'))   || 0;
    var surcharge_rate     = parseFloat(   surcharge.get('surcharge_rate'))  || 0;

    surcharge_amount = (surcharge_rate / 100 * transaction_amount).round(2) + surcharge_fee;
    surcharge_amount = (surcharge_amount > max_fee_amount) ? max_fee_amount : (surcharge_amount < min_fee_amount) ? min_fee_amount : surcharge_amount;
    if (transaction.get('action') === 'refund' && surcharge.get('refund_surcharges') === 0) {
      surcharge_amount = 0;
    }

    transaction.set('surcharge_amount', surcharge_amount.toFixed(2));

    return surcharge_amount;
  },

  fullyRefunded: Ember.computed('transaction.original_transaction_amount', 'transaction.refunded_amount', function() {
    const original_transaction_amount = this.get( 'transaction.original_transaction_amount' );
    const refunded_amount             = this.get( 'transaction.refunded_amount' );

    if ( ( original_transaction_amount - refunded_amount ) == 0 ) {
      return true;
    } else {
      return false;
    }
  }),

  refundCancelled: function(context) {
    var transaction = context.get('transaction');
    var tagList = context.get('tagList');
    var selectedTags = context.get('initialTags').split(',');
    var tempTags = [];
    tagList.forEach(function(tag) {
      selectedTags.map(function(t){
        if(t === tag.get('title')){
          tempTags.pushObject(tag);
        }
      });
    });
    transaction.set('tags', tempTags);
    transaction.rollbackAttributes();
    context.set('transaction', null);
  },

  unloadTransaction: function() {
    this.get('transaction').unloadRecord();
  },

  // Actions
  actions: {
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }

      // Get transaction object
      var transaction = this.get('transaction');
      // Get existing tags for transaction object
      var existingTags = transaction.get('tags');

      // Remove any existing tag objects
      if (existingTags) {
        existingTags.forEach(function(item) {
          existingTags.removeObject(item);
        });
      }

      // Then add back any newly selected tag objects
      var tagList = this.get('tagList');
      if (tags.filters[0].value !== null) {
        var selectedTags = tags.filters[0].value.split(',');
        tagList.forEach(function(tag) {
          if (selectedTags.indexOf(tag.get('title')) > -1) {
            transaction.get('tags').pushObject(tag);
          }
        });
      }
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var transaction  = this.get('transaction');
      var locationinfo = this.get('locationinfo');
      var sender = self.get('sender');

      if (!this.get('showSurcharge') && transaction.get('payment_method') !== 'ach') {
        transaction.set('subtotal_amount', transaction.get('transaction_amount'));
      }
      
      transaction.save().then(
        function() {
          var sender = self.get('sender');
          if (sender) {
            var senderController = self.get(sender);
            if (senderController) {
              senderController.set('needsRefresh', new moment().unix());
            }
          }
          var senderModel = self.get(sender);
          if (senderModel) {
            senderModel.set('needsRefresh', new moment().unix());
          }
          self.send('showTransDetail', transaction, locationinfo);
          self.set('isLoading', false);
        },
        function() {
          noty({
            text: 'Error refunding transaction!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        }
      );
    }
  }
});
