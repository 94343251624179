import Ember from "ember";
import jQuery from "jquery";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  helperFunctions: Ember.inject.service("helper-functions"),
  sessionService: Ember.inject.service("session"),
  salesOfficeController: Ember.inject.controller(
    "components/modals/addsalesoffice"
  ),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),
  country_data: Ember.computed(function () {
    return this.get("applicationData.country_code");
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransaction: true,
  showIAP:false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "producttransaction.errors.messages",
    function () {
      this.set("errorMessages", this.get("producttransaction.errors.messages"));
    }
  ),

  // Set controller params
  billingAccountLocation: {},
  billingAccountLocations: null,
  isLoading: false,
  isEditing: false,
  isLoadingSalesOffice: false,
  notEditing: Ember.computed("isEditing", function () {
    return !this.get("isEditing");
  }),
  isActive: Ember.computed("producttransaction.active", function () {
    return this.get("producttransaction.active");
  }),
  processors: Ember.computed(function () {
    return this.get("applicationData.processors");
  }),
  cc_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.cc_default_trans_types");
  }),
  ach_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.ach_default_trans_types");
  }),
  isPayaTerminalIds: Ember.computed(
    "producttransaction.processor_data.terminals.[]",
    function () {
      return this.get("producttransaction.processor_data.terminals.length") > 0;
    }
  ),
  payaTerminals: Ember.computed(
    "producttransaction.processor_data.terminals.[]",
    "payaTerminalsWithErrors.[]",
    function () {
      var terminals = this.get("producttransaction.processor_data.terminals");
      var payaTerminalsWithErrors = this.get("payaTerminalsWithErrors");
      terminals.map(function (terminal, index) {
        if (terminal.required && terminal.required.length) {
          Ember.set(
            terminal,
            "required_dl",
            terminal.required.indexOf("driver_license") !== -1
          );
          Ember.set(
            terminal,
            "required_verification_identity",
            terminal.required.indexOf("identity_verification") !== -1
          );
          Ember.set(
            terminal,
            "required_billing_street",
            terminal.required.indexOf("billing_street") !== -1
          );
          Ember.set(
            terminal,
            "required_billing_city",
            terminal.required.indexOf("billing_city") !== -1
          );
          Ember.set(
            terminal,
            "required_billing_state",
            terminal.required.indexOf("billing_state") !== -1
          );
          Ember.set(
            terminal,
            "required_billing_zip",
            terminal.required.indexOf("billing_zip") !== -1
          );
          Ember.set(
            terminal,
            "required_billing_phone",
            terminal.required.indexOf("billing_phone") !== -1
          );
          Ember.set(
            terminal,
            "required_check_number",
            terminal.required.indexOf("check_number") !== -1
          );
          Ember.set(
            terminal,
            "required_account_holder_name",
            terminal.required.indexOf("account_holder_name") !== -1 ||
              terminal.required.indexOf("company_name") !== -1
          );
          Ember.set(
            terminal,
            "required_routing",
            terminal.required.indexOf("routing") !== -1
          );
          Ember.set(
            terminal,
            "required_account_number",
            terminal.required.indexOf("account_number") !== -1
          );
          Ember.set(
            terminal,
            "required_account_type",
            terminal.required.indexOf("account_type") !== -1
          );
        }
        if (terminal.actions && terminal.actions.length) {
          Ember.set(
            terminal,
            "required_verification_check",
            terminal.actions.indexOf("avsonly") !== -1
          );
          Ember.set(
            terminal,
            "can_debit",
            terminal.actions.indexOf("debit") !== -1
          );
          Ember.set(
            terminal,
            "can_credit",
            terminal.actions.indexOf("credit") !== -1
          );
          Ember.set(
            terminal,
            "can_refund",
            terminal.actions.indexOf("refund") !== -1
          );
          Ember.set(
            terminal,
            "can_avsonly",
            terminal.actions.indexOf("avsonly") !== -1
          );
        }
        if (payaTerminalsWithErrors) {
          payaTerminalsWithErrors.map(function (i) {
            if (index === i) {
              Ember.set(terminal, "hasError", true);
            }
          });
        } else {
          Ember.set(terminal, "hasError", false);
        }
        return terminal;
      });
      return terminals;
    }
  ),
  canSeeIAP: Ember.computed("producttransaction.enable_IAP", function () {
    var userId = this.get('session.authenticated.user_type_id'),
    country_code = this.get('producttransaction.processor_data.country_code');
   if (userId == 300 && country_code != '124') {
      return true;
    } else {
      return false;
  }
 }),
 canEditIAP: Ember.computed("producttransaction.enable_IAP", function () {
  var userId = this.get('session.authenticated.user_type_id'),
  country_code = this.get('producttransaction.processor_data.country_code');
   if (userId >= 400 && country_code != '124') {
      return true;
    } else {
      return false;
  }
 }),

  canHPP: Ember.computed(
    "producttransaction.{payment_method,processor,isSubProcessorPaya}",
    function () {
      if (this.get("producttransaction.payment_method") === "cc") {
        return true;
      } else if (this.get("producttransaction.payment_method") === "ach") {
        if (this.get("producttransaction.isSubProcessorPaya")) {
          var terminals = this.get(
            "producttransaction.processor_data.terminals"
          );
          var hasValidSECCode = false;
          if (!terminals || terminals.get("length") === 0) {
            return false;
          }
          terminals.map(function (terminal) {
            var sec_code = terminal.sec_code;
            if (
              sec_code === "CCD" ||
              sec_code === "PPD" ||
              sec_code === "WEB"
            ) {
              hasValidSECCode = true;
            }
          });
          return hasValidSECCode;
        } else {
          return true;
        }
      }
    }
  ),
  billingAccountLocationObserver: Ember.observer(
    "billingAccountLocation.id",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var self = this;
      Ember.run.once(function () {
        var billingAccountLocationID = self.get("billingAccountLocation.id");
        var billingAccountLocations = self.get("billingAccountLocations");
        var bal;
        if (billingAccountLocations && billingAccountLocations.get("length")) {
          billingAccountLocations.map(function (billingAccountLocation) {
            if (billingAccountLocationID === billingAccountLocation.id) {
              bal = billingAccountLocation;
            }
          });
        }
        if (bal) {
          self.set("billingAccountLocation.name", bal.get("name"));
          self.getLocationBillingAccounts();
        }
      });
    }
  ),
  country_code_observer: Ember.observer("producttransaction.processor_data.country_code", function() {
    var country_code = this.get("producttransaction.processor_data.country_code");
    if(country_code === '124'){
      this.set('producttransaction.enable_IAP', false);
      this.set('showIAP',false);
    }else{
      this.set('showIAP',true);
    }
  }),

  getLocationBillingAccounts: function (id) {
    var self = this;
    var billingAccountLocationID = this.get("billingAccountLocation.id");
    if (id) {
      var billingAccount = this.get("store").query("locationbillingaccount", {
        location_id: billingAccountLocationID,
        sort: "name",
        page_size: "100",
        fields: "id,title,location_id",
      });
      billingAccount.then(function (result) {
        var billAcct = result
          .filter(function (res) {
            return res.get("id") === id;
          })
          .get("firstObject");
        self.set("billingAccounts", result);
        self.set("billAcct", billAcct);
        var billingAccountLocations = self.get("store").query("location", {
          id: billAcct.get("location_id"),
          fields: "id,name",
        });
        billingAccountLocations.then(function (result) {
          self.set("billingAccountLocations", result);
          var billAcctLoc = result;
          self.set("billingAccountLocation.id", billAcctLoc.get("id"));
          self.set("billingAccountLocation.name", billAcctLoc.get("name"));
          self.set("billAcct.locationName", billAcctLoc.get("name"));
        });
      });
    } else {
      var billingAccounts = this.get("store").query("locationbillingaccount", {
        location_id: billingAccountLocationID,
        sort: "name",
        page_size: "100",
        fields: "id,title,location_id",
      });
      billingAccounts.then(function (result) {
        self.set("billingAccounts", result);
        if (!self.get("producttransaction.location_billing_account_id")) {
          self.set(
            "producttransaction.location_billing_account_id",
            result.get("firstObject").get("id")
          );
        }
      });
    }
  },

  checkBillingAccountInfo: function () {
    var billingAccounts = this.get("billingAccounts");
    var location_billing_account_id = this.get(
      "producttransaction.location_billing_account_id"
    );
    var isInBillingAccounts = false;
    if (billingAccounts) {
      billingAccounts.map(function (billingAccount) {
        if (billingAccount.get("id") === location_billing_account_id) {
          isInBillingAccounts = true;
        }
      });
    }
    if (!isInBillingAccounts) {
      this.getLocationBillingAccounts(location_billing_account_id);
      this.set(
        "billingAccountLocation.name",
        this.get("billAcct.locationName")
      );
    }
  },

  isFirstData: false,
  isTsys: false,
  changedVtCvv: Ember.observer(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var vt_street = this.get("producttransaction.vt_cvv");
      if (!vt_street) {
        this.set("producttransaction.vt_require_cvv", false);
      }
    }
  ),
  vtCvvRequired: Ember.computed(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_cvv")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtStreet: Ember.observer(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var vt_street = this.get("producttransaction.vt_street");
      if (!vt_street) {
        this.set("producttransaction.vt_require_street", false);
      }
    }
  ),
  vtStreetRequired: Ember.computed(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_street")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtZip: Ember.observer(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var vt_zip = this.get("producttransaction.vt_zip");
      if (!vt_zip) {
        this.set("producttransaction.vt_require_zip", false);
      }
    }
  ),
  vtZipRequired: Ember.computed(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_zip")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changeSubProcessor: Ember.observer(
    "producttransaction.processor_data.sub_processor",
    "producttransaction.sub_processor",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var sub_processor = this.get("producttransaction.sub_processor");
      this.set("isProcessorFdnash", sub_processor === "fdnash");
      this.set("isProcessorTsys", sub_processor === "tsys");
      this.set("isProcessorNashville", sub_processor === "nashville");
      this.set("isProcessorElavon", sub_processor === "elavon");
      this.set("isProcessorPaya", sub_processor === "paya");
      this.set("isProcessorProfitStars", sub_processor === "profitstars");
      this.set("isProcessorVantiv", sub_processor === "vantiv610");
    }
  ),
  changeProcessor: Ember.observer("producttransaction.processor", function () {
    if (
      this.get("producttransaction.isDestroyed") ||
      this.get("producttransaction.isDestroying")
    ) {
      return;
    }
    var self = this;
    var processors = this.get("processors");
    var processor = this.get("producttransaction.processor") || null;

    self.set("isPaymentMethodCc", false);
    self.set("isPaymentMethodAch", false);
    processors.forEach(function (p) {
      self.set("isProcessor" + p.id.capitalize(), p.id === processor);
      if (
        p.id === processor ||
        (p.id === "profitstars" && processor === "ach")
      ) {
        self.set("isPaymentMethod" + p.type.capitalize(), true);
        self.set("producttransaction.payment_method", p.type);
        self.set(
          "default_trans_types",
          self.get(p.type + "_default_trans_types")
        );
      }
    });
  }),
  batchCloseAutomatic: Ember.computed(
    "producttransaction.batch_close_type",
    function () {
      return this.get("producttransaction.batch_close_type") === "automatic";
    }
  ),

  level3_allow_observer: Ember.observer(
    "producttransaction.level3_allow",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var isEditing = this.get("isEditing");
      var level3_allow = this.get("producttransaction.level3_allow");
      var level3_default = this.get("producttransaction.level3_default");
      var defaultLevel3 = this.get("applicationData.level3_defaults");
      if (level3_allow && !level3_default) {
        this.set("producttransaction.level3_default", defaultLevel3);
      } else if (!level3_allow) {
        this.set("producttransaction.level3_default", defaultLevel3);
        this.set("isViewingL3", false);
      } else if (level3_allow && level3_default && isEditing) {
        this.set(
          "producttransaction.level3_default",
          this.get("producttransaction.level3_default")
        );
      }
    }
  ),

  product_billing_group: Ember.computed(
    "producttransaction.product_billing_group_id",
    "productbillinggroups.[]",
    function () {
      var productbillinggroups = this.get("productbillinggroups");
      var product_billing_group_id = this.get(
        "producttransaction.product_billing_group_id"
      );
      if (productbillinggroups && productbillinggroups.get("length")) {
        return productbillinggroups
          .filter(function (pbg) {
            return pbg.get("id") === product_billing_group_id;
          })
          .get("firstObject");
      }
    }
  ),

  cauEnabledObserver: Ember.observer("cauEnabled", function () {
    if (
      this.get("producttransaction.isDestroyed") ||
      this.get("producttransaction.isDestroying")
    ) {
      return;
    }
    if (!this.get("cauEnabled")) {
      this.set("producttransaction.cau_account_number", null);
      this.set("producttransaction.cau_subscribe_type_id", "0");
    }
  }),

  payfac_enable_observer: Ember.observer(
    "producttransaction.payfac_enable",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var payfac_enable = this.get("producttransaction.payfac_enable");
      var payfac_data = this.get(
        "producttransaction.processor_data.payfac_data"
      );
      if (
        payfac_enable &&
        payfac_data &&
        Object.keys(payfac_data).length === 0
      ) {
        this.set("producttransaction.processor_data.payfac_data", {
          amex: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_street_address: this.get("location.address1"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
          mc: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
        });
      } else if ((payfac_enable && !payfac_data) || !payfac_enable) {
        this.set("producttransaction.processor_data.payfac_data", {});
        this.set("isViewingPayfacData", false);
      }
    }
  ),

  // Actions
  actions: {
    delete: function () {
      var self = this;
      var producttransaction = this.get("producttransaction");
      pretty_confirm(
        "Are you sure you want to delete this Deposit Account?",
        function () {
          // Bail if we are already loading
          if (self.get("isLoading")) {
            return;
          }
          self.set("isLoading", true);
          producttransaction.deleteRecord();
          producttransaction.save().then(
            function () {
              producttransaction.unloadRecord();
              noty({
                text: "Deposit Account Service deleted successfully!",
              });
              self.set("isLoading", false);
              self.transitionToRoute(
                "locations.products",
                self.get("location.id")
              );
            },
            function (data) {
              var error = "";
              if (data.errors) {
                data.errors.forEach(function (err) {
                  error += "<br>" + err.detail + ".";
                });
              }
              producttransaction.rollbackAttributes();
              noty({
                text: "Error deleting Deposit Account Service!" + error,
                type: "error",
                timeout: 10000,
              });
              self.set("isLoading", false);
            }
          );
        }
      );
    },
    edit: function () {
      this.set("isEditing", true);
      this.checkBillingAccountInfo();
    },
    save: function () {
      // Bail if we are already loading
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var location = this.get("location");
      var producttransaction = this.get("producttransaction");
      this.set("producttransaction.country_code", producttransaction.processor_data.country_code);
      
      var level3_default = this.get("producttransaction.level3_default");
      if (level3_default !== "" && producttransaction.get("level3_allow")) {
        this.set(
          "producttransaction.level3_default",
          JSON.parse(level3_default)
        );
      } else {
        this.set("producttransaction.level3_default", JSON.parse(level3_default));
      }
      this.set("payaTerminalsWithErrors", []);

      producttransaction.save().then(
        function () {
          noty({
            text: "Deposit Account Service saved successfully!",
          });
          self.set("isLoading", false);
          self.transitionToRoute("locations.products", location.id);
        },
        function (errors) {
          if (errors.errors) {
            var payaTerminalsWithErrors = [];
            errors.errors.map(function (err) {
              if (err.source.pointer === "data/attributes/otherErrors") {
                let prop = err.source.prop.split(".");
                payaTerminalsWithErrors.push(Number(prop[2]));
              }
            });
            self.set("defaultlevel3errors", errors.errors);
            self.set("payaTerminalsWithErrors", payaTerminalsWithErrors);
          }
          window.scrollTo(0, 0);
          self.set(
            "producttransaction.level3_default",
            JSON.stringify(self.get("producttransaction.level3_default"))
          );
          self.set("isLoading", false);
        }
      );
    },
    back: function () {
      var location = this.get("location");
      this.transitionToRoute("locations.products", location.id);
    },
    reset: function () {
      this.set(
        "producttransaction.level3_default",
        JSON.parse(this.get("producttransaction.level3_default"))
      );
      this.set("isEditing", false);
      this.send("refreshData");
    },
    reActivateProduct: function (id) {
      var self = this;
      var action_type = "producttransactions";
      if (self.get("isLoading")) {
        return;
      }
      self.set("isLoading", true);
      var session = this.get("session");
      jQuery
        .ajax({
          url:
            window.UiENV.APP.FULL_URL +
            "/" +
            action_type +
            "/" +
            id +
            "/activate",
          type: "POST",
          headers: {
            "Content-Type": "application/json",
            "developer-id": window.UiENV.APP.DEVELOPER_ID,
            "access-token": session.get("authenticated.token"),
          },
        })
        .then(
          function () {
            noty({
              text: "Deposit Account Service activation successful!",
            });
            self.send("refreshData");
            self.set("isLoading", false);
          },
          function () {
            self.send("refreshData");
            self.set("isLoading", false);
            noty({
              text: "Deposit Account Service activation failed!",
              type: "error",
            });
          }
        );
    },
    addSalesOffice: function () {
      var controller = this.get("salesOfficeController");
      var new_salesoffice = this.get("store").createRecord("salesoffice", {
        title: null,
        contact_name: null,
        phone: null,
        misc: null,
        email: null,
        location_id: this.get("producttransaction.location_id"),
      });
      this.set("producttransaction.sales_office_id", null);
      controller.set("salesoffice", new_salesoffice);
      controller.set("sender", "viewproducttransaction");
      this.send("openModal", "components/modals/addsalesoffice");
    },
    updateSalesOffice: function (data) {
      var self = this;
      var new_sales_office_id = self.get("new_sales_office_id");
      var salesoffice = this.get("store").query("salesoffice", {
        id: new_sales_office_id,
        title: data,
        sort: "title",
        page_size: "10",
        fields: "id,title",
      });
      salesoffice.then(function (result) {
        self.set("salesoffice", result);
        Ember.run.later(function () {
          if (new_sales_office_id) {
            self.set(
              "producttransaction.sales_office_id",
              self.get("new_sales_office_id")
            );
            self.set("new_sales_office_id", null);
          }
        }, 1);
        self.set("isLoadingSalesOffice", false);
      });
    },
    viewDefaults: function () {
      this.toggleProperty("isViewingL3");
    },
    addTerminalID: function (e) {
      var value = this.get("payaach_terminal_id");
      if (!value) {
        return;
      }
      var ids = this.get("producttransaction.processor_data.terminals");
      ids.pushObject({
        terminal_id: value,
      });
      this.set("payaach_terminal_id", null);
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      jQuery("#producttransaction_payaach_terminal_id").focus();
    },
    removeTerminalID: function (index) {
      var ids = this.get("producttransaction.processor_data.terminals");
      var payaTerminalsWithErrors = this.get("payaTerminalsWithErrors");
      if (payaTerminalsWithErrors) {
        var tempTerminalsWithErrors = [];
        payaTerminalsWithErrors.map(function (i) {
          if (index !== i) {
            if (i > index) {
              tempTerminalsWithErrors.push(i - 1);
            } else {
              tempTerminalsWithErrors.push(i);
            }
          }
        });
        this.set("payaTerminalsWithErrors", tempTerminalsWithErrors);
      }
      ids.removeAt(index);
    },
    updatebillingAccountLocation: function (data) {
      var self = this;
      var billingAccountLocations = this.get("store").query("location", {
        name: data,
        sort: "name",
        page_size: "10",
        fields: "id,name",
      });
      billingAccountLocations.then(function (result) {
        self.set("billingAccountLocations", result);
      });
    },
    updatebillingGroups: function (data) {
      var self = this;
      if (
        this.get("session.authenticated.resources.v2-productbillinggroups-get")
      ) {
        var productbillinggroups = this.get("store").query(
          "productbillinggroup",
          {
            title: data,
            sort: "title",
            page_size: "10",
            fields: "id,title",
          }
        );
        productbillinggroups.then(function (result) {
          self.set("productbillinggroups", result);
        });
      }
    },
    viewPayfacData: function () {
      this.toggleProperty("isViewingPayfacData");
      return;
    },
  },
});
