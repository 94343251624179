import Ember from 'ember';

export default Ember.Helper.extend({

  applicationData: Ember.inject.service('application-data'),
  trans_statuses: Ember.computed(function () {
    return this.get('applicationData.trans_statuses');
  }),

  compute(params) {
    var transaction = params[0];
    var type = params[1];
    var status = transaction.get('status_id');
    var title = 'Unknown';
    if (type === 'quickinvoicetransaction' && status === '131') {
      title = 'Accepted';
    } else {
      this.get('trans_statuses').forEach(function (trans_status) {
        if (trans_status.id === status) {
          title = trans_status.title;
        }
      });
    }
    return title;
  }
});
