import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),

  account_number: DS.attr('string'),
  ach_allow_credit: DS.attr('boolean'),
  ach_allow_debit: DS.attr('boolean'),
  ach_allow_refund: DS.attr('boolean'),
  active: DS.attr('boolean'),
  agent_code: DS.attr('string'),
  allow_partial_authorization: DS.attr('boolean'),
  allow_recurring_partial_authorization: DS.attr('boolean'),
  auto_decline_cvv: DS.attr('boolean'),
  auto_decline_street: DS.attr('boolean'),
  auto_decline_zip: DS.attr('boolean'),
  card_type_amex: DS.attr('boolean'),
  card_type_diners: DS.attr('boolean'),
  card_type_disc: DS.attr('boolean'),
  card_type_jcb: DS.attr('boolean'),
  card_type_mc: DS.attr('boolean'),
  card_type_visa: DS.attr('boolean'),
  cau_account_number: DS.attr('maskedbyui'),
  cau_subscribe_type_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  current_batch: DS.attr('string'),
  current_stan: DS.attr('string'),
  default_transaction_type: DS.attr('lcstring'),
  display_avs: DS.attr('boolean'),
  dup_check_per_batch: DS.attr('fakearray'),
  guarantor_location_id: DS.attr('string'),
  hosted_payment_page_allow: DS.attr('boolean'),
  hosted_payment_page_max_allowed: DS.attr('string'),
  industry_type: DS.attr('string'),
  level3_allow: DS.attr('boolean'),
  level3_default: DS.attr('array'),
  location_billing_account_id: DS.attr('string'),
  location_id: DS.attr('string'),
  mcc: DS.attr('string'),
  merchant_id: DS.attr('string'),
  notification_days: DS.attr('string'),
  partner: DS.attr('string'),
  password: DS.attr('string'),
  payfac_enable: DS.attr('boolean'),
  payment_method: DS.attr('string'),
  portfolio_id: DS.attr('string'),
  processor: DS.attr('string'),
  processor_data: DS.attr('array'),
  product_ach_pv_store_id: DS.attr('string'),
  product_billing_group_id: DS.attr('string'),
  quick_invoice_allow: DS.attr('boolean'),
  receipt_add_account_above_signature: DS.attr('string'),
  receipt_add_recurring_above_signature: DS.attr('string'),
  receipt_footer: DS.attr('string'),
  receipt_header: DS.attr('string'),
  receipt_logo: DS.attr('string'),
  receipt_show_contact_name: DS.attr('boolean'),
  receipt_vt_above_signature: DS.attr('string'),
  sales_office_id: DS.attr('string'),
  sub_processor: DS.attr('string'),
  surcharge_id: DS.attr('string'),
  tax_surcharge_config: DS.attr('string'),
  terminal_id: DS.attr('string'),
  title: DS.attr('string'),
  tz: DS.attr('string'),
  username: DS.attr('string'),
  vt_billing_phone: DS.attr('boolnum'),
  vt_clerk_number: DS.attr('boolnum'),
  vt_cvv: DS.attr('boolean'),
  vt_require_cvv: DS.attr('boolean'),
  enable_IAP: DS.attr('boolean'),
  vt_enable: DS.attr('boolean'),
  vt_enable_sales_tax: DS.attr('boolean'),
  vt_enable_tip: DS.attr('boolean'),
  vt_order_num: DS.attr('boolean'),
  vt_override_sales_tax_allowed: DS.attr('boolean'),
  vt_require_street: DS.attr('boolean'),
  vt_require_zip: DS.attr('boolean'),
  vt_street: DS.attr('boolean'),
  vt_zip: DS.attr('boolean'),
  country_code: DS.attr('string'),
  enable_blind_refunds: DS.attr('boolean'),

  // Computed properties
  canReregisterProduct: Ember.computed('isSubProcessorTsys', 'isSubProcessorNashville', function() {
    return this.get('isSubProcessorTsys') || this.get('isSubProcessorNashville');
  }),
  canTipAdjust: Ember.computed('isRestaurant', 'isRetail', 'isMOTO', 'surcharge_id', 'vt_enable_tip', function() {
    return (this.get('isRestaurant') || this.get('isRetail') || this.get('isMOTO')) && !this.get('surcharge_id') && this.get('vt_enable_tip');
  }),
  hasTip: Ember.computed('vt_enable_tip', function() {
    return this.get('vt_enable_tip');
  }),
  canQIPaya: Ember.computed('processor', 'processor_data.terminals', function() {
    var processor = this.get('processor');
    var terminals = this.get('processor_data.terminals');
    if (processor !== 'zach' || !terminals) {
      return false;
    }
    var hasValidSECCode = false;
    terminals.map(function(terminal) {
      if (terminal.sec_code === 'WEB' || terminal.sec_code === 'CCD' || terminal.sec_code === 'PPD') {
        hasValidSECCode = true;
      }
    });
    return hasValidSECCode;
  }),
  cau_subscribe_type_title: Ember.computed('cau_subscribe_type_id', function() {
    var options = this.get('applicationData.cau_subscribe_options');
    var cau_subscribe_type_id = this.get('cau_subscribe_type_id');
    var title = '';
    options.forEach(function(option) {
      if(option.id === cau_subscribe_type_id) {
        title = option.title;
      }
    });
    return title;
  }),
  isECommerce: Ember.computed('industry_type', function() {
    return this.get('industry_type') === 'ecommerce';
  }),
  isLodging: Ember.computed('industry_type', function() {
    return this.get('industry_type') === 'lodging';
  }),
  isMOTO: Ember.computed('industry_type', function() {
    return this.get('industry_type') === 'moto';
  }),
  isPaymentMethodCC: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'cc';
  }),
  isProcessorACH: Ember.computed('processor', function() {
    return this.get('processor') === 'ach' || this.get('processor') === 'zach';
  }),
  isProcessorZgate: Ember.computed('processor', function() {
    return this.get('processor') === 'zgate';
  }),
  isProcessorZgate2: Ember.computed('processor', function() {
    return this.get('processor') === 'zgate2';
  }),
  isRestaurant: Ember.computed('industry_type', function() {
    return this.get('industry_type') === 'restaurant';
  }),
  isRetail: Ember.computed('industry_type', function() {
    return this.get('industry_type') === 'retail';
  }),
  isSubProcessorFirstData: Ember.computed('processor', function() {
    return (this.get('processor_data.sub_processor') === 'tsys' || (typeof this.get('processor_data.sub_processor')) === 'undefined');
  }),
  isSubProcessorNashville: Ember.computed('processor', function() {
    return this.get('processor_data.sub_processor') === 'nashville';
  }),
  isSubProcessorPaya: Ember.computed('processor', function() {
    return this.get('processor') === 'zach';
  }),
  isSubProcessorTsys: Ember.computed('processor', function() {
    return this.get('processor_data.sub_processor') === 'tsys';
  }),
  logo_image: Ember.computed('receipt_logo', function() {
    return this.get('receipt_logo') ? this.get('receipt_logo') + '?' + moment().valueOf() : null;
  }),
  titleWithPaymentMethod: Ember.computed('title', 'payment_method', function() {
    var payment_method = this.get('payment_method');
    var method = payment_method === 'cc' ? 'CC' : 'ACH';
    return this.get('title').trim() + ' - ' + method;
  }),
  titleWithInactiveStatus: Ember.computed('title', 'payment_method', 'active', function() {
    var active = this.get('active');
    return !active ? this.get('title').trim() + ' <span class="label label-danger">INACTIVE</span>' : this.get('title').trim();
  }),
  isSurchargeAfterTax: Ember.computed('tax_surcharge_config', function() {
    return this.get('tax_surcharge_config') === '3';
  }),
  isTaxAfterSurcharge: Ember.computed('tax_surcharge_config', function() {
    return this.get('tax_surcharge_config') === '2';
  }),
  tax_surcharge_config_display: Ember.computed('tax_surcharge_config', function() {
    var producttaxconfigs = this.get('applicationData.producttaxconfigs');
    var tax_surcharge_config = this.get('tax_surcharge_config');
    var producttaxconfig = producttaxconfigs.filter(function(confi) {
      return String(tax_surcharge_config) === confi.id;
    }).get('firstObject');
    return producttaxconfig ? producttaxconfig.title : '';
  }),
  vt_automatically_calculate_tax: Ember.computed('vt_override_sales_tax_allowed', function() {
    return this.get('vt_override_sales_tax_allowed');
  }),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
  location_billing_account: DS.belongsTo('locationbillingaccount'),
  product_ach_store: DS.belongsTo('productachstore'),
  sales_office: DS.belongsTo('salesoffice'),
  surcharge: DS.belongsTo('surcharge'),
});
