import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),
  crudFormFields: Ember.inject.service('crud-form-fields'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      helppage: store.findRecord('helppage', params.view_id),
      userTypes: store.findAll('usertype'),
      helppagelocations: store.query('helppagelocation', {
        help_page_id: params.view_id,
        expand: 'location.id,location.name',
        page_size: 500
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var helppage = models.helppage;
    controller.set('userTypes', models.userTypes);
    controller.set('helppage', helppage);
    controller.set('helppagelocations', models.helppagelocations);
    controller.set('helppagelocation', this.get('store').createRecord('helppagelocation', {
      help_page_id: models.helppage.get('id'),
      include_children: false,
      location_id: null
    }));
    controller.set('crudParams', {
      formType: 'view',
      formModelName: 'Help Page',
      formHeaderProp: 'title',
      formFields: this.get('crudFormFields').getForModel('helppage'),
      buttonPermissions: {
        overall: 'v2-helppages-get',
        back: 'v2-helppages-get',
        cancel: 'v2-helppages-get',
        delete: 'v2-helppages-delete',
        edit: 'v2-helppages-put',
        save: 'v2-helppages-put'
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Help Page ● ' + helppage.get('title'),
      route_name: 'admin.helppages.view',
      route_id: helppage.get('id')
    });
    controller.set('pageTitle', 'View Help Page');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Help Pages',
      link: 'admin.helppages.index'
    }, {
      title: 'View Help Page',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.helppage, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
