import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    var ph = params[0];
    if (!ph) {
      return;
    }
    ph = ph.replace(/[^0-9]/g, '');
    ph = ph.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return ph;
  }
});