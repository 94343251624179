import Ember from 'ember';
import jQuery from 'jquery';
import envConfigFactory from './../config/esm/envConfigFactory';

export default {
  name: 'domain-branding',
  before: 'check-sigpad',

  toggleNavbarVisiblity: function (show) {
    Ember.run.later(function () {
      var navbar = jQuery('#navbar-container');
      var sitefooter = jQuery('.site-footer');
      if (show) {
        navbar.css('visibility', 'visible');
        sitefooter.css('visibility', 'visible');
      } else {
        navbar.css('visibility', 'hidden');
        sitefooter.css('visibility', 'hidden');
      }
    });
  },

  initialize: function (app) {
    app.deferReadiness();
    window.ENV = envConfigFactory.getEnv(window.UiENV.environment, window.UiENV);
    if (window.ENV.environment === 'development' && window.ENV.debug === 'verbose') { console.log('app->initializers->domain-branding:ENV: ', window.ENV); }

    var subDomain = window.location.host;
    subDomain = subDomain.split('.')[0];
    window.localStorage.sub_domain = subDomain;

    if (window.ENV.environment === 'development') {
      // window.ENV.APP.DOMAIN = "dev.payaconnect.com";
      window.ENV.APP.DOMAIN = "paya.dev.payaconnect.com";

    } else {
      window.ENV.APP.DOMAIN = window.location.hostname.replace(/\.beta\./, '.');
    }

    // Wait until all of the following promises are resolved
    if (window.localStorage.sub_domain) {
      var self = this;
      app.deferReadiness();
      self.toggleNavbarVisiblity(false);
      new Ember.RSVP.Promise(function () {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/domains/' + window.UiENV.APP.DOMAIN,
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
          },
          type: 'GET',
          timeout: 10000,
        }).then(function (domainBranding) {
          var fav = document.getElementById('favico');
          fav.href = (domainBranding.domain.fav_icon || 'favicon.ico') + '?' + new Date().getTime();
          if (domainBranding.domain.title) {
            document.title = domainBranding.domain.title;
          }
          app.register('main:domain-branding', domainBranding, {
            instantiate: false,
            singleton: true
          });
          app.inject('route', 'domainBranding', 'main:domain-branding');
          app.inject('controller', 'domainBranding', 'main:domain-branding');

          if (domainBranding.domain.custom_javascript) {
            try {
              jQuery('body').append(domainBranding.domain.custom_javascript);
            } catch (err) {
              console.log('Unable to run custom Javascript.');
            }
          }

          // Show navbar and sitefooter
          Ember.run.later(function () {
            self.toggleNavbarVisiblity(true);
          });

          // Set custom theme
          if (domainBranding.domain.custom_theme) {
            var theme = domainBranding.domain.custom_theme || 'default';
            document.getElementById('stylesheet_theme').href = '/css/themes/' + theme + '.css';
            window.sessionStorage.app_theme = theme;
          } else {
            window.sessionStorage.app_theme = 'default';
          }

          // Set custom css
          if (domainBranding.domain.custom_css) {
            document.getElementById('stylesheet_custom').innerHTML = domainBranding.domain.custom_css;
          }
          jQuery('#maintenance').hide();
          app.advanceReadiness();
        }, function (error, t) {
          jQuery('#loader').hide();
          if (error.status === 404) {
            jQuery('#invaliddomain').show();
          } else if (error.status === 406) {
            noty({
              text: '<div class="appRefresh hand">This application has been updated. Please click here to reload.</div>',
              layout: 'topCenter',
              type: 'warning',
              timeout: '0',
              closeWith: [],
              modal: true,
              killer: true
            });
          } else {
            if (t === 'timeout' || t === 'error') {
              jQuery('#maintenance').show();
              count_down(10);
            }
          }

          // Hide navbar and sitefooter
          Ember.run.later(function () {
            self.toggleNavbarVisiblity(false);
          });

          app.advanceReadiness();
        });
      });

    } else {
      app.advanceReadiness();
    }

  }
};
