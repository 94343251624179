import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  pacController: Ember.inject.controller('components/modals/productauthenticationcode'),
  pcController: Ember.inject.controller('components/modals/productcredentials'),
  ppController: Ember.inject.controller('components/modals/productportfolio'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,

  // controller properties
  isLoading: false,
  active: '1',
  queryParams: [
    'active'
  ],

  // Computed Properties
  showingDeleted: Ember.computed('active', function() {
    var active = this.get('active');
    if (active === '1') {
      return false;
    } else {
      return true;
    }
  }),

  // Actions
  actions: {
    activeProduct: function(index) {
      var products = this.get('products');
      var currentProduct = products.filter(function(product, i) {
        return index === i;
      }).get('firstObject');
      this.set('currentProduct', currentProduct);
    },
    addproduct: function(prod_type) {
      var location = this.get('location');
      this.transitionToRoute('locations.addproduct' + prod_type, location.id);
    },
    deleteproduct: function(item) {
      var currentProduct = this.get('currentProduct');
      var serviceType = '';
      switch(currentProduct.action) {
        case 'transaction':
        case 'transactiondetail':
          serviceType = 'Deposit Account';
          break;
        case 'file':
          serviceType = 'File Storage';
          break;
        case 'accountvault':
          serviceType = 'Account Vault';
          break;
        case 'recurring':
          serviceType = 'Recurring Billing';
          break;
      }
      var self = this;
      pretty_confirm('Are you sure you want to delete ' + item.get('title') + '?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        item.deleteRecord();
        item.save().then(function() {
          noty({
            text: serviceType + ' deleted successfully!'
          });
          item.unloadRecord();
          self.send('refreshData');
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          item.rollbackAttributes();
          noty({
            text: 'Error deleting ' + serviceType + '!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
          self.send('refreshData');
        });
      });
    },
    editproduct: function(prod_type, product_id) {
      var location = this.get('location');
      this.transitionToRoute('locations.viewproduct' + prod_type, location.id, product_id, {
        queryParams: {
          edit: true
        }
      });
    },
    goto: function(product, area) {
      var self = this;
      var route = '';
      switch(area) {
        case 'billing':
          route = 'locations.viewproducttransaction-billing';
          break;
        case 'migrate':
          route = 'locations.migrateproducttransaction';
          break;
        case 'receipts':
          route = self.get('currentProduct.action') === 'transactiondetail' ? 'locations.viewproducttransactiondetail-receipts' : 'locations.viewproducttransaction-receipts';
          break;
        case 'surcharge':
          route = self.get('currentProduct.action') === 'transactiondetail' ? 'locations.viewproducttransactiondetail-surcharge' : 'locations.viewproducttransaction-surcharge';
          break;
      }
      this.transitionToRoute(route, this.get('location.id'), product.get('id'));
    },
    hideDeleted: function() {
      this.set('active', '1');
      this.send('refreshData');
    },
    launchModal: function(product, modal, endpoint) {
      if(modal === 'gateway') {
        var productcreds = this.get('pcController');
        productcreds.set('producttransactioncredential', null);
        productcreds.set('product', null);
        productcreds.set('product', product);
        productcreds.set('isEditing', false);
        this.send('openModal', 'components/modals/productcredentials');
      } else if(modal === 'portfolio') {
        var portfolio = this.get('ppController');
        portfolio.set('product', null);
        portfolio.set('endpoint', null);
        portfolio.set('portfolio_name', null);
        portfolio.set('product', product.get('id'));
        portfolio.set('endpoint', 'product' + endpoint);
        portfolio.set('isEditing', false);
        this.send('openModal', 'components/modals/productportfolio');
      } else if(modal === 'reregister') {
        var productauthcodes = this.get('pacController');
        productauthcodes.set('producttransactionauthenticationcode', null);
        productauthcodes.set('product', null);
        productauthcodes.set('product', product);
        this.send('openModal', 'components/modals/productauthenticationcode');
      }
    },
    showDeleted: function() {
      this.set('active', '0,1');
      this.send('refreshData');
    },
    viewproduct: function(prod_type, product_id) {
      var location = this.get('location');
      this.transitionToRoute('locations.viewproduct' + prod_type, location.id, product_id);
    },
    reActivateProduct: function(product, action) {
      var self = this;
      var action_type = 'product' + action + 's';
      var serviceType = '';
      switch(action) {
        case 'transaction':
        case 'transactiondetail':
          serviceType = 'Deposit Account';
          break;
        case 'file':
          serviceType = 'File Storage';
          break;
        case 'accountvault':
          serviceType = 'Account Vault';
          break;
        case 'recurring':
          serviceType = 'Recurring Billing';
          break;
      }
      if (self.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      var session = this.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/' + action_type + '/' + product.get('id') + '/activate',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        noty({
          text: serviceType +  ' activation successful!'
        });
        self.send('refreshData');
        self.set('isLoading', false);
      }, function() {
        self.send('refreshData');
        self.set('isLoading', false);
        noty({
          text: serviceType + ' activation failed!',
          type: 'error'
        });
      });
    },
  }
});
