import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('developercompany.errors.messages', function() {
    this.set('errorMessages', this.get('developercompany.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminDeveloperCompanies: true,
  isLoading: false,
  isEditing: false,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('admin.developercompanies.index');
    },
    edit: function() {
      this.set('isEditing', true);
    },
    delete: function() {
      var self = this;
      var developercompany = this.get('developercompany');
      pretty_confirm('Are you sure want to delete this Developer Company?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        developercompany.deleteRecord();
        developercompany.save().then(function() {
          developercompany.unloadRecord();
          noty({
            text: 'Developer Company deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.developercompanies.index');
        }, function(err) {
          developercompany.rollbackAttributes();
          var errorMessage = 'Error deleting Developer Company!';
          if(err.errors) {
            err.errors.forEach(function(e){
              errorMessage += '<br>' + e.detail;
            });
          }
          noty({
            text: errorMessage,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var developercompany = this.get('developercompany');

      developercompany.save().then(function() {
        noty({
          text: 'Developer company updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.transitionToRoute('admin.developercompanies.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    cancel: function() {
      var developercompany = this.get('developercompany');
      developercompany.rollbackAttributes();
      this.set('isEditing', false);
    }
  }
});
