import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  selectedTerminalLine: null,
  errorMessages: null,
  updateTransMessages: Ember.observer('terminal.errors.[]', 'terminal.errors.messages', function() {
    this.set('errorMessages', this.get('terminal.errors.messages'));
  }),

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    if (queryParams) {
      queryParams.forEach(function(param) {
        self.set(param, parent.get(param));
        self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
      });
      self.updateQueryParamsOnParent();
    }
    this._super(...arguments);
  },
  didInsertElement: function() {
    var route = this.get('route');
    if (route === 'locations.terminals-add' || route === 'admin.terminals.add') {
      this.set('isEditing', true);
      var locationID = this.get('location.id') || null;
      var modelType = '';
      if (route === 'locations.terminals-add') {
        modelType = 'terminal';
      } else if (route === 'admin.terminals.add') {
        modelType = 'admin_terminal';
      }
      var terminal = this.get('store').createRecord(modelType, {
        active: true,
        cashback_enable: false,
        default_checkin: 'yesterday',
        default_checkout: 'today',
        default_room_number: '100',
        default_room_rate: '95',
        location_id: locationID,
        print_enable: false,
        sig_capture_enable: false,
        tip_enable: false,
        terminal_timeouts: {
          card_entry_timeout: '120',
          device_terms_prompt_timeout: '60',
          overall_timeout: '300',
          pin_entry_timeout: '30',
          signature_input_timeout: '10',
          signature_submit_timeout: '30',
          status_display_time: '7',
          tip_cashback_timeout: '30',
          transaction_timeout: '10',
        }
      });
      this.set('terminal', terminal);
    } else { // on view
      if (this.get('isTetraAndTeliumAvailable')) {
        var selectedTerminalLine = null;
        var terminalCVMTitle = this.get('terminal.terminal_cvm.title');
        if (terminalCVMTitle && terminalCVMTitle.toLowerCase().indexOf('telium') >= 0) {
          selectedTerminalLine = 'telium';
        } else if (terminalCVMTitle && terminalCVMTitle.toLowerCase().indexOf('tetra') >= 0) {
          selectedTerminalLine = 'tetra';
        }
        this.set('selectedTerminalLine', selectedTerminalLine);
      }
    }
    if (!this.get('terminal.terminal_timeouts')) {
      this.set('terminal.terminal_timeouts', {});
    }

    if (this.get('isRouteAdmin')) {
      this.set('formPrivs', {
        canDelete: this.get('session.authenticated.resources.v2admin-terminals-delete'),
        canPost: this.get('session.authenticated.resources.v2admin-terminals-post'),
        canPut: this.get('session.authenticated.resources.v2admin-terminals-put'),
      });
    } else {
      this.set('formPrivs', {
        canDelete: this.get('session.authenticated.resources.v2-terminals-delete'),
        canPost: this.get('session.authenticated.resources.v2-terminals-post'),
        canPut: this.get('session.authenticated.resources.v2-terminals-put'),
      });
    }
    this.set('isTransitioning', false);
  },

  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    if (queryParams) {
      queryParams.forEach(function(param) {
        self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
      });
    }

    var model = this.get('terminal');
    if(model.get('isDestroyed') || model.get('isDestroying')) {
      return;
    }
    if (model.get('hasDirtyAttributes') && model.get('isNew')) {
      model.unloadRecord();
    } else if (model.get('hasDirtyAttributes')) {
      model.rollbackAttributes();
    }
    this.set('isTransitioning', false);
  },

  // Properties

  created_ts: 'today',
  devicelogactions: [],

  // Computed Properties

  // Set data for pager component
  pagerParams: Ember.computed('terminal.id', 'needsRefresh', function() {
    return {
      pageRoute: this.get('isRouteAdmin') ? 'admin.terminals.view' : 'locations.terminals-view',
      pageModelId: this.get('terminal.id'),
      modelName: this.get('isRouteAdmin') ? 'admin_terminaldevicelog' : 'terminaldevicelog'
    };
  }),
  gridParams: Ember.computed('terminal.id', function() {
    return {
      id: this.get('terminal.id'),
      expand: 'device_log_action'
    };
  }),

  available_terminalcvms: Ember.computed('terminalcvms.[]', 'selectedTerminalLine', 'terminal.terminal_manufacturer_id', function() {
    var self = this;
    var cvms = this.get('terminalcvms');
    var selectedTerminalLine = this.get('selectedTerminalLine');
    var terminal_manufacturer_id = this.get('terminal.terminal_manufacturer_id');
    if (cvms && cvms.get('length')) {
      return cvms.filter(function(cvm) {
        if (self.get('isTetraAndTeliumAvailable')) {
          return cvm.get('title').toLowerCase().indexOf(selectedTerminalLine) >= 0 && Number(terminal_manufacturer_id) === Number(cvm.get('terminal_manufacturer.id'));
        } else {
          return terminal_manufacturer_id === cvm.get('terminal_manufacturer.id');
        }
      });
    }
  }),
  canUtilizeTimeouts: Ember.computed('isTetraAndTeliumAvailable', 'terminal.terminal_manufacturer_id', function() {
    return this.get('isTetraAndTeliumAvailable') || this.get('terminal.terminal_manufacturer_id') === '100';
  }),
  detailSections: Ember.computed(function() {
    var session = this.get('session');
    var isNewTerminal = this.get('terminal.isNew');
    return [{
      name: 'Detail',
      heading: 'heading0',
      group: 'group0',
      link: '#group0',
      action: 'detail',
      active: true,
      canView: true,
    }, {
      name: 'Device Logs',
      heading: 'heading2',
      group: 'group2',
      link: '#group2',
      action: 'devicelogs',
      active: false,
      canView: !isNewTerminal && ((this.get('isRouteAdmin') && session.get('authenticated.resources.v2admin-terminals-get-devicelogs')) || (!this.get('isRouteAdmin') && session.get('authenticated.resources.v2-terminals-get-devicelogs')))
    }, {
      name: 'Change Logs',
      heading: 'heading3',
      group: 'group3',
      link: '#group3',
      action: 'changelogs',
      active: false,
      canView: !isNewTerminal
    }];
  }),
  devicelogcolumns: [{
    'name': 'device_log_action_id',
    'title': 'Device Log<br>Action',
    'type': 'string',
    'sortable': true,
    'filter': {
      type: 'dropdown',
      items: 'devicelogactions',
      multi: true,
    }
  }, {
    'name': 'created_ts',
    'title': 'Created<br>Date',
    'type': 'shortdate',
    'sortable': true,
    'filter': {
      type: 'dropdown',
      style: 'min-width: 200px',
      items: 'dateFiltersTransaction',
      multi: false,
      showTimeFilter: true
    }
  }],
  hasTerminalActionPrivs: Ember.computed(function() {
    var session = this.get('session');
    var terminal = this.get('terminal');
    var canClearDatabase = terminal ? session.get('authenticated.resources.v2-terminals-post-cleardatabase') && terminal.get('canClearDatabase') : false;
    var canIsonline = session.get('authenticated.resources.v2-terminals-post-isonline');
    var canReboot = session.get('authenticated.resources.v2-terminals-post-reboot');
    var canupdate = session.get('authenticated.resources.v2-terminals-post-update');
    return canClearDatabase || canIsonline || canReboot || canupdate;
  }),
  isIngenicoTeliumOrTetra: Ember.computed('isTetraAndTeliumAvailable', 'selectedTerminalLine', function() {
    return this.get('isTetraAndTeliumAvailable') && this.get('selectedTerminalLine');
  }),
  isIngenicoTerminalManufacturer: Ember.computed('terminal.terminal_manufacturer_id', function() {
    var terminalmanufacturerID = this.get('terminal.terminal_manufacturer_id');
    var terminalmanufacturers = this.get('terminalmanufacturers');
    var terminalmanufacturer;
    if (terminalmanufacturers) {
      terminalmanufacturers.map(function(term) {
        if (terminalmanufacturerID === term.get('id')) {
          terminalmanufacturer = term.get('title');
        }
      });
    }
    return terminalmanufacturer && (terminalmanufacturer.toLowerCase().indexOf('ingenico') >= 0 || terminalmanufacturerID === '100');
  }),
  isLoadingOrisEditing: Ember.computed('isLoading', 'isEditing', function() {
    return this.get('isLoading') || this.get('isEditing');
  }),
  isRouteAdmin: Ember.computed('route', function() {
    return this.get('route').indexOf('admin') !== -1;
  }),
  isTetraAndTeliumAvailable: Ember.computed('terminalcvms.[]', 'terminal.terminal_manufacturer_id', function() {
    var cvms = this.get('terminalcvms');
    var terminal_manufacturer_id = this.get('terminal.terminal_manufacturer_id');
    if (cvms && cvms.get('length')) {
      var tempcvms = cvms.filter(function(cvm) {
        return terminal_manufacturer_id === cvm.get('terminal_manufacturer.id');
      });
      var hasTetra = false;
      var hasTelium = false;
      tempcvms.map(function(cvm) {
        if (cvm.get('title').toLowerCase().indexOf('tetra') >= 0) {
          hasTetra = true;
        }
        if (cvm.get('title').toLowerCase().indexOf('telium') >= 0) {
          hasTelium = true;
        }
      });
      return hasTelium && hasTetra;
    } else {
      return false;
    }
  }),
  locationTitle: Ember.computed('isRouteAdmin', 'location.id', function() {
    if (this.get('isRouteAdmin')) {
      return '';
    } else {
      return this.get('location.title');
    }
  }),
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  privPOST: Ember.computed('isRouteAdmin', function() {
    return this.get('isRouteAdmin') ? this.get('session.authenticated.resources.v2admin-terminals-post') : this.get('session.authenticated.resources.v2-terminals-post');
  }),
  privPUT: Ember.computed('isRouteAdmin', function() {
    return this.get('isRouteAdmin') ? this.get('session.authenticated.resources.v2admin-terminals-put') : this.get('session.authenticated.resources.v2-terminals-put');
  }),
  showLocationTypeahead: Ember.computed('isRouteAdmin', 'terminal.isNew', function() {
    return this.get('isRouteAdmin') && this.get('terminal.isNew');
  }),
  terminalApplicationPrintEnabled: Ember.computed('terminal.terminal_application_id', function() {
    return this.terminalApplicationFeatureEnabled('print_capable');
  }),
  terminalApplicationSigCaptureEnabled: Ember.computed('terminal.terminal_application_id', function() {
    return this.terminalApplicationFeatureEnabled('sig_capture_capable');
  }),
  terminalApplicationTitle: Ember.computed('terminal.terminal_application_id', 'terminalapplications.[]', function() {
    return this.getTitle(this.get('terminalapplications'), this.get('terminal.terminal_application_id'));
  }),
  terminalCVMTitle: Ember.computed('terminal.terminal_cvm_id', 'available_terminalcvms.[]', function() {
    return this.getTitle(this.get('available_terminalcvms'), this.get('terminal.terminal_cvm_id'));
  }),
  terminalManufacturerTitle: Ember.computed('terminal.terminal_manufacturer_id', 'terminalmanufacturers.[]', function() {
    return this.getTitle(this.get('terminalmanufacturers'), this.get('terminal.terminal_manufacturer_id'));
  }),

  // Observers

  currentSectionObserver: Ember.observer('currentSection.action', function() {
    var self = this;
    var currentSection = this.get('currentSection.action');
    if (currentSection === 'devicelogs') {
      this.getDeviceLogActions().then(function() {
        self.getDeviceLogs();
      }, function() {
        noty({
          text: 'Error getting Device Logs!',
          type: 'error'
        });
      });
    }
  }),

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
    if (this.get('currentSection') === 'devicelogs') {
      this.set('needsRefresh', new moment().unix());
    }
  },

  terminalApplicationIdObserver: Ember.observer('terminal.terminal_application_id', function() {
    var terminalapplicationID = this.get('terminal.terminal_application_id');
    var terminalapplications = this.get('terminalapplications');
    var terminalapplication;
    if (this.get('terminalapplications.length') && terminalapplicationID && this.get('terminal')) {
      terminalapplications.map(function(term) {
        if (terminalapplicationID === term.get('id')) {
          terminalapplication = term;
        }
      });
      if (!terminalapplication.get('sig_capture_capable')) {
        this.set('terminal.sig_capture_enable', false);
      }
      if (!terminalapplication.get('print_capable')) {
        this.set('terminal.print_enable', false);
      }
    }
  }),
  terminalLineObserver: Ember.observer('selectedTerminalLine', 'terminal.terminal_cvm.title', function() {
    var title = this.get('terminal.terminal_cvm.title');
    if (!this.get('terminal.terminal_cvm_id')) {
      title = null;
    }
    if (title) {
      title = title.toLowerCase();
    }
    var selectedTerminalLine = this.get('selectedTerminalLine');
    if (selectedTerminalLine) {
      selectedTerminalLine = selectedTerminalLine.toLowerCase();
    }
    if (selectedTerminalLine && title && title.indexOf(selectedTerminalLine) === -1) {
      this.set('terminal.terminal_cvm_id', null);
    }
  }),
  terminalManufacturerObserver: Ember.observer('terminal.terminal_manufacturer_id', 'available_terminalcvms.[]', function() {
    var terminal_manufacturer_id = this.get('terminal.terminal_manufacturer_id');
    var terminal_cvm_id = this.get('terminal.terminal_cvm_id');
    var cvms = this.get('terminalcvms');
    if (this.get('terminal') && this.get('available_terminalcvms.length') < 1) {
      this.set('terminal.terminal_cvm_id', null);
    }
    if (cvms && cvms.get('length')) {
      var selectedCVM = cvms.filter(function(cvm) {
        return terminal_cvm_id === cvm.get('id');
      }).get('firstObject');
      if (selectedCVM && Number(terminal_manufacturer_id) !== Number(selectedCVM.get('terminal_manufacturer_id'))) {
        this.set('terminal.terminal_cvm_id', null);
      }
    }
  }),

  // Functions

  getDeviceLogs: function() {
    if (!this.get('isDestroying') && !this.get('isDestroyed')) {
      this.get('parent').send('gridNeedsRefresh', this);
    }
  },
  getDeviceLogActions: function() {
    var self = this;
    var hasAccess = this.get('isRouteAdmin') ? this.get('session.authenticated.resources.v2admin-terminals-get-devicelogs') : this.get('session.authenticated.resources.v2-terminals-get-devicelogs');
    var devicelogactionsArray = this.get('devicelogactions');
    return new Ember.RSVP.Promise(function(resolve) {
      if (hasAccess && (!devicelogactionsArray || devicelogactionsArray.get('length') === 0)) {
        return self.get('store').query('devicelogaction', {
          page_size: '100'
        }).then(function(res) {
          if (!self.get('isDestroying') && !self.get('isDestroyed')) {
            self.set('devicelogactions', res);
          }
          resolve();
          return true;
        });
      } else if (!hasAccess) {
        if (!self.get('isDestroying') && !self.get('isDestroyed')) {
          self.set('devicelogactions', []);
        }
        resolve();
        return false;
      }
    });
  },
  getTitle: function(arr, id) {
    var title = '';
    if (arr && arr.get('length')) {
      arr.forEach(function(item) {
        if (item.get('id') === id) {
          title = item.get('title');
        }
      });
    }
    return title;
  },
  terminalApplicationFeatureEnabled: function(feature) {
    var terminalapplicationID = this.get('terminal.terminal_application_id');
    var terminalapplications = this.get('terminalapplications');
    var terminalapplication;
    if (this.get('terminalapplications.length')) {
      terminalapplications.map(function(term) {
        if (terminalapplicationID === term.get('id')) {
          terminalapplication = term;
        }
      });
    }
    return terminalapplication ? terminalapplication.get(feature) : false;
  },

  // Actions
  actions: {
    activeSection: function(index) {
      var detailSections = this.get('detailSections');
      var currentSection = detailSections.filter(function(section, i) {
        return index === i;
      }).get('firstObject');
      this.set('isChangeLogTab', currentSection.action === 'changelogs');
      this.set('isDeviceLogTab', currentSection.action === 'devicelogs');
      this.set('currentSection', currentSection);
    },
    back: function() {
      if(this.get('isTransitioning')) {
        return;
      }
      var route = this.get('route');
      var router = this.get('router');
      if (route === 'locations.terminals-add' || route === 'locations.terminals-view') {
        router.transitionTo('locations.terminals', this.get('terminal.location_id'));
      } else if (route === 'admin.terminals.add' || route === 'admin.terminals.view') {
        router.transitionTo('admin.terminals.index');
      }
    },
    cancel: function() {
      var terminal = this.get('terminal');
      terminal.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function() {
      var self = this;
      var terminal = this.get('terminal');
      var route = this.get('route');
      var router = this.get('router');
      pretty_confirm('Are you sure you want to delete this Terminal?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        terminal.deleteRecord();
        terminal.save().then(function() {
          terminal.unloadRecord();
          noty({
            text: 'Terminal deleted successfully!'
          });
          self.set('isLoading', false);
          if (route === 'locations.terminals-view') {
            router.transitionTo('locations.terminals', terminal.get('location_id'));
          } else if (route === 'admin.terminals.view') {
            router.transitionTo('admin.terminals.index');
          }
        }, function() {
          terminal.rollbackAttributes();
          noty({
            text: 'Error deleting Terminal!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    launchAction: function(actionName) {
      var self = this;
      var session = this.get('session');
      var message = '';
      switch (actionName) {
        case 'isonline':
          message = 'Is Online';
          break;
        case 'cleardatabase':
          message = 'Clear Database';
          break;
        case 'reboot':
          message = 'Reboot';
          break;
        case 'update':
          message = 'Update';
          break;
      }
      pretty_confirm('Are you sure you want to send "' + message + '" command to the Terminal?', function() {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/terminals/' + self.get('terminal.id') + '/' + actionName,
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          self.get('parent').send('refreshData');
          noty({
            text: message + ' command successful!'
          });
        }, function(err) {
          noty({
            text: err.responseJSON.result_text,
            type: 'error'
          });
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var terminal = this.get('terminal');
      var route = this.get('route');
      var router = this.get('router');

      terminal.save().then(function() {
        noty({
          text: 'Terminal saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        if (route === 'locations.terminals-add' || route === 'locations.terminals-view') {
          router.transitionTo('locations.terminals-view', terminal.get('location_id'), terminal.get('id'));
        } else if (route === 'admin.terminals.add' || route === 'admin.terminals.view') {
          router.transitionTo('admin.terminals.view', terminal.get('id'));
        }
        self.set('terminaltimeouterrors', {});
      }, function(err) {
        var errors = err.errors;
        if(errors && errors.get('length')) {
          var terminaltimeouterrors = {};
          errors.forEach(function(e) {
            if (e.source.pointer === 'data/attributes/terminal_timeouts') {
              terminaltimeouterrors[e.source.prop] = true;
            }
          });
          self.set('terminaltimeouterrors', terminaltimeouterrors);
        }
        self.set('isLoading', false);
      });
    },
    updateLocation: function(data) {
      var self = this;
      var location = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10',
        fields: 'id,name'
      });
      location.then(function(result) {
        self.set('locations', result);
      });
    },
  }

});
