import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('terminalapplication.errors.messages', function() {
    this.set('errorMessages', this.get('terminalapplication.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,

  // Actions
  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var terminalapplication = this.get('terminalapplication');
      if (!terminalapplication.get('title')) {
        this.set('errorMessages', ['Title cannot be blank.']);
        self.set('isLoading', false);
        return;
      }

      terminalapplication.save().then(function() {
        noty({
          text: 'Terminal Application added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.terminalapplications.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.terminalapplications.index');
    }
  }
});
