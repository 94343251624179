import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      belongsToLocations: store.query('locationinfo', {
        relationship: 'direct',
        expand: 'locations',
        page_size: '100'
      }),
      belongsToAll: store.query('locationinfo', {
        relationship: 'all',
        page_size: '100'
      })
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfo: self.get('store').queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
        }),
      });
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'contacts.files',
      pageModelId: models.contact.get('id'),
      modelName: 'file'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,visibility_group,product_file,file_category',
      resource_id: models.contact.get('id'),
      resource: 'Contact'
    };
    controller.set('gridParams', gridParams);
    var belongsToLocations = models.belongsToLocations.filter(function(item) {
      return item.get('product_file.id');
    });
    controller.set('contact', models.contact);
    controller.set('file_resource', {
      resource: 'Contact',
      resource_id: transition.to.params.id
    });
    controller.set('locationinfo', models.locationinfo);
    controller.set('belongsToLocations', belongsToLocations);
    controller.set('belongsToAll', models.belongsToAll);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Files',
      route_name: 'contacts.files',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Files',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
