import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsFileCategories: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('filecategory.errors.messages', function() {
    this.set('errorMessages', this.get('filecategory.errors.messages'));
  }),

  // Set controller params
  isLoading: false,

  // Actions
  actions: {
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    changeVisibilities: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var filecategory = this.get('filecategory');

      filecategory.save().then(function(res) {
        noty({
          text: 'File Category saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.editfilecategory', self.get('location.id'), res.get('id'));
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.filecategories', location.get('id'));
    }
  }
});
