import Ember from 'ember';
/*
	Displays message about which time zone is being use in reports
*/

export default Ember.Helper.extend({
  compute(params) {
    var sessionTZ = params[0];
    if (!sessionTZ) {
      return 'UTC';
    } else {
      return sessionTZ;
    }
  }
});