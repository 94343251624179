import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  helperFunction: Ember.inject.service("helper-functions"),

  TITLE:"Refund Transaction",

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  didInsertElement: function() {
    jQuery('#modal').modal({
      backdrop: 'static'
    });
    if (this.get('model') === 'userreport') {
      jQuery(document).on('shown.bs.modal', '#' + this.$().attr('id'), function() {
        jQuery('#userreportTitle').focus();
      });
    }
    if (this.get('model') === 'userreportshare') {
      jQuery(document).on('shown.bs.modal', '#' + this.$().attr('id'), function() {
        jQuery('#reportshare_model_id_autocomplete').focus();
      });
    }
  },
  willDestroyElement: function() {
    jQuery('#modal').data('modal', null);
    jQuery('.modal-backdrop').remove();
    jQuery('body').removeClass('modal-open');
    if (this.get('model') === 'userreport' || this.get('model') === 'userreportshare') {
      jQuery(document).off('shown.bs.modal', '#' + this.$().attr('id'));
    }
  },

  // Computed Properties
  modalTitleId: Ember.computed('titleId', function(){
    return 'modal_' + this.get('titleId');
  }),

  // Actions
  actions: {
    closeModal: function() {
      if (this.get('isLoading')) {
        return;
      }
      if (this.get('rollbackAttributes') === 'true') {
        this.get('model').rollbackAttributes();
      }
      var onClose = this.get('onClose');
      if (onClose) {
        onClose(this.get('parent'));
      }
      const parent = this.get('parent');
      /**
       * PCONN-578 comments below:
       * Setting only @transactions variable to empty array. This should stop multiple api calls for single quickinvoice payments.
       * Setting @showOnMultiPayment to false is stopping this ember computed property from being recalculated.
       * @showOnMultiPayment is still needed so that we are setting transactions array empty only when required.
       */

      const showOnMultiPayment = parent.get('showOnMultiPayment');
      if (typeof showOnMultiPayment !== undefined && showOnMultiPayment) {
        this.get('parent').set('transactions', []);
      }
      var transactiondetailFullController = this.get('parent').get('transactiondetailFullController');
      if(transactiondetailFullController) {
        transactiondetailFullController.set('updateRefundHistory', true);
      }
      this.helperFunction.refreshGrid();

      this.get('parent').send('closeModal');
    },
    fullDetail: function() {
      this.fullDetail();
    }
  }
});
