import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    created_user: {
      embedded: 'always'
    },
    account_vault: {
      serialize: false,
      deserialize: 'records'
    },
    transactions: {
      serialize: false,
      deserialize: 'records'
    },
    location: {
      serialize: false,
      deserialize: 'records'
    },
    contact: {
      serialize: false,
      deserialize: 'records'
    },
    tags: {
      embedded: 'always'
    },
    signature: {
      serialize: false,
      deserialize: 'records'
    },
    product_transaction: {
      serialize: false,
      deserialize: 'records'
    },
    changelogs: {
      serialize: false,
      deserialize: 'records'
    }
  }
});