import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminUsers: true,
  isLocationsNotificationTemplate: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'template_name',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'subject',
    'template_name',
    'title',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      template_name: {
        'name': 'template_name',
        'title': 'Template Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      subject: {
        'name': 'subject',
        'title': 'Subject',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(notificationtemplate) {
          self.send('edit', notificationtemplate);
        },
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(notificationtemplate) {
          self.send('delete', notificationtemplate);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'template_name', 'subject', 'created_ts', 'delete', 'edit'];
    var listOfMobileCols = ['title', 'template_name', 'subject', 'created_ts', 'delete', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    edit: function(notificationtemplate) {
      var location = this.get('location');
      this.transitionToRoute('locations.editnotificationtemplate', location.get('id'), notificationtemplate.get('id'));
    },
    delete: function(notificationtemplate) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Notification Template?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        notificationtemplate.deleteRecord();
        notificationtemplate.save().then(function() {
          noty({
            text: 'Notification Template deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          notificationtemplate.rollbackAttributes();
          noty({
            text: 'Error deleting Notification Template!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
