import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  body: DS.attr('string'),
  created_ts: DS.attr('ts'),
  priority: DS.attr('number'),
  subject: DS.attr('string'),
  user_criteria: DS.attr('array'),
});
