import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  batch_close_ts: DS.attr('ts'),
  batch_num: DS.attr('string'),
  created_ts: DS.attr('ts'),
  is_open: DS.attr('boolean'),
  location_id: DS.attr('string'),
  processing_status_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  settlement_file_name: DS.attr('string'),

  // Relationships
  location: DS.belongsTo('location'),
  processing_status: DS.belongsTo('processingstatus', {
    async: true
  }),
  product_transaction: DS.belongsTo('producttransaction'),
});
