import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('authrole.errors.messages', function() {
    this.set('errorMessages', this.get('authrole.errors.messages'));
  }),
  errorMessagesLogos: null,

  // Set the booleans for navigation classes
  isAdminRoles: true,

  // Set controller params
  isEditing: null,
  isLoading: false,
  selectedResources: null,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('admin.roles.index');
    },
    cancel: function() {
      var authrole = this.get('authrole');
      authrole.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function() {
      var self = this;
      var authrole = this.get('authrole');
      pretty_confirm('Are you sure you want to delete this Role?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        authrole.deleteRecord();
        authrole.save().then(function() {
          authrole.unloadRecord();
          noty({
            text: 'Role deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.roles.index');
        }, function() {
          authrole.rollbackAttributes();
          noty({
            text: 'Error deleting Role!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    editResources: function() {
      this.transitionToRoute('admin.roles.grid', {
        queryParams: {
          editThisRole: this.get('authrole.id')
        }
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var authrole = this.get('authrole');

      authrole.save().then(function() {
        noty({
          text: 'Authrole saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    },
  }
});
