import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/mailboxmessages/view', {
      controller: 'admin/mailboxmessages/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      mailboxmessage: store.createRecord('mailboxmessage', {
        user_criteria: {
          user_id: '',
          user_type_id: '',
          developer_id: '',
          location_id: '',
          relationship: ''
        }
      }),
      userTypes: store.findAll('usertype')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('mailboxmessage', models.mailboxmessage);
    controller.set('userTypes', models.userTypes.filter(function(type){
      return type.id !== '0';
    }));
    controller.set('isCriteria', {
      user: true,
      developer: false,
      location: false
    });
    controller.set('selectedUsers', []);
    controller.set('selectedUserTypes', []);
    controller.set('selectedDevelopers', []);
    controller.set('selectedLocations', []);
    controller.set('selectedLocationRelationships', []);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-mailboxmessages-delete'),
      canPost: this.get('session.authenticated.resources.v2-mailboxmessages-post'),
      canPut: this.get('session.authenticated.resources.v2-mailboxmessages-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Mailbox Message',
      route_name: 'admin.mailboxmessages.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Mailbox Message');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Mailbox Messages',
      link: 'admin.mailboxmessages.index'
    }, {
      title: 'Add Mailbox Message',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel;
      if (model.mailboxmessage.get('hasDirtyAttributes')) {
        model.mailboxmessage.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
