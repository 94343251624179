import Application from './application';

export default Application.extend({
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  attrs: {
    user_type: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
