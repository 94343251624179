import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (typeof value === 'undefined' || value === null) {
      return null;
    }
    return Boolean(value);
  },
  deserialize: function(value) {
    var type = typeof value;

    if (type === "boolean") {
      return value;
    } else if (type === "string") {
      return value.match(/^true$|^t$|^1$/i) !== null;
    } else if (type === "number") {
      return value === 1;
    } else {
      return null;
    }
  }
});