import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  classNames: 'ember-scroll-to-top',

  didInsertElement: function() {
    jQuery(document).on('scroll', () => {
      this.onWindowScroll.call(this);
    });
    var self = this;
    Ember.run.later(function() {
      self.set('showScroll', true);
    }, 1000);
    this.onWindowScroll();
  },

  onWindowScroll: function() {
    var isMobileSize = this.get('isMobileSize');
    var element = this.get('element');
    var clientHeight = jQuery('body')[0].clientHeight || 201;
    var scrollOffsetHeight = (clientHeight > 200 ? clientHeight : 200) / (isMobileSize ? 6 : 4);
    if (jQuery(window).scrollTop() > scrollOffsetHeight) {
      jQuery(element).fadeIn('slow');
    } else {
      jQuery(element).fadeOut('slow');
    }
  },

  actions: {
    scrollToTop() {
      jQuery('html, body').animate({
        scrollTop: 0
      });
    }
  }
});
