import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsViewUser: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('contact.errors.messages', function() {
    this.set('errorMessages', this.get('contact.errors.messages'));
  }),
  errorMessagesUser: null,
  updateErrorMessagesUser: Ember.observer('user.errors.messages', function() {
    this.set('errorMessagesUser', this.get('user.errors.messages'));
  }),

  isEditing: false,

  // Define actions for the template
  actions: {
    edit_username: function() {
      this.set('isEditing', true);
    },
    cancel: function() {
      this.set('isEditing', false);
    },
    save_username: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var user = this.get('user');
      var username = this.get('user.username');
      var session = this.get('session');

      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/users/' + user.get('id'),
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        type: 'PUT',
        data: JSON.stringify({
          'user': {
            'username': username
          }
        })
      }).then(function() {
        noty({
          text: 'Username changed successfully!'
        });
        self.set('isLoading', false);
        window.scrollTo(0, 0);
        self.set('isEditing', false);
      }, function(err) {
        user.get('errors').add('username', err.responseJSON.errors.username);
        self.set('isLoading', false);
      });
    },
  }
});
