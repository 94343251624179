import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (typeof value === 'undefined' || value === null || value.indexOf('*') !== -1) {
      return;
    } else {
      return value;
    }
  },
  deserialize: function(value) {
    return value;
  }
});
