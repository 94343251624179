import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  canAccountVault: Ember.computed('location.{id,hasProductAccountVault}', 'locations.[]', function() {
    if(this.get('location.id')) {
      return this.get('location.hasProductAccountVault');
    } else {
      var locations = this.get('locations');
      var hasProductAccountVault = false;
      if(locations && locations.get('length')) {
        locations.map(function(loc){
          if(loc.get('hasProductAccountVault')) {
            hasProductAccountVault = true;
          }
        });
        return hasProductAccountVault;
      } else {
        return false;
      }
    }
  }),
  canQuickInvoice: Ember.computed('location.{id,hasQuickInvoice}', 'locations.[]', function() {
    if(this.get('location.id')) {
      return this.get('location.hasQuickInvoice');
    } else {
      var locations = this.get('locations');
      var hasQuickInvoice = false;
      if(locations && locations.get('length')) {
        locations.map(function(loc){
          if(loc.get('hasQuickInvoice')) {
            hasQuickInvoice = true;
          }
        });
        return hasQuickInvoice;
      } else {
        return false;
      }
    }
  }),
  canRecurring: Ember.computed('location.{id,hasProductRecurring}', 'locations.[]', function() {
    if(this.get('location.id')) {
      return this.get('location.hasProductRecurring');
    } else {
      var locations = this.get('locations');
      var hasProductRecurring = false;
      if(locations && locations.get('length')) {
        locations.map(function(loc){
          if(loc.get('hasProductRecurring')) {
            hasProductRecurring = true;
          }
        });
        return hasProductRecurring;
      } else {
        return false;
      }
    }
  }),
  isLoadingOrEditing: Ember.computed('isLoading', 'isEditing', function() {
    return this.get('isLoading') || this.get('isEditing');
  }),

  showLinks: Ember.computed('canAccountVault', 'canQuickInvoice', 'canRecurring', 'session.authenticated.resources.v2-contacts-post', function() {
    return this.get('session.authenticated.resources.v2-contacts-post') || this.get('canAccountVault') || this.get('canQuickInvoice') || this.get('canRecurring');
  }),

  // Functions

  runTransition: function(route, location_id) {
    if(location_id) {
      this.get('parent').transitionToRoute(route, location_id);
    } else {
      this.get('parent').transitionToRoute(route);
    }
  },

  // Actions
  actions: {
    add: function(modelName) {
      var self = this;
      var location_id = this.get('location.id');
      switch(modelName) {
        case 'account':
          return self.runTransition(location_id ? 'locations.accounts-add' : 'accounts.add', location_id);
        case 'contact':
          return self.runTransition(location_id ? 'locations.contacts-add' : 'contacts.add', location_id);
        case 'quickinvoice':
          return self.runTransition(location_id ? 'locations.quickinvoices-add' : 'quickinvoice.add', location_id);
        case 'recurring':
          return self.runTransition(location_id ? 'locations.recurrings-add' : 'recurrings.add', location_id);
      }
    }
  },
});
