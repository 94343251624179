import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  cron_time_string: DS.attr('string'),
  schedule_data: DS.attr('json'),
  is_enabled: DS.attr('boolean'),
  name: DS.attr('string'),
  next_run_ts: DS.attr('ts'),
  next_run_ts_from: DS.attr('ts'),
  next_run_ts_to: DS.attr('ts'),
});
