import Ember from 'ember';

/*
	Displays numbers in currency format
*/

export default Ember.Helper.extend({

  compute(params) {
    var num = params[0];
    var places = params[1];
    if (isNaN(places)) {
      places = 2;
    }
    if (!num) {
      return '$ 0.00';
    }
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num)) {
      num = '0';
    }
    var sign = (Number(num) === (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    var cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10 && places >= 2) {
      cents = '0' + cents;
    }
    if (String(cents).length < places) {
      cents = (cents + '0000000000').slice(0, places);
    }
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
      num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    }
    return (((sign) ? '' : '-') + '$ ' + num + '.' + cents);
  }

});
