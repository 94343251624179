import Application from './application';

export default Application.extend({
  attrs: {
    schedule_data: {key: 'data'},
    next_run_ts: {
      serialize: false
    }
  }
});
