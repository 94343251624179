import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  description: DS.attr('string'),
  device_log_action_id: DS.attr('string'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),
  servername: DS.attr('string'),

  // Defined model relationships
	device_log_action: DS.belongsTo('devicelogaction'),

});
