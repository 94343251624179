import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  pass_reqs: {
    one_letter: false,
    one_number: false,
    one_special: false,
    eight_chars: false,
    pass_match: false
  },
  allow_submit: Ember.computed('password', 'confirmpassword', 'terms_condition_acceptance', function() {
    var password = this.get('password');
    var confirmpassword = this.get('confirmpassword');
    if (!password || !confirmpassword) {
      return false;
    }

    var requirements = this.get('pass_reqs');
    if (!requirements.one_letter || !requirements.one_number || !requirements.one_special || !requirements.eight_chars || !requirements.pass_match) {
      return false;
    }

    var termscondition = this.get('termscondition');
    this.set('terms_condition_id', null);
    if (termscondition) {
      var terms_condition_acceptance = this.get('terms_condition_acceptance');
      this.set('terms_condition_id', termscondition.id);
      if (!terms_condition_acceptance) {
        return false;
      }
    }
    return true;
  }),
  passwordChanged: Ember.observer('password', 'confirmpassword', function() {
    var self = this;
    var password = this.get('password');
    var confirmpassword = this.get('confirmpassword');
    if (!password) {
      self.set('pass_reqs.one_letter', false);
      self.set('pass_reqs.one_number', false);
      self.set('pass_reqs.one_special', false);
      self.set('pass_reqs.eight_chars', false);
      self.set('pass_reqs.pass_match', false);
    } else {
      var one_letter = false;
      var one_number = false;
      var one_special = false;
      var eight_chars = false;
      var pass_match = false;

      if (password.match(/[0-9]/)) {
        one_number = true;
      }
      if (password.match(/[a-z]/i)) {
        one_letter = true;
      }
      if (password.match(/\W/)) {
        one_special = true;
      }
      if (password.length >= 12) {
        eight_chars = true;
      }
      if (password === confirmpassword && password.length > 0) {
        pass_match = true;
      }

      self.set('pass_reqs.one_letter', one_letter);
      self.set('pass_reqs.one_number', one_number);
      self.set('pass_reqs.one_special', one_special);
      self.set('pass_reqs.eight_chars', eight_chars);
      self.set('pass_reqs.pass_match', pass_match);
    }
  }),
  actions: {
    save: function() {
      this.set('errors', null);

      var self = this;
      var username = this.get('username');
      var password = this.get('password');
      var confirmpassword = this.get('confirmpassword');
      var password_reset_id = this.get('password_reset_id');
      var terms_condition_id = this.get('terms_condition_id');

      if (password !== confirmpassword) {
        noty({
          text: 'Passwords do not match!',
          type: 'error',
          timeout: '5000'
        });
        return;
      }

      if (this.get('termscondition') && !this.get('terms_condition_acceptance')){
        noty({
          text: 'Must accept Terms and Conditions!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
        window.scrollTo(0, 0);
        return;
      }
      new Ember.RSVP.Promise(function() {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/resetpassword/' + password_reset_id,
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            'user': {
              'username': username,
              'password': password,
              'terms_condition_id': terms_condition_id,
              'url': window.UiENV.APP.DOMAIN
            }
          })
        }).then(function() {
          noty({
            text: 'Password update successful!'
          });
          self.transitionToRoute('login');
        }, function(error) {
          if (error.status === 410) {
            self.set('errors', 'This reset password link has expired. Please visiti the <a href="/#/forgotpassword">forgot password</a> page to request a new one.');
            noty({
              text: 'This password link has expired. Please go to forgot password page to request a new one.',
              type: 'error',
              timeout: '5000'
            });

          } else {
            var err = 'Errors:';
            var errors = JSON.parse(error.responseText);
            for (var x in errors) {
              err += '<br>' + errors[x];
              var element = document.getElementsByName(x);
              if (element != null) {
                for (x = 0; x < element.length; x++) {
                  element[x].parentNode.parentNode.classList.add('has-error');
                }
              }
            }
            self.set('errors', err);
            noty({
              text: 'Error saving new password!',
              type: 'error',
              timeout: '5000'
            });
          }
        });
      });
    }
  }
});
