import Ember from "ember";
import jQuery from "jquery";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  completeController: Ember.inject.controller("components/modals/complete"),
  incrementController: Ember.inject.controller("components/modals/increment"),
  refundController: Ember.inject.controller("components/modals/refund"),
  sessionService: Ember.inject.service("session"),
  tipadjustController: Ember.inject.controller("components/modals/tipadjust"),
  transactionEditController: Ember.inject.controller(
    "components/modals/transactionedit"
  ),
  transactiondetaillevelthreeController: Ember.inject.controller(
    "transactiondetaillevelthree"
  ),
  transactiondetailFullController: Ember.inject.controller(
    "transactiondetailfull"
  ),
  voidController: Ember.inject.controller("components/modals/void"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),
  transactionCalculations: Ember.inject.service("transaction-calculations"),
  store: Ember.inject.service(),

  // Various controller properties
  isLoading: false,
  emailReceipt: false,
  showPrint: true,
  hasTags: false,
  surchargeValue:0,
  showSurchargeLebel:false,
  bin_data:'',
  trxSubTotal:0,
  showSubTotal:false,
  showLabel:false,

  showAuthAmount: Ember.computed(
    "transaction.auth_amount",
    "transaction.transaction_amount",
    function () {
      return (
        this.get("transaction.auth_amount") !==
        this.get("transaction.transaction_amount")
      );
    }
  ),
  contactSubscribedToReceiptEmail: Ember.computed(
    "contact.email_trx_receipt",
    "contact.email",
    function () {
      var email = this.get("contact.email");
      var emailReceipt = this.get("contact.email_trx_receipt");
      return email && emailReceipt ? true : false;
    }
  ),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "transaction.errors.messages",
    function () {
      this.set("errorMessages", this.get("transaction.errors.messages"));
    }
  ),

  updateAction: Ember.observer("transaction_id", function () {
    var store = this.get("store");
    var self = this;
    var transaction_id = this.get("transaction_id");

    if (!transaction_id) {
      return;
    }
    return Ember.RSVP.hash({
      transaction: store
        .queryRecord("transaction", {
          id: self.get("transaction_id"),
          expand:
            "is_voidable,is_refundable,is_completable,tags,surcharge,signature,contact,reason_code,transaction_histories,serviceErrors,status,type,transaction_level3,terminal,product_transaction,refunded_amount",
        })
        .then(
          function (data) {
            self.set("transaction", data);
          },
          function () {
            noty({
              text: "Error getting transaction detail!",
              type: "error",
              timeout: "5000",
            });
          }
        ),
      statuses: store.findAll("status").then(function (data) {
        self.set("statuses", data);
      }),
    });
  }),

  showSurchargeLabelObserver: Ember.observer('transaction', function(){
    if(this.get("product.surcharge.id") && this.get('transaction.status_id') == 102)
      this.set('showSurchargeLabel',true);
    else
      this.set('showSurchargeLabel',false);
  }),

  is_incrementable: Ember.computed(
    "transaction.is_completable",
    "product.industry_type",
    function () {
      var completable = this.get("transaction.is_completable");
      var industry_type = this.get("product.industry_type");
      return completable && industry_type === "lodging" ? true : false;
    }
  ),

  trxSubTotal_observer: Ember.observer(
    "transaction.{id,transaction_amount,tip_amount,tax,surcharge.surcharge_amount}",'transaction',
    async function () {
      var self = this;
      var amount = parseFloat(this.get("transaction.transaction_amount")) || 0;
      var tip = parseFloat(this.get("transaction.tip_amount")) || 0;
      var tax = parseFloat(this.get("transaction.tax")) || 0;
      var type_id = this.get('transaction.status_id');
      var transaction = this.get('transaction');
      var card_type;
      
      var surcharge = transaction.get('subtotal_amount')? 
                          this.calculateSurcharge(transaction) 
                          : parseFloat(this.get("transaction.surcharge.surcharge_amount")) || 0;

      !self.transactionCalculations.isDebitCardCheck || 
      (self.transactionCalculations.isDebitCardCheck && self.transactionCalculations.isConvenienceFee)?
      this.set("surchargeValue",surcharge.round(2)):this.set("surchargeValue",0);

      if(this.transactionCalculations.accountVaultCardFlag)this.set("surchargeValue",0)
      var subTotalCalculation = 0;
      var productTransaction = this.get('transaction.product_transaction');      
      if(type_id == 102){

        if(productTransaction && productTransaction.get('payment_method') == 'cc')
          card_type = await this.getCardType(this.get('transaction.first_six'));
        else
          card_type = 'none';

        this.set('showLabel',false);
        let isSurcharge = this.get('transaction.is_surcharge');
        var subtotal = this.get('transaction.subtotal_amount');
        var transaction_amount = this.get('transaction.transaction_amount');
        var tax = this.get('transaction.tax')?this.get('transaction.tax'):0;

        if(isSurcharge && isSurcharge!='Surcharge'){
          //Convenience Fee
          var surcharge_value = +transaction_amount - +subtotal - +tax;
          subTotalCalculation = +transaction_amount - surcharge_value - +tax;
          this.set("surchargeValue",surcharge_value.round(2))
          this.set('showSurchargeLebel',true);
        }else if(isSurcharge && card_type && card_type.toLowerCase()=='credit'){ 
          // if inside this then flag is set to Surcharge
          //Surcharge Credit card
          var surcharge_value = +transaction_amount - +subtotal - +tax;
          subTotalCalculation = +transaction_amount - surcharge_value - +tax;
          this.set("surchargeValue",surcharge_value.round(2))
          this.set('showSurchargeLebel',true);
        }else{
          //No surcharge of flag Surcharge with debit card
          subTotalCalculation = +transaction_amount - +tax;

          this.set('showSurchargeLebel',false);
        }
        
      }else{
        this.set('showLabel',true);
        this.set('showSurchargeLebel',false);
        subTotalCalculation = !this.transactionCalculations.isDebitCardCheck ||
        (this.transactionCalculations.isDebitCardCheck && this.transactionCalculations.isConvenienceFee)?
        (amount - tip - tax - surcharge):(amount - tip - tax);
      }

      this.set('trxSubTotal',subTotalCalculation);      
    }
  ),

  calculateSurcharge: function(transaction){
    var subTotal = transaction.get('subtotal_amount') || 0,
        tax = transaction.get('tax') || 0;

    var amount = transaction.get('transaction_amount') - +subTotal - +tax;

    return amount;
  },

  showSubTotal_observer: Ember.observer(
    "product.{hasTip,surcharge_id,vt_enable_sales_tax}",
    "transaction.{surcharge.id,status_id}",
    function () {
        if((this.get("product.hasTip") ||
          this.get("product.surcharge_id") ||
          this.get("product.vt_enable_sales_tax") ||
          this.get("transaction.surcharge.id")) &&
          this.get("transaction.status_id") !== "201"){
          this.set('showSubTotal',true);
        }else{
          if(this.get('transaction.is_surcharge')){
            this.set('showSubTotal',true);
          }else
            this.set('showSubTotal',false);
        }
  
    }
  ),

  transactionQualifiesForLevel3Data: Ember.computed(
    "transaction.{id,is_level3_eligible}",
    "product.level3_allow",
    function () {
      var transactionIsEligible = this.get("transaction.is_level3_eligible");
      var productAllowsLevel3Data = this.get("product.level3_allow");
      return transactionIsEligible && productAllowsLevel3Data;
    }
  ),
  productAllowsL3DataButTransactionIsNotEligible: Ember.computed(
    "transaction.is_level3_eligible",
    "product.level3_allow",
    function () {
      var transactionIsEligible = this.get("transaction.is_level3_eligible");
      var productAllowsLevel3Data = this.get("product.level3_allow");
      return !transactionIsEligible && productAllowsLevel3Data;
    }
  ),
  canReprintOnTerminal: Ember.computed(
    "transaction.terminal_id",
    "transaction.terminal.terminal_manufacturer_id",
    "transaction.terminal.print_enable",
    function () {
      return (
        this.get("transaction.terminal_id") &&
        this.get("transaction.terminal.terminal_manufacturer_id") > 1 &&
        this.get("transaction.terminal.print_enable")
      );
    }
  ),
  showContactNameOnReceipt: Ember.computed(
    "transaction.{isCC,isACH}",
    "product.{id,receipt_show_contact_name}",
    function () {
      return this.get("transaction.isCC")
        ? this.get("product.receipt_show_contact_name")
        : true;
    }
  ),
  showLevel3: Ember.computed(
    "transactionQualifiesForLevel3Data",
    "session.authenticated.resources.v2-transactionlevel3s-get",
    "sender",
    function () {
      return (
        this.get("transactionQualifiesForLevel3Data") &&
        this.get("session.authenticated.resources.v2-transactionlevel3s-get") &&
        this.get("sender") &&
        this.get("sender").indexOf("contactuser") === -1
      );
    }
  ),
  //transactions
  //get all transactions data
  transactions: Ember.computed("transaction", function () {
    return [];
  }),
  currentRecord: Ember.computed(function () {
    return 1;
  }),
  disablePreviousButton: Ember.computed("currentRecord", function () {
    return this.currentRecord === 1;
  }),
  disableNextButton: Ember.computed(
    "currentRecord",
    "transactions",
    function () {
      return this.currentRecord >= this.transactions.length;
    }
  ),
  showOnMultiPayment: Ember.computed("transactions", function () {
    return this.transactions.length > 1;
  }),

  //Functions

  calculateSubtotalAmount:function(transactionAmount, surcharge_amount, surchargeRate){
    let actualAmount = 0;
    let amount = (1 + +surchargeRate/100);
    actualAmount = (+transactionAmount - +surcharge_amount) / amount;
    return actualAmount.toFixed(2) || 0; // Round to 2 decimal places
  },

  getCardType:function(first_six){
    return new Promise((res,rej)=>{
      this.get("store").findRecord("bin", first_six).then(function (bin){
        var card_type = bin.get('card_type')?bin.get('card_type'):'';
        res(card_type);
      }).catch(function (error) {
        this.set("error", error);
      });
    })
  },


  // Actions
  actions: {
    nextRecord: function () {
      this.set("currentRecord", this.currentRecord + 1);
      this.send(
        "showTransDetail",
        this.transaction,
        this.locationinfo,
        undefined,
        this.transactions
      );
    },
    previousRecord: function () {
      this.set("currentRecord", this.currentRecord - 1);
      this.send(
        "showTransDetail",
        this.transaction,
        this.locationinfo,
        undefined,
        this.transactions
      );
    },
    launchAction: function (actionName) {
      var self = this;
      var controller = this.get(actionName + "Controller");
      var transaction = this.get("transaction");
      var product = this.get("product");

      if(actionName !== "refund") {
        controller.set("transaction", transaction);
      }
      controller.set("initialTags", transaction.get("tags_csv"));
      controller.set("selectedTags", transaction.get("tags_csv"));
      controller.set("sender", this.get("sender"));

      if (transaction.get("advance_deposit")) {
        transaction.set("extra_flag", "advance_deposit");
      } else if (transaction.get("no_show")) {
        transaction.set("extra_flag", "no_show");
      } else {
        transaction.set("extra_flag", "");
      }
      if (actionName === "transactiondetaillevelthree") {
        self.send("closeModal");
        this.transitionToRoute(
          "transactiondetaillevelthree",
          transaction.get("id")
        );
        return;
      }

      if (actionName === "refund") {
        var surcharge_fee  = parseFloat(     product.get('surcharge.surcharge_fee')) || 0;
        var surcharge_rate = parseFloat(     product.get('surcharge.surcharge_rate')/100) || 0;

        var total = parseFloat(transaction.get("transaction_amount"));
        var refunded_amount = parseFloat( transaction.get( 'refunded_amount' ) ).toFixed(2);
        var subtotal = String( parseFloat( ( total - surcharge_fee ) / ( surcharge_rate + 1 ) ).toFixed(2));
        
        var transaction_amount = transaction.get("transaction_amount");
        var surcharge_amount = transaction.get("surcharge.surcharge_amount") || "0.00";

        var net_refundable  = parseFloat( subtotal ) - refunded_amount;

        if (transaction.get("payment_method") === "ach") {
          transaction.set('subtotal_amount', String(parseFloat(total - surcharge_amount).toFixed(2)));
          transaction.set('surcharge_amount', surcharge_amount);
        } else if (
          this.get("product.surcharge.id") &&
          this.get("product.surcharge.refund_surcharges")
        ) {
          if (transaction.get("surcharge.surcharge_amount") > 0) {
            transaction.set('subtotal_amount', String((parseFloat(transaction_amount) - parseFloat(surcharge_amount)).toFixed(2)));
            transaction.set('surcharge_amount', surcharge_amount);
          } else {
            transaction.set("subtotal_amount", subtotal);
            transaction.set('surcharge_amount', String(parseFloat(total - subtotal).toFixed(2)));
          }
        } else if (
          this.get("product.surcharge.id") &&
          !this.get("product.surcharge.refund_surcharges")
        ) {
          transaction.set("subtotal_amount", subtotal);
          transaction.set("surcharge_amount", "0.00");
        }

        var isSurcharge = this.get('product.surcharge.is_surcharge');
        var isSurchargeApplied = true;
        if (transaction.get('payment_method') == 'ach' && isSurcharge === 'Surcharge') {
          transaction.set('subtotal_amount', transaction.get('transaction_amount'));
          transaction.set('surcharge_amount', '0.00');
          isSurchargeApplied = false;
        }

        var isSurchargeRefunded = false;
        var surchargeAmount = transaction.get('surcharge_amount');
        if ( transaction.get( 'surcharge.surcharge_refunded' ) ) {
          isSurchargeRefunded = true;
          net_refundable = parseFloat( net_refundable ) + parseFloat( surcharge_amount );
          surchargeAmount = 0;
        }

        var refund_trans = this.get("store").createRecord("transaction", {
          payment_method: transaction.get("payment_method"),
          last_four: transaction.get("last_four"),
          first_six : (transaction.get('first_six') || ''),
          action: "refund",
          surcharge_amount: surchargeAmount,
          subtotal_amount: net_refundable.toFixed(2),
          surcharge_refunded: isSurchargeRefunded,
          net_refundable: net_refundable.toFixed(2),
          refunded_amount: refunded_amount,
          is_surcharge_applied: isSurchargeApplied,
          original_transaction_amount: total.toFixed(2),
          transaction_amount: transaction.get("transaction_amount"),
          previous_transaction_id: transaction.get("id"),
          description: transaction.get("description"),
          location_id: transaction.get("location_id"),
          checkin_date: transaction.get("checkin_date") || "",
          checkout_date: transaction.get("checkout_date") || "",
          room_rate: transaction.get("room_rate") || "",
          room_num: transaction.get("room_num") || "",
          contact_id: transaction.get("contact_id"),
          order_num: transaction.get("order_num") || "",
          tags: transaction.get("tags") || null,
          clerk_number: transaction.get("clerk_number") || "",
        });
        controller.set("locationinfo", this.get("locationinfo"));
        controller.set("product", this.get("product"));
        controller.set("tagList", this.get("tagList"));
        controller.set("selectedTags", transaction.get("tags_csv"));
        if (
          controller.get("transaction") &&
          this.get("sender") !== "contactsdashboard"
        ) {
          controller.unloadTransaction();
        }
        controller.set("transaction", refund_trans);

        controller.set("tagList", null);
        var tagList = this.get("locationinfo.tags");
        controller.set("tagList", tagList.sortBy("title"));

        self.send("openModal", "components/modals/" + actionName);

        return;
      }

      if (actionName === "tipadjust") {
        var subtotal_amount = this.get("trxSubTotal");
        controller.set("transaction_id", transaction.get("id"));
        controller.set("transaction_tax", transaction.get("tax"));
        controller.set(
          "transaction_tip_amount",
          transaction.get("tip_amount") || 0
        );
        controller.set("trxSubTotal", subtotal_amount);
        controller.set("product", this.get("product"));
        controller.set(
          "transaction.complete",
          transaction.get("status.id") === "102" ? true : false
        );
        controller.set(
          "isAuthOnly",
          transaction.get("status.id") === "102" ? true : false
        );
        controller.set("locationinfo", this.get("locationinfo"));
        self.send("openModal", "components/modals/" + actionName);
        return;
      }

      controller.set("locationinfo", this.get("locationinfo"));
      controller.set("product", this.get("product"));
      controller.set("contact", this.get("contact"));

      if (actionName !== "emailreceipt") {
        controller.set("tagList", null);
        var tagsList = this.get("locationinfo.tags");
        controller.set("tagList", tagsList.sortBy("title"));
        self.send("openModal", "components/modals/" + actionName);
      } else {
        controller.set("email_address", this.get("transaction.contact.email"));
        self.send("openModal", "components/modals/" + actionName);
      }
    },
    emailReceipt: function () {
      // Bail if we are already loading
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var transaction_id = this.get("transaction.id");
      var email_address = this.get("email_address");

      if (transaction_id && email_address) {
        var session = this.get("session");
        jQuery
          .ajax({
            url:
              window.UiENV.APP.FULL_URL +
              "/notifications?access-token=" +
              session.get("authenticated.token"),
            headers: {
              "developer-id": window.UiENV.APP.DEVELOPER_ID,
            },
            type: "POST",
            data: JSON.stringify({
              notification: {
                type: "transaction",
                email: email_address,
                transaction_id: transaction_id,
              },
            }),
            contentType: "application/json",
          })
          .then(
            function () {
              noty({
                text: "Transaction detail has been emailed to " + email_address,
              });
              self.set("isLoading", false);
              self.set("emailReceipt", false);
            },
            function () {
              noty({
                text: "Error emailing transaction detail to " + email_address,
                type: "error",
                timeout: "5000",
              });
              self.set("isLoading", false);
            }
          );
      } else {
        self.set("isLoading", false);
        self.set("emailReceipt", false);
      }
    },
    reprintReceiptOnTerminal: function () {
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);
      var self = this;
      var session = self.get("session");
      jQuery
        .ajax({
          url:
            window.UiENV.APP.FULL_URL +
            "/transactions/" +
            this.get("transaction.id") +
            "/reprintReceipt",
          type: "POST",
          headers: {
            "Content-Type": "application/json",
            "developer-id": window.UiENV.APP.DEVELOPER_ID,
            "access-token": session.get("authenticated.token"),
          },
        })
        .then(
          function () {
            noty({
              text: "Receipt Reprinted!",
            });
            self.set("isLoading", false);
          },
          function (err) {
            var error = "";
            var errors = err.responseJSON;
            if (errors) {
              for (var prop in errors) {
                if (errors.hasOwnProperty(prop)) {
                  if (typeof prop === "string" && prop === "result_text") {
                    error += "<br>" + errors[prop] + ".";
                  } else {
                    for (var p in errors[prop]) {
                      if (errors[prop].hasOwnProperty(p)) {
                        if (typeof p === "string" && p === "result_text") {
                          error += "<br>" + errors[prop][p] + ".";
                        }
                      }
                    }
                  }
                }
              }
            }
            self.set("isLoading", false);
            noty({
              text: "Reprint on Terminal has failed!" + error,
              type: "error",
            });
          }
        );
    },
    showEmailReceipt: function () {
      this.set("emailReceipt", true);
    },
    sigSign: function () {
      sigPad.onSign();
    },
    sigClear: function () {
      sigPad.onClear();
    },
    sigDone: function () {
      sigPad.onDone();
      var self = this;
      if (sigPad.numPoints() === 0) {
        noty({
          text: "Unable to save an empty signature.\nPlease try again!",
          type: "error",
        });
      } else {
        // Bail if we are already uploading
        if (this.get("isLoading")) {
          return;
        }
        self.set("isLoading", true);
        GetSigImageB64(function (sigString) {
          if (sigString) {
            self.send(
              "uploadSignature",
              self.get("transaction.id"),
              "Transaction",
              self.get("transaction"),
              sigString,
              self
            );
          } else {
            self.set("isLoading", false);
          }
        });
      }
    },
    showFullDetail: function (transaction) {
      var self = this;
      Ember.run.later(function () {
        self.send("closeModal");
        self.transitionToRoute("transactiondetailfull", transaction.get("id"));
      });
    },
  },
});
