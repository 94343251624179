import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsTransactions: true,

  sort: '-created_ts',
  page: 1,
  page_size: '15',
  needsRefresh: '',
  created_ts: 'last 90 days',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_type',
    'auth_amount',
    'batch',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'description',
    'is_recurring',
    'last_four',
    'needsRefresh',
    'status_id',
    'transaction_amount',
    'type_id',
  ],
  columns: Ember.computed(function() {
    var self = this;
    return [{
      'name': 'created_ts',
      'title': 'Transaction<br>Date',
      'type': 'shortdate',
      'sortable': true,
      'action': function(transaction) {
        self.send('showTrans', transaction);
      },
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersTransaction',
        multi: false
      }
    }, {
      'name': 'description',
      'title': 'Description',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'batch',
      'title': 'Batch',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'account_type',
      'title': 'Account Type',
      'type': 'image',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'accountTypes'
      }
    }, {
      'name': 'type_id',
      'title': 'Transaction<br>Type',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'types'
      }
    }, {
      'name': 'status_id',
      'title': 'Transaction<br>Status',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'statuses'
      }
    }, {
      'name': 'last_four',
      'title': 'Last Four',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'is_recurring',
      'title': 'Scheduled<br>Payment',
      'type': 'check',
      'sortable': true,
      'align': 'text-center',
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny',
        multi: false
      }
    }, {
      'name': 'auth_amount',
      'title': 'Auth Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_amount',
      'title': 'Transaction<br>Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right',
      'filter': {
        type: 'text'
      }
    }];
  }),

  // Actions
  actions: {
    showTrans: function(transaction) {
      var self = this;
      var store = this.get('store');
      return Ember.RSVP.hash({
        locationinfo: store.findRecord('locationinfo', transaction.get('location_id'))
      }).then(function(hash) {
        self.send('showTransDetail', transaction, hash.locationinfo);
      }, function() {
        noty({
          text: 'Error getting transaction detail!',
          type: 'error',
          timeout: '5000'
        });
      });
    },
  }
});
