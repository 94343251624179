import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function () {
    this.render('admin/salesoffice/view', {
      controller: 'admin/salesoffice/add'
    });
  },

  model: function () {
    var store = this.get('store');
    return Ember.RSVP.hash({
      salesoffice: store.createRecord('salesoffice', {
        country: 'USA',
        location_id: null
      })
    });
  },

  setupController: function (controller, models) {
    // Set Controller Properties
    controller.set('salesoffice', models.salesoffice);
    controller.set('isEditing', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-salesoffices-delete'),
      canPost: this.get('session.authenticated.resources.v2-salesoffices-post'),
      canPut: this.get('session.authenticated.resources.v2-salesoffices-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Sales Office',
      route_name: 'admin.salesoffice.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Sales Office');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Sales Offices',
      link: 'admin.salesoffice.index'
    }, {
      title: 'Add Sales Office',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function () {
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
