import Ember from 'ember';
import jQuery from 'jquery';

Ember.TextField.reopen({
  classNames: ['form-control']
});

export default Ember.TextField.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  type: 'file',
  multiple: false,
  xhr: false,
  change: function(e) {
    var input = e.target;
    if (input.files.length) {
      if(this.get('multiple')) {
        this.action(input.files); // send files as array
      } else {
        this.action(input.files[0]); // send a single file
      }
    } else {
      this.action(null); // send when no files selected
    }
  },
  didInsertElement: function() {
    var xhr = jQuery.ajaxSettings.xhr();
    this.set('xhr', xhr);
  },
});
