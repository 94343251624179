import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsFiles: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('file.errors.messages', function() {
    this.set('errorMessages', this.get('file.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'file_name',
    'file_category_id',
    'file_description',
    'product_file_id',
    'visibility_group_id',
  ],

  // Actions
  actions: {}
});
