import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('authroleuser.errors.messages', function() {
    this.set('errorMessages', this.get('authroleuser.errors.messages'));
  }),

  // Events
  didInsertElement: function() {
    this.set('loggedInUserCanEditViewedUser', this.get('usersMethods').loggedInUserCanEditViewedUser(this.get('user')));
    this.set('changesToMake', []);
    this.set('isEditing', false);
  },

  // Computed Properties
  canEdit: Ember.computed('loggedInUserCanEditViewedUser', 'isCurrentLoggedInUser', 'session.authenticated.resources.v2-authroleusers-post', 'session.authenticated.resources.v2-authroleusers-delete', function() {
    return !this.get('isCurrentLoggedInUser') && this.get('loggedInUserCanEditViewedUser') && (this.get('session.authenticated.resources.v2-authroleusers-post') || this.get('session.authenticated.resources.v2-authroleusers-delete'));
  }),
  isCurrentLoggedInUser: Ember.computed('session.authenticated.user_id', 'user.id', function() {
    var sessionUserId = this.get('session.authenticated.user_id');
    var userId = this.get('user.id');
    return sessionUserId === userId;
  }),
  isRouteLocationsUsersAuthroles: Ember.computed('route', function() {
    return this.get('route') === 'locations.users.authroles';
  }),
  selectedAuthRoles: Ember.computed('authroleusers.[]', function() {
    var authroleusers = this.get('authroleusers');
    var aruArray = [];
    authroleusers.forEach(function(aru) {
      aruArray.push(aru.get('auth_role_id'));
    });
    return aruArray;
  }),

  // Actions
  actions: {
    cancel: function() {
      var changesToMake = this.get('changesToMake');
      changesToMake.forEach(function(change){
        jQuery('#users_authroles_chk-' + change.auth_role_id).trigger('click');
      });
      this.set('changesToMake', []);
      this.set('isEditing', false);
    },
    changeRoles: function(data) {
      if (this.get('isLoading')) {
        return;
      }
      var hasRole = this.get('selectedAuthRoles').indexOf(data.id) !== -1;
      var changesToMake = this.get('changesToMake') || [];
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.auth_role_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          auth_role_id: data.id,
          title: data.title,
          type: hasRole ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    checkAllAuthRoles: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (!jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var changesToMake = this.get('changesToMake');
      var authroleusers = this.get('authroleusers');
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function(change, index) {
        if(change.type === 'POST') {
          canPostRoles.push(change);
          var authRoleUser = self.get('store').createRecord('authroleuser', {
            user_id: self.get('user.id'),
            auth_role_id: change.auth_role_id
          });
          return authRoleUser.save().then(function(aru) {
            authroleusers.addObject(aru); // Push the object we just created back onto the list of file categories object
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
              sendNoty();
            }
          }, function() {
            // error
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
            }
            noty({
              text: 'Error adding role ' + change.title,
              type: 'error',
              timeout: 10000
            });
          });
        } else if(change.type === 'DELETE') {
          canDeleteRoles.push(change);
          return authroleusers.forEach(function(authroleuser) {
            if (authroleuser.get('auth_role_id') === change.auth_role_id) {
              return authroleuser.destroyRecord().then(function() {
                authroleusers.removeObject(authroleuser); // Remove the oject from the parent if it was successfully deleted
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                  sendNoty();
                }
              }, function() {
                // error
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                }
                noty({
                  text: 'Error removing role ' + change.title,
                  type: 'error',
                  timeout: 10000
                });
              });
            }
          });
        }
      });

      self.set('isLoading', true);

      if(!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(function() {
          //
        }, function() {
          noty({
            text: 'Error applying changes!',
            type: 'error'
          });
          doneMakingChanges(self);
        });
      }
      function sendNoty() {
        Ember.run.later(function() {
          if((canPostRoles && canPostRoles.length)) {
            var successMessagePost = '';
            canPostRoles.forEach(function(role) {
              successMessagePost += role.title;
              successMessagePost += '<br>';
            });
            noty({
              text: 'The following Roles were successfully added:<br>' + successMessagePost,
              timeout: 10000
            });
          }
          if((canDeleteRoles && canDeleteRoles.length)) {
            var successMessageDelete = '';
            canDeleteRoles.forEach(function(role){
              successMessageDelete += role.title;
              successMessageDelete += '<br>';
            });
            noty({
              text: 'The following Roles were successfully removed:<br>' + successMessageDelete,
              timeout: 10000
            });
          }
        },1000);
      }
      function doneMakingChanges (context) {
        context.set('isLoading', false);
        context.set('isEditing', false);
        context.set('changesToMake', []);
      }

    },
    unCheckAllAuthRoles: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
  }

});
