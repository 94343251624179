import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller properties
  isLoadingTransactions: true,
  transactionFilter: 'all',

  // Computed Properties

  isFilterACH: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'ach';
  }),
  isFilterAll: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'all';
  }),
  isFilterCC: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'cc';
  }),
  showTypes: Ember.computed('locationinfos.[]', 'trans', function() {
    var locationinfos = this.get('locationinfos');
    var hasCC = false;
    var hasACH = false;
    if (locationinfos && locationinfos.get('length')) {
      locationinfos.forEach(function(location) {
        if (location.get('hasProductCC')) {
          hasCC = true;
        }
        if (location.get('hasProductACH')) {
          hasACH = true;
        }
      });
    }
    return hasCC && hasACH && this.get('trans');
  }),

  // Functions

  mapContent: function() {
    var content = this.get('trans');
    var type = this.get('transactionFilter');

    var storedData = [{
      name: 'Historical Non-Recurring Billing',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_nonrecurring_accountvault) + parseFloat(item.values.ach.historical_nonrecurring_accountvault);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_nonrecurring_accountvault);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_nonrecurring_accountvault);
        }
      }).slice(0, 12)
    }, {
      name: 'Historical Installment',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_installment) + parseFloat(item.values.ach.historical_installment);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_installment);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_installment);
        }
      }).slice(0, 12)
    }, {
      name: 'Historical Ongoing',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_ongoing) + parseFloat(item.values.ach.historical_ongoing);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_ongoing);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_ongoing);
        }
      }).slice(0, 12)
    }];

    this.set('storedChartData', storedData);
  },

  // Actions
  actions: {
    showMain: function() {
      this.transitionToRoute('dashboard');
    },
    updateTransactions: function() {
      var self = this;
      self.set('isLoadingTransactions', true);
      var session = this.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/dashboardsummaries?relationship=direct',
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function(data) {
        var trans = data.dashboardsummaries.data;
        var months = trans.map(function(item) {
          return moment(item.month.slice(2, 4), 'MM').format('MMM') + '-' + item.month.slice(0, 2);
        });

        self.set('storedChartOptions', {
          chart: {
            type: 'column'
          },
          title: {
            text: null
          },
          xAxis: {
            title: {
              text: 'Month'
            },
            categories: months.slice(0, 12)
          },
          yAxis: {
            title: {
              text: 'Volume'
            }
          },
          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },
        });

        self.set('trans', trans);
        self.mapContent();
        self.set('isLoadingTransactions', false);
      }, function() {
        self.set('trans', null);
        self.set('isLoadingTransactions', false);
      });
    },
    changeFilter: function(type) {
      this.set('transactionFilter', type);
      this.mapContent();
    }
  },

});
