import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsVisibilityGroups: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('visibilitygroup.errors.messages', function() {
    this.set('errorMessages', this.get('visibilitygroup.errors.messages'));
  }),

  // Set controller params
  isLoading: false,

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var visibilitygroup = this.get('visibilitygroup');
      pretty_confirm('Are you sure you want to delete this Visibility Group?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        visibilitygroup.deleteRecord();
        visibilitygroup.save().then(function() {
          visibilitygroup.unloadRecord();
          noty({
            text: 'Visibility Group deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('locations.visibilitygroups', self.get('location.id'));
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Visibility Group!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      var visibilitygroup = this.get('visibilitygroup');

      visibilitygroup.save().then(function() {
        noty({
          text: 'Visibility Group saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.visibilitygroups', location.id);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.visibilitygroups', location.id);
    }
  }
});
