import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function (params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      salesoffice: store.queryRecord('salesoffice', {
        id: params.view_id,
        expand: 'location'
      })
    });
  },

  setupController: function (controller, models) {
    // Set Controller Properties
    var salesoffice = models.salesoffice;
    controller.set('salesoffice', salesoffice);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-salesoffices-delete'),
      canPost: this.get('session.authenticated.resources.v2-salesoffices-post'),
      canPut: this.get('session.authenticated.resources.v2-salesoffices-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Sales Office ● ' + salesoffice.get('title'),
      route_name: 'admin.salesoffice.view',
      route_id: salesoffice.get('id')
    });
    controller.set('pageTitle', 'View Sales Office');
    controller.set('pageSubtitle', null);
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Sales Offices',
      link: 'admin.salesoffice.index'
    }, {
      title: 'View Sales Office',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function () {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.salesoffice, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
