import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  canViewResponseBody: Ember.computed(
    "session.authenticated",
    "authenticated.resources.v2-addaccessapiresponses",
    function () {
      const isAuthenticated = this.get("session.isAuthenticated");
      const hasAccessApiResponses = this.get("session.authenticated.resources.v2-addaccessapiresponses");
      if(isAuthenticated && hasAccessApiResponses) {
        return true;
      } else {
        return false;
      }
    }
  ),
  
  // Set the booleans for navigation classes
  isLogapis: true,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('admin.apilogs.index');
    },
  }
});
