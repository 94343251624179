import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isScheduleJobs: true,

  // Set controller params
  page: 1,
  page_size: '15',
  sort: 'name',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'id',
    'is_enabled',
    'name',
    'next_run_ts',
    'next_run_ts_from',
    'next_run_ts_to',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(schedule) {
          self.send('view', schedule);
        },
        'actionId': 'schedule_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      name: {
        'name': 'name',
        'title': 'Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      is_enabled: {
        'name': 'is_enabled',
        'title': 'Enabled',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      next_run_ts: {
        'name': 'next_run_ts',
        'title': 'Next Run',
        'type': 'longdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersFuture',
          multi: false
        }
      },
      cron_time_string: {
        'name': 'cron_time_string',
        'title': 'Cron Time String',
        'type': 'string'
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(schedule) {
          self.send('edit', schedule);
        },
        'actionId': 'schedule_edit_link',
        'type': 'edit',
        'isActionColumn': true
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(schedule) {
          self.send('view', schedule);
        },
        'actionId': 'schedule_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'name', 'is_enabled', 'next_run_ts', 'cron_time_string', 'view', 'edit'];
    var listOfMobileCols = ['id', 'name', 'is_enabled', 'next_run_ts', 'cron_time_string', 'view', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.jobs-schedules-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.jobs-schedules-put')) {
        colsArray.removeObject(columns.edit);
      }
    }
  }),

  // Actions
  actions: {
    edit: function(schedule) {
      this.transitionToRoute('admin.schedules.view', schedule.get('id'), {
        queryParams: {
          edit: true
        }
      });
    },
    view: function(schedule) {
      this.transitionToRoute('admin.schedules.view', schedule.get('id'));
    },
  }
});
