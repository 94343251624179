import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Events
  didInsertElement: function() {
    jQuery('#' + this.get('colorPickerId') + '-type').on('input');
  },
  willDestroyElement: function() {
    jQuery('#' + this.get('colorPickerId') + '-type').off('input');
  },

  // Computed Properties
  colorPickerId: Ember.computed('name', function() {
    return this.get('name') + '-colorpicker';
  }),

  // Actions
  actions: {
    clickColor: function() {
      var value = document.getElementById(this.get('colorPickerId') + '-pick').value;
      this.set('value', value);
    },
    typedColor: function() {
      var value = document.getElementById(this.get('colorPickerId') + '-type').value;
      this.set('value', value);
    }
  }
});
