import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  normalize: function(type, hash, property) {
    hash.branding_domain_url = null;
    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  },
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  attrs: {
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    product_transactions: {
      serialize: false,
      deserialize: 'records'
    },
    product_cc: {
      serialize: false,
      deserialize: 'records'
    },
    product_ach: {
      serialize: false,
      deserialize: 'records'
    },
    product_accountvault: {
      serialize: false,
      deserialize: 'records'
    },
    product_recurring: {
      serialize: false,
      deserialize: 'records'
    },
    product_file: {
      serialize: false,
      deserialize: 'records'
    },
    branding_domain: {
      serialize: false,
      deserialize: 'records'
    },
    parent: {
      serialize: false,
      deserialize: 'records'
    },
    developer_company: {
      serialize: false,
      deserialize: 'records'
    }
  }
});