import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsUsers: true,
  MINIMUM_SUPPORT_LEVEL: 300,

  // Set controller params
  sort: 'username',
  page: 1,
  page_size: '15',
  status_id: '1',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'email',
    'first_name',
    'last_login_ts',
    'last_login_ts_from',
    'last_login_ts_to',
    'last_name',
    'status_id',
    'username',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      username: {
        'name': 'username',
        'title': 'Username',
        'type': 'string',
        'sortable': true,
        'action': function(user) {
          self.send('view', user);
        },
        'actionId': 'user_username_view_link',
        'filter': {
          type: 'text'
        }
      },
      first_name: {
        'name': 'first_name',
        'title': 'First Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_name: {
        'name': 'last_name',
        'title': 'Last Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      email: {
        'name': 'email',
        'title': 'Email',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      status_id: {
        'name': 'status_id',
        'title': 'Status',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'active_status',
          multi: true
        }
      },
      isClientAdmin: {
        'name': 'isClientAdmin',
        'title': 'Client Admin',
        'type': 'check',
        'align': 'text-center',
        'sortable': false
      },
      last_login_ts: {
        'name': 'last_login_ts',
        'title': 'Last Login',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      }
    };

    var listOfCols = ['username', 'first_name', 'last_name', 'email', 'status_id', 'isClientAdmin', 'last_login_ts', 'created_ts'];
    var listOfMobileCols = ['username', 'first_name', 'last_name', 'email'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      if(this.get('session.authenticated.user.user_type_id') >= this.MINIMUM_SUPPORT_LEVEL){
        listOfCols.splice(4,1);
      }
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    view: function(user) {
      this.transitionToRoute('locations.users-view', this.get('location.id'), user.get('id'));
    }
  }
});
