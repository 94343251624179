import Ember from 'ember';
import jQuery from 'jquery';
/* global printElement: true */
export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),
  fileQueue: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.getTagList();
    this.setItemList();
    this.checkLocations();
    this.setSelectedTags();
    this.setProduct();
    this.getTransactions();
    this.set('originalTags', this.get('quickinvoice.tags'));
    this.set('tempFiles', []);
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-quickinvoices-delete'),
      canPost: this.get('session.authenticated.resources.v2-quickinvoices-post'),
      canPut: this.get('session.authenticated.resources.v2-quickinvoices-put'),
    });
  },
  willDestroyElement: function() {
    var quickinvoice = this.get('quickinvoice');
    if(quickinvoice) {
      if (quickinvoice.get('isNew')) {
        quickinvoice.deleteRecord();
      } else {
        quickinvoice.unloadRecord();
      }
    }
    this.set('fileQueue.files', []);
    this.set('isTransitioning', false);
  },

  // Properties
  addItemMessages: [],
  addFileMessages: [],

  // Computed Properties
  atMaxAttachedFiles: Ember.computed('quickinvoice.files.[]', function() {
    return this.get('quickinvoice.files.length') >= 4;
  }),
  canChangeLocation: Ember.computed('locations.[]', 'isRouteQuickInvoice', function() {
    return this.get('locations.length') > 1 && this.get('isRouteQuickInvoice') && this.get('quickinvoice.isNew') && this.get('isEditing');
  }),
  canChangeProduct: Ember.computed('quickinvoiceTransactions.[]', 'isEditing', function() {
    return (this.get('quickinvoiceTransactions.length') === 0 || this.get('quickinvoice.isNew')) && this.get('isEditing');
  }),
  canChangeContact: Ember.computed('route', 'quickinvoice.location_id', function() {
    return (this.get('isRouteQuickInvoice') || this.get('isRouteLocation')) && this.get('quickinvoice.location_id');
  }),
  canDeleteFileList: Ember.computed('quickinvoice.{active,isNew}', 'isEditing', 'session.authenticated.resources.v2-quickinvoices-delete-files', function() {
    if(this.get('quickinvoice.isNew')) {
      return true;
    } else {
      return this.get('quickinvoice.active') && this.get('isEditing') && this.get('session.authenticated.resources.v2-quickinvoices-delete-files')  && this.get('session.authenticated.resources.v2-quickinvoices-delete');
    }
  }),
  canEditFileList: Ember.computed('quickinvoice.{active,isNew}', 'isEditing', 'session.authenticated.resources.v2-quickinvoices-post-files', 'location.product_file.id', function() {
    if(this.get('quickinvoice.isNew')) {
      return true;
    } else {
      return this.get('quickinvoice.active') && this.get('isEditing') && this.get('session.authenticated.resources.v2-quickinvoices-post-files') && this.get('session.authenticated.resources.v2-quickinvoices-post') && this.get('location.product_file.id');
    }
  }),
  canEditItemList: Ember.computed('quickinvoice.payment_status_id', 'isEditing', function() {
    let payment_status_id = this.get('quickinvoice.payment_status_id');
    return (
        String(payment_status_id) === '1' ||
        String(payment_status_id) === '3' ||
        this.get('quickinvoice.isNew')
      ) && this.get('isEditing');
  }),
  isActive: Ember.computed('quickinvoice.active', 'quickinvoice.expire_date', function() {
    var isActive = this.get('quickinvoice.active');
    var tz = this.get('session.authenticated.timezone');
    var expire_date = moment(this.get('quickinvoice.expire_date'), 'MM-DD-YYYY').endOf('day').valueOf();
    var current_date = moment().tz(tz).valueOf();
    var isExpired = current_date >= expire_date;
    return isActive && !isExpired;
  }),

  isPartialPayment: Ember.computed('quickinvoice.allow_partial_pay', function() {
    var allow_partial_pay = this.get('quickinvoice.allow_partial_pay');
    return Number(allow_partial_pay) === 1 || allow_partial_pay;
  }),
  isAllowOverPayment: Ember.computed('quickinvoice.allow_overpayment', function() {
    var allow_overpayment = this.get('quickinvoice.allow_overpayment');
    return Number(allow_overpayment) === 1 || allow_overpayment;
  }),
  isSendEmail: Ember.computed('quickinvoice.send_email', function() {
    return String(this.get('quickinvoice.send_email')) === '1';
  }),
  isRouteContact: Ember.computed('route', function() {
    return this.get('route') === 'contact';
  }),
  isRouteContactUser: Ember.computed('route', function() {
    return this.get('route') === 'contactuser';
  }),
  isRouteLocation: Ember.computed('route', function() {
    return this.get('route') === 'location';
  }),
  isRouteQuickInvoice: Ember.computed('route', function() {
    return this.get('route') === 'quickinvoice';
  }),
  itemListTotal: Ember.computed('itemList.@each', function() {
    var itemList = this.get('itemList');
    var total = 0;
    if (itemList) {
      itemList.map(function(item) {
        if(typeof item.amount === 'number') {
          total += item.amount;
        } else if(typeof item.amount === 'string') {
          total += parseFloat(item.amount.replace(/,/g, ''));
        }
      });
    }
    return total.toFixed(2);
  }),
  noFileStoreServiceMessage: Ember.computed('route', function() {
    if(this.get('isRouteContactUser')) {
      return 'Accessing attachments is not enabled for this invoice.<br>Please contact the sender of this invoice for assistance.';
    } else {
      return 'File Storage Service is not configured for this Location.<br>Please contact support if you need assistance enabling File Storage Service.';
    }
  }),
  quickInvoiceProducts: Ember.computed('location.product_transactions.[]', function() {
    if (this.get('location.id')) {
      return this.get('location.product_transactions').sortBy('title').filter(function(product) {
        return product.get('quick_invoice_allow');
      });
    } else {
      return [];
    }
  }),
  quickInvoiceProductsCC: Ember.computed('quickInvoiceProducts.[]', function() {
    return this.get('quickInvoiceProducts').filter(function(product) {
      return product.get('payment_method') === 'cc' && product.get('active');
    });
  }),
  quickInvoiceProductsACH: Ember.computed('quickInvoiceProducts.[]', function() {
    return this.get('quickInvoiceProducts').filter(function(product) {
      if (product.get('processor') === 'zach') {
        return product.get('canQIPaya') && product.get('active');
      } else {
        return product.get('payment_method') === 'ach' && product.get('active');
      }
    });
  }),
  allProducts: Ember.computed('location.product_transactions.[]', function() {
    if (this.get('location.id')) {
      return this.get('location.product_transactions');
    } else {
      return [];
    }
  }),
  showFiles: Ember.computed('location.product_file.id', 'location.id', 'quickinvoice.isNew', 'quickinvoice.files.[]', function() {
    if(this.get('quickinvoice.isNew')) {
      return this.get('location.product_file.id');
    } else {
      if(this.get('quickinvoice.files.length') === 0) {
        return this.get('location.product_file.id');
      } else {
        return true;
      }
    }
  }),
  showProducts: Ember.computed('quickInvoiceProducts.[]', 'quickinvoice.location_id', 'isRouteContactUser', function() {
    return this.get('quickInvoiceProducts').get('length') >= 1 && !this.get('isRouteContactUser');
  }),
  showLocations: Ember.computed('locations.[]', 'isRouteQuickInvoice', function() {
    return this.get('isRouteQuickInvoice');
  }),
  notAContactRoute: Ember.computed('route', function() {
    return !this.get('isRouteContact') && !this.get('isRouteContactUser');
  }),

  allowNoneOptionACH: Ember.computed('quickInvoiceProductsCC.[]', 'quickInvoiceProductsACH.[]', function() {
    return this.get('quickInvoiceProductsCC.length') >= 1 ? 'false' : 'true';
  }),
  allowNoneOptionCC: Ember.computed('quickInvoiceProductsCC.[]', 'quickInvoiceProductsACH.[]', function() {
    return this.get('quickInvoiceProductsACH.length') >= 1 ? 'false' : 'true';
  }),
  selectMessageCC: Ember.computed('allowNoneOptionCC', function() {
    return this.get('allowNoneOptionCC') !== 'false' ? 'Select...' : 'None';
  }),
  selectMessageACH: Ember.computed('allowNoneOptionACH', function() {
    return this.get('allowNoneOptionACH') !== 'false' ? 'Select...' : 'None';
  }),
  quickinvoiceFiles: Ember.computed('quickinvoice.files.[]', function() {
    var files = this.get('quickinvoice.files');
    var self = this;
    var session = this.get('session');
    if(files && files.length) {
      files = files.slice().map(function(file){
        if(!self.get('quickinvoice.isNew')) {
          Ember.set(file, 'link', window.ENV.APP.FULL_URL +  '/files/' + file.id + '/attachment?access-token=' + session.get('authenticated.token') + '&developer-id=' + window.UiENV.APP.DEVELOPER_ID);
          Ember.set(file, 'linkid', file.id + file.created_ts);
        }
        return file;
      });
      return files;
    } else {
      return [];
    }
  }),

  noQIMessage: Ember.computed('isQI', 'route', function() {
    var message = 'Quick Invoice is not configured for any products at ';
    if(this.get('isRouteLocation')){
      message += 'this location.';
    } else if(this.get('isRouteQuickInvoice')){
      message += 'any of your locations.';
    } else if(this.get('isRouteContact')){
      message += 'this contact\'s location.';
    }
    message += '<br> Please contact support if you need assistance enabling Quick Invoice.';
    return message;
  }),
  qiFilesMaxMessage: Ember.computed(function() {
    return 'Maximum of 4 files can be attached to a Quick Invoice.';
  }),


  // Observers
  contactIdChanged: Ember.observer('quickinvoice.contact_id', function() {
    var self = this;
    var contact_id = this.get('quickinvoice.contact_id');
    var contacts = this.get('contactsArray');
    var contact = null;
    if (contacts && contacts.get('length')) {
      contacts.forEach(function(c) {
        if (c.get('id') === contact_id) {
          contact = c;
        }
      });
      self.set('quickinvoice.email', contact ? contact.get('email') : null);
    }
  }),
  getLocation: Ember.observer('quickinvoice.location_id', function() {
    var self = this;
    var quickinvoice_location_id = this.get('quickinvoice.location_id');
    var quickinvoice = this.get('quickinvoice');
    var locations = this.get('locations');
    if (this.get('isRouteQuickInvoice') && !this.get('quickinvoice.id')) {
      locations.forEach(function(loc) {
        if (loc.get('id') === quickinvoice_location_id) {
          self.get('store').queryRecord('locationinfo', {
            id: loc.get('id'),
            expand: 'quick_invoice_setting'
          }).then(function(data) {
            var location = data;
            self.set('location', location);
            quickinvoice.set('location_id', location.get('id'));
            self.getTagList();
            self.set('selectedTags', ''); // clear tags so no mismatch with location tags
            quickinvoice.set('tags', []); // clear tags so no mismatch with location tags
            if(!location.get('product_file.id')) {
              quickinvoice.set('files', []); // clear files since location doesn't have product file
              quickinvoice.set('attach_files_to_email', null); // clear since location doesn't have product file
            }
            quickinvoice.set('contact_id', null); // clear contact so no mismatch with location contacts
            self.setProduct();
            var quickinvoicesettings = location.get('quick_invoice_setting');
            quickinvoice.set('allow_partial_pay', quickinvoicesettings.default_quick_invoice_allow_partial_pay ? true : false);
            quickinvoice.set('notification_days_after_due_date', quickinvoicesettings.default_quick_invoice_notification_days_after_due_date);
            quickinvoice.set('notification_days_before_due_date', quickinvoicesettings.default_quick_invoice_notification_days_before_due_date);
            quickinvoice.set('notification_on_due_date', quickinvoicesettings.default_quick_invoice_notification_on_due_date ? true : false);
          });
        }
      });
    }
  }),

  isPartial_OverpaidObserver: Ember.observer('isAllowOverPayment', 'isPartialPayment', function() {
    var allow_overpayment = this.get('isAllowOverPayment');
    var allow_partial_pay = this.get('isPartialPayment');
    if (!allow_partial_pay) {
      this.set('quickinvoice.single_payment_min_amount', 0);
    }
    if (!allow_overpayment && !allow_partial_pay) {
      this.set('quickinvoice.single_payment_max_amount', 0);
    }
    if ((allow_overpayment || allow_partial_pay) && !this.get('quickinvoice.single_payment_max_amount')) {
      this.set('quickinvoice.single_payment_max_amount', 9999999.99);
    }
  }),

  refreshTransactionsObserver: Ember.observer('refreshTransactions', function() {
    this.getTransactions();
  }),

  // Functions
  checkLocations: function() {
    if (this.get('isRouteQuickInvoice') && this.get('locations').get('length') === 0) {
      this.set('QuickInvoiceNotEnabled', true);
    } else if((this.get('isRouteLocation') || this.get('isRouteContact')) && !this.get('location.hasQuickInvoice')){
      this.set('QuickInvoiceNotEnabled', true);
    } else {
      this.set('QuickInvoiceNotEnabled', false);
    }
  },
  getTagList: function() {
    var self = this;
    if(this.get('session.authenticated.resources.v2-tags-get') && this.get('location.id')) {
      this.get('store').query('tag', {
        location_id: self.get('location.id'),
        page_size: '500',
        sort: 'title'
      }).then(function(res) {
        self.set('tagList', res);
      });
    } else {
      self.set('tagList', []);
    }
  },
  getTransactions: function() {
    if(this.get('isGettingTransactions')) {
      return;
    }
    var self = this;
    var quickinvoice_id = this.get('quickinvoice.id');
    if (quickinvoice_id) {
      this.set('isGettingTransactions', true);
      this.get('store').query('transaction', {
        quick_invoice_id: quickinvoice_id,
        expand: 'status,type',
        show_totals: true
      }).then(function(data) {
        self.set('quickinvoiceTransactions', data);
        self.set('isGettingTransactions', false);
      }, function() {
        self.set('isGettingTransactions', false);
      });
    }
  },
  setItemList: function() {
    var itemList = this.get('quickinvoice.item_list');
    var arr = [];
    for (var prop in itemList) {
      let obj = {
        title: prop,
        amount: itemList[prop]
      };
      arr.push(obj);
    }
    this.set('itemList', arr);
    this.set('item', {
      title: '',
      amount: ''
    });
  },
  setProduct: function() {
    var self = this;
    var cc_product_transaction_id = this.get('quickinvoice.cc_product_transaction_id');
    var ach_product_transaction_id = this.get('quickinvoice.ach_product_transaction_id');
    var quickInvoiceProductsCC = this.get('quickInvoiceProductsCC');
    var quickInvoiceProductsACH = this.get('quickInvoiceProductsACH');
    if (quickInvoiceProductsCC.get('length') >= 1) {
      var defaultCC = this.get('location.default_cc');
      var useDefaultCC = false;
      quickInvoiceProductsCC.forEach(function(prod) {
        if (prod.get('id') === defaultCC) {
          useDefaultCC = true;
        }
      });
      if (!cc_product_transaction_id && useDefaultCC && this.get('quickinvoice.isNew')) {
        self.set('quickinvoice.cc_product_transaction_id', defaultCC);
      } else if (this.get('quickinvoice.isNew')) {
        self.set('quickinvoice.cc_product_transaction_id', quickInvoiceProductsCC.get('firstObject').get('id'));
      }
    } else if(self.get('quickinvoice.isNew')) {
      self.set('quickinvoice.cc_product_transaction_id', null);
    }
    if (quickInvoiceProductsACH.get('length') >= 1) {
      var defaultACH = this.get('location.default_ach');
      var useDefaultACH = false;
      quickInvoiceProductsACH.forEach(function(prod) {
        if (prod.get('id') === defaultACH) {
          useDefaultACH = true;
        }
      });
      if (!ach_product_transaction_id && useDefaultACH && this.get('quickinvoice.isNew')) {
        self.set('quickinvoice.ach_product_transaction_id', defaultACH);
      } else if (this.get('quickinvoice.isNew')) {
        self.set('quickinvoice.ach_product_transaction_id', quickInvoiceProductsACH.get('firstObject').get('id'));
      }
    } else if(self.get('quickinvoice.isNew')) {
      self.set('quickinvoice.ach_product_transaction_id', null);
    }
  },
  setSelectedTags: function() {
    var tags = this.get('quickinvoice.tags');
    if(tags) {
      var tagsText = tags.map(function(tag) {
        return tag.get('title');
      });
      this.set('selectedTags', tagsText.join(','));
    }
  },

  // Actions
  actions: {
    addFile: function(file) {
      if(this.get('atMaxAttachedFiles')) {
        this.set('tempFiles', []);
        this.set('fileQueue.files', []);
        return;
      }
      var self = this;
      var tempFile = file.blob;
      var filesArrayLength = this.get('fileQueue.files.length');
      var qiFilesArrayLength = self.get('quickinvoice.files.length') || 0;
      var tempFiles = this.get('tempFiles');
      this.set('showQIMaxFileErrorMessage', false);
      if(filesArrayLength + qiFilesArrayLength <= 4) {
        tempFiles.push(tempFile);
        if(tempFiles.length === filesArrayLength) {
          this.send('uploadFiles', tempFiles);
        }
      } else {
        this.set('showQIMaxFileErrorMessage', true);
        this.set('tempFiles', []);
        Ember.run.later(function() {
          self.set('fileQueue.files', []);
        });
      }
    },
    addItem: function() {
      var item = this.get('item');
      var itemList = this.get('itemList');
      this.set('addItemMessages', []);
      var addItemMessages = this.get('addItemMessages');
      var noTitle = !item.title || Ember.isBlank(item.title);
      var noAmount = (!item.amount && item.amount !== 0) || Ember.isBlank(item.amount);
      var amount_num = Number(item.amount.replace(/,/g, ''));
      var amount_not_number = String(amount_num) === 'NaN';
      var is_duplicate_item = itemList.find(existing_item => existing_item.title === item.title);
      if (is_duplicate_item) {
        addItemMessages.pushObject(item.title + ' is already in the item list');
      }
      if (amount_not_number) {
        addItemMessages.pushObject('Amount field must be a number');
      }
      if (noTitle) {
        addItemMessages.pushObject('Item field cannot be blank');
      }
      if (noAmount) {
        addItemMessages.pushObject('Amount field cannot be blank');
      }
      if (noTitle || noAmount || amount_not_number || is_duplicate_item) {
        return;
      } else {
        let obj = {
          title: item.title.replace('.', '_'),
          amount: item.amount
        };
        itemList.pushObject(obj);
        this.set('item', {
          title: '',
          amount: ''
        });
        jQuery('#quickinvoice-add-item').focus();
      }
    },
    back: function() {
      if(this.get('isTransitioning')) {
        return;
      }
      var contact = this.get('contact');
      var location = this.get('location');
      var isRouteContact = this.get('isRouteContact');
      var isRouteContactUser = this.get('isRouteContactUser');
      var isRouteLocation = this.get('isRouteLocation');
      var isRouteQuickInvoice = this.get('isRouteQuickInvoice');
      var router = this.get('router');

      if (isRouteQuickInvoice) {
        router.transitionTo('quickinvoice.index');
      } else if (isRouteContact) {
        router.transitionTo('contacts.quickinvoices-index', contact.get('id'));
      } else if (isRouteContactUser) {
        router.transitionTo('contactuser.quickinvoices-index');
      } else if (isRouteLocation) {
        router.transitionTo('locations.quickinvoices-index', location.get('id'));
      }
    },
    cancel: function() {
      var quickinvoice = this.get('quickinvoice');
      if (quickinvoice.get('isNew')) {
        quickinvoice.deleteRecord();
      } else {
        quickinvoice.rollbackAttributes();
        quickinvoice.set('tags', this.get('originalTags'));
        this.set('isEditing', false);
      }
      this.setItemList();
      this.send('back');
    },
    delete: function() {
      var self = this;
      var quickinvoice = this.get('quickinvoice');
      if (quickinvoice.get('active')) {
        pretty_confirm('Are you sure you want to delete this Quick Invoice?', function() {
          if (self.get('isLoading')) {
            return;
          }
          self.set('isLoading', true);
          quickinvoice.deleteRecord();
          quickinvoice.save().then(function() {
            quickinvoice.unloadRecord();
            noty({
              text: 'Quick Invoice deleted successfully!'
            });
            self.set('isLoading', false);
            self.send('back');
          }, function() {
            quickinvoice.rollbackAttributes();
            noty({
              text: 'Error deleting Quick Invoice!',
              type: 'error'
            });
            self.set('isLoading', false);
          });
        });
      } else {
        noty({
          text: 'Quick Invoice is already deleted!',
          type: 'warning'
        });
      }
    },
    deleteFile: function(file, index) {
      var self = this;
      pretty_confirm('Are you sure you want to remove ' + (file.file_name || file.name) + ' from this Quick Invoice?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        if(self.get('quickinvoice.isNew')) {
          self.get('quickinvoice.files').removeAt(index);
          self.set('isLoading', false);
        } else {
          var session = self.get('session');
          jQuery.ajax({
            url: window.UiENV.APP.FULL_URL + '/quickinvoices/' + self.get('quickinvoice.id') + '/files/' + file.id,
            type: 'DELETE',
            headers: {
              'developer-id': window.UiENV.APP.DEVELOPER_ID,
              'access-token': session.get('authenticated.token')
            },
          }).then(function() {
            noty({
              text: 'File removed successfully!',
              type: 'success'
            });
            self.get('quickinvoice.files').removeAt(index);
            self.set('isLoading', false);
          }, function() {
            noty({
              text: 'Error removing File!',
              type: 'error'
            });
            self.set('isLoading', false);
          });
        }
      });
    },
    deleteItem: function(item, index) {
      var itemList = this.get('itemList');
      var tempItemList = itemList.removeAt(index);
      this.set('itemList', tempItemList);
    },
    downloadFile: function(file) {
      if(this.get('isDownloading')) {
        return;
      }
      this.set('isDownloading', true);
      if(this.get('location.product_file.id')) {
        location.href = file.link;
      } else {
        noty({
          text: this.get('noFileStoreServiceMessage'),
          type: 'error',
          timeout: 5000
        });
      }
      this.set('isDownloading', false);
    },
    edit: function() {
      this.set('isEditing', true);
    },
    payQuickInvoice: function() {
      var payquickinvoiceController = this.get('payquickinvoiceController');
      var quickinvoice = this.get('quickinvoice');
      if(quickinvoice.get('cantPay')) {
        return;
      }

      payquickinvoiceController.set('quickinvoice', null);
      payquickinvoiceController.set('locations', null);
      payquickinvoiceController.set('accounts', null);
      payquickinvoiceController.set('contact', null);
      payquickinvoiceController.set('getTransactions', null);

      payquickinvoiceController.set('quickinvoice', quickinvoice);
      payquickinvoiceController.set('locations', this.get('locationinfos'));
      payquickinvoiceController.set('accounts', this.get('accounts'));
      payquickinvoiceController.set('contact', this.get('contact'));
      payquickinvoiceController.set('getTransactions', this.getTransactions);

      // Close any pre-existing modals
      this.get('parent').send('closeModal');
      this.get('parent').send('openModal', 'components/modals/payquickinvoice');
    },
    printQI: function() {
      printElement('quickinvoice_print_area');
    },
    save: function() {

      var quickinvoice = this.get('quickinvoice');

      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }

      jQuery('.dropdown-select.open .dropdown-toggle').dropdown('toggle'); // close any open dropdowns before proceeding. Ex: selecting/deselecting tags - changes only apply to model on dropdown close
      this.set('isLoading', true);

      var itemList = this.get('itemList');
      quickinvoice.set('item_list', {});

      var item_list = quickinvoice.get('item_list');

      var outstandingTotal = 0;

      itemList.map(function(item) {
        Ember.set(item_list, item.title.replace('.', '_'), item.amount);
        outstandingTotal += parseFloat( item.amount );
      });

      var single_payment_max_amount = String(quickinvoice.get('single_payment_max_amount'));
      var single_payment_min_amount = String(quickinvoice.get('single_payment_min_amount'));

      quickinvoice.set('amount_due', outstandingTotal);
      quickinvoice.set('single_payment_max_amount', Number(single_payment_max_amount.replace(/,/g, '')));
      quickinvoice.set('single_payment_min_amount', Number(single_payment_min_amount.replace(/,/g, '')));
      quickinvoice.set('item_list', item_list);

      var quickinvoiceTransactions = this.get('quickinvoiceTransactions');

      if (quickinvoiceTransactions) {

        quickinvoiceTransactions.map((t) => {
          outstandingTotal -= parseFloat( t.transaction_amount );
        });
  
        quickinvoice.set('remaining_balance', outstandingTotal);  
      }

      var self = this;
      quickinvoice.save().then(function() {
        noty({
          text: 'Quick Invoice saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.send('back');
      }, function() {
        noty({
          text: 'Error saving Quick Invoice!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    },
    showTrans: function(transaction) {
      var location = this.get('location');
      this.get('parent').send('showTransDetail', transaction, location);
    },
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }

      var quickinvoice = this.get('quickinvoice');
      var tagList = this.get('tagList');
      var selectedTags = [];
      if (tags.filters[0].value) {
        selectedTags = tags.filters[0].value.split(',');
        tagList.forEach(function(tag) {
          if (selectedTags.indexOf(tag.get('title')) > -1) {
            quickinvoice.get('tags').pushObject(tag);
          } else {
            quickinvoice.get('tags').removeObject(tag);
          }
        });
      } else {
        selectedTags = null;
        quickinvoice.set('tags', []);
      }
    },
    uploadFiles: function(files) {
      var self = this;
      if(!self.get('quickinvoice.files')) {
        self.set('quickinvoice.files', Ember.A());
      }
      this.set('addFileMessages', []);
      var addFileMessages = this.get('addFileMessages');
      files.forEach(function(tempFile){
        if(self.get('quickinvoice.isNew')) {
          self.get('quickinvoice.files').pushObject(tempFile);
          self.set('fileQueue.files', []);
          self.set('tempFiles', []);
        } else {
          var fd = new FormData();
          var newName = tempFile.name.replace(/[\|\[\]\/\:\?"<>\|\*\\]/g, '_');
          fd.append('file' + '[file]', tempFile, newName);
          self.set('isLoadingFile', true);
          jQuery.ajax({
            url: window.UiENV.APP.FULL_URL + '/quickinvoices/' + self.get('quickinvoice.id') + '/files',
            type: 'POST',
            headers: {
              'developer-id': window.UiENV.APP.DEVELOPER_ID,
              'access-token': self.get('session.authenticated.token')
            },
            contentType: false,
            processData: false,
            data: fd
          }).then(function(res) {
            self.set('isLoadingFile', false);
            noty({
              text: 'File added successfully!',
              type: 'success'
            });
            if(!self.get('quickinvoice.files')) {
              self.set('quickinvoice.files', Ember.A());
            }
            self.get('quickinvoice.files').pushObject(res.file);
            Ember.run.later(function() {
              self.set('fileQueue.files', []);
              self.set('tempFiles', []);
            });
          }, function(error) {
            var errorMessage = 'Error adding file to this Quick Invoice!';
            var responseJSON = error.responseJSON;
            var arr = responseJSON.errors;
            if (responseJSON.message && responseJSON.status && responseJSON.status === 422) {
              addFileMessages.push(responseJSON.message);
            }
            if(responseJSON && arr){
              for (var prop in arr) {
                setError(arr, prop);
              }
            }
            function setError(arr, prop){
              arr[prop].forEach(function(err){
                errorMessage += '<br>' + err + '.';
              });
            }
            self.set('isLoadingFile', false);
            noty({
              text: errorMessage,
              type: 'error'
            });
            Ember.run.later(function() {
              self.set('fileQueue.files', []);
              self.set('tempFiles', []);
            });
          });
        }
      });
    },

    resendQuickInvoice: function() {

      if (this.get('quickinvoice.status_id') === 0) {
        noty({
          text: 'Quick Invoice already closed.',
          type: 'error'
        });
        return;
      } else if (!this.get('quickinvoice.email')) {
        noty({
          text: 'Quick Invoice not configured with an email.',
          type: 'error'
        });
        return;
      }
      if (this.get('isLoading')) {
        return;
      }
      this.set('isResending', true);
      var self = this;
      var session = self.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + "/quickinvoices/" + this.get('quickinvoice.id') + "/resend",
        type: "POST",
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        self.set('isResending', false);
        noty({
          text: 'Email sent successful!',
          type: 'success'
        });
      }, function() {
        self.set('isResending', false);
        noty({
          text: 'Re-send has failed!',
          type: 'error'
        });
      });
    }
  }

});
