import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      recurring: store.queryRecord('recurring', {
        id: params.id,
        expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,location,contact,transactions,payment_schedule',
        active: '0,1'
      })
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return Ember.RSVP.Promise.all([
      self.get('notFound').check(models.recurring, transition, 'Recurring', self),
    ]).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: models.recurring.get('location.id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfos = data;
        }),
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('location', location);
    controller.set('locations', models.locationinfos);
    controller.set('recurring', models.recurring);
    controller.set('tagList', location.get('tags'));

    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.recurring.get('id'),
        type: 'recurring',
        attributes: {
          terms_agree: true
        }
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Recurring Billing',
      route_name: 'recurrings.view',
      route_id: models.recurring.get('id'),
    });
    controller.set('pageTitle', 'View Recurring Billing');
    controller.set('pageCrumbs', [{
      title: 'Recurring Billings',
      link: 'recurrings.index'
    }, {
      title: 'View Recurring Billing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.controller.get('recurring');
      if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
