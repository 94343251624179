import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  description: DS.attr('string'),
  height: DS.attr('string'),
  item_id: DS.attr('string'),
  length: DS.attr('string'),
  location_id: DS.attr('string'),
  price: DS.attr('string'),
  quantity: DS.attr('string'),
  tax_amount: DS.attr('string'),
  tax_rate: DS.attr('string'),
  title: DS.attr('string'),
  total: DS.attr('string'),
  weight: DS.attr('string'),
  width: DS.attr('string'),

  // Computed Properties
  display_title: Ember.computed('title', 'price', function() {
    return this.get('title') + ' ($ ' + this.get('price').currency(2) + ')';
  }),

  // Defined model relationships
  created_user: DS.belongsTo('user')
});
