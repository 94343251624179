import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_holder_name: DS.attr('string'),
  account_number: DS.attr('string'),
  account_type: DS.attr('lcstring'),
  account_vault_id: DS.attr('string'),
  ach_identifier: DS.attr('string'),
  ach_sec_code: DS.attr('string'),
  action: DS.attr('string'),
  advance_deposit: DS.attr('boolean'),
  all_tags: DS.attr('string'),
  auth_amount: DS.attr('string'),
  auth_code: DS.attr('string'),
  auth_surcharge_fee: DS.attr('string'),
  auth_surcharge_rate: DS.attr('string'),
  avs: DS.attr('string'),
  avs_enhanced: DS.attr('string'),
  batch: DS.attr('string'),
  billing_city: DS.attr('string'),
  billing_phone: DS.attr('string'),
  billing_state: DS.attr('string'),
  billing_street: DS.attr('string'),
  billing_zip: DS.attr('string'),
  card_type: DS.attr('lcstring'),
  charge_back_date: DS.attr('sqldatetime'),
  checkin_date: DS.attr('sqldate'),
  checkout_date: DS.attr('sqldate'),
  check_number: DS.attr('string'),
  clerk_number: DS.attr('string'),
  code: DS.attr('string'),
  complete: DS.attr('boolean'),
  contact_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_ts_from: DS.attr('ts'),
  created_ts_to: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  custom_data: DS.attr('array'),
  customer_ip: DS.attr('string'),
  cvv: DS.attr('string'),
  cvv_response: DS.attr('string'),
  description: DS.attr('string'),
  dl_number: DS.attr('string'),
  dl_state: DS.attr('string'),
  dob_year: DS.attr('string'),
  effective_date: DS.attr('sqldate'),
  email: DS.attr('string'),
  emv_receipt_data: DS.attr('array'),
  entry_mode_id: DS.attr('string'),
  exp_date: DS.attr('string'),
  exp_month: DS.attr('string'),
  exp_year: DS.attr('string'),
  first_six: DS.attr('string'),
  folio_num: DS.attr('string'),
  hosted_payment_page_id: DS.attr('string'),
  image_back: DS.attr('string'),
  image_front: DS.attr('string'),
  is_accountvault: DS.attr('boolean'),
  is_completable: DS.attr('boolean'),
  is_recurring: DS.attr('boolean'),
  is_refundable: DS.attr('boolean'),
  is_voidable: DS.attr('boolean'),
  is_surcharge: DS.attr('string'),
  last_four: DS.attr('string'),
  location_id: DS.attr('string'),
  log_email_id: DS.attr('string'),
  log_ts: DS.attr('dynamicts'),
  move_account_vault: DS.attr('boolean'),
  move_account_vault_transactions: DS.attr('boolean'),
  no_show: DS.attr('boolean'),
  order_num: DS.attr('string'),
  payment_method: DS.attr('string'),
  recaptcha_score: DS.attr('number'),
  po_number: DS.attr('string'),
  previous_transaction_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  quick_invoice_id: DS.attr('string'),
  reason_code_id: DS.attr('string'),
  recurring_id: DS.attr('string'),
  refunded_amount: DS.attr('string'),
  response_code_id: DS.attr('string'),
  response_message: DS.attr('string'),
  return_date: DS.attr('sqldatetime'),
  room_num: DS.attr('string'),
  room_rate: DS.attr('string'),
  routing: DS.attr('string'),
  save_account: DS.attr('boolnum'),
  save_account_title: DS.attr('string'),
  settle_date: DS.attr('sqldatetime'),
  serviceErrors: DS.attr('string'),
  ssn4: DS.attr('string'),
  status_id: DS.attr('string'),
  subtotal_amount: DS.attr('string'),
  surcharge_amount: DS.attr('string'),
  is_surcharge:DS.attr('string'),
  surcharge_id: DS.attr('string'),
  tax: DS.attr('string'),
  terminal_id: DS.attr('string'),
  terms_agree: DS.attr('boolnull'),
  timestamp: DS.attr('ts'),
  tip_amount: DS.attr('string'),
  track_data: DS.attr('string'),
  transaction_batch_id: DS.attr('string'),
  trans_class: DS.attr('string'),
  transaction_amount: DS.attr('string'),
  transaction_api_id: DS.attr('string'),
  trx_source_id : DS.attr('string'),
  type_id: DS.attr('string'),
  verbiage: DS.attr('string'),
  void_date: DS.attr('sqldatetime'),

  // Custom fields
  transaction_c1: DS.attr('string'),
  transaction_c2: DS.attr('string'),
  transaction_c3: DS.attr('string'),

  // Computed Properties
  entry_mode: Ember.computed('entry_mode_id', function() {
    switch (this.get('entry_mode_id')) {
      case 'B':
        return 'Bar Code';
      case 'S':
        return 'Swiped';
      case 'K':
        return 'Keyed';
      case 'C':
        return 'Chip Card';
      case 'P':
        return 'Contactless';
      case 'F':
        return 'Fallback';
      default:
        return 'Unknown';
    }
  }),
  hasSigPad: Ember.computed(function() {
    return window.sessionStorage.sigpad;
  }),
  isACH: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'ach';
  }),
  isCC: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'cc';
  }),
  isForce: Ember.computed('action', function() {
    return this.get('action') === 'force';
  }),
  is_level3_eligible: Ember.computed('status_id', 'account_type', 'transaction_level3.id', function() {
    var account_type = this.get('account_type');
    var status_id = this.get('status_id');
    var not_eligible_statuses = ['111', '301'];
    var level3data = this.get('transaction_level3.id');
    return not_eligible_statuses.indexOf(status_id) === -1 && (account_type === 'mc' || account_type === 'visa') && level3data;
  }),
  isSuccess: Ember.computed('status_id', function() {
    var status_id = this.get('status_id');
    return ((status_id) ? String(status_id).substr(0, 1) === '1' : false);
  }),
  isTransRefundable: Ember.computed('created_ts', function() {
    var created_ts = this.get('created_ts');
    var transTs = moment(created_ts);
    var nowTs = moment();
    if (Math.abs(transTs.diff(nowTs, 'days')) > 180) {
      return false;
    } else {
      return true;
    }
  }),
  needSignature: Ember.computed('status_id', 'status.id', 'signature', 'created_ts', 'hasSigPad', function() {
    if (!this.get('hasSigPad') || this.get('hasSigPad') === 'false') {
      return false;
    }
    var ts = this.get('created_ts');
    if (ts) {
      var transTs = moment(ts);
      var nowTs = moment();
      if (Math.abs(transTs.diff(nowTs, 'minutes')) > 15) {
        console.log('Transaction time expired for capturing signature.');
        return false;
      }
    }
    var signature = this.get('signature.id');
    if (signature) {
      console.log('Transaction has signature on file.');
      return false;
    }
    var status_id = this.get('status.id');
    return ((status_id) && status_id.substr(0, 1) === '1' && this.get('payment_method') === 'cc');
  }),
  tags_csv: Ember.computed('tags.[]', function() {
    var tags = this.get('tags');
    var tag_csv = '';
    tags.forEach(function(tag) {
      tag_csv += tag.get('title') + ',';
    });
    return tag_csv.replace(/\,$/, '');
  }),
  tags_display: Ember.computed('tags.[]', function() {
    var tags = this.get('tags');
    var tag_csv = '';
    tags.forEach(function(tag, index, tags) {
      tag_csv += tag.get('title');
      if (index < tags.get('length') - 1) {
        tag_csv += ', ';
      }
    });
    return tag_csv.replace(/\,$/, '');
  }),
  title: Ember.computed('id', function() {
    return this.get('id');
  }),
  transClass: Ember.computed('status.id', function() {
    var status_id = this.get('status.id');
    return 'table-striped-status' + ((status_id) ? status_id.substr(0, 1) : '');
  }),

  // Defined model relationships
  account_vault: DS.belongsTo('accountvault'),
  changelogs: DS.hasMany('changelog'),
  contact: DS.belongsTo('contact'),
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  hosted_payment_page: DS.belongsTo('hostedpaymentpage'),
  log_emails: DS.hasMany('logemail'),
  log_email_recipients: DS.hasMany('logemailrecipient'),
  postback_logs: DS.hasMany('postbacklog'),
  product_transaction: DS.belongsTo('producttransaction'),
  quick_invoice: DS.belongsTo('quickinvoice'),
  recurring: DS.belongsTo('recurring'),
  reason_code: DS.belongsTo('reasoncode', {
    async: true
  }),
  signature: DS.belongsTo('signature'),
  status: DS.belongsTo('status', {
    async: true
  }),
  surcharge: DS.belongsTo('surcharge'),
  tags: DS.hasMany('tag'),
  terminal: DS.belongsTo('terminal'),
  transaction_batch: DS.belongsTo('transactionbatch'),
  transaction_histories: DS.hasMany('transactionhistory'),
  transaction_level3: DS.belongsTo('transactionlevel3'),
  type: DS.belongsTo('type', {
    async: true
  })
});
