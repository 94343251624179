import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  accountsview: Ember.inject.controller('accounts/view'),
  contactsaccountsview: Ember.inject.controller('contacts/accounts-view'),
  contactscharge: Ember.inject.controller('contacts/charge'),
  contactsdashboard: Ember.inject.controller('contacts/dashboard'),
  contactsquickinvoicesview: Ember.inject.controller('contacts/quickinvoices-view'),
  contactsrecurringsview: Ember.inject.controller('contacts/recurrings-view'),
  contactstransactions: Ember.inject.controller('contacts/transactions'),
  contactuserdashboard: Ember.inject.controller('contactuser/dashboard'),
  contactusermakepayment: Ember.inject.controller('contactuser/makepayment'),
  contactuserpaymenthistory: Ember.inject.controller('contactuser/paymenthistory'),
  locationsaccountsview: Ember.inject.controller('locations/accounts-view'),
  locationsquickinvoicesview: Ember.inject.controller('locations/quickinvoices-view'),
  locationsrecurringsview: Ember.inject.controller('locations/recurrings-view'),
  locationstransactions: Ember.inject.controller('locations/transactions'),
  locationsunsettledtransactions: Ember.inject.controller('locations/reports-cc-unsettledtransactions'),
  quickinvoiceview: Ember.inject.controller('quickinvoice/view'),
  recurringsview: Ember.inject.controller('recurrings/view'),
  reportstransactions: Ember.inject.controller('reports/transactions'),
  reportstransactionslegacy: Ember.inject.controller('reports/transactionslegacy'),
  sessionService: Ember.inject.service('session'),
  transactiondetailfull: Ember.inject.controller('transactiondetailfull'),
  virtualterminal: Ember.inject.controller('virtualterminal'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),

  trxTotal: Ember.computed('transaction_tax', 'trxSubTotal', 'transaction_tip_amount', function() {
    var transaction_tax = this.get('transaction_tax');
    var transaction_tip_amount = this.get('transaction_tip_amount');
    // Do some chesks to see if values only include a decimal, if so, number calculations don't work properly
    if (this.get('trxSubTotal') === '.' || this.get('transaction_tax') === '.' || this.get('transaction_tip_amount') === '.') {
      return;
    }
    var subtotal_amount = this.get('trxSubTotal') || 0;
    var tax_amount = transaction_tax || 0;
    var tip_amount = transaction_tip_amount || 0;
    var trxAmount = String((parseFloat(subtotal_amount) + parseFloat(tax_amount) + parseFloat(tip_amount)).toFixed(2));
    return trxAmount;
  }),

  tipAdjustCancelled: function(context) {
    var transaction = context.get('transaction');
    transaction.rollbackAttributes();
    context.set('transaction', null);
    context.set('transaction_tip_amount', null);
  },

  actions: {
    save: function() {
      var transaction = this.get('transaction');
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      transaction.set('tip_amount', this.get('transaction_tip_amount'));
      transaction.set('transaction_amount', this.get('trxTotal'));
      transaction.set('action', 'tipadjust');
      this.set('isLoading', true);
      var locationinfo = this.get('locationinfo');
      var self = this;
      transaction.save().then(
        function() {
          var sender = self.get('sender');
          if (sender) {
            var senderController = self.get(sender);
            if (senderController) {
              senderController.set('needsRefresh', new moment().unix());
            }
          }
          if (sender !== 'transactiondetailfull') {
            self.send('showTransDetail', transaction, locationinfo);
          } else {
            self.send('closeModal');
          }
          self.set('isLoading', false);
        },
        function() {
          noty({
            text: 'Error adjusting tip!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        }
      );
    }
  }

});
