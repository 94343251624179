import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  isLoading: false,

  isLoggedInUser: Ember.computed('session.authenticated.user_id', 'user.id', function() {
    var userId = this.get('user.id');
    var loggedInUserId = this.get('session.authenticated.user_id');
    return userId === loggedInUserId ? true : false;
  }),
  userrreportsList: Ember.computed('userreports.@each', 'session.authenticated.user_id', function() {
    return this.setIsReportOwner('userreports', 'created_user_id');
  }),
  userrreportsharesList: Ember.computed('userreportshares.@each', 'session.authenticated.user_id', function() {
    return this.setIsReportOwner('userreportshares', 'user_report.created_user_id');
  }),

  setIsReportOwner: function(model, prop) {
    var loggedInUserId = this.get('session.authenticated.user_id');
    var reports = this.get(model);
    if (reports) {
      var tempReports = reports.map(function(report) {
        if (report.get(prop) === loggedInUserId) {
          report.set('isReportOwner', true);
        } else {
          report.set('isReportOwner', false);
        }
        return report;
      });
      return tempReports;
    } else {
      return [];
    }
  },

  // Actions
  actions: {
    deleteUserreport: function(report) {
      var self = this;
      var deleteMessage;
      var isDeletingDefault = false;
      if (report.get('title') === 'Default') {
        isDeletingDefault = true;
        deleteMessage = 'Deleting this report will revert it back to system default. Are you sure you want to delete this report?';
      } else {
        deleteMessage = 'Are you sure you want to delete this report?';
      }
      pretty_confirm(deleteMessage, function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        report.deleteRecord();
        report.save().then(function() {
          noty({
            text: 'Report deleted successfully!'
          });
          self.send('updateUserreportshares');
          self.set('isLoading', false);
        }, function() {
          report.rollbackAttributes();
          noty({
            text: 'Error deleting Report!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    shareUserreport: function(report) {
      var self = this;
      this.get('store').query('userreportshare', {
        user_report_id: report.id,
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }).then(function(data) {
        self.send('showUserreportShareModal', report, data);
      }, function() {
        noty({
          text: 'Error getting shared userreports!',
          type: 'error',
          timeout: '5000'
        });
      });
    },
    showUserreportShareModal: function(report, data) {
      this.showUserreportShareModal(report, data);
    },
    unlinkUserreportshare: function(report) {
      var self = this;
      var session = this.get('session');
      pretty_confirm('Are you sure you want to delete this Shared Report?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        report.deleteRecord();
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/userreportshares/' + report.get('id'),
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          noty({
            text: 'Shared Report deleted successfully!'
          });
          self.send('updateUserreportshares');
          self.set('isLoading', false);
        }, function() {
          report.rollbackAttributes();
          noty({
            text: 'Error deleting Shared Report!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    updateUserreports: function() {
      var self = this;
      self.set('isLoading', true);
      this.get('store').query('userreport', {
        created_user_id: self.get('user.id'),
        page_size: '500',
        sort: 'title'
      }).then(function(data) {
        self.set('userreports', data);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error updating reports!',
          type: 'error'
        });
        self.set('isLoading', false);
        return null;
      });
    },
    updateUserreportshares: function() {
      var self = this;
      self.set('isLoading', true);
      this.get('store').query('sharedreport', {
        user_id: self.get('user.id'),
        expand: 'user_report',
        page_size: '500',
        sort: 'user_report.title'
      }).then(function(data) {
        self.set('userreportshares', data);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error updating shared reports!',
          type: 'error'
        });
        self.set('isLoading', false);
        return null;
      });
    },
  }

});
