import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  },

  // Computed Properties
  columns: Ember.computed('isMobileSize', 'locations.[]', 'hasCCandACH', 'hasProductsACH', 'hasProductsCC', 'route', function() {
    var self = this;
    var route = this.get('route');
    var router = this.get('router');
    var session = this.get('session');
    var columns = {
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_full_name: {
        'name': 'contact.full_name',
        'title': 'Contact<br>Name',
        'type': 'string',
        'sortable': false,
        'action': function(account) {
          router.transitionTo('contacts.dashboard', account.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      payment_method: {
        'name': 'payment_method',
        'title': 'Payment<br>Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'paymentMethods',
          multi: true,
          showAllSelect: true
        }
      },
      exp_date: {
        'name': 'exp_date',
        'title': 'Expired<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      ach_sec_code: {
        'name': 'ach_sec_code',
        'title': 'SEC<br>Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'all_ach_sec_codes'
        },
        'showACHSECCodeInfo': true
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(account, action) {
          self.viewAccount(account, action);
        },
        'actionId': 'account_vault_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      active: {
        'name': 'active',
        'title': 'Active',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNo',
          multi: true,
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created<br>Date',
        'type': 'date',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      created_user_id: {
        'name': 'created_user_id',
        'title': 'Created By',
        'type': 'string',
        'sortable': false,
        'filter': {
          type: 'dropdown',
          items: 'users',
          multi: true,
          showAllSelect: true
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'action': function(account) {
          router.transitionTo('locations.view', account.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'locationsWithAccountVault',
          multi: true,
          showAllSelect: true
        }
      },
      actionbutton: {
        'sortable': false,
        'component': 'action-button',
        'isActionColumn': true,
        'params': {
          btnClass: 'btn-group-xs',
          align: 'right',
          type: 'accountvault'
        },
        'extra_data': {
          locations: self.get('locations'),
          contact: self.get('contact')
        },
        'actions': {
          add: function(account) {
            if (route === 'contacts.accounts-index') {
              router.transitionTo('contacts.recurrings-add', self.get('contact.id')).then(function(Route) {
                Route.controller.set('force_account', account);
              });
            } else if (route === 'accounts.index') {
              router.transitionTo('recurrings.add').then(function(Route) {
                Route.controller.set('force_account', account);
              });
            } else if (route === 'locations.accounts-index') {
              router.transitionTo('locations.recurrings-add', account.get('location_id')).then(function(Route) {
                Route.controller.set('force_account', account);
              });
            } else if (route === 'contactuser.accounts-index') {
              router.transitionTo('contactuser.recurrings-add').then(function(Route) {
                Route.controller.set('force_account', account);
              });
            }
          },
          delete: function(account) {
            if (account.get('has_recurring')) {
              return;
            }
            pretty_confirm('Are you sure want to delete this Account Vault?', function() {
              // Bail if we are already loading
              if (self.get('isLoading')) {
                noty({
                  text: 'Cannot delete Account Vaults with active Recurring Billing!',
                  type: 'error'
                });
                return;
              }
              account.destroyRecord().then(function() {
                account.unloadRecord();
                noty({
                  text: 'Account Vault deleted successfully!'
                });
                self.set('isLoading', false);
                self.get('parent').send('gridNeedsRefresh', self.get('parent'));
              }, function(data) {
                var errorMessage = 'Error deleting Account Vault!';
                if(data.errors){
                  data.errors.forEach(function(err){
                    errorMessage += '<br>' + err.detail + '.';
                  });
                }
                account.rollbackAttributes();
                self.get('parent').send('gridNeedsRefresh', self.get('parent'));
                noty({
                  text: errorMessage,
                  type: 'error',
                  timeout: 10000
                });
                self.set('isLoading', false);
              });
            });
          },
          launchModal: function(model, modal, other) {
            if(modal === 'virtualterminal') {
              self.launchvt(other, model);
            } else if(modal === 'signature') {
              self.getSignature(model, other);
            }
          },
          view: function(account, action) {
            self.viewAccount(account, action);
          }
        }
      },
    };
    if (route === 'contactuser.accounts-index') {
      delete columns.account_holder_name.filter;
      delete columns.account_type.filter;
      delete columns.last_four.filter;
      delete columns.exp_date.filter;
      delete columns.title.filter;
    }

    var listOfCols = ['title', 'contact_full_name', 'account_holder_name', 'account_type', 'last_four', 'payment_method', 'exp_date', 'ach_sec_code', 'active', 'created_ts', 'created_user_id', 'location_id', 'actionbutton'];
    var listOfMobileCols = ['title', 'contact_full_name', 'account_holder_name', 'account_type', 'last_four', 'payment_method', 'exp_date', 'ach_sec_code', 'active', 'created_ts', 'created_user_id', 'location_id', 'actionbutton'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (context.get('locations.length') <= 1 || route !== 'accounts.index') {
        colsArray.removeObject(columns.location_id);
      }
      if (route === 'contacts.accounts-index' || route === 'contactuser.accounts-index') {
        colsArray.removeObject(columns.contact_full_name);
      }
      if (!context.get('hasProductsACH') || route === 'contactuser.accounts-index') {
        colsArray.removeObject(columns.ach_sec_code);
      }
      if (!context.get('hasProductsCC')) {
        colsArray.removeObject(columns.exp_date);
      }
      if (!context.get('hasCCandACH') || route === 'contactuser.accounts-index') {
        colsArray.removeObject(columns.payment_method);
      }
      if (route === 'contactuser.accounts-index') {
        colsArray.removeObject(columns.active);
      }
      if (route !== 'locations.accounts-index') {
        colsArray.removeObject(columns.created_ts);
        colsArray.removeObject(columns.created_user_id);
      }
    }
  }),

  hasCCandACH: Ember.computed('hasProductsACH', 'hasProductsCC', function() {
    return this.get('hasProductsACH') && this.get('hasProductsCC');
  }),
  hasLocationsWithAccountVault: Ember.computed('locationsWithAccountVault.[]', function() {
    return this.get('locationsWithAccountVault.length') >= 1;
  }),
  hasProductsACH: Ember.computed('locations.@each.hasProductACH', 'locations.[]', function() {
    var hasACH = false;
    this.get('locations').forEach(function(location) {
      if (location.get('hasProductACH')) {
        hasACH = true;
      }
    });
    return hasACH;
  }),
  hasProductsCC: Ember.computed('locations.@each.hasProductCC', function() {
    var hasCC = false;
    this.get('locations').forEach(function(location) {
      if (location.get('hasProductCC')) {
        hasCC = true;
      }
    });
    return hasCC;
  }),
  isMultiLocation: Ember.computed('hasLocationsWithAccountVault', 'route', function() {
    return this.get('hasLocationsWithAccountVault') && this.get('route') === 'accounts.index';
  }),
  locationsWithAccountVault: Ember.computed('locations', function() {
    return this.get('locations').filter(function(location) {
      return location.get('hasProductAccountVault');
    });
  }),
  noAccountVaultMessage: Ember.computed('hasLocationsWithAccountVault', 'route', function() {
    if (!this.get('hasLocationsWithAccountVault')) {
      var message = 'Account Vault Service is not configured for ';
      var route = this.get('route');
      if (route === 'locations.accounts-index') {
        message += 'this location.';
      } else if (route === 'accounts.index') {
        message += 'any of your locations.';
      } else if (route === 'contacts.accounts-index') {
        message += 'this contact\'s location.';
      } else if (route === 'contactuser.accounts-index') {
        message += 'your location.<br> Please contact ' + this.get('locations').get('firstObject').get('name') + ' for alternatives.';
      }
      if (route !== 'contactuser.accounts-index') {
        message += '<br> Please contact support if you need assistance enabling Account Vault Service.';
      }
      return message;
    } else {
      return;
    }
  }),
  selected_contact_id: Ember.computed('contact_id', function() {
    var contact_id = this.get('contact_id');
    return Ember.isArray(contact_id) ? contact_id.join() : contact_id;
  }),
  selected_location_id: Ember.computed('route', 'locationsWithAccountVault', 'location_id', function() {
    var location_id = this.get('location_id');
    if (this.get('route') !== 'contacts.recurrings-index' && this.get('route') !== 'contactuser.recurrings-index') {
      if (location_id) {
        return location_id;
      } else {
        var locationsWithAccountVault = this.get('locationsWithAccountVault');
        var tempLocationIds = [];
        if (locationsWithAccountVault && locationsWithAccountVault.get('length')) {
          tempLocationIds = locationsWithAccountVault.map(function(location) {
            return location.get('id');
          });
          return tempLocationIds.join(',');
        }
      }
    }
  }),
  showContactFilter: Ember.computed('route', 'session.authenticated.resources.v2-contacts-get', function() {
    return this.get('route') !== 'contacts.accounts-index' && this.get('route') !== 'contactuser.accounts-index' && this.get('session.authenticated.resources.v2-contacts-get');
  }),

  // Functions

  getSignature: function(account) {
    var controller = this.get('parent').get('authagreementController');
    controller.set('authagreementType', null);
    controller.set('model', null);
    controller.set('location', null);
    controller.set('product', null);
    controller.set('authagreementType', 'account');
    var payment_method = account.get('payment_method');
    var locations = this.get('locations');
    var location = locations.filter(function(loc) {
      return loc.get('id') === account.get('location_id');
    }).get('firstObject');
    var product = payment_method === 'cc' ? location.get('defaultProductCC') : location.get('defaultProductACH');
    var signatureLegal = product.get('receipt_add_account_above_signature');
    controller.set('product', product);

    if (signatureLegal) {
      controller.set('model', account);
      controller.set('location', location);
      controller.set('routing', null);

      if (account.get('payment_method') === 'ach') {
        if(this.get('session.authenticated.resources.v2-routingnumbers-get')) {
          var routings = this.get('store').query('routingnumber', {
            routing: account.get('routing'),
            page_size: 1
          });
          routings.then(function(result) {
            controller.set('routing', result.get('firstObject'));
          });
        } else {
          controller.set('routing', null);
        }
      }

      this.get('parent').send('openModal', 'components/modals/authagreement');
    }
  },
  launchvt: function(trans_type, account) {
    var route = this.get('route');
    var isContactUser = this.get('session.authenticated.isContact');
    var vtcController = this.get('vtcController');
    var contact = this.get('contact');

    vtcController.set('action', trans_type);
    vtcController.set('modal_title', isContactUser ? 'Make a Payment' : trans_type.capitalize() + ' Transaction');
    vtcController.set('account', account);
    vtcController.set('disable_deposit_account', isContactUser ? true : false);
    vtcController.set('disable_tags', isContactUser ? true : false);
    vtcController.set('hide_transaction_action', isContactUser ? true : false);
    vtcController.set('hide_lodging_fields', isContactUser ? true : false);
    vtcController.set('show_location_name', (isContactUser || this.get('route') !== 'accounts.index') ? false : true);
    vtcController.set('show_contact_name', (isContactUser || !account.get('contact_id')) ? false : true);
    if(route === 'contactuser.accounts-index') {
      vtcController.set('route', 'contactuser');
    } else if(route === 'contacts.accounts-index') {
      vtcController.set('route', 'contact');
    } else if(route === 'locations.accounts-index') {
      vtcController.set('route', 'locations');
    } else if(route === 'accounts.index') {
      vtcController.set('route', 'accounts');
    }

    if (contact) {
      vtcController.set('contact', contact);
    } else if (account.get('contact.id')) {
      vtcController.set('contact', account.get('contact'));
    } else {
      vtcController.set('contact', null);
    }

    var locations = this.get('locations');
    var location_id = account.get('location_id');
    var location = locations.filter(function(loc) {
      return loc.get('id') === location_id;
    });
    vtcController.set('locations', location);
    this.get('parent').send('openModal', 'components/modals/virtualterminalcontact');
  },
  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },
  viewAccount: function(account, action) {
    var isEditing = action === 'edit';
    var route = this.get('route');
    var router = this.get('router');
    if (route === 'contacts.accounts-index') {
      router.transitionTo('contacts.accounts-view', this.get('contact.id'), account.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'accounts.index') {
      router.transitionTo('accounts.view', account.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'locations.accounts-index') {
      router.transitionTo('locations.accounts-view', account.get('location_id'), account.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'contactuser.accounts-index') {
      router.transitionTo('contactuser.accounts-view', this.get('contact.id'), account.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    }
  },

  // Actions
  actions: {},

});
