import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  client_id: DS.attr('string'),
  src: DS.attr('string'),
  user_id: DS.attr('string'),
  location_id: DS.attr('string'),
  integration_api_id: DS.attr('string'),
  custom_data: DS.attr('array'),

});
