import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  pay_multiple_invoices: Ember.computed('quickinvoice', function() {
    return this.get('quickinvoice._pay_multiple_invoices');
  }),
  totalPaymentAmount: Ember.computed(function() {
    return this.quickinvoice._totalPaymentAmount;
  }),
  pagerParams: {
    pageRoute: 'components.modals.payquickinvoice'
  },

  // Actions
  actions: {}
});
