import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  created_ts: DS.attr('ts'),
  alert_message: DS.attr('string'),
  alert_type_id: DS.attr('number'),
  date_end: DS.attr('sqldatetime'),
  date_start: DS.attr('sqldatetime'),
  description: DS.attr('string'),
  include_children: DS.attr('boolnum'),
  location_id: DS.attr('string'),
  notification_id: DS.attr('string'),
  user_contact: DS.attr('boolnum'),
  user_location: DS.attr('boolnum'),

  // Computed Properties
  alert_type_title: Ember.computed('alert_type_id', function() {
    var id = this.get('alert_type_id');
    var title = '';
    if (id === 0) {
      title = 'Pop Up Message';
    } else if (id === 1) {
      title = 'Red Banner';
    } else if (id === 2) {
      title = 'Yellow Banner';
    } else if (id === 3) {
      title = 'Blue Banner';
    } else if (id === 4) {
      title = 'Green Banner';
    }
    return title;
  }),

  // Defined model relationships
  alert_type: DS.belongsTo('alerttype', {
    async: true
  }),
  created_user: DS.belongsTo('user'),
});
