import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.contact_id,
        expand: 'user,email_blacklist'
      }),
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfos = data;
        }),
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('locationinfos', models.locationinfos);
    controller.set('location', location);
    controller.set('tagList', location.get('tags'));

    var product_transactions = [];
    models.locationinfos.forEach(function(loc) {
      if(loc.get('hasProductRecurring')) {
        var prods = loc.get('product_transactions');
        if (prods) {
          prods.forEach(function(product_transaction) {
            product_transactions.push({
              'id': product_transaction.get('id'),
              'title': product_transaction.get('title'),
              'processor': product_transaction.get('processor'),
              'receipt_add_recurring_above_signature': product_transaction.get('receipt_add_recurring_above_signature'),
              'surcharge': product_transaction.get('surcharge')
            });
          });
        }
      }
    });
    controller.set('product_transactions', product_transactions);

    var pagerParams = {
      pageRoute: 'contacts.recurrings-index',
      pageModelId: null,
      modelName: 'recurring',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      contact_id: models.contact.get('id'),
      expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,contact,location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Recurring Billings',
      route_name: 'contacts.recurrings-index',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    if (location.get('hasProductRecurring')) {
      controller.set('pageSubtitle', {
        title: 'Add Recurring Billing',
        link: 'contacts.recurrings-add',
        record: models.contact.get('id'),
        resource: this.get('session.authenticated.resources.v2-recurrings-post')
      });
    }
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Recurring Billings',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
