import Ember from 'ember';

export default Ember.Component.extend({
  tagName: 'tr',

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('item.errors.cost', function() {
    this.set('errorMessages', this.get('item.errors.messages'));
  }),

  // Computed Properties
  title: Ember.computed('item', function() {
    return this.get('item.item.title');
  }),
  cost: Ember.computed('item', function() {
    return this.get('item.cost');
  }),
  isFee: Ember.computed('item', function() {
    return this.idContains('fee');
  }),
  isRate: Ember.computed('item', function() {
    return this.idContains('rate');
  }),
  isIncluded: Ember.computed('item', function () {
    return this.idContains('included');
  }),

  // Functions
  idContains: function(txt) {
    var id = this.get('item.item.id').toLowerCase();
    return id.indexOf(txt) !== -1;
  },
});
