import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransactionSurcharge: true,
  isLocationsGear: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransaction.errors.messages', function() {
    this.set('errorMessages', this.get('producttransaction.errors.messages'));
  }),
  updateErrorMessagesSurcharge: Ember.observer('surcharge.errors.messages', function() {
    this.set('errorMessagesSurcharge', this.get('surcharge.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  isEditingSurcharge: false,
  showSurcharge: false,
  isActive: Ember.computed('producttransaction.active', function() {
    return this.get('producttransaction.active');
  }),
  notActive: Ember.computed('producttransaction.active', function() {
    return !this.get('producttransaction.active');
  }),

  surchargeUserTypes: [{
    id: '',
    title: 'All Users'
  }, {
    id: '100',
    title: 'Contact Users'
  }, {
    id: '200',
    title: 'Location Users'
  }],
  iSurchargeTypes: [{
    id: 'Surcharge',
    title: 'Surcharge'
  },
  {
    id: 'Convenience Fee',
    title: 'Convenience Fee'
  }],

  surchargeMessages: Ember.computed('producttransaction.surcharge_id', 'producttransaction.hasTip', 'producttransaction.quick_invoice_allow', 'producttransaction.hosted_payment_page_allow', function() {
    var producttransaction = this.get('producttransaction');
    var messages = [];
    if (producttransaction.get('hasTip')) {
      messages.push('Surcharge cannot be added if tip is enabled!');
    }
    return messages;
  }),

  // All form actions
  actions: {
    addSurcharge: function() {
      var producttransaction = this.get('producttransaction');
      if (producttransaction.get('surcharge_id')) {
        return;
      }

      this.set('surcharge', this.get('store').createRecord('surcharge', {
        apply_to_user_type_id: '',
        is_surcharge: '',
        max_fee_amount: '9999.99',
        max_transaction_amount: '99999.99',
        min_fee_amount: '0.00',
        product_transaction_id: producttransaction.get('id'),
        refund_surcharges: false,
        surcharge_label: 'Convenience Fee',
        surcharge_on_recurring: false
      }));

      this.set('showSurcharge', true);
      this.set('isEditingSurcharge', true);
    },
    cancelSurcharge: function() {
      var surcharge = this.get('surcharge');
      if (surcharge.get('hasDirtyAttributes') && !surcharge.get('isNew')) {
        surcharge.rollbackAttributes();
      } else if (surcharge.get('isNew')) {
        surcharge.deleteRecord();
      }
      var producttransaction = this.get('producttransaction');
      if (producttransaction.get('surcharge_id')) {
        this.set('isEditingSurcharge', false);
      } else {
        this.set('showSurcharge', false);
      }
    },
    deleteSurcharge: function() {
      var self = this;
      pretty_confirm('Are you sure want to delete Surcharge?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        var surcharge = self.get('surcharge');
        surcharge.deleteRecord();
        surcharge.save().then(function() {
          noty({
            text: 'Surcharge deleted successfully!'
          });
          self.send('refreshData');
          self.set('showSurcharge', false);
          self.set('isLoading', false);
        }, function() {
          surcharge.rollbackAttributes();
          noty({
            text: 'Error deleting Surcharge!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    editSurcharge: function() {
      this.set('isEditingSurcharge', true);
    },
    saveSurcharge: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var surcharge = this.get('surcharge');

      if (!surcharge.get('isNew')) {
        surcharge.set('product_transaction_id', null);
      }
      surcharge.save().then(function() {
        noty({
          text: 'Surcharge saved successfully!'
        });
        self.send('refreshData');
        self.set('isLoading', false);
        self.set('isEditingSurcharge', false);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
  }
});
