import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        expand: 'product_transactions,branding_domain',
        id: params.id,
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var store = this.get('store');
    controller.set('location', models.location);

    controller.set('isEditing', true);
    var self = this;
    var locationbillingaccount = store.createRecord('locationbillingaccount', {
      account_type: 'checking',
      created_user_id: self.get('session.authenticated.user_id'),
      exp_date: null,
      is_company: '0',
      location_id: models.location.get('id'),
      payment_method: 'ach',
      portfolio_id: null,
      routing: null
    });
    controller.set('locationbillingaccount', locationbillingaccount);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Billing Account',
      route_name: 'locations.locationbillingaccount-add',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Billing Accounts',
      link: 'locations.locationbillingaccount-index',
      record: models.location.get('id')
    }, {
      title: 'Add Billing Account',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
