import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var modelFields = [{
      id: 'first_name',
      value: ''
    }, {
      error: false,
      id: 'last_name',
      required: true,
      value: ''
    }, {
      id: 'account_number',
      value: ''
    }, {
      id: 'email',
      value: ''
    }, {
      id: 'company_name',
      value: ''
    }, {
      id: 'address',
      value: ''
    }, {
      id: 'city',
      value: ''
    }, {
      id: 'state',
      value: ''
    }, {
      id: 'zip',
      value: ''
    }, {
      id: 'home_phone',
      value: ''
    }, {
      id: 'office_phone',
      value: ''
    }, {
      id: 'office_ext_phone',
      value: ''
    }, {
      id: 'cell_phone',
      value: ''
    }, {
      id: 'contact_api_id',
      value: ''
    }, {
      id: 'contact_balance',
      value: ''
    }];
    controller.set('contact', this.get('store').createRecord('contact'));
    controller.set('results', null);
    controller.set('location_id', null);
    controller.set('modelFields', modelFields);
    controller.set('headerFields', null);
    controller.set('updateExisting', true);
    controller.set('isLoading', false);
    controller.set('errorMessages', []);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Import Contacts',
      route_name: 'admin.contacts.import',
      route_id: null
    });
    controller.set('pageTitle', 'Import Contacts');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Import Contacts',
      link: ''
    }]);
  },

  // Actions
  actions: {}
});
