import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  isRefreshing: true,

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'location_id',
    'product_transaction_id',
    'trx_source_id',
    'total_amount',
    'total_count'
  ],

  availableProducts: Ember.computed('locations.[]', 'location_id', function() {
    var locations = this.get('locations');
    var selectedLocations = this.get('location_id') ? this.get('location_id').split(',') : [];
    var product_transactions = [];
    if(locations){
      locations.forEach(function(loc) {
        var prods = loc.get('product_transactions');
        if (selectedLocations.indexOf(loc.get('id')) !== -1 && prods) {
          prods.forEach(function(product_transaction) {
            product_transactions.push(Ember.Object.extend({
              'id': product_transaction.get('id'),
              'location_name': loc.get('title'),
              'title': product_transaction.get('title')
            }).create());
          });
        }
      });
    }
    return product_transactions;
  }),
  availableProductIds: Ember.computed('availableProducts.[]', 'availableProducts.@each', function() {
    if(this.get('availableProducts.length')) {
      var tempProducts = this.get('availableProducts').map(function(prod){
        return prod.get('id');
      });
      return tempProducts;
    } else {
      return [];
    }
  }),
  showLocations: Ember.computed('locations.[]', function() {
    return this.get('locations.length') > 1;
  }),
  showProducts: Ember.computed('availableProducts.[]', 'location_id', function() {
    return this.get('availableProducts.length') > 1 && this.get('location_id');
  }),
  showOtherFilters: Ember.computed('showLocations', 'showProducts', function() {
    return this.get('showLocations') || this.get('showProducts');
  }),

  availableProductIdsObserver: Ember.observer('availableProductIds.[]', 'product_transaction_id', function() {
    var availableProductIds = this.get('availableProductIds');
    var selectedProducts = this.get('product_transaction_id');
    var self = this;
    var selectedAreInAvailable = false;
    if(selectedProducts && availableProductIds.length) {
      selectedProducts.split(',').map(function(prod){
        if(availableProductIds.indexOf(prod) !== -1) {
          selectedAreInAvailable = true;
        }
      });
      if(!selectedAreInAvailable) {
        self.set('product_transaction_id', null);
      }
    }
  }),

  columns: Ember.computed('isMobileSize', 'showLocations', function() {
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Date',
        'type': 'dateNoTimezoneConvert',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      payment_source: {
        'name': 'trx_source_id',
        'title': 'Payment Source',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'trans_sources',
          multi: true
        }
      },
      total_amount: {
        'name': 'total_amount',
        'title': 'Total Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right'
      },
      total_count: {
        'name': 'total_count',
        'title': 'Total Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right'
      }
    };

    var listOfCols = ['created_ts', 'payment_source', 'total_amount', 'total_count'];
    var listOfMobileCols = ['created_ts', 'payment_source', 'total_amount', 'total_count'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      return cols;
    }

  }),

  // Actions
  actions: {}
});
