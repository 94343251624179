import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  checked: DS.attr('boolean'),
  created_ts: DS.attr('ts'),
  description: DS.attr('string'),
  location_id: DS.attr('string'),
  title: DS.attr('string'),
  visibilitygroup_id: DS.attr('string'),

  // Defined model relationships
  file_categories: DS.hasMany('filecategory')
});
