import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  isLocations: true,
  isLocationsTransactionachsettlementsummary: true,

  // Set controller params
  sort: '-event_date',
  page: 1,
  page_size: '15',
  export_version: 'v1',
  isRefreshing: true,
  needsRefresh: '',
  event_date: 'today',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'credit_amount',
    'credit_count',
    'debit_amount',
    'debit_count',
    'deposit_amount',
    'event_date',
    'event_date_from',
    'event_date_to',
    'location_id',
    'needsRefresh',
    'product_transaction_id',
    'refund_amount',
    'refund_count',
    'rejected_amount',
    'rejected_count',
    'total_count',
  ],
  columns: Ember.computed('product_transactions', 'isMobileSize', function() {
    var columns = {
      event_date: {
        'name': 'event_date',
        'title': 'Settle<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      deposit_amount: {
        'name': 'deposit_amount',
        'title': 'Deposit<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      total_count: {
        'name': 'total_count',
        'title': 'Total<br>Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      credit_amount: {
        'name': 'credit_amount',
        'title': 'Credit<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      credit_count: {
        'name': 'credit_count',
        'title': 'Credit<br>Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      debit_amount: {
        'name': 'debit_amount',
        'title': 'Debit<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      debit_count: {
        'name': 'debit_count',
        'title': 'Debit<br>Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      refund_amount: {
        'name': 'refund_amount',
        'title': 'Refund<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      refund_count: {
        'name': 'refund_count',
        'title': 'Refund<br>Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      rejected_amount: {
        'name': 'rejected_amount',
        'title': 'Rejected<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      rejected_count: {
        'name': 'rejected_count',
        'title': 'Rejected<br>Count',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: false,
          showAllSelect: false,
          notuniqbytitle: true
        }
      }
    };

    var listOfCols = ['event_date', 'deposit_amount', 'total_count', 'credit_amount', 'credit_count', 'debit_amount', 'debit_count', 'refund_amount', 'refund_count', 'rejected_amount', 'rejected_count', 'product_transaction_id'];
    var listOfMobileCols = ['event_date', 'deposit_amount', 'total_count', 'credit_amount', 'credit_count', 'debit_amount', 'debit_count', 'refund_amount', 'refund_count', 'rejected_amount', 'rejected_count', 'product_transaction_id'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      if (context.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
    }
  }),

  // Actions
  actions: {}
});
