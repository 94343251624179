import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  itemList: null,
  value: null,
  selectedName: null,
  initialName: null,
  locationId: null,

  thisId: Ember.computed('id', function() {
    return this.get('name') + '_autocomplete';
  }),

  thisName: Ember.computed(function() {
    return this.get('name') || 'contact_id';
  }),

  optionLabelPath: Ember.computed('isMultiLocation', function() {
    return this.get('isMultiLocation') ? 'titleWithAccountNumberAndLocation' : 'titleWithAccountNumber';
  }),

  // Actions
  actions: {
    updateContacts: function(data) {
      var location_id = this.get('locationId');
      var isMultiLocation = this.get('isMultiLocation');
      var self = this;
      var store = self.get('store');
      var data_first_name = '';
      var data_last_name = '';
      var tempData = '';
      if (data) {
        tempData = data.split(' ');
      }
      if (tempData.length === 2) {
        data_first_name = tempData[0];
        data_last_name = tempData[1];
      }
      self.set('contacts', []);
      if (data_first_name && data_last_name) {
        store.query('contact', {
          location_id: location_id,
          first_name: data_first_name,
          last_name: data_last_name,
          sort: 'last_name',
          page_size: '10',
          fields: 'id,first_name,last_name,account_number,location,address,city,state,zip,phone,office_phone',
          expand: isMultiLocation ? 'location' : ''
        }).then(function(result) {
          handleResults(result);
        });
      } else {
        Ember.RSVP.Promise.all([
          store.query('contact', {
            location_id: location_id,
            last_name: data,
            sort: 'last_name',
            page_size: '10',
            fields: 'id,first_name,last_name,account_number,location,address,city,state,zip,phone,office_phone,email',
            expand: isMultiLocation ? 'location' : ''
          }),
          store.query('contact', {
            location_id: location_id,
            first_name: data,
            sort: 'last_name',
            page_size: '10',
            fields: 'id,first_name,last_name,account_number,location,address,city,state,zip,phone,office_phone,email',
            expand: isMultiLocation ? 'location' : ''
          })
        ]).then(function(result) {
          handleResults(result);
        });
      }

      function handleResults(result) {
        if (!Ember.isArray(result[0])) {
          setResults(result);
        } else {
          // Remove duplicates from result
          var res1 = result[0].toArray();
          var res2 = result[1].toArray();
          if (res1.get('length') > 0 && res2.get('length') > 0) {
            res1.forEach(function(item1) {
              var id = item1.get('id');
              res2.forEach(function(item2) {
                if (item2 && id === item2.get('id')) {
                  res2.removeObject(item2);
                }
              });
            });
          }
          // Merge the two arrays
          var rArray = Ember.A();
          rArray.pushObjects(res1.toArray());
          rArray.pushObjects(res2.toArray());
          setResults(rArray);
        }

      }

      function setResults(arr) {
        if (!self.get('isDestroyed') || !self.get('isDestroying')) {
          self.set('contacts', arr);
          var setResultsTo = self.get('setResultsTo');
          if (setResultsTo) {
            self.get('context').set(setResultsTo, arr);
          }
        }
      }
    },
  }

});
