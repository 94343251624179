import Ember from 'ember';

export default Ember.Component.extend({

  // tagName: 'button',
  classNames: ['btn-inline'],

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  thisId: Ember.computed('modelName', 'type', 'id', 'spot', function() {
    var modelName = this.get('modelName');
    var spot = this.get('spot');
    var id = this.get('id');
    if(id){
      return id;
    } else if(modelName){
      var tempId = 'btn_' + this.get('type') + '_' + this.get('modelName');
      if(spot) {
        tempId += '_' + spot;
      }
      return tempId;
    } else {
      return Math.random().toString(36).slice(2);
    }
  }),

  btnDanger: Ember.computed('isDelete', function() {
    return this.get('isDelete');
  }),
  btnPrimary: Ember.computed('isBack', 'isCancel', 'isEdit', 'isSearch', function() {
    return this.get('isBack') || this.get('isCancel') || this.get('isEdit') || this.get('isSearch');
  }),
  btnSuccess: Ember.computed('isPrint', function() {
    return this.get('isPrint');
  }),
  btnTitle: Ember.computed('type', 'noTitle', 'useTitle', function() {
    var useTitle = this.get('useTitle');
    if (useTitle) {
      return useTitle;
    } else if (!this.get('noTitle')) {
      switch (this.get('type')) {
        case 'back':
          return 'Back';
        case 'cancel':
          return 'Cancel';
        case 'delete':
          return 'Delete';
        case 'edit':
          return 'Edit';
        case 'print':
          return 'Print';
        case 'save':
          return 'Save';
        case 'activate':
          return 'Activate';
        case 'search':
          return 'Search';
      }
    } else {
      return '';
    }
  }),
  btnType: Ember.computed('isSave', function() {
    if(this.get('isSave')){
      return 'submit';
    } else {
      return 'button';
    }
  }),
  btnWarning: Ember.computed('isSave', 'isActivate', function() {
    return this.get('isSave') || this.get('isActivate');
  }),
  btnSM: Ember.computed('btn_size', function() {
    return this.get('btn_size') === 'sm';
  }),
  btnLG: Ember.computed('btn_size', function() {
    return this.get('btn_size') === 'lg';
  }),
  btnXS: Ember.computed('btn_size', function() {
    return this.get('btn_size') === 'xs';
  }),
  buttonDisabled: Ember.computed('disabled', 'isLoading', function() {
    return this.get('disabled') || this.get('isLoading');
  }),
  isActivate: Ember.computed('type', function() {
    return this.isType('activate');
  }),
  isBack: Ember.computed('type', function() {
    return this.isType('back');
  }),
  isCancel: Ember.computed('type', function() {
    return this.isType('cancel');
  }),
  isDelete: Ember.computed('type', function() {
    return this.isType('delete');
  }),
  isEdit: Ember.computed('type', function() {
    return this.isType('edit');
  }),
  isPrint: Ember.computed('type', function() {
    return this.isType('print');
  }),
  isSearch: Ember.computed('type', function() {
    return this.isType('search');
  }),
  isSave: Ember.computed('type', function() {
    return this.isType('save');
  }),

  // Functions

  isType: function(btn_type) {
    return this.get('type') === btn_type;
  },

  // Actions
  actions: {
    action: function() {
      this.action();
    },
  }
});
