import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  account_vault_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  last_four: DS.attr('string'),
  response_code: DS.attr('string'),
  response_message: DS.attr('string'),
  location_id: DS.attr('string'),
});