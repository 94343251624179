import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminUsers: true,
  MINIMUM_SUPPORT_LEVEL: 300,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'username',
  status_id: '1',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'email',
    'first_name',
    'id',
    'last_login_ts',
    'last_login_ts_from',
    'last_login_ts_to',
    'last_name',
    'status_id',
    'username',
  ],
  columns: Ember.computed(function() {
    var self = this;
    var data = [{
      'name': 'username',
      'title': 'Username',
      'type': 'string',
      'sortable': true,
      'action': function(user) {
        self.send('view', user);
      },
      'actionId': 'user_username_view_link',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'first_name',
      'title': 'First Name',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'last_name',
      'title': 'Last Name',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'email',
      'title': 'Email',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'status_id',
      'title': 'Status',
      'type': 'check',
      'align': 'text-center',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'active_status',
        multi: true
      }
    }, {
      'name': 'isClientAdmin',
      'title': 'Client Admin',
      'type': 'check',
      'align': 'text-center',
      'sortable': false
    }, {
      'name': 'last_login_ts',
      'title': 'Last Login',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }, {
      'name': 'created_ts',
      'title': 'Created',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }, {
      'name': 'branding_domain_url',
      'title': 'Login URL',
      'type': 'string',
      'sortable': false
    }, {
      'name': 'id',
      'title': 'User Id',
      'type': 'string',
      'sortable': false,
      'filter': {
        type: 'text'
      }
    }]; 
    if(this.get('session.authenticated.user.user_type_id') >= this.MINIMUM_SUPPORT_LEVEL){
      data.splice(4,1);
    }
    return data;
  }),

  // Actions
  actions: {
    view: function(user) {
      this.transitionToRoute('admin.users.view', user.get('id'));
    }
  }
});
