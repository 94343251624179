import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      hostedpaymentpage: store.queryRecord('hostedpaymentpage', {
        id: params.hppId,
        expand: 'product_transaction',
      }),
    });
  },
  afterModel: function(models, transition) {
    return this.get('notFound').check(models.hostedpaymentpage, transition, 'Hosted Payment Page', this);
  },

  setupController: function(controller, models, transition) {
      // Set Controller Properties
    controller.set('location', models.location);

    var unRemovableFieldsLodging = ['room_num', 'room_rate', 'checkin_date', 'checkout_date'];
    var unRemovableFields = ['transaction_amount', 'is_company', 'exp_date', 'captcha', 'submit_button', 'terms_agree'];

    var product = models.hostedpaymentpage.get('product_transaction');
    if (product.get('id')) {
      const terminals = product.get('processor_data.terminals');
      if (terminals && terminals.length) {
        terminals.forEach((terminal) => {
          if (terminal.sec_code === 'WEB') {
            const dlLicenseIndex = terminal.required.indexOf('driver_license');
            const idVerifyIndex = terminal.required.indexOf('identity_verification');
            if (dlLicenseIndex > 0) {
              terminal.required[dlLicenseIndex] = 'dl_number';
              terminal.required.push('dl_state');
            }
            if (idVerifyIndex > 0) {
              terminal.required[idVerifyIndex] = 'ssn4';
            }
            unRemovableFields = unRemovableFields.concat(terminal.required);
          }
        });
      }
      if (product.get('industry_type') === 'lodging') {
        unRemovableFields = unRemovableFields.concat(unRemovableFieldsLodging);
      }
      // Check if payment method is not ach and if the field is already present in the array to avoid duplicate fields.
      if (product.get('vt_require_street')
      && product.get('payment_method') !== 'ach'
      && unRemovableFields.indexOf('billing_street') === -1
      ) {
        unRemovableFields.push('billing_street');
      }
      // Check if payment method is not ach and if the field is already present in the array to avoid duplicate fields.
      if (product.get('vt_require_zip')
        && product.get('payment_method') !== 'ach'
        && unRemovableFields.indexOf('billing_zip') === -1
      ) {
        unRemovableFields.push('billing_zip');
      }
      if (product.get('vt_require_cvv')
      && product.get('payment_method') !== 'ach'
      && unRemovableFields.indexOf('cvv') === -1
      ) {
        unRemovableFields.push('cvv');
      }
      if (product.get('auto_decline_cvv')) {
        unRemovableFields.push('cvv');
      }
    }

    controller.set('product', product);
    controller.set('hostedpaymentpage', models.hostedpaymentpage);
    controller.set('unRemovableFields', unRemovableFields);
    if (transition.to.queryParams.usePP) {
      controller.set('isHowToUse', true);
      controller.set('isPreviewing', false);
    } else {
      controller.set('isHowToUse', false);
      controller.set('isPreviewing', false);
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● View Hosted Payment Page',
      route_name: 'locations.hpp-view',
      route_id: models.location.get('id'),
      route_id_plus: models.hostedpaymentpage.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Hosted Payment Pages',
      link: 'locations.hpp-index',
      record: models.location.get('id')
    }, {
      title: 'View Hosted Payment Pages',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
