import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  attrs: {
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
  }
});