import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      quickinvoice: store.queryRecord('quickinvoice', {
        id: params.quickinvoice_id,
        expand: 'contact,tags,created_user,cc_product_transaction,ach_product_transaction,files',
        active: '0,1'
      }),
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return Ember.RSVP.Promise.all([
      this.get('notFound').check(models.contact, transition, 'Contact', this),
      this.get('notFound').check(models.quickinvoice, transition, 'Quick Invoice', this),
    ]).then(function() {
      return Ember.RSVP.hash({
        locationinfo: self.get('store').queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          expand: 'product_transactions,product_file',
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
        })
      });
    });
  },
  setupController: function(controller, models) {
    // Set Controller Properties
    var quickinvoice = models.quickinvoice;
    controller.set('contact', models.contact);
    controller.set('quickinvoice', quickinvoice);
    controller.set('locationinfo', models.locationinfo);

    // Set: Page Title - Crumbs - History
    var quickinvoiceTitle = quickinvoice.get('title');
    var invoice_number = quickinvoice.get('invoice_number');
    if(invoice_number){
      quickinvoiceTitle += ' (' + invoice_number + ')';
    }
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● View Quick Invoice ● ' + quickinvoiceTitle,
      route_name: 'contacts.quickinvoices-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.quickinvoice.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Quick Invoices',
      link: 'contacts.quickinvoices-index',
      record: models.contact.get('id')
    }, {
      title: 'View Quick Invoice',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
