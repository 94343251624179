import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', this.get('session.authenticated.user.contact_id'))
    });
  },
  afterModel: function(models) {
    return Ember.RSVP.hash({
      locationinfos: this.get('store').query('locationinfo', {
        id: models.contact.get('location_id'),
        product_transaction_active: '0,1'
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('locationinfos', models.locationinfos);
    controller.set('contact', models.contact);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Account Vault',
      route_name: 'contactuser.accounts-add'
    });
    controller.set('pageTitle', 'Add Account Vault');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Account Vaults',
      link: 'contactuser.accounts-index'
    }, {
      title: 'Add Account Vault',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
