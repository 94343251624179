import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    const store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'reports.accountvaultcaulogreport',
      pageModelId: null,
      modelName: 'accountvaultcaulogreport',
      showExport: this.get('session.authenticated.resources.v2-accountvaultcaulogreport-get')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      fields: 'location_id',
      expand: 'location.id,',
      payment_method: 'cc',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);
    controller.set('locations', models.locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Account Vault Change Report',
      route_name: 'reports.accountvaultcaulogreport',
      route_id: null
    });
    controller.set('pageTitle', 'Account Vault Change Report');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'Account Vault Change Report',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
