import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,
  hours24: Ember.computed(function(){
    var hour = 1;
    var hours = [];
    while(hour < 25) {
      hours.push({id:hour});
      hour ++;
    }
    return hours;
  }),

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var session = self.get('session');
      var seconds = this.get('log_api_response_body_ts') * 3600;
      var data = {
        "addlogseconds": seconds
      };
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + 'admin/users/' + this.get('user.id') + '/addlogseconds',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        data: JSON.stringify(data)
      }).then(function(res) {
        noty({
          text: 'Time added to Log API Response successfully!',
          type: 'success'
        });
        self.set('parent.user.log_api_response_body_ts', (res.user.log_api_response_body_ts) * 1000);
        self.get('store').push({ // need this for accurate dirty checking in willTransition
          data: {
            id: res.user.id,
            type: self.get('context') === 'admin' ? 'admin_user' : 'user',
            attributes: {
              log_api_response_body_ts: (res.user.log_api_response_body_ts) * 1000
            }
          }
        });
        self.set('isLoading', false);
        self.send('closeModal');
      }, function() {
        noty({
          text: 'Error adding time to Log API Response!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.send('closeModal');
      });
    },
  }
});
