import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/terminalapplications/view', {
      controller: 'admin/terminalapplications/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminalapplication: store.createRecord('admin_terminalapplication', {
        active: true
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('terminalapplication', models.terminalapplication);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-terminalapplications-delete'),
      canPost: this.get('session.authenticated.resources.v2admin-terminalapplications-post'),
      canPut: this.get('session.authenticated.resources.v2admin-terminalapplications-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Terminal Application',
      route_name: 'admin.terminalapplications.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Terminal Application');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terminal Applications',
      link: 'admin.terminalapplications.index'
    }, {
      title: 'Add Terminal Application',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel;
      if (model.terminalapplication.get('hasDirtyAttributes')) {
        model.terminalapplication.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
