import Ember from 'ember';
import EmberHighChartsComponent from 'ember-highcharts/components/high-charts';
import { assign } from '@ember/polyfills';

export default EmberHighChartsComponent.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  classNames: ['highcharts-wrapper'],
  chartOptions: null,
  chartData: null,
  chart: null,

  buildOptions: Ember.computed('chartOptions', 'chartData', function() {
    var chartOptions = this.get('chartOptions');
    var chartColors;
    switch (window.sessionStorage.app_theme) {
      case 'pastel':
      case 'pay':
        chartColors = ['#fde9b7', '#caabcd', '#cfedfe', '#9871aa', '#a5c6d8'];
        break;
      case 'winter':
        chartColors = ['#99d3df', '#3b7899', '#aaaaaa', '#24678b', '#5b5a5b'];
        break;
      default:
        chartColors = ['#fee090', '#c2a5cf', '#d9f0d3', '#9970ab', '#a6dba0'];
    }
    this.set('chartOptions.colors', chartColors);
    var chartData = this.get('chartData');
    chartOptions.tooltip = {
      useHTML:true,
      formatter: function() {
        var tooltip = '<div class="text-center">';
        tooltip += this.series.userOptions.name + '<span class="fa-stack"><i class="fas fa-circle fa-stack-1x" style="color:' + this.point.color + '"></i></span></br>';
        tooltip += this.point.category + ' | $ <strong>' + window.Highcharts.numberFormat(this.point.y, 2, '.', ',') + '</strong>';
        tooltip += '</div>';
        return tooltip;
      }
    };
    var defaults = {
      series: chartData
    };
    return assign(defaults, chartOptions);
  }),
  contentDidChange: Ember.observer('chartData', function() {
    var chart = this.get('chart');
    return this.get('chartData').forEach(function(series, idx) {
      var _ref;
      if ((_ref = chart.get('noData')) != null) {
        _ref.remove();
      }
      if (chart.series[idx]) {
        return chart.series[idx].setData(series.data);
      } else {
        return chart.addSeries(series);
      }
    });
  })
});
