import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsNotificationTemplate: true,
  errorWarningMessage: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('notificationtemplate.errors.messages', function() {
    this.set('errorMessages', this.get('notificationtemplate.errors.messages'));
  }),

  change_notification_template_id: Ember.observer('notification_template_id', function() {
    var location = this.get('location');
    var notification_template_id = this.get('notification_template_id');
    var notification_template = this.get('notificationtemplate');
    var notification_templates = this.get('notificationtemplates');

    var notify_template = notification_templates.filter(function(i) {
      return i.id === notification_template_id;
    }).get('firstObject');

    if (notification_template_id !== null) {
      notification_template.setProperties({
        location_id: location.get('id'),
        template_name: notify_template.get('template_name'),
        title: notify_template.get('title'),
        subject: notify_template.get('subject'),
        body: notify_template.get('body'),
        email_cc: notify_template.get('email_cc'),
        email_bcc: notify_template.get('email_bcc')
      });
    }
    this.set('notificationtemplate', notification_template);
  }),
  // Set controller params
  isLoading: false,
  validateEmails: function (strEmails) {
    var self = this;
    var validEmail = false;

      if (strEmails.length > 0)
      {
        var validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var arrayEmails = strEmails.split(';');
        arrayEmails.every(function(email) {
          if (email.trim().match(validRegex))
          {
            self.set("errorWarningMessage", false);
            validEmail = true;
            return true;
          }
          else{
            self.set("errorWarningMessage", true);
            validEmail = false;
            return false;            
          }
        });
      }
      return validEmail;
  },
  // Actions
  actions: {
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      
      var location = this.get('location');

      var self = this;
      var notificationtemplate = this.get('notificationtemplate');

      if (notificationtemplate.email_cc) {
        if (!self.validateEmails(notificationtemplate.email_cc))
        {
          return;
        }
      }

      if (notificationtemplate.email_bcc) {
        if (!self.validateEmails(notificationtemplate.email_bcc))
        {
          return;
        }
      }

      this.set('isLoading', true);

      notificationtemplate.save().then(function() {
        noty({
          text: 'Notification template added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.notificationtemplates', location.get('id'));
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.notificationtemplates', location.get('id'));
    },
    actionValidateEmails: function (strEmails) {
      this.set("errorWarningMessage", false);
      if (strEmails) {
        this.validateEmails(strEmails); 
      }     
    }
  }
});
