import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/productfileform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      productfile: store.createRecord('productfile', {
        location_id: params.id,
        free_bytes: '13107200',
        byte_increment: '1073741824',
        increment_cost: '0.00',
        monthly_fee: '0.00',
        max_file_size_bytes: '5242880'
      }),
      productfilecredentials: store.findAll('productfilecredential')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('productfile', models.productfile);
    controller.set('productfilecredentials', models.productfilecredentials);
    controller.set('isEditing', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-productfiles-delete'),
      canPost: this.get('session.authenticated.resources.v2-productfiles-post'),
      canPut: this.get('session.authenticated.resources.v2-productfiles-put'),
      canActivate: this.get('session.authenticated.resources.v2-productfiles-post-activate')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add File Storage Service',
      route_name: 'locations.addproductfile',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: 'Add File Storage Service',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.controller.get('productfile');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
