import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('domain.errors.messages', function() {
    this.set('errorMessages', this.get('domain.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isDomainsAllowableAddOns: true,
  isLoading: false,
  isEditing: false,

 // Computed Properties
 domainRegistrationFields: Ember.computed('domain.registration_fields', function() {
  var registration_fields = this.get('domain.registration_fields') || '';
  return registration_fields.replace(/,/g, ', ');
}),
panelTitle: Ember.computed('domain.title', 'domain.isNew', function() {
  var isNew = this.get('domain.isNew');
  var title = this.get('domain.title');
  return isNew ? 'Add Domain' : title;
}),

// Actions
actions: {
  back: function() {
    this.transitionToRoute('admin.domains.index');
  },
  cancel: function() {
    var domain = this.get('domain');
    domain.rollbackAttributes();
    this.set('isEditing', false);
  },
  delete: function() {
    var self = this;
    var domain = this.get('domain');
    pretty_confirm('Are you sure you want to delete this Domain?', function() {
      // Bail if we are already loading
      if (self.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      domain.deleteRecord();
      domain.save().then(function() {
        domain.unloadRecord();
        noty({
          text: 'Domain deleted successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.domains.index');
      }, function(data) {
        var error = '';
        if(data.errors){
          data.errors.forEach(function(err){
            error += '<br>' + err.detail + '.';
          });
        }
        domain.rollbackAttributes();
        noty({
          text: 'Error deleting Domain!' + error,
          type: 'error',
          timeout: 10000
        });
        self.set('isLoading', false);
      });
    });
  },
  edit: function() {
    this.set('isEditing', true);
  },
  save: function() {
    // Bail if we are already loading
    if (this.get('isLoading')) {
      return;
    }
    this.set('isLoading', true);
    var self = this;
    var domain = this.get('domain');
    domain.save().then(function() {
      noty({
        text: 'Domain updated successfully!'
      });
      self.set('isLoading', false);
      self.set('isEditing', false);
    }, function() {
      self.set('isLoading', false);
    });
  },
}
});
