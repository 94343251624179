import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    var file = params[0];
    var fileSize = file.size || file.file_size_bytes;
    var decimals = params[1] || 1;
    if (fileSize === 0) {
      return '0 MB';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    var i = Math.floor(Math.log(fileSize) / Math.log(k));

    return parseFloat((fileSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
});
