import Ember from "ember";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";

export default Ember.Route.extend(AuthenticatedRouteMixin, {
  // Dependency Injections
  sessionService: Ember.inject.service("session"),

  // Set Template
  renderTemplate: function () {
    this.render("locations/producttransactionform");
  },

  model: function (params) {
    var store = this.get("store");
    return Ember.RSVP.hash({
      location: store.queryRecord("location", {
        id: params.id,
        expand:
          "created_user,parent,branding_domain,product_transactions,location_billing_accounts",
      }),
      productachstores: store.findAll("productachstore"),
    });
  },

  setupController: function (controller, models) {
    // Set Controller Properties
    var location = models.location;
    var producttransaction = this.get("store").createRecord(
      "producttransaction",
      {
        location_id: location.get("id"),
        processor_data: { terminals: [], country_code: "" },
        partner: "standalone",
        vt_cvv: true,
        vt_street: true,
        vt_zip: true,
        vt_order_num: false,
        vt_billing_phone: 0,
        vt_clerk_number: 0,
        display_avs: true,
        vt_enable: true,
        card_type_amex: true,
        card_type_diners: true,
        card_type_disc: true,
        card_type_jcb: true,
        card_type_mc: true,
        card_type_visa: true,
        receipt_show_contact_name: true,
        default_transaction_type: "sale",
        allow_debit: true,
        allow_refund: true,
        tax_surcharge_config: "2",
        current_batch: "1",
        receipt_header: location.get("full_address"),
        receipt_add_recurring_above_signature:
          "I understand that making monthly payments is a courtesy extended to me.  I understand that the monthly payment may not match the cost of services rendered within a given month.  I agree that if I discontinue care, I may have an outstanding balance for services already received.  Any balance is due in full upon cancellation.<br />Please initial________<br/>I agree to pay the above total according to my card issuer agreement and the schedule above. Merchant reserves the right to modify the schedule.",
        receipt_vt_above_signature:
          "I agree to pay the above total according to my card issuer agreement.",
        sales_office_id: null,
        cau_subscribe_type_id: "0",
        tz: location.get("tz"),
      }
    );
    controller.set("isEditing", true);
    controller.set("location", location);
    controller.set("producttransaction", producttransaction);
    controller.set("productachstores", models.productachstores);
    controller.set("processor", null);
    controller.set("newModel", true); // Need to ser this for showing editable fields
    controller.set("isViewingL3", false);
    controller.set("isAddingTerminalID", false);
    controller.set("payaach_terminal_id", null);
    controller.set("payaTerminalIds", []);
    controller.set("isViewingPayfacData", false);

    controller.set("formPrivs", {
      canDelete: this.get(
        "session.authenticated.resources.v2-producttransactions-delete"
      ),
      canPost: this.get(
        "session.authenticated.resources.v2-producttransactions-post"
      ),
      canPut: this.get(
        "session.authenticated.resources.v2-producttransactions-put"
      ),
    });

    controller.set("billingAccountLocation", {
      id: location.get("id"),
      name: location.get("name"),
    });
    if (
      this.get("session.authenticated.resources.v2-locationbillingaccounts-get")
    ) {
      controller.getLocationBillingAccounts();
    }

    // Set: Page Title - Crumbs - History
    this.send("addHistory", {
      title: location.get("name") + " ● Add Deposit Account",
      route_name: "locations.addproducttransaction",
      route_id: location.get("id"),
    });
    controller.set("pageTitle", location.get("location_header_title"));
    controller.set(
      "additionalBread",
      "Domain:&nbsp;&nbsp;" + location.get("branding_domain.url")
    );
    controller.set("pageCrumbs", [
      {
        title: "Locations",
        link: "locations",
      },
      {
        title: location.get("name"),
        link: "locations.view",
        record: location.get("id"),
      },
      {
        title: "Services",
        link: "locations.products",
        record: location.get("id"),
      },
      {
        title: "Add Deposit Account",
        link: "",
      },
    ]);
  },

  // Actions
  actions: {
    willTransition: function () {
      var model = this.controller.get("producttransaction");
      if (model.get("hasDirtyAttributes") && model.get("isNew")) {
        model.unloadRecord();
      } else if (model.get("hasDirtyAttributes")) {
        model.rollbackAttributes();
      }
      return true;
    },
  },

  // Computed Properties
  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
});
