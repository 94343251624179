import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsPartnerDrChrono: true,

  hasDrChronoConnection: Ember.computed('integration.id', function() {
    return this.get('integration.id');
  }),
  iframeSrc: Ember.computed('integration.{id,location_id,user_id}', 'location.id', function() {
    var drchrono_state = {
      location_id: this.get('location.id'),
      user_id: this.get('session.authenticated.user_id'),
      src: 'drchrono'
    };
    var redirect_uri =  `https://api.${window.ENV.APP.HOST_BASE}/oauth/authorizations`;
    var env = window.ENV.environment;
    var api_url = window.ENV.APP.API_URL;
    var url = 'https://drchrono.com/o/authorize?redirect_uri=';
    url += encodeURIComponent(redirect_uri);
    url += '&response_type=code';
    url += '&client_id=' + encodeURIComponent(window.ENV.APP.OAUTH_INTEGRATIONS.drchrono);
    url += '&state=' + btoa(JSON.stringify(drchrono_state));
    return url;
  }),
  isDrChronoIframeRegistered: Ember.computed('integration.custom_data.is_registered', function() {
    return this.get('integration.custom_data.is_registered');
  }),

  // Functions

  pollForIntegrationRecord: function() {
    var self = this;
    var id = setInterval(poll, 5000);
    var counter = 0;
    function poll() {
      if(self.get('isConnecting') && counter < 15) {
        counter++;
        self.set('isLoading', true);
        self.get('store').queryRecord('integration', {
          src: 'drchrono',
          location_id: self.get('location.id'),
          user_id: self.get('session.authenticated.user_id')
        }).then(function(res){
          self.set('integration', res);
          if(res && res.get('id')){
            self.set('isLoading', false);
            self.set('isConnecting', false);
            noty({
              text: 'Connection to Dr. Chrono successful!',
              timeout: 3000,
            });
          }
        }, function() {
          self.set('isConnecting', false);
          noty({
            text: 'Error connecting to Dr. Chrono!',
            type: 'error'
          });
        });
      } else {
        clearInterval(id);
        self.set('isLoading', false);
        self.set('isConnecting', false);
      }
    }
  },

  updateIntegration: function() {
    var self = this;
    self.get('store').queryRecord('integration', {
      src: 'drchrono',
      location_id: self.get('location.id'),
      user_id: self.get('session.authenticated.user_id')
    }).then(function(res){
      if(res && res.get('id')){
        self.set('isLoading', false);
        self.set('isUpdatingIframeRegistration', false);
        noty({
          text: 'Dr. Chrono iframe registration updated successfully!'
        });
      }
    }, function() {
      self.set('isLoading', false);
      self.set('isUpdatingIframeRegistration', false);
    });
  },

  // Actions
  actions: {
    disconnectDrChrono: function () {
      var self = this;
      pretty_confirm('Are you sure want to Disconnect from Dr. Chrono?', function() {
        console.log("self.get('isDisconnecting')", self.get('isDisconnecting'));
        // Bail if we are already loading
        if(self.get('isDisconnecting')) {
          return;
        }
        self.set('isLoading', true);
        self.set('isDisconnecting', true);
        var integration = self.get('integration');
        integration.destroyRecord().then(function() {
          self.set('isLoading', false);
          self.set('isDisconnecting', false);
          noty({
            text: 'Dr. Chrono disconnected successfully!'
          });
          self.send('refreshData');
        }, function() {
          self.set('isLoading', false);
          self.set('isDisconnecting', false);
          noty({
            text: 'Error disconnecting from Dr. Chrono!',
            type: 'error'
          });
        });
      });
    },
    iframeRegistration: function() {
      var is_registered = this.get('isDrChronoIframeRegistered');
      var self = this;
      var session = this.get('session');
      var integration = this.get('integration');
      if (this.get('isLoading')) {
        return;
      }

      this.set('isLoading', true);
      this.set('isUpdatingIframeRegistration', true);
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL.replace('v2', '') + 'drchrono/iframes/' + integration.get('id') + '/' + (is_registered ? 'unregister' : 'register'),
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        self.updateIntegration();
      }, function(err) {
        if(err && err.status === 201) {
          self.updateIntegration();
        } else {
          noty({
          text: 'Error updating Dr. Chrono iframe registration!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.set('isUpdatingIframeRegistration', false);
        }
      });
    },
    makeConnection: function() {
      if(this.get('isConnecting')) {
        return;
      }
      this.set('isConnecting', true);
      this.pollForIntegrationRecord();
    },
    refreshTokens: function() {
      var self = this;
      var session = this.get('session');
      var integration = this.get('integration');
      if (this.get('isLoading')) {
        return;
      }

      this.set('isLoading', true);
      this.set('isRefreshingTokens', true);
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL.replace('v2', '') + 'oauth/integrations/' + integration.get('id') + '/refresh',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        noty({
          text: 'Dr. Chrono tokens refreshed successfully!'
        });
        self.set('isLoading', false);
        self.set('isRefreshingTokens', false);
      }, function() {
        noty({
          text: 'Error refreshing Dr. Chrono tokens!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.set('isRefreshingTokens', false);
      });
    },
    syncPatients: function() {
      var self = this;
      var session = this.get('session');
      var location_id = this.get('location.id');
      var user_id = this.get('session.authenticated.user_id');
      if (this.get('isLoading')) {
        return;
      }

      this.set('isLoading', true);
      this.set('isSyncingPatients', true);
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL.replace('v2', '') + 'drchrono/updaterecords?location_id=' + location_id + '&user_id=' + user_id,
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        noty({
          text: 'Patient records syncing. You will be notified when process is complete!'
        });
        self.set('isLoading', false);
        self.set('isSyncingPatients', false);
      }, function(error) {
        if(error && (error.status === 200 || error.status === '200')) {
          noty({
            text: 'Patient records syncing. You will be notified when process is complete!'
          });
        } else {
          noty({
            text: 'Error syncing patient records!',
            type: 'error'
          });
        }
        self.set('isLoading', false);
        self.set('isSyncingPatients', false);
      });
    }
  }
});
