import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/developers/view', {
      controller: 'admin/developers/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      developerCompanies: store.query('developercompany', {
        page_size: '100'
      }),
      developer: store.createRecord('developer', {})
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('developerCompanies', models.developerCompanies);
    var developer = models.developer;
    developer.set('developer_company_id', models.developerCompanies.get('firstObject').get('id'));
    developer.set('external_developer', true);
    controller.set('developer', developer);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-developers-delete'),
      canPost: this.get('session.authenticated.resources.v2-developers-post'),
      canPut: this.get('session.authenticated.resources.v2-developers-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Developer',
      route_name: 'admin.developers.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Developer');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Developers',
      link: 'admin.developers.index'
    }, {
      title: 'Add Developer',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel.developer;
      if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
