import Ember from 'ember';

export default Ember.Route.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    if (!params.token) {
      return;
    }
    return params;
  },

  setupController: function(controller, model) {
    controller.set('params', model);
  },

  // Actions
  actions: {
    didTransition: function() {
      var session = this.get('session');
      var params = this.controller.get('params');
      var self = this;
      if (params) {
        session.set('authenticated.history', null);
        session.get('store').clear();
        console.log('Found sso parameters. Redirecting to authenticator.');
        var param_data = '';
        if (params.data) {
          param_data = JSON.parse(params.data);
        }
        window.sessionStorage.is_sso = true;
        if (session.get('isAuthenticated') && session.get('authenticated.token') === params.token) {
          var controller = this.controllerFor(params.route_name);
          controller.set('sso_params', param_data);
          if (params.route_id) {
            this.transitionTo(params.route_name, params.route_id);
          } else {
            this.transitionTo(params.route_name);
          }
          session.set('authenticated.history', []);
        } else if (session.get('isAuthenticated')) {
          session.invalidate().then(function() {
            authenticateSession();
          });
        } else {
          authenticateSession();
        }
      }

      function authenticateSession() {
        var session = self.get('session');
        session.authenticate('authenticator:custom', {
          'token': params.token,
          'route_name': params.route_name || '',
          'route_id': params.route_id || '',
          'data': param_data || '',
          'isIntegratedPartner': true
        });
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});

// Sample sso url to be received
// http://127.0.0.1:4200/#/sso?token=vy8q3YOC0UHsuA9W&route_name=contacts.dashboard&route_id=5407807b-dede-f0b8-86ff-08d5caf3d6f6
//&data={"model": "transaction", "transaction_amount":"1.20","description":"Test Payment"}

// http://127.0.0.1:4200/#/sso?token=QPaUh76hsHX9c0JU&route_name=contacts.charge&route_id=5407807b-dede-f0b8-86ff-08d5caf3d6f6&model=transaction&data=%7b%22transaction_amount%22%3a165%2c%22description%22%3a%2211+dfsalk%3bdfsadl+fasljkf+11%2c+22+dfiousdafasofuijaosfjdi+soajf+22%22%2c+%22tags%22%3a%5b%7b%22id%22%3a%22POS%22%2c%22title%22%3a%22POS%22%7d%5d%7d
// http://127.0.0.1:4200/#/sso?token=5ROLgMoldCrvNvIu&route_name=contacts.charge&route_id=5407807b-dede-f0b8-86ff-08d5caf3d6f6&model=transaction&data=%7B%22transaction_amount%22%3A1065.2%2C%22description%22%3A%2211%20dfsalk%3Bdfsadl%20fasljkf%2011%2C%2022%20dfiousdafasofuijaosfjdi%20soajf%2022%22%2C%20%22product_transaction_id%22%3A%20%2252f9f8e2-7344-6919-2dfa-d3924de932aa%22%2C%20%22tags%22%3A%5B%7B%22id%22%3A%22POS%22%2C%22title%22%3A%22POS%22%7D%5D%7D
