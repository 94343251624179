import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  applicationData: Ember.inject.service('application-data'),
  trans_reasoncodes: Ember.computed(function() {
    return this.get('applicationData.trans_reasoncodes');
  }),

  find: function(store, type, id) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      var t_reasoncodes = self.get('trans_reasoncodes');
      var t_reasoncode = t_reasoncodes.filter(function(item) {
        return parseInt(item.id) === parseInt(id);
      });
      if (t_reasoncode.length > 0) {
        resolve({
          reasoncode: t_reasoncode
        });
      } else {
        resolve({
          reasoncode: {
            'id': id,
            'title': ''
          }
        });
      }
    });
  },
  findAll: function() {
    return {
      reasoncode: this.get('trans_reasoncodes')
    };
  }
});
