import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    var session = this.get('session');
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
    });
  },
  afterModel: function(models) {
    return Ember.RSVP.hash({
      locationinfos: this.get('store').query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('recurrings', models.recurrings);
    controller.set('locationinfos', models.locationinfos);
    controller.set('locationinfo', locationinfo);

    var product_transactions = [];
    models.locationinfos.forEach(function(loc) {
      if(loc.get('hasProductRecurring')) {
        var prods = loc.get('product_transactions');
        if (prods) {
          prods.forEach(function(product_transaction) {
            product_transactions.push({
              'id': product_transaction.get('id'),
              'title': product_transaction.get('title'),
              'processor': product_transaction.get('processor'),
              'receipt_add_recurring_above_signature': product_transaction.get('receipt_add_recurring_above_signature'),
              'surcharge': product_transaction.get('surcharge')
            });
          });
        }
      }
    });
    controller.set('product_transactions', product_transactions);

    var pagerParams = {
      pageRoute: 'contactuser.recurrings-index',
      pageModelId: null,
      modelName: 'recurring',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);

    var gridParams = {
      contact_id: models.contact.get('id'),
      expand: 'created_user,account_vault,transactions,payment_schedule,product_transaction,contact,location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Scheduled Payments',
      route_name: 'contactuser.recurrings-index'
    });
    controller.set('pageTitle', 'Scheduled Payments');
    if(locationinfo.get('hasProductRecurring')) {
      controller.set('pageSubtitle', {
        title: 'Add Scheduled Payment',
        link: 'contactuser.recurrings-add',
        resource: this.get('session.authenticated.resources.v2-recurrings-post')
      });
    }
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Scheduled Payments',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
