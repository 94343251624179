import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminalapplications: store.findAll('terminalapplication'),
      terminalcvms: store.query('terminalcvm', {
        expand: 'terminal_manufacturer',
        page_size: '500'
      }),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('terminalapplications', models.terminalapplications);
    controller.set('terminalcvms', models.terminalcvms);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Terminal',
      route_name: 'admin.terminals.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Terminal');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terminals',
      link: 'admin.terminals.index'
    }, {
      title: 'Add Terminal',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
