import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransactionDetailSurcharge: true,
  isLocationsGear: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransactiondetails.errors.messages', function() {
    this.set('errorMessages', this.get('producttransactiondetails.errors.messages'));
  }),
  updateErrorMessagesSurcharge: Ember.observer('surcharge.errors.messages', function() {
    this.set('errorMessagesSurcharge', this.get('surcharge.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  isEditingSurcharge: false,
  showSurcharge: false,
  isActive: Ember.computed('producttransactiondetails.active', function() {
    return this.get('producttransactiondetails.active');
  }),
  notActive: Ember.computed('producttransactiondetails.active', function() {
    return !this.get('producttransactiondetails.active');
  }),

  surchargeUserTypes: [{
    id: '',
    title: 'All Users'
  }, {
    id: '100',
    title: 'Contact Users'
  }, {
    id: '200',
    title: 'Location Users'
  }],

  surchargeMessages: Ember.computed('producttransactiondetails.surcharge.id', 'producttransactiondetails.hasTip', 'producttransactiondetails.quick_invoice_allow', 'producttransactiondetails.hosted_payment_page_allow', function() {
    var producttransactiondetails = this.get('producttransactiondetails');
    var messages = [];
    if (producttransactiondetails.get('hasTip')) {
      messages.push('Surcharge cannot be added if tip is enabled!');
    }
    return messages;
  }),

  // Actions
  actions: {}
});
