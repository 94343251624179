import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/producttransactiondetailform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain',
      }),
      producttransactiondetails: store.queryRecord('producttransactiondetail', {
        id: params.product_id,
        active: '0,1',
      }),
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransactiondetails', models.producttransactiondetails);

    if (transition.to.queryParams.edit) {
      controller.set('isEditing', true);
    } else {
      controller.set('isEditing', false);
    }
    controller.set('isViewingL3', false);
    controller.set('cauEnabled',  models.producttransactiondetails.get('cau_account_number') &&  models.producttransactiondetails.get('cau_subscribe_type_id')); // initially set checkbox for subscribe for account updates

    controller.set('formPrivs', {
      canPost: this.get('session.authenticated.resources.v2-producttransactiondetails-post'),
      canPut: this.get('session.authenticated.resources.v2-producttransactiondetails-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + models.producttransactiondetails.get('title'),
      route_name: 'locations.viewproducttransactiondetail',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransactiondetails.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: models.producttransactiondetails.get('title'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      this.controller.set('showSurcharge', false);
      var model = this.currentModel;
      if (model.producttransactiondetails.get('hasDirtyAttributes')) {
        model.producttransactiondetails.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
