import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  changelog_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  http_verb: DS.attr('string'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),
  next_run_ts: DS.attr('ts'),
  postback_config_id: DS.attr('string'),
  postback_status_id: DS.attr('string'),

  // Computed Properties
  postback_status_title: Ember.computed('postback_status_id', function() {
    var status = String(this.get('postback_status_id'));
    if (status === '1') {
      return 'New';
    } else if (status === '2') {
      return 'Error';
    } else if (status === '3') {
      return 'Success';
    } else if (status === '4') {
      return 'Skipped';
    } else {
      return 'Unknown';
    }
  }),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  postback_status: DS.belongsTo('postbackstatus', {
    async: true
  }),
  postbacklogattempts: DS.hasMany('postbacklogattempt')
});
