import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  resource: DS.attr('string'),
  resource_id: DS.attr('string'),
  signature: DS.attr('string'),

  // Computed Properties
  href: Ember.computed('signature', function() {
    return 'data:image/bmp;base64,' + this.get('signature');
  })
});
