import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      types: store.findAll('type'),
      statuses: store.findAll('status')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'contactuser.paymenthistory',
      pageModelId: null,
      modelName: 'transaction',
      showExport: true
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'status,type'
    };
    controller.set('gridParams', gridParams);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Payment History',
      route_name: 'contactuser.paymenthistory'
    });
    controller.set('pageTitle', 'Payment History');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Payment History',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
