import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminRoles: true,
  numAuthRoles: 0,

  queryParams: [
    'selectedRoles'
  ],

  // Computed Properties
  availableAuthRoles: Ember.computed('authroles.[]', function() {
    var tempAuthRoles = this.get('authroles').filter(function(authrole) {
      authrole.set('user_type_id', authrole.get('user_type_id'));
      return authrole.get('id') !== '100';
    });
    tempAuthRoles = tempAuthRoles.sortBy('title');
    return tempAuthRoles;
  }),
  gettingAuthroleResources: Ember.computed('numAuthRoles', function() {
    return this.get('numAuthRoles') > 0;
  }),
  hasPrivs: Ember.computed('session.authenticated.resources.v2admin-authroleresources-post', 'session.authenticated.resources.v2admin-authroleresources-delete', function() {
    return this.get('session.authenticated.resources.v2admin-authroleresources-post') || this.get('session.authenticated.resources.v2admin-authroleresources-delete');
  }),
  notChanges: Ember.computed('changesToMake.[]', function() {
    return this.get('changesToMake.length') === 0;
  }),

  // Functions
  checkSideNav: function() {
    if(!this.get('isMobileSize')) {
      Ember.run.later(function() {
        if (window.sessionStorage.side_nav_open === 'false') {
          jQuery('.authroleresource-grid-table-column-static').css('left', '49px');
          jQuery('.authroleresource-grid-table-column-static-2').css('left', '349px');
        } else {
          jQuery('.authroleresource-grid-table-column-static').css('left', '200px');
          jQuery('.authroleresource-grid-table-column-static-2').css('left', '495px');
        }
      }, 1);
    }
  },

  saveGrid: function() {
    var self = this;
    var session = this.get('session');
    var changesToMake = this.get('changesToMake');
    var currentSelectedAuthRoles = this.get('selectedAuthRoles');
    var rolesGettingChanges = [];
    var changes = changesToMake.map(function(change) {
      rolesGettingChanges.push(change.roleId);
      var url = window.UiENV.APP.FULL_URL + 'admin/authroleresources';
      var data = {
        authroleresource: {
          auth_role_id: change.roleId,
          resource_id: change.resourceId
        }
      };
      if (change.type === 'DELETE') {
        url += '/' + change.authroleresourceId;
      }
      return jQuery.ajax({
        url: url,
        type: change.type,
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        data: JSON.stringify(data)
      });
    });
    rolesGettingChanges.uniq();

    self.set('isLoading', true);
    Ember.RSVP.Promise.all(changes).then(function() {
      noty({
        text: 'Changes Applied!',
        timeout: 5000
      });
      self.set('isEditingAuthenticated', false);
      rolesGettingChanges.map(function(roleID) {
        self.get('store').query('admin_authroleresource', {
          auth_role_id: roleID,
          page_size: 2000
        }).then(function(res) {
          var selectedResources = [];
          res.map(function(r) {
            selectedResources.push({
              id: r.get('id'),
              resource_id: r.get('resource_id')
            });
          });
          currentSelectedAuthRoles.map(function(role) {
            if (role.id === roleID) {
              Ember.set(role, 'selectedResources', selectedResources);
            }
          });
        });
      });
      self.set('changesToMake', []);
      self.set('isLoading', false);
    }, function() {
      noty({
        text: 'Error applying changes!',
        type: 'error'
      });
    });
  },

  // Actions
  actions: {
    cancel: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('changesToMake', []);
    },
    changeResourceForRole: function(roleId, resourceId, authroleresourceId, hasResource) {
      if ((roleId === '100' && !this.get('isEditingAuthenticated')) || this.get('isLoading')) {
        return;
      }
      var changesToMake = this.get('changesToMake');
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.roleId === roleId && change.resourceId === resourceId) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          roleId: roleId,
          resourceId: resourceId,
          authroleresourceId: authroleresourceId,
          type: hasResource ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    editAuthenticated: function() {
      var self = this;
      pretty_confirm('Are you sure you want to edit the Authenticated Role?<br><br>Any changes will affect all users of the system.', function() {
        self.set('isEditingAuthenticated', true);
      });
    },
    onCheck: function(value, action) {
      var self = this;
      var authroles = this.get('authroles');
      var title = '';
      var user_type_id = '';
      authroles.map(function(authrole) {
        if (value === authrole.get('id')) {
          title = authrole.get('title');
          user_type_id = authrole.get('user_type_id');
        }
      });
      var currentSelectedAuthRoles = this.get('selectedAuthRoles');
      let alreadyHaveRole = false;
      let alreadyHaveRoleIndex = 0;
      currentSelectedAuthRoles.map(function(r, i) {
        if (value === r.id) {
          alreadyHaveRole = true;
          alreadyHaveRoleIndex = i;
        }
      });
      if (action === 'add' && !alreadyHaveRole) {
        this.set('numAuthRoles', this.get('numAuthRoles') + 1);
        this.set('isLoading', true);
        this.get('store').query('admin_authroleresource', {
          auth_role_id: value,
          page_size: 2000
        }).then(function(res) {
          var selectedResources = [];
          res.map(function(r) {
            selectedResources.push({
              id: r.get('id'),
              resource_id: r.get('resource_id')
            });
          });
          currentSelectedAuthRoles.pushObject({
            id: value,
            title: title,
            user_type_id: user_type_id,
            selectedResources: selectedResources
          });
          self.set('numAuthRoles', self.get('numAuthRoles') - 1);
          self.set('isLoading', false);
          self.checkSideNav();
        });
      } else if (action === 'remove' && alreadyHaveRole) {
        currentSelectedAuthRoles.removeAt(alreadyHaveRoleIndex);
      }
    },
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var changesToMake = this.get('changesToMake');
      var isChangingAuthenticated = false;
      changesToMake.forEach(function(c){
        if(c.roleId === '100') {
          isChangingAuthenticated = true;
        }
      });
      if(isChangingAuthenticated) {
        pretty_confirm('You are making changes to the Authenticated Role.<br><br>This will affect all users of the system.<br><br>Are you sure you want to makes these changes?', function() {
          self.saveGrid();
        });
      } else {
        self.saveGrid();
      }
    }
  }
});
