import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  cost: DS.attr('string'),
  item_id: DS.attr('string'),
  percent_to_over_cost: DS.attr('string'),
  portfolio_id: DS.attr('string'),

  // Defined model relationships
  item: DS.belongsTo('item'),
  portfolio: DS.belongsTo('portfolio'),
});
