import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('domain.errors.messages', function() {
    this.set('errorMessages', this.get('domain.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isDomainsAttachedLocations: true,
  isLoading: false,
  isEditing: false,
  // Actions
  actions: {}
});
