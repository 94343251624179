import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  batch_close_time: DS.attr('string'),
  created: DS.attr('string'),
  created_user_id: DS.attr('string'),
  next_run_ts: DS.attr('ts'),
  product_transaction_id: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  product_transaction: DS.belongsTo('producttransaction'),
});
