import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'id',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'active',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'external_developer',
    'id',
    'title',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Developer ID',
        'type': 'string',
        'sortable': true,
        'action': function(developer) {
          self.send('view', developer);
        },
        'actionId': 'developer_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      external_developer: {
        'name': 'external_developer',
        'title': 'External Developer',
        'type': 'boolean',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: 'false'
        }
      },
      encryption_key: {
        'name': 'encryption_key',
        'title': 'Encryption Key',
        'type': 'string',
        'sortable': false
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      active: {
        'name': 'active',
        'title': 'Active',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(developer) {
          self.send('view', developer);
        },
        'actionId': 'developer_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(developer) {
          self.send('delete', developer);
        },
        'actionId': 'developer_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'title', 'external_developer', 'encryption_key', 'active', 'created_ts', 'view', 'delete'];
    var listOfMobileCols = ['id', 'title', 'external_developer', 'encryption_key', 'active', 'created_ts', 'view', 'delete'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      if (!context.get('session.authenticated.resources.v2admin-developers-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!context.get('session.authenticated.resources.v2admin-developers-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(developer) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Developer?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        developer.deleteRecord();
        developer.save().then(function() {
          developer.unloadRecord();
          noty({
            text: 'Developer deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          developer.rollbackAttributes();
          noty({
            text: 'Error deleting Developer!',
            type: 'error'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    view: function(developer) {
      this.transitionToRoute('admin.developers.view', developer.get('id'));
    },
  }
});
