import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  body: DS.attr('string'),
  created_ts: DS.attr('ts'),
  customizable: DS.attr('boolnum'),
  location_id: DS.attr('string'),
  template_name: DS.attr('string'),
  subject: DS.attr('string'),
  title: DS.attr('string'),
  email_cc: DS.attr('string'),
  email_bcc: DS.attr('string'),

  // Defined model relationships
  location: DS.belongsTo('location'),
});
