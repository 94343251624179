import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.location;
    controller.set('location', location);

    var product_transactions = [];
    var prods = location.get('product_transactions');
    var defaultACH;
    if (prods) {
      prods.forEach(function(product_transaction) {
        if (product_transaction.get('payment_method') === 'ach') {
          product_transactions.push({
            'id': product_transaction.get('id'),
            'title': product_transaction.get('title')
          });
          var productID = product_transaction.get('id');
          if (productID === location.get('default_ach')) {
            defaultACH = productID;
          }
        }
      });
    }
    controller.set('product_transactions', product_transactions);
    if (!controller.get('product_transaction_id')) {
      controller.set('product_transaction_id', defaultACH);
    }

    var pagerParams = {
      pageRoute: 'locations.reports-ach-transactionsettlementsummary',
      pageModelId: location.get('id'),
      modelName: 'transactionachsettlementsummary'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location.id,location.name,product_transaction',
      location_id: location.get('id')
    };
    controller.set('gridParams', gridParams);
    controller.set('location_id', location.get('id'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● Reports ● ACH Transactions Settlements Summary',
      route_name: 'locations.reports-ach-transactionsettlementsummary',
      route_id: location.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'ACH Transactions Settlements Summary',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
