import Ember from "ember";
import { assign } from "@ember/polyfills";
import jQuery from "jquery";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  helperFunctions: Ember.inject.service("helper-functions"),
  sessionService: Ember.inject.service("session"),
  store: Ember.inject.service(),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  gridData: null,
  binInfoData: [],
  isRefreshing: null,
  showFilter: false,
  showTotal: false,
  data:[],

  actions: {
    setChild(child) {
      this.set("user_reports", child);
    },
    filterGrid: function (data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if (typeof data !== "object") {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function (item) {
        props[item.name.replace(".", "-")] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
      var target = self.get("parent");
      target.setProperties(props);
      if (this.get("isEditableGrid")) {
        var user_reports = this.get("user_reports");
        user_reports.filterGrid(data);
        this.runUpdateGrid();
      }
    },
    removeColumn: function (column) {
      var user_reports = this.get("user_reports");
      user_reports.removeColumn(column);
    },
    sortByThisColumn: function (header, direction) {
      var sort = "";
      if (direction === "asc") {
        sort = header.sort.replace("-", "");
      } else {
        if (header.sort.indexOf("-") === -1) {
          sort = "-" + header.sort;
        } else {
          sort = header.sort;
        }
      }
      this.set("sort", sort);
      if (this.get("isEditableGrid")) {
        var user_reports = this.get("user_reports");
        user_reports.sortByThisColumn(sort);
      }
    },
    showUserReport: function (fieldData, filterData, url) {
      this.showUserReport(fieldData, filterData, url);
    },
    showUserreportShareModal: function (report, data) {
      this.showUserreportShareModal(report, data);
    },
    sortEndAction: function () {
      var user_reports = this.get("user_reports");
      user_reports.reorderColumns(this.get("headers"));
    },
    mouseenterHeader: function (header) {
      header.set("isHovered", true);
    },
    mouseleaveHeader: function (header) {
      header.set("isHovered", false);
    },
    showMobileFunctionality: function (functionality) {
      if (functionality === "sort") {
        this.toggleProperty("showMobileSorting");
        this.set("showMobileFiltering", false);
      } else if (functionality === "filter") {
        this.toggleProperty("showMobileFiltering");
        this.set("showMobileSorting", false);
      }
      var showMobileSorting = this.get("showMobileSorting");
      var showMobileFiltering = this.get("showMobileFiltering");
      if (showMobileSorting) {
        jQuery("#" + "mobile-functionality-btn-sort").addClass("active");
      } else {
        jQuery("#" + "mobile-functionality-btn-sort").removeClass("active");
        jQuery("#" + "mobile-functionality-btn-sort").blur();
      }
      if (showMobileFiltering) {
        jQuery("#" + "mobile-functionality-btn-filter").addClass("active");
      } else {
        jQuery("#" + "mobile-functionality-btn-filter").removeClass("active");
        jQuery("#" + "mobile-functionality-btn-filter").blur();
      }
    },
    mobileFilter: function (action) {
      var filters = this.get("filters");
      var filterValues = [];
      filters.map(function (filter) {
        if (filter.title) {
          if (action === "clear") {
            filter.set("value", "");
          }
          if (filter.valueFrom) {
            var valueFrom = filter.valueFrom;
            valueFrom += " ";
            valueFrom += filter.valueFromHour ? filter.valueFromHour : "00";
            valueFrom += ":";
            valueFrom += filter.valueFromMinute ? filter.valueFromMinute : "00";
            valueFrom += ":00";
            filterValues.push({
              name: filter.name + "_from",
              value: valueFrom,
            });
          }
          if (filter.valueTo) {
            var valueTo = filter.valueTo;
            valueTo += " ";
            valueTo += filter.valueToHour ? filter.valueToHour : "23";
            valueTo += ":";
            valueTo += filter.valueToMinute ? filter.valueToMinute : "59";
            valueTo += ":59";
            filterValues.push({
              name: filter.name + "_to",
              value: valueTo,
            });
          }
          filterValues.push({
            name: filter.name,
            value: Ember.isArray(filter.value)
              ? filter.value.join()
              : filter.value,
          });
        }
      });
      var data = {
        controller: this.get("pagerParams.pageRoute"),
        filters: filterValues,
      };
      this.send("filterGrid", data);
    },
    updateFilterTypeAhead: function (data, filterProps, byIds) {
      var queryProps = {
        page_size: "10",
        fields: filterProps.fields,
        sortBy: filterProps.sortBy,
      };
      if (byIds) {
        queryProps.id = data;
      } else {
        queryProps[filterProps.searchBy] = data;
      }
      var filterItems = this.get("store").query(
        filterProps.modelName,
        queryProps
      );
      filterItems.then(function (result) {
        Ember.set(filterProps, "items", result);
      });
    },
  },
  pageSizeObserver: Ember.observer("page_size", function () {
    var page_size = this.get("page_size");
    var user_reports = this.get("user_reports");
    if (user_reports) {
      user_reports.pageSizeChanged(this.get("page_size"));
    }
    if (!page_size) {
      this.set("page_size", 15);
    }
  }),
  pageObserver: Ember.observer("page", function () {
    if (this.get("isEditableGrid")) {
      this.runUpdateGrid();
    }
  }),

  runUpdateGrid: function () {
    Ember.run.once(this, "updateGrid");
  },
  populateProducts: function () {
    Ember.run.once(this, "getProducts");
  },
  didInsertElement: function () {
    // Add observers for grid filters. Must do this on init after all data has been set on form.
    var queryParams = this.get("queryParams");
    var self = this;
    var target = self.get("parent");
    if (!this.get("isEditableGrid")) {
      queryParams.forEach(function (param) {
        self.set(param, target.get(param));
        self.addObserver(param.replace(".", "-"), self, "runUpdateGrid");
      });
    } else {
      self.addObserver("needsRefresh", self, "runUpdateGrid");
    }
    self.addObserver("gridParams.needsRefresh", self, "runUpdateGrid");
    self.runUpdateGrid();
    self.populateProducts();
    // Run Ember debounce since quickinvoice data is not loaded completely in didInsertElement.
    if (target.route === "contactuser.quickinvoices-index") {
      Ember.run.debounce(this, "showAlert", 1000);
    }
    Ember.run.later(function () {
      self.setHeaders();
    });
    this.set("export_format", this.get("applicationData.export_format"));
  },

  getBinInfo: async function (data) {
    var self = this;
    const binData = [];
    
    data.forEach(element => {
      if (element && element.get('first_six')) {
        const binPromise = self.get("store").findRecord("bin", element.get('first_six'))
        .catch(error => {
            binData.push(null);
          });
        binData.push(binPromise);
      }
    });
  
    try {
      return await Promise.all(binData);
    } catch (error) {
      return null;
    }
  },

  updateGrid: async function () {
    var self = this;
    if (!this.get("isDestroying") || !this.get("isDestroyed")) {
      this.set("isRefreshing", true);
    }
    var queryParams = this.get("queryParams");
    var gridParams = this.get("gridParams");
    var pagerParams = this.get("pagerParams");

    queryParams.forEach(function (param) {
      if (param === "note_search") {
        gridParams["note"] =
          self.get(param) !== undefined ? self.get(param) : null;
      }
      gridParams[param.replace("-", ".")] =
        self.get(param) !== undefined ? self.get(param) : null;
    });

    var store = this.get("store");
    store.query(pagerParams.modelName, gridParams).then(async function (data) {
      if(pagerParams.pageRoute === "reports.transactions") {
        await self.getBinInfo(data).then((binData) => {
          self.set('binInfoData', binData);
        });
      } 
      var tmppagerParams = data.get("meta").pagination;
      if (!self.get("isDestroyed") || !self.get("isDestroying")) {
        self.set("pagerParams.pageCount", tmppagerParams.pageCount);
        self.set("pagerParams.totalCount", tmppagerParams.totalCount);
        self.set("pagerParams.page", tmppagerParams.currentPage + 1);
        self.set("gridParams.updateExportUrl", new moment().unix());
        self.set("gridData", data);
        self.set("isRefreshing", false);
      }
    });
  },
  gridNoDataId: Ember.computed("pagerParams", function () {
    var pagerParams = this.get("pagerParams");
    return "grid_no_data_" + pagerParams.modelName.split(".").join("-");
  }),
  isNotes: Ember.computed(
    "parent.{isContactsNotes,isLocationsNotes}",
    function () {
      return (
        this.get("parent.isContactsNotes") ||
        this.get("parent.isLocationsNotes")
      );
    }
  ),
  columnsArray: Ember.computed(
    "columns.[]",
    "actionColumns.@each.title",
    "isMobileSize",
    function () {
      var columns = this.get("columns") ? this.get("columns").slice() : [];
      return this.addActionColumns(columns);
    }
  ),

  //testing getting #of selected invoices

  selectedInvoices: Ember.computed("gridData", function () {
    const columns = this.get("gridData");
    let currentPage = this.get("parent.route");
    const hasChanged = this.get("parent.hasChanged");
    let selected = 0;
    if (columns) {
      columns.forEach((column) => {
        column.select && selected++;
      });
    }
    if (hasChanged) {
      // If condition is true when an invoice is selected/de-selected. Check for conflicting payment methods when if is true.
      Ember.run.once(this, "showAlert");
    }
    return selected;
  }),
  selectedMultipleInvoices: Ember.computed("gridData", function () {
    const columns = this.get("gridData");
    let selected = 0;
    if (columns)
      columns.forEach((column) => {
        column.select && selected++;
      });
    return selected > 1;
  }),
  // get a particular payment type for a given transaction
  getPaymentType: function(transaction){
    let paymentType = "cc"
    if ( transaction.ach_product_transaction_id != null &&
          transaction.cc_product_transaction_id != null &&
          transaction.ach_product_transaction_id.length > 0 &&
          transaction.cc_product_transaction_id.length > 0 ) {
            paymentType = "both";
        } else if ( transaction.ach_product_transaction_id != null &&
          transaction.ach_product_transaction_id.length > 0 ){
            paymentType = "ach";
        } else {
          paymentType = "cc";
        }
    return paymentType;
  },
  // determine whether multiple payment types
  hasMultipleInvoiceTypes: Ember.computed("gridData", function() {
    const columns = this.get("gridData");
    let multiple = false, currentPaymentType = null;
    if (columns)
      columns.forEach((column) => {
        if (column.select && currentPaymentType === null){
          currentPaymentType = this.getPaymentType(column);
        } else if (column.select && currentPaymentType !== this.getPaymentType(column)){
          multiple = true;
        }
      });
    return multiple;
  }),
  // show an alert if there are multiple invoice types selected
  showAlert: function(){
    let hasMultipleTypes = this.get("hasMultipleInvoiceTypes");
    if (hasMultipleTypes){
      alert(
        "You have selected invoices to be paid that do not match the first selected payment type.  These invoices will not be paid with the selected payment method."
      );
    }
  },
   // set total amount to be paid based on selected invoice composition
   paymentTotals: Ember.computed("gridData", function () {
    const columns = this.get("gridData");
    const paymentTotals = {
      subtotal: 0,
      surcharges: 0,
      overall_total: 0
    }
    if (this.get("parent.route") === "contactuser.quickinvoices-index"){
      if (columns && this.get('products')) {
        // let currentProduct = this.getCurrentProduct(columns);
        // if (currentProduct) {
          // let surcharge_fee = parseFloat(currentProduct.get('surcharge.surcharge_fee')) || 0;
          // let surcharge_rate = parseFloat(currentProduct.get('surcharge.surcharge_rate')) || 0;
          columns.forEach((column) => {
            if (column.select) {
              paymentTotals.subtotal += Number.parseFloat(column.amount);
              let currentSurcharge = 0;
              let currentProduct = this.getCurrentProduct(column);
              const surcharge_fee = parseFloat(currentProduct.get('surcharge.surcharge_fee')) || 0;
              const surcharge_rate = parseFloat(currentProduct.get('surcharge.surcharge_rate')) || 0;
              currentSurcharge = (surcharge_rate * parseFloat(column.amount) / 100).round(2) + surcharge_fee;
              // Same as form-transaction file. Commenting out code which resulted in incorrect transaction and surcharge calculation.
              // if ( surcharge_fee || surcharge_rate ) {
              //   let currentSurchargeFlatFee = 0, currentSurchargeRateFee = 0;
              //   if ( surcharge_fee ) {
              //     currentSurchargeFlatFee = parseInt(surcharge_fee);
              //     currentSurcharge += currentSurchargeFlatFee;
              //   }
              //   currentSurchargeRateFee = parseInt(column.amount) * parseInt(surcharge_rate)/100;
              //   currentSurcharge += currentSurchargeRateFee;
              // }
              let minSurcharge = parseFloat(currentProduct.get('surcharge.min_fee_amount'));
              let maxSurcharge = parseFloat(currentProduct.get('surcharge.max_fee_amount'));
              currentSurcharge = currentSurcharge < minSurcharge ? minSurcharge : currentSurcharge;
              currentSurcharge = currentSurcharge > maxSurcharge ? maxSurcharge : currentSurcharge;
              paymentTotals.surcharges += currentSurcharge;
            }
          });
          paymentTotals.overall_total = paymentTotals.subtotal + paymentTotals.surcharges;
        // }
      };
     }
    return paymentTotals;
  }),

  surchargeAmount: Ember.computed('gridData', function () {
    return this.get('paymentTotals.surcharges').toFixed(2).toString();
  }),

  showSurcharge: Ember.computed('gridData', function () {
    if (parseFloat(this.surchargeAmount) > 0) {
      return true;
    }
    return false;
  }),

  subtotalAmount: Ember.computed('gridData', function () {
    return this.get('paymentTotals.subtotal').toFixed(2).toString();
  }),

  totalPaymentAmount: Ember.computed('gridData', function () {
    return this.get('paymentTotals.overall_total').toFixed(2).toString();
  }),

  getCurrentProduct: function (column) {
    let currentProduct = null;
    // if (column && this.get('products')) {
      // let firstInvoice = columns.filterBy('select', true).get('firstObject');
      if ( column && column.cc_product_transaction_id ) {
        currentProduct = this.get('products').filterBy('id', column.cc_product_transaction_id).get('firstObject')
      } else if ( column && column.ach_product_transaction_id ){
        currentProduct = this.get('products').filterBy('id', column.ach_product_transaction_id).get('firstObject')
      }
    // }
    return currentProduct;
  },

  getProducts: function () {
    Ember.RSVP.hash({
      locationinfos: this.get('store').query('locationinfo', {
        relationship: 'direct'
      })
    }).then((data) => {
      data.locationinfos.forEach((item) => {
        this.set('products', item.product_transactions._objects);
        this.runUpdateGrid();
      })
    });
  },

  filters: Ember.computed(
    "columnsArray",
    "filterFields",
    "gridData",
    "tags",
    "account_type",
    "created_user_id",
    function () {
      var columns = this.get("columnsArray");
      var filterArray = [];
      var self = this;

      var i = 0;
      columns.forEach(function (col) {
        var filter = [];
        var val = [];
        if (col.filter) {
          filter = col.filter;
          switch (filter.type) {
            case "text":
              val.text = true;
              val.name = filter.field
                ? filter.field.replace(".", "-")
                : col.name.replace(".", "-");
              val.value = filter.field
                ? self.get(filter.field.replace(".", "-"))
                : self.get(col.name.replace(".", "-"));
              break;
            case "dropdown":
              val.dropdown = true;
              val.name = filter.field
                ? filter.field.replace(".", "-")
                : col.name.replace(".", "-");
              val.items = self.get(filter.items);
              val.showAllSelect = filter.showAllSelect ? true : false;
              val.value = filter.field
                ? self.get(
                    filter.field.replace(".", "-").replace(/\.(.*)/g, "_id")
                  )
                : self.get(
                    col.name.replace(".", "-").replace(/\.(.*)/g, "_id")
                  );
              val.valueFrom = self.get(
                col.name.replace(".", "-").replace(/\.(.*)/g, "_id") + "_from"
              );
              val.valueTo = self.get(
                col.name.replace(".", "-").replace(/\.(.*)/g, "_id") + "_to"
              );

              if (val.valueFrom !== undefined) {
                if (val.valueFrom === "") {
                  val.valueFrom = undefined;
                } else {
                  var from_date_time = moment(
                    val.valueFrom,
                    "MM/DD/YYYY HH:mm:ss"
                  );
                  val.valueFrom = from_date_time.format("MM/DD/YYYY");
                  if (typeof col.filter.showTimeFilter !== "undefined") {
                    if (col.filter.showTimeFilter === true) {
                      val.valueFromHour = from_date_time.format("HH");
                      val.valueFromMinute = from_date_time.format("mm");
                    }
                  }
                }
              }

              if (val.valueTo !== undefined) {
                if (val.valueTo === "") {
                  val.valueTo = undefined;
                } else {
                  var to_date_time = moment(val.valueTo, "MM/DD/YYYY HH:mm:ss");
                  val.valueTo = to_date_time.format("MM/DD/YYYY");
                  if (typeof col.filter.showTimeFilter !== "undefined") {
                    if (col.filter.showTimeFilter === true) {
                      val.valueToHour = to_date_time.format("HH");
                      val.valueToMinute = to_date_time.format("mm");
                    }
                  }
                }
              }

              val.button_style = col.filter.style;

              if (col.filter.labelKey) {
                val.labelKey = col.filter.labelKey;
              } else {
                val.labelKey = "id";
              }
              if (col.filter.labelValue) {
                val.labelValue = col.filter.labelValue;
              } else {
                val.labelValue = "title";
              }

              if (typeof col.filter.multi !== "undefined") {
                val.multi = col.filter.multi;
              } else {
                val.multi = true;
              }

              if (
                col.filter.items === "dateFiltersPast" ||
                col.filter.items === "dateFiltersTransaction" ||
                col.filter.items === "dateFiltersFuture" ||
                col.filter.items === "dateFiltersAll"
              ) {
                if (typeof col.filter.showTimeFilter !== "undefined") {
                  if (col.filter.showTimeFilter === true) {
                    val.showTimeFilter = true;
                    val.showDateFilter = false;
                  } else {
                    val.showDateFilter = true;
                  }
                } else {
                  val.showDateFilter = true;
                }
              }
              val.notuniqbytitle = col.filter.notuniqbytitle;
              break;
            case "typeahead":
              val.typeahead = true;
              val.name = filter.field
                ? filter.field.replace(".", "-")
                : col.name.replace(".", "-");
              val.value = filter.field
                ? self.get(filter.field.replace(".", "-"))
                : self.get(col.name.replace(".", "-"));
              val.modelName = filter.modelName;
              val.multi = filter.multi;
              val.searchBy = filter.searchBy;
              val.sortBy = filter.sortBy;
              val.fields = filter.fields;
              val.optionLabelPath = filter.optionLabelPath;
              if (val.value && !val.items) {
                self.send("updateFilterTypeAhead", val.value, val, true);
              }
              break;
            default:
              val = {};
          }
          val.title = col.title;
        }
        filterArray[i++] = val;
      });
      return filterArray;
    }
  ),
  isFilters: Ember.computed("filters.[]", function () {
    var filters = this.get("filters");
    var isFilters = false;
    filters.map(function (filter) {
      if (filter.name) {
        isFilters = true;
      }
    });
    return isFilters;
  }),
  headers: Ember.computed(
    "columnsArray.[]",
    "sort",
    "isMobileSize",
    function () {
      return this.setHeaders();
    }
  ),
  setHeaders: function (cols) {
    if (this.get("isDestroying") || this.get("isDestroyed")) {
      return;
    }
    var columns = cols || this.get("columnsArray");
    var sort = this.get("sort");
    var colArray = [];
    var self = this;
    columns = this.addActionColumns(columns);

    var i = 0;
    columns.forEach(function (col) {
      // Set value for calculating and showing filters
      if (col.filter) {
        self.set("showFilter", true);
      }
      if (col.total) {
        self.set("showTotal", true);
      }

      var val = [];
      val.name = col.name;
      val.title = col.title;
      val.type = col.type;
      val.align = col.align;
      val.sortable = col.sortable;
      val.isActionColumn = col.isActionColumn;
      val.showACHSECCodeInfo = col.showACHSECCodeInfo;
      val.alignRight = val.align
        ? val.align.indexOf("text-right") !== -1
          ? true
          : false
        : false;

      // Setup sorting
      var sortAscending = sort ? sort.substr(0, 1) !== "-" : true;
      var sortProperty = sort ? sort.replace(/^\-/, "") : "";
      var colName =
        col.filter && col.filter.field ? col.filter.field : col.name;
      if (sortProperty === colName) {
        if (sortAscending) {
          val.class = "fas fa-sort-up text-primary";
          val.sort = "-" + colName;
          val.isSortingGridAsc = true;

        } else {
          val.class = "fas fa-sort-down text-primary";
          val.sort = colName;
          val.isSortingGridDesc = true;
        }
      } else {
        val.class = "fas fa-sort";
        val.sort = colName;
        val.isSortingGridAsc = false;
        val.isSortingGridDesc = false;
      }
      colArray[i++] = val;
    });
    return colArray;
  },
  headersSortable: Ember.computed("headers.[]", function () {
    var headers = this.get("headers");
    return headers.filter(function (header) {
      return header.sortable;
    });
  }),
  totals: Ember.computed("columnsArray", "gridData", function () {
    var self = this;
    var columns = this.get("columnsArray");
    var model = this.get("gridData");
    var colArray = [];

    // Safety net, return empty if model is not loaded yet due to promise issues
    if (!model) {
      return;
    }

    var i = 0;
    columns.forEach(function (col) {
      // Set value for column totals
      var val = [];
      if (col.total) {
        if (col.totalValue) {
          col.totalValue.set("gridData", self.get("gridData"));
          val.total = col.totalValue.get("value");
        } else {
          var totalCol = 0;
          model.forEach(function (item) {
            if (item.get("isSuccess")) {
              if (
                item.get("type.id") &&
                (item.get("type.id") === "20" || item.get("type.id") === "50")
              ) {
                totalCol += parseFloat(item.get(col.name));
              } else if (
                item.get("type.id") &&
                (item.get("type.id") === "30" || item.get("type.id") === "40")
              ) {
                totalCol -= parseFloat(item.get(col.name));
              }
            }
          });
          val.total = "$ " + String(totalCol).currency(2);
        }
        val.align = col.align;
      } else {
        val.total = "";
        val.align = "";
      }
      colArray[i++] = val;
    });
    return colArray;
  }),
  dataObserver: Ember.observer(
    "gridData",
    "columnsArray",
    "isClosingBatch",
    "helperFunctions.reload_data",
    function () {
      var self = this;
      var dataArray = [];
      var model = this.get("gridData");
      var route = this.get("route");
      var cols = this.get("columnsArray");
      var i = 0;

      // Safety net, return empty if model is not loaded yet due to promise issues
      if (!model || model === null) {
        return;
      }

      var session = this.get("session");
      var tz = session.get("authenticated.timezone");
      var badTz = "";
      if (!tz) {
        tz = "America/New_York";
      }

      // Check if the zone is loaded
      if (!tz || !moment().tz(tz)) {
        tz = "America/New_York";
        badTz = " *";
      }

      var multiSelected = false;
      var multiSelectedCount = 0;

      model.forEach(function (row, rowIndex) {
        if (row.select) {
          multiSelectedCount += 1;
        }
      });

      if (multiSelectedCount > 1) {
        multiSelected = true;
      }

      model.forEach(function (row, rowIndex) {
        var x = 0;
        var rowArray = [];
        cols.forEach(function (col) {
          if (!col) {
            return;
          } else if (col.component) {
            var cell = assign(
              {
                action_data: row,
              },
              col
            );
            rowArray[x++] = cell;
          } else {
            var val;
            var td_class = col.classNames || "";
            var dateValue;
            var colDate;
            var actionDisabled = false;
            var showAction = true;
            var hasPriv = false;
            if (col.action && col.actionpriv) {
              if (Ember.isArray(col.actionpriv)) {
                var hasAll = true;
                col.actionpriv.forEach(function (priv) {
                  if (!session.get("authenticated.resources." + priv)) {
                    hasAll = false;
                  }
                });
                hasPriv = hasAll;
              } else if (typeof col.actionpriv === "string") {
                if (session.get("authenticated.resources." + col.actionpriv)) {
                  hasPriv = true;
                }
              }
            }
            switch (col.type) {
              case "currency":
                if (Ember.isEmpty(row.get(col.name))) {
                  val = "$ 0.00";
                } else if (isNaN(row.get(col.name.replace("-", ".")))) {
                  val = row.get(col.name.replace("-", "."));
                } else {
                  val = "$ " + row.get(col.name.replace("-", ".")).currency(2);
                }
                break;
              case "date":
                colDate = row.get(col.name);
                if (colDate !== undefined && colDate !== "Invalid date") {
                  dateValue = moment(colDate, col.dateFormat);
                  if (badTz) {
                    val = dateValue.isValid()
                      ? dateValue.format("MM/D/YYYY") + badTz
                      : "";
                  } else {
                    val = dateValue.isValid()
                      ? dateValue.tz(tz).format("M/D/YYYY")
                      : "";
                  }
                } else {
                  val = "";
                }
                break;
              case "dateNoTimezoneConvert":
                colDate = row.get(col.name);
                if (colDate !== undefined && colDate !== "Invalid date") {
                  dateValue = moment(colDate, col.dateFormat).utc();
                  val = dateValue.isValid() ? dateValue.format("M/D/YYYY") : "";
                } else {
                  val = "";
                }
                break;
              case "shortdate":
                colDate = row.get(col.name);
                if (colDate !== undefined && colDate !== "Invalid date") {
                  dateValue = moment(colDate, col.dateFormat);
                  val = dateValue.isValid()
                    ? dateValue.tz(tz).format("M/D/YYYY h:mm a")
                    : "";
                } else {
                  val = "";
                }
                break;
              case "longdate":
                colDate = row.get(col.name);
                if (colDate !== undefined && colDate !== "Invalid date") {
                  dateValue = moment(colDate, col.dateFormat);
                  if (badTz) {
                    val = dateValue.isValid()
                      ? dateValue.format("MMMM Do YYYY, h:mm:ss a z") + badTz
                      : "";
                  } else {
                    val = dateValue.isValid()
                      ? dateValue.tz(tz).format("MMMM Do YYYY, h:mm:ss a z")
                      : "";
                  }
                } else {
                  val = "";
                }
                break;
              case "check":
                td_class = "slim";
                if (row.get(col.name) && row.get(col.name) !== "false") {
                  val =
                    '<i class="fas fa-check grid-icon grid-icon-check"></i>';
                } else {
                  val = "";
                }
                break;
              case "checkbox":
                td_class = "slim";
                if (row.select) {
                  val = '<input type="checkbox" checked/>';
                } else {
                  val = '<input type="checkbox" />';
                }
                break;
              case "input":
                td_class = "slim";
                if (col.editableColumnType) {
                  col.isEditableColumn = true;
                }
                break;
              case "shield":
                td_class = "slim";
                if (row.get(col.name) && row.get(col.name) !== "false") {
                  if (col.action && hasPriv) {
                    val = '<i class="fas fa-shield-alt"></i>';
                  } else {
                    val =
                      '<i class="fas fa-shield-alt grid-icon grid-icon-shield"></i>';
                  }
                } else {
                  val = "";
                }
                break;
              case "redo":
                td_class = "slim";
                if (row.get(col.name) && row.get(col.name) !== "false") {
                  if (col.action && hasPriv) {
                    val = '<i class="fas fa-redo-alt"></i>';
                  } else {
                    val =
                      '<i class="fas fa-redo-alt grid-icon grid-icon-shield"></i>';
                  }
                } else {
                  val = "";
                }
                break;
              case "sync":
                td_class = "slim";
                if (row.get(col.name)) {
                  val =
                    '<i class="fas fa-exchange-alt grid-icon grid-icon-sync" title="Synced via API id ' +
                    row.get("contact_api_id") +
                    '"></i>';
                } else {
                  val = "";
                }
                break;
              case "user":
                td_class = "slim";
                if (row.get(col.name)) {
                  val =
                    '<i class="fas fa-user grid-icon grid-icon-user" title="Username: ' +
                    row.get("user.username") +
                    '"></i>';
                } else {
                  val = "";
                }
                break;
              case "detail":
                td_class = "slim";
                if (row.get(col.name)) {
                  col.actionClass = "btn btn-xs btn-primary";
                  val =
                    '<i class="fas fa-list-alt fa-1_1" alt="Full Detail" title="Full Detail"></i>';
                } else {
                  val = "";
                }
                break;
              case "receipt":
                td_class = "slim";
                if (row.get(col.name)) {
                  col.actionClass = "btn btn-xs btn-primary";
                  val =
                    '<i class="fas fa-file-alt fa-1_1" alt="Receipt" title="Receipt"></i>';
                } else {
                  val = "";
                }
                break;
              case "resend":
                td_class = "slim";
                col.actionClass = "btn btn-xs btn-primary";
                val =
                  '<i class="fas fa-envelope fa-1_1" alt="Resend" title="Resend"></i>';
                break;
              case "summary":
                if (row.get(col.name)) {
                  col.actionClass = "btn btn-xs btn-primary";
                  val =
                    '<i class="fas fa-list fa-1_1" alt="Summary" title="Summary"></i>';
                } else {
                  val = "";
                }
                break;
              case "image":
                var img = (row.get(col.name) || "settle").toLowerCase();
                val =
                  '<img src="/images/' + img + '.png" width="35" height="22">';
                break;
              case "view":
                td_class = "slim";
                col.actionClass = "btn btn-xs btn-primary";
                val =
                  '<i class="far fa-eye fa-1_1" alt="View" title="View"></i>';
                break;
              case "edit":
                td_class = "slim";
                col.actionClass = "btn btn-xs btn-primary";
                val =
                  '<i class="fas fa-pencil-alt fa-1_1" alt="Edit" title="Edit"></i>';
                break;
              case "delete":
                td_class = "slim";
                col.actionClass =
                  "btn btn-xs btn-danger" +
                  (row.get("active") !== undefined && !row.get("active")
                    ? " disabled"
                    : "");
                val =
                  '<i class="far fa-trash-alt fa-1_1" alt="Delete" title="Delete"></i>';
                actionDisabled =
                  row.get("active") !== undefined && !row.get("active");
                break;
              case "tree":
                td_class = "slim";
                col.actionClass = "btn btn-xs btn-primary";
                val =
                  '<i class="fas fa-tree fa-1_1" alt="View Tree" title="View Tree"></i>';
                break;
              case "pay":
                td_class = "slim";
                val =
                  '<button style="min-width:27px;" class="btn btn-xs btn-warning' +
                  (((!row.get("select") && !row.get("cantPay")) || (row.get("cantPay") || multiSelected)) ? " disabled" : "") +
                  '" alt="Pay Now" title="Pay Now"><i class="fas fa-dollar-sign fa-1_1"></i></button>';
                if (col.name === "payQuickInvoice") {
                  // Check if the invoice is not selected and its not paid.
                  const isInvoiceSelected = row.get("select");
                  const cantPay = row.get("cantPay");
                  if (!isInvoiceSelected && !cantPay) {
                    actionDisabled = true;
                  } else {
                    actionDisabled = row.get("cantPay");
                  }
                }
                break;
              case "phone":
                if (
                  row.get(col.name) === null ||
                  row.get(col.name) === undefined
                ) {
                  val = "";
                } else {
                  val = row
                    .get(col.name)
                    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                }
                break;
              case "array":
                var data = row.get(col.name);
                var data_csv = "";
                data.forEach(function (dat, index, data) {
                  data_csv += dat.get("title");
                  if (index < data.get("length") - 1) {
                    data_csv += ", ";
                  }
                });
                val = data_csv.replace(/\,$/, "");
                break;
              case "custom_data":
                var dataobject = row.get(col.name);
                if (dataobject) {
                  val = "";
                  Object.keys(dataobject).forEach(function (d) {
                    val += d + ": " + dataobject[d] + "<br>";
                  });
                } else {
                  val = "";
                }
                break;
              case "indicator":
                const level3Data = row.get(col.name);
                const binInfo = self.get('binInfoData');
                const gridD = self.get('gridData');
                if(binInfo && gridD) {
                  const allItems = gridD._objects;
                  const bin = binInfo.filter(function(item) {
                    return item !== null && item !== undefined && (item.get('bin') === allItems[i].first_six);
                  });  
                  if(bin[0]) {
                    const cardType = bin[0].card_type;
                    const cardBrand = bin[0].card_brand;
                    const binType = bin[0].bin_type;
                    if(level3Data && cardType === 'CREDIT' && (cardBrand === 'VISA' || cardBrand === 'MASTERCARD' || cardBrand === 'VISA/DANKORT') && binType === 'COMMERCIAL') {
                      val = '<i class="fa fa-credit-card" alt="L3 compatible with data" title="L3 compatible with data" style="font-size:20px;color:green"></i>';
                    }
                    if(!level3Data && cardType === 'CREDIT' && (cardBrand !== 'VISA' || cardBrand !== 'MASTERCARD' || cardBrand !== 'VISA/DANKORT') && binType !== 'COMMERCIAL') {
                      val = "";
                    }
                    if(!level3Data && cardType === 'CREDIT' && (cardBrand === 'VISA' || cardBrand === 'MASTERCARD' || cardBrand === 'VISA/DANKORT') && binType === 'COMMERCIAL') {
                      val = '<i class="fa fa-credit-card" alt="L3 compatible without data" title="L3 compatible without data" style="font-size:20px;color:red"></i>';
                    }
                    // Double check here for null because it may have ended up null after we translate the value
                    if (val === null || val === undefined) {
                      val = "";
                    }
                  } else {
                    val = "";
                  }
                } else {
                  val = "";
                }
                break;
              default:
                if (col.name === "location_id"
                  || col.name === "account_vault_id"
                  || col.name === 'response_code'
                ) {
                  val = !row.get(col.name) ? '' : row.get(col.name);
                } else if (
                  row.get(col.name) === null &&
                  col.name !== "reason_code.title"
                ) {
                  val = "";
                } else {
                  if (
                    col.name === "postback_config_id" ||
                    col.name === "model_id" ||
                    col.name.indexOf("user_type_id") !== -1 ||
                    col.name === "device_term_api_id" ||
                    col.name === "transaction_batch_id" ||
                    ((col.name === "merchant_id" ||
                      col.name === "terminal_id") &&
                      route === "supportproducttransactions")
                  ) {
                    val = row.get(col.name);
                  } else if (col.name === "entry_mode_id") {
                    var entryModeItems = self.get("EntryModeItems");
                    entryModeItems.forEach(function (item) {
                      if (item.id === row.get(col.name)) {
                        val = item.title;
                      }
                    });
                  } else if (col.name === "is_enabled") {
                    var featureFlagsEnableTypes = self.get(
                      "featureFlagsEnableTypes"
                    );
                    featureFlagsEnableTypes.forEach(function (item) {
                      if (item.id === row.get(col.name)) {
                        val = item.title;
                      }
                    });
                  } else if (
                    route === "supportnotificationtemplates" &&
                    col.name === "title"
                  ) {
                    val = row.get(col.name);
                    if (!row.get("location_id")) {
                      val +=
                        ' <span class="label label-primary">System Default</span>';
                    }
                  } else if (
                    route === "supportapilogs" &&
                    (col.name === "developer_id" || col.name === "user_id")
                  ) {
                    val = row.get(col.name);
                  } else if (
                    (route === "supportonboardingdetails" ||
                      route === "supportonboardingdetailsportfolio") &&
                    (col.name === "location_id" ||
                      col.name === "parent_location_id")
                  ) {
                    val = row.get(col.name);
                  } else if (col.name === "trx_source_id") {
                    var trx_sources = self.get("trans_sources");
                    val = row.get(col.name);
                    var tempTrxSource = trx_sources
                      .filter(function (trx_source) {
                        return trx_source.id === val;
                      })
                      .get("firstObject");
                    val = tempTrxSource.title;
                  } else if (
                    col.name === "reason_code_id" ||
                    col.name === "reason_code.id" ||
                    col.name === "reason_code.title"
                  ) {
                    val = self
                      .get("helperFunctions")
                      .formatReasoncodeTransaction([row], col.name);
                  } else {
                    val = row.get(col.name.replace(/_id?$/, ".title"));
                  }
                  // Double check here for null because it may have ended up null after we translate the value
                  if (val === null || val === undefined) {
                    val = "";
                  }
                }
            }
            if (!col.align || self.get("isMobileSize")) {
              col.align = "text-left";
            }
            if (row.get(col.name) === "description") {
              val = '<span class="truncate">' + val + "</span>";
            }
            if (
              col.name === "mailbox_message_status.title" &&
              row.get(col.name) === "unread"
            ) {
              td_class = "text-danger";
            }
            var action_data = row;
            if (col.action_data) {
              action_data = row.get(col.action_data);
            }
            var link;
            if (col.link && col.linkid) {
              link = col.link.replace(/{(.*?)}/g, function (x) {
                if (
                  col.hasOwnProperty("linkvisible") &&
                  col.linkvisible(row, self) === false
                ) {
                  return null;
                }
                var re = /\{(.*?)\}/g;
                var arr = re.exec(x);
                if (isNaN(arr[1])) {
                  if (arr[1] === "token") {
                    return session.get("authenticated.token");
                  } else if (arr[1] === "developer-id") {
                    return window.UiENV.APP.DEVELOPER_ID;
                  } else {
                    return self.get(arr[1]);
                  }
                } else {
                  return row.get(col.linkid[arr[1]]);
                }
              });
            }
            if (
              link &&
              link.indexOf("null") === -1 &&
              link.indexOf("undefined") === -1
            ) {
              val = val || "_____";
              val = '<a href="' + link + '">' + val + "</a>";
            }
            if (
              Ember.isEmpty(val) &&
              !Ember.isEmpty(col.action) &&
              !Ember.isEmpty(col.actionpriv)
            ) {
              val = "";
            } else if (Ember.isEmpty(val) && !Ember.isEmpty(col.action)) {
              val = "______";
            }
            var itemHTMLString = "";
            if (self.get("isMobileSize")) {
              itemHTMLString = Ember.String.htmlSafe(
                '<div class="' +
                  col.align +
                  '" style="display:inline-block">' +
                  val +
                  "</div>"
              );
            } else {
              itemHTMLString = Ember.String.htmlSafe(
                '<div class="' + col.align + '">' + val + "</div>"
              );
            }
            var actionId = "";
            if (col.actionId) {
              actionId = col.actionId + "_" + rowIndex;
            }
            if (col.actionDisabledCheck && !actionDisabled) {
              actionDisabled = col.actionDisabledCheck(row);
            }

            if (multiSelected && col.name === "payQuickInvoice") {

              rowArray[x++] = {
                item: itemHTMLString,
                action_name: undefined,
                action_data: action_data,
                actionpriv: col.actionpriv,
                class: td_class,
                hasPriv: false,
                showAction: true,
                actionDisabled: false,
                actionDisabledMessage: "",
                actionId: "",
                isActionColumn: undefined,
                actionClass: undefined,
                isEditableColumn: undefined,
                editableColumnType: undefined,
                adornment: undefined,
                editableColumnValue: action_data[col.name],
                showError: false,
                error: undefined,
              };

            } else {
              rowArray[x++] = {
                item: itemHTMLString,
                action_name: col.action,
                action_data: action_data,
                actionpriv: col.actionpriv,
                class: td_class,
                hasPriv: hasPriv,
                showAction: showAction,
                actionDisabled: actionDisabled,
                actionDisabledMessage: col.actionDisabledMessage || "",
                actionId: actionId,
                isActionColumn: col.isActionColumn,
                actionClass: col.actionClass,
                isEditableColumn: col.isEditableColumn,
                editableColumnType: col.editableColumnType,
                adornment: col.adornment,
                editableColumnValue: action_data[col.name],
                showError: col.name === "amount" && action_data.error,
                error: action_data.error,
              };
            }

          }
        });
        dataArray[i++] = rowArray;
      });
      this.set('data',dataArray)
      // return dataArray;
    }
  ),

  accountTypes: Ember.computed(function () {
    return this.get("applicationData.accountTypes");
  }),
  active_status: Ember.computed(function () {
    return this.get("applicationData.active_status");
  }),
  all_ach_sec_codes: Ember.computed("product_transactions.[]", function () {
    var all_ach_sec_codes = this.get("applicationData.all_ach_sec_codes");
    var product_transactions = this.get("product_transactions");
    var hasPaya = false;
    if (product_transactions && product_transactions.get("length")) {
      product_transactions.map(function (product) {
        if (product.processor === "zach") {
          hasPaya = true;
        }
      });
    }
    if (hasPaya) {
      all_ach_sec_codes = all_ach_sec_codes.concat({
        id: "C21",
        title: "C21",
      });
    }
    return all_ach_sec_codes;
  }),
  dateFiltersAll: Ember.computed(function () {
    return this.get("applicationData.dateFiltersAll");
  }),
  dateFiltersFuture: Ember.computed(function () {
    return this.get("applicationData.dateFiltersFuture");
  }),
  dateFiltersPast: Ember.computed(function () {
    return this.get("applicationData.dateFiltersPast");
  }),
  dateFiltersTransaction: Ember.computed(function () {
    return this.get("applicationData.dateFiltersTransaction");
  }),
  domainThemes: Ember.computed(function () {
    return this.get("applicationData.domainThemes");
  }),
  exportFormats: Ember.computed(function () {
    return this.get("applicationData.exportFormats");
  }),
  featureFlagsEnableTypes: Ember.computed(function () {
    return this.get("applicationData.featureFlagsEnableTypes");
  }),
  intervals: Ember.computed(function () {
    return this.get("applicationData.intervals");
  }),
  paymentMethods: Ember.computed(function () {
    return this.get("applicationData.paymentMethods");
  }),
  postback_resources: Ember.computed(function () {
    return this.get("applicationData.postback_resources");
  }),
  quickinvoice_paymentStatus: Ember.computed(function () {
    return this.get("applicationData.quickinvoice_paymentStatus");
  }),
  quickinvoice_status: Ember.computed(function () {
    return this.get("applicationData.quickinvoice_status");
  }),
  recurring_statuses: Ember.computed(function () {
    return this.get("applicationData.recurring_statuses");
  }),
  recurring_types: Ember.computed(function () {
    return this.get("applicationData.recurring_types");
  }),
  request_methods: Ember.computed(function () {
    return this.get("applicationData.request_methods");
  }),
  response_codes: Ember.computed(function () {
    return this.get("applicationData.response_codes");
  }),
  terminalmanufacturerIdTypes: Ember.computed(function () {
    return this.get("applicationData.terminalmanufacturerIdTypes");
  }),
  trans_reasoncodes: Ember.computed(function () {
    return this.get("applicationData.trans_reasoncodes");
  }),
  trans_sources: Ember.computed(function () {
    return this.get("applicationData.trans_sources");
  }),
  yesNo: Ember.computed(function () {
    return this.get("applicationData.yesNo");
  }),
  yesNoAny: Ember.computed(function () {
    return this.get("applicationData.yesNoAny");
  }),
  yesNoBoolean: Ember.computed(function () {
    return this.get("applicationData.yesNoBoolean");
  }),

  // Functions
  addActionColumns: function (cols) {
    var actionColumns = this.get("actionColumns");
    var self = this;
    if (actionColumns) {
      actionColumns.map(function (column) {
        let alreadyHasColumn = false;
        cols.map(function (co) {
          if (column.actionId === co.actionId) {
            alreadyHasColumn = true;
          }
        });
        if (!alreadyHasColumn) {
          if (self.get("isMobileSize")) {
            cols.pushObject(column);
          } else {
            cols.unshiftObject(column);
          }
        }
      });
    }
    return cols;
  },

  willDestroyElement: function () {
    this._super(...arguments);
    var queryParams = this.get("queryParams");
    var self = this;
    var target = self.get("parent");
    if (!this.get("isEditableGrid")) {
      queryParams.forEach(function (param) {
        self.set(param, target.get(param));
        self.removeObserver(param.replace(".", "-"), self, "runUpdateGrid");
      });
    } else {
      self.removeObserver("needsRefresh", self, "runUpdateGrid");
    }
    self.removeObserver("gridParams.needsRefresh", self, "runUpdateGrid");
  },
});
