import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  page: 1,
  page_size: '15',
  isRefreshing: true,
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'device_log_action_id',
    'model',
    'model_id',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      model_id: {
        'name': 'model_id',
        'title': 'Device Id',
        'type': 'string',
        'sortable': true,
        'action': function(devicelog) {
          self.send('view', devicelog);
        },
        'actionId': 'devicelog_model_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      model: {
        'name': 'model',
        'title': 'Device Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      device_log_action_id: {
        'name': 'device_log_action_id',
        'title': 'Device Log<br>Action',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'devicelogactions',
          multi: true,
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created<br>Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(devicelog) {
          self.send('view', devicelog);
        },
        'actionId': 'devicelog_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['model_id', 'model', 'device_log_action_id', 'created_ts', 'view'];
    var listOfMobileCols = ['model_id', 'model', 'device_log_action_id', 'created_ts', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    view: function(devicelog) {
      var self = this;
      if(devicelog.get('model').toLowerCase() === 'terminal') {
        this.get('store').query('terminal', {
          serial_number: devicelog.get('model_id')
        }).then(function(result){
          if(result && result.get('length') === 1) {
            self.transitionToRoute('admin.terminals.view', result.get('firstObject').get('id'));
          } else {
            noty({
              text: 'Error finding Terminal',
              type: 'error'
            });
          }
        });
      }

    }
  }
});
