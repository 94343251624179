import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  primaryKey: 'logo_url',
  normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
    let { modelName } = primaryModelClass;
    id = payload.logo.logo_url;
    if (store.hasRecordForId(modelName, id)) {
      let record = store.peekRecord(modelName, id);
      store.unloadRecord(record);
    }

    return this._super(store, primaryModelClass, payload, id, requestType);
  }
});
