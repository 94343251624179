import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),
  crudFormFields: Ember.inject.service('crud-form-fields'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminalmanufacturer: store.findRecord('terminalmanufacturer', params.id)
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var terminalmanufacturer = models.terminalmanufacturer;
    controller.set('terminalmanufacturer', terminalmanufacturer);
    controller.set('crudParams', {
      formModelName: 'Terminal Manufacturer',
      formFields: this.get('crudFormFields').getForModel('terminalmanufacturer'),
      buttonPermissions: {
        overall: 'v2-terminalmanufacturers-get',
        back: 'v2-terminalmanufacturers-get',
        cancel: 'v2-terminalmanufacturers-get'
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Terminal Manufacturer ● ' + terminalmanufacturer.get('title'),
      route_name: 'admin.terminalmanufacturers.crud',
      route_id: terminalmanufacturer.get('id')
    });
    controller.set('pageTitle', 'View Terminal Manufacturer');
    controller.set('pageCrumbs', [{
      title: 'Terminal Manufacturers',
      link: 'admin.terminalmanufacturers.index'
    }, {
      title: 'View Terminal Manufacturer',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.terminalmanufacturer, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
