import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/postbackconfigform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      postbackconfig: store.createRecord('postbackconfig', {
        location_id: params.id,
        resource: 'contact',
        on_create: 0,
        on_update: 0,
        on_delete: 0,
        number_of_attempts: 1,
        attempt_interval: 300,
        is_active: 1,
        format: 'api-default'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('postbackconfig', models.postbackconfig);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Postback Config',
      route_name: 'locations.postbackconfigs-add',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Postback Configs',
      link: 'locations.postbackconfigs-index',
      record: models.location.get('id')
    }, {
      title: 'Add Postback Config',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.postbackconfig, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
