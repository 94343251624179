import Ember from 'ember';
import jQuery from 'jquery';

Ember.TextField.reopen({
  classNames: ['form-control dynamic-textfield']
});

export default Ember.TextField.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  classNameBindings: ['uniqueClassNameForTesting'],

  uniqueClassNameForTesting: Ember.computed(function() {
    var name = this.get('name');
    var pagerParams = this.get('parent.pagerParams');
    return 'filter_' + pagerParams.pageRoute.split('.').join('-') + '_' + name;
  }),

  keyDown: function(e) {
    var self = this;
    if (e.keyCode === 13) {
      var parentController = this.get('parent');
      var pagerParams = parentController.get('pagerParams');
      var data = {
        'controller': pagerParams.pageRoute,
        'filters': [{
          'name': self.$().attr('name'),
          'value': self.value
        }]
      };
      this.action(data);
    }
  },

  init: function() {
    this._super(...arguments);
    var parentController = this.get('parent');
    var pagerParams = parentController.get('pagerParams');
    var name = this.get('name');
    this.set('elementId', 'filter_' + pagerParams.pageRoute.split('.').join('-') + '_' + name);
  },
  didInsertElement: function() {
    var parentController = this.get('parent');
    var pagerParams = parentController.get('pagerParams');
    var self = this;
    jQuery(document).on('blur', '#' + this.$().attr('id'), function setFilters() {
      var data = {
        'controller': pagerParams.pageRoute,
        'filters': [{
          'name': self.$().attr('name'),
          'value': self.value
        }]
      };
      self.action(data);
    });
  },
  willDestroyElement: function() {
    jQuery(document).off('blur', '#' + this.$().attr('id'));
  }
});
