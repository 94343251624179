import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsTransactions: true,

  sort: '-created_ts',
  page: 1,
  page_size: '15',
  needsRefresh: '',
  created_ts: 'last 90 days',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'auth_amount',
    'auth_code',
    'batch',
    'billing_zip',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'description',
    'is_accountvault',
    'is_recurring',
    'last_four',
    'needsRefresh',
    'order_num',
    'product_transaction_id',
    'quick_invoice_id',
    'room_num',
    'status_id',
    'tags',
    'tax',
    'terminal_id',
    'transaction_amount',
    'type_id',
  ],
  columns: Ember.computed('locationinfo', 'terminals.[]', 'locationinfo.product_transactions.[]', 'isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Transaction<br>Date',
        'type': 'shortdate',
        'sortable': true,
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      auth_amount: {
        'name': 'auth_amount',
        'title': 'Auth<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      transaction_amount: {
        'name': 'transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        },
        'total': true,
        'totalValue': Ember.Object.extend({
          data: null,
          value: Ember.computed('gridData', function() {
            return '$ ' + String(this.get('gridData.meta.report.pageTotalTransactionAmount')).currency(2) + ' of<br>$ ' + String(this.get('gridData.meta.report.totalTransactionAmount')).currency(2) + ' Total';
          })
        }).create()
      },
      tip_amount: {
        'name': 'tip_amount',
        'title': 'Tip<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      surcharge_amount: {
        'name': 'surcharge.surcharge_amount',
        'title': 'Surcharge<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      tax: {
        'name': 'tax',
        'title': 'Sales<br>Tax',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      billing_zip: {
        'name': 'billing_zip',
        'title': 'Billing<br>Zip',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      room_num: {
        'name': 'room_num',
        'title': 'Room<br>Num',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      tags: {
        'name': 'tags',
        'title': 'Tags',
        'type': 'array',
        'sortable': false,
        'filter': {
          type: 'dropdown',
          items: 'tagList',
          labelKey: 'title',
          multi: true,
          showAllSelect: true
        }
      },
      batch: {
        'name': 'batch',
        'title': 'Batch',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true
        }
      },
      created_user_id: {
        'name': 'created_user_id',
        'title': 'Created By',
        'type': 'string',
        'sortable': false,
        'action': function(transaction) {
          self.transitionToRoute('locations.users-view', transaction.get('location.id'), transaction.get('created_user.id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-users-get'],
        'filter': {
          type: 'dropdown',
          items: 'users',
          multi: true,
          showAllSelect: true
        }
      },
      type: {
        'name': 'type_id',
        'title': 'Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'types',
          multi: true,
          showAllSelect: true
        }
      },
      status_id: {
        'name': 'status_id',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'statuses',
          multi: true,
          showAllSelect: true
        }
      },
      auth_code: {
        'name': 'auth_code',
        'title': 'Auth<br>Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      is_recurring: {
        'name': 'is_recurring',
        'title': 'Recurring<br>Billing',
        'type': 'redo',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('contacts.recurrings-view', transaction.get('contact_id'), transaction.get('recurring_id'));
        },
        'actionpriv': 'v2-recurrings-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      is_accountvault: {
        'name': 'is_accountvault',
        'title': 'Account<br>Vault',
        'type': 'shield',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('contacts.accounts-view', transaction.get('contact_id'), transaction.get('account_vault_id'));
        },
        'actionpriv': 'v2-accountvaults-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      order_num: {
        'name': 'order_num',
        'title': 'Order<br>Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      terminal_id: {
        'name': 'terminal_id',
        'title': 'Terminal',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          if(session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) {
            self.transitionToRoute('admin.terminals.view', transaction.get('terminal_id'));
          } else {
            self.transitionToRoute('locations.terminals-view', transaction.get('location_id'), transaction.get('terminal_id'));
          }
        },
        'actionpriv': (session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) ? 'v2admin-terminals-get' : ['v2-locations-get', 'v2-terminals-get'],
        'filter': {
          type: 'dropdown',
          items: 'locationinfo.terminals',
          multi: true,
          showAllSelect: true
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.viewproducttransaction', transaction.get('location_id'), transaction.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          showAllSelect: true,
          notuniqbytitle: true
        }
      },
      quick_invoice_id: {
        'name': 'quick_invoice.title',
        'title': 'Quick<br>Invoice',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('contacts.quickinvoices-view', transaction.get('contact_id'), transaction.get('quick_invoice_id'));
        },
        'actionpriv': 'v2-quickinvoices-get',
        'filter': {
          type: 'text',
          field: 'quick_invoice_id'
        }
      },
      show_detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(transaction) {
          self.send('showDetail', transaction);
        },
        'actionId': 'contactstransactions_report_fulldetail_link',
        'sortable': false,
        'isActionColumn': true
      },
      show_trans: {
        'name': 'id',
        'title': '',
        'type': 'receipt',
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'actionId': 'contactstransactions_report_receipt_link',
        'sortable': false,
        'isActionColumn': true
      }
    };

    var terminals = this.get('locationinfo.terminals');
    var product_transactions = this.get('locationinfo.product_transactions');

    var listOfCols = ['show_trans', 'show_detail', 'created_ts', 'account_holder_name', 'auth_amount', 'transaction_amount', 'tip_amount', 'surcharge_amount', 'tax', 'billing_zip', 'room_num', 'description', 'tags', 'batch', 'account_type', 'created_user_id', 'type', 'status_id', 'auth_code', 'last_four', 'is_recurring', 'is_accountvault', 'order_num', 'terminal_id', 'product_transaction_id', 'quick_invoice_id'];
    var listOfMobileCols = ['created_ts', 'transaction_amount', 'status_id', 'show_trans', 'show_detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      if (!this.get('locationinfo.hasProductTip')) {
        cols.removeObject(columns.tip_amount);
      }
      if (!this.get('locationinfo.hasSurcharge')) {
        cols.removeObject(columns.surcharge_amount);
      }
      if (!this.get('locationinfo.hasTax')) {
        cols.removeObject(columns.tax);
        cols.removeObject(columns.billing_zip);
      }
      if (!this.get('locationinfo.hasLodging')) {
        cols.removeObject(columns.room_num);
      }
      if (!this.get('locationinfo.hasVTOrderNum')) {
        cols.removeObject(columns.order_num);
      }
      if ((terminals && terminals.get('length') < 1) || !terminals) {
        cols.removeObject(columns.terminal_id);
      }
      if ((product_transactions && product_transactions.get('length') < 1) || !product_transactions) {
        cols.removeObject(columns.product_transaction_id);
      }
      if (!this.get('locationinfo.hasQuickInvoice')) {
        cols.removeObject(columns.quick_invoice_id);
      }
      return cols;
    }
  }),

  // Actions
  actions: {
    showDetail: function(trans) {
      this.transitionToRoute('transactiondetailfull', trans.get('id'));
    },
    showTrans: function(transaction) {
      var self = this;
      var store = this.get('store');
      return Ember.RSVP.hash({
        locationinfo: store.findRecord('locationinfo', transaction.get('location_id'))
      }).then(
        function(hash) {
          self.send('showTransDetail', transaction, hash.locationinfo);
        },
        function() {
          noty({
            text: 'Error getting transaction detail!',
            type: 'error',
            timeout: '5000'
          });
        }
      );
    },
  }
});
