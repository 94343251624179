import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'declined_notification_per_item_fee',
    'id',
    'require_full_payment',
    'send_declined_notifications',
    'title',
  ],
  columns: Ember.computed(function() {
    var self = this;
    return [{
      'name': 'id',
      'title': 'Id',
      'type': 'string',
      'sortable': true,
      'action': function(productrecurring) {
        self.transitionToRoute('locations.viewproductrecurring', productrecurring.get('location_id'), productrecurring.get('id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-productrecurrings-get'],
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'title',
      'title': 'Title',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'send_declined_notifications',
      'title': 'Send Declined Notifications',
      'type': 'check',
      'align': 'text-center',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny',
        multi: false
      }
    }, {
      'name': 'declined_notification_per_item_fee',
      'title': 'Declined Notification Fee (per item)',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'require_full_payment',
      'title': 'Require Full Payment',
      'type': 'check',
      'align': 'text-center',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny',
        multi: false
      }
    }, {
      'name': 'created_ts',
      'title': 'Created',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }];
  }),
  actions: {}
});
