import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsHelpPageLocations: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('helppagelocation.errors.messages', function() {
    this.set('errorMessages', this.get('helppagelocation.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  userTypes: Ember.computed(function() {
    return this.get('applicationData.allUserTypes');
  }),

  // Observers
  changedUserTypeId: Ember.observer('helppagelocation.user_type_id', function() {
    var user_type_id = this.get('helppagelocation.user_type_id');
    var helppages = this.get('helppages');
    if(helppages && helppages.get('length')){
      var modifiedHelpPages = helppages.filter(function(helppage) {
        return helppage.get('user_type_id') === user_type_id;
      });
      this.set('modifiedHelpPages', modifiedHelpPages);
    }
  }),

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var helppagelocation = this.get('helppagelocation');
      var location = this.get('location');

      helppagelocation.save().then(function() {
        noty({
          text: 'Help page added successfully!'
        });
        self.set('isNew', false);
        self.set('isLoading', false);
        self.transitionToRoute('locations.helppages-index', location.get('id'));
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.helppages-index', location.get('id'));
    }
  }
});
