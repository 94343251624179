import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      })
    });
  },

  afterModel: function(models, transition) {
    var self = this;
    var store = self.get('store');
    var session = this.get('session');
    var getTerminals = function() {
      if (session.get('authenticated.resources.v2-terminals-get')) {
        return store.query('terminal', {
          page_size: '500',
          location_id: models.contact.get('location_id')
        }).then(function(data) {
          models.terminals = data;
        });
      } else {
        models.terminals = [];
      }
    };
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfos: store.query('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfos = data;
        }),
        terminals: getTerminals()
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('locationinfo', locationinfo);
    controller.set('locationinfos', models.locationinfos);
    controller.set('terminals', models.terminals);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Add Account Vault',
      route_name: 'contacts.accounts-add',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Account Vaults',
      link: 'contacts.accounts-index',
      record: models.contact.get('id')
    }, {
      title: 'Add Account Vault',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
