import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  event_date: DS.attr('sqldate'),
  credit_amount: DS.attr('string'),
  credit_count: DS.attr('string'),
  debit_amount: DS.attr('string'),
  debit_count: DS.attr('string'),
  deposit_amount: DS.attr('string'),
  location_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  refund_amount: DS.attr('string'),
  refund_count: DS.attr('string'),
  rejected_amount: DS.attr('string'),
  rejected_count: DS.attr('string'),
  total_count: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
});
