import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      authroleusers: store.query('authroleuser', {
        user_id: params.user_id,
        page_size: 500
      }),
      // The user request needs to stay towards the bottom otherwise we get inconsistent
      // results when running checks for isDomainMismatch
      user: store.queryRecord('user', {
        id: params.user_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      })
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    if(models.user) {
      return Ember.RSVP.hash({
        primaryLocation: store.findRecord('location', models.user.get('primary_location_id')).then(function(data) {
          models.primaryLocation = data;
        })
      });
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    var location = models.location;
    controller.set('user', user);
    controller.set('location', location);
    controller.set('primaryLocation', models.primaryLocation);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles

    var pagerParams = {
      pageRoute: 'locations.users-locations',
      pageModelId: location.get('id'),
      pageModelIdPlus: user.get('id'),
      modelName: 'locationuser'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      user_id: user.get('id'),
      expand: 'location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● ' + user.get('username') + ' ● Locations',
      route_name: 'locations.users-locations',
      route_id: location.get('id'),
      route_id_plus: user.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Users',
      link: 'locations.users-index',
      record: location.get('id')
    }, {
      title: user.get('username'),
      link: 'locations.users-view',
      record: location.get('id'),
      record_plus: user.get('id'),
    }, {
      title: 'Locations',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
