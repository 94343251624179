import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_holder_name: DS.attr('string'),
  account_number: DS.attr('string'),
  account_type: DS.attr('lcstring'),
  account_vault_api_id: DS.attr('string'),
  ach_sec_code: DS.attr('string'),
  active: DS.attr('boolnum'),
  billing_address: DS.attr('string'),
  billing_city: DS.attr('string'),
  billing_phone: DS.attr('string'),
  billing_state: DS.attr('string'),
  billing_zip: DS.attr('string'),
  card_type: DS.attr('string'),
  contact_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  dl_number: DS.attr('masked'),
  dl_state: DS.attr('string'),
  dob_year: DS.attr('masked'),
  exp_date: DS.attr('string'),
  exp_month: DS.attr('string'),
  exp_year: DS.attr('string'),
  expiring_in_months: DS.attr('string'),
  first_six: DS.attr('string'),
  has_recurring: DS.attr('boolean'),
  is_company: DS.attr('string'),
  last_four: DS.attr('string'),
  location_id: DS.attr('string'),
  payment_method: DS.attr('string'),
  previous_transaction_id: DS.attr('ts'),
  routing: DS.attr('string'),
  ssn4: DS.attr('masked'),
  terms_agree: DS.attr('boolnull'),
  title: DS.attr('string'),

  // Custom fields
  accountvault_c1: DS.attr('string'),
  accountvault_c2: DS.attr('string'),
  accountvault_c3: DS.attr('string'),

  // Computed Properties
  activeColor: Ember.computed('active', function() {
    var active = this.get('active');
    if (!active) {
      return 'panel-deleted';
    }
    return 'panel-info';
  }),
  expColor: Ember.computed('expiring_in_months', function() {
    var exp = this.get('expiring_in_months');
    if (exp > 3) {
      return 'exp-success';
    } else if (exp >= 0) {
      return 'exp-warning';
    } else {
      return 'exp-danger';
    }
  }),
  hasSigPad: Ember.computed(function() {
    return window.sessionStorage.sigpad;
  }),
  isACH: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'ach';
  }),
  isCC: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'cc';
  }),
  isCompany: Ember.computed('ach_sec_code', function() {
    return this.get('ach_sec_code') === 'CCD';
  }),
  needSignature: Ember.computed('signature', 'created_ts', 'hasSigPad', function() {
    if (!this.get('hasSigPad') || this.get('hasSigPad') === 'false') {
      return false;
    }
    var ts = this.get('created_ts');
    if (ts) {
      var transTs = moment(ts);
      var nowTs = moment();
      if (Math.abs(transTs.diff(nowTs, 'minutes')) > 15) {
        return false;
      }
    }
    var signature = this.get('signature.id');
    if (signature) {
      return false;
    }
    return true;
  }),
  select_title: Ember.computed('title', 'last_four', 'account_type', function() {
    var title = this.get('title');
    var account_type = this.get('account_type');
    return this.get('last_four') + (title ? (' - ' + this.get('title')) : '') + (account_type ? ' (' + account_type + ')' : '');
  }),

  // Defined model relationships
  account_vault_cau_logs: DS.hasMany('accountvaultcaulog'),
  account_vault_cau_product_transaction: DS.hasMany('accountvaultcauproducttransaction'),
  changelogs: DS.hasMany('changelog'),
  contact: DS.belongsTo('contact'),
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  signature: DS.belongsTo('signature'),
});
