import DS from "ember-data";
import Application from "./application";
import Ember from "ember";

export default Application.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),

  ach_allow_credit: DS.attr("boolean"),
  ach_allow_debit: DS.attr("boolean"),
  ach_allow_refund: DS.attr("boolean"),
  active: DS.attr("boolean"),
  auto_decline_cvv: DS.attr("boolean"),
  auto_decline_street: DS.attr("boolean"),
  auto_decline_zip: DS.attr("boolean"),
  card_type_amex: DS.attr("boolean"),
  card_type_diners: DS.attr("boolean"),
  card_type_disc: DS.attr("boolean"),
  card_type_jcb: DS.attr("boolean"),
  card_type_mc: DS.attr("boolean"),
  card_type_visa: DS.attr("boolean"),
  cau_account_number: DS.attr("maskedbyui"),
  cau_subscribe_type_id: DS.attr("string"),
  current_batch: DS.attr("string"),
  default_transaction_type: DS.attr("lcstring"),
  display_avs: DS.attr("boolean"),
  hosted_payment_page_allow: DS.attr("boolean"),
  hosted_payment_page_max_allowed: DS.attr("string"),
  industry_type: DS.attr("string"),
  level3_allow: DS.attr("boolean"),
  level3_default: DS.attr("array"),
  partner: DS.attr("string"),
  payfac_enable: DS.attr("boolean"),
  payment_method: DS.attr("string"),
  processor: DS.attr("string"),
  quick_invoice_allow: DS.attr("boolean"),
  receipt_add_account_above_signature: DS.attr("string"),
  receipt_add_recurring_above_signature: DS.attr("string"),
  receipt_footer: DS.attr("string"),
  receipt_header: DS.attr("string"),
  receipt_logo: DS.attr("string"),
  receipt_show_contact_name: DS.attr("boolean"),
  receipt_vt_above_signature: DS.attr("string"),
  tax_surcharge_config: DS.attr("string"),
  title: DS.attr("string"),
  tz: DS.attr("string"),
  vt_billing_phone: DS.attr("boolean"),
  vt_clerk_number: DS.attr("boolean"),
  vt_require_cvv: DS.attr("boolean"),
  vt_enable: DS.attr("boolean"),
  vt_enable_sales_tax: DS.attr("boolean"),
  vt_enable_tip: DS.attr("boolean"),
  vt_order_num: DS.attr("boolean"),
  vt_override_sales_tax_allowed: DS.attr("boolean"),
  vt_require_street: DS.attr("boolean"),
  vt_require_zip: DS.attr("boolean"),
  vt_street: DS.attr("boolean"),
  vt_zip: DS.attr("boolean"),

  // Computed properties
  hasTip: Ember.computed("vt_enable_tip", function () {
    return this.get("vt_enable_tip");
  }),
  isPaymentMethodACH: Ember.computed("payment_method", function () {
    return this.get("payment_method") === "ach";
  }),
  isPaymentMethodCC: Ember.computed("payment_method", function () {
    return this.get("payment_method") === "cc";
  }),
  isSubProcessorPaya: Ember.computed("processor", function () {
    return this.get("processor") === "zach";
  }),
  isSurchargeAfterTax: Ember.computed("tax_surcharge_config", function () {
    return this.get("tax_surcharge_config") === "3";
  }),
  isTaxAfterSurcharge: Ember.computed("tax_surcharge_config", function () {
    return this.get("tax_surcharge_config") === "2";
  }),
  logo_image: Ember.computed("receipt_logo", function () {
    return this.get("receipt_logo")
      ? this.get("receipt_logo") + "?" + moment().valueOf()
      : null;
  }),
  tax_surcharge_config_display: Ember.computed(
    "tax_surcharge_config",
    function () {
      var producttaxconfigs = this.get("applicationData.producttaxconfigs");
      var tax_surcharge_config = this.get("tax_surcharge_config");
      var producttaxconfig = producttaxconfigs
        .filter(function (confi) {
          return String(tax_surcharge_config) === confi.id;
        })
        .get("firstObject");
      return producttaxconfig ? producttaxconfig.title : "";
    }
  ),
  vt_automatically_calculate_tax: Ember.computed(
    "vt_override_sales_tax_allowed",
    function () {
      return this.get("vt_override_sales_tax_allowed");
    }
  ),

  // Defined model relationships
  changelogs: DS.hasMany("changelog"),
  created_user: DS.belongsTo("user"),
  surcharge: DS.belongsTo("surcharge"),
});
