import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  batch: DS.attr('string'),
  created_ts: DS.attr('ts'),
  location_id: DS.attr('string'),
  number_of_refunds: DS.attr('string'),
  number_of_sales: DS.attr('string'),
  number_of_voids: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  total_refund_amount: DS.attr('string'),
  total_sale_amount: DS.attr('string'),
  total_void_amount: DS.attr('string'),

  // Computed Properties
  total_net_amount: Ember.computed('total_sale_amount', 'total_refund_amount', function() {
    var sales = this.get('total_sale_amount') || 0;
    var refunds = this.get('total_refund_amount') || 0;
    return parseFloat(sales - refunds).toString();
  }),

  // Defined model relationships
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
  transaction_batch: DS.belongsTo('transactionbatch')
});
