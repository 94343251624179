import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: session.get('authenticated.user.contact_id'),
          page_size: '500'
        });
      } else {
        return [];
      }
    };
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
      accounts: getAccounts(),
      quickinvoice: store.queryRecord('quickinvoice', {
        id: params.quickinvoice_id,
        expand: 'cc_product_transaction,ach_product_transaction,files',
        active: '0,1'
      }),
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('quickinvoice', models.quickinvoice);
    controller.set('locationinfo', location);
    controller.set('locationinfos', models.locationinfos);
    controller.set('accounts', models.accounts);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Invoice',
      route_name: 'contactuser.quickinvoices-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.quickinvoice.get('id')
    });
    controller.set('pageTitle', 'View Invoice');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Invoices',
      link: 'contactuser.quickinvoices-index'
    }, {
      title: 'View Invoice',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
      this.controller.set('refreshTransactions', new moment().unix());
    },
    willTransition: function(transition) {
      var model = this.controller.get('quickinvoice');
      if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
