import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/salestax/view', {
      controller: 'admin/salestax/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      salestax: store.createRecord('salestax', {
        country: 'USA',
        location_id: null
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('salestax', models.salestax);
    controller.set('isEditing', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-salestaxes-delete'),
      canPost: this.get('session.authenticated.resources.v2-salestaxes-post'),
      canPut: this.get('session.authenticated.resources.v2-salestaxes-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Sales Tax',
      route_name: 'admin.salestax.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Sales Tax');
    controller.set('pageSubtitle', null);
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Sales Tax',
      link: 'admin.salestax.index'
    }, {
      title: 'Add Sales Tax',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
