import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsNotes: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('note.errors.messages', function() {
    this.set('errorMessages', this.get('note.errors.messages'));
  }),

  // Set the params for pagination
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'note_search'
  ],

  // Set the columns for the grid
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var cols = [{
      'name': 'created_ts',
      'title': 'Created Date',
      'type': 'shortdate',
      'sortable': true
    }, {
      'name': 'created_user.username',
      'title': 'Created By',
      'type': 'string',
      'sortable': false,
      'action': function(transaction) {
        if(session.get('authenticated.isSupport')) {
          self.transitionToRoute('admin.users.view', transaction.get('created_user.id'));
        } else {
          self.transitionToRoute('locations.users-view', transaction.get('location.id'), transaction.get('created_user.id'));
        }
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-users-get' : ['v2-locations-get', 'v2-users-get'],
    }, {
      'name': 'visibility_group.title',
      'title': 'Visibility Group',
      'type': 'string',
      'sortable': false,
      'action': function(note) {
        self.transitionToRoute('locations.editvisibilitygroup', note.get('visibility_group.location_id'), note.get('visibility_group_id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-visibilitygroups-get'],
    }, {
      'name': 'note',
      'title': 'Note',
      'type': 'string',
      'sortable': true,
      'classNames': 'wrapcol'
    }];
    if(session.get('authenticated.resources.v2-notes-delete')) {
      cols.pushObject({
        'name': 'delete',
        'title': '',
        'type': 'delete',
        'action': function(note) {
          self.send('delete', note);
        },
        'sortable': false,
        'isActionColumn': true
      });
    }
    if(session.get('authenticated.resources.v2-notes-put')) {
      cols.pushObject({
        'name': 'edit',
        'title': '',
        'type': 'edit',
        'action': function(note) {
          self.send('edit', note);
        },
        'sortable': false,
        'isActionColumn': true
      });
    }
    return cols;
  }),

  // Observers
  locationChanged: Ember.observer('note.location_id', function() {
    var location_id = this.get('note.location_id');
    if (location_id) {
      this.set('note.visibility_group_id', null);
      this.set('visibility_groups', this.get('store').query('visibilitygroup', {
        location_id: location_id,
        page_size: '100'
      }));
    } else {
      this.set('visibility_groups', []);
    }
  }),

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var note = this.get('note');

      note.save().then(function() {
        noty({
          text: 'Note saved successfully!'
        });
        self.set('isLoading', false);
        self.send('refreshData');
        self.init();
        self.send('gridNeedsRefresh', self);
      }, function() {
        self.set('isLoading', false);
      });
    },
    filterNotes: function() {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      this.set('note_search', this.get('search'));
    },
    filterReset: function() {
      this.set('search', '');
      this.set('note_search', '');
    },
    delete: function(note) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Note?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        note.destroyRecord().then(function() {
          noty({
            text: 'Note deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          note.rollbackAttributes();
          self.send('refreshData');
          noty({
            text: 'Error deleting Note!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function(note) {
      this.set('note', note);
    },
    cancel: function() {
      this.set('note', null);
      var location = this.get('location');
      var newNote = this.get('store').createRecord('note', {
        resource_id: location.get('id'),
        resource: 'Location'
      });
      this.set('note', newNote);
    }
  }
});
