import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  container_key: DS.attr('string'),
  container_secret: DS.attr('string'),
  created_user_id: DS.attr('string'),
  created_ts: DS.attr('string'),
  service: DS.attr('string'),
  title: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user')
});
