import Ember from "ember";
import { pluralize } from "ember-inflector";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  pageCount: Ember.computed("pagerParams.pageCount", function () {
    return this.get("pagerParams.pageCount");
  }),
  totalCount: Ember.computed("pagerParams.totalCount", function () {
    return this.get("pagerParams.totalCount");
  }),
  page: Ember.computed("pagerParams.page", function () {
    return this.get("pagerParams.page");
  }),

  exportFormatTitle: Ember.computed(
    "export_format",
    "exportFormats",
    function () {
      var format = this.get("export_format");
      var formats = this.get("exportFormats");
      var selectedFormat = {};
      formats.forEach(function (f) {
        Ember.set(f, "isActive", f.id === format ? true : false);
        if (f.id === format) {
          selectedFormat = f;
        }
      });
      this.set("exportFormats", formats);
      return selectedFormat.title;
    }
  ),

  exportUrl: Ember.computed(
    "pagerParams",
    "gridParams.updateExportUrl",
    "export_version",
    "export_format",
    function () {
      var session = this.get("session");
      var token = session.get("authenticated.token");
      if (!token) {
        return "";
      }
      var fieldData = this.get("fieldData");
      var pagerParams = this.get("pagerParams");
      var gridParams = this.get("gridParams");

      if (!gridParams || !pagerParams) {
        return;
      }

      var params = [];
      if (this.get("export_version")) {
        params.push("export=" + this.get("export_version"));
      }
      if (this.get("gridParams.sort")) {
        params.push("sort=" + this.get("gridParams.sort"));
      }
      if (this.get("gridParams.relationship")) {
        params.push("relationship=" + this.get("gridParams.relationship"));
      }
      if (fieldData) {
        params.push("page_size=50000");

        var columnData = fieldData.reduce(
          function (data, field) {
            if (!field.isActionColumn) {
              if (
                field.name &&
                (field.name.indexOf(".") !== -1 || field.name === "tags")
              ) {
                data.expands.push(field.name);
              }
              data.fields.push(field.name);
              if (field.filter && field.filter.field) {
                if (!data.filters) {
                  data.filters = [];
                }
                data.filters.push(field.filter.field);
              }
            }
            return data;
          },
          {
            fields: [],
            expands: [],
          }
        );
        if (!columnData.filters) {
          columnData.filters = [];
        }
        Object.keys(gridParams).forEach(function (key) {
          if (key.slice(-5) === "_from" || key.slice(-3) === "_to") {
            if (key.indexOf("created_ts") >= 0) {
              var tsValue = moment(gridParams[key], "MM/DD/YYYY HH:mm:ss");
              params.push(
                key + "=" + (tsValue.isValid() ? moment(tsValue).unix() : "")
              );
            } else {
              var newValue = moment(gridParams[key], "MM/DD/YYYY");
              params.push(
                key +
                  "=" +
                  (newValue.isValid() ? newValue.format("YYYY-MM-DD") : "")
              );
            }
          } else {
            if (
              columnData.fields.indexOf(key) !== -1 ||
              columnData.filters.indexOf(key) !== -1
            ) {
              if (gridParams[key]) {
                params.push(key + "=" + gridParams[key]);
              }
            }
          }
        });

        params.push("fields=" + columnData.fields.join(","));
        params.push("expand=" + columnData.expands.join(","));
      } else {
        for (var key in gridParams) {
          if (gridParams.hasOwnProperty(key)) {
            if (key !== "page") {
              if (key === "page_size") {
                params.push(key + "=" + 50000);
              } else {
                if (key.slice(-5) === "_from" || key.slice(-3) === "_to") {
                  var newValue = moment(gridParams[key], "MM/DD/YYYY");
                  params.push(
                    key +
                      "=" +
                      (newValue.isValid() ? newValue.format("YYYY-MM-DD") : "")
                  );
                } else {
                  params.push(key + "=" + (gridParams[key] || ""));
                }
              }
            }
          }
        }
      }

      params.push("_format=" + this.get("export_format"));
      params.push("access-token=" + token);
      params.push("developer-id=" + window.UiENV.APP.DEVELOPER_ID);
      let model = pagerParams.modelName;
      let fullUrl = window.UiENV.APP.FULL_URL;
      if (model.includes('admin_')) {
        model = pagerParams.modelName.split('_')[1];
        fullUrl = `${window.UiENV.APP.FULL_URL}admin`;
      }

      var newUrl =
        fullUrl +
        "/" +
        pluralize(model) +
        "?" +
        params.join("&");
      return newUrl;
    }
  ),
  firstPage: Ember.computed("page", "pagerParams", function () {
    var current = this.get("page");
    if (current === 1) {
      return {
        activeClass: "disabled",
      };
    } else {
      return {
        activeClass: "",
      };
    }
  }),
  lastPage: Ember.computed("page", "pagerParams", function () {
    var page = this.get("page");
    var lastPage = this.get("pagerParams.pageCount");
    if (page === lastPage) {
      return {
        activeClass: "disabled",
      };
    } else {
      return {
        activeClass: "",
      };
    }
  }),
  pages: Ember.computed("page", "pageCount", "totalCount", function () {
    var pageCount = this.get("pagerParams.pageCount");
    var page = this.get("page");
    var currentPath = this.get("pagerParams.currentPath");
    var currentUrl;
    var activeClass;
    var inactiveClass;
    var pages = [];
    var index = 0;
    var endCount = pageCount;
    var x = 0;

    if (pageCount > 7) {
      if (page > 2) {
        var beginning = parseInt(page) - 3;
        if (pageCount - page < 3) {
          beginning -= 2 - (pageCount - page);
        }
        x = beginning;
      }
      if (parseInt(pageCount) - 2 > page) {
        var ending = parseInt(page) + 2;
        if (page < 3) {
          ending += 3 - page;
        }
        endCount = ending;
      }
    }

    for (; x < endCount; x++) {
      if (page === x + 1) {
        activeClass = "active";
        inactiveClass = "disabled";
        currentUrl = "";
      } else {
        activeClass = "";
        inactiveClass = "";
        currentUrl = currentPath + "?pageNumber=" + (parseInt(x) + 1);
      }
      pages[index++] = {
        activeClass: activeClass,
        inactiveClass: inactiveClass,
        pageNumber: parseInt(x) + 1,
        pageHref: currentUrl,
        pageLinkId: "page-link-page-number-" + index,
      };
    }
    return pages;
  }),
  isMultiPayError: Ember.computed("gridData", function () {
    const columns = this.get("gridData");
    return columns.reduce((previous, next) => previous || next.error, null);
  }),
  quickinvoice: Ember.computed("gridData", function () {
    var self = this;
    const quickInvoicesFromGrid = self.parentView
      .get("data")
      .map((dataObj) => {
        return dataObj[0].action_data;
      })
      .filter((qi) => qi.select);
    quickInvoicesFromGrid[0]._selectedQuickInvoices = quickInvoicesFromGrid; // hacky way to do it but it doesnt break current flow
    quickInvoicesFromGrid[0]._totalPaymentAmount =
      self.get("totalPaymentAmount");
    return quickInvoicesFromGrid[0];
  }),

  actions: {
    payQuickInvoice: function (quickinvoice) {
      // setTimeout to let the grid refresh before calling modal
      setTimeout(() => {
        if (this.isMultiPayError) return;

        var payquickinvoiceController = this.parentView.get(
          "payquickinvoiceController"
        );
        if (quickinvoice.get("cantPay")) {
          return;
        }
        quickinvoice._pay_multiple_invoices = true;
        payquickinvoiceController.set("quickinvoice", null);
        payquickinvoiceController.set("locations", null);
        payquickinvoiceController.set("accounts", null);
        payquickinvoiceController.set("contact", null);
        payquickinvoiceController.set("products", this.get("products"));
        payquickinvoiceController.set("quickinvoice", quickinvoice);
        payquickinvoiceController.set(
          "locations",
          this.parentView.get("locations")
        );
        payquickinvoiceController.set(
          "accounts",
          this.parentView.get("parent2").get("accounts")
        );
        payquickinvoiceController.set(
          "contact",
          this.parentView.get("parent2").get("contact")
        );

        payquickinvoiceController.set(
          "totalPaymentAmount",
          this.get("totalPaymentAmount")
        );
        // Close any pre-existing modals
        this.parentView.get("parent2").send("closeModal");

        this.parentView
          .get("parent2")
          .send("openModal", "components/modals/payquickinvoice");
      }, 1000);
    },
    changeExportFormat: function (formatID) {
      this.set("export_format", formatID);
    },
  },
});
