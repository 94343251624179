import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  /**
   Tag of input box
   */
  id: null,

  /**
   Two-way bindings to element's attributes.
   */
  attributeBindings: ['type', 'placeholder', 'date', 'value', 'disabled', 'minDate', 'maxDate'],

  /**
   Input type
   */
  type: 'text',

  /**
   Input disabled true/false
   */
  disabled: false,

  /**
   Placeholder
   */
  placeholder: null,

  /**
   Pickaday object
   */
  picker: null,

  /**
   Moment format for input field. String format of date.
   */
  format: 'L',

  /**
   The current date. Default is today's date.
   */
  date: new Date(),

  /**
   String form of the date.
   */
  value: '',

  /**
   Boolean used for setting utc mode
   */
  utc: false,

  /**
   Date variable used for minimum date
   */
  minDate: null,

  /**
   Date variable used for minimum date
   */
  maxDate: null,

  /**
   Options object for passing other configuration parameters
   */
  options: {},

  /**
   Date field is updated. Triggers sync with Pikaday picker.
   */
  dateDidChange: Ember.observer('date', function() {
    var self = this;
    var date;
    // Get date & format
    if (self.get('utc')) { // If UTC mode enabled use moment's parser
      date = moment.utc(self.get('date'));
    } else {
      date = moment(self.get('date'));
    }
    var format = self.get('format');
    // Validate date
    if (date.isValid()) {
      // Set string value form of date
      var newValue = date.format(format);

      // Check if value has changed
      // if (!Ember.isEqual(newValue, self.get('value'))) {
      // Value is different
      self.set('value', newValue);
      // Set the new current date on the picker
      //self.get('picker').setDate(date);
      // }
    }
  }),

  /**
   The value of the textbox has changed. syncs with Pikaday
   */
  valueDidChange: Ember.observer('value', function() {
    var date = moment(this.get('value'), this.get('format'));
    if (date.isValid()) {
      this.set('date', date.toDate());
      this.get('picker').setDate(date.toDate());
      // } else if (this.get('value').trim() === '') {
    } else {
      this.set('date', null);
    }
    // else: Value is invalid. Wait for it to validate.
  }),

  /**
   The value of the minimum date has changed. syncs with Pikaday
   */
  minDateDidChange: Ember.observer('minDate', function() {
    var date = moment(this.get('minDate'), this.get('format'));
    if (date.isValid()) {
      this.get('picker').setMinDate(date);

      if (this.get('date') && this.get('date') < date) {
        this.set('date', date);
      }
      var m = date.format('M');
      var y = date.format('YYYY');
      if(!this.get('date')) {
        this.get('picker').gotoMonth(m - 1);
        this.get('picker').gotoYear(y);
      }
    }
  }),

  /**
   The value of the minimum date has changed. syncs with Pikaday
   */
  maxDateDidChange: Ember.observer('maxDate', function() {
    var date = moment(this.get('maxDate'), this.get('format'));
    if (date.isValid()) {
      this.get('picker').setMaxDate(date);

      if (this.get('date') > date) {
        this.set('date', date);
      }
    }
  }),

  /**
   Inserts the input element into the DOM
   */
   init: function() {
     this._super(...arguments);
     this.set('tagName', 'input');
   },
  didInsertElement: function() {
    var self = this;
    // Setup bindings to input field
    var $el = self.$();
    $el.keyup(function() {
      var val = $el.val();
      self.set('value', val);
    });
    $el.id = self.get('id');
    var format = self.get('format');

    // Merge options
    var options = jQuery.extend({}, this.get('options'), {
      field: $el[0],
      format: format,
      onSelect: function(date) {
        self.set('date', date);
      }
    });

    // Init Pikaday
    var picker = new Pikaday(options);

    // Remember the picker
    self.set('picker', picker);

    if (this.get('minDate')) {
      self.minDateDidChange();
    }
    if (this.get('maxDate')) {
      self.maxDateDidChange();
    }

  },

  /**
   Removes the input element from the DOM
   */
  willDestroyElement: function() {
    var picker = this.get('picker');
    if (picker) {
      picker.destroy();
    }
    this.set('picker', null);
  }
});
