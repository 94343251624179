import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  page: 1,
  page_size: '15',
  location_created_ts: 'last month',
  isRefreshing: true,
  sort: '-location_created_ts',
  location_created_user_id: null,
  location_developer_company_id: null,
  parent_location_id: null,
  product_transaction_created_user_id: null,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'developer_company',
    'developer_company_name',
    'location_created_ts',
    'location_created_ts_from',
    'location_created_ts_to',
    'location_created_user_id',
    'parent_location_id',
    'parent_location_include_children',
    'portfolio_id',
    'portfolio_title',
    'product_transaction_created_ts',
    'product_transaction_created_ts_from',
    'product_transaction_created_ts_to',
    'product_transaction_created_user_id',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var columns = {
      developer_company: {
          'name': 'developer_company',
          'title': 'Developer Company',
          'type': 'string',
          'sortable': true,
          'filter': {
            type: 'text'
          }
        },
        locations: {
          'name': 'locations',
          'title': 'Location',
          'type': 'number',
          'sortable': true,
        },
        cc_products: {
          'name': 'cc_products',
          'title': 'CC Deposit Account',
          'type': 'string',
          'sortable': true,
        },
        ach_products: {
          'name': 'ach_products',
          'title': 'ACH Deposit Account',
          'type': 'number',
          'sortable': true
        }
    };
    var listOfCols = ['developer_company', 'locations', 'cc_products', 'ach_products'];
    var listOfMobileCols = ['developer_company', 'locations', 'cc_products', 'ach_products'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    updateReport: function(data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof data) !== 'object') {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function(item) {
        props[item.name.replace('.', '-')] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
    },
    updateParentLocation: function(data) {
      var self = this;
      var parents = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    updateLocationUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('locationusers', result);
      });
    },
    updateProductTransactionUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('producttransactionusers', result);
      });
    },
    updatePortfolio: function(data) {
      var self = this;
      var portfolios = this.get('store').query('portfolio', {
        title: data,
        sort: 'title',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      portfolios.then(function(result) {
        self.set('portfoliolist', result);
      });
    },
    updateDeveloperCompany: function(data) {
      var self = this;
      var developercompanies = this.get('store').query('developercompany', {
        title: data,
        sort: 'title',
        page_size: '10',
      });
      developercompanies.then(function(result) {
        self.set('developercompanies', result);
      });
    }
  }
});
