import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'admin.reports.portfoliodetailsonboardingreports',
      pageModelId: null,
      modelName: 'portfoliodetailsonboardingreport'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {};
    controller.set('gridParams', gridParams);

    controller.set('location_created_user_id', null);
    controller.set('parent_location_id', null);
    controller.set('portfolio_id', null);
    controller.set('product_transaction_created_user_id', null);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Portfolio Details Onboarding',
      route_name: 'admin.reports.portfoliodetailsonboardingreports',
      route_id: null
    });
    controller.set('pageTitle', 'Portfolio Details Onboarding');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Portfolio Details Onboarding',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      var controller = this.get('controller');
      var params = controller.get('queryParams');
      params.forEach(function(param) {
        controller.set(param, '');
      });

      // Set default parameters here
      var session = this.get('session');
      var page_size = session.get('authenticated.ui_prefs.page_size') || '15';
      var export_format = session.get('authenticated.ui_prefs.report_export_type') || 'csv';

      controller.set('page_size', page_size);
      controller.set('export_format', export_format);
      controller.set('location_created_ts', 'last month');
      controller.set('product_transaction_created_ts', '');
      controller.set('sort', '-location_created_ts');
      controller.set('page', 1);

      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
