import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      user: store.queryRecord('admin_user', {
        id: params.changepassword_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      }),
      authroleusers: store.query('authroleuser', {
        user_id: params.changepassword_id,
        page_size: 500
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    controller.set('user', user);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: user.get('username') + ' ● Change Password',
      route_name: 'admin.users.changepassword',
      route_id: user.get('id')
    });
    controller.set('pageTitle', user.get('username'));
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Users',
      link: 'admin.users.index'
    }, {
      title: user.get('username'),
      link: 'admin.users.view',
      record: user.get('id')
    }, {
      title: 'Change Password',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      var model = this.currentModel.user;
      model.set('keyUrl', null);
      model.set('password', null);
      model.set('confirmpassword', null);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
