import Ember from 'ember';
import { assign } from '@ember/polyfills';

export default Ember.Component.extend({
  tagName: 'div',
  classNames: ['mobile-grid-row', 'text-right'],

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  data: Ember.computed('headers.[]', 'row.[]', 'isMobileSize', function() {
    var data = [];
    var headers = this.get('headers');
    var row = this.get('row');
    headers.map(function(header, headerIndex) {
      row.map(function(col, rowIndex) {
        if(headerIndex === rowIndex) {
          if(col.component) {
            var cell = assign({
              action_data: row,
              showAction: true,
              isActionColumn: header.isActionColumn
            }, col);
            data.push(cell);
          } else {
            data.push({
              title: header.title,
              value: col.item,
              hasPriv: col.hasPriv,
              action_name: col.action_name,
              action_data: col.action_data,
              showAction: col.showAction,
              actionDisabled: col.actionDisabled,
              actionId: col.actionId,
              isActionColumn: header.isActionColumn,
              actionClass: col.actionClass
            });
          }
        }
      });
    });
    return data;
  }),

  actions: {}
});
