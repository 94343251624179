import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminTermsConditions: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'id',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'id',
    'veribage'
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Term Condition ID',
        'type': 'string',
        'sortable': true,
        'action': function(termscondition) {
          self.send('view', termscondition);
        },
        'actionId': 'termscondition_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      verbiage: {
        'name': 'verbiage',
        'title': 'Verbiage',
        'type': 'string',
        'sortable': true,
        'classNames': 'wrapcol'
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(termscondition) {
          self.send('view', termscondition);
        },
        'actionId': 'termscondition_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(termscondition) {
          self.send('delete', termscondition);
        },
        'actionId': 'termscondition_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'verbiage', 'view', 'delete'];
    var listOfMobileCols = ['id', 'verbiage', 'view', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2admin-termsconditions-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2admin-termsconditions-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(termscondition) {
      var self = this;
      pretty_confirm('Are you sure you want to delete these Terms and Conditions?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        termscondition.deleteRecord();
        termscondition.save().then(function() {
          noty({
            text: 'Terms and Conditions deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          termscondition.rollbackAttributes();
          noty({
            text: 'Error deleting Terms and Conditions!',
            type: 'error'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    view: function(termsconditions) {
      this.transitionToRoute('admin.termsconditions.view', termsconditions.get('id'));
    },
  }
});
