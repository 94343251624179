import Ember from 'ember';
import {assign} from '@ember/polyfills';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('recurring.errors.messages', 'needsUpdate', function() {
    this.set('errorMessages', this.get('recurring.errors.messages'));
  }),

  // Events
  didInsertElement: function() {
    var self = this;
    if (this.get('isAdding') && !this.get('recurringNotEnabled')) {
      this.set('isEditing', true);
      var contact = this.get('contact');
      var force_account_id = this.get('force_account.id');
      var location_id = '';
      var locations = this.get('locationsWithRecurring');
      var userPrimaryLocationId = this.get('session.authenticated.primary_location_id');
      locations.forEach(function(loc) {
        if (force_account_id && loc.get('id') === self.get('force_account.location_id')) {
          location_id = loc.get('id');
        } else if (!force_account_id && loc.get('id') === userPrimaryLocationId) {
          location_id = loc.get('id');
        }
      });
      if (!location_id) {
        location_id = locations.get('firstObject').get('id');
      }
      var location = locations.filter(function(loc) {
        return location_id === loc.get('id');
      }).get('firstObject');
      this.set('location', location);
      var accounts = this.get('accounts');
      var account_vault_id = force_account_id ? force_account_id : null;
      var defaultRecurring = {
        interval: '1',
        interval_type: 'm',
        notification_days: location.get('recurring_notification_days_default'),
      };
      switch (this.get('route')) {
        case 'recurrings.add':
        case 'locations.recurrings-add':
          defaultRecurring = assign(defaultRecurring, {
            contact_id: null,
            location_id: location_id,
          });
          self.set('processMethodExisting', true);
          break;
        case 'contacts.recurrings-add':
          defaultRecurring = assign(defaultRecurring, {
            contact_id: contact.get('id')
          });
        case 'contactuser.recurrings-add':
          defaultRecurring = assign(defaultRecurring, {
            contact_id: contact.get('id'),
            location_id: contact.get('location_id'),
            account_vault_id: account_vault_id
          });
          self.set('processMethodExisting', account_vault_id ? true : (accounts.get('length') > 0 ? true : false));
          break;
      }
      var recurring = this.get('store').createRecord('recurring', defaultRecurring);
      this.set('recurring', recurring);
      this.set('selectedTags', '');
      this.set('oneTime', '0');
      this.set('endType', '1');
      this.set('recurring.start_date', null);
      this.set('newaccount', null);
      if (force_account_id) {
        self.set('recurring.account_vault_id', force_account_id);
        self.set('recurring.account_vault', self.get('force_account'));
      }
      if(this.get('showPaymentMethodOptions')) {
        var ccAccounts = accounts.filter(function(a){
          return a.get('payment_method') === 'cc' && a.get('expiring_in_months') >= 0;
        });
        var achAccounts = accounts.filter(function(a){
          return a.get('payment_method') === 'ach';
        });
        var forceAccountPaymentMethod = self.get('force_account.payment_method');
        this.set('paymentMethodCreditCard', forceAccountPaymentMethod ? forceAccountPaymentMethod === 'cc' : (ccAccounts.get('length') || !achAccounts.get('length')));
      }

      Ember.run.later(function() {
        self.set('dateTomorrow', moment().add(1, 'days').format('L')); // so recurring start date cannot be set in past
        var accountsAvailable = self.get('available_accounts');
        if (self.get('showStep1') && !force_account_id) {
          self.set('step2Collapsed', true);
          self.set('step3Collapsed', true);
          $('#collapse_recurring_location_producttransaction').collapse('show');
        } else if ((self.get('showStep2') || !self.get('recurring.account_vault_id') && !force_account_id)) {
          self.set('step1Collapsed', true);
          if (accountsAvailable.get('length') === 1 || force_account_id) {
            self.set('step2Collapsed', true);
            $('#collapse_recurring_details').collapse('show');
          } else {
            self.set('step3Collapsed', true);
            $('#collapse_recurring_accountvault').collapse('show');
          }
        } else {
          self.set('step1Collapsed', true);
          self.set('step2Collapsed', true);
          $('#collapse_recurring_details').collapse('show');
        }
        if(force_account_id && !self.get('isRouteContact') && !self.get('isRouteContactUser')) {
          self.send('searchAccountVaults', force_account_id);
        }
      });
    } else if (!this.get('isAdding') && !this.get('recurringNotEnabled')) {
      // on view
      this.set('selectedTags', this.get('recurring.tags_csv'));
      this.set('endDateMin', moment().format('L'));
      this.set('processMethodExisting', true);
      self.set('defer_count', null);
      self.set('skip_count', null);
      Ember.run.later(function() {
        self.send('searchAccountVaults', self.get('recurring.account_vault_id'));
        $('#collapse_recurring_details').collapse('show');
      });
    }
    this.set('accountvaultSearch', {});
    this.setLocation();
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-recurrings-delete'),
      canPost: this.get('session.authenticated.resources.v2-recurrings-post'),
      canPut: this.get('session.authenticated.resources.v2-recurrings-put') && this.get('recurring.active'),
    });

    self.set('step1Collapsed', true);
    self.set('step2Collapsed', true);
    self.set('step3Collapsed', true);
    $('#collapse_recurring_location_producttransaction').on('hidden.bs.collapse', function() {
      self.set('step1Collapsed', true);
    });
    $('#collapse_recurring_accountvault').on('hidden.bs.collapse', function() {
      self.set('step2Collapsed', true);
    });
    $('#collapse_recurring_details').on('hidden.bs.collapse', function() {
      self.set('step3Collapsed', true);
    });
    $('#collapse_recurring_location_producttransaction').on('shown.bs.collapse', function() {
      self.set('step1Collapsed', false);
      if (!self.get('step2Collapsed')) {
        $('#collapse_recurring_accountvault').collapse('hide');
      }
      if (!self.get('step3Collapsed')) {
        $('#collapse_recurring_details').collapse('hide');
      }
    });
    $('#collapse_recurring_accountvault').on('shown.bs.collapse', function() {
      self.set('step2Collapsed', false);
      if (!self.get('step1Collapsed')) {
        $('#collapse_recurring_location_producttransaction').collapse('hide');
      }
      if (!self.get('step3Collapsed')) {
        $('#collapse_recurring_details').collapse('hide');
      }
    });
    $('#collapse_recurring_details').on('shown.bs.collapse', function() {
      self.set('step3Collapsed', false);
      if (!self.get('step1Collapsed')) {
        $('#collapse_recurring_location_producttransaction').collapse('hide');
      }
      if (!self.get('step2Collapsed')) {
        $('#collapse_recurring_accountvault').collapse('hide');
      }
      $('#recurring_description').focus();
    });
  },
  willDestroyElement: function() {
    var recurring = this.get('recurring');
    if(recurring && (!recurring.get('isDestroyed') || !recurring.get('isDestroying'))) {
      if (recurring && (recurring.get('hasDirtyAttributes') || recurring.get('isNew')) && this.get('isAdding')) {
        recurring.unloadRecord();
      }
    }
    this.set('returnRoute', null);
    this.set('isTransitioning', false);
    $('#collapse_recurring_location_producttransaction').off('hidden.bs.collapse');
    $('#collapse_recurring_accountvault').off('hidden.bs.collapse');
    $('#collapse_recurring_details').off('hidden.bs.collapse');
    $('#collapse_recurring_location_producttransaction').off('shown.bs.collapse');
    $('#collapse_recurring_accountvault').off('shown.bs.collapse');
    $('#collapse_recurring_details').off('shown.bs.collapse');
  },

  // Computed Properties

  available_accounts: Ember.computed('accounts.[]', 'location.{hasProductCC,hasProductACH}', 'product.{id,payment_method}', function() {
    var hasCC = this.get('location.hasProductCC');
    var hasACH = this.get('location.hasProductACH');
    var accounts = this.get('accounts');
    var product = this.get('product');
    var recurringPaymentMethod = this.get('recurringPaymentMethod');
    if ((product || recurringPaymentMethod) && accounts && accounts.get('length')) {
      return accounts.filter(function(account) {
        var payment_method = account.get('payment_method');
        if ((payment_method === 'ach' && hasACH) || (payment_method === 'cc' && account.get('expiring_in_months') >= 0 && hasCC)) {
          if ((product ? product.get('payment_method') : recurringPaymentMethod) === payment_method) {
            return account;
          }
        }
      });
    } else {
      return [];
    }
  }),
  available_account_labels: Ember.computed('available_accounts.[]', function () {
    var available_accounts = this.get('available_accounts');
    if (available_accounts && available_accounts.length) {
      return available_accounts.map(function (account) {
          return account.get('select_title');
        });
    } else {
      return [];
    }
  }),
  available_accountTypes: Ember.computed('applicationData.accountTypes', 'recurring.product_transaction.payment_method', function() {
    var payment_method = this.get('recurring.product_transaction.payment_method');
    var accountTypes = this.get('applicationData.accountTypes');
    return accountTypes.filter(function(type){
      if(payment_method === 'ach') {
        return type.id === 'checking' || type.id === 'savings';
      } else {
        return type.id !== 'checking' && type.id !== 'savings';
      }
    });
  }),
  canChangeTags: Ember.computed('session.authenticated.user_type_id', 'session.authenticated.resources.v2-tags-get', function() {
    var user_type_id = this.get('session.authenticated.user_type_id');
    if (user_type_id < 200) {
      return false;
    }
    if (this.get('session.authenticated.resources.v2-tags-get')) {
      return true;
    }
    return false;
  }),
  canChangeTransactionAmount: Ember.computed('recurring.isInstallment', 'isAdding', 'isEditing', function() {
    return this.get('isAdding') || (!this.get('recurring.isInstallment') && this.get('isEditing'));
  }),
  canViewAccountVault: Ember.computed('isEditing', 'session.authenticated.resources.v2-accountvaults-get', function() {
    return !this.get('isEditing') && this.get('session.authenticated.resources.v2-accountvaults-get');
  }),
  hasAccountVaultPrivsBoth: Ember.computed('session.authenticated.resources.v2-accountvaults-get', 'session.authenticated.resources.v2-accountvaults-post', function() {
    return this.get('session.authenticated.resources.v2-accountvaults-get') && this.get('session.authenticated.resources.v2-accountvaults-post');
  }),
  hasAccountVaultPrivsAtLeastOne: Ember.computed('session.authenticated.resources.v2-accountvaults-get', 'session.authenticated.resources.v2-accountvaults-post', function() {
    return this.get('session.authenticated.resources.v2-accountvaults-get') || this.get('session.authenticated.resources.v2-accountvaults-post');
  }),
  isAdding: Ember.computed('route', function() {
    return this.get('route') === 'recurrings.add' || this.get('route') === 'contacts.recurrings-add' || this.get('route') === 'contactuser.recurrings-add' || this.get('route') === 'locations.recurrings-add';
  }),
  isEditable: Ember.computed('recurring.isInstallment', 'isEditing', function() {
    return this.get('isEditing') && !this.get('recurring.isInstallment');
  }),
  isEndTypeInstallment: Ember.computed('endType', function() {
    return this.get('endType') === '0';
  }),
  isRouteContact: Ember.computed('route', function() {
    return this.get('route') === 'contacts.recurrings-add' || this.get('route') === 'contacts.recurrings-view';
  }),
  isRouteContactUser: Ember.computed('route', function() {
    return this.get('route') === 'contactuser.recurrings-add' || this.get('route') === 'contactuser.recurrings-view';
  }),
  isRouteLocation: Ember.computed('route', function() {
    return this.get('route') === 'locations.recurrings-add' || this.get('route') === 'locations.recurrings-view';
  }),
  isRouteRecurrings: Ember.computed('route', function() {
    return this.get('route') === 'recurrings.add' || this.get('route') === 'recurrings.view';
  }),
  locationsWithRecurring: Ember.computed('locations.[]', function() {
    return this.get('locations').filter(function(location) {
      return location.get('hasProductRecurring');
    });
  }),
  newaccountRoute: Ember.computed('isRouteRecurrings', 'isRouteContact', 'isRouteContactUser', 'isRouteLocation', function() {
    if (this.get('isRouteRecurrings')) {
      return 'accounts.add';
    } else if (this.get('isRouteContact')) {
      return 'contacts.accounts-add';
    } else if (this.get('isRouteContactUser')) {
      return 'contactuser.accounts-add';
    } else if (this.get('isRouteLocation')) {
      return 'locations.accounts-add';
    } else {
      return '';
    }
  }),
  nextRunDateMax: Ember.computed('recurring.next_run_date_max', function() {
    var self = this;
    return moment(self.get('recurring.next_run_date_max'), 'MM-DD-YYYY').format('L');
  }),
  nextRunDateMin: Ember.computed('recurring.next_run_date_min', function() {
    var self = this;
    return moment(self.get('recurring.next_run_date_min'), 'MM-DD-YYYY').format('L');
  }),
  noAccountVaultProductOrPrivs: Ember.computed('location.hasProductAccountVault', 'processMethodExisting', 'session.authenticated.resources.v2-accountvaults-get', 'session.authenticated.resources.v2-accountvaults-post', function() {
    var canGET = this.get('session.authenticated.resources.v2-accountvaults-get');
    var canPOST = this.get('session.authenticated.resources.v2-accountvaults-post');
    var processMethodExisting = this.get('processMethodExisting');
    return this.get('recurring.isNew') && (!this.get('location.hasProductAccountVault') || (processMethodExisting && !canGET && !this.get('recurring.account_vault_id')) || (!processMethodExisting && !canPOST));
  }),
  noExistingAccounts: Ember.computed('available_accounts.[]', 'isRouteRecurrings', 'isRouteContact', 'isRouteContactUser', 'isRouteLocation', function() {
    if (this.get('isRouteContact') || this.get('isRouteContactUser')) {
      return this.get('available_accounts.length') < 1;
    } else {
      return false;
    }
  }),
  noProductsRecurringMessage: Ember.computed('recurringEnabledButNoProducts', 'isRouteRecurrings', 'isRouteContact', 'isRouteContactUser', 'isRouteLocation', function() {
    if (this.get('recurringEnabledButNoProducts')) {
      var message = 'No active Deposit Accounts at ';
      if (this.get('isRouteLocation')) {
        message += 'this location.';
      } else if (this.get('isRouteRecurrings')) {
        message += 'any of your locations.';
      } else if (this.get('isRouteContact')) {
        message += 'this contact\'s location.';
      } else if (this.get('isRouteContactUser')) {
        message += 'your location.<br> Please contact ' + this.get('location.name') + ' for alternatives.';
      }
      if (!this.get('isRouteContactUser')) {
        message += '<br> Please contact support if you need assistance activating Deposit Accounts.';
      }
      return message;
    } else {
      return;
    }
  }),
  noRecurringMessage: Ember.computed('recurringNotEnabled', 'isRouteRecurrings', 'isRouteContact', 'isRouteContactUser', 'isRouteLocation', function() {
    if (this.get('recurringNotEnabled')) {
      var message = 'Recurring Billing Service is not configured for ';
      if (this.get('isRouteLocation')) {
        message += 'this location.';
      } else if (this.get('isRouteRecurrings')) {
        message += 'any of your locations.';
      } else if (this.get('isRouteContact')) {
        message += 'this contact\'s location.';
      } else if (this.get('isRouteContactUser')) {
        message += 'your location.<br> Please contact ' + this.get('location.name') + ' for alternatives.';
      }
      if (!this.get('isRouteContactUser')) {
        message += '<br> Please contact support if you need assistance enabling Recurring Billing Service.';
      }
      return message;
    } else {
      return;
    }
  }),
  noRecurringTermsAgree: Ember.computed('recurring.terms_agree', 'route', 'processMethodExisting', 'product.{id,receipt_add_account_above_signature}', 'newaccount_terms_agree', function() {
    return this.get('route') === 'contactuser.recurrings-add' && (!this.get('recurring.terms_agree') || (!this.get('processMethodExisting') && this.get('product.receipt_add_account_above_signature') && !this.get('newaccount.terms_agree'))) ;
  }),
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  products: Ember.computed('location.id', 'location.product_transactions.[]', function() {
    var products = this.get('location.product_transactions');
    if (products && products.get('length')) {
      return products.filter(function(prod) {
        return prod.get('active') && prod.get('industry_type') !== 'lodging';
      });
    } else {
      return [];
    }
  }),
  productHasCAUSubscribeRecurring: Ember.computed('product.{id,cau_subscribe_type_id}', function() {
    return this.get('product.cau_subscribe_type_id') === '2';
  }),
  productHasSurchargeOnRecurring: Ember.computed('product.id', function() {
    var surcharge = this.get('product.surcharge');
    if (surcharge && surcharge.get('id')) {
      return surcharge.get('surcharge_on_recurring');
    } else {
      return false;
    }
  }),
  recurringContactFullName: Ember.computed('recurring.contact.id', 'recurring.account_vault.contact_id', function() {
    var fromAccountVault = this.get('recurring.account_vault.contact.id');
    var fromRecurring = this.get('recurring.contact.id');
    if (fromAccountVault) {
      return this.get('recurring.account_vault.contact.full_name');
    } else if (fromRecurring) {
      return this.get('recurring.contact.full_name');
    } else {
      return '';
    }
  }),
  recurringEnabledButNoProducts: Ember.computed('locationsWithRecurring.[]', 'products.[]', 'recurring.isNew', function() {
    return this.get('locationsWithRecurring.length') >= 1 && this.get('products.length') < 1 && this.get('recurring.isNew');
  }),
  recurringNotEnabled: Ember.computed('locationsWithRecurring.[]', function() {
    return this.get('locationsWithRecurring.length') < 1 && this.get('isAdding');
  }),
  recurringPaymentMethod: Ember.computed('recurring.account_vault_id', 'accounts.[]', function() {
    var accounts = this.get('accounts');
    var account_vault_id = this.get('recurring.account_vault_id');
    var payment_method = '';
    var newaccount_payment_method = this.get('newaccount.payment_method');
    var processMethodExisting = this.get('processMethodExisting');
    if (accounts && accounts.get('length') && processMethodExisting) {
      accounts.forEach(function(acc) {
        if (acc.get('id') === account_vault_id) {
          payment_method = acc.get('payment_method');
        }
      });
    } else if (this.get('recurring.account_vault.id') && processMethodExisting) {
      payment_method = this.get('recurring.account_vault.payment_method');
    } else if (newaccount_payment_method && !processMethodExisting) {
      payment_method = newaccount_payment_method;
    }
    return payment_method;
  }),
  recurringSurchargeAmount: Ember.computed('recurring.transaction_amount', 'product.surcharge', function() {
    var surcharge = this.get('product.surcharge');
    var recurring = this.get('recurring');
    if (!surcharge || !recurring) {
      return 0;
    }
    var surcharge_amount = 0;
    if (surcharge && surcharge.get('surcharge_on_recurring')) {
      var transaction_amount = parseFloat(recurring.get('transaction_amount')) || 0;
      var min_fee_amount = parseFloat(surcharge.get('min_fee_amount')) || 0;
      var max_fee_amount = parseFloat(surcharge.get('max_fee_amount')) || 0;
      var surcharge_fee = parseFloat(surcharge.get('surcharge_fee')) || 0;
      var surcharge_rate = parseFloat(surcharge.get('surcharge_rate')) || 0;
      surcharge_amount = (surcharge_rate / 100 * transaction_amount).round(2) + surcharge_fee;
      surcharge_amount = (surcharge_amount > max_fee_amount) ? max_fee_amount : (surcharge_amount < min_fee_amount) ? min_fee_amount : surcharge_amount;
    }
    return surcharge_amount;
  }),
  selectedAccountVaultIndex: Ember.computed('recurring.account_vault_id', 'available_accounts.[]', function() {
    var account_vault_id = this.get('recurring.account_vault_id');
    var available_accounts = this.get('available_accounts');
    if(account_vault_id && available_accounts) {
      var selectedIndex = null;
      available_accounts.forEach(function(acct, index){
        if(acct.get('id') === account_vault_id) {
          selectedIndex = index;
        }
      });
      return selectedIndex;
    }
  }),
  showAccounts: Ember.computed('isRouteContact', 'isRouteContactUser', 'accounts.[]', function() {
    return (this.get('isRouteContact') || this.get('isRouteContactUser')) && this.get('accounts.length') >= 1;
  }),
  showContacts: Ember.computed('route', 'recurring.location_id', function() {
    return this.get('recurring.location_id') && (this.get('route') === 'recurrings.add' || this.get('route') === 'recurrings.view' || this.get('route') === 'locations.recurrings-add' || this.get('route') === 'locations.recurrings-view');
  }),
  showLocations: Ember.computed('isRouteRecurrings', 'locationsWithRecurring.[]', 'session.authenticated.user.locations.[]', function() {
    return this.get('locationsWithRecurring.length') > 1 || (this.get('isRouteRecurrings') && this.get('session.authenticated.user.locations.length') > 1);
  }),
  showPaymentMethodOptions: Ember.computed('isRouteContactUser', 'location.{hasProductCC,hasProductACH,defaultProductCC}', function() {
    if(this.get('isRouteContactUser')) {
      return (this.get('location.hasProductCC') && this.get('location.defaultProductCC.industry_type') !== 'lodging') && this.get('location.hasProductACH');
    }
    return false;
  }),
  showProducts: Ember.computed('location.id', 'location.product_transactions.[]', 'isRouteContactUser', function() {
    var products = this.get('location.product_transactions');
    if (products && products.get('length') > 1) {
      products = products.filter(function(prod) {
        return prod.get('active') && prod.get('industry_type') !== 'lodging';
      });
      return products.get('length') > 1 && !this.get('isRouteContactUser');
    } else {
      return false;
    }
  }),
  showSignature: Ember.computed('recurring.product_transaction.receipt_add_recurring_above_signature', 'isAdding', 'session.authenticated.isLocation', function() {
    if (this.get('isAdding') || !this.get('session.authenticated.isLocation')) {
      return false;
    }
    return this.get('recurring.product_transaction.receipt_add_recurring_above_signature') ? true : false;
  }),

  showStep1: Ember.computed('showLocations', 'showProducts', 'isRouteContactUser', 'isRouteContact', 'isRouteLocation', 'isRouteRecurrings', function() {
    if (this.get('isRouteRecurrings')) {
      return this.get('showLocations') || this.get('showProducts');
    } else if (this.get('isRouteLocation') || this.get('isRouteContact')) {
      return this.get('showProducts');
    } else {
      return false;
    }
  }),
  showStep2: Ember.computed('isRouteContact', 'isRouteContactUser', 'showAccounts', function() {
    if (this.get('isRouteContact') || this.get('isRouteContactUser')) {
      return this.get('showAccounts');
    } else {
      return true;
    }
  }),

  // Observers

  changeAccountVault: Ember.observer('recurring.account_vault_id', 'isRouteContactUser', 'isRouteContact', function() {
    var recurring = this.get('recurring');
    var products = this.get('products');
    var product = this.get('product');
    var account_vault_id = recurring.get('account_vault_id');
    var accounts = this.get('accounts');

    if (this.get('isRouteContactUser')) {
      if (account_vault_id && product) {
        var account = accounts.filter(function(a) {
          return a.get('id') === account_vault_id;
        }).get('firstObject');
        if(account) {
          recurring.set('account_vault_id', account.get('id'));
          recurring.set('account_vault', account);
        }
        var payment_method = account.get('payment_method');
        var default_cc = this.get('location.default_cc');
        var default_ach = this.get('location.default_ach');
        if (payment_method && payment_method !== product.get('payment_method')) {
          products = products.filter(function(p) {
            return p.get('payment_method') === payment_method;
          });
          if (payment_method === 'cc') {
            var product_transaction_id_cc = default_cc ? default_cc : products.get('firstObject').get('id');
            product = products.filter(function(p) {
              return p.get('id') === product_transaction_id_cc;
            }).get('firstObject');
            this.set('product', product);
            this.set('recurring.product_transaction', product);
            recurring.set('product_transaction_id', product_transaction_id_cc);
          } else {
            var product_transaction_id_ach = default_ach ? default_ach : products.get('firstObject').get('id');
            product = products.filter(function(p) {
              return p.get('id') === product_transaction_id_ach;
            }).get('firstObject');
            this.set('product', product);
            this.set('recurring.product_transaction', product);
            recurring.set('product_transaction_id', product_transaction_id_ach);
          }
        }
      }
    } else if (this.get('isRouteContact') && account_vault_id && product) {
      var acct = accounts.filter(function(a) {
        return a.get('id') === account_vault_id;
      }).get('firstObject');
      if(acct) {
        recurring.set('account_vault_id', acct.get('id'));
        recurring.set('account_vault', acct);
      }
    }
  }),
  changeEndType: Ember.observer('endType', function() {
    var recurring = this.get('recurring');
    if (this.get('endType') === '1' && recurring.get('isNew')) {
      recurring.set('installment_total_count', null);
    }
    this.set('notNumOccurrences', this.get('endType') !== '0');
  }),
  changedLocationId: Ember.observer('recurring.{location_id,payment_method}', function() {
    this.setLocation();
  }),
  changeOneTime: Ember.observer('oneTime', function() {
    var recurring = this.get('recurring');
    var isOneTime = this.get('oneTime') === '1';
    this.set('isOneTime', isOneTime);
    if (isOneTime) {
      recurring.set('installment_total_count', 1);
      recurring.set('interval', 1);
    } else {
      this.set('endType', '1');
    }
  }),
  changeProduct: Ember.observer('recurring.product_transaction_id', function() {
    var product_transaction_id = this.get('recurring.product_transaction_id');
    this.setProductFromId(product_transaction_id);
  }),
  processMethodExistingObserver: Ember.observer('processMethodExisting', function() {
    var accounts = this.get('available_accounts');
    if(this.get('processMethodExisting') && accounts && accounts.get('length') && !this.get('recurring.account_vault_id')) {
      var account = accounts.get('firstObject');
      this.set('recurring.account_vault_id', account.get('id'));
      this.set('recurring.account_vault', account);
    }
    if(this.get('processMethodExisting')) {
      this.send('searchAccountVaultsClear');
    }
  }),
  setProducts: Ember.observer('recurring.location_id', 'location.id', 'products.[]', 'isRouteLocation', 'isRouteRecurrings', 'isRouteContact', 'isRouteContactUser', function() {
    var recurring = this.get('recurring');
    var accounts = this.get('accounts');
    var location = this.get('location');
    var isRouteLocation = this.get('isRouteLocation');
    var isRouteRecurrings = this.get('isRouteRecurrings');
    var isRouteContact = this.get('isRouteContact');
    var isRouteContactUser = this.get('isRouteContactUser');

    if (Ember.isEmpty(recurring) || Ember.isEmpty(location) || recurring.get('isDestroyed') || recurring.get('isDestroyed') === undefined || recurring.get('isDestroying') || recurring.get('isDestroying') === undefined) {
      return;
    }

    var products = this.get('products');
    var default_cc = location.get('defaultProductCC');
    var default_ach = location.get('defaultProductACH');
    var product_transaction_id = recurring.get('product_transaction_id');
    if (!products || products.get('length') < 1) {
      return;
    }
    var products_cc = products.filter(function(p) {
      return p.get('payment_method') === 'cc';
    });
    var products_ach = products.filter(function(p) {
      return p.get('payment_method') === 'ach';
    });
    var has_cc = products_cc && products_cc.get('length') > 0;
    var has_cc_default = default_cc ? true : false;
    var has_ach = products_ach && products_ach.get('length') > 0;
    var has_ach_default = default_ach ? true : false;
    if (!has_cc && !has_ach) {
      return;
    }
    var default_cc_is_available = false;
    if (has_cc && has_cc_default) {
      products_cc.map(function(p) {
        if (p.get('id') === default_cc.get('id')) {
          default_cc_is_available = true;
        }
      });
    }
    var default_ach_is_available = false;
    if (has_ach && has_ach_default) {
      products_ach.map(function(p) {
        if (p.get('id') === default_ach.get('id')) {
          default_ach_is_available = true;
        }
      });
    }

    var accountVaultPaymentMethod = recurring.get('account_vault.payment_method');
    var forceAccountPaymentMethod = this.get('force_account.payment_method');
    var product;
    if (isRouteLocation || isRouteRecurrings) {
      var tryCCProd = false;
      var tryACHProd = false;
      if (has_cc && default_cc_is_available) {
        tryCCProd = true;
      }
      if (has_ach && default_ach_is_available) {
        tryACHProd = true;
      }

      if(forceAccountPaymentMethod) {
        if(tryCCProd) {
          if(forceAccountPaymentMethod === 'cc') {
            if(has_cc && default_cc_is_available) {
              product = default_cc;
            } else {
              product = products.filter(function(prod){
                return prod.get('payment_method') === 'cc';
              }).get('firstObject');
            }
          } else {
            tryACHProd = true;
            tryCCProd = false;
          }
        }
        if(tryACHProd && !tryCCProd) {
          if(forceAccountPaymentMethod === 'ach') {
            if(has_ach && default_ach_is_available) {
              product = default_ach;
            } else {
              product = products.filter(function(prod){
                return prod.get('payment_method') === 'ach';
              }).get('firstObject');
            }
          }
        }
        if(!product) {
          product = products.filter(function(prod){
            return prod.get('payment_method') === forceAccountPaymentMethod;
          }).get('firstObject');
        }
      }
      if(!product) {
        if(product_transaction_id && (recurring.get('product_transaction.location_id') === location.get('id') || recurring.get('id'))) {
          product = products.filter(function(prod) {
            return prod.get('id') === product_transaction_id;
          }).get('firstObject');
        } else if (has_cc && default_cc_is_available && (accountVaultPaymentMethod === 'cc' || !accountVaultPaymentMethod)) {
          product = default_cc;
        } else if (has_ach && default_ach_is_available  && (accountVaultPaymentMethod === 'ach' || !accountVaultPaymentMethod)) {
          product = default_ach;
        } else {
          product = products.get('firstObject');
        }
      }
    } else if (isRouteContact || isRouteContactUser) {
      var account = null;
      var account_id = recurring.get('account_vault_id');
      // Find the currently selected account vault
      if (typeof this.get('processMethodExisting') === 'undefined') {
        return;
      }
      if (this.get('processMethodExisting')) {
        //Find the account obejct for the currently selected account vault
        account = accounts.filter(function(a) {
          return a.get('id') === account_id;
        }).get('firstObject');
      } else {
        account = this.get('newaccount');
      }
      if (!product_transaction_id && account) {
        if (has_cc && default_cc_is_available && account.get('isCC')) {
          product = default_cc;
        } else if (has_ach && default_ach_is_available && account.get('isACH')) {
          product = default_ach;
        } else {
          product = products.get('firstObject');
        }
      } else if (product_transaction_id || recurring.get('product_transaction.payment_method') !== recurring.get('account_vault.payment_method')) {
        product = products.filter(function(prod) {
          return prod.get('id') === product_transaction_id;
        }).get('firstObject');
        if (product && product.get('payment_method') === 'ach' && account && account.get('isCC')) {
          product = default_cc;
        } else if (product && product.get('payment_method') === 'cc' && account && account.get('isACH')) {
          product = default_ach;
        }
      } else {
        var tryCC = false;
        var tryACH = false;
        if (has_cc && default_cc_is_available) {
          tryCC = true;
        }
        if (has_ach && default_ach_is_available) {
          tryACH = true;
        }
        if(tryCC) {
          var cc_accounts = accounts.filter(function(acct) {
            return acct.get('payment_method') === 'cc' && acct.get('expiring_in_months') >= 0 && acct.get('active');
          });
          // var cc_accounts = Ember.A();
          if(cc_accounts && cc_accounts.get('length')) {
            product = default_cc;
            account = cc_accounts.get('firstObject');
          } else {
            tryACH = true;
            tryCC = false;
          }
        }
        if(tryACH && !tryCC) {
          var ach_accounts = accounts.filter(function(acct) {
            return acct.get('payment_method') === 'ach' && acct.get('active');
          });
          if(ach_accounts && ach_accounts.get('length')) {
            product = default_ach;
            account = ach_accounts.get('firstObject');
          }
        }
        if(!product) {
          if (has_cc && default_cc_is_available) {
            product = default_cc;
          } else if (has_ach && default_ach_is_available) {
            product = default_ach;
          } else {
            product = products.get('firstObject');
          }
        }
        if(account) {
          this.set('processMethodExisting', true);
          this.set('recurring.account_vault_id', account.get('id'));
          this.set('recurring.account_vault', account);
        } else {
          this.set('processMethodExisting', false);
        }

      }
      if (this.get('isEditing') && product && !this.get('recurring.account_vault_id')) {
        accounts = accounts.filter(function(a) {
          return a.get('payment_method') === product.get('payment_method');
        });
        if (accounts && accounts.get('length')) {
          account = accounts.filter(function(a) {
            return a.get('payment_method') === product.get('payment_method');
          }).get('firstObject');
          if (product.get('payment_method') === account.get('payment_method')) {
            this.set('recurring.account_vault_id', account.get('id'));
            this.set('recurring.account_vault', account);
          }
        }
      }
    } else {
      return;
    }
    if (product) {
      recurring.set('product_transaction_id', product.get('id'));
      this.set('product', product);
    }
  }),

  // Functions

  saveRecurring: function() {
    var self = this;
    var recurring = this.get('recurring');
    var contact = this.get('contact');
    var location = this.get('location');
    var session = this.get('session');
    self.set('isLoading', true);

    // Check to make sure the transaction_amount is greater than 0
    if (!recurring.get('transaction_amount') || parseInt(recurring.get('transaction_amount').replace('.', '')) === 0) {
      recurring.get('errors').add('transaction_amount', 'Transaction amount must be greater than zero.');
      self.set('isLoading', false);
      self.set('needsUpdate', new moment().unix());
      $('#collapse_recurring_details').collapse('show');
      return;
    }
    if (!recurring.get('next_run_date') && recurring.get('status') === 'active') {
      recurring.get('errors').add('next_run_date', 'Next Run Date cannot be blank.');
      self.set('isLoading', false);
      self.set('needsUpdate', new moment().unix());
      $('#collapse_recurring_details').collapse('show');
      return;
    }
    if (recurring.get('isNew') && this.get('isEndTypeInstallment') && !recurring.get('installment_total_count')) {
      recurring.get('errors').add('installment_total_count', 'Number of Payments cannot be blank.');
      self.set('isLoading', false);
      self.set('needsUpdate', new moment().unix());
      $('#collapse_recurring_details').collapse('show');
      return;
    }

    // Make sure we set the number of occurrences for one time payment
    if (this.get('isOneTime')) {
      recurring.set('installment_total_count', 1);
      recurring.set('interval', 1);
      recurring.set('interval_type', 'd');
    }

    recurring.save()
      .then(function (recurring_response) {
        return recurring_response;
      })
      .then(function (res) {
        noty({
          text: 'Recurring Billing saved successfully!'
        });

        var returnRoute = self.get('returnRoute');
        var isRouteRecurrings = self.get('isRouteRecurrings');
        var isRouteContact = self.get('isRouteContact');
        var isRouteContactUser = self.get('isRouteContactUser');
        var isRouteLocation = self.get('isRouteLocation');
        var router = self.get('router');

        if (isRouteRecurrings) {
          router.transitionTo('recurrings.index');
        } else if (isRouteContact) {
          if (returnRoute) {
            router.transitionTo(returnRoute, contact.get('id'));
          } else {
            router.transitionTo('contacts.recurrings-index', contact.get('id'));
          }
        } else if (isRouteContactUser) {
          if (returnRoute) {
            router.transitionTo(returnRoute);
          } else {
            router.transitionTo('contactuser.recurrings-index');
          }
        } else if (isRouteLocation) {
          router.transitionTo('locations.recurrings-index', location.get('id'));
        }

        // Show popup if there are legal notices set in config
        var producttransactions = location.get('product_transactions');
        var product = producttransactions.filter(function(p) {
          return p.get('id') === recurring.get('product_transaction_id');
        }).get('firstObject');

        if (product && product.get('receipt_add_recurring_above_signature') && self.get('isAdding') && self.get('route') !== 'contactuser.recurrings-add') {
          var controller = self.get('authagreementController');

          // Reset properties first
          controller.set('authagreementType', null);
          controller.set('model', null);
          controller.set('contact', null);
          controller.set('location', null);
          controller.set('product', null);

          // Now re-set the data
          controller.set('authagreementType', 'recurring');
          Ember.run.later(function() {
            if(session.get('authenticated.resources.v2-recurrings-get')) {
              self.get('store').queryRecord('recurring', {
                id: res.get('id'),
                expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,location,contact,transactions,payment_schedule'
              }).then(function(data){
                controller.set('model', data);
                self.get('parent').send('openModal', 'components/modals/authagreement');
              });
            } else {
              controller.set('model', recurring);
              self.get('parent').send('openModal', 'components/modals/authagreement');
            }
          },1000);
          controller.set('contact', contact);
          controller.set('location', location);
          controller.set('product', product);
        }

        self.set('oneTime', '0');
        self.set('endType', '0');

      }, function(err) {
        var errors = err.errors;
        if (errors && errors.get('length')) {
          var isAccountVaultAreaError = false;
          errors.forEach(function (e) {
            if (e.source && e.source.prop === 'account_vault_id') {
              isAccountVaultAreaError = true;
            }
          });
          if (isAccountVaultAreaError) {
            $('#collapse_recurring_accountvault').collapse('show');
          } else {
            $('#collapse_recurring_details').collapse('show');
          }
        }
        self.set('isLoading', false);
        self.set('needsUpdate', new moment().unix());
      });
  },
  setLocation: function() {
    var locations = this.get('locations');
    var recurring_location_id = this.get('recurring.location_id') || this.get('recurring.location.id');
    locations = locations.filter(function(loc) {
      return loc.get('id') === recurring_location_id;
    });
    if (locations && locations.get('length') === 1) {
      var location = locations.get('firstObject');
      this.set('location', location);
      this.set('recurring.location', location); // needed so info in collabsile header displays correctly
      this.set('tagList', location.get('tags').sortBy('title'));
      var force_account_id = this.get('force_account.id');
      var force_account_location_id = this.get('force_account.location_id');
      if(this.get('isAdding') && !this.get('isRouteContact') && !this.get('isRouteContactUser') && (location.get('id') !== force_account_location_id || !force_account_id)) {
        this.set('recurring.account_vault_id', null);
        this.set('recurring.account_vault', null);
        this.set('force_account', null);
        this.set('searchAccountVaultsResults', []);
      }
    }
  },
  setProductFromId: function(product_transaction_id) {
    var location = this.get('location');
    if (Ember.isEmpty(location)) {
      return;
    }
    var product = location.get('product_transactions').filter(function(prod) {
      return prod.get('id') === product_transaction_id;
    }).get('firstObject');
    this.set('product', product);
    this.set('recurring.product_transaction', product); // needed so total_transaction_amount computed property on model evaluates correctly
    if (product) {
      var legal = null;
      legal = product.get('receipt_add_recurring_above_signature');
      if (legal && this.get('route') === 'contactuser.recurrings-add') {
        this.set('legal', legal);
        this.set('recurring.terms_agree', false);
      } else {
        this.set('legal', null);
        this.set('recurring.terms_agree', true);
      }
    } else {
      this.set('recurring.terms_agree', true);
    }
    this.set('processMethodExisting', (!this.get('noExistingAccounts') && (this.get('session.authenticated.resources.v2-accountvaults-get') || false) && (!this.get('isRouteContact') || !this.get('isRouteContactUser'))));
    if (this.get('accountvaultSearch.payment_method') && this.get('accountvaultSearch.payment_method') !== product.get('payment_method')) {
      this.set('searchAccountVaultsResults', []);
      if (this.get('recurring.account_vault.payment_method') && this.get('recurring.account_vault.payment_method') !== product.get('payment_method')) {
        this.set('recurring.account_vault_id', null);
        this.set('recurring.account_vault', null);
      }
      this.set('accountvaultSearch.account_type', null);
      this.set('accountvaultSearch.payment_method', this.get('product.payment_method'));
      if (this.get('isRouteContact') || this.get('isRouteContactUser')) {
        this.send('searchAccountVaults');
      }
    }
    if (product && this.get('recurring.account_vault') && product.get('payment_method') !== this.get('recurring.account_vault.payment_method')) {
      var accounts = this.get('accounts');
      var account = null;
      if (accounts) {
        account = accounts.filter(function(a) {
          return a.get('payment_method') === product.get('payment_method');
        }).get('firstObject');
        this.set('recurring.account_vault_id', account ? account.get('id') : null);
        this.set('recurring.account_vault', account ? account : null);
      }
      if (!account) {
        this.set('searchAccountVaultsResults', []);
        if(this.get('isRouteContact') || this.get('isRouteContactUser')) {
          this.set('processMethodExisting', false);
        }
      }
    }
  },

  // Actions
  actions: {
    activate: function() {
      var self = this;
      pretty_confirm('Are you sure you would like to activate this Recurring Billing?', function() {
        activeRecurring(self);
      });

      function activeRecurring(self) {
        var recurring = self.get('recurring');
        var session = self.get('session');
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/recurrings/' + recurring.id + '/activate',
          headers: {
            'access-token': session.get('authenticated.token'),
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          data: '',
          contentType: 'application/json',
          timeout: 10000
        }).then(function(result) {
          var next_run_date = moment(Ember.get(result, 'recurring.next_run_date'), 'YYYY-MM-DD');
          recurring.set('next_run_date', next_run_date.format('MM/DD/YYYY'));
          recurring.set('status', 'active');
          noty({
            text: 'Recurring Billing successfully activated!'
          });
          self.get('store').push({ // need this for accurate dirty checking in willTransition
            data: {
              id: recurring.get('id'),
              type: 'recurring',
              attributes: {
                status: 'active',
                next_run_date: next_run_date.format('MM/DD/YYYY'),
              }
            }
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Error activating Recurring Billing!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    addAccountVault: function(account) {
      this.set('recurring.account_vault_id', account.get('id'));
      this.set('recurring.account_vault', account);
      $('#collapse_recurring_details').collapse('show');
      var searchAccountVaultsResults = this.get('searchAccountVaultsResults');
      searchAccountVaultsResults = searchAccountVaultsResults.map(function(acct) {
        acct.set('isSelectedAccountVault', acct.get('id') === account.get('id'));
        return acct;
      });
      this.set('searchAccountVaultsResults', searchAccountVaultsResults);
      account.set('isSelectedAccountVault', true);
    },
    changePaymentMethod: function(method) {
      if (method === 'cc') {
        this.set('paymentMethodCreditCard', true);
        this.set('recurring.product_transaction_id', this.get('location.default_cc'));
      } else {
        this.set('paymentMethodCreditCard', false);
        this.set('recurring.product_transaction_id', this.get('location.default_ach'));
      }
    },
    changeProcessMethod: function(method) {
      if (method === 'existing') {
        this.set('processMethodExisting', true);
      } else {
        this.set('recurring.account_vault_id', null);
        this.set('recurring.account_vault', null);
        this.set('newaccount', null);
        this.set('processMethodExisting', false);
      }
    },
    changeRecurringEndType: function() {
      Ember.run.later(function() {
        $('#recurring_installment_total_count').focus();
      });
    },
    selectAccountVault: function (index) {
      const available_accounts = this.get('available_accounts') || [];
      const account_vault = available_accounts[index];
      const account_vault_id = account_vault ? account_vault.id : null;
      this.set('recurring.account_vault_id', account_vault_id);
      if(account_vault_id === null) {
        this.set('recurring.account_vault', null);
      }
    },
    defer: function() {
      var self = this;
      var defer_count = this.get('defer_count');
      var noAmount = (!defer_count && defer_count !== 0) || Ember.isBlank(defer_count);
      var defer_count_num = defer_count ? Number(defer_count.replace(/,/g, '')) : 'NaN';
      var defer_count_not_number = String(defer_count_num) === 'NaN';
      if (noAmount) {
        this.set('deferCountError', true);
        return noty({
          text: 'Number of payments to defer cannot be blank!',
          type: 'error'
        });
      }
      if (defer_count_not_number) {
        this.set('deferCountError', true);
        return noty({
          text: 'Number of payments to defer must be a number!',
          type: 'error'
        });
      }
      if (defer_count_num % 1 !== 0) {
        this.set('deferCountError', true);
        return noty({
          text: 'Number of payments to defer must be an integer!',
          type: 'error'
        });
      }
      this.set('deferCountError', false);

      var confirm_message = 'Are you sure you would like to defer ' + defer_count;
      if (Number(defer_count) === 1) {
        confirm_message += ' payment?';
      } else {
        confirm_message += ' payments?';
      }
      pretty_confirm(confirm_message, function() {
        deferPayment(self);
      });

      function deferPayment(self) {
        var recurring = self.get('recurring');
        var session = self.get('session');
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/recurrings/' + recurring.id + '/deferPayment?defer_count=' + defer_count,
          headers: {
            'access-token': session.get('authenticated.token'),
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          data: '',
          contentType: 'application/json',
          timeout: 10000
        }).then(function(result) {
          var next_run_date = moment(Ember.get(result, 'recurring.next_run_date'), 'YYYY-MM-DD');
          var end_date = moment(Ember.get(result, 'recurring.end_date'), 'YYYY-MM-DD');
          recurring.set('next_run_date', next_run_date.format('MM/DD/YYYY'));
          recurring.set('end_date', end_date.format('MM/DD/YYYY'));
          self.get('store').push({ // need this for accurate dirty checking in route willTransition
            data: {
              id: recurring.get('id'),
              type: 'recurring',
              attributes: {
                next_run_date: recurring.get('next_run_date'),
                end_date: recurring.get('end_date'),
              }
            }
          });
          self.set('defer_count', null);
          pretty_confirm_ok_only('Payment successfully deferred!<br>Next run date is now ' + next_run_date.format('MM/DD/YYYY') + ' and end date is now ' + end_date.format('MM/DD/YYYY'), function() {
            return true;
          });
          self.set('isLoading', false);
        }, function(err) {
          noty({
            text: err.responseJSON ? err.responseJSON.message : 'Error deferring payment!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    delete: function() {
      var self = this;
      var recurring = this.get('recurring');
      var prompt = 'Are you sure you want to delete this ';
      var recurringName = this.get('isRouteContactUser') ? 'Scheduled Payment' : 'Recurring Billing';
      prompt += recurringName + '?';
      pretty_confirm(prompt, function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        recurring.destroyRecord().then(function() {
          recurring.unloadRecord();
          noty({
            text: recurringName + ' deleted successfully!'
          });
          // don't set isLoading to false, so crud buttons stay disabled after delete and during transition back to listing
          self.set('isEditing', false);
          self.send('reset');
        }, function() {
          recurring.rollbackAttributes();
          noty({
            text: 'Error deleting this ' + recurringName + '!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    getSignature: function() {
      var controller = this.get('authagreementController');
      var recurring = this.get('recurring');
      var location = this.get('location');
      var contact = this.get('contact') || this.get('recurring.contact');
      var product = recurring.get('product_transaction');

      if (recurring.get('product_transaction.receipt_add_recurring_above_signature')) {
        controller.set('authagreementType', null);
        controller.set('model', null);
        controller.set('location', null);
        controller.set('contact', null);
        controller.set('product', null);
        controller.set('authagreementType', 'recurring');
        controller.set('model', recurring);
        controller.set('location', location);
        controller.set('contact', contact);
        controller.set('product', product);
        if (product.get('receipt_add_recurring_above_signature')) {
          this.get('parent').send('openModal', 'components/modals/authagreement');
        }
      }
    },
    hold: function() {
      var self = this;
      pretty_confirm('Are you sure you would like to place this Recurring Billing on hold?', function() {
        holdRecurring(self);
      });

      function holdRecurring(self) {
        var recurring = self.get('recurring');
        var session = self.get('session');
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/recurrings/' + recurring.id + '/placeOnHold',
          headers: {
            'access-token': session.get('authenticated.token'),
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          data: '',
          contentType: 'application/json',
          timeout: 10000
        }).then(function() {
          recurring.set('next_run_date', null);
          recurring.set('status', 'on hold');
          self.get('store').push({ // need this for accurate dirty checking in willTransition
            data: {
              id: recurring.get('id'),
              type: 'recurring',
              attributes: {
                status: 'on hold',
                next_run_date: null,
              }
            }
          });
          noty({
            text: 'Recurring Billing successfully placed on hold!'
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Error placing Recurring Billing on hold!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    newAccount: function(account) {
      var payment_method = this.get('account.payment_method');
      var location = this.get('location');
      var defaultProduct = payment_method === 'cc' ? location.get('defaultProductCC') || null : location.get('defaultProductACH') || null;
      if (defaultProduct) {
        var legal = defaultProduct.get('receipt_add_account_above_signature');
        if (legal && !account.get('terms_agree')) {
          this.set('newaccount_terms_agree', false);
        } else {
          this.set('newaccount_terms_agree', true);
        }
      } else {
        this.set('newaccount_terms_agree', true);
      }
      this.set('newaccount', account);
    },
    reset: function() {
      if(this.get('isTransitioning')) {
        return;
      }
      this.set('recurring.account_vault', null);
      var contact = this.get('contact');
      var location = this.get('location');
      var returnRoute = this.get('returnRoute');
      var isRouteRecurrings = this.get('isRouteRecurrings');
      var isRouteContact = this.get('isRouteContact');
      var isRouteContactUser = this.get('isRouteContactUser');
      var isRouteLocation = this.get('isRouteLocation');
      var router = this.get('router');

      if (isRouteRecurrings) {
        router.transitionTo('recurrings.index');
      } else if (isRouteContact) {
        if (returnRoute) {
          router.transitionTo(returnRoute, contact.get('id'));
        } else {
          router.transitionTo('contacts.recurrings-index', contact.get('id'));
        }
      } else if (isRouteContactUser) {
        if (returnRoute) {
          router.transitionTo(returnRoute);
        } else {
          router.transitionTo('contactuser.recurrings-index');
        }
      } else if (isRouteLocation) {
        router.transitionTo('locations.recurrings-index', location.get('id'));
      }
    },
    save: function() {
      var recurring = this.get('recurring');
      // Bail if we are already loading
      if (this.get('isLoading') || (this.get('legal') && this.get('isRouteContactUser') && !recurring.get('terms_agree') && recurring.get('isNew'))) {
        return;
      }
      jQuery('.dropdown-select.open .dropdown-toggle').dropdown('toggle'); // close any open dropdowns before proceeding. Ex: selecting/deselecting tags - changes only apply to model on dropdown close
      this.set('isLoading', true);

      var self = this;

      if (this.get('processMethodExisting')) {
        this.saveRecurring();
      } else {
        if(recurring.get('isNew')) {
          this.get('newaccount').save().then(function(data) {
            if(self.get('isRouteContact') || self.get('isRouteContactUser')) {
              self.get('accounts').pushObject(data);
            } else {
              self.set('processMethodExisting', true);
              var searchAccountVaultsResults = self.get('searchAccountVaultsResults');
              data.set('isSelectedAccountVault', true);
              searchAccountVaultsResults.pushObject(data);
              self.set('searchAccountVaultsResults', searchAccountVaultsResults);
            }
            self.set('recurring.account_vault_id', data.get('id'));
            self.set('recurring.account_vault', data);

            self.saveRecurring();
          }, function() {
            $('#collapse_recurring_accountvault').collapse('show');
            self.set('isLoading', false);
          });
        } else {
          recurring.get('errors').add('account_vault_id', 'Account Vault cannot be blank.');
          self.set('isLoading', false);
          self.set('needsUpdate', new moment().unix());
          $('#collapse_recurring_accountvault').collapse('show');
        }
      }
    },
    searchAccountVaults: function(account_vault_id) {
      var self = this;
      var accountvaultSearch = this.get('accountvaultSearch');
      if(!accountvaultSearch) {
        return;
      }
      var payment_method = this.get('product.payment_method');
      this.set('accountvaultSearch.payment_method', payment_method);
      if(this.get('session.authenticated.resources.v2-accountvaults-get')) {
        var searchAccountVaultsResults = this.get('store').query('accountvault', {
          id: account_vault_id || null,
          contact_id: accountvaultSearch.contact_id,
          account_holder_name: accountvaultSearch.account_holder_name,
          title: accountvaultSearch.title,
          account_type: accountvaultSearch.account_type,
          last_four: accountvaultSearch.last_four,
          sort: '-title',
          page_size: '10',
          active: '1',
          location_id: this.get('location.id'),
          payment_method: payment_method,
          expand: 'contact,location'
        });
        this.set('isSearching', true);
        searchAccountVaultsResults.then(function(result) {
          if (result && result.get('length')) {
            var tempResults = result.map(function(res) {
              res.set('isSelectedAccountVault', res.get('id') === self.get('recurring.account_vault_id'));
              return res;
            });
            self.set('searchAccountVaultsResults', tempResults);
          } else {
            self.set('searchAccountVaultsResults', []);
          }
          self.set('isSearching', false);
        });
      }
    },
    searchAccountVaultsClear: function() {
      this.set('searchAccountVaultsResults', []);
      this.set('accountvaultSearch', {
        contact_id: null,
        payment_method: this.get('product.payment_method')
      });
    },
    showTrans: function(transaction) {
      var location = this.get('location');
      this.get('parent').send('showTransDetail', transaction, location);
    },
    skip: function() {
      var self = this;
      var skip_count = this.get('skip_count');
      var noAmount = (!skip_count && skip_count !== 0) || Ember.isBlank(skip_count);
      var skip_count_num = skip_count ? Number(skip_count.replace(/,/g, '')) : 'NaN';
      var skip_count_not_number = String(skip_count_num) === 'NaN';
      if (noAmount) {
        this.set('skipCountError', true);
        return noty({
          text: 'Number of payments to skip cannot be blank!',
          type: 'error'
        });
      }
      if (skip_count_not_number) {
        this.set('skipCountError', true);
        return noty({
          text: 'Number of payments to skip must be a number!',
          type: 'error'
        });
      }
      if (skip_count_num % 1 !== 0) {
        this.set('skipCountError', true);
        return noty({
          text: 'Number of payments to skip must be an integer!',
          type: 'error'
        });
      }
      this.set('skipCountError', false);

      var confirm_message = 'Are you sure you would like to skip ' + skip_count;
      if (Number(skip_count) === 1) {
        confirm_message += ' payment?';
      } else {
        confirm_message += ' payments?';
      }
      pretty_confirm(confirm_message, function() {
        skipPayment(self);
      });

      function skipPayment(self) {
        var recurring = self.get('recurring');
        var session = self.get('session');
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/recurrings/' + recurring.id + '/skipPayment?skip_count=' + skip_count,
          headers: {
            'access-token': session.get('authenticated.token'),
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          data: '',
          contentType: 'application/json',
          timeout: 10000
        }).then(function(result) {
          var next_run_date = moment(Ember.get(result, 'recurring.next_run_date'), 'YYYY-MM-DD');
          recurring.set('next_run_date', next_run_date.format('MM/DD/YYYY'));
          recurring.set('installment_total_count', Ember.get(result, 'recurring.installment_total_count'));
          self.get('store').push({ // need this for accurate dirty checking in route willTransition
            data: {
              id: recurring.get('id'),
              type: 'recurring',
              attributes: {
                next_run_date: recurring.get('next_run_date'),
                installment_total_count: recurring.get('installment_total_count')
              }
            }
          });
          self.set('skip_count', null);
          pretty_confirm_ok_only('Payment successfully skipped!<br>Next run date is now ' + next_run_date.format('MM/DD/YYYY'), function() {
            return true;
          });
          self.set('isLoading', false);
        }, function(err) {
          noty({
            text: err.responseJSON ? err.responseJSON.message : 'Error skipping payment!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    stepNext: function(step) {
      if (step === '2') {
        $('#collapse_recurring_accountvault').collapse('show');
      } else if (step === '3') {
        $('#collapse_recurring_details').collapse('show');
      }
    },
    stepPrevious: function(step) {
      if (step === '1') {
        $('#collapse_recurring_location_producttransaction').collapse('show');
      } else if (step === '2') {
        $('#collapse_recurring_accountvault').collapse('show');
      }
    },
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }

      // Get recurring object
      var recurring = this.get('recurring');

      // Get existing tags for recurring object
      var existingTags = recurring.get('tags');
      var arrayTags = existingTags.toArray();

      // Remove all existing tags for recurring
      arrayTags.forEach(function(item) {
        existingTags.removeObject(item);
      });
      recurring.set('tags', existingTags);

      // Then add back any newly selected tag objects
      var tagList = this.get('tagList');
      var selectedTags = tags.filters[0].value.split(',');
      tagList.forEach(function(tag) {
        if (selectedTags.indexOf(tag.get('title')) > -1) {
          recurring.get('tags').pushObject(tag);
        }
      });
    },
    viewAccountVault: function() {
      var isRouteRecurrings = this.get('isRouteRecurrings');
      var isRouteContact = this.get('isRouteContact');
      var isRouteContactUser = this.get('isRouteContactUser');
      var isRouteLocation = this.get('isRouteLocation');
      var router = this.get('router');

      if (isRouteRecurrings) {
        router.transitionTo('accounts.view', this.get('recurring.account_vault_id'));
      } else if (isRouteContact) {
        router.transitionTo('contacts.accounts-view', this.get('contact.id'), this.get('recurring.account_vault_id'));
      } else if (isRouteContactUser) {
        router.transitionTo('contactuser.accounts-view', this.get('contact.id'), this.get('recurring.account_vault_id'));
      } else if (isRouteLocation) {
        router.transitionTo('locations.accounts-view', this.get('location.id'), this.get('recurring.account_vault_id'));
      }
    }
  }
});
