import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  logo: DS.attr(),
  logo_url: DS.attr('string'),
  resource: DS.attr('string'),
  resource_id: DS.attr('string'),
});
