import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  location_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  trx_source_id: DS.attr('string'),
  total_amount: DS.attr('string'),
  total_count: DS.attr('string'),

});
