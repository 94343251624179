import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  amex_grand_total: DS.attr('string'),
  amex_total_refund_amount: DS.attr('string'),
  amex_total_sale_amount: DS.attr('string'),
  amex_total_void_amount: DS.attr('string'),
  batch: DS.attr('string'),
  created_ts: DS.attr('ts'),
  disc_grand_total: DS.attr('string'),
  disc_total_refund_amount: DS.attr('string'),
  disc_total_sale_amount: DS.attr('string'),
  disc_total_void_amount: DS.attr('string'),
  grand_total: DS.attr('string'),
  mc_grand_total: DS.attr('string'),
  mc_total_refund_amount: DS.attr('string'),
  mc_total_sale_amount: DS.attr('string'),
  mc_total_void_amount: DS.attr('string'),
  other_grand_total: DS.attr('string'),
  other_total_refund_amount: DS.attr('string'),
  other_total_sale_amount: DS.attr('string'),
  other_total_void_amount: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  visa_grand_total: DS.attr('string'),
  visa_total_refund_amount: DS.attr('string'),
  visa_total_sale_amount: DS.attr('string'),
  visa_total_void_amount: DS.attr('string'),

  // Defined model relationships
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
  transaction_batch: DS.belongsTo('transactionbatch'),
});
