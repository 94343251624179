import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('mailboxmessagesuser.errors.messages', function() {
    this.set('errorMessages', this.get('mailboxmessagesuser.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isLoading: false,
  isEditing: false,

  // Observers
  findMailboxMessagesUserStatus: Ember.observer('mailboxmessagesuser.mailbox_message_status_id', function() {
    var self = this;
    var status = this.get('mailboxmessagesuser.mailbox_message_status_id');
    switch (status) {
      case 1: // read
        self.set('isRead', true);
        self.set('isUnread', false);
        self.set('isArchived', false);
        break;
      case 2: // unread
        self.set('isRead', false);
        self.set('isUnread', true);
        self.set('isArchived', false);
        break;
      case 3: // archived
        self.set('isRead', false);
        self.set('isUnread', false);
        self.set('isArchived', true);
        break;
    }
  }),

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('mailboxmessagesuser.' + this.get('returnRoute'));
    },
    changeStatus: function(status) {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      self.set('mailboxmessagesuser.mailbox_message_status_id', status);
      var mailboxmessageuser = this.get('mailboxmessagesuser');
      mailboxmessageuser.save({
        expand: 'mailbox_message'
      }).then(function(result) {
        self.set('mailboxmessagesuser', result);
        self.send('getUsersUnreadMessages');
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    },
  }
});
