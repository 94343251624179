import Ember from 'ember';
import jQuery from 'jquery';
/* global REDIPS: true */
var previewHPP = {};
export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  //Events
  didInsertElement: function() {
    this.set('hppIsDirty', false);
    this.set('rowsAndColumns.header.rows', this.get('hpp.field_configuration.header.settings.rows'));
    this.set('rowsAndColumns.header.columns', this.get('hpp.field_configuration.header.settings.columns'));
    this.set('rowsAndColumns.body.rows', this.get('hpp.field_configuration.body.settings.rows'));
    this.set('rowsAndColumns.body.columns', this.get('hpp.field_configuration.body.settings.columns'));
    this.set('rowsAndColumns.footer.rows', this.get('hpp.field_configuration.footer.settings.rows'));
    this.set('rowsAndColumns.footer.columns', this.get('hpp.field_configuration.footer.settings.columns'));
    this.set('compactView', this.get('hpp.field_configuration.css_mini'));
    var self = this;
  
    // Setting default value for recaptcha_score_threshold to 0.5 if value not present.
    if (!this.get('hpp.recaptcha_score_threshold')) {
      this.set('hpp.recaptcha_score_threshold', 0.5);
    }
    Ember.run.later(function() {
      self.send('makeSectionActive', 'header', null, self.get('isHowToUse'));
      if (self.get('isHowToUse')) {
        self.getURL();
      }
    });
  },
  didReceiveAttrs: function() {
    previewHPP = JSON.parse(JSON.stringify(this.get('hpp')));
  },
  willDestroyElement: function() {
    this.set('isTransitioning', false);
  },

  // Properties
  border_styles: [{
      id: 'none',
      title: 'None'
    },
    {
      id: 'dashed',
      title: 'Dashed'
    },
    {
      id: 'dotted',
      title: 'Dotted'
    },
    {
      id: 'double',
      title: 'Double'
    },
    {
      id: 'groove',
      title: 'Groove'
    },
    {
      id: 'inset',
      title: 'Inset'
    },
    {
      id: 'outset',
      title: 'Outset'
    },
    {
      id: 'ridge',
      title: 'Ridge'
    },
    {
      id: 'solid',
      title: 'Solid'
    },
  ],
  customButton: {
    title: 'Pay Now',
    background_color: '#428bca',
    border_color: '#357ebd',
    border_radius: 5,
    border_width: 0,
    border_style: 'none',
    font_size: 12,
    color: '#ffffff',
    height: 30,
    width: 100,
    margin: 0,
    padding: 7,
    text_decoration: 'none',
    text_align: 'center',
    display: 'inline-block',
    styleString: 'display:inline-block;background-color:#428bca;border-color:#357ebd;border-radius:5px;border-width:0;border-style:none;color:#ffffff;font-size:12px;height:30px;width:100px;margin:0px;padding:7px;text-decoration:none;text-align:center'
  },
  exampleJSON: {
    "id": "hosted_payment_page_id",
    "stylesheet_url": "https://127.0.0.1/css/payform.css",
    "field_configuration": {
      "header": {
        "fields": [{
          "id": "header_title",
          "label": "OVERRING WITH DATABAG"
        }]
      },
      "body": {
        "fields": [{
            "id": "transaction_amount",
            "label": "Amount",
            "value": "25.00"
          },
          {
            "id": "account_number",
            "placeholder": "Please enter account number here!"
          },
          {
            "id": "billing_street",
            "required": true,
            "readonly": false
          }
        ],
      },
      "footer": {
        "fields": [{
          "id": "submit_button",
          "label": "DONATE NOW!"
        }]
      }
    },
    "redirect_url_on_approve": "https://www.google.com",
    "redirect_url_on_decline": "https://www.bing.com",
    "redirect_url_delay": "15",
    "parent_send_message": 0
  },
  rowsAndColumns: {
    header: {
      rows: 1,
      columns: 2
    },
    body: {
      rows: 1,
      columns: 2
    },
    footer: {
      rows: 1,
      columns: 2
    }
  },
  showingFormConfig: false,
  showingButtonConfig: false,
  useAsButtonType: 'Example',

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('hppErrorMessages.@each', function() {
    this.set('errorMessages', this.get('hppErrorMessages'));
  }),

  // Computed Properties

  advancedLink: Ember.computed(function() {
    return 'https://docs.' + window.ENV.APP.API_URL + '/developers/quick-start/hosted-payment-page';
  }),
  disableAddColumns: Ember.computed('activeSection', 'rowsAndColumns.{header.columns,body.columns,footer.columns}', 'isAddingRowOrColumn', function() {
    var area = this.get('activeSection');
    var rowsAndColumns = this.get('rowsAndColumns');
    var isAddingRowOrColumn = this.get('isAddingRowOrColumn');
    if (rowsAndColumns[area] || isAddingRowOrColumn) {
      return rowsAndColumns[area].columns === 4 || isAddingRowOrColumn;
    } else {
      return false;
    }
  }),
  disableAddRows: Ember.computed('activeSection', 'rowsAndColumns.{header.rows,body.rows,footer.rows}', 'isAddingRowOrColumn', function() {
    var area = this.get('activeSection');
    var rowsAndColumns = this.get('rowsAndColumns');
    var isAddingRowOrColumn = this.get('isAddingRowOrColumn');
    if (rowsAndColumns[area] || isAddingRowOrColumn) {
      return rowsAndColumns[area].rows === 100 || isAddingRowOrColumn;
    } else {
      return false;
    }
  }),
  custom_options: Ember.computed(function() {
    var arr = [{
        id: 'customfield_checkbox',
        label: 'Checkbox',
        field_type: 'inputCheckbox',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_date',
        label: 'Date Input',
        field_type: 'inputDate',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_select',
        label: 'Dropdown',
        field_type: 'inputSelect',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_text',
        label: 'Text Input',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_heading_display',
        label: 'Display Heading',
        field_type: 'heading',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_image_display',
        label: 'Display Image',
        field_type: 'image',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
      {
        id: 'customfield_text_display',
        label: 'Display Text',
        field_type: 'text',
        position: [null, null, '1', '1'],
        isCloneable: true
      },
    ];
    return arr;
  }),
  customOptionsLength: Ember.computed('activeSection', 'custom_options.@each', function() {
    var options = this.get('custom_options');
    return options.get('length');
  }),
  field_options: Ember.computed('activeSection', 'product.payment_method', function() {
    var product = this.get('product');
    var area = this.get('activeSection');
    var arr = [];
    if(area === 'header') {
      arr.push({
        id: 'option_header_logo',
        label: 'Header Logo',
        field_type: 'image',
        link: product.get('receipt_logo') ? product.get('receipt_logo') + "?" + moment().valueOf() : 'Image Here',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_header_title',
        label: 'Form Title',
        field_type: 'heading',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
    } else if (area === 'body') {
      arr.push({
        id: 'option_account_holder_name',
        label: 'Account Holder Name',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
      if (product.get('industry_type') === 'lodging') {
        arr.push({
          id: 'option_advance_deposit',
          label: 'Advance Deposit',
          field_type: 'inputCheckbox',
          position: [null, null, '1', '1'],
          isCloneable: true
        });
      }
      arr.push({
        id: 'option_heading_billing_info',
        label: 'Billing Information',
        field_type: 'heading',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_billing_street',
        label: 'Billing Street',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_billing_city',
        label: 'Billing City',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_billing_phone',
        label: 'Billing Phone',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_billing_state',
        label: 'Billing State',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_check_number',
        label: 'Check Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_billing_zip',
        label: 'Billing Zip',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_dl_number',
        label: 'Driver\'s License Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true,
      }, {
        id: 'option_dl_state',
        label: 'Driver\'s License State',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true,
      }, {
        id: 'option_ssn4',
        label: 'SSN Last Four',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true,
      }, {
        id: 'option_dob_year',
        label: 'Date of Birth Year',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true,
      });
      if (product.get('industry_type') === 'lodging') {
        arr.push({
          id: 'option_checkin_date',
          label: 'Checkin Date',
          field_type: 'inputText',
          position: [null, null, '1', '1'],
          isCloneable: true
        }, {
          id: 'option_checkout_date',
          label: 'Checkout Date',
          field_type: 'inputText',
          position: [null, null, '1', '1'],
          isCloneable: true
        });
      }
      arr.push({
        id: 'option_clerk_number',
        label: 'Clerk Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
      if (product.get('payment_method') === 'cc') {
        arr.push({
          id: 'option_cvv',
          label: 'CVV',
          field_type: 'inputText',
          position: [null, null, '1', '1'],
          isCloneable: true
        });
      }
      arr.push({
        id: 'option_description',
        label: 'Description',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_notification_email_address',
        label: 'Email',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
      if (product.get('industry_type') === 'lodging') {
        arr.push({
          id: 'option_no_show',
          label: 'No Show',
          field_type: 'inputCheckbox',
          position: [null, null, '1', '1'],
          isCloneable: true
        });
      }
      arr.push({
        id: 'option_order_num',
        label: 'Order Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_heading_payment_info',
        label: 'Payment Information',
        field_type: 'heading',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_po_number',
        label: 'PO Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_confirm_account_number',
        label: 'Confirm Account Number',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        required: true,
        isCloneable: true,
      });

      if (product.get('industry_type') === 'lodging') {
        arr.push({
          id: 'option_room_num',
          label: 'Room Number',
          field_type: 'inputText',
          position: [null, null, '1', '1'],
          isCloneable: true
        }, {
          id: 'option_room_rate',
          label: 'Room Rate',
          field_type: 'inputText',
          position: [null, null, '1', '1'],
          isCloneable: true
        });
      }
      arr.push({
        id: 'option_tax',
        label: 'Tax',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
      arr.push({
        id: 'option_tip_amount',
        label: 'Tip Amount',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
      arr.push({
        id: 'option_transaction_api_id',
        label: 'Transaction API ID',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_transaction_c1',
        label: 'Transaction Custom 1',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_transaction_c2',
        label: 'Transaction Custom 2',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      }, {
        id: 'option_transaction_c3',
        label: 'Transaction Custom 3',
        field_type: 'inputText',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
    } else if(area === 'footer') {
      arr.push({
        id: 'option_terms_agree',
        label: 'Authorization Verbiage',
        field_type: 'inputCheckbox',
        position: [null, null, '1', '1'],
        isCloneable: true
      });
    }
    return arr;
  }),
  fieldSections: Ember.computed('rowsAndColumns.{header.rows,body.rows,footer.rows}', 'rowsAndColumns.{header.columns,body.columns,footer.columns}', function() {
    return [{
        id: 'header',
        title: 'Header',
        showing: true,
        rows: this.get('rowsAndColumns.header.rows'),
        columns: this.get('rowsAndColumns.header.columns')
      },
      {
        id: 'body',
        title: 'Body',
        showing: false,
        rows: this.get('rowsAndColumns.body.rows'),
        columns: this.get('rowsAndColumns.body.columns')
      },
      {
        id: 'footer',
        title: 'Footer',
        showing: false,
        rows: this.get('rowsAndColumns.footer.rows'),
        columns: this.get('rowsAndColumns.footer.columns')
      }
    ];
  }),
  prettyExampleJSON: Ember.computed('exampleJSON', function() {
    return Ember.String.htmlSafe(this.prettyJSON(JSON.stringify(this.get('exampleJSON'), null, 4)));
  }),
  styleString: Ember.computed('customButton.styleString', function() {
    var styleString = this.get('customButton.styleString');
    return Ember.String.htmlSafe(styleString);
  }),

  // Observers

  customButtonObserver: Ember.observer('customButton.{title,id,classNames,background_color,border_style,border_color,border_width,border_radius,color,font_size,height,width,padding,margin}', function() {
    var customButton = this.get('customButton');
    if (customButton && document.getElementById('customButtonPreview')) {
      this.send('previewCustomizedButton', customButton);
    }
  }),
  isHowToUseObserver: Ember.observer('isHowToUse', function() {
    if (this.get('isHowToUse')) {
      this.getURL();
    }
  }),

  // Functions

  deleteErrorMessage: function(field) {
    var unRemovableFields = this.get('unRemovableFields');
    var isCC = this.get('product.payment_method') === 'cc';
    if (unRemovableFields.indexOf(field.id) === -1) {
      return '';
    } else {
      switch(field.id) {
        case 'transaction_amount':
          return 'Transaction Amount field cannot be removed!';
        case 'cvv':
          return 'CVV field cannot be removed!';
        case 'account_number':
          return (isCC ? 'Card' : 'Account') + ' Number field cannot be removed!';
        case 'exp_date':
          return 'Exp Date field cannot be removed!';
        case 'captcha':
          return 'Captcha field cannot be removed!';
        case 'submit_button':
          return 'Submit Button cannot be removed!';
        case 'room_num':
          return 'Room Number field cannot be removed!';
        case 'room_rate':
          return 'Room Rate field cannot be removed!';
        case 'checkin_date':
          return 'Checkin Date field cannot be removed!';
        case 'checkout_date':
          return 'Checkout Date field cannot be removed!';
        case 'billing_street':
          return 'Billing Street field cannot be removed!';
        case 'billing_city':
          return 'Billing City field cannot be removed!';
        case 'billing_state':
          return 'Billing State field cannot be removed!';
        case 'billing_zip':
          return 'Billing Zip field cannot be removed!';
        case 'billing_phone':
          return 'Billing Phone field cannot be removed!';
        default:
          return '';
      }
    }

  },
  getCurrentFieldConfiguration: function(tableId) {
    var self = this;
    var rd = REDIPS.drag;
    var table = document.getElementById(tableId);
    if(!table) {
      return;
    }
    var divs = table.getElementsByTagName('DIV');
    var JSONOBJ = [];
    for (var j = 0; j < divs.length; j++) {
      var div = divs[j];
      if (div.className.indexOf('redips-drag') > -1) {
        var obj = {
          id: div.id,
        };
        var attributes = div.attributes;
        for (var prop in attributes) {
          if (attributes[prop].name === 'field_configuration_parameters') {
            var configArray = attributes[prop].value.split('|');
            obj = self.setSaveObj(configArray, obj);
          }
        }
        JSONOBJ.push(obj);
      }
    }
    var tableJSON = rd.saveContent(tableId, 'json') ? JSON.parse(rd.saveContent(tableId, 'json')) : [];
    var newJSON = JSONOBJ.map(function(obj) {
      tableJSON.map(function(o) {
        if (obj && obj.id === o[0]) {
          if (obj.position) {
            obj.position[0] = String(o[1]);
            obj.position[1] = String(o[2]);
          }
        }
      });
      return obj;
    });
    return newJSON;
  },
  getFieldHTML: function(field, isOptionField) {
    var unRemovableFields = this.get('unRemovableFields');
    if (isOptionField) {
      var optionFieldHTML = '<div class="redips-option-child">' + field.label;
      if (field.field_type === 'text' || field.field_type === 'heading' || field.field_type === 'image') {
        optionFieldHTML += ' <i class="far fa-eye" title="Display Only"></i>';
      }
      optionFieldHTML += '</div>';
      return optionFieldHTML;
    } else {
      var html = '<div class="redips-child">';
      if (field.field_type === 'heading') {
        html += '<h3>' + field.label + '</h3>';
      } else if (field.field_type === 'button') {
        html += '<button class="btn btn-info">' + field.label + '</button>';
      } else if (field.field_type === 'image') {
        html += '<img src="' + field.link + '" alt="IMAGE" class="redips-fakeimage">';
      } else if (field.field_type === 'captcha') {
        html += '<div style="max-width:256px;max-height:56px;height:46px;"><img src="/images/recaptcha.jpg" alt="reCAPTCHA" style="max-width:100%;max-height:70px;"></div>';
      } else {
        if (field.required || unRemovableFields.indexOf(field.id) > 0) {
          html += '<label class="control-label required redips-fakeinput-label">' + field.label + '</label>';
        } else {
          html += '<label class="control-label redips-fakeinput-label">' + field.label + '</label>';
        }
        if (!field.visible) {
          html += ' <i class="far fa-eye-slash" aria-hidden="true" title="Field Hidden on Form"></i><small><i> Hidden</i></small>';
        }
        if (field.readonly) {
          html += ' <i class="fas fa-book" aria-hidden="true" title="Field Readonly on Form"></i><small><i> Readonly</i></small>';
        }
      }
      html += '<div>';
      if (field.field_type === 'inputText' || field.field_type === 'inputDate') {
        html += '<div class="redips-fakeinput"></div>';
      } else if (field.field_type === 'inputSelect') {
        if (field.id === 'exp_date') {
          html += '<div class="redips-fakeselect-exp-date month">Month<i class="fas fa-caret-down pull-right" aria-hidden="true"></i></div><div class="redips-fakeselect-exp-date">Year<i class="fas fa-caret-down pull-right" aria-hidden="true"></i></div>';
        } else if (field.id === 'transaction_amount') {
          html += '<div><ul class="redips-fake-radio-ul">';
          field.options.forEach(function(option, index) {
            if (option === 'other') {
              html += '<li class="redips-fake-radio-li-other">';
              html += '<div class="redips-fake-radio-input-other" style="color:#ccc">Other</div></li>';
            } else {
              html += '<li class="redips-fake-radio-li">';
              var id = field.id + '_' + index;
              html += '<input class="redips-fake-radio-input" type="radio" value="' + option + '" name="' + field.id + '" id="' + id + '"/>';
              html += '<label class="redips-fake-radio-label" for="' + id + '">$' + option + '</label>';
              html += '</li>';
            }
          });
          html += '</ul></div>';
        } else {
          html += '<div class="redips-fakeselect text-right"><i class="fas fa-caret-down" aria-hidden="true"></i></div>';
        }
      } else if (field.field_type === 'inputCheckbox') {
        html += '<div class="redips-fakecheckbox"></div>';
      } else if (field.field_type === 'inputRadio') {
        html += '<div>';
        field.options.forEach(function(option, index) {
          var id = field.id + '_' + index;
          html += '<label style="margin-right:5px"><input type="radio" value="' + option + '" name="' + field.id + '" id="' + id + '" disabled/> ' + option + '</label>';
        });
        html += '</div>';
      }

      html += '</div>';
      html += '</div>';
      html += '<div class="config-icons">';
      var uneditableFields = ['captcha', 'terms_agree'];
      if (uneditableFields.indexOf(field.id) === -1) {
        html += '<div onclick="REDIPS.editField(\'' + field.id + '\')" class="text-center"><i class="fas fa-cog fa-1_3 config-icon" title="Edit Settings" aria-hidden="true"></i></div>';
      }
      if (unRemovableFields.indexOf(field.id) === -1) {
        html += '<div onclick="REDIPS.divDelete(\'' + field.id + '\')" class="text-center"><i class="far fa-trash-alt fa-1_3 config-icon" title="Delete Field" aria-hidden="true"></i></div>';
      } else {
        if (field.id !== 'captcha') {
          html += '<div class="text-center"><i class="fas fa-bolt" title="Cannot Remove" aria-hidden="true"></i></div>';
        }
      }
      if (field.field_type === 'text' || field.field_type === 'heading' || field.field_type === 'image') {
        html += '<div class="text-center"><i class="far fa-eye fa-1_3 config-icon" title="Display Only" aria-hidden="true"></i></div>';
      }
      html += '</div>';
      return html;
    }
  },
  getURL: function() {
    var self = this;
    var id = this.get('hpp.id');
    var session = this.get('session');
    this.set('isLoading', true);
    jQuery.ajax({
      url: window.UiENV.APP.FULL_URL + '/hostedpaymentpages/' + id + '/geturl',
      type: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'developer-id': window.UiENV.APP.DEVELOPER_ID,
        'access-token': session.get('authenticated.token')
      }
    }).then(function(data) {
      self.set('hppURL', data.hostedpaymentpage);
      self.set('isLoading', false);
    }, function() {
      self.set('isLoading', false);
      noty({
        text: 'Error getting Hosted Payment Page URL!',
        type: 'error'
      });
    });
  },
  isEmptyCell: function(id) {
    var el = document.getElementById(id);
    if (el.nodeName !== 'TD') {
      return undefined;
    } else {
      return {
        isEmpty: (el.childNodes.length === 0 || (el.childNodes.length === 1 && el.firstChild.nodeType === 3)),
        field: el.childNodes[0]
      };
    }
  },
  prettyJSON: function(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
      var cls = 'json-number-color';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'json-key-color';
        } else {
          cls = 'json-string-color';
        }
      } else if (/true|false/.test(match)) {
        cls = 'json-boolean-color';
      } else if (/null/.test(match)) {
        cls = 'json-null-color';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  },
  setDirty: function(val) {
    this.set('hppIsDirty', val);
  },
  setSaveObj: function(arr, obj) {
    arr.forEach(function(config) {
      var c = config.split('::');
      var prop = c[0];
      var value = c[1];
      var booleanProps = ['required', 'readonly', 'visible'];
      if (prop === 'position' || prop === 'options') {
        obj[prop] = value.split(',');
      } else if (booleanProps.indexOf(prop) !== -1) {
        if (value === 'true') {
          obj[prop] = true;
        } else {
          obj[prop] = false;
        }
      } else {
        if (value === 'true') {
          value = true;
        } else if(value === 'false') {
          value = false;
        } else if (value === 'null') {
          value = null;
        }
        obj[prop] = (obj.id === 'billing_zip' && prop === 'value' && value) ? value.replace(/\s/g, '') : value;
      }
    });
    return obj;
  },
  updateEditField: function(field) {
    var self = this;
    this.updateInnerHtml(field);
    Ember.run.later(function() {
      var area = self.get('activeSection');
      var fields = self.getCurrentFieldConfiguration('table-' + area + '-table');
      self.send('loadForm', area, fields, false);
    });
    this.setDirty(true);
  },
  updateInnerHtml: function(field, isOptionField) {
    var self = this;
    var html = this.getFieldHTML(field, isOptionField);
    Ember.run.later(function() {
      var thisField = jQuery('#' + field.id);
      if (field.shortname) {
        thisField.attr('id', field.shortname);
        field.id = field.shortname;
        delete field.shortname;
        delete field.temp_shortname;
      }
      thisField.html(html);
      var fieldConfigArray = [];
      for (var prop in field) {
        fieldConfigArray.push(prop + '::' + field[prop]);
      }
      var fieldConfigString = fieldConfigArray.join('|');
      thisField.attr('field_configuration_parameters', fieldConfigString);
      self.updatePreviewHPP();
    });
  },
  updatePreviewHPP: function() {
    var self = this;
    var fieldSections = this.get('fieldSections');
    fieldSections.map(function(section) {
      var sectionId = section.id;
      var fields = self.getCurrentFieldConfiguration('table-' + sectionId + '-table');
      var rows = self.get('rowsAndColumns.' + sectionId + '.rows');
      var columns = self.get('rowsAndColumns.' + sectionId + '.columns');
      previewHPP.field_configuration[sectionId].fields = fields;
      previewHPP.field_configuration[sectionId].settings.rows = rows;
      previewHPP.field_configuration[sectionId].settings.columns = columns;
    });
    return previewHPP;
  },

  // Actions

  actions: {
    checkThreshold: function (data) {
      let threshold = parseFloat(this.get('hpp.recaptcha_score_threshold'));
      if (threshold) {
        threshold = threshold.toFixed(1);
        this.set('hpp.recaptcha_score_threshold', threshold);
      }
    },
    addCol: function() {
      if (this.get('isAddingRowOrColumn')) {
        return;
      }
      this.updatePreviewHPP();
      this.set('isAddingRowOrColumn', true);
      var self = this;
      var area = this.get('activeSection');
      var tableId = 'table-' + area + '-table';
      var cols = this.get('rowsAndColumns.' + area + '.columns');
      var JSONOBJ = this.getCurrentFieldConfiguration(tableId);
      this.set('rowsAndColumns.' + area + '.columns', cols + 1);
      this.send('makeSectionActive', area);
      Ember.run.later(function() {
        self.send('loadForm', area, JSONOBJ);
      });
      this.setDirty(true);
    },
    addOption: function(id, custom, pos) {
      var rd = REDIPS.drag;
      var area = this.get('activeSection');
      var options = custom ? this.get('custom_options') : this.get('field_options');
      var fields = previewHPP.field_configuration.header.fields;
      fields = fields.concat(previewHPP.field_configuration.body.fields);
      fields = fields.concat(previewHPP.field_configuration.footer.fields);
      var addOption;
      options.map(function(option) {
        if (id.indexOf(option.id) !== -1) {
          var addOptionId;
          var nextOfThisType = 1;
          if (option.id.indexOf('option_') > -1) {
            addOptionId = option.id.replace('option_', '');
          } else if (option.id.indexOf('customfield_') > -1) {
            var nonCustomDataFields = ['customfield_text_display', 'customfield_heading_display', 'customfield_image_display'];
            if (nonCustomDataFields.indexOf(option.id) > -1) {
              addOptionId = option.id.replace('customfield_', '') + '_';
            } else {
              addOptionId = option.id.replace('customfield_', 'custom_data-');
            }
            fields.map(function(field) {
              if (field.field_type === option.field_type && (field.id.indexOf('custom_data-') > -1 || field.id.indexOf(addOptionId) > -1)) {
                nextOfThisType = nextOfThisType + 1;
              }
            });
            addOptionId += nextOfThisType;
            fields.map(function(field) {
              if (field.id === addOptionId) {
                addOptionId = addOptionId + '_';
              }
            });
            var notUniqueId = true;
            while(notUniqueId) {
              checkUnique(fields, addOptionId, notUniqueId);
              if(notUniqueId) {
                break;
              }
            }
          }
          addOption = {
            temp_shortname: addOptionId,
            shortname: addOptionId,
            id: addOptionId,
            field_type: option.field_type,
            label: option.label,
            link: option.link,
            required: option.required || false,
            readonly: false,
            visible: true,
            isNew: true,
            value: option.field_type === 'inputCheckbox' ? false : null,
            position: [null, null, '1', '1'],
            options: null
          };
          rd.init('table-' + area);
        }
      });
      function checkUnique(fields, addOptionId, notUniqueId) {
        fields.map(function(field) {
          if (field.id === addOptionId) {
            addOptionId = addOptionId + '_';
            notUniqueId = false;
          }
        });
      }
      var tableId = 'table-' + area + '-table';
      var xPos = pos[1];
      var yPos = pos[2];
      addOption.position = [xPos, yPos, 1, 1];
      fields.pushObject(addOption);
      previewHPP.field_configuration[area].fields = fields;
      var arr = [addOption.id, xPos, yPos, '', ''];
      this.updateInnerHtml(addOption);
      rd.loadContent(tableId, [arr]);
      this.setDirty(true);
      var self = this;
      Ember.run.later(function() {
        self.updatePreviewHPP();
        self.send('makeSectionActive', area);
      });
    },
    addRow: function() {
      if (this.get('isAddingRowOrColumn')) {
        return;
      }
      this.updatePreviewHPP();
      this.set('isAddingRowOrColumn', true);
      var self = this;
      var area = this.get('activeSection');
      var tableId = 'table-' + area + '-table';
      var rows = this.get('rowsAndColumns.' + area + '.rows');
      var JSONOBJ = this.getCurrentFieldConfiguration(tableId);
      this.set('rowsAndColumns.' + area + '.rows', rows + 1);
      this.send('makeSectionActive', area);
      Ember.run.later(function() {
        self.send('loadForm', area, JSONOBJ);
      });
      this.setDirty(true);
    },
    cancel: function() {
      if(this.get('isTransitioning')) {
        return;
      }
      var hostedpaymentpage = this.get('hpp');
      var sender = this.get('sender');
      if (sender === 'add' && hostedpaymentpage) {
        hostedpaymentpage.deleteRecord();
      } else if (sender === 'view' && hostedpaymentpage) {
        hostedpaymentpage.rollbackAttributes();
      }
      this.send('resetCustomButton');
      this.get('router').transitionTo('locations.hpp-index', this.get('location.id'));
    },
    copyToClipboard: function(value, successMessage, failureMessage) {
      var textArea = document.createElement('textarea');
      if (value) {
        textArea.value = value.trim();
      }
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        noty({
          text: successMessage || 'Hosted Payment Page URL copied!'
        });
      } catch (err) {
        noty({
          text: failureMessage || 'Unable to copy Hosted Payment Page URL!',
          type: 'error'
        });
      }
      document.body.removeChild(textArea);
    },
    copyToClipboardHTML: function(id) {
      var value = document.getElementById(id).innerHTML.replace('onclick="return false;"', '').replace('&amp;', '&');

      this.send('copyToClipboard', value, 'Button HTML copied!', 'Unable to copy Button HTML!');
    },
    customizeButton: function() {
      this.set('showButtonCustomizeSettings', true);
      this.set('useAsButtonType', 'Custom');
    },
    deleteColumn: function(index, tableId) {
      var self = this;
      var rt = REDIPS.table;
      var area = this.get('activeSection');
      var rows = this.get('rowsAndColumns.' + area + '.rows');
      var cols = this.get('rowsAndColumns.' + area + '.columns');
      var isEmpty = true;
      var fieldsInColumn = [];
      for (var i = 0; i < rows; i++) {
        fieldsInColumn.push(this.isEmptyCell(area + '-' + i + '-' + index));
      }
      var message = '';
      fieldsInColumn.forEach(function(field) {
        if (!field.isEmpty) {
          var fieldMessage = self.deleteErrorMessage(field.field);
          message += fieldMessage + (fieldMessage ? '<br>' : '');
          isEmpty = false;
        }
      });
      if (isEmpty) {
        rt.column(tableId, 'delete', index);
        var JSONOBJ = this.getCurrentFieldConfiguration(tableId);
        this.set('rowsAndColumns.' + area + '.columns', cols - 1);
        this.send('makeSectionActive', area);
        Ember.run.later(function() {
          self.send('loadForm', area, JSONOBJ);
        });
      } else {
        noty({
          text: message + 'Column must be empty before deleting!',
          type: 'warning',
          timeout: '5000'
        });
      }
      this.setDirty(true);
      this.updatePreviewHPP();
    },
    deleteField: function(fieldId) {
      this.updatePreviewHPP();
      var area = this.get('activeSection');
      var fields = this.get('hpp.field_configuration.' + area + '.fields');
      var index;
      fields.map(function(field, i) {
        if (fieldId === field.id) {
          index = i;
          Ember.set(field, 'tempDeleted', true);
        }
      });
      this.set('hpp.field_configuration.' + area + '.fields', fields);
      this.send('makeSectionActive', area);
      this.setDirty(true);
    },
    deleteForm: function() {
      var self = this;
      var hostedpaymentpage = this.get('hpp');
      pretty_confirm('Are you sure you want to delete this Hosted Payment Page?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        hostedpaymentpage.deleteRecord();
        hostedpaymentpage.save().then(function() {
          hostedpaymentpage.unloadRecord();
          noty({
            text: 'Hosted Payment Page deleted successfully!'
          });
          self.set('isLoading', false);
          self.get('router').transitionTo('locations.hpp-index', self.get('location.id'));
        }, function() {
          hostedpaymentpage.rollbackAttributes();
          noty({
            text: 'Error deleting Hosted Payment Page!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    deleteRow: function(index, tableId) {
      var self = this;
      var rt = REDIPS.table;
      var area = this.get('activeSection');
      var rows = this.get('rowsAndColumns.' + area + '.rows');
      var cols = this.get('rowsAndColumns.' + area + '.columns');
      var isEmpty = true;
      var fieldsInRow = [];
      for (var i = 0; i < cols; i++) {
        fieldsInRow.push(this.isEmptyCell(area + '-' + index + '-' + i));
      }
      var message = '';
      fieldsInRow.forEach(function(field) {
        if (!field.isEmpty) {
          var fieldMessage = self.deleteErrorMessage(field.field);
          message = message += fieldMessage + (fieldMessage ? '<br>' : '');
          isEmpty = false;
        }
      });
      if (isEmpty) {
        rt.row(tableId, 'delete', index);
        var JSONOBJ = this.getCurrentFieldConfiguration(tableId);
        this.set('rowsAndColumns.' + area + '.rows', rows - 1);
        this.send('makeSectionActive', area);
        Ember.run.later(function() {
          self.send('loadForm', area, JSONOBJ);
        });
      } else {
        noty({
          text: message + 'Row must be empty before deleting!',
          type: 'warning'
        });
      }
      this.setDirty(true);
      this.updatePreviewHPP();
    },
    editField: function(fieldId) {
      var formBuilderEditFieldController = this.get('formBuilderEditFieldController');
      var product = this.get('product');
      var fieldAttributes = document.getElementById(fieldId).attributes;
      var self = this;
      var editField = {};
      for (var prop in fieldAttributes) {
        if (fieldAttributes[prop].name === 'field_configuration_parameters') {
          var configArray = fieldAttributes[prop].value.split('|');
          editField = self.setSaveObj(configArray, editField);
        }
      }
      formBuilderEditFieldController.set('includeOther', null);
      if (editField.options && editField.options.length > 0) {
        var includeOther = false;
        editField.options = editField.options.filter(function(option) {
          if (option === 'other') {
            includeOther = true;
          } else {
            if (option !== 'null' && option !== '') {
              return true;
            }
          }
        });
        formBuilderEditFieldController.set('includeOther', includeOther);
      }
      var fields = previewHPP.field_configuration.header.fields;
      fields = fields.concat(previewHPP.field_configuration.body.fields);
      fields = fields.concat(previewHPP.field_configuration.footer.fields);
      if(editField.id.indexOf('custom_data') >= 0) {
        editField.temp_shortname = editField.id.replace('custom_data-', '');
        editField.shortname = editField.id;
      }
      var fieldIndex = -1;
      fields.forEach(function(f,i){
        if(f.id === editField.id) {
          fieldIndex = i;
        }
      });
      formBuilderEditFieldController.set('optionAdd', '');
      formBuilderEditFieldController.set('errorMessages', null);
      formBuilderEditFieldController.set('field', null);
      formBuilderEditFieldController.set('fields', null);
      formBuilderEditFieldController.set('fieldIndex', null);
      formBuilderEditFieldController.set('product', null);
      formBuilderEditFieldController.set('context', this);
      formBuilderEditFieldController.set('sender', this.get('sender'));
      formBuilderEditFieldController.set('field', JSON.parse(JSON.stringify(editField)));
      formBuilderEditFieldController.set('fields', fields);
      formBuilderEditFieldController.set('fieldIndex', fieldIndex);
      formBuilderEditFieldController.set('product', product);
      this.get('parent').send('openModal', 'components/modals/formbuildereditfield');
    },
    editForm: function(action) {
      var self = this;
      var area = self.get('activeSection');
      this.set('isPreviewing', false);
      this.set('isHowToUse', false);
      this.set('returningFromPreview', true);
      var header = this.get('hppHeader');
      var body = this.get('hppBody');
      var footer = this.get('hppFooter');
      if (action !== 'back' && header && body && footer) {
        previewHPP.field_configuration.header.settings = header.settings;
        previewHPP.field_configuration.header.fields = header.fields;
        previewHPP.field_configuration.body.settings = body.settings;
        previewHPP.field_configuration.body.fields = body.fields;
        previewHPP.field_configuration.footer.settings = footer.settings;
        previewHPP.field_configuration.footer.fields = footer.fields;

        var fieldSections = this.get('fieldSections');
        fieldSections.map(function(section) {
          var area = section.id;
          Ember.run.later(function() {
            if (area === 'header') {
              self.send('loadForm', area, header.fields);
            } else if (area === 'body') {
              self.send('loadForm', area, body.fields);
            } else if (area === 'footer') {
              self.send('loadForm', area, footer.fields);
            }
          });
        });
        Ember.run.later(function() {
          self.send('loadForm', 'field');
          var fields;
          if (area === 'header') {
            fields = header.fields;
          } else if (area === 'body') {
            fields = body.fields;
          } else if (area === 'footer') {
            fields = footer.fields;
          }
          self.send('makeSectionActive', area, fields);
        });
      } else {
        Ember.run.later(function() {
          self.send('makeSectionActive', area);
        });
      }
      this.set('returningFromPreview', false);
    },
    fieldDropped: function(fieldId, position) {
      var self = this;
      if (fieldId.indexOf('customfield_') !== -1) {
        this.send('addOption', fieldId, true, position);
      } else if (fieldId.indexOf('option_') !== -1) {
        this.send('addOption', fieldId, false, position);
      } else {
        var field = jQuery('#' + fieldId);
        var attributes = field[0].attributes;
        var obj = {};
        for (var prop in attributes) {
          if (attributes[prop].name === 'field_configuration_parameters') {
            var configArray = attributes[prop].value.split('|');
            obj = self.setSaveObj(configArray, obj);
          }
        }
        obj.position.splice(0, 1, position[1]);
        obj.position.splice(1, 1, position[2]);
        var fieldConfigArray = [];
        for (var p in obj) {
          fieldConfigArray.push(p + '::' + obj[p]);
        }
        var fieldConfigString = fieldConfigArray.join('|');
        field.attr('field_configuration_parameters', fieldConfigString);
      }
      this.setDirty(true);
      Ember.run.later(function() {
        self.updatePreviewHPP();
      });
    },
    loadForm: function(area, fieldsJSON, resetForm) {
      var rd = REDIPS.drag;
      var self = this;
      var tableId = 'table-' + area + '-table';
      var fields;
      var contentArray;
      if (area === 'field' || area === 'custom') {
        fields = this.get(area + '_options');
        contentArray = fields.map(function(field, index) {
          var className = 'redips-option';
          if (field.isCloneable) {
            className += ' redips-clone';
          }
          var arr = [field.id, index, 0, className, field.label];
          self.updateInnerHtml(field, true);
          return arr;
        });
      } else if (fieldsJSON) {
        contentArray = fieldsJSON.map(function(field) {
          var arr = [field.id, field.position[0], field.position[1], '', ''];
          self.updateInnerHtml(field);
          return arr;
        });
      } else {
        fields = previewHPP.field_configuration[area].fields;
        if (resetForm) {
          fields = this.get('hpp.field_configuration.' + area + '.fields');
          fields = fields.filter(function(field) {
            return !field.isNew;
          });
          previewHPP.field_configuration[area].fields = fields;
        }
        contentArray = fields.map(function(field) {
          var arr = [field.id, field.position[0], field.position[1], '', ''];
          self.updateInnerHtml(field);
          return arr;
        });
      }
      rd.clearTable('table-' + area + '-table');
      rd.loadContent(tableId, contentArray);
      rd.init();
      Ember.run.later(function() {
        self.set('isAddingRowOrColumn', false);
      }, 500);
    },
    makeSectionActive: function(id, fieldsJSON, dontClear) {
      var self = this;
      var fieldSections = this.get('fieldSections');
      fieldSections.map(function(section) {
        if (section.id === id) {
          Ember.set(section, 'showing', true);
          self.set('activeSection', id);
        } else {
          Ember.set(section, 'showing', false);
        }
      });
      var rd = REDIPS.drag;
      var options = this.get('field_options');
      var fields = fieldsJSON ? fieldsJSON : previewHPP.field_configuration[id].fields;
      options = options.filter(function(option) {
        var isAvailable = true;
        fields.map(function(item) {
          if ('option_' + item.id === option.id) {
            if (!item.tempDeleted) {
              isAvailable = false;
            }
          }
        });
        return isAvailable;
      });
      if (!dontClear) {
        rd.clearTable('table-field-table');
        self.set('fieldOptionsLength', options.get('length'));
        Ember.run.later(function() {
          var contentArray = options.map(function(field, index) {
            var className = 'redips-option';
            if (field.isCloneable) {
              className += ' redips-clone';
            }
            var arr = [field.id, index, 0, className, field.label];
            self.updateInnerHtml(field, true);
            return arr;
          });
          rd.loadContent('table-field-table', contentArray);
          rd.init();
        });
      }
    },
    previewCustomizedButton: function(customButton) {
      this.set('customButton.styleString', '');
      var buttonContainer = document.getElementById('customButtonPreview');
      var buttonElement = buttonContainer.childNodes[0];
      var styleString = '';
      for (var prop in customButton) {
        if (customButton.hasOwnProperty(prop)) {
          var ignoreProps = ['styleString', 'title', 'openInNewTab'];
          var value = customButton[prop];
          if (prop === 'id') {
            buttonElement.id = value;
          } else if (prop === 'classNames') {
            buttonElement.class = value;
          } else if (ignoreProps.indexOf(prop) === -1) {
            var propString = prop.slice().replace('_', '-');
            switch (propString) {
              case 'height':
              case 'width':
              case 'padding':
              case 'margin':
              case 'border-radius':
              case 'font-size':
              case 'border-width':
                value += 'px';
                break;
            }
            styleString += propString + ':' + value + ';';
          }
        }
      }
      this.set('customButton.styleString', Ember.String.htmlSafe(styleString));
    },
    previewForm: function() {
      var self = this;
      var fieldSections = this.get('fieldSections');
      fieldSections.map(function(section) {
        var sectionId = section.id;
        var fields = self.getCurrentFieldConfiguration('table-' + sectionId + '-table');
        var rows = self.get('rowsAndColumns.' + sectionId + '.rows');
        var columns = self.get('rowsAndColumns.' + sectionId + '.columns');
        previewHPP.field_configuration[sectionId].fields = fields;
        previewHPP.field_configuration[sectionId].settings.rows = rows;
        previewHPP.field_configuration[sectionId].settings.columns = columns;

      });
      var header = previewHPP.field_configuration.header;
      this.set('hppHeader', header);
      var body = previewHPP.field_configuration.body;
      this.set('hppBody', body);
      var footer = previewHPP.field_configuration.footer;
      this.set('hppFooter', footer);
      var stack = previewHPP.field_configuration.stack;
      this.set('hppStack', stack);
      var mini = this.get('compactView');
      this.set('hppMini', mini);
      this.set('isPreviewing', true);
      this.set('returningFromPreview', true);
    },
    resetCustomButton: function() {
      var customButton = {
        title: 'Pay Now',
        background_color: '#428bca',
        border_color: '#357ebd',
        border_radius: 5,
        border_width: 0,
        border_style: 'none',
        font_size: 12,
        color: '#ffffff',
        height: 30,
        width: 100,
        margin: 0,
        padding: 7,
        text_decoration: 'none',
        text_align: 'center',
        display: 'inline-block',
        styleString: 'display:inline-block;background-color:#428bca;border-color:#357ebd;border-radius:5px;border-width:0;border-style:none;color:#ffffff;font-size:12px;height:30px;width:100px;margin:0px;padding:7px;text-decoration:none;text-align:center'
      };
      this.set('customButton', customButton);
    },
    resetForm: function() {
      var rd = REDIPS.drag;
      var self = this;
      var sections = this.get('fieldSections');
      sections.map(function(section) {
        rd.clearTable('table-' + section.id + '-table');
        var fields = self.get('hpp.field_configuration.' + section.id + '.fields');
        fields.map(function(field) {
          Ember.set(field, 'tempDeleted', false);
        });
        self.set('hpp.field_configuration.' + section.id + '.fields', fields);
        self.send('loadForm', section.id, null, true);
      });
      this.set('rowsAndColumns.header.rows', this.get('hpp.field_configuration.header.settings.rows'));
      this.set('rowsAndColumns.header.columns', this.get('hpp.field_configuration.header.settings.columns'));
      this.set('rowsAndColumns.body.rows', this.get('hpp.field_configuration.body.settings.rows'));
      this.set('rowsAndColumns.body.columns', this.get('hpp.field_configuration.body.settings.columns'));
      this.set('rowsAndColumns.footer.rows', this.get('hpp.field_configuration.footer.settings.rows'));
      this.set('rowsAndColumns.footer.columns', this.get('hpp.field_configuration.footer.settings.columns'));
      previewHPP = JSON.parse(JSON.stringify(this.get('hpp')));
      this.send('makeSectionActive', this.get('activeSection'));
      this.setDirty(false);
    },
    saveForm: function(redirect) {
      var self = this;
      var fieldSections = this.get('fieldSections');
      var header_fields = [];
      var body_fields = [];
      var footer_fields = [];
      fieldSections.map(function(field) {
        var tableId = 'table-' + field.id + '-table';
        var table = document.getElementById(tableId);
        var divs = table.getElementsByTagName('DIV');
        var JSONOBJ = [];
        for (var i = 0; i < divs.length; i++) {
          var div = divs[i];
          if (div.className.indexOf('redips-drag') > -1) {
            var obj = {
              id: div.id,
            };
            var attributes = div.attributes;
            for (var prop in attributes) {
              if (attributes[prop].name === 'field_configuration_parameters') {
                var configArray = attributes[prop].value.split('|');
                obj = self.setSaveObj(configArray, obj);
              }
            }
            delete obj.isNew;
            delete obj.shortname;
            JSONOBJ.push(obj);
          }
        }
        if (field.id === 'header') {
          header_fields = JSONOBJ;
        } else if (field.id === 'body') {
          body_fields = JSONOBJ;
        } else if (field.id === 'footer') {
          footer_fields = JSONOBJ;
        }
      });
      var tempHeaderFields = this.get('hpp.field_configuration.header.fields');
      var tempBodyFields = this.get('hpp.field_configuration.body.fields');
      var tempFooterFields = this.get('hpp.field_configuration.footer.fields');
      let threshold = this.get('hpp.recaptcha_score_threshold') || 0.5;
      this.set('hpp.recaptcha_score_threshold', threshold);
      this.set('hpp.field_configuration.header.fields', header_fields);
      this.set('hpp.field_configuration.body.fields', body_fields);
      this.set('hpp.field_configuration.footer.fields', footer_fields);
      this.set('hpp.field_configuration.header.settings.rows', this.get('rowsAndColumns.header.rows'));
      this.set('hpp.field_configuration.header.settings.columns', this.get('rowsAndColumns.header.columns'));
      this.set('hpp.field_configuration.body.settings.rows', this.get('rowsAndColumns.body.rows'));
      this.set('hpp.field_configuration.body.settings.columns', this.get('rowsAndColumns.body.columns'));
      this.set('hpp.field_configuration.footer.settings.rows', this.get('rowsAndColumns.footer.rows'));
      this.set('hpp.field_configuration.footer.settings.columns', this.get('rowsAndColumns.footer.columns'));
      this.set('hpp.field_configuration.css_mini', this.get('compactView'));
      if (this.get('hpp.parent_send_message')) {
        this.set('hpp.parent_send_message', 1);
      } else {
        this.set('hpp.parent_send_message', 0);
      }
      var hostedpaymentpage = this.get('hpp');
      this.set('isLoading', true);
      hostedpaymentpage.save().then(function() {
        if (redirect) {
          noty({
            text: 'Hosted Payment Page created successfully!'
          });
          self.get('router').transitionTo('locations.hpp-index', self.get('location.id'));
        }
        self.setDirty(false);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error saving Hosted Payment Page!',
          type: 'error'
        });
        hostedpaymentpage.set('field_configuration.header.fields', tempHeaderFields);
        hostedpaymentpage.set('field_configuration.body.fields', tempBodyFields);
        hostedpaymentpage.set('field_configuration.footer.fields', tempFooterFields);
        self.set('isLoading', false);
      });
    },
    showEncryptionKey: function() {
      this.set('shownEncryptionKey', true);
    },
    showHideFormConfig: function() {
      this.toggleProperty('showingFormConfig');
    },
    showHideButtonConfig: function() {
      this.toggleProperty('showingButtonConfig');
    },
    useForm: function() {
      this.send('saveForm', false);
      this.send('resetCustomButton');
      this.set('isHowToUse', true);
    },
  }
});
