import Ember from 'ember';

export default Ember.TextField.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  type: 'file',
  attributeBindings: ['name'],
  change: function(evt) {
    var input, reader;
    input = evt.target;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = (function(_this) {
        return function(e) {
          var uploadedFile;
          uploadedFile = e.srcElement.result;
          return _this.action(uploadedFile);
        };
      })(this);
      return reader.readAsText(input.files[0]);
    }
  }
});
