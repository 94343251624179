import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  addcardtoaccountvaultController: Ember.inject.controller('components/modals/addcardtoaccountvault'),
  applicationData: Ember.inject.service('application-data'),
  completeController: Ember.inject.controller('components/modals/complete'),
  incrementController: Ember.inject.controller('components/modals/increment'),
  postbacklogController: Ember.inject.controller('components/modals/postbacklog'),
  refundController: Ember.inject.controller('components/modals/refund'),
  runagainController: Ember.inject.controller('components/modals/runagain'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),
  tipadjustController: Ember.inject.controller('components/modals/tipadjust'),
  transactionEditController: Ember.inject.controller('components/modals/transactionedit'),
  voidController: Ember.inject.controller('components/modals/void'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  needsRefresh: '',
  updateRefundHistory: false,
  convenience_fee:0,
  showConvenienceFee:false,
  needsRefreshObserve: Ember.observer('needsRefresh', function() {
    this.send('updateTransaction', {
      id: this.get('transaction.id')
    });
  }),

  isRefundVisible: Ember.computed(function() {
    var transaction = this.get('transaction');
    var type_id = transaction.get('type.id');
    var status_id = transaction.get('status.id');
    return type_id === '20' && status_id === '101';
  }),
  tipAdjustTrxSubtotal: Ember.computed('transaction.transaction_amount', 'transaction.tip_amount', 'transaction.tax', function() {
    var amount = parseFloat(this.get('transaction.transaction_amount')) || 0;
    var tip = parseFloat(this.get('transaction.tip_amount')) || 0;
    var tax = parseFloat(this.get('transaction.tax')) || 0;
    return amount - tip - tax;
  }),
  canAddOrRemoveQuickInvoice: Ember.computed('product.isLodging', 'session.authenticated.resources.v2-quickinvoices-put-addtransaction', 'session.authenticated.resources.v2-quickinvoices-put-removetransaction', 'transaction.status_id', function() {
    var status_id = Number(this.get('transaction.status_id'));
    var type_id = Number(this.get('transaction.type_id'));
    var trans_statuses = [101, 131, 132, 133, 134, 136];
    var isACH = trans_statuses.indexOf(status_id) !== -1 && status_id !== 101;
    var isValidTransStatus = trans_statuses.indexOf(status_id) !== -1;
    var hasPriv = this.get('session.authenticated.resources.v2-quickinvoices-put-addtransaction') || this.get('session.authenticated.resources.v2-quickinvoices-put-removetransaction');
    var productAllows = !this.get('product.isLodging');
    if (isACH) {
      return type_id === 50 ? productAllows && hasPriv : false;
    } else {
      return isValidTransStatus && productAllows && hasPriv;
    }
  }),
  canComplete: Ember.computed('transaction.is_completable', 'session.authenticated.resources.v2-transactions-put-authcomplete', function() {
    return this.get('transaction.is_completable') && this.get('session.authenticated.resources.v2-transactions-put-authcomplete');
  }),
  canIncrement: Ember.computed('transaction.is_completable', 'product.industry_type', 'session.authenticated.resources.v2-transactions-put-authincrement', function() {
    return this.get('transaction.is_completable') && this.get('product.industry_type') === 'lodging' && this.get('session.authenticated.resources.v2-transactions-put-authincrement');
  }),
  canLevel3: Ember.computed('transaction.is_level3_eligible', 'product.level3_allow', 'session.authenticated.resources.v2-transactionlevel3s-get', function() {
    return this.get('transaction.is_level3_eligible') && this.get('product.level3_allow') && this.get('session.authenticated.resources.v2-transactionlevel3s-get');
  }),
  canOverride: Ember.computed('transaction.reason_code_id', 'session.authenticated.resources.v2-transactions-post-override', function() {
    return this.get('transaction.reason_code_id') === '1663' && this.get('session.authenticated.resources.v2-transactions-post-override');
  }),
  canRefund: Ember.computed('transaction.{is_refundable,isTransRefundable}', 'session.authenticated.resources.v2-transactions-post-refund', 'product.isSubProcessorPaya', function() {
    var isRefund1 = this.get('transaction.is_refundable');
        // isRefund1 = true;
    var isRefund2 = this.get('transaction.isTransRefundable');
    var hasPriv = this.get('session.authenticated.resources.v2-transactions-post-refund');
    var isPaya = this.get('product.isSubProcessorPaya');
    if ( isPaya ) {
      var terminals = this.get('product.processor_data.terminals');
      var hasRefund = false;
      if ( terminals ) {
        terminals.map(function(t){
          if(t.actions.indexOf('refund')){
            hasRefund = true;
          }
        });
      }
      return isRefund1 && isRefund2 && hasPriv && hasRefund;
    } else {
      return isRefund1 && isRefund2 && hasPriv;
    }
  }),
  canSavePaymentMethod: Ember.computed('locationinfo.hasProductAccountVault', 'session.authenticated.resources.v2-accountvaults-post', function() {
    return this.get('locationinfo.hasProductAccountVault') && this.get('session.authenticated.resources.v2-accountvaults-post');
  }),
  canTipAdjust: Ember.computed('transaction.is_voidable', 'product.canTipAdjust', 'session.authenticated.resources.v2-transactions-put-tipadjust', function() {
    return this.get('transaction.is_voidable') && this.get('product.canTipAdjust') && this.get('session.authenticated.resources.v2-transactions-put-tipadjust');
  }),
  canVoid: Ember.computed('transaction.is_voidable', 'session.authenticated.resources.v2-transactions-put-void', function() {
    return this.get('transaction.is_voidable') && this.get('session.authenticated.resources.v2-transactions-put-void');
  }),
  productAllowsL3DataButTransactionIsNotEligible: Ember.computed('transaction.is_level3_eligible', 'product.level3_allow', function() {
    var transactionIsEligible = this.get('transaction.is_level3_eligible');
    var productAllowsLevel3Data = this.get('product.level3_allow');
    return !transactionIsEligible && productAllowsLevel3Data;
  }),

  surchargeLabel: Ember.computed('product.surcharge.id', function() {
    if (this.get('product.surcharge.id') && this.get('product.surcharge.surcharge_label')) {
      return this.get('product.surcharge.surcharge_label');
    } else {
      return 'Convenience Fee';
    }
  }),
  showSurcharge: Ember.computed('product.surcharge', 'transaction.action', function() {
    return this.get('product.surcharge.id') && (this.get('transaction.action') !== 'refund' || this.get('product.surcharge.refund_surcharges'));
  }),
  showTax: Ember.computed('product.vt_enable_sales_tax', function() {
    return (this.get('product.vt_enable_sales_tax') ? true : false);
  }),
  showTip: Ember.computed('product.hasTip', function() {
    return (this.get('product.hasTip') ? true : false);
  }),
  showSubtotal: Ember.computed('showSurcharge', 'showTax', 'showTip', function() {
    return ((this.get('showSurcharge') || this.get('showTax') || this.get('showTip')) ? true : false);
  }),
  showCheckDetails: Ember.computed('transaction.ach_sec_code', function() {
    return this.get('transaction.ach_sec_code') === 'C21';
  }),
  trxPaymentSource: Ember.computed('transaction.trx_source_id', 'applicationData.trans_sources.[]', function() {
    var trx_source_id = this.get('transaction.trx_source_id') || '1';
    var trans_sources = this.get('applicationData.trans_sources');
    var temp = trans_sources.filter(function(source) {
      return String(source.id) === String(trx_source_id);
    })[0];
    return temp.title;
  }),

  refundHistory: Ember.observer('transaction.id', 'updateRefundHistory', async function() {
    const originalTransactionId = this.get('transaction.id');
    if(originalTransactionId || this.get('updateRefundHistory')) {
      const refundRecords = await this.getPreviousTransaction(originalTransactionId);
      this.set('refundRecords', refundRecords);
      this.set('updateRefundHistory', false);
    }
  }),

  calculateSurchargeObserver: Ember.observer('transaction.{id,transaction_amount,subtotal_amount}','locations',
  function(){
    var subtotal = this.get('transaction.subtotal_amount');
    var transactionAmount = this.get('transaction.transaction_amount');
    var tax = this.get('transaction.tax')?this.get('transaction.tax'):0;

    var surcharge_fee = 0;
    if(subtotal){
      this.set('showConvenienceFee',true);
      surcharge_fee = transactionAmount - +subtotal - +tax;
    }else if(this.get('transaction.surcharge.id')){
      this.set('showConvenienceFee',true);
      surcharge_fee = this.get('transaction.surcharge.surcharge_amount');
    }else{
      this.set('showConvenienceFee',false);
      surcharge_fee = 0;
    }

    this.set(
      'convenience_fee',
      surcharge_fee
      ?surcharge_fee.round(2)
      :0);
  }),
  
  getPreviousTransaction: function(originalTransactionId) {
    return new Promise((resolve, reject) => {
      this.get('store').query('transaction', {
        previous_transaction_id: originalTransactionId,
      }).then(data => {
        var prevData = data.map(item => {
          var value = this.applicationData.trans_statuses.filter(x=>x.id===item.get('status_id'));
          return {
            date: item.get('created_ts'),
            auth_amount: item.get('auth_amount'),
            status: value[0].title
          }
        });
        resolve(prevData);
      }).catch(error => {
        console.error("Error:", error);
        reject('ERROR');
      });
    });
  },

  // Observers

  currentSectionObserver: Ember.observer('currentSection.action', function() {
    var currentSection = this.get('currentSection.action');
    if(currentSection === 'apilogs') {
      this.getLogApis();
    } else if(currentSection === 'postbacks') {
      this.getPostbakLogs();
    } else if(currentSection === 'processordata') {
      this.getProcessorData();
    }
    if(currentSection !== 'apilogs') {
      this.logapisCollapseActions(false);
    }
  }),

  logapisObserver: Ember.observer('logapis.[]', function() {
    this.logapisCollapseActions(true);
  }),

  // Functions

  getPostbakLogs: function() {
    var self = this;
    if(this.get('session.authenticated.resources.v2-postbacklogs-get')){
      this.set('isLoading', true);
      this.get('store').query('postbacklog', {
        model_id: self.get('transaction.id'),
        model: 'transaction'
      }).then(function(data) {
        self.set('postbacklogs', data);
        self.set('isLoading', false);
      }, function() {
        self.set('isLoading', false);
        return null;
      });
    } else {
      return null;
    }
  },
  getProcessorData: function() {
    var self = this;
    // Bail if we are already loading
    if (self.get('isLoading')) {
      return;
    }
    self.set('isLoading', true);
    jQuery.ajax({
      url: window.UiENV.APP.FULL_URL + '/transactions/' + this.get('transaction.id') + '/processordata ',
      type: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'developer-id': window.UiENV.APP.DEVELOPER_ID,
        'access-token': self.get('session.authenticated.token')
      }
    }).then(function(data) {
      if(data) {
        self.set('processordata', data);
      }
      self.set('isLoading', false);
    }, function() {
      noty({
        text: 'Error getting Processor Data!',
        type: 'error'
      });
      self.set('isLoading', false);
    });
  },
  getLogApis: function() {
    var self = this;
    if(this.get('session.authenticated.resources.v2admin-logapis-get')){
      this.set('isLoading', true);
      this.get('store').query('admin-logapi', {
        endpoint: self.get('transaction.id'),
        sort: 'created_ts',
        request_method: 'POST,PUT',
        expand: 'log_api_body'
      }).then(function(data) {
        self.set('isLoading', false);
        if(data && data.get('length')) {
          var logapis = data.forEach(function(d,i) {
            if(d) {
              Ember.set(d, 'created_ts_timestamp', moment(d.get('created_ts')).tz(self.get('session.authenticated.timezone')).unix());
              Ember.set(d, 'is_collapsed', i > 0);
            }
            return d;
          });
          self.set('logapis', logapis);
          Ember.run.later(function() {
            jQuery('#trx_api_log_0').addClass('in');
          },1);
        } else {
          self.set('logapis', []);
        }
      }, function() {
        self.set('isLoading', false);
        return null;
      });
    } else {
      return null;
    }
  },
  logapisCollapseActions: function(turnOn) {
    var logapis = this.get('logapis');
    if(logapis && logapis.get('length')) {
      Ember.run.later(function() {
        logapis.forEach(function(logapi,index) {
          if(turnOn) {
            $('#trx_api_log_' + index).on('shown.bs.collapse', function() {
              Ember.set(logapi, 'is_collapsed', false);
            });
            $('#trx_api_log_' + index).on('hidden.bs.collapse', function() {
              Ember.set(logapi, 'is_collapsed', true);
            });
          } else {
            $('#trx_api_log_' + index).off('shown.bs.collapse');
            $('#trx_api_log_' + index).off('hidden.bs.collapse');
            Ember.set(logapi, 'is_collapsed', true);
          }
        });
      });
    }
  },

  // Actions
  actions: {
    resendEmail: function(log_email) {
      var self = this;
      var session = self.get('session');
      pretty_confirm('Are you sure you want to re-send this email?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/logemails/' + log_email.id + '/resend',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          if (data.logemail.id) {
            self.send('refreshData');
            noty({
              text: 'Resend email successful!'
            });
          } else {
            noty({
              text: 'Send email failed!',
              type: 'error'
            });
          }
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Send email failed!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    viewPostbacklogDetail: function(postbacklog) {
      var self = this;
      var postbacklogController = self.get('postbacklogController');
      postbacklogController.set('postback_log_id', null);
      postbacklogController.set('postbacklogs_modal', null);
      postbacklogController.set('postback_log_id', postbacklog.id);
      postbacklogController.set('postbacklog', postbacklog);
      self.send('openModal', 'components/modals/postbacklog');
    },
    launchAction: function(actionName) {
      var self = this;
      var controller = this.get(actionName + 'Controller');
      controller.set('sender', 'transactiondetailfull');

      var transaction = this.get('transaction');
      var product = this.get('product');
      controller.set('product', product);

      if (transaction.get('advance_deposit')) {
        transaction.set('extra_flag', 'advance_deposit');
      }
      if (transaction.get('no_show')) {
        transaction.set('extra_flag', 'no_show');
      }

      if (actionName === 'tipadjust') {
        var subtotal_amount = this.get('tipAdjustTrxSubtotal');
        controller.set('transaction', transaction);
        controller.set('transaction_tax', transaction.get('tax'));
        controller.set('transaction_tip_amount', transaction.get('tip_amount') || 0);
        controller.set('trxSubTotal', subtotal_amount);
        controller.set('product', this.get('product'));
        controller.set('transaction.complete', transaction.get('status.id') === '102' ? true : false);
        controller.set('isAuthOnly', transaction.get('status.id') === '102' ? true : false);
        controller.set('locationinfo', this.get('locationinfo'));
        self.send('openModal', 'components/modals/' + actionName);
        return;
      }

      if (actionName === 'runagain') {
        var tempTrans = {
          order_num: null,
          quick_invoice_id: null,
          transaction_api_id: null,
          product_transaction_id: transaction.get('product_transaction_id'),
          previous_transaction_id: transaction.get('id'),
          action: product.get('default_transaction_type'),
          transaction_amount: transaction.get('transaction_amount'),
          tip_amount: transaction.get('tip_amount'),
          tax: transaction.get('tax'),
          surcharge_amount: transaction.get('surcharge.surcharge_amount'),
          payment_method: transaction.get('payment_method'),
          contact_id: transaction.get('contact_id'),
          last_four: transaction.get('last_four'),
          billing_street: transaction.get('billing_street'),
          billing_city: transaction.get('billing_city'),
          billing_state: transaction.get('billing_state'),
          billing_zip: transaction.get('billing_zip'),
          billing_phone: transaction.get('billing_phone'),
          email: transaction.get('email'),
          room_rate: transaction.get('room_rate'),
          room_num: transaction.get('room_num'),
          checkin_date: transaction.get('checkin_date'),
          checkout_date: transaction.get('checkout_date'),
          extra_flag: transaction.get('extra_flag'),
          clerk_number: transaction.get('clerk_number'),
          description: transaction.get('description'),
          tags_csv: transaction.get('tags_csv'),
          ach_sec_code: transaction.get('ach_sec_code'),
        };
        subtotal = transaction.get('subtotal_amount');
        if (subtotal && subtotal.length == 0){
          tempTrans.subtotal_amount = '0';
        }else{
          tempTrans.subtotal_amount = subtotal?subtotal:'0'
        }
        tempTrans.tax = '0';
        tempTrans.surcharge_amount = '0';
        tempTrans.tip_amount = '0';
        tempTrans.transaction_amount = '0';
        var contact = transaction.get('contact');
        controller.set('contact', contact);
        controller.set('transactionData', tempTrans); //set last so observers fire correctly
        var locations = Ember.A();
        locations.pushObject(this.get('locationinfo'));
        controller.set('locations', locations);
      } else if (actionName === 'addcardtoaccountvault') {
        controller.set('transaction', null);
        controller.set('account', null);
        controller.set('transaction', transaction);
        controller.set('account', self.get('store').createRecord('accountvault', {
          contact_id: transaction.get('contact_id'),
          payment_method: transaction.get('payment_method'),
          location_id: transaction.get('location_id'),
          account_holder_name: transaction.get('account_holder_name'),
          billing_address: transaction.get('billing_street'),
          billing_city: transaction.get('billing_city'),
          billing_state: transaction.get('billing_state'),
          billing_zip: transaction.get('billing_zip'),
          billing_phone: transaction.get('billing_phone'),
          previous_transaction_id: transaction.get('id'),
          last_four: transaction.get('last_four'),
          exp_month: transaction.get('exp_month'),
          exp_year: transaction.get('exp_year'),
          dl_state: null,
          dl_number: null,
          ssn4: null,
          dob_year: null,
        }));
        if(transaction.get('contact_id')) {
          controller.set('contact', transaction.get('contact'));
        } else {
          controller.set('contact', null);
        }

      } else if (actionName === 'refund') {

        var surcharge      =                    this.get('product.surcharge.id');
        var surcharge_fee  = parseFloat(     product.get('surcharge.surcharge_fee')) || 0;
        var surcharge_rate = parseFloat(     product.get('surcharge.surcharge_rate')/100) || 0;
        var total          = parseFloat( transaction.get('transaction_amount'));
        var subtotal = String( parseFloat( ( total - surcharge_fee ) / ( surcharge_rate + 1 ) ).toFixed(2));

        var refunded_amount = parseFloat( this.get( 'transaction.refunded_amount' ) ).toFixed(2);
        var net_refundable  = parseFloat( subtotal ) - refunded_amount;

        var transaction_amount = transaction.get('transaction_amount');
        var surcharge_amount = transaction.get('surcharge.surcharge_amount') || '0.00';

        if (transaction.get('payment_method') === 'ach') {

          transaction.set('subtotal_amount', String(parseFloat(total - surcharge_amount).toFixed(2)));
          transaction.set('surcharge_amount', surcharge_amount);

        } else if (surcharge && this.get('product.surcharge.refund_surcharges')) {

          if (transaction.get('surcharge.surcharge_amount') > 0) {
            transaction.set('subtotal_amount', String((parseFloat(transaction_amount) - parseFloat(surcharge_amount)).toFixed(2)));
            transaction.set('surcharge_amount', surcharge_amount);
          } else {
            transaction.set('subtotal_amount', subtotal);
            transaction.set('surcharge_amount', String(parseFloat(total - subtotal).toFixed(2)));
          }

        } else if(surcharge && !this.get('product.surcharge.refund_surcharges')) {

          transaction.set('subtotal_amount', subtotal);
          transaction.set('surcharge_amount', '0.00');
        }

        var isSurcharge = this.get('product.surcharge.is_surcharge');
        var isSurchargeApplied = true;

        if (transaction.get('payment_method') == 'ach' && isSurcharge === 'Surcharge') {
          transaction.set('subtotal_amount', transaction.get('transaction_amount'));
          transaction.set('surcharge_amount', '0.00');
          isSurchargeApplied = false;
        }

        var isSurchargeRefunded = false;
        var surchargeAmount = transaction.get('surcharge_amount');
        if ( transaction.get( 'surcharge.surcharge_refunded' ) ) {
          isSurchargeRefunded = true;
          net_refundable = parseFloat( net_refundable ) + parseFloat( surcharge_amount );
          surchargeAmount = 0;
        }

        var refund_trans = this.get('store').createRecord('transaction', {
          "action": "refund",
          "checkin_date": (transaction.get('checkin_date') || ''),
          "checkout_date": (transaction.get('checkout_date') || ''),
          "clerk_number": (transaction.get('clerk_number') || ''),
          "contact_id": transaction.get('contact_id'),
          "description": transaction.get('description'),
          "first_six" : (transaction.get('first_six') || ''),
          "is_surcharge_applied" : isSurchargeApplied,
          "last_four": transaction.get('last_four'),
          "location_id": transaction.get('location_id'),
          "net_refundable": net_refundable.toFixed(2),
          "order_num": (transaction.get('order_num') || ''),
          "original_transaction_amount": total.toFixed(2),
          "payment_method": transaction.get('payment_method'),
          "previous_transaction_id": transaction.get('id'),
          "refunded_amount": refunded_amount,
          "room_num": (transaction.get('room_num') || ''),
          "room_rate": (transaction.get('room_rate') || ''),
          "subtotal_amount": net_refundable.toFixed(2),
          "surcharge_amount": surchargeAmount,
          "surcharge_refunded": isSurchargeRefunded,
          "tags": (transaction.get('tags') || null),
          "transaction_amount": transaction.get('transaction_amount')
        });

        controller.set('transaction', refund_trans);

      } else {

        controller.set('transaction', transaction);
      }

      controller.set('initialTags', transaction.get('tags_csv'));
      controller.set('selectedTags', transaction.get('tags_csv'));
      controller.set('locationinfo', this.get('locationinfo'));

      controller.set('tagList', null);
      var tagList = this.get('locationinfo.tags');
      controller.set('tagList', tagList.sortBy('title'));
      self.send('openModal', 'components/modals/' + actionName);
    },
    overrideTransaction: function() {
      var self = this;
      var transaction = this.get('transaction');
      var session = this.get('session');
      pretty_confirm('Are you sure you want to Override this transaction?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/transactions/' + transaction.get('id') + '/override',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          noty({
            text: 'Successful!'
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Error on transaction override.',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    transactionlevel3: function() {
      this.transitionToRoute('transactiondetaillevelthree', this.get('transaction.id'));
    },
    activeSection: function(index) {
      var detailSections = this.get('detailSections');
      var currentSection = detailSections.filter(function(section, i) {
        return index === i;
      }).get('firstObject');
      this.set('isChangeLogTab', currentSection.action === 'changelogs');
      this.set('isBinInfoTab', currentSection.action === 'bininfo');
      this.set('currentSection', currentSection);
    },
    collapseApiLog: function(logapi) {
      logapi.toggleProperty('is_collapsed');
    },
    viewBatch: function() {
      var transaction = this.get('transaction');
      var date = transaction.get('created_ts');
      var date_from = moment(date).subtract(4, 'months').startOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var date_to = moment(date).add(1, 'months').endOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var batch_num = transaction.get('batch');
      this.transitionToRoute('locations.transactions', transaction.get('location_id'), {
        queryParams: {
          batch: batch_num + ',' + batch_num,
          product_transaction_id: transaction.get('product_transaction_id'),
          created_ts: 'custom',
          created_ts_from: date_from,
          created_ts_to: date_to,
          status_id: '101,111'
        }
      });
    },
    viewContact: function() {
      this.transitionToRoute('contacts.dashboard', this.get('transaction.contact_id'));
    },
    viewQuickInvoice: function() {
      this.transitionToRoute('quickinvoice.view', this.get('transaction.quick_invoice_id'));
    },
    viewRecurring: function() {
      this.transitionToRoute('recurrings.view', this.get('transaction.recurring_id'));
    },
    viewLocation: function() {
      this.transitionToRoute('locations.view', this.get('transaction.location_id'));
    },
    viewTerminal: function() {
      this.transitionToRoute('locations.terminals-view', this.get('transaction.location_id'), this.get('transaction.terminal_id'));
    },
    viewCheckImages: function() {
      this.send('showCheckImages', this, this.get('transaction.id'));
    }
  }
});
