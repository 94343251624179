import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  showPrint: true,
  historyVisible: false,
  showHistory: false,
  changelogs: null,
  showLink: false,
  data: null,
  model: null,
  isLoading: true,
  linkID: Ember.computed(function() {
    return this.get('model.constructor.modelName') + '_change_history';
  }),
  changelogsCheck: function() {
    var self = this;
    var hasCreateChange = false;
    var changes = null;
    var store = this.get('store');
    if(self.get('isDestroyed') || self.get('isDestroying')) {
      return;
    }
    return Ember.RSVP.hash({
      changelogs: store.queryRecord(this.get('model.constructor.modelName'), {
        expand: 'changelogs',
        id: this.get('model.id'),
        page_size: '1',
        active: '0,1'
      }).then(function(data) {
        if(self.get('isDestroyed') || self.get('isDestroying')) {
          return;
        }
        self.set('isLoading', true);

        changes = data.get('changelogs');

        if (typeof changes === 'undefined') {
          self.set('isLoading', false);
          return null;
        }
        self.set('data', self.get('model').toJSON());
        self.set('title', self.get('model.title'));
        changes.forEach(function(c) {
          if (c.get('action') === 'CREATE') {
            hasCreateChange = true;
          }
          if (c.get('action') !== 'CREATE') {
            var logs = c.get('changelog_details');
            if (typeof logs !== 'undefined') {
              logs.forEach(function(l) {
                if (typeof l !== 'undefined' && l.get('action') !== 'CREATE') {
                  var field = l.get('field');
                  if (field) {
                    var display_field = field.humanize();
                    if (['reportable', 'new_created_ts', 'new_modified_ts', 'full_response', 'current_stan', 'current_batch'].indexOf(field) > -1) {
                      l.set('show_field', false);
                    } else {
                      l.set('show_field', true);
                      var new_value = self.get('data.' + field);
                      if (field === 'selectable') {
                        display_field = 'Is Active';
                      }
                      l.set('display_field', display_field);
                      if (['bank_account_number', 'password'].indexOf(field) > -1) {
                        l.set('old_value', '<i>hidden value</i>');
                      }
                      if(typeof new_value === 'object' && new_value !== null) {
                        if(!Ember.isArray(new_value)) {
                          new_value = JSON.stringify(new_value);
                        }
                      }
                      if(field === 'tags') {
                        var tagList = self.get('tagList');
                        if(tagList && tagList.get('length') && Ember.isArray(new_value)) {
                          new_value = new_value.map(function(val){
                            var new_val = '';
                            tagList.forEach(function(tag){
                              if(val === tag.get('id')) {
                                new_val = tag.get('title');
                              }
                            });
                            return new_val;
                          });
                        }
                      }
                      if(field === 'files') {
                        var fileList = self.get('fileList');
                        if(fileList && fileList.get('length') && Ember.isArray(new_value)) {
                          new_value = new_value.map(function(val) {
                            var new_val = '';
                            fileList.forEach(function(file) {
                              if(val.id === file.id) {
                                new_val = file.file_name;
                              }
                            });
                            return new_val;
                          });
                        }
                      }
                      if(field === 'cau_account_number') {
                        if(new_value) {
                          var validIndexes = [0, 1, new_value.length - 2, new_value.length - 1];
                          new_value = new_value.split('').map(function(char, index) {
                            return (validIndexes.indexOf(index) >= 0) ? char : '*';
                          }).join('');
                        } else {
                          new_value = data.get('cau_account_number');
                        }
                        var old_value = l.get('old_value');
                        if(old_value) {
                          var validIndexesOld = [0, 1, old_value.length - 2, old_value.length - 1];
                          old_value = old_value.split('').map(function(char, index) {
                            return (validIndexesOld.indexOf(index) >= 0) ? char : '*';
                          }).join('');
                          l.set('old_value', old_value);
                        }
                      }
                      l.set('new_value', new_value);
                      self.set('data.' + field, l.get('old_value'));
                    }
                  }
                }
              });
            }
          }
        });
        if ((changes.get('length') > 0 && !hasCreateChange) || (changes.get('length') > 1 && hasCreateChange)) {
          self.set('showHistory', true);
        }
        if (self.get('showLink') === false || self.get('showLink') === 'false') {
          self.set('historyVisible', true);
        }
        self.set('changelogs', changes);
        self.set('isLoading', false);
      })
    });
  },

  // Observers
  isChangeLogTabObserver: Ember.observer('isChangeLogTab', function() {
    if(this.get('isChangeLogTab')){
      this.changelogsCheck();
      this.set('historyVisible', true);
    }
  }),

  actions: {
    toggleHistory: function() {
      this.changelogsCheck();
      this.set('historyVisible', !this.get('historyVisible'));
    }
  }
});
