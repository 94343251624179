import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  check: function(model, transition, type, context) {
    var prop = 'id';
    switch(type) {
      case 'Hosted Payment Page':
        prop = 'hppId';
        break;
      case 'Terminal':
        prop = transition.targetName === 'admin.terminals.view' ? 'view_id' : 'terminal_id';
        break;
    }
    var model_id = transition.to.params[prop];
    return new Ember.RSVP.Promise(function(resolve) {
      if(!model){
        transition.abort();
        context.replaceWith('modelnotfound', {model: type.toLowerCase(), model_id: model_id});
        return true;
      } else {
        resolve();
        return false;
      }
    });
  }

});
