import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      })
    });
  },

  setupController: function(controller, models) {
    var session = this.get('session');
    var location = models.location;


    var pagerParams = {
      pageRoute: 'locations.reports-ach-transactionsummary',
      pageModelId: null,
      modelName: 'transactionsummaryach',
      showExport: session.get('authenticated.resources.v2-transactionsummaryaches-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location.id,location.name',
      location_id: location.get('id')
    };
    controller.set('gridParams', gridParams);

    controller.set('location', location);
    controller.set('location_id', location.get('id'));

    this.send('addHistory', {
      title: location.get('name') + ' ● Reports ● ACH Transactions Summary',
      route_name: 'locations.reports-ach-transactionsummary',
      route_id: location.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'ACH Transactions Summary',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
