import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  // Set controller properties
  isLoadingRecurrings: true,
  isLoadingTransactions: true,
  transactionFilter: 'all',
  recurrings: [],
  recurringsArray: [
    'today',
    'yesterday',
    'this_week',
    'last_week',
    'this_month',
    'last_month'
  ],

  // Computed Properties

  isFilterACH: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'ach';
  }),
  isFilterAll: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'all';
  }),
  isFilterCC: Ember.computed('transactionFilter', function() {
    return this.get('transactionFilter') === 'cc';
  }),
  showTypes: Ember.computed('locationinfos.[]', 'trans', function() {
    var locationinfos = this.get('locationinfos');
    var hasCC = false;
    var hasACH = false;
    if (locationinfos && locationinfos.get('length')) {
      locationinfos.forEach(function(location) {
        if (location.get('hasProductCC')) {
          hasCC = true;
        }
        if (location.get('hasProductACH')) {
          hasACH = true;
        }
      });
    }
    return hasCC && hasACH && this.get('trans');
  }),

  // Functions

  mapContent: function() {
    var content = this.get('trans');
    var type = this.get('transactionFilter');
    var historicalData = [{
      name: 'Historical Onetime',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_nonrecurring_accountvault) + parseFloat(item.values.cc.historical_nonrecurring_nonaccountvault) + parseFloat(item.values.ach.historical_nonrecurring_accountvault) + parseFloat(item.values.ach.historical_nonrecurring_nonaccountvault);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_nonrecurring_accountvault) + parseFloat(item.values.cc.historical_nonrecurring_nonaccountvault);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_nonrecurring_accountvault) + parseFloat(item.values.ach.historical_nonrecurring_nonaccountvault);
        }
      })
    }, {
      name: 'Historical Installment',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_installment) + parseFloat(item.values.ach.historical_installment);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_installment);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_installment);
        }
      })
    }, {
      name: 'Historical Ongoing',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.historical_ongoing) + parseFloat(item.values.ach.historical_ongoing);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.historical_ongoing);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.historical_ongoing);
        }
      })
    }, {
      name: 'Future Installment',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.forecast_installment) + parseFloat(item.values.ach.forecast_installment);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.forecast_installment);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.forecast_installment);
        }
      })
    }, {
      name: 'Future Ongoing',
      data: content.map(function(item) {
        if (type === 'all') {
          return parseFloat(item.values.cc.forecast_ongoing) + parseFloat(item.values.ach.forecast_ongoing);
        }
        if (type === 'cc') {
          return parseFloat(item.values.cc.forecast_ongoing);
        }
        if (type === 'ach') {
          return parseFloat(item.values.ach.forecast_ongoing);
        }
      })
    }];

    this.set('historicalChartData', historicalData);
  },

  // Actions
  actions: {
    showAdditional: function() {
      this.transitionToRoute('dashboard-additionalgraphs');
    },
    updateRecurrings: function() {
      var self = this;
      self.set('isLoadingRecurrings', true);
      var session = this.get('session');
      if (session.get('authenticated.resources.v2-reports-get')) {
        var store = this.get('store');
        var recurring_data = Ember.RSVP.hash({
          today: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'today'
          }),
          yesterday: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'yesterday'
          }),
          this_week: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'this week'
          }),
          last_week: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'last week'
          }),
          this_month: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'this month'
          }),
          last_month: store.query('transaction', {
            is_recurring: true,
            status_id: '301,331',
            relationship: 'direct',
            created_ts: 'last month'
          })
        });
        recurring_data.then(function(hash) {
          var recurrings = [];
          var recurringsArray = self.get('recurringsArray');
          recurringsArray.forEach(function(item) {
            var recurring = {
              'count': 0,
              'amount': 0
            };
            hash[item].forEach(function(data) {
              var amount = parseFloat(data.get('transaction_amount') || '0');
              recurring.amount += amount;
              recurring.count += 1;
            });
            recurring.id = item.replace(/_/g, ' ');
            recurring.title = item.replace('_', ' ').replace(/\w\S*/g, function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            recurrings.push(recurring);
          });
          self.set('recurrings', recurrings);
          self.set('isLoadingRecurrings', false);
        });
      } else {
        self.set('recurrings', null);
        self.set('isLoadingRecurrings', false);
      }
    },
    updateTransactions: function() {
      var self = this;
      self.set('isLoadingTransactions', true);
      var session = this.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/dashboardsummaries?relationship=direct',
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function(data) {
        var trans = data.dashboardsummaries.data;
        var months = trans.map(function(item) {
          return moment(item.month.slice(2, 4), 'MM').format('MMM') + '-' + item.month.slice(0, 2);
        });

        self.set('historicalChartOptions', {
          chart: {
            type: 'column'
          },
          title: {
            text: null
          },
          xAxis: {
            title: {
              text: 'Month'
            },
            categories: months
          },
          yAxis: {
            title: {
              text: 'Volume'
            }
          },
          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },
        });

        self.set('trans', trans);
        self.mapContent();
        self.set('isLoadingTransactions', false);
      }, function() {
        self.set('trans', null);
        self.set('isLoadingTransactions', false);
      });
    },
    changeFilter: function(type) {
      this.set('transactionFilter', type);
      this.mapContent();
    }
  }

});
