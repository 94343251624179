import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('productrecurring.errors.messages', function() {
    this.set('errorMessages', this.get('productrecurring.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,

  isActive: Ember.computed('productrecurring.active', function() {
    return this.get('productrecurring.active');
  }),

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var productrecurring = this.get('productrecurring');
      pretty_confirm('Are you sure you want to delete this Recurring Billing Service?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        productrecurring.deleteRecord();
        productrecurring.save().then(function() {
          productrecurring.unloadRecord();
          noty({
            text: 'Recurring Billing Service deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('locations.products', self.get('location.id'));
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          productrecurring.rollbackAttributes();
          noty({
            text: 'Error deleting Recurring Billing Service!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      var productrecurring = this.get('productrecurring');

      productrecurring.save().then(function() {
        noty({
          text: 'Recurring Billing Service saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.products', location.id);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
    back: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.products', location.id);
    },
    reset: function() {
      this.set('isEditing', false);
    },
    reActivateProduct: function(id) {
      var self = this;
      var action_type = 'productrecurrings';
      if (self.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      var session = this.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/' + action_type + '/' + id + '/activate',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function() {
        noty({
          text: 'Recurring Billing Service activation successful!'
        });
        self.send('refreshData');
        self.set('isLoading', false);
      }, function() {
        self.send('refreshData');
        self.set('isLoading', false);
        noty({
          text: 'Recurring Billing Service activation failed!',
          type: 'error'
        });
      });
    }
  }
});
