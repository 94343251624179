import Ember from 'ember';
import jQuery from 'jquery';
import { copy } from 'ember-copy';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  hiddenAvailableGridColumns: {},
  availableGridColumns: [],
  fieldDataOptionsSearchTerm: '',

  errorMessages: [],
  updateErrorMessages: Ember.observer('userreport.errors.messages', function() {
    this.set('errorMessages', this.get('userreport.errors.messages'));
  }),

  // Events

  didInsertElement: function() {
    this._super();
    this.get('parent').send('setChild', this);
    var self = this;
    var availableGridColumns = [];

    var gridColumnOptions = this.get('gridViewColumnOptions.gridColumnOptions');
    var mainModel = this.get('gridViewColumnOptions.mainModel');
    var addToModel = this.get('gridViewColumnOptions.addToModel');
    var validForRoute = this.get('gridViewColumnOptions.validForRoute');

    gridColumnOptions.forEach(function(option) {
      createGridColumnOption(option.model, option.header, option.modelName);
    });

    function createGridColumnOption(model, header, modelName) {
      let columnOption = {
        header: header,
        collapsed: true,
        options: setOptionName(model, modelName),
      };
      availableGridColumns.push(columnOption);
    }

    function setOptionName(columns, model) {
      if (model.toLowerCase() === mainModel.toLowerCase()) {
        return columns;
      } else {
        return columns.map(function(column) {
          if (column.name.indexOf(model + '.') === -1) {
            column.name = model + '.' + column.name;
          }
          return column;
        });
      }
    }

    // add related models to the column options of the main model for this route
    availableGridColumns.map(function(column) {
      if (column.header.toLowerCase() === mainModel.toLowerCase()) {
        addToModel.map(function(atm) {
          atm.map(function(option) {
            if (!column.options.isAny('name', option.name)) {
              if (option.filter && option.filter.field) {
                option.name = option.filter.field.replace('_id', '.' + option.name);
              }
              if (validForRoute.indexOf(option.name) !== -1) {
                column.options.push(option);
              }
            }
          });
        });
      }
    });

    // sort column options and remove any that aren't valid for the route
    availableGridColumns.map(function(column, index) {
      var colopts = column.options.filter(function(option) {
        return validForRoute.indexOf(option.name) !== -1;
      });
      var sortedArr = colopts.sortBy('title');
      Ember.set(column, 'options', sortedArr);
      Ember.set(column, 'id', 'column-option-' + index);
    });

    this.set('availableGridColumns', availableGridColumns.sortBy('header'));
    this.setupUserReport();
    this.set('reportSearchKeyword', '');

    jQuery('#changeUserreport').parent().on('shown.bs.dropdown', function() {
      jQuery('#reportSearchKeyword').focus();
      self.set('reportSearchKeyword', '');
    });
  },
  willDestroyElement: function() {
    jQuery('#changeUserreport').parent().off('shown.bs.dropdown');
  },

  // Computed Properties

  filteredAvailableGridColumnOptions: Ember.computed('fieldDataOptionsSearchTerm', 'availableGridColumns.[]', function() {
    var self = this;
    var keyword = this.get('fieldDataOptionsSearchTerm');
    var availableGridColumns = this.get('availableGridColumns');
    return availableGridColumns.map(function(availableGridColumn) {
      return self.filterAvailableGridColumnOptions(availableGridColumn, keyword);
    });
  }),
  filteredUserReports: Ember.computed('reportSearchKeyword', 'userreports.[]', function() {
    var keyword = this.get('reportSearchKeyword') || '';
    var userreports = this.get('sortedUserreports').toArray();
    return this.filterReports(userreports, 'title', keyword);
  }),
  filteredUserReportShares: Ember.computed('reportSearchKeyword', 'userreportshares.[]', function() {
    var keyword = this.get('reportSearchKeyword') || '';
    var userreportshares = this.get('sortedUserreportshares');
    return this.filterReports(userreportshares, 'user_report.title', keyword);
  }),
  isDifferencesUserreportAndCurrentState: Ember.computed('fieldData.[]', 'sort', 'page_size', 'filtering', 'userreport.filter_data', function() {
    //column changes
    var selectedItemsDiff = false;
    var userreportItems = this.get('userreport.field_data');
    var selectedItems = this.get('selectedItemsNames');
    if (selectedItems && userreportItems) {
      userreportItems = userreportItems.split(',');
      if (selectedItems.length !== userreportItems.length) {
        return true;
      }
      var i = selectedItems.length;
      while (i--) {
        if (selectedItems[i] !== userreportItems[i]) {
          selectedItemsDiff = true;
          break;
        }
      }
    }
    if (selectedItemsDiff) {
      return true;
    }

    //filtering changes
    var reportFilteringDiff = false;
    var userreportFilterData = this.get('userreport.filter_data');
    var grid = this.get('parent');
    if (userreportFilterData) {
      this.get('queryParams').map(function(param) {
        var filterDataProp = param.replace('-', '.');
        if (param !== 'page') {
          if (userreportFilterData.hasOwnProperty(filterDataProp)) {
            if (String(userreportFilterData[filterDataProp]) !== String(grid.get(param))) {
              reportFilteringDiff = true;
            }
          } else if (!userreportFilterData.hasOwnProperty(filterDataProp) && grid.get(param)) {
            reportFilteringDiff = true;
          }
        }
      });
    }
    if (reportFilteringDiff) {
      return true;
    }
    return selectedItemsDiff || reportFilteringDiff;
  }),
  isViewingSharedReport: Ember.computed('userreport.id', function() {
    var isSystemDefault = this.get('userreport.isSystemDefault');
    var userId = this.get('session.authenticated.user_id');
    var userreportCreatedUserId = this.get('userreport.created_user_id');
    return userId === userreportCreatedUserId || isSystemDefault ? false : true;
  }),
  selectedItemsNames: Ember.computed('selectedItems.[]', function() {
    var selectedItems = this.get('selectedItems');
    var tempArr;
    if (selectedItems) {
      tempArr = selectedItems.map(function(item) {
        return item.name;
      });
    } else {
      tempArr = [];
    }
    return tempArr;
  }),
  sortedUserreports: Ember.computed.sort('userreports.@each.title', function(a, b) {
    var aTitle = a.get('title').toLowerCase();
    var bTitle = b.get('title').toLowerCase();
    if (aTitle > bTitle) {
      return 1;
    } else if (aTitle < bTitle) {
      return -1;
    }
    return 0;
  }),
  sortedUserreportshares: Ember.computed.sort('userreportshares.@each', function(a, b) {
    var aTitle = a.get('user_report.title').toLowerCase();
    var bTitle = b.get('user_report.title').toLowerCase();
    if (aTitle > bTitle) {
      return 1;
    } else if (aTitle < bTitle) {
      return -1;
    }
    return 0;
  }),
  thisUrl: Ember.computed('userreport.id', function() {
    return this.get('userreport.url');
  }),

  // Observers

  // Functions

  confirmFiltersAreSelectedColumn: function() {
    var self = this;
    var grid = this.get('parent');
    var filtering = this.get('filtering');
    var selectedItems = this.get('selectedItems');
    for (var prop in filtering) {
      if (filtering.hasOwnProperty(prop)) {
        if (!check(prop, selectedItems)) {
          filtering[prop] = ''; // prevent from filtering by invalid/inactive column
          grid.set(prop.replace('.','-'), '');
          self.set(prop.replace('.','-'), '');
          if(self.get('filtering.sort') === prop) { // prevent from sorting by invalid/inactive column
            self.set('sort', '');
            grid.set('sort', '');
          }
        }
      }
    }

    function check(prop, selectedItems) {
      let filterIsInAColumn = false;
      selectedItems.map(function(item) {
        if ((item.name === prop || (item.filter && item.filter.field === prop)) || prop === 'sort' || prop === 'page_size' || prop === 'page') {
          filterIsInAColumn = true;
        }
      });
      return filterIsInAColumn;
    }
  },
  filterAvailableGridColumnOptions: function(availableGridColumn, keyword) {
    var self = this;
    var header = availableGridColumn.header;
    var optionsToAdd = self.get('hiddenAvailableGridColumns[' + header + ']');
    if (optionsToAdd) {
      availableGridColumn.options.pushObjects([optionsToAdd]);
    }
    var newArr = [].concat.apply([], availableGridColumn.options);
    self.set('hiddenAvailableGridColumns[' + header + ']', []);
    var hiddenArr = [];
    var tempArr = newArr.filter(function(option) {
      var lowerCaseOptionTitle = option.title.toLowerCase();
      var lowerCasekeyword = keyword.toLowerCase();
      if (lowerCaseOptionTitle.indexOf(lowerCasekeyword) !== -1) {
        return true;
      } else {
        hiddenArr.pushObject(option);
      }
    });
    self.set('hiddenAvailableGridColumns[' + header + ']', hiddenArr);
    Ember.set(availableGridColumn, 'options', tempArr);
    return availableGridColumn;
  },
  filterGrid: function(data) {
    var filtering = {};
    var filteringObj = this.get('filtering');
    for (var prop in filteringObj) {
      filtering[prop] = filteringObj[prop];
    }
    var invalidFilterFields = ['page', 'created_ts_from', 'created_ts_to', 'charge_back_date', 'charge_back_date_from', 'charge_back_date_to', 'settle_date', 'settle_date_from', 'settle_date_to', 'effective_date', 'effective_date_from', 'effective_date_to'];
    data.filters.map(function(item) {
      if (invalidFilterFields.indexOf(item.name) === -1) {
        filtering[(item.name).replace('-', '.')] = item.value;
      }
    });
    this.set('filtering', filtering);
  },
  filterReports: function(arr, filterProp, keyword) {
    var tempArr = arr.filter(function(obj) {
      var lowerCaseReportTitle = obj.get(filterProp).toLowerCase();
      var lowerCasekeyword = keyword.toLowerCase();
      if (lowerCaseReportTitle.indexOf(lowerCasekeyword) !== -1) {
        return true;
      }
    });
    return tempArr;
  },
  pageSizeChanged: function(pageSize) {
    if (this.get('filtering')) {
      this.set('page_size', pageSize);
      this.set('filtering.page_size', pageSize);
      this.send('setColumns');
    }
  },
  removeColumn: function(column) {
    var grid = this.get('parent');
    var self = this;
    var colName = column.get('name');
    var sort = this.get('filtering.sort');
    var availableGridColumns = this.get('availableGridColumns');
    var tempAvailable = availableGridColumns.map(function(availableGridColumn) {
      availableGridColumn.options.map(function(option) {
        if (option.name === colName) {
          Ember.set(option, 'selected', false);
        }
        return option;
      });
      return availableGridColumn;
    });
    this.set('availableGridColumns', tempAvailable);
    var selectedItems = this.get('selectedItems');
    var tempSelected = selectedItems.filter(function(item) {
      // if grid was being filtered by this column set filter to empty string
      if (item.name === colName) {
        if (item.filter && item.filter.field) {
          grid.set(item.filter.field, '');
          self.set(item.filter.field, '');
          self.set('filtering.' + item.filter.field, '');
        } else {
          grid.set(colName.replace('.', '-'), '');
          self.set(colName.replace('.', '-'), '');
        }
      }
      return item.name !== colName;
    });
    this.set('selectedItems', tempSelected);
    // if grid was being sorted by this column set sort to empty string
    if (colName.replace('-', '') === sort.replace('-', '')) {
      grid.set('sort', '');
      this.set('sort', '');
      this.set('filtering.sort', '');
    }
    this.send('setColumns');
  },
  reorderColumns: function(columns) {
    var tempColumns = [];
    var selectedItems = this.get('selectedItems');
    columns.map(function(column) {
      selectedItems.map(function(item) {
        if (column.name === item.name && column.title === item.title) {
          tempColumns.push(item);
        }
      });
    });
    this.set('selectedItems', tempColumns);
    this.send('setColumns');
  },
  setupUserReport: function(report, reportDeleted) {
    var userreports = this.get('userreports').toArray();
    var defaultReport = this.get('defaultReport');
    var userHasSavedOwnDefault;
    var usersDefault;

    userreports.map(function(report) {
      if (report.get('title') === 'Default' && !report.isSystemDefault) {
        userHasSavedOwnDefault = true;
        usersDefault = report;
      }
    });
    if (userHasSavedOwnDefault) {
      if (!this.get('userreport') || reportDeleted) {
        this.set('userreport', usersDefault);
      }
    } else {
      if (!userreports.isAny('title', 'Default')) {
        userreports.pushObject(defaultReport);
      }
      if (!this.get('userreport') || (!report && reportDeleted)) {
        this.set('userreport', defaultReport);
      }
    }
    if (report) {
      this.set('userreport', report);
    }
    // set up initial selectedItems based on the userreport
    var userreport = this.get('userreport');
    var userreportFieldData = userreport.get('field_data').split(',');
    var validForRoute = this.get('gridViewColumnOptions.validForRoute');
    userreportFieldData = userreportFieldData.filter(function(d){
      return validForRoute.indexOf(d) >= 0;
    });
    var availableGridColumns = this.get('availableGridColumns');
    var selectedItems = Array.apply('', Array(userreportFieldData.length));
    var fieldsNotAvailable = [];
    userreportFieldData.map(function(field) {
      let fieldIsAvailable = false;
      availableGridColumns.map(function(gridColumn) {
        gridColumn.options.map(function(option) {
          if (field === option.name) {
            fieldIsAvailable = true;
          }
          var index = userreportFieldData.indexOf(option.name);
          if (index >= 0) {
            Ember.set(option, 'selected', true);
            selectedItems.splice(index, 1, option);
          } else {
            Ember.set(option, 'selected', false);
          }
        });
      });
      if (!fieldIsAvailable) {
        fieldsNotAvailable.push(field);
      }
    });
    userreportFieldData = userreportFieldData.filter(function(field) {
      return fieldsNotAvailable.indexOf(field) === -1;
    });
    this.set('userreport.field_data', userreportFieldData.join(','));

    this.set('selectedItems', selectedItems);
    var self = this;
    var grid = this.get('parent');
    this.get('queryParams').map(function(param) {
      var filterDataProp = param.replace('-', '.');
      var userreportFilterData = userreport.get('filter_data');
      if (userreportFilterData.hasOwnProperty(filterDataProp)) {
        grid.set(param, userreportFilterData[filterDataProp]);
        self.set(param, userreportFilterData[filterDataProp]);
      } else {
        grid.set(param, '');
        self.set(param, '');
      }
    });

    var filtering = copy(userreport.get('filter_data'));
    this.set('filtering', filtering);
    this.set('userreports', userreports);
    this.confirmFiltersAreSelectedColumn();
    this.send('setColumns');
  },
  sortByThisColumn: function(sort) {
    this.set('sort', sort);
    this.set('filtering.sort', sort);
    this.send('setColumns');
  },

  // Actions
  actions: {
    enterAction: function() {
      return;
    },
    fieldDataOptionsSearch: function(keyword) {
      this.set('fieldDataOptionsSearchTerm', keyword);
    },
    clearReportSearch: function() {
      this.set('reportSearchKeyword', '');
    },
    setColumns: function() {
      var selectedItems = this.get('selectedItems').map(function(item) {
        return item;
      });
      this.set('fieldData', selectedItems);
      var grid = this.get('parent');
      Ember.run.later(function() {
        grid.runUpdateGrid();
      });
    },
    changeUserReport: function(userreport) {
      jQuery('#changeUserreport').dropdown('toggle');
      this.setupUserReport(userreport);
    },
    saveChanges: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var store = this.get('store');
      var session = this.get('session');
      var userreport = this.get('userreport');
      var fieldData = this.get('selectedItemsNames').toString();
      var filterData = this.get('filtering');
      var availableGridColumns = this.get('availableGridColumns');
      var tempFieldData = fieldData.split(',');
      for (var prop in filterData) {
        prop = prop.replace('-', '.');
        if (filterData.hasOwnProperty(prop)) {
          availableGridColumns.map(function(gridColumn) {
            gridColumn.options.map(function(option) {
              if (option.selected && option.filter && option.filter.field && prop === option.filter.field) {
                prop = option.name;
              }
            });
          });
          if (!filterData[prop] || (tempFieldData.indexOf(prop) === -1 && prop !== 'page' && prop !== 'page_size' && prop !== 'sort')) { // check if there is a value for filtered field and if filtered column is visible in report.
            delete filterData[prop];
          }
        }
      }
      this.set('filtering', filterData);
      var url = this.get('gridViewColumnOptions.url');
      if (filterData && !filterData.page_size) {
        Ember.set(filterData, 'page_size', session.get('authenticated.ui_prefs.page_size') || '15');
      }

      if (filterData && filterData.created_ts === 'custom') {
        this.set('errorMessages', ['Custom dates are invalid for saved reports']);
        this.set('isLoading', false);
      } else {
        if (userreport.isSystemDefault) { // if saving new default from system default
          var newuserreport = store.createRecord('userreport', {
            'title': userreport.get('title'),
            'url': url,
            'field_data': fieldData,
            'filter_data': filterData,
          });
          newuserreport.save().then(function(data) {
            noty({
              text: 'Successfully saved new default report!',
              type: 'success',
              timeout: '5000'
            });
            self.send('updateUserreports', data);
            self.set('isLoading', false);
          }, function() {
            noty({
              text: 'Error saving report!',
              type: 'error',
              timeout: '5000'
            });
            self.set('isLoading', false);
          });
        } else { // if updating an existing userreport
          userreport.set('field_data', fieldData);
          userreport.set('filter_data', filterData);
          userreport.set('url', url);
          userreport.save().then(function(data) {
            noty({
              text: 'Successfully saved changes to report!',
              type: 'success',
              timeout: '5000'
            });
            self.send('updateUserreports', data, null);
            self.set('isLoading', false);
          }, function() {
            noty({
              text: 'Error saving report!',
              type: 'error',
              timeout: '5000'
            });
            self.set('isLoading', false);
          });
        }
      }
    },
    deleteUserReport: function(report) {
      var self = this;
      var deleteMessage;
      var isDeletingDefault = false;
      if (report.get('title') === 'Default') {
        isDeletingDefault = true;
        deleteMessage = 'Deleting this report will revert it back to system default. Are you sure you want to delete this report?';
      } else {
        deleteMessage = 'Are you sure you want to delete this report?';
      }
      pretty_confirm(deleteMessage, function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        report.deleteRecord();
        report.save().then(function() {
          noty({
            text: 'Report deleted successfully!'
          });
          if (report.get('id') === self.get('userreport.id')) {
            self.send('updateUserreports', null, true);
          } else {
            self.send('updateUserreports', self.get('userreport'), true);
          }
        }, function(err) {
          report.rollbackAttributes();
          if (err.errors.user_report_shares) {
            noty({
              text: err.errors.user_report_shares,
              type: 'error'
            });
            self.set('isLoading', false);
          } else {
            noty({
              text: 'Error deleting Report!',
              type: 'error'
            });
          }
          self.set('isLoading', false);
        });
        jQuery('#changeUserreport').dropdown('toggle');
      });
    },
    showUserReport: function(isShared) {
      if (isShared) {
        this.set('isShared', true);
      } else {
        this.set('isShared', false);
      }
      this.showUserReport(this);
    },
    resetChanges: function() {
      var userreport = this.get('userreport');
      this.send('changeUserReport', userreport);
    },
    createNewReport: function() {
      jQuery('#manageColumns_id').dropdown('toggle');
    },
    updateUserreports: function(report, reportDeleted) {
      var self = this;
      var session = this.get('session');
      self.set('isLoading', true);
      this.get('store').query('userreport', {
        created_user_id: session.get('authenticated.user_id'),
        url: self.get('thisUrl'),
        page_size: '500',
        sort: 'title'
      }).then(function(data) {
        self.set('userreports', data);
        self.setupUserReport(report, reportDeleted);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error updating reports!',
          type: 'error'
        });
        self.set('isLoading', false);
        return null;
      });
    },
    updateUserreportshares: function(report, reportDeleted) {
      var self = this;
      var session = this.get('session');
      self.set('isLoading', true);
      this.get('store').query('sharedreport', {
        user_id: session.get('authenticated.user_id'),
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }).then(function(data) {
        self.set('userreportshares', data);
        self.setupUserReport(report, reportDeleted);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error updating shared reports!',
          type: 'error'
        });
        self.set('isLoading', false);
        return null;
      });
    },
    shareUserreport: function(report) {
      var self = this;
      this.get('store').query('userreportshare', {
        user_report_id: report.id,
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }).then(function(data) {
        self.showUserreportShareModal(report, data);
      }, function() {
        noty({
          text: 'Error getting shared userreports!',
          type: 'error',
          timeout: '5000'
        });
      });
    },
    showUserreportShareModal: function(report, data) {
      this.showUserreportShareModal(report, data);
    },
    unlinkUserreportshare: function(report) {
      var self = this;
      var session = this.get('session');
      pretty_confirm('Are you sure you want to delete this Shared Report?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        report.deleteRecord();
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/userreportshares/' + report.get('id'),
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          noty({
            text: 'Shared Report deleted successfully!'
          });
          if (report.get('user_report.id') === self.get('userreport.id')) {
            self.send('updateUserreportshares', null, true);
          } else {
            self.send('updateUserreportshares', self.get('userreport'), true);
          }
          self.set('isLoading', false);
        }, function() {
          report.rollbackAttributes();
          noty({
            text: 'Error deleting Shared Report!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
  }


});
