import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

let required = [];
export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  requiredFields: [],

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      hostedpaymentpage: store.createRecord('hostedpaymentpage', {
        parent_send_message: 1
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('isPreviewing', false);
    var hostedpaymentpage = models.hostedpaymentpage;
    if (transition.to.queryParams.selectedProductID) {
      controller.set('noTempateSelected', true);
      hostedpaymentpage.set('product_transaction_id', transition.to.queryParams.selectedProductID);
      hostedpaymentpage.set('location_id', models.location.get('id'));
    } else {
      this.transitionTo('locations.hpp-index', models.location.get('id'));
    }

    var unRemovableFieldsLodging = ['room_num', 'room_rate', 'checkin_date', 'checkout_date'];
    var unRemovableFields = ['transaction_amount', 'is_company', 'exp_date', 'captcha', 'submit_button', 'terms_agree'];

    var product_transactions = models.location.get('product_transactions');
    var product;
    var productLogo;
    let requiredFields = [];
    product_transactions.map(function(prod) {
      if (prod.get('id') === transition.to.queryParams.selectedProductID) {
        const terminals = prod.get('processor_data.terminals');
        if (terminals && terminals.length) {
          terminals.forEach((terminal) => {
            if (terminal.sec_code === 'WEB') {
              requiredFields = terminal.required;
              const dlLicenseIndex = terminal.required.indexOf('driver_license');
              const idVerifyIndex = terminal.required.indexOf('identity_verification');
              if (dlLicenseIndex > 0) {
                terminal.required[dlLicenseIndex] = 'dl_number';
                terminal.required.push('dl_state');
              }
              if (idVerifyIndex > 0) {
                terminal.required[idVerifyIndex] = 'ssn4';
              }
              unRemovableFields = unRemovableFields.concat(terminal.required);
            }
          });
        }
        product = prod;

        if (product.get('industry_type') === 'lodging') {
          unRemovableFields = unRemovableFields.concat(unRemovableFieldsLodging);
        }
        // Check if payment method is not ach and if the field is already present in the array to avoid duplicate fields.
        if (product.get('vt_require_street')
          && product.get('payment_method') !== 'ach'
          && unRemovableFields.indexOf('billing_street') === -1
        ) {
          unRemovableFields.push('billing_street');
        }
        // Check if payment method is not ach and if the field is already present in the array to avoid duplicate fields.
        if (product.get('vt_require_zip')
          && product.get('payment_method') !== 'ach'
          && unRemovableFields.indexOf('billing_zip') === -1
        ) {
          unRemovableFields.push('billing_zip');
        }
        if (product.get('auto_decline_cvv')) {
          unRemovableFields.push('cvv');
        }

        productLogo = prod.get('receipt_logo') ? prod.get('receipt_logo') + "?" + moment().valueOf() : 'Image Here';
        var isLodging = prod.get('industry_type') === 'lodging';
        var paymentFormVertical = createForm('payment', 'vertical', product, {
          header: {
            columns: 2,
            rows: 1
          },
          body: {
            columns: 2,
            rows: prod.get('payment_method') === 'ach' ? (prod.get('isSubProcessorPaya') ? 11 : 10) : (isLodging ? 11 : 7)
          },
          footer: {
            columns: 2,
            rows: (prod.get('receipt_vt_above_signature') && prod.get('receipt_vt_above_signature') !== null) ? 2 : 1
          }
        }, unRemovableFields);
        var paymentFormSimpleVertical = createForm('payment_simple', 'vertical', product, {
          header: {
            columns: prod.get('isSubProcessorPaya') ? 2 : 1,
            rows: 2
          },
          body: {
            columns: prod.get('isSubProcessorPaya') ? 2 : 1,
            rows: prod.get('payment_method') === 'ach' ? (prod.get('isSubProcessorPaya') ? 10 : 9) : (isLodging ? 11 : 7)
          },
          footer: {
            columns: prod.get('isSubProcessorPaya') ? 2 : 1,
            rows: (prod.get('receipt_vt_above_signature') && prod.get('receipt_vt_above_signature') !== null) ? 2 : 1
          }
        }, unRemovableFields);
        var paymentFormHorizontal = createForm('payment', 'horizontal', product, {
          header: {
            columns: 4,
            rows: 1
          },
          body: {
            columns: 4,
            rows: 8
          },
          footer: {
            columns: 4,
            rows: (prod.get('receipt_vt_above_signature') && prod.get('receipt_vt_above_signature') !== null) ? 2 : 1
          }
        }, unRemovableFields);
        var paymentFormSimpleHorizontal = createForm('payment_simple', 'horizontal', product, {
          header: {
            columns: 4,
            rows: 1
          },
          body: {
            columns: 4,
            rows: prod.get('isSubProcessorPaya') ? 7 : 4
          },
          footer: {
            columns: 4,
            rows: (prod.get('receipt_vt_above_signature') && prod.get('receipt_vt_above_signature') !== null) ? 2 : 1
          }
        }, unRemovableFields);
        var donationFormVertical = createForm('donation', 'vertical', product, {
          header: {
            columns: 2,
            rows: 1
          },
          body: {
            columns: prod.get('isSubProcessorPaya') ? 2 : 1,
            rows: prod.get('payment_method') === 'ach' ? (prod.get('isSubProcessorPaya') ? 10 : 9) : 5
          },
          footer: {
            columns: 2,
            rows: (prod.get('receipt_vt_above_signature') && prod.get('receipt_vt_above_signature') !== null) ? 2 : 1
          }
        }, unRemovableFields);

        controller.set('template_paymentform_vertical', paymentFormVertical);
        controller.set('template_paymentform_simple_vertical', paymentFormSimpleVertical);
        controller.set('template_paymentform_horizontal', paymentFormHorizontal);
        controller.set('template_paymentform_simple_horizontal', paymentFormSimpleHorizontal);
        controller.set('template_donationform_vertical', donationFormVertical);
      }
    });

    controller.set('isHowToUse', false);
    controller.set('isPreviewing', false);
    controller.set('product', product);
    controller.set('hostedpaymentpage', hostedpaymentpage);
    controller.set('unRemovableFields', unRemovableFields);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Hosted Payment Page',
      route_name: 'locations.hpp-add',
      route_id: models.location.get('id')
    });

    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Hosted Payment Pages',
      link: 'locations.hpp-index',
      record: models.location.get('id')
    }, {
      title: 'Add Hosted Payment Pages',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});


function createForm(type, stack, product, rowsColsSettings, requiredFields = []) {
  var fieldConfiguration = {
    css_mini: false,
    stack: stack,
    header: {
      settings: {
        enabled: true,
        columns: rowsColsSettings.header.columns,
        rows: rowsColsSettings.header.rows
      },
      fields: []
    },
    body: {
      settings: {
        enabled: true,
        columns: rowsColsSettings.body.columns,
        rows: rowsColsSettings.body.rows
      },
      fields: []
    },
    footer: {
      settings: {
        enabled: true,
        columns: rowsColsSettings.footer.columns,
        rows: rowsColsSettings.footer.rows
      },
      fields: []
    },
  };
  var headerFields = fieldConfiguration.header.fields;
  var bodyFields = fieldConfiguration.body.fields;
  var footerFields = fieldConfiguration.footer.fields;
  if (product.get('payment_method') === 'cc') {
    if (type === 'payment_simple') {
      if (stack === 'vertical') {
        if(product.get('industry_type') === 'lodging') {
          setFields(fields_and_positions_paymentform_simple_vertical_with_lodging_cc(product), headerFields, bodyFields, footerFields, product);
        } else {
          setFields(fields_and_positions_paymentform_simple_vertical_cc(product), headerFields, bodyFields, footerFields, product);
        }
      } else {
        if(product.get('industry_type') === 'lodging') {
          setFields(fields_and_positions_paymentform_simple_horizontal_with_lodging_cc(product), headerFields, bodyFields, footerFields, product);
        } else {
          setFields(fields_and_positions_paymentform_simple_horizontal_cc(product), headerFields, bodyFields, footerFields, product);
        }
      }
    } else if (type === 'payment' && stack === 'vertical') {
      if (product.get('industry_type') === 'lodging') {
        setFields(fields_and_positions_paymentform_vertical_with_lodging_cc(product), headerFields, bodyFields, footerFields, product);
      } else {
        setFields(fields_and_positions_paymentform_vertical_cc(product), headerFields, bodyFields, footerFields, product);
      }
    } else if (type === 'payment' && stack === 'horizontal') {
      if (product.get('industry_type') === 'lodging') {
        setFields(fields_and_positions_paymentform_horizontal_with_lodging_cc(product), headerFields, bodyFields, footerFields, product);
      } else {
        setFields(fields_and_positions_paymentform_horizontal_cc(product), headerFields, bodyFields, footerFields, product);
      }
    } else if (type === 'donation') {
      setFields(fields_and_positions_donationform_vertical_cc(product), headerFields, bodyFields, footerFields, product);
    }
  } else if (product.get('payment_method') === 'ach') {
    if (type === 'payment_simple') {
      if (stack === 'vertical') {
        setFields(fields_and_positions_paymentform_simple_vertical_ach(product, requiredFields), headerFields, bodyFields, footerFields, product);
      } else {
        setFields(fields_and_positions_paymentform_simple_horizontal_ach(product, requiredFields), headerFields, bodyFields, footerFields, product);
      }
    } else if (type === 'payment' && stack === 'vertical') {
      setFields(fields_and_positions_paymentform_vertical_ach(product, requiredFields), headerFields, bodyFields, footerFields, product);
    } else if (type === 'payment' && stack === 'horizontal') {
      setFields(fields_and_positions_paymentform_horizontal_ach(product, requiredFields), headerFields, bodyFields, footerFields, product);
    } else if (type === 'donation') {
      setFields(fields_and_positions_donationform_vertical_ach(product, requiredFields), headerFields, bodyFields, footerFields, product);
    }
  }
  return fieldConfiguration;
}

function setFields(fieldConfigFields, headerFields, bodyFields, footerFields, product) {
  fieldConfigFields.map(function(section, index) {
    section.map(function(field) {
      let tempField = JSON.parse(JSON.stringify(setPosition(field.field, field.position)));
      var possibleProductRequires = ['billing_street', 'billing_zip', 'cvv'];
      if (tempField.id === 'header_logo') {
        tempField.link = product.get('receipt_logo') ? product.get('receipt_logo') + "?" + moment().valueOf() : 'Image Here';
      } else if (possibleProductRequires.indexOf(tempField.id) !== -1 && product.get('payment_method') === 'cc') {
        tempField.required = checkProductRequires(product, tempField.id);
      }

      let fields;
      if (index === 0) {
        fields = headerFields;
      } else if (index === 1) {
        fields = bodyFields;
      } else if (index === 2) {
        fields = footerFields;
      }
      fields = addField(fields, tempField);
    });
  });
}

function checkProductRequires(product, id) {
  var value;
  switch (id) {
    case 'billing_street':
      value = product.get('vt_require_street');
      break;
    case 'billing_zip':
      value = product.get('vt_require_zip');
      break;
    case 'cvv':
      value = product.get('vt_require_cvv');
      break;
    case 'auto_decline_cvv':
      value = product.get('auto_decline_cvv');
      break;
  }
  return value;
}

function addField(arr, field) {
  arr.push(field);
  return arr;
}

function setPosition(field, position) {
  field.position = position;
  return field;
}

function isRequired(fieldId, requiredFields = []) {
  if (requiredFields.length && fieldId.length) {
    return requiredFields.includes(fieldId);
  }
}

function accountHolderNameACH(required) {
  return {
    id: 'account_holder_name',
    label: 'Account Holder Name',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function accountNumberAch(required) {
  return {
    id: 'account_number',
    label: 'Account Number',
    field_type: 'inputText',
    required: required,
    confirmRequired: false,
    readonly: false,
    visible: true,
    value: ''
  };
}

function routingACH(required) {
  return {
    id: 'routing',
    label: 'Routing Number',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function accountTypeACH(required) {
  return {
    id: 'account_type',
    label: 'Account Type',
    field_type: 'inputRadio',
    required: required,
    readonly: false,
    visible: true,
    value: '',
    options: ['Checking', 'Savings']
  };
}

function billingStreetACH(required) {
  return {
    id: 'billing_street',
    label: 'Billing Street',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function billingCityACH(required) {
  return {
    id: 'billing_city',
    label: 'Billing City',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function billingStateACH(required) {
  return {
    id: 'billing_state',
    label: 'Billing State',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function billingZipACH(required) {
  return {
    id: 'billing_zip',
    label: 'Billing Zip',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function billingPhoneACH(required) {
  return {
    id: 'billing_phone',
    label: 'Billing Phone',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
    value: ''
  };
}

function drivingLicenseACH(required) {
  return {
    id: 'dl_number',
    label: 'Driver\'s License Number',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
  };
}

function drivingStateACH(required) {
  return {
    id: 'dl_state',
    label: 'Driver\'s License State',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
  };
}

function checkNumberACH(required) {
  return {
    id: 'check_number',
    label: 'Check Number',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
  };
}

function identityFieldACH(required) {
  return {
    id: 'ssn4',
    label: 'SSN Last Four',
    field_type: 'inputText',
    required: required,
    readonly: false,
    visible: true,
  }
}

var headerLogo = {
  id: 'header_logo',
  label: 'Logo',
  field_type: 'image',
  link: '',
  visible: true,
};
var headerTile = {
  id: 'header_title',
  label: 'Form Title',
  field_type: 'heading',
  visible: true,
};
var paymentInfo = {
  id: 'heading_payment_info',
  label: 'Payment Information',
  field_type: 'heading',
  visible: true,
};
var transactionAmount = {
  id: 'transaction_amount',
  label: 'Transaction Amount',
  field_type: 'inputText',
  required: required,
  readonly: false,
  visible: true,
  value: ''
};
var transactionAmountDonation = {
  id: 'transaction_amount',
  label: 'Donation Amount',
  field_type: 'inputSelect',
  required: true,
  readonly: false,
  visible: true,
  value: '',
  placeholder: 'Other',
  options: ['10', '25', '50', '100', 'other']
};
var accountHolderName = {
  id: 'account_holder_name',
  label: 'Account Holder Name',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
  value: ''
};
var accountNumberCC = {
  id: 'account_number',
  label: 'Card Number',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
  value: ''
};
var accountNumberACH = {
  id: 'account_number',
  label: 'Account Number',
  field_type: 'inputText',
  required: true,
  confirmRequired: false,
  readonly: false,
  visible: true,
  value: ''
};
var expDate = {
  id: 'exp_date',
  label: 'Exp Date',
  field_type: 'inputSelect',
  required: true,
  readonly: false,
  visible: true
};
var cvv = {
  id: 'cvv',
  label: 'CVV',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var accountType = {
  id: 'account_type',
  label: 'Account Type',
  field_type: 'inputRadio',
  required: true,
  readonly: false,
  visible: true,
  value: '',
  options: ['Checking', 'Savings']
};
var isCompany = {
  id: 'is_company',
  label: 'Business/Personal Account',
  field_type: 'inputRadio',
  required: true,
  readonly: false,
  visible: true,
  value: '',
  options: ['Personal', 'Business']
};
var routingNumber = {
  id: 'routing',
  label: 'Routing Number',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
  value: ''
};
var billingInfo = {
  id: 'heading_billing_info',
  label: 'Billing Information',
  field_type: 'heading',
  visible: true,
};
var billingStreet = {
  id: 'billing_street',
  label: 'Billing Street',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var billingCity = {
  id: 'billing_city',
  label: 'Billing City',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var billingState = {
  id: 'billing_state',
  label: 'Billing State',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var billingZip = {
  id: 'billing_zip',
  label: 'Billing Zip',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var billingPhone = {
  id: 'billing_phone',
  label: 'Billing Phone',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
  value: ''
};
var captcha = {
  id: 'captcha',
  label: 'Captcha',
  field_type: 'captcha',
  required: true,
  readonly: false,
  visible: true,
  value: ''
};
var submitButton = {
  id: 'submit_button',
  label: 'Submit Payment',
  field_type: 'button',
  visible: true,
};
var roomNum = {
  id: 'room_num',
  label: 'Room Number',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
};
var roomRate = {
  id: 'room_rate',
  label: 'Room Rate',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
};
var checkinDate = {
  id: 'checkin_date',
  label: 'Checkin Date',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
};
var checkoutDate = {
  id: 'checkout_date',
  label: 'Checkout Date',
  field_type: 'inputText',
  required: true,
  readonly: false,
  visible: true,
};
var checkNumber = {
  id: 'check_number',
  label: 'Check Number',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var dlState = {
  id: 'dl_state',
  label: 'Driver\'s License State',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var dlNumber = {
  id: 'dl_number',
  label: 'Driver\'s License Number',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var ssn4 = {
  id: 'ssn4',
  label: 'SSN Last Four',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var dobYear = {
  id: 'dob_year',
  label: 'Date of Birth Year',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var customField1 = {
  id: 'transaction_c1',
  label: 'Custom Field 1',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var customField2 = {
  id: 'transaction_c2',
  label: 'Custom Field 2',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var customField3 = {
  id: 'transaction_c3',
  label: 'Custom Field 3',
  field_type: 'inputText',
  required: false,
  readonly: false,
  visible: true,
};
var termsAgree = {
  id: 'terms_agree',
  label: 'Authorization Verbiage',
  field_type: 'inputCheckbox',
  required: true,
  readonly: false,
  visible: true,
  value: false
};

var fields_and_positions_paymentform_simple_vertical_cc = function(product) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var isLodging = product.get('industry_type') === 'lodging';
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['1', '0', '1', '1']
  }];
  var bodyFields = [{
    field: transactionAmount,
    position: ['0', '0', '1', '1']
  }, {
    field: accountNumberCC,
    position: ['1', '0', '1', '1']
  }, {
    field: expDate,
    position: ['2', '0', '1', '1']
  }, {
    field: cvv,
    position: ['3', '0', '1', '1']
  }, {
    field: billingStreet,
    position: ['4', '0', '1', '1']
  }, {
    field: billingZip,
    position: ['5', '0', '1', '1']
  }, {
    field: captcha,
    position: isLodging ? ['10', '0', '1', '1'] : ['6', '0', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '0', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '0', '1', '1'] : ['0', '0', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_simple_vertical_ach = function(product, requiredFields = []) {
  var isPaya = product.get('isSubProcessorPaya');
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: isPaya ? ['0', '1', '1', '1'] : ['1', '0', '1', '1']
  }];
  var bodyFields = [{
    field: transactionAmount,
    position: ['0', '0', '1', '1']
  }, {
    field: accountTypeACH(isRequired('account_type', requiredFields)),
    position: ['1', '0', '1', '1']
  }, {
    field: isCompany,
    position: ['2', '0', '1', '1']
  }, {
    field: accountHolderNameACH(isRequired('account_holder_name', requiredFields)),
    position: ['3', '0', '1', '1']
  }, {
    field: routingACH(isRequired('routing', requiredFields)),
    position: ['4', '0', '1', '1']
  }, {
    field: accountNumberAch(isRequired('account_number', requiredFields)),
    position: ['5', '0', '1', '1']
  }, {
    field: billingStreetACH(isRequired('billing_street', requiredFields)),
    position: isPaya ? ['0', '1', '1', '1'] : ['6', '0', '1', '1']
  }, {
    field: billingZipACH(isRequired('billing_zip', requiredFields)),
    position: isPaya ? ['3', '1', '1', '1'] : ['7', '0', '1', '1']
  }, {
    field: captcha,
    position: isPaya ? ['9', '1', '1', '1'] : ['8', '0', '1', '1']
  }];

  if (isPaya) {
    bodyFields.push({
      field: billingCityACH(isRequired('billing_city', requiredFields)),
      position: ['1', '1', '1', '1']
    });
    bodyFields.push({
      field: billingStateACH(isRequired('billing_state', requiredFields)),
      position: ['2', '1', '1', '1']
    });
    bodyFields.push({
      field: billingPhoneACH(isRequired('billing_phone', requiredFields)),
      position: ['4', '1', '1', '1']
    });
    bodyFields.push({
      field: drivingStateACH(isRequired('dl_state', requiredFields)),
      position: ['5', '1', '1', '1']
    });
    bodyFields.push({
      field: drivingLicenseACH(isRequired('dl_number', requiredFields)),
      position: ['6', '1', '1', '1']
    });
    bodyFields.push({
      field: identityFieldACH(isRequired('ssn4', requiredFields)),
      position: ['7', '1', '1', '1']
    });
    bodyFields.push({
      field: dobYear,
      position: ['8', '1', '1', '1']
    });
    bodyFields.push({
      field: checkNumberACH(isRequired('check_number', requiredFields)),
      position: ['6', '0', '1', '1']
    });
    bodyFields.push({
      field: customField1,
      position: ['7', '0', '1', '1']
    });
    bodyFields.push({
      field: customField2,
      position: ['8', '0', '1', '1']
    });
    bodyFields.push({
      field: customField3,
      position: ['9', '0', '1', '1']
    });
  }

  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '0', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: (isPaya || hasTermsAgree) ? ['1', '0', '1', '1'] : ['0', '0', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_simple_horizontal_cc = function(product) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var isLodging = product.get('industry_type') === 'lodging';
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '3', '1', '1']
  }];
  var bodyFields = [{
    field: transactionAmount,
    position: ['0', '0', '1', '1']
  }, {
    field: accountNumberCC,
    position: ['0', '1', '1', '1']
  }, {
    field: expDate,
    position: ['0', '2', '1', '1']
  }, {
    field: cvv,
    position: ['0', '3', '1', '1']
  }, {
    field: billingStreet,
    position: isLodging ? ['2', '0', '1', '1'] : ['1', '0', '1', '1']
  }, {
    field: billingZip,
    position: isLodging ? ['2', '1', '1', '1'] : ['1', '1', '1', '1']
  }, {
    field: captcha,
    position: isLodging ? ['2', '3', '1', '1'] : ['1', '3', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '3', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '3', '1', '1'] : ['0', '3', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_simple_horizontal_ach = function(product, requiredFields = []) {
  var isPaya = product.get('isSubProcessorPaya');
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '3', '1', '1']
  }];
  var bodyFields = [{
    field: transactionAmount,
    position: ['0', '0', '1', '1']
  }, {
    field: accountTypeACH(isRequired('account_type', requiredFields)),
    position: ['0', '1', '1', '1']
  }, {
    field: isCompany,
    position: ['0', '2', '1', '1']
  }, {
    field: accountHolderNameACH(isRequired('account_holder_name', requiredFields)),
    position: ['1', '0', '1', '1']
  }, {
    field: routingACH(isRequired('routing', requiredFields)),
    position: ['1', '1', '1', '1']
  }, {
    field: accountNumberAch(isRequired('account_number', requiredFields)),
    position: ['1', '2', '1', '1']
  }, {
    field: billingStreetACH(isRequired('billing_street', requiredFields)),
    position: ['3', '0', '1', '1']
  }, {
    field: billingZipACH(isRequired('billing_zip', requiredFields)),
    position: isPaya ? ['4', '0', '1', '1'] : ['3', '1', '1', '1']
  }, {
    field: captcha,
    position: isPaya ? ['6', '3', '1', '1'] : ['3', '2', '1', '1']
  }];
  if (isPaya) {
    bodyFields.push({
      field: billingCityACH(isRequired('billing_city', requiredFields)),
      position: ['3', '1', '1', '1']
    });
    bodyFields.push({
      field: billingStateACH(isRequired('billing_state', requiredFields)),
      position: ['3', '2', '1', '1']
    });
    bodyFields.push({
      field: billingPhoneACH(isRequired('billing_phone', requiredFields)),
      position: ['4', '1', '1', '1']
    });
    bodyFields.push({
      field: drivingStateACH(isRequired('dl_state', requiredFields)),
      position: ['5', '0', '1', '1']
    });
    bodyFields.push({
      field: drivingLicenseACH(isRequired('dl_number', requiredFields)),
      position: ['5', '1', '1', '1']
    });
    bodyFields.push({
      field: identityFieldACH(isRequired('ssn4', requiredFields)),
      position: ['5', '2', '1', '1']
    });
    bodyFields.push({
      field: dobYear,
      position: ['5', '3', '1', '1']
    });
    bodyFields.push({
      field: checkNumberACH(isRequired('check_number', requiredFields)),
      position: ['1', '3', '1', '1']
    });
    bodyFields.push({
      field: customField1,
      position: ['2', '0', '1', '1']
    });
    bodyFields.push({
      field: customField2,
      position: ['2', '1', '1', '1']
    });
    bodyFields.push({
      field: customField3,
      position: ['2', '2', '1', '1']
    });
  }
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '3', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '3', '1', '1'] : ['0', '3', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_vertical_cc = function(product) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '1', '1', '1']
  }];
  var bodyFields = [{
    field: paymentInfo,
    position: ['0', '0', '1', '1']
  }, {
    field: transactionAmount,
    position: ['1', '0', '1', '1']
  }, {
    field: accountNumberCC,
    position: ['2', '0', '1', '1']
  }, {
    field: expDate,
    position: ['3', '0', '1', '1']
  }, {
    field: cvv,
    position: ['4', '0', '1', '1']
  }, {
    field: billingInfo,
    position: ['0', '1', '1', '1']
  }, {
    field: billingStreet,
    position: ['1', '1', '1', '1']
  }, {
    field: billingCity,
    position: ['2', '1', '1', '1']
  }, {
    field: billingState,
    position: ['3', '1', '1', '1']
  }, {
    field: billingZip,
    position: ['4', '1', '1', '1']
  }, {
    field: captcha,
    position: ['5', '1', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '1', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '1', '1', '1'] : ['0', '1', '1', '1']
  });

  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_vertical_ach = function(product, requiredFields = []) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '1', '1', '1']
  }];
  var bodyFields = [{
    field: paymentInfo,
    position: ['0', '0', '1', '1']
  }, {
    field: transactionAmount,
    position: ['1', '0', '1', '1']
  }, {
    field: accountType,
    position: ['2', '0', '1', '1']
  }, {
    field: isCompany,
    position: ['3', '0', '1', '1']
  }, {
    field: accountHolderName,
    position: ['4', '0', '1', '1']
  }, {
    field: routingNumber,
    position: ['5', '0', '1', '1']
  }, {
    field: accountNumberACH,
    position: ['6', '0', '1', '1']
  }, {
    field: billingInfo,
    position: ['0', '1', '1', '1']
  }, {
    field: billingStreet,
    position: ['1', '1', '1', '1']
  }, {
    field: billingCity,
    position: ['2', '1', '1', '1']
  }, {
    field: billingState,
    position: ['3', '1', '1', '1']
  }, {
    field: billingZip,
    position: ['4', '1', '1', '1']
  }, {
    field: captcha,
    position: ['5', '1', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '1', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '1', '1', '1'] : ['0', '1', '1', '1']
  });

  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_horizontal_cc = function(product) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '3', '1', '1']
  }];
  var bodyFields = [{
    field: paymentInfo,
    position: ['0', '0', '1', '1']
  }, {
    field: transactionAmount,
    position: ['1', '0', '1', '1']
  }, {
    field: accountNumberCC,
    position: ['1', '1', '1', '1']
  }, {
    field: expDate,
    position: ['1', '2', '1', '1']
  }, {
    field: cvv,
    position: ['1', '3', '1', '1']
  }, {
    field: billingInfo,
    position: ['4', '0', '1', '1']
  }, {
    field: billingStreet,
    position: ['5', '0', '1', '1']
  }, {
    field: billingCity,
    position: ['5', '1', '1', '1']
  }, {
    field: billingState,
    position: ['5', '2', '1', '1']
  }, {
    field: billingZip,
    position: ['5', '3', '1', '1']
  }, {
    field: captcha,
    position: ['7', '0', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '3', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '3', '1', '1'] : ['0', '3', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_paymentform_horizontal_ach = function(product, requiredFields = []) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '3', '1', '1']
  }];
  var bodyFields = [{
    field: paymentInfo,
    position: ['0', '0', '1', '1']
  }, {
    field: transactionAmount,
    position: ['1', '0', '1', '1']
  }, {
    field: accountType,
    position: ['1', '1', '1', '1']
  }, {
    field: isCompany,
    position: ['1', '2', '1', '1']
  }, {
    field: accountHolderName,
    position: ['2', '0', '1', '1']
  }, {
    field: routingNumber,
    position: ['2', '1', '1', '1']
  }, {
    field: accountNumberACH,
    position: ['2', '2', '1', '1']
  }, {
    field: billingInfo,
    position: ['4', '0', '1', '1']
  }, {
    field: billingStreet,
    position: ['5', '0', '1', '1']
  }, {
    field: billingCity,
    position: ['5', '1', '1', '1']
  }, {
    field: billingState,
    position: ['5', '2', '1', '1']
  }, {
    field: billingZip,
    position: ['5', '3', '1', '1']
  }, {
    field: captcha,
    position: ['7', '0', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '3', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '3', '1', '1'] : ['0', '3', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var paymentform_vertical_lodging_cc = function(type) {
  var isSimple = type === 'simple';
  return [{
    field: roomNum,
    position: isSimple ? ['6', '0', '1', '1'] : ['5', '0', '1', '1']
  }, {
    field: roomRate,
    position: isSimple ? ['7', '0', '1', '1'] : ['6', '0', '1', '1']
  }, {
    field: checkinDate,
    position: isSimple ? ['8', '0', '1', '1'] : ['7', '0', '1', '1']
  }, {
    field: checkoutDate,
    position: isSimple ? ['9', '0', '1', '1'] : ['8', '0', '1', '1']
  }];
};
var paymentform_horizontal_lodging_cc = function(type) {
  var isSimple = type === 'simple';
  return [{
    field: roomNum,
    position: isSimple ? ['1', '0', '1', '1'] : ['2', '0', '1', '1']
  }, {
    field: roomRate,
    position: isSimple ? ['1', '1', '1', '1'] : ['2', '1', '1', '1']
  }, {
    field: checkinDate,
    position: isSimple ? ['1', '2', '1', '1'] : ['2', '2', '1', '1']
  }, {
    field: checkoutDate,
    position: isSimple ? ['1', '3', '1', '1'] : ['2', '3', '1', '1']
  }];
};
var fields_and_positions_donationform_vertical_cc = function(product) {
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '1', '1', '1']
  }];
  var bodyFields = [{
    field: transactionAmountDonation,
    position: ['0', '0', '1', '1']
  }, {
    field: accountNumberCC,
    position: ['1', '0', '1', '1']
  }, {
    field: expDate,
    position: ['2', '0', '1', '1']
  }, {
    field: cvv,
    position: ['3', '0', '1', '1']
  }, {
    field: captcha,
    position: ['4', '0', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '1', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '1', '1', '1'] : ['0', '1', '1', '1']
  });
  return [headerFields, bodyFields, footerFields];
};
var fields_and_positions_donationform_vertical_ach = function(product, requiredFields = []) {
  var isPaya = product.get('isSubProcessorPaya');
  var hasTermsAgree = product.get('receipt_vt_above_signature') && product.get('receipt_vt_above_signature') !== null;
  var headerFields = [{
    field: headerLogo,
    position: ['0', '0', '1', '1']
  }, {
    field: headerTile,
    position: ['0', '1', '1', '1']
  }];
  var footerFields = [];
  if(hasTermsAgree) {
    footerFields.push({
      field: termsAgree,
      position: ['0', '1', '1', '1']
    });
  }
  footerFields.push({
    field: submitButton,
    position: hasTermsAgree ? ['1', '1', '1', '1'] : ['0', '1', '1', '1']
  });
  var bodyFields = [{
    field: transactionAmountDonation,
    position: ['0', '0', '1', '1']
  }, {
    field: accountTypeACH(isRequired('account_type', requiredFields)),
    position: ['1', '0', '1', '1']
  }, {
    field: isCompany,
    position: ['2', '0', '1', '1']
  }, {
    field: accountHolderNameACH(isRequired('account_holder_name', requiredFields)),
    position: ['3', '0', '1', '1']
  }, {
    field: routingACH(isRequired('routing', requiredFields)),
    position: ['4', '0', '1', '1']
  }, {
    field: accountNumberAch(isRequired('account_number', requiredFields)),
    position: ['5', '0', '1', '1']
  }, {
    field: billingStreetACH(isRequired('billing_street', requiredFields)),
    position: isPaya ? ['0', '1', '1', '1'] : ['6', '0', '1', '1']
  }, {
    field: billingZipACH(isRequired('billing_zip', requiredFields)),
    position: isPaya ? ['3', '1', '1', '1'] : ['7', '0', '1', '1']
  }, {
    field: captcha,
    position: isPaya ? ['9', '1', '1', '1'] : ['8', '0', '1', '1']
  }];
  if (isPaya) {
    bodyFields.push({
      field: billingCityACH(isRequired('billing_city', requiredFields)),
      position: ['1', '1', '1', '1']
    });
    bodyFields.push({
      field: billingStateACH(isRequired('billing_state', requiredFields)),
      position: ['2', '1', '1', '1']
    });
    bodyFields.push({
      field: billingPhoneACH(isRequired('billing_phone', requiredFields)),
      position: ['4', '1', '1', '1']
    });
    bodyFields.push({
      field: drivingStateACH(isRequired('dl_state', requiredFields)),
      position: ['5', '1', '1', '1']
    });
    bodyFields.push({
      field: drivingLicenseACH(isRequired('dl_number', requiredFields)),
      position: ['6', '1', '1', '1']
    });
    bodyFields.push({
      field: identityFieldACH(isRequired('ssn4', requiredFields)),
      position: ['7', '1', '1', '1']
    });
    bodyFields.push({
      field: dobYear,
      position: ['8', '1', '1', '1']
    });
    bodyFields.push({
      field: checkNumberACH(isRequired('check_number', requiredFields)),
      position: ['6', '0', '1', '1']
    });
    bodyFields.push({
      field: customField1,
      position: ['7', '0', '1', '1']
    });
    bodyFields.push({
      field: customField2,
      position: ['8', '0', '1', '1']
    });
    bodyFields.push({
      field: customField3,
      position: ['9', '0', '1', '1']
    });
  }
  return [headerFields, bodyFields, footerFields];
};

var fields_and_positions_paymentform_vertical_with_lodging_cc = function(product) {
  return addLodging(fields_and_positions_paymentform_vertical_cc(product).slice(), paymentform_vertical_lodging_cc('billing').slice(), 1);
};
var fields_and_positions_paymentform_simple_vertical_with_lodging_cc = function(product) {
  return addLodging(fields_and_positions_paymentform_simple_vertical_cc(product).slice(), paymentform_vertical_lodging_cc('simple').slice(), 1);
};
var fields_and_positions_paymentform_horizontal_with_lodging_cc = function(product) {
  return addLodging(fields_and_positions_paymentform_horizontal_cc(product).slice(), paymentform_horizontal_lodging_cc('billing').slice(), 1);
};
var fields_and_positions_paymentform_simple_horizontal_with_lodging_cc = function(product) {
  return addLodging(fields_and_positions_paymentform_simple_horizontal_cc(product).slice(), paymentform_horizontal_lodging_cc('simple').slice(), 1);
};
function addLodging(paymentFormArr, lodgingArr, addIndex) {
  paymentFormArr[addIndex] = paymentFormArr[addIndex].concat(lodgingArr);
  return paymentFormArr;
}
