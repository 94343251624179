import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        page_size: '1000',
        relationship: 'direct'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locations = models.locations;
    controller.set('locations', locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Contact',
      route_name: 'contacts.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Contact');
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: 'Add Contact',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
