import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/filecategoryform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      filecategory: store.createRecord('filecategory', {
        location_id: params.id
      }),
      visibilitygroups: store.query('visibilitygroup', {
        location_id: params.id
      }),
      filecategoryvisibilitygroups: store.query('filecategoryvisibilitygroup', {
        file_category_id: 'unknown id'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    models.visibilitygroups.forEach(function(group) { // Find all filecategoryvisibilitygroups and add a key to visibilitygroups stating they are checked
      group.set('checked', false);
    });
    controller.set('location', models.location);
    controller.set('filecategory', models.filecategory);
    controller.set('visibilitygroups', models.visibilitygroups);
    controller.set('filecategoryvisibilitygroups', models.filecategoryvisibilitygroups.toArray());
    controller.set('isNew', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-filecategories-delete'),
      canPost: this.get('session.authenticated.resources.v2-filecategories-post'),
      canPut: this.get('session.authenticated.resources.v2-filecategories-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add File Category',
      route_name: 'locations.addfilecategory',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'File Categories',
      link: 'locations.filecategories',
      record: models.location.get('id')
    }, {
      title: 'Add File Category',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.controller.get('filecategory');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
