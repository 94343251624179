import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      domain: store.queryRecord('domain', {
        id: params.view_id,
        expand: 'locations,created_user',
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var domain = models.domain;
    controller.set('domain', domain);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-domains-delete'),
      canPost: this.get('session.authenticated.resources.v2-domains-post'),
      canPut: this.get('session.authenticated.resources.v2-domains-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Domain ● View Domain',
      route_name: 'admin.domains.view',
      route_id: domain.get('id')
    });
    controller.set('pageTitle', 'View Domain');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Domains',
      link: 'admin.domains.index'
    }, {
      title: 'View Domain',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.domain, transition, this);
      return true;
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
