import Ember from "ember";
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    var getProductBillingGroups = function() {
      if (session.get('authenticated.resources.v2-productbillinggroups-get')) {
        return store.query('productbillinggroup', {
          page_size: '200'
        });
      } else {
        return [];
      }
    };
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        expand: 'created_user,parent,branding_domain,product_transactions',
        id: params.id,
      }),
      producttransaction: store.queryRecord('producttransaction', {
        id: params.product_id,
        active: '0,1',
        expand: 'surcharge,product_ach_store,created_user,portfolio,sales_office'
      }),
      productitems: store.query('productitem', {
        model: 'ProductTransaction',
        model_id: params.product_id,
        expand: 'item',
        sort: 'item_id',
        page_size: '100'
      }),
      productbillinggroups: getProductBillingGroups()
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      productbillinggroupitems: store.query('productbillinggroupitem', {
        product_billing_group_id: models.producttransaction.get('product_billing_group_id'),
        expand: 'item',
        page_size: '200'
      }).then(function(data) {
        models.productbillinggroupitems = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransaction', models.producttransaction);
    controller.set('productitems', models.productitems);
    controller.set('productbillinggroups', models.productbillinggroups);

    var oneTimeProductBillingItems = getBillingItemsByType('o', models.productitems);
    controller.set('oneTimeProductBillingItems', oneTimeProductBillingItems);

    var items = models.productbillinggroupitems;
    items = items.sortBy('item.title');
    var oneTimeBillingItems = getBillingItemsByType('o', items);
    var annualBillingItems = getBillingItemsByType('a', items);
    var monthlyBillingItems = getBillingItemsByType('m', items);
    var perOccurrenceBillingItems = getBillingItemsByType('p', items);
    var systemBillingItems = getBillingItemsByType('s', items);

    function getBillingItemsByType(type, billingItems) {
      return billingItems.filter(function(billingItem) {
        var item_id = billingItem.get('item_id');
        return item_id.charAt(0) === type && item_id.charAt(1) === '-';
      });
    }

    var otherBillingItems = [{
      title: 'Annual Billing Items',
      items: annualBillingItems
    }, {
      title: 'Monthly Billing Items',
      items: monthlyBillingItems
    }, {
      title: 'Per Occurrence Billing Items',
      items: perOccurrenceBillingItems
    }, {
      title: 'System Generated Billing Items',
      items: systemBillingItems
    }];

    controller.set('oneTimeBillingItems', oneTimeBillingItems);
    controller.set('otherBillingItems', otherBillingItems);
    controller.set('isEditingBillingItem', false);

    var productitem = controller.getNewProductItem();
    controller.set('newproductitem', productitem);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + models.producttransaction.get('title') + ' ● Billing',
      route_name: 'locations.viewproducttransaction-billing',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransaction.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', "Domain:&nbsp;&nbsp;" + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: models.producttransaction.get('title'),
      link: 'locations.viewproducttransaction',
      record: models.location.get('id'),
      record_plus: models.producttransaction.get('id'),
    }, {
      title: 'Billing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var model = this.currentModel;
      var self = this;

      model.productitems.forEach(function(item) {
        if (item.get('hasDirtyAttributes') && self.get('session.isAuthenticated')) {
          self.controller.set('isEditingBillingItem', true);
          transition.abort();
          pretty_confirm('You have unsaved information. Are you sure you want to leave this page?', function() {
            self.controller.set('isEditingBillingItem', false);
            item.rollback();
            transition.retry();
          });
        }
      });
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
