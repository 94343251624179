import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('productbillinggroup.errors.messages', function() {
    this.set('errorMessages', this.get('productbillinggroup.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,

  // Actions
  actions: {}
});
