import Ember from 'ember';

export default Ember.TextField.extend({
  applicationData: Ember.inject.service('application-data'),

  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  classNames: ['form-control'],

  type: Ember.computed('isMobileSize', function () {
    return this.get('isMobileSize') ? 'number' : 'text';
  }),
});
