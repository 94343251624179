import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'active',
    'cashback_enable',
    'id',
    'last_registration_ts',
    'local_ip_address',
    'mac_address',
    'password',
    'print_enable',
    'serial_number',
    'sig_capture_enable',
    'terminal_cvm_id',
    'terminal_manufacturer_id',
    'terminal_number',
    'tip_enable',
    'title',
    'username',
  ],

  // Actions
  actions: {}
});
