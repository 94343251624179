import Ember from 'ember';
export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service('router'),
  sessionService: Ember.inject.service('session'),
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),
  currentRouteTitle: Ember.computed('domainRoutes.[]', 'isDomainsGeneral', 'isDomainsAttachedLocations', 'isDomainsAllowableAddOns', function() {
    var routes = this.get('domainRoutes');
    var title = '';
    routes.map(function(route){
      if(route.isActive){
        title = route.title;
      }
    });
    return title;
  }),
  domainRoutes: Ember.computed('sessionService.session.authenticated.token', 'isDomainsGeneral', 'isDomainsAttachedLocations', 'isDomainsAllowableAddOns', function() {
    var self = this;
    var session = this.get('session');
    var routes = [];
    if(session.get('authenticated.resources.v2-domains-get')) {
      let routeName = 'admin.domains.view';
      routes.pushObject({
        title: 'General',
        isActive: self.get('isDomainsGeneral') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-domains-get')) {
      let routeName = 'admin.domains.attached-locations';
      routes.pushObject({
        title: 'Attached Locations',
        isActive: self.get('isDomainsAttachedLocations') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-domains-get')) {
      let routeName = 'admin.domains.allowable-add-ons';
      routes.pushObject({
        title: 'Allowable Add-Ons',
        isActive: self.get('isDomainsAllowableAddOns') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),
  // Functions
  getId: function(routeName) {
    if(this.get('router.currentRouteName')) {
      return 'domain_' + routeName.replace('admin.domains.', '').replace('.', '-') + '_tab';
    }
  },
  actions: {
    refreshData: function() {
      this.get('parent').send('refreshData');
    }
  },

}); 
