import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      note: store.createRecord('note', {
        resource_id: params.id,
        resource: 'Location'
      }),
      locationinfos: store.query('locationinfo', {
        page_size: '100',
        relationship: 'direct'
      }),
      visibility_groups: store.query('visibilitygroup', {
        location_id: params.id,
        page_size: '100'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'locations.notes',
      pageModelId: models.location.get('id'),
      modelName: 'note'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));
    
    var gridParams = {
      expand: 'created_user,visibility_group',
      resource: 'Location',
      resource_id: models.location.get('id')
    };
    controller.set('gridParams', gridParams);
    controller.set('location', models.location);
    controller.set('locationinfos', models.locationinfos);
    controller.set('note', models.note);
    controller.set('visibility_groups', models.visibility_groups);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Notes',
      route_name: 'locations.notes',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Notes',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
