import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', this.get('session.authenticated.user.contact_id')),
    });
  },
  afterModel: function(models) {
    return Ember.RSVP.hash({
      locationinfos: this.get('store').query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('contact', models.contact);
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('locationinfos', models.locationinfos);

    var pagerParams = {
      pageRoute: 'contactuser.accounts-index',
      pageModelId: null,
      modelName: 'accountvault',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);

    var gridParams = {
      contact_id: models.contact.get('id'),
      expand: 'created_user,contact,location'
    };
    controller.set('gridParams', gridParams);

    if (!controller.get('active') && controller.get('active') !== 0) {
      controller.set('active', '1');
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Account Vaults',
      route_name: 'contactuser.accounts-index'
    });
    controller.set('pageTitle', 'Account Vaults');
    if(locationinfo.get('hasProductAccountVault')) {
      controller.set('pageSubtitle', {
        title: 'Add Account Vault',
        link: 'contactuser.accounts-add',
        resource: this.get('session.authenticated.resources.v2-accountvaults-post')
      });
    }
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Account Vaults',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

});
