import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  routing: Ember.inject.service('-routing'),
  sessionService: Ember.inject.service('session'),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.set('loggedInUserCanEditViewedUser', this.get('usersMethods').loggedInUserCanEditViewedUser(this.get('user')));
  },

  // Computed Properties

  currentRouteTitle: Ember.computed('userRoutes.[]', 'isUsersView', 'isUsersChangePassword', 'isUsersAuthRoles', 'isUsersPreferences', 'isUsersVisibilityGroups', 'isUsersLocations', 'isUsersEmailLogs', 'isUsersUserreports', 'isUsersFeatureFlags', function() {
    var routes = this.get('userRoutes');
    var title = '';
    routes.map(function(route) {
      if (route.isActive) {
        title = route.title;
      }
    });
    return title;
  }),
  isRouteAdmin: Ember.computed('context', function() {
    return this.get('context') === 'admin';
  }),
  userRoutes: Ember.computed('isRouteAdmin', 'loggedInUserCanEditViewedUser', 'sessionService.session.authenticated.token', 'isUsersView', 'isUsersChangePassword', 'isUsersAuthRoles', 'isUsersPreferences', 'isUsersVisibilityGroups', 'isUsersLocations', 'isUsersEmailLogs', 'isUsersUserreports', 'isUsersFeatureFlags', function() {
    var self = this;
    var session = this.get('session');
    var isRouteAdmin = this.get('isRouteAdmin');
    var user = this.get('user');
    var routes = [];
    if (session.get('authenticated.resources.v2-users-get')) {
      let routeName = isRouteAdmin ? 'admin.users.view' : 'locations.users-view';
      routes.pushObject({
        title: 'User Info',
        isActive: self.get('isUsersView'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (session.get('authenticated.resources.v2-users-post') && this.get('loggedInUserCanEditViewedUser')) {
      let routeName = isRouteAdmin ? 'admin.users.changepassword' : 'locations.users-changepassword';
      routes.pushObject({
        title: 'Change Password',
        isActive: self.get('isUsersChangePassword'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (session.get('authenticated.resources.v2-authroleusers-get')) {
      let routeName = isRouteAdmin ? 'admin.users.authroles' : 'locations.users-authroles';
      routes.pushObject({
        title: 'Roles',
        isActive: self.get('isUsersAuthRoles'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (!isRouteAdmin) {
      if (session.get('authenticated.resources.v2-userprefs-get') && session.get('authenticated.resources.v2-useruserprefs-get')) {
        let routeName = 'locations.users-preferences';
        routes.pushObject({
          title: 'Preferences',
          isActive: self.get('isUsersPreferences'),
          routeName: routeName,
          id: this.getId(routeName)
        });
      }
      if (session.get('authenticated.resources.v2-visibilitygroups-post')) {
        let routeName = 'locations.users-visibilitygroups';
        routes.pushObject({
          title: 'Visibility Groups',
          isActive: self.get('isUsersVisibilityGroups'),
          routeName: routeName,
          id: this.getId(routeName)
        });
      }
    }
    if (session.get('authenticated.resources.v2-locationusers-post')) {
      let routeName = isRouteAdmin ? 'admin.users.locations' : 'locations.users-locations';
      routes.pushObject({
        title: 'Locations',
        isActive: self.get('isUsersLocations'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (session.get('authenticated.resources.v2admin-logemails-get')) {
      let routeName = isRouteAdmin ? 'admin.users.emaillogs' : 'locations.users-emaillogs';
      routes.pushObject({
        title: 'Email Logs',
        isActive: self.get('isUsersEmailLogs'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (session.get('authenticated.resources.v2-userreports-post') && this.get('loggedInUserCanEditViewedUser') && Number(user.get('user_type_id')) > 100) {
      let routeName = isRouteAdmin ? 'admin.users.userreports' : 'locations.users-userreports';
      routes.pushObject({
        title: 'Report List',
        isActive: self.get('isUsersUserreports'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if (session.get('authenticated.resources.v2-featureflags-get')) {
      let routeName = isRouteAdmin ? 'admin.users.featureflags' : 'locations.users-featureflags';
      routes.pushObject({
        title: 'Feature Flags',
        isActive: self.get('isUsersFeatureFlags'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }

    return routes;
  }),

  // Functions

  getId: function(routeName) {
    if(this.get('routing.currentRouteName')) {
      return 'user_' + routeName.replace('users.', '').replace('users-', '').replace('locations.', '').replace('admin.', '').replace('.', '-') + '_tab';
    }
  },

  // Actions
  actions: {
    refreshData: function() {
      this.get('parent').send('refreshData');
    }
  },
});
