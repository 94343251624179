import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('portfolio.errors.messages', function() {
    this.set('errorMessages', this.get('portfolio.errors.messages'));
  }),
  errorMessagesPortfolioItems: null,
  updateErrorMessagesPortfolioItems: Ember.observer('portfolioitem.errors.messages', function() {
    this.set('errorMessagesPortfolioItems', this.get('portfolioitem.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminPortfolios: true,
  isLoading: false,
  isEditing: false,

  updateLocation: Ember.observer('location_id', function() {
    var self = this;
    var portfolioitem = this.get('portfolioitem');
    var location_id = this.get('location_id');
    if (!location_id) {
      return;
    }
    this.get('store').query('item', {
      location_id: location_id,
      page_size: '50'
    }).then(function(result) {
      if (result.get('length') > 0) {
        portfolioitem.set('item_id', result.get('firstObject').get('id'));
      }
      self.set('items', result);
    });
  }),

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var portfolio = this.get('portfolio');
      pretty_confirm('Are you sure you want to delete this Portfolio?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        portfolio.deleteRecord();
        portfolio.save().then(function() {
          portfolio.unloadRecord();
          noty({
            text: 'Portfolio deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.portfolios.index');
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Portfolio!' + error,
            type: 'error',
            timeout: 10000
          });
          portfolio.rollbackAttributes();
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    updateParents: function(data) {
      var self = this;
      var parents = this.get('store').query('portfolio', {
        title: data,
        sort: 'title',
        page_size: '10'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var portfolio = this.get('portfolio');

      portfolio.save().then(function() {
        noty({
          text: 'Portfolio updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.portfolios.index');
    },
    cancel: function() {
      var portfolio = this.get('portfolio');
      portfolio.rollbackAttributes();
      this.set('isEditing', false);
    },
    addItem: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var portfolioitem = this.get('portfolioitem');

      portfolioitem.save().then(function() {
        noty({
          text: 'Portfolio Item added successfully!'
        });
        self.set('isLoading', false);
        self.send('refreshData');
      }, function() {
        self.set('isLoading', false);
      });
    },
    deleteItem: function(pItem) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Portfolio Item?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        pItem.deleteRecord();
        pItem.save().then(function() {
          noty({
            text: 'Portfolio Item deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          pItem.rollbackAttributes();
          noty({
            text: 'Error deleting Portfolio Item!',
            type: 'error'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        });
      });
    },
    addUser: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var portfoliouser = this.get('portfoliouser');

      portfoliouser.save().then(function() {
        noty({
          text: 'Portfolio User added successfully!'
        });
        self.set('isLoading', false);
        self.send('refreshData');
      }, function() {
        noty({
          text: 'Error creating portfoliouser!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    },
    deleteUser: function(pUser) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Portfolio User?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        pUser.deleteRecord();
        pUser.save().then(function() {
          noty({
            text: 'Portfolio User deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          pUser.rollbackAttributes();
          noty({
            text: 'Error deleting Portfolio User!',
            type: 'error'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        });
      });
    },
    updateUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('users', result);
      });
    },
  }
});
