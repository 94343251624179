import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  isDebitCardCheck:false,
  isConvenienceFee:false,
  firstSixChar:"",
  binStatus : false, //true value for debit card.
  surchargeType:'',
  accountVaultCardFlag:false,
  isAccountVaultDebitCheck:false,

  calculateTotal: function(transaction, product, isLoading, isDebitCard, isAccountVaultedCard) {
    var self = this;
    
    if(isAccountVaultedCard)this.getBinDetails(this.firstSixChar,product)
    
    if (isLoading || !transaction) {
      return;
    }
    
    // Do some chesks to see if values only include a decimal, if so, number calculations don't work properly
    if (transaction.get('subtotal_amount') === '.' || transaction.get('tax') === '.' || transaction.get('tip_amount') === '.') {
      return;
    }
    var subtotal_amount = 0;
    var convertedAmount;
    if(transaction.get('subtotal_amount')&&transaction.get('subtotal_amount').includes(','))
    convertedAmount = transaction.get('subtotal_amount').replace(/^[, ]+|[, ]+$|[, ]+/g, "").trim();
    
    if(convertedAmount>0){
      subtotal_amount = parseFloat(convertedAmount);
    }else if (parseFloat(transaction.get('subtotal_amount')) > 0) {
      subtotal_amount = parseFloat(transaction.get('subtotal_amount'));
    }
    // need to strimline this operation

    var surcharge_amount = this.calculateSurcharge(transaction, product,isDebitCard);
    var tax_amount = transaction.get('tax') || 0;
    var tip_amount = transaction.get('tip_amount') || 0;
    if (parseFloat(subtotal_amount + tax_amount + tip_amount + surcharge_amount) === 0) {
      transaction.set('transaction_amount', '');
      return;
    }
    self.isDebitCardCheck = isDebitCard
    // Setting surcharge in the transaction object as this is used from the invoice details page.

    //-----------------Handled for Account vault transaction------------------------------
    if(this.binStatus && isAccountVaultedCard && this.surchargeType.toLowerCase() != 'convenience fee' && this.surchargeType.toLowerCase() != 'none' && this.isAccountVaultDebitCheck){
      transaction.set('surcharge_amount', 0);
      surcharge_amount = 0;
      this.accountVaultCardFlag=isAccountVaultedCard;
    }
    //-----------------Handled for Account vault transaction------------------------------

    transaction.set('current_surcharge', surcharge_amount);
    transaction.set('transaction_amount', String((parseFloat(subtotal_amount) + parseFloat(tax_amount) + parseFloat(tip_amount) + parseFloat(surcharge_amount)).toFixed(2)));
  },

  calculateTax: function(transaction, product, salestax, cause, context,isDebitFlag) {
    var self = this;
    var zip = transaction.get('billing_zip');
    var sales_tax;
    var taxableAmount = 0;
    if (zip && zip.length === 5) {
      var subtotalAmount = transaction.get('subtotal_amount');
      var surchargeAmount = this.calculateSurcharge(transaction, product,isDebitFlag);
      if (product.get('isTaxAfterSurcharge')) {
        taxableAmount = parseFloat(subtotalAmount) + parseFloat(surchargeAmount);
      } else {
        taxableAmount = subtotalAmount;
      }
      var tax_amount = 0;
      if ((!salestax || cause === 'zip') && taxableAmount) {
        context.set('calculatingTax', true);
        this.get('store').query('salestax', {
          location_id: transaction.get('location_id'),
          zip_code: zip
        }).then(function(data) {
          if (!context.get('isDestroyed') && !context.get('isDestroying')) {
            sales_tax = data.get('firstObject');
            if (sales_tax && sales_tax.get('zip_code') === zip) {
              context.set('sales_tax', sales_tax);
              tax_amount = self.calculateTaxFromRate(taxableAmount, sales_tax.get('rate'));
            } else {
              context.set('sales_tax', null);
              tax_amount = 0;
            }
            context.set('transaction.tax', tax_amount);
            context.calculateTotal();
            context.set('calculatingTax', false);
          }
        }, function() {
          context.calculateTotal();
          context.set('calculatingTax', false);
        });
      } else if (salestax && cause !== 'zip') {
        tax_amount = self.calculateTaxFromRate(taxableAmount, salestax.get('rate'));
        context.set('transaction.tax', tax_amount);
        context.calculateTotal();
        context.set('calculatingTax', false);
      } else {
        context.calculateTotal();
        context.set('calculatingTax', false);
      }
    } else {
      if(zip && zip.length != 5){
        context.set('transaction.tax', 0);
      }
      context.calculateTotal();
    }
  },

  calculateTaxFromRate: function(amt, rt) {
    var taxableAmount = parseFloat(amt) || 0;
    var rate = parseFloat(rt) || 0;
    // Calculate tax
    var taxAmount = (taxableAmount * (rate / 100));

    // Ensure proper rounding
    return (+taxAmount).round(2);
},

  calculateSurcharge: function(transaction, product,isDebitFlag) {
    var surcharge = null;
    var self = this;
    if (product) {
      surcharge = product.get('surcharge');
    }
    if (!surcharge || !transaction) {
      return 0;
    }
    
    if (transaction.get('action') === 'refund' && transaction.get('payment_method') !== 'ach') {
      if (!surcharge.get('refund_surcharges')) {
        return 0;
      }
    }
    
    var apply_to_user_type_id = surcharge.get('apply_to_user_type_id');
    var applies_to_user = (apply_to_user_type_id && this.get('session.authenticated.user_type_id').toString() === apply_to_user_type_id) || !apply_to_user_type_id;
    if (!applies_to_user) {
      return 0;
    }
    if(this.isAccountVaultDebitCheck)isDebitFlag = this.isAccountVaultDebitCheck;
    var surchargeType = surcharge.get('is_surcharge') ? surcharge.get('is_surcharge') : "";
    var surcharge_amount = 0;
    var transaction_amount = parseFloat(transaction.get('subtotal_amount')) || 0;
    var min_fee_amount = parseFloat(surcharge.get('min_fee_amount')) || 0;
    var max_fee_amount = parseFloat(surcharge.get('max_fee_amount')) || 0;
    var surcharge_fee = parseFloat(surcharge.get('surcharge_fee')) || 0;
    var surcharge_rate = parseFloat(surcharge.get('surcharge_rate')) || 0;
    var tax_amount = transaction.get('tax') || 0;
    var surchargeableAmount = 0;
    if(surchargeType.length > 0 && (surchargeType.toLowerCase() == "convenience fee" || surchargeType.toLowerCase() == "none")) self.isConvenienceFee = true;
    else self.isConvenienceFee = false;
    if (product.get('isSurchargeAfterTax')) {
      surchargeableAmount = parseFloat(transaction_amount) + parseFloat(tax_amount);
    } else {
      surchargeableAmount = transaction_amount;
    }
    surcharge_amount = (surcharge_rate * surchargeableAmount / 100).round(2) + surcharge_fee;
    surcharge_amount = (surcharge_amount > max_fee_amount) ? max_fee_amount : (surcharge_amount < min_fee_amount) ? min_fee_amount : surcharge_amount;
    if (transaction.get('action') === 'refund' && surcharge.get('refund_surcharges') === 0) {
      surcharge_amount = 0;
    }

    if(isDebitFlag && surchargeType.toLowerCase()=='surcharge'){
      transaction.set('surcharge_amount',0);
      surcharge_amount=0;
    }else{
      transaction.set('surcharge_amount', surcharge_amount.toFixed(2));
    }
    return surcharge_amount;
  },

  getBinDetails: function(firstsix,product){
    var self = this;
    if(firstsix.length>=6){
      this.get('store').findRecord("bin",firstsix).then((bin)=>{
        if(bin.get('card_type') === 'DEBIT'){
          this.binStatus = true;
          this.isAccountVaultDebitCheck = true;
          var surchargeData = ''
          if(product)surchargeData=product.get('surcharge');
          this.surchargeType = surchargeData.get('is_surcharge') ? surchargeData.get('is_surcharge') : "";
        } else {
          this.binStatus = false;
          this.isAccountVaultDebitCheck = false;
        }
      }).catch(function(error){
        self.set("error",error);
      })
    }
  }

});
