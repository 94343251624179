import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  sort: 'next_run_date',
  active: '1,0',
  status: 'active,on hold',
  types: [
    { id: 'o', title: 'Ongoing'},
    { id: 'i', title: 'Installment'}
  ],
  page: 1,
  page_size: '15',
  export_version: 'v1',
  isRefreshing: true,
  showLocations: false,

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'active',
    'contact-first_name',
    'contact-last_name',
    'created_user_id',
    'description',
    'end_date',
    'end_date_from',
    'end_date_to',
    'id',
    'interval',
    'interval_type',
    'last_four',
    'location_id',
    'next_run_date',
    'next_run_date_from',
    'next_run_date_to',
    'payment_method',
    'product_transaction_id',
    'recurring_type_id',
    'start_date',
    'start_date_from',
    'start_date_to',
    'status',
    'tags',
    'transaction_amount',
  ],
  columns: Ember.computed('product_transactions.@each', 'isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      contact_first_name:{
        'name': 'contact.first_name',
        'title': 'Contact<br>First<br>Name',
        'type': 'string',
        'action': function(recurring) {
          self.transitionToRoute('contacts.dashboard', recurring.get('contact.id'));
        },
        'actionpriv': 'v2-contacts-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_last_name: {
        'name': 'contact.last_name',
        'title': 'Contact<br>Last<br>Name',
        'type': 'string',
        'action': function(recurring) {
          self.transitionToRoute('contacts.dashboard', recurring.get('contact.id'));
        },
        'actionpriv': 'v2-contacts-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      account_holder_name: {
        'name': 'account_vault.account_holder_name',
        'title': 'Account<br>Holder<br>Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field:'account_holder_name'
        }
      },
      account_vault_title: {
        'name': 'account_vault.title',
        'title': 'Account<br>Vault',
        'type': 'string',
        'action': function(recurring) {
          self.transitionToRoute('accounts.view', recurring.get('account_vault_id'));
        },
        'actionpriv': 'v2-accountvaults-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'action': function(recurring) {
          self.send('view', recurring);
        },
        'actionId': 'recurring_description_view_link',
        'filter': {
          type: 'text'
        }
      },
      start_date: {
        'name': 'start_date',
        'title': 'Recurring<br>Start',
        'type': 'date',
        'dateFormat': 'MM/DD/YYYY',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersAll',
          multi: false
        }
      },
      end_date: {
        'name': 'end_date',
        'title': 'Recurring<br>End',
        'type': 'date',
        'dateFormat': 'MM/DD/YYYY',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersAll',
          multi: false
        }
      },
      next_run_date: {
        'name': 'next_run_date',
        'title': 'Next<br>Run<br>Date',
        'type': 'date',
        'dateFormat': 'MM/DD/YYYY',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersFuture',
          multi: false
        }
      },
      interval: {
        'name': 'interval',
        'title': 'Frequency',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      interval_type: {
        'name': 'interval_type',
        'title': 'Interval<br>Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'intervals'
        }
      },
      transaction_amount: {
        'name': 'transaction_amount',
        'title': 'Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        },
        'total': true,
        'totalValue': Ember.Object.extend({
          data: null,
          value: Ember.computed('gridData', function() {
            var recurrings = this.get('gridData');
            var ret = 0;
            recurrings.forEach(function(recurring) {
              ret += parseFloat(recurring.get("transaction_amount"));
            });
            return '<div class="nobr">Page Total: $ ' + String(ret).currency(2) + '</div>';
          })
        }).create()
      },
      last_four: {
        'name': 'account_vault.last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'last_four'
        }
      },
      account_type: {
        'name': 'account_vault.account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true,
          field: 'account_type'
        }
      },
      payment_method: {
        'name': 'payment_method',
        'title': 'Payment<br>Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'paymentMethods',
          multi: true,
          showAllSelect: true
        }
      },
      exp_date: {
        'name': 'account_vault.exp_date',
        'title': 'Exp<br>Date',
        'type': 'string',
        'sortable': false,
      },
      tags: {
        'name': 'tags',
        'title': 'Tags',
        'type': 'array',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'tagList',
          labelKey: 'title'
        }
      },
      status: {
        'name': 'status',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'recurring_statuses'
        }
      },
      recurring_type_id: {
        'name': 'recurringTypeTitle',
        'title': 'Recurring Billing<br>Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'types',
          multi: true,
          field: 'recurring_type_id'
        }
      },
      created_user_id: {
        'name': 'created_user_id',
        'title': 'Created<br>User',
        'type': 'string',
        'sortable': true,
        'action': function(recurring) {
          if(session.get('authenticated.isSupport')) {
            self.transitionToRoute('admin.users.view', recurring.get('created_user.id'));
          } else {
            self.transitionToRoute('locations.users-view', recurring.get('location.id'), recurring.get('created_user.id'));
          }
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-users-get' : ['v2-locations-get', 'v2-users-get'],
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'action': function(recurring) {
          self.transitionToRoute('locations.view', recurring.get('location.id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'locations',
          multi: true,
          showAllSelect: true
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(recurring) {
          self.transitionToRoute('locations.viewproducttransaction', recurring.get('location.id'), recurring.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          showAllSelect: true,
          notuniqbytitle: true
        }
      },
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(recurring) {
          self.send('view', recurring);
        },
        'actionId': 'recurring_id_view_link',
        'filter': {
          type: 'text'
        }
      },
    };

    var listOfCols = ['description', 'contact_first_name', 'contact_last_name', 'account_holder_name', 'account_vault_title', 'start_date', 'end_date', 'next_run_date', 'interval', 'interval_type', 'transaction_amount', 'last_four', 'payment_method', 'account_type', 'exp_date', 'tags', 'status', 'recurring_type_id', 'created_user_id', 'location_id', 'product_transaction_id', 'id'];
    var listOfMobileCols = ['description', 'account_holder_name', 'account_vault_title', 'next_run_date', 'transaction_amount', 'location_id', 'product_transaction_id', 'id'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      if (context.get('locations.length') <= 1) {
        colsArray.removeObject(columns.location_id);
      }
      if (context.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
    }
  }),

  // Actions
  actions: {
    view(recurring) {
      this.transitionToRoute('recurrings.view', recurring.get('id'));
    },
  }
});
