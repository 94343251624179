import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        expand: 'terminals',
        sort: 'name',
        page_size: '500',
        relationship: 'direct',
        product_transaction_active: '1,0'
      }),
      users: store.query('user', {
        relationship: 'direct',
        page_size: '50',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000',
        status_id: 1
      }).then(function(data) {
        return data.toArray();
      }),
      tagList: store.query('tag', {
        page_size: '500',
        relationship: 'direct',
        sort: 'title'
      }),
      types: store.findAll('type'),
      statuses: store.findAll('status')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var product_transactions = [];
    models.locations.forEach(function(loc) {
      var prods = loc.get('product_transactions');
      if (prods) {
        prods.forEach(function(product_transaction) {
          product_transactions.push({
            'id': product_transaction.get('id'),
            'title': product_transaction.get('title'),
            'processor': product_transaction.get('processor')
          });
        });
      }
    });
    controller.set('product_transactions', product_transactions);
    var pagerParams = {
      pageRoute: 'reports.transactionslegacy',
      pageModelId: null,
      modelName: 'transaction',
      showExport: this.get('session.authenticated.resources.v2-transactions-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user.id,created_user.username,contact.id,contact.first_name,contact.last_name,contact.account_number,contact.email,surcharge.id,surcharge.surcharge_amount,location.id,location.name,tags,status,type,all_tags,product_transaction,terminal.id,terminal.title,quick_invoice',
      relationship: 'direct',
      show_totals: true
    };
    controller.set('gridParams', gridParams);
    models.users.pushObjects([{
      id: 'terminal',
      title: 'terminal',
      username: 'terminal'
    }, {
      id: 'system',
      title: 'system',
      username: 'system'
    }]);
    controller.set('users', models.users);
    controller.set('locations', models.locations);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);
    controller.set('tagList', models.tagList);

    if((controller.get('created_ts_from') || controller.get('created_ts_to')) && controller.get('created_ts') !== 'custom') {
      controller.set('created_ts_from', '');
      controller.set('created_ts_to', '');
    }
    if(controller.get('created_ts') === 'custom' && !controller.get('created_ts_from') && !controller.get('created_ts_to')) {
      controller.set('created_ts', 'today');
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Transactions',
      route_name: 'reports.transactionslegacy',
      route_id: null
    });
    controller.set('pageTitle', 'Transactions Report (Legacy)');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'Transactions (Legacy)',
      link: ''
    }]);

    controller.set('eol_warning', noty({
      text: 'This report is being phased out in the near future, please start using the new <a href="#/reports/transactions?created_ts=today&page=&page_size=15&sort=-created_ts">Transactions</a> report',
      layout: 'top',
      type: 'warning',
      timeout: '0',
      closeWith: ['click'],
    }));
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function () {
      this.controller.get('eol_warning').close();
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
