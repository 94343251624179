import Ember from 'ember';

var get = Ember.get;

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsUsers: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('user.errors.messages', 'locationuser.errors.messages', function() {
    this.set('errorMessages', this.get('user.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,
  user_id: null,

  contactEntryPages: Ember.computed(function() {
    return this.get('applicationData.contactEntryPages');
  }),
  pageSizes: Ember.computed(function() {
    return this.get('applicationData.pageSizes');
  }),
  processMethods: Ember.computed(function() {
    return this.get('applicationData.processMethods');
  }),
  reportExportTypes: Ember.computed(function() {
    return this.get('applicationData.exportFormats');
  }),
  userEntryPages: Ember.computed(function() {
    return this.get('applicationData.userEntryPages');
  }),
  userTypes: Ember.computed(function() {
    return this.get('applicationData.userTypes');
  }),

  default_terminal: Ember.computed('user.ui_prefs.default_terminal', function() {
    var terminals = this.get('terminals');
    var default_terminal = this.get('user.ui_prefs.default_terminal');
    var defaultTerm = terminals.filter(function(item) {
      return item.id === default_terminal;
    });
    if (get(defaultTerm, 'length') === 1) {
      return get(get(defaultTerm, 'firstObject'), 'title');
    }
    return '';
  }),
  entry_page: Ember.computed('user.ui_prefs.entry_page', function() {
    var entryPages = this.get('entryPages');
    var entryPage = this.get('user.ui_prefs.entry_page');
    var pref = entryPages.filter(function(item) {
      return item.id === entryPage;
    });
    if (get(pref, 'length') === 1) {
      return get(get(pref, 'firstObject'), 'title');
    }
    return '';
  }),
  entryPages: Ember.computed('user.user_type_id', function() {
    var isContact = String(this.get('user.user_type_id')) === '100';
    if (isContact) {
      return this.get('contactEntryPages');
    } else {
      return this.get('userEntryPages');
    }
  }),
  ui_prefs_page_size: Ember.computed('user.ui_prefs.page_size', function() {
    var pageSizes = this.get('pageSizes');
    var pageSize = this.get('user.ui_prefs.page_size');
    var page = pageSizes.filter(function(item) {
      return parseInt(item.id) === parseInt(pageSize);
    });
    if (get(page, 'length') === 1) {
      return get(get(page, 'firstObject'), 'title');
    }
    return '';
  }),
  process_method: Ember.computed('user.ui_prefs.process_method', function() {
    var processMethods = this.get('processMethods');
    var process_method = this.get('user.ui_prefs.process_method');
    var processMeth = processMethods.filter(function(item) {
      return item.id === process_method;
    });
    if (get(processMeth, 'length') === 1) {
      return get(get(processMeth, 'firstObject'), 'title');
    }
    return '';
  }),
  report_export_type: Ember.computed('user.ui_prefs.report_export_type', function() {
    var reportExportTypes = this.get('reportExportTypes');
    var exportType = this.get('user.ui_prefs.report_export_type');
    var type = reportExportTypes.filter(function(item) {
      return item.id === exportType;
    });
    if (get(type, 'length') === 1) {
      return get(get(type, 'firstObject'), 'title');
    }
    return '';
  }),
  showTerminals: Ember.computed('user.ui_prefs.process_method', function() {
    return this.get('user.ui_prefs.process_method') === 'physical_terminal';
  }),

  changeNewUser: Ember.observer('isNewUser', function() {
    this.get('user.errors').clear();
    this.get('locationuser.errors').clear();
    this.set('newUser', this.get('isNewUser') === '1');
  }),

  // Actions
  actions: {
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    addExisting: function() {
      this.get('user.errors').clear();
      this.get('locationuser.errors').clear();
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var user_id = this.get('user_id');
      var location = this.get('location');
      var locationuser = this.get('locationuser');

      locationuser.set('user_id', user_id);
      locationuser.save().then(function() {
          noty({
            text: 'User added successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('locations.users-index', location.get('id'));
        },
        function() {
          self.set('isLoading', false);
        });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.users-index', location.get('id'));
    },
    save: function() {
      this.get('user.errors').clear();
      this.get('locationuser.errors').clear();
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var user = this.get('user');
      var location = this.get('location');

      user.save().then(function() {
        noty({
          text: 'User saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.users-view', location.get('id'), user.get('id'));
      }, function() {
        self.set('isLoading', false);
      });
    },
    updateUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('users', result);
      });
    }
  }
});
