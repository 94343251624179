import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  credit_account_vault_id: DS.attr('string'),
  debit_account_vault_id: DS.attr('string'),
  parent_id: DS.attr('string'),
  title: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  parent: DS.belongsTo('portfolio')
});
