import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({
  tagName: 'span',

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Events
  didInsertElement: function() {
    this._super();
    var self = this;

    jQuery(document).on('shown.bs.dropdown', '#' + this.$().attr('id'), function() {
      if(self.get('isACHSECCode')){
        self.get('ach_sec_codes_infos').map(function(item) {
          $('#sec-code-info-' + item.code).on('hide.bs.collapse', function() {
            Ember.set(item, 'collapsed', true);
          });
          $('#sec-code-info-' + item.code).on('show.bs.collapse', function() {
            Ember.set(item, 'collapsed', false);
          });
        });
      }
    });

  },

  willDestroyElement: function() {
    jQuery(document).off('hidden.bs.dropdown', '#' + this.$().attr('id'));
    jQuery(document).off('shown.bs.dropdown', '#' + this.$().attr('id'));

    if(this.get('isACHSECCode')){
      this.get('ach_sec_codes_infos').map(function(item) {
        $('#sec-code-info-' + item.code).off('hide.bs.collapse');
        $('#sec-code-info-' + item.code).off('show.bs.collapse');
      });
    }
  },

  // Computed Properties
  ach_sec_codes_infos: [{
      code: 'CCD',
      collapsed: true,
      description: 'Transfer of funds where they are either<br>distributed or consolidated between<br>corporate entities.',
      title: 'Corporate Credit or Debit'
    },
    {
      code: 'POP',
      collapsed: true,
      description: 'Made in person at the register.',
      title: 'Point-of-Purchase Entry'
    },
    {
      code: 'PPD',
      collapsed: true,
      description: 'Through standing authorizations, the<br>consumer grants the company authority<br>to initiate periodic charges to his or her<br>account as bills become due.',
      title: 'Prearranged Payment and Deposit'
    },
    {
      code: 'TEL',
      collapsed: true,
      description: 'This Standard Entry Class Code is used for<br>the origination of a Single Entry debit<br>transaction to a consumer\'s account<br>pursuant to an oral authorization obtained<br>from the consumer via the telephone.',
      title: 'Telephone-Initiated Entry'
    },
    {
      code: 'WEB',
      collapsed: true,
      description: 'This Standard Entry Class Code is used for<br>the origination of debit entries (either Single<br>or Recurring Entry) to a consumer\'s account<br>pursuant to an authorization that is obtained<br>from the Receiver via the Internet.',
      title: 'Internet-Initiated Entry'
    }
  ],
  isACHSECCode: Ember.computed('type', function() {
    return this.get('type') === 'ach_sec_code';
  }),
  isHPPFieldShortName: Ember.computed('type', function() {
    return this.get('type') === 'hpp_field_shortname';
  }),
  isL3Field: Ember.computed('type', function() {
    return this.get('type').indexOf('level3data_') >= 0;
  }),
  l3FieldText: Ember.computed('isL3Field', 'type', function() {
    var type = this.get('type').replace('level3data_', '');
    if(this.get('isL3Field')){
      switch(type) {
        case 'alternate_tax_id':
          return 'Tax identification number of the merchant that reported the alternate tax amount.';
        case 'commodity_code':
          return 'An international description code of the individual good or service being supplied.';
        case 'customer_vat_registration':
          return 'Tax registration number supplied by the Commercial Card cardholder.';
        case 'destination_country_code':
          return 'Code of the country where the goods are being shipped.';
        case 'discount_amount':
          return 'Total discount amount applied against the line item total.';
        case 'duty_amount':
          return 'Fee amount associated with the import of the purchased goods.';
        case 'freight_amount':
          return 'Freight or shipping portion of the total transaction amount.';
        case 'merchant_vat_registration':
          return 'Government assigned tax identification number of the Merchant.';
        case 'other_tax_amount':
          return 'Used if city or multiple county taxes need to be broken out separately.';
        case 'product_code':
          return 'Merchant-defined description code of the item.';
        case 'shipfrom_zip_code':
          return 'Postal/ZIP code of the address from where the purchased goods are being shipped.';
        case 'shipto_zip_code':
          return 'Postal/ZIP code of the address where purchased goods will be delivered.';
        case 'summary_commodity_code':
          return 'International description code of the overall goods or services being supplied.';
        case 'tax_type_id':
          return 'Indicates the type of tax collected in relationship to a specific tax amount.';
        case 'unique_vat_ref_number':
          return 'Invoice number that is associated with the VAT invoice.';
        case 'unit_code':
          return 'Units of measurement as used in international trade.';
        case 'unit_cost':
          return 'If left blank, system will calculate a value.';
        default:
          return '';
      }
    } else {
      return '';
    }
  }),

  thisId: Ember.computed('id', function() {
    return this.get('name') + '_popover_info';
  }),
});
