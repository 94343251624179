import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  destination_type_id: DS.attr('string'),
  email: DS.attr('string'),
  log_email_id: DS.attr('string'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),
  user_user_pref_id: DS.attr('string'),

  // Computed Properties
  title: Ember.computed('email', function() {
    return this.get('email');
  })
});
