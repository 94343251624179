import Ember from 'ember';

Ember.TextArea.reopen({
  classNames: ['form-control']
});

export default Ember.TextArea.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  didInsertElement: function() {
    var self = this;
    if (this.get('has-focus')) {
      Ember.run.later(function() {
        self.$().focus();
      });
    }
  },
});
