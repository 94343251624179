import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    var getProductTransactions = function() {
      if (session.get('authenticated.resources.v2-producttransactions-get')) {
        return store.query('producttransaction', {
          location_id: params.id,
          page_size: '1000',
          active: params.active
        });
      }
    };
    var getProductTransactionDetails = function() {
      if (session.get('authenticated.resources.v2-producttransactiondetails-get') && !session.get('authenticated.resources.v2-producttransactions-get')) {
        return store.query('producttransactiondetail', {
          location_id: params.id,
          page_size: '50',
          active: params.active
        });
      }
    };
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      producttransactions: getProductTransactions(),
      producttransactiondetails: getProductTransactionDetails(),
      productfiles: store.query('productfile', {
        location_id: params.id,
        page_size: '50',
        active: params.active
      }),
      productaccountvaults: store.query('productaccountvault', {
        location_id: params.id,
        page_size: '50',
        active: params.active
      }),
      productrecurrings: store.query('productrecurring', {
        location_id: params.id,
        page_size: '50',
        active: params.active
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var session = this.get('session');
    controller.set('location', models.location);
    var index = 0;
    if (session.get('authenticated.resources.v2-producttransactions-get')) {
      index = 0;
    } else if (session.get('authenticated.resources.v2-producttransactiondetails-get')) {
      index = 1;
    } else if (session.get('authenticated.resources.v2-productfiles-get')) {
      index = 2;
    } else if (session.get('authenticated.resources.v2-productaccountvaults-get')) {
      index = 3;
    } else if (session.get('authenticated.resources.v2-productrecurrings-get')) {
      index = 4;
    }
    var products = [{
      name: 'Deposit Accounts',
      heading: 'heading0',
      group: 'group0',
      link: '#group0',
      action: 'transaction',
      items: models.producttransactions,
      items_ach: models.producttransactions ? models.producttransactions.filter(function(p){
        return p.get('payment_method') === 'ach';
      }) : [],
      items_cc: models.producttransactions ? models.producttransactions.filter(function(p){
        return p.get('payment_method') === 'cc';
      }) : [],
      active: (index === 0),
      isTransactionService: true,
      viewPortfolio: session.get('authenticated.resources.v2-portfolios-get'),
      migrateProduct: session.get('authenticated.resources.v2-producttransactionmigrations-put'),
      viewGateway: session.get('authenticated.user_type_id') === 1000,
      canView: session.get('authenticated.resources.v2-producttransactions-get'),
      canAdd: session.get('authenticated.resources.v2-producttransactions-post'),
      canEdit: session.get('authenticated.resources.v2-producttransactions-put'),
      canDelete: session.get('authenticated.resources.v2-producttransactions-delete'),
      canActivate: session.get('authenticated.resources.v2-producttransactions-post-activate'),
      canReregister: session.get('authenticated.resources.v2-producttransactions-reregister-post'),
      canBilling: session.get('authenticated.resources.v2-productbillinggroups-get') && session.get('authenticated.resources.v2-productbillinggroupitems-get') && session.get('authenticated.resources.v2-productitems-get'),
      canSurcharge: session.get('authenticated.resources.v2-producttransactions-put'),
      canReceipts: session.get('authenticated.resources.v2-producttransactions-put')
    }, {
      name: 'Deposit Accounts',
      heading: 'heading1',
      group: 'group1',
      link: '#group1',
      action: 'transactiondetail',
      items: models.producttransactiondetails,
      items_ach: models.producttransactiondetails ? models.producttransactiondetails.filter(function(p){
        return p.get('payment_method') === 'ach';
      }) : [],
      items_cc: models.producttransactiondetails ? models.producttransactiondetails.filter(function(p){
        return p.get('payment_method') === 'cc';
      }) : [],
      active: (index === 1),
      isTransactionService: true,
      canView: session.get('authenticated.resources.v2-producttransactiondetails-get') && !session.get('authenticated.resources.v2-producttransactions-get'),
      canEdit: session.get('authenticated.resources.v2-producttransactiondetails-put') && !session.get('authenticated.resources.v2-producttransactions-get'), // this should be the same as the above line (get)
      canSurcharge: session.get('authenticated.resources.v2-producttransactiondetails-put'),
      canReceipts: session.get('authenticated.resources.v2-producttransactiondetails-put')
    }, {
      name: 'File Storage',
      heading: 'heading2',
      group: 'group2',
      link: '#group2',
      action: 'file',
      items: models.productfiles,
      active: (index === 2),
      viewPortfolio: session.get('authenticated.resources.v2-portfolios-get'),
      canView: session.get('authenticated.resources.v2-productfiles-get'),
      canAdd: session.get('authenticated.resources.v2-productfiles-post'),
      canEdit: session.get('authenticated.resources.v2-productfiles-put'),
      canDelete: session.get('authenticated.resources.v2-productfiles-delete'),
      canActivate: session.get('authenticated.resources.v2-productfiles-post-activate')
    }, {
      name: 'Account Vault',
      heading: 'heading3',
      group: 'group3',
      link: '#group3',
      action: 'accountvault',
      items: models.productaccountvaults,
      active: (index === 3),
      viewPortfolio: session.get('authenticated.resources.v2-portfolios-get'),
      canView: session.get('authenticated.resources.v2-productaccountvaults-get'),
      canAdd: session.get('authenticated.resources.v2-productaccountvaults-post'),
      canEdit: session.get('authenticated.resources.v2-productaccountvaults-put'),
      canDelete: session.get('authenticated.resources.v2-productaccountvaults-delete'),
      canActivate: session.get('authenticated.resources.v2-productaccountvaults-post-activate')
    }, {
      name: 'Recurring Billing',
      heading: 'heading4',
      group: 'group4',
      link: '#group4',
      action: 'recurring',
      items: models.productrecurrings,
      active: (index === 4),
      viewPortfolio: session.get('authenticated.resources.v2-portfolios-get'),
      canView: session.get('authenticated.resources.v2-productrecurrings-get'),
      canAdd: session.get('authenticated.resources.v2-productrecurrings-post'),
      canEdit: session.get('authenticated.resources.v2-productrecurrings-put'),
      canDelete: session.get('authenticated.resources.v2-productrecurrings-delete'),
      canActivate: session.get('authenticated.resources.v2-productrecurrings-post-activate')
    }];
    controller.set('products', products);
    controller.set('location', models.location);

    controller.send('activeProduct', index);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Services',
      route_name: 'locations.products',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
