import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsNotificationAlerts: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('notificationalert.errors.messages', function() {
    this.set('errorMessages', this.get('notificationalert.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isHtmlMessage: Ember.computed('notificationalert.alert_type_id', function() {
    var alert_message = this.get('notificationalert.alert_message');
    var alert_type_id = String(this.get('notificationalert.alert_type_id'));
    if (alert_type_id !== '0' && alert_message) {
      this.set('notificationalert.alert_message', alert_message.replace(/<(?:.|\n)*?>/gm, ''));
    }
    return (alert_type_id === '0');
  }),

  // Actions
  actions: {
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      var notificationalert = this.get('notificationalert');

      notificationalert.save().then(function() {
        noty({
          text: 'Notification alert saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.notificationalerts', location.id);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.notificationalerts', location.id);
    }
  }
});
