import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('locationinfo', {
        id: params.id,
        expand: 'product_transactions,branding_domain,product_file',
      }),
      quickinvoice: store.queryRecord('quickinvoice', {
        id: params.quickinvoice_id,
        expand: 'contact,tags,created_user,cc_product_transaction,ach_product_transaction,files',
        active: '0,1'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var quickinvoice = models.quickinvoice;
    controller.set('quickinvoice', quickinvoice);
    controller.set('location', models.location);

    // Set: Page Title - Crumbs - History
    var quickinvoiceTitle = quickinvoice.get('title');
    var invoice_number = quickinvoice.get('invoice_number');
    if (invoice_number) {
      quickinvoiceTitle += ' (' + invoice_number + ')';
    }
    this.send('addHistory', {
      title: models.location.get('name') + ' ● View Quick Invoice ● ' + quickinvoiceTitle,
      route_name: 'locations.quickinvoices-view',
      route_id: models.location.get('id'),
      route_id_plus: models.quickinvoice.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Quick Invoices',
      link: 'locations.quickinvoices-index',
      record: models.location.get('id')
    }, {
      title: 'View Quick Invoice',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
