import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  crudFormFields: Ember.inject.service('crud-form-fields'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/terminalcvms/crud', {
      controller: 'admin/terminalcvms/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminalcvm: store.createRecord('terminalcvm', {}),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('terminalcvm', models.terminalcvm);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);
    controller.set('crudParams', {
      formModelName: 'Terminal CVM',
      formFields: this.get('crudFormFields').getForModel('terminalcvm'),
      buttonPermissions: {
        overall: 'v2-terminalcvms-get',
        back: 'v2-terminalcvms-get',
        cancel: 'v2-terminalcvms-get',
        delete: 'v2-terminalcvms-delete',
        edit: 'v2-terminalcvms-put',
        save: 'v2-terminalcvms-post'
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Terminal CVM',
      route_name: 'admin.terminalcvms.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Terminal CVM');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terminal CVMs',
      link: 'admin.terminalcvms.index'
    }, {
      title: 'Add Terminal CVM',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel;
      if (model.terminalcvm.get('hasDirtyAttributes')) {
        model.terminalcvm.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

});
