import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  btnTitleDelete: Ember.computed(function() {
    if(this.get('disableButtons') && this.get('disableButtons.delete') && this.get('disableButtons.delete.whenThis')) {
      return this.get('disableButtons.delete.message');
    } else {
      return 'Delete';
    }
  }),
  disabledBtn: Ember.computed('isLoading', 'disableAll', function() {
    return this.get('isLoading') || this.get('disableAll');
  }),
  disabledBtnDelete: Ember.computed('disableButtons', 'disabledBtn', function() {
    return (this.get('disableButtons') && this.get('disableButtons.delete') && this.get('disableButtons.delete.whenThis')) || this.get('disabledBtn');
  }),
  bodyTypeTable: Ember.computed('body_type', function() {
    return this.get('body_type') === 'table';
  }),
  extraButtonsShowCheck: Ember.computed('modelName', function() {
    return this.get('modelName') === 'location' ? this.get('session.authenticated.isSupport') : true;
  }),
  isNewOrNotEditing: Ember.computed('isEditing', 'model.isNew', function() {
    var isEditing = this.get('isEditing');
    var isNew = this.get('model.isNew');
    return (isEditing && isNew) || !isEditing;
  }),
  noBackButton: Ember.computed('noBack', function() {
    return this.get('noBack') === 'true';
  }),
  showBack: Ember.computed('formActionBack', 'isNewOrNotEditing', 'noBackButton', 'hideBackOnAdd', 'model.isNew', function() {
    if (this.get('model.isNew') && this.get('hideBackOnAdd')) {
      return false;
    } else {
      return this.get('formActionBack') && this.get('isNewOrNotEditing') && (!this.get('noBackButton') ? true : false);
    }
  }),
  showCancel: Ember.computed('formActionCancel', 'model.isNew', function() {
    return this.get('formActionCancel') && ((!this.get('model.isNew') || this.get('canCancelNew')) ? true : false);
  }),
  showDelete: Ember.computed('formActionDelete', 'formPrivs.canDelete', 'model.isNew', function() {
    var model = this.get('model');
    if(!model || model.get('isNew') || model.get('isDeleted') || model.get('isEmpty')) {
      return false;
    }
    var attributes = model ? Object.keys(model.toJSON()) : [];
    if((attributes.indexOf('active') >= 0 && (model.get('active') && model.get('active') !== '0')) || attributes.indexOf('active') === -1) {
      return this.get('formActionDelete') && (this.get('formPrivs.canDelete') ? true : false);
    } else {
      return false;
    }
  }),
  showEdit: Ember.computed('formActionEdit', 'formPrivs.canPut', 'modelName', 'isActive', function() {
    var extraCheckModels = ['quickinvoice', 'producttransaction', 'producttransactiondetails', 'developer', 'developercompany', 'recurring', 'productaccountvault', 'productfile', 'productrecurring'];
    var extraCheck = (extraCheckModels.indexOf(this.get('modelName')) >= 0) ? this.get('isActive') : true;
    return this.get('formActionEdit') && this.get('formPrivs.canPut') && (extraCheck ? true : false);
  }),
  showPrint: Ember.computed('formActionPrint', function() {
    return this.get('formActionPrint');
  }),
  showReactivate: Ember.computed('formActionReactivate', 'isActive', 'formPrivs.canActivate', function() {
    return this.get('formActionReactivate') && (this.get('formPrivs.canActivate') ? !this.get('isActive') : false);
  }),
  showSave: Ember.computed('formActionSave', 'formPrivs.canPost', 'formPrivs.canPut', 'model.isNew', function() {
    var hasSaveAction = this.get('formActionSave') ? true : false;
    var isNew = this.get('model.isNew');
    var canPost = this.get('formPrivs.canPost');
    var canPut = this.get('formPrivs.canPut');
    return hasSaveAction && ((canPost && isNew) || (canPut && !isNew));
  }),
  uniqueSaveBtnAccountVaultDisabled: Ember.computed('isLoading', 'noTermsAgree', function() {
    return this.get('isLoading') || this.get('noTermsAgree');
  }),
  uniqueSaveBtnNotificationTemplateDisabled: Ember.computed('isLoading', 'notification_template_id', function() {
    return this.get('isLoading') || !this.get('notification_template_id');
  }),
  uniqueSaveBtnRecurringDisabled: Ember.computed('isLoading', 'noTermsAgree', 'noAccountVaultProduct', function() {
    return this.get('isLoading') || this.get('noTermsAgree') || this.get('noAccountVaultProduct');
  }),

  // Actions
  actions: {
    back: function() {
      this.formActionBack();
    },
    cancel: function() {
      this.formActionCancel();
    },
    delete: function() {
      this.formActionDelete();
    },
    edit: function() {
      this.formActionEdit();
    },
    print: function() {
      this.formActionPrint();
    },
    reactivate: function(data) {
      this.formActionReactivate(data);
    },
    save: function(data) {
      this.formActionSave(data);
    }
  }
});
