import Ember from 'ember';

export default Ember.Checkbox.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  tagName: 'td',
  classNames: ['text-center', 'hand', 'authroleresource-grid-td'],
  classNameBindings: ['isChangingRemove:bg-danger', 'isChangingAdd:bg-success', 'notEditingAuthenticated:authroleresource-grid-table-column-static-2'],

  // Computed Properties
  hasResource: Ember.computed('role.selectedResources.[]', 'resource.id', function() {
    var self = this;
    var selectedResources = this.get('role.selectedResources');
    var currentResourceID = this.get('resource.id');
    if (selectedResources) {
      var hasResource = false;
      for (var i = selectedResources.length - 1; i >= 0; i--) {
        if (selectedResources[i].resource_id === currentResourceID) {
          self.set('thisId', selectedResources[i].id);
          hasResource = true;
          break;
        }
      }
      return hasResource;
    } else {
      return null;
    }
  }),
  notEditingAuthenticated: Ember.computed('index', 'isEditingAuthenticated', function() {
    return this.get('index') === 0 && !this.get('isEditingAuthenticated');
  }),
  showCheck: Ember.computed('isChangingAdd', 'hasResource', function() {
    return this.get('isChangingAdd') || this.get('hasResource');
  }),

  // Observers

  changesToMakeObserver: Ember.observer('notChanges', function() {
    if (this.get('notChanges')) {
      this.set('isChangingAdd', false);
      this.set('isChangingRemove', false);
    }
  }),

  // Events
  click: function() {
    var hasResource = this.get('hasResource');
    if ((hasResource && !this.get('session.authenticated.resources.v2admin-authroleresources-delete')) || (!hasResource && !this.get('session.authenticated.resources.v2admin-authroleresources-post'))) {
      noty({
        text: 'You do not have the privilege to perform this action.',
        type: 'error'
      });
      return;
    }
    if (this.get('role.id') !== '100' || (this.get('role.id') === '100') && this.get('isEditingAuthenticated')) {
      if (hasResource) {
        this.set('isChangingRemove', this.get('isChangingRemove') ? false : true);
      } else {
        this.set('isChangingAdd', this.get('isChangingAdd') ? false : true);
      }
    }
    this.action(this.get('role.id'), this.get('resource.id'), this.get('thisId'), this.get('hasResource'));
  },
  didInsertElement: function() {
    this.set('isChangingAdd', false);
    this.set('isChangingRemove', false);
  },

  // Actions
  actions: {}
});
