import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin,{
	attrs: {
		ach_product_transaction: {
			serialize: false,
			deserialize: 'records'
		},
		changelogs: {
			serialize: false,
			deserialize: 'records'
		},
    contact: {
      serialize: false,
      deserialize: 'records'
    },
    cc_product_transaction: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      embedded: 'always'
    },
		location: {
			serialize: false,
			deserialize: 'records'
		},
    log_emails: {
      serialize: false,
      deserialize: 'records'
    },
    tags: {
			embedded: 'always'
		},
    transactions: {
      serialize: false,
      deserialize: 'records'
    },
	}
});
