import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'id',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'description',
    'id',
    'is_enabled'
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(featureflag) {
          self.send('view', featureflag);
        },
        'actionId': 'featureflag_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      is_enabled: {
        'name': 'is_enabled',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'align': 'text-center',
        'filter': {
          type: 'dropdown',
          items: 'featureFlagsEnableTypes',
          multi: true
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(featureflag) {
          self.send('view', featureflag);
        },
        'actionId': 'featureflag_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'description', 'is_enabled', 'view'];
    var listOfMobileCols = ['id', 'description', 'is_enabled', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-featureflags-get')) {
        colsArray.removeObject(columns.view);
      }
    }
  }),

  // Actions
  actions: {
    view: function(featureflag) {
      this.transitionToRoute('admin.featureflags.view', featureflag.get('id'));
    },
  }
});
