import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  setupController: function(controller) {
    controller.set('pageTitle', '404');
    controller.set('pageCrumbs', [{
      title: '404',
      link: ''
    }]);
    var isInvalidDomainError = jQuery('#invaliddomain').css('display') === 'block';
    var isMaintenanceError = jQuery('#maintenance').css('display') === 'block';
    controller.set('notOtherError', (isInvalidDomainError || isMaintenanceError) ? false : true);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },
});
