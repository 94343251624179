import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLoading: false,
  isEditing: false,

  terminalmanufacturerIdTypes: Ember.computed(function() {
    return this.get('applicationData.terminalmanufacturerIdTypes');
  }),

  actions: {
    back: function() {
      this.transitionToRoute('admin.terminalmanufacturers.index');
    },
    cancel: function() {
      var terminalmanufacturer = this.get('terminalmanufacturer');
      terminalmanufacturer.rollbackAttributes();
      this.set('isEditing', false);
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var terminalmanufacturer = this.get('terminalmanufacturer');

      terminalmanufacturer.save().then(function() {
        noty({
          text: 'Terminal CVM saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    }
  }
});
