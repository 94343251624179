import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('helppage.errors.messages', function() {
    this.set('errorMessages', this.get('helppage.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,
  userTypes: Ember.computed(function() {
    return this.get('applicationData.allUserTypes');
  }),

  // Actions
  actions: {
    back: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var helppage = this.get('helppage');
      helppage.save().then(function() {
        noty({
          text: 'Help page added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.helppages.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    cancel: function() {
      this.transitionToRoute('admin.helppages.index');
    }
  }
});
