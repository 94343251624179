import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    changelogs: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    location_billing_account: {
			serialize: false,
			deserialize: 'records'
		},
    product_ach_store: {
      serialize: false,
      deserialize: 'records'
    },
    sales_office: {
			serialize: false,
			deserialize: 'records'
		},
    surcharge: {
      serialize: false,
      deserialize: 'records'
    },
  }
});
