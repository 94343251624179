import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions'
      }),
      producttransaction: store.queryRecord('producttransaction', {
        id: params.product_id,
        active: '0,1',
        expand: 'surcharge,product_ach_store,created_user,portfolio,sales_office'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransaction', models.producttransaction);

    if (models.producttransaction.get('surcharge_id')) {
      const surcharge = models.producttransaction.get('surcharge.content');
      let is_surcharge = surcharge.get('is_surcharge');
      if (is_surcharge) {
        const words = is_surcharge.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        is_surcharge = words.join(' ');
      }
      surcharge.set('is_surcharge', is_surcharge);
      controller.set('surcharge', surcharge);
      controller.set('showSurcharge', true);
    } else {
      controller.set('showSurcharge', false);
    }
    controller.set('isEditing', false);
    controller.set('isEditingSurcharge', false);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-surcharges-delete'),
      canPost: this.get('session.authenticated.resources.v2-surcharges-post'),
      canPut: this.get('session.authenticated.resources.v2-surcharges-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + models.producttransaction.get('title') + ' ● Surcharge',
      route_name: 'locations.viewproducttransaction-surcharge',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransaction.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: models.producttransaction.get('title'),
      link: 'locations.viewproducttransaction',
      record: models.location.get('id'),
      record_plus: models.producttransaction.get('id'),
    }, {
      title: 'Surcharge',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var surcharge = this.controller.get('surcharge');
      this.get('unsavedInfo').warning(surcharge, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
