import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobile: Ember.computed('applicationData.isMobile', function() {
    return this.get('applicationData.isMobile');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsBillingAccounts: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('locationbillingaccount.errors.messages', function() {
    this.set('errorMessages', this.get('locationbillingaccount.errors.messages'));
  }),

  actions: {
    transition: function() {
      this.transitionToRoute('locations.locationbillingaccount-index', this.get('location.id'));
    },
  }
});
