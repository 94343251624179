import Ember from "ember";
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  accountsindexController: Ember.inject.controller('accounts/index'),
  accountsviewController: Ember.inject.controller('accounts/view'),
  contactschargeController: Ember.inject.controller('contacts/charge'),
  contactsaccountsindexController: Ember.inject.controller('contacts/accounts-index'),
  contactsaccountsviewController: Ember.inject.controller('contacts/accounts-view'),
  contactsdashboardController: Ember.inject.controller('contacts/dashboard'),
  contactstransactionsController: Ember.inject.controller('contacts/transactions'),
  contactuseraccountsindexController: Ember.inject.controller('contactuser/accounts-index'),
  contactuseraccountsviewController: Ember.inject.controller('contactuser/accounts-view'),
  contactuserdashboardController: Ember.inject.controller('contactuser/dashboard'),
  contactusermakepaymentController: Ember.inject.controller('contactuser/makepayment'),
  contactuserpaymenthistoryController: Ember.inject.controller('contactuser/paymenthistory'),
  locationsaccountsindexController: Ember.inject.controller('locations/accounts-index'),
  locationsaccountsviewController: Ember.inject.controller('locations/accounts-view'),
  locationstransactionsController: Ember.inject.controller('locations/transactions'),
  locationsunsettledtransactionsController: Ember.inject.controller('locations/reports-cc-unsettledtransactions'),
  reportstransactionsController: Ember.inject.controller('reports/transactions'),
  reportstransactionslegacyController: Ember.inject.controller('reports/transactionslegacy'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),
  transactiondetailfullController: Ember.inject.controller('transactiondetailfull'),
  virtualterminalController: Ember.inject.controller('virtualterminal'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),
  quickInvoiceErrorMessages: null,
  updateQuickInvoiceErrorMessages: Ember.observer('quickinvoice.errors.messages', function() {
    this.set('quickInvoiceErrorMessages', this.get('quickinvoice.errors.messages'));
  }),

  // Various controller properties
  sender: null,
  contacts: [],
  quickinvoices: [],
  selectedTags: [],
  isEditingField: {},
  editField: '',

  // Computed Properties
  disabledMoveAccountVaultTransactions: Ember.computed('transaction.move_account_vault', function() {
    var isMovingAccountVault = this.get('transaction.move_account_vault');
    return !isMovingAccountVault;
  }),
  initialContactName: Ember.computed('transaction.contact_id', 'transaction.contact.id', function() {
    if (this.get('transaction.contact_id') || this.get('transaction.contact.id')) {
      return this.get('transaction.contact.titleWithAccountNumber');
    } else {
      return '';
    }
  }),
  initialQuickInvoiceName: Ember.computed('transaction.quick_invoice_id', function() {
    if (this.get('transaction.quick_invoice_id')) {
      return this.get('transaction.quick_invoice.titleWithInvoiceNumber');
    }
  }),
  senderController: Ember.computed('sender', function() {
    var controller = this.get('sender').replace(/\./, '').replace(/\-/, '') + 'Controller';
    return this.get(controller);
  }),
  showUnlinkContactButton: Ember.computed('initiallyHasContact', function() {
    return this.get('initiallyHasContact');
  }),
  titleId: Ember.computed('editField', function() {
    var editField = this.get('editField');
    return 'edit_transaction_' + editField.replace(/ /g, '_').toLowerCase() + '_header';
  }),
  transactionHasNoQuickInvoice: Ember.computed('transaction.quick_invoice_id', 'transaction_quick_invoice_id', function() {
    return this.get('transaction.quick_invoice_id') || this.get('transaction_quick_invoice_id') ? false : true;
  }),

  // Observers
  isEditingFieldObserver: Ember.observer('editField', function() {
    var editField = this.get('editField');
    if (editField) {
      this.set('isEditingField', {});
      this.set('isEditingField.' + editField.replace(/ /g, '_').toLowerCase(), true);
      this.set('title', 'Edit Transaction ' + editField);
    }
  }),

  // Functions
  returnToSender: function() {
    var self = this;
    var sender = this.get('sender');
    var transaction = this.get('transaction');
    if (sender) {
      var senderController = self.get('senderController');
      if (senderController) {
        senderController.set('needsRefresh', new moment().unix());
      }
      if (sender !== 'transactiondetailfull') {
        self.get('store').queryRecord('locationinfo', {
          id: transaction.get('location_id'),
          product_transaction_active: '1,0',
        }).then(function(data) {
          self.send('showTransDetail', transaction, data);
        });
      } else {
        self.send('closeModal');
      }
    }
  },

  saveTransaction(action, undoChanges) {
    // Bail if we are already loading
    if (this.get('isLoading')) {
      return;
    }
    jQuery('.dropdown-select.open .dropdown-toggle').dropdown('toggle'); // close any open dropdowns before proceeding. Ex: selecting/deselecting tags - changes only apply to model on dropdown close
    this.set('isLoading', true);
    var editField = this.get('editField');

    var confirmationMessage = '';

    var self = this;
    var transaction = this.get('transaction');
    var undoChangesObj = undoChanges || {};
    if (editField === 'Contact' && action !== 'unsetContact' && !transaction.get('contact_id')) {
      this.set('customErrorMessages', ['Must select a Contact']);
      this.set('isLoading', false);
      return;
    }
    if (editField === 'Contact') {
      var isMovingAccountVault = this.get('transaction.move_account_vault');
      if (!isMovingAccountVault) {
        this.set('transaction.move_account_vault_transactions', false);
      }
      undoChangesObj.contact_id = this.get('transaction.contact_id');
      if(Ember.isPresent( transaction.changedAttributes().contact_id )){
        confirmationMessage = 'Are you sure you want to update the Contact for this Transaction?';
      }
    }
    if (editField === 'Description') {
      undoChangesObj.description = transaction.get('description');
      transaction.set('description', this.get('transactionDescription'));
      confirmationMessage = 'Are you sure you want to update the Description for this Transaction?';
    }
    if (editField === 'Tags') {
      undoChangesObj.tags = transaction.get('tags');
      transaction.set('tags', this.get('selectedTags'));
      confirmationMessage = 'Are you sure you want to update the Tags for this Transaction?';
    }

    pretty_confirm(confirmationMessage, function() {
      transaction.set('action', 'edit');
      transaction.save().then(function() {
        noty({
          text: editField + ' updated successfully!'
        });
        self.returnToSender();
        self.clearErrors();
        self.set('isLoading', false);
      }, function(error) {
        noty({
          text: 'Error updating ' + editField + '!',
          type: 'error'
        });
        if (error && error.errors) {
          var errors = error.errors;
          var errMsgs = [];
          errors.forEach(function(err) {
            if (typeof err.detail === 'string') {
              errMsgs.push(err.detail);
            } else {
              err.detail.forEach(function(er) {
                errMsgs.push(er);
              });
            }
          });
          self.undoChanges(undoChangesObj, editField);
        }
        self.set('isLoading', false);
      });
    }, function() {
      self.undoChanges(undoChangesObj, editField);
      self.set('isLoading', false);
    });

  },

  quickInvoiceSave: function(action) {
    // Bail if we are already loading
    if (this.get('isLoading')) {
      return;
    }
    this.set('isLoading', true);
    var self = this;
    var transaction = this.get('transaction');
    var session = this.get('session');
    var quick_invoice_id;
    if (action === 'addTransaction') {
      if (!this.get('transaction_quick_invoice_id')) {
        this.set('customErrorMessages', ['Must select a Quick Invoice']);
        this.set('isLoading', false);
        return;
      }
      quick_invoice_id = this.get('transaction_quick_invoice_id');
    } else if (action === 'removeTransaction') {
      quick_invoice_id = this.get('transaction.quick_invoice_id');
    }
    jQuery.ajax({
      url: window.UiENV.APP.FULL_URL + '/quickinvoices/' + quick_invoice_id + '/' + action,
      type: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'developer-id': window.UiENV.APP.DEVELOPER_ID,
        'access-token': session.get('authenticated.token')
      },
      data: JSON.stringify({
        'quickinvoice': {
          'transaction_id': transaction.get('id')
        }
      })
    }).then(function() {
      var message = '';
      if (action === 'addTransaction') {
        message = 'Transaction linked to Quick Invoice successfully!';
      } else if (action === 'removeTransaction') {
        message = 'Transaction unlinked from Quick Invoice successfully!';
        transaction.set('quick_invoice_id', null);
      }
      noty({
        text: message
      });
      self.returnToSender();
      self.clearErrors();
      self.set('isLoading', false);
    }, function(error) {
      if (error && error.responseJSON && error.responseJSON.errors) {
        var errors = error.responseJSON.errors;
        var errMsgs = [];
        Object.keys(errors).forEach(function(m) {
          if (typeof errors === 'string') {
            errMsgs.push(errors[m]);
          } else {
            Object.keys(errors[m]).forEach(function(n) {
              errMsgs.push(errors[m][n]);
            });
          }
        });
        self.set('customErrorMessages', errMsgs);
      }
      self.set('isLoading', false);
    });
  },

  undoChanges: function(obj, editField) {
    var transaction = this.get('transaction');
    if (editField === 'Description') {
      transaction.set('description', obj.description);
    }
    if (editField === 'Tags') {
      var tagList = this.get('tagList');
      var selectedTags = this.get('initialTags').split(',');
      var tempTags = [];
      tagList.forEach(function(tag) {
        selectedTags.map(function(t){
          if(t === tag.get('title')){
            tempTags.pushObject(tag);
          }
        });
      });
      transaction.set('tags', tempTags);
    }
    if (editField === 'Contact') {
      transaction.set('contact_id', obj.contact_id || null);
      transaction.set('contact', obj.contact);
    }
  },
  clearErrors: function() {
    this.set('customErrorMessages', []);
  },

  // All form actions
  actions: {
    save: function() {
      if (this.get('editField') === 'Quick Invoice') {
        var self = this;
        pretty_confirm('Are you sure you want to link this Quick Invoice to this Transaction?  The Quick Invoice balance may be affected.', function() {
          self.quickInvoiceSave('addTransaction');
        });
      } else {
        this.saveTransaction();
      }
    },
    unsetContact: function() {
      var self = this;
      pretty_confirm('Are you sure you want to unlink the Contact from this Transaction?', function() {
        var transaction = self.get('transaction');
        var undoChangesObj = {
          contact_id: transaction.get('contact_id'),
          contact: transaction.get('contact'),
          move_account_vault: transaction.get('move_account_vault'),
          move_account_vault_transactions: transaction.get('move_account_vault_transactions'),
        };
        transaction.set('contact_id', null);
        transaction.set('contact', {});
        transaction.set('move_account_vault', false);
        transaction.set('move_account_vault_transactions', false);
        self.saveTransaction('unsetContact', undoChangesObj);
      });
    },
    unsetQuickInvoice: function() {
      if (this.get('transaction.quick_invoice_id')) {
        var self = this;
        pretty_confirm('Are you sure you want to unlink this Quick Invoice from this Transaction? The Quick Invoice balance may be affected.', function() {
          self.quickInvoiceSave('removeTransaction');
        });
      } else {
        this.set('transaction_quick_invoice_id', null);
        this.returnToSender();
      }
    },
    updateQuickInvoices: function(data) {
      var self = this;
      var location_id = this.get('transaction.location_id') || null;
      var contact_id = this.get('transaction.contact_id') || null;

      Ember.RSVP.Promise.all([
        self.get('store').query('quickinvoice', {
          location_id: location_id,
          contact_id: contact_id,
          status_id: 1,
          title: data,
          sort: 'title',
          page_size: '10',
          fields: 'id,title,invoice_number'
        }),
        self.get('store').query('quickinvoice', {
          location_id: location_id,
          contact_id: contact_id,
          status_id: 1,
          invoice_number: data,
          sort: 'title',
          page_size: '10',
          fields: 'id,title,invoice_number'
        })
      ]).then(function(result) {
        handleResults(self, result);
      });

      function handleResults(context, result){
        // Remove duplicates from result
        var res1 = result[0].toArray();
        var res2 = result[1].toArray();
        if (res1.get('length') > 0 && res2.get('length') > 0) {
          res1.forEach(function(item1) {
            var id = item1.get('id');
            res2.forEach(function(item2) {
              if (item2 && id === item2.get('id')) {
                res2.removeObject(item2);
              }
            });
          });
        }
        // Merge the two arrays
        var rArray = Ember.A();
        rArray.pushObjects(res1.toArray());
        rArray.pushObjects(res2.toArray());
        self.set('quickinvoices', rArray);
      }
    },
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }
      var self = this;
      var tagList = this.get('tagList');
      var selectedTags = [];
      this.set('selectedTags', []);
      if (tags.filters[0].value) {
        selectedTags = tags.filters[0].value.split(',');
        tagList.forEach(function(tag) {
          if (selectedTags.indexOf(tag.get('title')) > -1) {
            self.get('selectedTags').pushObject(tag);
          }
        });
      } else {
        selectedTags = null;
        this.set('selectedTags', []);
      }
      var tagsText = self.get('selectedTags').map(function(tag) {
        return tag.get('title');
      });
      this.set('selectedTagsPlaceholder', tagsText.join(','));
    },
  }
});
