import Ember from "ember";
import jQuery from "jquery";
import DS from "ember-data";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),
  clientWidth: Ember.computed("applicationData.clientWidth", function () {
    return this.get("applicationData.clientWidth");
  }),

  hasOtherProfileOptions: Ember.computed(
    "session.authenticated.isContact",
    "session.authenticated.resources.v2-users-put",
    "session.authenticated.resources.v2-users_me-put",
    "session.authenticated.resources.v2-mailboxmessageusers-get",
    function () {
      return (
        (!this.get("session.authenticated.isContact") &&
          this.get("session.authenticated.resources.v2-users-put")) ||
        this.get("session.authenticated.resources.v2-users_me-put") ||
        this.get("session.authenticated.resources.v2-mailboxmessageusers-get")
      );
    }
  ),
  isShowingExtra: Ember.computed(
    "sideNavExtra_Reports",
    "sideNavExtra_Settings",
    "sideNavExtra_History",
    "sideNavExtra_Profile",
    function () {
      return (
        this.get("sideNavExtra_Reports") ||
        this.get("sideNavExtra_Settings") ||
        this.get("sideNavExtra_History") ||
        this.get("sideNavExtra_Profile")
      );
    }
  ),
  showOptionTitle: Ember.computed("isMobileSize", "sideNavOpen", function () {
    var isMobileSize = this.get("isMobileSize");
    var sideNavOpen = this.get("sideNavOpen");
    if (!sideNavOpen && !isMobileSize) {
      return false;
    } else {
      return true;
    }
  }),
  unreadMessagesCount: Ember.computed(
    "session.authenticated.numUnreadMessages",
    function () {
      return this.get("session.authenticated.numUnreadMessages");
    }
  ),

  reportsDropdownOptions: Ember.computed(
    "session.authenticated.resources.v2-transactions-get",
    "session.authenticated.resources.v2-transactionsummaryccs-get",
    "session.authenticated.resources.v2-transactionsummariesbyaccounttypes-get",
    "session.authenticated.resources.v2-transactionsummaryaches-get",
    "session.authenticated.resources.v2-transactionhistories-get",
    "session.authenticated.resources.v2-transactionachsettlements-get",
    "session.authenticated.resources.v2-transactionachsettlementsummaries-get",
    "session.authenticated.resources.v2-accountvaults-get",
    "session.authenticated.resources.v2-recurrings-get",
    "session.authenticated.resources.v2-accountvaults-get",
    function () {
      var session = this.get("session");
      var routes = [];
      if (session.get("authenticated.resources.v2-transactions-get")) {
        let routeName = "reports.transactions";
        routes.pushObject({
          title: "Transactions",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-transactions-get")) {
        let routeName = "reports.transactionslegacy";
        routes.pushObject({
          title: "Transactions (Legacy)",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (
        session.get("authenticated.resources.v2-transactionachsettlements-get")
      ) {
        let routeName = "reports.ach-transactionsettlement";
        routes.pushObject({
          title: "ACH Transactions Settlements",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (
        session.get(
          "authenticated.resources.v2-transactionachsettlementsummaries-get"
        )
      ) {
        let routeName = "reports.ach-transactionsettlementsummary";
        routes.pushObject({
          title: "ACH Transactions Settlements Summary",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (
        session.get("authenticated.resources.v2-transactionsummaryaches-get")
      ) {
        let routeName = "reports.ach-transactionsummary";
        routes.pushObject({
          title: "ACH Transactions Summary",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-transactionsummaryccs-get")) {
        let routeName = "reports.cc-transactionsummary";
        routes.pushObject({
          title: "CC Transactions Summary",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (
        session.get(
          "authenticated.resources.v2-transactionsummariesbyaccounttypes-get"
        )
      ) {
        let routeName = "reports.cc-transactionsummarybytype";
        routes.pushObject({
          title: "CC Transactions Summary by Type",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-accountvaults-get")) {
        let routeName = "reports.cardsadded";
        routes.pushObject({
          title: "Credit Cards Added",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-accountvaults-get")) {
        let routeName = "reports.accountexpiration";
        routes.pushObject({
          title: "Expiring Account Vaults",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (
        session.get("authenticated.resources.v2-paymentsourcesummaries-get")
      ) {
        let routeName = "reports.paymentsourcesummary";
        routes.pushObject({
          title: "Payment Source Summary",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-recurrings-get")) {
        let routeName = "reports.recurrings";
        routes.pushObject({
          title: "Recurring Billings",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-accountvaultcaulogreport-get")) {
        let routeName = "reports.accountvaultcaulogreport";
        routes.pushObject({
          title: "Account Vault Change Report",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      return routes;
    }
  ),
  settingsDropdownOptions: Ember.computed(
    "session.authenticated.resources.v2-reports-get",
    "session.authenticated.resources.v2-locations-get",
    "session.authenticated.resources.v2-users-get",
    "session.authenticated.resources.v2-tags-get",
    "session.authenticated.resources.v2-visibilitygroups-get",
    "session.authenticated.resources.v2-filecategories-get",
    "session.authenticated.resources.v2-notificationalerts-get",
    "session.authenticated.resources.v2-postbackconfigs-get",
    "session.authenticated.resources.v2-postbacklogs-get",
    function () {
      var session = this.get("session");
      var routes = [];
      if (session.get("authenticated.resources.v2-reports-get")) {
        let routeName = "locations.dashboard";
        routes.pushObject({
          title: "Location Dashboard",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-locations-get")) {
        let routeName = "locations.view";
        routes.pushObject({
          title: "Location Info",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-users-get")) {
        let routeName = "locations.users-index";
        routes.pushObject({
          title: "Users",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-tags-get")) {
        let routeName = "locations.tags";
        routes.pushObject({
          title: "Tags",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-visibilitygroups-get")) {
        let routeName = "locations.visibilitygroups";
        routes.pushObject({
          title: "Visibility Groups",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-filecategories-get")) {
        let routeName = "locations.filecategories";
        routes.pushObject({
          title: "File Categories",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-notificationalerts-get")) {
        let routeName = "locations.notificationalerts";
        routes.pushObject({
          title: "Notification Alerts",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-postbackconfigs-get")) {
        let routeName = "locations.postbackconfigs-index";
        routes.pushObject({
          title: "Postback Config",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      if (session.get("authenticated.resources.v2-postbacklogs-get")) {
        let routeName = "locations.postbacklogs";
        routes.pushObject({
          title: "Postback Logs",
          routeName: routeName,
          id: this.getId(routeName),
        });
      }
      return routes;
    }
  ),
  sideNavClose: Ember.computed("sideNavExtraTitle", function () {
    return (
      "main-menu_" +
      (this.get("sideNavExtraTitle")
        ? this.get("sideNavExtraTitle").toLowerCase()
        : "") +
      "_more-options-close"
    );
  }),
  contactUser: Ember.computed(function () {
    return this.get("store").findRecord(
      "contact",
      this.get("session.authenticated.user.contact_id")
    );
  }),
  contactUserById: Ember.computed("contactUser", function () {
    // query store for the current logged in contact user. following code return the promise.
    return this.get("store").query(
      "contact",
      this.get("session.authenticated.user.contact_id")
    );
    // ------ leaving original code untouched ----
    // return this.get("store").peekRecord(
    //   "contact",
    //   this.get("session.authenticated.user.contact_id")
    // );
  }),
  locations: Ember.computed(function () {
    return this.get("store").findAll("locationinfo");
  }),
  location: Ember.computed("locations.[]", function () {
    return this.get("store").peekAll("locationinfo");
  }),
  isHideBlindPayment: Ember.computed(
    "session.authenticated.isContact",
    "contactUserById",
    "location",
    "isBrandingHideBlindPayment",
    function () {
      return DS.PromiseObject.create({
        promise: new Ember.RSVP.Promise(async (resolve, reject) => {
          const isContactUser = this.get("session.authenticated.isContact");
          const contact = await this.get("contactUserById");
          const contactHideBlindPayment = contact.get("firstObject").get("hide_blind_payment");
          const location = await this.get("location");
          const locationHideBlindPayment = location
            .get("firstObject")
            .get("hide_blind_payment");
          const domainHideBlindPayment = this.get("location.firstObject.branding_domain").get("content.hide_blind_payment");
          resolve(
            isContactUser &&
            (contactHideBlindPayment ||
              locationHideBlindPayment ||
              domainHideBlindPayment)
          );
        }),
      });
    }
  ),
  // Observers

  wrapperAuthPaddingObserver: Ember.observer(
    "isMobileSize",
    "session.isAuthenticated",
    "clientWidth",
    function () {
      this.wrapperAuthPadding();
    }
  ),
  showNavDropdownOptionsObserver: Ember.observer(
    "showNavDropdownOptions",
    "clientWidth",
    function () {
      var sideNavExtra = jQuery(".side-nav-extra");
      var color = jQuery(".site-footer").css("color");
      sideNavExtra.css("border-right", "2px solid " + color);
      if (!this.get("showNavDropdownOptions")) {
        sideNavExtra.animate(
          {
            left: "-768",
          },
          "slow",
          function () {
            sideNavExtra.css("border-right", "2px solid transparent");
          }
        );
      } else if (this.get("showNavDropdownOptions")) {
        jQuery(".side-nav-extra").animate(
          {
            left: "0",
          },
          "slow",
          function () {
            sideNavExtra.css("border-right", "2px solid transparent");
          }
        );
      }
    }
  ),
  sideNavExtraTitleObserver: Ember.observer("sideNavExtraTitle", function () {
    var self = this;
    var sideNavExtraTitle = this.get("sideNavExtraTitle");
    var areas = ["Reports", "Settings", "History", "Profile"];
    areas.forEach(function (a) {
      if (a === sideNavExtraTitle) {
        self.set("sideNavExtra_" + sideNavExtraTitle, true);
      } else {
        self.set("sideNavExtra_" + a, false);
      }
    });
  }),

  // Functions

  getId: function (routeName) {
    return "main-menu_" + routeName.replace(".", "-");
  },

  wrapperAuthPadding: function () {
    jQuery(".side-nav-collapse-button").css("display", "none");

    var sideNavOpen;
    if (window.sessionStorage.side_nav_open === "false") {
      sideNavOpen = false;
    } else {
      sideNavOpen = true;
    }
    this.set("sideNavOpen", sideNavOpen);
    this.send("sideNavCollapse", !sideNavOpen, true);
    var isMobileSize = this.get("isMobileSize");
    if (isMobileSize) {
      jQuery(".side-nav").css("margin-left", "-15px");
      jQuery(".wrapper-auth").css("padding-left", "0px");
      jQuery(".wrapper-auth").animate(
        {
          "padding-left": "0",
        },
        "fast"
      );
    } else if (!isMobileSize) {
      Ember.run.later(function () {
        if (sideNavOpen) {
          jQuery(".site-footer").removeClass("side-nav-collapsed");
          jQuery(".side-nav-collapse-container").animate(
            {
              left: "200",
            },
            "fast"
          );
          jQuery(".side-nav").css("margin-left", "-200px");
          jQuery(".wrapper-auth").animate(
            {
              "padding-left": "200",
            },
            "fast"
          );
        } else {
          jQuery(".site-footer").addClass("side-nav-collapsed");
          jQuery(".side-nav-collapse-container").animate(
            {
              left: "50",
            },
            "fast"
          );
          jQuery(".wrapper-auth").animate(
            {
              "padding-left": "50",
            },
            "fast"
          );
          jQuery(".side-nav").css("margin-left", "-350px");
        }
        jQuery(".side-nav-collapse-container").css(
          "height",
          "calc(100vh - 50px)"
        );
      }, 1);
    }
  },

  // Actions
  actions: {
    AdminMenu: function () {
      if (jQuery(".admin-side-nav").css("right") === "-200px") {
        jQuery(".admin-side-nav").animate(
          {
            right: "0",
          },
          "fast"
        );
      } else {
        jQuery(".admin-side-nav").animate(
          {
            right: "-200px",
          },
          "fast"
        );
      }
    },
    logout: function () {
      var session = this.get("session");
      window.sessionStorage.is_sso = false;
      session.invalidate();
    },
    sideNavCollapse: function (sideNavOpen, noSet) {
      var isOpen;
      if (sideNavOpen === undefined) {
        isOpen = jQuery(".side-nav").css("margin-left") === "-200px";
      } else {
        isOpen = sideNavOpen;
      }
      if (isOpen && this.get("isShowingExtra")) {
        this.send("showNavDropdownOptionsFunc", "hide");
      }
      var isMobileSize = this.get("isMobileSize");
      var isAuthenticated = this.get("session.isAuthenticated");
      if (isAuthenticated && !isMobileSize) {
        if (!isOpen) {
          Ember.run.later(function () {
            if (!noSet) {
              jQuery(".side-nav").animate(
                {
                  "margin-left": "-200",
                },
                "fast"
              );
              jQuery(".wrapper-auth").animate(
                {
                  "padding-left": "200",
                },
                "fast"
              );
            }
            jQuery(".side-nav-collapse-container").animate(
              {
                left: "200",
              },
              "fast"
            );
            jQuery(".site-footer").animate(
              {
                "margin-left": "-0",
              },
              "fast"
            );
            jQuery(".site-footer").removeClass("side-nav-collapsed");
          }, 1);
          if (!noSet) {
            this.set("sideNavOpen", true);
            window.sessionStorage.side_nav_open = true;
          }
        } else {
          Ember.run.later(function () {
            if (!noSet) {
              jQuery(".side-nav").animate(
                {
                  "margin-left": "-350",
                },
                "fast"
              );
              jQuery(".wrapper-auth").animate(
                {
                  "padding-left": "50",
                },
                "fast"
              );
            }
            jQuery(".side-nav-collapse-container").animate(
              {
                left: "50",
              },
              "fast"
            );
            jQuery(".site-footer").animate(
              {
                "margin-left": "-200",
              },
              "fast"
            );
            jQuery(".site-footer").addClass("side-nav-collapsed");
          }, 1);
          if (!noSet) {
            this.set("sideNavOpen", false);
            window.sessionStorage.side_nav_open = false;
          }
        }
      }
      if (isOpen) {
        jQuery(".authroleresource-grid-table-column-static").css(
          "left",
          "49px"
        );
        jQuery(".authroleresource-grid-table-column-static-2").css(
          "left",
          "349px"
        );
      } else {
        jQuery(".authroleresource-grid-table-column-static").css(
          "left",
          "200px"
        );
        jQuery(".authroleresource-grid-table-column-static-2").css(
          "left",
          "495px"
        );
      }
      Ember.run.later(function () {
        jQuery(".side-nav-collapse-button").css("display", "none");
      }, 1);
    },
    showNavDropdownOptionsFunc: function (action, title) {
      var isMobileSize = this.get("isMobileSize");
      var ignoreIfNotMobile = ["Messages", "Profile"];
      if (
        action === "hide" ||
        (!isMobileSize && ignoreIfNotMobile.indexOf(title) !== -1)
      ) {
        this.set("showNavDropdownOptions", false);
        this.set("sideNavExtraTitle", "");
      } else if (action === "show") {
        this.set("showNavDropdownOptions", true);
      } else {
        if (
          title === "Messages" ||
          title === "Profile" ||
          title === "Reports" ||
          title === "Settings" ||
          (title === "History" &&
            (!this.get("showOptionTitle") || this.get("isMobileSize")))
        ) {
          this.set("sideNavExtraTitle", title);
          this.toggleProperty("showNavDropdownOptions");
        }
      }
    },
    mouseenterCollapseContainer: function () {
      jQuery(".side-nav-collapse-button").css("display", "block");
    },
    mouseleaveCollapseContainer: function () {
      jQuery(".side-nav-collapse-button").css("display", "none");
    },
    goToRoute: function (route, param1, param2) {
      if (this.get("isTransitioning")) {
        return;
      }
      if (param1 && param2) {
        this.get("router").transitionTo(route, param1, param2);
      } else if (param1) {
        this.get("router").transitionTo(route, param1);
      } else {
        this.get("router").transitionTo(route);
      }
      this.send("showNavDropdownOptionsFunc", "hide");
    },
  },

  // Events
  didInsertElement: function () {
    this._super();
    var self = this;

    Ember.run.later(function () {
      jQuery(".site-footer").css("visibility", "hidden"); // hide footer on load to disguise some undesirable looks
      self.wrapperAuthPadding();
      if (window.sessionStorage.side_nav_open === "false") {
        self.send("sideNavCollapse", true);
      }
      jQuery(".main-nav").collapse("hide");
    }, 1);
    Ember.run.later(function () {
      jQuery(".site-footer").css("visibility", "visible");
    }, 1000);
    jQuery(".side-nav-extra").css("left", "-768px");
    this.set("showNavDropdownOptions", false);
    this.set("sideNavExtra_Reports", false);
    this.set("sideNavExtra_Settings", false);
    this.set("sideNavExtra_History", false);
    this.set("sideNavExtra_Profile", false);
  },
});
