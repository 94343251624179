let CONFIG_NAMESPACE = {
    "hasBeenDecorated": false,
    "DECORATED_ENV": null,
    "docker": (_ENV) => {
      _ENV.APP.HOST_BASE = "docker.payaconnect.com";
      _ENV.APP.API_URL = "api.docker.payaconnect.com";
      _ENV.APP.DEVELOPER_ID = `b1111111`;
      _ENV.APP.IS_PROD = false;
      _ENV.APP.OAUTH_INTEGRATIONS.drchrono = `Oi5fTcPf3BBO9wEF2nmbcvhI0MnIEexj3PH2HKp2`;
      _ENV.APP.FULL_URL = `https://${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
  
      return _ENV;
    },
    "development": (_ENV) => {
      _ENV.APP.HOST_BASE = "dev.payaconnect.com";
      _ENV.APP.API_URL = "paya.dev.payaconnect.com";
      _ENV.APP.DEVELOPER_ID = 'b1111111';
      _ENV.APP.IS_PROD = false;
      _ENV.APP.OAUTH_INTEGRATIONS.drchrono = 'Oi5fTcPf3BBO9wEF2nmbcvhI0MnIEexj3PH2HKp2';
      _ENV.rootURL = ``;
      _ENV.APP.FULL_URL = `https://${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
  
      return _ENV;
    },
    "develop": (_ENV) => {
      _ENV.APP.HOST_BASE = "dev.payaconnect.com";
      _ENV.APP.API_URL = "api.dev.payaconnect.com";
      _ENV.APP.DEVELOPER_ID = 'payadev'; // payasand
      _ENV.APP.IS_PROD = false;
      _ENV.APP.OAUTH_INTEGRATIONS.drchrono = `Oi5fTcPf3BBO9wEF2nmbcvhI0MnIEexj3PH2HKp2`;
      _ENV.APP.FULL_URL = `https://${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
  
      return _ENV;
    },
    "payasandbox": (_ENV) => {
      _ENV.APP.HOST_BASE = "sandbox.payaconnect.com";
      _ENV.APP.API_URL = `api.sandbox.payaconnect.com`;
      _ENV.APP.DEVELOPER_ID = `paya`; // payasand
      _ENV.APP.IS_PROD = false;
      _ENV.APP.OAUTH_INTEGRATIONS.drchrono = `Oi5fTcPf3BBO9wEF2nmbcvhI0MnIEexj3PH2HKp2`;
      _ENV.APP.FULL_URL = `https://${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
      _ENV.APP.DEVELOPER_ID = _ENV.APP.DEVELOPER_ID += _ENV.APP.IS_PROD ? `prod` : `sand`;
  
      return _ENV;
    },
    "payaproduction": (_ENV) => {
      _ENV.APP.HOST_BASE = "payaconnect.com";
      _ENV.APP.API_URL = `payaconnect.com`;
      _ENV.APP.DEVELOPER_ID = `paya`; // payaprod
      _ENV.APP.IS_PROD = true;
      _ENV.APP.FULL_URL = `https://api.${_ENV.APP.IS_PROD ? `` : `sandbox.`}${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
      _ENV.APP.DEVELOPER_ID = _ENV.APP.DEVELOPER_ID += _ENV.APP.IS_PROD ? `prod` : `sand`;
  
      return _ENV;
    },
    "qa": (_ENV) => {
      _ENV.APP.HOST_BASE = "qa.payaconnect.com";
      _ENV.APP.API_URL = "api.qa.payaconnect.com";
      _ENV.APP.DEVELOPER_ID = 'payaqa'; // payasand
      _ENV.APP.IS_PROD = false;
      _ENV.APP.FULL_URL = `https://${_ENV.APP.API_URL}/${_ENV.APP.API_NAMESPACE}`;
      _ENV.APP.OAUTH_INTEGRATIONS.drchrono = `Oi5fTcPf3BBO9wEF2nmbcvhI0MnIEexj3PH2HKp2`;
  
      return _ENV;
    },
    "test": (_ENV) => {
      _ENV.rootURL = `/dist/tests/`;
      /*
       *  Testem prefers this...
       * _ENV.locationType = 'none';
       */
  
      // keep test console output quieter
      _ENV.APP.LOG_ACTIVE_GENERATION = false;
      _ENV.APP.LOG_VIEW_LOOKUPS = false;
  
      _ENV.APP.rootElement = `#ember-testing`;
      _ENV.APP.autoboot = false;
  
      return _ENV;
    },
    "cloudfront": (_ENV) => {
      _ENV.APP.HOST_BASE = "#{HOST_BASE}";
      _ENV.APP.API_URL = `#{API_URL}`;
      _ENV.APP.DEVELOPER_ID = `#{DEVELOPER_ID}`; // payaprod
      _ENV.APP.IS_PROD = true;
      _ENV.APP.FULL_URL = `#{FULL_URL}`;
  
      return _ENV;
    },
  }
  
  const decorate = (environment, ENV) => {
    if (CONFIG_NAMESPACE.hasBeenDecorated === true) return CONFIG_NAMESPACE.DECORATED_ENV;
  
    CONFIG_NAMESPACE['DECORATED_ENV'] = CONFIG_NAMESPACE[environment](Object.assign({}, ENV));
    CONFIG_NAMESPACE.hasBeenDecorated = true;
  
    return CONFIG_NAMESPACE['DECORATED_ENV'];
  };
  
  export default {
    decorate,
    "getEnv": (environment, ENV) => {
      if (typeof CONFIG_NAMESPACE.DECORATED_ENV !== 'undefined' && CONFIG_NAMESPACE.DECORATED_ENV !== null) {
        return CONFIG_NAMESPACE.DECORATED_ENV;
      }
  
      return decorate(environment, ENV);
    }
  };
  
  
  
