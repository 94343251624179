import Ember from "ember";
import ApplicationRouteMixin from "ember-simple-auth/mixins/application-route-mixin";
import jQuery from "jquery";

export default Ember.Route.extend(ApplicationRouteMixin, {
  // Dependency Injections
  sessionService: Ember.inject.service("session"),
  applicationData: Ember.inject.service("application-data"),
  router: Ember.inject.service("router"),

  // Computed Properties
  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),

  isLoading: false,
  apphistory: null,
  appSession: null,

  activate: function () {
    var self = this;
    jQuery(window).on("resize", function () {
      self.setClientWidth();
    });

    if (!this.get("domainBranding") && window.localStorage.sub_domain) {
      this.transitionTo("fourohfour");
    }

    this._super();
  },
  model: function () {},

  setupController: function () {},

  checkAppVersion: function () {
    // Get available version from source
    jQuery
      .ajax({
        url: window.location.origin + "/version.txt?" + secondsTimestamp(),
        type: "GET",
      })
      .then(
        function (version) {
          console.log("Origin Version: " + version.trim());
          console.log("Current Version: " + window.UiENV.APP.VERSION);
          var new_version = false;
          var originParts = version.trim().split(".");
          var currentParts = window.UiENV.APP.VERSION.split(".");
          originParts.forEach(function (item, index) {
            if (originParts[index] !== currentParts[index]) {
              new_version = true;
            }
          });
          if (new_version) {
            noty({
              text: '<div class="appRefresh hand">This page is out of date. Please click here to refresh and get the latest version.</div>',
              layout: "topRight",
              type: "warning",
              timeout: "0",
              closeWith: [],
            });
          }
        },
        function () {
          console.log("Unable to get available version.");
        }
      );
  },

  setClientWidth: function () {
    var body = jQuery(".ember-application")[0];
    var applicationData = this.get("applicationData");
    applicationData.set("clientWidth", body.clientWidth);
  },

  error: function (reason) {
    if (reason.status === 401) {
      this.send("authorizationFailed");
    } else if (reason.status === 504) {
      this.transitionTo("fiveohfour");
    }
  },

  clearSessionTimeout: Ember.observer(
    "session.authenticated.expire_ts",
    function () {
      var session = this.get("session");

      // Remove any stale session timeouts
      var appSess = this.get("appSession");
      if (appSess) {
        var n = jQuery(".PrettyOkNoty");
        if (n.length > 0) {
          jQuery.noty.close(n[0].id);
        }
        Ember.run.cancel(appSess);
      }

      if (!session || !session.get("authenticated.expire_ts")) {
        return;
      }

      if (secondsTimestamp() > session.get("authenticated.expire_ts")) {
        return;
      }
      this.setSessionTimeout();
    }
  ),
  setSessionTimeout: function () {
    var self = this;
    var session = this.get("session");

    if (session) {
      var appSess = Ember.run.later(
        this,
        function () {
          pretty_confirm_ok_only(
            "Your session is about to expire.<br>Are you still here?<br><br><small>You're going have to click 'ok' to stay logged in.</small>",
            function () {
              self.get("store").query("user", {
                id: session.get("authenticated.user.id"),
                timestamp: secondsTimestamp(),
              });
            }
          );
          var expSess = Ember.run.later(
            this,
            function () {
              console.log("Session time expired, redirecting");
              window.sessionStorage.is_sso = false;
              session.invalidate();
            },
            30000
          );
          self.set("appSession", expSess);
        },
        (session.get("authenticated.expire_ts") - secondsTimestamp() - 30) *
          1000
      );
      this.set("appSession", appSess);
    }
  },
  setBanners: function () {
    var controllerName = this.router.currentRouteName;
    if (controllerName && controllerName.indexOf("contactuser") === -1) {
      var controller = this.controllerFor(controllerName);
      if (controller) {
        var contact = controller.get("contact");
        if (contact && controllerName.indexOf("report") === -1) {
          var session = this.get("session");
          if (session) {
            var history = session.get("authenticated.history");
            if (history) {
              if (history.length > 0) {
                if (history[0].link_name.substring(0, 8) === "contacts") {
                  if (history[0].link_id === contact.get("id")) {
                    return;
                  }
                }
              }
            }
          }
          var notyId = jQuery(".contactNoty").attr("id");
          if (notyId) {
            return;
          }
          this.send("displayNotification", {
            controller_name: "contact",
            header_message_type_id: contact.get("header_message_type_id"),
            header_message: contact.get("header_message"),
            close_previous: false,
          });
        }
      }
    }
  },
  getUsersUnreadMessages: function () {
    var session = this.get("session");
    var self = this;
    this.get("store")
      .query("mymailboxmessageuser", {
        user_id: session.get("authenticated.user_id"),
        mailbox_message_status_id: "2",
        expand: "mailbox_message, mailbox_message_status",
        sort: "-created_ts",
      })
      .then(function (results) {
        self.controller.set("unreadMessages", results);
        session.set("authenticated.numUnreadMessages", results.content.length);
        session.store.persist({
          authenticated: session.get("authenticated"),
        });
      }).catch(function (error) {
        return;
      });
  },
  disconnectUsersUnreadMessagesWebsocket: function () {
    var unreadMessagesSocket = this.get("unreadMessagesSocket");
    if (unreadMessagesSocket) {
      unreadMessagesSocket.off("connect");
      unreadMessagesSocket.off("authentication_error");
      unreadMessagesSocket.off("notifications");
      unreadMessagesSocket.off("disconnect");
      unreadMessagesSocket.disconnect();
    }
    this.set("unreadMessagesSocket", null);
  },
  connectUsersUnreadMessagesWebsocket: function () {
    var self = this;
    var session = this.get("session");

    const url = `https://terminalrouter.${window.ENV.APP.HOST_BASE}:8443/notifications`;
    console.log("connectUsersUnreadMessagesWebsocket:url: ", url);

    var unreadMessagesSocket = io(url, {
      transports: ["websocket"],
      reconnection: false,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      secure: true,
      query: {
        token: session.get("authenticated.token"),
      },
    });
    unreadMessagesSocket.on("connect", function () {
      self.set("unreadMessagesSocket", unreadMessagesSocket);
      window.addEventListener("beforeunload", function () {
        self.disconnectUsersUnreadMessagesWebsocket();
      });
    });
    unreadMessagesSocket.on("authentication_error", function () {
      self.disconnectUsersUnreadMessagesWebsocket();
    });
    unreadMessagesSocket.on("notifications", function (data) {
      var session = self.get("session");
      session.set("authenticated.numUnreadMessages", data.unread);
      session.store.persist({
        authenticated: session.get("authenticated"),
      });
    });
    unreadMessagesSocket.on("disconnect", function () {
      self.disconnectUsersUnreadMessagesWebsocket();
    });
    unreadMessagesSocket.on("connect_error", function () {
      self.disconnectUsersUnreadMessagesWebsocket();
    });
    this.set("unreadMessagesSocket", unreadMessagesSocket);
  },

  sessionAuthenticated: function () {
    console.log("User Authenticated Successfully");
    this._super(...arguments);
    var additionalText = "";
    var loginController = this.controllerFor("login");
    if (loginController.get("changePassword")) {
      additionalText = "Password changed successfully!<br>";
    }
    loginController.set("changePassword", false);

    // Get session data
    var session = this.get("session");

    var tabId = session.get("authenticated.tab_id");
    var sessionTabId = window.sessionStorage.tab_id;
    if (sessionTabId !== tabId) {
      console.log("**************** Not Main Tab ****************");
      this.transitionTo("altindex");
      return;
    }

    this.checkAppVersion();

    var self = this;
    var user = this.get("store").queryRecord("user", {
      expand: "active_notification_alerts",
      id: session.get("authenticated.user_id"),
    });
    user.then(
      function (data) {
        var u = data;
        var alerts = u.get("active_notification_alerts");
        alerts.forEach(function (item) {
          // Set user notifications
          self.send("displayNotification", {
            controller_name: "notification",
            header_message_type_id: item.get("alert_type_id"),
            header_message: item.get("alert_message"),
            close_previous: false,
            on_close_id: item.get("id"),
          });
        });
      },
      function () {
        console.log("Error retrieving notification alerts");
      }
    );

    // GET unread messages
    this.getUsersUnreadMessages();

    // setup websocket for realtime new message recieving
    this.connectUsersUnreadMessagesWebsocket();

    var password_expires = moment(
      session.get("authenticated.password_expire_ts"),
      "X"
    );
    if (session.get("authenticated.password_expire_ts")) {
      var password_warn = moment(
        session.get("authenticated.password_expire_ts"),
        "X"
      ).subtract(2, "weeks");
      if (password_warn.unix() < moment().unix()) {
        noty({
          text:
            "Please consider changing your password, it expires on " +
            password_expires.format("MM/DD/YYYY") +
            ".",
          layout: "topRight",
          type: "information",
          timeout: "12000",
        });
      }
    }

    // Establish single sign-on params, route and id if they are present
    var params = session.get("authenticated.param_data");
    var redirect_route_name = window.sessionStorage.redirect_route_name;
    var redirect_route_id = window.sessionStorage.redirect_route_id;
    delete window.sessionStorage.redirect_route_name;
    delete window.sessionStorage.redirect_route_id;

    // Establish single sign-on parameters
    if (params && redirect_route_name) {
      var controller = this.controllerFor(redirect_route_name);
      controller.set("sso_params", params);
    }

    var entry_page = session.get("authenticated.ui_prefs.entry_page") || "";
    if (session.get("authenticated.isContact")) {
      if (entry_page) {
        this.transitionTo("contactuser." + entry_page);
      } else {
        this.transitionTo("contactuser.makepayment");
      }
    } else {
      if (redirect_route_name) {
        if (redirect_route_id) {
          this.transitionTo(redirect_route_name, redirect_route_id);
        } else {
          this.transitionTo(redirect_route_name);
        }
      } else {
        if (entry_page) {
          this.transitionTo(entry_page);
        } else {
          this.transitionTo("virtualterminal");
        }
      }
    }
  },
  sessionInvalidated: function () {
    console.log("Session Invalidation Succeeded");

    // Close all modals
    var modal = jQuery("#modal").data("bs.modal");
    if (modal && modal.isShown) {
      this.send("closeModal");
    }

    // Close all notifications
    var notys = jQuery(".notificationNoty");
    for (var x = 0; x < notys.length; x++) {
      var notyObj = jQuery.noty.get(jQuery(notys[x]).attr("id"));
      delete notyObj.options.callback.onClose;
    }
    jQuery.noty.closeAll();

    this.disconnectUsersUnreadMessagesWebsocket();
    if (window.sessionStorage.is_sso === "false") {
      this.transitionTo("login");
    }
    Ember.run.later(function () {
      jQuery(".wrapper-auth").animate(
        {
          "padding-left": "0",
        },
        "fast"
      );
      jQuery(".wrapper").animate(
        {
          "padding-left": "0",
        },
        "fast"
      );
      jQuery(".site-footer").animate(
        {
          "margin-left": "0",
        },
        "fast"
      );
    }, 1);
  },

  actions: {
    loading: function () {
      jQuery(".progress-bar-container").removeClass("hidden");
      return true;
    },
    willTransition: function (transition) {
      this.controller.set("isTransitioning", true);
      var self = this;
      var options = self.get("apphistory");
      var session = this.get("session");
      if (options && transition.targetName !== "login") {
        var history = session.get("authenticated.history");

        // Remove from history if the length is over the max
        if (history) {
          var alreadyInHistory = false;
          if (history.length) {
            history.forEach(function (h) {
              if (
                h.title === options.title &&
                h.link_name === options.route_name
              ) {
                alreadyInHistory = true;
              }
              if (options.route_id) {
                if (options.route_id !== h.link_id) {
                  alreadyInHistory = false;
                }
              }
            });
            if (history.length >= 5 && !alreadyInHistory) {
              history.popObject();
            }
          }
          if (!alreadyInHistory) {
            history.unshiftObject({
              title: options.title,
              link_name: options.route_name,
              link_id: options.route_id || null,
              link_id_plus: options.route_id_plus || null,
            });
          }
        }
        session.set("authenticated.history", history);
      }
      self.set("apphistory", null);
    },
    didTransition: function () {
      this.controller.set("isTransitioning", false);
      jQuery(".progress-bar-container").addClass("hidden");
      jQuery("#loader").hide();
      window.scrollTo(0, 0);

      //this.setSessionTimeout();
      Ember.run.once(this, function () {
        this.setBanners();
      });

      // Set notification for sigpad
      var sigpad = window.sessionStorage.sigpad;
      if (sigpad) {
        this.controller.set(
          "sigPadConnected",
          sigpad.toLowerCase() === "true" ? true : false
        );
      } else {
        this.controller.set("sigPadConnected", false);
      }
      var session = this.get("session");
      var unreadMessagesSocket = this.get("unreadMessagesSocket");
      if (!unreadMessagesSocket && session.isAuthenticated) {
        this.connectUsersUnreadMessagesWebsocket();
      }
      this.setClientWidth();
      window.scrollTo(0, 0);
      this.controller.set("showNavDropdownOptions", false);
      if (session.get("isAuthenticated")) {
        Ember.run.later(function () {
          jQuery("#navbar-container").css("visibility", "visible");
          jQuery(".site-footer").css("visibility", "visible");
        });
      }
    },
    // Add link to history
    addHistory: function (options) {
      this.set("apphistory", options);
    },

    getUsersUnreadMessages: function () {
      this.getUsersUnreadMessages();
    },
    connectUsersUnreadMessagesWebsocket: function () {
      this.connectUsersUnreadMessagesWebsocket();
    },

    /***************************************************
		Session Authorization Callbacks
		**************************************************/
    authorizationFailed: function () {
      console.log("Authorization Failed");
      var self = this;
      var session = this.get("session");
      if (session.get("isAuthenticated")) {
        window.sessionStorage.is_sso = false;
        session.invalidate().then(function () {
          self.transitionTo("login");
        });
      } else {
        self.transitionTo("login");
      }
    },
    sessionDataInvalidated: function () {
      console.log("Session Data Invalidated");
      this.transitionTo("login");
    },

    // Modal functions
    openModal: function (modalName) {
      setTimeout(function () {
        jQuery("body").attr("style", "padding-right:0px");
      }, 1);
      return this.render(modalName, {
        into: "application",
        outlet: "modal",
      });
    },
    closeModal: function () {
      setTimeout(function () {
        jQuery("body").attr("style", "padding-right:0px");
      }, 1);
      return this.disconnectOutlet({
        outlet: "modal",
        parentView: "application",
      });
    },

    // Fired when the route did contain contacts but no longer does.
    // This is used to remove popup banners for specific contacts
    leavingContacts: function (targetName) {
      var stillHere = false;
      if (
        targetName.indexOf("contacts.") > -1 &&
        targetName !== "contacts.index"
      ) {
        stillHere = true;
      }
      if (!stillHere) {
        var notyId = jQuery(".contactNoty").attr("id");
        jQuery.noty.close(notyId);
      }
    },
    enteringRecurringDecline: function () {
      this.controller.set("isRecurringDecline", true);
    },

    displayNotification: function (options) {
      // Define the following option in the call
      var session = this.get("session");
      var close_previous = options.close_previous || false;
      var header_message_type_id =
        parseInt(options.header_message_type_id) || 0;
      var header_message = options.header_message || "";
      var controller_name = options.controller_name || "sample";
      var on_close_id = options.on_close_id || null;
      var on_close = "";
      var title = options.title || "Notice";

      // Close any existing notifications so we can redraw them if needed
      if (close_previous) {
        var notyId = jQuery("." + controller_name + "Noty").attr("id");
        jQuery.noty.close(notyId);
      }

      if (header_message) {
        var type = "alert";
        switch (header_message_type_id) {
          case 0: // Pop Up
            var dynamicController = this.controllerFor(
              "components.modals.dynamic"
            );
            on_close = on_close_id
              ? "removeNotification('" +
                on_close_id +
                "', '" +
                session.get("authenticated.user_id") +
                "', '" +
                session.get("authenticated.token") +
                "')"
              : "";
            if (on_close_id) {
              header_message +=
                '<div class="clearfix"><br></div><div><label><input id=' +
                on_close_id +
                session.get("authenticated.token") +
                ' onChange="' +
                on_close +
                '" type="checkbox"> Don\'t show this message again</label></div>';
            }
            dynamicController.set("content", header_message);
            dynamicController.set("title", title);
            dynamicController.set("className", "fiveinch");
            this.send("openModal", "components/modals/dynamic");
            return true;
          case 1: // Red Banner
            type = "error";
            break;
          case 2: // Yellow Banner
            type = "warning";
            break;
          case 3: // Blue Banner
            type = "information";
            break;
          case 4: // Green Banner
            type = "success";
            break;
        }

        on_close = on_close_id
          ? function () {
              removeNotification(
                on_close_id,
                session.get("authenticated.user_id"),
                session.get("authenticated.token")
              );
            }
          : function () {};

        var n = noty({
          text: header_message.replace(/<(?:.|\n)*?>/gm, ""),
          timeout: null,
          type: type,
          layout: "top",
          callback: {
            onClose: on_close,
          },
        });
        jQuery("#" + n.options.id).addClass(controller_name + "Noty");
      }
    },
    showCheckImages: function (context, id) {
      var self = this;
      var controller = self.controllerFor("components.modals.checkimages");
      context.set("isLoading", true);
      jQuery
        .ajax({
          url: window.UiENV.APP.FULL_URL + "/transactions/" + id + "/getimages",
          type: "GET",
          headers: {
            "Content-Type": "application/json",
            "developer-id": window.UiENV.APP.DEVELOPER_ID,
            "access-token": self.get("session.authenticated.token"),
          },
        })
        .then(
          function (data) {
            controller.set("images", data.transaction_images);
            context.set("isLoading", false);
            // Close any pre-existing modals
            self.send("closeModal");
            self.send("openModal", "components/modals/checkimages");
          },
          function () {
            noty({
              text: "Error getting check images.",
              type: "error",
            });
            context.set("isLoading", false);
          }
        );
    },
    showTransDetail: function (
      transaction,
      locationinfo,
      tagList,
      multiTransactions
    ) {
      var self = this;
      var tdController = self.controllerFor(
        "components.modals.transactiondetail"
      );

      if (multiTransactions) {
        const recordCount = tdController.get("currentRecord") - 1;
        const trans = multiTransactions[recordCount];
        transaction = multiTransactions[recordCount].get("firstObject");
        tdController.set("transactions", multiTransactions);
      }

      // Get the product for this transaction
      var product_transaction_id = transaction.get("product_transaction_id");
      if (!product_transaction_id) {
        product_transaction_id = locationinfo.get(
          "default_" + transaction.get("payment_method")
        );
      }
      var products = locationinfo.get("product_transactions");
      var product = products
        .filter(function (p) {
          return p.get("id") === product_transaction_id;
        })
        .get("firstObject");

      // These next two lines are needed to clear the form so the next time
      // the form shows its blank until the promise returns
      tdController.set("transaction", null);
      tdController.set("transactionsplits", null);
      tdController.set("transaction_id", null);
      tdController.set("product", null);
      tdController.set("locationinfo", null);
      tdController.set("contact", null);

      tdController.set("transaction_id", transaction.get("id"));
      tdController.set("locationinfo", locationinfo);
      tdController.set(
        "hasTags",
        locationinfo.get("tags.length") > 0 ||
          (tagList && tagList.get("length") > 0)
      );
      tdController.set("product", product);
      tdController.set("emailReceipt", false);
      tdController.set("email_address", transaction.get("contact.email"));
      tdController.set("contact", transaction.get("contact"));
      tdController.set(
        "sender",
        self.router.currentRouteName.replace(/[\.\-]/g, "")
      );

      // Close any pre-existing modals
      self.send("closeModal");
      self.send("openModal", "components/modals/transactiondetail");
    },
    showUserReportName: function (context) {
      var controller = this.controllerFor("components.modals.userreport");
      var self = this;

      // These next two lines are needed to clear the form so the next time
      // the form shows its blank until the promise returns
      controller.set("context", context);
      controller.set("userreport", {});
      controller.set(
        "userreport",
        self.get("store").createRecord("userreport", {
          field_data: context.get("selectedItemsNames").toString(),
          filter_data: context.get("filtering"),
          title: context.get("isShared")
            ? context.get("userreport.title")
            : null,
          url: context.get("gridViewColumnOptions.url"),
        })
      );

      // Close any pre-existing modals
      this.send("closeModal");
      this.send("openModal", "components/modals/userreport");
    },
    showShareReport: function (report, reportSharedWith) {
      var sharereportController = this.controllerFor(
        "components.modals.sharereport"
      );

      // These next two lines are needed to clear the form so the next time
      // the form shows its blank until the promise returns
      sharereportController.set("reportshare", {
        model_id: null,
      });
      sharereportController.set("isCriteria", {});
      sharereportController.set("showSharedWith", {});
      sharereportController.set("reportCreated", null);

      sharereportController.set("reportshare.user_report_id", report.id);
      sharereportController.set("reportshare.model", "user");
      sharereportController.set("reportSharedWith", reportSharedWith);
      sharereportController.set("reportCreated", report);
      sharereportController.set("showSharedWith", {
        user: true,
        location: true,
      });
      sharereportController.set("isCriteria", {
        user: true,
        location: false,
      });

      // Close any pre-existing modals
      this.send("closeModal");
      this.send("openModal", "components/modals/sharereport");
    },
    gridNeedsRefresh: function (context) {
      context.set("gridParams.needsRefresh", new moment().unix());
    },
    uploadSignature: function (
      resource_id,
      resource,
      model,
      base64String,
      controller
    ) {
      sigPad.onClear();
      var signature = this.get("store").createRecord("signature", {
        resource_id: resource_id,
        resource: resource,
        signature: base64String,
      });
      signature.save().then(
        function (data) {
          model.set("signature", data);
          noty({
            text: "Signature upload successfully!",
            type: "success",
          });
          if (!Ember.isEmpty(controller)) {
            controller.set("isLoading", false);
          }
        },
        function () {
          noty({
            text: "Error uploading signature!",
            type: "error",
            timeout: "5000",
          });
          if (!Ember.isEmpty(controller)) {
            controller.set("isLoading", false);
          }
        }
      );
    },
    setFormErrors: function (controller, model, response) {
      if (!response.responseText) {
        return;
      }
      var errors = JSON.parse(response.responseText).errors;
      var messages = [];

      for (var key in errors) {
        if (errors.hasOwnProperty(key)) {
          messages.push(errors[key]);
        }
      }
      controller.set("errorMessages", messages);
    },
    generateUUID: function (inputRoute, inputName) {
      var controller = this.controllerFor(inputRoute);
      if (controller.get(inputName)) {
        pretty_confirm("Are you sure?", function () {
          controller.set(inputName, createUUID());
        });
      } else {
        controller.set(inputName, createUUID());
      }
    },
  },
});
