import Ember from 'ember'
import DS from 'ember-data';

// This adapter sets the values for the following dropdowns in the contact profile section
// bill_via_email_enrollment_status, text_to_pay_enrollment_status, paperless_billing
// Also specifies methods to find the status text related to the status id. 
export default DS.Adapter.extend({
  enrollment_status: [
    {
      id: '0',
      title: 'pending',
    },
    {
      id: '1',
      title: 'not enrolled',
    },
    {
      id: '2',
      title: 'enrolled',
    },
  ],

  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var enroll_status = self.get('enrollment_status');
      var enroll_status = enroll_status.filter(function(item) {
        return item.id === query.id;
      });
      if (enroll_status.length > 0) {
        resolve({
          enrollment_status: enroll_status
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findAll: function() {
    return {
      enrollmentstatus: this.get('enrollment_status')
    };
  }
});