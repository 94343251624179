import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set controller params
  sort: '-due_date',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'ach_product_transaction_id',
    'active',
    'amount_due',
    'cc_product_transaction_id',
    'contact_id',
    'due_date',
    'email',
    'expire_date',
    'invoice_number',
    'location_id',
    'payment_status_id',
    'remaining_balance',
    'status_id',
    'tags',
    'title'
  ],

  hasProductsWithQuickInvoice: Ember.computed('locations.[]', function() {
    var products = [];
    this.get('locations').map(function(location) {
      location.get('product_transactions').map(function(product) {
        if (product.get('quick_invoice_allow')) {
          products.pushObject(product);
        }
      });
    });
    return products.length >= 1;
  }),

  // Actions
  actions: {}

});
