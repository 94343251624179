import Ember from 'ember';

export default Ember.Component.extend({
  tagName: 'option',
  attributeBindings: ['value', 'selected'],

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  init: function() {
    this._super(...arguments);
    this.set('elementId', this.get('thisId') + '_' + this.get('value'));
  },

  value: Ember.computed('content', 'optionValue', function() {
    var content = this.get('content');
    var optionValue = this.get('optionValue');
    var contentValue;
    if (optionValue) {
      contentValue = Ember.get(content, optionValue);
    } else {
      contentValue = content;
    }
    var validContentValue = contentValue || contentValue === 0 || contentValue === false || contentValue === '';
    return validContentValue ? contentValue : content[optionValue];
  }),
  title: Ember.computed('content', 'optionTitle', function() {
    var content = this.get('content');
    var optionTitle = this.get('optionTitle');
    var contentTitle;
    if (optionTitle) {
      contentTitle = Ember.get(content, optionTitle);
    } else if (content) {
      contentTitle = content;
    } else {
      contentTitle = ' ';
    }
    return contentTitle ? contentTitle : ' ';
  }),
  selected: Ember.computed('value', 'selectedContent', function() {
    var value = this.get('value');
    var selectedContent = this.get('selectedContent');
    if (this.get('isMobileSort')) {
      return String(value) === String(selectedContent) || '-' + String(value) === String(selectedContent);
    } else {
      return String(value) === String(selectedContent);
    }
  }),
});
