import Ember from 'ember';
import DS from 'ember-data';
import {pluralize} from 'ember-inflector';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import envConfigFactory from './../config/esm/envConfigFactory';

var get = Ember.get;

export default DS.RESTAdapter.extend(DataAdapterMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  host: Ember.computed(function () {
    const ENV = envConfigFactory.getEnv(window.UiENV.environment, window.UiENV);
    const computed_host = `https://api.${ENV.APP.HOST_BASE}`;

    if (ENV.environment === 'development') { console.log(`computed_host: ${computed_host}`); }

    return computed_host;
  }),
  namespace: window.UiENV.APP.API_NAMESPACE,
  authorizer: 'authorizer:custom',
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  headers: Ember.computed('session.authenticated.token', function() {
    return {
      'access-token': this.get('session.authenticated.token'),
      'developer-id': ENV.APP.DEVELOPER_ID
    };
  }),

  pathForType: function(modelName) {
    var lowered = modelName.toLowerCase();
    return pluralize(lowered).replace('admin-', '').replace('admin_', '');
  },
  serializeDate: function(value) {
    var newValue;
    if (value.length === 10) {
      newValue = moment(value, 'MM/DD/YYYY');
    } else if (value.length === 19) {
      newValue = moment(value, 'MM/DD/YYYY HH:mm:ss');
    } else {
      return null;
    }
    return newValue.isValid() ? newValue.unix() : null;
  },
  serializeSql: function(value) {
    var newValue;
    if (value.length === 10) {
      newValue = moment(value, 'MM/DD/YYYY');
    } else if (value.length === 19) {
      newValue = moment(value, 'MM/DD/YYYY HH:mm:ss');
    } else {
      return null;
    }
    return newValue.isValid() ? newValue.format('YYYY-MM-DD') : null;
  },
  ajaxOptions: function(url, type, hash) {
    hash = hash || {};
    hash.url = url;
    hash.type = type;
    hash.dataType = 'json';
    hash.context = this;

    // Method to set the data properly if not posting files to API
    // contentType for Quickinvoice should be multipart/form-data on POST only, otherwise application/json; charset=utf-8
    if (hash.data && type !== 'GET' && !hash.data.file && !hash.data.logo && (!hash.data.quickinvoice || (hash.data.quickinvoice && type !== 'POST'))) {
      hash.contentType = 'application/json; charset=utf-8';
      hash.data = JSON.stringify(hash.data);
    }

    // Special handler for formatting data when posting files to API
    // contentType for Quickinvoice should be multipart/form-data on POST only, otherwise application/json; charset=utf-8
    if (hash.data && (hash.data.file || hash.data.logo || (hash.data.quickinvoice && type === 'POST'))) {
      hash.processData = false;
      hash.contentType = false;
      var fd = new FormData();
      var root = Object.keys(hash.data)[0];
      var _ref = Object.keys(hash.data[root]);

      for (var _i = 0, _len = _ref.length; _i < _len; _i++) {
        var key = _ref[_i];
        if (hash.data[root][key] || root === 'quickinvoice') {
          if(typeof hash.data[root][key] === 'object' && root === 'quickinvoice') {
            if(Ember.isArray(hash.data[root][key])) {
              hash.data[root][key].forEach(function(k,index){
                if(key === 'tags') {
                  fd.append('' + root + '[' + key + ']' + '[' + index + ']', hash.data[root][key][index]['title']);
                } else if(key === 'files') {
                  let newName = hash.data[root][key][index].name.replace(/[\|\[\]\/\:\?"<>\|\*\\]/g, '_');
                  fd.append('' + root + '[' + key + ']' + '[' + index + ']', hash.data[root][key][index], newName);
                } else {
                  fd.append('' + root + '[' + key + ']' + '[' + index + ']', hash.data[root][key][index]);
                }
              });
            } else if(hash.data[root][key] !== null) {
              for(var prop in hash.data[root][key]) {
                fd.append('' + root + '[' + key + ']' + '[' + prop + ']', hash.data[root][key][prop]);
              }
            } else if(key === 'ach_product_transaction_id' || key === 'cc_product_transaction_id') {
              fd.append('' + root + '[' + key + ']', hash.data[root][key] ? hash.data[root][key] : "");
            }
          } else if((root === 'file' && key === 'file') || (root === 'logo' && key === 'logo')) {
            let newName = hash.data[root][key].name.replace(/[\|\[\]\/\:\?"<>\|\*\\]/g, '_');
            fd.append('' + root + '[' + key + ']', hash.data[root][key], newName);
          } else {
            fd.append('' + root + '[' + key + ']', hash.data[root][key]);
          }
        }
      }
      hash.data = fd;
    }

    // Special handler for formatting date filters
    if (hash.data && !hash.data.file && !hash.data.file && !hash.data.quickinvoice && type === 'GET') {
      var self = this;
      var data = hash.data;
      for (var d in data) {
        if (data.hasOwnProperty(d)) {
          if (d.substr(d.length - 5, 5) === '_from' || d.substr(d.length - 3, 3) === '_to') {
            if (['end_date_from', 'end_date_to', 'next_run_date_from', 'next_run_date_to', 'start_date_from', 'start_date_to', 'event_date_from', 'event_date_to', 'settle_date_from', 'settle_date_to'].indexOf(d) > -1) {
              data[d] = data[d] ? self.serializeSql(data[d]) : '';
            } else {
              data[d] = data[d] ? self.serializeDate(data[d]) : '';
            }
          }
        }
      }
    }

    var headers = get(this, 'headers');
    if (headers !== undefined) {
      hash.beforeSend = function(xhr) {
        Object.keys(headers).map(function(key) {
          xhr.setRequestHeader(key, headers[key]);
        });
      };
    }

    var session = this.get('session');
    if (!Ember.isEmpty(session.get('authenticated.token'))) {
      if (secondsTimestamp() > session.get('authenticated.expire_ts')) {
        if (hash.type !== 'DELETE' && hash.url.indexOf('/' + window.UiENV.APP.API_NAMESPACE + '/token') === -1) {
          console.log('Session expired. Redirecting to login');
          window.sessionStorage.is_sso = false;
          session.invalidate();
        }
      } else {
        Ember.run.once(this, function() {
          var expire_ts = parseInt(secondsTimestamp()) + (parseInt(session.get('authenticated.expire')) * 60);
          if (expire_ts !== parseInt(session.get('authenticated.expire_ts'))) {
            session.set('authenticated.expire_ts', expire_ts);
            session.store.persist({
              authenticated: session.get('authenticated')
            });
          }
        });
      }
    }

    return hash;
  },

  handleResponse: function(status, headers, payload) {
    var session = this.get('session');
    if (status === 422 || status === 409) {
      var errors = [];
      if (payload.errors != null) {
        let jsonErrors = payload.errors;
        Object.keys(jsonErrors).forEach(function(key) {
          if (typeof jsonErrors[key][0] === 'string') {
            let error = {
              detail: jsonErrors[key][0],
              source: {
                pointer: 'data/attributes/' + key,
                prop: key
              }
            };
            errors.push(error);
          } else {
            Object.keys(jsonErrors[key][0]).forEach(function(k) {
              if (typeof jsonErrors[key][0][k] === 'string') {
                let error = {
                  detail: jsonErrors[key][0][k],
                  source: {
                    pointer: 'data/attributes/' + key,
                    prop: k
                  }
                };
                errors.push(error);
              } else {
                Object.keys(jsonErrors[key][0][k]).forEach(function(l) {
                  if (typeof jsonErrors[key][0][k][l] === 'string') {
                    let error = {
                      detail: jsonErrors[key][0][k][l],
                      source: {
                        pointer: 'data/attributes/' + key,
                        prop: l
                      }
                    };
                    if(key === 'level3_default' || key === 'terminal_timeouts') {
                      error.source.prop = k;
                    }
                    errors.push(error);
                  } else {
                    Object.keys(jsonErrors[key][0][k][l]).forEach(function(m) {
                      if (typeof jsonErrors[key][0][k][l][m] === 'string') {
                        let error = {
                          detail: jsonErrors[key][0][k][l][m],
                          source: {
                            pointer: 'data/attributes/' + key,
                            prop: m
                          }
                        };
                        errors.push(error);
                      } else {
                        Object.keys(jsonErrors[key][0][k][l][m]).forEach(function(n) {
                          if (typeof jsonErrors[key][0][k][l][m][n] === 'string') {
                            let error = {
                              detail: jsonErrors[key][0][k][l][m][n],
                              source: {
                                pointer: 'data/attributes/' + key,
                                prop: n
                              }
                            };
                            if(key === 'level3_default') {
                              error.source.prop = k + '.' + m;
                            }
                            errors.push(error);
                          } else {
                            Object.keys(jsonErrors[key][0][k][l][m][n]).forEach(function(o) {
                              let error = {
                                detail: jsonErrors[key][0][k][l][m][n][o],
                                source: {
                                  pointer: 'data/attributes/' + (key + [0] + [k] + [l] + [m] + [n] + [o]).replace(/0|1/g, '_'),
                                  prop: o
                                }
                              };
                              errors.push(error);
                            });
                          }

                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
      return new DS.InvalidError(errors);
    } else if (status === 406) {
      noty({
        text: '<div class="appRefresh hand">This application has been updated. Please click here to reload.</div>',
        layout: 'topCenter',
        type: 'warning',
        timeout: '0',
        closeWith: [],
        modal: true,
        killer: true
      });
      return payload;
    } else if (status === 403) {
      noty({
        text: payload.message,
        type: 'error',
        layout: 'topCenter',
        modal: true
      });
      return payload;
    } else if (status === 401 && session.get('isAuthenticated') && session.get('authenticated.token')) {
      session.invalidate();
    } else {
      return payload;
    }
  },
});
