import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Various controller properties
  isLoading: false,
  showPrint: true,
  showRecurringDetails: false,

  accountTypeName: Ember.computed('accountTypes.[]', 'model.account_type', 'model.account_vault.account_type', function() {
    var self = this;
    var name = '';
    this.get('applicationData.accountTypes').forEach(function(type) {
      if(type.id === self.get('model.account_type') || type.id === self.get('model.account_vault.account_type')) {
        name = type.title;
      }
    });
    return name;
  }),
  accountTypes: Ember.computed(function() {
    return this.get('applicationData.accountTypes');
  }),
  contactFullName: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'contact.{id,full_name}', function() {
    return this.get('contact.full_name');
  }),
  hasSignature: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.signature.id', function() {
    return this.get('model.signature.id');
  }),
  isTypeAccountVault: Ember.computed('authagreementType', function() {
    return this.get('authagreementType') === 'account';
  }),
  isTypeRecurring: Ember.computed('authagreementType', function() {
    return this.get('authagreementType') === 'recurring';
  }),
  modalWidth: Ember.computed('isTypeAccountVault', 'isTypeRecurring', function() {
    if(this.get('isTypeAccountVault')) {
      return 'fiveinch';
    } else if(this.get('isTypeRecurring')) {
      return 'fourinch';
    }
  }),
  modelAccountHolderName: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.account_holder_name', 'model.account_vault.account_holder_name', function() {
    if(this.get('isTypeAccountVault')) {
      return this.get('model.account_holder_name');
    } else if (this.get('isTypeRecurring')) {
      return this.get('model.account_vault.account_holder_name');
    } else {
      return null;
    }
  }),
  modelAccountType: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.account_type', 'model.account_vault.account_type', function() {
    if(this.get('isTypeAccountVault')) {
      return this.get('model.account_type');
    } else if (this.get('isTypeRecurring')) {
      return this.get('model.account_vault.account_type');
    } else {
      return null;
    }
  }),
  modelLastFour: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.last_four', 'model.account_vault.last_four', function() {
    if(this.get('isTypeAccountVault')) {
      return this.get('model.last_four');
    } else if (this.get('isTypeRecurring')) {
      return this.get('model.account_vault.last_four');
    } else {
      return null;
    }
  }),
  needSignature: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.needSignature', function() {
    return this.get('model.needSignature');
  }),
  recurringSurchargeAmount: Ember.computed('model.transaction_amount', 'product.surcharge.id', function() {
   var surcharge = this.get('product.surcharge');
   var model = this.get('model');
   if (!surcharge || !model) {
     return 0;
   }
   var surcharge_amount = 0;
   if (surcharge && surcharge.get('surcharge_on_recurring')) {
     var transaction_amount = parseFloat(model.get('transaction_amount')) || 0;
     var min_fee_amount = parseFloat(surcharge.get('min_fee_amount')) || 0;
     var max_fee_amount = parseFloat(surcharge.get('max_fee_amount')) || 0;
     var surcharge_fee = parseFloat(surcharge.get('surcharge_fee')) || 0;
     var surcharge_rate = parseFloat(surcharge.get('surcharge_rate')) || 0;
     surcharge_amount = (surcharge_rate / 100 * transaction_amount).round(2) + surcharge_fee;
     surcharge_amount = (surcharge_amount > max_fee_amount) ? max_fee_amount : (surcharge_amount < min_fee_amount) ? min_fee_amount : surcharge_amount;
   }
   return surcharge_amount;
 }),
  signatureImageSrc: Ember.computed('isTypeAccountVault', 'isTypeRecurring', 'model.signature.href', function() {
    return this.get('model.signature.href');
  }),
  signatureLegal: Ember.computed('location.id', 'model.id', function() {
    var self = this;
    var signatureType = null;
    var location = this.get('location');
    var products = location ? location.get('product_transactions') : [];
    var product = null;
    if(this.get('isTypeRecurring')) {
      signatureType = 'receipt_add_recurring_above_signature';
      product = products.filter(function(prod) {
        return prod.get('id') === self.get('model.product_transaction_id');
      }).get('firstObject');
    } else if(this.get('isTypeAccountVault')) {
      signatureType = 'receipt_add_account_above_signature';
      product = self.get('model.payment_method') === 'cc' ? location.get('defaultProductCC') : location.get('defaultProductACH');
    }
    return product ? product.get(signatureType) : null;
  }),
  submittedByContactUser: Ember.computed('model.contact_id', 'model.created_user.contact_id', 'model.contact.id', function() {
    var contact_id = this.get('model.contact_id') || this.get('model.contact.id');
    var created_id = this.get('model.created_user.contact_id');
    return (created_id && contact_id && created_id === contact_id) ? true : false;
  }),

  // Actions
  actions: {
    showDetails: function() {
      this.toggleProperty('showRecurringDetails');
    },
    sigSign: function() {
      sigPad.onSign();
    },
    sigClear: function() {
      sigPad.onClear();
    },
    sigDone: function() {
      var modelName = null;
      if(this.get('isTypeAccountVault')) {
        modelName = 'AccountVault';
      } else if(this.get('isTypeRecurring')) {
        modelName = 'Recurring';
      }
      sigPad.onDone();
      var self = this;
      if (sigPad.numPoints() === 0) {
        noty({
          text: 'Unable to save an empty signature.\nPlease try again!',
          type: 'error',
        });
      } else {
        self.set('isLoading', true);
        GetSigImageB64(function(sigString) {
          if (sigString) {
            self.send('uploadSignature', self.get('model.id'), modelName, self.get('model'), sigString, self);
          }
        });
      }
    }
  }
});
