import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsView: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('location.errors.messages', function() {
    this.set('errorMessages', this.get('location.errors.messages'));
  }),

  // Actions
  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    triggerEmail: function(location) {
      // Trigger welcome email here
      const localStorageSession = JSON.parse(localStorage.getItem('ember_simple_auth-session'))
      const { authenticated: { token: authToken } } = localStorageSession;
      const token = this.get('sessionService.data.authenticated.token') || authToken;
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      const self = this;
      jQuery.ajax({
        url: `${window.UiENV.APP.FULL_URL}/locations/${location.id}/sendwelcomeemail`,
        type: 'POST',
        data: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': token,
        }
      })
        .then(() => {
          noty({
            text: 'Welcome email sent!!',
          });
          self.set('isLoading', false);
          self.set('isEditing', false);
          self.send('refreshData');
        })
        .catch(() => {
          self.set('isLoading', false);
          noty({
            text: 'Unable to send welcome email!',
            type: 'error'
          });
        });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      location.set('is_new_domain', false);

      location.save().then(function() {
        noty({
          text: 'Location updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.send('refreshData');
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('locations.index');
    },
    cancel: function() {
      var location = this.get('location');
      location.rollbackAttributes();
      this.set('isEditing', false);
    },
    updateDomains: function(data) {
      var self = this;
      var domains = this.get('store').query('domain', {
        url: data,
        sort: 'url',
        page_size: '10',
        fields: 'id,url,title'
      });
      domains.then(function(result) {
        self.set('domains', result);
      });
    },
    updateParents: function(data) {
      var self = this;
      var parents = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    }
  }
});
