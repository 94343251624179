import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'byte_increment',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'free_bytes',
    'id',
    'increment_cost',
    'max_file_size_bytes',
    'monthly_fee',
    'title',
  ],
  columns: Ember.computed(function() {
    var self = this;
    return [{
      'name': 'id',
      'title': 'Id',
      'type': 'string',
      'sortable': true,
      'action': function(productfile) {
        console.log('productfile', productfile);
        var location_id = null;
        if(productfile.get('locations.length') >= 1) {
          location_id = productfile.get('locations').get('firstObject').get('id');
        }
        if(location_id) {
          self.transitionToRoute('locations.viewproductfile', location_id, productfile.get('id'));
        } else {
          noty({
            text: 'File Storage Service is currently unavailable!',
            type: 'error'
          });
        }
      },
      'actionpriv': ['v2-locations-get', 'v2-productfiles-get'],
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'title',
      'title': 'Title',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'free_bytes',
      'title': 'Free Bytes',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'byte_increment',
      'title': 'Byte Increment',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'increment_cost',
      'title': 'Increment Cost',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'monthly_fee',
      'title': 'Monthly Fee',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'max_file_size_bytes',
      'title': 'Max File Size Bytes',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'created_ts',
      'title': 'Created',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }];
  }),

  // Actions
  actions: {}
});
