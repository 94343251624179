import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  resetController: function(controller) {
    controller.set('tags', '');
    controller.set('account_type', '');
    controller.set('created_user_id', '');
  },
  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      users: store.query('user', {
        location_id: params.id,
        page_size: '50',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000',
        status_id: 1
      }).then(function(data) {
        return data.toArray();
      }),
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions,terminals',
      }),
      tagList: store.query('tag', {
        location_id: params.id,
        page_size: '500',
        sort: 'title'
      }),
      types: store.findAll('type'),
      statuses: store.findAll('status')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var product_transactions = [];
    var prods = models.location.get('product_transactions');
    if (prods) {
      prods.forEach(function(product_transaction) {
        product_transactions.push({
          'id': product_transaction.get('id'),
          'title': product_transaction.get('title')
        });
      });
    }
    controller.set('product_transactions', product_transactions);
    var pagerParams = {
      pageRoute: 'locations.transactions',
      pageModelId: models.location.get('id'),
      modelName: 'transaction',
      showExport: this.get('session.authenticated.resources.v2-transactions-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user.id,created_user.username,contact.id,contact.first_name,contact.last_name,contact.account_number,contact.email,surcharge.id,surcharge.surcharge_amount,location.id,location.name,tags,status,type,all_tags,product_transaction.id,product_transaction.title,terminal.id,terminal.title,quick_invoice',
      location_id: models.location.get('id'),
      show_totals: true
    };

    controller.set('gridParams', gridParams);
    models.users.pushObjects([{
      id: 'terminal',
      title: 'terminal',
      username: 'terminal'
    }, {
      id: 'system',
      title: 'system',
      username: 'system'
    }]);
    controller.set('users', models.users);
    controller.set('location', models.location);
    controller.set('tagList', models.tagList);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);

    if((controller.get('created_ts_from') || controller.get('created_ts_to')) && controller.get('created_ts') !== 'custom') {
      controller.set('created_ts_from', '');
      controller.set('created_ts_to', '');
    }
    if(controller.get('created_ts') === 'custom' && !controller.get('created_ts_from') && !controller.get('created_ts_to')) {
      controller.set('created_ts', 'today');
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Transactions',
      route_name: 'locations.transactions',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Transactions',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
