import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    mailbox_message: {
      embedded: 'always'
    },
    mailbox_message_status: {
      serialize: false,
      deserialize: 'records'
    }
  }
});