import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  active: DS.attr('boolean'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  declined_notification_per_item_fee: DS.attr('string'),
  location_id: DS.attr('string'),
  require_full_payment: DS.attr('boolean'),
  send_declined_notifications: DS.attr('boolean'),
  title: DS.attr('string'),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
});
