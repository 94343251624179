import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  dynamicController: Ember.inject.controller('components/modals/dynamic'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set controller params
  sort: 'name',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_number',
    'address1',
    'city',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'name',
    'office_ext_phone',
    'office_phone',
    'state',
    'zip_code',
  ],
  columns: Ember.computed('gridData', 'isMobileSize', function() {
    var self = this;
    var columns = {
      name: {
        'name': 'name',
        'title': 'Location Name',
        'type': 'string',
        'sortable': true,
        'action': function(location) {
          self.send('view', location);
        },
        'actionId': 'location_name_view_link',
        'filter': {
          type: 'text'
        }
      },
      account_number: {
        'name': 'account_number',
        'title': 'Account Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      address1: {
        'name': 'address1',
        'title': 'Address',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      city: {
        'name': 'city',
        'title': 'City',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      state: {
        'name': 'state',
        'title': 'State',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      zip_code: {
        'name': 'zip_code',
        'title': 'Zip Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      office_phone: {
        'name': 'office_phone',
        'title': 'Office Phone',
        'type': 'phone',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      office_ext_phone: {
        'name': 'office_ext_phone',
        'title': 'Office Ext',
        'type': 'phone',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      viewTree: {
        'name': 'id',
        'title': '',
        'action': function(location) {
          self.send('viewTree', location);
        },
        'actionId': 'location_viewtree_link',
        'type': 'tree',
        'isActionColumn': true
      }
    };

    var listOfCols = ['name', 'account_number', 'address1', 'city', 'state', 'zip_code', 'office_phone', 'office_ext_phone', 'created_ts', 'viewTree'];
    var listOfMobileCols = ['name', 'account_number', 'created_ts', 'viewTree'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);

      return cols;
    }

    function removeCols(context, colsArray){
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-locations-get-tree')) {
        colsArray.removeObject(columns.viewTree);
      }
    }
  }),
  actions: {
    view: function(locations) {
      this.transitionToRoute('locations.view', locations.get('id'));
    },
    viewTree: function(location) {
      var self = this;
      var session = this.get('session');
      var dynamicController = this.get('dynamicController');

      // Set initial content
      dynamicController.set('title', 'Location Tree');
      dynamicController.set('className', 'sixinch');
      dynamicController.set('content', '<pre style="font-size: .85em;"><i class="fas fa-cog fa-spin fa-fw"></i> Please wait, loading data...</pre>');
      self.send('openModal', 'components/modals/dynamic');

      function getChildren(children, level) {
        var l = '';
        for (var i = 0; i < children.length; i++) {
          l += '<div><a href="#/locations/view/' + children[i].id + '">' + repeatStringNumTimes('&nbsp;', level) + '&gt; ' + children[i].name + '</a></div>';
          if (children[i].children.length > 0) {
            l += getChildren(children[i].children, level + 1);
          }
        }
        return l;
      }
      function repeatStringNumTimes(string, times) {
        if(times <= 0) {
          return "";
        } else if(times === 1) {
          return string;
        } else {
          return string + repeatStringNumTimes(string, times - 1);
        }
      }

      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/locations/' + location.get('id') + '/tree?fields=id,name',
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function(data) {
        var display = '<div>' + data.location.name + '</div>';
        if (data.location.children.length > 0) {
          display += getChildren(data.location.children, 1);
        }
        dynamicController.set('content', '<pre style="font-size: .85em;">' + display + '</pre>');
      });
    }
  }
});
