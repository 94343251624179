import Ember from 'ember';
import jQuery from 'jquery';

const get = Ember.get;

export default Ember.Component.extend({
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  didRender: function() {
    const idNo = this.get('idNo');
    const idYes = this.get('idYes');
    const value = this.get('value');
    const isEditing = this.get('isEditing');
    const isEnabled = this.get('isEnabled');
    let disabled = false;
    let checkedYes = false;
    let checkedNo = true;
    if (!isEditing) {
      disabled = true;
    }
    if (!isEnabled) {
      disabled = true;
    }
    if (value) {
      checkedYes = true;
      checkedNo = false;
    }
    jQuery(`#${idYes}`).prop('disabled', disabled);
    jQuery(`#${idNo}`).prop('disabled', disabled);
    jQuery(`#${idYes}`).prop('checked', checkedYes);
    jQuery(`#${idNo}`).prop('checked', checkedNo);
  },
});
