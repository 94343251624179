import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  resetController: function(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.set('force_account', null);
    }
  },

  model: function() {
    var store = this.get('store');
    var session = this.get('session');
    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: session.get('authenticated.user.contact_id'),
          page_size: '500'
        });
      } else {
        return [];
      }
    };
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
      accounts: getAccounts()
    });
  },
  afterModel: function(models) {
    var self = this;
    return Ember.RSVP.hash({
      locationinfos: self.get('store').query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('accounts', models.accounts.toArray()); // using .toArray() to get mutable array in case where new account is created successfully, but POST recurring returns 422
    controller.set('locationinfo', location);
    controller.set('locationinfos', models.locationinfos);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Scheduled Payment',
      route_name: 'contactuser.recurrings-add'
    });
    controller.set('pageTitle', 'Add Scheduled Payment');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Scheduled Payments',
      link: 'contactuser.recurrings-index'
    }, {
      title: 'Add Scheduled Payment',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
