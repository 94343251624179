import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  normalize: function(type, hash, property) {

    hash.id = Math.random().toString(36).slice(2) + '-' + Math.random().toString(36).slice(2);

    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  },
});
