import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  crudFormFields: Ember.inject.service('crud-form-fields'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/helppages/view', {
      controller: 'admin/helppages/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      helppage: store.createRecord('helppage', {})
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('helppage', models.helppage);
    controller.set('crudParams', {
      formType: 'add',
      formModelName: 'Help Page',
      formFields: this.get('crudFormFields').getForModel('helppage'),
      buttonPermissions: {
        overall: 'v2-helppages-get',
        back: 'v2-helppages-get',
        cancel: 'v2-helppages-get',
        delete: 'v2-helppages-delete',
        edit: 'v2-helppages-put',
        save: 'v2-helppages-post'
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Help Page',
      route_name: 'admin.helppages.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Help Page');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Help Pages',
      link: 'admin.helppages.index'
    }, {
      title: 'Add Help Page',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel;
      if (model.helppage.get('hasDirtyAttributes')) {
        model.helppage.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
