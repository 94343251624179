import DS from 'ember-data';
import Ember from 'ember';

DS.Model.reopen({
  clone: function(overrides) {
    var model = this,
      attrs = model.toJSON(),
      class_type = model.constructor.modelName;

    var root = Ember.String.decamelize(class_type);

    /*
     * Need to replace the belongsTo association ( id ) with the
     * actual model instance.
     *
     * For example if belongsTo association is project, the
     * json value for project will be:  ( project: "project_id_1" )
     * and this needs to be converted to ( project: [projectInstance] )
     */
    this.eachRelationship(function(key, relationship) {
      if (relationship.kind === 'belongsTo') {
        attrs[key] = model.get(key);
      }
      // Remove the relationship data
      delete attrs[key];
    });

    /*
     * Need to dissociate the new record from the old.
     */
    delete attrs.id;

    /*
     * Apply overrides if provided.
     */
    if (Ember.typeOf(overrides) === 'object') {
      Ember.setProperties(attrs, overrides);
    }

    return this.get('store').createRecord(root, attrs);
  }
});

export default DS.Model.extend({
  // Default method to set validation errors on attributes
  adapterDidInvalidate: function(errors) {
    var recordErrors = this.get('errors');
    var results = [];
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        var errorValue = errors[key];
        results.push(recordErrors.add(key, errorValue));
      }
    }
    return results;
  },
  save: function(options, validations) {
    var self = this;

    return new Ember.RSVP.Promise(function(resolve, reject) {
      if (self.validateModel(validations)) {
        // on success
        resolve(self._super(options));
      } else {
        // on failure
        reject(false);
      }
    });
    //
  },
  validateModel: function() {
    return true;
  }
});