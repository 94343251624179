import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('portfolio.errors.messages', function() {
    this.set('errorMessages', this.get('portfolio.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,
  parents: [],

  // Actions
  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    updateParents: function(data) {
      var self = this;
      var parents = this.get('store').query('portfolio', {
        title: data,
        sort: 'title',
        page_size: '10'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var portfolio = this.get('portfolio');

      portfolio.save().then(function() {
        noty({
          text: 'Portfolio added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.portfolios.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.portfolios.index');
    }
  }
});
