import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('featureflags.errors.messages', function() {
    this.set('errorMessages', this.get('featureflags.errors.messages'));
  }),

  // Events
  didInsertElement: function() {
    this.set('loggedInUserCanEditViewedUser', this.get('usersMethods').loggedInUserCanEditViewedUser(this.get('user')));
    this.set('changesToMake', []);
    this.set('isEditing', false);
  },

  // Computed Properties
  canEdit: Ember.computed('loggedInUserCanEditViewedUser', 'session.authenticated.resources.v2-featureflagusers-post', 'session.authenticated.resources.v2-featureflagusers-delete', function() {
    return this.get('loggedInUserCanEditViewedUser') && (this.get('session.authenticated.resources.v2-featureflagusers-post') || this.get('session.authenticated.resources.v2-featureflagusers-delete'));
  }),
  selectedFeatureFlags: Ember.computed('feature_flags.[]', function() {
    var feature_flags = this.get('feature_flags');
    var tempArray = [];
    if (feature_flags.get('length')) {
      feature_flags.forEach(function(flag) {
        tempArray.push(flag.id);
      });
    }
    return tempArray;
  }),

  // Actions
  actions: {
    cancel: function() {
      var changesToMake = this.get('changesToMake');
      changesToMake.forEach(function(change){
        jQuery('#users_featureflags_chk-' + change.feature_flag_id).trigger('click');
      });
      this.set('changesToMake', []);
      this.set('isEditing', false);
    },
    changeFeatureFlags: function(data) {
      if (this.get('isLoading')) {
        return;
      }
      var hasFeatureFlag = this.get('selectedFeatureFlags').indexOf(data.id) !== -1;
      var changesToMake = this.get('changesToMake') || [];
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.feature_flag_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          feature_flag_id: data.id,
          title: data.title,
          type: hasFeatureFlag ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    checkAllFeatureFlags: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (!jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var session = this.get('session');
      var changesToMake = this.get('changesToMake');
      var feature_flags = this.get('feature_flags');
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function(change, index) {
        if(change.type === 'POST') {
          canPostRoles.push(change);
          var featureflaguser = self.get('store').createRecord('featureflaguser', {
            feature_flag_id: change.feature_flag_id,
            user_criteria: {
              developer_id: [],
              location_id: [],
              user_type_id: [],
              relationship: 'direct',
              user_id: [self.get('user.id')]
            },
            user_id: null
          });
          return featureflaguser.save().then(function(flag) {
            feature_flags.addObject({id:flag.get('feature_flag_id')}); // Push the object we just created back onto the list of file categories object
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
              sendNoty();
            }
          }, function() {
            // error
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
            }
            noty({
              text: 'Error adding Feature Flag ' + change.title,
              type: 'error',
              timeout: 10000
            });
          });
        } else if(change.type === 'DELETE') {
          canDeleteRoles.push(change);
          return feature_flags.forEach(function(flag) {
            if (flag.id.indexOf(change.feature_flag_id) !== -1) {
              jQuery.ajax({
                url: window.UiENV.APP.FULL_URL + '/featureflagusers?feature_flag_id=' + change.feature_flag_id,
                type: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  'developer-id': window.UiENV.APP.DEVELOPER_ID,
                  'access-token': session.get('authenticated.token')
                }
              }).then(function() {
                feature_flags.removeObject(flag); // Remove the oject from the parent if it was successfully deleted
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                  sendNoty();
                }
              }, function() {
                //error
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                }
                noty({
                  text: 'Error removing Feature Flag ' + change.title,
                  type: 'error',
                  timeout: 10000
                });
              });
            }
          });
        }
      });

      self.set('isLoading', true);

      if(!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(function() {
          //
        }, function() {
          noty({
            text: 'Error applying changes!',
            type: 'error'
          });
          doneMakingChanges(self);
        });
      }
      function sendNoty() {
        if((canPostRoles && canPostRoles.length)) {
          var successMessagePost = '';
          canPostRoles.forEach(function(role) {
            successMessagePost += role.title;
            successMessagePost += '<br>';
          });
          noty({
            text: 'The following Feature Flags were successfully added:<br>' + successMessagePost,
            timeout: 10000
          });
        }
        if((canDeleteRoles && canDeleteRoles.length)) {
          var successMessageDelete = '';
          canDeleteRoles.forEach(function(role){
            successMessageDelete += role.title;
            successMessageDelete += '<br>';
          });
          noty({
            text: 'The following Feature Flags were successfully removed:<br>' + successMessageDelete,
            timeout: 10000
          });
        }
      }
      function doneMakingChanges (context) {
        context.set('isLoading', false);
        context.set('isEditing', false);
        context.set('changesToMake', []);
      }
    },
    uncheckAllFeatureFlags: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
  }

});
