import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  processing_status: [{
      'id': '1',
      'title': 'Open'
    },
    {
      'id': '2',
      'title': 'Closed'
    },
    {
      'id': '3',
      'title': 'Error'
    },
    {
      'id': '4',
      'title': 'Unknown'
    },
    {
      'id': '5',
      'title': 'Processing'
    },
    {
      'id': '6',
      'title': 'Force Closed'
    },
  ],
  query: function(store, type, query) {
    var self = this;

    return new Ember.RSVP.Promise(function(resolve, reject) {
      var p_statuses = self.get('processing_status');
      var p_status = p_statuses.filter(function(item) {
        return parseInt(item.id) === parseInt(query.id);
      });
      if (p_status.length > 0) {
        resolve({
          processingstatus: p_status
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findAll: function() {
    return {
      processingstatus: this.get('processing_status')
    };
  }
});