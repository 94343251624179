import Ember from 'ember';

export default Ember.Helper.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  compute(params) {
    var account = params[0];
    var contactName = params[1];
    var compactview = params[2];
    var html = '';
    if(account && (account.get('id') || account.get('isNew'))) {
      var isCC = account.get('isCC');
      var account_holder_name = account.get('account_holder_name');
      var account_number = account.get('account_number');
      var title = account.get('title');
      if(account.get('isNew')) {
        if(isCC) {
          html += '<strong>Credit Card</strong>';
        } else {
          html += '<strong>ACH/EFT</strong>';
        }
      } else {
        html += '<img class="' + account.get('account_type') + ' account-sm hidden-print" style="display:inline-block;">';
      }
      html += '<strong>&nbsp; (' + (account.get('last_four') || (account_number ? account_number.slice(-4) : 'xxxx')) + ')';
      if(account.get('isACH')) {
        html += ' ● ' + account.get('account_type');
      }
      if(isCC && account.get('exp_date')) {
        html += ' ● ' + account.get('exp_date') + '<span class="fa-stack fa-stack-mobile hidden-print">';
        if(account.get('expiring_in_months')) {
          html += '<i class="fas fa-circle fa-stack-1x ' + account.get('expColor') + '"></i></span>';
        } else {
          html += '</span>';
        }
      }
      html += '</strong>';
      html += '<br>';
      if(!compactview) {
        html += 'Title: ' + (title || 'N/A');
        html += '<br>';
      }
      html += 'Account Holder: ' + (account_holder_name || 'N/A');
      html += '<br>';
      if(account.get('contact_id') && !this.get('session.authenticated.isContact') && contactName && !compactview) {
        html += 'Contact: ' + contactName;
      }
    }

    return new Ember.String.htmlSafe(html);
  }
});
