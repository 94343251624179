import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('notificationtemplate.errors.messages', function() {
    this.set('errorMessages', this.get('notificationtemplate.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var notificationtemplate = this.get('notificationtemplate');
      if (notificationtemplate.get('location_id') === null) {
        pretty_confirm_ok_only('Base Notification Templates cannot be deleted.', function() {});
        return;
      }

      pretty_confirm('Are you sure want to delete this Notification Template?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        notificationtemplate.deleteRecord();
        notificationtemplate.save().then(function() {
          notificationtemplate.unloadRecord();
          noty({
            text: 'Notification Template deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.notificationtemplates.index');
        }, function() {
          notificationtemplate.rollbackAttributes();
          noty({
            text: 'Error deleting Notification Template!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var notificationtemplate = this.get('notificationtemplate');
      notificationtemplate.save().then(function() {
        noty({
          text: 'Notifcation template updated successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.notificationtemplates.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      this.transitionToRoute('admin.notificationtemplates.index');
    }
  }
});
