import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,

  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  actions: {
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      this.set('errorMessages', null);

      var contact = this.get('contact');
      if (this.get('locations.length') === 1 && !this.get('contact.location_id')) {
        contact.set('location_id', this.get('locations').get('firstObject').id);
      }
      var self = this;
      var data = contact.serialize();
      new Ember.RSVP.Promise(function() {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/registration',
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            'contact': data
          })
        }).then(function() {
          noty({
            text: 'Registration was successful!<br>An email has been sent with instructions to complete the process.',
            timeout: '6000'
          });
          self.set('isLoading', false);
          self.transitionToRoute('login');
        }, function(error) {
          var errors = '';
          try{
            var errRes = JSON.parse(error.responseText);
            if(errRes && errRes.error){
              errors = errRes.error
            }else{
              console.error('Invalid JSON structure: Missing "errors" property.');
            }
          }catch (err){
            console.error('Error parsing JSON:', err);
          }
          self.set('errorMessages', errors);
          noty({
            text: 'Signup Failed!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
