import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  loggedInUserCanEditViewedUser: function(user) {
    if(!user){
      return false;
    }
    var user_type_id = user.get('user_type_id');
    var logged_in_user_type_id = this.get('session.authenticated.user_type_id');
    return Number(logged_in_user_type_id) >= Number(user_type_id);
  }

});
