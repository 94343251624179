import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (!value) {
      return null;
    } else {
      return value;
    }
  },
  deserialize: function(value) {
    return value;
  }
});