import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        page_size: '500',
        relationship: 'direct',
        product_transaction_active: '0,1'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'quickinvoice.index',
      pageModelId: null,
      modelName: 'quickinvoice',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,contact,tags,status,all_tags,location,cc_product_transaction,ach_product_transaction,files',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);

    controller.set('locations', models.locations);

    if (!controller.get('active') && controller.get('active') !== 0) {
      controller.set('active', '0,1');
    }

    // Set: Page Title - Crumbs - History
    // ** No History entry since route is already in main nav menu
    controller.set('pageTitle', 'Quick Invoices');
    controller.set('pageSubtitle', {
      title: 'Add Quick Invoice',
      link: 'quickinvoice.add',
      resource: this.get('session.authenticated.resources.v2-quickinvoices-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Quick Invoices',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
