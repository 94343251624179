import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  getForModel: function(model) {
    return this.get(model);
  },

  accountvault: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Account<br>Vault Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('accounts.view', model.get('account_vault_id'));
      },
      'actionpriv': 'v2-accountvaults-get',
      'filter': {
        type: 'text',
        field: 'account_vault_id'
      }
    }, {
      'name': 'title',
      'title': 'Account<br>Vault Title',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('accounts.view', model.get('account_vault_id'));
      },
      'actionpriv': 'v2-accountvaults-get',
    }];
  }),

  contact: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Contact Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('contacts.dashboard', model.get('contact.id'));
      },
      'actionpriv': 'v2-contacts-get',
      'filter': {
        type: 'text',
        field: 'contact_id'
      }
    }, {
      'name': 'first_name',
      'title': 'Contact<br>First Name',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        router.transitionTo('contacts.dashboard', model.get('contact.id'));
      },
      'actionpriv': 'v2-contacts-get',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'last_name',
      'title': 'Contact<br>Last Name',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        router.transitionTo('contacts.dashboard', model.get('contact.id'));
      },
      'actionpriv': 'v2-contacts-get',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'account_number',
      'title': 'Contact<br>Account Number',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'email',
      'title': 'Contact<br>Email',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'company_name',
      'title': 'Contact<br>Company',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'address',
      'title': 'Contact<br>Address',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'city',
      'title': 'Contact<br>City',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'home_phone',
      'title': 'Contact<br>Home Phone',
      'type': 'phone',
      'sortable': true
    }, {
      'name': 'cell_phone',
      'title': 'Contact<br>Cell Phone',
      'type': 'phone',
      'sortable': true
    }, {
      'name': 'office_phone',
      'title': 'Contact<br>Office Phone',
      'type': 'phone',
      'sortable': true
    }, {
      'name': 'location_id',
      'title': 'Contact<br>Location',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'locationinfos'
      }
    }];
  }),

  hostedpaymentpage: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Hosted Payment<br>Page Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('locations.hpp-view', model.get('location.id'), model.get('hosted_payment_page_id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-hostedpaymentpages-get']
    }, {
      'name': 'title',
      'title': 'Hosted Payment<br>Page Title',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        router.transitionTo('locations.hpp-view', model.get('location.id'), model.get('hosted_payment_page_id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-hostedpaymentpages-get']
    }];
  }),

  location: Ember.computed(function() {
    var router = this.get('router');
    var session = this.get('session');
    return [{
      'name': 'id',
      'title': 'Location Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('locations.view', model.get('location_id'));
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      'filter': {
        type: 'text',
        field: 'location_id'
      }
    }, {
      'name': 'name',
      'title': 'Location Name',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        router.transitionTo('locations.view', model.get('location_id'));
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      'filter': {
        type: 'dropdown',
        items: 'locations',
        field: 'location_id'
      }
    }, {
      'name': 'account_number',
      'title': 'Location<br>Account Number',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'address1',
      'title': 'Location<br>Address',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'city',
      'title': 'Location<br>City',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'state',
      'title': 'Location<br>State',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'zip_code',
      'title': 'Location<br>Zip Code',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'office_phone',
      'title': 'Location<br>Office Phone',
      'type': 'phone',
      'sortable': true
    }, {
      'name': 'office_ext_phone',
      'title': 'Location<br>Office Ext',
      'type': 'phone',
      'sortable': true
    }, {
      'name': 'created_ts',
      'title': 'Location<br>Created Date',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }];
  }),

  producttransaction: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Deposit<br>Account Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('locations.viewproducttransaction', model.get('location.id'), model.get('product_transaction_id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
      'filter': {
        type: 'text',
        field: 'product_transaction_id'
      }
    }, {
      'name': 'title',
      'title': 'Deposit<br>Account Name',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        router.transitionTo('locations.viewproducttransaction', model.get('location.id'), model.get('product_transaction_id'));
      },
      'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
      'filter': {
        type: 'dropdown',
        items: 'product_transactions',
        multi: true,
        field: 'product_transaction_id',
        notuniqbytitle: true
      }
    }];
  }),

  quickinvoice: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Quick<br>Invoice Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('quickinvoice.view', model.get('quick_invoice_id'));
      },
      'actionpriv': 'v2-quickinvoices-get',
      'filter': {
        type: 'text',
        field: 'quick_invoice_id'
      }
    }, {
      'name': 'title',
      'title': 'Quick<br>Invoice Title',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('quickinvoice.view', model.get('quick_invoice_id'));
      },
      'actionpriv': 'v2-quickinvoices-get',
    }];
  }),

  reason_code: Ember.computed(function() {
    return [ {
      'name': 'id',
      'title': 'Reason<br>Code Id',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text',
        field: 'reason_code_id'
      }
    }, {
      'name': 'title',
      'title': 'Reason<br>Code Title',
      'type': 'string',
      'filter': {
        type: 'dropdown',
        items: 'trans_reasoncodes',
        field: 'reason_code_id'
      }
    }];
  }),
  recurring: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'id',
      'title': 'Recurring<br>Billing Id',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('recurrings.view', model.get('recurring_id'));
      },
      'actionpriv': 'v2-recurrings-get',
      'filter': {
        type: 'text',
        field: 'recurring_id'
      }
    }, {
      'name': 'description',
      'title': 'Recurring<br>Billing Description',
      'type': 'string',
      'action': function(model) {
        router.transitionTo('recurrings.view', model.get('recurring_id'));
      },
      'actionpriv': 'v2-recurrings-get',
    }];
  }),

  status: [{
    'name': 'id',
    'title': 'Status Id',
    'type': 'string',
    'filter': {
      type: 'text',
      field: 'status_id'
    }
  }, {
    'name': 'title',
    'title': 'Status Title',
    'type': 'string',
    'sortable': true,
    'filter': {
      type: 'dropdown',
      items: 'statuses',
      field: 'status_id'
    }
  }],

  surcharge: [{
    'name': 'surcharge.surcharge_amount',
    'title': 'Surcharge<br>Amount',
    'type': 'currency',
    'sortable': true,
    'align': 'text-right nobr'
  }],

  tag: [{
    'name': 'tags',
    'title': 'Tags',
    'type': 'array',
    'sortable': false,
    'filter': {
      type: 'dropdown',
      items: 'tagList',
      labelKey: 'title',
      multi: true
    }
  }],

  terminal: Ember.computed(function() {
    var router = this.get('router');
    var session = this.get('session');
    return [{
      'name': 'title',
      'title': 'Terminal Name',
      'type': 'string',
      'sortable': true,
      'action': function(model) {
        if(session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) {
          router.transitionTo('admin.terminals.view', model.get('terminal_id'));
        } else {
          router.transitionTo('locations.terminals-view', model.get('location_id'), model.get('terminal_id'));
        }
      },
      'actionpriv': (session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) ? 'v2admin-terminals-get' : ['v2-locations-get', 'v2-terminals-get'],
      'filter': {
        type: 'dropdown',
        items: 'terminals',
        multi: true,
        field: 'terminal_id'
      }
    }, {
      'name': 'id',
      'title': 'Terminal Id',
      'type': 'string',
      'action': function(model) {
        if(session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) {
          router.transitionTo('admin.terminals.view', model.get('terminal_id'));
        } else {
          router.transitionTo('locations.terminals-view', model.get('location_id'), model.get('terminal_id'));
        }
      },
      'actionpriv': (session.get('authenticated.isSupport') && session.get('v2admin-terminals-get')) ? 'v2admin-terminals-get' : ['v2-locations-get', 'v2-terminals-get'],
      'filter': {
        type: 'text',
        field: 'terminal_id'
      }
    }, {
      'name': 'username',
      'title': 'Username',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'password',
      'title': 'Password',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'serial_number',
      'title': 'Terminal<br>Serial #',
      'type': 'string',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'mac_address',
      'title': 'MAC Address',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'terminal_number',
      'title': 'Terminal #',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'local_ip_address',
      'title': 'Local IP',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'last_registration_ts',
      'title': 'Last Registration',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast'
      }
    }, {
      'name': 'active',
      'title': 'Active',
      'type': 'check',
      'align': 'text-center',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny'
      }
    }];
  }),

  transaction: Ember.computed(function() {
    var router = this.get('router');
    return [{
      'name': 'account_holder_name',
      'title': 'Account<br>Holder',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'account_type',
      'title': 'Account<br>Type',
      'type': 'image',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'accountTypes'
      }
    }, {
      'name': 'customer_ip',
      'title': 'Customer IP',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text',
      },
    }, {
      'name': 'ach_sec_code',
      'title': 'SEC<br>Code',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'all_ach_sec_codes'
      },
      'showACHSECCodeInfo': true
    }, {
      'name': 'auth_amount',
      'title': 'Auth<br>Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'auth_code',
      'title': 'Auth<br>Code',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'batch',
      'title': 'Batch',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'billing_city',
      'title': 'Billing<br>City',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'billing_phone',
      'title': 'Billing<br>Phone',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'billing_state',
      'title': 'Billing<br>State',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'billing_street',
      'title': 'Billing<br>Street',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'billing_zip',
      'title': 'Billing<br>Zip',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'charge_back_date',
      'title': 'Chargeback<br>Date',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersTransaction',
        multi: false
      }
    }, {
      'name': 'clerk_number',
      'title': 'Clerk<br>Number',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'created_ts',
      'title': 'Transaction<br>Date',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        style: 'min-width: 200px',
        items: 'dateFiltersTransaction',
        multi: false,
        showTimeFilter: true
      }
    }, {
      'name': 'custom_data',
      'title': 'Custom Data',
      'type': 'custom_data'
    }, {
      'name': 'description',
      'title': 'Description',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'effective_date',
      'title': 'Effective<br>Date',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersTransaction',
        multi: false
      }
    }, {
      'name': 'entry_mode_id',
      'title': 'Entry Mode',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'EntryModeItems',
        field: 'entry_mode_id',
        multi: true
      }
    }, {
      'name': 'first_six',
      'title': 'First Six',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'id',
      'title': 'Transaction<br>Id',
      'type': 'string',
      'filter': {
        type: 'text',
        field: 'id'
      }
    }, {
      'name': 'is_accountvault',
      'title': 'Account<br>Vault',
      'type': 'shield',
      'sortable': true,
      'align': 'text-center',
      'action': function(model) {
        router.transitionTo('accounts.view', model.get('account_vault_id'));
      },
      'actionpriv': 'v2-accountvaults-get',
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny',
        multi: false
      }
    }, {
      'name': 'is_recurring',
      'title': 'Recurring<br>Billing',
      'type': 'redo',
      'sortable': true,
      'align': 'text-center',
      'action': function(transaction) {
        router.transitionTo('recurrings.view', transaction.get('recurring_id'));
      },
      'actionpriv': 'v2-recurrings-get',
      'filter': {
        type: 'dropdown',
        items: 'yesNoAny',
        multi: false
      }
    }, {
      'name': 'last_four',
      'title': 'Last<br>Four',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'order_num',
      'title': 'Order<br>Number',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'payment_method',
      'title': 'Payment<br>Method',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'paymentMethods'
      }
    }, {
      'name': 'room_num',
      'title': 'Room<br>Number',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'room_rate',
      'title': 'Room<br>Rate',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right nobr',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'settle_date',
      'title': 'Settle<br>Date',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersTransaction',
        multi: false
      }
    }, {
      'name': 'tax',
      'title': 'Sales<br>Tax',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right nobr',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'tip_amount',
      'title': 'Tip<br>Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right nobr',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_amount',
      'title': 'Transaction<br>Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right nobr',
      'filter': {
        type: 'text'
      },
      'total': true,
      'totalValue': Ember.Object.extend({
        data: null,
        value: Ember.computed('gridData', function() {
          return '$ ' + String(this.get('gridData.meta.report.pageTotalTransactionAmount')).currency(2) + ' of<br>$ ' + String(this.get('gridData.meta.report.totalTransactionAmount')).currency(2) + ' Total';
        })
      }).create()
    }, {
      'name': 'transaction_api_id',
      'title': 'Transaction<br>API ID',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_batch_id',
      'title': 'Transaction<br>Batch ID',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_c1',
      'title': 'Custom Field 1',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_c2',
      'title': 'Custom Field 2',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'transaction_c3',
      'title': 'Custom Field 3',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'trx_source_id',
      'title': 'Payment<br>Source',
      'type': 'string',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'trans_sources',
        multi: true,
        showAllSelect: true
      }
    }, {
      'name': 'transaction_level3.id',
      'title': 'Level 3<br>Indicator',
      'type': 'indicator',
      'sortable': true,
      'align': 'text-center',
      'filter': {
        field: 'transaction_level3_id',
      }
    }];
  }),

  type: [{
    'name': 'id',
    'title': 'Type Id',
    'type': 'string',
    'filter': {
      type: 'text',
      field: 'type_id'
    }
  }, {
    'name': 'title',
    'title': 'Type Title',
    'type': 'string',
    'sortable': true,
    'filter': {
      type: 'dropdown',
      items: 'types',
      field: 'type_id'
    }
  }],

  user: Ember.computed(function() {
    var router = this.get('router');
    var session = this.get('session');
    return [{
      'name': 'id',
      'title': 'Created By<br>User Id',
      'type': 'string',
      'action': function(model) {
        if(session.get('authenticated.isSupport')) {
          router.transitionTo('admin.users.view', model.get('created_user.id'));
        } else {
          router.transitionTo('locations.users-view', model.get('location.id'), model.get('created_user.id'));
        }
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-users-get' : ['v2-locations-get', 'v2-users-get'],
      'filter': {
        type: 'text',
        field: 'created_user_id'
      }
    }, {
      'name': 'username',
      'title': 'Created By<br>Username',
      'type': 'string',
      'sortable': false,
      'action': function(model) {
        if(session.get('authenticated.isSupport')) {
          router.transitionTo('admin.users.view', model.get('created_user.id'));
        } else {
          router.transitionTo('locations.users-view', model.get('location.id'), model.get('created_user.id'));
        }
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-users-get' : ['v2-locations-get', 'v2-users-get'],
      'filter': {
        type: 'dropdown',
        items: 'users',
        field: 'created_user_id'
      }
    }];
  }),


});
