import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'admin.postbackconfigs.index',
      modelName: 'postbackconfig'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));
    
    var gridParams = {
      expand: 'alerttype'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Postback Configs',
      route_name: 'admin.postbackconfigs.index'
    });
    controller.set('pageTitle', 'Postback Configs');
    controller.set('pageSubtitle', {
      title: 'Add Postback Config',
      link: 'admin.postbackconfigs.add',
      resource: this.get('session.authenticated.resources.v2-postbackconfigs-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Postback Configs',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
