import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      postback_statuses: store.findAll('postbackstatus')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('postback_statuses', models.postback_statuses);
    var pagerParams = {
      pageRoute: 'admin.postbacklogs.index',
      pageModelId: null,
      modelName: 'postbacklog'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      relationship: 'all'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Postback Logs',
      route_name: 'admin.postbacklogs.index'
    });
    controller.set('pageTitle', 'Postback Logs');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Postback Logs',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
