import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      userTypes: store.findAll('usertype'),
      resources: store.query('resource', {
        page_size: 1000,
        sort: 'resource_name'
      }),
      authroles: store.query('admin_authrole', {
        page_size: 500,
        sort: 'title',
        expand: 'auth_role_resources'
      }),
      authenticatedresources: store.query('admin_authroleresource', {
        auth_role_id: '100',
        page_size: 2000
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    controller.set('userTypes', models.userTypes);
    controller.set('authroles', models.authroles);
    controller.set('resources', models.resources);
    controller.set('changesToMake', []);
    controller.set('numAuthRoles', 0);

    var store = this.get('store');
    var selectedRoles = transition.to.queryParams.selectedRoles;
    if(transition.to.queryParams.editThisRole) {
      selectedRoles = [transition.to.queryParams.editThisRole];
    } else if (selectedRoles) {
      selectedRoles = selectedRoles.split(',');
    } else {
      selectedRoles = ['100'];
    }
    if (selectedRoles.indexOf('100') === -1) {
      selectedRoles.unshift('100');
    }
    controller.set('selectedRoles', selectedRoles.join());

    var rolesArray = selectedRoles.map(function(role) {
      return store.query('admin_authroleresource', {
        auth_role_id: role,
        page_size: 2000
      });
    });
    controller.set('numAuthRoles', rolesArray.length);
    Ember.RSVP.Promise.all(rolesArray).then(function(result) {
      if (result) {
        var selectedAuthRoles = [];
        result.map(function(res) {
          var selectedResources = [];
          res.map(function(r) {
            selectedResources.push({
              id: r.get('id'),
              resource_id: r.get('resource_id')
            });
          });
          selectedAuthRoles.pushObject({
            id: res.query.auth_role_id,
            title: '',
            user_type_id: '',
            selectedResources: selectedResources
          });
          controller.set('numAuthRoles', controller.get('numAuthRoles') - 1);
        });
        selectedAuthRoles = selectedAuthRoles.map(function(authrole) {
          models.authroles.forEach(function(ar) {
            if (ar.get('id') === authrole.id) {
              authrole.title = ar.get('title');
              authrole.user_type_id = ar.get('user_type_id');
            }
          });
          return authrole;
        });
        controller.set('selectedAuthRoles', selectedAuthRoles);
      }
      controller.checkSideNav();
    }, function() {
      noty({
        text: 'Error getting Auth Role resources!',
        type: 'error'
      });
      controller.set('selectedAuthRoles', []);
    });

    if(transition.to.queryParams.editThisRole === '100') {
      controller.set('isEditingAuthenticated', true);
      noty({
        text: 'You are making changes to the Authenticated Role.<br><br>This will affect all users of the system.',
        type: 'warning',
        timeout: 10000
      });
    }


    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Roles Grid',
      route_name: 'admin.roles.grid'
    });
    controller.set('pageTitle', 'Roles Grid');
    controller.set('pageSubtitle', null);
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Roles',
      link: 'admin.roles.index'
    }, {
      title: 'Roles Grid',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var controller = this.controller;
      if (controller.get('changesToMake.length') > 0 && this.get('session.isAuthenticated')) {
        transition.abort();
        pretty_confirm('You have unsaved information.<br><br> Are you sure you want to leave this page?', function() {
          controller.set('changesToMake', []);
          transition.retry();
        });
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
