import Ember from 'ember';

export default Ember.Helper.extend({

  compute(params) {
    var code = params[0];
    if (!code) {
      return;
    }
    return code.toUpperCase();
  }

});
