import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    const words = params[0].split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  }
});
