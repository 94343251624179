import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsChangeRecurringDepositAccount: true,

  // Observers

  changedOldProductTransaction: Ember.observer('old_default_product_transaction_id', function() {
    var old_default_product_transaction_id = this.get('old_default_product_transaction_id');
    if (!old_default_product_transaction_id) {
      return;
    }
    var old_products = this.get('non_lodging_cc_products');
    if (!old_products) {
      return;
    }
    var new_cc_products = old_products.filter(function(p) {
      return p.get('id') !== old_default_product_transaction_id;
    });
    this.set('new_cc_products', new_cc_products);
    this.set('new_default_product_transaction_id', null);
  }),

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('locations.view', this.get('location.id'));
    },
    saveChangeProductTransaction: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      var changerecurringdepositaccountErrors = {};
      var changerecurringdepositaccountErrorsMessages = [];
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/recurrings/changeproducttransactions',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': self.get('session.authenticated.token')
        },
        data: JSON.stringify({
          'changeproducttransactions': {
            'location_id': location.get('id'),
            'old_default_product_transaction_id': this.get('old_default_product_transaction_id'),
            'new_default_product_transaction_id': this.get('new_default_product_transaction_id')
          }
        })
      }).then(function() {
        self.set('isLoading', false);
        self.set('old_default_product_transaction_id', null);
        self.set('new_default_product_transaction_id', null);
        noty({
          text: 'Change Deposit Account successful!',
          type: 'success'
        });
      }, function(errors) {
        if (errors && errors.responseJSON && errors.responseJSON.errors) {
          Object.keys(errors.responseJSON.errors).forEach(function(key) {
            changerecurringdepositaccountErrors[key] = true;
            changerecurringdepositaccountErrorsMessages.push(errors.responseJSON.errors[key]);
          });
        }
        self.set('changerecurringdepositaccountErrors', changerecurringdepositaccountErrors);
        self.set('changerecurringdepositaccountErrorsMessages', changerecurringdepositaccountErrorsMessages);
        self.set('isLoading', false);
        noty({
          text: 'Change Deposit Account failed!',
          type: 'error'
        });
      });
    },

  }
});
