import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  postbacklogController: Ember.inject.controller('components/modals/postbacklog'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminPostbacklogs: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'http_verb',
    'id',
    'model',
    'model_id',
    'next_run_ts',
    'next_run_ts_from',
    'next_run_ts_to',
    'postback_config_id',
    'postback_status_id',
  ],

  // Actions
  actions: {
    resendAll: function() {
      var self = this;
      if (self.get('isResending')) {
        return;
      }
      self.set('isResending', true);
      var session = this.get('session');

      var postbacks = this.get('gridData');
      var post_length = postbacks.get('length');

      postbacks.forEach(function(postback) {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/postbacklogs/' + postback.get('id') + '/retry',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          post_length--;
          if (post_length === 0) {
            self.set('isResending', false);
            noty({
              text: 'Done sending postbacks!'
            });
            self.send('gridNeedsRefresh', self);
          }
          var result = data.postbacklog.postback_status_id;
          if (result === 3) {
            postback.set('postback_status_id', 3);
          }
        }, function() {
          post_length--;
          if (post_length === 0) {
            self.set('isResending', false);
            noty({
              text: 'Done sending postbacks!'
            });
            self.send('gridNeedsRefresh', self);
          }
        });
      });
    }
  }
});
