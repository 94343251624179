import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),
  crudFormFields: Ember.inject.service('crud-form-fields'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminalcvm: store.queryRecord('terminalcvm', {
        id: params.id,
        expand: 'terminal_manufacturer',
      }),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var terminalcvm = models.terminalcvm;
    controller.set('terminalcvm', terminalcvm);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);
    controller.set('crudParams', {
      formModelName: 'Terminal CVM',
      formFields: this.get('crudFormFields').getForModel('terminalcvm'),
      buttonPermissions: {
        overall: 'v2-terminalcvms-get',
        back: 'v2-terminalcvms-get',
        cancel: 'v2-terminalcvms-get',
        delete: 'v2-terminalcvms-delete',
        edit: 'v2-terminalcvms-put',
        save: 'v2-terminalcvms-put'
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Terminal CVM ● ' + terminalcvm.get('title'),
      route_name: 'admin.terminalcvms.crud',
      route_id: terminalcvm.get('id')
    });
    controller.set('pageTitle', 'View Terminal CVM');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terminal CVMs',
      link: 'admin.terminalcvms.index'
    }, {
      title: 'View Terminal CVM',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.terminalcvm, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
