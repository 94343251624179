import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'id',
    'merchant_id',
    'processor',
    'sub_processor',
    'terminal_id',
    'title',
  ],
  processors: [{
    'id': 'zgate2',
    'title': 'zGate2'
  }, {
    'id': 'ach',
    'title': 'ACH/EFT'
  }],

  columns: Ember.computed(function() {
    var self = this;
    return [{
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(producttransaction) {
          self.transitionToRoute('locations.viewproducttransaction', producttransaction.get('location_id'), producttransaction.get('id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'text'
        }
      }, {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      {
        'name': 'industry_type',
        'title': 'Industry Type',
        'type': 'string',
        'sortable': true
      },
      {
        'name': 'merchant_id',
        'title': 'Merchant Id',
        'type': 'number',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      {
        'name': 'terminal_id',
        'title': 'Terminal Id',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      {
        'name': 'processor',
        'title': 'Processor',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'processors',
          multi: true
        }
      },
      {
        'name': 'sub_processor',
        'title': 'Sub Processor',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      }
    ];
  }),
  actions: {}
});
