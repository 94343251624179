import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      portfolio: store.queryRecord('portfolio', {
        id: params.view_id,
        expand: 'parent,created_user'
      }),
      portfolioitems: store.query('portfolioitem', {
        portfolio_id: params.view_id
      }),
      portfoliousers: store.query('portfoliouser', {
        portfolio_id: params.view_id,
        expand: 'user'
      }),
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      parent: store.queryRecord('portfolio', {
        id: models.portfolio.get('parent_id'),
      }).then(function(data) {
        models.parent = data;
      }, function(){
        models.parent = null;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var portfolio = models.portfolio;
    if(models.parent) {
      portfolio.set('parent', models.parent);
    }
    controller.set('portfolio', portfolio);
    controller.set('portfolioitems', models.portfolioitems);
    controller.set('portfolioitem', this.get('store').createRecord('portfolioitem', {
      portfolio_id: portfolio.get('id'),
    }));
    controller.set('portfoliousers', models.portfoliousers);
    controller.set('portfoliouser', this.get('store').createRecord('portfoliouser', {
      portfolio_id: portfolio.get('id'),
      user_id: null,
    }));
    controller.set('location_id', null);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-portfolios-delete'),
      canPost: this.get('session.authenticated.resources.v2-portfolios-post'),
      canPut: this.get('session.authenticated.resources.v2-portfolios-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Portfolio ● ' + portfolio.get('title'),
      route_name: 'admin.portfolios.view',
      route_id: portfolio.get('id')
    });
    controller.set('pageTitle', 'View Portfolio');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Portfolios',
      link: 'admin.portfolios.index'
    }, {
      title: 'View Portfolio',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.portfolio, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
