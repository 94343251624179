import Ember from "ember";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  authagreementController: Ember.inject.controller(
    "components/modals/authagreement"
  ), // needed by this controller
  payquickinvoiceController: Ember.inject.controller(
    "components/modals/payquickinvoice"
  ), // needed by this controller to payquickinvoice
  sessionService: Ember.inject.service("session"),
  vtcController: Ember.inject.controller(
    "components/modals/virtualterminalcontact"
  ),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "transaction.errors.messages",
    function () {
      this.set("errorMessages", this.get("transaction.errors.messages"));
    }
  ),

  // Set controller properties
  isLoading: false,
  accounts: null,
  showTransactions: Ember.computed("locationinfo", function () {
    var locationinfo = this.get("locationinfo");
    if (!locationinfo) {
      return;
    }
    return locationinfo.get("product_transactions.length") > 0 ? true : false;
  }),

  products: Ember.computed(
    "locationinfo.product_transactions.@each",
    function () {
      return this.get("locationinfo.product_transactions");
    }
  ),

  needsRefresh: "",
  needsRefreshObserve: Ember.observer("needsRefresh", function () {
    this.send("updateTransactions");
  }),

  isHideBlindPayment: Ember.computed(
    "session.authenticated.isContact",
    "contactuser.hide_blind_payment",
    "locationinfo.hide_blind_payment",
    "domainBranding.domain.hide_blind_payment",
    function () {
      const isContactUser = this.get("session.authenticated.isContact");
      const contactHideBlindPayment = this.get(
        "contactuser.hide_blind_payment"
      );
      const locationHideBlindPayment = this.get(
        "locationinfo.hide_blind_payment"
      );
      const domainHideBlindPayment = this.get(
        "domainBranding.domain.hide_blind_payment"
      );
      return (
        isContactUser &&
        (contactHideBlindPayment ||
          locationHideBlindPayment ||
          domainHideBlindPayment)
      );
    }
  ),
  showPayMultipleInvoice: Ember.computed("quickinvoices", function () {
    let openInvoices = 0;

    const quickinvoices = this.get("quickinvoices");
    const content = quickinvoices && quickinvoices.get("content");
    if (content) {
      content.forEach((x, index) => {
        if (content.objectAt(index).get("status_title") === "Open")
          openInvoices += 1;
      });
    }

    return openInvoices > 1;
  }),

  // Functions
  launchvt: function (trans_type, account) {
    // var self = this;
    var vtcController = this.get("vtcController");

    var locationinfo = this.get("locationinfo");
    vtcController.set("contact", this.get("contactuser"));
    vtcController.set("action", trans_type);
    vtcController.set("modal_title", "Make a Payment");
    vtcController.set("account", account);
    vtcController.set("disable_deposit_account", true);
    vtcController.set("disable_tags", true);
    vtcController.set("hide_transaction_action", true);
    vtcController.set("hide_lodging_fields", true);
    vtcController.set("show_contact_name", false);
    vtcController.set("route", "contactuser");

    var locations = Ember.A();
    locations.pushObject(locationinfo);
    vtcController.set("locations", locations);
    this.send("openModal", "components/modals/virtualterminalcontact");
  },

  // Actions
  actions: {
    launchModal: function (model, modal, other) {
      if (modal === "virtualterminal") {
        this.launchvt(other, model);
      }
    },
    deleteAccount: function (account) {
      var self = this;
      if (account.get("has_recurring")) {
        return;
      }

      pretty_confirm(
        "Are you sure you want to delete this Account Vault?",
        function () {
          // Bail if we are already loading
          if (self.get("isLoading")) {
            return;
          }
          self.set("isLoading", true);
          account.deleteRecord();
          account.save().then(
            function () {
              noty({
                text: "Account Vault deleted successfully!",
              });
              self.set("isLoading", false);
              self.send("updateAccounts");
            },
            function (data) {
              var errorMessage = "Error deleting Account Vault!";
              if (data.errors) {
                data.errors.forEach(function (err) {
                  errorMessage += "<br>" + err.detail + ".";
                });
              }
              account.rollbackAttributes();
              noty({
                text: errorMessage,
                type: "error",
                timeout: 10000,
              });
              self.set("isLoading", false);
            }
          );
        }
      );
    },
    deleteQuickInvoice: function (quickinvoice) {
      var self = this;
      pretty_confirm(
        "Are you sure you want to delete this Quick Invoice?",
        function () {
          if (self.get("isLoading")) {
            return;
          }
          self.set("isLoading", true);
          quickinvoice.deleteRecord();
          quickinvoice.save().then(
            function () {
              noty({
                text: "Quick Invoice deleted successfully!",
              });
              quickinvoice.unloadRecord();
              self.send("updateQuickinvoices");
            },
            function () {
              quickinvoice.rollbackAttributes();
              noty({
                text: "Error deleting Quick Invoice!",
                type: "error",
              });
            }
          );
        }
      );
    },
    deleteRecurring: function (recurring) {
      var self = this;
      pretty_confirm(
        "Are you sure you want to delete this Scheduled Payment?",
        function () {
          // Bail if we are already loading
          if (self.get("isLoading")) {
            return;
          }
          self.set("isLoading", true);
          recurring.destroyRecord().then(
            function () {
              recurring.unloadRecord();
              noty({
                text: "Scheduled Payment deleted successfully!",
              });
              self.set("isLoading", false);
              self.send("updateRecurrings");
            },
            function () {
              recurring.rollbackAttributes();
              noty({
                text: "Error deleting Scheduled Payment!",
                type: "error",
              });
              self.set("isLoading", false);
            }
          );
        }
      );
    },
    payQuickInvoice: function (quickinvoice) {
      var payquickinvoiceController = this.get("payquickinvoiceController");

      payquickinvoiceController.set("quickinvoice", null);
      payquickinvoiceController.set("locations", null);
      payquickinvoiceController.set("accounts", null);
      payquickinvoiceController.set("contact", null);
      // Setting below option as false when the user navigates to the dashboard. This need not be true, as we can pay multiple invoices only from the quickinvoices page.
      quickinvoice.set('_pay_multiple_invoices', false);
      payquickinvoiceController.set("quickinvoice", quickinvoice);
      payquickinvoiceController.set("quickinvoice._pay_multiple_invoices", false);
      payquickinvoiceController.set("locations", this.get("locationinfos"));
      payquickinvoiceController.set("accounts", this.get("accounts"));
      payquickinvoiceController.set("contact", this.get("contactuser"));

      // Close any pre-existing modals
      this.send("closeModal");
      this.send("openModal", "components/modals/payquickinvoice");
    },
    showQuickInvoice: function (quickinvoice) {
      this.transitionToRoute(
        "contactuser.quickinvoices-view",
        this.get("contactuser.id"),
        quickinvoice.get("id")
      );
    },
    showTrans: function (transaction) {
      var locationinfo = this.get("locationinfo");
      this.send("showTransDetail", transaction, locationinfo);
    },
    updateAccounts: function () {
      var session = this.get("session");
      if (session.get("authenticated.resources.v2-accountvaults-get")) {
        var self = this;
        self.set("accounts", null);
        var accounts = self.get("store").query("accountvault", {
          expand: "created_user",
          contact_id: self.get("contactuser.id"),
          page_size: "5",
          sort: "title",
        });
        accounts.then(function () {
          self.set("accounts", accounts);
        });
      }
    },
    updateQuickinvoices: function () {
      var hasPriv = this.get(
        "session.authenticated.resources.v2-quickinvoices-get"
      );
      if (hasPriv && this.get("locationinfo.hasQuickInvoice")) {
        var self = this;
        self.set("isLoading", true);
        self.set("quickinvoices", null);
        var quickinvoices = self.get("store").query("quickinvoice", {
          contact_id: self.get("contactuser.id"),
          sort: "-due_date",
          page_size: "5",
          expand: "cc_product_transaction,ach_product_transaction,files",
          active: "1",
          payment_status_id: "1,3",
        });
        quickinvoices.then(
          function () {
            self.set("quickinvoices", quickinvoices);
            self.set("isLoading", false);
          },
          function () {
            self.set("isLoading", false);
          }
        );
      } else {
        this.set("quickinvoices", null);
      }
    },
    updateRecurrings: function () {
      var self = this;
      if (this.get("session.authenticated.resources.v2-recurrings-get")) {
        self.set("recurrings", null);
        var recurrings = self.get("store").query("recurring", {
          expand:
            "created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags",
          contact_id: self.get("contactuser.id"),
          sort: "next_run_date",
          page_size: "5",
        });
        recurrings.then(function () {
          self.set("recurrings", recurrings);
        });
      } else {
        this.set("recurrings", null);
      }
    },
    updateTransactions: function () {
      var self = this;
      self.set("transactions", null);
      var transactions = self.get("store").query("transaction", {
        expand:
          "contact.id,contact.first_name,contact.last_name,contact.email,surcharge.id,surcharge.surcharge_amount,created_user.id,created_user.username,tags,status,type",
        fields:
          "id,location_id,created_ts,account_holder_name,auth_amount,transaction_amount,description,tags,batch,account_type,created_user_id,type_id,status_id,auth_code,last_four,is_recurring,is_accountvault,room_num,room_rate,checkin_date,checkout_date,product_transaction_id,tip_amount",
        contact_id: self.get("contactuser.id"),
        page_size: "5",
        sort: "-created_ts",
      });
      transactions.then(function () {
        self.set("transactions", transactions);
      });
    },
    viewAccount: function (account, action) {
      var isEditing = action === "edit";
      this.transitionToRoute(
        "contactuser.accounts-view",
        this.get("contactuser.id"),
        account.get("id")
      ).then(function (Route) {
        Route.controller.set("isEditing", isEditing);
      });
    },
    viewRecurring: function (recurring, action) {
      var isEditing = action === "edit";
      this.transitionToRoute(
        "contactuser.recurrings-view",
        this.get("contactuser.id"),
        recurring.get("id")
      ).then(function (Route) {
        Route.controller.set("isEditing", isEditing);
      });
    },
    createRecurring: function (account) {
      this.transitionToRoute("contactuser.recurrings-add").then(function (
        Route
      ) {
        Route.controller.set("force_account", account);
      });
    },
  },
});
