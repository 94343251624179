import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  reportstransactions: Ember.inject.controller('reports/transactions'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sender: null,

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('userreport.errors.messages', function() {
    this.set('errorMessages', this.get('userreport.errors.messages'));
  }),

  pagerParams: {
    pageRoute: 'components.modals.userreport'
  },

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var context = this.get('context');
      var userreport = this.get('userreport');
      var filterData = userreport.get('filter_data');

      if (filterData && filterData.created_ts === 'custom') {
        this.set('errorMessages', ['Custom dates are invalid for saved reports']);
        this.set('isLoading', false);
      } else {
        userreport.save().then(
          function(data) {
            noty({
              text: 'Successfully saved as new report!',
              type: 'success',
              timeout: '5000'
            });
            self.set('isLoading', false);
            self.send('closeModal');
            context.send('updateUserreports', data);
          },
          function() {
            self.set('isLoading', false);
          }
        );
      }
    }
  }
});
