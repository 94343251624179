import Ember from 'ember';
/*
	Displays dates in designated format

	short: 1/1/1971 1:01 am
	long: January 1st 1971, 1:01:01 am
	default: long
*/
export default Ember.Helper.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  compute(params, options) {
    var date = params[0];
    var session = this.get('session');
    if (date === '0000-00-00' || date === null || typeof date === 'undefined' || date === 'Invalid date') {
      return '';
    }

    if (options.dateInput === 'YYYY-MM-DD') {
      var newValue = moment(date, 'YYYY-MM-DD');
      return newValue.isValid() ? newValue.format('MM/DD/YYYY') : null;
    }

    var dateInput = (options.dateInput === 'ISO') ? '' : options.dateInput || 'X';
    var dateOutput = options.dateOutput || 'long';

    var tz = session.get('authenticated.timezone');

    var badTz = '';
    if (!tz) {
      tz = 'America/New_York';
    }

    // Check if the zone is loaded
    if (!tz || !moment(new Date()).utcOffset(tz)) {
      // Throw an error so it will be logged to the API server
      try {
        throw 'Timezone ' + tz + ' not valid';
      } catch (err) {
        tz = 'America/New_York';
        badTz = ' *';
      }
    }

    var tempDate = moment(date, dateInput);
    if (!tempDate.isValid()) {
      return '';
    }
    var formattedDate;
    switch (dateOutput) {
      case 'date':
        formattedDate = tempDate.tz(tz).format('MM/DD/YY');
        break;
      case 'short':
        formattedDate = tempDate.tz(tz).format('M/D/YY h:mm a z');
        break;
      case 'mid':
        formattedDate = tempDate.tz(tz).format('M/D/YYYY h:mm:ss a z');
        break;
      case 'long':
        formattedDate = tempDate.tz(tz).format('MMMM Do YYYY, h:mm:ss a z');
        break;
      default:
        formattedDate = tempDate.tz(tz).format('MMMM Do YYYY, h:mm:ss a z');
    }

    return formattedDate + badTz;
  }

});
