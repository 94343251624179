import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authrole: store.findRecord('admin_authrole', params.view_id),
      userTypes: store.findAll('usertype'),
      resources: store.query('resource', {
        page_size: 1000,
        sort: 'resource_name'
      }),
      authroleresources: store.query('admin_authroleresource', {
        auth_role_id: params.view_id,
        page_size: 2000
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var authrole = models.authrole;

    controller.set('authrole', authrole);
    controller.set('userTypes', models.userTypes);
    controller.set('resources', models.resources);

    var arrArray = [];
    var tempArray = [];
    models.authroleresources.forEach(function(arr) {
      arrArray.push(arr.get('resource_id'));
    });
    models.resources.forEach(function(resource) {
      arrArray.forEach(function(arr) {
        if(arr === resource.get('id')) {
          tempArray.push({
            title: resource.get('title'),
            resource_name: resource.get('resource_name')
          });
        }
      });
    });
    controller.set('selectedResources', tempArray);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-authroles-delete'),
      canPost: this.get('session.authenticated.resources.v2admin-authroles-post'),
      canPut: this.get('session.authenticated.resources.v2admin-authroles-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Role ● ' + authrole.get('title'),
      route_name: 'admin.roles.view',
      route_id: authrole.get('id')
    });
    controller.set('pageTitle', 'View Role');
    controller.set('pageSubtitle', null);
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Roles',
      link: 'admin.roles.index'
    }, {
      title: 'View Role',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.authrole, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
