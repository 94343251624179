import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  transactionbatchemailsController: Ember.inject.controller('components/modals/transactionbatchemails'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsSettle: true,
  ptas: null,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransactionautosettle.errors.messages', function () {
    this.set('errorMessages', this.get('producttransactionautosettle.errors.messages'));
  }),
  errorMessagesBatchEmails: null,
  updateErrorMessagesBatchEmails: Ember.observer('producttransactionbatchcloseemail.errors.messages', function () {
    this.set('errorMessages', this.get('producttransactionbatchcloseemail.errors.messages'));
  }),

  // Set controller params
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  showProducts: false,
  isAddUser: true,
  showBatchEmails: false,

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'batch_close_ts',
    'batch_close_ts_from',
    'batch_close_ts_to',
    'batch_num',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'processing_status_id',
    'product_transaction_active',
    'product_transaction_id',
  ],
  hourList: [{
    'id': '01',
    'title': '01'
  }, {
    'id': '02',
    'title': '02'
  }, {
    'id': '03',
    'title': '03'
  }, {
    'id': '04',
    'title': '04'
  }, {
    'id': '05',
    'title': '05'
  }, {
    'id': '06',
    'title': '06'
  }, {
    'id': '07',
    'title': '07'
  }, {
    'id': '08',
    'title': '08'
  }, {
    'id': '09',
    'title': '09'
  }, {
    'id': '10',
    'title': '10'
  }, {
    'id': '11',
    'title': '11'
  }, {
    'id': '00',
    'title': '12'
  }],
  minuteList: [{
    'id': '00',
    'title': '00'
  }, {
    'id': '05',
    'title': '05'
  }, {
    'id': '10',
    'title': '10'
  }, {
    'id': '15',
    'title': '15'
  }, {
    'id': '20',
    'title': '20'
  }, {
    'id': '25',
    'title': '25'
  }, {
    'id': '30',
    'title': '30'
  }, {
    'id': '35',
    'title': '35'
  }, {
    'id': '40',
    'title': '40'
  }, {
    'id': '45',
    'title': '45'
  }, {
    'id': '50',
    'title': '50'
  }, {
    'id': '55',
    'title': '55'
  }],
  meridiemList: [{
    'id': 'am',
    'title': 'am'
  }, {
    'id': 'pm',
    'title': 'pm'
  }],
  columns: Ember.computed('transactionbatches', function () {
    var self = this;
    var cols = [{
      'name': 'created_ts',
      'title': 'Created<br>Date',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false,
        showTimeFilter: true
      }
    }, {
      'name': 'batch_close_ts',
      'title': 'Batch<br>Close Time',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false,
        showTimeFilter: true
      }
    }, {
      'name': 'batch_num',
      'title': 'Batch<br>Number',
      'type': 'string',
      'align': 'text-right',
      'sortable': true,
      'action': function (batch) {
        self.send('view', batch);
      },
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'processing_status_id',
      'title': 'Status',
      'type': 'string',
      'align': 'text-left',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'processingstatuses',
      }
    }];
    var session = this.get('session');
    if (session.get('authenticated.resources.v2-transactionbatches-post') || session.get('authenticated.resources.v2-transactionbatches-post-forceclose')) {
      var actions = {};
      if (session.get('authenticated.resources.v2-transactionbatches-post')) {
        actions['postTransactionBatch'] = {
          run(batch) {
            // Bail if we are already closing batch
            if (self.get('isClosingBatch')) {
              return;
            }
            var session = self.get('session');
            self.set('isClosingBatch', true);
            jQuery.ajax({
              url: window.UiENV.APP.FULL_URL + '/transactionbatches/' + batch.id + '/settle',
              type: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'developer-id': window.UiENV.APP.DEVELOPER_ID,
                'access-token': session.get('authenticated.token')
              }
            }).then(function (data) {
              self.set('isClosingBatch', false);
              var status_id = data.transactionbatch.processing_status_id;
              var closed = false;

               if (status_id === 2) {
                closed = !closed;
              }

              if (closed) {
                noty({
                  text: 'Batch closed successfully!'
                });
              } else {
                noty({
                  text: 'Batch closed with error!',
                  type: 'error'
                });
              }
              self.send('gridNeedsRefresh', self);
            }, function (result) {
              var errorText = '';
              var errors = result.responseJSON.errors;
              Object.keys(errors).forEach(function (m) {
                if (typeof errors === 'string') {
                  errorText += errors[m] + '<br>';
                } else {
                  Object.keys(errors[m]).forEach(function (n) {
                    errorText += errors[m][n] + '<br>';
                  });
                }
              });
              noty({
                text: errorText,
                type: 'error'
              });
              self.set('isClosingBatch', false);
            });
          },
          display(batch) {
            var processing_status_id = batch.get('processing_status_id');
            var is_open = batch.get('is_open');
            var is_closing_batch = self.get('isClosingBatch');

            if (processing_status_id === '1' && is_open) {
              if (is_closing_batch) {
                return '<i class="fas fa-cog fa-spin fa-2x fa-fw"></i>';
              } else {
                return 'Close Batch';
              }
            } else if (processing_status_id === '1' && !is_open) {
              return 'Pending Settlement';
            } else if (processing_status_id === '3' && !is_open) {
              if (is_closing_batch) {
                return '<i class="fas fa-cog fa-spin fa-2x fa-fw"></i>';
              } else {
                return 'Retry Batch';
              }
            } else {
              return '';
            }
          }
        };
      }

      if (session.get('authenticated.resources.v2-transactionbatches-post-forceclose')) {
        actions['forceCloseBatch'] = {
          run(batch) {
            if (self.get('isClosingBatch')) {
              return;
            }
            var session = self.get('session');

            pretty_confirm('Are you sure you want to force close this batch? No funding will occur when a batch is force closed.', function () {
              self.set('isClosingBatch', true);
              jQuery.ajax({
                  url: window.UiENV.APP.FULL_URL + '/transactionbatches/' + batch.id + '/forceclose',
                  type: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'developer-id': window.UiENV.APP.DEVELOPER_ID,
                    'access-token': session.get('authenticated.token')
                  }
                })
                .then(
                  function (data) {
                    self.set('isClosingBatch', false);
                    var status_id = data.transactionbatch.processing_status_id;

                    if (status_id === 2) {
                      noty({
                        text: 'Batch closed successfully!'
                      });
                    } else if (status_id === 6) {
                      noty({
                        text: 'Batch forced closed!'
                      });
                    } else {
                      noty({
                        text: 'Batch closed with error!',
                        type: 'error'
                      });
                    }
                    self.send('gridNeedsRefresh', self);
                  },
                  function (result) {
                    var errorText = '';
                    var errors = result.responseJSON.errors;
                    if (errors) {
                      Object.keys(errors).forEach(function (m) {
                        if (typeof errors === 'string') {
                          errorText += errors[m] + '<br>';
                        } else {
                          Object.keys(errors[m]).forEach(function (n) {
                            errorText += errors[m][n] + '<br>';
                          });
                        }
                      });
                    } else {
                      errorText = "Communication error with processor";
                    }
                    noty({
                      text: errorText,
                      type: 'error'
                    });
                    self.set('isClosingBatch', false);
                  }
                );
            });
          },
          display(batch) {
            var processing_status_id = batch.get('processing_status_id');
            var is_open = batch.get('is_open');
            var is_closing_batch = self.get('isClosingBatch');

            if (processing_status_id === '3' && !is_open) {
              if (is_closing_batch) {
                return '';
              } else {
                return 'Force Close';
              }
            } else {
              return '';
            }
          },
        };
      }

      cols.push({
        'name': 'processing_status.id',
        'title': 'Action',
        'align': 'text-center',
        'component': 'grid-cell-action',
        'action': actions
      });
    }
    if (session.get('authenticated.resources.v2-logemails-get-transactionbatch')) {
      cols.push({
        'name': 'none',
        'title': 'Emails',
        'type': 'resend',
        'align': 'text-center',
        'action': function (batch) {
          self.send('batchEmailShow', batch);
        },
      });
    }
    return cols;
  }),

  // Observers

  changedProduct: Ember.observer('product_transaction_id', function () {
    this.getBatchCloseTimes();
    this.getBatchCloseEmails();
    this.getProductTransactionActive();
  }),

  // Functions
  getBatchCloseTimes: function () {
    var product_transaction_id = this.get('product_transaction_id');
    if (Ember.isEmpty(product_transaction_id)) {
      return;
    }
    var self = this;
    this.set('isLoadingPTAs', true);
    this.get('store').query('producttransactionautosettle', {
      product_transaction_id: product_transaction_id,
      sort: 'batch_close_time'
    }).then(function (data) {
      data.forEach(function (d) {
        var time = d.get('batch_close_time');
        var hour = time.slice(0, 2);
        var minute = time.slice(2, 4);
        var meridiem = 'am';
        if (parseInt(hour) >= 12) {
          meridiem = 'pm';
          if (hour > 12) {
            hour = parseInt(hour) - 12;
          }
        }
        if (hour === '00') {
          hour = '12';
        }
        d.set('batch_close', hour + ':' + minute + ' ' + meridiem);
      });
      self.set('ptas', data);
      self.set('isLoadingPTAs', false);
    }, function () {
      noty({
        text: 'Error retrieving auto batch times!',
        type: 'error'
      });
      self.set('isLoadingPTAs', false);
    });
  },
  getBatchCloseEmails: function () {
    var session = this.get('session');
    var product_transaction_id = this.get('product_transaction_id');
    var location_id = this.get('location.id');
    if (Ember.isEmpty(product_transaction_id)) {
      return;
    }
    var self = this;
    if (session.get('authenticated.resources.v2-users-get')) {
      this.get('store').query('user', {
        location_id: location_id,
        page_size: '50'
      }).then(function (data) {
        self.set('users', data);
      }, function () {
        noty({
          text: 'Error retrieving batch emails!',
          type: 'error'
        });
      });
    }
    if (session.get('authenticated.resources.v2-producttransactionbatchcloseemails-get')) {
      this.set('isLoadingPTBEs', true);
      this.get('store').query('producttransactionbatchcloseemail', {
        product_transaction_id: product_transaction_id,
        expand: 'user',
        page_size: '50'
      }).then(function (data) {
        self.set('ptbes', data);
        self.set('isLoadingPTBEs', false);
      }, function () {
        noty({
          text: 'Error retrieving batch emails!',
          type: 'error'
        });
        self.set('isLoadingPTBEs', false);
      });
    }
  },
  getProductTransactionActive: function () {
    if (this.get('products')) {
      var product = this.getSelectedProduct();
      this.set('product_transaction_active', (product && product.get('active')) ? '1' : '0');
      this.set('isActiveProduct', (product && product.get('active')));
    }
  },

  getSelectedProduct: function () {
    var selected_product_id = this.get('product_transaction_id');
    return this.get('products').filter(i => i.get('id') === selected_product_id).get('firstObject');
  },

  // Actions
  actions: {
    batchEmailShow: function (batch) {
      var self = this;
      var transactionbatchemailsController = this.get('transactionbatchemailsController');
      transactionbatchemailsController.set('batchlogemails', null);
      this.get('store').query('logemail', {
        reason_model: 'TransactionBatch',
        reason_model_id: batch.get('id'),
        expand: 'log_email_recipients'
      }).then(function (data) {
        transactionbatchemailsController.set('batchlogemails', data);
        self.send('openModal', 'components/modals/transactionbatchemails');
      }, function (error) {
        return error;
      });
    },
    addType: function (type) {
      var producttransactionbatchcloseemail = this.get('producttransactionbatchcloseemail');
      producttransactionbatchcloseemail.set('email', null);
      producttransactionbatchcloseemail.set('user_id', null);

      if (type === 'user') {
        this.set('isAddUser', true);
      } else {
        this.set('isAddUser', false);
      }
    },
    addSettle: function () {
      var self = this;
      var producttransactionautosettle = this.get('producttransactionautosettle');
      var hour = producttransactionautosettle.get('hour');
      var minute = producttransactionautosettle.get('minute');
      var meridiem = producttransactionautosettle.get('meridiem');

      producttransactionautosettle.set('product_transaction_id', this.get('product_transaction_id'));
      if (meridiem === 'am' && hour === '12') {
        hour = '00';
      }
      if (meridiem === 'pm' && hour !== 12) {
        hour = parseInt(hour) + 12;
      }
      producttransactionautosettle.set('batch_close_time', hour + minute);
      producttransactionautosettle.save().then(function () {
        noty({
          text: 'Batch close time added successfully!'
        });
        self.getBatchCloseTimes();
        self.init();
        self.send('refreshData');
        jQuery('#btn_add_batch_close_times').dropdown('toggle');
      }, function () {
        noty({
          text: 'Error adding batch close time!',
          type: 'error'
        });
      });
    },
    cancelAddSettle: function (alreadyToggled) {
      this.set('producttransactionautosettle.hour', '10');
      this.set('producttransactionautosettle.minute', '30');
      this.set('producttransactionautosettle.meridiem', 'pm');
      if (!alreadyToggled) {
        jQuery('#btn_add_batch_close_times').dropdown('toggle');
      }
    },
    deleteSettle: function (ptas) {
      var self = this;
      pretty_confirm('Are you sure you would like to delete the auto batch close time?', deletePtas);

      function deletePtas() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        ptas.deleteRecord();
        ptas.save().then(function () {
          noty({
            text: 'Batch close time deleted successfully!'
          });
          self.set('isLoading', false);
        }, function () {
          ptas.rollbackAttributes();
          noty({
            text: 'Error deleting batch close time!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    addRecipient: function () {
      var self = this;
      var producttransactionbatchcloseemail = this.get('producttransactionbatchcloseemail');

      producttransactionbatchcloseemail.set('product_transaction_id', this.get('product_transaction_id'));
      producttransactionbatchcloseemail.save().then(function () {
        noty({
          text: 'Batch report recipient added successfully!'
        });
        self.getBatchCloseEmails();
        self.init();
        self.send('refreshData');
        jQuery('#btn_add_batch_close_emails').dropdown('toggle');
      }, function () {
        noty({
          text: 'Error adding batch report recipient!',
          type: 'error'
        });
      });
    },
    cancelAddRecipient: function (alreadyToggled) {
      this.set('producttransactionbatchcloseemail.email', null);
      this.set('producttransactionbatchcloseemail.user_id', null);
      this.set('producttransactionbatchcloseemail.notification_template_id', '0'); // set to 0 then null to clear error if there was one for this field
      this.set('producttransactionbatchcloseemail.notification_template_id', null);
      if (!alreadyToggled) {
        jQuery('#btn_add_batch_close_emails').dropdown('toggle');
      }
    },
    deleteRecipient: function (ptbe) {
      var self = this;
      pretty_confirm('Are you sure you would like to remove this recipient from the batch close email?', deletePtbe);

      function deletePtbe() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        ptbe.deleteRecord();
        ptbe.save().then(function () {
          noty({
            text: 'Batch email recipient deleted successfully!'
          });
          self.set('isLoading', false);
        }, function () {
          ptbe.rollbackAttributes();
          noty({
            text: 'Error deleting batch report recipient!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
    updateUsers: function (data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function (result) {
        self.set('users', result);
      });
    },
    view: function (batch) {
      var date = '';
      if (batch.get('batch_close_ts') === 'Invalid Date') {
        date = batch.get('created_ts');
      } else {
        date = batch.get('batch_close_ts');
      }
      var date_from = moment(date).subtract(1, 'months').startOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var date_to = moment(date).add(1, 'months').endOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var batch_num = batch.get('batch_num');
      this.transitionToRoute('locations.transactions', this.get('location.id'), {
        queryParams: {
          batch: batch_num + ',' + batch_num,
          product_transaction_id: batch.get('product_transaction_id'),
          created_ts: 'custom',
          created_ts_from: date_from,
          created_ts_to: date_to,
          status_id: '101,111'
        }
      });
    }
  }
});
