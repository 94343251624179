import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    var getProducts = function() {
      if (session.get('authenticated.resources.v2-producttransactions-get') && params.view_id !== 'nofees') {
        return store.query('producttransaction', {
          product_billing_group_id: params.view_id,
          page_size: '200'
        });
      }
    };
    var getProductBillingGroupItems = function() {
      if (session.get('authenticated.resources.v2-productbillinggroupitems-get')) {
        return store.query('productbillinggroupitem', {
          product_billing_group_id: params.view_id,
          expand: 'item',
          page_size: '200'
        });
      }
    };
    return Ember.RSVP.hash({
      productbillinggroup: store.queryRecord('productbillinggroup', {
        id: params.view_id,
        expand: 'location'
      }),
      productbillinggroupitems: getProductBillingGroupItems(),
      producttransactions: getProducts()
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('productbillinggroup', models.productbillinggroup);
    controller.set('producttransactions', models.producttransactions);
    var items = models.productbillinggroupitems;
    items = items ? items.sortBy('item.title') : [];
    controller.set('productbillinggroupitems', items);
    controller.set('isEditing', false);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Product Billing Group ● View',
      route_name: 'admin.productbillinggroups.view',
      route_id: models.productbillinggroup.get('id')
    });
    controller.set('pageTitle', 'Edit Product Billing Group');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Product Billing Groups',
      link: 'admin.productbillinggroups.index'
    }, {
      title: 'View',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.productbillinggroup, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
