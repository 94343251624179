import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'admin.reports.developersummaryreports',
      pageModelId: null,
      modelName: 'developersummaryreport'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {};
    controller.set('gridParams', gridParams);

    controller.set('location_created_user_id', null);
    controller.set('location_developer_company_id', null);
    controller.set('parent_location_id', null);
    controller.set('product_transaction_created_user_id', null);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Developer Summary',
      route_name: 'admin.reports.developersummaryreports',
      route_id: null
    });
    controller.set('pageTitle', 'Developer Summary');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Developer Summary',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
