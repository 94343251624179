import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  normalize: function(type, hash, property) {
    if(!hash.id){
      hash.id = ((hash.location_id || Math.random().toString(36).slice(2)) + (hash.zip_code || Math.random().toString(36).slice(2)) + Math.random().toString(36).slice(2));
    }
    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  },
  attrs: {
    location: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
