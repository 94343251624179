import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: params.id,
          page_size: '500'
        });
      } else {
        return [];
      }
    };
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', params.id),
      accounts: getAccounts(),
      recurring: store.queryRecord('recurring', {
        id: params.recurring_id,
        expand: 'next_run_date_min,next_run_date_max,location,product_transaction,account_vault,created_user,transactions,payment_schedule',
        active: '0,1'
      })
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        id: models.contact.get('location_id'),
        product_transaction_active: '0,1',
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('accounts', models.accounts);
    controller.set('recurring', models.recurring);
    controller.set('locationinfo', location);
    controller.set('locationinfos', models.locationinfos);

    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.recurring.get('id'),
        type: 'recurring',
        attributes: {
          terms_agree: true
        }
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Scheduled Payment',
      route_name: 'contactuser.recurrings-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.recurring.get('id')
    });
    controller.set('pageTitle', 'View Scheduled Payment');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Scheduled Payments',
      link: 'contactuser.recurrings-index'
    }, {
      title: 'View Scheduled Payment',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.controller.get('recurring');
      if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
