import Ember from 'ember';

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  page: 1,
  page_size: '15',
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'account_vault_id',
    'last_four',
    'response_code',
    'location_id'
  ],

  columns: Ember.computed('gridData', 'isMobileSize', function() {
    var self = this;

    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      account_vault_id: {
        'name': 'account_vault_id',
        'title': 'Account Vault ID',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
        }
      },
      response_code: {
        'name': 'response_code',
        'title': 'Update Response Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
        }
      },
      response_message: {
        'name': 'response_message',
        'title': 'Update Response Message',
        'type': 'string',
        'sortable': false,
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location ID',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.transitionToRoute('locations.view', account.get('location_id'));
        },
        'filter': {
          type: 'text',
          items: 'locations'
        }
      },
    };
    const listOfCols = ['created_ts', 'account_vault_id', 'last_four', 'response_code', 'response_message', 'location_id'];
    const listOfMobileCols = ['created_ts', 'account_vault_id', 'last_four', 'response_code', 'response_message', 'location_id'];

    if(this.get('isMobileSize')){
      const mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      const cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  actions: {
    updateReport: function(data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof data) !== 'object') {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function(item) {
        props[item.name.replace('.', '-')] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
    },
  }
});
