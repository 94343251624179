import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('locationinfo', {
        id: params.id,
        expand: 'product_transactions,branding_domain,quick_invoice_setting,product_file',
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var store = this.get('store');
    controller.set('location', models.location);
    var quickinvoicesettings = models.location.get('quick_invoice_setting');

    controller.set('isEditing', true);
    var quickinvoice = store.createRecord('quickinvoice', {
      allow_overpayment: false,
      allow_partial_pay: quickinvoicesettings && quickinvoicesettings.default_quick_invoice_allow_partial_pay ? true : false,
      attach_files_to_email: false,
      cc_product_transaction_id: null,
      contact_id: '',
      email: '',
      item_list: {},
      location_id: models.location.get('id'),
      notification_days_after_due_date: quickinvoicesettings && quickinvoicesettings.default_quick_invoice_notification_days_after_due_date,
      notification_days_before_due_date: quickinvoicesettings && quickinvoicesettings.default_quick_invoice_notification_days_before_due_date,
      notification_on_due_date: quickinvoicesettings && quickinvoicesettings.default_quick_invoice_notification_on_due_date ? true : false,
      send_email: true,
      single_payment_min_amount: 0
    });
    controller.set('quickinvoice', quickinvoice);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Quick Invoice',
      route_name: 'locations.quickinvoices-add',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Quick Invoices',
      link: 'locations.quickinvoices-index',
      record: models.location.get('id')
    }, {
      title: 'Add Quick Invoice',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
