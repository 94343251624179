import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.set('loggedInUserCanEditViewedUser', this.get('usersMethods').loggedInUserCanEditViewedUser(this.get('user')));
  },

  columns: Ember.computed('loggedInUserCanEditViewedUser', function() {
    var loggedInUserCanEditViewedUser = this.get('loggedInUserCanEditViewedUser');
    var self = this;
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      reason_sent: {
        'name': 'reason_sent',
        'title': 'Reason Sent',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      subject: {
        'name': 'subject',
        'title': 'Subject',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      resendEmail: {
        'name': 'id',
        'title': '',
        'action': function(email) {
          self.send('resendEmail', email);
        },
        'type': 'resend',
        'isActionColumn': true
      }
    };

    var listOfCols = ['created_ts', 'reason_sent', 'subject','resendEmail'];
    var listOfMobileCols = ['created_ts', 'reason_sent', 'subject', 'resendEmail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (!loggedInUserCanEditViewedUser) {
        colsArray.removeObject(columns.resendEmail);
      }
    }

  }),


  // Actions
  actions: {
    resendEmail: function(log_email) {
      var self = this;
      var parent = this.get('parent');
      var session = this.get('session');
      pretty_confirm('Are you sure you want to re-send this email?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/logemails/' + log_email.id + '/resend',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          parent.send('gridNeedsRefresh', self);
          noty({
            text: 'Resend email successful!'
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Send email failed!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
  }

});
