import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  postback_statuses: [{
    'id': '1',
    'title': 'New'
  }, {
    'id': '2',
    'title': 'Error'
  }, {
    'id': '3',
    'title': 'Success'
  }, {
    'id': '4',
    'title': 'Skipped'
  }],
  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      var t_types = self.get('postback_statuses');
      var t_type = t_types.filter(function(item) {
        return String(item.id) === String(query.id);
      });
      if (typeof t_type !== 'undefined' && t_type.length === 1) {
        resolve({
          postbackstatus: t_type.get('firstObject')
        });
      } else {
        resolve({
          postbackstatus: {
            'id': '0',
            'title': 'Unknown'
          }
        });
      }
    });
  },
  findAll: function() {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      resolve({
        postbackstatuses: self.get('postback_statuses')
      });
    });
  }
});