import jQuery from 'jquery';

export default {
  name: 'check-sigpad',
  after: 'domain-branding',

  initialize: function(app) {
    // See if signature pad is attached
    jQuery.ajax({
      url: 'https://tablet.sigwebtablet.com:47290/SigWeb/Version',
      type: 'GET',
      timeout: 5000
    }).then(function(version) {
      console.log('Signature pad support available');
      console.log('Loaded sigpad js script: ' + version);
      window.sessionStorage.sigpad = true;
      app.advanceReadiness();
    }, function() {
      console.log('Signature pad support not available');
      window.sessionStorage.sigpad = false;
      app.advanceReadiness();
    });
  }
};
