import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/roles/view', {
      controller: 'admin/roles/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authrole: store.createRecord('admin_authrole'),
      userTypes: store.findAll('usertype'),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('authrole', models.authrole);
    controller.set('userTypes', models.userTypes);
    controller.set('isEditing', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-authroles-delete'),
      canPost: this.get('session.authenticated.resources.v2admin-authroles-post'),
      canPut: this.get('session.authenticated.resources.v2admin-authroles-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Role',
      route_name: 'admin.roles.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Role');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Roles',
      link: 'admin.roles.index'
    },  {
      title: 'Add Role',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
