import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      terminal: store.queryRecord('admin_terminal', {
        id: params.view_id,
        expand: 'location,created_user,terminal_application,terminal_cvm,terminal_manufacturer',
        active: '0,1'
      }),
      terminalapplications: store.findAll('terminalapplication'),
      terminalcvms: store.query('terminalcvm', {
        expand: 'terminal_manufacturer',
        page_size: '500'
      }),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },

  afterModel: function(models, transition) {
    return this.get('notFound').check(models.terminal, transition, 'Terminal', this);
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var terminal = models.terminal;
    controller.set('terminal', terminal);
    controller.set('terminalapplications', models.terminalapplications);
    controller.set('terminalcvms', models.terminalcvms);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);
    controller.set('page_size', this.get('applicationData.page_size'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Terminal ● ' + terminal.get('title'),
      route_name: 'admin.terminals.view',
      route_id: terminal.get('id')
    });
    controller.set('pageTitle', 'View Terminal');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terminals',
      link: 'admin.terminals.index'
    }, {
      title: terminal.get('title'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      this.controller.set('isTransitioning', true);
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
