import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      recurring: store.queryRecord('recurring', {
        id: params.recurring_id,
        expand: 'tags,next_run_date_min,next_run_date_max,location,product_transaction,account_vault,created_user,transactions,payment_schedule',
        active: '0,1'
      })
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    var store = this.get('store');
    var session = this.get('session');
    const contact = models.contact;

    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: contact.get('id'),
          page_size: '500'
        }).then(function(data) {
          models.accounts = data;
        });
      } else {
        return models.accounts = [];
      }
    };

    return Ember.RSVP.Promise.all([
      self.get('notFound').check(models.contact, transition, 'Contact', self),
      self.get('notFound').check(models.recurring, transition, 'Recurring', self),
    ]).then(function() {
      return Ember.RSVP.hash({
        locationinfos: store.query('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1',
        }).then(function(data) {
          models.locationinfos = data;
        }),
        accounts: getAccounts(),
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('accounts', models.accounts);
    controller.set('recurring', models.recurring);
    controller.set('locationinfos', models.locationinfos);
    controller.set('locationinfo', locationinfo);

    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.recurring.get('id'),
        type: 'recurring',
        attributes: {
          terms_agree: true
        }
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● View Recurring Billing',
      route_name: 'contacts.recurrings-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.recurring.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Recurring Billings',
      link: 'contacts.recurrings-index',
      record: models.contact.get('id')
    }, {
      title: 'View Recurring Billing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.controller.get('recurring');
      if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
