import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  unsavedInfo: Ember.inject.service('unsaved-info'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    if (session.get('authenticated.isSupport')) {
      return Ember.RSVP.hash({
        location: store.queryRecord('admin-location', {
          id: params.id,
          expand: 'created_user,parent,branding_domain,product_transactions,developer_company_id,developer_company',
        })
      });
    } else {
      return Ember.RSVP.hash({
        location: store.queryRecord('location', {
          id: params.id,
          expand: 'created_user,parent,branding_domain,product_transactions',
        })
      });
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('producttransactions', models.location.get('product_transactions'));
    var product_transactions = models.location.get('product_transactions');
    controller.set('cc_products', product_transactions.sortBy('title').filter(function(pt) {
      return pt.get('payment_method') === 'cc';
    }));
    controller.set('ach_products', product_transactions.sortBy('title').filter(function(pt) {
      return pt.get('payment_method') === 'ach';
    }));

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-locations-delete'),
      canPost: this.get('session.authenticated.resources.v2-locations-post'),
      canPut: this.get('session.authenticated.resources.v2-locations-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● General Info',
      route_name: 'locations.view',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    didTransition: function() {
      // This is needed when transitioning from a modal like the tree view
      this.send('closeModal');
      return true;
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.location, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

});
