import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  user_types: [{
    'id': '0',
    'title': 'All Users'
  }, {
    'id': '100',
    'title': 'Contact User'
  }, {
    'id': '200',
    'title': 'Location User'
  }, {
    'id': '250',
    'title': 'Location Admin'
  }, {
    'id': '300',
    'title': 'Support Level 1'
  }, {
    'id': '400',
    'title': 'Support Level 2'
  }, {
    'id': '500',
    'title': 'Support Level 3'
  }, {
    'id': '600',
    'title': 'Support Level 4'
  }, {
    'id': '700',
    'title': 'Support Level 5'
  }, {
    'id': '800',
    'title': 'Support Level 6'
  }, {
    'id': '900',
    'title': 'Support Level 7'
  }, {
    'id': '1000',
    'title': 'Admin'
  }],
  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      var t_types = self.get('user_types');
      var t_type = t_types.filter(function(item) {
        return String(item.id) === String(query.id);
      });
      if (typeof t_type !== 'undefined' && t_type.length === 1) {
        resolve({
          usertype: t_type.get('firstObject')
        });
      } else {
        resolve({
          usertype: {
            'id': '0',
            'title': 'All Users'
          }
        });
      }
    });
  },
  findAll: function() {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      resolve({
        usertypes: self.get('user_types')
      });
    });
  }
});
