import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by this controller
  sessionService: Ember.inject.service('session'),
  vtcController: Ember.inject.controller('components/modals/virtualterminalcontact'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),

  // Set controller properties
  isContactsDashboard: true,
  isLoading: false,
  accounts: null,
  showTransactions: Ember.computed('locationinfo.id', 'locationinfo.product_transactions.[]', function() {
    var locationinfo = this.get('locationinfo');
    if (!locationinfo) {
      return;
    }
    return locationinfo.get('product_transactions.length') > 0 ? true : false;
  }),

  products: Ember.computed('locationinfo.product_transactions.@each', function() {
    return this.get('locationinfo.product_transactions');
  }),

  owned_accounts: Ember.computed('accounts.[]', function () {
    const contact_id = this.get('contact.id');
    const accounts = this.get('accounts');

    if (!accounts) {
      return [];
    }

    return accounts.filter(account => account.get('contact_id') === contact_id);
  }),

  needsRefresh: '',
  needsRefreshObserve: Ember.observer('needsRefresh', function() {
    this.send('updateTransactions');
  }),

  // Functions
  getSignature: function(model, type) {
    var controller = this.get('authagreementController');
    controller.set('authagreementType', null);
    controller.set('model', null);
    controller.set('contact', null);
    controller.set('location', null);
    controller.set('product', null);
    var location = this.get('locationinfo');
    var products = location.get('product_transactions');
    var modelType = null;
    var product = null;
    if(type === 'account') {
      modelType = 'account';
      product = payment_method === 'cc' ? location.get('defaultProductCC') : location.get('defaultProductACH');
      if(model.get('payment_method') === 'ach') {
        var routings = this.get('store').query('routingnumber', {
          routing: model.get('routing'),
          page_size: 1
        });
        routings.then(function(result) {
          controller.set('routing', result.get('firstObject'));
        });
      }
    } else if(type === 'recurring') {
      modelType = 'recurring';
      product = products.filter(function(prod) {
        return prod.get('id') === model.get('product_transaction_id');
      }).get('firstObject');
    }
    var payment_method = model.get('payment_method');
    controller.set('model', model);
    controller.set('location', location);
    controller.set('product', product);
    controller.set('contact', this.get('contact'));
    controller.set('authagreementType', type);

    this.send('openModal', 'components/modals/authagreement');
  },
  launchvt: function(trans_type, account) {
    var vtcController = this.get('vtcController');
    var locationinfo = this.get('locationinfo');

    vtcController.set('contact', this.get('contact'));
    vtcController.set('action', trans_type);
    vtcController.set('modal_title', trans_type.capitalize() + ' Transaction');
    vtcController.set('account', account);
    vtcController.set('disable_deposit_account', false);
    vtcController.set('disable_tags', false);
    vtcController.set('hide_transaction_action', false);
    vtcController.set('hide_lodging_fields', false);
    vtcController.set('show_contact_name', true);
    vtcController.set('route', 'contact');

    var locations = Ember.A();
    locations.pushObject(locationinfo);
    vtcController.set('locations', locations);
    this.send('openModal', 'components/modals/virtualterminalcontact');
  },

  // Actions
  actions: {
    launchModal: function(model, modal, other) {
      if(modal === 'virtualterminal') {
        this.launchvt(other, model);
      } else if(modal === 'signature') {
        this.getSignature(model, other);
      }
    },
    deleteAccount: function(account) {
      if (account.get('has_recurring')) {
        return;
      }
      var self = this;
      pretty_confirm('Are you sure you want to delete this Account Vault?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        account.destroyRecord().then(function() {
          account.unloadRecord();
          noty({
            text: 'Account Vault deleted successfully!'
          });
          self.set('isLoading', false);
        }, function(data) {
          var errorMessage = 'Error deleting Account Vault!';
          if(data.errors){
            data.errors.forEach(function(err){
              errorMessage += '<br>' + err.detail + '.';
            });
          }
          account.rollbackAttributes();
          noty({
            text: errorMessage,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    deleteQuickInvoice: function(quickinvoice) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Quick Invoice?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        quickinvoice.deleteRecord();
        quickinvoice.save().then(function() {
          noty({
            text: 'Quick Invoice deleted successfully!'
          });
          quickinvoice.unloadRecord();
          self.send('updateQuickinvoices');
        }, function() {
          quickinvoice.rollbackAttributes();
          noty({
            text: 'Error deleting Quick Invoice!',
            type: 'error'
          });
        });
      });
    },
    deleteRecurring: function(recurring) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Recurring Billing?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        recurring.destroyRecord().then(function() {
          recurring.unloadRecord();
          noty({
            text: 'Recurring Billing deleted successfully!'
          });
          self.send('updateAccounts');
          self.set('isLoading', false);
        }, function() {
          recurring.rollbackAttributes();
          noty({
            text: 'Error deleting Recurring Billing!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    showTrans: function(transaction) {
      var locationinfo = this.get('locationinfo');
      this.send('showTransDetail', transaction, locationinfo);
    },
    showQuickInvoice: function(quickinvoice, action) {
      var isEditing = action === 'edit';
      this.transitionToRoute('contacts.quickinvoices-view', this.get('contact.id'), quickinvoice.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    },
    updateAccounts: function() {
      var session = this.get('session');
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        var self = this;

        self.set('accounts', null);
        var accounts = self.get('store').query('accountvault', {
          expand: 'created_user,signature,contact,location',
          contact_id: self.get('contact.id'),
          page_size: '5',
          sort: 'title'
        });
        accounts.then(function() {
          self.set('accounts', accounts);
        });
      }
    },
    updateQuickinvoices: function() {
      var hasPriv = this.get('session.authenticated.resources.v2-quickinvoices-get');
      if (hasPriv) {
        var self = this;
        self.set('isLoading', true);
        self.set('quickinvoices', null);
        var quickinvoices = self.get('store').query('quickinvoice', {
          contact_id: self.get('contact.id'),
          sort: '-due_date',
          page_size: '5'
        });
        quickinvoices.then(function() {
          self.set('quickinvoices', quickinvoices);
          self.set('isLoading', false);
        }, function() {
          self.set('isLoading', false);
        });
      } else {
        this.set('quickinvoices', null);
      }
    },
    updateRecurrings: function() {
      var self = this;
      self.set('recurrings', null);
      if(this.get('session.authenticated.resources.v2-recurrings-get')) {
        var recurrings = self.get('store').query('recurring', {
          expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,contact',
          contact_id: self.get('contact.id'),
          sort: 'next_run_date',
          page_size: '5',
          active: 1
        });
        recurrings.then(function() {
          self.set('recurrings', recurrings);
        });
      }
    },
    updateTransactions: function() {
      var self = this;
      self.set('transactions', null);
      var transactions = self.get('store').query('transaction', {
        expand: 'contact.id,contact.first_name,contact.last_name,contact.email,surcharge.id,surcharge.surcharge_amount,created_user.id,created_user.username,tags,status,type',
        contact_id: self.get('contact.id'),
        page_size: '5',
        sort: '-created_ts'
      });
      transactions.then(function() {
        self.set('transactions', transactions);
      });
    },
    viewAccount: function(account, action) {
      var isEditing = action === 'edit';
      this.transitionToRoute('contacts.accounts-view', this.get('contact.id'), account.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    },
    viewRecurring: function(recurring, action) {
      var isEditing = action === 'edit';
      this.transitionToRoute('contacts.recurrings-view', this.get('contact.id'), recurring.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    },
    createRecurring: function(account) {
      this.transitionToRoute('contacts.recurrings-add', this.get('contact.id')).then(function(Route) {
        Route.controller.set('force_account', account);
      });
    }
  }
});
