import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'location_id',
    'title',
    'filter_time'
  ],
  columns: Ember.computed(function() {
    var self = this;
    var session = this.get('session');
    var cols = [{
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(productbillinggroup){
          self.send('view', productbillinggroup);
        },
        'actionId': 'productbillinggroup_title_view_link',
        'filter': {
          type: 'text'
        }
      }, {
        'name': 'location_id',
        'title': 'Location Name',
        'type': 'string',
        'sortable': true,
        'action': function(productbillinggroup) {
          self.transitionToRoute('locations.view', productbillinggroup.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'filter': {
          type: 'typeahead',
          style: 'min-width: 200px',
          modelName: 'location',
          multi: true,
          searchBy: 'name',
          sortBy: 'name',
          optionLabelPath: 'titleWithAccountNumber',
          fields: 'id,name,account_number',
          field: 'location_id'
        }
      }, {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false
        }
      }, {
        'name': 'id',
        'title': '',
        'action': function(productbillinggroup){
          self.send('view', productbillinggroup);
        },
        'actionId': 'productbillinggroup_view_link',
        'type': 'view'
      }];
      if(this.get('session.authenticated.resources.v2-productbillinggroups-delete')){
        cols.pushObject({
          'name': 'id',
          'title': '',
          'action': function(productbillinggroup){
            self.send('delete', productbillinggroup);
          },
          'type': 'delete'
        });
      }
      return cols;
  }),

  // Actions
  actions: {
    delete: function(productbillinggroup) {
      var self = this;
      pretty_confirm("Are you sure want to delete this Product Billing Group?", function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);

        productbillinggroup.deleteRecord();
        productbillinggroup.save().then(
          function() {
            noty({
              text: 'Product Billing Group deleted successfully!'
            });
            self.send('gridNeedsRefresh', self);
            self.set('isLoading', false);
          },
          function(data) {
            var error = '';
            if(data.errors){
              data.errors.forEach(function(err){
                error += '<br>' + err.detail;
              });
            }
            productbillinggroup.rollbackAttributes();
            noty({
              text: 'Error deleting Product Billing Group!' + error,
              type: 'error',
              timeout: 10000
            });
            self.set('isLoading', false);
          }
        );
      });
    },
    view: function(productbillinggroup){
      this.transitionToRoute('admin.productbillinggroups.view', productbillinggroup.get('id'));
    }
  }
});
