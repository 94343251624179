import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({
  session: Ember.inject.service(),

  tagName: 'tr',

  // Events
  didInsertElement: function() {
    var item = this.get('item');
    var oneTimeBillingItems = this.get('oneTimeBillingItems');
    var oneitembillinggroupitem = oneTimeBillingItems.filter(function(o) {
      return o.get('item_id') === item.get('item_id');
    }).get('firstObject');
    this.set('oneitembillinggroupitem', oneitembillinggroupitem);
  },

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('item.errors.cost', function() {
    this.set('oneTimeBillingItemErrorMessages', this.get('item.errors.messages'));
  }),

  // Computed Properties

  cost: Ember.computed('item', function() {
    return this.get('item.cost');
  }),
  fieldId: Ember.computed('item.item_id', function() {
    return this.get('item.item_id') + '-billinginput';
  }),
  isFee: Ember.computed('item', function() {
    return this.idContains('fee');
  }),
  isRate: Ember.computed('item', function() {
    return this.idContains('rate');
  }),
  isIncluded: Ember.computed('item', function () {
    return this.idContains('included');
  }),
  title: Ember.computed('item', function() {
    return this.get('item.item.title');
  }),

  // Functions
  idContains: function(txt) {
    var id = this.get('item.item.id').toLowerCase();
    return id.indexOf(txt) !== -1;
  },
  actions: {
    cancelBillingItem: function() {
      var productitem = this.get('item');
      productitem.rollbackAttributes();
      this.set('isEditing', false);
      this.set('isEditingBillingItem', false);
    },
    deleteBillingItem: function(productitem) {
      var self = this;
      self.set('isEditingBillingItem', true);
      pretty_confirm('Are you sure want to delete this billing item?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        productitem.deleteRecord();
        productitem.save().then(function() {
          noty({
            text: 'Billing Item deleted successfully!'
          });
          self.refreshData();
          self.set('isLoading', false);
          self.set('isEditingBillingItem', false);
        }, function() {
          productitem.rollbackAttributes();
          noty({
            text: 'Error deleting Billing Item!',
            type: 'error'
          });
          self.set('isLoading', false);
          self.set('isEditingBillingItem', false);
        });
      });
      self.set('isEditingBillingItem', false);
    },
    editBillingItem: function() {
      this.set('isEditing', true);
      this.set('isEditingBillingItem', true);
      var fieldId = this.get('fieldId');
      setTimeout(function() {
        jQuery('#' + fieldId).focus().select();
      }, 1);
    },
    saveBillingItem: function() {
      var productitem = this.get('item');
      var self = this;
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      self.set('isEditingBillingItem', true);
      productitem.save().then(function() {
        noty({
          text: 'Billing Item saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.set('isEditingBillingItem', false);
        self.refreshData();
      }, function() {
        noty({
          text: 'Error saving Billing Item!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.set('isEditingBillingItem', true);
      });
    },
  }
});
