import Ember from 'ember';
import jQuery from 'jquery';

var get = Ember.get;

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  allSelected: null,
  allDeselected: null,
  multi: null,

  isGrid: Ember.computed('context', function() {
    return this.get('context') === 'grid';
  }),

  actions: {},
  chkBoxId: Ember.computed(function() {
    var id = 'chk-' + this.get('name') + '_' + this.get('item.id') + '-' + this.get('idSuffix');
    return id.replaceAll(' ', '_');
  }),
  label: Ember.computed(function() {
    var item = this.get('item');
    return get(item, this.get('labelValue'));
  }),
  selectedChanged: Ember.observer('selected', function() {
    var self = this;
    var item = this.get('item');
    var key = get(item, this.get('labelKey'));

    if (!this.get('multi')) {
      jQuery('#' + self.get('chkBoxId')).parent().parent().parent().parent().parent().find('li').removeClass('active');
      jQuery('#' + self.get('chkBoxId')).parent().parent().parent().addClass('active');
    }
    if (!get(item, 'grouplabel')) {
      if (this.get('selected')) {
        this.adjustPlaceholderValue(key, 'add');
      } else {
        this.adjustPlaceholderValue(key, 'remove');
        // Set the select all checkbox
        this.set('allSelected', false);
      }
    }
    return;
  }),
  allSelectedChanged: Ember.observer('allSelected', function() {
    if (this.get('allSelected')) {
      this.set('selected', true);
    }
  }),
  allDeselectedChanged: Ember.observer('allDeselected', function() {
    if (this.get('allDeselected')) {
      this.set('selected', false);
    }
  }),
});
