import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsTransactions: true,

  // Set controller params
  sort: '-created_ts',
  page: 1,
  page_size: '15',
  created_ts: 'today',
  isRefreshing: true,
  batchCloseStatus: null,
  needsRefresh: '',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'auth_amount',
    'auth_code',
    'batch',
    'billing_phone',
    'billing_zip',
    'clerk_number',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'description',
    'is_accountvault',
    'is_recurring',
    'last_four',
    'needsRefresh',
    'order_num',
    'product_transaction_id',
    'quick_invoice_id',
    'room_num',
    'status_id',
    'tags',
    'tax',
    'terminal_id',
    'transaction_amount',
    'type_id',
  ],
  columns: Ember.computed('transactions', 'location', 'isMobileSize', function() {
    var self = this;
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Transaction<br>Date',
        'type': 'shortdate',
        'sortable': true,
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_full_name: {
        'name': 'contact.full_name',
        'title': 'Contact<br>Name',
        'type': 'string',
        'action': function(transaction) {
          self.transitionToRoute('contacts.dashboard', transaction.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
      },
      auth_amount: {
        'name': 'auth_amount',
        'title': 'Auth<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      transaction_amount: {
        'name': 'transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        },
        'total': true,
        'totalValue': Ember.Object.extend({
          data: null,
          value: Ember.computed('gridData', function() {
            return '$ ' + String(this.get('gridData.meta.report.pageTotalTransactionAmount')).currency(2) + ' of<br>$ ' + String(this.get('gridData.meta.report.totalTransactionAmount')).currency(2) + ' Total';
          })
        }).create()
      },
      tip_amount: {
        'name': 'tip_amount',
        'title': 'Tip<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      surcharge_amount: {
        'name': 'surcharge.surcharge_amount',
        'title': 'Surcharge<br>Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
      },
      tax: {
        'name': 'tax',
        'title': 'Sales<br>Tax',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right nobr',
        'filter': {
          type: 'text'
        }
      },
      billing_zip: {
        'name': 'billing_zip',
        'title': 'Billing<br>Zip',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      room_num: {
        'name': 'room_num',
        'title': 'Room<br>Num',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      billing_phone: {
        'name': 'billing_phone',
        'title': 'Billing<br>Phone',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      clerk_number: {
        'name': 'clerk_number',
        'title': 'Clerk<br>Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      tags: {
        'name': 'tags',
        'title': 'Tags',
        'type': 'array',
        'sortable': false,
        'filter': {
          type: 'dropdown',
          items: 'tagList',
          labelKey: 'title',
          multi: true,
          showAllSelect: true
        }
      },
      batch: {
        'name': 'batch',
        'title': 'Batch',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true
        }
      },
      created_user_id: {
        'name': 'created_user_id',
        'title': 'Created By',
        'type': 'string',
        'sortable': false,
        'action': function(transaction) {
          self.transitionToRoute('locations.users-view', transaction.get('location.id'), transaction.get('created_user.id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-users-get'],
        'filter': {
          type: 'dropdown',
          items: 'users',
          multi: true,
          showAllSelect: true
        }
      },
      type_id: {
        'name': 'type_id',
        'title': 'Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'types',
          multi: true,
          showAllSelect: true
        }
      },
      status_id: {
        'name': 'status_id',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'statuses',
          multi: true,
          showAllSelect: true
        }
      },
      auth_code: {
        'name': 'auth_code',
        'title': 'Auth<br>Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      is_recurring: {
        'name': 'is_recurring',
        'title': 'Recurring<br>Billing',
        'type': 'redo',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('locations.recurrings-view', transaction.get('location_id'), transaction.get('recurring_id'));
        },
        'actionpriv': 'v2-recurrings-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      is_accountvault: {
        'name': 'is_accountvault',
        'title': 'Account<br>Vault',
        'type': 'shield',
        'sortable': true,
        'align': 'text-center',
        'action': function(transaction) {
          self.transitionToRoute('locations.accounts-view', transaction.get('location_id'), transaction.get('account_vault_id'));
        },
        'actionpriv': 'v2-accountvaults-get',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      order_num: {
        'name': 'order_num',
        'title': 'Order<br>Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      terminal_id: {
        'name': 'terminal_id',
        'title': 'Terminal',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.terminals-view', transaction.get('location_id'), transaction.get('terminal_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-terminals-get'],
        'filter': {
          type: 'dropdown',
          items: 'location.terminals',
          multi: true,
          showAllSelect: true
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.viewproducttransaction', transaction.get('location_id'), transaction.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          showAllSelect: true,
          notuniqbytitle: true
        }
      },
      quick_invoice_id: {
        'name': 'quick_invoice.title',
        'title': 'Quick<br>Invoice',
        'type': 'string',
        'sortable': true,
        'action': function(transaction) {
          self.transitionToRoute('locations.quickinvoices-view', transaction.get('location_id'), transaction.get('quick_invoice_id'));
        },
        'actionpriv': 'v2-quickinvoices-get',
        'filter': {
          type: 'text',
          field: 'quick_invoice_id'
        }
      },
      show_detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(transaction) {
          self.send('showDetail', transaction);
        },
        'actionId': 'locationstransactions_report_fulldetail_link',
        'sortable': false,
        'isActionColumn': true
      },

      show_trans: {
        'name': 'id',
        'title': '',
        'type': 'receipt',
        'action': function(transaction) {
          self.send('showTrans', transaction);
        },
        'actionId': 'locationstransactions_report_receipt_link',
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['show_trans', 'show_detail', 'created_ts', 'account_holder_name', 'contact_full_name', 'auth_amount', 'transaction_amount', 'tip_amount', 'surcharge_amount', 'tax',  'billing_zip', 'billing_phone', 'clerk_number',  'room_num', 'description', 'tags', 'batch', 'account_type', 'created_user_id', 'type_id', 'status_id', 'auth_code', 'last_four', 'is_recurring', 'is_accountvault', 'order_num', 'terminal_id', 'quick_invoice_id', 'product_transaction_id'];
    var listOfMobileCols = ['created_ts', 'transaction_amount', 'status_id', 'show_trans', 'show_detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      var location = self.get('location');
      var products = self.get('location.product_transactions');
      var billing_phone = products.filter(function(product) {
        return (product.get('vt_billing_phone'));
      });
      var clerk_number = products.filter(function(product) {
        return (product.get('vt_clerk_number'));
      });
      var terminals = self.get('location.terminals');
      if(!location.get('hasProductTip')){
        colsArray.removeObject(columns.tip_amount);
      }
      if(!location.get('hasSurcharge')){
        colsArray.removeObject(columns.surcharge_amount);
      }
      if(!location.get('hasTax')){
        colsArray.removeObject(columns.tax);
        colsArray.removeObject(columns.billing_zip);
      }
      if(!location.get('hasLodging')){
        colsArray.removeObject(columns.room_num);
        colsArray.removeObject(columns.billing_zip);
      }
      if ((billing_phone && billing_phone.get('length') < 1) || !billing_phone) {
        colsArray.removeObject(columns.billing_phone);
      }
      if ((clerk_number && clerk_number.get('length') < 1) || !clerk_number) {
        colsArray.removeObject(columns.clerk_number);
      }
      if(!location.get('hasVTOrderNum')){
        colsArray.removeObject(columns.order_num);
      }
      if ((terminals && terminals.get('length') < 1) || !terminals) {
        colsArray.removeObject(columns.terminal_id);
      }
      if (location.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
      if (!location.get('hasQuickInvoice')) {
        colsArray.removeObject(columns.quick_invoice_id);
      }
    }
  }),

  // Actions
  actions: {
    showDetail: function(trans) {
      this.transitionToRoute('transactiondetailfull', trans.get('id'));
    },
    closebatch: function() {
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      this.set('batchCloseStatus', 'Getting last open batch...');

      var self = this;
      var location = this.get('location');
      this.get('store').queryRecord('transaction', {
        location_id: location.get('id'),
        sort: '-created_ts',
        status_id: '101'
      }).then(function(trans) {
        if (!Ember.isEmpty('trans')) {
          if (trans.get('length') === 1) {
            var batch = trans.get('batch');
            if (batch) {
              self.set('batchCloseStatus', 'Closing batch # ' + batch);
              var transaction = self.get('store').createRecord('transaction', {
                action: 'settle',
                payment_method: 'cc',
                location_id: location.get('id'),
                batch: batch
              });
              transaction.save().then(function(data) {
                var error = data.get('serviceErrors');
                if (error) {
                  noty({
                    text: 'Error closing batch!',
                    type: 'error',
                    timeout: '5000'
                  });
                  self.set('batchCloseStatus', 'Error: ' + error);
                } else {
                  noty({
                    text: 'Batch closed successfully!',
                    timeout: '5000'
                  });
                  self.set('batchCloseStatus', 'Batch closed successfully!');
                }
                self.set('isLoading', false);
              }, function() {
                noty({
                  text: 'Error closing batch!',
                  type: 'error',
                  timeout: '5000'
                });
                self.set('batchCloseStatus', 'Error closing batch.');
                self.set('isLoading', false);
              });
              self.set('isLoading', false);
            } else {
              self.set('batchCloseStatus', 'Unable to determine last open batch.');
              self.set('isLoading', false);
            }
          } else {
            self.set('batchCloseStatus', 'Unable to get last open batch.');
            self.set('isLoading', false);
          }
        } else {
          self.set('batchCloseStatus', 'Unable to get last open batch.');
          self.set('isLoading', false);
        }
      }, function() {
        self.set('batchCloseStatus', 'Unable to get last open batch.');
        self.set('isLoading', false);
      });
    },
    showTrans: function(transaction) {
      this.send('showTransDetail', transaction, this.get('location'), this.get('tagList'));
    },
  }
});
