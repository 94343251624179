import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  location_created_ts: 'last month',
  location_created_user_id: null,
  parent_location_id: null,
  portfolio_id: null,
  product_transaction_created_user_id: null,
  sort: '-location_created_ts',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'location_created_ts',
    'location_created_ts_from',
    'location_created_ts_to',
    'location_created_user_id',
    'location_developer_company',
    'parent_location_id',
    'portfolio_id',
    'portfolio_title',
    'product_transaction_created_ts',
    'product_transaction_created_ts_to',
    'product_transaction_created_ts_from',
    'product_transaction_created_user_id',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var columns = {
      portfolio_title: {
        'name': 'portfolio_title',
        'title': 'Portfolio Title',
        'type': 'string',
        'sortable': true
      },
      developer_company: {
        'name': 'developer_company',
        'title': 'Developer Company',
        'type': 'string',
        'sortable': true,
      },
      locations: {
        'name': 'locations',
        'title': 'Locations',
        'type': 'number',
        'sortable': true,
      },
      cc_products_developer: {
        'name': 'cc_products_developer',
        'title': 'CC Products Developer',
        'type': 'number',
        'sortable': true
      },
      ach_products_developer: {
        'name': 'ach_products_developer',
        'title': 'ACH Products Developer',
        'type': 'number',
        'sortable': true
      },
      cc_products_portfolio_total: {
        'name': 'cc_products_portfolio_total',
        'title': 'CC Products Portfolio Total',
        'type': 'number',
        'sortable': true
      },
      ach_products_portfolio_total: {
        'name': 'ach_products_portfolio_total',
        'title': 'ACH Products Portfolio Total',
        'type': 'number',
        'sortable': true
      },
      detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'link': window.UiENV.APP.FULL_URL + 'portfoliostatements/{0}/detailreport',
        'linkid': ['id'],
        'isActionColumn': true
      },
      summary: {
        'name': 'id',
        'title': '',
        'type': 'summary',
        'link': window.UiENV.APP.FULL_URL + 'portfoliostatements/{0}/summaryreport',
        'linkid': ['id'],
        'isActionColumn': true
      }
    };

    var listOfCols = ['portfolio_title', 'developer_company', 'locations', 'cc_products_developer', 'ach_products_developer', 'cc_products_portfolio_total', 'ach_products_portfolio_total', 'detail', 'summary'];
    var listOfMobileCols = ['portfolio_title', 'developer_company', 'locations', 'cc_products_developer', 'ach_products_developer', 'cc_products_portfolio_total', 'ach_products_portfolio_total', 'detail', 'summary'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    updateReport: function(data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof data) !== 'object') {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function(item) {
        props[item.name.replace('.', '-')] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
    },
    updateParentLocation: function(data) {
      var self = this;
      var parents = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    updateLocationUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10'
      });
      users.then(function(result) {
        self.set('locationusers', result);
      });
    },
    updateProductTransactionUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10'
      });
      users.then(function(result) {
        self.set('producttransactionusers', result);
      });
    },
    updatePortfolio: function(data) {
      var self = this;
      var portfolios = this.get('store').query('portfolio', {
        title: data,
        sort: 'title',
        page_size: '10'
      });
      portfolios.then(function(result) {
        self.set('portfolios', result);
      });
    },
  }
});
