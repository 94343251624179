import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/producttransactionform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions,product_accountvault',
      }),
      productachstores: store.findAll('productachstore')
    });
  },

  afterModel: function(models, transition) {
    var self = this;
    return Ember.RSVP.hash({
      producttransaction: self.get('store').queryRecord('producttransaction', {
        id: transition.to.params.product_id,
        active: '0,1',
        expand: 'surcharge,product_ach_store,created_user,portfolio,sales_office,location_billing_account'
      }).then(function(data) {
        models.producttransaction = data;
      }),
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    controller.set('location', models.location);
    var producttransaction = models.producttransaction;
    controller.set('producttransaction', producttransaction);

    if (transition.to.queryParams.edit) {
      controller.set('isEditing', true);
    } else {
      controller.set('isEditing', false);
    }
    controller.set('isViewingL3', false);
    controller.set('isViewingPayfacData', false);
    controller.set('productachstores', models.productachstores);
    controller.set('isAddingTerminalID', false);
    controller.set('payaach_terminal_id', null);
    controller.set('payaTerminalsWithErrors', []);
    controller.set('cauEnabled', producttransaction.get('cau_account_number') && producttransaction.get('cau_subscribe_type_id')); // initially set checkbox for subscribe for account updates
    var payfac_data = producttransaction.get('processor_data.payfac_data');
    if(!payfac_data) {
      controller.set('producttransaction.processor_data.payfac_data', {});
    }

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-producttransactions-delete'),
      canPost: this.get('session.authenticated.resources.v2-producttransactions-post'),
      canPut: this.get('session.authenticated.resources.v2-producttransactions-put'),
      canActivate: this.get('session.authenticated.resources.v2-producttransactions-post-activate')
    });

    controller.set('billingAccountLocation', {
      id: producttransaction.get('location_billing_account.location_id'),
    });

    if (this.get('session.authenticated.resources.v2-locationbillingaccounts-get')) {
      controller.getLocationBillingAccounts(models.producttransaction.get('location_billing_account_id'));
    }
    if (this.get('session.authenticated.resources.v2-productbillinggroups-get')) {
      this.get('store').query('productbillinggroup', {
        id: models.producttransaction.get('product_billing_group_id')
      }).then(function(res) {
        controller.set('productbillinggroups', res);
      });
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + models.producttransaction.get('title'),
      route_name: 'locations.viewproducttransaction',
      route_id: models.location.get('id'),
      route_id_plus: models.producttransaction.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: models.producttransaction.get('title'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.producttransaction, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
