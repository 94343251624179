import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function verifyAction(params) {
    let [action, id] = params;

    if (action === "UNSUBSCRIBE PENDING" || action === "SUBSCRIBE PENDING") {
        return htmlSafe(`<td>${id}</td>`);
    }
    return htmlSafe(`<td>-</td>`);
}

export default helper(verifyAction);