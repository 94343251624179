import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,

  queryParams: [
    'id'
  ],
  params: null,
  showPay: false,
  showreceipt: false,
  isLoading: false,
  isRecurringNotification: true,

  termsAgreed: Ember.computed('terms_agree_verbiage', 'terms_agree', function() {
    return this.get('terms_agree_verbiage') ? this.get('terms_agree') : true;
  }),

  actions: {
    makePayment: function() {
      this.set('errorMessages', null);

      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var recurringnotification = this.get('recurringnotification');
      var transaction = {
        transaction: {
          payment_method: 'cc',
          transaction_amount: this.get('transaction.transaction_amount') || '',
          account_number: this.get('transaction.account_number') || '',
          exp_date: (this.get('transaction.exp_month') + this.get('transaction.exp_year')) || '',
          cvv: this.get('transaction.cvv') || '',
          terms_agree: this.get('terms_agree'),
          replace_account_vault: this.get('transaction.replace_account_vault')
        }
      };
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/recurringnotification?id=' + recurringnotification.id,
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'POST',
        data: JSON.stringify(transaction),
        contentType: 'application/json'
      }).then(function(response) {
        noty({
          text: 'Thank you for your payment!',
          timeout: '5000'
        });
        self.set('transaction.payment_id', response.recurringnotification.payment_transaction_id);
        self.set('showPay', false);
        self.set('showReceipt', true);
        self.set('isLoading', false);
      }, function(err) {
        self.send('setFormErrors', self, 'transaction', err);
        noty({
          text: 'There was an error processing your payment!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
      });

    }
  }
});
