import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  model: function(params) {
    return new Ember.RSVP.Promise(function(resolve) {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/recurringnotification?id=' + params.id,
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
        },
        type: 'GET'
      }).then(function(data) {
        resolve(data);
      }, function(err) {
        resolve({
          error: err
        });
      });
    });
  },

  setupController: function(controller, model) {
    // Set Controller Properties
    if (model.error && model.error.responseJSON) {
      controller.set('error_message', model.error.responseJSON.message);
      return;
    }
    if (model) {
      controller.set('recurringnotification', model.recurringnotification);
      controller.set('recurringnotification.statement_balance', model.recurringnotification.transaction_amount);
      controller.set('transaction', {
        transaction_amount: model.recurringnotification.transaction_amount,
        exp_month: moment().format('MM'),
        exp_year: moment().format('YY')
      });
      controller.set('showPay', !Boolean(model.recurringnotification.payment_transaction_id));
      controller.set('showPay', true);
      controller.set('terms_agree_verbiage', model.recurringnotification.receipt_vt_above_signature);
      controller.set('terms_agree', model.recurringnotification.receipt_vt_above_signature ? false : true);
    }
  },

 // Actions
  actions: {
    didTransition: function() {
      this.send('enteringRecurringDecline');
      return true;
    },
  }

});
