import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  'a-cert-fee-merchant-cost': DS.attr('string'),
  'a-cert-fee-net-cost': DS.attr('string'),
  'a-cert-fee-portfolio-cost': DS.attr('string'),
  'agent_code': DS.attr('string'),
  'developer_company': DS.attr('string'),
  'location_created_ts': DS.attr('ts'),
  'location_date_created': DS.attr('string'),
  'location_id': DS.attr('string'),
  'location_name': DS.attr('string'),
  'm-access-fee-merchant-cost': DS.attr('string'),
  'm-access-fee-net-cost': DS.attr('string'),
  'm-access-fee-portfolio-cost': DS.attr('string'),
  'm-ach-min-fee-merchant-cost': DS.attr('string'),
  'm-ach-min-fee-net-cost': DS.attr('string'),
  'm-ach-min-fee-portfolio-cost': DS.attr('string'),
  'o-cert-fee-first-month-merchant-cost': DS.attr('string'),
  'o-cert-fee-first-month-portfolio-cost': DS.attr('string'),
  'o-cert-fee-first-month-net-cost': DS.attr('string'),
  'p-ach-reject-fee-merchant-cost': DS.attr('string'),
  'p-ach-reject-fee-net-cost': DS.attr('string'),
  'p-ach-reject-fee-portfolio-cost': DS.attr('string'),
  'p-ach-ret-against-settle-acct-merchant-cost': DS.attr('string'),
  'p-ach-ret-against-settle-acct-net-cost': DS.attr('string'),
  'p-ach-ret-against-settle-acct-portfolio-cost': DS.attr('string'),
  'p-ach-unauthorized-fee-merchant-cost': DS.attr('string'),
  'p-ach-unauthorized-fee-net-cost': DS.attr('string'),
  'p-ach-unauthorized-fee-portfolio-cost': DS.attr('string'),
  'p-failure-to-notify-dda-change-merchant-cost': DS.attr('string'),
  'p-failure-to-notify-dda-change-net-cost': DS.attr('string'),
  'p-failure-to-notify-dda-change-portfolio-cost': DS.attr('string'),
  'p-trx-fee-cc-merchant-cost': DS.attr('string'),
  'p-trx-fee-cc-net-cost': DS.attr('string'),
  'p-trx-fee-cc-portfolio-cost': DS.attr('string'),
  'p-trx-rate-ach-merchant-cost': DS.attr('string'),
  'p-trx-rate-ach-net-cost': DS.attr('string'),
  'p-trx-rate-ach-portfolio-cost': DS.attr('string'),
  'p-trx-rate-cc-merchant-cost': DS.attr('string'),
  'p-trx-rate-cc-net-cost': DS.attr('string'),
  'p-trx-rate-cc-portfolio-cost': DS.attr('string'),
  'parent_location_name': DS.attr('string'),
  'parent_location_id': DS.attr('string'),
  'portfolio_title': DS.attr('string'),
  'product_transaction_industry_type': DS.attr('string'),
  'product_transaction_partner': DS.attr('string'),
  'product_transaction_payment_method': DS.attr('string'),
  'product_transaction_processor': DS.attr('string'),
  'product_transaction_title': DS.attr('string'),
});
