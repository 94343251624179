import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  classNames: ['emberTagInput'],
  classNameBindings: ['isDisabled:emberTagInput--readOnly'],

  // Events

  tagName: 'ul',

  init: function() {
    this._super(...arguments);
    this.set('itemList', null);
  },

  didInsertElement: function() {
    var self = this;
    self.set('runActions', false);
    self.set('searchValue', '');
    self.set('childHover', false);
    self.set('selectedValues', []);
    var initialName = this.get('initialName');
    if (initialName && this.get('multiple')) {
      initialName.forEach(function(name) {
        self.send('selectItem', Ember.Object.extend({
          title: name
        }).create(), null, true, true);
      });
    } else if (initialName && !this.get('multiple')) {
      self.send('selectItem', Ember.Object.extend({
        id: self.get('value'),
        title: initialName
      }).create(), null, true, true);
    }

    // Set actions to know when the child ul is mouseover
    jQuery(document).on('mouseover', '#' + self.get('thisId') + '_items', function() {
      self.set('childHover', true);
    });
    jQuery(document).on('mouseout', '#' + self.get('thisId') + '_items', function() {
      self.set('childHover', false);
    });
    Ember.run.later(function() {
      jQuery('#' + self.get('thisId')).removeClass('form-control');
    }, 1);

    self.set('runActions', true);
  },
  willDestroyElement: function() {
    var self = this;
    jQuery(document).off('mouseover', '#' + self.get('thisId') + '_items');
    jQuery(document).off('mouseout', '#' + self.get('thisId') + '_items');
    var typeAdheadOptions = jQuery('#' + self.get('thisId') + '_items').children();
    typeAdheadOptions.each(function(index, value) {
      value.parentElement.removeChild(value);
    });
  },

  click: function(e) {
    var hasClass = false;
    var classesToIgnoreWhenClicked = ['typeahead-clear-item', 'hand', 'emberTagInput-tag'];
    classesToIgnoreWhenClicked.forEach(function(c){
      if(e.target.classList.contains(c)){
        hasClass = true;
      }
    });
    if ((!this.get('isDisabled') && !hasClass) || this.get('selectedValues.length') === 0) {
      jQuery('#' + this.get('thisId')).focus();
    }
    e.stopPropagation();
  },
  focusIn: function() {
    this.set('isFocused', true);
    jQuery('#' + this.get('thisId')).parent().parent('.emberTagInput').addClass('typeahead-focus');
  },
  focusOut: function() {
    this.set('isFocused', false);
    jQuery('#' + this.get('thisId')).parent().parent('.emberTagInput').removeClass('typeahead-focus');
    if(!this.get('isFilter') || this.get('isMobileSize')) {
      return;
    } else {
      var data = {
        'filters': [{
          'name': this.get('filterProps.name'),
          'value': Ember.isArray(this.get('filterProps.value')) ? this.get('filterProps.value').join() : this.get('filterProps.value')
        }]
      };
      this.filterOnBlur(data);
    }
  },

  keyDown: function(e) {
    var self = this;
    var itemsEl = jQuery('#' + self.get('thisId') + '_items');
    var searchValue = this.get('searchValue');
    searchValue = searchValue ? searchValue.trim() : '';

    var itemList = this.get('itemList');
    var selectedValue = itemsEl.children('li.active').children('.dataValue').text().trim();
    var selectedTitle = itemsEl.children('li.active').children('.dataName').text().trim();
    var selectedIndex = itemsEl.children('li.active').children('.dataIndex').text().trim();
    switch (e.which) {
      case 13: // enter
        if (self.get('isNoData')) {
          itemsEl.hide();
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (itemList && itemList.get('length') && itemsEl.css('display') === 'block' && selectedValue && selectedTitle) {
          if (this.get('return_full_model')) {
            self.send('selectItem', itemList[selectedIndex]);
          } else {
            self.send('selectItem', Ember.Object.extend({
              id: selectedValue,
              title: selectedTitle
            }).create());
          }

          itemsEl.children('li.active').removeClass('active');
          itemsEl.hide();
        }
        e.preventDefault();
        e.stopPropagation();
        break;
      case 8: // backspace
        if (!searchValue || searchValue.length === 0) {
          self.set('runActions', false);
          // self.set('searchValue', '');
          // self.set('selectedName', '');
          itemsEl.children('li.active').removeClass('active');
          itemsEl.hide();
          self.set('runActions', true);
        }
        break;
      case 9: // tab
        if ((searchValue.length > 0 && itemsEl.css('display') === 'none') || self.get('isNoData') || searchValue.length === 0) {
          itemsEl.hide();
          return;
        }
        if (itemList && itemList.get('length') && itemsEl.css('display') === 'block' && selectedValue && selectedValue !== self.get('thisId') + '_nodata') {
          if (this.get('return_full_model')) {
            self.send('selectItem', itemList[selectedIndex]);
          } else {
            self.send('selectItem', Ember.Object.extend({
              id: selectedValue,
              title: selectedTitle
            }).create());
          }
          itemsEl.children('li.active').removeClass('active');
          itemsEl.hide();
          Ember.run.later(function() {
            var inputs = $(':focus').closest('form').find(':input');
            var thisId = self.get('thisId');
            var index = 0;
            inputs.each(function(i, input) {
              if (input.id === thisId) {
                index = i;
              }
            });
            inputs.eq(index + 1).focus();
          }, 1);
        } else if (itemsEl.children('li:first-child.dataValue').text().trim() !== self.get('thisId') + '_nodata' && !self.get('isNoData')) {
          itemsEl.children('li:first-child').addClass('active');
        }
        e.preventDefault();
        e.stopPropagation();
        break;
      case 38: // up
        itemsEl.children('li.active').removeClass('active').prev().addClass('active');
        e.preventDefault();
        e.stopPropagation();
        break;
      case 40: // down
        if (selectedValue) {
          itemsEl.children('li.active').removeClass('active').next().addClass('active');
        } else {
          itemsEl.children('li:first-child').addClass('active');
        }
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
        itemsEl.children('li.active').removeClass('active');
        break;
    }
  },

  // Computed Properties

  thisId: Ember.computed('id', function() {
    return this.get('name') + '_autocomplete';
  }),
  showTitleWithAccountNumber: Ember.computed('optionLabelPath', function() {
    return this.get('optionLabelPath') === 'titleWithAccountNumber' ? true : false;
  }),
  showContactTitleWithAccountNumberAndLocation: Ember.computed('optionLabelPath', function() {
    return this.get('optionLabelPath') === 'titleWithAccountNumberAndLocation' ? true : false;
  }),
  showQuickInvoiceTitleWithInvoiceNumber: Ember.computed('optionLabelPath', function() {
    return this.get('optionLabelPath') === 'titleWithInvoiceNumber' ? true : false;
  }),
  showRoutingWithBankName: Ember.computed('optionLabelPath', function() {
    return this.get('optionLabelPath') === 'titleWithBankName' ? true : false;
  }),
  pattern: Ember.computed('typeahead_type', function() {
    if (this.get('typeahead_type') === 'number') {
      return '[0-9]*';
    } else {
      return null;
    }
  }),
  isDisabled: Ember.computed('disableInput', 'multiple', 'selectedValues.[]', function() {
    var alreadySelectedAndNotMultiple = !this.get('multiple') && this.get('selectedValues.length') >= 1;
    return this.get('disableInput') || alreadySelectedAndNotMultiple ? 'disabled' : null;
  }),
  isNoData: Ember.computed('itemListOptions.[]', function() {
    var self = this;
    var items = this.get('itemListOptions');
    var noData = false;
    if (items) {
      items.map(function(item) {
        if (item.id === self.get('thisId') + '_nodata') {
          noData = true;
        }
      });
    }
    return noData;
  }),
  itemListOptions: Ember.computed('itemList.[]', 'selectedValues.[]', function() {
    var items = this.get('itemList');
    var selectedValues = this.get('selectedValues');
    if (items) {
      var tempItems = items.filter(function(item) {
        var alreadySelected = false;
        if (selectedValues) {
          selectedValues.map(function(value) {
            if (value.title === item.get('title')) {
              alreadySelected = true;
            }
          });
        }
        return !alreadySelected;
      });
      return tempItems.get('length') > 0 ? tempItems : [{
        id: this.get('thisId') + '_nodata',
        title: 'No Data',
        titleWithAccountNumber: 'No Data',
        titleWithInvoiceNumber: 'No Data',
        showRoutingWithBankName: 'No Data'
      }];
    } else {
      return [];
    }
  }),

  // Observers
  itemListObserver: Ember.observer('itemListOptions.[]', 'isNoData', function() {
    var self = this;
    var typeAdheadOptions = jQuery('#' + self.get('thisId') + '_items').children();
    var noActive = true;
    typeAdheadOptions.each(function(index, value) {
      if (value.classList.contains('active')) {
        noActive = false;
      }
    });
    var noData = this.get('isNoData');
    if (noActive && !noData) {
      Ember.run.later(function() {
        jQuery('#' + self.get('thisId') + '_items').children('li:first-child').addClass('active');
      }, 1);
    }
    var optionLabelPath = this.get('optionLabelPath') || 'title';
    var items = this.get('itemListOptions');
    var selectedValues = this.get('selectedValues');
    var value = this.get('value') && typeof this.get('value') === 'string' ? this.get('value').split(',') : this.get('value');
    if(value && (Ember.isArray(items) ? items.length : false) && (Ember.isArray(selectedValues) ? !selectedValues.length : true) && !this.get('isClearingItem')) {
      items.map(function(item){
        if(value.indexOf(item.id) !== -1) {
          self.send('selectItem', Ember.Object.extend({
            id: item.get('id'),
            title: item.get(optionLabelPath)
          }).create(), null, true, true);
        }
      });
    }
    var parentWidth = jQuery('#' + self.get('thisId') + '_items').parent().css('width');
    jQuery('#' + self.get('thisId') + '_items').css('width', parentWidth);
  }),
  updateSelection: Ember.observer('searchValue', function() {
    if (!this.get('runActions')) {
      return;
    }
    var searchValue = this.get('searchValue');
    if (searchValue && searchValue.length > 0 && searchValue.length < 3) {
      return;
    }
    if (!this.get('isDisabled')) {
      if (!searchValue || searchValue.length === 0) {
        jQuery('#' + this.get('thisId') + '_items').hide();
      } else if (searchValue) {
        jQuery('#' + this.get('thisId') + '_items').show();
        if(this.get('isFilter')) {
          this.action(searchValue.trim(), this.get('filterProps'));
        } else {
          this.action(searchValue.trim());
        }
      }
    }
  }),
  valueChanged: Ember.observer('value', function() {
    var self = this;
    var value = this.get('value');
    if (value === null || value === '') {
      jQuery('#' + this.get('thisId'))[0].value = '';
      this.set('selectedValues', []);
      this.set('value', null);
      Ember.run.later(function() {
        jQuery('#' + self.get('thisId')).removeClass('form-control');
      }, 1);
    } else {
      if (this.get('selectedValues.length') === 0) {
        var items = this.get('itemListOptions');
        if (!self.get('isNoData') && items) {
          items.map(function(item) {
            if (item.get('id') === value) {
              self.send('selectItem', Ember.Object.extend({
                id: item.get('id'),
                title: item.get('title')
              }).create(), null, true, true);
            }
          });
        }
      }
    }
  }),

  actions: {
    selectItem: function(item, addMethod, noSetValue, noFocus) {
      if (item.id === this.get('thisId') + '_nodata') {
        jQuery('#' + this.get('thisId') + '_items').hide();
        return;
      }
      var title = '';
      if (addMethod === 'clicked') {
        if (this.get('showTitleWithAccountNumber')) {
          title = item.get('titleWithAccountNumber');
        } else if (this.get('showContactTitleWithAccountNumberAndLocation')) {
          title = item.get('titleWithAccountNumberAndLocation');
        } else if (this.get('showQuickInvoiceTitleWithInvoiceNumber')) {
          title = item.get('titleWithInvoiceNumber');
        } else if (this.get('showRoutingWithBankName')) {
          title = item.get('titleWithBankName');
        } else {
          title = item.get('title');
        }
      } else {
        title = item.get('title');
      }
      if (!this.get('isDisabled') && !noFocus) {
        jQuery('#' + this.get('thisId')).focus();
      }
      this.get('selectedValues').pushObject({
        id: item.get('id'),
        title: title ? title.trim() : title
      });
      if (!noSetValue) {
        if (this.get('multiple')) {
          var tempArr = this.get('selectedValues').map(function(val) {
            return val.id;
          });
          this.set('value', tempArr);
        } else {
          if (this.get('return_full_model')) {
            this.set('value', item);
          } else {
            this.set('value', item.id);
          }
        }
      }
      jQuery('#' + this.get('thisId') + '_items').hide();
      this.set('searchValue', '');
    },
    clearItem: function(index) {
      this.set('isClearingItem', true);
      var self = this;
      this.get('selectedValues').removeAt(index);
      this.set('searchValue', '');
      if (this.get('selectedValues.length') === 0) {
        if (this.get('multiple')) {
          this.set('value', []);
        } else {
          this.set('value', '');
        }
      } else {
        var tempArr = this.get('selectedValues').map(function(val) {
          return val.id;
        });
        this.set('value', tempArr);
      }
      Ember.run.later(function() {
        jQuery('#' + self.get('thisId')).removeClass('form-control');
      }, 1);
      this.set('isClearingItem', false);
      if(this.get('isFilter')){
        this.focusOut();
      }
    },
  }

});
