import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  findTransaction: function(params) {
    return this.get('store').queryRecord('transaction', {
      id: params.id,
      expand: 'created_user,contact,surcharge,location,tags,status,type,all_tags,transaction_histories,product_transaction,terminal,log_emails,log_email_recipients,is_completable,is_refundable,is_voidable,quick_invoice,transaction_level3,refunded_amount',
    }).then(function(data) {
      return data;
    }, function(err) {
      console.log(err);
      return null;
    });
  },

  model: function(params) {
    var self = this;
    var store = this.get('store');
    return Ember.RSVP.hash({
      transaction: self.findTransaction(params),
      types: store.findAll('type'),
      statuses: store.findAll('status')
    });
  },
  afterModel: function(models) {
    if (!models.transaction || typeof models.transaction === 'undefined' || models.transaction.get('length') === 0) {
      this.transitionTo('fourohfour');
      return;
    }
    var store = this.get('store');
    if (typeof models.transaction !== 'undefined') {
      return Ember.RSVP.hash({
        locationinfo: store.queryRecord('locationinfo', {
          id: models.transaction.get('location_id'),
          product_transaction_active: '1,0',
        }).then(function(data) {
          models.locationinfo = data;
        })
      });
    } else {
      this.transitionTo('fourohfour');
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('transaction', models.transaction);
    controller.set('locationinfo', models.locationinfo);
    var products = models.locationinfo.get('product_transactions');
    var product = products.filter(function(prod) {
      return prod.get('id') === models.transaction.get('product_transaction_id');
    }).get('firstObject');
    controller.set('product', product);
    controller.set('products', products);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);

    var session = this.get('session');
    var detailSections = [{
      name: 'Detail',
      heading: 'heading0',
      group: 'group0',
      link: '#group0',
      action: 'detail',
      active: true,
      canView: true
    }, {
      name: 'Terminal',
      heading: 'heading1',
      group: 'group1',
      link: '#group1',
      action: 'terminal',
      active: false,
      canView: true
    }, {
      name: 'Location',
      heading: 'heading2',
      group: 'group2',
      link: '#group2',
      action: 'location',
      active: false,
      canView: true
    }, {
      name: 'Contact',
      heading: 'heading3',
      group: 'group3',
      link: '#group3',
      action: 'contact',
      active: false,
      canView: true
    }, {
      name: 'Emails',
      heading: 'heading4',
      group: 'group4',
      link: '#group4',
      action: 'emails',
      active: false,
      canView: true
    }, {
      name: 'Postbacks',
      heading: 'heading5',
      group: 'group5',
      link: '#group5',
      action: 'postbacks',
      active: false,
      canView: session.get('authenticated.resources.v2-postbacklogs-get'),
      ga: 'transactionfulldetail_postbacks_tab'
    },{
      name: 'API Logs',
      heading: 'heading7',
      group: 'group7',
      link: '#group7',
      action: 'apilogs',
      active: false,
      canView: session.get('authenticated.resources.v2admin-logapis-get')
    }, {
      name: 'Processor Data',
      heading: 'heading8',
      group: 'group8',
      link: '#group8',
      action: 'processordata',
      active: false,
      canView: session.get('authenticated.resources.v2-transactions-get-processordata')
    }, {
      name: 'BIN Info',
      heading: 'heading9',
      group: 'group9',
      link: '#group9',
      action: 'bininfo',
      active: false,
      canView: session.get('authenticated.resources.v2-transactions-get-bininfo') && models.transaction.get('isCC')
    }, {
      name: 'Change Logs',
      heading: 'heading10',
      group: 'group10',
      link: '#group10',
      action: 'changelogs',
      active: false,
      canView: true
    }];

    controller.set('detailSections', detailSections);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Transaction Detail',
      route_name: 'transactiondetailfull',
      route_id: models.transaction.get('id')
    });
    controller.set('pageTitle', 'Transaction Detail');
    controller.set('pageCrumbs', [{
      title: 'Transaction Detail',
      link: 'transactiondetailfull'
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    didTransition: function() {
      this.controller.set('isBinInfoTab', false);
      return true;
    },
    willTransition: function() {
      this.controller.logapisCollapseActions(false);
      return true;
    },
    updateTransaction: function(params) {
      return this.findTransaction(params);
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
