import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      logapi: store.queryRecord('admin_logapi', {
        id: params.view_id,
        expand: 'log_api_body'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var logapi = models.logapi;
    controller.set('logapi', logapi);

    // Set: Page Title - Crumbs - History
    controller.set('pageTitle', 'View API Log');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'API Logs',
      link: 'admin.apilogs.index'
    }, {
      title: 'View API Log',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
