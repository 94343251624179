import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    var type = params[0];
    switch (type) {
      case 'd':
        return 'Daily';
      case 'w':
        return 'Weekly';
      case 'm':
        return 'Monthly';
      default:
        return '';
    }
  }
});