import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isSharingGroups: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'id',
    'description',
    'created_ts',
    'created_ts_from',
    'created_ts_to'
  ],

  columns: Ember.computed('isMobileSize', function () {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Sharing Group Id',
        'type': 'string',
        'sortable': true,
        'action': function(sharinggroup) {
          self.send('view', sharinggroup);
        },
        'actionId': 'sharinggroup_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(sharinggroup) {
          self.send('view', sharinggroup);
        },
        'actionId': 'sharinggroup_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(sharinggroup) {
          self.send('edit', sharinggroup);
        },
        'actionId': 'sharinggroup_edit_link',
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(sharinggroup) {
          self.send('delete', sharinggroup);
        },
        'actionId': 'sharinggroup_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'description', 'created_ts', 'view', 'delete', 'edit'];
    var listOfMobileCols = ['id', 'description', 'created_ts', 'view', 'delete', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function (col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function (col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2admin-sharinggroups-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2admin-sharinggroups-put')) {
        colsArray.removeObject(columns.edit);
      }
      if (!session.get('authenticated.resources.v2admin-sharinggroups-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function (sharinggroup) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Sharing Group?', function () {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        sharinggroup.deleteRecord();
        sharinggroup.save().then(function () {
          noty({
            text: 'Sharing Group deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function () {
          sharinggroup.rollbackAttributes();
          noty({
            text: 'Error deleting Sharing Group!',
            type: 'error'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    edit: function (page) {
      this.transitionToRoute('admin.sharinggroups.view', page.get('id')).then(function (Route) {
        Route.controller.set('isEditing', true);
      });
    },
    view: function (page) {
      this.transitionToRoute('admin.sharinggroups.view', page.get('id')).then(function (Route) {
        Route.controller.set('isEditing', false);
      });
    }
  }
});
