import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsBillingstatements: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-due_date',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'due_date',
    'id',
    'invoice_number',
    'invoice_status_id',
    'title',
    'total'
  ],
  columns: Ember.computed(function() {
    var self = this;
    return [{
      'name': 'id',
      'title': 'Invoice Id',
      'type': 'string',
      'sortable': true,
      'action': function(billingstatement) {
        self.send('view', billingstatement);
      },
      'actionId': 'billingstatement_id_view_link',
      'filter': {
        type: 'text'
      }
    }, {
      'name': 'due_date',
      'title': 'Due Date',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'total',
      'title': 'Invoice Amount',
      'type': 'currency',
      'sortable': true,
      'align': 'text-right',
      'filter': {
        type: 'text'
      }
    }];
  }),

  // Actions
  actions: {
    view: function(billingstatement) {
      this.transitionToRoute('locations.viewbillingstatement', this.get('location.id'), billingstatement.get('id'));
    }
  }
});
