import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('portfolio.errors.messages', function() {
    this.set('errorMessages', this.get('portfolio.errors.messages'));
  }),

  changedProduct: Ember.observer('product', 'endpoint', function() {
    var self = this;
    var product = this.get('product');
    var endpoint = this.get('endpoint');

    if (!product || !endpoint) {
      return;
    }

    var portfolios = self.get('store').query('portfolio', {
      page_size: 100
    });
    portfolios.then(function(response) {
      self.set('portfolios', response);

      var new_product = self.get('store').findRecord('admin_' + endpoint + 'portfolio', product);
      new_product.then(function(result) {
        var productportfolio = result;
        self.set('productportfolio', productportfolio);
        var portfolio_id = productportfolio.get('portfolio_id');
        if (portfolio_id) {
          var new_portfolios = portfolios.filter(function(item) {
            return item.get('id') === portfolio_id;
          }).get('firstObject');
          if (new_portfolios) {
            self.set('portfolio_name', new_portfolios.get('title'));
          } else {
            self.set('portfolio_name', '');
          }
        }
      });
    });
  }),

  // Actions
  actions: {
    edit: function() {
      this.set('isEditing', true);
      var portfolios = this.get('portfolios');
      if (!portfolios) {
        return;
      }
      var productportfolio = this.get('productportfolio');
      if (!productportfolio) {
        return;
      }
      if (productportfolio.get('portfolio_id') === null) {
        productportfolio.set('portfolio_id', portfolios.get('firstObject').get('id'));
      }
    },
    cancel: function() {
      var productportfolio = this.get('productportfolio');
      productportfolio.rollbackAttributes();
      this.set('isEditing', false);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var productportfolio = this.get('productportfolio');
      productportfolio.save().then(function() {
        noty({
          text: 'Portfolio saved successfully!',
          timeout: '5000'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.send('closeModal');
      }, function() {
        noty({
          text: 'Error saving portfolio!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
      });
    }
  }
});
