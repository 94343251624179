import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('mailboxmessagesuser/index', {
      controller: 'mailboxmessagesuser/archive'
    });
  },

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'mailboxmessagesuser.archive',
      pageModelId: null,
      modelName: 'mymailboxmessageuser'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      user_id: this.get('session').get('authenticated.user_id'),
      mailbox_message_status_id: '3',
      expand: 'mailbox_message,mailbox_message_status'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Mailbox Messages ● Archive',
      route_name: 'mailboxmessagesuser.archive',
      route_id: null
    });
    controller.set('pageTitle', 'Mailbox Messages');
    controller.set('pageCrumbs', [{
      title: 'Mailbox Messages',
      link: 'mailboxmessagesuser.index'
    }, {
      title: 'Archive',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

});
