import Ember from 'ember';

export default Ember.Route.extend({

  setupController: function(controller) {
    controller.set('pageTitle', 'Signature Pad Test');
    controller.set('pageCrumbs', [{
      title: 'Signature Pad Test',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },
});