import Ember from 'ember';
import Base from 'ember-simple-auth/authenticators/base';
import jQuery from 'jquery';

export default Base.extend({

  sessionService: Ember.inject.service('session'),
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  restore: function(data) {
    return new Ember.RSVP.Promise(function(resolve, reject) {
      if (!Ember.isEmpty(data.token)) {
        resolve(data);
      } else {
        reject();
      }
    });
  },
  authenticate: function(credentials) {
    var tabId = createUUID();
    window.sessionStorage.tab_id = tabId;
    console.log('Authenticating user session.');
    var method, data, show_expiration;
    if (credentials.token) {
      method = 'GET';
      data = '/' + credentials.token;
      show_expiration = false;
    } else {
      method = 'POST';
      credentials['domain'] = window.UiENV.APP.DOMAIN;
      data = credentials;
      show_expiration = true;
    }

    

    return new Ember.RSVP.Promise(function(resolve, reject) {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/token' + ((method === 'GET') ? data : '') + '?developer-id=' + window.UiENV.APP.DEVELOPER_ID,
        type: method,
        data: ((method === 'POST') ? JSON.stringify(data) : ''),
        contentType: 'application/json',
        timeout: 10000
      }).then(function(response) {
        if(response == true) {
          reject(response);
        } else {
          Ember.run(function() {
            console.log('Loading session resources');

            new Ember.RSVP.Promise(function() {
              jQuery.ajax({
                url: window.UiENV.APP.FULL_URL + '/users/me?expand=resources,locations',
                headers: {
                  'developer-id': window.UiENV.APP.DEVELOPER_ID,
                  'access-token': response.token.token
                },
                type: 'GET',
                contentType: 'application/json'
              }).then(function(user) {
                var privList = user.user.resources;
                var resources = {};
                for (var p in privList) {
                  if (privList.hasOwnProperty(p) && privList[p].resource_name) {
                    resources[privList[p].resource_name.replace(/\./g, '-')] = true;
                    if (['v2.onboardingdetails.get', 'v2.portfoliodetailsonboardingreports.get', 'v2.developersummaryreports.get', 'v2.portfoliosummaryreports.get', 'v2admin.devicelogs.get'].indexOf(privList[p].resource_name) > -1) { // admin reports
                      resources['v2-adminreports-get'] = true;
                    }
                    if (['v2.locations.get', 'v2.users.get', 'v2.tags.get', 'v2.visibilitygroups.get', 'v2.filecategories.get', 'v2.notificationalerts.get'].indexOf(privList[p].resource_name) > -1) {
                      resources['v2-settings-get'] = true;
                    }
                    if (response.token.user_type_id >= 300) {
                      resources['v2-admin-get'] = true;
                    }
                  }
                }
                delete user.resources;

                // Set the session
                var expire_ts = parseInt(secondsTimestamp()) + (parseInt(response.token.expire) * 60);
                window.sessionStorage.redirect_route_name = credentials.route_name || response.route_name || '';
                window.sessionStorage.redirect_route_id = credentials.route_id || response.route_id || '';

                // Resolve the token returned by the API
                resolve({
                  token: response.token.token,
                  username: response.token.username,
                  expire_ts: expire_ts,
                  password_expire_ts: (show_expiration) ? response.token.password_expire_ts : null,
                  expire: response.token.expire,
                  user_id: response.token.id,
                  first_name: response.token.first_name,
                  last_name: response.token.last_name,
                  primary_location_id: response.token.primary_location_id,
                  single_location: user.user.locations.length === 1,
                  user_type_id: response.token.user_type_id,
                  timezone: response.token.timezone,
                  //route_model: credentials.route_model || response.model || '',
                  param_data: credentials.data || response.data || '',
                  user: user.user,
                  isContact: response.token.user_type_id === 100,
                  isLocation: response.token.user_type_id > 100,
                  isSupport: response.token.user_type_id > 250,
                  isIntegratedPartner: credentials.isIntegratedPartner || false,
                  ui_prefs: response.token.ui_prefs,
                  history: [],
                  sigpad: false,
                  numUnreadMessages: '',
                  tab_id: tabId,
                  resources
                });
              });
            });
          });
        }
      }, function(xhr) {
        console.log('Error retrieving valid authentication token.');
        Ember.run(function() {
          if (xhr) {
            reject(xhr);
          } else {
            reject('Error contacting authentication server!');
          }
        });
      });
    });
  },
  invalidate: function(data) {
    console.log('Invalidating User session');
    var self = this;
    return new Ember.RSVP.Promise(function(resolve) {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/token/' + data.token,
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'DELETE'
      }).always(function() {
        self.get('session').set('authenticated.token', null); // set token to null so any rogue api requests won't cause invalidate to be called again.
        self.get('session').store.persist({
          authenticated: self.get('session').get('authenticated')
        });
        // Pause briefly before trnasitioning in order to make sure all brwoser tabs have logged out
        setTimeout(function() {
          resolve();
        }, 500);
      });
    });
  },
});
