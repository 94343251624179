import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        relationship: 'all'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('locationinfos', models.locationinfos);
    var pagerParams = {
      pageRoute: 'admin.salestax.index',
      pageModelId: null,
      modelName: 'salestax'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));
    
    var gridParams = {
      expand: 'location'
    };
    controller.set('gridParams', gridParams);

    controller.set('location_id', null);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Sales Tax',
      route_name: 'admin.salestax.index',
      route_id: null
    });
    controller.set('pageTitle', 'Sales Tax');
    controller.set('pageSubtitle', {
      title: 'Add Sales Tax',
      link: 'admin.salestax.add',
      record: null,
      resource: this.get('session.authenticated.resources.v2-salestaxes-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Sales Tax',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
