import Ember from "ember";

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  tagName: 'span',
  display: {},
  didInsertElement: function() {
    var type = this.get('displayType');
    this.set('display', {});
    this.set('display.' + type, true);
  },

  thisId: Ember.computed('editField', 'context', function(){
    var field = this.get('editField');
    field = field ? field.toLowerCase() : '';
    return 'btn_edit_transaction_' + field + '_' + this.get('context');
  }),

  actions: {
    launchModal: function() {
      var self = this;
      var controller = this.get('transactionEditController');
      var transaction = this.get('transaction');
      var editField = this.get('editField');
      controller.set('transaction', {});
      controller.set('transactionDescription', '');
      controller.set('transaction_quick_invoice_id', null);
      controller.set('editField', null);
      controller.set('editField', editField);
      controller.set('customErrorMessages', []);
      controller.set('sender', this.get('sender'));
      transaction.set('move_account_vault', false);
      transaction.set('move_account_vault_transactions', false);

      var account_vault_id = transaction.get('account_vault_id');
      if(account_vault_id){
        this.get('store').queryRecord('accountvault', {
          id: account_vault_id,
          active: '0,1'
        }).then(function(data) {
          var accountvault = data;
          if(accountvault && accountvault.get('active')){
            controller.set('isActiveAccountVault', true);
          } else {
            controller.set('isActiveAccountVault', false);
          }
        });
      } else {
        controller.set('isActiveAccountVault', false);
      }

      if (editField === 'Tags') {
        controller.set('selectedTags', []);
        this.get('store').query('tag', {
          page_size: '500',
          location_id: self.get('transaction.location_id'),
          sort: 'title'
        }).then(function(tags) {
          controller.set('tagList', tags);
          controller.set('selectedTags', transaction.get('tags').slice());
          controller.set('initialTags', transaction.get('tags_csv'));
          var currentselectedTagsPlaceholder = controller.get('selectedTagsPlaceholder');
          var newselectedTagsPlaceholder = transaction.get('tags_csv').slice();
          if (currentselectedTagsPlaceholder !== newselectedTagsPlaceholder) {
            controller.set('selectedTagsPlaceholder', newselectedTagsPlaceholder);
          }
          controller.set('transaction', transaction);
          controller.set('transactionDescription', transaction.get('description'));
          controller.set('sender', self.get('sender'));
          controller.send('openModal', 'components/modals/transactionedit');
        });
      } else if (editField === 'Contact' && transaction.get('quick_invoice_id')) {
        noty({
          text: 'Cannot link/unlink Contact if Transaction is associated to a Quick Invoice.<br>Must unlink Quick Invoice before changning Contact.',
          type: 'warning',
          timeout: '7000'
        });
        return;
      } else {
        var initiallyHasContact = transaction.get('contact_id');
        controller.set('initiallyHasContact', initiallyHasContact);
        controller.set('transaction', transaction);
        controller.set('transactionDescription', transaction.get('description'));
        controller.set('sender', this.get('sender'));
        controller.send('openModal', 'components/modals/transactionedit');
      }
    }
  }
});
