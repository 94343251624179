import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var session = this.get('session');
    var pagerParams = {
      pageRoute: 'contacts.index',
      pageModelId: null,
      modelName: 'contact',
      showExport: true
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location,user',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);
    controller.set('locations', models.locations);
    controller.set('page_size', session.get('authenticated.ui_prefs.page_size') || '15');
    controller.set('export_format', session.get('authenticated.ui_prefs.report_export_type') || 'csv');

    // Set: Page Title - Crumbs - History
    // ** No History entry since route is already in main nav menu
    controller.set('pageTitle', 'Contacts');
    controller.set('pageSubtitle', {
      title: 'Add Contact',
      link: 'contacts.add',
      record: null,
      resource: session.get('authenticated.resources.v2-contacts-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
