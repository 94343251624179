import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('termscondition.errors.messages', function() {
    this.set('errorMessages', this.get('termscondition.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminTermsConditions: true,
  isLoading: false,
  isEditing: false,

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var termscondition = this.get('termscondition');
      pretty_confirm('Are you sure you want to delete these Terms and Conditions?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        termscondition.deleteRecord();
        termscondition.save().then(function() {
          termscondition.unloadRecord();
          noty({
            text: 'Terms and Conditions deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.termsconditions.index');
        }, function() {
          termscondition.rollbackAttributes();
          noty({
            text: 'Error deleting Terms and Conditions!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var termscondition = this.get('termscondition');
      termscondition.set('_id', termscondition.get('id'));
      termscondition.save().then(function() {
        noty({
          text: 'Term and condition updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.transitionToRoute('admin.termsconditions.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.termsconditions.index');
    },
    cancel: function() {
      var termscondition = this.get('termscondition');
      termscondition.rollbackAttributes();
      this.set('isEditing', false);
    }
  }
});
