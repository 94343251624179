import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsFileCategories: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('filecategory.errors.messages', function() {
    this.set('errorMessages', this.get('filecategory.errors.messages'));
  }),

  // Computed Properties
  canEdit: Ember.computed('session.authenticated.resources.v2-filecategoryvisibilitygroups-post', 'session.authenticated.resources.v2-filecategoryvisibilitygroups-delete', function() {
    return this.get('session.authenticated.resources.v2-filecategoryvisibilitygroups-post') || this.get('session.authenticated.resources.v2-filecategoryvisibilitygroups-delete');
  }),
  selectedGroups: Ember.computed('filecategoryvisibilitygroups.[]', function() {
    var filecategoryvisibilitygroups = this.get('filecategoryvisibilitygroups');
    var tempArray = [];
    if (filecategoryvisibilitygroups.get('length')) {
      filecategoryvisibilitygroups.forEach(function(group) {
        tempArray.push(group.get('visibility_group_id'));
      });
    }
    return tempArray;
  }),

  // Actions
  actions: {
    cancelVisGroups: function() {
      var changesToMake = this.get('changesToMake');
      changesToMake.forEach(function(change){
        jQuery('#filecategories_visibilitygroups_chk-' + change.visibility_group_id).trigger('click');
      });
      this.set('changesToMake', []);
      this.set('isEditing', false);
    },
    changeVisibilities: function(data) {
      if (this.get('isLoading')) {
        return;
      }
      var hasVisibilities = this.get('selectedGroups').indexOf(data.id) !== -1;
      var changesToMake = this.get('changesToMake');
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.visibility_group_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          visibility_group_id: data.id,
          title: data.title,
          type: hasVisibilities ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    checkAllVisGroups: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (!jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
    delete: function() {
      var self = this;
      var filecategory = this.get('filecategory');
      pretty_confirm('Are you sure you want to delete this File Category?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        filecategory.deleteRecord();
        filecategory.save().then(function() {
          filecategory.unloadRecord();
          noty({
            text: 'File Category deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('locations.filecategories', self.get('location.id'));
        }, function() {
          filecategory.rollbackAttributes();
          noty({
            text: 'Error deleting File Category!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    editVisGroups: function() {
      this.set('isEditing', true);
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.filecategories', location.get('id'));
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var filecategory = this.get('filecategory');
      var location = this.get('location');

      filecategory.save().then(function() {
        noty({
          text: 'File Category saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.filecategories', location.get('id'));
      }, function() {
        noty({
          text: 'Error saving file category!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    },
    saveVisGroups: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var changesToMake = this.get('changesToMake');
      var filecategoryvisibilitygroups = this.get('filecategoryvisibilitygroups');
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function(change, index) {
        if(change.type === 'POST') {
          canPostRoles.push(change);
          var fileCatVisGroup = self.get('store').createRecord('filecategoryvisibilitygroup', {
            file_category_id: self.get('filecategory.id'),
            visibility_group_id: change.visibility_group_id
          });
          return fileCatVisGroup.save().then(function(fcvg) {
            filecategoryvisibilitygroups.addObject(fcvg); // Push the object we just created back onto the list of file categories object
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
              sendNoty();
            }
          }, function() {
            // error
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
            }
            noty({
              text: 'Error adding Visibility Group ' + change.title,
              type: 'error',
              timeout: 10000
            });
          });
        } else if(change.type === 'DELETE') {
          canDeleteRoles.push(change);
          return filecategoryvisibilitygroups.forEach(function(fcvisgroup) {
            if (fcvisgroup.get('visibility_group_id') === change.visibility_group_id) {
              fcvisgroup.destroyRecord().then(function() {
                filecategoryvisibilitygroups.removeObject(fcvisgroup); // Remove the oject from the parent if it was successfully deleted
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                  sendNoty();
                }
              }, function() {
                //error
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                }
                noty({
                  text: 'Error removing Visibility Group ' + change.title,
                  type: 'error',
                  timeout: 10000
                });
              });
            }
          });
        }
      });

      self.set('isLoading', true);

      if(!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(function() {
          //
        }, function() {
          noty({
            text: 'Error applying changes!',
            type: 'error'
          });
          doneMakingChanges(self);
        });
      }
      function sendNoty() {
        if((canPostRoles && canPostRoles.length)) {
          var successMessagePost = '';
          canPostRoles.forEach(function(role) {
            successMessagePost += role.title;
            successMessagePost += '<br>';
          });
          noty({
            text: 'The following Visibility Groups were successfully added:<br>' + successMessagePost,
            timeout: 10000
          });
        }
        if((canDeleteRoles && canDeleteRoles.length)) {
          var successMessageDelete = '';
          canDeleteRoles.forEach(function(role){
            successMessageDelete += role.title;
            successMessageDelete += '<br>';
          });
          noty({
            text: 'The following Visibility Groups were successfully removed:<br>' + successMessageDelete,
            timeout: 10000
          });
        }
      }
      function doneMakingChanges (context) {
        context.set('isLoading', false);
        context.set('isEditing', false);
        context.set('changesToMake', []);
      }
    },
    uncheckAllVisGroups: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    }
  }
});
