import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-deviceterms-delete'),
      canPost: this.get('session.authenticated.resources.v2-deviceterms-post'),
      canPut: this.get('session.authenticated.resources.v2-deviceterms-put')
    });
  },

  // Computed Properties
  canLinkToAdminTerminal: Ember.computed('isRouteAdmin', 'session.authenticated.resources.v2admin-terminals-get', function() {
    return this.get('session.authenticated.resources.v2admin-terminals-get') && this.get('isRouteAdmin');
  }),
  canLinkToLocationTerminal: Ember.computed('isRouteLocations', 'session.authenticated.resources.v2-terminals-get', function() {
    return this.get('session.authenticated.resources.v2-terminals-get') & this.get('isRouteLocations');
  }),
  isRouteAdmin: Ember.computed('route', function() {
    return this.get('route') === 'admin.deviceterms.view';
  }),
  isRouteLocations: Ember.computed('route', function() {
    return this.get('route') === 'locations.deviceterms-view';
  }),
  showLocation: Ember.computed('route', function() {
    return this.get('isRouteAdmin');
  }),

  // Observers

  // Functions

  // Actions
  actions: {
    back: function() {
      if (this.get('isRouteLocations')) {
        var location = this.get('location');
        this.get('parent').transitionToRoute('locations.deviceterms-index', location.get('id'));
      } else if (this.get('isRouteAdmin')) {
        this.get('parent').transitionToRoute('admin.deviceterms.index');
      } else {
        return;
      }
    },
  }

});
