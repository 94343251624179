import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  accountsview: Ember.inject.controller('accounts/view'),
  contactsaccountsview: Ember.inject.controller('contacts/accounts-view'),
  contactscharge: Ember.inject.controller('contacts/charge'),
  contactsdashboard: Ember.inject.controller('contacts/dashboard'),
  contactsquickinvoicesview: Ember.inject.controller('contacts/quickinvoices-view'),
  contactsrecurringsview: Ember.inject.controller('contacts/recurrings-view'),
  contactstransactions: Ember.inject.controller('contacts/transactions'),
  locationsaccountsview: Ember.inject.controller('locations/accounts-view'),
  locationsquickinvoicesview: Ember.inject.controller('locations/quickinvoices-view'),
  locationsrecurringsview: Ember.inject.controller('locations/recurrings-view'),
  locationstransactions: Ember.inject.controller('locations/transactions'),
  locationsunsettledtransactions: Ember.inject.controller('locations/reports-cc-unsettledtransactions'),
  quickinvoiceview: Ember.inject.controller('quickinvoice/view'),
  recurringsview: Ember.inject.controller('recurrings/view'),
  reportstransactions: Ember.inject.controller('reports/transactions'),
  reportstransactionslegacy: Ember.inject.controller('reports/transactionslegacy'),
  sessionService: Ember.inject.service('session'),
  transactiondetailfull: Ember.inject.controller('transactiondetailfull'),
  virtualterminal: Ember.inject.controller('virtualterminal'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  store: Ember.inject.service(),

  sender: null,

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),

  // Various controller properties
  tagList: null,
  selectedTags: null,
  pagerParams: {
    pageRoute: 'components.modals.complete'
  },

  SUBTOTAL_LABEL:'Subtotal Amount',
  TRANSACTION_AMOUNT_LABEL:'Total Settlement Amount',
  TAX_LABEL:'Sales Tax',
  TAX_SURCHARGE_CONFIG_TAX_AFTER_SURCHARGE:'2',
  TAX_SURCHARGE_CONFIG_SURCHARGE_AFTER_TAX:'3',

  checkinDateMin: null,
  checkinDateMax: null,
  checkoutDateMin: null,
  checkoutDateMax: null,
  surcharge : null,
  transaction_amount:0,
  surcharge_flag:false,
  subtotal_amount:0,
  bin_card_type:'',
  surchargeAmount:0,
  auto_sales_tax:false,
  sales_tax:true,
  tax_amount:0,
  auto_tax_calc:false,

  
  surcharge_label: Ember.computed(
    'locationinfo',
    'product',
    function(){
      this.getSurcharge();
      var label = this.surcharge.surcharge_label?this.surcharge.surcharge_label:'Convenience Fee';
      return label||'';
    }
  ),

  show_auto_sales_tax_observer: Ember.observer('transaction',function(){
    var product_transaction = this.get('transaction.product_transaction');
    if(product_transaction && product_transaction.get('vt_override_sales_tax_allowed'))
      this.set('auto_sales_tax',true);
    else 
      this.set('auto_sales_tax',false);
  }),

  show_sales_tax_observer: Ember.observer('transaction',function(){
    var product_transaction = this.get('transaction.product_transaction');
    if(product_transaction 
      && product_transaction.get('vt_enable_sales_tax')
      && !product_transaction.get('vt_override_sales_tax_allowed'))
      this.set('sales_tax',true);
    else 
      this.set('sales_tax',false);
  }),
    
  // Observers
  
  // To manage Surcharge field visibility
  viewSurcharge: Ember.observer(
    'locationinfo','transaction',
    async function(){
      this.getSurcharge();
      let card_type = sessionStorage.getItem('cardType')
        ? sessionStorage.getItem('cardType')
        : await this.getBinDetails(this.get('transaction.first_six'));

      if(card_type.toLowerCase() == 'debit' && this.surcharge.is_surcharge != 'Surcharge'){
        this.set('surcharge_flag',this.surcharge.is_surcharge != 'none');
        this.set('bin_card_type',card_type)
      }else{
        if(this.surcharge.is_surcharge && this.surcharge.is_surcharge != 'none'){
          this.set('surcharge_flag',true);
        }
        else{
          this.set('surcharge_flag',false);
        }
        this.set('bin_card_type',card_type);
      }
    }
  ),
 // For subtal amount calculation
  subtotal_amount_observer: Ember.observer('transaction',
  async function(){
    this.getSurcharge();
    var amount = this.get('transaction.transaction_amount');
    var isSurcharge = this.surcharge.is_surcharge =='Surcharge';
    var card_type = await this.getBinDetails(this.get('transaction.first_six'));
    var surcharge_amount = (card_type.toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge )
      ? 0 : this.surcharge.surcharge_amount,
      surcharge_rate = (card_type.toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge )
      ? 0 : this.surcharge.surcharge_rate;
    var final_amount = 0;
    var surchargeDetails;
    final_amount = this.calculateSubtotalAmount(amount);
    surchargeDetails = this.calculateSurchargeAmount(surcharge_amount, final_amount, surcharge_rate);
    this.set('surchargeAmount',surchargeDetails.toFixed(2))
    this.set('subtotal_amount',final_amount)
  }),

  transaction_amount_observer: Ember.observer(
    'locationinfo', 'transaction',
    async function(){
      this.getSurcharge() 
      var final_amount = 0;
      var isSurcharge = this.surcharge.is_surcharge 
        && this.surcharge.is_surcharge =='Surcharge'
        ?this.surcharge.is_surcharge =='Surcharge' : false;
      let card_type = sessionStorage.getItem('cardType')
        ? sessionStorage.getItem('cardType')
        : await this.getBinDetails(this.get('transaction.first_six'));

      var surcharge_amount = (card_type.toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge)
      ? 0 : this.surcharge.surcharge_amount,
      surcharge_rate = (card_type.toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge)
      ? 0 : this.surcharge.surcharge_rate;

      var tax_amount = this.get('tax_amount');
      
      var amount = this.calculateSubtotalAmount(this.get('transaction.transaction_amount'));
      final_amount = this.calculateTransactionAmount(amount, 
        surcharge_amount || 0, 
        surcharge_rate || 0,
        tax_amount || 0);
        
      this.set('transaction_amount',final_amount.round(2));
    }
  ),

  changedExtraFlag: Ember.observer('transaction.extra_flag', 'route', function() {
    var one_day = moment.duration(1, 'd');
    var two_days = moment.duration(2, 'd');

    var transaction = this.get('transaction');
    var today = moment().format('L');
    var yesterday = moment().subtract(one_day).format('L');
    var tomorrow = moment().add(one_day).format('L');
    var twodays = moment().add(two_days).format('L');
    var elseCheckinDateMax = yesterday;
    var elseCheckoutDateMax = this.get('route') === 'runagain' ? tomorrow : '12/31/2050';
    var elseCheckinDate = yesterday;
    var elseCheckoutDate = today;
    if(transaction) {
      if (transaction.get('extra_flag') === 'advance_deposit') {
        this.set('checkinDateMin', tomorrow);
        this.set('checkinDateMax', '12/31/2050');
        this.set('checkoutDateMin', twodays);
        this.set('checkoutDateMax', '12/31/2050');
        transaction.set('checkin_date', tomorrow);
        transaction.set('checkout_date', twodays);
      } else {
        this.set('checkinDateMin', '01/01/1970');
        this.set('checkinDateMax', elseCheckinDateMax);
        this.set('checkoutDateMin', '01/01/1970');
        this.set('checkoutDateMax', elseCheckoutDateMax);
        transaction.set('checkin_date', elseCheckinDate);
        transaction.set('checkout_date', elseCheckoutDate);
      }
    }
  }),

  tax_field_observer: Ember.observer('transaction',
  function(){
    var tax_amount = this.get('transaction.tax') || 0;
    
    this.set('tax_amount',tax_amount);
  }),

  // Functions

  // Calculate the surcharge amount with the Combined surcharge amount and rate
  calculateSurchargeAmount:function(surcharge_amount,amount,surcharge_rate){
    var product_transaction = this.get('transaction.product_transaction');
    var subtotal = +amount;
    var result = 0
    if(product_transaction &&
      product_transaction.get('tax_surcharge_config') 
      && +product_transaction.get('tax_surcharge_config') === +this.TAX_SURCHARGE_CONFIG_SURCHARGE_AFTER_TAX
      ){
      var tax = this.get('tax_amount') || 0;
      subtotal = subtotal+ +tax;
      result = +surcharge_amount + (subtotal * +surcharge_rate/100).round(2);
    }else{
      result = +surcharge_amount + (subtotal * +surcharge_rate/100).round(2);
    }
    return result || 0;
  },

  // To get the Surcharge Object
  getSurcharge : function(){
      var product_transaction = ''
      if(this.get('transaction') && this.get('transaction.product_transaction')){
        product_transaction = this.get('transaction.product_transaction');
      }else{
        return this.surcharge='none';
      }
      if(
        this.get('transaction') 
        && this.get('transaction.is_surcharge')
        && (this.get('transaction.auth_surcharge_fee')
        || this.get('transaction.auth_surcharge_rate'))
      ){
        let surchargeObj = {
          surcharge_amount:this.get('transaction.auth_surcharge_fee')||0,
          surcharge_rate:this.get('transaction.auth_surcharge_rate')||0,
          is_surcharge:this.get('transaction.is_surcharge'),
          surcharge_label:'Convenience Fee'
        }
  
        this.set('surcharge',surchargeObj);
      }else{
        let surchargeObj = {
          surcharge_amount:0,
          surcharge_rate:0,
          is_surcharge:'none',
          surcharge_label:'Convenience Fee'
        }
        this.set('surcharge',surchargeObj);
      }
  },

  //To Calculate Total Settele amount after calculation 
  calculateTransactionAmount: function(amount,surcharge_amount = 0, surcharge_rate = 0, tax_amount=0){
    var product_transaction = this.get('transaction.product_transaction');
    var subtotal = +amount;
    var result = 0
    if(product_transaction &&
      product_transaction.get('tax_surcharge_config') 
      && +product_transaction.get('tax_surcharge_config') === +this.TAX_SURCHARGE_CONFIG_SURCHARGE_AFTER_TAX
      ){
        subtotal = subtotal+ +tax_amount;
        result = (+subtotal + (+surcharge_amount + ((surcharge_rate / 100)* +subtotal)))
      }else{
        result = (+subtotal + (+surcharge_amount + ((surcharge_rate / 100)* +subtotal))) + +tax_amount;
      }
    return result || 0;
  },

  // For calculation of Subtotal amount from Transaction tip_amount
  // With the help of surcharge amount and rate
  calculateSubtotalAmount:function(transactionAmount){
    let actualAmount = this.get('transaction.subtotal_amount')?this.get('transaction.subtotal_amount'):this.get('transaction.transaction_amount');
    actualAmount = +actualAmount
    return actualAmount.toFixed(2) || 0; // Round to 2 decimal places
  },

  completeCancelled: function(context) {
    var transaction = context.get('transaction');
    var tagList = context.get('tagList');
    var selectedTags = context.get('initialTags').split(',');
    var tempTags = [];
    tagList.forEach(function(tag) {
      selectedTags.map(function(t){
        if(t === tag.get('title')){
          tempTags.pushObject(tag);
        }
      });
    });
    transaction.set('tags', tempTags);
    transaction.rollbackAttributes();
    context.set('transaction', null);
  },

  //To Get Bin detail basede on card first six digit from transaction object
  getBinDetails: function(first_six){
    return new Promise((res,rej)=>{
      this.get("store").findRecord("bin", first_six).then(function (bin){
        var card_type = bin.get('card_type')?bin.get('card_type'):'';
        sessionStorage.setItem("cardType",card_type);
        res(card_type);
      }).catch(function (error) {
        this.set("error", error);
      });
    })
  },

  calculateTaxFunction: function(taxableAmount, surcharge_fee, surcharge_rate){
    return new Promise((res,rej)=>{
      var self = this;
      var product_transaction = this.get('transaction.product_transaction'),
      transaction = this.get('transaction');
      var tax_config = product_transaction.get('tax_surcharge_config');
      var surcharge = {
        fee:surcharge_fee,rate:surcharge_rate
      }
      var sales_tax, tax_amount = 0;
      if(product_transaction && product_transaction.get('vt_override_sales_tax_allowed')){
        this.set('auto_tax_calc',true);
        var billing_zip = this.get('transaction.billing_zip');
        if(!billing_zip) {
          res(tax_amount)
        }
        this.get('store').query('salestax', {
          location_id: transaction.get('location_id'),
          zip_code: billing_zip
        }).then((data) => {
          if(data.get('firstObject')){
            sales_tax = data.get('firstObject');
            tax_amount = self.calculateTaxFromRate(taxableAmount, sales_tax.get('rate'), tax_config, surcharge);
            res(tax_amount);
          }else res(tax_amount);
        }).catch((error) => {
          this.set("error", error);
        });
      }else{
        this.set('auto_tax_calc',false);
        res(tax_amount);
      }
    })
  },

  calculateTaxFromRate: function(amt, rt, tax_surcharge_config, surcharge){
    var taxableAmount = parseFloat(amt) || 0;
    var rate = parseFloat(rt) || 0;
    var tax_amount = 0
    if(tax_surcharge_config === this.TAX_SURCHARGE_CONFIG_TAX_AFTER_SURCHARGE){
      // First Calculate Surcharge
      let surcharge_amount = +surcharge.fee + ( +taxableAmount * (+surcharge.rate * 0.01));
      // Calculation For Tax
      tax_amount = (taxableAmount + surcharge_amount) * (rt * 0.01);
    }else if(tax_surcharge_config === this.TAX_SURCHARGE_CONFIG_SURCHARGE_AFTER_TAX){
      tax_amount = ((rate / 100) * taxableAmount).toFixed(2)
    }
    
    return (+tax_amount).round(2);
  },
  // Actions
  actions: {
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }

      // Get transaction object
      var transaction = this.get('transaction');
      // Get existing tags for transaction object
      var existingTags = transaction.get('tags');

      // Remove any existing tag objects
      if (existingTags) {
        existingTags.forEach(function(item) {
          existingTags.removeObject(item);
        });
      }

      // Then add back any newly selected tag objects
      var tagList = this.get('tagList');
      var selectedTags = tags.filters[0].value.split(',');
      tagList.forEach(function(tag) {
        if (selectedTags.indexOf(tag.get('title')) > -1) {
          transaction.get('tags').pushObject(tag);
        }
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var transaction = this.get('transaction');
      var locationinfo = this.get('locationinfo');
      var contact = this.get('contact');

      transaction.set('no_show', transaction.get('extra_flag') === 'no_show');
      transaction.set('advance_deposit', transaction.get('extra_flag') === 'advance_deposit');
      
      var subtotal_amount = +this.get('subtotal_amount');
      var transaction_amount = this.get('transaction_amount');
      var tax = this.get('tax_amount');
      var surchargeAmount = 0
      
      transaction.set('subtotal_amount',subtotal_amount.round(2));
      transaction.set('transaction_amount',transaction_amount+'');
      transaction.set('tax',tax);
      if(tax)
        surchargeAmount = (transaction_amount - subtotal_amount - tax).toFixed(2);
      else
        surchargeAmount = (transaction_amount - subtotal_amount).toFixed(2);

      transaction.set('surcharge_amount',surchargeAmount);
      if (parseFloat(transaction.get('transaction_amount')).toFixed(2) > (parseFloat(transaction.get('auth_amount')).toFixed(2) * 1.15)) {
        pretty_confirm('Please note: your settle amount is at least 15% more than the authorized amount. We suggest you reauthorize (increment) the transaction.<br><br>Process anyway?', function() {
          completeTransaction();
        }, function() {
          self.set('isLoading', false);
          transaction.rollbackAttributes();
          noty({
            text: 'Action cancelled!',
            type: 'error',
            timeout: '5000'
          });
        });
      } else {
        completeTransaction();
      }

      function completeTransaction() {
        transaction.set('action', 'authcomplete');
        transaction.save().then(function() {
          self.set('isLoading', true);
          // Set the contact on the transaction for the receipt
          transaction.set('contact', contact);

          var sender = self.get('sender');
          if (sender) {
            var senderController = self.get(sender);
            if (senderController) {
              senderController.set('needsRefresh', new moment().unix());
            }
          }
          self.send('showTransDetail', transaction, locationinfo);
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Error completing transaction!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        });
      }
    },
    calculateTotal: async function(){
      var amount = this.get('subtotal_amount');  
      var product_transaction = this.get('transaction.product_transaction')
      var isSurcharge = this.surcharge.is_surcharge =='Surcharge';
      var auto_asles_tax_flag = (product_transaction 
                                && product_transaction.get('vt_override_sales_tax_allowed'));
      
      var surcharge_fee = (this.get('bin_card_type').toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge)
      ? 0 : this.surcharge.surcharge_amount,
      surcharge_rate = (this.get('bin_card_type').toLowerCase() == 'debit' && this.surcharge!='none' && isSurcharge)
      ? 0 : this.surcharge.surcharge_rate ;
      var total = 0;
      var surchargeData = 0;
      
      var tax_amount = 0;
      if(auto_asles_tax_flag)
        tax_amount = await this.calculateTaxFunction(amount, surcharge_fee, surcharge_rate);
      else  
        tax_amount = this.get('tax_amount');
      
      this.set('tax_amount',(+tax_amount).round(2));
      total = this.calculateTransactionAmount(amount, surcharge_fee, surcharge_rate, tax_amount);
      surchargeData = this.calculateSurchargeAmount(surcharge_fee,amount,surcharge_rate);
      
      this.set('surchargeAmount',surchargeData.round(2));
      this.set('transaction_amount',total.round(2));
    },

  }
});
