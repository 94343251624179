import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      belongsToLocations: store.query('locationinfo', {
        relationship: 'direct',
        expand: 'locations',
        page_size: '100'
      }),
      belongsToAll: store.query('locationinfo', {
        relationship: 'all',
        page_size: '100'
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'locations.files',
      pageModelId: models.location.get('id'),
      modelName: 'file'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,visibility_group,product_file,file_category',
      resource_id: models.location.get('id'),
      resource: 'Location'
    };
    controller.set('gridParams', gridParams);
    var belongsToLocations = models.belongsToLocations.filter(function(item) {
      return item.get('product_file.id');
    });
    controller.set('location', models.location);
    controller.set('file_resource', {
      resource: 'Location',
      resource_id: transition.to.params.id
    });
    controller.set('belongsToLocations', belongsToLocations);
    controller.set('belongsToAll', models.belongsToAll);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Files',
      route_name: 'locations.files',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Files',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
