import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  alert_types: [{
      'id': '0',
      'title': 'Pop Up Message'
    },
    {
      'id': '1',
      'title': 'Red Banner'
    },
    {
      'id': '2',
      'title': 'Yellow Banner'
    },
    {
      'id': '3',
      'title': 'Blue Banner'
    },
    {
      'id': '4',
      'title': 'Green Banner'
    }
  ],
  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var t_alert_types = self.get('alert_types');
      var t_alert_type = t_alert_types.filter(function(item) {
        return item.id === query.id;
      });
      if (t_alert_type.length > 0) {
        resolve({
          alerttype: t_alert_type
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findAll: function() {
    return {
      alerttype: this.get('alert_types')
    };
  }
});