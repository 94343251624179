import Ember from "ember";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Computed Properties

  isRouteContact: Ember.computed("route", function () {
    return this.get("route") === "contacts.quickinvoices-index";
  }),
  isRouteContactUser: Ember.computed("route", function () {
    return this.get("route") === "contactuser.quickinvoices-index";
  }),
  isRouteLocation: Ember.computed("route", function () {
    return this.get("route") === "locations.quickinvoices-index";
  }),
  isRouteQuickInvoice: Ember.computed("route", function () {
    return this.get("route") === "quickinvoice.index";
  }),
  noQIMessage: Ember.computed("isQI", "route", function () {
    if (this.get("isRouteContactUser")) {
      return (
        "Invoices are not configured for your location.<br> Please contact " +
        this.get("locations").get("firstObject").get("name") +
        " for alternatives."
      );
    } else {
      var message = "Quick Invoice is not configured for any products at ";
      if (this.get("isRouteLocation")) {
        message += "this location.";
      } else if (this.get("isRouteQuickInvoice")) {
        message += "any of your locations.";
      } else if (this.get("isRouteContact")) {
        message += "this contact's location.";
      }
      message +=
        "<br> Please contact support if you need assistance enabling Quick Invoice.";
      return message;
    }
  }),

  productsWithQuickInvoice: Ember.computed(
    "locations.@each",
    "location.product_transactions.@each",
    function () {
      var products = [];
      if (this.get("route") === "quickinvoice.index") {
        this.get("locations").map(function (location) {
          location.get("product_transactions").map(function (product) {
            if (product.get("quick_invoice_allow")) {
              products.pushObject(product);
            }
          });
        });
      } else {
        this.get("location.product_transactions").map(function (product) {
          if (product.get("quick_invoice_allow")) {
            products.pushObject(product);
          }
        });
      }
      return products;
    }
  ),
  productsWithQuickInvoiceCC: Ember.computed(
    "productsWithQuickInvoice.@each",
    function () {
      return this.get("productsWithQuickInvoice").filter(function (product) {
        return product.get("payment_method") === "cc";
      });
    }
  ),
  productsWithQuickInvoiceACH: Ember.computed(
    "productsWithQuickInvoice.@each",
    function () {
      return this.get("productsWithQuickInvoice").filter(function (product) {
        return product.get("payment_method") === "ach";
      });
    }
  ),

  tagList: Ember.computed(
    "locations.@each",
    "location.tags.@each",
    "route",
    "locationTags.@each",
    function () {
      var tags = [];
      if (this.get("isRouteContactUser")) {
        return tags;
      } else if (this.get("isRouteQuickInvoice")) {
        this.get("locations").map(function (location) {
          location.get("tags").map(function (tag) {
            tags.pushObject(tag);
          });
        });
      } else if (this.get("isRouteLocation")) {
        this.get("locationTags").map(function (tag) {
          tags.pushObject(tag);
        });
      } else if (this.get("isRouteContact")) {
        this.get("location.tags").map(function (tag) {
          tags.pushObject(tag);
        });
      }
      return tags.sortBy("title");
    }
  ),

  columns: Ember.computed("isMobileSize", function () {
    var self = this;
    var session = this.get("session");
    var router = this.get("router");
    var columns = {
      invoice_number: {
        name: "invoice_number",
        title: "Invoice<br>Number",
        type: "string",
        action: function (quickinvoice) {
          self.send("view", quickinvoice);
        },
        actionId: "quickinvoice_invoice_number_view_link",
        sortable: true,
        filter: {
          type: "text",
        },
      },
      due_date: {
        name: "due_date",
        title: "Due<br>Date",
        type: "string",
        sortable: true,
      },
      expire_date: {
        name: "expire_date",
        title: "Expired<br>Date",
        type: "string",
        sortable: true,
      },
      title: {
        name: "title",
        title: "Title",
        type: "string",
        sortable: true,
        filter: {
          type: "text",
        },
      },
      contact_full_name: {
        name: "contact.full_name",
        title: "Contact",
        type: "string",
        action: function (quickinvoice) {
          router.transitionTo(
            "contacts.dashboard",
            quickinvoice.get("contact_id")
          );
        },
        actionpriv: "v2-contacts-get",
        sortable: true,
      },
      status_id: {
        name: "status_title",
        title: "Status",
        type: "string",
        sortable: true,
        filter: {
          type: "dropdown",
          items: "quickinvoice_status",
          multi: true,
          showAllSelect: true,
          field: "status_id",
        },
      },
      payment_status_id: {
        name: "payment_status_title",
        title: "Payment<br>Status",
        type: "string",
        sortable: true,
        filter: {
          type: "dropdown",
          items: "quickinvoice_paymentStatus",
          multi: true,
          showAllSelect: true,
          field: "payment_status_id",
        },
      },
      active: {
        name: "active",
        title: "Active",
        type: "check",
        align: "text-center",
        sortable: true,
        filter: {
          type: "dropdown",
          items: "yesNo",
          multi: true,
        },
      },
      amount_due: {
        name: "amount_due",
        title: "Amount<br>Due",
        type: "currency",
        sortable: true,
        align: "text-right",
        filter: {
          type: "text",
        },
      },
      remaining_balance: {
        name: "remaining_balance",
        title: "Remaining<br>Balance",
        type: "currency",
        sortable: true,
        align: "text-right",
        filter: {
          type: "text",
        },
      },
      select: {
        name: "select",
        title: "Select",
        type: "input",
        sortable: false,
        align: "text-center",
        actionDisabledCheck: function (quickinvoice) {
          const isInvoiceClosed = quickinvoice.get("status_id") === 0;
          if (isInvoiceClosed) quickinvoice.set("select", false);
          const isSelectCheckboxDisabled = isInvoiceClosed;
          return isSelectCheckboxDisabled;
        },
        editableColumnType: "checkbox",
        action: function (quickinvoice, event) {
          const isSelected = event.target.checked;
          quickinvoice.set("select", isSelected);
          // Set this field to true if an invoice is selected/de-selected.
          self.set("hasChanged", true);
          const defaultAmount = isSelected
            ? quickinvoice.get("remaining_balance")
            : "0.00";
          quickinvoice.set("amount", defaultAmount);

          self.get("parent").send("refreshData");
        },
      },
      amount: {
        name: "amount",
        title: "Payment Amount",
        type: "input",
        sortable: false,
        align: "text-center",
        adornment: "$",
        actionDisabledCheck: function (quickinvoice) {
          const isInvoiceSelected = quickinvoice.get("select");
          const isInvoiceClosed = quickinvoice.get("status_id") === 0;
          const isPartialPayAllowed =
            quickinvoice.get("allow_partial_pay") === true;

          const isAmountFieldDisabled =
            !isInvoiceSelected || isInvoiceClosed || !isPartialPayAllowed;

          return isAmountFieldDisabled;
        },
        editableColumnType: "number",
        action: function (quickinvoice, event) {
          let newAmount = event.target.value;

          const amountDue = quickinvoice.amount_due;
          const singlePaymentMinAmount = quickinvoice.single_payment_min_amount;
          const single_payment_max_amount =
            quickinvoice.single_payment_max_amount;
          const allowOverpayment = quickinvoice.allow_overpayment;
          quickinvoice.set("error", "");

          if (
            !newAmount.includes(".") ||
            Number.parseFloat(Number.parseFloat(newAmount).toFixed(2)) ===
              Number.parseFloat(newAmount)
          ) {
            newAmount = Number.parseFloat(newAmount).toFixed(2).toString();
          }

          if (Number.parseFloat(newAmount) > single_payment_max_amount) {
            quickinvoice.set(
              "error",
              `Amount must be less than $${single_payment_max_amount}`
            );
          }

          if (Number.parseFloat(newAmount) > amountDue && !allowOverpayment) {
            quickinvoice.set("error", `Amount must be less than $${amountDue}`);
          }

          if (Number.parseFloat(newAmount) < singlePaymentMinAmount) {
            quickinvoice.set(
              "error",
              `Amount must be more than $${singlePaymentMinAmount}`
            );
          }

          if (
            Number.parseFloat(newAmount).toFixed(2).toString() !== newAmount
          ) {
            quickinvoice.set("error", "Enter a Valid Amount");
          }

          quickinvoice.set("amount", newAmount);

          self.get("parent").send("refreshData");
        },
      },
      email: {
        name: "email",
        title: "Email",
        type: "string",
        sortable: true,
        filter: {
          type: "text",
        },
      },
      tags: {
        name: "tags",
        title: "Tags",
        type: "array",
        sortable: false,
        filter: {
          type: "dropdown",
          items: "tagList",
          labelKey: "title",
          multi: true,
          showAllSelect: true,
        },
      },
      location_id: {
        name: "location_id",
        title: "Location",
        type: "string",
        action: function (quickinvoice) {
          router.transitionTo(
            "locations.view",
            quickinvoice.get("location_id")
          );
        },
        actionpriv: session.get("authenticated.isSupport")
          ? "v2admin-locations-get"
          : "v2-locations-get",
        sortable: true,
        filter: {
          type: "dropdown",
          items: "locations",
          multi: true,
          showAllSelect: true,
        },
      },
      cc_product_transaction_id: {
        name: "cc_product_transaction_id",
        title: "CC Deposit<br>Account",
        type: "string",
        sortable: true,
        action: function (quickinvoice) {
          router.transitionTo(
            "locations.viewproducttransaction",
            quickinvoice.get("location_id"),
            quickinvoice.get("cc_product_transaction_id")
          );
        },
        actionpriv: ["v2-locations-get", "v2-producttransactions-get"],
        filter: {
          type: "dropdown",
          items: "productsWithQuickInvoiceCC",
          multi: true,
          showAllSelect: true,
        },
      },
      ach_product_transaction_id: {
        name: "ach_product_transaction_id",
        title: "ACH Deposit<br>Account",
        type: "string",
        sortable: true,
        action: function (quickinvoice) {
          router.transitionTo(
            "locations.viewproducttransaction",
            quickinvoice.get("location_id"),
            quickinvoice.get("ach_product_transaction_id")
          );
        },
        actionpriv: ["v2-locations-get", "v2-producttransactions-get"],
        filter: {
          type: "dropdown",
          items: "productsWithQuickInvoiceACH",
          multi: true,
          showAllSelect: true,
        },
      },
      pay: {
        name: "payQuickInvoice",
        title: "",
        type: "pay",
        action: function (quickinvoice) {
          self.send("payQuickInvoice", quickinvoice);
        },
        actionDisabledMessage: "Unable to Pay",
        sortable: false,
        isActionColumn: true,
      },
      delete: {
        name: "delete",
        title: "",
        type: "delete",
        action: function (quickinvoice) {
          self.send("delete", quickinvoice);
        },
        actionId: "quickinvoice_delete_link",
        actionDisabledMessage: "Already Deleted",
        sortable: false,
        isActionColumn: true,
      },
      show_detail: {
        name: "id",
        title: "",
        type: "view",
        action: function (quickinvoice) {
          self.send("view", quickinvoice);
        },
        actionId: "quickinvoice_view_link",
        sortable: false,
        isActionColumn: true,
      },
    };

    if (this.get("isRouteContactUser")) {
      delete columns.invoice_number.filter;
      delete columns.title.filter;
      delete columns.status_id.filter;
      delete columns.payment_status_id.filter;
      delete columns.active.filter;
      delete columns.amount_due.filter;
      delete columns.remaining_balance.filter;
      delete columns.email.filter;
    }

    var listOfCols = [
      "invoice_number",
      "due_date",
      "expire_date",
      "title",
      "contact_full_name",
      "status_id",
      "payment_status_id",
      "active",
      "amount_due",
      "remaining_balance",
      "select",
      "amount",
      "email",
      "tags",
      "location_id",
      "cc_product_transaction_id",
      "ach_product_transaction_id",
      "pay",
      "show_detail",
      "delete",
    ];
    var listOfMobileCols = [
      "invoice_number",
      "due_date",
      "title",
      "payment_status_id",
      "location_id",
      "cc_product_transaction_id",
      "ach_product_transaction_id",
      "pay",
      "show_detail",
      "delete",
    ];

    if (this.get("isMobileSize")) {
      var mobileCols = [];
      listOfMobileCols.forEach(function (col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function (col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (context.get("isRouteContact") || context.get("isRouteContactUser")) {
        colsArray.removeObject(columns.contact_full_name);
      }
      if (
        context.get("locations.length") <= 1 ||
        context.get("isRouteLocation") ||
        context.get("isRouteContact") ||
        context.get("isRouteContactUser")
      ) {
        colsArray.removeObject(columns.location_id);
      }
      if (
        context.get("productsWithQuickInvoiceCC.length") < 1 ||
        context.get("isRouteContactUser")
      ) {
        colsArray.removeObject(columns.cc_product_transaction_id);
      }
      if (
        context.get("productsWithQuickInvoiceACH.length") < 1 ||
        context.get("isRouteContactUser")
      ) {
        colsArray.removeObject(columns.ach_product_transaction_id);
      }
      if (context.get("isRouteContactUser")) {
        colsArray.removeObject(columns.tags);
        colsArray.removeObject(columns.active);
        colsArray.removeObject(columns.email);
        colsArray.removeObject(columns.expire_date);
      }
      if (
        !context.get(
          "session.authenticated.resources.v2-quickinvoices-delete"
        ) ||
        context.get("isRouteContactUser")
      ) {
        colsArray.removeObject(columns.delete);
      }
      if (
        !context.get("session.authenticated.resources.v2-transactions-post") ||
        !context.get("isRouteContactUser")
      ) {
        colsArray.removeObject(columns.pay);
        colsArray.removeObject(columns.select);
        colsArray.removeObject(columns.amount);
      }
    }
  }),

  // Functions
  updateQueryParamsOnParent: function () {
    var self = this;
    var queryParams = this.get("queryParams");
    var parent = this.get("parent");

    queryParams.forEach(function (param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    delete: function (quickinvoice) {
      var self = this;
      if (quickinvoice.get("active")) {
        pretty_confirm(
          "Are you sure you want to delete this Quick Invoice?",
          function () {
            if (self.get("isLoading")) {
              return;
            }
            self.set("isLoading", true);
            quickinvoice.deleteRecord();
            quickinvoice.save().then(
              function () {
                noty({
                  text: "Quick Invoice deleted successfully!",
                });
                quickinvoice.unloadRecord();
                self.get("parent").send("gridNeedsRefresh", self);
                self.set("isLoading", false);
              },
              function () {
                quickinvoice.rollbackAttributes();
                noty({
                  text: "Error deleting Quick Invoice!",
                  type: "error",
                });
                self.set("isLoading", false);
              }
            );
          }
        );
      } else {
        noty({
          text: "Quick Invoice is already deleted!",
          type: "warning",
        });
      }
    },
    payQuickInvoice: function (quickinvoice) {
      var payquickinvoiceController = this.get("payquickinvoiceController");
      if (quickinvoice.get("cantPay")) {
        return;
      }

      payquickinvoiceController.set("quickinvoice", null);
      payquickinvoiceController.set("locations", null);
      payquickinvoiceController.set("accounts", null);
      payquickinvoiceController.set("contact", null);

      quickinvoice._pay_multiple_invoices = false;
      payquickinvoiceController.set("quickinvoice", quickinvoice);
      payquickinvoiceController.set("locations", this.get("locations"));
      payquickinvoiceController.set("accounts", this.get("accounts"));
      payquickinvoiceController.set("contact", this.get("contact"));

      // Close any pre-existing modals
      this.get("parent").send("closeModal");
      this.get("parent").send("openModal", "components/modals/payquickinvoice");
    },
    view: function (quickinvoice) {
      var route = this.get("route");
      var router = this.get("router");
      if (this.get("isRouteQuickInvoice")) {
        router
          .transitionTo("quickinvoice.view", quickinvoice.get("id"))
          .then(function (Route) {
            Route.controller.set("isEditing", false);
          });
      } else if (route === "contacts.quickinvoices-index") {
        router
          .transitionTo(
            "contacts.quickinvoices-view",
            this.get("contact.id"),
            quickinvoice.get("id")
          )
          .then(function (Route) {
            Route.controller.set("isEditing", false);
          });
      } else if (this.get("isRouteLocation")) {
        router
          .transitionTo(
            "locations.quickinvoices-view",
            this.get("location.id"),
            quickinvoice.get("id")
          )
          .then(function (Route) {
            Route.controller.set("isEditing", false);
          });
      } else if (route === "contactuser.quickinvoices-index") {
        router
          .transitionTo(
            "contactuser.quickinvoices-view",
            this.get("contact.id"),
            quickinvoice.get("id")
          )
          .then(function (Route) {
            Route.controller.set("isEditing", false);
          });
      } else {
        return;
      }
    },
  },

  // Events
  init: function () {
    var self = this;
    var queryParams = this.get("queryParams");
    var parent = self.get("parent");
    queryParams.forEach(function (param) {
      self.set(param, parent.get(param));
      self.addObserver(
        param.replace(".", "-"),
        self,
        "updateQueryParamsOnParent"
      );
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function () {
    var self = this;
    var queryParams = this.get("queryParams");
    queryParams.forEach(function (param) {
      self.removeObserver(
        param.replace(".", "-"),
        self,
        "updateQueryParamsOnParent"
      );
    });
  },
});
