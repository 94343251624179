import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/notificationtemplates/notificationtemplateform');
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      notificationtemplate: store.createRecord('admin_notificationtemplate', {

      })
    });
  },
  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('notificationtemplate', models.notificationtemplate);
    controller.set('isNew', true);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-notificationtemplates-delete'),
      canPost: this.get('session.authenticated.resources.v2admin-notificationtemplates-post'),
      canPut: this.get('session.authenticated.resources.v2admin-notificationtemplates-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Notification Template',
      route_name: 'admin.notificationtemplates.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Notification Template');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Notification Templates',
      link: 'admin.notificationtemplates.index'
    }, {
      title: 'Add Notification Template',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.controller.get('notificationtemplate');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
