import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    if (this.get('isLoggedInUser')) {
      var self = this;
      new Ember.RSVP.Promise(function() {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/termsconditions/latest',
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'GET',
          contentType: 'application/json'
        }).then(function(data) {
          self.set('termscondition', data.termscondition);
          if (self.get('termscondition')) {
            self.set('terms_condition_acceptance', false);
          }
        }, function() {
          noty({
            text: 'Error getting terms and conditions!',
            type: 'error'
          });
        });
      });
    }

    var old_password = this.get('user.old_password');
    var password = this.get('user.password');
    var confirmpassword = this.get('user.confirmpassword');
    if(old_password){
      this.set('user.old_password', '');
    }
    if(password){
      this.set('user.password', '');
    }
    if(confirmpassword){
      this.set('user.confirmpassword', '');
    }
    this.set('terms_condition_acceptance', false);
  },

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('user.errors.messages', function() {
    this.set('errorMessages', this.get('user.errors.messages'));
  }),

  isEditing: false,
  pass_reqs: {
    one_letter: false,
    one_number: false,
    one_special: false,
    eight_chars: false,
    pass_match: false
  },

  // Computed Properties

  allow_submit: Ember.computed('user.password', 'user.confirmpassword', 'user.confirmpassword', 'terms_condition_acceptance', 'termscondition', function() {
    var showOldPasswordField = this.get('showOldPasswordField');
    var old_password = this.get('user.old_password');
    var password = this.get('user.password');
    var confirmpassword = this.get('user.confirmpassword');
    if (showOldPasswordField) {
      if (!old_password || !password || !confirmpassword) {
        return false;
      }
    } else {
      if (!password || !confirmpassword) {
        return false;
      }
    }
    var requirements = this.get('pass_reqs');
    if (!requirements.one_letter || !requirements.one_number || !requirements.one_special || !requirements.eight_chars || !requirements.pass_match) {
      return false;
    }

    var termscondition = this.get('termscondition');
    if (termscondition) {
      this.set('user.terms_condition_id', null);
      var terms_condition_acceptance = this.get('terms_condition_acceptance');
      this.set('user.terms_condition_id', termscondition.id);
      if (!terms_condition_acceptance) {
        return false;
      }
    }
    return true;
  }),
  canChangePassword: Ember.computed('session.authenticated.resources.@each', function() {
    var userPost = this.get('session.authenticated.resources.v2-users-post');
    var userPut = this.get('session.authenticated.resources.v2-users-put');
    var userMePut = this.get('session.authenticated.resources.v2-users_me-put');
    return userPost || userPut || userMePut ? true : false;
  }),
  isLoggedInUser: Ember.computed('user.id', 'session.authenticated.user_id', function() {
    var userId = this.get('user.id');
    var sessionId = this.get('session.authenticated.user_id');
    return userId === sessionId;
  }),
  showOldPasswordField: Ember.computed('isLoggedInUser', function() {
    return this.get('isLoggedInUser');
  }),

  // Observers

  passwordChanged: Ember.observer('user.password', 'user.confirmpassword', function() {
    var self = this;
    var password = this.get('user.password');
    var confirmpassword = this.get('user.confirmpassword');
    if (!password) {
      self.set('pass_reqs.one_letter', false);
      self.set('pass_reqs.one_number', false);
      self.set('pass_reqs.one_special', false);
      self.set('pass_reqs.eight_chars', false);
      self.set('pass_reqs.pass_match', false);
    } else {
      var one_letter = false;
      var one_number = false;
      var one_special = false;
      var eight_chars = false;
      var pass_match = false;

      if (password.match(/[0-9]/)) {
        one_number = true;
      }
      if (password.match(/[a-z]/i)) {
        one_letter = true;
      }
      if (password.match(/\W/)) {
        one_special = true;
      }
      if (password.length >= 12) {
        eight_chars = true;
      }
      if (password === confirmpassword && password.length > 0) {
        pass_match = true;
      }

      self.set('pass_reqs.one_letter', one_letter);
      self.set('pass_reqs.one_number', one_number);
      self.set('pass_reqs.one_special', one_special);
      self.set('pass_reqs.eight_chars', eight_chars);
      self.set('pass_reqs.pass_match', pass_match);
    }
  }),

  // Actions
  actions: {
    change_password: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      this.set('errorMessages', null);
      var isOldPasswordVisible = this.get('showOldPasswordField');

      var self = this;
      var user = this.get('user');
      var session = this.get('session');

      var old_password;
      var terms_condition_id;
      var password = user.get('password');
      var confirm_password = user.get('confirmpassword');
      if (!password) {
        noty({
          text: 'Password cannot be blank!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
        window.scrollTo(0, 0);
        return;
      }
      if (password !== confirm_password) {
        noty({
          text: 'Passwords do not match!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
        window.scrollTo(0, 0);
        return;
      }
      if (isOldPasswordVisible) {
        old_password = user.get('old_password');
        if (!old_password) {
          noty({
            text: 'Current password cannot be blank!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
          window.scrollTo(0, 0);
          return;
        }
      }
      if (this.get('termscondition') && !this.get('terms_condition_acceptance')) {
        noty({
          text: 'Must accept Terms and Conditions!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
        window.scrollTo(0, 0);
        return;
      }

      var url;
      var data;
      var isContactUser = false;

      // Check if the current URL contains 'contacts' i.e the function is called from contacts page
      if (window.location.href.includes('contacts')) {
        isContactUser = true;
      }

      if (isOldPasswordVisible) {
        terms_condition_id = user.get('terms_condition_id');
        url = '/users/me/changePassword';
        data = JSON.stringify({
          'old_password': old_password,
          'password': password,
          'terms_condition_id': terms_condition_id
        });
      } else {
        var prop = this.get('prop');
        var id = prop ? user.get(prop) : user.get('id');
        url = '/users/' + id + '/changePassword';
        data = JSON.stringify({
          'password': password
        });
      }

      if (isContactUser) {
        url += '?isContactUser=true';
      }

      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + url,
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        type: 'POST',
        data: data
      }).then(function() {
        noty({
          text: 'Password changed successfully!'
        });
        if (isOldPasswordVisible) {
          user.set('temp_password', null);
          user.set('temp_confirm_password', null);
          self.set('isLoading', false);
          self.get('router').transitionTo('virtualterminal');
        } else {
          self.set('user.password', null);
          self.set('user.confirmpassword', null);
          self.set('isLoading', false);
          window.scrollTo(0, 0);
          self.set('isEditing', false);
        }
      }, function(err) {
        var errors;
        try{
          var errRes = JSON.parse(err.responseText);
          if(errRes && errRes.error){
            errors = errRes.error
          }else{
            console.error('Invalid JSON structure: Missing "errors" property.');
          }
        }catch (err){
          console.error('Error parsing JSON:', err);
        }
        var messages = [];
        for (var x in errors) {
          messages.push(errors[x]);
        }
        self.set('errorMessages', messages);
        self.set('isLoading', false);
      });
    },
  }

});
