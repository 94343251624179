import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isHelpPages: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'id',
    'title',
    'user_type_id'
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Help Page Id',
        'type': 'string',
        'sortable': true,
        'action': function(helppage) {
          self.send('view', helppage);
        },
        'actionId': 'helppage_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      user_type_id: {
        'name': 'user_type_id',
        'title': 'User Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'userTypes',
          multi: true
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(helppage) {
          self.send('view', helppage);
        },
        'actionId': 'helppage_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(helppage) {
          self.send('edit', helppage);
        },
        'actionId': 'helppage_edit_link',
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(helppage) {
          self.send('delete', helppage);
        },
        'actionId': 'helppage_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'title', 'user_type_id', 'view', 'delete', 'edit'];
    var listOfMobileCols = ['id', 'title', 'user_type_id', 'view', 'delete', 'edit'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-helppages-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-helppages-put')) {
        colsArray.removeObject(columns.edit);
      }
      if (!session.get('authenticated.resources.v2-helppages-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(helppage) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Help Page?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        helppage.deleteRecord();
        helppage.save().then(function() {
          noty({
            text: 'Help Page deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          helppage.rollbackAttributes();
          noty({
            text: 'Error deleting Help Page!' + error,
            type: 'error',
            timeout: 10000
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    edit: function(page) {
      this.transitionToRoute('admin.helppages.view', page.get('id')).then(function(Route) {
        Route.controller.set('isEditing', true);
      });
    },
    view: function(page) {
      this.transitionToRoute('admin.helppages.view', page.get('id')).then(function(Route) {
        Route.controller.set('isEditing', false);
      });
    }
  }
});
