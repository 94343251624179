import Ember from 'ember';

var get = Ember.get;

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  classNames: ['make-margin-bottom-5', 'checkbox-select-checkbox'],

  isInsertingElement: true,
  didInsertElement: function() {
    var item = this.get('item');
    var labelKey = this.get('labelKey');
    var value = this.get('value');

    if (!item || !labelKey || !value) {
      return;
    }
    this.set('selected', value.indexOf(get(item, labelKey)) > -1);
    this.set('isInsertingElement', false);
  },

  // Computed Properties

  description: Ember.computed(function() {
    var item = this.get('item');
    return get(item, this.get('labelDescription'));
  }),
  disabled: Ember.computed('selected', 'privname', 'privname2', 'isEditing', 'canEdit', 'isLoading', function() {
    var value = this.get('value');
    var session = this.get('session');
    var selected = value.indexOf(this.get('item.id')) !== -1;
    var isprivname = session.get('authenticated.resources.' + this.get('privname'));
    var isprivname2 = session.get('authenticated.resources.' + this.get('privname2'));
    if(this.get('isEditing') && this.get('canEdit') && !this.get('isLoading') && ((!selected && isprivname && typeof this.get('privname') !== 'undefined') || (selected && isprivname2 && typeof this.get('privname2') !== 'undefined'))) {
      return false;
    } else {
      return true;
    }
  }),
  id: Ember.computed(function() {
    var item = this.get('item');
    var labelKey = this.get('labelKey');
    return 'chk-' + get(item, labelKey);
  }),
  key: Ember.computed(function() {
    var item = this.get('item');
    return get(item, this.get('labelKey'));
  }),
  label: Ember.computed(function() {
    var item = this.get('item');
    return get(item, this.get('labelValue'));
  }),
  showDescription: Ember.computed('description', function() {
    return this.get('description') && this.get('description') !== 'NULL';
  }),
  thisId: Ember.computed(function() {
    return this.get('chkType') + '_' + this.get('id');
  }),

  // Observers
  selectedChanged: Ember.observer('selected', function() {
    if(this.get('isInsertingElement')) {
      return;
    }
    this.action({
      'id': this.get('key'),
      'title': this.get('label'),
      'selected': this.get('selected')
    });
  })
});
