import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  tagName: 'input',
  type: 'radio',
  attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled', 'id'],
  classNames: ['hand'],

  value: null,
  checked: null,

  htmlChecked: Ember.computed('value', 'checked', function() {
    return this.get('value') === this.get('checked');
  }),
  click: function() {
    if(this.get('onClick')) {
      this.onClick();
    }
  },

  change: function() {
    this.set('checked', this.get('value'));
  },

  _setCheckedProp: function() {
    if (!this.$()) {
      return;
    }
    this.$().prop('checked', this.get('htmlChecked'));
  },

  _updateElementValue: Ember.observer('htmlChecked', function() {
    Ember.run.once(this, '_setCheckedProp');
  })
});
