import Ember from 'ember';
import jQuery from 'jquery';

var get = Ember.get;

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,
  showSaveNewUUID: false,
  showGenerateUUID: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('user.errors.messages', function() {
    this.set('errorMessages', this.get('user.errors.messages'));
  }),

  userEntryPages: Ember.computed(function() {
    return this.get('applicationData.userEntryPages');
  }),
  contactEntryPages: Ember.computed(function() {
    return this.get('applicationData.contactEntryPages');
  }),
  pageSizes: Ember.computed(function() {
    return this.get('applicationData.pageSizes');
  }),
  processMethods: Ember.computed(function() {
    return this.get('applicationData.processMethods');
  }),
  reportExportTypes: Ember.computed(function() {
    return this.get('applicationData.exportFormats');
  }),

  entryPages: Ember.computed(function() {
    var session = this.get('session');
    if (session.get('isContact')) {
      return this.get('contactEntryPages');
    } else {
      return this.get('userEntryPages');
    }
  }),
  entry_page: Ember.computed('user.ui_prefs.entry_page', function() {
    var entryPages = this.get('entryPages');
    var entryPage = this.get('user.ui_prefs.entry_page');
    var pref = entryPages.filter(function(item) {
      return item.id === entryPage;
    });
    if (get(pref, 'length') === 1) {
      return get(get(pref, 'firstObject'), 'title');
    }
    return '';
  }),
  page_size: Ember.computed('user.ui_prefs.page_size', function() {
    var pageSizes = this.get('pageSizes');
    var pageSize = this.get('user.ui_prefs.page_size');
    var page = pageSizes.filter(function(item) {
      return parseInt(item.id) === parseInt(pageSize);
    });
    if (get(page, 'length') === 1) {
      return get(get(page, 'firstObject'), 'title');
    }
    return '';
  }),
  report_export_type: Ember.computed('user.ui_prefs.report_export_type', function() {
    var reportExportTypes = this.get('reportExportTypes');
    var exportType = this.get('user.ui_prefs.report_export_type');
    var type = reportExportTypes.filter(function(item) {
      return item.id === exportType;
    });
    if (get(type, 'length') === 1) {
      return get(get(type, 'firstObject'), 'title');
    }
    return '';
  }),
  process_method: Ember.computed('user.ui_prefs.process_method', function() {
    var processMethods = this.get('processMethods');
    var process_method = this.get('user.ui_prefs.process_method');
    var processMeth = processMethods.filter(function(item) {
      return item.id === process_method;
    });
    if (get(processMeth, 'length') === 1) {
      return get(get(processMeth, 'firstObject'), 'title');
    }
    return '';
  }),
  default_terminal: Ember.computed('user.ui_prefs.default_terminal', function() {
    var terminals = this.get('terminals');
    var default_terminal = this.get('user.ui_prefs.default_terminal');
    var defaultTerm = terminals.filter(function(item) {
      return item.id === default_terminal;
    });
    if (get(defaultTerm, 'length') === 1) {
      return get(get(defaultTerm, 'firstObject'), 'title');
    }
    return '';
  }),
  showTerminals: Ember.computed('user.ui_prefs.process_method', function() {
    return this.get('user.ui_prefs.process_method') === 'physical_terminal';
  }),

  // Actions
  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    cancel: function() {
      var user = this.get('user');
      user.rollbackAttributes();
      this.set('isEditing', false);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var user = this.get('user');

      user.save().then(function() {
        noty({
          text: 'Profile settings updated successfully!'
        });
        var session = self.get('session');
        session.set('authenticated.ui_prefs.page_size', user.get('ui_prefs.page_size'));
        session.set('authenticated.ui_prefs.report_export_type', user.get('ui_prefs.report_export_type'));
        session.store.persist({
          authenticated: session.get('authenticated')
        });

        self.set('isLoading', false);
        self.set('isEditing', false);
        window.location.reload(true);
      }, function() {
        self.set('isLoading', false);
        noty({
          text: 'Error updating porofile settings.',
          type: 'error',
          timeout: '5000'
        });
      });
    },
    requestKey: function() {
      var self = this;
      pretty_confirm('Requesting a new api key will invalid any existing key, are you sure?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);

        var session = self.get('session');
        var user = self.get('user');

        return jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/users/me/generateuserapikey',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          noty({
            text: 'Key request successful!'
          });
          user.set('user_api_key', data.user.user_api_key);
          self.set('user', user);
          self.set('isLoading', false);
        }, function(error) {
          noty({
            text: 'Error requesting new key.',
            type: 'error',
            timeout: '5000'
          });
          var errors = '';
          try{
            var errRes = JSON.parse(error.responseText);
            if(errRes && errRes.error){
              errors = errRes.error
            }else{
              console.error('Invalid JSON structure: Missing "errors" property.');
            }
          }catch (err){
            console.error('Error parsing JSON:', err);
          }
          self.set('errorMessages', errors);
          self.set('isLoading', false);
        });
      });
    },
    generateNewUUID: function() {
      this.send('generateUUID', 'users.profile', 'user.user_hash_key');
      this.set('showSaveNewUUID', true);
    },
    saveNewUUID: function() {
      var self = this;
      var user = this.get("user");
      this.set('showSaveNewUUID', false);
      this.set('showGenerateUUID', false);
      user.save().then(function() {
        // Success callback
        self.set('showGenerateUUID', true);
        noty({
          text: 'User Hash Key was updated successfully!'
        });
      }, function() {
        window.scrollTo(0, 0);
        noty({
          text: 'Error updating user!',
          type: 'error'
        });
        self.set('showSaveNewUUID', true);
        self.set('showGenerateUUID', true);
      });
    }
  }
});
