import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_fund_source: DS.attr('string'),
  card_class: DS.attr('string'),
  country_code: DS.attr('string'),
  detail_card_indicator: DS.attr('string'),
  detail_card_product: DS.attr('string'),
  fsa_indicator: DS.attr('string'),
  issuer_bank_name: DS.attr('string'),
  issuing_network: DS.attr('string'),
  prepaid_indicator: DS.attr('string'),
  product_id: DS.attr('string'),
  regulator_indicator: DS.attr('string'),
  token_ind: DS.attr('string'),
  visa_large_ticket_indicator: DS.attr('string'),
  visa_product_sub_type: DS.attr('string'),
  bin_type: DS.attr('string'), // new field included

  // Computed Properties

  // description not required according to new bin fields. Already descriptive
  // account_fund_source_description: Ember.computed('account_fund_source', function(){
  //   return getTitle(accountFundSources, this.get('account_fund_source'));
  // }),
  // card_class_description: Ember.computed('card_class', function(){
  //   return getTitle(cardClasses, this.get('card_class'));
  // }),
  detail_card_indicator_description: Ember.computed('detail_card_indicator', function(){
    return getTitle(detailCardIndicators, this.get('detail_card_indicator'));
  }),
  // commenting card product description. According to new bin fields this is already descriptive.
  // detail_card_product_description: Ember.computed('detail_card_product', function(){
  //   return getTitle(detailCardProducts, this.get('detail_card_product'));
  // }),
  fsa_indicator_description: Ember.computed('fsa_indicator', function(){
    return this.get('fsa_indicator') === 'F' ? 'FSA' : '';
  }),
  prepaid_indicator_description: Ember.computed('prepaid_indicator', function(){
    return this.get('prepaid_indicator') === 'P' ? 'Prepaid Card' : '';
  }),
  issuing_network_description: Ember.computed('issuing_network', function(){
    return getTitle(issuingNetworks, this.get('issuing_network'));
  }),
  product_id_description: Ember.computed('product_id', function(){
    return getTitle(productIds, this.get('product_id'));
  }),
  regulator_indicator_description: Ember.computed('regulator_indicator', function(){
    return getTitle(regulatorIndicators, this.get('regulator_indicator'));
  }),
  token_ind_description: Ember.computed('token_ind', function(){
    return this.get('token_ind') === 'Y' ? 'Token BIN' : '';
  }),
  visa_large_ticket_indicator_description: Ember.computed('visa_large_ticket_indicator', function(){
    return this.get('visa_large_ticket_indicator') === 'L' ? 'Visa Large Ticket' : '';
  }),
  visa_product_sub_type_description: Ember.computed('visa_product_sub_type', function(){
    return getTitle(visaProductSubTypes, this.get('visa_product_sub_type'));
  }),
});

var getTitle = function(arr, val) {
  if(!val) {
    return '';
  }
  var title = 'Unknown';
  arr.map(function(item){
    if(val === item.id) {
      title = item.title;
    }
  });
  return title;
};

var accountFundSources = [{
  id: 'C',
  title: 'Credit'
}, {
  id: 'D',
  title: 'Debit'
},{
  id: 'P',
  title: 'Prepaid'
},{
  id: 'H',
  title: 'Charge'
},{
  id: 'R',
  title: 'Deferred Debit (Visa Only)'
},{
  id: '',
  title: 'Network only ranges'
}];
var cardClasses = [{
  id: 'B',
  title: 'Business'
}, {
  id: 'C',
  title: 'Consumer'
},{
  id: 'P',
  title: 'Purchase'
},{
  id: 'T',
  title: 'Corporate'
}];
var detailCardIndicators = [{
  id: 'C',
  title: 'Credit Hybrid (has PIN capability also)'
}, {
  id: 'E',
  title: 'Debit – PIN Only with EBT.'
},{
  id: 'H',
  title: 'Debit Hybrid (PIN and Signature)'
},{
  id: 'J',
  title: 'USA Commercial Debit – Signature Only – Not PIN Capable'
},{
  id: 'K',
  title: 'USA Commercial Debit – PIN Capable'
},{
  id: 'L',
  title: 'NON USA Consumer Debit – Not PIN Capable'
},{
  id: 'M',
  title: 'NON USA Commercial Debit – Not PIN Capable'
},{
  id: 'N',
  title: 'NON USA Consumer Debit – PIN Capable'
},{
  id: 'O',
  title: 'NON USA Commercial Debit – PIN Capable'
},{
  id: 'P',
  title: 'Debit – PIN Only without EBT'
},{
  id: 'R',
  title: 'Private Label (MasterCard)'
},{
  id: 'S',
  title: 'Signature only (not PIN capable)'
},{
  id: 'X',
  title: 'True credit (– Not PIN Capable/ No Signature capability)'
}];
var detailCardProducts = [{
  id: 'V',
  title: 'Visa'
}, {
  id: 'M',
  title: 'MasterCard'
},{
  id: 'A',
  title: 'American Express'
},{
  id: 'D',
  title: 'Discover'
},{
  id: 'N',
  title: 'PIN Only (Non-Visa/MasterCard/AMEX/Discover)'
}];
var issuingNetworks = [{
  id: '00',
  title: 'Discover'
}, {
  id: '01',
  title: 'Diners'
},{
  id: '02',
  title: 'JCB (Japanese Credit Bank)'
},{
  id: '03',
  title: 'CUP (China Union Pay)'
},{
  id: '04',
  title: 'PayPal'
}];
var productIds = [{
  id: 'A^',
  title: 'Visa Traditional'
}, {
  id: 'B^',
  title: 'Visa Traditional Rewards'
},{
  id: 'C^',
  title: 'Visa Signature'
},{
  id: 'D^',
  title: 'Visa Signature Preferred'
},{
  id: 'E^',
  title: 'Proprietary ATM'
},{
  id: 'F^',
  title: 'Visa Classic'
},{
  id: 'G^',
  title: 'Visa Business Tier 1 (US) / Visa Business (non-US)'
},{
  id: 'G1',
  title: 'Visa Business Tier 3 (US) / Visa Signature Business (non-US)'
},{
  id: 'G3',
  title: 'Visa Business Tier 2 (US) / Visa Business Enhanced (non-US)'
},{
  id: 'G4',
  title: 'Visa Business Tier 4 (US) / Visa Infinite Business (non-US)'
},{
  id: 'G5',
  title: 'Visa Business Rewards'
},{
  id: 'I^',
  title: 'Visa Infinite'
},{
  id: 'I1',
  title: 'Visa Infinite Privilege'
},{
  id: 'I2',
  title: 'Visa Ultra High Net Worth'
},{
  id: 'J3',
  title: 'Visa Healthcare'
},{
  id: 'K^',
  title: 'Visa Corporate T&E'
},{
  id: 'K1',
  title: 'Visa GSA Corporate T&E (US) Visa Government Corporate T&E (global)'
},{
  id: 'L^',
  title: 'Visa Electron'
},{
  id: 'N^',
  title: 'Visa Platinum'
},{
  id: 'N1',
  title: 'Visa Rewards'
},{
  id: 'N2',
  title: 'Visa Select'
},{
  id: 'P^',
  title: 'Visa Gold'
},{
  id: 'Q^',
  title: 'Private Label'
},{
  id: 'Q2',
  title: 'Private Label Basic'
},{
  id: 'Q3',
  title: 'Private Label Standard'
},{
  id: 'Q4',
  title: 'Private Label Enhanced'
},{
  id: 'Q5',
  title: 'Private Label Specialized'
},{
  id: 'Q6',
  title: 'Private Label Premium'
},{
  id: 'R^',
  title: 'Proprietary'
},{
  id: 'S^',
  title: 'Visa Purchasing'
},{
  id: 'S1',
  title: 'Visa Purchasing with Fleet'
},{
  id: 'S2',
  title: 'Visa GSA Purchasing (US) Visa Government Purchasing (Global)'
},{
  id: 'S3',
  title: 'Visa GSA Purchasing with Fleet (US) Visa Government Purchasing With Fleet (global)'
},{
  id: 'S4',
  title: 'Government Services Loan'
},{
  id: 'S5',
  title: 'Visa Commercial Transport (EBT)'
},{
  id: 'S6',
  title: 'Business Loan'
},{
  id: 'U^',
  title: 'Visa Travel Money'
},{
  id: 'V^',
  title: 'V Pay'
},{
  id: 'X^',
  title: 'Visa B2B Virtual Payments'
},{
  id: 'ACS',
  title: 'Digital Debit'
},{
  id: 'BPD',
  title: 'Business Premium Debit'
},{
  id: 'DAG',
  title: 'Gold Debit MasterCard Salary'
},{
  id: 'DAP',
  title: 'Platinum Debit MasterCard Salary'
},{
  id: 'DAS',
  title: 'Standard Debit MasterCard Salary'
},{
  id: 'DLG',
  title: 'Debit MasterCard Gold Delayed Debit'
},{
  id: 'DLH',
  title: 'Debit MasterCard World Embossed Delayed Debit'
},{
  id: 'DLP',
  title: 'Debit MasterCard Platinum Delayed Debit'
},{
  id: 'DLS',
  title: 'Debit MasterCard Card Delayed Debit'
},{
  id: 'DOS',
  title: 'Standard Debit MasterCard Social'
},{
  id: 'DWF',
  title: 'Debit MasterCard Humanitarian Prepaid'
},{
  id: 'MAB',
  title: 'World Elite MasterCard for Business Card'
},{
  id: 'MAC',
  title: 'MasterCard World Elite Corporate Card'
},{
  id: 'MAP',
  title: 'MAP MasterCard Commercial Payments Account'
},{
  id: 'MAQ',
  title: 'MasterCard Prepaid Commercial Payments Account'
},{
  id: 'MBB',
  title: 'MasterCard Prepaid Consumer'
},{
  id: 'MBC',
  title: 'MasterCard Prepaid Voucher'
},{
  id: 'MBD',
  title: 'MasterCard Professional Debit BusinessCard Card'
},{
  id: 'MBE',
  title: 'MasterCard Electronic Business Card'
},{
  id: 'MBF',
  title: 'Prepaid MC Food'
},{
  id: 'MBK',
  title: 'MasterCard Black Card'
},{
  id: 'MBM',
  title: 'Prepaid MC Meal'
},{
  id: 'MBP',
  title: 'MasterCard Corporate Prepaid'
},{
  id: 'MBS',
  title: 'MasterCard B2B Product'
},{
  id: 'MBT',
  title: 'MasterCard Corporate Prepaid Travel'
},{
  id: 'MBW',
  title: 'World MasterCard Black Edition Debit'
},{
  id: 'MCB',
  title: 'MasterCard Business Card Card'
},{
  id: 'MCC',
  title: 'MasterCard Credit Card (mixed BIN)'
},{
  id: 'MCD',
  title: 'Debit MasterCard Card'
},{
  id: 'MCE',
  title: 'MasterCard Electronic Card'
},{
  id: 'MCF',
  title: 'MasterCard Fleet Card'
},{
  id: 'MCG',
  title: 'Gold MasterCard Card'
},{
  id: 'MCH',
  title: 'MasterCard Premium Charge'
},{
  id: 'MCO',
  title: 'MasterCard Corporate Card'
},{
  id: 'MCP',
  title: 'MasterCard Purchasing Card'
},{
  id: 'MCS',
  title: 'Standard MasterCard Card'
},{
  id: 'MCT',
  title: 'Titanium MasterCard Card'
},{
  id: 'MCV',
  title: 'Merchant-Branded Program'
},{
  id: 'MCW',
  title: 'World MasterCard Card'
},{
  id: 'MDB',
  title: 'Debit MasterCard BusinessCard Card'
},{
  id: 'MDG',
  title: 'Gold Debit MasterCard Card'
},{
  id: 'MDH',
  title: 'Debit Other Embossed MasterCard Card'
},{
  id: 'MDJ',
  title: 'Debit MasterCard Enhanced'
},{
  id: 'MDL',
  title: 'Business Debit Other Embossed'
},{
  id: 'MDO',
  title: 'Debit Other'
},{
  id: 'MDP',
  title: 'Platinum Debit MasterCard Card'
},{
  id: 'MDR',
  title: 'Debit Brokerage 1'
},{
  id: 'MDS',
  title: 'Debit MasterCard Card'
},{
  id: 'MDT',
  title: 'Commercial Debit MasterCard Card'
},{
  id: 'MDW',
  title: 'World Black Debit (LAC region excluding Mexico)'
},{
  id: 'MEB',
  title: 'MasterCard Executive BusinessCard Card'
},{
  id: 'MEC',
  title: 'MasterCard Electronic Commercial'
},{
  id: 'MEF',
  title: 'MasterCard Electronic Payment Account'
},{
  id: 'MEO',
  title: 'MasterCard Corporate Executive Card'
},{
  id: 'MET',
  title: 'Titanium Debit MasterCard Card'
},{
  id: 'MFB',
  title: 'Flex World Elite'
},{
  id: 'MFD',
  title: 'Flex Platinum'
},{
  id: 'MFE',
  title: 'Flex Charge World Elite'
},{
  id: 'MFH',
  title: 'Flex World'
},{
  id: 'MFL',
  title: 'Flex Charge Platinum'
},{
  id: 'MFW',
  title: 'Flex Charge World'
},{
  id: 'MGF',
  title: 'MasterCard Government Commercial Card'
},{
  id: 'MHA',
  title: 'MasterCard Healthcare Prepaid Non-Tax'
},{
  id: 'MHB',
  title: 'MasterCard HSA Substantiated'
},{
  id: 'MHD',
  title: 'HELOC Debit Standard'
},{
  id: 'MHH',
  title: 'MasterCard HSA Non-Substantiated'
},{
  id: 'MHK',
  title: 'Magna Health Access Card'
},{
  id: 'MHL',
  title: 'HELOC Debit Gold'
},{
  id: 'MHM',
  title: 'HELOC Debit Platinum'
},{
  id: 'MHN',
  title: 'HELOC Debit Premium'
},{
  id: 'MIA',
  title: 'Prepaid MasterCard Unembossed Student Card'
},{
  id: 'MIP',
  title: 'MasterCard Prepaid Student Card'
},{
  id: 'MIU',
  title: 'Debit MasterCard Unembossed (Non-US)'
},{
  id: 'MLA',
  title: 'MasterCard Central Travel Solutions Air Card'
},{
  id: 'MLB',
  title: 'MasterCard Brazil Benefit for Home Improvement'
},{
  id: 'MLD',
  title: 'MasterCard Distribution Card'
},{
  id: 'MLE',
  title: 'MasterCard Brazil General Benefits'
},{
  id: 'MLF',
  title: 'MasterCard Agro'
},{
  id: 'MLL',
  title: 'MasterCard Central Travel Solutions Land Card'
},{
  id: 'MNF',
  title: 'MasterCard Public Sector Commercial Card'
},{
  id: 'MNW',
  title: 'MasterCard World Card'
},{
  id: 'MOC',
  title: 'Standard Maestro Social'
},{
  id: 'MOG',
  title: 'Maestro Gold Card'
},{
  id: 'MOP',
  title: 'Maestro Platinum'
},{
  id: 'MOW',
  title: 'World Maestro'
},{
  id: 'MPA',
  title: 'MasterCard Prepaid Debit Standard Payroll'
},{
  id: 'MPB',
  title: 'MasterCard Preferred Business Card'
},{
  id: 'MPD',
  title: 'MasterCard Flex Prepaid'
},{
  id: 'MPF',
  title: 'MasterCard Prepaid Debit Standard Gift'
},{
  id: 'MPG',
  title: 'Debit MasterCard Standard Prepaid General Spend'
},{
  id: 'MPH',
  title: 'MasterCard Cash'
},{
  id: 'MPJ',
  title: 'Prepaid MasterCard Debit Voucher Meal/Food Card'
},{
  id: 'MPK',
  title: 'MasterCard Prepaid Government Commercial Card'
},{
  id: 'MPL',
  title: 'Platinum MasterCard Card'
},{
  id: 'MPM',
  title: 'MasterCard Prepaid Debit Standard Consumer Incentive'
},{
  id: 'MPN',
  title: 'MasterCard Prepaid Debit Standard Insurance'
},{
  id: 'MPO',
  title: 'MasterCard Prepaid Debit Standard Other'
},{
  id: 'MPP',
  title: 'MasterCard Prepaid Card'
},{
  id: 'MPR',
  title: 'MasterCard Prepaid Debit Standard Travel'
},{
  id: 'MPT',
  title: 'MasterCard Prepaid Debit Standard Teen'
},{
  id: 'MPV',
  title: 'MasterCard Prepaid Debit Standard Government'
},{
  id: 'MPW',
  title: 'Debit MasterCard BusinessCard Prepaid Workplace Business to Business'
},{
  id: 'MPX',
  title: 'MasterCard Prepaid Debit Standard Flex Benefit'
},{
  id: 'MPY',
  title: 'MasterCard Prepaid Debit Standard Employee Incentive'
},{
  id: 'MPZ',
  title: 'MasterCard Prepaid Debit Standard Government Consumer'
},{
  id: 'MRC',
  title: 'Prepaid MasterCard Electronic Card (Non-US)'
},{
  id: 'MRF',
  title: 'European Regulated Individual Pay'
},{
  id: 'MRG',
  title: 'MasterCard Prepaid Card (Non-US)'
},{
  id: 'MRH',
  title: 'MasterCard Platinum Prepaid Travel Card'
},{
  id: 'MRJ',
  title: 'Prepaid MasterCard Voucher Meal/Food Card'
},{
  id: 'MRK',
  title: 'Prepaid MasterCard Public Sector Commercial Card'
},{
  id: 'MRO',
  title: 'MasterCard Rewards Only'
},{
  id: 'MRL',
  title: 'MasterCard Prepaid Business Preferred'
},{
  id: 'MRP',
  title: 'Standard Retailer Centric Payments'
},{
  id: 'MRW',
  title: 'Prepaid MasterCard Business Card (Non-US)'
},{
  id: 'MSA',
  title: 'Prepaid Maestro Payroll Card'
},{
  id: 'MSB',
  title: 'Maestro Small Business Card'
},{
  id: 'MSF',
  title: 'Prepaid Maestro Gift Card'
},{
  id: 'MSG',
  title: 'Prepaid Maestro Consumer Reloadable Card'
},{
  id: 'MSI',
  title: 'Maestro Card'
},{
  id: 'MSJ',
  title: 'Maestro Prepaid Voucher Meal and Food Card'
},{
  id: 'MSM',
  title: 'Maestro Prepaid Consumer Promotion Card'
},{
  id: 'MSN',
  title: 'Maestro Prepaid Insurance Card'
},{
  id: 'MSO',
  title: 'Maestro Prepaid Other Card'
},{
  id: 'MSQ',
  title: 'Reserved for Future Use'
},{
  id: 'MSR',
  title: 'Prepaid Maestro Travel Card'
},{
  id: 'MST',
  title: 'Prepaid Maestro Teen Card'
},{
  id: 'MSV',
  title: 'Prepaid Maestro Government Benefit Card'
},{
  id: 'MSW',
  title: 'Prepaid Maestro Corporate Card'
},{
  id: 'MSX',
  title: 'Prepaid Maestro Flex Benefit Card'
},{
  id: 'MSY',
  title: 'Prepaid Maestro Employee Incentive Card'
},{
  id: 'MSZ',
  title: 'Prepaid Maestro Emergency Assistance Card'
},{
  id: 'MTP',
  title: 'MasterCard Platinum Prepaid Travel Card'
},{
  id: 'MUW',
  title: 'MasterCard World Domestic Affluent'
},{
  id: 'MWB',
  title: 'World MasterCard for Business Card'
},{
  id: 'MWD',
  title: 'World Deferred'
},{
  id: 'MWE',
  title: 'World Elite MasterCard Card'
},{
  id: 'MWF',
  title: 'MasterCard Humanitarian Prepaid'
},{
  id: 'MWO',
  title: 'World Elite MasterCard Corporate Card'
},{
  id: 'MWP',
  title: 'MasterCard World Prepaid'
},{
  id: 'MWR',
  title: 'World Retailer Centric Payments'
},{
  id: 'OLB',
  title: 'Maestro Small Business Delayed Debit'
},{
  id: 'OLG',
  title: 'Maestro Gold Delayed Debit'
},{
  id: 'OLP',
  title: 'Maestro Platinum Delayed Debit'
},{
  id: 'OLS',
  title: 'Maestro Delayed Debit'
},{
  id: 'OLW',
  title: 'World Maestro Delayed Debit'
},{
  id: 'PMC',
  title: 'Proprietary Credit Card (Sweden domestic)'
},{
  id: 'PMD',
  title: 'Proprietary Debit Card (Sweden domestic)'
},{
  id: 'PSC',
  title: 'Common Proprietary Swedish Credit Card'
},{
  id: 'PSD',
  title: 'Common Proprietary Swedish Debit Card'
},{
  id: 'PVA',
  title: 'Private Label A'
},{
  id: 'PVB',
  title: 'Private Label B'
},{
  id: 'PVC',
  title: 'Private Label C'
},{
  id: 'PVd',
  title: 'Private Label d'
},{
  id: 'PVe',
  title: 'Private Label e'
},{
  id: 'PVF',
  title: 'Private Label F'
},{
  id: 'PVG',
  title: 'Private Label G'
},{
  id: 'PVH',
  title: 'Private Label H'
},{
  id: 'PVI',
  title: 'Private Label I'
},{
  id: 'PVJ',
  title: 'Private Label J'
},{
  id: 'PVL',
  title: 'Private Label L'
},{
  id: 'PVT',
  title: 'Private Label Trade Program'
},{
  id: 'SAG',
  title: 'Gold MasterCard Salary Immediate Debit'
},{
  id: 'SAL',
  title: 'Standard Maestro Salary'
},{
  id: 'SAP',
  title: 'Platinum MasterCard Salary Immediate Debit'
},{
  id: 'SAS',
  title: 'Standard MasterCard Salary Immediate Debit'
},{
  id: 'SOS',
  title: 'Standard MasterCard Social Immediate Debit'
},{
  id: 'SUR',
  title: 'Prepaid Unembossed MasterCard Card (Non-US)'
},{
  id: 'TBE',
  title: 'MasterCard Electronic Business Immediate Debit'
},{
  id: 'TCB',
  title: 'MasterCard Corporate Executive Business Card Immediate Debit'
},{
  id: 'TCC',
  title: 'MasterCard (mixed BIN) Immediate Debit'
},{
  id: 'TCE',
  title: 'MasterCard Electronic Immediate Debit'
},{
  id: 'TCF',
  title: 'MasterCard Fleet Card Immediate Debit'
},{
  id: 'TCG',
  title: 'Gold MasterCard Card Immediate Debit'
},{
  id: 'TCO',
  title: 'MasterCard Corporate Immediate Debit'
},{
  id: 'TCP',
  title: 'MasterCard Purchasing Card Immediate Debit'
},{
  id: 'TCS',
  title: 'MasterCard Standard Card Immediate Debit'
},{
  id: 'TCW',
  title: 'World Signia MasterCard Card Immediate Debit'
},{
  id: 'TEB',
  title: 'MasterCard Executive BusinessCard Card'
},{
  id: 'TEC',
  title: 'MasterCard Electronic Commercial Immediate Debit'
},{
  id: 'TEO',
  title: 'MasterCard Corporate Executive Card Immediate Debit'
},{
  id: 'TNF',
  title: 'MasterCard Public Sector Commercial Card Immediate Debit'
},{
  id: 'TNW',
  title: 'MasterCard New World Immediate Debit'
},{
  id: 'TPB',
  title: 'MasterCard Preferred Business Card Immediate Debit'
},{
  id: 'TPL',
  title: 'Platinum MasterCard Immediate Debit'
},{
  id: 'TWB',
  title: 'World MasterCard Black Edition Immediate Debit'
},{
  id: 'WBE',
  title: 'World MasterCard Black Edition'
},{
  id: 'WPD',
  title: 'World Prepaid Debit'
},{
  id: '001',
  title: 'Consumer Credit – Rewards'
},{
  id: '002',
  title: 'Commercial Credit'
},{
  id: '003',
  title: 'Consumer Debit'
},{
  id: '004',
  title: 'Commercial Debit'
},{
  id: '005',
  title: 'Prepaid Gift'
},{
  id: '006',
  title: 'Prepaid ID known'
},{
  id: '007',
  title: 'Consumer Credit - Premium'
},{
  id: '008',
  title: 'Consumer Credit – Core'
},{
  id: '009',
  title: 'Private Label Credit'
},{
  id: '010',
  title: 'Commercial Credit – Executive Busines'
},{
  id: '011',
  title: 'Consumer Credit – Premium Plus'
},{
  id: '012',
  title: 'Commercial Prepaid – Non-Reloadable'
},{
  id: '013',
  title: 'PayPal'
},{
  id: '014',
  title: 'PayPal Mobile'
}];
var regulatorIndicators = [{
  id: 'B',
  title: 'ISS REGULATED (regulated issuer)'
},{
  id: 'N',
  title: 'ISS NONREGULATED (unregulated issuer OR Non US issued card)'
},{
  id: '1',
  title: 'ISS REGULATED FRAUD (regulated issuer with fraud)'
}];
var visaProductSubTypes = [{
  id: 'AC',
  title: 'Brazil Agriculture Maintenance Account / Custeio'
},{
  id: 'AE',
  title: 'Brazil Agriculture Debit Account / Electron'
},{
  id: 'AG',
  title: 'Brazil Agriculture'
},{
  id: 'AI',
  title: 'Brazil Agriculture Investment Loan / Investimento'
},{
  id: 'CG',
  title: 'Brazil Cargo'
},{
  id: 'CS',
  title: 'Construction'
},{
  id: 'DS',
  title: 'Distribution'
},{
  id: 'EN',
  title: 'Large Market Enterprise'
},{
  id: 'EX',
  title: 'Small Market Expenses'
},{
  id: 'HC',
  title: 'Healthcare'
},{
  id: 'LP',
  title: 'Visa Large-Purchase Advantage (VLPA)'
},{
  id: 'MA',
  title: 'Visa Mobile Agent'
},{
  id: 'MB',
  title: 'Interoperable Mobile Branchless Banking'
},{
  id: 'MG',
  title: 'Visa Mobile General'
},{
  id: 'MX',
  title: 'Merchant Agent and Merchant Payment'
},{
  id: 'VF',
  title: 'Brazil Food or Supermarket / Alimentacao Visa Vale'
},{
  id: 'VF',
  title: 'Brazil Fuel/Flex Visa Vale '
},{
  id: 'VM',
  title: 'Visa Vale Meal Voucher'
},{
  id: 'VN',
  title: 'Visa Vale Food Voucher'
},{
  id: 'VR',
  title: 'Brazil Food or Restaurant / Refeicao Visa Vale'
}];
