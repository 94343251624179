import DS from "ember-data";
import Application from "./application";
import Ember from "ember";

export default Application.extend({
  account_number: DS.attr("string"),
  address: DS.attr("string"),
  auto_pay: DS.attr("boolnum"),
  bill_via_email_enrollment_status: DS.attr("string"),
  text_to_pay_enrollment_status: DS.attr("string"),
  paperless_billing: DS.attr("boolnum"),
  cell_phone: DS.attr("string"),
  city: DS.attr("string"),
  company_name: DS.attr("string"),
  contact_api_id: DS.attr("string"),
  contact_balance: DS.attr("string"),
  created_ts: DS.attr("ts"),
  email: DS.attr("string"),
  email_blacklist: DS.attr("array"),
  email_trx_receipt: DS.attr("boolean"),
  first_name: DS.attr("string"),
  header_message: DS.attr("string"),
  header_message_type_id: DS.attr("string"),
  hide_blind_payment: DS.attr("boolnum"),
  home_phone: DS.attr("string"),
  last_name: DS.attr("string"),
  location_id: DS.attr("string"),
  office_ext_phone: DS.attr("string"),
  office_phone: DS.attr("string"),
  password: DS.attr("string"),
  state: DS.attr("string"),
  update_if_exists: DS.attr("number"),
  zip: DS.attr("string"),

  // Custom fields
  contact_c1: DS.attr("string"),
  contact_c2: DS.attr("string"),
  contact_c3: DS.attr("string"),

  // Computed Properties
  contact_header_title: Ember.computed(
    "contact_api_id",
    "full_name",
    "contact_balance",
    "account_number",
    function () {
      var contact_balance = this.get("contact_balance");
      var display_balance = "";
      if (contact_balance && contact_balance !== null) {
        display_balance =
          " <small>(balance: $" + contact_balance.currency(2) + ")</small>";
      }

      var link_icon = "";
      if (this.get("contact_api_id")) {
        link_icon +=
          ' <small><i class="fas fa-exchange-alt grid-icon grid-icon-user" title="Synced via API id ' +
          this.get("contact_api_id") +
          '"></i></small>';
      }
      if (this.get("user.id")) {
        link_icon +=
          ' <small><i class="fas fa-user grid-icon grid-icon-user" title="Username: ' +
          this.get("user.username") +
          '"></i></small>';
      }
      return (
        (this.get("full_name") || "") +
        " " +
        (this.get("account_number") !== null
          ? "<small>(account: " + this.get("account_number") + ")</small>"
          : "") +
        display_balance +
        link_icon
      );
    }
  ),
  full_name: Ember.computed("first_name", "last_name", function () {
    return (this.get("first_name") || "") + " " + (this.get("last_name") || "");
  }),
  title: Ember.computed("full_name", function () {
    return this.get("full_name");
  }),
  titleWithAccountNumber: Ember.computed(
    "title",
    "account_number",
    function () {
      var account_number = this.get("account_number");
      return account_number
        ? (this.get("title") + " - " + account_number).trim()
        : this.get("title").trim();
    }
  ),
  titleWithAccountNumberAndLocation: Ember.computed(
    "title",
    "account_number",
    "location.id",
    function () {
      var account_number = this.get("account_number");
      var hasLocation = this.get("location.id");
      if (hasLocation) {
        let location = this.get("location");
        return account_number
          ? (
              this.get("title") +
              " - " +
              account_number +
              " - " +
              location.get("name")
            ).trim()
          : (this.get("title") + " - " + location.get("name")).trim();
      } else {
        return account_number
          ? (this.get("title") + " - " + account_number).trim()
          : this.get("title").trim();
      }
    }
  ),

  // Defined model relationships
  changelogs: DS.hasMany("changelog"),
  created_user: DS.belongsTo("user"),
  location: DS.belongsTo("location"),
  notes: DS.hasMany("note"),
  user: DS.belongsTo("user"),
});
