import Ember from 'ember';

export default Ember.Route.extend({

  model: function(params) {
    return params;
  },

  setupController: function(controller) {
    controller.set('errorMessages', null);
    controller.set('errors', []);
    controller.set('username', null);
  }
});