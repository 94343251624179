import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_number: DS.attr('string'),
  active_session_count_max: DS.attr('string'),
  address: DS.attr('string'),
  branding_domain_url: DS.attr('string'),
  cell_phone: DS.attr('string'),
  city: DS.attr('string'),
  company_name: DS.attr('string'),
  contact_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  date_of_birth: DS.attr('string'),
  domain_id: DS.attr('string'),
  email: DS.attr('string'),
  email_blacklist: DS.attr('array'),
  feature_flags: DS.attr('array'),
  first_name: DS.attr('string'),
  home_phone: DS.attr('string'),
  last_login_ts: DS.attr('ts'),
  last_name: DS.attr('string'),
  log_api_response_body_ts: DS.attr('ts'),
  login_attempts: DS.attr('string'),
  office_ext_phone: DS.attr('string'),
  office_phone: DS.attr('string'),
  primary_location_id: DS.attr('string'),
  state: DS.attr('string'),
  status_id: DS.attr('number'),
  temp_confirm_password: DS.attr('string'),
  temp_password: DS.attr('string'),
  terms_accepted_ts: DS.attr('ts'),
  terms_agree_ip: DS.attr('string'),
  terms_condition_id: DS.attr('string'),
  tz: DS.attr('string'),
  ui_prefs: DS.attr(),
  user_api_key: DS.attr('string'),
  user_hash_key: DS.attr('string'),
  user_type_id: DS.attr('string'),
  username: DS.attr('string'),
  zip: DS.attr('string'),

  // Computed Properties
  full_name: Ember.computed('first_name', 'last_name', function() {
    return (this.get('first_name') || '') + ' ' + (this.get('last_name') || '');
  }),
  isActive: Ember.computed('status_id', function() {
    return this.get('status_id') > 0;
  }),
  isClientAdmin: Ember.computed('user_type_id', function() {
    return parseInt(this.get('user_type_id')) === 250;
  }),
  isDomainMismatch: Ember.computed('domain_id', 'primary_location', function() {
    var domain_id = this.get('domain_id');
    if (!domain_id) {
      return false;
    }
    var branding_domain_id = this.get('primary_location.branding_domain_id');
    if (!branding_domain_id) {
      return false;
    }
    if (domain_id !== branding_domain_id) {
      return true;
    }
    return false;
  }),
  isLocked: Ember.computed('login_attempts', function() {
    return (this.get('login_attempts') > 3) ? true : false;
  }),
  notRemovable: Ember.computed('location_users', function() {
    return (this.get('location_users.length') <= 1);
  }),
  title: Ember.computed('username', function() {
    return this.get('username');
  }),
  user_type_title: Ember.computed('user_type_id', function() {
    switch(this.get('user_type_id')){
      case '100':
      return 'Contact User';
      case '200':
      return 'Location User';
      case '250':
      return 'Location Admin';
      case '300':
      return 'Support Level 1';
      case '400':
      return 'Support Level 2';
      case '500':
      return 'Support Level 3';
      case '600':
      return 'Support Level 4';
      case '700':
      return 'Support Level 5';
      case '800':
      return 'Support Level 6';
      case '900':
      return 'Support Level 7';
      case '1000':
      return 'Admin';
    }
  }),

  // Defined model relationships
  active_notification_alerts: DS.hasMany('notificationalert'),
  auth_roles: {
    deserialize: 'records'
  },
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
  domain: DS.belongsTo('domain'),
  location_users: DS.hasMany('locationuser'),
  primary_location: DS.belongsTo('location'),
});
