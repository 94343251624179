import Ember from "ember";
import jQuery from "jquery";
import { copy } from "ember-copy";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  helperFunctions: Ember.inject.service("helper-functions"),
  sessionService: Ember.inject.service("session"),
  salesOfficeController: Ember.inject.controller(
    "components/modals/addsalesoffice"
  ),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLoadingSalesOffice: false,
  isLocationsProductTransaction: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "producttransaction.errors.messages",
    function () {
      this.set("errorMessages", this.get("producttransaction.errors.messages"));
    }
  ),

  // Set controller params
  billingAccountLocationID: null,
  billingAccountLocations: null,
  isLoading: false,
  isEditing: true,
  showIAP:false,
  notEditing: Ember.computed("isEditing", function () {
    return !this.get("isEditing");
  }),
  isCC: false,
  isACH: false,
  default_trans_types: null,
  processors: Ember.computed(function () {
    return this.get("applicationData.processors");
  }),
  cc_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.cc_default_trans_types");
  }),
  ach_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.ach_default_trans_types");
  }),
  country_data: Ember.computed(function () {
    return this.get("applicationData.country_code");
  }),
  isPayaTerminalIds: Ember.computed(
    "producttransaction.processor_data.terminals.[]",
    function () {
      return this.get("producttransaction.processor_data.terminals.length") > 0;
    }
  ),
  payaTerminals: Ember.computed(
    "producttransaction.processor_data.terminals.[]",
    "payaTerminalsWithErrors.[]",
    function () {
      var terminals = copy(
        this.get("producttransaction.processor_data.terminals"),
        true
      );
      var payaTerminalsWithErrors = this.get("payaTerminalsWithErrors");
      terminals.map(function (terminal, index) {
        if (terminal.required) {
          terminal.required_dl =
            terminal.required.indexOf("driver_license") !== -1;
          terminal.required_verification_identity =
            terminal.required.indexOf("identity_verification") !== -1;
        }
        if (terminal.actions) {
          terminal.required_verification_check =
            terminal.actions.indexOf("avsonly") !== -1;
        }
        if (payaTerminalsWithErrors) {
          payaTerminalsWithErrors.map(function (i) {
            if (index === i) {
              terminal.hasError = true;
            }
          });
        }
        return terminal;
      });
      return terminals;
    }
  ),
  canSeeIAP: Ember.computed("producttransaction.enable_IAP", function () {
    var userId = this.get('session.authenticated.user_type_id'),
    country_code = this.get('producttransaction.processor_data.country_code');
   if (userId == 300 && country_code != '124') {
      return true;
    } else {
      return false;
  }
 }),
 canEditIAP: Ember.computed("producttransaction.enable_IAP", function () {
  var userId = this.get('session.authenticated.user_type_id'),
  country_code = this.get('producttransaction.processor_data.country_code');;
   if (userId >= 400  && country_code != '124') {
      return true;
    } else {
      return false;
  }
 }),
  canHPP: Ember.computed(
    "producttransaction.{payment_method,processor,isSubProcessorPaya}",
    function () {
      if (this.get("producttransaction.payment_method") === "cc") {
        return true;
      } else if (this.get("producttransaction.payment_method") === "ach") {
        if (this.get("producttransaction.isSubProcessorPaya")) {
          var terminals = this.get(
            "producttransaction.processor_data.terminals"
          );
          var hasValidSECCode = false;
          if (!terminals || terminals.get("length") === 0) {
            return false;
          }
          terminals.map(function (terminal) {
            var sec_code = terminal.sec_code;
            if (
              sec_code === "CCD" ||
              sec_code === "PPD" ||
              sec_code === "WEB"
            ) {
              hasValidSECCode = true;
            }
          });
          return hasValidSECCode;
        } else {
          return true;
        }
      }
    }
  ),

  changedVtCvv: Ember.observer(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var vt_street = this.get("producttransaction.vt_cvv");
      if (!vt_street) {
        this.set("producttransaction.vt_require_cvv", false);
      }
    }
  ),
  vtCvvRequired: Ember.computed(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_cvv")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtStreet: Ember.observer(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      var vt_street = this.get("producttransaction.vt_street");
      if (!vt_street) {
        this.set("producttransaction.vt_require_street", false);
      }
    }
  ),
  vtStreetRequired: Ember.computed(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_street")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtZip: Ember.observer(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      var vt_zip = this.get("producttransaction.vt_zip");
      if (!vt_zip) {
        this.set("producttransaction.vt_require_zip", false);
      }
    }
  ),
  vtZipRequired: Ember.computed(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_zip")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  batchCloseAutomatic: Ember.computed(
    "producttransaction.processor_data.batch_close_type",
    function () {
      return (
        this.get("producttransaction.processor_data.batch_close_type") ===
        "automatic"
      );
    }
  ),
  changeProcessor: Ember.observer("processor", function () {
    var self = this;
    var processors = this.get("processors");
    var processor = this.get("processor") || null;
    var webUrl = window.ENV.APP.API_URL;
    var newWebUrl = webUrl.replace(/.*?(?=payaconnect\.com)/, "");

    self.set("isPaymentMethodCc", false);
    self.set("isPaymentMethodAch", false);
    self.set("isProcessorTsys", false);
    self.set("isProcessorNashville", false);
    self.set("isProcessorFdnash", false);
    self.set("isProcessorElavon", false);
    self.set("isProcessorPaya", false);
    self.set("isProcessorProfitStars", false);
    self.set("isProcessorVantiv", false);

    processors.forEach(function (p) {
      // self.set('isProcessor' + p.id.capitalize(), p.id === processor);
      if (p.id === processor) {
        self.set("producttransaction.processor", p.processor);
        self.set("isProcessorTsys", p.sub_processor === "tsys");
        self.set("isProcessorNashville", p.sub_processor === "nashville");
        self.set("isProcessorFdnash", p.processor === "zgate");
        self.set("isProcessorElavon", p.sub_processor === "elavon");
        self.set("isProcessorPaya", p.sub_processor === "paya");
        self.set("isProcessorProfitStars", p.sub_processor === "profitstars");
        self.set("isProcessorVantiv", p.sub_processor === "vantiv610");

        self.set("isPaymentMethod" + p.type.capitalize(), true);
        self.set("producttransaction.payment_method", p.type);
        self.set(
          "default_trans_types",
          self.get(p.type + "_default_trans_types")
        );
        self.set(
          "producttransaction.default_transaction_type",
          self.get(p.type + "_default_trans_types")[0].id
        );
        self.set(
          "producttransaction.processor_data.sub_processor",
          p.sub_processor
        );
        // self.set('isFirstData', p.sub_processor === 'fdnash' || p.sub_processor === 'nashville');
        // self.set('isNashDirect', p.sub_processor === 'nashville');
        // self.set('isTsys', p.sub_processor === 'vital');

        if (p.type === "ach") {
          self.set("producttransaction.mcc", "0000");
          self.set(
            "producttransaction.receipt_add_account_above_signature",
            "I authorize Merchant, to electronically debit my Bank Account indicated above for any fees due Merchant. If this item is dishonored for any reason, I authorize Merchant to initiate an additional electronic debit to the same Account for a returned item fee in the amount of $30. My signature and/or electronic submission on this agreement below shall be my authorization to execute these electronic transactions. This authorization shall remain in full force and effect until  Merchant has received written notification from me of its termination in such a time and manner as to afford  Merchant a reasonable opportunity to act on it."
          );
          if (newWebUrl === "payaconnect.com") {
            self.set(
              "producttransaction.receipt_vt_above_signature",
              "By authorizing this transaction, customer agrees that merchant may convert this transaction into an Electronic Funds Transfer (EFT) transaction or paper draft, and to debit this account for the amount of the transaction. Additionally, in the event this draft or EFT is returned unpaid, a service fee, as allowable by law, will be charged to this account via EFT or draft. In the event you choose to revoke this authorization, please do so by contacting the merchant directly. Please note that processing times may not allow for revocation of this authorization."
            );
          }
          if (p.sub_processor !== "paya") {
            self.set("producttransaction.processor_data.terminals", []);
            self.set("producttransaction.processor_data.company_name", null);
            self.set(
              "producttransaction.processor_data.merchant_password",
              null
            );
            self.set(
              "producttransaction.processor_data.merchant_username",
              null
            );
          }
        } else {
          self.set("producttransaction.mcc", "");
          self.set("producttransaction.processor_data.merchant_tax_id", "");
          self.set(
            "producttransaction.receipt_add_account_above_signature",
            null
          );
          self.set(
            "producttransaction.receipt_vt_above_signature",
            "I agree to pay the above total according to my card issuer agreement."
          );
        }
      }
    });
  }),

  hosted_payment_page_allow_observer: Ember.observer(
    "producttransaction.hosted_payment_page_allow",
    function () {
      var hosted_payment_page_allow = this.get(
        "producttransaction.hosted_payment_page_allow"
      );
      this.set(
        "producttransaction.hosted_payment_page_max_allowed",
        hosted_payment_page_allow ? "5" : null
      );
    }
  ),

  level3level3_allow_observer: Ember.observer(
    "producttransaction.level3_allow",
    function () {
      var level3_allow = this.get("producttransaction.level3_allow");
      var level3_default = this.get("producttransaction.level3_default");
      var defaultLevel3 = this.get("applicationData.level3_defaults");
      if (level3_allow && !level3_default) {
        this.set("producttransaction.level3_default", defaultLevel3);
      } else if (!level3_allow) {
        this.set("producttransaction.level3_default", null);
        this.set("level3HasError", false);
        this.set("isViewingL3", false);
      }
    }
  ),

  cauEnabledObserver: Ember.observer("cauEnabled", function () {
    if (!this.get("cauEnabled")) {
      this.set("producttransaction.cau_account_number", null);
      this.set("producttransaction.cau_subscribe_type_id", "0");
    }
  }),

  payfac_enable_observer: Ember.observer(
    "producttransaction.payfac_enable",
    function () {
      var payfac_enable = this.get("producttransaction.payfac_enable");
      var payfac_data = this.get(
        "producttransaction.processor_data.payfac_data"
      );
      if (payfac_enable && Object.keys(payfac_data).length === 0) {
        this.set("producttransaction.processor_data.payfac_data", {
          amex: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_street_address: this.get("location.address1"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
          mc: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
        });
      } else if (!payfac_enable) {
        this.set("producttransaction.processor_data.payfac_data", {});
        this.set("isViewingPayfacData", false);
      }
    }
  ),
  country_code_observer: Ember.observer("producttransaction.processor_data.country_code", function() {
    var country_code = this.get("producttransaction.processor_data.country_code");
    if(country_code === '124'){
      this.set('producttransaction.enable_IAP', false);
      this.set('showIAP',false);
    } else {
      this.set('showIAP',true);
    }
  }),
  // Functions

  getLocationBillingAccounts: function () {
    var self = this;
    var billingAccountLocationID = this.get("billingAccountLocation.id");
    var billingAccounts = this.get("store").query("locationbillingaccount", {
      location_id: billingAccountLocationID,
      sort: "name",
      page_size: "100",
      fields: "id,title",
      expand: "location",
    });
    billingAccounts.then(function (result) {
      self.set("billingAccounts", result);
      if (!self.get("billingAccountLocation")) {
        self.set("billingAccountLocation", { id: "", name: "" });
      }
      if (!self.get("billingAccountLocation.name")) {
        self.set(
          "billingAccountLocation.name",
          result.get("firstObject").get("location.name")
        );
      }
    });
  },

  // Actions
  actions: {
    delete: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    reActivateProduct: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function () {
      // Bail if we are already loading
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var location = this.get("location");
      var producttransaction = this.get("producttransaction");
      this.set("producttransaction.country_code", producttransaction.processor_data.country_code);
      
      producttransaction.get("errors").clear();
      var level3_default = this.get("producttransaction.level3_default");
      if (level3_default !== "" && producttransaction.get("level3_allow")) {
        this.set(
          "producttransaction.level3_default",
          JSON.parse(level3_default)
        );
      } else {
        this.set("producttransaction.level3_default", null);
      }
      this.set("payaTerminalsWithErrors", []);

      producttransaction.save().then(
        function () {
          noty({
            text: "Deposit Account Service saved successfully!",
          });
          self.set("isLoading", false);
          self.transitionToRoute("locations.products", location.id);
        },
        function (errors) {
          if (errors.errors) {
            var payaTerminalsWithErrors = [];
            errors.errors.map(function (err) {
              if (err.source.pointer === "data/attributes/otherErrors") {
                let prop = err.source.prop.split(".");
                payaTerminalsWithErrors.push(Number(prop[2]));
              }
            });
            self.set("defaultlevel3errors", errors.errors);
            self.set("payaTerminalsWithErrors", payaTerminalsWithErrors);
          }
          window.scrollTo(0, 0);
          self.set(
            "producttransaction.level3_default",
            JSON.stringify(self.get("producttransaction.level3_default"))
          );
          self.set("isLoading", false);
        }
      );
    },
    reset: function () {
      var location = this.get("location");
      this.transitionToRoute("locations.products", location.id);
    },
    back: function () {
      var location = this.get("location");
      this.transitionToRoute("locations.products", location.id);
    },
    addSalesOffice: function () {
      var controller = this.get("salesOfficeController");
      var new_salesoffice = this.get("store").createRecord("salesoffice", {
        title: null,
        contact_name: null,
        phone: null,
        misc: null,
        email: null,
        location_id: this.get("producttransaction.location_id"),
      });
      this.set("producttransaction.sales_office_id", null);
      controller.set("salesoffice", new_salesoffice);
      controller.set("sender", "addproducttransaction");
      this.send("openModal", "components/modals/addsalesoffice");
    },
    updateSalesOffice: function (data) {
      var self = this;
      var new_sales_office_id = self.get("new_sales_office_id");
      var salesoffice = this.get("store").query("salesoffice", {
        id: new_sales_office_id,
        title: data,
        sort: "title",
        page_size: "10",
        fields: "id,title",
      });
      salesoffice.then(function (result) {
        self.set("salesoffice", result);
        Ember.run.later(function () {
          if (new_sales_office_id) {
            self.set(
              "producttransaction.sales_office_id",
              self.get("new_sales_office_id")
            );
            self.set("new_sales_office_id", null);
          }
        }, 1);
        self.set("isLoadingSalesOffice", false);
      });
    },
    viewDefaults: function () {
      this.toggleProperty("isViewingL3");
    },
    addTerminalID: function (e) {
      var value = this.get("payaach_terminal_id");
      if (!value) {
        return;
      }
      var ids = this.get("producttransaction.processor_data.terminals");
      ids.pushObject({
        terminal_id: value,
      });
      this.set("payaach_terminal_id", null);
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      jQuery("#producttransaction_payaach_terminal_id").focus();
    },
    removeTerminalID: function (index) {
      var ids = this.get("producttransaction.processor_data.terminals");
      var payaTerminalsWithErrors = this.get("payaTerminalsWithErrors");
      if (payaTerminalsWithErrors) {
        var tempTerminalsWithErrors = [];
        payaTerminalsWithErrors.map(function (i) {
          if (index !== i) {
            if (i > index) {
              tempTerminalsWithErrors.push(i - 1);
            } else {
              tempTerminalsWithErrors.push(i);
            }
          }
        });
        this.set("payaTerminalsWithErrors", tempTerminalsWithErrors);
      }
      ids.removeAt(index);
    },
    updatebillingAccountLocation: function (data) {
      var self = this;
      var billingAccountLocations = this.get("store").query("location", {
        name: data,
        sort: "name",
        page_size: "10",
        fields: "id,name",
      });
      billingAccountLocations.then(function (result) {
        self.set("billingAccountLocations", result);
      });
    },
    updatebillingGroups: function (data) {
      var self = this;
      if (
        this.get("session.authenticated.resources.v2-productbillinggroups-get")
      ) {
        var productbillinggroups = this.get("store").query(
          "productbillinggroup",
          {
            title: data,
            sort: "title",
            page_size: "10",
            fields: "id,title",
          }
        );
        productbillinggroups.then(function (result) {
          self.set("productbillinggroups", result);
        });
      }
    },
    viewPayfacData: function () {
      this.toggleProperty("isViewingPayfacData");
    },
  },
});
