import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  /////////     DEFAULT PROPERTIES     //////////

  isEditing: true,
  isLoading: false,
  selectedDeveloper: '',
  selectedDevelopers: [],
  selectedLocation: '',
  selectedLocationRelationship: 'direct',
  selectedLocations: [],
  selectedUser: '',
  selectedUsers: [],
  selectedUserType: '',
  selectedUserTypes: [],

  /////////     COMPUTED PROPERTIES     //////////

  disabledUserTypeDropdown: Ember.computed('isSelectedLocations', 'isSelectedDevelopers', 'isCriteria.location', 'isCriteria.developer', function() {
    if(this.get('isCriteria.developer')) {
      return !this.get('isSelectedDevelopers');
    } else if(this.get('isCriteria.location')) {
      return !this.get('isSelectedLocations');
    }
  }),
  isCriteriaUserTypesArray: Ember.computed('userTypeOptionsArray', function() {
    return this.get('userTypeOptionsArray');
  }),
  isLocationRelationshipAll: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'all' ? true : false;
  }),
  isLocationRelationshipChild: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'child' ? true : false;
  }),
  isLocationRelationshipDirect: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'direct' ? true : false;
  }),
  isNoSelectedRecipients: Ember.computed('isSelectedDevelopers', 'isSelectedLocations', 'isSelectedUsers', 'isSelectedUserTypes', function() {
    var isSelectedDevelopers = this.get('isSelectedDevelopers');
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var isSelectedUserTypes = this.get('isSelectedUserTypes');
    var notAny = !isSelectedDevelopers && !isSelectedLocations && !isSelectedUsers && !isSelectedUserTypes;
    return notAny ? true : false;
  }),
  isSelectedDevelopers: Ember.computed('selectedDevelopers.[]', function() {
    return this.get('selectedDevelopers.length') > 0;
  }),
  isSelectedDevelopersAndLocationsOrUsers: Ember.computed('isSelectedDevelopers', 'isSelectedLocations', 'isSelectedUsers', function() {
    var isSelectedDevelopers = this.get('isSelectedDevelopers');
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var isDevelopersAndLocations = isSelectedDevelopers && isSelectedLocations;
    var isDevelopersAndUsers = isSelectedDevelopers && isSelectedUsers;
    return isDevelopersAndLocations || isDevelopersAndUsers ? true : false;
  }),
  isSelectedLocations: Ember.computed('selectedLocations.[]', function() {
    return this.get('selectedLocations.length') > 0;
  }),
  isSelectedLocationsAndUsers: Ember.computed('isSelectedLocations', 'isSelectedUsers', function() {
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var isLocationsAndUsers = isSelectedLocations && isSelectedUsers;
    return isLocationsAndUsers ? true : false;
  }),
  isSelectedUsers: Ember.computed('selectedUsers.[]', function() {
    return this.get('selectedUsers.length') > 0;
  }),
  isSelectedUserTypes: Ember.computed('selectedUserTypes.[]', function() {
    return this.get('selectedUserTypes.length') > 0;
  }),

  /////////     OBSERVERS     //////////

  updateSelectedDevelopers: Ember.observer('selectedDeveloper', function() {
    var selectedDevelopers = this.get('selectedDevelopers');
    var selectedDeveloper = this.get('selectedDeveloper');
    if (selectedDeveloper) {
      var isCriteriaDevelopersArray = this.get('isCriteriaDevelopersArray');
      var selectedDeveloperTitle = '';
      isCriteriaDevelopersArray.map(function(dev) {
        if (dev.get('id') === selectedDeveloper) {
          selectedDeveloperTitle = dev.get('title');
        }
      });
      selectedDevelopers.pushObject({
        id: selectedDeveloper,
        title: selectedDeveloperTitle
      });
      this.set('selectedDeveloper', null);
    }
  }),
  updateSelectedLocations: Ember.observer('selectedLocation', function() {
    var selectedLocations = this.get('selectedLocations');
    var selectedLocation = this.get('selectedLocation');
    if (selectedLocation) {
      var isCriteriaLocationsArray = this.get('isCriteriaLocationsArray');
      var selectedLocationTitle = '';
      isCriteriaLocationsArray.map(function(loc) {
        if (loc.get('id') === selectedLocation) {
          selectedLocationTitle = loc.get('title');
        }
      });
      selectedLocations.pushObject({
        id: selectedLocation,
        title: selectedLocationTitle
      });
      this.set('selectedLocation', null);
    }
  }),
  updateselectedUsers: Ember.observer('selectedUser', function() {
    var selectedUsers = this.get('selectedUsers');
    var selectedUser = this.get('selectedUser');
    if (selectedUser) {
      var isCriteriaUsersArray = this.get('isCriteriaUsersArray');
      var selectedUserTitle = '';
      isCriteriaUsersArray.map(function(user) {
        if (user.get('id') === selectedUser) {
          selectedUserTitle = user.get('username');
        }
      });
      selectedUsers.pushObject({
        id: selectedUser,
        title: selectedUserTitle
      });
      this.set('selectedUser', null);
    }
  }),
  updateSelectedUserType: Ember.observer('selectedUserTypes.@each', 'selectedUserType.@each', function() {
    Ember.run.once(this, 'selectedUserTypesDidChange');
  }),

  /////////     FUNCTIONS     //////////

  selectedUserTypesDidChange: function() {
    var userTypes = this.get('userTypes');
    var selectedUserTypes = this.get('selectedUserTypes');
    var selectedUserType = this.get('selectedUserType');
    if (selectedUserType) {
      var selectedUserTypeTitle = '';
      userTypes.map(function(userTypeId) {
        if (userTypeId.get('id') === selectedUserType) {
          selectedUserTypeTitle = userTypeId.get('title');
        }
      });
      selectedUserTypes.pushObject({
        id: selectedUserType,
        title: selectedUserTypeTitle
      });
      this.set('selectedUserType', null);
    }
    var filteredUserTypeOptions = userTypes.filter(function(option) {
      var tempSelectedUserTypes = selectedUserTypes.map(function(usertype) {
        return usertype.id;
      });
      return tempSelectedUserTypes.indexOf(option.id) === -1;
    });
    this.set('userTypeOptionsArray', filteredUserTypeOptions);
  },

  /////////     ACTIONS     //////////

  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    back: function() {
      this.transitionToRoute('admin.mailboxmessages.index');
    },
    isCriteriaDevelopersArrayFind: function(data) {
      var self = this;
      var isCriteriaDevelopersArray = this.get('store').query('developer', {
        title: data,
        sort: 'title',
        page_size: '10'
      });
      isCriteriaDevelopersArray.then(function(result) {
        var selectedDevelopers = self.get('selectedDevelopers');
        selectedDevelopers = selectedDevelopers.map(function(dev) {
          return dev.id;
        });
        var filteredArray = result.filter(function(developer) {
          if (selectedDevelopers.indexOf(developer.id) === -1) {
            return developer;
          }
        });
        self.set('isCriteriaDevelopersArray', filteredArray);
      });
    },
    isCriteriaLocationsArrayFind: function(data) {
      var self = this;
      var isCriteriaLocationsArray = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10'
      });
      isCriteriaLocationsArray.then(function(result) {
        var selectedLocations = self.get('selectedLocations');
        selectedLocations = selectedLocations.map(function(loc) {
          return loc.id;
        });
        var filteredArray = result.filter(function(location) {
          if (selectedLocations.indexOf(location.id) === -1) {
            return location;
          }
        });
        self.set('isCriteriaLocationsArray', filteredArray);
      });
    },
    isCriteriaUsersArrayFind: function(data) {
      var self = this;
      var isCriteriaUsersArray = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10'
      });
      isCriteriaUsersArray.then(function(result) {
        var selectedUsers = self.get('selectedUsers');
        selectedUsers = selectedUsers.map(function(user) {
          return user.id;
        });
        var filteredArray = result.filter(function(user) {
          if (selectedUsers.indexOf(user.id) === -1) {
            return user;
          }
        });
        self.set('isCriteriaUsersArray', filteredArray);
      });
    },
    removeSelectedRecipient: function(index, emberArrayModel) {
      var arr = this.get(emberArrayModel);
      arr.map(function(recipient) {
        if (index === recipient.id) {
          arr.removeObject(recipient);
        }
      });
      this.set(emberArrayModel, arr);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var mailboxmessage = this.get('mailboxmessage');
      this.set('mailboxmessage.user_criteria.user_id', this.get('selectedUsers').map(function(user) {
        return user.id;
      }));
      this.set('mailboxmessage.user_criteria.user_type_id', this.get('selectedUserTypes').map(function(usertype) {
        return usertype.id;
      }));
      this.set('mailboxmessage.user_criteria.developer_id', this.get('selectedDevelopers').map(function(dev) {
        return dev.id;
      }));
      this.set('mailboxmessage.user_criteria.location_id', this.get('selectedLocations').map(function(loc) {
        return loc.id;
      }));
      this.set('mailboxmessage.user_criteria.relationship', this.get('selectedLocationRelationship'));

      mailboxmessage.save().then(function() {
        noty({
          text: 'Mailbox message added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.mailboxmessages.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    sendMessageTo: function(type) {
      let objectPath = 'isCriteria.' + type;
      this.set('isCriteria', {});
      this.set(objectPath, true);
    },
  },

  /////////     ERROR VALIDATION     //////////

  errorMessages: null,
  updateErrorMessages: Ember.observer('mailboxmessage.errors.messages', function() {
    this.set('errorMessages', this.get('mailboxmessage.errors.messages'));
  }),

});
