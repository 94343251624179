import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  /////////     QUERY PARAMS     //////////

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'developer_company_name',
    'location_created_ts',
    'location_created_ts_from',
    'location_created_ts_to',
    'location_created_user_id',
    'parent_location_id',
    'product_transaction_created_ts',
    'product_transaction_created_ts_from',
    'product_transaction_created_ts_to',
    'product_transaction_created_user_id',
    'product_transaction_id',
    'product_transaction_payment_method',
    'product_transaction_sub_processor',
  ],

  /////////     DEFAULT PROPERTIES     //////////

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      location_created_ts: {
        'name': 'location_created_ts',
        'title': 'Location Created<br>Date',
        'type': 'shortdate',
        'sortable': true
      },
      aba: {
        'name': 'aba',
        'title': 'Aba',
        'type': 'string',
        'sortable': true
      },
      auto_batch_close_time: {
        'name': 'auto_batch_close_time',
        'title': 'Auto Batch<br>Close Time',
        'type': 'string',
        'sortable': true
      },
      branding_domain: {
        'name': 'branding_domain',
        'title': 'Branding Domain',
        'type': 'string',
        'sortable': true
      },
      developer_company: {
        'name': 'developer_company_name',
        'title': 'Developer<br>Company Name',
        'type': 'string',
        'sortable': true
      },
      location_name: {
        'name': 'location_name',
        'title': 'Location<br>Name',
        'type': 'string',
        'sortable': true,
        'action': function(onboardingdetail) {
          self.send('viewLocation', onboardingdetail);
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      parent_location_name: {
        'name': 'parent_location_name',
        'title': 'Parent Location Name',
        'type': 'string',
        'sortable': true,
        'action': function(onboardingdetail) {
          self.send('viewLocationParent', onboardingdetail);
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      partner: {
        'name': 'partner',
        'title': 'Partner',
        'type': 'string',
        'sortable': true
      },
      dda: {
        'name': 'dda',
        'title': 'Dda',
        'type': 'string',
        'sortable': true
      },
      location_date_created: {
        'name': 'location_date_created',
        'title': 'Location<br>Date Created',
        'type': 'string',
        'sortable': true
      },
      location_creted_by: {
        'name': 'location_created_by',
        'title': 'Location<br>Created By',
        'type': 'string',
        'sortable': true
      },
      file_storage: {
        'name': 'file_storage',
        'title': 'File Storage',
        'type': 'string',
        'sortable': true
      },
      account_vault: {
        'name': 'account_vault',
        'title': 'Account Vault',
        'type': 'string',
        'sortable': true
      },
      recurring: {
        'name': 'recurring',
        'title': 'Recurring Billing',
        'type': 'string',
        'sortable': true
      },
      portfolio_name:  {
        'name': 'portfolio_name',
        'title': 'Portfolio Name',
        'type': 'string',
        'sortable': true
      },
      agent_code: {
        'name': 'agent_code',
        'title': 'Agent Code',
        'type': 'string',
        'sortable': true
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true
      },
      processor: {
        'name': 'processor',
        'title': 'Processor',
        'type': 'string',
        'sortable': true
      },
      debit: {
        'name': 'debit',
        'title': 'Debit',
        'type': 'string',
        'sortable': true
      },
      credit: {
        'name': 'credit',
        'title': 'Credit',
        'type': 'string',
        'sortable': true
      },
      refund: {
        'name': 'refund',
        'title': 'Refund',
        'type': 'string',
        'sortable': true
      },
      industry_type: {
        'name': 'industry_type',
        'title': 'Industry Type',
        'type': 'string',
        'sortable': true
      },
      p_trx_fee_ach: {
        'name': 'p-trx-fee-ach',
        'title': 'Transaction<br>Fee Ach',
        'type': 'string',
        'sortable': true
      },
      m_ach_min_fee: {
        'name': 'm-ach-min-fee',
        'title': 'Ach<br>Min Fee',
        'type': 'string',
        'sortable': true
      },
      p_trx_fee_cc: {
        'name': 'p-trx-fee-cc',
        'title': 'Transaction<br>Fee CC',
        'type': 'string',
        'sortable': true
      },
      m_access_fee: {
        'name': 'm-access-fee',
        'title': 'Access Fee',
        'type': 'string',
        'sortable': true
      },
      transaction_account_created_date: {
        'name': 'transaction_account_created_date',
        'title': 'Deposit Account<br>Created Date',
        'type': 'string',
        'sortable': true
      },
      transaction_account_created_by: {
        'name': 'transaction_account_created_by',
        'title': 'Deposit Account Created By',
        'type': 'string',
        'sortable': true
      },
      product_transaction_sub_processor: {
        'name': 'product_transaction_sub_processor',
        'title': 'Deposit Account<br>Sub Processor',
        'type': 'string',
        'sortable': true
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location Id',
        'type': 'string',
        'sortable': true,
        'action': function(onboardingdetail) {
          self.send('viewLocation', onboardingdetail);
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      parent_location_id: {
        'name': 'parent_location_id',
        'title': 'Parent<br>Location Id',
        'type': 'string',
        'sortable': true,
        'action': function(onboardingdetail) {
          self.send('viewLocationParent', onboardingdetail);
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      }
    };

    var listOfCols = ['location_created_ts', 'aba', 'auto_batch_close_time', 'branding_domain', 'developer_company', 'location_name', 'parent_location_name', 'partner', 'dda', 'location_date_created', 'location_creted_by', 'file_storage', 'account_vault', 'recurring', 'portfolio_name', 'agent_code', 'title', 'processor', 'debit', 'credit', 'refund', 'industry_type', 'p_trx_fee_ach', 'm_ach_min_fee', 'p_trx_fee_cc', 'm_access_fee', 'transaction_account_created_date', 'transaction_account_created_by', 'product_transaction_sub_processor', 'location_id', 'parent_location_id'];
    var listOfMobileCols = ['location_created_ts', 'aba', 'auto_batch_close_time', 'branding_domain', 'developer_company', 'location_name', 'parent_location_name', 'partner', 'dda', 'location_date_created', 'location_creted_by', 'file_storage', 'account_vault', 'recurring', 'portfolio_name', 'agent_code', 'title', 'processor', 'debit', 'credit', 'refund', 'industry_type', 'p_trx_fee_ach', 'm_ach_min_fee', 'p_trx_fee_cc', 'm_access_fee', 'transaction_account_created_date', 'transaction_account_created_by', 'product_transaction_sub_processor', 'location_id', 'parent_location_id'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      if (this.get('locations.length') <= 1) {
        cols.removeObject(columns.location_id);
      }
      return cols;
    }
  }),

  isRefreshing: true,
  location_created_ts: 'today',
  location_created_user_id: null,
  parent_location_id: null,
  product_transaction_created_user_id: null,
  page: 1,
  page_size: '15',
  sort: '-location_created_ts',
  subProcessors: [{
    'id': 'fdnash',
    'title': 'First Data (Nashville)'
  }, {
    'id': 'tsys',
    'title': 'Tsys (Vital)'
  }],

  /////////     COMPUTED PROPERTIES     //////////


  /////////     ACTIONS     //////////

  actions: {
    updateLocationUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('locationusers', result);
      });
    },
    updateParentLocation: function(data) {
      var self = this;
      var parents = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    updateProductTransactionUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('producttransactionusers', result);
      });
    },
    updateReport: function(data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof data) !== 'object') {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function(item) {
        props[item.name.replace('.', '-')] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
    },
    viewLocation: function(onboardingdetail) {
      this.transitionToRoute('locations.view', onboardingdetail.get('location_id'));
    },
    viewLocationParent: function(onboardingdetail) {
      this.transitionToRoute('locations.view', onboardingdetail.get('parent_location_id'));
    }
  }

});
