import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  normalize: function(type, hash, property) {
    // Ember Data use the zone name as the ID.
    if(!hash.id){
      hash.id = hash.feature_flag_id + Math.random().toString(36).slice(2);
    }

    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  },
  attrs: {
    user: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
