import Ember from 'ember';
import DS from 'ember-data';
import Application from './application';

var get = Ember.get;

export default Application.extend(DS.EmbeddedRecordsMixin, {
  serialize: function(snapshot, options) {
    var json = {};

    if (options && options.includeId) {
      var id = snapshot.id;

      if (id) {
        json[get(this, 'primaryKey')] = id;
      }
    }

    snapshot.eachAttribute(function(key, attribute) {
      this.serializeAttribute(snapshot, json, key, attribute);
    }, this);

    snapshot.eachRelationship(function(key, relationship) {
      if (relationship.kind === 'belongsTo') {
        this.serializeBelongsTo(snapshot, json, relationship);
      } else if (relationship.kind === 'hasMany') {
        this.serializeHasMany(snapshot, json, relationship);
      }
    }, this);

    // Work around for submitting model with id already defined
    json.id = json._id;

    return json;
  },
  attrs: {
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    developer_company: {
      serialize: false,
      deserialize: 'records'
    }
  }
});