import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  allow_contact_login: DS.attr('boolean'),
  allow_contact_registration: DS.attr('boolean'),
  allow_contact_signup: DS.attr('boolean'),
  created_ts: DS.attr('ts'),
  nav_logo: DS.attr('string'),
  registration_fields: DS.attr('fakearray'),
  title: DS.attr('string'),
  url: DS.attr('string'),

  // Computed Properties
  allowContactUsers: Ember.computed('allow_contact_signup', 'allow_contact_registration', function() {
    return this.get('allow_contact_signup') || this.get('allow_contact_registration') || false;
  })
});
