import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  trans_reasoncodes: Ember.computed(function() {
    return this.get('applicationData.trans_reasoncodes');
  }),

  reload_data:false,

  //format-reasoncode-transaction

  formatReasoncodeTransaction: function(params, type) {
    var transaction = params[0];
    var title = 'Unknown';

    var trx_reasoncodes = this.get('trans_reasoncodes');
    var tempTrxReasonCode = trx_reasoncodes.filter(function(trx_reasoncode){
      return trx_reasoncode.id === transaction.get('reason_code_id');
    }).get('firstObject'); 
    if(!type || type === 'reason_code.title') {
      if(tempTrxReasonCode && Number(tempTrxReasonCode.id) === 1000) {
        if(transaction.response_message && transaction.response_message != transaction.verbiage){
          title = transaction.response_message;
        } else if(transaction.get('isCC')) {
          title = 'CC - Approved';
        } else if(transaction.get('isACH')) {
          title = 'ACH - Accepted';
        }
      }  else {
        if(transaction.verbiage == transaction.response_message)
          title = tempTrxReasonCode ? tempTrxReasonCode.title : 'Unknown';
        else {
          title = transaction.response_message ? 
          transaction.response_message : 
          tempTrxReasonCode ? tempTrxReasonCode.title : 'Unknown';
        }
      }
    } else {
      title = tempTrxReasonCode ? tempTrxReasonCode.id : '0';
    }

    return title;
  },

  refreshGrid: function(){
    this.set('reload_data',!this.reload_data);
  }
});
