import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by grid-accounts component
  sessionService: Ember.inject.service('session'),
  vtcController: Ember.inject.controller('components/modals/virtualterminalcontact'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sort: '-created_ts',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',
  ],

  // Actions
  actions: {}
});
