import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  showInvoiceNumber: false,
  showPoNumber: false,
  isLocationsBillingstatements: true,

  itemList: Ember.computed('invoice.invoice_items.[]', 'showZeros', function(){
    var items = this.get('invoice.invoice_items');
    var showZeros = this.get('showZeros');
    return items.filter(function(item){
      return showZeros ? true : item.get('total') !== '0.00';
    });
  }),

  // Actions
  actions: {
    toggleZeros: function() {
      this.toggleProperty('showZeros');
    }
  }
});
