import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  },

  // Computed Properties
  columns: Ember.computed('isMobileSize', 'locations.[]', 'hasCCandACH', 'hasProductsACH', 'hasProductsCC', 'route', function() {
    var self = this;
    var route = this.get('route');
    var session = this.get('session');
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Created<br>Date',
        'type': 'date',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      next_run_date: {
        'name': 'display_next_run_date',
        'title': 'Next Run<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          field: 'next_run_date'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'action': function(recurring, action) {
          self.viewRecurring(recurring, action);
        },
        'actionId': 'recurring_description_view_link',
        'filter': {
          type: 'text'
        }
      },
      contact_full_name: {
        'name': 'contact.full_name',
        'title': 'Contact<br>Name',
        'type': 'string',
        'sortable': false,
        'action': function(recurring) {
          self.get('parent').transitionToRoute('contacts.dashboard', recurring.get('contact.id'));
        },
        'actionpriv': 'v2-contacts-get',
      },
      notification_days: {
        'name': 'display_notification_days',
        'title': 'Notify Before<br>Payment',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'notification_days'
        }
      },
      status: {
        'name': 'display_status',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'recurring_statuses',
          field: 'status'
        }
      },
      start_date: {
        'name': 'display_start_date',
        'title': 'Start<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          field: 'start_date'
        }
      },
      end_date: {
        'name': 'display_end_date',
        'title': 'End<br>Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          field: 'end_date'
        }
      },
      recurring_type_id: {
        'name': 'recurringTypeTitle',
        'title': route === 'contactuser.recurrings-index' ? 'Scheduled Payment<br>Type' : 'Recurring Billing<br>Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'recurring_types',
          field: 'recurring_type_id'
        }
      },
      interval: {
        'name': 'interval',
        'title': 'Interval',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      interval_type: {
        'name': 'interval_type_name',
        'title': 'Interval<br>Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'intervals',
          field: 'interval_type'
        }
      },
      installment_total_count: {
        'name': 'display_installment_total_count',
        'title': '# of<br>Payments',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'installment_total_count'
        }
      },
      transaction_amount: {
        'name': 'total_transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'transaction_amount'
        }
      },
      installment_amount_total: {
        'name': 'display_installment_amount_total',
        'title': 'Total to<br>Collect',
        'type': 'currency',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'installment_amount_total'
        }
      },
      payment_method: {
        'name': 'payment_method',
        'title': 'Payment<br>Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'paymentMethods',
          multi: true,
          showAllSelect: true
        }
      },
      last_four: {
        'name': 'account_vault.last_four',
        'title': 'Account<br>Last Four',
        'type': 'string',
        'action': function(recurring) {
          self.send('viewAccount', recurring);
        },
        'actionpriv': 'v2-accountvaults-get',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'last_four'
        }
      },
      account_type: {
        'name': 'account_vault.account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true,
          field: 'account_type'
        }
      },
      account_holder_name: {
        'name': 'account_vault.account_holder_name',
        'title': 'Account<br>Holder Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'account_holder_name'
        }
      },
      tags: {
        'name': 'tags',
        'title': 'Tags',
        'type': 'array',
        'sortable': false,
        'filter': {
          type: 'dropdown',
          items: 'tagList',
          labelKey: 'title',
          multi: true,
          showAllSelect: true
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'action': function(recurring) {
          self.get('parent').transitionToRoute('locations.viewproducttransaction', recurring.get('location.id'), recurring.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          showAllSelect: true,
          notuniqbytitle: true
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'action': function(recurring) {
          self.get('parent').transitionToRoute('locations.view', recurring.get('location.id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'locationsWithRecurring',
          multi: true,
          showAllSelect: true
        }
      },
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(recurring, action) {
          self.viewRecurring(recurring, action);
        },
        'actionId': 'recurring_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      actionbutton: {
        'sortable': false,
        'component': 'action-button',
        'isActionColumn': true,
        'params': {
          btnClass: 'btn-group-xs',
          align: 'right',
          type: 'recurring'
        },
        'extra_data' : {
          locations: self.get('locations'),
          products: self.get('product_transactions'),
          contact: self.get('contact')
        },
        'actions': {
          delete: function(recurring) {
            var prompt = 'Are you sure you want to delete this ';
            var recurringName = route === 'contactuser.recurrings-index' ? 'Scheduled Payment' : 'Recurring Billing';
            prompt += recurringName + '?';
            pretty_confirm(prompt, function() {
              // Bail if we are already loading
              if (self.get('isLoading')) {
                return;
              }
              recurring.destroyRecord().then(function() {
                recurring.unloadRecord();
                noty({
                  text: recurringName + ' deleted successfully!'
                });
                recurring.unloadRecord();
                self.set('isLoading', false);
                self.get('parent').send('gridNeedsRefresh', self.get('parent'));
              }, function() {
                recurring.rollbackAttributes();
                self.get('parent').send('gridNeedsRefresh', self.get('parent'));
                noty({
                  text: 'Error deleting ' + recurringName + '!',
                  type: 'error'
                });
                self.set('isLoading', false);
              });
            });
          },
          launchModal: function(model, modal, other) {
            if(modal === 'signature') {
              self.getSignature(model, other);
            }
          },
          view: function(recurring, action) {
            self.viewRecurring(recurring, action);
          }
        }
      },
    };
    if(route === 'contactuser.recurrings-index') {
      delete columns.description.filter.type;
      delete columns.last_four.filter.type;
      delete columns.account_holder_name.filter.type;
      delete columns.account_type.filter.type;
      delete columns.next_run_date.filter.type;
      delete columns.start_date.filter.type;
      delete columns.end_date.filter.type;
      delete columns.interval.filter.type;
      delete columns.interval_type.filter.type;
      delete columns.recurring_type_id.filter.type;
      delete columns.status.filter.type;
      delete columns.installment_total_count.filter.type;
      delete columns.installment_amount_total.filter.type;
      delete columns.notification_days.filter.type;
      delete columns.transaction_amount.filter.type;
    }

    var listOfCols = ['description', 'contact_full_name', 'next_run_date', 'start_date', 'end_date', 'interval', 'interval_type', 'recurring_type_id', 'status', 'installment_total_count', 'transaction_amount', 'installment_amount_total', 'notification_days', 'payment_method', 'last_four', 'account_type', 'account_holder_name', 'tags', 'product_transaction_id', 'location_id', 'created_ts', 'id', 'actionbutton'];
    var listOfMobileCols = ['description', 'contact_full_name', 'next_run_date', 'start_date', 'end_date', 'interval', 'interval_type', 'recurring_type_id', 'status', 'installment_total_count', 'transaction_amount', 'installment_amount_total', 'notification_days', 'payment_method', 'last_four', 'account_type', 'account_holder_name', 'tags', 'product_transaction_id', 'location_id', 'created_ts', 'id', 'actionbutton'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (context.get('locations.length') <= 1 || route === 'locations.recurrings-index' || route === 'contacts.recurrings-index' || route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.location_id);
      }
      if (route === 'contacts.recurrings-index' || route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.contact_full_name);
      }
      if (!context.get('product_transactions') || context.get('product_transactions.length') <= 1 || route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.product_transaction_id);
      }
      if (!context.get('tagList') || context.get('tagList.length') < 1 || route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.tags);
      }
      if (!context.get('hasCCandACH') || route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.payment_method);
      }
      if (route === 'contactuser.recurrings-index') {
        colsArray.removeObject(columns.created_ts);
        colsArray.removeObject(columns.id);
        colsArray.removeObject(columns.account_holder_name);
      }
    }
  }),

  hasCCandACH: Ember.computed('hasProductsACH', 'hasProductsCC', function() {
    return this.get('hasProductsACH') && this.get('hasProductsCC');
  }),
  hasLocationsWithRecurring: Ember.computed('locationsWithRecurring.[]', function() {
    return this.get('locationsWithRecurring.length') >= 1;
  }),
  hasProductsACH: Ember.computed('locations.@each.hasProductACH', function() {
    var hasACH = false;
    this.get('locations').map(function(location) {
      if (location.get('hasProductACH')) {
        hasACH = true;
      }
    });
    return hasACH;
  }),
  hasProductsCC: Ember.computed('locations.@each.hasProductCC', function() {
    var hasCC = false;
    this.get('locations').map(function(location) {
      if (location.get('hasProductCC')) {
        hasCC = true;
      }
    });
    return hasCC;
  }),
  isMultiLocation: Ember.computed('hasLocationsWithRecurring', 'route', function() {
    return this.get('hasLocationsWithRecurring') && this.get('route') === 'recurrings.index';
  }),
  locationsWithRecurring: Ember.computed('locations', function() {
    return this.get('locations').filter(function(location) {
      return location.get('hasProductRecurring');
    });
  }),
  noRecurringGetPrivMessage: Ember.computed('session.authenticated.resources.v2-recurrings-get', function() {
    if (!this.get('session.authenticated.resources.v2-recurrings-get')) {
      var message = 'You do not have access to view ';
      var route = this.get('route');
      if (route === 'locations.recurrings-index' || route === 'recurrings.index' || route === 'contacts.recurrings-index') {
        message += 'Recurring Billings.';
      }  else if (route === 'contactuser.recurrings-index') {
        message += 'Scheduled Payments.<br> Please contact ' + this.get('locations').get('firstObject').get('name') + ' for assistance.';
      }
      if(route !== 'contactuser.recurrings-index') {
        message += '<br> Please contact support if you need assistance enabling access to Recurring Billings.';
      }
      return message;
    } else {
      return;
    }
  }),
  noRecurringMessage: Ember.computed('hasLocationsWithRecurring', 'route', function() {
    if (!this.get('hasLocationsWithRecurring')) {
      var message = 'Recurring Billing Service is not configured for ';
      var route = this.get('route');
      if (route === 'locations.recurrings-index') {
        message += 'this location.';
      } else if (route === 'recurrings.index') {
        message += 'any of your locations.';
      } else if (route === 'contacts.recurrings-index') {
        message += 'this contact\'s location.';
      } else if (route === 'contactuser.recurrings-index') {
        message = 'Scheduled Payments Service is not configured for your location.<br> Please contact ' + this.get('locations').get('firstObject').get('name') + ' for alternatives.';
      }
      if(route !== 'contactuser.recurrings-index') {
        message += '<br> Please contact support if you need assistance enabling Recurring Billing Service.';
      }
      return message;
    } else {
      return;
    }
  }),
  selected_contact_id: Ember.computed('contact_id', function() {
    var contact_id = this.get('contact_id');
    return Ember.isArray(contact_id) ? contact_id.join() : contact_id;
  }),
  selected_location_id: Ember.computed('route', 'locationsWithRecurring', 'location_id', function() {
    var location_id = this.get('location_id');
    if(this.get('route') !== 'contacts.recurrings-index' && this.get('route') !== 'contactuser.recurrings-index') {
      if(location_id) {
        return location_id;
      } else {
        var locationsWithRecurring = this.get('locationsWithRecurring');
        var tempLocationIds = [];
        if(locationsWithRecurring && locationsWithRecurring.get('length')) {
          tempLocationIds = locationsWithRecurring.map(function(location){
            return location.get('id');
          });
          return tempLocationIds.join(',');
        }
      }
    }
  }),
  showContactFilter: Ember.computed('route', 'session.authenticated.resources.v2-contacts-get', function() {
    return this.get('route') !== 'contacts.recurrings-index' && this.get('route') !== 'contactuser.recurrings-index' && this.get('session.authenticated.resources.v2-contacts-get');
  }),

  // Functions

  getSignature: function(recurring) {
    var controller = this.get('parent').get('authagreementController');
    controller.set('authagreementType', null);
    controller.set('model', null);
    controller.set('location', null);
    controller.set('product', null);
    controller.set('contact', null);
    controller.set('contact', this.get('contact') || recurring.get('contact'));
    controller.set('authagreementType', 'recurring');
    var locations = this.get('locations');
    var location = locations.filter(function(loc) {
      return loc.get('id') === recurring.get('account_vault.location_id');
    }).get('firstObject');
    var products = this.get('product_transactions');
    var product = products.filter(function(prod) {
      return prod.id === recurring.get('product_transaction_id');
    }).get('firstObject');

    controller.set('product', product);
    if (product.receipt_add_recurring_above_signature) {
      controller.set('model', recurring);
      controller.set('location', location);

      var signatureLegal = product.receipt_add_recurring_above_signature;
      if (signatureLegal) {
        this.get('parent').send('openModal', 'components/modals/authagreement');
      }
    }
  },
  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },
  viewRecurring(recurring, action) {
    var isEditing = action === 'edit';
    var route = this.get('route');
    var router = this.get('router');
    if (route === 'contacts.recurrings-index') {
      router.transitionTo('contacts.recurrings-view', this.get('contact.id'), recurring.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'recurrings.index') {
      router.transitionTo('recurrings.view', recurring.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'locations.recurrings-index') {
      var location = this.get('locations').get('firstObject');
      router.transitionTo('locations.recurrings-view', location.get('id'), recurring.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    } else if (route === 'contactuser.recurrings-index') {
      router.transitionTo('contactuser.recurrings-view', this.get('contact.id'), recurring.get('id')).then(function(Route) {
        Route.controller.set('isEditing', isEditing);
      });
    }
  },

  // Actions
  actions: {
    viewAccount(recurring) {
      var route = this.get('route');
      var router = this.get('router');
      if (route === 'contacts.recurrings-index') {
        router.transitionTo('contacts.accounts-view', this.get('contact.id'), recurring.get('account_vault_id'));
      } else if (route === 'recurrings.index') {
        router.transitionTo('accounts.view', recurring.get('account_vault_id'));
      } else if (route === 'locations.recurrings-index') {
        var location = this.get('locations').get('firstObject');
        router.transitionTo('locations.accounts-view', location.get('id'), recurring.get('account_vault_id'));
      } else if (route === 'contactuser.recurrings-index') {
        router.transitionTo('contactuser.accounts-view', this.get('contact.id'), recurring.get('account_vault_id'));
      }
    }
  },

});
