import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var session = this.get('session');
    var pagerParams = {
      pageRoute: 'locations.index',
      pageModelId: null,
      modelName: 'location'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      relationship: 'all'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    // ** No History entry since route is already in main nav menu
    controller.set('pageTitle', 'Locations');
    controller.set('pageSubtitle', {
      title: 'Add Location',
      link: 'locations.add',
      record: null,
      resource: session.get('authenticated.resources.v2-locations-post') && session.get('authenticated.isSupport')
    });
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
