import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  allRegistrationFields: ['id', 'first_name', 'last_name', 'account_number', 'email', 'company_name', 'address', 'city', 'state', 'zip', 'home_phone', 'office_phone', 'office_ext_phone', 'cell_phone', 'contact_api_id'],
  updateErrorMessages: Ember.observer('domain.errors.messages', function() {
    this.set('errorMessages', this.get('domain.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,

  panelTitle: Ember.computed('domain.title', 'domain.isNew', function() {
    var isNew = this.get('domain.isNew');
    var title = this.get('domain.title');
    return isNew ? 'Add Domain' : title;
  }),

  // Actions
  actions: {
    contactConfigHandler: function (key, data) {
      // Use a single switch case to handle contact, login, registration radio-buttons
      switch (key) {
        case 'LOGIN':
          this.get('domain').set('allow_contact_login', data);
          break;
        case 'REGISTRATION':
          if (data) {
            this.get('domain').set('allow_contact_signup', false);
          }
          this.get('domain').set('allow_contact_registration', data);
          break;
        case 'SIGNUP':
          if (data) {
            this.get('domain').set('allow_contact_registration', false);
          }
          this.get('domain').set('allow_contact_signup', data);
          break;
        case 'HIDE_BLIND_PAYMENT':
          this.get('domain').set('hide_blind_payment', data);
          break;
      }
    },
    selectValue: function (data) {
      const fields = this.get('domain.registration_fields');
      let fieldsArray = [];
      const array = [];
      if (fields) {
        fieldsArray = fields.split(',');
        fieldsArray.forEach((item) => {
          array.push(item.replace(/['"]+/g, '').trim());
        });
      }
      if (array.length >= 1) {
        const index = array.findIndex((item) => item === data);
        if (index === -1) {
          array.push(data);
        } else {
          array.splice(index, 1);
        }
      } else {
        array.push(data);
      }
      this.get('domain').set('registration_fields', array.join(','));
    },
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var domain = this.get('domain');

      domain.save().then(function() {
        noty({
          text: 'Domain added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.domains.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.domains.index');
    }
  }
});
