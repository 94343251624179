import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    var session = this.get('session');
    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: session.get('authenticated.user.contact_id'),
          page_size: '500'
        });
      } else {
        return [];
      }
    };
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
      accounts: getAccounts(),
    });
  },
  afterModel: function(models) {
    return Ember.RSVP.hash({
      locationinfos: this.get('store').query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('locationinfo', locationinfo);
    controller.set('locationinfos', models.locationinfos);
    controller.set('accounts', models.accounts);

    var pagerParams = {
      pageRoute: 'contactuser.quickinvoices-index',
      pageModelId: null,
      modelName: 'quickinvoice',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      contact_id: models.contact.get('id'),
      expand: 'status,cc_product_transaction,ach_product_transaction,files'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Invoices',
      route_name: 'contactuser.quickinvoices-index'
    });
    controller.set('pageTitle', 'Invoices');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Invoices',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
