import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        id: params.id,
        page_size: '500',
        product_transaction_active: '0,1',
        expand: 'product_transactions,branding_domain,tags',
      }),
      recurring: store.queryRecord('recurring', {
        id: params.recurring_id,
        expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,location,contact,transactions,payment_schedule',
        active: '0,1'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locations.get('firstObject');
    controller.set('location', location);
    controller.set('locations', models.locations);
    controller.set('recurring', models.recurring);

    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.recurring.get('id'),
        type: 'recurring',
        attributes: {
          terms_agree: true
        }
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● View Recurring Billing',
      route_name: 'locations.recurrings-view',
      route_id: location.get('id'),
      route_id_plus: models.recurring.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Recurring Billings',
      link: 'locations.recurrings-index',
      record: location.get('id')
    }, {
      title: 'View Recurring Billing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.controller.get('recurring');
      if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
