import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var product_transactions = [];
    models.locations.forEach(function(loc) {
      var prods = loc.get('product_transactions');
      if (prods) {
        prods.forEach(function(product_transaction) {
          product_transactions.push({
            'id': product_transaction.get('id'),
            'title': product_transaction.get('title')
          });
        });
      }
    });
    controller.set('product_transactions', product_transactions);
    var pagerParams = {
      pageRoute: 'reports.cc-transactionsummarybytype',
      pageModelId: null,
      modelName: 'transactionsummariesbyaccounttype',
      showExport: this.get('session.authenticated.resources.v2-transactionsummariesbyaccounttypes-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location.id,location.name,product_transaction,transaction_batch',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);
    if (models.locations.get('content').length > 1) {
      controller.set('showLocations', true);
    }
    controller.set('locations', models.locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● CC Transactions Summary by Type',
      route_name: 'reports.cc-transactionsummarybytype',
      route_id: null
    });
    controller.set('pageTitle', 'CC Transactions Summary by Type');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'CC Transactions Summary by Type',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
