import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function (params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      sharinggroups: store.query('admin_sharinggroup', {
        id: params.view_id,
        expand: 'created_user.id,created_user.username'
      }),
      locationsharinggroups: store.query('admin_locationsharinggroup', {
        sharing_group_id: params.view_id,
        expand: 'location.id,location.name',
        page_size: 500
      })
    });
  },

  setupController: function (controller, models) {
    // Set Controller Propertie
    var sharinggroup = models.sharinggroups.get('firstObject');
    controller.set('sharinggroup', sharinggroup);
    controller.set('locationsharinggroups', models.locationsharinggroups);
    controller.set('locationsharinggroup', this.get('store').createRecord('admin_locationsharinggroup', {
      sharing_group_id: sharinggroup.get('id'),
      location_id: null
    }));
    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-sharinggroups-delete'),
      canPut: this.get('session.authenticated.resources.v2admin-sharinggroups-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Sharing Group ● ' + sharinggroup.get('description'),
      route_name: 'admin.sharinggroups.view',
      route_id: sharinggroup.get('id')
    });
    controller.set('pageTitle', 'View Sharing Group');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Sharing Groups',
      link: 'admin.sharinggroups.index'
    }, {
      title: 'View Sharing Group',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function () {
      this.refresh();
    },
    willTransition: function (transition) {
      this.get('unsavedInfo').warning(this.currentModel.sharinggroup, transition, this);
      return true;
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
});
