import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  warning: function(model, transition, context) {
    if(model && (model.get('isDestroyed') || model.get('isDestroying'))) {
      return;
    }
    if (model && model.get('hasDirtyAttributes') && model.get('isNew') && this.get('session.isAuthenticated')) {
      model.unloadRecord();
    } else if (model && model.get('hasDirtyAttributes') && this.get('session.isAuthenticated')) {
      transition.abort();
      pretty_confirm('You have unsaved information.<br><br> Are you sure you want to leave this page?', function() {
        context.controller.set('isEditing', false);
        model.rollbackAttributes();
        transition.retry();
      }, function() {
        context.controller.set('isTransitioning', false);
      });
    } else {
      context.controller.set('isEditing', false);
    }
  }


});
