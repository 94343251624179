import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      user: store.queryRecord('admin_user', {
        id: params.locations_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      }),
    });
  },

  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authroleusers: store.query('authroleuser', {
        user_id: models.user.get('id'),
        page_size: 500
      }).then(function(data) {
        models.authroleusers = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    controller.set('user', user);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles

    var pagerParams = {
      pageRoute: 'admin.users.locations',
      pageModelId: user.get('id'),
      modelName: 'locationuser'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      user_id: user.get('id'),
      expand: 'location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: user.get('username') + ' ● Locations',
      route_name: 'admin.users.locations',
      route_id: user.get('id')
    });
    controller.set('pageTitle', user.get('username'));
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Users',
      link: 'admin.users.index'
    }, {
      title: user.get('username'),
      link: 'admin.users.view',
      record: user.get('id')
    }, {
      title: 'Locations',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
