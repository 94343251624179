import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  adapterDidInvalidate: function(errors) {
    var recordErrors = this.get('errors');
    var results = [];
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        var errorValue = errors[key];
        if (typeof errorValue[0] === 'object') {
          for (var subErrors in errorValue) {
            if (errorValue.hasOwnProperty(subErrors)) {
              var subKey = errorValue[subErrors];
              for (var k in subKey) {
                if (subKey.hasOwnProperty(k)) {
                  var e = subKey[k];
                  results.push(recordErrors.add(k, k + ' ' + parseInt(subErrors) + 1 + ': ' + e));
                }
              }
            }
          }
        } else {
          results.push(recordErrors.add(key, errorValue));
        }
      }
    }
    return results;
  },

  contact_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_ts_from: DS.attr('ts'),
  created_ts_to: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  due_date: DS.attr('sqldatetime'),
  footer_message: DS.attr('string'),
  header_message: DS.attr('string'),
  invoice_number: DS.attr('string'),
  invoice_status_id: DS.attr('string'),
  invoice_template_id: DS.attr('string'),
  location_id: DS.attr('string'),
  po_number: DS.attr('string'),
  shipping_amount: DS.attr('string'),
  tax_amount: DS.attr('string'),
  title: DS.attr('string'),
  total: DS.attr('string'),

  // Defined model relationships
  contact: DS.belongsTo('contact'),
  created_user: DS.belongsTo('user'),
  invoice_items: DS.hasMany('item'),
  invoice_status: DS.belongsTo('billingstatementstatus'),
  location: DS.belongsTo('location')
});
