import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  'aba': DS.attr('string'),
  'account_vault': DS.attr('string'),
  'agent_code': DS.attr('string'),
  'auto_batch_close_time': DS.attr('string'),
  'branding_domain': DS.attr('string'),
  'credit': DS.attr('string'),
  'dda': DS.attr('string'),
  'debit': DS.attr('string'),
  'developer_company_name': DS.attr('string'),
  'file_storage': DS.attr('string'),
  'industry_type': DS.attr('string'),
  'location_created_by': DS.attr('string'),
  'location_created_ts': DS.attr('ts'),
  'location_created_user_id': DS.attr('string'),
  'location_date_created': DS.attr('string'),
  'location_developer_company': DS.attr('string'),
  'location_id': DS.attr('string'),
  'location_name': DS.attr('string'),
  'm-access-fee': DS.attr('string'),
  'm-ach-min-fee': DS.attr('string'),
  'p-trx-fee-ach': DS.attr('string'),
  'p-trx-fee-cc': DS.attr('string'),
  'parent_location_id': DS.attr('string'),
  'parent_location_name': DS.attr('string'),
  'partner': DS.attr('string'),
  'portfolio_id': DS.attr('string'),
  'portfolio_name': DS.attr('string'),
  'processor': DS.attr('string'),
  'product_transaction_created_ts': DS.attr('ts'),
  'product_transaction_created_user_id': DS.attr('string'),
  'product_transaction_id': DS.attr('string'),
  'product_transaction_payment_method': DS.attr('string'),
  'product_transaction_sub_processor': DS.attr('string'),
  'recurring': DS.attr('string'),
  'refund': DS.attr('string'),
  'title': DS.attr('string'),
  'transaction_account_created_by': DS.attr('string'),
  'transaction_account_created_date': DS.attr('string'),
});
