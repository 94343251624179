import Ember from 'ember';
import Base from 'ember-simple-auth/authorizers/base';

export default Base.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  // authorize: function(jqXHR, requestOptions) {
  //   jqXHR.setRequestHeader('developer-id', window.UiENV.APP.DEVELOPER_ID);
  //   var session = this.get('session');
  //   console.log("session.isAuthenticated && !Ember.isEmpty(session.get('authenticated.token'))", session.isAuthenticated && !Ember.isEmpty(session.get('authenticated.token')));
  //   if (session.get('isAuthenticated') && !Ember.isEmpty(session.get('authenticated.token'))) {
  //     if (secondsTimestamp() > session.get('authenticated.expire_ts')) {
  //       if (requestOptions.type !== "DELETE" && requestOptions.url.indexOf('/' + window.UiENV.APP.API_NAMESPACE + '/token') === -1) {
  //         console.log("Session expired. Redirecting to login");
  //         session.invalidate();
  //       }
  //     } else {
  //       console.log("session.get('authenticated.token')", session.get('authenticated.token'));
  //       jqXHR.setRequestHeader('access-token', session.get('authenticated.token'));
  //       Ember.run.once(this, function() {
  //         var expire_ts = parseInt(secondsTimestamp()) + (parseInt(session.get('authenticated.expire')) * 60);
  //         if (expire_ts !== parseInt(session.get('authenticated.expire_ts'))) {
  //           session.set('authenticated.expire_ts', expire_ts);
  //           session.store.persist({
  //             authenticated: session.get('authenticated')
  //           });
  //         }
  //       });
  //     }
  //   }
  // },
  // authorize: function(sessionData, block){
  //   console.log('sessionData', sessionData);
  //   console.log('block', block);
  //   block('X-Authorization', 'developer-id: ' + window.UiENV.APP.DEVELOPER_ID);
  //   var session = this.get('session');
  //   if(!Ember.isEmpty(sessionData.token)){
  //     if (secondsTimestamp() > session.get('authenticated.expire_ts')) {
  //       if (block.type !== 'DELETE' && block.url.indexOf('/' + window.UiENV.APP.API_NAMESPACE + '/token') === -1) {
  //         console.log('Session expired. Redirecting to login');
  //         session.invalidate();
  //       }
  //     } else {
  //       block('X-Authorization', 'access-token: ' + sessionData.token);
  //       Ember.run.once(this, function() {
  //         var expire_ts = parseInt(secondsTimestamp()) + (parseInt(session.get('authenticated.expire')) * 60);
  //         if (expire_ts !== parseInt(session.get('authenticated.expire_ts'))) {
  //           session.set('authenticated.expire_ts', expire_ts);
  //           session.store.persist({
  //             authenticated: session.get('authenticated')
  //           });
  //         }
  //       });
  //     }
  //   }
  // }
});
