import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isSalesoffice: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'location_id',
    'title',
    'contact_name',
    'email'
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(salesoffice) {
          self.send('view', salesoffice);
        },
        'actionId': 'salesoffice_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(salesoffice) {
          self.transitionToRoute('locations.view', salesoffice.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      contact_name: {
        'name': 'contact_name',
        'title': 'Contact Name',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      email: {
        'name': 'email',
        'title': 'Email',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      phone: {
        'name': 'phone',
        'title': 'Phone',
        'type': 'string',
        'sortable': true
      },
      misc: {
        'name': 'misc',
        'title': 'Misc',
        'type': 'string',
        'sortable': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(salesoffice) {
          self.send('delete', salesoffice);
        },
        'actionId': 'salesoffice_delete_link',
        'type': 'delete',
        'isActionColumn': true
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(salesoffice) {
          self.send('view', salesoffice);
        },
        'actionId': 'salesoffice_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'location_id', 'contact_name', 'email', 'phone', 'misc', 'view', 'delete'];
    var listOfMobileCols = ['title', 'location_id', 'contact_name', 'email', 'phone', 'misc', 'view', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-salesoffices-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-salesoffices-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
    delete: function (salesoffice) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Sales Office?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        salesoffice.deleteRecord();
        salesoffice.save().then(function () {
          noty({
            text: 'Sales Office deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function () {
          salesoffice.rollbackAttributes();
          noty({
            text: 'Error deleting Sales Office!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    view: function(salesoffice) {
      this.transitionToRoute('admin.salesoffice.view', salesoffice.get('id'));
    },
  }
});
