import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  attrs: {
    active_notification_alerts: {
      embedded: 'always'
    },
    auth_roles: {
      embedded: 'always'
    },
    changelogs: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    domain: {
      embedded: 'always'
    },
    location_users: {
      embedded: 'always'
    },
    primary_location: {
      serialize: false,
      deserialize: 'records'
    },
  }
});
