import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  accountsview: Ember.inject.controller('accounts/view'),
  contactsaccountsview: Ember.inject.controller('contacts/accounts-view'),
  contactscharge: Ember.inject.controller('contacts/charge'),
  contactsdashboard: Ember.inject.controller('contacts/dashboard'),
  contactsquickinvoicesview: Ember.inject.controller('contacts/quickinvoices-view'),
  contactsrecurringsview: Ember.inject.controller('contacts/recurrings-view'),
  contactstransactions: Ember.inject.controller('contacts/transactions'),
  locationsaccountsview: Ember.inject.controller('locations/accounts-view'),
  locationsquickinvoicesview: Ember.inject.controller('locations/quickinvoices-view'),
  locationsrecurringsview: Ember.inject.controller('locations/recurrings-view'),
  locationstransactions: Ember.inject.controller('locations/transactions'),
  locationsunsettledtransactions: Ember.inject.controller('locations/reports-cc-unsettledtransactions'),
  quickinvoiceview: Ember.inject.controller('quickinvoice/view'),
  recurringsview: Ember.inject.controller('recurrings/view'),
  reportstransactions: Ember.inject.controller('reports/transactions'),
  reportstransactionslegacy: Ember.inject.controller('reports/transactionslegacy'),
  sessionService: Ember.inject.service('session'),
  transactiondetailfull: Ember.inject.controller('transactiondetailfull'),
  virtualterminal: Ember.inject.controller('virtualterminal'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sender: null,

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transaction.errors.messages', function() {
    this.set('errorMessages', this.get('transaction.errors.messages'));
  }),

  // Various controller properties
  tagList: null,
  selectedTags: null,
  pagerParams: {
    pageRoute: 'components.modals.increment'
  },

  // Functions
  incrementCancelled: function(context) {
    var transaction = context.get('transaction');
    var tagList = context.get('tagList');
    var selectedTags = context.get('initialTags').split(',');
    var tempTags = [];
    tagList.forEach(function(tag) {
      selectedTags.map(function(t){
        if(t === tag.get('title')){
          tempTags.pushObject(tag);
        }
      });
    });
    transaction.set('tags', tempTags);
    transaction.rollbackAttributes();
    context.set('transaction', null);
  },

  // Actions
  actions: {
    updateTags: function(tags) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof tags) !== 'object') {
        return;
      }

      // Get transaction object
      var transaction = this.get('transaction');
      // Get existing tags for transaction object
      var existingTags = transaction.get('tags');

      // Remove any existing tag objects
      if (existingTags) {
        existingTags.forEach(function(item) {
          existingTags.removeObject(item);
        });
      }

      // Then add back any newly selected tag objects
      var tagList = this.get('tagList');
      var selectedTags = tags.filters[0].value.split(',');
      tagList.forEach(function(tag) {
        if (selectedTags.indexOf(tag.get('title')) > -1) {
          transaction.get('tags').pushObject(tag);
        }
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var transaction = this.get('transaction');
      var locationinfo = this.get('locationinfo');
      var contact = this.get('contact');

      transaction.set('action', 'authincrement');
      transaction.save().then(function() {
        // Set the contact on the transaction for the receipt
        transaction.set('contact', contact);

        var sender = self.get('sender');
        if (sender) {
          var senderController = self.get(sender);
          if (senderController) {
            senderController.set('needsRefresh', new moment().unix());
          }
        }
        self.send('showTransDetail', transaction, locationinfo);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error incrementing transaction!',
          type: 'error',
          timeout: '5000'
        });
        self.set('isLoading', false);
      });
    }
  }
});
