import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        relationship: 'direct',
        page_size: '500',
        sort: 'name'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'admin.contacts.index',
      pageModelId: null,
      modelName: 'contact'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      relationship: 'all',
      expand: 'location.id,location.name'
    };
    controller.set('gridParams', gridParams);
    controller.set('locationinfos', models.locationinfos);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'All Contacts Listing',
      route_name: 'admin.contacts.index',
      route_id: null
    });
    controller.set('pageTitle', 'All Contacts Listing');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'All Contacts Listing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
