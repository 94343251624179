import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isMailboxMessages: true,
  isInbox: true,
  isArchive: false,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'subject',
  ],
  columns: Ember.computed(function() {
    var self = this;
    return [{
      'name': 'mailbox_message.subject',
      'title': 'Subject',
      'type': 'string',
      'sortable': true,
      'action': function(mailboxmessage) {
        self.send('view', mailboxmessage);
      },
      'actionId': 'mailboxmessage_subject_view_link'
    }, {
      'name': 'created_ts',
      'title': 'Date',
      'type': 'shortdate',
      'sortable': true,
      'filter': {
        type: 'dropdown',
        items: 'dateFiltersPast',
        multi: false
      }
    }, {
      'name': 'mailbox_message_status.title',
      'title': 'Status',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'id',
      'title': '',
      'action': function(mailboxmessage) {
        self.send('view', mailboxmessage);
      },
      'actionId': 'mailboxmessage_view_link',
      'type': 'view',
      'isActionColumn': true
    }];
  }),

  // Actions
  actions: {
    view: function(mailboxmessage) {
      this.transitionToRoute('mailboxmessagesuser.view', mailboxmessage.get('id'), {
        queryParams: {
          returnRoute: 'index'
        }
      });
    },
  }
});
