import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdmisalesoffice: true,

  // Set controller params
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('salesoffice.errors.messages', function() {
    this.set('errorMessages', this.get('salesoffice.errors.messages'));
  }),

  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var salesoffice = this.get("salesoffice");

      salesoffice.save().then(function () {
        // Success callback
        noty({
          text: 'Sales Office added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.salesoffice.index');
      }, function () {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.salesoffice.index');
    },
    updateLocation: function (data) {
      var self = this;
      var locations = this.get('store').query('location', {
        name: data,
        page_size: '10'
      });
      locations.then(function (result) {
        self.set('locations', result);
      });
    }
  }
});
