import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  email: DS.attr('string'),
  notification_template_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  title: DS.attr('string'),
  user_id: DS.attr('string'),

  // Defined model relationships
  product_transaction: DS.belongsTo('producttransaction'),
  user: DS.belongsTo('user'),
});
