import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_vault_id: DS.attr('string'),
  active: DS.attr('boolnum'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  contact_id: DS.attr('string'),
  description: DS.attr('string'),
  end_date: DS.attr('sqldatezeros'),
  installment_amount_total: DS.attr('string'),
  installment_current_count: DS.attr('string'),
  installment_total_count: DS.attr('string'),
  interval: DS.attr('string'),
  interval_type: DS.attr('string'),
  location_id: DS.attr('string'),
  next_run_date: DS.attr('sqldatezeros'),
  next_run_date_max: DS.attr('sqldate'),
  next_run_date_min: DS.attr('sqldate'),
  notification_days: DS.attr('string'),
  num_payments: DS.attr('string'),
  payment_method: DS.attr('string'),
  payment_schedule: DS.attr(),
  product_transaction_id: DS.attr('string'),
  recurring_type_id: DS.attr('string'),
  start_date: DS.attr('sqldate'),
  status: DS.attr('string'),
  terms_agree: DS.attr('boolnull'),
  transaction_amount: DS.attr('string'),

  // Custom fields
  recurring_c1: DS.attr('string'),
  recurring_c2: DS.attr('string'),
  recurring_c3: DS.attr('string'),

  // Computed Properties
  display_end_date: Ember.computed('status', 'end_date', function() {
    if (!this.get('end_date') || this.get('end_date') === '0000-00-00') {
      return 'Ongoing';
    } else {
      return moment(this.get('end_date'), 'MM/DD/YYYY').format('MM/DD/YY');
    }
  }),
  display_installment_total_count: Ember.computed('isInstallment', 'installment_total_count', function() {
    if (this.get('isInstallment')) {
      return this.get('installment_total_count');
    } else {
      return 'Indefinite';
    }
  }),
  display_installment_amount_total: Ember.computed('isInstallment', 'installment_total_count', 'total_transaction_amount', function() {
    if (this.get('isInstallment')) {
      return Number(this.get('installment_total_count')) * Number(this.get('total_transaction_amount'));
    } else {
      return 'N/A';
    }
  }),
  display_next_run_date: Ember.computed('status', 'display_status', 'next_run_date', function() {
    if (this.get('status') === 'active') {
      return moment(this.get('next_run_date'), 'MM/DD/YYYY').format('MM/DD/YY');
    } else {
      return this.get('display_status');
    }
  }),
  display_notification_days: Ember.computed('notification_days', function() {
    var days = this.get('notification_days');
    return days && days !== '0' ? days + ' Days' : 'Off';
  }),
  display_start_date: Ember.computed('start_date', function() {
    return moment(this.get('start_date'), 'MM/DD/YYYY').format('MM/DD/YY');
  }),
  display_status: Ember.computed('status', function() {
    switch (this.get('status')) {
      case 'active':
        return 'Active';
      case 'ended':
        return 'Ended';
      case 'on hold':
        return 'On Hold';
      default:
        return 'Unknown';
    }
  }),
  hasSigPad: Ember.computed(function() {
    return window.sessionStorage.sigpad;
  }),
  installmentsCompleted: Ember.computed('transactions.[]', function() {
    return this.get('transactions.length');
  }),
  interval_type_name: Ember.computed('interval_type', function() {
    switch (this.get('interval_type')) {
      case 'd':
        return 'Days';
      case 'w':
        return 'Weeks';
      case 'm':
        return 'Months';
      default:
        return '';
    }
  }),
  isACH: Ember.computed('payment_method', function() {
    var payment_method = this.get('payment_method');
    return (payment_method) === 'ach' ? true : false;
  }),
  isCC: Ember.computed('payment_method', function() {
    var payment_method = this.get('payment_method');
    return (payment_method) === 'cc' ? true : false;
  }),
  isEditable: Ember.computed('end_date', 'isInstallment', 'next_run_date', function() {
    var end_date = this.get('end_date');
    if (
      (end_date && new Date() > new Date(end_date)) ||
      (this.get('isInstallment') && this.get('next_run_date') === null) ||
      this.get('status') === 'ended'
    ) {
      return false;
    } else {
      return true;
    }
  }),
  isInstallment: Ember.computed('installment_total_count', function() {
    return this.get('installment_total_count') > 0;
  }),
  isOngoing: Ember.computed('end_date', 'recurring_type_id', function() {
    if (!this.get('end_date') || this.get('end_date') === '0000-00-00' || this.get('recurring_type_id') === 'o') {
      return true;
    } else {
      return false;
    }
  }),
  isOnHold: Ember.computed('status', function() {
    var status = this.get('status');
    return (status === 'on hold') ? true : false;
  }),
  needSignature: Ember.computed('signature', 'created_ts', 'hasSigPad', function() {
    if (!this.get('hasSigPad') || this.get('hasSigPad') === 'false') {
      return false;
    }
    var ts = this.get('created_ts');
    if (ts) {
      var transTs = moment(ts);
      var nowTs = moment();
      if (Math.abs(transTs.diff(nowTs, 'minutes')) > 15) {
        return false;
      }
    }
    var signature = this.get('signature.id');
    if (signature) {
      return false;
    }
    return true;
  }),
  paymentAmountRemaining: Ember.computed('paymentCountRemaining', 'transaction_amount', function() {
    return parseInt(this.get('paymentCountRemaining')) * parseFloat(this.get('transaction_amount'));
  }),
  paymentCountRemaining: Ember.computed('installmentsCompleted', 'installment_total_count', function() {
    return parseInt(this.get('installment_total_count')) - parseInt(this.get('installmentsCompleted'));
  }),
  recurringTypeTitle: Ember.computed('recurring_type_id', function() {
    if(this.get('recurring_type_id') === 'o') {
      return 'Ongoing';
    } else if (this.get('recurring_type_id') === 'i') {
      return 'Installment';
    }
  }),
  tags_csv: Ember.computed('tags.[]', function() {
    var tags = this.get('tags');
    var tag_csv = '';
    tags.forEach(function(tag) {
      tag_csv += tag.get('title') + ',';
    });
    return tag_csv.replace(/\,$/, '');
  }),
  title: Ember.computed('description', function() {
    return this.get('description');
  }),
  total_collected: Ember.computed('transactions.@each.transaction_amount', function() {
    var transactions = this.get('transactions');
    var total = 0;
    transactions.forEach(function(trans) {
      if (trans.get('isSuccess')) {
        total += parseFloat(trans.get('transaction_amount'));
      }
    });
    return total;
  }),
  total_transaction_amount: Ember.computed('transaction_amount', 'product_transaction.id', 'product_transaction.surcharge.id', function() {
    var product = this.get('product_transaction');
    var total = 0;
    var surcharge = product.get('surcharge');
    var surcharge_amount = 0;
    var transaction_amount = parseFloat(this.get('transaction_amount')) || 0;
    if (surcharge && surcharge.get('surcharge_on_recurring')) {
      var min_fee_amount = parseFloat(surcharge.get('min_fee_amount')) || 0;
      var max_fee_amount = parseFloat(surcharge.get('max_fee_amount')) || 0;
      var surcharge_fee = parseFloat(surcharge.get('surcharge_fee')) || 0;
      var surcharge_rate = parseFloat(surcharge.get('surcharge_rate')) || 0;
      surcharge_amount = (surcharge_rate / 100 * transaction_amount).round(2) + surcharge_fee;
      surcharge_amount = (surcharge_amount > max_fee_amount) ? max_fee_amount : (surcharge_amount < min_fee_amount) ? min_fee_amount : surcharge_amount;
    }
    total = Number(surcharge_amount) + Number(transaction_amount);
    return total;
  }),
  productHasSurchargeOnRecurring: Ember.computed('product_transaction.id', 'product_transaction.surcharge.id', function() {
    var surcharge = this.get('product_transaction.surcharge');
    return surcharge && surcharge.get('surcharge_on_recurring');
  }),
  recurringSurchargeLabel: Ember.computed('product_transaction.id', 'product_transaction.surcharge.id', function() {
    var surcharge = this.get('product_transaction.surcharge');
    return surcharge ? surcharge.get('surcharge_label') : '';
  }),

  // Defined model relationships
  account_vault: DS.belongsTo('accountvault'),
  changelogs: DS.hasMany('changelog'),
  contact: DS.belongsTo('contact'),
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
  signature: DS.belongsTo('signature'),
  tags: DS.hasMany('tag'),
  transactions: DS.hasMany('transaction')
});
