import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    return Ember.RSVP.hash({
      model: params.model,
      model_id: params.model_id,
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var model = models.model;
    var model_id = models.model_id;
    controller.set('model', model);
    controller.set('model_id', model_id);

    // Set: Page Title - Crumbs - History
    controller.set('pageTitle', 'Not Found ● ' + model + ' ● ' + model_id);
    controller.set('pageCrumbs', []);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
