import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminSalesoffice: true,

  // Set controller params
  isEditing: null,
  isLoading: false,
  selectedResources: null,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('salesoffice.errors.messages', function() {
    this.set('errorMessages', this.get('salesoffice.errors.messages'));
  }),

  actions: {
    back: function() {
      this.transitionToRoute('admin.salesoffice.index');
    },
    cancel: function () {
      var salesoffice = this.get('salesoffice');
      salesoffice.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function () {
      var self = this;
      var salesoffice = this.get('salesoffice');
      pretty_confirm('Are you sure you want to delete this Sales Office?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        salesoffice.deleteRecord();
        salesoffice.save().then(function () {
          salesoffice.unloadRecord();
          noty({
            text: 'Sales Office deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.salesoffice.index');
        }, function () {
          salesoffice.rollbackAttributes();
          noty({
            text: 'Error deleting Sales Office!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var salesoffice = this.get("salesoffice");

      salesoffice.save().then(function (res) {
        var location_id = res.get('location_id');
        var locations = self.get('locations');
        if(locations) {
          locations.map(function(loc){
            if(loc.get('id') === location_id) {
              self.set('salesoffice.location', loc);
            }
          });
        }
        noty({
          text: 'Sales office saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function () {
        self.set('isLoading', false);
      });
    },
    updateLocation: function (data) {
      var self = this;
      var location = this.get('store').query('location', {
        name: data,
        page_size: '10'
      });
      location.then(function (result) {
        self.set('locations', result);
      });
    }
  }
});
