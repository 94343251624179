import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  portfolio_id: DS.attr('string'),
  user_id: DS.attr('string'),

  // Defined model relationships
  portfolio: DS.belongsTo('portfolio'),
  user: DS.belongsTo('user')
});
