import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  active: DS.attr('boolean'),
  cashback_enable: DS.attr('boolean'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  default_checkin: DS.attr('string'),
  default_checkout: DS.attr('string'),
  default_room_number: DS.attr('string'),
  default_room_rate: DS.attr('string'),
  header_line_1: DS.attr('string'),
  header_line_2: DS.attr('string'),
  header_line_3: DS.attr('string'),
  header_line_4: DS.attr('string'),
  header_line_5: DS.attr('string'),
  last_registration_ts: DS.attr('ts'),
  local_ip_address: DS.attr('string'),
  location_id: DS.attr('string'),
  mac_address: DS.attr('string'),
  port: DS.attr('string'),
  print_enable: DS.attr('boolean'),
  serial_number: DS.attr('string'),
  sig_capture_enable: DS.attr('boolean'),
  terminal_application_id: DS.attr('string'),
  terminal_cvm_id: DS.attr('string'),
  terminal_manufacturer_id: DS.attr('string'),
  terminal_number: DS.attr('string'),
  terminal_timeouts: DS.attr('array'),
  tip_enable: DS.attr('boolean'),
  title: DS.attr('string'),
  trailer_line_1: DS.attr('string'),
  trailer_line_2: DS.attr('string'),
  trailer_line_3: DS.attr('string'),
  trailer_line_4: DS.attr('string'),
  trailer_line_5: DS.attr('string'),

  // Computed Properties
  canClearDatabase: Ember.computed('terminal_manufacturer_id', function() {
    return String(this.get('terminal_manufacturer_id')) === '1';
  }),
  manufacturer_name: Ember.computed('terminal_manufacturer_id', function() {
    return String(this.get('terminal_manufacturer.title'));
  }),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  terminal_application: DS.belongsTo('terminalapplication'),
  terminal_cvm: DS.belongsTo('terminalcvm'),
  terminal_manufacturer: DS.belongsTo('terminalmanufacturer')
});
