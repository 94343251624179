import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        id: params.id,
        page_size: '500',
        product_transaction_active: '0,1',
        expand: 'product_transactions,branding_domain,tags',
      }),
      users: store.query('user', {
        location_id: params.id,
        page_size: '50',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000',
        status_id: 1
      }).then(function (data) {
        return data.toArray();
      }),
    });
  },
  afterModel: function(models, transition) {
    var contact_id = transition.to.queryParams.contact_id;
    var store = this.get('store');
    if(contact_id) {
      return Ember.RSVP.hash({
        initialContact: store.queryRecord('contact', {
          id: contact_id,
          expand: 'location'
        }).then(function(data){
          models.initialContact = data.get('titleWithAccountNumber');
        }),
      });
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locations.get('firstObject');
    controller.set('locations', models.locations);
    controller.set('location', location);
    controller.set('initialContact', models.initialContact);
    controller.set('users', models.users);

    var pagerParams = {
      pageRoute: 'locations.accounts-index',
      pageModelId: null,
      modelName: 'accountvault',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,contact,location,signature',
      location_id: location.get('id')
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● Account Vaults',
      route_name: 'locations.accounts-index',
      route_id: location.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    if(location.get('hasProductAccountVault')) {
      controller.set('pageSubtitle', {
        title: 'Add Account Vault',
        link: 'locations.accounts-add',
        record: location.get('id'),
        resource: this.get('session.authenticated.resources.v2-accountvaults-post')
      });
    }
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Account Vaults',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
