import DS from "ember-data";
import Application from "./application";

export default Application.extend({
  contact_id: DS.attr('string'),
  statement_number: DS.attr('string'),
  statement_balance: DS.attr('string'),
  log_email_id: DS.attr('string'),
  created_user_id: DS.attr('string'),
  transaction_id: DS.attr('string'),
  transaction_type: DS.attr('string'),
  access_key: DS.attr('string'),
  created_ts: DS.attr('ts'),
  statement_body: DS.attr('string'),
});
