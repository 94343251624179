import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),
  user_report_id: DS.attr('string'),

  // Computed Properties
  isLocationSharedReport: Ember.computed('model', function() {
    return this.get('model') === 'location';
  }),
  isUserSharedReport: Ember.computed('model', function() {
    return this.get('model') === 'user';
  }),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  user_report: DS.belongsTo('userreport'),
});
