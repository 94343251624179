import Ember from 'ember';

export default Ember.TextField.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  fieldId: null,
  values: null,
  classNames: ['form-control'],

  valueChange: Ember.observer('value', function() {
    var fieldId = this.get('fieldId');
    var values = this.get('values');
    if (values && fieldId) {
      values.set(fieldId, this.get('value'));
    }
  })
});
