import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsHelpPageLocations: true,

  // Set controller params
  sort: 'help_page_id',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true
      },
      title: {
        'name': 'helppage.title',
        'title': 'Help Page',
        'type': 'string',
        'sortable': true
      },
      user_type_id: {
        'name': 'helppage.user_type_id',
        'title': 'User Type Id',
        'type': 'string',
        'sortable': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(helppagelocation) {
          self.send('delete', helppagelocation);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'title', 'user_type_id', 'delete'];
    var listOfMobileCols = ['id', 'title', 'user_type_id', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-helppages-put')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-helppages-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(helppagelocation) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Help Page?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        helppagelocation.deleteRecord();
        helppagelocation.save().then(function() {
          noty({
            text: 'Help Page deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          helppagelocation.rollbackAttributes();
          noty({
            text: 'Error deleting Help Page!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
