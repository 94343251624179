import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('location.errors.messages', function() {
    this.set('errorMessages', this.get('location.errors.messages'));
  }),

  // Set controller params
  isLoading: false,

  // Computed Properties

  noParentLocation: Ember.computed('location.parent_id', function() {
    return !this.get('location.parent_id');
  }),

  // Actions
  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');

      location.save().then(function() {
        noty({
          text: 'Location saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.view', location.id);
      }, function() {
        noty({
          text: 'Error saving location!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('locations.index');
    },
    updateDomains: function(data) {
      var self = this;
      var domains = this.get('store').query('domain', {
        url: data,
        sort: 'url',
        page_size: '10',
        fields: 'id,url,title'
      });
      domains.then(function(result) {
        self.set('domains', result);
      });
    },
    updateParents: function(data) {
      var self = this;
      var parents = this.get('store').query('location', {
        name: data,
        sort: 'url',
        page_size: '10'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    copyFromParent: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var session = this.get('session');

      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/locations/' + this.get('location.parent_id') + '?expand=branding_domain',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        type: 'GET'
      }).then(function(res) {
        var custom_javascript = '';
        if (res.location && res.location.branding_domain && res.location.branding_domain.custom_javascript) {
          custom_javascript = res.location.branding_domain.custom_javascript;
        }
        self.set('location.branding_domain_custom_javascript', custom_javascript);
        self.set('isLoading', false);
      }, function() {
        noty({
          text: 'Error getting parent location custom javascript.',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    }
  }
});
