import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'id',
    'terminal_manufacturer_id',
    'title'
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(terminalcvm) {
          self.send('view', terminalcvm);
        },
        'actionId': 'terminalcvm_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      terminal_manufacturer_id: {
        'name': 'terminal_manufacturer_id',
        'title': 'Terminal Manufacturer Id',
        'type': 'string',
        'sortable': true,
        'action': function(terminalcvm) {
          self.transitionToRoute('admin.terminalmanufacturers.crud', terminalcvm.get('terminal_manufacturer_id'));
        },
        'actionpriv': 'v2-terminalmanufacturers-get',
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(terminalcvm) {
          self.send('view', terminalcvm);
        },
        'actionId': 'terminalcvm_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(terminalcvm) {
          self.send('delete', terminalcvm);
        },
        'actionId': 'terminalcvm_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'terminal_manufacturer_id', 'created_ts', 'view', 'delete'];
    var listOfMobileCols = ['title', 'terminal_manufacturer_id', 'created_ts', 'view', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-terminalcvms-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-terminalcvms-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(terminalcvm) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Terminal CVM?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        terminalcvm.deleteRecord();
        terminalcvm.save().then(function() {
          noty({
            text: 'Terminal CVM deleted successfully!'
          });
          self.send('refreshData');
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          terminalcvm.rollbackAttributes();
          noty({
            text: 'Error deleting Terminal CVM!' + error,
            type: 'error',
            timeout: 10000
          });
          self.send('refreshData');
          self.set('isLoading', false);
        });
      });
    },
    view: function(terminalcvm) {
      this.transitionToRoute('admin.terminalcvms.crud', terminalcvm.get('id'));
    },
  }
});
