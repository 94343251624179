import Ember from 'ember';

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),

  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  tagName: 'td',

  buttons: Ember.computed('cell', function () {
    var cell = this.get('cell');
    var actions = cell.action;
    return Object.keys(actions).reduce(function (buttons, action_name) {
      buttons[action_name] = Ember.String.htmlSafe(actions[action_name].display(cell.action_data));
      return buttons;
    }, {});
  }),

  actions: {
    run: function (action_name) {
      var cell = this.get('cell');
      return cell.action[action_name].run(cell.action_data);
    },
  }
});
