import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  bank_name: DS.attr('string'),
  created_ts: DS.attr('ts'),
  routing: DS.attr('string'),

  // Defined model relationships
  title: Ember.computed('id', function() {
    return this.get('id');
  }),
  titleWithBankName: Ember.computed('routing', 'bank_name', function() {
    var bank_name = this.get('bank_name');
    return bank_name ? (this.get('routing') + ' - ' + bank_name).trim() : this.get('routing').trim();
  })
});
