import emberFileUpload from 'ember-file-upload/components/file-upload/component';
import { get } from '@ember/object';

//
// Should ember-file-upload version ever get upgraded above version 2.7.1, this file should be double checked
//
// This file overrides the 'change' action in npm module ember-file-uploaded
// This action was causing an application error in the following scenario:
// Step 1: Edit Quick Invoice > go to the Files section
// Step 2: Click on 'Upload' in Files area
// Step 3: From operating system file popup select a file and drag/drop into area ....file should upload successfully, but operating system file popup is still open
// Step 4: From operating system file popup click 'OK' or 'Select' or whatever applicable action would select the file normally....
// The following error would show:
// "Cannot read property 'querySelector' of null"
// That error traces the change action in the ember-file-upload/addon/components/file-upload/components.js file....where there is no check that the element still exists
// This file copies the same functionality as the original action, but adds a check for the element so the application error does not occur.
// The original npm module looks like it was assuming the file upload input would always be visible, which isn't consistent with our desired user experience in several instances of file uploading.
//

export default emberFileUpload.extend({
  actions: {
    change: function(files) {
      get(this, 'queue')._addFiles(files, 'browse');
      if(this.element) {
        this.element.querySelector('input').value = null;
      }
    }
  }
});
