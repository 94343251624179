import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Events
  didInsertElement: function() {
    this.getBillingSources();
    this.getProductTransactions();
    this.getRoutingNumbers();

    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-locationbillingaccounts-delete'),
      canPost: this.get('session.authenticated.resources.v2-locationbillingaccounts-post'),
      canPut: this.get('session.authenticated.resources.v2-locationbillingaccounts-put'),
    });
  },
  willDestroyElement: function() {
    this.set('isTransitioning', false);
  },

  // Properties
  billing_sources: null,
  products: null,
  routingNumbers: null,

  // Computed Properties

  canChangeBillingProductTransactionId: Ember.computed('session.authenticated.resources.v2admin-locationbillingaccounts-put', 'isEditing', 'billing_sources.@each', function() {
    var isEditing = this.get('isEditing');
    var hasPriv = this.get('session.authenticated.resources.v2admin-locationbillingaccounts-put');
    var billing_sources_length = this.get('billing_sources.length');
    var isBillingSources = billing_sources_length >= 1 ? true : false;
    return isEditing && hasPriv && isBillingSources;
  }),
  canChangePortfolio: Ember.computed('session.authenticated.resources.v2-portfolios-get', 'isEditing', function() {
    var isEditing = this.get('isEditing');
    var hasPriv = this.get('session.authenticated.resources.v2-portfolios-get');
    return isEditing && hasPriv;
  }),
  canSave: Ember.computed('session.authenticated.resources.v2-locationbillingaccounts-put', 'session.authenticated.resources.v2-locationbillingaccounts-put', 'locationbillingaccount.isNew', function() {
    var canPOST = this.get('session.authenticated.resources.v2-locationbillingaccounts-post');
    var canPUT = this.get('session.authenticated.resources.v2-locationbillingaccounts-put');
    var isNew = this.get('locationbillingaccount.isNew');
    if ((isNew && canPOST) || (!isNew && canPUT)) {
      return true;
    }
    return false;
  }),
  canViewProducts: Ember.computed('session.authenticated.resources.v2-producttransactions-get', 'session.authenticated.resources.v2-producttransactiondetails-get', function() {
    var canProductTransactionGet = this.get('session.authenticated.resources.v2-producttransactions-get');
    var canProductTransactionDetailsGet = this.get('session.authenticated.resources.v2-producttransactiondetails-get');
    return canProductTransactionGet || (!canProductTransactionGet && canProductTransactionDetailsGet);
  }),
  isNewOrEditing: Ember.computed('locationbillingaccount.isNew', 'isEditingPaymentInfo', function() {
    var isNew = this.get('locationbillingaccount.isNew');
    var isEditing = this.get('isEditingPaymentInfo');
    return isNew || isEditing;
  }),
  notEditing: Ember.computed('isEditingPaymentInfo', function() {
    return !this.get('isEditingPaymentInfo');
  }),
  routing_bank_name: Ember.computed('locationbillingaccount.routing', 'routingNumbers.@each', function() {
    var routing = this.get('locationbillingaccount.routing');
    var routings = this.get('routingNumbers');
    if(!routing){
      return null;
    } else if (routings && routings.get('length')) {
      var tempRoutings = routings.filter(function(r) {
        return r.get('routing') === routing;
      });
      if (tempRoutings.get('length')) {
        return tempRoutings.get('firstObject').get('bank_name');
      } else {
        return null;
      }
    } else {
      return null;
    }
  }),
  showPaymentInfo: Ember.computed('isEditingPaymentInfo', 'locationbillingaccount.last_four', function() {
    return this.get('isEditingPaymentInfo') || this.get('locationbillingaccount.last_four');
  }),

  // Observers

  changePortfolioId: Ember.observer('locationbillingaccount.portfolio_id', 'portfolios.@each', function() {
    var self = this;
    var portfolio_id = this.get('locationbillingaccount.portfolio_id');
    var portfolios = this.get('portfolios');
    var portfolios_length = this.get('portfolios.length');
    if(!portfolio_id){
      return;
    } else if(portfolios_length && portfolio_id){
      var portfolio = portfolios.filter(function(p){
        return p.get('id') === portfolio_id;
      });
      var portfolio_length = portfolio.get('length');
      if(portfolio_length > 1){
        return;
      } else if(portfolio_length === 1){
        self.set('portfolio_name', portfolio.get('firstObject').get('title'));
      } else if(portfolio_length < 1){
        getPortfolios(self, portfolio_id);
      }
    } else if(!portfolios_length && portfolio_id){
      getPortfolios(self, portfolio_id);
    }

    function getPortfolios(context, id){
      var portfolios = context.get('store').query('portfolio', {
        id: id,
        page_size: '10',
        fields: 'id,title'
      });
      portfolios.then(function(result) {
        context.set('portfolio_name', result.get('title'));
      });
    }
  }),

  changeRouting: Ember.observer('locationbillingaccount.routing', 'routingNumbers', function() {
    var routing = this.get('locationbillingaccount.routing');
    var routings = this.get('routingNumbers');
    if (!routing || !routings) {
      return;
    }
    var r = routings.filter(function(item) {
      return item.get('id') === routing;
    });
    if (r.length === 1) {
      this.set('locationbillingaccount.routing_bank_name', r[0].get('bank_name'));
      this.set('locationbillingaccount.routing_number_with_bank_name_title', this.get('locationbillingaccount.routing') + ' - ' + r[0].get('bank_name'));
    }
  }),

  // Functions

  getBillingSources: function() {
    var self = this;
    var billing_sources = this.get('billing_sources');
    if (!this.get('locationbillingaccount.isNew')) {
      if (this.get('session.authenticated.resources.v2admin-locationbillingaccounts-put') && this.get('session.authenticated.resources.v2admin-billingsources-get')) {
        self.get('store').query('admin_billingsource', {
          sort: 'title',
        }).then(function(results) {
          results = results.filter(function(result) {
            return result.get('payment_method') === self.get('locationbillingaccount.payment_method');
          });
          if(!self.get('isDestroying') && !self.get('isDestroyed')) {
            self.set('billing_sources', results);
            setBillingProductTransactionId(results, self);
          }
        });
      } else {
        if(!self.get('isDestroying') && !self.get('isDestroyed')) {
          setBillingProductTransactionId(billing_sources, this);
        }
      }
    }

    function setBillingProductTransactionId(sources, context){
      if (sources && sources.length >= 1) {
        sources.forEach(function(billingsource) {
          if (billingsource.get('title') === context.get('locationbillingaccount.billing_descriptor') && !context.get('locationbillingaccount.isDestroyed')  && !context.get('locationbillingaccount.isDestroying')) {
            context.set('locationbillingaccount.billing_product_transaction_id', billingsource.get('id'));
          }
        });
      }
    }
  },
  getProductTransactions: function() {
    if (!this.get('locationbillingaccount.isNew')) {
      var canProductTransactionGet = this.get('session.authenticated.resources.v2-producttransactions-get');
      var canProductTransactionDetailsGet = this.get('session.authenticated.resources.v2-producttransactiondetails-get');
      var location_billing_account_id = this.get('locationbillingaccount.id');
      var self = this;
      self.set('products', null);
      var model;
      if (canProductTransactionGet) {
        model = 'producttransaction';
      } else if (!canProductTransactionGet && canProductTransactionDetailsGet) {
        model = 'producttransactiondetail';
      } else {
        this.set('products', null);
        return;
      }
      var products = self.get('store').query(model, {
        location_billing_account_id: location_billing_account_id,
        sort: 'title'
      });
      products.then(function() {
        self.set('products', products);
      });
    } else {
      this.set('products', null);
    }
  },
  getRoutingNumbers: function() {
    if (!this.get('locationbillingaccount.isNew')) {
      this.send('updateItems', this.get('locationbillingaccount.routing'));
    }
  },

  // Actions
  actions: {
    addPaymentInfo: function() {
      this.set('isEditingPaymentInfo', true);
      var locationbillingaccount = this.get('locationbillingaccount');
      if(!locationbillingaccount.get('account_type')){
        locationbillingaccount.set('account_type', 'checking');
      }
      if(!locationbillingaccount.get('is_company')){
        locationbillingaccount.set('is_company', '0');
      }
      if(!locationbillingaccount.get('payment_method')){
        locationbillingaccount.set('payment_method', 'ach');
      }
    },
    back: function() {
      if(this.get('isTransitioning')) {
        return;
      }
      this.get('router').transitionTo('locations.locationbillingaccount-index', this.get('location.id'));
    },
    cancel: function() {
      var locationbillingaccount = this.get('locationbillingaccount');
      if (locationbillingaccount.get('isNew')) {
        locationbillingaccount.deleteRecord();
        this.get('router').transitionTo('locations.locationbillingaccount-index', this.get('location.id'));
      } else {
        locationbillingaccount.rollbackAttributes();
        this.getBillingSources();
        this.set('isEditing', false);
        this.set('isEditingPaymentInfo', false);
      }
    },
    delete: function() {
      var self = this;
      var billingaccount = this.get('locationbillingaccount');
      pretty_confirm('Are you sure you want to delete this Billing Account?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        billingaccount.deleteRecord();
        billingaccount.save().then(function() {
          billingaccount.unloadRecord();
          noty({
            text: 'Billing Account deleted successfully!'
          });
          self.set('isLoading', false);
          self.get('router').transitionTo('locations.locationbillingaccount-index', self.get('location.id'));
        }, function(err) {
          billingaccount.rollbackAttributes();
          var errorText = '';
          if(err.errors){
            err.errors.forEach(function(err){
              errorText += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Billing Account ' + errorText,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    editPaymentInfo: function() {
      this.set('isEditingPaymentInfo', true);
    },
    editBillingDescriptor: function() {
      var self = this;
      var session = this.get('session');
      var billing_product_transaction_id = this.get('locationbillingaccount.billing_product_transaction_id');
      var billing_descriptor = this.get('locationbillingaccount.billing_descriptor');
      var billing_sources = this.get('billing_sources');
      var sendRequest = false;
      if(billing_sources && billing_sources.get('length')){
        billing_sources.map(function(billing_source) {
          if (billing_product_transaction_id === billing_source.get('id') && billing_descriptor !== billing_source.get('title')) {
            sendRequest = true;
          }
        });
      }
      if (sendRequest) {
        this.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + 'admin/locationbillingaccounts/' + this.get('locationbillingaccount.id') + '/billingsource',
          type: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          },
          data: JSON.stringify({
            'locationbillingaccount': {
              'billing_product_transaction_id': billing_product_transaction_id
            }
          })
        }).then(function() {
          self.set('isLoading', false);
        }, function() {
          self.set('isLoading', false);
          noty({
            text: 'Error changing Billing Descriptor on Billing Account!',
            type: 'error'
          });
        });
      }
    },
    updatePortfolios: function(data){
      var self = this;
      var portfolios = this.get('store').query('portfolio', {
        title: data,
        page_size: '10',
        fields: 'id,title'
      });
      portfolios.then(function(result) {
        self.set('portfolios', result);
      });
    },
    removeAccount: function() {
      var self = this;
      var session = this.get('session');
      pretty_confirm('Are you sure you want to delete payment information?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/locationbillingaccounts/' + self.get('locationbillingaccount.id') + '/removeaccount',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          self.set('locationbillingaccount.account_holder_name', null);
          self.set('locationbillingaccount.account_number', null);
          self.set('locationbillingaccount.account_type', null);
          self.set('locationbillingaccount.billing_zip', null);
          self.set('locationbillingaccount.exp_date', null);
          self.set('locationbillingaccount.is_company', null);
          self.set('locationbillingaccount.last_four', null);
          self.set('locationbillingaccount.routing', null);
          self.set('locationbillingaccount.routing_number_with_bank_name_title', null);
          self.set('isEditingPaymentInfo', false);
          self.set('isLoading', false);
          self.get('store').push({
            data: {
              id: self.get('locationbillingaccount.id'),
              type: 'locationbillingaccount',
              attributes: {
                account_holder_name: self.get('locationbillingaccount.account_holder_name'),
                account_number: self.get('locationbillingaccount.account_number'),
                account_type: self.get('locationbillingaccount.account_type'),
                billing_zip: self.get('locationbillingaccount.billing_zip'),
                exp_date: self.get('locationbillingaccount.exp_date'),
                is_company: self.get('locationbillingaccount.is_company'),
                last_four: self.get('locationbillingaccount.last_four'),
                routing: self.get('locationbillingaccount.routing'),
              }
            }
          });
          noty({
            text: 'Payment information successfully removed from Billing Account!',
          });
        }, function() {
          self.set('isLoading', false);
          noty({
            text: 'Error removing payment information from Billing Account!',
            type: 'error'
          });
        });
      });
    },
    save: function() {
      var locationbillingaccount = this.get('locationbillingaccount');
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var exp_month = this.get('locationbillingaccount.exp_month');
      var exp_year = this.get('locationbillingaccount.exp_year');
      if (exp_month && exp_year) {
        locationbillingaccount.set('exp_date', exp_month + exp_year);
      }

      if (this.get('session.authenticated.resources.v2admin-locationbillingaccounts-put')) {
        this.send('editBillingDescriptor');
      }
      var isNewLBA = locationbillingaccount.get('isNew');
      var self = this;
      locationbillingaccount.save().then(function(res) {
        self.get('store').push({
          data: {
            id: res.get('id'),
            type: 'locationbillingaccount',
            attributes: {
              account_number: null,
            }
          }
        });
        noty({
          text: 'Billing Account created successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.set('isEditingPaymentInfo', false);
        if(isNewLBA){
          self.get('router').transitionTo('locations.locationbillingaccount-view', self.get('location.id'), res.get('id'));
        } else {
          self.get('router').transitionTo('locations.locationbillingaccount-index', self.get('location.id'));
        }
      }, function() {
        noty({
          text: 'Error creating Billing Account!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    },
    updateItems: function(data) {
      var self = this;
      if(this.get('session.authenticated.resources.v2-routingnumbers-get')) {
        var routings = this.get('store').query('routingnumber', {
          routing: data,
          page_size: 20
        });
        routings.then(function(result) {
          self.set('routingNumbers', result);
        });
      } else {
        self.set('routingNumbers', []);
      }
    }
  }

});
