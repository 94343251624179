import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  showLocations: true,
  isRefreshing: true,
  sort: '-created_ts',
  page: 1,
  page_size: '15',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'card_type',
    'contact_id',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'exp_date',
    'first_six',
    'last_four',
    'location_id',
    'title',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      created_ts:{
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account Holder Name',
        'type': 'string',
        'sortable': true
      },
      contact_id: {
        'name': 'contact_id',
        'title': 'Contact Name',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.transitionToRoute('contacts.dashboard', account.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account Type',
        'type': 'image',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'accountTypes',
          multi: true,
          showAllSelect: true
        }
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.send('view', account);
        },
        'actionId': 'account_title_view_link',
        'filter': {
          type: 'text',
        }
      },
      first_six: {
        'name': 'first_six',
        'title': 'First Six',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
        }
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last Four',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
        }
      },
      exp_date: {
        'name': 'exp_date',
        'title': 'Expiration Date',
        'type': 'string',
        'sortable': false,
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.transitionToRoute('locations.view', account.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'filter': {
          type: 'dropdown',
          items: 'locations'
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'type': 'view',
        'action': function(account) {
          self.send('view', account);
        },
        'actionId': 'account_view_link',
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'account_holder_name', 'contact_id', 'account_type', 'first_six', 'last_four', 'exp_date', 'location_id', 'created_ts'];
    var listOfMobileCols = ['title', 'account_holder_name', 'contact_id', 'account_type', 'last_four', 'location_id', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    view: function(account) {
      this.transitionToRoute('accounts.view', account.get('id'));
    }
  }

});
