import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('locations/productaccountvaultform');
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      productaccountvault: store.queryRecord('productaccountvault', {
        id: params.product_id,
        active: '0,1',
        expand: 'created_user'
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('productaccountvault', models.productaccountvault);

    if (transition.to.queryParams.edit) {
      controller.set('isEditing', true);
    } else {
      controller.set('isEditing', false);
    }

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-productaccountvaults-delete'),
      canPost: this.get('session.authenticated.resources.v2-productaccountvaults-post'),
      canPut: this.get('session.authenticated.resources.v2-productaccountvaults-put'),
      canActivate: this.get('session.authenticated.resources.v2-productaccountvaults-post-activate')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● View Account Vault Service',
      route_name: 'locations.viewproductaccountvault',
      route_id: models.location.get('id'),
      route_id_plus: models.productaccountvault.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Services',
      link: 'locations.products',
      record: models.location.get('id')
    }, {
      title: 'View Account Vault Service',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var model = this.currentModel;
      var self = this;
      if (model.productaccountvault.get('hasDirtyAttributes') && this.get('session.isAuthenticated')) {
        transition.abort();
        pretty_confirm('You have unsaved information. Are you sure you want to leave this page?', function() {
          self.controller.set('isEditing', false);
          model.productaccountvault.rollbackAttributes();
          transition.retry();
        });
        return false;
      } else {
        self.controller.set('isEditing', false);
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
