import DS from 'ember-data';
import Application from './application';

export default Application.extend({

  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  location_id: DS.attr('string'),
  title: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location')
});
