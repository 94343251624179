import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  model: function() {
    return this.get('store').createRecord('contact', {});
  },
  setupController: function(controller, model) {
    controller.set('contact', model);

    new Ember.RSVP.Promise(function() {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/locations?url=' + window.UiENV.APP.DOMAIN,
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'GET',
        contentType: 'application/json'
      }).then(function(locations) {
        controller.set('locations', locations.locations);
      }, function() {
        noty({
          text: 'Error getting locations!',
          type: 'error'
        });
      });
    });
  }
});
