import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'branding_domain',
      }),
      terminalapplications: store.findAll('terminalapplication'),
      terminalcvms: store.query('terminalcvm', {
        expand: 'terminal_manufacturer',
        page_size: '500'
      }),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('terminalapplications', models.terminalapplications);
    controller.set('terminalcvms', models.terminalcvms);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Add Terminal',
      route_name: 'locations.terminals-add',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Terminals',
      link: 'locations.terminals',
      record: models.location.get('id')
    }, {
      title: 'Add Terminal',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
