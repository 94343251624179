import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLogapis: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'developer_id',
    'resource',
    'response_code',
    'request_method',
    'user_id'
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false,
          showTimeFilter: true
        }
      },
      developer_id: {
        'name': 'developer_id',
        'title': 'Developer Id',
        'type': 'string',
        'action': function(apilog) {
          self.transitionToRoute('admin.developers.view', apilog.get('developer_id'));
        },
        'actionpriv': 'v2-developers-get',
        'sortable': true,
        'filter': {
          type: 'text'
        },
        'isActionColumn': true
      },
      resource: {
        'name': 'resource',
        'title': 'Resource',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      response_code: {
        'name': 'response_code',
        'title': 'Response Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'response_codes',
          multi: true
        }
      },
      request_method: {
        'name': 'request_method',
        'title': 'Request Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'request_methods'
        }
      },
      user_id: {
        'name': 'user_id',
        'title': 'User Id',
        'type': 'string',
        'action': function(apilog) {
          self.transitionToRoute('admin.users.view', apilog.get('user_id'));
        },
        'actionpriv': 'v2admin-users-get',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'type': 'view',
        'action': function(apilog) {
          self.transitionToRoute('admin.apilogs.view', apilog.get('id'));
        },
        'isActionColumn': true
      }
    };

    var listOfCols = ['created_ts', 'request_method', 'response_code', 'resource', 'user_id', 'developer_id', 'view'];
    var listOfMobileCols = ['created_ts', 'request_method', 'response_code', 'resource', 'user_id', 'developer_id', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2admin-logapis-get')) {
        colsArray.removeObject(columns.view);
      }
    }
  }),

  // Actions
  actions: {}
});
