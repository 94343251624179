import Ember from 'ember';
import config from './config/environment';

var Router = Ember.Router.extend({
  location: config.locationType
});

Router.map(function() {
  this.route('/');
  this.route('fiveohfour');
  this.route('fourohfour');
  this.route('index', {
    path: '/'
  });
  this.route('altindex');
  this.route('help');
  this.route('sso');
  this.route('recurringnotification');
  this.route('signup');
  this.route('register');
  this.route('forgotpassword');
  this.route('resetpassword', {
    path: '/resetpassword/:id'
  });
  this.route('sigpadtest');
  this.route('login');
  this.route('virtualterminal');
  this.route('dashboard');
  this.route('dashboard-additionalgraphs', {
    path: '/dashboard/additionalgraphs'
  });
  this.route('transactiondetailfull', {
    path: '/transactiondetailfull/:id'
  });
  this.route('transactiondetaillevelthree', {
    path: '/transactiondetailfull/:id/L3data'
  });
  this.route('modelnotfound', {
    path: '/notfound/:model/:model_id'
  });
  this.route('contactuser', {
    resetNamespace: true
  }, function() {
    this.route('makepayment');
    this.route('paymenthistory');
    this.route('accounts-add', {
      path: '/accountvault/add'
    });
    this.route('accounts-view', {
      path: '/accountvault/view/:id/:accountvault_id'
    });
    this.route('accounts-index', {
      path: '/accountvaults'
    });
    this.route('quickinvoices-view', {
      path: '/invoices/view/:id/:quickinvoice_id'
    });
    this.route('quickinvoices-index', {
      path: '/invoices'
    });
    this.route('recurrings-index', {
      path: '/scheduledpayments'
    });
    this.route('recurrings-add', {
      path: '/scheduledpayment/add'
    });
    this.route('recurrings-view', {
      path: '/scheduledpayment/edit/:id/:recurring_id'
    });
    this.route('dashboard');
  });
  this.route('contacts', {
    resetNamespace: true
  }, function() {
    this.route('accounts-add', {
      path: '/accounts/add/:id'
    });
    this.route('accounts-view', {
      path: '/accounts/view/:id/:accountvault_id'
    });
    this.route('accounts-index', {
      path: '/accounts/:id'
    });
    this.route('recurrings-add', {
      path: '/recurrings/add/:id'
    });
    this.route('recurrings-view', {
      path: '/recurrings/view/:id/:recurring_id'
    });
    this.route('recurrings-index', {
      path: '/recurrings/:contact_id' //cannot use just dynamic segment ':id' since recurring listing has filter by recurring's id property...having a query param and dynamic url segment both as 'id' causes ember error
    });
    this.route('add');
    this.route('charge', {
      path: '/charge/:id'
    });
    this.route('dashboard', {
      path: '/dashboard/:id'
    });
    this.route('emails', {
      path: '/emails/:id'
    });
    this.route('files', {
      path: '/files/:id'
    });
    this.route('notes', {
      path: '/notes/:id'
    });
    this.route('quickinvoices-add', {
      path: '/quickinvoices/add/:id'
    });
    this.route('quickinvoices-index', {
      path: '/quickinvoices/:id'
    });
    this.route('quickinvoices-view', {
      path: '/quickinvoices/view/:id/:quickinvoice_id'
    });
    this.route('transactions', {
      path: '/transactions/:id'
    });
    this.route('view', {
      path: '/view/:id'
    });
    this.route('viewuser', {
      path: '/viewuser/:id'
    });
    this.route('virtualterminal', {
      path: '/virtualterminal/:id/:accountvault_id'
    });
  });
  this.route('locations', {
    resetNamespace: true
  }, function() {
    this.route('add');
    this.route('view', {
      path: '/view/:id'
    });
    this.route('accounts-add', {
      path: '/accounts/add/:id'
    });
    this.route('accounts-view', {
      path: '/accounts/view/:id/:accountvault_id'
    });
    this.route('accounts-index', {
      path: '/accounts/:id'
    });
    this.route('addfilecategory', {
      path: '/addfilecategory/:id'
    });
    this.route('addons', {
      path: '/addons/:addons_id'
    });
    this.route('addnotificationalert', {
      path: '/addnotificationalert/:id'
    });
    this.route('addnotificationtemplate', {
      path: '/addnotificationtemplate/:id'
    });
    this.route('addproductaccountvault', {
      path: '/addproductaccountvault/:id'
    });
    this.route('addproductfile', {
      path: '/addproductfile/:id'
    });
    this.route('addproductrecurring', {
      path: '/addproductrecurring/:id'
    });
    this.route('addproducttransaction', {
      path: '/addproducttransaction/:id'
    });
    this.route('addtag', {
      path: '/addtag/:id'
    });
    this.route('addvisibilitygroup', {
      path: '/addvisibilitygroup/:id'
    });
    this.route('billingstatements', {
      path: '/billingstatements/:location_id'
    });
    this.route('contacts-add', {
      path: '/contacts/:id/add'
    });
    this.route('contacts-index', {
      path: '/contacts/:id'
    });
    this.route('dashboard', {
      path: '/dashboard/:id'
    });
    this.route('dashboard-additionalgraphs', {
      path: '/dashboard/additionalgraphs/:id'
    });
    this.route('deviceterms-index', {
      path: '/deviceterms/:location_id'
    });
    this.route('deviceterms-view', {
      path: '/deviceterms/view/:location_id/:deviceterm_id'
    });
    this.route('editfilecategory', {
      path: '/editfilecategory/:id/:filecategory_id'
    });
    this.route('editnotificationalert', {
      path: '/editnotificationalert/:id/:notificationalert_id'
    });
    this.route('editnotificationtemplate', {
      path: '/editnotificationtemplate/:id/:notificationtemplate_id'
    });
    this.route('edittag', {
      path: '/edittag/:id/:tag_id'
    });
    this.route('editvisibilitygroup', {
      path: '/editvisibilitygroup/:id/:visibilitygroup_id'
    });
    this.route('filecategories', {
      path: '/filecategories/:id'
    });
    this.route('files', {
      path: '/files/:id'
    });
    this.route('helppages-index', {
      path: '/helppages/:id'
    });
    this.route('helppages-add', {
      path: '/helppages/add/:id'
    });
    this.route('hpp-add', {
      path: '/hostedpaymentpages/add/:id'
    });
    this.route('hpp-view', {
      path: '/hostedpaymentpages/view/:id/:hppId'
    });
    this.route('hpp-index', {
      path: '/hostedpaymentpages/:id'
    });
    this.route('locationbillingaccount-add', {
      path: '/billingaccounts/add/:id'
    });
    this.route('locationbillingaccount-view', {
      path: '/billingaccounts/view/:id/:locationbillingaccountId'
    });
    this.route('locationbillingaccount-index', {
      path: '/billingaccounts/:id'
    });
    this.route('migrateproducttransaction', {
      path: '/migrateproducttransaction/:id/:product_id'
    });
    this.route('notes', {
      path: '/notes/:id'
    });
    this.route('notificationalerts', {
      path: '/notificationalerts/:id'
    });
    this.route('notificationtemplates', {
      path: '/notificationtemplates/:id'
    });
    this.route('partnerdrchrono', {
      path: '/partnerdrchrono/:id'
    });
    this.route('postbackconfigs-index', {
      path: '/postbackconfigs/:id'
    });
    this.route('postbackconfigs-add', {
      path: '/postbackconfigs/add/:id'
    });
    this.route('postbackconfigs-edit', {
      path: '/postbackconfigs/edit/:id/:postbackconfig_id'
    });
    this.route('postbacklogs', {
      path: '/postbacklogs/:location_id'
    });
    this.route('products', {
      path: '/products/:id'
    });
    this.route('quickinvoices-add', {
      path: '/quickinvoices/add/:id'
    });
    this.route('quickinvoices-index', {
      path: '/quickinvoices/:id'
    });
    this.route('quickinvoices-view', {
      path: '/quickinvoices/view/:id/:quickinvoice_id'
    });
    this.route('quickinvoicesettings', {
      path: '/quickinvoicesettings/:id'
    });
    this.route('recurrings-add', {
      path: '/recurrings/add/:id'
    });
    this.route('recurrings-view', {
      path: '/recurrings/view/:id/:recurring_id'
    });
    this.route('recurrings-index', {
      path: '/recurrings/:location_id' //cannot use just dynamic segment ':id' since recurring listing has filter by recurring's id property...having a query param and dynamic url segment both as 'id' causes ember error
    });
    this.route('reports-ach-transactionsummary', {
      path: '/transactionsummaryach/:id'
    });
    this.route('reports-ach-transactionsettlement', {
      path: '/transactionachsettlement/:id'
    });
    this.route('reports-ach-transactionsettlementsummary', {
      path: '/transactionachsettlementsummary/:id'
    });
    this.route('reports-cc-transactionsummary', {
      path: '/transactionsummarycc/:id'
    });
    this.route('reports-cc-transactionsummarybytype', {
      path: '/transactionsummarybytype/:id'
    });
    this.route('reports-cc-unsettledtransactions', {
      path: '/unsettledtransactions/:id'
    });
    this.route('settings-changecausubscriptions', {
      path: '/settings/changecausubscriptions/:id'
    });
    this.route('settings-changeemailstrxreceipts', {
      path: '/settings/changeemailstrxreceipts/:id'
    });
    this.route('settings-changerecurringdepositaccount', {
      path: '/settings/changerecurringdepositaccount/:id'
    });
    this.route('settle', {
      path: '/settle/:id'
    });
    this.route('tags', {
      path: '/tags/:id'
    });
    this.route('terminals', {
      path: '/terminals/:id'
    });
    this.route('terminals-add', {
      path: '/terminals/add/:id'
    });
    this.route('terminals-view', {
      path: '/terminals/view/:id/:terminal_id'
    });
    this.route('transactions', {
      path: '/transactions/:id'
    });
    this.route('users-add', {
      path: '/adduser/:id'
    });
    this.route('users-authroles', {
      path: '/viewuser/authroles/:id/:user_id'
    });
    this.route('users-changepassword', {
      path: '/viewuser/changepassword/:id/:user_id'
    });
    this.route('users-emaillogs', {
      path: '/viewuser/emaillogs/:id/:user_id'
    });
    this.route('users-featureflags', {
      path: '/viewuser/featureflags/:id/:user_id'
    });
    this.route('users-index', {
      path: '/users/:id'
    });
    this.route('users-locations', {
      path: '/viewuser/locations/:id/:user_id'
    });
    this.route('users-preferences', {
      path: '/viewuser/preferences/:id/:user_id'
    });
    this.route('users-userreports', {
      path: '/viewuser/userreports/:id/:user_id'
    });
    this.route('users-view', {
      path: '/viewuser/:id/:user_id'
    });
    this.route('users-visibilitygroups', {
      path: '/viewuser/visibilitygroups/:id/:user_id'
    });
    this.route('viewbillingstatement', {
      path: '/viewbillingstatement/:id/:billingstatement_id'
    });
    this.route('viewproductaccountvault', {
      path: '/viewproductaccountvault/:id/:product_id'
    });
    this.route('viewproductfile', {
      path: '/viewproductfile/:id/:product_id'
    });
    this.route('viewproductrecurring', {
      path: '/viewproductrecurring/:id/:product_id'
    });
    this.route('viewproducttransaction', {
      path: '/viewproducttransaction/:id/:product_id'
    });
    this.route('viewproducttransaction-billing', {
      path: '/viewproducttransaction/billing/:id/:product_id'
    });
    this.route('viewproducttransaction-receipts', {
      path: '/viewproducttransaction/receipts/:id/:product_id'
    });
    this.route('viewproducttransaction-surcharge', {
      path: '/viewproducttransaction/surcharge/:id/:product_id'
    });
    this.route('viewproducttransactiondetail', {
      path: '/viewproducttransactiondetail/:id/:product_id'
    });
    this.route('viewproducttransactiondetail-receipts', {
      path: '/viewproducttransactiondetail/receipts/:id/:product_id'
    });
    this.route('viewproducttransactiondetail-surcharge', {
      path: '/viewproducttransactiondetail/surcharge/:id/:product_id'
    });
    this.route('visibilitygroups', {
      path: '/visibilitygroups/:id'
    });
  });
  this.route('reports', {
    resetNamespace: true
  }, function() {
    this.route('ach-transactionsummary', {
      path: 'transactionsummaryach'
    });
    this.route('ach-transactionsettlement', {
      path: 'transactionachsettlement'
    });
    this.route('ach-transactionsettlementsummary', {
      path:'transactionachsettlementsummary'
    });
    this.route('cc-transactionsummary', {
      path:'transactionsummarycc'
    });
    this.route('cc-transactionsummarybytype', {
      path: 'transactionsummarybytype'
    });
    this.route('transactions');
    this.route('transactionslegacy');
    this.route('accountexpiration');
    this.route('recurrings');
    this.route('cardsadded');
    this.route('paymentsourcesummary');
    this.route('accountvaultcaulogreport')
  });
  this.route('users', {
    resetNamespace: true
  }, function() {
    this.route('changepassword');
    this.route('profile');
  });

  //User Mailbox Messages
  this.route('mailboxmessagesuser', {
    resetNamespace: true,
    path: '/mailboxmessages'
  }, function() {
    this.route('index', {
      path: '/inbox'
    });
    this.route('view', {
      path: '/view/:id'
    });
    this.route('archive', {
      path: '/archive'
    });
  });
  this.route('quickinvoice', {
    resetNamespace: true,
    path: '/quickinvoice'
  }, function() {
    this.route('add', {
      path: '/add'
    });
    this.route('index', {
      path: '/index'
    });
    this.route('view', {
      path: '/view/:id'
    });
  });
  this.route('accounts', {
    resetNamespace: true,
    path: '/accounts'
  }, function() {
    this.route('add', {
      path: '/add'
    });
    this.route('index', {
      path: '/index'
    });
    this.route('view', {
      path: '/view/:id'
    });
  });
  this.route('recurrings', {
    resetNamespace: true,
    path: '/recurrings'
  }, function() {
    this.route('add', {
      path: '/add'
    });
    this.route('index', {
      path: '/index'
    });
    this.route('view', {
      path: '/view/:id'
    });
  });

  this.route('admin', {
    resetNamespace: true,
  }, function() {
    this.route('contacts', {
      path: '/contacts'
    }, function() {
      this.route('import', {
        path: '/import'
      });
      this.route('index', {
        path: '/'
      });
    });
    this.route('developercompanies', {
      path: '/developercompanies'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('developers', {
      path: '/developers'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('deviceterms', {
      path: '/deviceterms'
    }, function() {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('domains', {
      path: '/domains'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
      this.route('attached-locations', {
        path:'/attached-locations/:attached_id'
      });
      this.route('allowable-add-ons', {
        path:'/allowable-add-ons/:allowable_id'
      });
    });
    this.route('featureflags', {
      path: '/featureflags'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('helppages', {
      path: '/helppages'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('sharinggroups', {
      path: '/sharinggroups'
    }, function () {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('apilogs', {
      path: '/apilogs'
    }, function() {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('mailboxmessages', {
      path: '/mailboxmessages'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('notificationtemplates', {
      path: '/notificationtemplates'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('edit', {
        path: '/edit/:edit_id'
      });
      this.route('index', {
        path: '/'
      });
    });
    this.route('portfolios', {
      path: '/portfolios'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('postbackconfigs', {
      path: '/postbackconfigs'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('postbacklogs', {
      path: '/postbacklogs'
    }, function() {
      this.route('index', {
        path: '/'
      });
    });
    this.route('productbillinggroups', {
      path: '/productbillinggroups'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('reports', {
      path: '/reports'
    }, function() {
      this.route('developersummaryreports', {
        path: '/developersummaryreports'
      });
      this.route('devicelogs', {
        path: '/devicelogs'
      });
      this.route('onboardingdetails', {
        path: '/onboardingdetails'
      });
      this.route('portfoliodetailsonboardingreports', {
        path: '/portfoliodetailsonboardingreports'
      });
      this.route('portfoliosummaryreports', {
        path: '/portfoliosummaryreports'
      });
      this.route('accountvaultcaureports', {
        path: '/accountvaultcaureports'
      });
    });
    this.route('roles', {
      path: '/roles'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('grid', {
        path: '/grid'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('salestax', {
      path: '/salestax'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('salesoffice', {
      path: '/salesoffice'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('schedules', {
      path: '/schedules'
    }, function() {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('services', {
      path: '/services'
    }, function() {
      this.route('accountvault', {
        path: '/accountvault'
      });
      this.route('filestorage', {
        path: '/filestorage'
      });
      this.route('recurring', {
        path: '/recurring'
      });
      this.route('transaction', {
        path: '/transaction'
      });
    });
    this.route('terminalapplications', {
      path: '/terminalapplications'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('terminalcvms', {
      path: '/terminalcvms'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('crud', {
        path: '/view/:id'
      });
      this.route('index', {
        path: '/'
      });
    });
    this.route('terminalmanufacturers', {
      path: '/terminalmanufacturers'
    }, function() {
      this.route('crud', {
        path: '/view/:id'
      });
      this.route('index', {
        path: '/'
      });
    });
    this.route('terminals', {
      path: '/terminals'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('termsconditions', {
      path: '/termsconditions'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
    this.route('users', {
      path: '/users'
    }, function() {
      this.route('add', {
        path: '/add'
      });
      this.route('authroles', {
        path: '/view/authroles/:authroles_id'
      });
      this.route('changepassword', {
        path: '/changepassword/:changepassword_id'
      });
      this.route('emaillogs', {
        path: '/view/emaillogs/:emaillogs_id'
      });
      this.route('featureflags', {
        path: '/view/featureflags/:featureflags_id'
      });
      this.route('index', {
        path: '/'
      });
      this.route('locations', {
        path: '/view/locations/:locations_id'
      });
      this.route('userreports', {
        path: '/view/userreports/:userreports_id'
      });
      this.route('view', {
        path: '/view/:view_id'
      });
    });
  });

  // 404 Not Found
  this.route('notfound', {
    path: '*:'
  });
});

export default Router;
