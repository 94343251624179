import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      developer: store.queryRecord('developer', {
        id: params.view_id,
        expand: 'developer_company',
        active: '0,1'
      }),
      developerCompanies: store.query('developercompany', {
        page_size: '100'
      })
    });
  },

  setupController: function(controller, models) {
    var developer = models.developer;
    controller.set('developer', developer);
    controller.set('developerCompanies', models.developerCompanies);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-developers-delete'),
      canPost: this.get('session.authenticated.resources.v2admin-developers-post'),
      canPut: this.get('session.authenticated.resources.v2admin-developers-put')
    });

    this.send('addHistory', {
      title: 'View Developer ● ' + developer.get('title'),
      route_name: 'admin.developers.view',
      route_id: developer.get('id')
    });
    controller.set('pageTitle', 'View Developer');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Developers',
      link: 'admin.developers.index'
    }, {
      title: 'View Developer',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.developer, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
