import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // form header stuffs
  formHeader: Ember.computed('crudParams.{formHeaderProp,formModelName}', function() {
    var model = this.get('model');
    var prop = this.get('crudParams.formHeaderProp');
    return prop ? model.get(prop) : this.get('crudParams.formModelName');
  }),

  // button permissions
  hasOverallPermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('overall');
  }),
  hasBackPermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('back');
  }),
  hasCancelPermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('cancel');
  }),
  hasEditPermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('edit');
  }),
  hasDeletePermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('delete');
  }),
  hasSavePermission: Ember.computed('crudParams.buttonPermissions', function() {
    return this.permissionCheck('save');
  }),

  showDelete: Ember.computed('hasDeletePermission', 'model.isNew', function() {
    return this.get('hasDeletePermission') && !this.get('model.isNew');
  }),

  permissionCheck: function(perm) {
    var permissions = this.get('crudParams.buttonPermissions');
    var permission = permissions[perm];
    var hasPermission = this.get('session.authenticated.resources.' + permission);
    return permission && hasPermission;
  },

  actions: {
    updateModel: function(prop, value) {
      var model = this.get('model');
      model.set(prop, value);
    }
  }
});
