import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  active: DS.attr('boolean'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('ts'),
  location_id: DS.attr('string'),
  title: DS.attr('string'),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
});
