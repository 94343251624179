import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by form-accountvault component
  sessionService: Ember.inject.service('session'),
  vtcController: Ember.inject.controller('components/modals/virtualterminalcontact'), // needed by form-accountvault component

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsAccounts: true,

  // Actions
  actions: {}
});
