import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  resetController: function(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.set('force_account', null);
    }
  },

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        id: params.id,
        page_size: '500',
        product_transaction_active: '0,1',
        expand: 'product_transactions,branding_domain,tags',
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locations.get('firstObject');
    controller.set('location', location);
    controller.set('locations', models.locations);
    controller.set('isEditing', true);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● Add Recurring Billing',
      route_name: 'locations.recurrings-add',
      route_id: location.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Recurring Billings',
      link: 'locations.recurrings-index',
      record: location.get('id')
    }, {
      title: 'Add Recurring Billing',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
