import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (typeof value === 'undefined' || value === null) {
      return null;
    }
    return (value && value !== '0' && value !== 'false') ? '1' : '0';
  },
  deserialize: function(value) {
    return (parseInt(value) > 0);
  }
});