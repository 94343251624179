import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by grid-accounts component
  sessionService: Ember.inject.service('session'),
  vtcController: Ember.inject.controller('components/modals/virtualterminalcontact'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  active: '1',
  sort: 'account_holder_name',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_holder_name',
    'account_type',
    'ach_sec_code',
    'active',
    'contact_id',
    'exp_date',
    'last_four',
    'location_id',
    'needsRefresh',
    'payment_method',
    'title'
  ],

  // Actions
  actions: {}

});
