import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.store;
    var session = this.get('session');
    var getProducts = function() {
      if (session.get('authenticated.resources.v2-portfolios-get')) {
        return store.query('portfolio', {
          page_size: 100
        });
      }
    };
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        expand: 'product_transactions',
        id: params.id
      }),
      portfolios: getProducts(),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('portfolios', models.portfolios);

    var pagerParams = {
      pageRoute: 'locations.locationbillingaccount-index',
      pageModelId: models.location.get('id'),
      modelName: 'locationbillingaccount',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user',
      location_id: models.location.get('id'),
    };
    controller.set('gridParams', gridParams);
    controller.set('location_id', models.location.get('id'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Billing Accounts',
      route_name: 'locations.locationbillingaccount-index',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageSubtitle', {
      title: 'Add Billing Account',
      link: 'locations.locationbillingaccount-add',
      record: models.location.get('id'),
      resource: this.get('session.authenticated.resources.v2-locationbillingaccounts-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Billing Accounts',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
