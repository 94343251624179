import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsUsers: true,
  isUsersVisibilityGroups: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('locationuservisibilitygroups.errors.messages', function() {
    this.set('errorMessages', this.get('locationuservisibilitygroups.errors.messages'));
  }),

  // Computed Properties
  canEdit: Ember.computed('loggedInUserCanEditViewedUser', 'session.authenticated.resources.v2-locationuservisibilitygroups-post', 'session.authenticated.resources.v2-locationuservisibilitygroups-delete', function() {
    return this.get('loggedInUserCanEditViewedUser') && (this.get('session.authenticated.resources.v2-locationuservisibilitygroups-post') || this.get('session.authenticated.resources.v2-locationuservisibilitygroups-delete'));
  }),
  selectedVisibilities: Ember.computed('locationuservisibilitygroups.[]', function() {
    var locationuservisibilitygroups = this.get('locationuservisibilitygroups');
    var tempArray = [];
    if (locationuservisibilitygroups.get('length')) {
      locationuservisibilitygroups.forEach(function(group) {
        tempArray.push(group.get('visibility_group_id'));
      });
    }
    return tempArray;
  }),

  // Actions
  actions: {
    cancel: function() {
      var changesToMake = this.get('changesToMake');
      changesToMake.forEach(function(change){
        jQuery('#users_visibilitygroups_chk-' + change.visibility_group_id).trigger('click');
      });
      this.set('changesToMake', []);
      this.set('isEditing', false);
    },
    checkAllVisGroups: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (!jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
    changeVisibilities: function(data) {
      if (this.get('isLoading')) {
        return;
      }
      var hasVisibilities = this.get('selectedVisibilities').indexOf(data.id) !== -1;
      var changesToMake = this.get('changesToMake') || [];
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.visibility_group_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          visibility_group_id: data.id,
          title: data.title,
          type: hasVisibilities ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var changesToMake = this.get('changesToMake');
      var locationuservisibilitygroups = this.get('locationuservisibilitygroups');
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function(change, index) {
        if(change.type === 'POST') {
          canPostRoles.push(change);
          var locUserVisGroup = self.get('store').createRecord('locationuservisibilitygroup', {
            location_id: self.get('location.id'),
            user_id: self.get('user.id'),
            visibility_group_id: change.visibility_group_id
          });
          return locUserVisGroup.save().then(function(luvg) {
            locationuservisibilitygroups.addObject(luvg); // Push the object we just created back onto the list of file categories object
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
              sendNoty();
            }
          }, function() {
            // error
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
            }
            noty({
              text: 'Error adding Visibility Group ' + change.title,
              type: 'error',
              timeout: 10000
            });
          });
        } else if(change.type === 'DELETE') {
          canDeleteRoles.push(change);
          return locationuservisibilitygroups.forEach(function(luvisgroup) {
            if (luvisgroup.get('visibility_group_id') === change.visibility_group_id) {
              luvisgroup.destroyRecord().then(function() {
                locationuservisibilitygroups.removeObject(luvisgroup); // Remove the oject from the parent if it was successfully deleted
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                  sendNoty();
                }
              }, function() {
                //error
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                }
                noty({
                  text: 'Error removing Visibility Group ' + change.title,
                  type: 'error',
                  timeout: 10000
                });
              });
            }
          });
        }
      });

      self.set('isLoading', true);

      if(!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(function() {
          //
        }, function() {
          noty({
            text: 'Error applying changes!',
            type: 'error'
          });
          doneMakingChanges(self);
        });
      }
      function sendNoty() {
        if((canPostRoles && canPostRoles.length)) {
          var successMessagePost = '';
          canPostRoles.forEach(function(role) {
            successMessagePost += role.title;
            successMessagePost += '<br>';
          });
          noty({
            text: 'The following Visibility Groups were successfully added:<br>' + successMessagePost,
            timeout: 10000
          });
        }
        if((canDeleteRoles && canDeleteRoles.length)) {
          var successMessageDelete = '';
          canDeleteRoles.forEach(function(role){
            successMessageDelete += role.title;
            successMessageDelete += '<br>';
          });
          noty({
            text: 'The following Visibility Groups were successfully removed:<br>' + successMessageDelete,
            timeout: 10000
          });
        }
      }
      function doneMakingChanges (context) {
        context.set('isLoading', false);
        context.set('isEditing', false);
        context.set('changesToMake', []);
      }
    },
    uncheckAllVisGroups: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
  }
});
