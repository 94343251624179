import Ember from 'ember';

export default Ember.Helper.extend({

  compute(params) {
    var data = params.slice()[0];
    if (!data) {
      return;
    }
    return data.replace(/_/g, ' ')
      .replace(/(\w+)/g, function(match) {
        return match.charAt(0).toUpperCase() + match.slice(1);
      });
  }
});