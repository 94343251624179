import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminPortfolios: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'title',
    'credit_account_vault_id',
    'debit_account_vault_id',
    'parent_id'
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'action': function(portfolio) {
          self.send('view', portfolio);
        },
        'actionId': 'portfolio_title_view_link',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      credit_account_vault_id: {
        'name': 'credit_account_vault_id',
        'title': 'Credit Accountvault',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      debit_account_vault_id: {
        'name': 'debit_account_vault_id',
        'title': 'Debit Accountvault',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      parent_id: {
        'name': 'parent_id',
        'title': 'Parent Portfolio',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(portfolio) {
          self.send('delete', portfolio);
        },
        'actionId': 'portfolio_delete_link',
        'type': 'delete',
        'isActionColumn': true
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(portfolio) {
          self.send('view', portfolio);
        },
        'actionId': 'portfolio_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'credit_account_vault_id', 'debit_account_vault_id', 'parent_id', 'view', 'delete'];
    var listOfMobileCols = ['title', 'credit_account_vault_id', 'debit_account_vault_id', 'parent_id', 'view', 'delete'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-portfolios-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-portfolios-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(portfolio) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Portfolio?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        portfolio.deleteRecord();
        portfolio.save().then(function() {
          noty({
            text: 'Portfolio deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Portfolio!' + error,
            type: 'error',
            timeout: 10000
          });
          portfolio.rollbackAttributes();
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    view: function(portfolio) {
      this.transitionToRoute('admin.portfolios.view', portfolio.get('id'));
    },
  }
});
