import Ember from "ember";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),
  salesOfficeController: Ember.inject.controller(
    "components/modals/addsalesoffice"
  ),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "producttransaction.errors.messages",
    function () {
      this.set("errorMessages", this.get("producttransaction.errors.messages"));
    }
  ),

  // Set controller params
  isLoading: false,
  isEditing: true,
  notEditing: Ember.computed("isEditing", function () {
    return !this.get("isEditing");
  }),
  isCC: false,
  isACH: false,
  default_trans_types: null,
  processors: Ember.computed(function () {
    return this.get("applicationData.processors").filter(function (processor) {
      return processor.type === "cc";
    });
  }),
  cc_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.cc_default_trans_types");
  }),
  ach_default_trans_types: Ember.computed(function () {
    return this.get("applicationData.ach_default_trans_types");
  }),

  canHPP: Ember.computed(
    "producttransaction.{payment_method,processor,isSubProcessorPaya}",
    function () {
      if (this.get("producttransaction.payment_method") === "cc") {
        return true;
      } else if (this.get("producttransaction.payment_method") === "ach") {
        if (this.get("producttransaction.isSubProcessorPaya")) {
          var terminals = this.get(
            "producttransaction.processor_data.terminals"
          );
          var hasValidSECCode = false;
          if (!terminals || terminals.get("length") === 0) {
            return false;
          }
          terminals.map(function (terminal) {
            var sec_code = terminal.sec_code;
            if (
              sec_code === "CCD" ||
              sec_code === "PPD" ||
              sec_code === "WEB"
            ) {
              hasValidSECCode = true;
            }
          });
          return hasValidSECCode;
        } else {
          return true;
        }
      }
    }
  ),
  product_billing_group: Ember.computed(
    "producttransaction.product_billing_group_id",
    "productbillinggroups.[]",
    function () {
      var productbillinggroups = this.get("productbillinggroups");
      var product_billing_group_id = this.get(
        "producttransaction.product_billing_group_id"
      );
      if (productbillinggroups && productbillinggroups.get("length")) {
        return productbillinggroups
          .filter(function (pbg) {
            return pbg.get("id") === product_billing_group_id;
          })
          .get("firstObject");
      }
    }
  ),

  vtCvvRequired: Ember.computed(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_cvv")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtCvv: Ember.observer(
    "producttransaction.vt_cvv",
    "isEditing",
    function () {
      if (
        this.get("producttransaction.isDestroyed") ||
        this.get("producttransaction.isDestroying")
      ) {
        return;
      }
      var vt_street = this.get("producttransaction.vt_cvv");
      if (!vt_street) {
        this.set("producttransaction.vt_require_cvv", false);
      }
    }
  ),
  vtStreetRequired: Ember.computed(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_street")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changedVtStreet: Ember.observer(
    "producttransaction.vt_street",
    "isEditing",
    function () {
      var vt_street = this.get("producttransaction.vt_street");
      if (!vt_street) {
        this.set("producttransaction.vt_require_street", false);
      }
    }
  ),
  changedVtZip: Ember.observer(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      var vt_zip = this.get("producttransaction.vt_zip");
      if (!vt_zip) {
        this.set("producttransaction.vt_require_zip", false);
      }
    }
  ),
  vtZipRequired: Ember.computed(
    "producttransaction.vt_zip",
    "isEditing",
    function () {
      if (this.get("isEditing") && this.get("producttransaction.vt_zip")) {
        return false;
      } else {
        return true;
      }
    }
  ),
  changeSubProcessor: Ember.observer(
    "producttransaction.processor_data.sub_processor",
    function () {
      var sub_processor = this.get(
        "producttransaction.processor_data.sub_processor"
      );
      this.set("isFirstData", sub_processor === "fdnash");
      this.set("isTsys", sub_processor === "vital");
    }
  ),
  batchCloseAutomatic: Ember.computed(
    "producttransaction.processor_data.batch_close_type",
    function () {
      return (
        this.get("producttransaction.processor_data.batch_close_type") ===
        "automatic"
      );
    }
  ),
  changeProcessor: Ember.observer("processor", function () {
    var self = this;
    var processors = this.get("processors");
    var processor = this.get("processor") || null;

    self.set("isPaymentMethodCc", false);
    self.set("isPaymentMethodAch", false);
    self.set("isProcessorTsys", false);
    self.set("isProcessorNashville", false);
    self.set("isProcessorFdnash", false);
    self.set("isProcessorElavon", false);
    self.set("isProcessorVantiv", false);

    processors.forEach(function (p) {
      // self.set('isProcessor' + p.id.capitalize(), p.id === processor);
      if (p.id === processor) {
        self.set("producttransaction.processor", p.processor);
        self.set("isProcessorTsys", p.sub_processor === "tsys");
        self.set("isProcessorNashville", p.sub_processor === "nashville");
        self.set("isProcessorFdnash", p.processor === "zgate");
        self.set("isProcessorElavon", p.sub_processor === "elavon");
        self.set("isProcessorVantiv", p.sub_processor === "vantiv610");

        self.set("isPaymentMethod" + p.type.capitalize(), true);
        self.set("producttransaction.payment_method", p.type);
        self.set(
          "default_trans_types",
          self.get(p.type + "_default_trans_types")
        );
        self.set(
          "producttransaction.default_transaction_type",
          self.get(p.type + "_default_trans_types")[0].id
        );
        self.set(
          "producttransaction.processor_data.sub_processor",
          p.sub_processor
        );
        self.set("producttransaction.sub_processor", p.sub_processor);
        // self.set('isFirstData', p.sub_processor === 'fdnash' || p.sub_processor === 'nashville');
        // self.set('isNashDirect', p.sub_processor === 'nashville');
        // self.set('isTsys', p.sub_processor === 'vital');

        if (p.type === "ach") {
          self.set("producttransaction.mcc", "0000");
          self.set(
            "producttransaction.receipt_add_account_above_signature",
            "I authorize Merchant, to electronically debit my Bank Account indicated above for any fees due Merchant. If this item is dishonored for any reason, I authorize Merchant to initiate an additional electronic debit to the same Account for a returned item fee in the amount of $30. My signature and/or electronic submission on this agreement below shall be my authorization to execute these electronic transactions. This authorization shall remain in full force and effect until  Merchant has received written notification from me of its termination in such a time and manner as to afford  Merchant a reasonable opportunity to act on it."
          );
        } else {
          self.set(
            "producttransaction.receipt_add_account_above_signature",
            null
          );
        }
      }
    });
    if (!this.get("isProcessorTsys")) {
      this.set("producttransaction.payfac_enable", false);
      this.set("producttransaction.processor_data.payfac_data", {});
    }
    if (
      !this.get("isProcessorElavon") &&
      !this.get("isProcessorTsys") &&
      !this.get("isProcessorElavon")
    ) {
      this.set("producttransaction.processor_data.bank_id", null);
    }
  }),

  level3_allow_observer: Ember.observer(
    "producttransaction.level3_allow",
    "isEditing",
    function () {
      var isEditing = this.get("isEditing");
      var level3_allow = this.get("producttransaction.level3_allow");
      var level3_default = this.get("producttransaction.level3_default");
      var defaultLevel3 = this.get("applicationData.level3_defaults");
      if (level3_allow && !level3_default) {
        this.set("producttransaction.level3_default", defaultLevel3);
      } else if (!level3_allow) {
        this.set("producttransaction.level3_default", null);
      } else if (level3_allow && level3_default && isEditing) {
        this.set(
          "producttransaction.level3_default",
          this.get("producttransaction.level3_default")
        );
      }
    }
  ),

  cauEnabledObserver: Ember.observer("cauEnabled", function () {
    if (!this.get("cauEnabled")) {
      this.set("producttransaction.cau_account_number", null);
      this.set("producttransaction.cau_subscribe_type_id", "0");
    }
  }),

  payfac_enable_observer: Ember.observer(
    "producttransaction.payfac_enable",
    function () {
      var payfac_enable = this.get("producttransaction.payfac_enable");
      var payfac_data = this.get(
        "producttransaction.processor_data.payfac_data"
      );
      if (
        payfac_enable &&
        payfac_data &&
        Object.keys(payfac_data).length === 0
      ) {
        this.set("producttransaction.processor_data.payfac_data", {
          amex: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_street_address: this.get("location.address1"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
          mc: {
            sub_merchant_name: this.get("location.name"),
            sub_merchant_city: this.get("location.city"),
            sub_merchant_state: this.get("location.state"),
            sub_merchant_postal_code: this.get("location.zip_code"),
            sub_merchant_phone: this.get("location.office_phone"),
            sub_merchant_email: this.get("location.email_reply_to"),
          },
        });
      } else if (!payfac_enable) {
        this.set("producttransaction.processor_data.payfac_data", {});
        this.set("isViewingPayfacData", false);
      }
    }
  ),

  // Actions
  actions: {
    delete: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    reActivateProduct: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    updatebillingAccountLocation: function () {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function () {
      // Bail if we are already loading
      if (this.get("isLoading")) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var location = this.get("location");
      var producttransaction = this.get("producttransaction");
      var level3_default = this.get("producttransaction.level3_default");
      if (level3_default !== "" && producttransaction.get("level3_allow")) {
        if (typeof level3_default === "string") {
          this.set(
            "producttransaction.level3_default",
            JSON.parse(level3_default)
          );
        } else {
          this.set("producttransaction.level3_default", level3_default);
        }
      } else {
        this.set("producttransaction.level3_default", null);
      }
      producttransaction.get("errors").clear();
      if (!this.get("isProcessorNashville")) {
        producttransaction.set("processor_data.merchant_tax_id", null);
      }

      producttransaction.save().then(
        function () {
          noty({
            text: "Deposit Account Service saved successfully!",
          });
          self.set("isLoading", false);
          self.transitionToRoute("locations.products", location.id);
        },
        function (errors) {
          if (errors.errors) {
            self.set("defaultlevel3errors", errors.errors);
          }
          window.scrollTo(0, 0);
          self.set(
            "producttransaction.level3_default",
            JSON.stringify(self.get("producttransaction.level3_default"))
          );
          self.set("isLoading", false);
        }
      );
    },
    reset: function () {
      var location = this.get("location");
      this.transitionToRoute("locations.products", location.id);
    },
    back: function () {
      var location = this.get("location");
      this.transitionToRoute("locations.products", location.id);
    },
    updatebillingGroups: function (data) {
      var self = this;
      if (
        this.get("session.authenticated.resources.v2-productbillinggroups-get")
      ) {
        var productbillinggroups = this.get("store").query(
          "productbillinggroup",
          {
            title: data,
            sort: "title",
            page_size: "10",
            fields: "id,title",
          }
        );
        productbillinggroups.then(function (result) {
          self.set("productbillinggroups", result);
        });
      }
    },
    viewDefaults: function () {
      this.toggleProperty("isViewingL3");
    },
    viewPayfacData: function () {
      this.toggleProperty("isViewingPayfacData");
      return;
    },
    addSalesOffice: function () {
      var controller = this.get("salesOfficeController");
      var new_salesoffice = this.get("store").createRecord("salesoffice", {
        title: null,
        contact_name: null,
        phone: null,
        misc: null,
        email: null,
        location_id: this.get("producttransaction.location_id"),
      });
      this.set("producttransaction.sales_office_id", null);
      controller.set("salesoffice", new_salesoffice);
      controller.set("sender", "migrateproducttransaction");
      this.send("openModal", "components/modals/addsalesoffice");
    },
    updateSalesOffice: function (data) {
      var self = this;
      var new_sales_office_id = self.get("new_sales_office_id");
      var salesoffice = this.get("store").query("salesoffice", {
        id: new_sales_office_id,
        title: data,
        sort: "title",
        page_size: "10",
        fields: "id,title",
      });
      salesoffice.then(function (result) {
        self.set("salesoffice", result);
        Ember.run.later(function () {
          if (new_sales_office_id) {
            self.set(
              "producttransaction.sales_office_id",
              self.get("new_sales_office_id")
            );
            self.set("new_sales_office_id", null);
          }
        }, 1);
        self.set("isLoadingSalesOffice", false);
      });
    },
  },
});
