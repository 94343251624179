import DS from "ember-data";
import Application from "./application";

export default Application.extend(DS.EmbeddedRecordsMixin,{
  attrs: {
    location: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
