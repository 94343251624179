import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  resetController: function(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.set('force_account', null);
    }
  },

  model: function(params) {
    return Ember.RSVP.hash({
      contact: this.get('store').queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      })
    });
  },

  afterModel: function(models, transition) {
    var self = this;
    var store = this.get('store');
    var session = this.get('session');
    const contact = models.contact;

    var getAccounts = function() {
      if (session.get('authenticated.resources.v2-accountvaults-get')) {
        return store.query('accountvault', {
          contact_id: contact.get('id'),
          page_size: '500'
        }).then(function(data) {
          models.accounts = data;
        });
      } else {
        return models.accounts = [];
      }
    };

    return this.get('notFound').check(contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: contact.get('location_id')
        }).then(function(data) {
          models.locationinfos = data;
        }),
        accounts: getAccounts()
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('accounts', models.accounts.toArray()); // using .toArray() to get mutable array in case where new account is created successfully, but POST recurring returns 422
    controller.set('locationinfo', location);
    controller.set('locationinfos', models.locationinfos);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Add Recurring Billing',
      route_name: 'contacts.recurrings-add',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Recurring Billings',
      link: 'contacts.recurrings-index',
      record: models.contact.get('id')
    }, {
      title: 'Add Recurring Billing',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
