import DS from "ember-data";
import Application from "./application";
import Ember from "ember";

export default Application.extend({
  allow_contact_registration: DS.attr("boolean"),
  allow_contact_signup: DS.attr("boolean"),
  allow_contact_login: DS.attr("boolean"),
  allow_qbo: DS.attr("boolean"),
  created_ts: DS.attr("ts"),
  modified_ts: DS.attr("ts"),
  custom_css: DS.attr("string"),
  custom_javascript: DS.attr("string"),
  custom_theme: DS.attr("string"),
  fav_icon: DS.attr("string"),
  help_text: DS.attr("string"),
  hide_blind_payment: DS.attr("boolnum"),
  nav_logo: DS.attr("string"),
  registration_fields: DS.attr("fakearray"),
  title: DS.attr("string"),
  url: DS.attr("string"),

  // Computed Properties
  allowContactUsers: Ember.computed(
    "allow_contact_signup",
    "allow_contact_registration",
    function () {
      return (
        this.get("allow_contact_signup") ||
        this.get("allow_contact_registration") ||
        false
      );
    }
  ),
  logo_image: Ember.computed("nav_logo", function () {
    return this.get("nav_logo")
      ? this.get("nav_logo") + "?" + moment().valueOf()
      : null;
  }),

  // Defined model relationships
  created_user: DS.belongsTo("user"),
  locations: DS.hasMany("location"),
});
