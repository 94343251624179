import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  beforeModel: function() {
    var controller = this.controllerFor('contactuser.dashboard');
    controller.set('accounts', null);
    controller.set('transactions', null);
    controller.set('recurrings', null);
  },
  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contactuser: store.queryRecord('contact', {
        id: params.id,
        expand: 'user'
      })
    });
  },
  afterModel: function(models) {
    if (Ember.isEmpty(models.contactuser)) {
      return;
    }
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        id: models.contactuser.get('location_id'),
        product_transaction_active: '0,1'
      }).then(function(data) {
        models.locationinfos = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    if (Ember.isEmpty(models.contactuser)) {
      return;
    }
    var location = models.locationinfos.get('firstObject');
    controller.set('contactuser', models.contactuser);
    controller.set('locationinfo', location);
    controller.set('locationinfos', models.locationinfos);
    Ember.run.once(function() { // Make dashboard calls async, when they come back, they come back no hurry
      controller.send('updateAccounts');
      controller.send('updateTransactions');
      controller.send('updateRecurrings');
      if (location.hasQuickInvoice) {
        controller.send('updateQuickinvoices');
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Dashboard',
      route_name: 'contactuser.dashboard'
    });
    controller.set('pageTitle', 'Dashboard');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var target = transition.targetName;
      if (target === 'contactuser.recurrings-view' || target === 'contactuser.recurrings-add' || target === 'contactuser.accounts-view' || target === 'contactuser.accounts-add') {
        var controller = this.controllerFor(target);
        controller.set('returnRoute', 'contactuser.dashboard');
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
