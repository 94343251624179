import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  beforeModel: function() {
    this._super(...arguments);
    var controller = this.controllerFor('dashboard-additionalgraphs');
    controller.set('transactions', null);
  },
  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        relationship: 'direct'
      })
    });
  },

  setupController: function(controller, model) {
    // Set Controller Properties
    controller.set('locationinfos', model.locationinfos);
    Ember.run.once(function() { // Make dashboard calls async, when they come back, they come back no hurry
      controller.send('updateTransactions');
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Dashboard - Additional Graphs',
      route_name: 'dashboard-additionalgraphs',
      route_id: null
    });
    controller.set('pageTitle', 'Dashboard - Additional Graphs');
    controller.set('pageCrumbs', [{
      title: 'Dashboard - Additional Graphs',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
