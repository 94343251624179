import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      userTypes: store.findAll('usertype')
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var userTypes = models.userTypes;
    if(userTypes && userTypes.get('length')) {
      var tempUserTypes = models.userTypes.filter(function(userType) {
        return userType.get('id') !== '0';
      });
      controller.set('userTypes', tempUserTypes);
    } else {
      controller.set('userTypes', []);
    }
    var pagerParams = {
      pageRoute: 'admin.helppages.index',
      pageModelId: null,
      modelName: 'helppage'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      relationship: 'all'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Help Pages',
      route_name: 'admin.helppages.index',
      route_id: null
    });
    controller.set('pageSubtitle', {
      title: 'Add Help Page',
      link: 'admin.helppages.add',
      record: null,
      resource: this.get('session.authenticated.resources.v2-helppages-post')
    });
    controller.set('pageTitle', 'Help Pages');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Help Pages',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
