import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  applicationData: Ember.inject.service('application-data'),
  trans_statuses: Ember.computed(function() {
    return this.get('applicationData.trans_statuses');
  }),
  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var t_statuses = self.get('trans_statuses');
      var t_status = t_statuses.filter(function(item) {
        return parseInt(item.id) === parseInt(query.id);
      });
      if (t_status.length > 0) {
        resolve({
          status: t_status
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findRecord: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var t_statuses = self.get('trans_statuses');
      var t_status = t_statuses.filter(function(item) {
        return String(item.id) === String(query.id);
      });
      if (t_status.length > 0) {
        resolve({
          status: t_status
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findAll: function() {
    return {
      status: this.get('trans_statuses')
    };
  }
});
