import Ember from 'ember';

export default Ember.Component.extend({

  applicationData: Ember.inject.service('application-data'),

  level3default: {},
  level3defaultitem: {},
  isError: false,

  didInsertElement: function() {
    this.setDefaults();
  },

  // Computed Properties

  level3_lineitem_debitcredit: Ember.computed(function() {
    return this.get('applicationData.level3_lineitem_debitcredit');
  }),

  // Observers

  errorsObserver: Ember.observer('defaultlevel3errors.[]', function() {
    var errors = this.get('defaultlevel3errors');
    var level3defaulterrors = {};
    var level3defaulterrormessages = [];
    if (errors && errors.length) {
      errors.map(function(err) {
        if (err.source.pointer === 'data/attributes/level3_default') {
          if (err.source.prop.indexOf('line_items') > -1) {
            level3defaulterrors[err.source.prop.replace('.', '_')] = err.detail;
            level3defaulterrormessages.push('Line Item ' + err.detail);
          } else {
            level3defaulterrors[err.source.prop] = err.detail;
            level3defaulterrormessages.push(err.detail);
          }
        }
      });
      if (level3defaulterrormessages.length) {
        noty({
          text: 'Level 3 Data Defaults have errors!',
          type: 'error'
        });
      }
      this.set('level3defaulterrors', level3defaulterrors);
      this.set('level3defaulterrormessages', level3defaulterrormessages);
    }

  }),

  ptl3dObserver: Ember.observer('level3default.{customer_vat_registration,destination_country_code,duty_amount,freight_amount,merchant_vat_registration,national_tax,order_date,sales_tax,shipfrom_zip_code,shipto_zip_code,summary_commodity_code,tax_amount,tax_exempt,tax_rate,unique_vat_ref_number}', 'level3defaultitem.{alternate_tax_id,commodity_code,debit_credit,description,discount_amount,discount_rate,other_tax_amount,product_code,quantity,tax_amount,tax_rate,tax_type_applied,tax_type_id,unit_code,unit_cost}', function() {
    var self = this;
    var level3default = this.get('level3default');
    var level3defaultitem = this.get('level3defaultitem');
    if (level3default && level3defaultitem) {
      Object.keys(level3default).forEach(function(key) {
        self.updateValue(key, 'main');
      });
      Object.keys(level3defaultitem).forEach(function(key) {
        self.updateValue(key, 'item');
      });
    }
  }),

  // Functions

  setDefaults: function() {
    var defaultValues = this.get('producttransaction.level3_default');
    if (typeof defaultValues === 'object') {
      defaultValues = JSON.parse(JSON.stringify(defaultValues));
    } else if (typeof defaultValues === 'string') {
      defaultValues = JSON.parse(defaultValues);
    }
    var defaultLineItems = null;
    this.set('level3default', {
      customer_vat_registration: defaultValues.customer_vat_registration,
      destination_country_code: defaultValues.destination_country_code,
      duty_amount: defaultValues.duty_amount,
      freight_amount: defaultValues.freight_amount,
      merchant_vat_registration: defaultValues.merchant_vat_registration,
      national_tax: defaultValues.national_tax,
      order_date: defaultValues.order_date,
      sales_tax: defaultValues.sales_tax,
      shipfrom_zip_code: defaultValues.shipfrom_zip_code,
      shipto_zip_code: defaultValues.shipto_zip_code,
      summary_commodity_code: defaultValues.summary_commodity_code,
      tax_amount: defaultValues.tax_amount,
      tax_exempt: defaultValues.tax_exempt,
      tax_rate: defaultValues.tax_rate,
      unique_vat_ref_number: defaultValues.unique_vat_ref_number,
    });
    if (defaultValues && defaultValues.line_items) {
      defaultLineItems = defaultValues.line_items[0];
      if(defaultLineItems) {
        this.set('level3defaultitem', {
          alternate_tax_id: defaultLineItems.alternate_tax_id,
          commodity_code: defaultLineItems.commodity_code,
          debit_credit: defaultLineItems.debit_credit,
          description: defaultLineItems.description,
          discount_amount: defaultLineItems.discount_amount,
          discount_rate: defaultLineItems.discount_rate,
          other_tax_amount: defaultLineItems.other_tax_amount,
          product_code: defaultLineItems.product_code,
          quantity: defaultLineItems.quantity,
          tax_amount: defaultLineItems.tax_amount,
          tax_rate: defaultLineItems.tax_rate,
          tax_type_applied: defaultLineItems.tax_type_applied,
          tax_type_id: defaultLineItems.tax_type_id,
          unit_code: defaultLineItems.unit_code,
          unit_cost: defaultLineItems.unit_cost
        });
      }
    }
  },

  updateValue: function(prop, loc) {
    var val = loc === 'main' ? this.get('level3default.' + prop) : this.get('level3defaultitem.' + prop);
    var defaultValues = this.get('producttransaction.level3_default');
    if (typeof defaultValues === 'object') {
      defaultValues = JSON.parse(JSON.stringify(defaultValues));
    } else if (typeof defaultValues === 'string') {
      defaultValues = JSON.parse(defaultValues);
    }
    var defaultLineItems = {};
    if (defaultValues && defaultValues.line_items) {
      defaultLineItems = defaultValues.line_items[0];
    }
    if (loc === 'main') {
      defaultValues[prop] = val;
    } else if (loc === 'item') {
      if(!defaultLineItems) {
        defaultLineItems = {};
      }
      defaultLineItems[prop] = val;
    }
    if (defaultValues && defaultValues.line_items) {
      defaultValues.line_items[0] = defaultLineItems;
    }
    this.set('producttransaction.level3_default', JSON.stringify(defaultValues));
  },

  // Actions

  actions: {
    resetToDefaults: function() {
      var self = this;
      pretty_confirm('Performing this action will reset all Level 3 data to their system default values. Are you sure you want to proceed?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        var defaultLevel3 = self.get('applicationData.level3_defaults');
        self.set('producttransaction.level3_default', defaultLevel3);
        self.setDefaults();
        self.set('isLoading', false);
      });
    }
  }

});
