import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsChangeCAUSubscriptions: true,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('locations.view', this.get('location.id'));
    },
    saveChangeCAU: function() {
      if (this.get('isLoading')) {
        return;
      }
      if (!this.get('cau_subscription_action')) {
        noty({
          text: 'Must select a Subscription Action!',
          type: 'error'
        });
        return;
      }

      this.set('isLoading', true);
      var self = this;
      const productTransactions = this.get('cau_producttransaction_options');
      const productTransactionsId = [];

      for (let i = 0; i < productTransactions.length; i++) {
        productTransactionsId.push(productTransactions[i].id);
      }

      var data = {
        accountvaultupdate: {
          action: this.get('cau_subscription_action'),
          product_transaction_id: productTransactionsId
        }
      };
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/accountvaultupdates',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': self.get('session.authenticated.token')
        },
        data: JSON.stringify(data)
      }).then(function() {
        self.set('isLoading', false);
        self.set('cau_subscription_action', null);
        self.set('clear_cau_product_transaction_ids', new moment().unix());
        noty({
          text: 'Credit Card Automatic Update changed successfully!',
          type: 'success'
        });
      }, function() {
        self.set('isLoading', false);
        noty({
          text: 'Credit Card Automatic Update change failed!',
          type: 'error'
        });
      });
    }
  }
});
