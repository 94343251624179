import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      mailboxmessagesuser: store.queryRecord('mymailboxmessageuser', {
        id: params.id,
        expand: 'mailbox_message'
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var self = this;
    var mailboxmessagesuser = models.mailboxmessagesuser;
    if (mailboxmessagesuser.get('mailbox_message_status_id') === 2) { // if message is unread
      mailboxmessagesuser.set('mailbox_message_status_id', 1); // change it to read
      mailboxmessagesuser.save({
        expand: 'mailbox_message'
      }).then(function(result) {
        controller.set('mailboxmessagesuser', result);
        self.send('getUsersUnreadMessages');
      });
    } else {
      controller.set('mailboxmessagesuser', mailboxmessagesuser);
    }
    controller.set('returnRoute', transition.to.queryParams.returnRoute || 'index');

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Mailbox Message ● ' + mailboxmessagesuser.get('mailbox_message.subject'),
      route_name: 'mailboxmessagesuser.view',
      route_id: mailboxmessagesuser.get('id')
    });
    controller.set('pageTitle', 'View Mailbox Message');
    controller.set('pageCrumbs', [{
      title: 'Mailbox Messages',
      link: 'mailboxmessagesuser.index'
    }, {
      title: 'View Mailbox Message'
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
