import Ember from "ember";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the booleans for navigation classes
  isDomains: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: "15",
  sort: "url",
  queryParams: [
    // pagination params
    "page",
    "page_size",
    "sort",

    // filter params
    "allow_contact_login",
    "allow_contact_registration",
    "allow_contact_signup",
    "custom_theme",
    "title",
    "url",
  ],
  columns: Ember.computed("isMobileSize", function () {
    var self = this;
    var columns = {
      url: {
        name: "url",
        title: "URL",
        type: "string",
        sortable: true,
        action: function (domain) {
          self.send("view", domain);
        },
        actionId: "domain_url_view_link",
        filter: {
          type: "text",
        },
      },
      title: {
        name: "title",
        title: "Title",
        type: "string",
        sortable: true,
        filter: {
          type: "text",
        },
      },
      allow_contact_login: {
        name: "allow_contact_login",
        title: "Allow Login",
        type: "check",
        sortable: true,
        align: "text-center",
        filter: {
          type: "dropdown",
          items: "yesNoAny",
          multi: false,
        },
      },
      allow_contact_signup: {
        name: "allow_contact_signup",
        title: "Allow Signup",
        type: "check",
        sortable: true,
        align: "text-center",
        filter: {
          type: "dropdown",
          items: "yesNoAny",
          multi: false,
        },
      },
      allow_contact_registration: {
        name: "allow_contact_registration",
        title: "Allow Registration",
        type: "check",
        sortable: true,
        align: "text-center",
        filter: {
          type: "dropdown",
          items: "yesNoAny",
          multi: false,
        },
      },
      hide_blind_payment: {
        name: "hide_blind_payment",
        title: "Hide Customer Portal Blind Payments",
        type: "check",
        sortable: true,
        align: "text-center",
        filter: {
          type: "dropdown",
          items: "yesNoAny",
          multi: false,
        },
      },
      custom_theme: {
        name: "custom_theme",
        title: "Theme",
        type: "text",
        sortable: true,
        filter: {
          type: "dropdown",
          items: "domainThemes",
          multi: true,
        },
      },
      delete: {
        name: "id",
        title: "",
        action: function (domain) {
          self.send("delete", domain);
        },
        actionId: "domain_delete_link",
        type: "delete",
        isActionColumn: true,
      },
      view: {
        name: "id",
        title: "",
        action: function (domain) {
          self.send("view", domain);
        },
        actionId: "domain_view_link",
        type: "view",
        isActionColumn: true,
      },
    };

    var listOfCols = [
      "url",
      "title",
      "allow_contact_login",
      "allow_contact_signup",
      "allow_contact_registration",
      "custom_theme",
      "view",
      "delete",
    ];
    var listOfMobileCols = [
      "url",
      "title",
      "allow_contact_login",
      "allow_contact_signup",
      "allow_contact_registration",
      "custom_theme",
      "view",
      "delete",
    ];

    if (this.get("isMobileSize")) {
      var mobileCols = [];
      listOfMobileCols.forEach(function (col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function (col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get("session");
      if (!session.get("authenticated.resources.v2-domains-get")) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get("authenticated.resources.v2-domains-delete")) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function (domain) {
      var self = this;
      pretty_confirm(
        "Are you sure you want to delete this Domain?",
        function () {
          // Bail if we are already loading
          if (self.get("isLoading")) {
            return;
          }
          self.set("isLoading", true);
          domain.deleteRecord();
          domain.save().then(
            function () {
              noty({
                text: "Domain deleted successfully!",
              });
              self.send("gridNeedsRefresh", self);
              self.set("isLoading", false);
            },
            function (data) {
              var error = "";
              if (data.errors) {
                data.errors.forEach(function (err) {
                  error += "<br>" + err.detail + ".";
                });
              }
              domain.rollbackAttributes();
              noty({
                text: "Error deleting Domain!" + error,
                type: "error",
                timeout: 10000,
              });
              self.send("gridNeedsRefresh", self);
              self.set("isLoading", false);
            }
          );
        }
      );
    },
    view: function (domain) {
      this.transitionToRoute("admin.domains.view", domain.get("id"));
    },
  },
});
