import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    changelogs: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    location: {
      serialize: false,
      deserialize: 'records'
    },
    portfolio: {
      serialize: false,
      deserialize: 'records'
    },
  }
});
