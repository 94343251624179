import DS from "ember-data";
import Application from "./application";

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    item: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
