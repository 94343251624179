import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminTerminalApplications: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'description',
    'emv_capable',
    'nfc_capable',
    'print_capable',
    'sig_capture_capable',
    'standalone',
    'title',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(terminalapplication) {
          self.send('view', terminalapplication);
        },
        'actionId': 'terminalapplication_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      standalone: {
        'name': 'standalone',
        'title': 'Standalone',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
        'filter': {
          type: 'text'
        }
      },
      emv_capable: {
        'name': 'emv_capable',
        'title': 'EMV Capable',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
        'filter': {
          type: 'text'
        }
      },
      nfc_capable: {
        'name': 'nfc_capable',
        'title': 'NFC Capable',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
      },
      print_capable: {
        'name': 'print_capable',
        'title': 'Print Capable',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
      },
      sig_capture_capable: {
        'name': 'sig_capture_capable',
        'title': 'Signature Capture Capable',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(terminalapplication) {
          self.send('view', terminalapplication);
        },
        'actionId': 'terminalapplication_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'description', 'standalone', 'emv_capable', 'nfc_capable', 'print_capable', 'sig_capture_capable', 'view'];
    var listOfMobileCols = ['title', 'description', 'standalone', 'emv_capable', 'nfc_capable', 'print_capable', 'sig_capture_capable', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Actions
  actions: {
    view: function(terminalapplication) {
      this.transitionToRoute('admin.terminalapplications.view', terminalapplication.get('id'));
    },
  }
});
