import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  model: function() {
    return this.get('store').createRecord('contact', {});
  },

  setupController: function(controller, model) {
    // Set Controller Properties
    controller.set('contact', model);
    var domain = this.get('domainBranding.domain');
    if(domain.registration_fields.indexOf('email') === -1){
      domain.registration_fields.push('email');
    }
    controller.set('domain', domain);

    var pagerParams = {
      pageRoute: 'register',
      pageModelId: null,
      modelName: 'contact',
      showExport: true
    };
    controller.set('pagerParams', pagerParams);
    new Ember.RSVP.Promise(function() {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/locations?url=' + window.UiENV.APP.DOMAIN,
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'GET',
        contentType: 'application/json'
      }).then(function(locations) {
        controller.set('locations', locations.locations);
      }, function() {
        noty({
          text: 'Error getting locations!',
          type: 'error'
        });
      });
    });
  }
});
