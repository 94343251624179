import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  postbacklogController: Ember.inject.controller('components/modals/postbacklog'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsPostbackLogs: true,

  // Set controller params
  sort: 'name',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'http_verb',
    'id',
    'model',
    'model_id',
    'next_run_ts',
    'next_run_ts_from',
    'next_run_ts_to',
    'postback_config_id',
    'postback_status_id',
  ],

  // Controller actions
  actions: {}
});
