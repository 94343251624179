import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by form-accountvault component
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsAccounts: true,

  canAddAccountVault: Ember.computed('locationinfo.hasProductAccountVault', 'locationinfo.product_transactions.[]', function() {
    var products = this.get('locationinfo.product_transactions').filter(function(p) {
      return p.get('active');
    });
    return this.get('locationinfo.hasProductAccountVault') && products.get('length') > 0;
  }),

  // Actions
  actions: {}
});
