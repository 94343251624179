import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('locations', models.locations);

    var product_transactions = [];
    models.locations.forEach(function(loc) {
      var prods = loc.get('product_transactions');
      if (prods) {
        prods.forEach(function(product_transaction) {
          if (product_transaction.get('payment_method') === 'ach') {
            product_transactions.push({
              'id': product_transaction.get('id'),
              'title': product_transaction.get('title')
            });
          }
        });
      }
    });
    controller.set('product_transactions', product_transactions);
    if (!controller.get('product_transaction_id')) {
      controller.set('product_transaction_id', product_transactions[0].id);
    }

    var pagerParams = {
      pageRoute: 'reports.ach-transactionsettlement',
      pageModelId: null,
      modelName: 'transactionachsettlement'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location.id,location.name,product_transaction,transaction'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● ACH Transactions Settlements',
      route_name: 'reports.ach-transactionsettlement',
      route_id: null
    });
    controller.set('pageTitle', 'ACH Transactions Settlements');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'ACH Transactions Settlements',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
