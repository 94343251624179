import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    account_vault: {
      serialize: false,
      deserialize: 'records'
    },
    changelogs: {
      serialize: false,
      deserialize: 'records'
    },
    contact: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    hosted_payment_page: {
      serialize: false,
      deserialize: 'records'
    },
    location: {
      serialize: false,
      deserialize: 'records'
    },
    log_emails: {
      serialize: false,
      deserialize: 'records'
    },
    log_email_recipients: {
      serialize: false,
      deserialize: 'records'
    },
    postback_logs: {
      serialize: false,
      deserialize: 'records'
    },
    product_transaction: {
      serialize: false,
      deserialize: 'records'
    },
    quick_invoice: {
      serialize: false,
      deserialize: 'records'
    },
    reason_code: {
      serialize: false,
      deserialize: 'records'
    },
    recurring: {
      serialize: false,
      deserialize: 'records'
    },
    signature: {
      serialize: false,
      deserialize: 'records'
    },
    status: {
      serialize: false,
      deserialize: 'records'
    },
    surcharge: {
      embedded: 'always'
    },
    tags: {
      embedded: 'always'
    },
    terminal: {
      serialize: false,
      deserialize: 'records'
    },
    transaction_batch: {
      serialize: false,
      deserialize: 'records'
    },
    transaction_histories: {
      serialize: false,
      deserialize: 'records'
    },
    transaction_level3: {
      serialize: false,
      deserialize: 'records'
    },
    type: {
      serialize: false,
      deserialize: 'records'
    },
  }
});
