import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    created_user: {
      embedded: 'always'
    },
    visibility_group: {
      embedded: 'always'
    },
    product_file: {
      embedded: 'always'
    },
    file_category: {
      embedded: 'always'
    }
  }
});