import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      locationinfos: store.query('locationinfo', {
        page_size: '100',
        relationship: 'direct'
      }),
      note: store.createRecord('note', {
        resource_id: params.id,
        resource: 'Contact'
      })
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfo: self.get('store').queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
        }),
      });
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'contacts.notes',
      pageModelId: models.contact.get('id'),
      modelName: 'note'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,visibility_group',
      resource: 'Contact',
      resource_id: models.contact.get('id'),
    };
    controller.set('gridParams', gridParams);
    controller.set('search', transition.to.queryParams.note_search);
    controller.set('contact', models.contact);
    controller.set('locationinfos', models.locationinfos);
    controller.set('note', models.note);
    controller.set('locationinfo', models.locationinfo);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Notes',
      route_name: 'contacts.notes',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Notes',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
