import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobile: Ember.computed('applicationData.isMobile', function() {
    return this.get('applicationData.isMobile');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsBillingAccounts: true,

  sort: '-title',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'billing_product_transaction_id',
    'billing_descriptor',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'last_four',
    'location_id',
    'payment_method',
    'portfolio_id',
    'title',
  ],

  columns: Ember.computed('isMobile', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      created_ts: {
        'name': 'created_ts',
        'title': 'Created<br>Date',
        'type': 'date',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(locationbillingaccount) {
          self.send('view', locationbillingaccount);
        },
        'actionId': 'locationbillingaccount_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      billing_descriptor: {
        'name': 'billing_descriptor',
        'title': 'Billing<br>Descriptor',
        'type': 'string',
        'sortable': true,
      },
      portfolio_id: {
        'name': 'portfolio_id',
        'title': 'Portfolio',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'portfolios',
          multi: true,
          showAllSelect: true
        }
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder',
        'type': 'string',
        'sortable': true
      },
      payment_method: {
        'name': 'payment_method',
        'title': 'Payment<br>Method',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'paymentMethods',
          multi: true,
          showAllSelect: true
        }
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true
      },
      routing: {
        'name': 'routing',
        'title': 'Routing',
        'type': 'string',
        'sortable': true
      },
      billing_zip: {
        'name': 'billing_zip',
        'title': 'Billing<br>Zip',
        'type': 'string',
        'sortable': true
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last<br>Four',
        'type': 'string',
        'sortable': true
      },
      exp_date: {
        'name': 'exp_date',
        'title': 'Exp<br>Date',
        'type': 'string',
        'sortable': true
      },
      view: {
        'name': 'id',
        'title': '',
        'type': 'view',
        'action': function(locationbillingaccount) {
          self.send('view', locationbillingaccount);
        },
        'actionId': 'locationbillingaccount_view_link',
        'sortable': false,
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'type': 'delete',
        'action': function(locationbillingaccount) {
          self.send('delete', locationbillingaccount);
        },
        'actionId': 'locationbillingaccount_delete_link',
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'created_ts', 'billing_descriptor', 'portfolio_id', 'account_holder_name', 'payment_method', 'account_type', 'routing', 'billing_zip', 'last_four', 'exp_date', 'view', 'delete'];
    var listOfMobileCols = ['title', 'created_ts', 'billing_descriptor', 'portfolio_id', 'account_holder_name', 'payment_method', 'account_type', 'routing', 'billing_zip', 'last_four', 'exp_date', 'delete', 'view'];

    if (this.get('isMobile')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      if (!session.get('authenticated.resources.v2-locationbillingaccounts-delete')) {
        cols.removeObject(columns.delete);
      }
      return cols;
    }

  }),

  // Actions
  actions: {
    delete: function(billingaccount) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Billing Account?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        billingaccount.deleteRecord();
        billingaccount.save().then(function() {
          billingaccount.unloadRecord();
          noty({
            text: 'Billing Account deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(err) {
          billingaccount.rollbackAttributes();
          var errorText = '';
          if(err.errors){
            err.errors.forEach(function(err){
              errorText += '<br>' + err.detail + '.';
            });
          }
          noty({
            text: 'Error deleting Billing Account ' + errorText,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    view: function(billingaccount) {
      billingaccount.set('account_number', null);
      this.transitionToRoute('locations.locationbillingaccount-view', this.get('location.id'), billingaccount.get('id')).then(function(Route) {
        Route.controller.set('isEditing', false);
      });
    }
  }
});
