import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  page: 1,
  page_size: '15',
  location_created_ts: 'last month',
  location_created_user_id: null,
  parent_location_id: null,
  portfolio_id: null,
  product_transaction_created_user_id: null,
  isRefreshing: true,
  sort: '-location_created_ts',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'developer_company_name',
    'developer_company',
    'location_created_ts',
    'location_created_ts_from',
    'location_created_ts_to',
    'location_created_user_id',
    'parent_location_id',
    'portfolio_id',
    'portfolio_title',
    'product_transaction_created_ts',
    'product_transaction_created_ts_from',
    'product_transaction_created_ts_to',
    'product_transaction_created_user_id',
  ],
  columns: Ember.computed(function() {
    var self = this;
    var session = this.get('session');
    return [{
      'name': 'location_created_ts',
      'title': 'Location<br>Created Date',
      'type': 'shortdate',
      'sortable': true
    }, {
      'name': 'portfolio_title',
      'title': 'Portfolio<br>Title',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'developer_company',
      'title': 'Developer<br>Company',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'parent_location_name',
      'title': 'Parent<br>Location Name',
      'type': 'string',
      'sortable': true,
      'action': function(onboardingdetail) {
        self.send('viewLocationParent', onboardingdetail);
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
    }, {
      'name': 'location_name',
      'title': 'Location<br>Name',
      'type': 'string',
      'sortable': true,
      'action': function(onboardingdetail) {
        self.send('viewLocation', onboardingdetail);
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
    }, {
      'name': 'product_transaction_partner',
      'title': 'Deposit Account<br>Partner',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'location_date_created',
      'title': 'Location<br>Date Created',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'agent_code',
      'title': 'Agent Code',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'product_transaction_title',
      'title': 'Product Transaction Title',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'product_transaction_payment_method',
      'title': 'Deposit Account<br>Payment Method',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'product_transaction_processor',
      'title': 'Deposit Account<br>Processor',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'product_transaction_industry_type',
      'title': 'Deposit Account<br>Industry Type',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'a-cert-fee-merchant-cost',
      'title': 'Cert Fee<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'a-cert-fee-portfolio-cost',
      'title': 'Cert Fee<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'a-cert-fee-net-cost',
      'title': 'Cert Fee<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-access-fee-merchant-cost',
      'title': 'Access Fee<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-access-fee-portfolio-cost',
      'title': 'Access Fee<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-access-fee-net-cost',
      'title': 'Access Fee<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-ach-min-fee-merchant-cost',
      'title': 'ACH Min Fee<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-ach-min-fee-portfolio-cost',
      'title': 'ACH Min Fee<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'm-ach-min-fee-net-cost',
      'title': 'ACH Min Fee<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'o-cert-fee-first-month-merchant-cost',
      'title': 'Cert Fee First<br>Month Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'o-cert-fee-first-month-portfolio-cost',
      'title': 'Cert Fee First<br>Month Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'o-cert-fee-first-month-net-cost',
      'title': 'Cert Fee First<br>Month Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-reject-fee-merchant-cost',
      'title': 'ACH Reject Fee<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-reject-fee-portfolio-cost',
      'title': 'ACH Reject Fee<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-reject-fee-net-cost',
      'title': 'ACH Reject Fee<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-ret-against-settle-acct-merchant-cost',
      'title': 'ACH Ret Against Settle<br>Account Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-ret-against-settle-acct-portfolio-cost',
      'title': 'ACH Ret Against Settle<br>Account Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-ret-against-settle-acct-net-cost',
      'title': 'ACH Ret Against Settle<br>Account Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-unauthorized-fee-merchant-cost',
      'title': 'ACH Unauthorized<br>Fee Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-unauthorized-fee-portfolio-cost',
      'title': 'ACH Unauthorized<br>Fee Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-ach-unauthorized-fee-net-cost',
      'title': 'ACH Unauthorized<br>Fee Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-failure-to-notify-dda-change-merchant-cost',
      'title': 'Failure To Notify<br>DBA Change Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-failure-to-notify-dda-change-portfolio-cost',
      'title': 'Failure To Notify<br>DBA Change Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-failure-to-notify-dda-change-net-cost',
      'title': 'Failure To Notify<br>DBA Change Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-fee-cc-merchant-cost',
      'title': 'Transaction Fee CC<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-fee-cc-portfolio-cost',
      'title': 'Transaction Fee CC<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-fee-cc-net-cost',
      'title': 'Transaction Fee CC<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-ach-merchant-cost',
      'title': 'Transaction Rate ACH<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-ach-portfolio-cost',
      'title': 'Transaction Rate ACH<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-ach-net-cost',
      'title': 'Transaction Rate ACH<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-cc-merchant-cost',
      'title': 'Transaction Rate CC<br>Merchant Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-cc-portfolio-cost',
      'title': 'Transaction Rate CC<br>Portfolio Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'p-trx-rate-cc-net-cost',
      'title': 'Transaction Rate CC<br>Net Cost',
      'type': 'string',
      'sortable': true
    }, {
      'name': 'location_id',
      'title': 'Location Id',
      'type': 'string',
      'sortable': true,
      'action': function(onboardingdetail) {
        self.send('viewLocation', onboardingdetail);
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
    }, {
      'name': 'parent_location_id',
      'title': 'Parent<br>Location Id',
      'type': 'string',
      'sortable': true,
      'action': function(onboardingdetail) {
        self.send('viewLocationParent', onboardingdetail);
      },
      'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
    }];
  }),
  actions: {
    updateReport: function(data) {
      // Have to check for chrome/ember bug and exit if we get a string and not an object
      if ((typeof data) !== 'object') {
        return;
      }
      var self = this;
      var props = [];
      self.beginPropertyChanges();
      data.filters.forEach(function(item) {
        props[item.name.replace('.', '-')] = item.value;
      });
      self.setProperties(props);
      self.endPropertyChanges();
    },
    updateParentLocation: function(data) {
      var self = this;
      var parents = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      parents.then(function(result) {
        self.set('parents', result);
      });
    },
    updateLocationUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('locationusers', result);
      });
    },
    updateProductTransactionUsers: function(data) {
      var self = this;
      var users = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      users.then(function(result) {
        self.set('producttransactionusers', result);
      });
    },
    updatePortfolio: function(data) {
      var self = this;
      var portfolios = this.get('store').query('portfolio', {
        title: data,
        sort: 'title',
        page_size: '10',
        relationship: 'all'
        // user_type_id: '200,250,300,400,500,600,700,800,900,1000'
      });
      portfolios.then(function(result) {
        self.set('portfoliolist', result);
      });
    },
    viewLocation: function(onboardingdetail) {
      this.transitionToRoute('locations.view', onboardingdetail.get('location_id'));
    },
    viewLocationParent: function(onboardingdetail) {
      this.transitionToRoute('locations.view', onboardingdetail.get('parent_location_id'));
    }
  }
});
