import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  formBuilderEditFieldController: Ember.inject.controller('components/modals/formbuildereditfield'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the model for validation errors
  hppErrorMessages: null,
  updateErrorMessages: Ember.observer('hostedpaymentpage.errors.messages', function() {
    this.set('hppErrorMessages', this.get('hostedpaymentpage.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsHPPSettings: true,

  transitiontoIndex: function() {
    this.transitionToRoute('locations.hpp-index', this.get('location.id'));
  },

  // Actions
  actions: {
    cancel: function() {
      this.transitiontoIndex();
    },
    pickTemplate: function(template) {
      this.set('noTempateSelected', false);
      var field_configuration = this.get(template);
      var hostedpaymentpage = this.get('hostedpaymentpage');
      hostedpaymentpage.set('field_configuration', field_configuration);
    },
  }
});
