import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  body: DS.attr('string'),
  title: DS.attr('string'),
  user_type_id: DS.attr('string'),

  // Defined model relationships
  user_type: DS.belongsTo('usertype', {
    async: true
  }),
});
