import Ember from 'ember';
import LoginControllerMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import jQuery from 'jquery';

export default Ember.Controller.extend(LoginControllerMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  authenticator: 'authenticator:custom',
  showLogin: false,
  pass_reqs: {
    one_letter: false,
    one_number: false,
    one_special: false,
    eight_chars: false,
    pass_match: false
  },
  allow_submit: Ember.computed('newpassword', 'confirmpassword', 'terms_condition_acceptance', function() {
    var password = this.get('newpassword');
    var confirmpassword = this.get('confirmpassword');
    if (!password || !confirmpassword) {
      return false;
    }

    var requirements = this.get('pass_reqs');
    if (!requirements.one_letter || !requirements.one_number || !requirements.one_special || !requirements.eight_chars || !requirements.pass_match) {
      return false;
    }

    var termscondition = this.get('termscondition');
    this.set('terms_condition_id', null);
    if (termscondition) {
      var terms_condition_acceptance = this.get('terms_condition_acceptance');
      this.set('terms_condition_id', termscondition.id);
      if (!terms_condition_acceptance) {
        return false;
      }
    }
    return true;
  }),
  passwordChanged: Ember.observer('newpassword', 'confirmpassword', function() {
    var self = this;
    var password = this.get('newpassword');
    var confirmpassword = this.get('confirmpassword');
    if (!password) {
      self.set('pass_reqs.one_letter', false);
      self.set('pass_reqs.one_number', false);
      self.set('pass_reqs.one_special', false);
      self.set('pass_reqs.eight_chars', false);
      self.set('pass_reqs.pass_match', false);
    } else {
      var one_letter = false;
      var one_number = false;
      var one_special = false;
      var eight_chars = false;
      var pass_match = false;

      if (password.match(/[0-9]/)) {
        one_number = true;
      }
      if (password.match(/[a-z]/i)) {
        one_letter = true;
      }
      if (password.match(/\W/)) {
        one_special = true;
      }
      if (password.length >= 12) {
        eight_chars = true;
      }
      if (password === confirmpassword && password.length > 0) {
        pass_match = true;
      }

      self.set('pass_reqs.one_letter', one_letter);
      self.set('pass_reqs.one_number', one_number);
      self.set('pass_reqs.one_special', one_special);
      self.set('pass_reqs.eight_chars', eight_chars);
      self.set('pass_reqs.pass_match', pass_match);
    }
  }),
  betaUrl: Ember.computed(function() {
    var location = window.location.hostname;
    if (location.indexOf('.beta') > -1) {
      return 'https://' + location.replace('.beta', '');
    }
    return null;
  }),
  changePassword: false,
  loadMessage: 'Authenticating...',
  reloadMessage: null,
  domainLookupMessage: 'Please enter your credentials and we will attempt to lookup your login location.',
  isDomainLookup: Ember.computed(function() {
    var hostname = window.location.hostname;
    var host = hostname.split('.');
    return (host[0] === 'login');
  }),

  // Functions

  authenticateUser: function(credentials, context){
    context.set('showLogin', false);
    context.get('session').authenticate('authenticator:custom', credentials).then(function() {
      context.set('loadMessage', 'Loading Settings...');
      context.set('reloadMessage', '<div>&nbsp;</div><div>If your session is stuck "loading..." for more than 10 seconds,<br><a href="javascript:window.localStorage.clear(); var loc = window.location.protocol+\'//\'+window.location.hostname+\':\'+window.location.port; window.location.href = loc;">Click here</a> to clear your session and start over.</div>');
      context.resetPage();
    }, function(error) {
      if(error == true) {
        context.set('isMfaCode', true);
      } else {
        console.log('Session Authentication Failed');
        if (error.status === 403) {
          context.set('changePassword', true);
          noty({
            text: 'Your password has expired! You must update it to proceed.',
            layout: 'topCenter',
            type: 'warning'
          });
        } else if (error.status === 406) {
          noty({
            text: '<div class="appRefresh hand">This application has been updated. Please click here to reload.</div>',
            layout: 'topCenter',
            type: 'warning',
            timeout: '0',
            closeWith: [],
            modal: true,
            killer: true
          });
        } else if (error.status === 422) {
          var display_errors = '';
          if (error.responseText != null) {
            var errors = JSON.parse(error.responseText);
            for (var e in errors) {
              if (errors.hasOwnProperty(e)) {
                display_errors += errors[e].join('<br>');
              }
            }
            noty({
              text: display_errors,
              layout: 'topCenter',
              type: 'warning'
            });
          } else {
            noty({
              text: 'Unknown error occurred while updating password!',
              layout: 'topCenter',
              type: 'warning'
            });
          }
        } else if (error.status === 423) {
          noty({
            text: 'Your account is now locked (too many login attempts). You must reset your password to unlock it.',
            layout: 'top',
            type: 'error',
            timeout: 16000
          });
          context.transitionToRoute('forgotpassword');
          return;
        } else if (error.status === 411) {
          noty({
            text: 'Invalid MFA code entered, please try again with the correct code.',
            layout: 'top',
            type: 'error',
            timeout: 5000
          });
        } else {
          noty({
            text: 'Invalid username or password!',
            layout: 'topCenter',
            type: 'error',
            timeout: 5000
          });
        }
      }
      context.set('showLogin', true);
      context.resetPage();
    });
  },

  resetPage: function() {
    //this.set('showLogin', true);
    this.set('password', null);
    this.set('newpassword', null);
    this.set('confirmpassword', null);
  },

  // Actions
  actions: {
    changeBeta: function() {
      this.set('betaUrl', false);
    },
    goToMain: function() {
      var betaUrl = this.get('betaUrl');
      window.location.replace(betaUrl);
    },
    authenticate: function() {
      if(this.get('changePassword') && !this.get('allow_submit')) {
        return;
      }
      var self = this;
      let credentials;
      if(this.get('isMfaCode') == true) {
        credentials = {
          username: this.get('username'),
          terms_condition_id: this.get('terms_condition_id'),
          mfacode: this.get('mfacode')
        };
      } else {
        credentials = {
          username: this.get('username'),
          password: this.get('password'),
          terms_condition_id: this.get('terms_condition_id')
        };
      }
      this.set('showLogin', false);

      if (this.get('isDomainLookup')) {
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/public/domainlookup?username=' + credentials.username + '&password=' + credentials.password,
          headers: {
            'developer-id': window.UiENV.APP.DEVELOPER_ID
          },
          type: 'GET'
        }).then(function(result) {
          if (result) {
            self.set('domainLookupMessage', 'Your login location is <a href="https://' + result + '">' + result + '</a>');
            self.set('showLogin', true);
            self.resetPage();
          } else {
            self.set('domainLookupMessage', 'We were unable to find your login location. Please contact support for assistance.');
            self.set('showLogin', true);
            self.resetPage();
          }
        }, function() {
          self.set('domainLookupMessage', 'We were unable to find your login location. Please contact support for assistance.');
          self.set('showLogin', true);
          self.resetPage();
        });
      } else {
        if (this.get('changePassword')) {
          if (this.get('newpassword') !== this.get('confirmpassword')) {
            noty({
              text: 'New Password and Confirm Password do not match!',
              layout: 'topCenter',
              type: 'error'
            });
            this.set('showLogin', true);
            return;
          }
          credentials['new_password'] = this.get('newpassword');
        }
        self.authenticateUser(credentials, self);
      }
    }
  }
});
