import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  getForModel: function(model) {
    return this.get(model);
  },

  helppage: Ember.computed(function() {
    return [{
      'prop': 'title',
      'label': 'Title',
      'type': 'textfield',
      'required': true,
      'editOnEdit': true,
      'editOnAdd': true,
    },
    {
      'prop': 'user_type_id',
      'label': 'User Type',
      'type': 'selectfield',
      'required': true,
      'editOnEdit': false,
      'editOnAdd': true,
      'select': {
        'content': 'userTypes',
        'optionValuePath': 'id',
        'optionLabelPath': 'title',
        'prompt': 'Select User Type',
      }
    },
    {
      'prop': 'body',
      'label': 'Body',
      'type': 'editorfield',
      'required': true,
      'editOnEdit': true,
      'editOnAdd': true,
      'height': '600px'
    }];
  }),

  terminalcvm: Ember.computed(function() {
    return [{
      'prop': 'title',
      'label': 'Title',
      'type': 'textfield',
      'required': true,
      'editOnEdit': true,
      'editOnAdd': true
    }, {
      'prop': 'terminal_manufacturer_id',
      'label': 'Terminal Manufacturer Id',
      'type': 'selectfield',
      'required': true,
      'editOnEdit': true,
      'editOnAdd': true,
      'select': {
        'content': 'terminalmanufacturers',
        'optionValuePath': 'id',
        'optionLabelPath': 'title',
        'prompt': 'Select Terminal Manufacturer',
      }
    }, {
      'prop': 'contact_data',
      'label': 'Contact Data',
      'type': 'textarea',
      'rows': 6,
      'required': false,
      'editOnEdit': true,
      'editOnAdd': true,
      'viewAsCode': true,
    }, {
      'prop': 'contactless_data',
      'label': 'Contactless Data',
      'type': 'textarea',
      'rows': 6,
      'required': false,
      'editOnEdit': true,
      'editOnAdd': true,
      'viewAsCode': true,
    }];
  }),
  
  terminalmanufacturer: Ember.computed(function() {
    return [{
      'prop': 'title',
      'label': 'Title'
    }, {
      'prop': 'idtype',
      'label': 'Id Type'
    }];
  }),


});
