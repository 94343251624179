import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'last_name',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'account_number',
    'address',
    'cell_phone',
    'city',
    'company_name',
    'contact_api_id',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'email',
    'first_name',
    'home_phone',
    'last_name',
    'office_phone',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      first_name: {
        'name': 'first_name',
        'title': 'First Name',
        'type': 'string',
        'sortable': true,
        'action': function(contact) {
          self.send('view', contact);
        },
        'actionId': 'contact_first_name_view_link',
        'filter': {
          type: 'text'
        }
      },
      last_name: {
        'name': 'last_name',
        'title': 'Last Name',
        'type': 'string',
        'sortable': true,
        'action': function(contact) {
          self.send('view', contact);
        },
        'actionId': 'contact_last_name_view_link',
        'filter': {
          type: 'text'
        }
      },
      account_number: {
        'name': 'account_number',
        'title': 'Account Number',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      contact_api_id: {
        'name': 'contact_api_id',
        'title': 'Contact API Id',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      email: {
        'name': 'email',
        'title': 'Email',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      company_name: {
        'name': 'company_name',
        'title': 'Company',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      address: {
        'name': 'address',
        'title': 'Address',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      city: {
        'name': 'city',
        'title': 'City',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      home_phone: {
        'name': 'home_phone',
        'title': 'Home Phone',
        'type': 'phone',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      cell_phone: {
        'name': 'cell_phone',
        'title': 'Cell Phone',
        'type': 'phone',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      office_phone: {
        'name': 'office_phone',
        'title': 'Office Phone',
        'type': 'phone',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(contact) {
          self.transitionToRoute('locations.view', contact.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(contact) {
          self.send('view', contact);
        },
        'actionId': 'contact_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['first_name', 'last_name', 'account_number', 'contact_api_id', 'email', 'company_name', 'address', 'city', 'home_phone', 'cell_phone', 'office_phone', 'location_id', 'created_ts', 'view'];
    var listOfMobileCols = ['first_name', 'last_name', 'account_number', 'email', 'view'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      if(this.get('locations.length') <= 1){
        cols.removeObject(columns.location_id);
      }
      return cols;
    }
  }),

  // Actions
  actions: {
    view: function(contact) {
      this.transitionToRoute('contacts.dashboard', contact.get('id'));
    }
  }

});
