import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('transactionlevel3s.errors.messages', function() {
    this.set('errorMessages', this.get('transactionlevel3s.errors.messages'));
  }),

  // Properties

  isEditing: false,
  isLoading: false,
  showActiveItemForm: false,
  level3_lineitem_debitcredit: Ember.computed(function() {
    return this.get('applicationData.level3_lineitem_debitcredit');
  }),

  // Computed Properties

  activeItemDebitCredit: Ember.computed('activeItem.debit_credit', function() {
    var debit_credit = this.get('activeItem.debit_credit');
    if (debit_credit === 'D') {
      return 'Debit';
    } else if (debit_credit === 'C') {
      return 'Credit';
    } else {
      return 'Unknown';
    }
  }),
  canPost: Ember.computed('session.authenticated.resources.v2-transactionlevel3s-post', function() {
    return this.get('session.authenticated.resources.v2-transactionlevel3s-post');
  }),
  cardImageSrc: Ember.computed('transaction.account_type', function() {
    return '/images/' + this.get('transaction.account_type') + '.png';
  }),
  isVisaOrMC: Ember.computed('isVISA','isMC', function() {
    var isVISA = this.get('isVISA');
    var isMC = this.get('isMC');
    if(isVISA || isMC) {
      return true;
    } else {
      return false;
    }
  }),
  colspan: Ember.computed('canPost', 'isVISA', 'isMC', function() {
    var canPost = this.get('canPost');
    var isVISA = this.get('isVISA');
    var isMC = this.get('isMC');
    if (canPost && isVISA) {
      return 12;
    } else if (canPost && isMC) {
      return 15;
    } else if (!canPost && isVISA) {
      return 10;
    } else if (!canPost && isMC) {
      return 13;
    }
  }),
  colspanAddRow: Ember.computed('colspan', function() {
    return this.get('colspan') - 1;
  }),
  defaultActiveItem: Ember.computed('product.level3_default', function() {
    var defaultItems = this.get('product.level3_default.line_items');
    var defaultItem;
    if (defaultItems) {
      defaultItem = defaultItems.get('firstObject');
    }
    return defaultItem ? defaultItem : {};
  }),
  headers: Ember.computed('isVISA', 'isMC', 'isEditing', 'session.authenticated.resources.v2-transactionlevel3-post', function() {
    var isVISA = this.get('isVISA');
    var isMC = this.get('isMC');
    var isEditing = this.get('isEditing');
    var canPost = this.get('session.authenticated.resources.v2-transactionlevel3-post');
    var headers = ['Description', 'Product<br>Code', 'Unit<br>Cost', 'Tax<br>Amount', 'Tax<br>Rate', 'Discount<br>Amount', 'Quantity', 'Unit<br>Code'];
    if (isVISA) {
      headers.push('Commodity<br>Code');
      headers.push('Other Tax<br>Amount');
    }
    if (isMC) {
      headers.push('Tax Type<br>Applied');
      headers.push('Debit/Credit');
      headers.push('Alternate<br>Tax Id');
      headers.push('Discount<br>Rate');
      headers.push('Tax<br>Type Id');
    }
    if (isEditing && canPost) {
      headers.push(' ');
      headers.push(' ');
    }
    return headers;
  }),
  isAddingLineItem: Ember.computed('activeItem.tempId', function() {
    return this.get('activeItem.tempId') === -1;
  }),
  isMC: Ember.computed('transaction.account_type', function() {
    return this.get('transaction.account_type') === 'mc';
  }),
  isVISA: Ember.computed('transaction.account_type', function() {
    return this.get('transaction.account_type') === 'visa';
  }),
  notAddingOrEditing: Ember.computed('isAddingLineItem', 'isEditing', function() {
    return !this.get('isAddingLineItem') && this.get('isEditing');
  }),

  // Functions

  errorCheck: function(fields, requireds, obj, errorObj, isModel) {
    var self = this;
    var errors = [];
    fields.forEach(function(field) {
      if (requireds.indexOf(field) >= 0) {
        var value = isModel ? obj.get('level3_data.' + field) : obj[field];
        if (!value && value !== 0) {
          var fieldNameParts = field.split('_');
          var fieldNameArray = fieldNameParts.map(function(part) {
            return part.capitalize();
          });
          var fieldName = fieldNameArray.join(' ');
          errors.push(fieldName + ' cannot be blank.');
          self.set(errorObj + '.' + field, 'Cannot be blank');
        }
      }
    });
    return errors;
  },

  errorCheckL3Data: function(l3) {
    this.set('transactionlevel3.errorsMessages', []);
    if (this.get('transactionlevel3.level3_data.line_items').get('length') === 0) {
      var nolineitemerrors = ['Level 3 Data must contain at least one line item.'];
      this.set('transactionlevel3.errorsMessages', nolineitemerrors);
      return nolineitemerrors;
    }
    this.set('transactionlevel3.errors.level3_data', {});
    var fieldsArray = ['destination_country_code', 'duty_amount', 'freight_amount', 'national_tax', 'sales_tax', 'shipfrom_zip_code', 'shipto_zip_code', 'tax_amount', 'tax_exempt'];
    if (this.get('isVISA')) {
      fieldsArray = fieldsArray.concat(['customer_vat_registration', 'merchant_vat_registration', 'order_date', 'summary_commodity_code', 'tax_rate', 'unique_vat_ref_number']);
    }
    var requiredL3Fields = ['customer_vat_registration', 'destination_country_code', 'duty_amount', 'freight_amount', 'merchant_vat_registration', 'national_tax', 'order_date', 'sales_tax', 'shipfrom_zip_code', 'shipto_zip_code', 'summary_commodity_code', 'tax_amount', 'tax_exempt', 'tax_rate', 'unique_vat_ref_number'];
    var errors = this.errorCheck(fieldsArray, requiredL3Fields, l3, 'transactionlevel3.errors.level3_data', true);
    this.set('transactionlevel3.errorsMessages', errors);
    return errors;
  },

  errorCheckLineItem: function(item) {
    this.set('lineItemErrors', []);
    this.set('activeItem.errors', {});
    var fieldsArray = ['description', 'discount_amount', 'product_code', 'quantity', 'tax_amount', 'tax_rate', 'unit_code', 'unit_cost'];
    if (this.get('isVISA')) {
      fieldsArray = fieldsArray.concat(['commodity_code', 'other_tax_amount']);
    }
    if (this.get('isMC')) {
      fieldsArray = fieldsArray.concat(['alternate_tax_id', 'debit_credit', 'discount_rate', 'tax_type_applied', 'tax_type_id']);
    }
    var requiredLineItemFields = ['alternate_tax_id', 'commodity_code', 'debit_credit', 'description', 'discount_rate', 'other_tax_amount', 'product_code', 'quantity', 'tax_amount', 'tax_type_id', 'unit_code', 'unit_cost'];
    var errors = this.errorCheck(fieldsArray, requiredLineItemFields, item, 'activeItem.errors');
    this.set('lineItemErrors', errors);
    return errors;
  },

  getL3FieldName: function(fieldId) {
    switch (fieldId) {
      case 'tax_amount':
        return 'Tax Amount';
      case 'freight_amount':
        return 'Freight Amount';
      case 'duty_amount':
        return 'Duty Amount';
      case 'national_tax':
        return 'National Tax';
      case 'sales_tax':
        return 'Sales Tax';
      case 'tax_exempt':
        return 'Tax Exempt';
      case 'shipfrom_zip_code':
        return 'Ship From Zip Code';
      case 'shipto_zip_code':
        return 'Ship To Zip Code';
      case 'destination_country_code':
        return 'Destination Country Code';
      case 'merchant_vat_registration':
        return 'Merchant VAT Registration';
      case 'customer_vat_registration':
        return 'Customer VAT Registration';
      case 'unique_vat_ref_number':
        return 'Unique VAT Reference Number';
      case 'summary_commodity_code':
        return 'Summary Commodity Code';
      case 'order_date':
        return 'Order Date';
      case 'tax_rate':
        return 'Tax Rate';
    }
  },
  getLineItemFieldName: function(fieldId) {
    switch (fieldId) {
      case 'description':
        return 'Description';
      case 'quantity':
        return 'Quantity';
      case 'unit_cost':
        return 'Unit Cost';
      case 'unit_code':
        return 'Unit Code';
      case 'product_code':
        return 'Product Code';
      case 'commodity_code':
        return 'Commodity Code';
      case 'debit_credit':
        return 'Debit/Credit';
      case 'discount_amount':
        return 'Discount Amount';
      case 'discount_rate':
        return 'Discount Rate';
      case 'tax_amount':
        return 'Tax Amount';
      case 'tax_rate':
        return 'Tax Rate';
      case 'other_tax_amount':
        return 'Other Tax Amount';
      case 'tax_type_applied':
        return 'Tax Type Applied';
      case 'tax_type_id':
        return 'Tax Type Id';
      case 'alternate_tax_id':
        return 'Alternate Tax Id';
    }
  },

  setActiveItem: function(item, index) {
    var defaultItem = this.get('defaultActiveItem');
    var i = index || index === 0 ? index : -1;
    this.set('activeItem', {
      description: item.description || defaultItem.description || null,
      discount_amount: item.discount_amount || defaultItem.discount_amount || null,
      product_code: item.product_code || defaultItem.product_code || null,
      quantity: item.quantity || defaultItem.quantity || null,
      tax_amount: item.tax_amount || defaultItem.tax_amount || null,
      tax_rate: item.tax_rate || defaultItem.tax_rate || null,
      unit_code: item.unit_code || defaultItem.unit_code || null,
      unit_cost: item.unit_cost || defaultItem.unit_cost || null,
      tempId: i,
      errors: []
    });
    if (this.get('isVISA')) {
      this.set('activeItem.commodity_code', item.commodity_code || defaultItem.commodity_code || null);
      this.set('activeItem.other_tax_amount', item.other_tax_amount || defaultItem.other_tax_amount || null);
    }
    if (this.get('isMC')) {
      this.set('activeItem.alternate_tax_id', item.alternate_tax_id || defaultItem.alternate_tax_id || null);
      this.set('activeItem.debit_credit', item.debit_credit || defaultItem.debit_credit || null);
      this.set('activeItem.discount_rate', item.discount_rate || defaultItem.discount_rate || null);
      this.set('activeItem.tax_type_applied', item.tax_type_applied || defaultItem.tax_type_applied || null);
      this.set('activeItem.tax_type_id', item.tax_type_id || defaultItem.tax_type_id || null);
    }
    this.set('lineItemErrors', []);
  },
  transitionToTransactionDetailFull: function() {
    this.transitionToRoute('transactiondetailfull', this.get('transaction.id'));
  },

  // Actions
  actions: {
    actOnItem: function(action, item, index) {
      var line_items = this.get('transactionlevel3.level3_data.line_items');
      var errors = [];
      var self = this;
      switch (action) {
        case 'add':
          errors = this.errorCheckLineItem(item);
          if (errors.length > 0) {
            return;
          } else if (errors.length === 0) {
            delete item.erorrs;
          }
          delete item.tempId;
          line_items.pushObject(item);
          this.set('transactionlevel3.level3_data.line_items', line_items);
          this.setActiveItem({}, -2);
          this.set('showActiveItemForm', false);
          break;
        case 'cancel':
          delete item.tempId;
          this.setActiveItem({}, -2);
          this.set('showActiveItemForm', false);
          break;
        case 'delete':
          pretty_confirm('Are you sure want to delete this line item?', function() {
            line_items = line_items.removeAt(index);
            self.set('transactionlevel3.level3_data.line_items', line_items);
          });
          break;
        case 'edit':
          this.setActiveItem(item, index);
          this.set('showActiveItemForm', true);
          Ember.run.later(function() {
            jQuery('#line_item_description').focus();
          });
          break;
        case 'new':
          this.setActiveItem(this.get('defaultActiveItem'));
          this.set('showActiveItemForm', true);
          Ember.run.later(function() {
            jQuery('#line_item_description').focus();
          });
          break;
        case 'update':
          errors = this.errorCheckLineItem(item);
          if (errors.length > 0) {
            return;
          } else if (errors.length === 0) {
            delete item.erorrs;
          }
          var i = item.tempId;
          delete item.hasError;
          delete item.tempId;
          line_items.replace(i, 1, [item]);
          this.set('transactionlevel3.line_items', line_items);
          this.setActiveItem({}, -2);
          this.set('showActiveItemForm', false);
          break;
        case 'view':
          this.setActiveItem(item, index);
          this.set('showActiveItemForm', true);
          break;
        default:
          return;
      }
    },
    back: function() {
      this.transitionToTransactionDetailFull();
    },
    cancel: function() {
      var transactionlevel3 = this.get('transactionlevel3');
      if (transactionlevel3.get('isNew')) {
        transactionlevel3.deleteRecord();
        this.transitionToTransactionDetailFull();
        this.set('isEditing', false);
      } else {
        transactionlevel3.rollbackAttributes();
        this.send('refreshData');
        this.set('isEditing', false);
      }
    },
    delete: function() {
      var transactionlevel3 = this.get('transactionlevel3');
      var self = this;
      pretty_confirm('Are you sure you want to delete Level 3 Data?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        transactionlevel3.deleteRecord();
        transactionlevel3.save().then(function() {
          noty({
            text: 'L3 Data deleted successfully!'
          });
          self.set('isLoading', false);
          self.set('isEditing', false);
          self.transitionToTransactionDetailFull();
        }, function() {
          transactionlevel3.rollbackAttributes();
          noty({
            text: 'Error deleting L3 Data!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      var transactionlevel3 = {
        'transactionlevel3': this.get('transactionlevel3')
      };
      this.set('isLoading', true);
      var errors = this.errorCheckL3Data(transactionlevel3.transactionlevel3);
      if (errors && errors.length > 0) {
        this.set('isLoading', false);
        return;
      } else {
        var items = this.get('transactionlevel3.level3_data.line_items');
        items.forEach(function(item) {
          delete item.errors;
          delete item.hasError;
        });
      }
      var self = this;
      var session = self.get('session');
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/transactionlevel3s',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        },
        data: JSON.stringify(transactionlevel3)
      }).then(function() {
        noty({
          text: 'L3 Data saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.set('transactionlevel3.errorsMessages', []);
        self.set('lineItemErrors', []);
      }, function(errs) {
        var errors = errs.responseJSON.errors;
        var hasL3Error = {};
        var L3Errors = [];
        var lineItemErrors = [];
        var lineitems = self.get('transactionlevel3.level3_data.line_items');
        Object.keys(errors).forEach(function(m) {
          if (typeof errors === 'string') {
            if (m.indexOf('line_items') !== -1) {
              lineItemErrors.push(errors[m]);
            } else {
              L3Errors.push(errors[m]);
            }
          } else {
            Object.keys(errors[m]).forEach(function(n) {
              if (m.indexOf('line_items') !== -1) {
                let lineitemNumber = m.split('.')[1];
                let lineitemField = self.getLineItemFieldName(m.split('.')[2]);
                lineItemErrors.push('Line Item ' + (Number(lineitemNumber) + 1) + '. Field: ' + lineitemField + ': ' + errors[m][n]);
                lineitems.map(function(lineitem, index) {
                  if (index === Number(lineitemNumber)) {
                    if (!lineitem.errors) {
                      Ember.set(lineitem, 'errors', {});
                    }
                    Ember.set(lineitem, 'hasError', true);
                    Ember.set(lineitem, 'errors.' + m.split('.')[2], true);
                  }
                });
              } else {
                hasL3Error[m] = true;
                L3Errors.push(self.getL3FieldName(m) + ': ' + errors[m][n]);
              }
            });
          }
        });
        self.set('transactionlevel3.errorsMessages', L3Errors);
        self.set('lineItemErrors', lineItemErrors);
        self.set('hasL3Error', hasL3Error);
        noty({
          text: 'Error saving L3 Data!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    }
  }
});
