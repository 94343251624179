import Application from './application';

export default Application.extend({
  modelNameFromPayloadKey: function(payloadKey) {
    return this._super('admin-' + payloadKey);
  },
  serializeBelongsTo: function(snapshot, json, relationship) {
    var key = relationship.key;
    var belongsTo = snapshot.belongsTo(key);
    key = this.keyForRelationship ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;
    json[key] = belongsTo.record.id;
  }
});