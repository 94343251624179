import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {},

  setupController: function(controller) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'admin.termsconditions.index',
      pageModelId: null,
      modelName: 'admin_termscondition'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Terms Conditions',
      route_name: 'admin.termsconditions.index',
      route_id: null
    });
    controller.set('pageTitle', 'Terms and Conditions');
    controller.set('pageSubtitle', {
      title: 'Add Terms and Conditions',
      link: 'admin.termsconditions.add',
      record: null,
      resource: this.get('session.authenticated.resources.v2admin-termsconditions-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Terms and Conditions',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
