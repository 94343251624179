import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      postbackconfig: store.createRecord('postbackconfig', {
        location_id: null,
        resource: 'contact',
        on_create: 0,
        on_update: 0,
        on_delete: 0,
        number_of_attempts: 1,
        attempt_interval: 300,
        is_active: 1,
        format: 'api-default'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('postbackconfig', models.postbackconfig);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Postback Config',
      route_name: 'admin.postbackconfigs.add'
    });
    controller.set('pageTitle', 'Add Postback Config');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Postback Configs',
      link: 'admin.postbackconfigs.index',
    }, {
      title: 'Add Postback Config',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.postbackconfig, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
