import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  isLocations: true,
  isLocationsTransactionSummaryByType: true,

  // Set controller params
  sort: '-batch',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  summary_group: 'batch',
  viewBatch: true,

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'amex_grand_total',
    'amex_total_refund_amount',
    'amex_total_sale_amount',
    'batch',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'disc_grand_total',
    'disc_total_sale_amount',
    'disc_total_refund_amount',
    'grand_total',
    'mc_grand_total',
    'mc_total_refund_amount',
    'mc_total_sale_amount',
    'other_grand_total',
    'other_total_refund_amount',
    'other_total_sale_amount',
    'product_transaction_id',
    'summary_group',
    'visa_total_refund_amount',
    'visa_total_sale_amount',
    'visa_grand_total',
  ],
  changeView: Ember.observer('summary_group', function() {
    if (this.get('summary_group') === 'date') {
      this.set('sort', '-date');
      this.set('created_ts', 'today');
      this.set('batch', '');
    } else {
      this.set('created_ts', '');
      this.set('created_ts_from', '');
      this.set('created_ts_to', '');
      this.set('sort', '-batch');
    }
    this.set('viewBatch', this.get('summary_group') === 'batch');
  }),
  showLocations: false,
  reportTypes: [{
    id: 'batch',
    title: 'Batch'
  }, {
    id: 'date',
    title: 'Date'
  }],
  columns: Ember.computed('summary_group', 'isMobileSize', function() {
    var self = this;
    var columns = {
      batch: {
        'name': 'batch',
        'title': 'Batch',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'action': function(transactionsummary) {
          self.send('viewBatch', transactionsummary);
        },
        'actionpriv': ['v2-locations-get', 'v2-transactions-get'],
        'filter': {
          type: 'text'
        }
      },
      batch_close_ts: {
        'name': 'transaction_batch.batch_close_ts',
        'title': 'Batch Close',
        'type': 'shortdate'
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Date',
        'type': 'date',
        'sortable': true,
        'action': function(transactionsummary) {
          self.send('viewTransactions', transactionsummary);
        },
        'actionpriv': ['v2-locations-get', 'v2-transactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      grand_total: {
        'name': 'grand_total',
        'title': 'Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      visa_total_sale_amount: {
        'name': 'visa_total_sale_amount',
        'title': 'Visa<br>Total<br>Sales',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      visa_total_refund_amount: {
        'name': 'visa_total_refund_amount',
        'title': 'Visa<br>Total<br>Refunds',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      visa_grand_total: {
        'name': 'visa_grand_total',
        'title': 'Visa<br>Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      mc_total_sale_amount: {
        'name': 'mc_total_sale_amount',
        'title': 'MC<br>Total<br>Sales',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      mc_total_refund_amount: {
        'name': 'mc_total_refund_amount',
        'title': 'MC<br>Total<br>Refunds',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      mc_grand_total: {
        'name': 'mc_grand_total',
        'title': 'MC<br>Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      disc_total_sale_amount: {
        'name': 'disc_total_sale_amount',
        'title': 'Disc<br>Total<br>Sales',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      disc_total_refund_amount: {
        'name': 'disc_total_refund_amount',
        'title': 'Disc<br>Total<br>Refunds',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      disc_grand_total: {
        'name': 'disc_grand_total',
        'title': 'Disc<br>Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      amex_total_sale_amount: {
        'name': 'amex_total_sale_amount',
        'title': 'Amex<br>Total<br>Sales',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      amex_total_refund_amount: {
        'name': 'amex_total_refund_amount',
        'title': 'Amex<br>Total<br>Refunds',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      amex_grand_total: {
        'name': 'amex_grand_total',
        'title': 'Amex<br>Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      other_total_sale_amount: {
        'name': 'other_total_sale_amount',
        'title': 'Other<br>Total<br>Sales',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      other_total_refund_amount: {
        'name': 'other_total_refund_amount',
        'title': 'Other<br>Total<br>Refunds',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      other_grand_total: {
        'name': 'other_grand_total',
        'title': 'Other<br>Net<br>Total',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transactionsummary) {
          self.transitionToRoute('locations.viewproducttransaction', transactionsummary.get('location.id'), transactionsummary.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: true,
          notuniqbytitle: true
        }
      }
    };

    var listOfCols = ['created_ts', 'batch', 'batch_close_ts', 'grand_total', 'visa_total_sale_amount', 'visa_total_refund_amount', 'visa_grand_total', 'mc_total_sale_amount', 'mc_total_refund_amount', 'mc_grand_total', 'disc_total_sale_amount', 'disc_total_refund_amount', 'disc_grand_total', 'amex_total_sale_amount', 'amex_total_refund_amount', 'amex_grand_total', 'other_total_sale_amount', 'other_total_refund_amount', 'other_grand_total', 'product_transaction_id'];
    var listOfMobileCols = ['created_ts', 'batch', 'batch_close_ts', 'grand_total', 'visa_total_sale_amount', 'visa_total_refund_amount', 'visa_grand_total', 'mc_total_sale_amount', 'mc_total_refund_amount', 'mc_grand_total', 'disc_total_sale_amount', 'disc_total_refund_amount', 'disc_grand_total', 'amex_total_sale_amount', 'amex_total_refund_amount', 'amex_grand_total', 'other_total_sale_amount', 'other_total_refund_amount', 'other_grand_total', 'product_transaction_id'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray){
      if (context.get('product_transactions.length') <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
      if (context.get('summary_group') !== 'batch') {
        colsArray.removeObject(columns.batch_close_ts);
        colsArray.removeObject(columns.batch);
      }
      if (context.get('summary_group') !== 'date') {
        colsArray.removeObject(columns.created_ts);
      }
    }
  }),

  // Actions
  actions: {
    viewBatch: function(transactionsummary) {
      var date = transactionsummary.get('transaction_batch.created_ts');
      var date_from = moment(date).subtract(4, 'months').startOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var date_to = moment(date).add(1, 'months').endOf('day').utc().format("MM/DD/YYYY HH:mm:ss");
      var batch_num = transactionsummary.get('transaction_batch.batch_num');
      this.transitionToRoute('locations.transactions', transactionsummary.get('location.id'), {
        queryParams: {
          batch: batch_num + ',' + batch_num,
          product_transaction_id: transactionsummary.get('product_transaction_id'),
          created_ts: 'custom',
          created_ts_from: date_from,
          created_ts_to: date_to,
          status_id: '101,111'
        }
      });
    },
    viewTransactions: function(transactionsummary) {
      var date = transactionsummary.get('created_ts');
      var date_from = moment(date).startOf('day').format("MM/DD/YYYY HH:mm:ss");
      var date_to = moment(date).endOf('day').format("MM/DD/YYYY HH:mm:ss");
      this.transitionToRoute('locations.transactions', transactionsummary.get('location.id'), {
        queryParams: {
          batch: null,
          product_transaction_id: transactionsummary.get('product_transaction_id'),
          created_ts: 'custom',
          created_ts_from: date_from,
          created_ts_to: date_to
        }
      });
    }
  }
});
