import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('contact.errors.messages', function() {
    this.set('errorMessages', this.get('contact.errors.messages'));
  }),
  errorMessagesUser: null,
  updateErrorMessagesUser: Ember.observer('user.errors.messages', function() {
    this.set('errorMessagesUser', this.get('user.errors.messages'));
  }),

  // Events
  didInsertElement: function() {
    var addroutes = ['contactadd', 'locationcontactadd'];
    if (addroutes.indexOf(this.get('route')) !== -1) {
      var contact = this.get('store').createRecord('contact', {
        header_message: null,
        header_message_type_id: '1',
        email_trx_receipt: true
      });
      if(this.get('location_id')){
        contact.set('location_id', this.get('location_id'));
      }
      if(this.get('contact_email_trx_receipt_default')){
        contact.set('email_trx_receipt', this.get('contact_email_trx_receipt_default'));
      }
      this.set('contact', contact);
      if(!this.get('contact.location_id')) {
        contact.set('location_id', this.get('session.authenticated.primary_location_id'));
      }
      this.set('isEditing', true);
    } else {
      this.set('isEditing', false);
      this.set('isEditingContactInfo', false);
    }
    var alert_types = this.get('store').findAll('alerttype');
    const enrollment_status = this.get('store').findAll('enrollmentstatus');
    this.set('alert_types', alert_types);
    this.set('enroll_status_types', enrollment_status);
    this.set('apiIdLocked', true);
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-contacts-delete'),
      canPost: this.get('session.authenticated.resources.v2-contacts-post'),
      canPut: this.get('session.authenticated.resources.v2-contacts-put'),
    });
  },
  willDestroyElement: function() {},

  // Properties

  // Computed Properties
  alert_type_title: Ember.computed('alert_types.[]', 'contact.header_message_type_id', function() {
    var alert_types = this.get('alert_types');
    var type_id = this.get('contact.header_message_type_id');
    var title = '';
    if(alert_types){
      alert_types.forEach(function(alert_type){
        if(alert_type.get('id') === type_id){
          title = alert_type.get('title');
        }
      });
    }
    return title;
  }),

  // using same variables to convert from id to title and title to id.
  email_enroll_id: Ember.computed('enroll_status_types.[]', 'contact.bill_via_email_enrollment_status', function() {
    const enrollment_status = this.get('enroll_status_types');
    const status_title = this.get('contact.bill_via_email_enrollment_status');
    let id = 0;
    if (enrollment_status) {
      enrollment_status.forEach(function(status) {
        if (status.get('title') === status_title) {
          id = status.get('id');
        }
      });
    }
    return id;
  }),

  text_pay_id: Ember.computed('enroll_status_types.[]', 'contact.text_to_pay_enrollment_status', function() {
    const enrollment_status = this.get('enroll_status_types');
    const status_title = this.get('contact.text_to_pay_enrollment_status');
    let id = 0;
    if (enrollment_status) {
      enrollment_status.forEach(function(status) {
        if (status.get('title') === status_title) {
          id = status.get('id');
        }
      });
    }
    return id;
  }),

  isHtmlMessage: Ember.computed('contact.header_message_type_id', function() {
    var message_type = this.get('contact.header_message_type_id');
    var header_message = this.get('contact.header_message');
    if (message_type !== '0' && header_message) {
      this.set('contact.header_message', header_message.replace(/<(?:.|\n)*?>/gm, ''));
    }
    return (message_type === '0');
  }),

  showLocation: Ember.computed('contact.isNew', 'route', function() {
    return this.get('contact.isNew') && this.get('route') === 'contactadd';
  }),

  // Observers
  changedLocation: Ember.observer('contact.location_id', function() {
    var location_id = this.get('contact.location_id');
    if (!location_id || this.get('route') === 'locationcontactadd') {
      return;
    }

    var locations = this.get('locations') || [];
    var location = locations.filter(function(loc) {
      return loc.id === location_id;
    })[0];
    if (!location) {
      return;
    }

    // Check defaults for trx email receipt
    this.set('contact.email_trx_receipt', location.get('contact_email_trx_receipt_default'));
  }),

  // Functions
  toggleEditMode: function(key, value) {
    // single source to toggle edit modes ON and OFF.
    this.set(key, value);
  },

  getEnrollStatus: function(id) {
    const enrollTypes = this.get('enroll_status_types');
    let title = '';
    enrollTypes.forEach((type) => {
      if (type.get('id') === id) {
        title = type.get('title');
      }
    });
    return title;
  },

  // Actions
  actions: {
    createUser: function() {
      var self = this;
      self.set('errorMessages', null);
      var contact = this.get('contact');
      if (contact.get('email')) {
        var user = self.get('store').createRecord('user', {
          contact_id: contact.get('id'),
          user_type_id: '100',
          username: contact.get('email'),
          tz: self.get('locationinfo.tz') || 'America/Detroit',
          status_id: 1,
          location_id: self.get('locationinfo.id')
        });
        // this.set('user', user);
        user.save().then(
          function() {
            noty({
              text: 'User created successfully from contact!',
              type: 'success'
            });
            self.set('user', user);
          },
          function() {
            self.set('user', user);
            noty({
              text: 'Error creating user from contact!',
              type: 'error',
              timeout: '8000'
            });
          }
        );
      } else {
        noty({
          text: 'Contact must have an email address to become a user!',
          type: 'error',
          timeout: '8000'
        });
      }
    },
    delete: function () {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Contact?', function () {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        var contact = self.get('contact');
        contact.deleteRecord();
        contact.save().then(function () {
          noty({
            text: 'Contact deleted successfully!'
          });
          self.set('isLoading', false);
          contact.unloadRecord();
          self.get('parent').transitionToRoute('contacts');
        }, function (data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          contact.rollbackAttributes();
          noty({
            text: 'Error deleting Contact!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },

    edit: function(key) {
      // leaving original code as is
      // this.set('isEditing', true);
      this.toggleEditMode(key, true);
    },
    editApiId: function() {
      var self = this;
      pretty_confirm('You should only edit this field if you understand the consequences.<br><br>Are you sure you want to edit?', function() {
        self.set('apiIdLocked', false);
      });
    },
    reset: function(key = 'isEditingContactInfo') {
      var contact = this.get('contact');
      if (contact.get('isNew')) {
        contact.deleteRecord();
        if(this.get('route') === 'locationcontactadd'){
          this.get('parent').transitionToRoute('locations.contacts-index', this.get('location_id'));
        } else {
          this.get('parent').transitionToRoute('contacts');
        }
      } else {
        contact.rollbackAttributes();
        // this.set('isEditing', false);
        this.toggleEditMode(key, false);
      }
    },
    save: function(key) {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }

      var self = this;
      var contact = this.get('contact');
      // API uses string values pending, not enrolled, enrolled. UI dropdown uses numbers 0, 1, 2.
      // Function below translates between numbers and strings.
      contact.set('bill_via_email_enrollment_status', this.getEnrollStatus(self.email_enroll_id));
      contact.set('text_to_pay_enrollment_status', this.getEnrollStatus(self.text_pay_id));
      this.set('isLoading', true);

      contact.save()
        .then(() => {
          // If there's a better way to get what PUT /v2/contacts/:id?expand=created_user,location,user,email_blacklist
          // responds with, then we should do that. Without this we get stale data.
          return this.get('store').queryRecord('contact', {
            id: contact.get('id'),
            expand: 'created_user,location,user,email_blacklist',
          });
        })
        .then(function(saved_contact) {
          self.set('contact', saved_contact);
          noty({
            text: 'Contact saved successfully!'
          });
          self.set('isEditing', false);
          self.toggleEditMode(key, false);
          self.set('isLoading', false);
          self.set('apiIdLocked', true);
          if(self.get('route') === 'contactadd' || self.get('route') === 'locationcontactadd'){
            self.get('parent').transitionToRoute('contacts.dashboard', contact.id);
          } else {
            self.get('parent').send('displayNotification', {
              close_previous: true,
              header_message_type_id: contact.get('header_message_type_id'),
              header_message: contact.get('header_message'),
              controller_name: 'contact'
            });
          }
        }, function() {
          self.set('isLoading', false);
          self.set('apiIdLocked', true);
          var errors = self.get('contact.errors.messages');
          var text = errors ? errors.join('\n') : 'Error saving contact!';
          noty({
            text,
            type: 'error',
            timeout: '8000'
          });
        });
    },

  }

});
