import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  description: DS.attr('string'),
  emv_capable: DS.attr('boolean'),
  nfc_capable: DS.attr('boolean'),
  print_capable: DS.attr('boolean'),
  sig_capture_capable: DS.attr('boolean'),
  standalone: DS.attr('boolean'),
  title: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  terminals: DS.hasMany('terminal'),
});
