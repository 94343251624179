import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function (params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
          id: params.id,
          expand: 'user,email_blacklist'
        })
      });
    },
  afterModel: function(models, transition) {
    var store = this.get('store');
    var session = this.get('session');
    const contact = models.contact;

    var accounts;
    if (session.get('authenticated.resources.v2-accountvaults-get')) {

      accounts = store.query('accountvault', {
        contact_id: contact.get('id'),
        page_size: '100',
        fields: 'id,title,first_six,last_four,payment_method,account_type,expiring_in_months,account_holder_name,billing_zip,billing_address,ach_sec_code,contact_id'
      })
      .then(function (data) {
        console.log('charge.js => afterModel => query.accountvault');
        console.log(data);
        models.accounts = data;
      });

    } else {

      accounts = [];
    }

    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfo: store.queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
          models.tagList = data.get('tags').sortBy('title');
        }),
        terminals: store.query('terminal', {
          page_size: 500,
          location_id: models.contact.get('location_id')
        }).then(function(data) {
          models.terminals = data;
        }),
        accounts: accounts
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('accounts', models.accounts);
    controller.set('contact', models.contact);
    controller.set('terminals', models.terminals);
    controller.set('locationinfo', models.locationinfo);

    var locations = Ember.A();
    locations.pushObject(models.locationinfo);
    controller.set('locations', locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Charge',
      route_name: 'contacts.charge',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Charge',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    didTransition: function() {
      var controller = this.controllerFor('contacts.charge');
      // controller.setupCardReader();
      controller.notifyPropertyChange('scenario');
      return true;
    },
    willTransition: function(transition) {
      var controller = this.controllerFor('contacts.charge');
      // controller.destroyCardReader();
      controller.set('sso_params', null);
      controller.set('selectedTags', null);
      // controller.set('transaction.tax', 0);
      // controller.set('transaction.subtotal_amount', 0);
      // controller.set('transaction.transaction_amount', 0);
      // controller.set('transaction.tip_amount', 0);
      // controller.set('transaction.surcharge_amount', 0);
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
