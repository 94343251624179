import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  location_id: DS.attr('string'),
  number_of_credits: DS.attr('string'),
  number_of_debits: DS.attr('string'),
  number_of_refunds: DS.attr('string'),
  settle_date: DS.attr('date'),
  total_credit_amount: DS.attr('string'),
  total_debit_amount: DS.attr('string'),
  total_deposit_amount: DS.attr('string'),
  total_refund_amount: DS.attr('string'),

  // Defined model relationships
  location: DS.belongsTo('location')
});