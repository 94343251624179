import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  cost: DS.attr('string'),
  item_id: DS.attr('string'),
  model: DS.attr('string'),
  model_id: DS.attr('string'),

  // Defined model relationships
  item: DS.belongsTo('item')
});
