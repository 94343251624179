import Ember from 'ember';

export default Ember.Component.extend({

  item_list: Ember.computed('items', 'value', 'thisId', function () {
    var items = this.get('items');
    var value = this.get('value');
    var thisId = this.get('thisId');
    if(items) {
      return items.map(function(item, index){
        return {
          id: thisId + '_option_' + index,
          title: item,
          selected: value === index
        };
      });
    } else {
      return [];
    }
    return this.get('items');
  }),
  promptId: Ember.computed('name', function() {
    return this.get('name') + '_prompt';
  }),
  promptText: Ember.computed('prompt', function() {
    var prompt = this.get('prompt');
    return prompt ? prompt : 'Select...';
  }),
  thisId: Ember.computed('name', function() {
    return this.get('name') + '_select';
  }),

  thisId: Ember.computed('name', function() {
    return this.get('name') + '_select';
  }),

  actions: {
    select: function(index) {
      this.select(index);
    },
  }
});
