import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  // Observers
  isChangeLogTabObserver: Ember.observer('isBinInfoTab', function(){
    if(this.get('isBinInfoTab')){
      this.getBinInfo();
    }
  }),

  // Functions
  getBinInfo: function() {
    var store = this.get('store');
    var self = this;
    var model_id = this.get('model.id');
    var type = this.get('type');

    if (!model_id) {
      return;
    }
    this.set('isLoadingBINInfo', true);
    store.queryRecord('bininfo', {
      id: model_id,
      modelName: type
    }).then(function(res){
      self.set('bininfo', res);
      self.set('isLoadingBINInfo', false);
    }, function(data) {
      var error = '';
      if(data.errors){
        data.errors.forEach(function(err){
          error += '<br>' + err.detail + '.';
        });
      }
      if(data.message) {
        error += '<br>' + data.message + '.';
      }
      noty({
        text: 'Error getting BIN Info!' + error,
        type: 'error',
        timeout: 10000
      });
      self.set('isLoadingBINInfo', false);
    });
  },

  // Actions
  actions: {}
});
