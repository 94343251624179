import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        page_size: '500',
        relationship: 'direct',
        expand: 'quick_invoice_setting,product_file'
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var store = this.get('store');
    var session = this.get('session');
    var quickinvoicesettings = null;
    var locations = models.locations.filter(function(loc) {
      return loc.get('hasQuickInvoice');
    });
    var location = null;
    locations.forEach(function(loc) {
      if (loc.get('id') === session.get('authenticated.primary_location_id')) {
        location = loc;
        quickinvoicesettings = loc.get('quick_invoice_setting');
        controller.set('location', location);
      }
    });
    if (!location || !quickinvoicesettings) {
      location = locations.get('firstObject');
      if(location){
        controller.set('location', location);
        quickinvoicesettings = location.get('quick_invoice_setting');
      }
    }
    if(location && quickinvoicesettings){
      var quickinvoice = store.createRecord('quickinvoice', {
        allow_overpayment: false,
        allow_partial_pay: quickinvoicesettings.default_quick_invoice_allow_partial_pay ? true : false,
        attach_files_to_email: false,
        cc_product_transaction_id: null,
        contact_id: '',
        email: '',
        item_list: {},
        location_id: location.get('id'),
        notification_days_after_due_date: quickinvoicesettings.default_quick_invoice_notification_days_after_due_date,
        notification_days_before_due_date: quickinvoicesettings.default_quick_invoice_notification_days_before_due_date,
        notification_on_due_date: quickinvoicesettings.default_quick_invoice_notification_on_due_date ? true : false,
        send_email: true,
        single_payment_min_amount: 0,
      });
      controller.set('quickinvoice', quickinvoice);
    } else {
      controller.set('location', null);
    }
    controller.set('locations', locations);
    controller.set('isEditing', true);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Quick Invoice',
      route_name: 'quickinvoice.add'
    });
    controller.set('pageTitle', 'Add Quick Invoice');
    controller.set('pageCrumbs', [{
      title: 'Quick Invoices',
      link: 'quickinvoice.index'
    }, {
      title: 'Add Quick Invoice',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
