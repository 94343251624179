import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  sigPadConnected: false,
  appVersion: Ember.computed(function() {
    return window.UiENV.APP.VERSION;
  }),

  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  history: Ember.computed('session.authenticated.history.[]', function() {
    var histories = this.get('session.authenticated.history');
    if(histories){
      return histories.get('firstObject');
    } else {
      return null;
    }
  }),

  showMenus: Ember.computed('session.isAuthenticated', 'session.authenticated.token', 'isRecurringDecline', function() {
    return this.get('isRecurringDecline') ? false : this.get('session.isAuthenticated');
  }),
  showNavBar: Ember.computed(function() {
    return window.localStorage.sub_domain;
  }),

  navLogo: Ember.computed('domainBranding.{url,nav_logo}', 'session.authenticated.token', function() {
    var domain = this.get('domainBranding');
    if (domain) {
      return domain.domain.nav_logo;
    }
  }),
  showLogin: Ember.computed('domainBranding.{url,nav_logo}', 'session.authenticated.token', function() {
    return this.get('domainBranding');
  }),
  unreadMessages: null,
  unreadMessagesCount: Ember.computed('session.authenticated.numUnreadMessages', function() {
    return this.get('session.authenticated.numUnreadMessages');
  }),

  // Actions
  actions: {
    refreshApp: function() {
      window.location.reload(true);
    },
    goBack: function() {
      if(window.history){
        window.history.back();
      }
    }
  }
});
