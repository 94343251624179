import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      mailboxmessage: store.findRecord('mailboxmessage', params.view_id),
      userTypes: store.findAll('usertype')
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var mailboxmessage = models.mailboxmessage;
    controller.set('userTypes', models.userTypes);
    controller.set('mailboxmessage', mailboxmessage);
    if (transition.to.queryParams.edit) {
      controller.set('isEditing', true);
    } else {
      controller.set('isEditing', false);
    }

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-mailboxmessages-delete'),
      canPost: this.get('session.authenticated.resources.v2-mailboxmessages-post'),
      canPut: this.get('session.authenticated.resources.v2-mailboxmessages-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Mailbox Message',
      route_name: 'admin.mailboxmessages.view',
      route_id: mailboxmessage.get('id')
    });
    controller.set('pageTitle', 'View Mailbox Message');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Mailbox Messages',
      link: 'admin.mailboxmessages.index'
    }, {
      title: 'View Mailbox Message',
      link: ''
    }]);
  },

  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.mailboxmessage, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
