import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  sort: '-settle_date',
  page: 1,
  page_size: '15',
  settle_date: 'today',
  export_version: 'v1',
  isRefreshing: true,

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'location_id',
    'number_of_credits',
    'number_of_debits',
    'number_of_refunds',
    'settle_date',
    'settle_date_from',
    'settle_date_to',
    'total_credit_amount',
    'total_debit_amount',
    'total_deposit_amount',
    'total_refund_amount',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      settle_date:{
        'name': 'settle_date',
        'title': 'Settle Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      total_debit_amount: {
        'name': 'total_debit_amount',
        'title': 'Total Debit Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      number_of_debits: {
        'name': 'number_of_debits',
        'title': 'Number of Debits',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      total_credit_amount: {
        'name': 'total_credit_amount',
        'title': 'Total Credit Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      number_of_credits: {
        'name': 'number_of_credits',
        'title': 'Number of Credits',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      total_refund_amount: {
        'name': 'total_refund_amount',
        'title': 'Total Refund Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      number_of_refunds: {
        'name': 'number_of_refunds',
        'title': 'Number of Refunds',
        'type': 'string',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      total_deposit_amount: {
        'name': 'total_deposit_amount',
        'title': 'Total Deposit Amount',
        'type': 'currency',
        'sortable': true,
        'align': 'text-right',
        'filter': {
          type: 'text'
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(transactionachsettlementsummary) {
          self.transitionToRoute('locations.view', transactionachsettlementsummary.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'filter': {
          type: 'dropdown',
          items: 'locations'
        }
      }
    };

    var listOfCols = ['settle_date', 'total_debit_amount', 'number_of_debits', 'total_credit_amount', 'number_of_credits', 'total_refund_amount', 'number_of_refunds', 'total_deposit_amount', 'location_id'];
    var listOfMobileCols = ['settle_date', 'total_debit_amount', 'number_of_debits', 'total_credit_amount', 'number_of_credits', 'total_refund_amount', 'number_of_refunds', 'total_deposit_amount', 'location_id'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

});
