import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    var newValue = moment(value, 'MM/DD/YYYY');
    return newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm:ss') : null;
  },
  deserialize: function(value) {
    var newValue = moment(value, 'YYYY-MM-DD HH:mm:ss');
    return newValue.isValid() ? newValue.format('MM/DD/YYYY') : null;
  }
});
