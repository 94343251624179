import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  isVerticalStack: Ember.computed('previewHPP.field_configuration.stack', function() {
    return this.get('previewHPP.field_configuration.stack') === 'vertical';
  }),
  headerCols: Ember.computed('headerSettings', function() {
    var settings = this.get('headerSettings');
    return this.setColClass(settings);
  }),
  bodyCols: Ember.computed('bodySettings', function() {
    var settings = this.get('bodySettings');
    return this.setColClass(settings);
  }),
  footerCols: Ember.computed('footerSettings', function() {
    var settings = this.get('footerSettings');
    return this.setColClass(settings);
  }),

  setColClass: function(settings) {
    var cols = settings.columns;
    if (cols === 1) {
      return 'col-sm-12';
    } else if (cols === 2) {
      return 'col-sm-6';
    } else if (cols === 3) {
      return 'col-sm-4';
    } else if (cols === 4) {
      return 'col-sm-3';
    }
  },

  headerSettings: Ember.computed('previewHPP.field_configuration.header.settings', function() {
    return this.get('previewHPP.field_configuration.header.settings');
  }),
  bodySettings: Ember.computed('previewHPP.field_configuration.body.settings', function() {
    return this.get('previewHPP.field_configuration.body.settings');
  }),
  footerSettings: Ember.computed('previewHPP.field_configuration.footer.settings', function() {
    return this.get('previewHPP.field_configuration.footer.settings');
  }),
  headerFields: Ember.computed('previewHPP.field_configuration.header.fields', function() {
    return this.get('previewHPP.field_configuration.header.fields');
  }),
  bodyFields: Ember.computed('previewHPP.field_configuration.body.fields', function() {
    return this.get('previewHPP.field_configuration.body.fields');
  }),
  footerFields: Ember.computed('previewHPP.field_configuration.footer.fields', function() {
    return this.get('previewHPP.field_configuration.footer.fields');
  }),
  headerFieldsSorted: Ember.computed('headerFields', 'isVerticalStack', function() {
    var fields = this.get('headerFields');
    var isVerticalStack = this.get('isVerticalStack');
    return this.sortFields(fields, isVerticalStack);
  }),
  bodyFieldsSorted: Ember.computed('bodyFields', 'isVerticalStack', function() {
    var fields = this.get('bodyFields');
    var isVerticalStack = this.get('isVerticalStack');
    return this.sortFields(fields, isVerticalStack);
  }),
  footerFieldsSorted: Ember.computed('footerFields', 'isVerticalStack', function() {
    var fields = this.get('footerFields');
    var isVerticalStack = this.get('isVerticalStack');
    return this.sortFields(fields, isVerticalStack);
  }),
  headerFieldsWithBlanks: Ember.computed('headerFieldsSorted', 'isVerticalStack', 'headerSettings', function() {
    var fields = this.get('headerFieldsSorted');
    var stack = this.get('isVerticalStack');
    var settings = this.get('headerSettings');
    return this.setArea(fields, stack, settings);
  }),
  bodyFieldsWithBlanks: Ember.computed('bodyFieldsSorted', 'isVerticalStack', 'bodySettings', function() {
    var fields = this.get('bodyFieldsSorted');
    var stack = this.get('isVerticalStack');
    var settings = this.get('bodySettings');
    return this.setArea(fields, stack, settings);
  }),
  footerFieldsWithBlanks: Ember.computed('footerFieldsSorted', 'isVerticalStack', 'footerSettings', function() {
    var fields = this.get('footerFieldsSorted');
    var stack = this.get('isVerticalStack');
    var settings = this.get('footerSettings');
    return this.setArea(fields, stack, settings);
  }),

  setArea: function(array, stack, settings) {
    var cols = settings.columns;
    var rows = 1;
    array.map(function(field) {
      var r = Number(field.position[0]) + 1;
      if (rows < r) {
        rows = r;
      }
    });
    var amount = stack ? rows : cols;
    var pos1 = stack ? 1 : 0;
    var pos2 = stack ? 0 : 1;
    var arr = [];
    for (var i = 0; i < amount; i++) {
      arr = arr.concat(this.fillInGaps(array, amount, i, pos1, pos2));
    }
    return arr;
  },

  fillInGaps: function(array, amount, index, pos1, pos2) {
    var fieldsArray = array.filter(function(field) {
      return field.position[pos1] === String(index);
    });
    var indexes;
    if (fieldsArray.length < amount) { // missing a row||column
      indexes = fieldsArray.map(function(field) {
        return field.position[pos2];
      });
      for (var i = 0; i < amount; i++) {
        if (indexes.indexOf(String(i)) === -1) {
          fieldsArray.splice(i, 0, {
            id: 'blank_' + Math.random().toString(36).slice(2)
          }); // adding blank
        }
      }
    }
    return fieldsArray;
  },

  sortFields: function(arr, stack) {
    arr.sort(function(a, b) {
      var aPos0 = Number(a.position[0]);
      var aPos1 = Number(a.position[1]);
      var bPos0 = Number(b.position[0]);
      var bPos1 = Number(b.position[1]);
      if (stack) {
        if (aPos1 < bPos1) {
          return -1;
        }
        if (aPos1 > bPos1) {
          return 1;
        }
        if (aPos0 < bPos0) {
          return -1;
        }
        if (aPos0 > bPos0) {
          return 1;
        }
      } else {
        if (aPos0 < bPos0) {
          return -1;
        }
        if (aPos0 > bPos0) {
          return 1;
        }
        if (aPos1 < bPos1) {
          return -1;
        }
        if (aPos1 > bPos1) {
          return 1;
        }
      }
    });
    return arr;
  },

  didInsertElement: function() {
    var header = this.get('hppHeader');
    var body = this.get('hppBody');
    var footer = this.get('hppFooter');
    var stack = this.get('hppStack');
    var mini = this.get('hppMini');
    var product = this.get('product');

    setArea(header, 'header', stack, product);
    setArea(body, 'body', stack, product);
    setArea(footer, 'footer', stack, product);

    if (mini) {
      document.getElementById('hppform_container').classList.add('hppform_mini');
    }

    function setArea(area, name, stack, product) {
      var parentElement = document.getElementById('hpp_' + name + '_container');
      var cols = area.settings.columns;
      var fields = area.fields;
      if(fields.length === 0) {
        return;
      }
      fields.sort(function(a, b) {
        var aPos0 = Number(a.position[0]);
        var aPos1 = Number(a.position[1]);
        var bPos0 = Number(b.position[0]);
        var bPos1 = Number(b.position[1]);
        if (stack === 'horizontal') {
          if (aPos0 < bPos0) {
            return -1;
          }
          if (aPos0 > bPos0) {
            return 1;
          }
          if (aPos1 < bPos1) {
            return -1;
          }
          if (aPos1 > bPos1) {
            return 1;
          }
        } else {
          if (aPos1 < bPos1) {
            return -1;
          }
          if (aPos1 > bPos1) {
            return 1;
          }
          if (aPos0 < bPos0) {
            return -1;
          }
          if (aPos0 > bPos0) {
            return 1;
          }
        }
      });
      var numRows = 1;
      fields.map(function(field) {
        var row = Number(field.position[0]) + 1;
        if (numRows < row) {
          numRows = row;
        }
      });
      if (stack === 'vertical') {
        for (var h = 0; h < cols; h++) {
          var el = document.createElement('div');
          el.className = setColClass(cols);
          el.id = 'hpp_' + name + '_col_' + h;
          parentElement.appendChild(el);
          fillInGaps(el, null, fields, numRows, 1, 0, h, product);
        }
      } else {
        for (var j = 0; j < numRows; j++) {
          fillInGaps(parentElement, setColClass(cols), fields, cols, 0, 1, j, product);
        }
      }
    }

    function fillInGaps(parentElement, colClass, array, amount, pos1, pos2, index, product) {
      var fieldsArray = array.filter(function(field) {
        return String(field.position[pos1]) === String(index);
      });
      var indexes;
      if (fieldsArray.length < amount) { // missing a row||column
        indexes = fieldsArray.map(function(field) {
          return field.position[pos2];
        });
        for (var i = 0; i < amount; i++) {
          if (indexes.indexOf(String(i)) === -1) {
            fieldsArray.splice(i, 0, {
              id: 'blank_' + Math.random().toString(36).slice(2)
            }); // adding blank
          }
        }
      }
      fieldsArray.map(function(field) {
        createField(field, parentElement, colClass, product);
      });
    }

    function createField(field, parentElement, colClass, product) {
      var fieldElement = document.createElement('div');
      fieldElement.className = 'hppform_field';
      if (colClass) {
        fieldElement.className += ' ' + colClass;
      }
      fieldElement.id = 'hppform_field_' + field.id;

      if (field.id.indexOf('blank_') === -1 && (field.id !== 'terms_agree' || (field.id === 'terms_agree' && product.get('receipt_vt_above_signature')))) { // if not a blank field
        if (field.field_type === 'heading') {
          if (field.id === 'header_title') {
            createFieldElement(field, fieldElement, 'h1', null, 'hppform_field_heading', null, null, true);
          } else {
            createFieldElement(field, fieldElement, 'h3', null, 'hppform_field_heading', null, null, true);
          }
        } else if (field.field_type === 'text') {
          createFieldElement(field, fieldElement, 'p', null, 'hppform_field_text', null, null, true);
        } else if (field.field_type === 'button') {
          createFieldElement(field, fieldElement, 'button', null, 'hppform_field_button btn btn-info', null, 'hppform_' + field.id, true);
        } else if (field.field_type === 'image') {
          createFieldElement(field, fieldElement, 'img', null, 'hppform_field_image', null, null, null, true);
        } else if (field.field_type === 'captcha') {
          var captchaElement = document.createElement('div');
          captchaElement.className = 'hppform_field_captcha';
          captchaElement.innerHTML = '<img src="/images/recaptcha.jpg" alt="reCAPTCHA" style="max-width:100%;max-height:45px;">';
          fieldElement.appendChild(captchaElement);
        } else {
          // set label stuffs
          if(field.id !== 'terms_agree') {
            createFieldElement(field, fieldElement, 'label', null, 'hppform_field_label', null, null, true);
          }

          // set input stuffs
          if (field.field_type === 'inputText') {
            createFieldElement(field, fieldElement, 'input', null, 'hppform_field_input');
          } else if (field.field_type === 'inputDate') {
            createFieldElement(field, fieldElement, 'input', 'date', 'hppform_field_input');
          } else if (field.field_type === 'inputSelect') {
            if (field.id === 'exp_date') {
              var selectContainer = document.createElement('div');

              var selectContainerElement1 = document.createElement('div');
              selectContainerElement1.className = 'exp_date_select_container months';
              createFieldElement(field, selectContainerElement1, 'select', null, 'hppform_field_select exp_date_select', 'exp_month', 'exp_month');

              var selectContainerElement2 = document.createElement('div');
              selectContainerElement2.className = 'exp_date_select_container';
              createFieldElement(field, selectContainerElement2, 'select', null, 'hppform_field_select exp_date_select', 'exp_year', 'exp_year');

              selectContainer.appendChild(selectContainerElement1);
              selectContainer.appendChild(selectContainerElement2);
              fieldElement.appendChild(selectContainer);

              setTimeout(function() {
                createSelectOptions('exp_month', exp_months, 'Month');
                createSelectOptions('exp_year', exp_years, 'Year');
              }, 1);
            } else if (field.id === 'transaction_amount') {
              var transactionAmountDonationContainer = document.createElement('div');
              var transactionAmountDonationUL = document.createElement('ul');
              transactionAmountDonationUL.className = 'hppform_field_select_ul';

              field.options.forEach(function(option, index) {
                var transactionAmountDonationLI = document.createElement('li');
                transactionAmountDonationLI.className = 'hppform_field_select_li';

                if (option === 'other') {
                  transactionAmountDonationLI.className += ' hppform_field_select_li_other';
                  createFieldElement(field, transactionAmountDonationLI, 'input', null, 'hppform_field_select_input_other');
                } else {
                  var transactionAmountDonationINPUT = document.createElement('input');
                  transactionAmountDonationINPUT.name = field.id;
                  transactionAmountDonationINPUT.className = 'hppform_field_select_input';
                  transactionAmountDonationINPUT.id = field.id + '_' + index;
                  transactionAmountDonationINPUT.type = 'radio';
                  transactionAmountDonationINPUT.value = option;
                  if (field.value === option) {
                    transactionAmountDonationINPUT.checked = 'checked';
                  }
                  if (field.readonly) {
                    transactionAmountDonationINPUT.disabled = true;
                  }
                  var transactionAmountDonationLABEL = document.createElement('label');
                  transactionAmountDonationLABEL.className = 'hppform_field_select_label';
                  transactionAmountDonationLABEL.setAttribute('for', field.id + '_' + index);
                  transactionAmountDonationLABEL.innerHTML = '$' + option;
                  transactionAmountDonationLI.appendChild(transactionAmountDonationINPUT);
                  transactionAmountDonationLI.appendChild(transactionAmountDonationLABEL);
                }
                transactionAmountDonationUL.appendChild(transactionAmountDonationLI);
              });
              transactionAmountDonationContainer.appendChild(transactionAmountDonationUL);
              fieldElement.appendChild(transactionAmountDonationContainer);

            } else {
              createFieldElement(field, fieldElement, 'select', null, 'hppform_field_select', null, 'hppform_field_select_' + field.id);
              setTimeout(function() {
                createSelectOptions('hppform_field_select_' + field.id, field.options, field.placeholder, field.value);
              }, 1);
            }
          } else if (field.field_type === 'inputCheckbox') {
            if(field.id === 'terms_agree') {
              createFieldElement(field, fieldElement, 'input', 'checkbox', 'hppform_field_checkbox', null, null, product.get('receipt_vt_above_signature'), null);
            } else {
              createFieldElement(field, fieldElement, 'input', 'checkbox', 'hppform_field_checkbox');
            }
          } else if (field.field_type === 'inputRadio')  {
            field.options.forEach(function(f) {
              createFieldElement(field, fieldElement, 'input', 'radio', 'hppform_field_radio', null, 'hppform_field_radio_' + field.id + '_' + f.toLowerCase(), f);
            });
          }
        }
        var alwaysVisible = ['submit_button', 'header_title', 'header_logo', 'captcha'];
        if (!field.visible && alwaysVisible.indexOf(field.id) === -1) {
          fieldElement.className += ' hppform_field_hidden';
        }
      } else { // is a blank field
        fieldElement.className += ' hppform_field_blank';
      }

      parentElement.appendChild(fieldElement);
    }

    function createFieldElement(field, parentElement, tag, type, className, overrideName, overrideId, addTextNode, addSrc) {
      var displayFieldElements = ['label', 'p', 'h1', 'h3', 'img'];
      var el = document.createElement(tag);
      el.className = className;
      if (displayFieldElements.indexOf(tag) !== -1) {
        var elText = document.createTextNode(field.label);
        if (addTextNode && field.field_type !== 'inputRadio') {
          el.appendChild(elText);
        }
        if (field.required) {
          el.appendChild(elText);
          var requiredElement = document.createElement('span');
          requiredElement.className = 'hppform_field_required_indicator';
          var requiredElementText = document.createTextNode(' *');
          requiredElement.appendChild(requiredElementText);
          el.appendChild(requiredElement);
        }
        if (addSrc) {
          el.src = field.link;
          el.alt = 'IMAGE';
        }
      } else {
        el.name = overrideName || field.id;
        if (overrideId) {
          el.id = overrideId;
        }
        if (type) {
          el.type = type;
        }
        if (field.readonly) {
          if (tag === 'select') {
            el.disabled = true;
          } else {
            el.readOnly = true;
          }
        }
        if (field.value) {
          if (type === 'checkbox') {
            if (field.value === 'true') {
              el.checked = 'checked';
            }
          } else {
            if (className !== 'hppform_field_select_input_other') {
              el.value = field.value;
            }
          }
        }
        if (field.placeholder) {
          el.placeholder = field.placeholder;
        }
        if (addTextNode && field.field_type !== 'inputRadio' && field.field_type !== 'inputCheckbox') {
          var elText2 = document.createTextNode(field.label);
          el.appendChild(elText2);
        }
        if (field.id === 'account_number') {
          el.id = 'hppform_account_number';
          el.onkeypress = removeHasErrorFromAccountNumber;
        }
      }

      parentElement.appendChild(el);
      if (addTextNode && typeof addTextNode === 'string' && (field.field_type === 'inputRadio' || field.field_type === 'inputCheckbox')) {
        var addTextNodeText = document.createTextNode(addTextNode);
        parentElement.appendChild(addTextNodeText);
      }
    }

    function removeHasErrorFromAccountNumber() {
      var el = document.getElementById('hppform_account_number');
      el.classList.remove('has-error');
    }

    function setColClass(cols) {
      if (cols === 2) {
        return 'col-sm-6';
      } else if (cols === 3) {
        return 'col-sm-4';
      } else if (cols === 4) {
        return 'col-sm-3';
      } else {
        return 'col-sm-12';
      }
    }

    function createSelectOptions(prop, options, title, value) {
      var selectElement = document.getElementById(prop);
      var opts = options.slice();
      opts.unshift({
        id: 0,
        title: title || 'Select...'
      });
      opts = opts.filter(function(opt) {
        return opt !== 'null' && opt !== '';
      });
      opts.forEach(function(option) {
        var optionElement = document.createElement('option');
        optionElement.value = option.id || option;
        if (option.id === 0) {
          optionElement.disabled = true;
          optionElement.selected = true;
        }
        optionElement.className = 'hppform_field_select_option';
        if (option === value) {
          optionElement.selected = true;
        }
        var optionElementText = document.createTextNode(option.title || option);
        optionElement.appendChild(optionElementText);
        selectElement.appendChild(optionElement);
      });
    }
    var exp_months = [{id: '01', title: '01 - January'},{id: '02', title: '02 - February'},{id: '03', title: '03 - March'},{id: '04', title: '04 - April'},{id: '05', title: '05 - May'},{id: '06', title: '06 - June'},{id: '07', title: '07 - July'},{id: '08', title: '08 - Auguust'},{id: '09', title: '09 - September'},{id: '10', title: '10 - October'},{id: '11', title: '11 - November'},{id: '12', title: '12 - December'}];
    var exp_years = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 10; i++) {
      exp_years.push({
        'id': (parseInt(year.toString().substr(2, 2)) + i).toString(),
        'title': (year + i).toString()
      });
    }
  }

});
