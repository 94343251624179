import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('featureflag.errors.messages', function() {
    this.set('errorMessages', this.get('featureflag.errors.messages'));
  }),
  errorMessagesFeatureFlagUsers: null,
  updateErrorMessagesFeatureFlagUsers: Ember.observer('featureflaguser.errors.messages', function() {
    this.set('errorMessagesFeatureFlagUsers', this.get('featureflaguser.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isLoading: false,
  isEditing: false,
  selectedDeveloper: '',
  selectedDevelopers: [],
  selectedLocation: '',
  selectedLocationRelationship: 'direct',
  selectedLocations: [],
  selectedUser: '',
  selectedUsers: [],
  selectedUserType: '',
  selectedUserTypes: [],

  // Computed Properties
  disabledUserButtons: Ember.computed('isAdding', 'isEditing', function() {
    return this.get('isAdding') || this.get('isEditing');
  }),
  disabledUserTypeDropdown: Ember.computed('isSelectedLocations', 'isSelectedDevelopers', 'isCriteria.location', 'isCriteria.developer', function() {
    if(this.get('isCriteria.developer')) {
      return !this.get('isSelectedDevelopers');
    } else if(this.get('isCriteria.location')) {
      return !this.get('isSelectedLocations');
    }
  }),
  isCriteriaUserTypesArray: Ember.computed('userTypeOptionsArray', function() {
    return this.get('userTypeOptionsArray');
  }),
  isLocationRelationshipAll: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'all' ? true : false;
  }),
  isLocationRelationshipChild: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'child' ? true : false;
  }),
  isLocationRelationshipDirect: Ember.computed('selectedLocationRelationship', function() {
    return this.get('selectedLocationRelationship') === 'direct' ? true : false;
  }),
  isNoSelectedRecipients: Ember.computed('isSelectedDevelopers', 'isSelectedLocations', 'isSelectedUsers', function() {
    var isSelectedDevelopers = this.get('isSelectedDevelopers');
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var notAny = !isSelectedDevelopers && !isSelectedLocations && !isSelectedUsers;
    return notAny ? true : false;
  }),
  isSelectedDevelopers: Ember.computed('selectedDevelopers.@each', function() {
    return this.get('selectedDevelopers').length ? true : false;
  }),
  isSelectedDevelopersAndLocationsOrUsers: Ember.computed('isSelectedDevelopers', 'isSelectedLocations', 'isSelectedUsers', function() {
    var isSelectedDevelopers = this.get('isSelectedDevelopers');
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var isDevelopersAndLocations = isSelectedDevelopers && isSelectedLocations;
    var isDevelopersAndUsers = isSelectedDevelopers && isSelectedUsers;
    return isDevelopersAndLocations || isDevelopersAndUsers ? true : false;
  }),
  isSelectedLocations: Ember.computed('selectedLocations.@each', function() {
    return this.get('selectedLocations').length ? true : false;
  }),
  isSelectedLocationsAndUsers: Ember.computed('isSelectedLocations', 'isSelectedUsers', function() {
    var isSelectedLocations = this.get('isSelectedLocations');
    var isSelectedUsers = this.get('isSelectedUsers');
    var isLocationsAndUsers = isSelectedLocations && isSelectedUsers;
    return isLocationsAndUsers ? true : false;
  }),
  isSelectedUsers: Ember.computed('selectedUsers.@each', function() {
    return this.get('selectedUsers').length ? true : false;
  }),
  isSelectedUserTypes: Ember.computed('selectedUserTypes.@each', function() {
    return this.get('selectedUserTypes').length ? true : false;
  }),
  showActions: Ember.computed('featureflag.is_enabled', 'isEditing', 'session.authenticated.resources.v2-featureflagusers-delete', 'session.authenticated.resources.v2-featureflagusers-post', function() {
    return this.get('featureflag.is_enabled') === '2' && !this.get('isEditing') && (this.get('session.authenticated.resources.v2-featureflagusers-delete') || this.get('session.authenticated.resources.v2-featureflagusers-post'));
  }),

  // OBSERVERS
  updateSelectedDevelopers: Ember.observer('selectedDeveloper', function() {
    var selectedDevelopers = this.get('selectedDevelopers');
    var selectedDeveloper = this.get('selectedDeveloper');
    var isCriteriaDevelopersArray = this.get('isCriteriaDevelopersArray');
    if (selectedDeveloper) {
      isCriteriaDevelopersArray.map(function(developer){
        if(developer.id === selectedDeveloper){
          selectedDevelopers.pushObject(developer);
        }
      });
      this.set('selectedDeveloper', null);
    }
  }),
  updateSelectedLocations: Ember.observer('selectedLocation', function() {
    var selectedLocations = this.get('selectedLocations');
    var selectedLocation = this.get('selectedLocation');
    var isCriteriaLocationsArray = this.get('isCriteriaLocationsArray');
    if (selectedLocation) {
      isCriteriaLocationsArray.map(function(location){
        if(location.id === selectedLocation){
          selectedLocations.pushObject(location);
        }
      });
      this.set('selectedLocation', null);
    }
  }),
  updateselectedUsers: Ember.observer('selectedUser', function() {
    var selectedUsers = this.get('selectedUsers');
    var selectedUser = this.get('selectedUser');
    var isCriteriaUsersArray = this.get('isCriteriaUsersArray');
    if (selectedUser) {
      isCriteriaUsersArray.map(function(user){
        if(user.id === selectedUser){
          selectedUsers.pushObject(user);
        }
      });
      this.set('selectedUser', null);
    }
  }),
  updateSelectedUserType: Ember.observer('selectedUserTypes.@each', 'selectedUserType.@each', function() {
    Ember.run.once(this, 'selectedUserTypesDidChange');
  }),

  // Functions
  selectedUserTypesDidChange: function() {
    var userTypes = this.get('userTypes');
    var selectedUserTypes = this.get('selectedUserTypes');
    var selectedUserType = this.get('selectedUserType');
    if (selectedUserType) {
      var selectedUserTypeTitle = '';
      userTypes.map(function(userTypeId) {
        if (userTypeId.get('id') === selectedUserType) {
          selectedUserTypeTitle = userTypeId.get('title');
        }
      });
      selectedUserTypes.pushObject({
        id: selectedUserType,
        title: selectedUserTypeTitle
      });
      this.set('selectedUserType', null);
    }
    var filteredUserTypeOptions = userTypes.filter(function(option) {
      var tempSelectedUserTypes = selectedUserTypes.map(function(usertype) {
        return usertype.id;
      });
      return tempSelectedUserTypes.indexOf(option.id) === -1;
    });
    this.set('userTypeOptionsArray', filteredUserTypeOptions);
  },

  // Actions
  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var featureflag = this.get('featureflag');
      featureflag.save().then(function() {
        noty({
          text: 'Feature Flag updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.transitionToRoute('admin.featureflags.index');
      }, function() {
        self.set('isLoading', false);
      });
    },
    saveFeatureFlagUser: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var featureflaguser = this.get('featureflaguser');
      var selectedUsers = this.get('selectedUsers').map(function(user){
        return user.id;
      });
      var selectedUserTypes = this.get('selectedUserTypes').map(function(type){
        return type.id;
      });
      var selectedDevelopers = this.get('selectedDevelopers').map(function(developer){
        return developer.id;
      });
      var selectedLocations = this.get('selectedLocations').map(function(location){
        return location.id;
      });
      var selectedLocationRelationship = this.get('selectedLocationRelationship');
      if (selectedUsers.get('length') === 0 && selectedUserTypes.get('length') === 0 && selectedDevelopers.get('length') === 0 && selectedLocations.get('length') === 0) {
        featureflaguser.set('user_criteria', null);
      } else {
        this.set('featureflaguser.user_criteria.user_id', selectedUsers);
        this.set('featureflaguser.user_criteria.user_type_id', selectedUserTypes);
        this.set('featureflaguser.user_criteria.developer_id', selectedDevelopers);
        this.set('featureflaguser.user_criteria.location_id', selectedLocations);
        this.set('featureflaguser.user_criteria.relationship', selectedLocationRelationship);
      }

      featureflaguser.save().then(function() {
        featureflaguser.unloadRecord();
        self.set('selectedUsers', []);
        self.set('selectedUserTypes', []);
        self.set('selectedDevelopers', []);
        self.set('selectedLocations', []);
        self.set('selectedLocationRelationship', 'direct');
        self.set('isCriteria', {});
        self.set('isCriteria.user', true);
        noty({
          text: 'Users added successfully!'
        });
        self.set('isLoading', false);
        self.set('isAdding', false);
      }, function() {
        noty({
          text: 'Error adding users!'
        });
        featureflaguser.set('user_criteria', {
          user_id: '',
          user_type_id: '',
          developer_id: '',
          location_id: '',
          relationship: ''
        });
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.featureflags.index');
    },
    cancel: function() {
      var featureflag = this.get('featureflag');
      featureflag.rollbackAttributes();
      this.set('isEditing', false);
    },
    cancelFeatureFlagUser: function() {
      var featureflaguser = this.get('featureflaguser');
      featureflaguser.rollbackAttributes();
      this.set('isAdding', false);
    },
    addUsers: function() {
      var feature_flag_id = this.get('featureflag.id');
      var featureflaguser = this.get('store').createRecord('featureflaguser', {
        feature_flag_id: feature_flag_id,
        user_criteria: {
          user_id: '',
          user_type_id: '',
          developer_id: '',
          location_id: '',
          relationship: ''
        }
      });
      this.set('featureflaguser', featureflaguser);
      this.set('isAdding', true);
    },
    removeAllUsers: function() {
      var self = this;
      var session = this.get('session');
      pretty_confirm('Are you sure you want to remove all users from this Feature Flag?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/featureflagusers?feature_flag_id=' + self.get('featureflag.id'),
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          noty({
            text: 'Users removed successfully!'
          });
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Error removing Users from Feature Flag!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    addFeatureFlagTo: function(type) {
      let objectPath = 'isCriteria.' + type;
      this.set('isCriteria', {});
      this.set(objectPath, true);
    },
    isCriteriaDevelopersArrayFind: function(data) {
      var self = this;
      var isCriteriaDevelopersArray = this.get('store').query('developer', {
        title: data,
        sort: 'title',
        page_size: '10'
      });
      isCriteriaDevelopersArray.then(function(result) {
        var selectedDevelopers = self.get('selectedDevelopers').map(function(developer){
          return developer.id;
        });
        var filteredArray = result.filter(function(developer) {
          if (selectedDevelopers.indexOf(developer.id) === -1) {
            return developer;
          }
        });
        self.set('isCriteriaDevelopersArray', filteredArray);
      });
    },
    isCriteriaLocationsArrayFind: function(data) {
      var self = this;
      var isCriteriaLocationsArray = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10'
      });
      isCriteriaLocationsArray.then(function(result) {
        var selectedLocations = self.get('selectedLocations').map(function(location){
          return location.id;
        });
        var filteredArray = result.filter(function(location) {
          if (selectedLocations.indexOf(location.id) === -1) {
            return location;
          }
        });
        self.set('isCriteriaLocationsArray', filteredArray);
      });
    },
    isCriteriaUsersArrayFind: function(data) {
      var self = this;
      var isCriteriaUsersArray = this.get('store').query('user', {
        username: data,
        sort: 'username',
        page_size: '10'
      });
      isCriteriaUsersArray.then(function(result) {
        var selectedUsers = self.get('selectedUsers').map(function(user){
          return user.id;
        });
        var filteredArray = result.filter(function(user) {
          if (selectedUsers.indexOf(user.id) === -1) {
            return user;
          }
        });
        self.set('isCriteriaUsersArray', filteredArray);
      });
    },
    removeSelectedRecipient: function(index, emberArrayModel) {
      var arr = this.get(emberArrayModel);
      arr.map(function(recipient) {
        if (index === recipient) {
          arr.removeObject(recipient);
        }
      });
      this.set(emberArrayModel, arr);
    },
  }
});
