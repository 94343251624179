import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  ach_products_developer: DS.attr('number'),
  ach_products_portfolio_total: DS.attr('number'),
  cc_products_developer: DS.attr('number'),
  cc_products_portfolio_total: DS.attr('number'),
  developer_company: DS.attr('string'),
  locations: DS.attr('number'),
  portfolio_title: DS.attr('string'),
});
