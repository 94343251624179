import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminSalestax: true,

  // Set controller params
  isEditing: null,
  isLoading: false,
  selectedResources: null,

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('admin.salestax.index');
    },
    cancel: function() {
      var salestax = this.get('salestax');
      salestax.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function() {
      var self = this;
      var salestax = this.get('salestax');
      pretty_confirm('Are you sure you want to delete this Sales Tax?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        salestax.deleteRecord();
        salestax.save().then(function() {
          salestax.unloadRecord();
          noty({
            text: 'Sales Tax deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.salestax.index');
        }, function() {
          salestax.rollbackAttributes();
          noty({
            text: 'Error deleting Sales Tax!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var salestax = this.get('salestax');
      var newSalesTax = this.get('newSalesTax');
      newSalesTax.set('country', salestax.get('country'));
      newSalesTax.set('location_id', salestax.get('location_id'));
      newSalesTax.set('rate', salestax.get('rate'));
      newSalesTax.set('zip_code', salestax.get('zip_code'));

      newSalesTax.save().then(function(result) {
        noty({
          text: 'Sales tax saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.get('store').push({ // need this for accurate dirty checking in willTransition
          data: {
            id: salestax.get('id'),
            type: 'salestax',
            attributes: {
              country: result.get('country'),
              location_id: result.get('location_id'),
              rate: result.get('rate'),
              zip_code: result.get('zip_code')
            }
          }
        });
        self.transitionToRoute('admin.salestax.index');
      }, function(result) {
        self.set('isLoading', false);
        var errorMessages = [];
        var errors = result.errors;
        Object.keys(errors).forEach(function(m) {
          if (typeof errors === 'string') {
            if (m === 'detail') {
              errorMessages.push(errors[m]);
            }
          } else {
            Object.keys(errors[m]).forEach(function(n) {
              if (n === 'detail') {
                errorMessages.push(errors[m][n]);
              }
            });
          }
        });
        self.set('errorMessages', errorMessages);
      });
    },
    updateLocation: function(data) {
      var self = this;
      var locationinfos = this.get('store').query('locationinfo', {
        name: data,
        page_size: '10'
      });
      locationinfos.then(function(result) {
        self.set('locationinfos', result);
      });
    }
  }
});
