import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
      account: store.queryRecord('accountvault', {
        id: params.accountvault_id,
        expand: 'account_vault_cau_product_transaction,contact,created_user,location,signature',
        active: '0,1'
      }),
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return Ember.RSVP.Promise.all([
      self.get('notFound').check(models.contact, transition, 'Contact', self),
      self.get('notFound').check(models.account, transition, 'Account', self),
    ]).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfos = data;
        }),
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var exp_date = models.account.get('exp_date');
    var title = models.account.get('title');
    if (exp_date) {
      models.account.set('exp_month', exp_date.substr(0, 2));
      models.account.set('exp_year', exp_date.substr(2, 2));
    }
    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.account.get('id'),
        type: 'accountvault',
        attributes: {
          title: title ? title : '',
          exp_month: exp_date ? exp_date.substr(0, 2) : '',
          exp_year: exp_date ? exp_date.substr(2, 2) : ''
        }
      }
    });
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('account', models.account);
    controller.set('locationinfo', locationinfo);
    controller.set('locationinfos', models.locationinfos);
    controller.set('apiIdLocked', true);
    controller.set('location_id', null);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● View Account Vault',
      route_name: 'contacts.accounts-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.account.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Account Vaults',
      link: 'contacts.accounts-index',
      record: models.contact.get('id')
    }, {
      title: 'View Account Vault',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
      this.controller.set('needsRefresh', new moment().unix());
    },
    willTransition: function(transition) {
      var model = this.controller.get('account');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.send('leavingContacts', transition.targetName);
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
