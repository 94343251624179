import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  isLessThan14Days: Ember.computed('model.email_blacklist.created_ts', function() {
    var created_ts = moment.unix(this.get('model.email_blacklist.created_ts'));
    var now_ts = new moment().endOf('day').unix();
    created_ts = created_ts.endOf('day').add('14', 'days').unix();
    return created_ts >= now_ts;
  }),

  actions: {
    deleteBlackList: function() {
      var self = this;
      var model = this.get('model');
      var session = this.get('session');
      var message = this.get('isLessThan14Days') ? 'Email addresses remain on our email providers blacklist for 14 days. Removing it here will only remove it from the local API blacklist. Future emails may still not be delivered until this email address has been removed from our email service provider.' : 'Are you sure you want to remove this email from the blacklist?';
      pretty_confirm(message, function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);

        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + 'admin/emailblacklists/' + model.get('email_blacklist.id'),
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function() {
          noty({
            text: 'Blacklist removal successful!'
          });
          self.set('isLoading', false);
          self.get('parent').send('refreshData');
        }, function() {
          noty({
            text: 'Error removing email from blacklist!',
            type: 'error'
          });
          self.set('isLoading', false);
        });


      });
    }
  },
});
