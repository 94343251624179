import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  routing: Ember.inject.service('-routing'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  showMore: false,

  isLocationsGear: Ember.computed('isLocationsProducts', 'isLocationsUsers', 'isLocationsTags', 'isLocationsBillingstatements', 'isLocationsTerminals', 'isLocationsVisibilityGroups', 'isLocationsFileCategories', 'isLocationsNotificationAlerts', 'isLocationsPostbackConfigs', 'isLocationsPostbackLogs', 'isLocationsNotificationTemplate', 'isLocationsHelpPageLocations', 'isLocationsQuickInvoiceSettings', 'isLocationsHPPSettings', 'isLocationsChangeRecurringDepositAccount', 'isLocationsChangeEmailsTrxReceipts', 'isLocationsChangeCAUSubscriptions', 'isLocationsDeviceTerms', 'isLocationsBillingAccounts', function() {
    return this.get('isLocationsProducts') || this.get('isLocationsUsers') || this.get('isLocationsTags') || this.get('isLocationsBillingstatements') || this.get('isLocationsTerminals') || this.get('isLocationsVisibilityGroups') || this.get('isLocationsFileCategories') || this.get('isLocationsNotificationAlerts') || this.get('isLocationsPostbackConfigs') || this.get('isLocationsPostbackLogs') || this.get('isLocationsNotificationTemplate') || this.get('isLocationsHelpPageLocations') || this.get('isLocationsQuickInvoiceSettings') || this.get('isLocationsHPPSettings') || this.get('isLocationsChangeRecurringDepositAccount') || this.get('isLocationsChangeEmailsTrxReceipts') || this.get('isLocationsChangeCAUSubscriptions') || this.get('isLocationsDeviceTerms') || this.get('isLocationsBillingAccounts');
  }),
  isLocationsReports: Ember.computed('isLocationsTransactionSummaryCC', 'isLocationsTransactionSummaryByType', 'isLocationsTransactionSummaryACH', 'isLocationsUnsettledTransactions', 'isLocationsTransactionachsettlements', 'isLocationsTransactionachsettlementsummary', function() {
    return this.get('isLocationsTransactionSummaryCC') || this.get('isLocationsTransactionSummaryByType') || this.get('isLocationsTransactionSummaryACH') || this.get('isLocationsUnsettledTransactions') || this.get('isLocationsTransactionachsettlements') || this.get('isLocationsTransactionachsettlementsummary');
  }),

  currentRouteTitle: Ember.computed('isMobileSize', 'locationRoutes.[]', 'locationSettingsRoutes.[]','locationReportsRoutes', 'isLocationsDashboard', 'isLocationsView', 'isLocationsTransactions', 'isLocationsContacts', 'isLocationsQuickinvoices', 'isLocationsNotes',
  'isLocationsFiles', 'isLocationsSettle', 'isLocationsProducts', 'isLocationsUsers', 'isLocationsTags',
  'isLocationsBillingstatements', 'isLocationsTerminals', 'isLocationsVisibilityGroups', 'isLocationsFileCategories', 'isLocationsNotificationAlerts',
  'isLocationsPostbackConfigs', 'isLocationsPostbackLogs', 'isLocationsNotificationTemplate', 'isLocationsHelpPageLocations', 'isLocationsQuickInvoiceSettings', 'isLocationsHPPSettings', 'isLocationsTransactionSummaryCC',
  'isLocationsTransactionSummaryByType', 'isLocationsTransactionSummaryACH', 'isLocationsUnsettledTransactions', 'isLocationsTransactionachsettlements', 'isLocationsTransactionachsettlementsummary', 'isLocationsChangeRecurringDepositAccount', 'isLocationsChangeEmailsTrxReceipts', 'isLocationsChangeCAUSubscriptions', 'isLocationsDeviceTerms', 'isLocationsBillingAccounts', function() {
    var routes = this.get('locationRoutes');
    var routesLocationSettings = this.get('locationSettingsRoutes');
    var routesLocationSettingsMore = this.get('locationSettingsRoutesMore');
    var routesLocationReports = this.get('locationReportsRoutes');
    var title = '';
    routes.map(function(route){
      if(route.isActive){
        title = route.title;
      }
    });
    routesLocationSettings.map(function(route){
      if(route.isActive){
        console.log('route.title', route.title);
        title = route.title;
      }
    });
    routesLocationSettingsMore.map(function(route){
      if(route.isActive){
        title = route.title;
      }
    });
    routesLocationReports.map(function(route){
      if(route.isActive){
        title = route.title;
      }
    });
    return title;
  }),

  locationRoutes: Ember.computed('location.{hasDrChrono,hasQuickInvoice}', 'isMobileSize', 'sessionService.session.authenticated.token', 'isLocationsDashboard', 'isLocationsView', 'isLocationsTransactions', 'isLocationsContacts', 'isLocationsQuickinvoices', 'isLocationsNotes', 'isLocationsFiles', 'isLocationsSettle', 'isLocationsAccounts', 'isLocationsRecurrings', 'isLocationsAddOns', function() {
    var self = this;
    var session = this.get('session');
    var location = this.get('location');
    var routes = [];
    if(session.get('authenticated.resources.v2-reports-get')){
      let routeName = 'locations.dashboard';
      routes.pushObject({
        title: 'Dashboard',
        isActive: self.get('isLocationsDashboard') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-locations-get')){
      let routeName = 'locations.view';
      routes.pushObject({
        title: 'General Info',
        isActive: self.get('isLocationsView') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-contacts-get')){
      let routeName = 'locations.contacts-index';
      routes.pushObject({
        title: 'Contacts',
        isActive: self.get('isLocationsContacts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-transactions-get')){
      let routeName = 'locations.transactions';
      routes.pushObject({
        title: 'Transactions',
        isActive: self.get('isLocationsTransactions') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-accountvaults-get')){
      let routeName = 'locations.accounts-index';
      routes.pushObject({
        title: 'Account Vaults',
        isActive: self.get('isLocationsAccounts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-recurrings-get')){
      let routeName = 'locations.recurrings-index';
      routes.pushObject({
        title: 'Recurring Billings',
        isActive: self.get('isLocationsRecurrings') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-quickinvoices-get') && location.get('hasQuickInvoice')){
      let routeName = 'locations.quickinvoices-index';
      routes.pushObject({
        title: 'Quick Invoices',
        isActive: self.get('isLocationsQuickinvoices') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-notes-get')){
      let routeName = 'locations.notes';
      routes.pushObject({
        title: 'Notes',
        isActive: self.get('isLocationsNotes') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-files-get')){
      let routeName = 'locations.files';
      routes.pushObject({
        title: 'Files',
        isActive: self.get('isLocationsFiles') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-locations-get') && location.get('hasDrChrono')){
      let routeName = 'locations.partnerdrchrono';
      routes.pushObject({
        title: 'Dr. Chrono',
        isActive: self.get('isLocationsPartnerDrChrono'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-transactionbatches-get')){
      let routeName = 'locations.settle';
      routes.pushObject({
        title: 'Batch',
        isActive: self.get('isLocationsSettle') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-domains-get')){
         let routeName = 'locations.addons';
      routes.pushObject({
        title: 'Add-Ons',
        isActive: self.get('isLocationsAddOns') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),

  locationSettingsRoutes: Ember.computed('isMobileSize', 'sessionService.session.authenticated.token', 'isLocationsBillingAccounts', 'isLocationsProducts', 'isLocationsUsers', 'isLocationsTags', 'isLocationsBillingstatements', 'isLocationsVisibilityGroups', 'isLocationsFileCategories', 'isLocationsNotificationAlerts', 'isLocationsQuickInvoiceSettings', 'isLocationsHPPSettings', function() {
    var self = this;
    var session = this.get('session');
    var routes = [];
    if(session.get('authenticated.resources.v2-locationbillingaccounts-get')){
      routes.pushObject({
        title: 'Billing Accounts',
        isActive: self.get('isLocationsBillingAccounts'),
        routeName: 'locations.locationbillingaccount-index'
      });
    }
    if(session.get('authenticated.resources.v2-billingstatements-get')){
      let routeName = 'locations.billingstatements';
      routes.pushObject({
        title: 'Billing Statements',
        isActive: self.get('isLocationsBillingstatements') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-deviceterms-get')){
      let routeName = 'locations.deviceterms-index';
      routes.pushObject({
        title: 'Device Terms',
        isActive: self.get('isLocationsDeviceTerms'),
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-filecategories-get')){
      let routeName = 'locations.filecategories';
      routes.pushObject({
        title: 'File Categories',
        isActive: self.get('isLocationsFileCategories') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-hostedpaymentpages-get') && !this.get('isMobileSize')){
      let routeName = 'locations.hpp-index';
      routes.pushObject({
        title: 'Hosted Payment Pages',
        isActive: self.get('isLocationsHPPSettings') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-notificationalerts-get')){
      let routeName = 'locations.notificationalerts';
      routes.pushObject({
        title: 'Notification Alerts',
        isActive: self.get('isLocationsNotificationAlerts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-quickinvoicesettings-get')){
      let routeName = 'locations.quickinvoicesettings';
      routes.pushObject({
        title: 'Quick Invoice Settings',
        isActive: self.get('isLocationsQuickInvoiceSettings') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-products-get')){
      let routeName = 'locations.products';
      routes.pushObject({
        title: 'Services',
        isActive: self.get('isLocationsProducts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-tags-get')){
      let routeName = 'locations.tags';
      routes.pushObject({
        title: 'Tags',
        isActive: self.get('isLocationsTags') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-users-get')){
      let routeName = 'locations.users-index';
      routes.pushObject({
        title: 'Users',
        isActive: self.get('isLocationsUsers') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-visibilitygroups-get')){
      let routeName = 'locations.visibilitygroups';
      routes.pushObject({
        title: 'Visibility Groups',
        isActive: self.get('isLocationsVisibilityGroups') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),

  locationSettingsRoutesMore: Ember.computed('isMobileSize', 'isLocationsHelpPageLocations', 'isLocationsNotificationTemplate', 'isLocationsPostbackConfigs', 'isLocationsPostbackLogs', 'isLocationsTerminals', 'isLocationsChangeRecurringDepositAccount', 'isLocationsChangeEmailsTrxReceipts', 'isLocationsChangeCAUSubscriptions', function() {
    var self = this;
    var session = this.get('session');
    var routes = [];
    if(session.get('authenticated.resources.v2-locations-put')){
      let routeName = 'locations.settings-changeemailstrxreceipts';
      routes.pushObject({
        title: 'Change All Emails Transactions Receipts',
        isActive: self.get('isLocationsChangeEmailsTrxReceipts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-accountvaultupdates-post') && this.get('cau_producttransaction_options')){
      let routeName = 'locations.settings-changecausubscriptions';
      routes.pushObject({
        title: 'Change Credit Card Automatic Update Service',
        isActive: self.get('isLocationsChangeCAUSubscriptions') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-recurrings-changeproducttransactions-post') && this.get('show_change_recurrings')){
      let routeName = 'locations.settings-changerecurringdepositaccount';
      routes.pushObject({
        title: 'Change Recurring Billings Deposit Account <small>(CC Only)</small>',
        isActive: self.get('isLocationsChangeRecurringDepositAccount') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-helppages-get')){
      let routeName = 'locations.helppages-index';
      routes.pushObject({
        title: 'Help Pages',
        isActive: self.get('isLocationsHelpPageLocations') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-notificationtemplates-get')){
      let routeName = 'locations.notificationtemplates';
      routes.pushObject({
        title: 'Notification Templates',
        isActive: self.get('isLocationsNotificationTemplate') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-postbackconfigs-get')){
      let routeName = 'locations.postbackconfigs-index';
      routes.pushObject({
        title: 'Postback Configs',
        isActive: self.get('isLocationsPostbackConfigs') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-postbacklogs-get')){
      let routeName = 'locations.postbacklogs';
      routes.pushObject({
        title: 'Postback Logs',
        isActive: self.get('isLocationsPostbackLogs') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-terminals-get')){
      let routeName = 'locations.terminals';
      routes.pushObject({
        title: 'Terminals',
        isActive: self.get('isLocationsTerminals') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),

  locationReportsRoutes: Ember.computed('isMobileSize', 'sessionService.session.authenticated.token', 'isLocationsTransactionSummaryCC',
  'isLocationsTransactionSummaryByType', 'isLocationsTransactionSummaryACH', 'isLocationsUnsettledTransactions', 'isLocationsTransactionachsettlements', 'isLocationsTransactionachsettlementsummary', function() {
    var self = this;
    var session = this.get('session');
    var location = this.get('location');
    var routes = [];
    if(session.get('authenticated.resources.v2-transactionachsettlements-get')){
      let routeName = 'locations.reports-ach-transactionsettlement';
      routes.pushObject({
        title: 'ACH Transactions Settlements',
        isActive: self.get('isLocationsTransactionachsettlements') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-transactionachsettlementsummaries-get')){
      let routeName = 'locations.reports-ach-transactionsettlementsummary';
      routes.pushObject({
        title: 'ACH Transactions Settlements Summary',
        isActive: self.get('isLocationsTransactionachsettlementsummary') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(location.get('hasProductACH')){
      let routeName = 'locations.reports-ach-transactionsummary';
      routes.pushObject({
        title: 'ACH Transactions Summary',
        isActive: self.get('isLocationsTransactionSummaryACH') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(location.get('hasProductCC')){
      let routeName = 'locations.reports-cc-transactionsummary';
      routes.pushObject({
        title: 'CC Transactions Summary',
        isActive: self.get('isLocationsTransactionSummaryCC') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(location.get('hasProductCC')){
      let routeName = 'locations.reports-cc-transactionsummarybytype';
      routes.pushObject({
        title: 'CC Transactions Summary by Type',
        isActive: self.get('isLocationsTransactionSummaryByType') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-reportingtransactions-get') && location.get('hasProductCC')){
      let routeName = 'locations.reports-cc-unsettledtransactions';
      routes.pushObject({
        title: 'CC Unsettled Transactions',
        isActive: self.get('isLocationsUnsettledTransactions') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),

  show_change_recurrings: Ember.computed('location.product_transactions.[]', 'location.id', function() {
    var product_transactions = this.get('location.product_transactions');
    if(product_transactions && product_transactions.get('length')) {
      var non_lodging_cc_products = product_transactions.sortBy('title').filter(function (pt) {
        return pt.get('payment_method') === 'cc' && pt.get('industry_type') !== 'lodging';
      });
      return non_lodging_cc_products.get('length') > 1;
    } else {
      return false;
    }
  }),
  cau_producttransaction_options: Ember.computed('location.product_transactions.[]', 'location.id', function() {
    var product_transactions = this.get('location.product_transactions');
    if(product_transactions && product_transactions.get('length')) {
      return product_transactions.sortBy('title').filter(function(pt) {
        return pt.get('payment_method') === 'cc' && pt.get('cau_account_number');
      });
    } else {
      return [];
    }
  }),

  didInsertElement: function() {
    jQuery(document).on('click', '.dropdown-header', function(e) {
      e.stopPropagation();
    });
  },
  willDestroyElement: function() {
    jQuery(document).off('click', '.dropdown-header', function(e) {
      e.stopPropagation();
    });
  },

  // Functions

  getId: function(routeName) {
    if(this.get('routing.currentRouteName')) {
      return 'location_' + routeName.replace('locations.', '').replace('.', '-') + '_tab';
    }
  },

  // Actions
  actions: {
    toggleShowMore: function() {
      this.toggleProperty('showMore');
    }
  },
});
