import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  columns: Ember.computed('isMobileSize', 'isRouteAdmin', 'isRouteLocations', function() {
    var self = this;
    var session = this.get('session');
    var router = this.get('router');
    var columns = {
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'action': function(deviceterm) {
          self.send('view', deviceterm);
        },
        'actionId': 'deviceterm_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      location_id: {
        'name': 'location.title',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(deviceterm) {
          router.transitionTo('locations.view', deviceterm.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
        'filter': {
          type: 'typeahead',
          style: 'min-width: 200px',
          modelName: 'location',
          multi: true,
          searchBy: 'name',
          sortBy: 'name',
          fields: 'id,name',
          field: 'location_id'
        }
      },
      terminal_id: {
        'name': 'terminal.title',
        'title': 'Terminal',
        'type': 'string',
        'sortable': true,
        'action': function(deviceterm) {
          if(self.get('isRouteAdmin')) {
            router.transitionTo('admin.terminals.view', deviceterm.get('terminal_id'));
          } else {
            router.transitionTo('locations.terminals-view', deviceterm.get('location_id'), deviceterm.get('terminal_id'));
          }
        },
        'actionpriv': self.get('isRouteAdmin') ? 'v2admin-terminals-get' : 'v2-terminals-get',
        'filter': {
          type: self.get('isRouteAdmin') ? 'typeahead' : 'dropdown',
          style: 'min-width: 200px',
          modelName: self.get('isRouteAdmin') && self.get('session.authenticated.resources.v2admin-terminals-get') ? 'admin_terminal' : 'terminal',
          multi: true,
          showAllSelect: true,
          searchBy: 'title',
          sortBy: 'title',
          fields: 'id,title',
          field: 'terminal_id',
          items: self.get('isRouteAdmin') ? null : 'terminals'
        }
      },
      require_signature: {
        'name': 'require_signature',
        'title': 'Require Signature',
        'type': 'check',
        'sortable': true,
        'align': 'text-center',
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: true
        }
      },
      reason_code_id: {
        'name': 'reason_code.title',
        'title': 'Reason Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text',
          field: 'reason_code_id'
        }
      },
      device_term_api_id: {
        'name': 'device_term_api_id',
        'title': 'Device Term<br>API Id',
        'type': 'text',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(deviceterm) {
          self.send('view', deviceterm);
        },
        'actionId': 'deviceterm_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'created_ts', 'location_id', 'terminal_id', 'require_signature', 'reason_code_id', 'device_term_api_id', 'view'];
    var listOfMobileCols = ['id', 'created_ts', 'location_id', 'terminal_id', 'require_signature', 'reason_code_id', 'device_term_api_id', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-deviceterms-get')) {
        colsArray.removeObject(columns.view);
      }
      if (context.get('isRouteLocations')) {
        colsArray.removeObject(columns.location_id);
      }
    }
  }),
  isRouteAdmin: Ember.computed('route', function() {
    return this.get('route') === 'admin.deviceterms.index';
  }),
  isRouteLocations: Ember.computed('route', function() {
    return this.get('route') === 'locations.deviceterms-index';
  }),

  // Functions

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    view: function(deviceterm) {
      var router = this.get('router');
      if (this.get('isRouteAdmin')) {
        router.transitionTo('admin.deviceterms.view', deviceterm.get('id'));
      } else if (this.get('isRouteLocations')) {
        var location = this.get('location');
        router.transitionTo('locations.deviceterms-view', location.get('id'), deviceterm.get('id'));
      } else {
        return;
      }
    }
  },

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  }
});
