import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  attempt_interval: DS.attr('number'),
  basic_auth_username: DS.attr('string'),
  basic_auth_password: DS.attr('string'),
  expands: DS.attr('string'),
  format: DS.attr('string'),
  is_active: DS.attr('boolnum'),
  location_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  number_of_attempts: DS.attr('number'),
  on_create: DS.attr('boolnum'),
  on_delete: DS.attr('boolnum'),
  on_update: DS.attr('boolnum'),
  resource: DS.attr('string'),
  url: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  alert_type: DS.belongsTo('alerttype', {
    async: true
  })
});
