import Ember from 'ember';
import jQuery from 'jquery';

var get = Ember.get;

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  addlogapiresponsebodytsController: Ember.inject.controller('components/modals/addlogapiresponsebodyts'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('user.errors.messages', function() {
    this.set('errorMessages', this.get('user.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminUsers: true,
  isUsersView: true,

  // Set controller params
  isLoading: false,
  isEditing: false,
  isLoadingKey: false,
  MINIMUM_SUPPORT_LEVEL: 300,

  userEntryPages: Ember.computed(function() {
    return this.get('applicationData.userEntryPages');
  }),
  contactEntryPages: Ember.computed(function() {
    return this.get('applicationData.contactEntryPages');
  }),
  pageSizes: Ember.computed(function() {
    return this.get('applicationData.pageSizes');
  }),
  processMethods: Ember.computed(function() {
    return this.get('applicationData.processMethods');
  }),
  reportExportTypes: Ember.computed(function() {
    return this.get('applicationData.exportFormats');
  }),
  userTypes: Ember.computed(function() {
    return this.get('applicationData.userTypes');
  }),

  default_terminal: Ember.computed('user.ui_prefs.default_terminal', function() {
    var terminals = this.get('terminals');
    var default_terminal = this.get('user.ui_prefs.default_terminal');
    var defaultTerm = terminals.filter(function(item) {
      return item.id === default_terminal;
    });
    if (get(defaultTerm, 'length') === 1) {
      return get(get(defaultTerm, 'firstObject'), 'title');
    }
    return '';
  }),
  entry_page: Ember.computed('user.ui_prefs.entry_page', function() {
    var entryPages = this.get('entryPages');
    var entryPage = this.get('user.ui_prefs.entry_page');
    var pref = entryPages.filter(function(item) {
      return item.id === entryPage;
    });
    if (get(pref, 'length') === 1) {
      return get(get(pref, 'firstObject'), 'title');
    }
    return '';
  }),
  entryPages: Ember.computed('user.user_type_id', function() {
    var isContact = String(this.get('user.user_type_id')) === '100';
    if (isContact) {
      return this.get('contactEntryPages');
    } else {
      return this.get('userEntryPages');
    }
  }),
  ui_prefs_page_size: Ember.computed('user.ui_prefs.page_size', function() {
    var pageSizes = this.get('pageSizes');
    var pageSize = this.get('user.ui_prefs.page_size');
    var page = pageSizes.filter(function(item) {
      return parseInt(item.id) === parseInt(pageSize);
    });
    if (get(page, 'length') === 1) {
      return get(get(page, 'firstObject'), 'title');
    }
    return '';
  }),
  process_method: Ember.computed('user.ui_prefs.process_method', function() {
    var processMethods = this.get('processMethods');
    var process_method = this.get('user.ui_prefs.process_method');
    var processMeth = processMethods.filter(function(item) {
      return item.id === process_method;
    });
    if (get(processMeth, 'length') === 1) {
      return get(get(processMeth, 'firstObject'), 'title');
    }
    return '';
  }),
  report_export_type: Ember.computed('user.ui_prefs.report_export_type', function() {
    var reportExportTypes = this.get('reportExportTypes');
    var exportType = this.get('user.ui_prefs.report_export_type');
    var type = reportExportTypes.filter(function(item) {
      return item.id === exportType;
    });
    if (get(type, 'length') === 1) {
      return get(get(type, 'firstObject'), 'title');
    }
    return '';
  }),
  showTerminals: Ember.computed('user.ui_prefs.process_method', function() {
    return this.get('user.ui_prefs.process_method') === 'physical_terminal';
  }),
  userType: Ember.computed('user.user_type_id', function() {
    return this.get('store').query('usertype', this.get('user.user_type_id'));
  }),

  isCurrentLoggedInUser: Ember.computed('session.authenticated.user_id', 'user.id', function() {
    var sessionUserId = this.get('session.authenticated.user_id');
    var userId = this.get('user.id');
    return sessionUserId === userId;
  }),
  showAddLogAPITime: Ember.computed('session.authenticated.resources.v2admin-users-post-addlogseconds', 'isEditing', 'user.{isActive,id}', function() {
    return this.get('session.authenticated.resources.v2admin-users-post-addlogseconds') && this.get('isEditing') && this.get('user.isActive');
  }),
  isUserTypeIdAndAuthenticatedResource: Ember.computed('session.authenticated.resources.v2-users-put','session.authenticated.user.user_type_id', function() {
    return this.get('session.authenticated.resources.v2-users-put') && this.get('session.authenticated.user.user_type_id') <= this.MINIMUM_SUPPORT_LEVEL;
  }),
  changeStatus: Ember.observer('userIsActive', function() {
    // Bail if status_id is the same as isActive. Nothing to save.
    if (Boolean(this.get('userIsActive')) === Boolean(this.get('user.status_id'))) {
      return;
    }
    // Bail if we are already loading
    if (this.get('isLoading')) {
      return;
    }
    jQuery('.onoffswitch-checkbox').attr('disabled', true);
    this.set('isLoading', true);

    var self = this;
    var user = this.get('user');

    user.set('status_id', (this.get('userIsActive') ? 1 : 0));

    user.save().then(function() {
      noty({
        text: 'User status changed successfully!'
      });
      jQuery('.onoffswitch-checkbox').attr('disabled', false);
      self.set('isLoading', false);
      self.set('isEditing', false);
      self.transitionToRoute('/admin/users');
    }, function() {
      window.scrollTo(0, 0);
      noty({
        text: 'Error changing user status!',
        type: 'error'
      });
      jQuery('.onoffswitch-checkbox').attr('disabled', false);
      self.set('isLoading', false);
    });
  }),

  // Actions
  actions: {
    addTimeToLogApiResponseBody: function() {
      var addlogapiresponsebodytsController = this.get('addlogapiresponsebodytsController');
      addlogapiresponsebodytsController.set('context', null);
      addlogapiresponsebodytsController.set('parent', null);
      addlogapiresponsebodytsController.set('user', null);
      addlogapiresponsebodytsController.set('log_api_response_body_ts', null);

      addlogapiresponsebodytsController.set('context', 'admin');
      addlogapiresponsebodytsController.set('parent', this);
      addlogapiresponsebodytsController.set('user', this.get('user'));
      addlogapiresponsebodytsController.set('log_api_response_body_ts', '24');
      this.send('openModal', 'components/modals/addlogapiresponsebodyts');
    },
    edit: function() {
      this.set('isEditing', true);
    },
    generateKeyUrl: function() {
      var self = this;
      pretty_confirm('This will provide a new, one time use, url that can be used to generate a new user-api-key.<br><br>Generating a new key form the provided url will invalidate the old key.<br><br>Are you sure you want to proceed?', function() {
        if (self.get('isLoadingKey')) {
          return;
        }
        self.set('isLoadingKey', true);
        var session = self.get('session');
        var user = self.get('user');

        jQuery.ajax({
          url: window.ENV.APP.FULL_URL + '/users/' + user.get('id') + '/generatekeyurl',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.ENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          user.set('keyUrl', data.user._links.getuserkey.href);
          self.set('user', user);
          noty({
            text: 'Key generated successfully!',
            timeout: '5000'
          });
          self.set('isLoadingKey', false);
        }, function() {
          noty({
            text: 'Error generating new key.',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoadingKey', false);
        });
      });
    },
    generateNewKey: function() {
      var self = this;

      pretty_confirm('This will provide a new key that will only be shown one time.<br><br>Generating a new key will invalidate the old key.<br><br>Are you sure you want to proceed?', function() {
        if (self.get('isLoadingKey')) {
          return;
        }
        self.set('isLoadingKey', true);
        var session = self.get('session');
        var user = self.get('user');

        jQuery.ajax({
          url: window.ENV.APP.FULL_URL + '/users/' + user.get('id') + '/generateuserapikey',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.ENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          user.set('keyUrl', data.user.user_api_key);
          self.set('user', user);
          noty({
            text: 'Key generated successfully!',
            timeout: '5000'
          });
          self.set('isLoadingKey', false);
        }, function() {
          noty({
            text: 'Error generating new key.',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoadingKey', false);
        });
      });
    },
    reset: function() {
      var user = this.get('user');
      if (user.get('hasDirtyAttributes')) {
        user.rollbackAttributes();
      }
      this.set('isEditing', false);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var user = this.get('user');

      user.save().then(function() {
        noty({
          text: 'User updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.send('refreshData');
      }, function() {
        self.set('isLoading', false);
      });
    },
    updatePrimaryLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('location', {
        relationship: 'all',
        name: data,
        page_size: 20
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
  }
});
