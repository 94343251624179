import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  beforeModel: function() {
    var controller = this.controllerFor('contacts.dashboard');
    controller.set('accounts', null);
    controller.set('transactions', null);
    controller.set('recurrings', null);
  },
  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: models.contact.get('location_id'),
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfos = data;
        }),
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');

    var product_transactions = [];
    var prods = locationinfo.get('product_transactions');
    if (prods) {
      prods.forEach(function(product_transaction) {
        product_transactions.push({
          'id': product_transaction.get('id'),
          'title': product_transaction.get('title'),
          'processor': product_transaction.get('processor'),
          'receipt_add_recurring_above_signature': product_transaction.get('receipt_add_recurring_above_signature')
        });
      });
    }
    controller.set('product_transactions', product_transactions);
    controller.set('contact', models.contact);
    controller.set('locationinfos', models.locationinfos);
    controller.set('locationinfo', locationinfo);
    Ember.run.once(function() { // Make dashboard calls async, when they come back, they come back no hurry
      controller.send('updateAccounts');
      controller.send('updateTransactions');
      controller.send('updateRecurrings');
      if (locationinfo.hasQuickInvoice) {
        controller.send('updateQuickinvoices');
      }
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Overview',
      route_name: 'contacts.dashboard',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageSubtitle', {
      title: 'Charge Contact',
      link: 'contacts.charge',
      record: models.contact.id,
      resource: this.get('session.authenticated.resources.v2-transactions-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name')
    }, {
      title: 'Overview',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var target = transition.targetName;
      if (target === 'contacts.recurrings-view' || target === 'contacts.recurrings-add' || target === 'contacts.accounts-view' || target === 'contacts.accounts-add') {
        var controller = this.controllerFor(target);
        controller.set('returnRoute', 'contacts.dashboard');
      }
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
