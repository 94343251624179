import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_ts_from: DS.attr('ts'),
  created_ts_to: DS.attr('ts'),
  event_date_ts: DS.attr('ts'),
  location_id: DS.attr('string'),
  payment_method: DS.attr('string'),
  status_id: DS.attr('string'),
  transaction_id: DS.attr('string'),

  // Defined model relationships
  location: DS.belongsTo('location'),
  status: DS.belongsTo('status', {
    async: true
  }),
  transaction: DS.belongsTo('transaction')
});
