import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  terminalmanufacturerIdTypes: Ember.computed(function() {
    return this.get('applicationData.terminalmanufacturerIdTypes');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: 'title',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'created_user_id',
    'id',
    'idtype',
    'title'
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(terminalmanufacturer) {
          self.send('view', terminalmanufacturer);
        },
        'actionId': 'terminalmanufacturer_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      idtype: {
        'name': 'idtype',
        'title': 'Id Type',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'terminalmanufacturerIdTypes'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(terminalmanufacturer) {
          self.send('view', terminalmanufacturer);
        },
        'actionId': 'terminalmanufacturer_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'idtype', 'created_ts', 'view'];
    var listOfMobileCols = ['title', 'idtype', 'created_ts', 'view'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-terminalmanufacturers-get')) {
        colsArray.removeObject(columns.view);
      }
    }
  }),

  // Actions
  actions: {
    view: function(terminalmanufacturer) {
      this.transitionToRoute('admin.terminalmanufacturers.crud', terminalmanufacturer.get('id'));
    },
  }
});
