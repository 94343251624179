import Ember from 'ember';

export default Ember.Component.extend({

  classNameBindings: ['isDisplay:format-json-border'],

  isDisplay: Ember.computed('noStringify', 'addBorder', function() {
    return this.get('noStringify') || this.get('addBorder');
  }),

  jsonData: Ember.computed('json', function() {
    var json = this.get('json');
    var prettyJSON = function(json) {
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
        var cls = 'json-number-color';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'json-key-color';
          } else {
            cls = 'json-string-color';
          }
        } else if (/true|false/.test(match)) {
          cls = 'json-boolean-color';
        } else if (/null/.test(match)) {
          cls = 'json-null-color';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
    };
    if (!json) {
      return '';
    }
    if (this.get('noStringify')) {
      var parsedJSON = json;
      if (typeof json === 'string') {
        parsedJSON = JSON.parse(json);
      }
      return json ? Ember.String.htmlSafe(prettyJSON(JSON.stringify(parsedJSON, null, 4))) : '';
    } else {
      return json ? Ember.String.htmlSafe(prettyJSON(JSON.stringify(json, null, 4))) : '';
    }
  }),

});
