import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLoading: false,
  isEditing: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('terminalcvm.errors.messages', function() {
    this.set('errorMessages', this.get('terminalcvm.errors.messages'));
  }),

  // Actions
  actions: {
    back: function() {
      this.transitionToRoute('admin.terminalcvms.index');
    },
    cancel: function() {
      var terminalcvm = this.get('terminalcvm');
      terminalcvm.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function() {
      var self = this;
      var terminalcvm = this.get('terminalcvm');
      pretty_confirm('Are you sure you want to delete this Terminal CVM?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        terminalcvm.deleteRecord();
        terminalcvm.save().then(function() {
          terminalcvm.unloadRecord();
          noty({
            text: 'Terminal CVM deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.terminalcvms.index');
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          terminalcvm.rollbackAttributes();
          noty({
            text: 'Error deleting Terminal CVM!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var terminalcvm = this.get('terminalcvm');

      terminalcvm.save().then(function() {
        noty({
          text: 'Terminal CVM saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    }
  }
});
