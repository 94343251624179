import DS from "ember-data";
import Application from "./application";
import Ember from "ember";

export default Application.extend({
  ach_product_transaction_id: DS.attr("string"),
  active: DS.attr("boolean"),
  allow_overpayment: DS.attr("boolnum"),
  allow_partial_pay: DS.attr("boolnum"),
  attach_files_to_email: DS.attr("boolnum"),
  amount_due: DS.attr("string"),
  cc_product_transaction_id: DS.attr("string"),
  contact_id: DS.attr("string"),
  created_ts: DS.attr("ts"),
  created_user_id: DS.attr("string"),
  due_date: DS.attr("sqldate"),
  email: DS.attr("string"),
  email_blacklist: DS.attr("array"),
  expire_date: DS.attr("sqldate"),
  files: DS.attr("array"),
  invoice_number: DS.attr("string"),
  item_footer: DS.attr("string"),
  item_header: DS.attr("string"),
  item_list: DS.attr("array"),
  location_id: DS.attr("string"),
  note: DS.attr("string"),
  notification_days_after_due_date: DS.attr("number"),
  notification_days_before_due_date: DS.attr("number"),
  notification_email: DS.attr("string"),
  notification_on_due_date: DS.attr("boolnum"),
  payment_status_id: DS.attr("number"),
  remaining_balance: DS.attr("string"),
  send_email: DS.attr("boolnum"),
  single_payment_max_amount: DS.attr("number"),
  single_payment_min_amount: DS.attr("number"),
  status_id: DS.attr("number"),
  title: DS.attr("string"),
  transaction_id: DS.attr("string"),

  // Computed Properties
  cantPay: Ember.computed(
    "isExpired",
    "active",
    "isPaid",
    "isNoActiveProduct",
    "ach_product_transaction_id",
    "cc_product_transaction_id",
    "allow_overpayment",
    function () {
      if (
        this.get("isExpired") ||
        this.get("active") === 0 ||
        this.get("isNoActiveProduct") ||
        (this.get("isPaid") && !this.get("allow_overpayment"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  ),
  isExpired: Ember.computed("expire_date", function () {
    if (this.get("expire_date")) {
      var expire_date = moment(this.get("expire_date"), "MM-DD-YYYY")
        .endOf("day")
        .valueOf();
      var current_date = moment().endOf("day").valueOf();
      return current_date >= expire_date;
    } else {
      return false;
    }
  }),
  isPaid: Ember.computed("payment_status_id", function () {
    return this.get("payment_status_id") === 2;
  }),
  isNoActiveProduct: Ember.computed(
    "ach_product_transaction.{id,active,quick_invoice_allow}",
    "cc_product_transaction.{id,active,quick_invoice_allow}",
    function () {
      var ach_prod_id = this.get("ach_product_transaction.id");
      var ach_prod = this.get("ach_product_transaction");
      var cc_prod_id = this.get("cc_product_transaction.id");
      var cc_prod = this.get("cc_product_transaction");
      if (!ach_prod_id && !cc_prod_id) {
        return true;
      } else if (ach_prod_id && !cc_prod_id) {
        return !ach_prod.get("active") || !ach_prod.get("quick_invoice_allow");
      } else if (cc_prod_id && !ach_prod_id) {
        return !cc_prod.get("active") || !cc_prod.get("quick_invoice_allow");
      } else {
        return (
          ach_prod_id &&
          (!ach_prod.get("active") || !ach_prod.get("quick_invoice_allow")) &&
          cc_prod_id &&
          (!cc_prod.get("active") || !cc_prod.get("quick_invoice_allow"))
        );
      }
    }
  ),
  notification_email_display: Ember.computed("notification_email", function () {
    var notification_email = this.get("notification_email");
    return notification_email ? notification_email.replace(/\,/g, ", ") : "";
  }),
  payment_status_title: Ember.computed("payment_status_id", function () {
    var status = this.get("payment_status_id");
    if (status === 1) {
      return "Unpaid";
    } else if (status === 2) {
      return "Paid";
    } else if (status === 3) {
      return "Partially Paid";
    } else {
      return "";
    }
  }),
  select: Ember.computed({
    get() {
      if (this._select) {
        return this._select;
      }

      const isInvoiceClosed = this.get("status_id") === 0;
      return !isInvoiceClosed; 
    },

    set(key, value) {
      return this._select = value;
    }
  }),
  amount: Ember.computed({
    get() {
      if (this._amount) {
        return this._amount;
      }

      return this.get("remaining_balance");
    },

    set(key, value) {
      return this._amount = value;
    }
  }),
  status_title: Ember.computed("status_id", function () {
    var status = this.get("status_id");
    if (status === 0) {
      return "Closed";
    } else if (status === 1) {
      return "Open";
    } else {
      return "";
    }
  }),
  tags_display: Ember.computed("tags.[]", function () {
    var tags = this.get("tags");
    var tag_csv = "";
    tags.forEach(function (tag, index, tags) {
      tag_csv += tag.get("title");
      if (index < tags.get("length") - 1) {
        tag_csv += ", ";
      }
    });
    return tag_csv.replace(/\,$/, "");
  }),
  titleWithInvoiceNumber: Ember.computed(
    "title",
    "invoice_number",
    function () {
      var invoice_number = this.get("invoice_number");
      return invoice_number
        ? (this.get("title") + " - " + invoice_number).trim()
        : this.get("title").trim();
    }
  ),

  // Model Relations
  ach_product_transaction: DS.belongsTo("producttransaction"),
  changelogs: DS.hasMany("changelog"),
  contact: DS.belongsTo("contact"),
  cc_product_transaction: DS.belongsTo("producttransaction"),
  created_user: DS.belongsTo("user"),
  location: DS.belongsTo("location"),
  log_emails: DS.hasMany("logemail"),
  tags: DS.hasMany("tag"),
  transactions: DS.hasMany("transaction"),
});
