import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var self = this;
    var session = this.get('session');
    if (session.get('isAuthenticated')) {
      return jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/users/me?expand=helppage',
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function(data) {
        var helppage = data.user.helppage;
        if (helppage) {
          return helppage.body;
        } else {
          return returnDomainBrandingHelpText(self);
        }
      }, function() {
        return 'Help Page Error';
      });
    } else {
      return returnDomainBrandingHelpText(self);
    }

    function returnDomainBrandingHelpText(context) {
      var domain = context.get('domainBranding');
      return domain.domain.help_text || '';
    }
  },

  setupController: function(controller, model) {
    controller.set('helpText', model);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

});
