import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isSalestax: true,

  // Set controller params
  isRefreshing: true,
  location_id: null,
  page: 1,
  page_size: '15',
  sort: 'zip_code',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'country',
    'location_id',
    'rate',
    'zip_code',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(salestax) {
          self.transitionToRoute('locations.view', salestax.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get'
      },
      country: {
        'name': 'country',
        'title': 'Country',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      zip_code: {
        'name': 'zip_code',
        'title': 'Zip Code',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      rate: {
        'name': 'rate',
        'title': 'Rate',
        'type': 'string',
        'sortable': true,
        'action': function(salestax) {
          self.send('view', salestax);
        },
        'actionId': 'salestax_rate_view_link',
        'filter': {
          type: 'text'
        }
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(salestax) {
          self.send('delete', salestax);
        },
        'actionId': 'salestax_delete_link',
        'type': 'delete',
        'isActionColumn': true
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(salestax) {
          self.send('view', salestax);
        },
        'actionId': 'salestax_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['rate', 'country', 'zip_code', 'location_id', 'view', 'delete'];
    var listOfMobileCols = ['rate', 'country', 'zip_code', 'location_id', 'view', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-salestaxes-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-salestaxes-delete')) {
        colsArray.removeObject(columns.delete);
      }
      if (!session.get('authenticated.resources.v2admin-locations-get') && !session.get('authenticated.resources.v2-locations-get')) {
        colsArray.removeObject(columns.location_id);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(salestax) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Sales Tax?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        salestax.deleteRecord();
        salestax.save().then(function() {
          noty({
            text: 'Sales Tax deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          salestax.rollbackAttributes();
          noty({
            text: 'Error deleting Sales Tax!',
            type: 'error'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('admin_location', {
        name: data,
        sort: 'url',
        page_size: '10',
        relationship: 'all'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
    view: function(salestax) {
      this.transitionToRoute('admin.salestax.view', salestax.get('id'));
    },
  }
});
