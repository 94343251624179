import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.TextArea.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  editor: null,
  height: null,
  _suspendValueChange: false,

  didRender: function() {
    var id = '#' + this.get('elementId');
    var view = this;
    var toolbar = '';
    if(this.get('isMobileSize')){
      toolbar = 'forecolor bold italic underline';
    } else {
      toolbar = 'forecolor bold italic underline | alignleft aligncenter alignright | hr bullist numlist | cut copy paste | link code';
    }
    tinymce.init({
      selector: id,
      height: this.height,
      plugins: [
        'advlist autolink link image lists hr anchor pagebreak',
        'wordcount visualblocks visualchars nonbreaking',
        'table contextmenu directionality textcolor paste colorpicker code'
      ],
      toolbar: toolbar,
      block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
      menubar: false,
      statusbar: false,
      toolbar_items_size: 'small',
      relative_urls: false,
      convert_urls: false,
      remove_script_host: false,
      setup: function(ed) {
        view.set('editor', ed);
        ed.on('keyup change', function() {
          view.suspendValueChange(function() {
            view.set('value', ed.getContent());
          });
        });
      }
    });
  },
  willDestroyElement: function() {
    jQuery('.mce-close').click();
  },
  suspendValueChange: function(cb) {
    this._suspendValueChange = true;
    cb();
    this._suspendValueChange = false;
  },
  valueChanged: Ember.observer('value', function() {
    if (this._suspendValueChange) {
      return;
    }
    var content = this.get('value') || '';
    var editor = this.get('editor');
    editor.undoManager.clear();
    editor.setContent(content);
  })
});
