import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  chkTypeName: Ember.computed('chkType', function() {
    return this.get('chkType') + '_checkboxes';
  }),

  // Actions
  actions: {
    parentAction: function(data) {
      this.action(data);
    }
  },
});
