import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isAdminDeveloperCompanies: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  sort: 'title',
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'active',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'id',
    'title',
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Developer Company ID',
        'type': 'string',
        'sortable': true,
        'action': function(developercompany) {
          self.send('view', developercompany);
        },
        'actionId': 'developercompany_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      active: {
        'name': 'active',
        'title': 'Active',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(developercompany) {
          self.send('delete', developercompany);
        },
        'actionId': 'developercompany_delete_link',
        'type': 'delete',
        'isActionColumn': true
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(developercompany) {
          self.send('view', developercompany);
        },
        'actionId': 'developercompany_view_link',
        'type': 'view',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'title', 'active', 'created_ts', 'view', 'delete'];
    var listOfMobileCols = ['id', 'title', 'active', 'created_ts', 'view', 'delete'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-developercompanies-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-developercompanies-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    delete: function(developer_company) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Developer Company?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        developer_company.deleteRecord();
        developer_company.save().then(function() {
          developer_company.unloadRecord();
          noty({
            text: 'Developer Company deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(err) {
          developer_company.rollbackAttributes();
          var errorMessage = 'Error deleting Developer Company!';
          if(err.errors) {
            err.errors.forEach(function(e){
              errorMessage += '<br>' + e.detail;
            });
          }
          noty({
            text: errorMessage,
            type: 'error',
            timeout: 10000
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    },
    view: function(developercompany) {
      this.transitionToRoute('admin.developercompanies.view', developercompany.get('id'));
    }
  }
});
