import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  headerFields: null,
  csvData: null,
  results: [],
  modelFields: null,
  isLoading: false,
  errorMessages: [],

  addContact: function(importData, csvData, index, arrayLength) {
    var self = this;
    var results = this.get('results');
    if (this.get('updateExisting')) {
      importData.update_if_exists = 1;
    }
    var contact = this.get('store').createRecord('contact', importData);

    var emptyLastName = Ember.isEmpty(importData.last_name);
    var emptyContactApiId = Ember.isEmpty(importData.contact_api_id);
    var emptyLastNameMessage = 'Last Name cannot be blank.';
    var emptyContactApiIdMessage = 'Import skipped. Contact Api Id cannot be blank.';
    if (emptyLastName || (emptyContactApiId && this.get('updateExisting'))) {
      var error = [];
      if (emptyLastName) {
        error.push(emptyLastNameMessage);
      }
      if (emptyContactApiId && this.get('updateExisting')) {
        error.push(emptyContactApiIdMessage);
      }
      results.pushObject({
        name: contact.get('email') || contact.get('full_name'),
        status: 'failure',
        errors: error,
        importData: importData,
        csvData: csvData,
        contact: {}
      });
      self.doneImporting(index, arrayLength);
      return;
    }
    contact.save().then(function(result) {
      results.pushObject({
        name: result.get('email') || result.get('full_name'),
        method: 'N/A',
        status: 'success',
        errors: '',
        importData: importData,
        csvData: csvData,
        contact: result
      });
      self.doneImporting(index, arrayLength);
    }, function() {
      results.pushObject({
        name: contact.get('email') || contact.get('full_name'),
        method: 'N/A',
        status: 'failure',
        errors: contact.get('errors.messages'),
        importData: importData,
        csvData: csvData,
        contact: {}
      });
      self.doneImporting(index, arrayLength);
    });
  },
  doneImporting: function(index, arrayLength) {
    if (index === arrayLength - 1) {
      this.set('isLoading', false);
      noty({
        text: 'Done importing contacts!'
      });
      setTimeout(function() { // need to delay to make sure element is in DOM so document.getElementById finds the element.
        document.getElementById('import-results').scrollIntoView(true);
      }, 1000);
    }
  },
  actions: {
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10',
        fields: 'id,name'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
    getHeaderFromCsv: function(csvData) {
      this.set('csvData', csvData);
      var allTextLines = csvData.split(/\r\n|\n/);
      var headers = allTextLines[0].split(',');
      var headerFields = [];
      headers.forEach(function(item, index) {
        headerFields.push({
          id: item,
          title: item,
          index: index
        });
      });
      this.set('headerFields', headerFields);
    },
    importContacts: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      this.set('errorMessages', []);
      var errorMessages = this.get('errorMessages');

      this.set('results', []);
      var self = this;
      var modelFields = this.get('modelFields');
      var headerFields = this.get('headerFields');
      var exitFunction = false;
      var checkIfFieldIsBlank = function(field) {
        var fieldsToCheck = ['last_name'];
        if (self.get('updateExisting')) {
          fieldsToCheck.push('contact_api_id');
        }
        var message;
        if (field.id === 'last_name') {
          message = 'Last Name cannot be blank.';
        }
        if (field.id === 'contact_api_id') {
          message = 'Contact Api Id cannot be blank.';
        }
        if (fieldsToCheck.indexOf(field.id) >= 0) {
          if (!field.value) {
            errorMessages.pushObject(message);
            Ember.set(field, 'error', true);
            self.set('isLoading', false);
            exitFunction = true;
          } else if (field.value) {
            Ember.set(field, 'error', false);
          }
        } else {
          Ember.set(field, 'error', false);
        }
      };
      modelFields.forEach(function(field) {
        field.index = -1;
        if (field.value !== null) {
          var headerField = headerFields.filter(function(i) {
            return i.id === field.value;
          });
          if (headerField.length === 1) {
            field.index = headerField[0].index;
          }
        }
        checkIfFieldIsBlank(field);
      });
      if (exitFunction) {
        return;
      }
      var csvData = this.get('csvData');
      var allTextLines = csvData.split(/\r\n|\n/);
      var headers = allTextLines[0].split(',');
      this.set('csvHeaders', headers);
      for (var i = 1; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(',');
        if (data.length === headers.length) { // Verify no extra commas in line
          var importData = {};
          for (var j = 0; j < modelFields.length; j++) {
            if (modelFields[j].index > -1) {
              importData[modelFields[j].id] = data[modelFields[j].index];
            }
          }
          importData.header_message_type_id = '0';
          importData.location_id = this.get('location_id');
          this.addContact(importData, data, i, allTextLines.length);
        }
      }
    },
    exportTableToCSV: function() {
      var csv = '';
      var csvHeaders = this.get('csvHeaders');
      var results = this.get('results');
      var addHeaders = ['id', 'method', 'status', 'errors'];
      csvHeaders = csvHeaders.concat(addHeaders);
      csv += csvHeaders.join(',') + '\r\n';
      results.map(function(result) {
        var data = [];
        var errorString = '';
        if (result.errors.length > 0) {
          result.errors.map(function(error, index) {
            errorString += (index + 1) + ': ' + error + ' ';
          });
        }
        var addData = [result.contact.id, result.method, result.status, errorString].join(',');
        data.push(result.csvData + ',' + addData);
        csv += (data.join(',') + '\r\n');
      });

      var csvFile = new Blob([csv], {
        type: 'text/csv'
      }); // create CSV file
      var downloadLink = document.createElement('a'); // Download link
      var locationId = this.get('location_id') ? this.get('location_id') : '';
      downloadLink.download = 'location-' + locationId + '-import-results.csv'; // File name
      downloadLink.href = window.URL.createObjectURL(csvFile); // Create a link to the file
      downloadLink.style.display = 'none'; // Hide download link
      document.body.appendChild(downloadLink); // Add the link to DOM
      downloadLink.click(); // Click download link
    }
  }
});
