import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Actions
  actions: {
    sigSign: function() {
      sigPad.onSign();
    },
    sigClear: function() {
      sigPad.onClear();
    },
    sigDone: function() {
      sigPad.onDone();
      if (sigPad.numPoints() === 0) {
        noty({
          text: 'Signature empty. Please try again.',
          type: 'error',
          timeout: 5000
        });
      } else {
        GetSigImageB64(function(sigString) {
          if (sigString) {
            noty({
              text: 'Signature pad test successful',
              timeout: 5000
            });
          }
        });
      }
    }
  }
});
