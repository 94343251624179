import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin,{
	attrs: {
		created_user: {
			serialize: false,
			deserialize: 'records'
		},
		location: {
			serialize: false,
			deserialize: 'records'
		},
		product_transaction: {
			serialize: false,
			deserialize: 'records'
		},
	}
});
