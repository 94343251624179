import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('helppage.errors.messages', function() {
    this.set('errorMessages', this.get('helppage.errors.messages'));
  }),

  updateAltErrorMessages: Ember.observer('helppagelocation.errors.messages', function() {
    this.set('helppagelocationErrors', this.get('helppagelocation.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminHelpPages: true,
  isLoading: false,
  isEditing: false,

  actions: {
    delete: function() {
      var self = this;
      var helppage = this.get('helppage');
      pretty_confirm('Are you sure want to delete this Help Page?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        helppage.deleteRecord();
        helppage.save().then(function() {
          helppage.unloadRecord();
          noty({
            text: 'Help Page deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.helppages.index');
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          helppage.rollbackAttributes();
          noty({
            text: 'Error deleting Help Page!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var helppage = this.get('helppage');
      helppage.save().then(function() {
        noty({
          text: 'Help Page updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        self.set('isLoading', false);
      });
    },
    back: function() {
      this.transitionToRoute('admin.helppages.index');
    },
    cancel: function() {
      var helppage = this.get('helppage');
      helppage.rollbackAttributes();
      this.set('isEditing', false);
    },
    updateLocation: function(data) {
      var self = this;
      var locationinfos = this.get('store').query('locationinfo', {
        name: data,
        page_size: '10'
      });
      locationinfos.then(function(result) {
        self.set('locationinfos', result);
      });
    },
    addLocation: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var helppagelocation = this.get('helppagelocation');
      var helppage = this.get('helppage');

      helppagelocation.save().then(function() {
        noty({
          text: 'Help Page Location added successfully!'
        });

        helppagelocation.unloadRecord();
        var hpage = self.get('store').createRecord('helppagelocation', {
          help_page_id: helppage.get('id'),
          include_children: false,
          location_id: null
        });

        self.set('helppagelocation', hpage);
        self.init();
        self.send('refreshData');
        self.set('isLoading', false);
      }, function() {
        self.set('isLoading', false);
        noty({
          text: 'Error creating Help Page Location!',
          type: 'error'
        });
      });
    },
    deleteLocation: function(hpl) {
      var self = this;
      pretty_confirm('Are you sure want to delete the Help Page Location?', deleteHelppagelocation);

      function deleteHelppagelocation() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        hpl.deleteRecord();
        hpl.save().then(function() {
          hpl.unloadRecord();
          noty({
            text: 'Help Page Location deleted successfully!'
          });
          self.set('isLoading', false);
        }, function() {
          hpl.rollbackAttributes();
          noty({
            text: 'Error deleting Help Page Location!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
  }
});
