import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.set('loggedInUserCanEditViewedUser', this.get('usersMethods').loggedInUserCanEditViewedUser(this.get('user')));
  },

  // Computed Properties
  isAttachedToMultiLocations: Ember.computed('user.location_users', function() {
    return this.get('user.location_users.length') > 1;
  }),

  columns: Ember.computed('loggedInUserCanEditViewedUser', function() {
    var loggedInUserCanEditViewedUser = this.get('loggedInUserCanEditViewedUser');
    var self = this;
    var session = this.get('session');
    var columns = {
      locationname: {
        'name': 'location.name',
        'title': 'Location Name',
        'type': 'string',
        'action': function(locationuser) {
          self.get('router').transitionTo('locations.view', locationuser.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      removeUser: {
        'name': 'id',
        'title': '',
        'action': function(locationuser) {
          self.send('removeUser', locationuser);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['locationname', 'removeUser'];
    var listOfMobileCols = ['locationname',  'removeUser'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (!loggedInUserCanEditViewedUser) {
        colsArray.removeObject(columns.removeUser);
      }
    }

  }),

  // Actions
  actions: {
    removeUser: function(locationuser) {
      var self = this;
      var locationName = locationuser.get('location.name') || 'this location';
      var userName = this.get('user.username');
      pretty_confirm('Are you sure you want to remove ' + userName + ' from ' + locationName + '?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        locationuser.deleteRecord();
        locationuser.save().then(function() {
          noty({
            text: userName + ' removed successfully from ' + locationName + '!'
          });
          self.set('isLoading', false);
          self.get('parent').send('gridNeedsRefresh', self);
        }, function() {
          locationuser.rollbackAttributes();
          noty({
            text: 'Error removing ' + userName + '!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
  }

});
