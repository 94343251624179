import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  didRender: function() {
    const isEnabled = this.get('isEnabled');
    const allFields = this.get('allFields');
    const fields = this.get('fields');
    const unselected = this.get('unselected');
    const formatted = [];
    if (fields) {
      //Reason for converting into array and then modifying is because registration fields is a string separated by commas.
      const splitFields = fields.split(',');
      splitFields.forEach((field) => {
        formatted.push(field.replace(/['"]+/g, ''));
      });
    }
    // Irrespective of which field is present in the registration fields list, all the checkboxes need to be disabled if not in edit mode.
    allFields.forEach((field) => {
      if (!isEnabled) {
        jQuery(`#${field}`).prop('disabled', true);
      } else {
        jQuery(`#${field}`).prop('disabled', false);
      }
    });
    // When the component renders this loop will check all the fields that are present in the domain registration fields array
    if (formatted && formatted.length >= 1) {
      formatted.forEach((fieldId) => {
        jQuery(`#${fieldId}`).prop('checked', true);
      });
    }
  },

  actions: {
    parentAction: function(data) {
      this.action(data);
    },
  }
});
