import Ember from "ember";
import { assign } from "@ember/polyfills";
import jQuery from "jquery";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer(
    "account.errors.messages",
    "needsUpdate",
    function () {
      this.set("errorMessages", this.get("account.errors.messages"));
    }
  ),

  // Events
  didInsertElement: function () {
    this.set("contactsArray", []);
    this.set("processMethodTerminal", false);
    this.set("terminal_id", null);
    this.set("routingNumbers", null);
    this.set(
      "locked_account_vault_api_id",
      this.get("isAdding") ? false : true
    );
    this.set("locked_dl_number", this.get("isAdding") ? false : true);
    this.set("locked_ssn4", this.get("isAdding") ? false : true);
    this.set("locked_dob_year", this.get("isAdding") ? false : true);
    if (this.get("enable_card_reader") && !this.get("cardReader")) {
      this.cardReaderSetup();
    }
    if (this.get("isAdding") && !this.get("accountVaultNotEnabled")) {
      this.set("isEditing", true);
      var contact = this.get("contact");
      var location_id = "";
      if (this.get("force_location_id")) {
        location_id = this.get("force_location_id");
      } else {
        var locations = this.get("locationsWithAccountVault");
        var userPrimaryLocationId = this.get(
          "session.authenticated.primary_location_id"
        );
        locations.forEach(function (loc) {
          if (loc.get("id") === userPrimaryLocationId) {
            location_id = loc.get("id");
          }
        });
        if (!location_id) {
          location_id = locations.get("firstObject").get("id");
        }
      }
      var defaultAccount = {
        payment_method: this.get("force_payment_method") || "cc",
        account_type: "checking",
        is_company: "0",
        exp_month: null,
        exp_year: null,
        routing: null,
      };
      switch (this.get("route")) {
        case "accounts.add":
        case "locations.accounts-add":
          defaultAccount = assign(defaultAccount, {
            contact_id: null,
            location_id: location_id,
            account_holder_name: null,
            billing_address: null,
            billing_city: null,
            billing_state: null,
            billing_zip: null,
            billing_phone: null,
          });
          break;
        case "contacts.accounts-add":
        case "contactuser.accounts-add":
          defaultAccount = assign(defaultAccount, {
            contact_id: contact.get("id"),
            location_id: contact.get("location_id"),
            account_holder_name: contact.get("full_name"),
            billing_address: contact.get("address"),
            billing_city: contact.get("city"),
            billing_state: contact.get("state"),
            billing_zip: contact.get("zip"),
            billing_phone: contact.get("phone"),
          });
          break;
      }
      var account = this.get("store").createRecord(
        "accountvault",
        defaultAccount
      );
      this.set("account", account);
      this.set("showCAUs", true);
    } else if (!this.get("isAdding") && !this.get("accountVaultNotEnabled")) {
      if (this.get("account.isACH")) {
        this.send("updateItems", this.get("account.routing"));
      }
      this.set("changesToMake", []);
      this.set("isEditingCAU", false);
      this.set("showRecentTransactions", false);
      this.set("showRecurrings", false);
    }
    this.setLocation();
    this.set("formPrivs", {
      canDelete: this.get(
        "session.authenticated.resources.v2-accountvaults-delete"
      ),
      canPost: this.get(
        "session.authenticated.resources.v2-accountvaults-post"
      ),
      canPut:
        this.get("session.authenticated.resources.v2-accountvaults-put") &&
        this.get("account.active"),
    });
  },
  willDestroyElement: function () {
    var account = this.get("account");
    if (
      this.get("account") &&
      (account.get("hasDirtyAttributes") || account.get("isNew")) &&
      this.get("isAdding")
    ) {
      account.unloadRecord();
    }
    if (this.get("enable_card_reader")) {
      this.cardReaderDestroy();
    }
    this.set("returnRoute", null);
    this.set("isTransitioning", false);
  },

  // Computed Properties
  accountVaultActive: Ember.computed("account.active", function () {
    return this.get("account.active");
  }),
  accountVaultActiveAndExpiringSoon: Ember.computed(
    "account.{id,active,expiring_in_months,payment_method}",
    function () {
      var account = this.get("account");
      if (this.get("account.payment_method") === "cc") {
        return (
          account.get("active") &&
          account.get("expiring_in_months") >= 0 &&
          account.get("expiring_in_months") < 3
        );
      } else {
        return false;
      }
    }
  ),
  accountVaultActiveAndExpired: Ember.computed(
    "account.{active,expiring_in_months,payment_method}",
    function () {
      var account = this.get("account");
      if (this.get("account.payment_method") === "cc") {
        return account.get("active") && account.get("expiring_in_months") < 0;
      } else {
        return false;
      }
    }
  ),
  accountVaultWarningMessage: Ember.computed(
    "accountVaultActive",
    "accountVaultActiveAndExpiringSoon",
    "accountVaultActiveAndExpired",
    function () {
      if (!this.get("accountVaultActive")) {
        return "Account Vault is Deleted.";
      } else if (this.get("accountVaultActiveAndExpiringSoon")) {
        return "Account Vault is Expiring Soon!";
      } else if (this.get("accountVaultActiveAndExpired")) {
        return "Account Vault is Expired.";
      } else {
        return false;
      }
    }
  ),
  accountVaultEnabledButNoProducts: Ember.computed(
    "locations.[]",
    "location.id",
    "locationsWithAccountVault.[]",
    "account.isNew",
    function () {
      var locations = this.get("locationsWithAccountVault");
      var location_id = this.get("account.location_id");
      if (locations && locations.get("length")) {
        var location = locations
          .filter(function (loc) {
            return loc.get("id") === location_id;
          })
          .get("firstObject");
        if (location) {
          var products = location
            .get("product_transactions")
            .filter(function (p) {
              return p.get("active");
            });
          return (
            this.get("locationsWithAccountVault.length") >= 1 &&
            products.get("length") < 1 &&
            this.get("account.isNew")
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  ),
  accountVaultNotEnabled: Ember.computed(
    "locationsWithAccountVault.[]",
    function () {
      return (
        this.get("locationsWithAccountVault.length") < 1 && this.get("isAdding")
      );
    }
  ),
  ach_sec_codes_is_company: Ember.computed(function () {
    return this.get("applicationData.ach_sec_codes_is_company");
  }),
  ach_sec_codes_not_company: Ember.computed(function () {
    // Here getting the terminal id from the location
    // let data = this.get("location").get('defaultProductACH');
    // let terminalIds = data.processor_data.terminals.map(function (
    //   t
    // ) {
    //   return {
    //     id: t.sec_code,
    //     title: t.sec_code,
    //   };
    // });
    // return terminalIds;
    let arr = [];
    let location = this.get("location");
    if(location) {
    let productTransactionData = location.get('product_transactions');
    if(productTransactionData?._objects?.length > 0) {
      productTransactionData._objects.forEach((element) => {
        if(element?.payment_method == 'ach' && element.processor_data?.terminals?.length > 0){
          let tIds = element.processor_data.terminals.map((t) => {
            return {
              id: t.sec_code,
              title: t.sec_code,
            };
          })
          arr.push(...tIds);
        }
      })
    }
  }
    const uniqueObjects = [...new Map(arr.map(item => [item.id, item])).values()]
    return uniqueObjects;
  }),
  checkIsCompany: Ember.computed("account.is_company", function () {
    var checkCompany = false;
    
    if (this.get("account.is_company") === "1" || this.get("account.is_company") === "true" ||
    this.get("account.is_company") === true)
    {
      checkCompany = true;
    }
    return checkCompany;
  }),
  achSecCodeContents: Ember.computed("account.is_company", function () {
    var codes;
    let account = this.get("account");
    if (account && 
      (account.is_company === "1" || account.is_company === "true" || account.is_company === true)) 
    {
      codes = this.get("ach_sec_codes_is_company");
    } else {
      codes = this.get("ach_sec_codes_not_company");
    }
    // if (this.get('location.hasProductPayaWithC21')) { // NOTE: Do not add C21 until support in UI is implemented
    // codes = codes.concat({
    //   id: 'C21',
    //   title: 'C21'
    // });
    // }
    return codes;
  }),
  addRecurringTitle: Ember.computed(
    "canCharge",
    "session.authenticated.isContact",
    "account.isActive",
    "canUseForRecurring",
    function () {
      if (!this.get("account.active")) {
        return "Account Vault is not active";
      } else if (this.get("canCharge") && this.get("canUseForRecurring")) {
        return this.get("session.authenticated.isContact")
          ? "Add Scheduled Payment"
          : "Add Recurring Billing";
      } else if (!this.get("canCharge")) {
        return "Account Vault has expired.";
      } else if (!this.get("canUseForRecurring")) {
        return "No active Deposit Accounts for this Payment Method.";
      } else {
        return "Account Vault unavailable.";
      }
    }
  ),
  canBillingInfo: Ember.computed(
    "account.isCC",
    "processMethodTerminal",
    function () {
      return this.get("account.isCC") || this.get("processMethodTerminal");
    }
  ),
  canCAULogs: Ember.computed(
    "isRouteContactUser",
    "account.{isCC,isNew}",
    "session.authenticated.resources.v2-accountvaultcaulogs-get",
    function () {
      return (
        !this.get("isRouteContactUser") &&
        this.get("account.isCC") &&
        !this.get("account.isNew") &&
        this.get("session.authenticated.resources.v2-accountvaultcaulogs-get")
      );
    }
  ),
  canCAUPTs: Ember.computed(
    "isRouteContactUser",
    "account.{isNew,isCC}",
    "cau_producttransaction_options.[]",
    "cau_producttransaction_options_automatically_subscribed.[]",
    function () {
      return (
        !this.get("isRouteContactUser") &&
        this.get("account.isCC") &&
        (this.get("account.isNew")
          ? this.get(
              "cau_producttransaction_options_automatically_subscribed.length"
            ) > 0
          : this.get("cau_producttransaction_options.length") > 0)
      );
    }
  ),
  canCharge: Ember.computed(
    "account.{id,active,expiring_in_months,payment_method}",
    function () {
      var payment_method = this.get("account.payment_method");
      var isActive = this.get("account.active");
      if (payment_method === "ach") {
        return isActive;
      } else {
        var exp = this.get("account.expiring_in_months");
        if (exp && exp >= 0 && isActive) {
          return true;
        } else {
          return false;
        }
      }
    }
  ),
  canEditCAU: Ember.computed(
    "account.{active,isNew}",
    "session.authenticated.resources.v2-accountvaultcauproducttransactions-post",
    "session.authenticated.resources.v2-accountvaultcauproducttransactions-delete",
    function () {
      return (
        this.get("account.active") &&
        !this.get("account.isNew") &&
        (this.get(
          "session.authenticated.resources.v2-accountvaultcauproducttransactions-post"
        ) ||
          this.get(
            "session.authenticated.resources.v2-accountvaultcauproducttransactions-delete"
          ))
      );
    }
  ),
  canUseForRecurring: Ember.computed("canCharge", function () {
    var canCharge = this.get("canCharge");
    var location_id = this.get("account.location_id");
    var locations = this.get("locations");
    var payment_method = this.get("account.payment_method");
    var locationHasProduct = false;
    if (locations && locations.get("length")) {
      var location = locations
        .filter(function (loc) {
          return loc.get("id") === location_id;
        })
        .get("firstObject");
      if (payment_method === "ach") {
        locationHasProduct = location.get("hasProductACH");
      } else if (payment_method === "cc") {
        var products = location.get("product_transactions");
        if (products && products.get("length")) {
          products = products.filter(function (i) {
            return (
              i.get("payment_method") === "cc" &&
              i.get("active") &&
              i.get("industry_type") !== "lodging"
            );
          });
          locationHasProduct = products.get("length") >= 1;
        } else {
          locationHasProduct = false;
        }
      }
    }
    return canCharge && locationHasProduct;
  }),
  canUseForTransaction: Ember.computed(
    "canCharge",
    "account.{location_id,payment_method}",
    function () {
      var canCharge = this.get("canCharge");
      var location_id = this.get("account.location_id");
      var locations = this.get("locations");
      var payment_method = this.get("account.payment_method");
      var locationHasProduct = false;
      if (locations && locations.get("length")) {
        var location = locations
          .filter(function (loc) {
            return loc.get("id") === location_id;
          })
          .get("firstObject");
        if (payment_method === "ach") {
          locationHasProduct = location.get("hasProductACH");
        } else if (payment_method === "cc") {
          locationHasProduct = location.get("hasProductCC");
        }
      }
      return canCharge && locationHasProduct;
    }
  ),
  cau_producttransaction_options: Ember.computed(
    "location.product_transactions.[]",
    function () {
      if (this.get("location.id")) {
        return this.get("location.product_transactions").filter(function (
          prod
        ) {
          return prod.get("cau_account_number");
        });
      } else {
        return [];
      }
    }
  ),
  cau_producttransaction_options_automatically_subscribed: Ember.computed(
    "cau_producttransaction_options.[]",
    function () {
      return this.get("cau_producttransaction_options").filter(function (prod) {
        return prod.get("cau_subscribe_type_id") === "1";
      });
    }
  ),
  detailSections: Ember.computed(function () {
    var session = this.get("session");
    var isAdding = this.get("isAdding");
    return [
      {
        name: "Detail",
        heading: "heading0",
        group: "group0",
        link: "#group0",
        action: "detail",
        active: true,
        canView: !isAdding,
      },
      {
        name: "Recent Transactions",
        heading: "heading1",
        group: "group1",
        link: "#group1",
        action: "recenttransactions",
        active: false,
        canView:
          session.get("authenticated.resources.v2-transactions-get") &&
          !isAdding,
      },
      {
        name: this.get("isRouteContactUser")
          ? "Scheduled Payments"
          : "Recurring Billings",
        heading: "heading2",
        group: "group2",
        link: "#group2",
        action: "recurringbilling",
        active: false,
        canView:
          session.get("authenticated.resources.v2-recurrings-get") && !isAdding,
      },
      {
        name: "BIN Info",
        heading: "heading3",
        group: "group3",
        link: "#group3",
        action: "bininfo",
        active: false,
        canView:
          session.get("authenticated.resources.v2-accountvaults-get-bininfo") &&
          this.get("account.isCC") &&
          !this.get("isRouteContactUser") &&
          !isAdding,
      },
      {
        name: "CAU Logs",
        heading: "heading4",
        group: "group4",
        link: "#group4",
        id: "btn_accountvault_cau_logs",
        action: "caulogs",
        active: false,
        canView: this.get("canCAULogs") && !isAdding,
      },
      {
        name: "Change Logs",
        heading: "heading5",
        group: "group5",
        link: "#group5",
        action: "changelogs",
        active: false,
        canView: !this.get("isRouteContactUser") && !isAdding,
      },
    ];
  }),
  disableButtons: Ember.computed("account.has_recurring", function () {
    return {
      delete: {
        whenThis: this.get("account.has_recurring"),
        message: "Cannot delete Account Vault due to active Recurring Payments",
      },
    };
  }),
  hideOnContactuserEditAccount: Ember.computed("route", function () {
    return this.get("route") === "contactuser.accounts-view";
  }),
  ingenicoTerminals: Ember.computed("terminals.[]", "location.id", function () {
    var self = this;
    var terminals = this.get("terminals");
    if (terminals) {
      return terminals.filter(function (terminal) {
        var terminal_manufacturer_id = Number(
          terminal.get("terminal_manufacturer_id")
        );
        return (
          (terminal_manufacturer_id === 2 ||
            terminal_manufacturer_id === 100) &&
          terminal.get("location_id") === self.get("location.id")
        );
      });
    } else {
      return [];
    }
  }),
  isAdding: Ember.computed("route", function () {
    return (
      this.get("route") === "contacts.accounts-add" ||
      this.get("route") === "contactuser.accounts-add" ||
      this.get("route") === "accounts.add" ||
      this.get("route") === "locations.accounts-add"
    );
  }),
  isHideBlindPayment: Ember.computed(
    "session.authenticated.isContact",
    "contact.hide_blind_payment",
    "locations.firstObject.hide_blind_payment",
    "locations.firstObject.branding_domain",
    function () {
      const isContactUser = this.get("session.authenticated.isContact");
      const contactHideBlindPayment = this.get("contact.hide_blind_payment");
      const locationHideBlindPayment = this.get(
        "locations.firstObject.hide_blind_payment"
      );
      const domainHideBlindPayment = this.get(
        "locations.firstObject.branding_domain"
      ).get("hide_blind_payment");

      return (
        isContactUser &&
        (contactHideBlindPayment ||
          locationHideBlindPayment ||
          domainHideBlindPayment)
      );
    }
  ),
  isRouteAccounts: Ember.computed("route", function () {
    return (
      this.get("route") === "accounts.view" ||
      this.get("route") === "accounts.add"
    );
  }),
  isRouteContact: Ember.computed("route", function () {
    return (
      this.get("route") === "contacts.accounts-view" ||
      this.get("route") === "contacts.accounts-add"
    );
  }),
  isRouteContactUser: Ember.computed("route", function () {
    return (
      this.get("route") === "contactuser.accounts-view" ||
      this.get("route") === "contactuser.accounts-add"
    );
  }),
  isRouteLocation: Ember.computed("route", function () {
    return (
      this.get("route") === "locations.accounts-view" ||
      this.get("route") === "locations.accounts-add"
    );
  }),
  launchVirtTermTitle: Ember.computed(
    "canCharge",
    "session.authenticated.isContact",
    "account.isActive",
    "canUseForTransaction",
    function () {
      if (!this.get("account.active")) {
        return "Account Vault is not active";
      } else if (this.get("canCharge") && this.get("canUseForTransaction")) {
        return this.get("session.authenticated.isContact")
          ? "Make a Payment"
          : "Run Transaction";
      } else if (!this.get("canCharge")) {
        return "Account Vault has expired.";
      } else if (!this.get("canUseForTransaction")) {
        return "No active Deposit Accounts for this Payment Method.";
      } else {
        return "Account Vault unavailable.";
      }
    }
  ),
  locationsWithAccountVault: Ember.computed("locations.[]", function () {
    return this.get("locations").filter(function (location) {
      return location.get("hasProductAccountVault");
    });
  }),
  noAccountTermsAgree: Ember.computed(
    "account.terms_agree",
    "route",
    function () {
      return (
        !this.get("account.terms_agree") &&
        this.get("route") === "contactuser.accounts-add"
      );
    }
  ),
  noAccountVaultMessage: Ember.computed(
    "accountVaultNotEnabled",
    "isRouteAccounts",
    "isRouteContact",
    "isRouteContactUser",
    "isRouteLocation",
    function () {
      if (this.get("accountVaultNotEnabled")) {
        var message = "Account Vault Service is not configured for ";
        if (this.get("isRouteLocation")) {
          message += "this location.";
        } else if (this.get("isRouteAccounts")) {
          message += "any of your locations.";
        } else if (this.get("isRouteContact")) {
          message += "this contact's location.";
        } else if (this.get("isRouteContactUser")) {
          message +=
            "your location.<br> Please contact " +
            this.get("location.name") +
            " for alternatives.";
        }
        if (!this.get("isRouteContactUser")) {
          message +=
            "<br> Please contact support if you need assistance enabling Account Vault Service.";
        }
        return message;
      } else {
        return;
      }
    }
  ),
  noProductsAccountVaultMessage: Ember.computed(
    "accountVaultEnabledButNoProducts",
    "isRouteAccounts",
    "isRouteContact",
    "isRouteContactUser",
    "isRouteLocation",
    function () {
      if (this.get("accountVaultEnabledButNoProducts")) {
        var message = "No active Deposit Accounts at ";
        if (this.get("isRouteLocation")) {
          message += "this location.";
        } else if (this.get("isRouteAccounts")) {
          message += "any of your locations.";
        } else if (this.get("isRouteContact")) {
          message += "this contact's location.";
        } else if (this.get("isRouteContactUser")) {
          message +=
            "your location.<br> Please contact " +
            this.get("location.name") +
            " for alternatives.";
        }
        if (!this.get("isRouteContactUser")) {
          message +=
            "<br> Please contact support if you need assistance activating Deposit Accounts.";
        }
        return message;
      } else {
        return;
      }
    }
  ),
  notEditing: Ember.computed("isEditing", function () {
    return !this.get("isEditing");
  }),
  payment_methods: Ember.computed(
    "location.id",
    "force_payment_method",
    function () {
      var location = this.get("location");
      var force_payment_method = this.get("force_payment_method");
      var methods = [];
      if (
        location &&
        location.get("hasProductCC") &&
        (!force_payment_method || force_payment_method === "cc")
      ) {
        methods.push({
          id: "cc",
          title: "Credit Card",
        });
      }
      if (
        location &&
        location.get("hasProductACH") &&
        (!force_payment_method || force_payment_method === "ach")
      ) {
        methods.push({
          id: "ach",
          title: "ACH/EFT",
        });
      }
      return methods;
    }
  ),
  selectedAccountVaultCAUProducttransactions: Ember.computed(
    "accountvaultcauproducttransactions.[]",
    "changesToMake.[]",
    function () {
      var accountvaultcauproducttransactions = this.get(
        "accountvaultcauproducttransactions"
      );
      var tempArray = [];
      if (
        accountvaultcauproducttransactions &&
        accountvaultcauproducttransactions.get("length")
      ) {
        accountvaultcauproducttransactions.forEach(function (group) {
          tempArray.push(group.get("product_transaction_id"));
        });
      }
      return tempArray;
    }
  ),
  showAccountVaultActions: Ember.computed(
    "account.{active,isNew}",
    "hide_panel",
    "session.authenticated.resources.v2-transactions-post",
    "session.authenticated.resources.v2-recurrings-post",
    function () {
      return (
        this.get("account.active") &&
        !this.get("account.isNew") &&
        !this.get("hide_panel") &&
        (this.get("session.authenticated.resources.v2-transactions-post") ||
          this.get("session.authenticated.resources.v2-recurrings-post"))
      );
    }
  ),
  showContacts: Ember.computed("route", "account.location_id", function () {
    return (
      this.get("account.location_id") &&
      (this.get("isRouteAccounts") || this.get("isRouteLocation")) &&
      (!this.get("isAdding") ||
        (this.get("isAdding") &&
          this.get("session.authenticated.resources.v2-contacts-get")))
    );
  }),
  showLocations: Ember.computed(
    "isRouteAccounts",
    "isAdding",
    "locationsWithAccountVault.[]",
    "force_location_id",
    function () {
      return (
        this.get("isRouteAccounts") &&
        ((this.get("isAdding") &&
          this.get("locationsWithAccountVault.length") > 1) ||
          !this.get("isAdding")) &&
        !this.get("force_location_id")
      );
    }
  ),
  showProcessMethod: Ember.computed(
    "isRouteAccounts",
    "isRouteContact",
    "isRouteLocation",
    "isAdding",
    "ingenicoTerminals.[]",
    "account.payment_method",
    "location.id",
    function () {
      return (
        this.get("account.payment_method") === "cc" &&
        this.get("isAdding") &&
        (this.get("isRouteAccounts") ||
          this.get("isRouteContact") ||
          this.get("isRouteLocation")) &&
        this.get("ingenicoTerminals.length") > 0
      );
    }
  ),
  showSignature: Ember.computed(
    "location.id",
    "isAdding",
    "session.authenticated.isLocation",
    function () {
      var location = this.get("location");
      if (
        this.get("isAdding") ||
        !location ||
        !this.get("session.authenticated.isLocation")
      ) {
        return false;
      }
      var payment_method = this.get("account.payment_method");
      var product =
        payment_method === "cc"
          ? location.get("defaultProductCC") || null
          : location.get("defaultProductACH") || null;
      return product
        ? product.get("receipt_add_account_above_signature")
        : false;
    }
  ),

  // Observers

  changedAccountNumberOrPaymentMethod: Ember.observer(
    "account.{account_number,payment_method}",
    function () {
      const errors = this.get("account.errors");
      if (errors) {
        errors.clear();
      }

      if (
        this.get("account.payment_method") === "cc" &&
        jQuery("#accountvault_account_number").val()
      ) {
        jQuery("#accountvault_account_number").validateCreditCard(function (
          result
        ) {
          if (result.length_valid === true && result.luhn_valid === true) {
            jQuery("#accountvault_account_number")
              .parent()
              .parent()
              .addClass("has-success");
            jQuery("#accountvault_account_number")
              .parent()
              .parent()
              .removeClass("has-error");
          } else {
            jQuery("#accountvault_account_number")
              .parent()
              .parent()
              .addClass("has-error");
            jQuery("#accountvault_account_number")
              .parent()
              .parent()
              .removeClass("has-success");
          }
        });
      } else {
        jQuery("#accountvault_account_number")
          .parent()
          .parent()
          .removeClass("has-error");
        jQuery("#accountvault_account_number")
          .parent()
          .parent()
          .removeClass("has-success");
      }
    }
  ),
  changedACHSECCode: Ember.observer(
    "account.{ach_sec_code,is_company,payment_method}",
    "isRouteAccounts",
    "isRouteContact",
    "isRouteLocation",
    function () {
      if (
        this.get("isRouteAccounts") ||
        this.get("isRouteContact") ||
        this.get("isRouteLocation")
      ) {
        var account = this.get("account");
        var ach_sec_code = account.get("ach_sec_code");
        var is_company = account.get("is_company") === "1" || account.get("is_company") === true || account.get("is_company") === "true";
        var payment_method = account.get("payment_method");
        if (payment_method === "ach") {
          if (is_company && !ach_sec_code) {
            this.set("account.ach_sec_code", "");
          } else if (!is_company && !ach_sec_code) {
            // setting ach_sec_code default to null or blank
            this.set("account.ach_sec_code", "");
          }
        } else if (payment_method === "cc") {
          this.set("account.ach_sec_code", null);
        }
      }
    }
  ),
  changedContactId: Ember.observer("account.contact_id", function () {
    var account = this.get("account");
    var contact_id = account.get("contact_id");
    var contacts = this.get("contactsArray");
    if (contact_id && contacts && contacts.get("length")) {
      var contact = contacts
        .filter(function (c) {
          return contact_id === c.get("id");
        })
        .get("firstObject");
      if (contact) {
        account.set(
          "account_holder_name",
          contact.get("full_name") ? contact.get("full_name").trim() : ""
        );
        account.set("billing_address", contact.get("address"));
        account.set("billing_city", contact.get("city"));
        account.set("billing_state", contact.get("state"));
        account.set("billing_zip", contact.get("zip"));
        account.set(
          "billing_phone",
          contact.get("phone") || contact.get("office_phone")
        );
      }
    }
  }),
  changedForcedPaymentMethod: Ember.observer(
    "force_payment_method",
    function () {
      var account = this.get("account");
      if (account) {
        account.set("payment_method", this.get("force_payment_method"));
      }
    }
  ),
  changedLocationId: Ember.observer(
    "account.{location_id,payment_method}",
    "showProcessMethod",
    "force_location_id",
    function () {
      this.setLocation();
      if (!this.get("showProcessMethod")) {
        this.set("processMethodTerminal", false);
      } else {
        this.setTerminalId();
      }
    }
  ),
  changedLocationIdClearContact: Ember.observer(
    "account.location_id",
    function () {
      if (this.get("route") === "accounts.add") {
        this.set("account.contact_id", null);
      }
    }
  ),
  changedPaymentMethod: Ember.observer("account.payment_method", function () {
    var payment_method = this.get("account.payment_method");
    var location = this.get("location");
    var defaultProduct =
      payment_method === "cc"
        ? location?.get("defaultProductCC") || null
        : location?.get("defaultProductACH") || null;

    if (defaultProduct) {
      var legal = null;
      legal = defaultProduct.get("receipt_add_account_above_signature");
      if (legal && this.get("route") === "contactuser.accounts-add") {
        this.set("legal", legal);
        this.set("account.terms_agree", false);
      } else {
        this.set("legal", null);
        this.set(
          "account.terms_agree",
          this.get("isRouteAccounts") ||
            this.get("isRouteContact") ||
            this.get("isRouteLocation")
            ? null
            : true
        );
      }
    } else {
      this.set("account.terms_agree", true);
    }
  }),
  changedRouting: Ember.observer(
    "account.routing",
    "routingNumbers.[]",
    function () {
      var routing = this.get("account.routing");
      var routings = this.get("routingNumbers");
      if (!routing || !routings) {
        return;
      }
      var r = routings.filter(function (item) {
        return item.get("id") === routing;
      });
      if (r.length === 1) {
        this.set("account.routing_bank_name", r[0].get("bank_name"));
      }
    }
  ),
  currentSectionObserver: Ember.observer("currentSection.action", function () {
    var currentSection = this.get("currentSection.action");
    if (currentSection === "recenttransactions") {
      this.set("showRecentTransactions", true);
      this.set("showRecurrings", false);
      this.getTransactions();
    } else if (currentSection === "recurringbilling") {
      this.set("showRecentTransactions", false);
      this.set("showRecurrings", true);
      this.getRecurrings();
    } else if (currentSection === "caulogs") {
      this.set("showRecentTransactions", false);
      this.set("showRecurrings", false);
      this.getCAULogs();
    }
  }),
  needsRefreshObserve: Ember.observer("needsRefresh", function () {
    if (this.get("showRecentTransactions")) {
      this.getTransactions();
    }
  }),
  sendNewAccount: Ember.observer(
    "account.{account_holder_name,account_number,account_type,ach_sec_code,billing_address,billing_city,billing_phone,billing_state,billing_zip,dl_number,dl_state,dob_year,exp_date,exp_month,exp_year,is_company,payment_method,routing,ssn4,title,terms_agree}",
    "newAccount",
    function () {
      if (!this.get("newAccount")) {
        return;
      }
      var account = this.get("account");
      if (account.get("exp_month") && account.get("exp_year")) {
        account.set(
          "exp_date",
          account.get("exp_month") + account.get("exp_year")
        );
      }
      this.newAccount(account);
    }
  ),
  updateExpire: Ember.observer(
    "account.{exp_month,exp_year,account_type,is_company}",
    function () {
      this.get("account.errors").clear();
      this.get("account.errors.messages").clear();
    }
  ),

  // Functions

  cardReaderDestroy: function () {
    var reader = this.get("cardReader");
    if (reader) {
      reader.remove(jQuery(document));
    }
  },
  cardReaderSetup: function () {
    var self = this;
    var reader = new CardReader("\u00e9", ["%"], ["?"], 100);
    reader.observe(jQuery(document));
    reader.cardError(function () {
      noty({
        text: "A read error occurred!",
        type: "error",
      });
    });
    reader.cardRead(function (value) {
      var account = self.get("account");
      // Get track data and assign it to form vars
      var parsed_data = parseTrackData(value);
      account.set("account_number", parsed_data.card_number);
      account.set("exp_month", parsed_data.exp_month);
      account.set("exp_year", parsed_data.exp_year);
      account.set("account_holder_name", parsed_data.cardholder);
    });
    self.set("cardReader", reader);
  },
  getCAULogs: function () {
    var self = this;
    if (this.get("isLoading")) {
      return;
    }
    this.set("isLoading", true);
    this.get("store")
      .query("accountvaultcaulog", {
        account_vault_id: self.get("account.id"),
        expand: "created_user,account_vault,product_transaction",
      })
      .then(function (res) {
        self.set("accountvaultcaulogs", res);
        self.set("isLoading", false);
      });
  },
  getRecurrings: function () {
    var self = this;
    var account_vault_id = this.get("account.id");
    if (this.get("isLoadingRecurrings")) {
      return;
    }
    this.set("isLoadingRecurrings", true);
    this.get("store")
      .query("recurring", {
        account_vault_id: account_vault_id,
        page_size: 10,
        sort: "-created_ts",
        expand: "account_vault",
      })
      .then(
        function (result) {
          self.set("accountvault_recurrings", result);
          self.set("isLoadingRecurrings", false);
        },
        function () {
          self.set("accountvault_recurrings", []);
          self.set("isLoadingRecurrings", false);
        }
      );
  },
  getTransactions: function () {
    var self = this;
    var account_vault_id = this.get("account.id");
    if (this.get("isLoadingTransactions")) {
      return;
    }
    this.set("isLoadingTransactions", true);
    this.get("store")
      .query("transaction", {
        account_vault_id: account_vault_id,
        page_size: 10,
        sort: "-created_ts",
      })
      .then(
        function (result) {
          self.set("accountvault_transactions", result);
          self.set("isLoadingTransactions", false);
        },
        function () {
          self.set("accountvault_transactions", []);
          self.set("isLoadingTransactions", false);
        }
      );
  },
  setLocation: function () {
    var locations = this.get("locations");
    var location = null;
    var account_location_id = this.get("account.location_id");
    var force_location_id = this.get("force_location_id");
    locations = locations.filter(function (loc) {
      return force_location_id
        ? loc.get("id") === force_location_id
        : loc.get("id") === account_location_id;
    });
    if (locations && locations.get("length") === 1) {
      location = locations.get("firstObject");
      this.set("location", location);
      if (this.get("account.isNew")) {
        var payment_method = this.get("account.payment_method");
        if (
          payment_method === "cc" &&
          location.get("hasProductACH") &&
          !location.get("hasProductCC")
        ) {
          this.set("account.payment_method", "ach");
        } else if (
          payment_method === "ach" &&
          !location.get("hasProductACH") &&
          location.get("hasProductCC")
        ) {
          this.set("account.payment_method", "cc");
        }
      }
    }
  },
  setTerminalId: function () {
    var session = this.get("session");
    var terminals = this.get("ingenicoTerminals");
    var defaultProcessMethod = session.get(
      "authenticated.ui_prefs.process_method"
    );
    var defaultTerminalId = session.get(
      "authenticated.ui_prefs.default_terminal"
    );
    var processMethodTerminal = this.get("processMethodTerminal");
    var defaultIsIngenico = false;
    if (defaultTerminalId) {
      terminals.forEach(function (terminal) {
        if (terminal.get("id") === defaultTerminalId) {
          defaultIsIngenico = true;
        }
      });
    }
    if (processMethodTerminal) {
      if (defaultProcessMethod === "physical_terminal" && defaultIsIngenico) {
        this.set("terminal_id", defaultTerminalId);
      } else {
        this.set("terminal_id", terminals.get("firstObject").get("id"));
      }
    } else {
      this.set("terminal_id", null);
    }
  },

  // Actions
  actions: {
    activeSection: function (index) {
      var detailSections = this.get("detailSections");
      var currentSection = detailSections
        .filter(function (section, i) {
          return index === i;
        })
        .get("firstObject");
      this.set("isChangeLogTab", currentSection.action === "changelogs");
      this.set("isBinInfoTab", currentSection.action === "bininfo");
      this.set("currentSection", currentSection);
    },
    cancelCAUs: function () {
      var changesToMake = this.get("changesToMake");
      changesToMake.forEach(function (change) {
        jQuery(
          "#accountvault_caus_chk-" + change.product_transaction_id
        ).trigger("click");
      });
      this.set("changesToMake", []);
      this.set("isEditingCAU", false);
    },
    changeCAUs: function (data) {
      if (this.get("isLoading")) {
        return;
      }
      var hasCAU =
        this.get("selectedAccountVaultCAUProducttransactions").indexOf(
          data.id
        ) !== -1;
      var changesToMake = this.get("changesToMake");
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function (change) {
        if (change.product_transaction_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          product_transaction_id: data.id,
          title: data.title,
          type: hasCAU ? "DELETE" : "POST",
        });
      }
      this.set("changesToMake", tempChanges);
    },
    changeProcessMethod: function (method) {
      if (method === "terminal") {
        this.set("processMethodTerminal", true);
        this.setTerminalId();
      } else {
        this.set("processMethodTerminal", false);
      }
    },
    checkAllCAUs: function () {
      jQuery(".checkbox-select .checkbox-select-input").each(function () {
        var self = this;
        if (!jQuery(this).prop("checked") && !jQuery(this).prop("disabled")) {
          jQuery(self).trigger("click");
        }
      });
    },
    createRecurring: function (account) {
      var route = this.get("route");
      var router = this.get("router");
      if (route === "contacts.accounts-view") {
        router
          .transitionTo("contacts.recurrings-add", this.get("contact.id"))
          .then(function (Route) {
            Route.controller.set("force_account", account);
          });
      } else if (route === "accounts.view") {
        router.transitionTo("recurrings.add").then(function (Route) {
          Route.controller.set("force_account", account);
        });
      } else if (route === "locations.accounts-view") {
        router
          .transitionTo("locations.recurrings-add", account.get("location_id"))
          .then(function (Route) {
            Route.controller.set("force_account", account);
          });
      } else if (route === "contactuser.accounts-view") {
        router
          .transitionTo("contactuser.recurrings-add")
          .then(function (Route) {
            Route.controller.set("force_account", account);
          });
      }
    },
    delete: function () {
      var self = this;
      var account = this.get("account");
      if (account.get("has_recurring")) {
        return;
      }
      pretty_confirm(
        "Are you sure want to delete this Account Vault?",
        function () {
          // Bail if we are already loading
          if (self.get("isLoading")) {
            noty({
              text:
                "Cannot delete Account Vaults with active Recurring Billing!",
              type: "error",
            });
            return;
          }
          account.destroyRecord().then(
            function () {
              account.unloadRecord();
              noty({
                text: "Account Vault deleted successfully!",
              });
              self.set("isLoading", false);
              self.send("reset");
            },
            function (data) {
              var error = "";
              if (data.errors) {
                data.errors.forEach(function (err) {
                  error += "<br>" + err.detail + ".";
                });
              }
              account.rollbackAttributes();
              noty({
                text: "Error deleting Account Vault!" + error,
                type: "error",
              });
              self.set("isLoading", false);
            }
          );
        }
      );
    },
    edit: function () {
      this.set("isEditing", true);
    },
    editCAUs: function () {
      this.set("isEditingCAU", true);
    },
    editField: function (field) {
      var self = this;
      if (field === "account_vault_api_id") {
        pretty_confirm(
          "You should only edit this field if you understand the consequences.<br><br>Are you sure you want to edit?",
          function () {
            self.set("locked_account_vault_api_id", false);
          }
        );
      } else {
        self.set("locked_" + field, false);
        Ember.run.later(function () {
          jQuery("#accountvault_" + field)
            .focus()
            .select();
        }, 1);
      }
    },
    getSignature: function () {
      var controller = this.get("authagreementController");
      var contact = this.get("contact") || this.get("account.contact");
      var account = this.get("account");
      var location = this.get("location");
      var routings = this.get("routingNumbers");
      var payment_method = account.get("payment_method");
      var product =
        payment_method === "cc"
          ? location.get("defaultProductCC")
          : location.get("defaultProductACH");

      if (product && product.get("receipt_add_account_above_signature")) {
        controller.set("authagreementType", null);
        controller.set("model", null);
        controller.set("location", null);
        controller.set("contact", null);
        controller.set("routing", null);
        controller.set("authagreementType", "account");
        controller.set("model", account);
        controller.set("location", location);
        controller.set("contact", contact);
        if (product.get("receipt_add_account_above_signature")) {
          if (account.get("payment_method") === "ach") {
            if (routings) {
              controller.set("routing", routings.get("firstObject"));
            } else {
              if (
                this.get(
                  "session.authenticated.resources.v2-routingnumbers-get"
                )
              ) {
                var temproutings = this.get("store").query("routingnumber", {
                  routing: account.get("routing"),
                  page_size: 1,
                });
                temproutings.then(function (result) {
                  controller.set("routing", result.get("firstObject"));
                });
              } else {
                controller.set("routing", null);
              }
            }
          }
          this.get("parent").send(
            "openModal",
            "components/modals/authagreement"
          );
        }
      }
    },
    getCAUs: function (isShowing) {
      var self = this;
      var account_vault_id = this.get("account.id");
      if (isShowing) {
        this.set("showCAUs", false);
        return;
      }
      if (
        this.get(
          "session.authenticated.resources.v2-accountvaultcauproducttransactions-get"
        )
      ) {
        this.set("isLoadingCAUs", true);
        this.set("showCAUs", true);
        this.get("store")
          .query("accountvaultcauproducttransaction", {
            account_vault_id: account_vault_id,
          })
          .then(
            function (result) {
              self.set("accountvaultcauproducttransactions", result.toArray());
              self.set("showCAUPTOptions", true);
              self.set("isLoadingCAUs", false);
            },
            function () {
              self.set("accountvaultcauproducttransactions", []);
              self.set("isLoadingCAUs", false);
            }
          );
      } else {
        self.set("accountvaultcauproducttransactions", []);
      }
    },
    goToRecurring: function (recurring) {
      var isRouteAccounts = this.get("isRouteAccounts");
      var isRouteContact = this.get("isRouteContact");
      var isRouteContactUser = this.get("isRouteContactUser");
      var isRouteLocation = this.get("isRouteLocation");
      var router = this.get("router");
      if (isRouteAccounts) {
        router.transitionTo("recurrings.view", recurring.get("id"));
      } else if (isRouteContact) {
        router.transitionTo(
          "contacts.recurrings-view",
          recurring.get("account_vault.contact_id"),
          recurring.get("id")
        );
      } else if (isRouteContactUser) {
        router.transitionTo(
          "contactuser.recurrings-view",
          recurring.get("account_vault.contact_id"),
          recurring.get("id")
        );
      } else if (isRouteLocation) {
        router.transitionTo(
          "locations.recurrings-view",
          recurring.get("account_vault.location_id"),
          recurring.get("id")
        );
      }
    },
    launchvt: function (account) {
      var isContactUser = this.get("session.authenticated.isContact");
      var vtcController = this.get("vtcController");
      var route = this.get("route");
      var contact = this.get("contact");

      vtcController.set("action", "run");
      vtcController.set(
        "modal_title",
        isContactUser ? "Make a Payment" : "Run Transaction"
      );
      vtcController.set("account", account);
      vtcController.set(
        "disable_deposit_account",
        isContactUser ? true : false
      );
      vtcController.set("disable_tags", isContactUser ? true : false);
      vtcController.set(
        "hide_transaction_action",
        isContactUser ? true : false
      );
      vtcController.set("hide_lodging_fields", isContactUser ? true : false);
      vtcController.set(
        "show_contact_name",
        isContactUser || !account.get("contact_id") ? false : true
      );
      if (route === "contactuser.accounts-view") {
        vtcController.set("route", "contactuser");
      } else if (route === "contacts.accounts-view") {
        vtcController.set("route", "contact");
      } else if (route === "locations.accounts-view") {
        vtcController.set("route", "locations");
      } else if (route === "accounts.view") {
        vtcController.set("route", "accounts");
      }

      if (contact) {
        vtcController.set("contact", contact);
      } else if (account.get("contact.id")) {
        vtcController.set("contact", account.get("contact"));
      } else {
        vtcController.set("contact", null);
      }

      vtcController.set("locations", this.get("locations"));
      this.get("parent").send(
        "openModal",
        "components/modals/virtualterminalcontact"
      );
    },
    reset: function () {
      if (this.get("isTransitioning")) {
        return;
      }
      var contact = this.get("contact");
      var location = this.get("location");
      var returnRoute = this.get("returnRoute");
      var isRouteAccounts = this.get("isRouteAccounts");
      var isRouteContact = this.get("isRouteContact");
      var isRouteContactUser = this.get("isRouteContactUser");
      var isRouteLocation = this.get("isRouteLocation");
      var router = this.get("router");

      if (isRouteAccounts) {
        router.transitionTo("accounts.index");
      } else if (isRouteContact) {
        if (returnRoute) {
          router.transitionTo(returnRoute, contact.get("id"));
        } else {
          router.transitionTo("contacts.accounts-index", contact.get("id"));
        }
      } else if (isRouteContactUser) {
        if (returnRoute) {
          router.transitionTo(returnRoute);
        } else {
          router.transitionTo("contactuser.accounts-index");
        }
      } else if (isRouteLocation) {
        router.transitionTo("locations.accounts-index", location.get("id"));
      }
    },
    save: function (processMethod) {
      // Bail if we are already loading
      if (
        this.get("isLoading") ||
        (this.get("legal") &&
          this.get("isRouteContactUser") &&
          !this.get("account.terms_agree") &&
          this.get("account.isNew"))
      ) {
        return;
      }
      this.set("isLoading", true);

      var self = this;
      var account = this.get("account");
      var location = this.get("location");
      var routings = this.get("routingNumbers");
      var session = this.get("session");

      if (processMethod === "terminal") {
        var data = {
          routeraccountvault: {
            contact_id: account.get("contact_id"),
            location_id: account.get("location_id"),
            terminal_id: this.get("terminal_id"),
            title: account.get("title"),
            billing_address: account.get("billing_address"),
            billing_zip: account.get("billing_zip"),
            account_vault_api_id: account.get("account_vault_api_id"),
          },
        };
        jQuery
          .ajax({
            url: window.UiENV.APP.FULL_URL + "/routeraccountvaults",
            type: "POST",
            headers: {
              "Content-Type": "application/json",
              "developer-id": window.UiENV.APP.DEVELOPER_ID,
              "access-token": session.get("authenticated.token"),
            },
            data: JSON.stringify(data),
          })
          .then(
            function () {
              accountSaveSuccess(self, account, location, routings);
            },
            function (err) {
              var error = "";
              var errors = err.responseJSON.errors;
              if (errors) {
                Object.keys(errors).forEach(function (key) {
                  if (typeof errors[key][0] === "string") {
                    error += "<br>" + errors[key][0] + ".";
                  } else {
                    Object.keys(errors[key][0]).forEach(function (k) {
                      if (typeof errors[key][0][k] === "string") {
                        error += "<br>" + errors[key][0][k] + ".";
                      }
                    });
                  }
                });
              }
              noty({
                text: "Error Saving Account!" + error,
                type: "error",
              });
              self.set("isLoading", false);
            }
          );
      } else {
        if (this.get("isAdding")) {
          const errors = account.get("errors");
          if (this.get("account.payment_method") === "cc") {
            if (
              Ember.isEmpty(account.get("account_number")) ||
              account.get("account_number").length < 13 ||
              account.get("account_number").length > 19
            ) {
              errors.clear();
              errors.add("account_number", "Card number is required.");
            }
          } else {
            if (
              Ember.isEmpty(account.get("account_number")) ||
              account.get("account_number").length < 4 ||
              account.get("account_number").length > 17
            ) {
              errors.clear();
              errors.add("account_number", "Account number is required.");
            }
          }
        }

        if (account.get("errors").get("length") > 0) {
          self.set("isLoading", false);
          return;
        }

        if (account.get("payment_method") === "cc") {
          if (account.get("exp_month") && account.get("exp_year")) {
            account.set(
              "exp_date",
              account.get("exp_month") + account.get("exp_year")
            );
          } else {
            account.set("exp_date", null);
          }
        } else {
          account.set("exp_date", null);
        }

        account.save().then(
          function () {
            accountSaveSuccess(self, account, location, routings);
          },
          function () {
            self.set("isLoading", false);
            self.set("needsUpdate", new moment().unix());
          }
        );
      }

      function accountSaveSuccess(context) {
        noty({
          text: "Account Vault saved successfully!",
        });

        // Show popup if there are legal notices set in config
        if (
          context.get("route") === "contacts.accounts-add" ||
          context.get("route") === "accounts.add" ||
          context.get("route") === "locations.accounts-add"
        ) {
          context.send("getSignature");
        }

        context.set("isLoading", false);
        var contact = context.get("contact");
        var location = context.get("location");
        var returnRoute = context.get("returnRoute");
        var isRouteAccounts = context.get("isRouteAccounts");
        var isRouteContact = context.get("isRouteContact");
        var isRouteContactUser = context.get("isRouteContactUser");
        var isRouteLocation = context.get("isRouteLocation");
        var router = context.get("router");

        if (isRouteAccounts) {
          router.transitionTo("accounts.index");
        } else if (isRouteContact) {
          if (returnRoute) {
            router.transitionTo(returnRoute, contact.get("id"));
          } else {
            router.transitionTo("contacts.accounts-index", contact.get("id"));
          }
        } else if (isRouteContactUser) {
          if (returnRoute) {
            router.transitionTo(returnRoute);
          } else {
            router.transitionTo("contactuser.accounts-index");
          }
        } else if (isRouteLocation) {
          router.transitionTo("locations.accounts-index", location.get("id"));
        }
      }
    },
    saveCAUs: function () {
      if (this.get("isLoading")) {
        return;
      }
      var self = this;
      var changesToMake = this.get("changesToMake");
      var accountvaultcauproducttransactions = this.get(
        "accountvaultcauproducttransactions"
      );
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function (change, index) {
        if (change.type === "POST") {
          canPostRoles.push(change);
          var accountvaultcauproducttransaction = self
            .get("store")
            .createRecord("accountvaultcauproducttransaction", {
              account_vault_id: self.get("account.id"),
              product_transaction_id: change.product_transaction_id,
            });
          return accountvaultcauproducttransaction.save().then(
            function (a) {
              accountvaultcauproducttransactions.addObject(a); // Push the object we just created back onto the list of file categories object
              if (index === changesToMake.get("length") - 1) {
                doneMakingChanges(self);
                sendNoty();
              }
            },
            function () {
              // error
              if (index === changesToMake.get("length") - 1) {
                doneMakingChanges(self);
              }
              noty({
                text: "Error applying change for " + change.title,
                type: "error",
                timeout: 10000,
              });
            }
          );
        } else if (change.type === "DELETE") {
          canDeleteRoles.push(change);
          return accountvaultcauproducttransactions.forEach(function (arr) {
            if (
              arr.get("product_transaction_id") ===
              change.product_transaction_id
            ) {
              arr.destroyRecord().then(
                function () {
                  arr.unloadRecord();
                  accountvaultcauproducttransactions.removeObject(arr); // Remove the oject from the parent if it was successfully deleted
                  if (index === changesToMake.get("length") - 1) {
                    doneMakingChanges(self);
                    sendNoty();
                  }
                },
                function () {
                  //error
                  if (index === changesToMake.get("length") - 1) {
                    doneMakingChanges(self);
                  }
                  noty({
                    text: "Error applying change for " + change.title,
                    type: "error",
                    timeout: 10000,
                  });
                }
              );
            }
          });
        }
      });

      self.set("isLoading", true);

      if (!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(
          function () {
            //
          },
          function () {
            noty({
              text: "Error applying changes!",
              type: "error",
            });
            doneMakingChanges(self);
          }
        );
      }
      function sendNoty() {
        if (canPostRoles && canPostRoles.length) {
          var successMessagePost = "";
          canPostRoles.forEach(function (role) {
            successMessagePost += role.title;
            successMessagePost += "<br>";
          });
          noty({
            text:
              "Account Vault was successfully subscribed to the following Deposit Accounts:<br>" +
              successMessagePost,
            timeout: 10000,
          });
        }
        if (canDeleteRoles && canDeleteRoles.length) {
          var successMessageDelete = "";
          canDeleteRoles.forEach(function (role) {
            successMessageDelete += role.title;
            successMessageDelete += "<br>";
          });
          noty({
            text:
              "Account Vault was successfully unsubscribed from the following Deposit Accounts:<br>" +
              successMessageDelete,
            timeout: 10000,
          });
        }
      }
      function doneMakingChanges(context) {
        context.set("isLoading", false);
        context.set("isEditingCAU", false);
        context.set("changesToMake", []);
      }
    },
    showTrans: function (transaction) {
      var location = this.get("location");
      this.get("parent").send("showTransDetail", transaction, location);
    },
    toggleCAULogs: function () {
      var self = this;
      this.set("showCAULogs", !this.get("showCAULogs"));
      if (this.get("showCAULogs")) {
        if (this.set("isLoading")) {
          return;
        }
        this.set("isLoading", true);
        this.get("store")
          .query("accountvaultcaulog", {
            account_vault_id: self.get("account.id"),
            expand: "created_user,account_vault,product_transaction",
          })
          .then(function (res) {
            self.set("accountvaultcaulogs", res);
            self.set("isLoading", false);
          });
      }
    },
    unCheckAllCAUs: function () {
      jQuery(".checkbox-select .checkbox-select-input").each(function () {
        var self = this;
        if (jQuery(this).prop("checked") && !jQuery(this).prop("disabled")) {
          jQuery(self).trigger("click");
        }
      });
    },
    updateItems: function (data) {
      if (!this.get("session.authenticated.resources.v2-routingnumbers-get")) {
        return;
      }
      var self = this;
      var routings = this.get("store").query("routingnumber", {
        routing: data,
        page_size: 20,
      });
      routings.then(function (result) {
        self.set("routingNumbers", result);
      });
    },
  },
})
