import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (!value) {
      return null;
    } else {
      var newValue = null;
      var tempValue = value.split(',');
      var tempValArray = tempValue.map(function(val) {
        var v = val.replace(/"/g, '');
        return '"' + v + '"';
      });
      var tempVal = tempValArray.join(',');
      try {
        newValue = JSON.parse("[" + tempVal + "]");
      } catch (er) {
        console.log(er);
      }
      return newValue;
    }
  },
  deserialize: function(value) {
    if (!value) {
      return null;
    } else {
      return JSON.stringify(value).slice(1, -1);
    }
  }
});
