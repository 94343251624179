import Ember from 'ember';

Ember.TextField.reopen({
  classNames: ['form-control']
});

export default Ember.TextField.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  attributeBindings: ['autocomplete', 'isDecimal:decimal'],

  autocomplete: 'off',
  isDecimal: Ember.computed('decimal', function() {
    return this.get('decimal');
  }),

  keyDown: function(e) {
		if (e.keyCode === 13) {
			var onEnter = this.get('onEnter');
			if(onEnter){
				onEnter(e);
			}
		}
	},

  didInsertElement: function() {
    var self = this;
    if (this.get('has-focus')) {
      Ember.run.later(function() {
        self.$().focus();
      });
    }
  },
});
