import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isMailboxMessages: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page_size',
    'page',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'id',
    'subject'
  ],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Mailbox Message Id',
        'type': 'string',
        'sortable': true,
        'action': function(mailboxmessage) {
          self.send('view', mailboxmessage);
        },
        'actionId': 'mailboxmessage_id_view_link',
        'filter': {
          type: 'text'
        }
      },
      subject: {
        'name': 'subject',
        'title': 'Subject',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(mailboxmessage) {
          self.send('view', mailboxmessage);
        },
        'actionId': 'mailboxmessage_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(mailboxmessage) {
          self.send('edit', mailboxmessage);
        },
        'actionId': 'mailboxmessage_edit_link',
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(mailboxmessage) {
          self.send('delete', mailboxmessage);
        },
        'actionId': 'mailboxmessage_delete_link',
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'subject', 'created_ts', 'view', 'delete', 'edit'];
    var listOfMobileCols = ['id', 'subject', 'created_ts', 'view', 'delete', 'edit'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-mailboxmessages-get')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-mailboxmessages-put')) {
        colsArray.removeObject(columns.edit);
      }
      if (!session.get('authenticated.resources.v2-mailboxmessages-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Actions
  actions: {
    edit: function(mailboxmessage) {
      this.transitionToRoute('admin.mailboxmessages.view', mailboxmessage.get('id'), {
        queryParams: {
          edit: true
        }
      });
    },
    view: function(mailboxmessage) {
      this.transitionToRoute('admin.mailboxmessages.view', mailboxmessage.get('id'));
    },
    delete: function(mailboxmessage) {
      var self = this;
      pretty_confirm('Are you sure want to delete this Mailbox Message?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        mailboxmessage.deleteRecord();
        mailboxmessage.save().then(function() {
          noty({
            text: 'Mailbox Message deleted successfully!'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          mailboxmessage.rollbackAttributes();
          noty({
            text: 'Error deleting Mailbox Message!',
            type: 'error'
          });
          self.send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        });
      });
    }
  }
});
