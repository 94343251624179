import Ember from 'ember';

export default Ember.Helper.extend({

  // Dependency Injections
  helperFunctions: Ember.inject.service('helper-functions'),

  compute(params) {
    return this.get('helperFunctions').formatReasoncodeTransaction(params);
  }

});
