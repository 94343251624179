import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  card_type: DS.attr('string'),
  card_brand: DS.attr('string'),
  bin_type: DS.attr('string'),
  bin: DS.attr('string'),

  // Computed Properties

  card_type_description: Ember.computed('card_type', function(){
    return getTitle(cardType, this.get('card_type'));
  }),
});

var getTitle = function(arr, val) {
  if(!val) {
    return '';
  }
  var title = 'Unknown';
  arr.map(function(item){
    if(val === item.id) {
      title = item.title;
    }
  });
  return title;
};


var cardType = [{
    id: 'C',
    title: 'CREDIT'
  },{
    id: 'D',
    title: 'DEBIT'
}];
