import DS from 'ember-data';
import Application from './application';

export default Application.extend({

  cost: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  item_id: DS.attr('string'),
  product_billing_group_id: DS.attr('string'),
  title: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  item: DS.belongsTo('item')
});
