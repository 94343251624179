import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service(),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var router = this.get('router');
    var columns = {
      file_name: {
        'name': 'file_name',
        'title': 'File Name',
        'type': 'string',
        'sortable': true,
        'link': window.ENV.APP.FULL_URL + '/files/{0}/attachment?access-token={token}&developer-id={developer-id}',
        'linkid': ['id'],
        'linkvisible': function(row, controller) {
          var found = false;
          var product_file_id = row.get('product_file_id');
          controller.get('belongsToLocations').forEach(function(data) {
            if (data.get('product_file.id') === product_file_id) {
              found = true;
            }
          });
          return found;
        },
        'filter': {
          type: 'text'
        }
      },
      file_description: {
        'name': 'file_description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Uploaded',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      belongs_to: {
        'name': 'product_file_id',
        'title': 'Belongs To',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'belongsToAll',
          multi: true,
          showAllSelect: true
        }
      },
      visibility_group_id: {
        'name': 'visibility_group_id',
        'title': 'Visibility Group',
        'type': 'string',
        'sortable': true,
        'action': function(file) {
          router.transitionTo('locations.editvisibilitygroup', file.get('visibility_group.location_id'), file.get('visibility_group_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-visibilitygroups-get'],
      },
      file_category_id: {
        'name': 'file_category_id',
        'title': 'Category',
        'type': 'string',
        'sortable': true,
        'action': function(file) {
          router.transitionTo('locations.editfilecategory', file.get('file_category.location_id'), file.get('file_category_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-filecategories-get'],
      },
      delete: {
        'name': 'delete',
        'title': '',
        'type': 'delete',
        'action': function(file) {
          self.send('delete', file);
        },
        'sortable': false,
        'isActionColumn': true
      },
    };

    var listOfCols = ['file_name', 'file_description', 'created_ts', 'belongs_to', 'visibility_group_id', 'file_category_id', 'delete'];
    var listOfMobileCols = ['file_name', 'file_description', 'belongs_to', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-files-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Functions

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    delete: function(file) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this File?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        file.deleteRecord();
        file.save().then(function() {
          noty({
            text: 'File deleted successfully!'
          });
          self.get('parent').send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          file.rollbackAttributes();
          noty({
            text: 'Error deleting File!' + error,
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  },

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  }
});
