import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var session = this.get('session');
    if (session.get('authenticated.isContact')) {
      this.transitionTo('contactuser.makepayment');
      return;
    }
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        page_size: '500',
        relationship: 'direct'
      }),
      terminals: store.query('terminal', {
        page_size: '500',
        relationship: 'direct'
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var session = this.get('session');
    var locationinfos = models.locationinfos.filter(function(item) {
      return item.get('hasProductCC') || item.get('hasProductACH');
    });
    if (locationinfos.get('length') === 0) {
      if (session.get('authenticated.user_type_id') > 250) {
        this.transitionTo('locations');
      } else {
        this.transitionTo('contacts');
      }
      return;
    }
    var pagerParams = {
      pageRoute: 'virtualterminal',
      pageModelId: null,
      modelName: null
    };

    controller.set('pagerParams', pagerParams);
    controller.set('locationinfos', locationinfos);
    controller.set('terminals', models.terminals);

    // Set: Page Title - Crumbs - History
    // ** No History entry since route is already in main nav menu
    controller.set('pageTitle', 'Virtual Terminal');
    controller.set('pageCrumbs', [{
      title: 'Virtual Terminal',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      var controller = this.get('controller');
      var terminals = controller.get('terminals');
      if (terminals.get('length') > 0) {
        var terms = terminals.toArray();
        terms.forEach(function(item) {
          item.unloadRecord();
        });
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
