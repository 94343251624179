import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  normalize: function(type, hash, property) {
    // Ember Data use the zone name as the ID.
    hash.id = createUUID();

    // Delegate to any type-specific normalizations.
    return this._super(type, hash, property);
  }
});