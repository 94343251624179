import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  event_date: DS.attr('sqldate'),
  location_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),
  transaction_id: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
  transaction: DS.belongsTo('transaction'),
});
