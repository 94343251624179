import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      locations: store.query('location', {
        relationship: 'all',
        page_size: 20,
        sort: 'name'
      }),
      domains: store.query('domain', {
        relationship: 'all',
        page_size: 20,
        sort: 'url'
      }),
      terminals: store.query('terminal', {
        location_id: params.id,
        page_size: 100,
      }),
      // The user request needs to stay towards the bottom otherwise we get inconsistent
      // results when running checks for isDomainMismatch
      user: store.queryRecord('user', {
        id: params.user_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      })
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authroles: store.query('authrole', {
        user_type_id: models.user.get('user_type_id'),
        page_size: 500,
        sort: 'title'
      }).then(function(data) {
        models.authroles = data;
      }),
      authroleusers: store.query('authroleuser', {
        user_id: models.user.get('id'),
        page_size: 500
      }).then(function(data) {
        models.authroleusers = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    controller.set('user', user);
    controller.set('location', models.location);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles
    var default_terminal = user.get('ui_prefs.default_terminal'); // Set a default terminal
    if (models.terminals.get('length') > 0 && !default_terminal) {
      user.set('ui_prefs.default_terminal', models.terminals.get('firstObject').get('id'));
    }
    if (models.locations.get('length') < 20) { // Only set locations if its less than 20, this way we get a drop down and not a type ahead
      controller.set('locationList', models.locations);
    }
    if (models.domains.get('length') < 20) { // Only set domain if its less than 20, this way we get a drop down and not a type ahead
      controller.set('domainList', models.domains);
    }

    controller.set('terminals', models.terminals);
    controller.notifyPropertyChange('userTypes');

    var loggedInUserCanEditViewedUser = this.get('usersMethods').loggedInUserCanEditViewedUser(user);
    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-users-delete') && loggedInUserCanEditViewedUser,
      canPost: this.get('session.authenticated.resources.v2-users-post') && loggedInUserCanEditViewedUser,
      canPut: this.get('session.authenticated.resources.v2-users-put') && loggedInUserCanEditViewedUser
    });
    controller.set('loggedInUserCanEditViewedUser', loggedInUserCanEditViewedUser);
    controller.set('userIsActive',  user.get('isActive'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● ' + user.get('username'),
      route_name: 'locations.users-view',
      route_id: models.location.get('id'),
      route_id_plus: user.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Users',
      link: 'locations.users-index',
      record: models.location.get('id')
    }, {
      title: user.get('username'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var model = this.controller.get('user');
      model.set('keyUrl', null);
      this.get('unsavedInfo').warning(model, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
