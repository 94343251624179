import Ember from 'ember';

export default Ember.Service.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  accountTypes: [{
    'id': 'visa',
    'title': 'Visa'
  }, {
    'id': 'mc',
    'title': 'MC'
  }, {
    'id': 'disc',
    'title': 'Disc'
  }, {
    'id': 'amex',
    'title': 'Amex'
  }, {
    'id': 'jcb',
    'title': 'JCB'
  }, {
    'id': 'diners',
    'title': 'Diners'
  }, {
    'id': 'checking',
    'title': 'Checking'
  }, {
    'id': 'savings',
    'title': 'Saving'
  }, {
    'id': 'debit',
    'title': 'Debit'
  }],
  ach_default_trans_types: [{
    'id': 'debit',
    'title': 'Debit (Collect)'
  }, {
    'id': 'credit',
    'title': 'Credit (Send)'
  }],
  achIsCompany: [{
    'id': '0',
    'title': 'Personal'
  }, {
    'id': '1',
    'title': 'Business'
  }],
  ach_sec_codes_is_company: [{
    id: 'CCD',
    title: 'CCD'
  }],
  ach_sec_codes_not_company: [{
    id: 'POP',
    title: 'POP'
  }, {
    id: 'PPD',
    title: 'PPD'
  }, {
    id: 'TEL',
    title: 'TEL'
  }, {
    id: 'WEB',
    title: 'WEB'
  }],
  ach_trans_actions: Ember.computed('session.authenticated.resources.v2-transactions-post-debit', 'session.authenticated.resources.v2-transactions-post-credit', function() {
    // Read in session privs to return available actions
    var session = this.get('session');
    var actions = [];
    if (session.get('authenticated.resources.v2-transactions-post-debit')) {
      actions.push({
        'id': 'debit',
        'title': 'Collect (Debit)'
      });
    }
    if (session.get('authenticated.resources.v2-transactions-post-credit')) {
      actions.push({
        'id': 'credit',
        'title': 'Send (Credit)'
      });
    }
    return actions;
  }),
  active_status: [{
    'id': '0',
    'title': 'Inactive'
  }, {
    'id': '1',
    'title': 'Active'
  }],
  all_ach_sec_codes: Ember.computed(function() {
    let arr = [];
    return arr.concat(this.get('ach_sec_codes_is_company')).concat(this.get('ach_sec_codes_not_company'));
  }),
  allUserTypes: Ember.computed('session.authenticated.user_type_id', function() {
    // Read in session user_type_id to return available userTypes
    var user_type_id = this.get('session.authenticated.user_type_id');

    var uTypes = [{
      'id': '100',
      'title': 'Contact User'
    }];
    if (user_type_id >= 200) {
      uTypes.push({
        'id': '200',
        'title': 'Location User'
      });
    }
    if (user_type_id >= 250) {
      uTypes.push({
        'id': '250',
        'title': 'Location Admin'
      });
    }
    if (user_type_id >= 300) {
      uTypes.push({
        'id': '300',
        'title': 'Support Level 1'
      });
    }
    if (user_type_id >= 400) {
      uTypes.push({
        'id': '400',
        'title': 'Support Level 2'
      });
    }
    if (user_type_id >= 500) {
      uTypes.push({
        'id': '500',
        'title': 'Support Level 3'
      });
    }
    if (user_type_id >= 600) {
      uTypes.push({
        'id': '600',
        'title': 'Support Level 4'
      });
    }
    if (user_type_id >= 700) {
      uTypes.push({
        'id': '700',
        'title': 'Support Level 5'
      });
    }
    if (user_type_id >= 800) {
      uTypes.push({
        'id': '800',
        'title': 'Support Level 6'
      });
    }
    if (user_type_id >= 900) {
      uTypes.push({
        'id': '900',
        'title': 'Support Level 7'
      });
    }
    if (user_type_id >= 1000) {
      uTypes.push({
        'id': '1000',
        'title': 'Super Admin'
      });
    }
    return uTypes;
  }),
  batch_close_types: [{
    'id': 'automatic',
    'title': 'Automatic'
  }, {
    'id': 'manual',
    'title': 'Manual'
  }],
  cau_subscribe_options: [{
    'id': '0',
    'title': 'Manual'
  }, {
    'id': '1',
    'title': 'Always'
  }, {
    'id': '2',
    'title': 'Recurring Billings Only'
  }],
  cau_subscription_options: [{
    'id': 'subscribe_all',
    'title': 'Subscribe All Accounts'
  }, {
    'id': 'subscribe_recurring_only',
    'title': 'Subscribe Only Recurring Billings Accounts'
  }, {
    'id': 'unsubscribe_all',
    'title': 'Unsubscribe All Accounts'
  }, {
    'id': 'unsubscribe_non_recurring',
    'title': 'Unsubscribe Non-Recurring Billings Accounts'
  }],
  cc_default_trans_types: [{
    'id': 'sale',
    'title': 'Sale'
  }, {
    'id': 'authonly',
    'title': 'AuthOnly'
  }],
  cc_trans_actions: Ember.computed('session.authenticated.resources.v2-transactions-post-sale', 'session.authenticated.resources.v2-transactions-post-authonly', 'session.authenticated.resources.v2-transactions-post-refund', 'session.authenticated.resources.v2-transactions-post-force', 'session.authenticated.resources.v2-transactions-post-avsonly', function() {
    // Read in session privs to return available actions
    var session = this.get('session');
    var actions = [];
    if (session.get('authenticated.resources.v2-transactions-post-sale')) {
      actions.push({
        'id': 'sale',
        'title': 'Sale'
      });
    }
    if (session.get('authenticated.resources.v2-transactions-post-authonly')) {
      actions.push({
        'id': 'authonly',
        'title': 'AuthOnly'
      });
    }
    if (session.get('authenticated.resources.v2-transactions-post-refund')) {
      actions.push({
        'id': 'refund',
        'title': 'Refund'
      });
    }
    if (session.get('authenticated.resources.v2-transactions-post-force')) {
      actions.push({
        'id': 'force',
        'title': 'Force'
      });
    }
    if (session.get('authenticated.resources.v2-transactions-post-avsonly')) {
      actions.push({
        'id': 'avsonly',
        'title': 'AVS Only'
      });
    }
    return actions;
  }),
  clientWidth: null,
  contactEntryPages: [{
    'id': 'dashboard',
    'title': 'Dashboard'
  }, {
    'id': 'makepayment',
    'title': 'Make Payment'
  }, {
    'id': 'paymenthistory',
    'title': 'Payment History'
  }, {
    'id': 'accounts',
    'title': 'Accounts'
  }, {
    'id': 'recurrings',
    'title': 'Recurrings'
  }],
  dateFiltersAll: [{
    'id': '',
    'title': 'All Time'
  }, {
    'id': 'last 90 days',
    'title': 'Last 90 Days'
  }, {
    'id': 'last month',
    'title': 'Last Month'
  }, {
    'id': 'last week',
    'title': 'Last Week'
  }, {
    'id': 'yesterday',
    'title': 'Yesterday'
  }, {
    'id': 'today',
    'title': 'Today'
  }, {
    'id': 'tomorrow',
    'title': 'Tomorrow'
  }, {
    'id': 'next week',
    'title': 'Next Week'
  }, {
    'id': 'next month',
    'title': 'Next Month'
  }, {
    'id': 'custom',
    'title': 'Custom'
  }],
  dateFiltersFuture: [{
    'id': '',
    'title': 'All Time'
  }, {
    'id': 'today',
    'title': 'Today'
  }, {
    'id': 'tomorrow',
    'title': 'Tomorrow'
  }, {
    'id': 'this week',
    'title': 'This Week'
  }, {
    'id': 'next week',
    'title': 'Next Week'
  }, {
    'id': 'next 30 days',
    'title': 'Next 30 Days'
  }, {
    'id': 'this month',
    'title': 'This Month'
  }, {
    'id': 'next month',
    'title': 'Next Month'
  }, {
    'id': 'custom',
    'title': 'Custom'
  }],
  dateFiltersPast: [{
    'id': '',
    'title': 'All Time'
  }, {
    'id': 'today',
    'title': 'Today'
  }, {
    'id': 'yesterday',
    'title': 'Yesterday'
  }, {
    'id': 'this week',
    'title': 'This Week'
  }, {
    'id': 'last week',
    'title': 'Last Week'
  }, {
    'id': 'last 30 days',
    'title': 'Last 30 Days'
  }, {
    'id': 'last 90 days',
    'title': 'Last 90 Days'
  }, {
    'id': 'this month',
    'title': 'This Month'
  }, {
    'id': 'last month',
    'title': 'Last Month'
  }, {
    'id': 'custom',
    'title': 'Custom'
  }],
  dateFiltersTransaction: [{
    'id': 'today',
    'title': 'Today'
  }, {
    'id': 'yesterday',
    'title': 'Yesterday'
  }, {
    'id': 'this week',
    'title': 'This Week'
  }, {
    'id': 'last week',
    'title': 'Last Week'
  }, {
    'id': 'last 30 days',
    'title': 'Last 30 Days'
  }, {
    'id': 'last 90 days',
    'title': 'Last 90 Days'
  }, {
    'id': 'this month',
    'title': 'This Month'
  }, {
    'id': 'last month',
    'title': 'Last Month'
  }, {
    'id': 'custom',
    'title': 'Custom'
  }],
  domainThemes: [{
    id: 'default',
    title: 'Default'
  }, {
    id: 'pastel',
    title: 'Pastel'
  }, {
    id: 'pay',
    title: 'Pay'
  }, {
    id: 'winter',
    title: 'Winter'
  }],
  exp_months: [{
    'id': '01',
    'title': '01 - January'
  }, {
    'id': '02',
    'title': '02 - February'
  }, {
    'id': '03',
    'title': '03 - March'
  }, {
    'id': '04',
    'title': '04 - April'
  }, {
    'id': '05',
    'title': '05 - May'
  }, {
    'id': '06',
    'title': '06 - June'
  }, {
    'id': '07',
    'title': '07 - July'
  }, {
    'id': '08',
    'title': '08 - August'
  }, {
    'id': '09',
    'title': '09 - September'
  }, {
    'id': '10',
    'title': '10 - October'
  }, {
    'id': '11',
    'title': '11 - November'
  }, {
    'id': '12',
    'title': '12 - December'
  }],
  exp_years: Ember.computed(function() {
    var years = [];
    var year = new Date().getFullYear();
    for (var i = 0; i < 17; i++) {
      years[i] = {
        'id': (parseInt(year.toString().substr(2, 2)) + i).toString(),
        'title': (year + i).toString()
      };
    }
    return years;
  }),
  export_format: Ember.computed('session.authenticated.token', function() {
    return this.get('session.authenticated.ui_prefs.report_export_type') || 'csv';
  }),
  exportFormats: [{
    'id': 'csv',
    'title': 'CSV'
  }, {
    'id': 'tsv',
    'title': 'TSV'
  }, {
    'id': 'xls',
    'title': 'Excel 95'
  }, {
    'id': 'xlsx',
    'title': 'Excel 2007'
  }],
  extra_flags: [{
    'id': '',
    'title': 'None'
  }, {
    'id': 'no_show',
    'title': 'No Show'
  }, {
    'id': 'advance_deposit',
    'title': 'Advance Deposit'
  }],
  featureFlagsEnableTypes: [{
    'id': '0',
    'title': 'Off for All'
  }, {
    'id': '1',
    'title': 'On for All'
  }, {
    'id': '2',
    'title': 'On Per User'
  }],
  industry_types: [{
    id: 'ecommerce',
    title: 'E-Commerce'
  }, {
    id: 'restaurant',
    title: 'Food/Restaurant'
  }, {
    id: 'lodging',
    title: 'Hotel/Lodging'
  }, {
    id: 'moto',
    title: 'MOTO'
  }, {
    id: 'retail',
    title: 'Retail'
  }, {
    id: 'retail self serve',
    title: 'Retail Self Serve (kiosk)'
  }],
  intervals: [{
    'id': 'd',
    'title': 'Days'
  }, {
    'id': 'w',
    'title': 'Weeks'
  }, {
    'id': 'm',
    'title': 'Months'
  }],
  isMobileSize: Ember.computed('clientWidth', function() {
    return window.matchMedia('(max-width: 767px)').matches;
  }),
  level3_defaults: '{"customer_vat_registration": null,"destination_country_code": "840","duty_amount": "0.00","freight_amount": "0.00","merchant_vat_registration": null,"national_tax": "0.00","order_date": null,"sales_tax": "0.00","shipfrom_zip_code": null,"shipto_zip_code": null,"summary_commodity_code": "9999","tax_amount": "0.00","tax_exempt": "0","tax_rate": "5.00","unique_vat_ref_number": null,"line_items": [{"alternate_tax_id": null,"commodity_code": "9999","debit_credit": "D","description": "MISC","discount_amount": "0.00","discount_rate":"0.00","other_tax_amount": "0.00","product_code": "999999","quantity": "1","tax_amount": "0.00","tax_rate": "0.00","tax_type_applied": null,"tax_type_id": "00","unit_code": "EA","unit_cost": "0.0000"}]}',
  level3_lineitem_debitcredit: [{
    id: 'C',
    title: 'Credit'
  }, {
    id: 'D',
    title: 'Debit'
  }],
  page_size: Ember.computed('session.authenticated.token', function() {
    var session = this.get('session');
    return session.get('authenticated.ui_prefs.page_size') || '15';
  }),
  pageSizes: [{
    'id': '5',
    'title': '5'
  }, {
    'id': '10',
    'title': '10'
  }, {
    'id': '15',
    'title': '15'
  }, {
    'id': '25',
    'title': '25'
  }, {
    'id': '50',
    'title': '50'
  }, {
    'id': '100',
    'title': '100'
  }],
  paymentMethods: [{
    'id': 'cc',
    'title': 'Credit Card'
  }, {
    'id': 'ach',
    'title': 'ACH/EFT'
  }],
  postback_resources: [{
    id: 'accountvault',
    title: 'Account Vault',
  }, {
    id: 'contact',
    title: 'Contact'
  }, {
    id: 'quickinvoice',
    title: 'Quick Invoice'
  }, { 
    id: 'transaction',
    title: 'Transaction'
  }, {
    id: 'transactionbatch',
    title: 'Transaction Batch'
  }],
  processMethods: [{
    'id': 'virtual_terminal',
    'title': 'Virtual Terminal'
  }, {
    'id': 'physical_terminal',
    'title': 'Physical Terminal'
  }],
  processors: Ember.computed(function() {

    var processors = [{
      "id": "zgate2",
      "processor": "zgate2",
      "type": "cc",
      "sub_processor": "tsys",
      "title": "TSYS"
    }, {
      "id": "zgate2_new",
      "processor": "zgate2",
      "type": "cc",
      "sub_processor": "nashville",
      "title": "Nashville Direct"
    }, {
      "id": "zgate2_elavon",
      "processor": "zgate2",
      "type": "cc",
      "sub_processor": "elavon",
      "title": "Elavon"
    }, {
      "id": "zgate2_vantiv",
      "processor": "zgate2",
      "type": "cc",
      "sub_processor": "vantiv610",
      "title": "Vantiv/Worldpay"
    }, {
      "id": "profitstars",
      "processor": "ach",
      "type": "ach",
      "sub_processor": 'profitstars',
      "title": "ACH/EFT - ProfitStars"
    }, {
      "id": "zach",
      "processor": "zach",
      "type": "ach",
      "sub_processor": 'paya',
      "title": "ACH - Paya"
    }];

    if (window.UiENV.environment !== 'payaproduction') {
      processors.push({
          "id": "demo_zgate2",
          "processor": "zgate2",
          "type": "cc",
          "sub_processor": "demo",
          "title": "CC Demo"
        }, {
          "id": "zach_demo",
          "processor": "zach",
          "type": "ach",
          "sub_processor": "demo",
          "title": "ACH Demo"
        });
    }

    return processors;
  }),
  producttaxconfigs: [{
    'id': '2',
    'title': 'Tax After Surcharge'
  }, {
    'id': '3',
    'title': 'Surcharge After Tax'
  }],
  quickinvoice_paymentStatus: [{
    'id': '1',
    'title': 'Unpaid'
  }, {
    'id': '2',
    'title': 'Paid'
  }, {
    'id': '3',
    'title': 'Partially Paid'
  }],
  quickinvoice_status: [{
    'id': '0',
    'title': 'Closed'
  }, {
    'id': '1',
    'title': 'Open'
  }],
  registrationFields: [{
    'id': 'first_name',
    'title': 'First Name'
  }, {
    'id': 'last_name',
    'title': 'Last Name'
  }, {
    'id': 'account_number',
    'title': 'Account Number'
  }, {
    'id': 'email',
    'title': 'Email'
  }, {
    'id': 'company_name',
    'title': 'Company Name'
  }, {
    'id': 'address',
    'title': 'Address'
  }, {
    'id': 'city',
    'title': 'City'
  }, {
    'id': 'state',
    'title': 'State'
  }, {
    'id': 'zip',
    'title': 'Zip Code'
  }, {
    'id': 'home_phone',
    'title': 'Home Phone'
  }, {
    'id': 'office_phone',
    'title': 'Office Phone'
  }, {
    'id': 'cell_phone',
    'title': 'Cell Phone'
  }],
  recurring_statuses: [{
    'id': 'ended',
    'title': 'Ended'
  }, {
    'id': 'active',
    'title': 'Active'
  }, {
    'id': 'on hold',
    'title': 'On Hold'
  }],
  recurring_types: [{
    'id': 'i',
    'title': 'Installment'
  }, {
    'id': 'o',
    'title': 'Ongoing'
  }],
  request_methods: [{
    'id': 'delete',
    'title': 'DELETE'
  }, {
    'id': 'get',
    'title': 'GET'
  }, {
    'id': 'options',
    'title': 'OPTIONS'
  }, {
    'id': 'post',
    'title': 'POST'
  }, {
    'id': 'put',
    'title': 'PUT'
  }],
  response_codes: [{
    'id': '200',
    'title': '200'
  }, {
    'id': '201',
    'title': '201'
  }, {
    'id': '204',
    'title': '204'
  }, {
    'id': '210',
    'title': '210'
  }, {
    'id': '302',
    'title': '302'
  }, {
    'id': '400',
    'title': '400'
  }, {
    'id': '401',
    'title': '401'
  }, {
    'id': '403',
    'title': '403'
  }, {
    'id': '404',
    'title': '404'
  }, {
    'id': '405',
    'title': '405'
  }, {
    'id': '409',
    'title': '409'
  }, {
    'id': '422',
    'title': '422'
  }, {
    'id': '500',
    'title': '500'
  }],
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  sub_processors: [{
    'id': 'fdnash',
    'title': 'First Data (Nashville)'
  }, {
    'id': 'vital',
    'title': 'Tsys (Vital)'
  }],
  terminalmanufacturerIdTypes: Ember.computed(function() {
    return [
      Ember.Object.create({
        'id': "mac",
        "title": "MAC"
      }),
      Ember.Object.create({
        'id': "sn",
        "title": "SN"
      })
    ];
  }),
  trans_reasoncodes: [{
    'id': '0',
    'title': 'N/A'
  }, {
    'id': '1000',
    'title': 'Approved and complete'
  }, {
    'id': '1001',
    'title': 'AuthCompleted'
  }, {
    'id': '1002',
    'title': 'Forced'
  }, {
    'id': '1003',
    'title': 'Invalid Merchant ID'
  }, {
    'id': '1004',
    'title': 'Validation Failure (System Run Transaction)'
  }, {
    'id': '1005',
    'title': 'Processor Response Invalid'
  }, {
    'id': '1006',
    'title': 'Approved - PIN Debit'
  }, {
    'id': '1200',
    'title': 'Voided'
  }, {
    'id': '1201',
    'title': 'Partial Approval'
  }, {
    'id': '1500',
    'title': 'Do not honor'
  }, {
    'id': '1510',
    'title': 'Refer to issuer'
  }, {
    'id': '1518',
    'title': 'Transaction not permittedTerminal'
  }, {
    'id': '1520',
    'title': 'Pickup Card'
  }, {
    'id': '1530',
    'title': 'Re-enter transaction  '
  }, {
    'id': '1531',
    'title': 'Communication Error'
  }, {
    'id': '1540',
    'title': 'Setup Issue, contact Support'
  }, {
    'id': '1541',
    'title': 'Device is not signature capable'
  }, {
    'id': '1588',
    'title': 'Data could not be de-tokenized'
  }, {
    'id': '1599',
    'title': 'Invalid amount'
  }, {
    'id': '1601',
    'title': 'Generic Decline'
  }, {
    'id': '1602',
    'title': 'Call'
  }, {
    'id': '1603',
    'title': 'File is temporarily unavailable'
  }, {
    'id': '1604',
    'title': 'Pickup Card - No Fraud'
  }, {
    'id': '1605',
    'title': 'Pickup Card - Fraud'
  }, {
    'id': '1606',
    'title': 'Pickup Card - Lost'
  }, {
    'id': '1607',
    'title': 'Pickup Card - Stolen'
  }, {
    'id': '1608',
    'title': 'Verification error'
  }, {
    'id': '1609',
    'title': 'Already reversed at switch'
  }, {
    'id': '1610',
    'title': 'Incorrect PIN'
  }, {
    'id': '1611',
    'title': 'Cashback Exceeded'
  }, {
    'id': '1612',
    'title': 'Cashback Not Available'
  }, {
    'id': '1613',
    'title': 'CID Error'
  }, {
    'id': '1614',
    'title': 'Date Error'
  }, {
    'id': '1615',
    'title': 'Do Not Honor'
  }, {
    'id': '1616',
    'title': 'NSF'
  }, {
    'id': '1617',
    'title': 'Exceeded Withdrawal Limit'
  }, {
    'id': '1618',
    'title': 'Transaction not permittedCard'
  }, {
    'id': '1619',
    'title': 'Invalid ABA number, not an ACH participant'
  }, {
    'id': '1620',
    'title': 'Unpaid items, failed negative file check'
  }, {
    'id': '1621',
    'title': 'Cryptographic error'
  }, {
    'id': '1622',
    'title': 'Expired card'
  }, {
    'id': '1623',
    'title': 'Renter'
  }, {
    'id': '1624',
    'title': 'PIN tries exceeded'
  }, {
    'id': '1625',
    'title': 'Card Type Verification Error'
  }, {
    'id': '1626',
    'title': 'Invalid transaction'
  }, {
    'id': '1627',
    'title': 'System malfunction'
  }, {
    'id': '1628',
    'title': 'Invalid Merchant ID'
  }, {
    'id': '1629',
    'title': 'Duplicate Batch (Already Closed)'
  }, {
    'id': '1630',
    'title': 'Batch Rejected'
  }, {
    'id': '1631',
    'title': 'Account Closed'
  }, {
    'id': '1632',
    'title': 'Payment Facilitator Invalid'
  }, {
    'id': '1650',
    'title': 'Contact Support'
  }, {
    'id': '1651',
    'title': 'Max Sending - Throttle Limit Hit'
  }, {
    'id': '1652',
    'title': 'Max Attempts Exceeded'
  }, {
    'id': '1653',
    'title': 'Contact Support'
  }, {
    'id': '1654',
    'title': 'Voided - Online Reversal Failed'
  }, {
    'id': '1655',
    'title': 'Decline (AVS Auto Reversal)'
  }, {
    'id': '1656',
    'title': 'Decline (CVV Auto Reversal)'
  }, {
    'id': '1657',
    'title': 'Decline (Partial Auth Auto Reversal)'
  }, {
    'id': '1658',
    'title': 'Expired Authorization'
  }, {
    'id': '1659',
    'title': 'Declined - Partial Approval not Supported'
  }, {
    'id': '1660',
    'title': 'Bank Account Error, please delete and re-add Account Vault'
  }, {
    'id': '1661',
    'title': 'Declined AuthIncrement'
  }, {
    'id': '1662',
    'title': 'Auto Reversal - Processor cant settle'
  }, {
    'id': '1663',
    'title': 'Manager Override Needed'
  }, {
    'id': '1664',
    'title': 'Account Vault Inaccessible'
  }, {
    'id': '1800',
    'title': 'CVV2 Value supplied is invalid'
  }, {
    'id': '1801',
    'title': 'Duplicate check number'
  }, {
    'id': '1802',
    'title': 'MID/TID Not Registered'
  }, {
    'id': '1803',
    'title': 'Stop Recurring'
  }, {
    'id': '1804',
    'title': 'No Transactions in Batch'
  }, {
    'id': '1805',
    'title': 'Batch Does Not Exist'
  }, {
    'id': '2101',
    'title': 'Rejected-R01'
  }, {
    'id': '2102',
    'title': 'Rejected-R02'
  }, {
    'id': '2103',
    'title': 'Rejected-R03'
  }, {
    'id': '2104',
    'title': 'Rejected-R04'
  }, {
    'id': '2105',
    'title': 'Rejected-R05'
  }, {
    'id': '2106',
    'title': 'Rejected-R06'
  }, {
    'id': '2107',
    'title': 'Rejected-R07'
  }, {
    'id': '2108',
    'title': 'Rejected-R08'
  }, {
    'id': '2109',
    'title': 'Rejected-R09'
  }, {
    'id': '2110',
    'title': 'Rejected-R10'
  }, {
    'id': '2111',
    'title': 'Rejected-R11'
  }, {
    'id': '2112',
    'title': 'Rejected-R12'
  }, {
    'id': '2113',
    'title': 'Rejected-R13'
  }, {
    'id': '2114',
    'title': 'Rejected-R14'
  }, {
    'id': '2115',
    'title': 'Rejected-R15'
  }, {
    'id': '2116',
    'title': 'Rejected-R16'
  }, {
    'id': '2117',
    'title': 'Rejected-R17'
  }, {
    'id': '2118',
    'title': 'Rejected-R18'
  }, {
    'id': '2119',
    'title': 'Rejected-R19'
  }, {
    'id': '2120',
    'title': 'Rejected-R20'
  }, {
    'id': '2121',
    'title': 'Rejected-R21'
  }, {
    'id': '2122',
    'title': 'Rejected-R22'
  }, {
    'id': '2123',
    'title': 'Rejected-R23'
  }, {
    'id': '2124',
    'title': 'Rejected-R24'
  }, {
    'id': '2125',
    'title': 'Rejected-R25'
  }, {
    'id': '2126',
    'title': 'Rejected-R26'
  }, {
    'id': '2127',
    'title': 'Rejected-R27'
  }, {
    'id': '2128',
    'title': 'Rejected-R28'
  }, {
    'id': '2129',
    'title': 'Rejected-R29'
  }, {
    'id': '2130',
    'title': 'Rejected-R30'
  }, {
    'id': '2131',
    'title': 'Rejected-R31'
  }, {
    'id': '2132',
    'title': 'Rejected-R32'
  }, {
    'id': '2133',
    'title': 'Rejected-R33'
  }, {
    'id': '2134',
    'title': 'Rejected-R34'
  }, {
    'id': '2135',
    'title': 'Rejected-R35'
  }, {
    'id': '2136',
    'title': 'Rejected-R36'
  }, {
    'id': '2137',
    'title': 'Rejected-R37'
  }, {
    'id': '2138',
    'title': 'Rejected-R38'
  }, {
    'id': '2139',
    'title': 'Rejected-R39'
  }, {
    'id': '2140',
    'title': 'Rejected-R40'
  }, {
    'id': '2141',
    'title': 'Rejected-R41'
  }, {
    'id': '2142',
    'title': 'Rejected-R42'
  }, {
    'id': '2143',
    'title': 'Rejected-R43'
  }, {
    'id': '2144',
    'title': 'Rejected-R44'
  }, {
    'id': '2145',
    'title': 'Rejected-R45'
  }, {
    'id': '2146',
    'title': 'Rejected-R46'
  }, {
    'id': '2147',
    'title': 'Rejected-R47'
  }, {
    'id': '2150',
    'title': 'Rejected-R50'
  }, {
    'id': '2151',
    'title': 'Rejected-R51'
  }, {
    'id': '2152',
    'title': 'Rejected-R52'
  }, {
    'id': '2153',
    'title': 'Rejected-R53'
  }, {
    'id': '2161',
    'title': 'Rejected-R61'
  }, {
    'id': '2162',
    'title': 'Rejected-R62'
  }, {
    'id': '2163',
    'title': 'Rejected-R63'
  }, {
    'id': '2164',
    'title': 'Rejected-R64'
  }, {
    'id': '2165',
    'title': 'Rejected-R65'
  }, {
    'id': '2166',
    'title': 'Rejected-R66'
  }, {
    'id': '2167',
    'title': 'Rejected-R67'
  }, {
    'id': '2168',
    'title': 'Rejected-R68'
  }, {
    'id': '2169',
    'title': 'Rejected-R69'
  }, {
    'id': '2170',
    'title': 'Rejected-R70'
  }, {
    'id': '2171',
    'title': 'Rejected-R71'
  }, {
    'id': '2172',
    'title': 'Rejected-R72'
  }, {
    'id': '2173',
    'title': 'Rejected-R73'
  }, {
    'id': '2174',
    'title': 'Rejected-R74'
  }, {
    'id': '2180',
    'title': 'Rejected-R80'
  }, {
    'id': '2181',
    'title': 'Rejected-R81'
  }, {
    'id': '2182',
    'title': 'Rejected-R82'
  }, {
    'id': '2183',
    'title': 'Rejected-R83'
  }, {
    'id': '2201',
    'title': 'Rejected-C01'
  }, {
    'id': '2202',
    'title': 'Rejected-C02'
  }, {
    'id': '2203',
    'title': 'Rejected-C03'
  }, {
    'id': '2204',
    'title': 'Rejected-C04'
  }, {
    'id': '2205',
    'title': 'Rejected-C05'
  }, {
    'id': '2206',
    'title': 'Rejected-C06'
  }, {
    'id': '2207',
    'title': 'Rejected-C07'
  }, {
    'id': '2208',
    'title': 'Rejected-C08'
  }, {
    'id': '2209',
    'title': 'Rejected-C09'
  }, {
    'id': '2210',
    'title': 'Rejected-C10'
  }, {
    'id': '2211',
    'title': 'Rejected-C11'
  }, {
    'id': '2212',
    'title': 'Rejected-C12'
  }, {
    'id': '2213',
    'title': 'Rejected-C13'
  }, {
    'id': '2261',
    'title': 'Rejected-C61'
  }, {
    'id': '2262',
    'title': 'Rejected-C62'
  }, {
    'id': '2263',
    'title': 'Rejected-C63'
  }, {
    'id': '2264',
    'title': 'Rejected-C64'
  }, {
    'id': '2265',
    'title': 'Rejected-C65'
  }, {
    'id': '2266',
    'title': 'Rejected-C66'
  }, {
    'id': '2267',
    'title': 'Rejected-C67'
  }, {
    'id': '2268',
    'title': 'Rejected-C68'
  }, {
    'id': '2269',
    'title': 'Rejected-C69'
  }, {
    'id': '2301',
    'title': 'Rejected-X01'
  }, {
    'id': '2304',
    'title': 'Rejected-X04'
  }, {
    'id': '2305',
    'title': 'Rejected-X05'
  }, {
    'id': '2306',
    'title': 'Rejected-X06'
  }, {
    'id': '2307',
    'title': 'Rejected-X07'
  }, {
    'id': '2308',
    'title': 'Rejected-X08'
  }, {
    'id': '2309',
    'title': 'Rejected-X09'
  }, {
    'id': '2310',
    'title': 'Rejected-X10'
  }, {
    'id': '2321',
    'title': 'Rejected-X21'
  }, {
    'id': '2322',
    'title': 'Rejected-X22'
  }, {
    'id': '2323',
    'title': 'Rejected-X23'
  }, {
    'id': '2329',
    'title': 'Rejected-X29'
  }, {
    'id': '2345',
    'title': 'Rejected-X45'
  }, {
    'id': '2371',
    'title': 'Rejected-X71'
  }, {
    'id': '2372',
    'title': 'Rejected-X72'
  }, {
    'id': '2373',
    'title': 'Rejected-X73'
  }],
  trans_sources: [{
      'id': '1',
      'title': 'Unknown'
    }, {
      'id': '2',
      'title': 'Mobile'
    }, {
      'id': '3',
      'title': 'Web'
    }, {
      'id': '4',
      'title': 'IVR'
    }, {
      'id': '5',
      'title': 'Contact Statement'
    }, {
      'id': '6',
      'title': 'Contact Payment Mobile'
    }, {
      'id': '7',
      'title': 'Contact Payment'
    }, {
      'id': '8',
      'title': 'Quick Invoice'
    }, {
      'id': '9',
      'title': 'PayForm'
    }, {
      'id': '10',
      'title': 'Hosted Payment Page'
    }, {
      'id': '11',
      'title': 'Emulator'
    }, {
      'id': '12',
      'title': 'Integration'
    }, {
      'id': '13',
      'title': 'Recurring Billing'
    },
    // {
    //   'id': '14',
    //   'title': 'Recurring Secondary'
    // },
    {
      'id': '15',
      'title': 'Declined Recurring Email'
    }
  ],
  trans_statuses: [{
    'id': '101',
    'title': 'Approved'
  }, {
    'id': '102',
    'title': 'AuthOnly'
  }, {
    'id': '111',
    'title': 'Refunded'
  }, {
    'id': '121',
    'title': 'AvsOnly'
  }, {
    'id': '131',
    'title': 'Pending Origination'
  }, {
    'id': '132',
    'title': 'Originating'
  }, {
    'id': '133',
    'title': 'Originated'
  }, {
    'id': '134',
    'title': 'Settled-ACH'
  }, {
    'id': '135',
    'title': 'Reserved'
  }, {
    'id': '136',
    'title': 'Retrying'
  }, {
    'id': '191',
    'title': 'Settled-CC'
  }, {
    'id': '201',
    'title': 'Voided'
  }, {
    'id': '301',
    'title': 'Declined'
  }, {
    'id': '331',
    'title': 'Charged Back'
  }],
  tz: [{
    'tz': 'Pacific/Honolulu',
    'title': 'Pacific/Honolulu (Hawaiian GMT' + moment().tz('Pacific/Honolulu').format().slice(-6) + ')'
  }, {
    'tz': 'America/Anchorage',
    'title': 'America/Anchorage (Alaskan GMT' + moment().tz('America/Anchorage').format().slice(-6) + ')'
  }, {
    'tz': 'America/Los_Angeles',
    'title': 'America/Los_Angeles (Pacific GMT' + moment().tz('America/Los_Angeles').format().slice(-6) + ')'
  }, {
    'tz': 'America/Vancouver',
    'title': 'America/Vancouver (Pacific GMT' + moment().tz('America/Vancouver').format().slice(-6) + ')'
  }, {
    'tz': 'America/Boise',
    'title': 'America/Boise (Mountain GMT' + moment().tz('America/Boise').format().slice(-6) + ')'
  }, {
    'tz': 'America/Denver',
    'title': 'America/Denver (Mountain GMT' + moment().tz('America/Denver').format().slice(-6) + ')'
  }, {
    'tz': 'America/Phoenix',
    'title': 'America/Phoenix (Mountain GMT' + moment().tz('America/Phoenix').format().slice(-6) + ')'
  }, {
    'tz': 'America/Edmonton',
    'title': 'America/Edmonton (Mountain GMT' + moment().tz('America/Edmonton').format().slice(-6) + ')'
  }, {
    'tz': 'America/Chicago',
    'title': 'America/Chicago (Central GMT' + moment().tz('America/Chicago').format().slice(-6) + ')'
  }, {
    'tz': 'America/Menominee',
    'title': 'America/Menominee (Central GMT' + moment().tz('America/Menominee').format().slice(-6) + ')'
  }, {
    'tz': 'America/Indiana/Knox',
    'title': 'America/Indiana/Knox (Central GMT' + moment().tz('America/Indiana/Knox').format().slice(-6) + ')'
  }, {
    'tz': 'America/North_Dakota/Center',
    'title': 'America/North_Dakota/Center (Central GMT' + moment().tz('America/North_Dakota/Center').format().slice(-6) + ')'
  }, {
    'tz': 'America/Winnipeg',
    'title': 'America/Winnipeg (Central GMT' + moment().tz('America/Winnipeg').format().slice(-6) + ')'
  }, {
    'tz': 'America/Detroit',
    'title': 'America/Detroit (Eastern GMT' + moment().tz('America/Detroit').format().slice(-6) + ')'
  }, {
    'tz': 'America/Indiana/Indianapolis',
    'title': 'America/Indiana/Indianapolis (Eastern GMT' + moment().tz('America/Indiana/Indianapolis').format().slice(-6) + ')'
  }, {
    'tz': 'America/Kentucky/Louisville',
    'title': 'America/Kentucky/Louisville (Eastern GMT' + moment().tz('America/Kentucky/Louisville').format().slice(-6) + ')'
  }, {
    'tz': 'America/New_York',
    'title': 'America/New_York (Eastern GMT' + moment().tz('America/New_York').format().slice(-6) + ')'
  }, {
    'tz': 'America/Halifax',
    'title': 'America/Halifax (Atlantic GMT' + moment().tz('America/Halifax').format().slice(-6) + ')'
  }],
  tzPlusGMT: [{
    'tz': 'Pacific/Honolulu',
    'title': 'Pacific/Honolulu (Hawaiian GMT' + moment().tz('Pacific/Honolulu').format().slice(-6) + ')'
  }, {
    'tz': 'America/Anchorage',
    'title': 'America/Anchorage (Alaskan GMT' + moment().tz('America/Anchorage').format().slice(-6) + ')'
  }, {
    'tz': 'America/Los_Angeles',
    'title': 'America/Los_Angeles (Pacific GMT' + moment().tz('America/Los_Angeles').format().slice(-6) + ')'
  }, {
    'tz': 'America/Vancouver',
    'title': 'America/Vancouver (Pacific GMT' + moment().tz('America/Vancouver').format().slice(-6) + ')'
  }, {
    'tz': 'America/Boise',
    'title': 'America/Boise (Mountain GMT' + moment().tz('America/Boise').format().slice(-6) + ')'
  }, {
    'tz': 'America/Denver',
    'title': 'America/Denver (Mountain GMT' + moment().tz('America/Denver').format().slice(-6) + ')'
  }, {
    'tz': 'America/Phoenix',
    'title': 'America/Phoenix (Mountain GMT' + moment().tz('America/Phoenix').format().slice(-6) + ')'
  }, {
    'tz': 'America/Edmonton',
    'title': 'America/Edmonton (Mountain GMT' + moment().tz('America/Edmonton').format().slice(-6) + ')'
  }, {
    'tz': 'America/Chicago',
    'title': 'America/Chicago (Central GMT' + moment().tz('America/Chicago').format().slice(-6) + ')'
  }, {
    'tz': 'America/Menominee',
    'title': 'America/Menominee (Central GMT' + moment().tz('America/Menominee').format().slice(-6) + ')'
  }, {
    'tz': 'America/Indiana/Knox',
    'title': 'America/Indiana/Knox (Central GMT' + moment().tz('America/Indiana/Knox').format().slice(-6) + ')'
  }, {
    'tz': 'America/North_Dakota/Center',
    'title': 'America/North_Dakota/Center (Central GMT' + moment().tz('America/North_Dakota/Center').format().slice(-6) + ')'
  }, {
    'tz': 'America/Winnipeg',
    'title': 'America/Winnipeg (Central GMT' + moment().tz('America/Winnipeg').format().slice(-6) + ')'
  }, {
    'tz': 'America/Detroit',
    'title': 'America/Detroit (Eastern GMT' + moment().tz('America/Detroit').format().slice(-6) + ')'
  }, {
    'tz': 'America/Indiana/Indianapolis',
    'title': 'America/Indiana/Indianapolis (Eastern GMT' + moment().tz('America/Indiana/Indianapolis').format().slice(-6) + ')'
  }, {
    'tz': 'America/Kentucky/Louisville',
    'title': 'America/Kentucky/Louisville (Eastern GMT' + moment().tz('America/Kentucky/Louisville').format().slice(-6) + ')'
  }, {
    'tz': 'America/New_York',
    'title': 'America/New_York (Eastern GMT' + moment().tz('America/New_York').format().slice(-6) + ')'
  }, {
    'tz': 'America/Halifax',
    'title': 'America/Halifax (Atlantic GMT' + moment().tz('America/Halifax').format().slice(-6) + ')'
  }, {
    'tz': 'GMT',
    'title': 'GMT'
  }],
  unit_codes: [{
    id: 'EA',
    title: 'EA - Unknown unit of measure'
  }, {
    id: 'ACR',
    title: 'ACR - Acre (4840 yd2)'
  }, {
    id: 'AMH',
    title: 'AMH - Ampere-hour (3.6 kC)'
  }, {
    id: 'AMP',
    title: 'AMP - Ampere'
  }, {
    id: 'ANN',
    title: 'ANN - Year'
  }, {
    id: 'APZ',
    title: 'APZ - Ounce GB, US (31.10348 g) (tr oz.)'
  }, {
    id: 'ARE',
    title: 'ARE - Are (100 m2)'
  }, {
    id: 'ASM',
    title: 'ASM - Alcoholic strength mass'
  }, {
    id: 'ASV',
    title: 'ASV - Alcoholic strength by volume'
  }, {
    id: 'ATM',
    title: 'ATM - Standard atmosphere (101325 Pa)'
  }, {
    id: 'ATT',
    title: 'ATT - Technical atmosphere (98066.5 Pa)'
  }, {
    id: 'BAR',
    title: 'BAR - Bar'
  }, {
    id: 'BFT',
    title: 'BFT - Board foot'
  }, {
    id: 'BG',
    title: 'BG - Unknown unit of measure'
  }, {
    id: 'BHP',
    title: 'BHP - Brake horsepower (745.7 W)'
  }, {
    id: 'BHX',
    title: 'BHX - Hundred boxes'
  }, {
    id: 'BIL',
    title: 'BIL - Billion Eur (trillion US)'
  }, {
    id: 'BLD',
    title: 'BLD - Dry barrel (115.627 dm3)'
  }, {
    id: 'BLL',
    title: 'BLL - Barrel'
  }, {
    id: 'BQL',
    title: 'BQL - Becquerel'
  }, {
    id: 'BTU',
    title: 'BTU - British thermal unit (1.055 Kilojoules)'
  }, {
    id: 'BUA',
    title: 'BUA - Bushel (35.2391 dm3)'
  }, {
    id: 'BUI',
    title: 'BUI - Bushel (36.36874 dm3)'
  }, {
    id: 'BX',
    title: 'BX - Unknown unit of measure'
  }, {
    id: 'C',
    title: 'C - Unknown unit of measure'
  }, {
    id: 'CA',
    title: 'CA - Unknown unit of measure'
  }, {
    id: 'CCT',
    title: 'CCT - Carrying capacity in metric tons'
  }, {
    id: 'CD',
    title: 'CD - Unknown unit of measure'
  }, {
    id: 'CDL',
    title: 'CDL - Candela'
  }, {
    id: 'CEL',
    title: 'CEL - Celsius degrees'
  }, {
    id: 'CEN',
    title: 'CEN - Hundred'
  }, {
    id: 'CGM',
    title: 'CGM - Centigram'
  }, {
    id: 'CKG',
    title: 'CKG - Coulomb per kg'
  }, {
    id: 'CLF',
    title: 'CLF - Hundred leaves'
  }, {
    id: 'CLT',
    title: 'CLT - Centiliter'
  }, {
    id: 'CMK',
    title: 'CMK - Square centimeter'
  }, {
    id: 'CMT',
    title: 'CMT - Centimeter'
  }, {
    id: 'CNP',
    title: 'CNP - Hundred packs'
  }, {
    id: 'CNT',
    title: 'CNT - Cental GB (45.359237 kg)'
  }, {
    id: 'COU',
    title: 'COU - Coulomb'
  }, {
    id: 'CS',
    title: 'CS - Unknown unit of measure'
  }, {
    id: 'CTM',
    title: 'CTM - Metric carat (200 Mg = 2.10-4 kg)'
  }, {
    id: 'CUR',
    title: 'CUR - Curie'
  }, {
    id: 'CWA',
    title: 'CWA - Hundredweight US (45.3592 kg)'
  }, {
    id: 'D',
    title: 'D - Unknown unit of measure'
  }, {
    id: 'DAA',
    title: 'DAA - Decare'
  }, {
    id: 'DAD',
    title: 'DAD - Ten days'
  }, {
    id: 'DAY',
    title: 'DAY - Day'
  }, {
    id: 'DEC',
    title: 'DEC - Decade (10 years)'
  }, {
    id: 'DLT',
    title: 'DLT - Deciliter'
  }, {
    id: 'DMK',
    title: 'DMK - Square decimeter'
  }, {
    id: 'DMQ',
    title: 'DMQ - Cubic decimeter'
  }, {
    id: 'DMT',
    title: 'DMT - Decimeter'
  }, {
    id: 'DPC',
    title: 'DPC - Dozen pieces'
  }, {
    id: 'DPT',
    title: 'DPT - Displacement tonnage'
  }, {
    id: 'DRA',
    title: 'DRA - Dram US (3.887935 g)'
  }, {
    id: 'DRI',
    title: 'DRI - Dram GB (1.771745 g)'
  }, {
    id: 'DRL',
    title: 'DRL - Dozen rolls'
  }, {
    id: 'DRM',
    title: 'DRM - Drachm gm (3.887935 g)'
  }, {
    id: 'DTH',
    title: 'DTH - Hectokilogram'
  }, {
    id: 'DTN',
    title: 'DTN - Centner / Quintal, metric (100 kg) (decitonne)'
  }, {
    id: 'DWT',
    title: 'DWT - Pennyweight GB, US (1.555174 g)'
  }, {
    id: 'DZ',
    title: 'DZ - Unknown unit of measure'
  }, {
    id: 'DZN',
    title: 'DZN - Dozen'
  }, {
    id: 'DZP',
    title: 'DZP - Dozen packs'
  }, {
    id: 'DZR',
    title: 'DZR - Dozen pairs'
  }, {
    id: 'EAC',
    title: 'EAC - Each'
  }, {
    id: 'FAH',
    title: 'FAH - Fahrenheit degrees'
  }, {
    id: 'FAR',
    title: 'FAR - Farad'
  }, {
    id: 'FOT',
    title: 'FOT - Foot (.3048 m)'
  }, {
    id: 'FT',
    title: 'FT - Unknown unit of measure'
  }, {
    id: 'FTK',
    title: 'FTK - Square foot'
  }, {
    id: 'FTQ',
    title: 'FTQ - Cubic foot'
  }, {
    id: 'G',
    title: 'G - Unknown unit of measure'
  }, {
    id: 'GAL',
    title: 'GAL - Unknown unit of measure'
  }, {
    id: 'GBQ',
    title: 'GBQ - Gigabequerel'
  }, {
    id: 'GFI',
    title: 'GFI - Gram of fissile isotopes'
  }, {
    id: 'GGR',
    title: 'GGR - Great gross (12 gross)'
  }, {
    id: 'GIA',
    title: 'GIA - Gill (11.8294 cm3)'
  }, {
    id: 'GII',
    title: 'GII - Gill (0.142065 dm3)'
  }, {
    id: 'GLD',
    title: 'GLD - Dry gallon (4.404884 dm3)'
  }, {
    id: 'GLI',
    title: 'GLI - Gallon (4.546092 dm3)'
  }, {
    id: 'GLL',
    title: 'GLL - Liquid gallon (3.78541 dm3)'
  }, {
    id: 'GRM',
    title: 'GRM - Gram'
  }, {
    id: 'GRN',
    title: 'GRN - Grain GB, US (64.798910 mg)'
  }, {
    id: 'GRO',
    title: 'GRO - Gross'
  }, {
    id: 'GRT',
    title: 'GRT - Gross (Register) ton'
  }, {
    id: 'GWH',
    title: 'GWH - Gigawatt-hour (1 Million kWh'
  }, {
    id: 'HAR',
    title: 'HAR - Hectare'
  }, {
    id: 'HBA',
    title: 'HBA - Hectobar'
  }, {
    id: 'HGM',
    title: 'HGM - Hectogram'
  }, {
    id: 'HIU',
    title: 'HIU - Hundred international units'
  }, {
    id: 'HLT',
    title: 'HLT - Hectoliter'
  }, {
    id: 'HMQ',
    title: 'HMQ - Million cubic meters'
  }, {
    id: 'HMT',
    title: 'HMT - Hectometer'
  }, {
    id: 'HPA',
    title: 'HPA - Hectoliter of pure alcohol'
  }, {
    id: 'HTZ',
    title: 'HTZ - Hertz'
  }, {
    id: 'HUR',
    title: 'HUR - Hour'
  }, {
    id: 'INH',
    title: 'INH - Inch (25.4 mm)'
  }, {
    id: 'INK',
    title: 'INK - Square inch'
  }, {
    id: 'INQ',
    title: 'INQ - Cubic inch'
  }, {
    id: 'ITM',
    title: 'ITM - Item'
  }, {
    id: 'JOU',
    title: 'JOU - Joule'
  }, {
    id: 'KBA',
    title: 'KBA - Kilobar'
  }, {
    id: 'KEL',
    title: 'KEL - Kelvin'
  }, {
    id: 'KGM',
    title: 'KGM - Kilogram'
  }, {
    id: 'KGS',
    title: 'KGS - Kilogram per second'
  }, {
    id: 'KHZ',
    title: 'KHZ - Kilohertz'
  }, {
    id: 'KJO',
    title: 'KJO - Kilojoule'
  }, {
    id: 'KMH',
    title: 'KMH - Kilometer per hour'
  }, {
    id: 'KMK',
    title: 'KMK - Square kilometer'
  }, {
    id: 'KMQ',
    title: 'KMQ - Kilogram per cubic meter'
  }, {
    id: 'KMT',
    title: 'KMT - Kilometer'
  }, {
    id: 'KNI',
    title: 'KNI - Kilogram of nitrogen'
  }, {
    id: 'KNS',
    title: 'KNS - Kilogram of named substance'
  }, {
    id: 'KNT',
    title: 'KNT - Knot (1 nautical mile per hour)'
  }, {
    id: 'KPA',
    title: 'KPA - Kilopascal'
  }, {
    id: 'KPH',
    title: 'KPH - Kilogram of caustic potash (kilogram of potassium hydroxide)'
  }, {
    id: 'KPO',
    title: 'KPO - Kilogram of potassium oxide'
  }, {
    id: 'KPP',
    title: 'KPP - Kilogram of phosphoric anhydride (kilogram of phosphoric pentoxide)'
  }, {
    id: 'KSD',
    title: 'KSD - Kilogram of substance 90% dry'
  }, {
    id: 'KSH',
    title: 'KSH - Kilogram of caustic soda'
  }, {
    id: 'KTN',
    title: 'KTN - Kilotonne'
  }, {
    id: 'KUR',
    title: 'KUR - Kilogram of uranium'
  }, {
    id: 'KVA',
    title: 'KVA - Kilovolt-ampere'
  }, {
    id: 'KVR',
    title: 'KVR - Kilovar'
  }, {
    id: 'KVT',
    title: 'KVT - Kilovolt'
  }, {
    id: 'KWH',
    title: 'KWH - Kilowatt-hour'
  }, {
    id: 'KWT',
    title: 'KWT - Kilowatt'
  }, {
    id: 'LBR',
    title: 'LBR - Pound GB, US (0.45359237 kg)'
  }, {
    id: 'LBS',
    title: 'LBS - Unknown unit of measure'
  }, {
    id: 'LBT',
    title: 'LBT - Troy pound, US (373.242 g)'
  }, {
    id: 'LEF',
    title: 'LEF - Leaf'
  }, {
    id: 'LPA',
    title: 'LPA - Liter of pure alcohol'
  }, {
    id: 'LTN',
    title: 'LTN - Long ton GB, US (1.0160469 T)'
  }, {
    id: 'LTR',
    title: 'LTR - Liter (1 dm3)'
  }, {
    id: 'LUM',
    title: 'LMN - Lumen'
  }, {
    id: 'LUX',
    title: 'LUX - Lux'
  }, {
    id: 'MAL',
    title: 'MAL - Megaliter'
  }, {
    id: 'MAM',
    title: 'MAM - Megameter'
  }, {
    id: 'MAW',
    title: 'MAW - Megawatt'
  }, {
    id: 'MBE',
    title: 'MBE - Thousand standard brick equivalent'
  }, {
    id: 'MBF',
    title: 'MBF - Thousand board-feet (2.36 m3)'
  }, {
    id: 'MBR',
    title: 'MBR - Millibar'
  }, {
    id: 'MCU',
    title: 'MCU - Millicurie'
  }, {
    id: 'MGM',
    title: 'MGM - Milligram'
  }, {
    id: 'MHZ',
    title: 'MHZ - Megahertz'
  }, {
    id: 'MIK',
    title: 'MIK - Square mile'
  }, {
    id: 'MIL',
    title: 'MIL - Thousand'
  }, {
    id: 'MIN',
    title: 'MIN - Minute'
  }, {
    id: 'MIO',
    title: 'MIO - Million'
  }, {
    id: 'MIU',
    title: 'MIU - Million international units'
  }, {
    id: 'MLD',
    title: 'MLD - Billion US (milliard)'
  }, {
    id: 'MLT',
    title: 'MLT - Milliliter'
  }, {
    id: 'MMK',
    title: 'MMK - Square millimeter'
  }, {
    id: 'MMQ',
    title: 'MMQ - Cubic millimeter'
  }, {
    id: 'MMT',
    title: 'MMT - Millimeter'
  }, {
    id: 'MON',
    title: 'MON - Month'
  }, {
    id: 'MPA',
    title: 'MPA - Megapascal'
  }, {
    id: 'MQH',
    title: 'MQH - Cubic meter per hour'
  }, {
    id: 'MQS',
    title: 'MQS - Cubic meter per second'
  }, {
    id: 'MSK',
    title: 'MSK - Meter per second squared'
  }, {
    id: 'MTK',
    title: 'MTK - Square meter'
  }, {
    id: 'MTQ',
    title: 'MTQ - Cubic meter'
  }, {
    id: 'MTR',
    title: 'MTR - Meter'
  }, {
    id: 'MTS',
    title: 'MTS - Meter per second'
  }, {
    id: 'MVA',
    title: 'MVA - Megavolt-ampere (1000 kva)'
  }, {
    id: 'MWH',
    title: 'MWH - Megawatt-hour (1000 kWh)'
  }, {
    id: 'NAR',
    title: 'NAR - Number of articles'
  }, {
    id: 'NBB',
    title: 'NBB - Number of bobbins'
  }, {
    id: 'NCL',
    title: 'NCL - Number of cells'
  }, {
    id: 'NEW',
    title: 'NEW - Newton'
  }, {
    id: 'NIU',
    title: 'NIU - Number of international units'
  }, {
    id: 'NMB',
    title: 'NMB - Number'
  }, {
    id: 'NMI',
    title: 'NMI - Nautical mile (1852 m)'
  }, {
    id: 'NMP',
    title: 'NMP - Number of packs'
  }, {
    id: 'NMR',
    title: 'NMR - Number of pairs'
  }, {
    id: 'NPL',
    title: 'NPL - Number of parcels'
  }, {
    id: 'NPT',
    title: 'NPT - Number of parts'
  }, {
    id: 'NRL',
    title: 'NRL - Number of rolls'
  }, {
    id: 'NTT',
    title: 'NTT - Net (register) ton'
  }, {
    id: 'OHM',
    title: 'OHM - Ohm'
  }, {
    id: 'ONZ',
    title: 'ONZ - Ounce GB, US (28.349523 g)'
  }, {
    id: 'OZA',
    title: 'OZA - Fluid ounce (29.5735 cm3)'
  }, {
    id: 'OZI',
    title: 'OZI - Fluid ounce (28.413 cm3)'
  }, {
    id: 'PAL',
    title: 'PAL - Pascal'
  }, {
    id: 'PCB',
    title: 'PCB - Piece'
  }, {
    id: 'PCE',
    title: 'PCE - Unknown unit of measure'
  }, {
    id: 'PER',
    title: 'PER - Unknown unit of measure'
  }, {
    id: 'PGL',
    title: 'PGL - Proof gallon'
  }, {
    id: 'PK',
    title: 'PK - Unknown unit of measure'
  }, {
    id: 'PTD',
    title: 'PTD - Dry pint (0.55061 dm3)'
  }, {
    id: 'PTI',
    title: 'PTI - Pint (0.568262 dm3)'
  }, {
    id: 'PTL',
    title: 'PTL - Liquid pint (0.473176 dm3)'
  }, {
    id: 'QAN',
    title: 'QAN - Quarter (of a year)'
  }, {
    id: 'QTD',
    title: 'QTD - Dry quart (1.101221 dm3)'
  }, {
    id: 'QTI',
    title: 'QTI - Quart (1.136523 dm3)'
  }, {
    id: 'QTL',
    title: 'QTL - Liquid quart (0.946353 dm3)'
  }, {
    id: 'QTR',
    title: 'QTR - Quarter, GB (12.700586 kg)'
  }, {
    id: 'RL',
    title: 'RL - Unknown unit of measure'
  }, {
    id: 'RM',
    title: 'RM - Unknown unit of measure'
  }, {
    id: 'RPM',
    title: 'RPM - Revolution per minute'
  }, {
    id: 'RPS',
    title: 'RPS - Revolution per second'
  }, {
    id: 'SAN',
    title: 'SAN - Half year (6 months)'
  }, {
    id: 'SCO',
    title: 'SCO - Score'
  }, {
    id: 'SCR',
    title: 'SCR - Scruple GB, US (1.295982 g)'
  }, {
    id: 'SEC',
    title: 'SEC - Second'
  }, {
    id: 'SET',
    title: 'SET - Set'
  }, {
    id: 'SHT',
    title: 'SHT - Shipping ton'
  }, {
    id: 'SIE',
    title: 'SIE - Siemens'
  }, {
    id: 'SMI',
    title: 'SMI - (Statute) mile (1609.344 m)'
  }, {
    id: 'SST',
    title: 'SST - Short Standard (7200 matches)'
  }, {
    id: 'ST',
    title: 'ST - Unknown unit of measure'
  }, {
    id: 'STI',
    title: 'STI - Stone GB (6.350293 kg)'
  }, {
    id: 'STN',
    title: 'STN - Short ton GB, US (0.90718474 T)'
  }, {
    id: 'TAH',
    title: 'TAH - Thousand ampere-hour'
  }, {
    id: 'TNE',
    title: 'TNE - Metric ton (1000 kg) (tonne (1000 kg))'
  }, {
    id: 'TQD',
    title: 'TQD - Thousand cubic meters per day'
  }, {
    id: 'TPR',
    title: 'TPR - Ten pairs'
  }, {
    id: 'TRL',
    title: 'TRL - Trillion EUR'
  }, {
    id: 'TSD',
    title: 'TSD - Tonne of substance 90% dry'
  }, {
    id: 'TSH',
    title: 'TSH - Ton of steam per hour'
  }, {
    id: 'VLT',
    title: 'VLT - Volt'
  }, {
    id: 'WCD',
    title: 'WCD - Cord (3.63 m3)'
  }, {
    id: 'WEB',
    title: 'WEB - Weber'
  }, {
    id: 'WEE',
    title: 'WEE - Week'
  }, {
    id: 'WHR',
    title: 'WHR - Watt-hour'
  }, {
    id: 'WSD',
    title: 'WSD - Standard'
  }, {
    id: 'WTT',
    title: 'WTT - Watt'
  }, {
    id: 'YDK',
    title: 'YDK - Square yard'
  }, {
    id: 'YDQ',
    title: 'YDQ - Cubic yard'
  }, {
    id: 'YRD',
    title: 'YRD - Yard (0.9144 m)'
  }],
  userEntryPages: [{
    'id': 'dashboard',
    'title': 'Dashboard'
  }, {
    'id': 'virtualterminal',
    'title': 'Virtual Terminal'
  }, {
    'id': 'contacts',
    'title': 'Contact Listing'
  }, {
    'id': 'locations',
    'title': 'Location Listing'
  }],
  userTypes: Ember.computed('session.authenticated.user_type_id', function() {
    // Read in session privs to return available actions
    var user_type_id = this.get('session.authenticated.user_type_id');

    var uTypes = [];
    if (user_type_id >= 200) {
      uTypes.push({
        'id': '200',
        'title': 'Location User'
      });
    }
    if (user_type_id >= 250) {
      uTypes.push({
        'id': '250',
        'title': 'Location Admin'
      });
    }
    if (user_type_id >= 300) {
      uTypes.push({
        'id': '300',
        'title': 'Support Level 1'
      });
    }
    if (user_type_id >= 400) {
      uTypes.push({
        'id': '400',
        'title': 'Support Level 2'
      });
    }
    if (user_type_id >= 500) {
      uTypes.push({
        'id': '500',
        'title': 'Support Level 3'
      });
    }
    if (user_type_id >= 600) {
      uTypes.push({
        'id': '600',
        'title': 'Support Level 4'
      });
    }
    if (user_type_id >= 700) {
      uTypes.push({
        'id': '700',
        'title': 'Support Level 5'
      });
    }
    if (user_type_id >= 800) {
      uTypes.push({
        'id': '800',
        'title': 'Support Level 6'
      });
    }
    if (user_type_id >= 900) {
      uTypes.push({
        'id': '900',
        'title': 'Support Level 7'
      });
    }
    if (user_type_id >= 1000) {
      uTypes.push({
        'id': '1000',
        'title': 'Super Admin'
      });
    }
    return uTypes;
  }),
  yesNo: [{
    'id': '0',
    'title': 'No'
  }, {
    'id': '1',
    'title': 'Yes'
  }],
  yesNoAny: [{
    'id': '',
    'title': 'Any',
  }, {
    'id': '0',
    'title': 'No'
  }, {
    'id': '1',
    'title': 'Yes'
  }],
  yesNoBoolean: [{
    'id': false,
    'title': 'No'
  }, {
    'id': true,
    'title': 'Yes'
  }],
  showPasswordResetMessage:false,
  country_code: [{
    "ID":"USA",
    "country_code":"840",
    "label":"USA - 840"
  }, {
    "ID":"Canada",
    "country_code":"124",
    "label":"CAD - 124"
  }, {
    "ID":"USVI",
    "country_code":"850",
    "label":"USVI - 850"
  }], 
});
