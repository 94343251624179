import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsTags: true,

  // Set controller params
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('tag.errors.messages', function() {
    this.set('errorMessages', this.get('tag.errors.messages'));
  }),

  // Actions
  actions: {
    delete: function() {
      var self = this;
      var tag = this.get('tag');
      pretty_confirm('Are you sure you want to delete this Tag?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        tag.deleteRecord();
        tag.save().then(function() {
          tag.unloadRecord();
          noty({
            text: 'Tag deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('locations.tags', self.get('location.id'));
        }, function() {
          tag.rollbackAttributes();
          noty({
            text: 'Error deleting Tag!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var tag = this.get('tag');
      var location = this.get('location');

      tag.save().then(function() {
        noty({
          text: 'Tag saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.tags', location.get('id'));
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.tags', location.get('id'));
    }
  }
});
