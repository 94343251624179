import Ember from "ember";

export default Ember.Component.extend({
  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),
  sessionService: Ember.inject.service("session"),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
  isMobileSize: Ember.computed("applicationData.isMobileSize", function () {
    return this.get("applicationData.isMobileSize");
  }),

  // Events

  init: function () {
    this._super();
    if (this.get("cell")) {
      this.set("tagName", "td");
    } else {
      this.set("tagName", "div");
    }
  },

  didInsertElement: function () {
    this._super();
    var self = this;
    if (this.get("cell")) {
      this.set("isDropup", this.get("dropup") ? true : false);
      var params = this.get("cell.params");
      for (var paramsProp in params) {
        if (params.hasOwnProperty(paramsProp)) {
          self.set(paramsProp, params[paramsProp]);
        }
      }
      var actions = this.get("cell.actions");
      for (var actionsProp in actions) {
        if (actions.hasOwnProperty(actionsProp)) {
          self.set(actionsProp, actions[actionsProp]);
        }
      }
      if (this.get("isTypeAccountVault")) {
        this.set("account", this.get("cell.action_data"));
      }
      if (this.get("isTypeRecurring")) {
        this.set("recurring", this.get("cell.action_data"));
      }
      var extraData = this.get("cell.extra_data");
      for (var extraDataProps in extraData) {
        if (extraData.hasOwnProperty(extraDataProps)) {
          self.set(extraDataProps, extraData[extraDataProps]);
        }
      }
    }
  },

  // Computed Properties

  btnId: Ember.computed(
    "isTypeLocationProducts",
    "isTypeRecurring",
    "isTypeQuickInvoice",
    "isTypeHPP",
    "isTypeAccountVault",
    "dataIndex",
    function () {
      var btnId = "action_btn_";
      if (this.get("isTypeLocationProducts")) {
        return (btnId += "product_" + this.get("item.id"));
      } else if (this.get("isTypeAccountVault")) {
        return (btnId += "account_vault_" + this.get("dataIndex"));
      } else if (this.get("isTypeRecurring")) {
        return (btnId += "recurring_" + this.get("dataIndex"));
      } else if (this.get("isTypeQuickInvoice")) {
        return (btnId += "quickinvoice_" + this.get("dataIndex"));
      } else if (this.get("isTypeHPP")) {
        return (btnId += "hpp_" + this.get("dataIndex"));
      }
    }
  ),

  canBtnBlock: Ember.computed("sameSize", "isMobileSize", function () {
    var sameSize = this.get("sameSize");
    var isMobileSize = this.get("isMobileSize");
    if (isMobileSize && !sameSize) {
      return true;
    } else if (!isMobileSize || (isMobileSize && sameSize)) {
      return false;
    }
  }),
  canUseForRecurring: Ember.computed("canCharge", function () {
    var canCharge = this.get("canCharge");
    var location_id = this.get("account.location_id");
    var locations = this.get("locations");
    var payment_method = this.get("account.payment_method");
    var locationHasProduct = false;
    if (locations && locations.get("length")) {
      var location = locations
        .filter(function (loc) {
          return loc.get("id") === location_id;
        })
        .get("firstObject");
      if (payment_method === "ach") {
        locationHasProduct = location.get("hasProductACH");
      } else if (payment_method === "cc") {
        var products = location.get("product_transactions");
        if (products && products.get("length")) {
          products = products.filter(function (i) {
            return (
              i.get("payment_method") === "cc" &&
              i.get("active") &&
              i.get("industry_type") !== "lodging"
            );
          });
          locationHasProduct = products.get("length") >= 1;
        } else {
          locationHasProduct = false;
        }
      }
    }
    return canCharge && locationHasProduct;
  }),
  canUseForTransaction: Ember.computed(
    "canCharge",
    "account.{location_id,payment_method}",
    function () {
      var canCharge = this.get("canCharge");
      var location_id = this.get("account.location_id");
      var locations = this.get("locations");
      var payment_method = this.get("account.payment_method");
      var locationHasProduct = false;
      if (locations && locations.get("length")) {
        var location = locations
          .filter(function (loc) {
            return loc.get("id") === location_id;
          })
          .get("firstObject");
        if (payment_method === "ach") {
          locationHasProduct = location.get("hasProductACH");
        } else if (payment_method === "cc") {
          locationHasProduct = location.get("hasProductCC");
        }
      }
      return canCharge && locationHasProduct;
    }
  ),
  canCharge: Ember.computed(
    "account.{active,expiring_in_months,payment_method}",
    function () {
      var payment_method = this.get("account.payment_method");
      var isActive = this.get("account.active");
      if (payment_method === "ach") {
        return isActive;
      } else {
        var exp = this.get("account.expiring_in_months");
        if (exp && exp >= 0 && isActive) {
          return true;
        } else {
          return false;
        }
      }
    }
  ),
  canPostDebitOrCredit: Ember.computed(
    "session.authenticated.resources.v2-transactions-post-debit",
    "session.authenticated.resources.v2-transactions-post-credit",
    function () {
      return (
        this.get(
          "session.authenticated.resources.v2-transactions-post-debit"
        ) ||
        this.get("session.authenticated.resources.v2-transactions-post-credit")
      );
    }
  ),
  canPostCCOptions: Ember.computed(
    "session.authenticated.resources.v2-transactions-post-sale",
    "session.authenticated.resources.v2-transactions-post-avsonly",
    "session.authenticated.resources.v2-transactions-post-return",
    "session.authenticated.resources.v2-transactions-post-force",
    function () {
      return (
        this.get("session.authenticated.resources.v2-transactions-post-sale") ||
        this.get(
          "session.authenticated.resources.v2-transactions-post-avsonly"
        ) ||
        this.get(
          "session.authenticated.resources.v2-transactions-post-return"
        ) ||
        this.get("session.authenticated.resources.v2-transactions-post-force")
      );
    }
  ),
  hasPrivsToRunTransaction: Ember.computed(
    "canPostCCOptions",
    "canPostDebitOrCredit",
    function () {
      if (this.get("account.payment_method") === "ach") {
        return this.get("canPostDebitOrCredit");
      }
      if (this.get("account.payment_method") === "cc") {
        return this.get("canPostCCOptions");
      }
    }
  ),
  isActiveItem: Ember.computed("item.active", function () {
    return this.get("item.active");
  }),
  isAlignRight: Ember.computed("align", function () {
    return this.get("align") === "right";
  }),
  isTypeAccountVault: Ember.computed("type", function () {
    return (
      this.get("type") === "cc" ||
      this.get("type") === "ach" ||
      this.get("type") === "accountvault"
    );
  }),
  isTypeHPP: Ember.computed("type", function () {
    return this.get("type") === "hpp";
  }),
  isTypeQuickInvoice: Ember.computed("type", function () {
    return this.get("type") === "quickinvoice";
  }),
  isTypeRecurring: Ember.computed("type", function () {
    return this.get("type") === "recurring";
  }),
  isTypeLocationProducts: Ember.computed("type", function () {
    return this.get("type") === "locationproducts";
  }),
  addRecurringTitle: Ember.computed(
    "canCharge",
    "session.authenticated.isContact",
    "account.isActive",
    "canUseForRecurring",
    function () {
      if (!this.get("account.active")) {
        return "Account Vault is not active";
      } else if (this.get("canCharge") && this.get("canUseForRecurring")) {
        return this.get("session.authenticated.isContact")
          ? "Add Scheduled Payment"
          : "Add Recurring Billing";
      } else if (!this.get("canCharge")) {
        return "Account Vault has expired.";
      } else if (!this.get("canUseForRecurring")) {
        return "No active Deposit Accounts for this Payment Method.";
      } else {
        return "Account Vault unavailable.";
      }
    }
  ),
  launchVirtTermId: Ember.computed("dataIndex", function () {
    return "btn_launch_vt_" + this.get("dataIndex");
  }),
  launchVirtTermTitle: Ember.computed(
    "canCharge",
    "session.authenticated.isContact",
    "account.isActive",
    "canUseForTransaction",
    function () {
      if (!this.get("account.active")) {
        return "Account Vault is not active";
      } else if (this.get("canCharge") && this.get("canUseForTransaction")) {
        return this.get("session.authenticated.isContact")
          ? "Make a Payment"
          : "Run Transaction";
      } else if (!this.get("canCharge")) {
        return "Account Vault has expired.";
      } else if (!this.get("canUseForTransaction")) {
        return "No active Deposit Accounts for this Payment Method.";
      } else {
        return "Account Vault unavailable.";
      }
    }
  ),
  productCanOtherActions: Ember.computed("product", function () {
    var product = this.get("product");
    if (!product) {
      return false;
    } else {
      return (
        product.canActivate ||
        product.canDelete ||
        product.canReregister ||
        product.migrateProduct ||
        product.viewGateway ||
        product.viewPortfolio ||
        product.canBilling ||
        product.canSurcharge ||
        product.canReceipts
      );
    }
  }),
  showAddRecurring: Ember.computed(
    "account.location_id",
    "locations.[]",
    "isTypeAccountVault",
    "session.authenticated.resources.v2-recurrings-post",
    function () {
      var location_id = this.get("account.location_id");
      var locations = this.get("locations");
      var hasPriv = this.get(
        "session.authenticated.resources.v2-recurrings-post"
      );
      if (
        hasPriv &&
        locations &&
        locations.get("length") &&
        this.get("isTypeAccountVault")
      ) {
        var location = locations
          .filter(function (loc) {
            return loc.get("id") === location_id;
          })
          .get("firstObject");
        return location ? location.get("hasProductRecurring") : false;
      } else {
        return false;
      }
    }
  ),
  showSignature: Ember.computed(
    "account.{needSignature,payment_method,location_id}",
    "recurring.{needSignature,payment_method,location_id}",
    "isTypeRecurring",
    "isTypeAccountVault",
    "locations.[]",
    function () {
      if (this.get("session.authenticated.isContact")) {
        return false;
      }
      var modelType = null;
      var signatureType = null;
      if (this.get("isTypeRecurring")) {
        modelType = "recurring";
        signatureType = "receipt_add_recurring_above_signature";
      } else if (this.get("isTypeAccountVault")) {
        modelType = "account";
        signatureType = "receipt_add_account_above_signature";
      }
      var model = this.get(modelType);
      var payment_method = model.get("payment_method");
      var locations = this.get("locations");
      var products = this.get("products");
      var product = null;
      if (
        locations &&
        locations.get("length") &&
        this.get("isTypeAccountVault")
      ) {
        var location = locations
          .filter(function (loc) {
            return loc.get("id") === model.get("location_id");
          })
          .get("firstObject");
        product =
          payment_method === "cc"
            ? location.get("defaultProductCC") || null
            : location.get("defaultProductACH") || null;
        return product ? product.get(signatureType) : false;
      } else if (
        products &&
        products.get("length") &&
        this.get("isTypeRecurring")
      ) {
        product = products
          .filter(function (prod) {
            return prod.id === model.get("product_transaction_id");
          })
          .get("firstObject");
        return product ? product[signatureType] : false;
      } else {
        return false;
      }
    }
  ),
  disableAccountDelete: Ember.computed("account", function () {
    return this.get("account.has_recurring");
  }),

  isHideBlindPayment: Ember.computed(
    "session.authenticated.isContact",
    "contact.hide_blind_payment",
    "locations.firstObject.hide_blind_payment",
    "locations.firstObject.branding_domain",
    function () {
      const isContactUser = this.get("session.authenticated.isContact");
      const contactHideBlindPayment = this.get("contact.hide_blind_payment");
      const locationHideBlindPayment = this.get(
        "locations.firstObject.hide_blind_payment"
      );
      const domainHideBlindPayment = this.get(
        "locations.firstObject.branding_domain"
      ).get("hide_blind_payment");

      return (
        isContactUser &&
        (contactHideBlindPayment ||
          locationHideBlindPayment ||
          domainHideBlindPayment)
      );
    }
  ),
  // Functions

  // Actions
  actions: {
    actOn: function (model, action) {
      this.actOn(model, action);
    },
    add: function (model) {
      if (this.get("isTypeAccountVault") && !this.get("canUseForRecurring")) {
        return;
      }
      this.add(model);
    },
    delete: function (model) {
      if (this.get("isTypeAccountVault") && this.get("disableAccountDelete")) {
        return;
      }
      this.delete(model);
    },
    launchModal: function (model, modal, other) {
      if (
        modal === "virtualterminal" &&
        (!this.get("canCharge") ||
          !this.get("canUseForTransaction") ||
          this.get("isHideBlindPayment"))
      ) {
        return;
      }
      this.launchModal(model, modal, other);
    },
    getSignature: function (model, type) {
      this.getSignature(model, type);
    },
    goto: function (model, area) {
      this.goto(model, area);
    },
    view: function (model, action) {
      this.view(model, action);
    },
  },
});
