import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        relationship: 'direct',
        sort: 'name',
        page_size: '500'
      })
    });
  },
  setupController: function(controller, models) {
    // Set Controller Properties
    var pagerParams = {
      pageRoute: 'reports.accountexpiration',
      pageModelId: null,
      modelName: 'accountvault',
      showExport: this.get('session.authenticated.resources.v2-accountvaults-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      fields: 'id,title,account_holder_name,account_type,exp_date,first_six,last_four,has_recurring,location_id,contact_id',
      expand: 'location.id,location.name,contact.id,contact.first_name,contact.last_name',
      payment_method: 'cc',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);
    controller.set('locations', models.locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Expiring Account Vaults',
      route_name: 'reports.accountexpiration',
      route_id: null
    });
    controller.set('pageTitle', 'Expiring Account Vaults Report');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'Expiring Account Vaults',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
