import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('sharinggroup.errors.messages', function () {
    this.set('errorMessages', this.get('sharinggroup.errors.messages'));
  }),

  updateAltErrorMessages: Ember.observer('locationsharinggroup.errors.messages', function () {
    this.set('locationsharinggroupErrors', this.get('locationsharinggroup.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminsharinggroups: true,
  isLoading: false,
  isEditing: false,

  showDeleteLocationSharingGroup: Ember.computed('locationsharinggroups.[]', function () {
    const locationsharinggroups = this.get('locationsharinggroups');
    return this.get('session.authenticated.resources.v2admin-locationsharinggroups-delete') && locationsharinggroups.get('length') > 1;
  }),

  actions: {
    back: function () {
      this.transitionToRoute('admin.sharinggroups.index');
    },
    cancel: function () {
      var sharinggroup = this.get('sharinggroup');
      sharinggroup.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function () {
      var self = this;
      var sharinggroup = this.get('sharinggroup');
      pretty_confirm('Are you sure want to delete this Sharing Group?', function () {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        sharinggroup.deleteRecord();
        sharinggroup.save().then(function () {
          sharinggroup.unloadRecord();
          noty({
            text: 'Sharing Group deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.sharinggroups.index');
        }, function () {
          sharinggroup.rollbackAttributes();
          noty({
            text: 'Error deleting Sharing Group!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function () {
      this.set('isEditing', true);
    },
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var sharinggroup = this.get('sharinggroup');
      sharinggroup.save().then(function () {
        noty({
          text: 'Sharing group updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function () {
        self.set('isLoading', false);
      });
    },
    updateLocation: function (data) {
      var self = this;

      var locationinfos = this.get('store').query('locationinfo', {
        name: data,
        page_size: '10'
      });
      locationinfos.then(function (result) {
        self.set('locationinfos', result);
      });
    },
    addLocation: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var locationsharinggroup = this.get('locationsharinggroup');
      var sharinggroup = this.get('sharinggroup');

      locationsharinggroup.save().then(function () {
        noty({
          text: 'Sharing Group Location added successfully!'
        });

        locationsharinggroup.unloadRecord();
        var sgroup = self.get('store').createRecord('admin_locationsharinggroup', {
          sharing_group_id: sharinggroup.get('id'),
          location_id: null
        });

        self.set('locationsharinggroup', sgroup);
        self.init();
        self.send('refreshData');
        self.set('isLoading', false);
      }, function () {
        self.set('isLoading', false);
        noty({
          text: 'Error creating Sharing Group Location!',
          type: 'error'
        });
      });
    },
    deleteLocation: function (location_sharing_group) {
      var self = this;
      pretty_confirm('Are you sure want to delete the Sharing Group Location?', deletelocationsharinggroup);

      function deletelocationsharinggroup() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        location_sharing_group.deleteRecord();
        location_sharing_group.save().then(function () {
          noty({
            text: 'Sharing Group Location deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function () {
          location_sharing_group.rollbackAttributes();
          noty({
            text: 'Error deleting Sharing Group Location!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
  }
});
