import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransactionBilling: true,
  isLocationsGear: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('newproductitem.errors.messages', function() {
    this.set('addOneTimeBillingItemErrorMessages', this.get('newproductitem.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,
  isEditingBillingItem: false,

  // Computed Properties
  canActOn: Ember.computed('privsA', 'privsMprivsM', 'privsP', function() {
    var canActOnA = this.get('privsA.canActOn');
    var canActOnM = this.get('privsM.canActOn');
    var canActOnP = this.get('privsP.canActOn');
    return canActOnA || canActOnM || canActOnP;
  }),
  isActive: Ember.computed('producttransaction.active', function() {
    return this.get('producttransaction.active');
  }),
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  privsA: Ember.computed('session.authenticated.resources.v2-productitems-delete-a', 'session.authenticated.resources.v2-productitems-post-a', 'session.authenticated.resources.v2-productitems-put-a', function() {
    var canDELETEa = this.get('session.authenticated.resources.v2-productitems-delete-a');
    var canPOSTa = this.get('session.authenticated.resources.v2-productitems-post-a');
    var canPUTa = this.get('session.authenticated.resources.v2-productitems-put-a');
    return {
      delete: canDELETEa,
      post: canPOSTa,
      put: canPUTa,
      canActOn: canDELETEa || canPOSTa || canPUTa
    };
  }),
  privsM: Ember.computed('session.authenticated.resources.v2-productitems-delete-m', 'session.authenticated.resources.v2-productitems-post-m', 'session.authenticated.resources.v2-productitems-put-m', function() {
    var canDELETEm = this.get('session.authenticated.resources.v2-productitems-delete-m');
    var canPOSTm = this.get('session.authenticated.resources.v2-productitems-post-m');
    var canPUTm = this.get('session.authenticated.resources.v2-productitems-put-m');
    return {
      delete: canDELETEm,
      post: canPOSTm,
      put: canPUTm,
      canActOn: canDELETEm || canPOSTm || canPUTm
    };
  }),
  privsO: Ember.computed('session.authenticated.resources.v2-productitems-delete-o', 'session.authenticated.resources.v2-productitems-post-o', 'session.authenticated.resources.v2-productitems-put-o', function() {
    var canDELETEo = this.get('session.authenticated.resources.v2-productitems-delete-o');
    var canPOSTo = this.get('session.authenticated.resources.v2-productitems-post-o');
    var canPUTo = this.get('session.authenticated.resources.v2-productitems-put-o');
    return {
      delete: canDELETEo,
      post: canPOSTo,
      put: canPUTo,
      canActOn: canDELETEo || canPUTo
    };
  }),
  privsP: Ember.computed('session.authenticated.resources.v2-productitems-delete-p', 'session.authenticated.resources.v2-productitems-post-p', 'session.authenticated.resources.v2-productitems-put-p', function() {
    var canDELETEp = this.get('session.authenticated.resources.v2-productitems-delete-p');
    var canPOSTp = this.get('session.authenticated.resources.v2-productitems-post-p');
    var canPUTp = this.get('session.authenticated.resources.v2-productitems-put-p');
    return {
      delete: canDELETEp,
      post: canPOSTp,
      put: canPUTp,
      canActOn: canDELETEp || canPOSTp || canPUTp
    };
  }),
  privsS: Ember.computed('session.authenticated.resources.v2-productitems-delete-s', 'session.authenticated.resources.v2-productitems-post-s', 'session.authenticated.resources.v2-productitems-put-s', function() {
    var canDELETEs = this.get('session.authenticated.resources.v2-productitems-delete-s');
    var canPOSTs = this.get('session.authenticated.resources.v2-productitems-post-s');
    var canPUTs = this.get('session.authenticated.resources.v2-productitems-put-s');
    return {
      delete: canDELETEs,
      post: canPOSTs,
      put: canPUTs,
      canActOn: canDELETEs || canPOSTs || canPUTs
    };
  }),
  product_billing_group: Ember.computed('producttransaction.product_billing_group_id', 'productbillinggroups.[]', function() {
    var productbillinggroups = this.get('productbillinggroups');
    var product_billing_group_id = this.get('producttransaction.product_billing_group_id');
    return productbillinggroups.filter(function(pbg) {
      return pbg.get('id') === product_billing_group_id;
    }).get('firstObject');
  }),

  // Observers

  newProductItemCostObserver: Ember.observer('newproductitem.item_id', function() {
    var oneTimeBillingItems = this.get('oneTimeBillingItems');
    var newproductitem = this.get('newproductitem');
    if (newproductitem.get('item_id')) {
      var item = oneTimeBillingItems.filter(function(p) {
        var item_id = p.get('item_id');
        return item_id === newproductitem.get('item_id');
      }).get('firstObject');
      this.set('newproductitem.cost', item.get('cost'));
    }
  }),

  // Functions

  getNewProductItem: function() {
    var producttransaction = this.get('producttransaction');
    var productitem = this.get('store').createRecord('productitem', {
      cost: '0.0000',
      item_id: null,
      model: 'ProductTransaction',
      model_id: producttransaction.get('id'),
    });
    return productitem;
  },

  // All form actions
  actions: {
    addOneTimeBillingItem: function() {
      var productitem = this.get('newproductitem');
      var self = this;
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      self.set('isLoading', true);
      self.set('isEditingBillingItem', true);
      productitem.save().then(function() {
        noty({
          text: 'One Time Billing Item saved successfully!'
        });
        self.send('refreshData');
        self.set('isLoading', false);
        self.set('isEditingBillingItem', false);
        var newproductitem = self.getNewProductItem();
        self.set('newproductitem', newproductitem);
      }, function() {
        noty({
          text: 'Error saving Billing Item!',
          type: 'error'
        });
        self.set('isLoading', false);
        self.set('isEditingBillingItem', false);
      });
    },
    deleteOneTimeBillingItem: function(productitem) {
      // Bail if we are already loading
      var self = this;
      self.set('isEditingBillingItem', true);
      pretty_confirm('Are you sure want to delete this billing item?', function() {
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        productitem.deleteRecord();
        productitem.save().then(
          function() {
            noty({
              text: 'Billing Item deleted successfully!'
            });
            self.set('isLoading', false);
            self.set('isEditingBillingItem', false);
          },
          function() {
            productitem.rollbackAttributes();
            noty({
              text: 'Error deleting Billing Item!',
              type: 'error'
            });
            self.set('isLoading', false);
            self.set('isEditingBillingItem', false);
          }
        );
      });
      self.set('isEditingBillingItem', false);
    },
    refreshProductItems: function() {
      this.set('oneTimeProductBillingItems', null);
      this.send('refreshData');
    },
    saveNewProductBillingGroup: function() {
      var producttransaction = this.get('producttransaction');
      var self = this;
      self.set('isLoading', true);
      self.set('isEditingBillingItem', true);
      producttransaction.save().then(function() {
        self.set('oneTimeProductBillingItems', null);
        self.send('refreshData');
        self.set('isLoading', false);
        self.set('editingProductBillingGroup', false);
      }, function() {
        self.set('isEditingBillingItem', false);
        self.set('isLoading', false);
      });
    },
    showEditProductBillingGroup: function() {
      this.set('editingProductBillingGroup', true);
    },
    cancelEditProductBillingGroup: function() {
      this.get('producttransaction').rollbackAttributes();
      this.set('editingProductBillingGroup', false);
    }

  }
});
