import Ember from 'ember';

export default Ember.Helper.extend({
  compute(params) {
    var object = params[0];
    var html = '';
    for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
        html +=
          '<tr>' +
          '<td style="white-space:nowrap;">' + prop + '</td>' +
          '<td style="word-break:break-word;">' + object[prop] + '</td>' +
          '</tr>';
      }
    }
    return new Ember.String.htmlSafe(html);
  }
});
