import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsQuickInvoiceSettings: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('quickinvoicesettings.errors.messages', function() {
    this.set('errorMessages', this.get('quickinvoicesettings.errors.messages'));
  }),

  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    reset: function() {
      var quickinvoicesettings = this.get('quickinvoicesettings');
      quickinvoicesettings.rollbackAttributes();
      this.set('isEditing', false);
    },
    save: function() {
      var self = this;
      var quickinvoicesettings = this.get('quickinvoicesettings');
      this.set('isLoading', true);
      quickinvoicesettings.save().then(function() {
        noty({
          text: 'Quick Invoice Settings saved successfully!',
          timeout: 3000,
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        noty({
          text: 'Error saving Quick Invoice Settings!',
          type: 'error'
        });
        self.set('isLoading', false);
      });
    }
  }
});
