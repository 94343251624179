import Ember from 'ember';
import {pluralize} from 'ember-inflector';
import DS from 'ember-data';

export default DS.RESTSerializer.extend({
  keyForRelationship: function(rel, kind) {
    var underscored = '';
    if (kind === 'belongsTo') {
      underscored = rel.underscore();
      return underscored + '_id';
    }
  },
  primaryKey: 'id',

  serializeIntoHash: function(data, type, record, options) {
    var root = Ember.String.decamelize(type.modelName).replace('admin_', '').replace('admin-', '');
    data[root] = this.serialize(record, options);
  },
  serializeBelongsTo: function(snapshot, json, relationship) {
    var key = relationship.key;

    if (this._canSerialize(key)) {
      var belongsToId = snapshot.belongsTo(key, {
        id: true
      });

      // if provided, use the mapping provided by `attrs` in
      // the serializer
      var payloadKey = this._getMappedKey(key);
      if (payloadKey === key && this.keyForRelationship) {
        payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
      }

      if (belongsToId === null) {
        belongsToId = snapshot.get(payloadKey);
      }

      //Need to check whether the id is there for new&async records
      if (Ember.isNone(belongsToId)) {
        json[payloadKey] = null;
      } else {
        json[payloadKey] = belongsToId;
      }

      if (relationship.options.polymorphic) {
        this.serializePolymorphicType(snapshot, json, relationship);
      }
    }
  },
  normalizeQueryRecordResponse(store, primaryModelClass, payload) {
    var modelName = primaryModelClass.modelName;
    var pluralizedModel = pluralize(modelName);
    var singluarizedModel = modelName;
    if(payload[pluralizedModel] && payload[pluralizedModel].length){
      payload[singluarizedModel] = payload[pluralizedModel][0];
      delete payload[pluralizedModel];
    }

    return this._super(...arguments);
  }
});
