import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  router: Ember.inject.service('router'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  currentRouteTitle: Ember.computed('contactRoutes.[]', 'isContactsDashboard', 'isContactsCharge', 'isContactsProfile', 'isContactsViewUser', 'isContactsAccounts', 'isContactsTransactions', 'isContactsRecurrings', 'isContactsQuickInvoices', 'isContactsNotes', 'isContactsFiles', 'isContactsEmails', function() {
    var routes = this.get('contactRoutes');
    var title = '';
    routes.map(function(route){
      if(route.isActive){
        title = route.title;
      }
    });
    return title;
  }),

  contactRoutes: Ember.computed('sessionService.session.authenticated.token', 'isContactsDashboard', 'isContactsCharge', 'isContactsProfile', 'isContactsViewUser', 'isContactsAccounts', 'isContactsTransactions', 'isContactsRecurrings', 'isContactsQuickInvoices', 'isContactsNotes', 'isContactsFiles', 'isContactsEmails', function() {
    var self = this;
    var session = this.get('session');
    var routes = [];
    if(session.get('authenticated.resources.v2-contacts-get')) {
      let routeName = 'contacts.dashboard';
      routes.pushObject({
        title: 'Overview',
        isActive: self.get('isContactsDashboard') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-transactions-post')) {
      let routeName = 'contacts.charge';
      routes.pushObject({
        title: 'Charge',
        isActive: self.get('isContactsCharge') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-contacts-get')) {
      let routeName = 'contacts.view';
      routes.pushObject({
        title: 'Profile',
        isActive: self.get('isContactsProfile') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-users-get') && this.get('contact.user.id')) {
      let routeName = 'contacts.viewuser';
      routes.pushObject({
        title: 'Login Info',
        isActive: self.get('isContactsViewUser') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-transactions-get')) {
      let routeName = 'contacts.transactions';
      routes.pushObject({
        title: 'Transactions',
        isActive: self.get('isContactsTransactions') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-accountvaults-get')) {
      let routeName = 'contacts.accounts-index';
      routes.pushObject({
        title: 'Account Vaults',
        isActive: self.get('isContactsAccounts') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-recurrings-get')) {
      let routeName = 'contacts.recurrings-index';
      routes.pushObject({
        title: 'Recurring Billings',
        isActive: self.get('isContactsRecurrings') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-quickinvoices-get') && this.get('locationinfo.hasQuickInvoice')) {
      let routeName = 'contacts.quickinvoices-index';
      routes.pushObject({
        title: 'Quick Invoices',
        isActive: self.get('isContactsQuickInvoices') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-notes-get') && this.get('locationinfo.show_contact_notes')) {
      let routeName = 'contacts.notes';
      routes.pushObject({
        title: 'Notes',
        isActive: self.get('isContactsNotes') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-files-get') && this.get('locationinfo.show_contact_files')) {
      let routeName = 'contacts.files';
      routes.pushObject({
        title: 'Files',
        isActive: self.get('isContactsFiles') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    if(session.get('authenticated.resources.v2-logemails-get-contact')) {
      let routeName = 'contacts.emails';
      routes.pushObject({
        title: 'Emails',
        isActive: self.get('isContactsEmails') ? 'active' : '',
        routeName: routeName,
        id: this.getId(routeName)
      });
    }
    return routes;
  }),

  // Functions

  getId: function(routeName) {
    if(this.get('router.currentRouteName')) {
      return 'contact_' + routeName.replace('contacts.', '').replace('.', '-') + '_tab';
    }
  },

  actions: {
    refreshData: function() {
      this.get('parent').send('refreshData');
    }
  },
});
