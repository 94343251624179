import Ember from "ember";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";

export default Ember.Route.extend(AuthenticatedRouteMixin, {
  // Dependency Injections
  sessionService: Ember.inject.service("session"),

  // Set Template
  renderTemplate: function () {
    this.render("locations/view", {
      controller: "locations/add",
    });
  },

  model: function () {
    var store = this.get("store");
    return Ember.RSVP.hash({
      location: store.createRecord("admin-location", {
        branding_domain_allow_contact_signup: false,
        branding_domain_allow_contact_login: false,
        branding_domain_allow_contact_registration: false,
        branding_domain_id: null,
        branding_domain_url: null,
        contact_email_trx_receipt_default: false,
        hide_blind_payment: null,
        allowed_expired_token: false,
        is_new_domain: true,
        parent_id: null,
        show_contact_notes: 1,
        show_contact_files: 1,
      }),
    });
  },

  setupController: function (controller, models) {
    // Set Controller Properties
    controller.set("isEditing", true);
    controller.set("location", models.location);

    controller.set("formPrivs", {
      canDelete: this.get(
        "session.authenticated.resources.v2-locations-delete"
      ),
      canPost: this.get("session.authenticated.resources.v2-locations-post"),
      canPut: this.get("session.authenticated.resources.v2-locations-put"),
    });

    // Set Controller Properties
    this.send("addHistory", {
      title: "Add Location",
      route_name: "locations.add",
      route_id: null,
    });
    controller.set("pageTitle", "Add Location");
    controller.set("pageCrumbs", [
      {
        title: "Locations",
        link: "locations",
      },
      {
        title: "Add Location",
        link: "",
      },
    ]);
  },

  // Actions
  actions: {
    willTransition: function () {
      var model = this.currentModel;
      if (model.location.get("hasDirtyAttributes")) {
        model.location.rollbackAttributes();
      }
      return true;
    },
  },

  // Computed Properties
  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
});
