import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  beforeModel: function() {
    var session = this.get('session');
    var entry_page = session.get('authenticated.user.ui_prefs.entry_page');
    var isAuthenticated = session.get('isAuthenticated');
    this.set('applicationData.showPasswordResetMessage',false);
    if (isAuthenticated && session.get('authenticated.isContact')) {
      if (entry_page) {
        this.transitionTo('contactuser.' + entry_page);
      } else {
        this.transitionTo('contactuser.makepayment');
      }
    } else if (isAuthenticated) {
      if (entry_page) {
        this.transitionTo(entry_page);
      } else {
        this.transitionTo('virtualterminal');
      }
    }
  },

  model: function(params) {
    return params;
  },
  setupController: function(controller) {
    // Set Controller Properties
    new Ember.RSVP.Promise(function() {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/termsconditions/latest',
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'GET',
        contentType: 'application/json'
      }).then(function(data) {
        controller.set('termscondition', data.termscondition);
        if (controller.get('termscondition')) {
          controller.set('terms_condition_acceptance', false);
        }
      }, function() {
        noty({
          text: 'Error getting terms and conditions!',
          type: 'error'
        });
      });
    });
    controller.set('password', null);
    controller.set('new_password', null);
    controller.set('isMfaCode', false);
    controller.set('confirm_password', null);
    controller.set('showLogin', true);
    controller.set('loadMessage', 'Authenticating...');
    controller.set('reloadMessage', null);
    jQuery('#maintenance').hide();
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  })
});
