import Ember from 'ember';
import DS from 'ember-data';

export default DS.Adapter.extend({
  trans_types: [{
      'id': '20',
      'title': 'Sale'
    },
    {
      'id': '21',
      'title': 'AvsOnly'
    },
    {
      'id': '22',
      'title': 'Settle'
    },
    {
      'id': '30',
      'title': 'Refund'
    },
    {
      'id': '40',
      'title': 'Credit'
    },
    {
      'id': '50',
      'title': 'Debit'
    }
  ],
  query: function(store, type, query) {
    var self = this;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var t_types = self.get('trans_types');
      var t_type = t_types.filter(function(item) {
        return parseInt(item.id) === parseInt(query.id);
      });
      if (t_type.length > 0) {
        resolve({
          type: t_type
        });
      } else {
        reject('Id not found');
      }
    });
  },
  findAll: function() {
    return {
      type: this.get('trans_types')
    };
  }
});