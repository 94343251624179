import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      })
    });
  },
  afterModel: function(models, transition) {
    var self = this;
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return self.get('store').queryRecord('locationinfo', {
        id: models.contact.get('location_id'),
        expand: 'product_transactions,tags',
        product_transaction_active: '0,1'
      }).then(function(data) {
        return models.locationinfo = data;
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('contact', models.contact);
    controller.set('locationinfo', models.locationinfo);

    var pagerParams = {
      pageRoute: 'contacts.quickinvoices-index',
      pageModelId: models.contact.get('id'),
      modelName: 'quickinvoice',
      showExport: true
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    if (!controller.get('active') && controller.get('active') !== 0) {
      controller.set('active', '0,1');
    }

    // Set data for grid params
    var gridParams = {
      expand: 'created_user,contact,tags,status,all_tags,cc_product_transaction,ach_product_transaction',
      contact_id: models.contact.get('id'),
      show_totals: true
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Quick Invoices',
      route_name: 'contacts.quickinvoices-index',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageSubtitle', {
      title: 'Add Quick Invoice',
      link: 'contacts.quickinvoices-add',
      record: models.contact.get('id'),
      resource: this.get('session.authenticated.resources.v2-quickinvoices-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Quick Invoices',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
