import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        page_size: '500',
        relationship: 'direct',
        product_transaction_active: '0,1'
      }),
    });
  },
  afterModel: function(models, transition) {
    var contact_id = transition.to.queryParams.contact_id;
    var store = this.get('store');
    if(contact_id) {
      return Ember.RSVP.hash({
        initialContact: store.queryRecord('contact', {
          id: contact_id,
          expand: 'location'
        }).then(function(data){
          models.initialContact = data.get('titleWithAccountNumberAndLocation');
        }),
      });
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('locations', models.locations);
    controller.set('initialContact', models.initialContact);

    var pagerParams = {
      pageRoute: 'recurrings.index',
      pageModelId: null,
      modelName: 'recurring',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,contact,location',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);

    var product_transactions = [];
    var tagList = [];
    models.locations.forEach(function(loc) {
      if(loc.get('hasProductRecurring')) {
        if(Ember.isEmpty(tagList)) {
          tagList = loc.get('tags').toArray();
        } else {
          tagList = tagList.concat(loc.get('tags').toArray());
        }
        var prods = loc.get('product_transactions');
        if (prods) {
          prods.forEach(function(product_transaction) {
            product_transactions.push({
              'id': product_transaction.get('id'),
              'title': product_transaction.get('title'),
              'processor': product_transaction.get('processor'),
              'receipt_add_recurring_above_signature': product_transaction.get('receipt_add_recurring_above_signature'),
              'surcharge': product_transaction.get('surcharge')
            });
          });
        }
      }
    });
    controller.set('product_transactions', product_transactions);
    controller.set('tagList', tagList);

    // Set: Page Title - Crumbs - History
    // ** No History entry since route is already in main nav menu
    controller.set('pageTitle', 'Recurring Billings');
    controller.set('pageSubtitle', {
      title: 'Add Recurring Billing',
      link: 'recurrings.add',
      resource: this.get('session.authenticated.resources.v2-recurrings-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Recurring Billings',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
