import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import jQuery from 'jquery';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  getNotificationTemplates: function(id, token, store) {
    return new Promise(function (resolve, reject) {
      jQuery
      .ajax({
        url:
        window.UiENV.APP.FULL_URL +
        "/notificationtemplatesbatch?template_name=batch_close_summary&location_id=" +
        id,
        type: "GET",
        headers: {
          'Content-Type': 'application/json',
          'access-token': token,
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
      })
      .then(
        function (res) {
          const templates = res.notificationtemplate;
          resolve(templates);
        }
      );
    });
  },
  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('locationinfo', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
        product_transaction_active: '0,1'
      }),
      notificationtemplates: this.getNotificationTemplates(params.id, this.get('session').get('authenticated.token'), store),
      processingstatuses: store.findAll('processingstatus'),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var products = models.location.get('product_transactions');
    var cc_products = products.filter(function(product) { // Filter products for cc products only
      return product.get('payment_method') === 'cc';
    });
    controller.set('products', cc_products);
    var product_transaction_id = controller.get('product_transaction_id');
    controller.set('product_transaction_id', product_transaction_id || models.location.get('default_cc') || ((cc_products && cc_products.get('length') > 1) ? cc_products.get('firstObject').get('id') : null));
    controller.set('showProducts', (cc_products && cc_products.get('length') > 1));

    controller.set('producttransactionautosettle', this.get('store').createRecord('producttransactionautosettle', {
      hour: '10',
      minute: '30',
      meridiem: 'pm'
    }));
    
    controller.set('notificationtemplates', models.notificationtemplates);
    controller.set('producttransactionbatchcloseemail', this.get('store').createRecord('producttransactionbatchcloseemail', {
      notification_template_id: null,
      user_id: null
    }));
    controller.set('processingstatuses', models.processingstatuses);
    var pagerParams = {
      pageRoute: 'locations.settle',
      pageModelId: models.location.get('id'),
      modelName: 'transactionbatch'
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));
    
    var gridParams = {
      expand: 'processingstatus.title,log_emails',
      fields: 'id,batch_num,location_id,product_transaction_id,created_ts,processing_status_id,is_open,batch_close_ts,log_emails',
      product_transaction_id: models.location.get('default_cc'),
      product_transaction_active: '0,1',
      location_id: models.location.get('id')
    };
    controller.set('gridParams', gridParams);
    controller.set('location', models.location);
    controller.set('showBatchEmails', false);
    controller.getProductTransactionActive();

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Batch',
      route_name: 'locations.settle',
      route_id: models.location.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Batch',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      jQuery(document).off('click', '.batch_select, .form, .form-group-sm, .fa-times-circle, .type-ahead, .btn, .dropdown-menu', function(e) {
        e.stopPropagation();
      });
      jQuery(document).off('hidden.bs.dropdown', '#btn_add_batch_close_emails_container');
      jQuery(document).off('hidden.bs.dropdown', '#btn_add_batch_close_times_container');
      return true;
    },
    didTransition: function() {
      var self = this;
      jQuery(document).on('click', '.batch_select, .form, .form-group-sm, .fa-times-circle, .type-ahead, .btn, .dropdown-menu', function(e) {
        e.stopPropagation();
      });
      jQuery(document).on('hidden.bs.dropdown', '#btn_add_batch_close_emails_container', function() {
        self.controllerFor('locations.settle').send('cancelAddRecipient', true);
      });
      jQuery(document).on('hidden.bs.dropdown', '#btn_add_batch_close_times_container', function() {
        self.controllerFor('locations.settle').send('cancelAddSettle', true);
      });
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
