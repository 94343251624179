import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsUsers: true,
  isUsersPreferences: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('useruserpref.errors.messages', function() {
    this.set('errorMessages', this.get('useruserpref.errors.messages'));
  }),

  // Computed Properties
  canEdit: Ember.computed('loggedInUserCanEditViewedUser', 'session.authenticated.resources.v2-useruserprefs-post', 'session.authenticated.resources.v2-useruserprefs-delete', function() {
    return this.get('loggedInUserCanEditViewedUser') && (this.get('session.authenticated.resources.v2-useruserprefs-post') || this.get('session.authenticated.resources.v2-useruserprefs-delete'));
  }),
  selectedUserPrefs: Ember.computed('userUserPrefs.[]', function() {
    var userUserPrefs = this.get('userUserPrefs');
    var tempArray = [];
    if (userUserPrefs.get('length')) {
      userUserPrefs.forEach(function(pref) {
        tempArray.push(pref.get('user_pref_id'));
      });
    }
    return tempArray;
  }),

  // Actions
  actions: {
    cancel: function() {
      var changesToMake = this.get('changesToMake');
      changesToMake.forEach(function(change){
        jQuery('#users_preferences_chk-' + change.user_pref_id).trigger('click');
      });
      this.set('changesToMake', []);
      this.set('isEditing', false);
    },
    changeUserPrefs: function(data) {
      if (this.get('isLoading')) {
        return;
      }
      var hasPreferences = this.get('selectedUserPrefs').indexOf(data.id) !== -1;
      var changesToMake = this.get('changesToMake') || [];
      var removingFromChanges = false;
      var tempChanges = changesToMake.filter(function(change) {
        if (change.user_pref_id === data.id) {
          removingFromChanges = true;
          return false;
        } else {
          return true;
        }
      });
      if (!removingFromChanges) {
        tempChanges.pushObject({
          user_pref_id: data.id,
          title: data.title,
          type: hasPreferences ? 'DELETE' : 'POST'
        });
      }
      this.set('changesToMake', tempChanges);
    },
    checkAllUserPrefs: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (!jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      if (this.get('isLoading')) {
        return;
      }
      var self = this;
      var changesToMake = this.get('changesToMake');
      var useruserprefs = this.get('userUserPrefs');
      var canDeleteRoles = [];
      var canPostRoles = [];
      var changes = changesToMake.map(function(change, index) {
        if(change.type === 'POST') {
          canPostRoles.push(change);
          var useruserpref = self.get('store').createRecord('useruserpref', {
            location_id: self.get('location.id'),
            user_id: self.get('user.id'),
            user_pref_id: change.user_pref_id
          });
          return useruserpref.save().then(function(uup) {
            useruserprefs.addObject(uup); // Push the object we just created back onto the list of file categories object
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
              sendNoty();
            }
          }, function() {
            // error
            if(index === changesToMake.get('length') - 1) {
              doneMakingChanges(self);
            }
            noty({
              text: 'Error adding Preference ' + change.title,
              type: 'error',
              timeout: 10000
            });
          });
        } else if(change.type === 'DELETE') {
          canDeleteRoles.push(change);
          return useruserprefs.forEach(function(uupgroup) {
            if (uupgroup.get('user_pref_id') === change.user_pref_id) {
              uupgroup.destroyRecord().then(function() {
                useruserprefs.removeObject(uupgroup); // Remove the oject from the parent if it was successfully deleted
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                  sendNoty();
                }
              }, function() {
                //error
                if(index === changesToMake.get('length') - 1) {
                  doneMakingChanges(self);
                }
                noty({
                  text: 'Error removing Preference ' + change.title,
                  type: 'error',
                  timeout: 10000
                });
              });
            }
          });
        }
      });

      self.set('isLoading', true);

      if(!changes || changes.length === 0) {
        doneMakingChanges(self);
      } else {
        Ember.RSVP.Promise.all(changes).then(function() {
          //
        }, function() {
          noty({
            text: 'Error applying changes!',
            type: 'error'
          });
          doneMakingChanges(self);
        });
      }
      function sendNoty() {
        if((canPostRoles && canPostRoles.length)) {
          var successMessagePost = '';
          canPostRoles.forEach(function(role) {
            successMessagePost += role.title;
            successMessagePost += '<br>';
          });
          noty({
            text: 'The following Preferences were successfully added:<br>' + successMessagePost,
            timeout: 10000
          });
        }
        if((canDeleteRoles && canDeleteRoles.length)) {
          var successMessageDelete = '';
          canDeleteRoles.forEach(function(role){
            successMessageDelete += role.title;
            successMessageDelete += '<br>';
          });
          noty({
            text: 'The following Preferences were successfully removed:<br>' + successMessageDelete,
            timeout: 10000
          });
        }
      }
      function doneMakingChanges (context) {
        context.set('isLoading', false);
        context.set('isEditing', false);
        context.set('changesToMake', []);
      }
    },
    uncheckAllUserPrefs: function() {
      jQuery('.checkbox-select .checkbox-select-input').each(function() {
        var self = this;
        if (jQuery(this).prop('checked') && !jQuery(this).prop('disabled')) {
          jQuery(self).trigger('click');
        }
      });
    },
  }
});
