import Ember from 'ember';
import jQuery from 'jquery';

var get = Ember.get;
export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  placeholderDisplay: null,
  allSelected: false,
  allDeselected: false,
  sendImmediate: false,
  selectedTime: null,
  name: '',

  itemsArray: Ember.computed('items.@each', function() {
    var items = this.get('items');
    var groupby = this.get('groupby');
    var groupby_hide_labels = this.get('groupby_hide_labels');
    var sort_desc = this.get('sort_desc');
    if (typeof items === 'string') {
      return this.get(items);
    } else {
      if (items) {
        if (groupby) {
          var groupbylabels = [];
          items = this.get('notuniqbytitle') ? items : items.uniqBy('title');
          var arrays = {};
          items.forEach(function(item) {
            let groupbylabel = item.get(groupby);
            if (!arrays[groupbylabel]) {
              arrays[groupbylabel] = [];
            }
            arrays[groupbylabel].push(item);
          });
          var tempItems = [];
          var tempArray = Object.keys(arrays).sort();
          if(sort_desc) {
            tempArray = tempArray.reverse();
          }
          tempArray.forEach(function(prop) {
            arrays[prop] = arrays[prop].sortBy('title');
            tempItems = tempItems.concat(arrays[prop]);
          });
          tempItems.map(function(item) {
            groupbylabels.push(item.get(groupby));
          });
          groupbylabels = groupbylabels.uniq();
          var firstItems = [];
          groupbylabels.map(function(label) {
            firstItems.push(tempItems.findBy(groupby, label));
          });
          var firstItemIndexes = [];
          firstItems.map(function(item) {
            firstItemIndexes.push(tempItems.indexOf(item));
          });
          if(!groupby_hide_labels){
            for (var i = firstItemIndexes.length - 1; i >= 0; i--) {
              let label = groupbylabels[i];
              tempItems.insertAt(firstItemIndexes[i], {
                id: 'groupby_' + label,
                title: label,
                grouplabel: true
              });
            }
          }
          return tempItems;
        } else {
          return this.get('notuniqbytitle') ? items : items.uniqBy('title');
        }
      } else {
        return [];
      }

    }
  }),
  idSuffix: Ember.computed('context', function() {
    return this.get('context');
  }),
  isGrid: Ember.computed('context', function() {
    return this.get('context') === 'grid';
  }),

  hours: Ember.computed(function() {
    var array = [];
    var hours, ampm, rhours;
    for (var i = 0; i <= 1380; i += 60) {
      hours = Math.floor(i / 60);
      rhours = Math.floor(i / 60);
      ampm = hours % 24 < 12 ? 'am' : 'pm';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      if (hours < 10) {
        hours = '0' + hours; // add leading zero
      }
      if (rhours < 10) {
        rhours = '0' + rhours; // add leading zero
      }
      array.push({
        'id': rhours,
        'title': hours + ' ' + ampm
      });
    }
    return array;
  }),
  minutes: Ember.computed(function() {
    var array = [];
    for (var i = 0; i <= 59; i++) {
      if (i < 10) {
        i = '0' + i;
      }
      array.push({
        'id': i,
        'title': i
      });
    }
    return array;
  }),
  fieldId: Ember.computed(function() {
    var pagerParams = this.get('parent.pagerParams');
    if(pagerParams && pagerParams.pageRoute) {
      return 'filter_' + pagerParams.pageRoute.split('.').join('-') + '_' + get(this, 'name');
    } else {
      return get(this, 'name') + '_id';
    }
  }),
  idFromName: Ember.computed('name', function() {
    return get(this, 'name') + '_from';
  }),
  idToName: Ember.computed('name', function() {
    return get(this, 'name') + '_to';
  }),
  idFromHour: Ember.computed(function() {
    return get(this, 'name') + '_from_hour';
  }),
  idToHour: Ember.computed(function() {
    return get(this, 'name') + '_to_hour';
  }),
  idFromMinute: Ember.computed(function() {
    return get(this, 'name') + '_from_minute';
  }),
  idToMinute: Ember.computed(function() {
    return get(this, 'name') + '_to_minute';
  }),

  showAllSelectCheckBox: Ember.computed('showAllSelect', 'multi', 'itemsArray.@each', function() {
    var showAllSelect = this.get('showAllSelect');
    var multi = this.get('multi');
    var itemsArrayLength = this.get('itemsArray.length');
    return showAllSelect || (multi && itemsArrayLength > 1);
  }),

  dateFiltersPast: Ember.computed(function() {
    return this.get('applicationData.dateFiltersPast');
  }),
  intervals: Ember.computed(function() {
    return this.get('applicationData.intervals');
  }),
  paymentMethods: Ember.computed(function() {
    return this.get('applicationData.paymentMethods');
  }),
  yesNoAny: Ember.computed(function() {
    return this.get('applicationData.yesNoAny');
  }),

  clearAllObserver: Ember.observer('clearAll', function() {
    if (this.get('clearAll')) {
      this.set('placeholderValue', '');
      this.set('allSelected', false);
      this.set('allDeselected', true);
    }
  }),

  actions: {
    go: function() {
      jQuery('#' + this.$().attr('id')).find(':button').dropdown('toggle');
    },
    selectAll: function() {
      if (this.get('allSelected')) {
        this.set('placeholderValue', '');
        this.set('allSelected', false);
        this.set('allDeselected', true);
      } else {
        this.set('allSelected', true);
        this.set('allDeselected', false);
      }
    },
    adjustPlaceholderValue: function(value, action) {
      var placeholderValue = this.get('placeholderValue') || '';
      var placeholderArray = placeholderValue.split(',');
      if (placeholderArray[0] === '') {
        placeholderArray.splice(0, 1);
      }
      var items = this.get('itemsArray');
      if (action === 'add') {
        if (placeholderArray.indexOf(value) === -1) {
          if (this.get('multi')) {
            placeholderArray.push(value);
            if (get(items, 'length') === placeholderArray.length) {
              this.set('allSelected', true);
            }
          } else {
            placeholderArray.splice(0, 1, value);
          }
        }
      } else if (action === 'remove') {
        if (this.get('multi')) {
          var index = placeholderArray.indexOf(value);
          if (index || index === 0) {
            placeholderArray.splice(index, 1);
            this.set('allSelected', false);
          }
        } else {
          placeholderArray.splice(0, 1, value);
        }
      }
      this.set('placeholderValue', placeholderArray.join(','));
      if (this.get('onCheck')) {
        this.onCheck(value, action);
      }
    },
  },

  filter_name: Ember.computed(function() {
    if (Ember.isEmpty(this.get('name'))) {
      return '';
    }
    return this.get('name').replace(/./g, '-');
  }),

  selectedChanged: Ember.observer('placeholderValue', 'itemsArray.[]', function() {
    var self = this;
    Ember.run.once(function() {
      self.runSelectedChanged();
    });
  }),

  runSelectedChanged: function() {
    var self = this;
    var placeholder = [];
    var placeholderArray = [];
    var placeholderValue = this.get('placeholderValue');
    var items = this.get('itemsArray');

    if (Ember.isEmpty(placeholderValue)) {
      if (this.get('multi')) {
        this.set('placeholderDisplay', '');
        this.set('allSelected', false);
        jQuery('#' + self.get('fieldId')).parent().find('.selectall').each(function() {
          this.checked = false;
        });
      } else {
        if (!Ember.isEmpty(items)) {
          var firstItem = get(items, 'firstObject');
          this.set('placeholderDisplay', get(firstItem, self.get('labelValue')));
        }
        if (jQuery('#' + self.get('fieldId')).parent().hasClass('open')) {
          jQuery('#' + self.get('fieldId')).dropdown('toggle');
        }
      }
      return;
    } else {
      placeholderArray = placeholderValue.split(',');
    }

    // Iterate through the items to see which ones are check/selected
    if (!Ember.isEmpty(items)) {
      items.forEach(function(i) {
        var item = get(i, self.get('labelKey'));
        if (placeholderArray.indexOf(item) > -1) {
          placeholder.push(get(i, self.get('labelValue')));
          var itemId = (i.id || i.id === '') ? i.id : i.get('id');
          var id = 'chk-' + self.get('name') + '_' + itemId + '-' + self.get('idSuffix');
          id = id.replaceAll(' ', '_');
          jQuery('#' + id).attr('checked', true);
          if (!self.get('multi')) {
            Ember.run.once(function() {
              jQuery('#' + id).parent().parent().parent().addClass('active');
            });
          }
        }
      });
    }
    this.set('placeholderDisplay', placeholder.join(',').replace(/,/g, ', '));

    // Have to do additional things for non multi select
    if (!this.get('multi')) {
      if (this.get('placeholderValue') !== 'custom') {
        if (jQuery('#' + self.get('fieldId')).parent().hasClass('open')) {
          jQuery('#' + self.get('fieldId')).dropdown('toggle');
        }
      }
    }

    // Uncheck the select all if not all items are checked
    if (this.get('multi')) {
      if (this.get('allSelected')) {
        jQuery('#' + self.get('fieldId')).parent().find('.selectall').each(function() {
          this.checked = true;
        });
      } else {
        jQuery('#' + self.get('fieldId')).parent().find('.selectall').each(function() {
          this.checked = false;
        });
      }
    }

    // Set the read only attributes for date filters
    if (this.get('showDateFilter')) {
      if (this.get('placeholderValue') === 'custom') {
        jQuery('#' + self.get('idFromName')).attr('readonly', null);
        jQuery('#' + self.get('idToName')).attr('readonly', null);
      } else {
        jQuery('#' + self.get('idFromName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToName')).val(null).attr('readonly', 'readonly');
      }
    }
    if (this.get('showTimeFilter')) {
      if (this.get('placeholderValue') === 'custom') {
        jQuery('#' + self.get('idFromName')).attr('readonly', null);
        jQuery('#' + self.get('idToName')).attr('readonly', null);
        jQuery('#' + self.get('idFromHour')).attr('readonly', null);
        jQuery('#' + self.get('idToHour')).attr('readonly', null);
        jQuery('#' + self.get('idFromMinute')).attr('readonly', null);
        jQuery('#' + self.get('idToMinute')).attr('readonly', null);
      } else {
        jQuery('#' + self.get('idFromName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', 'readonly');
        jQuery('#' + self.get('idToHour')).val('23').attr('readonly', 'readonly');
        jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', 'readonly');
        jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', 'readonly');
      }
    }
    if (self.get('sendImmediate')) {
      self.sendData();
    }
  },
  didInsertElement: function() {
    this._super();
    var self = this;

    // Create some triggers so inputs will become enabled on click
    if (this.get('showDateFilter')) {
      jQuery(document).on('click', '#' + self.get('idFromName'), function() {
        if (jQuery('#' + self.get('idFromName')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromName')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idToName'), function() {
        if (jQuery('#' + self.get('idToName')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).trigger('click');
        }
      });
    }


    if (this.get('showTimeFilter')) {
      jQuery(document).on('click', '#' + self.get('idFromName'), function() {
        if (jQuery('#' + self.get('idFromName')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idFromName')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idToName'), function() {
        if (jQuery('#' + self.get('idToName')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idToName')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idFromHour'), function() {
        if (jQuery('#' + self.get('idFromHour')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idToHour'), function() {
        if (jQuery('#' + self.get('idToHour')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idFromMinute'), function() {
        if (jQuery('#' + self.get('idFromMinute')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).trigger('click');
        }
      });
      jQuery(document).on('click', '#' + self.get('idToMinute'), function() {
        if (jQuery('#' + self.get('idToMinute')).is('[readonly]')) {
          jQuery('#chk-' + get(self, 'name') + '_custom-grid').trigger('click');
          jQuery('#' + self.get('idFromName')).val(get(self, self.get('idFromName'))).attr('readonly', null);
          jQuery('#' + self.get('idToName')).val(get(self, self.get('idToName'))).attr('readonly', null);
          jQuery('#' + self.get('idFromHour')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToHour')).val('23').attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).val('00').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).val('59').attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).trigger('click');
        }
      });
    }

    // Set the read only attributes for date filters
    if (this.get('showDateFilter')) {
      if (this.get('placeholderValue') === 'custom') {
        jQuery('#' + self.get('idFromName')).attr('readonly', null);
        jQuery('#' + self.get('idToName')).attr('readonly', null);
      } else {
        jQuery('#' + self.get('idFromName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToName')).val(null).attr('readonly', 'readonly');
      }
    }

    if (this.get('showTimeFilter')) {
      if (this.get('placeholderValue') === 'custom') {
        jQuery('#' + self.get('idFromName')).attr('readonly', null);
        jQuery('#' + self.get('idToName')).attr('readonly', null);
        if (jQuery('#' + self.get('idFromName')).val() === '') {
          jQuery('#' + self.get('idFromHour')).attr('readonly', null);
          jQuery('#' + self.get('idFromMinute')).attr('readonly', null);
          self.set('valueFromHour', '00');
          self.set('valueFromMinute', '00');
        }
        if (jQuery('#' + self.get('idToName')).val() === '') {
          jQuery('#' + self.get('idToHour')).attr('readonly', null);
          jQuery('#' + self.get('idToMinute')).attr('readonly', null);
          self.set('valueToHour', '23');
          self.set('valueToMinute', '59');
        }
        jQuery('#' + self.get('idFromHour')).attr('readonly', null);
        jQuery('#' + self.get('idToHour')).attr('readonly', null);
        jQuery('#' + self.get('idFromMinute')).attr('readonly', null);
        jQuery('#' + self.get('idToMinute')).attr('readonly', null);
      } else {
        jQuery('#' + self.get('idFromName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToName')).val(null).attr('readonly', 'readonly');
        jQuery('#' + self.get('idFromHour')).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToHour')).attr('readonly', 'readonly');
        jQuery('#' + self.get('idFromMinute')).attr('readonly', 'readonly');
        jQuery('#' + self.get('idToMinute')).attr('readonly', 'readonly');
        self.set('valueFromHour', '00');
        self.set('valueFromMinute', '00');
        self.set('valueToHour', '23');
        self.set('valueToMinute', '59');
      }
    }

    if (this.get('showDateFilter') || this.get('showTimeFilter')) {
      jQuery(document).on('click', '.pika-select-month', function(e) {
        e.stopPropagation();
      });
      jQuery(document).on('click', '.pika-select-year', function(e) {
        e.stopPropagation();
      });
    }

    // Send the new values to the controller upon closing the drop down
    jQuery(document).on('hidden.bs.dropdown', '#' + this.$().attr('id'), function() {

      var from_date = jQuery('#' + self.get('idFromName')).val();
      var to_date = jQuery('#' + self.get('idToName')).val();
      var date_from_val = '';
      var from_timestamp;
      var date_to_val = '';
      var to_timestamp;

      if (from_date !== '') {
        date_from_val = jQuery('#' + self.get('idFromName')).val();
        var from_hour = '00',
          from_min = '00',
          from_sec = '00';
        if (self.get('showTimeFilter')) {
          from_hour = jQuery('#' + self.get('idFromHour')).val();
          from_min = jQuery('#' + self.get('idFromMinute')).val();
        }
        var concatenate_from = date_from_val + ' ' + from_hour + ':' + from_min + ':' + from_sec;
        from_timestamp = concatenate_from;
      }

      if (to_date !== '') {
        date_to_val = jQuery('#' + self.get('idToName')).val();
        var to_hour = '23',
          to_min = '59',
          to_sec = '59';
        if (self.get('showTimeFilter')) {
          to_hour = jQuery('#' + self.get('idToHour')).val();
          to_min = jQuery('#' + self.get('idToMinute')).val();
        }
        var concatenate_to = date_to_val + ' ' + to_hour + ':' + to_min + ':' + to_sec;
        to_timestamp = concatenate_to;
      }

      var data = {
        'controller': self.get('pagerParams.pageRoute'),
        'filters': [{
          'name': get(self, 'name'),
          'value': self.get('placeholderValue')
        }]
      };
      if (self.get('showDateFilter') || self.get('showTimeFilter')) {
        if (self.get('placeholderValue') === 'custom') {
          data.filters.push({
            'name': self.get('idFromName'),
            'value': from_timestamp
          });
          data.filters.push({
            'name': self.get('idToName'),
            'value': to_timestamp
          });
        } else {
          data.filters.push({
            'name': self.get('idFromName'),
            'value': ''
          });
          data.filters.push({
            'name': self.get('idToName'),
            'value': ''
          });
          jQuery('#' + self.get('idFromName')).val('');
          jQuery('#' + self.get('idToName')).val('');
        }
      }

      if(self.action) {
        self.action(data);
      }
      if (!self.get('sendImmediate') && self.get('context') !== 'grid') {
        self.sendData();
      }
    });

    // Uncheck the select all if not all items are checked
    if (this.get('multi')) {
      var items = this.get('itemsArray');
      var placeholderValue = this.get('placeholderValue');
      if (!Ember.isEmpty(placeholderValue)) {
        var placeholderArray = this.get('placeholderValue').split(',');
        if (get(items, 'length') === placeholderArray.length) {
          self.set('allSelected', true);
          jQuery('#' + self.get('fieldId')).parent().find('.selectall').each(function() {
            this.checked = true;
          });
        } else {
          self.set('allSelected', false);
          jQuery('#' + self.get('fieldId')).parent().find('.selectall').each(function() {
            this.checked = false;
          });
        }
      }
    }
    this.runSelectedChanged();
  },
  willDestroyElement: function() {
    jQuery(document).off('hidden.bs.dropdown', '#' + this.$().attr('id'));
    jQuery(document).off('click', '#' + this.get('idFromName') + '_from');
    jQuery(document).off('click', '#' + this.get('idToName') + '_to');
    jQuery(document).off('click', '.pika-select-month');
    jQuery(document).off('click', '.pika-select-year');
  },
  sendData: function() {
    var data = {
      'controller': this.get('pagerParams.pageRoute'),
      'filters': [{
        'name': get(this, 'name'),
        'value': this.get('placeholderValue')
      }]
    };
    if (this.get('showDateFilter')) {
      if (this.get('placeholderValue') === 'custom') {
        data.filters.push({
          'name': this.get('idFromName'),
          'value': jQuery('#' + this.get('idFromName')).val()
        });
        data.filters.push({
          'name': this.get('idToName'),
          'value': jQuery('#' + this.get('idToName')).val()
        });
      } else {
        data.filters.push({
          'name': this.get('idFromName'),
          'value': ''
        });
        data.filters.push({
          'name': this.get('idToName'),
          'value': ''
        });
        jQuery('#' + this.get('idFromName')).val('');
        jQuery('#' + this.get('idToName')).val('');
      }
    }
    if(this.action) {
      this.action(data);
    }
  }
});
