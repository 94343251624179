import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      }),
    });
  },
  afterModel: function(models, transition) {
    var store = this.get('store');
    return this.get('notFound').check(models.contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfo: store.queryRecord('locationinfo', {
          id: models.contact.get('location_id'),
          expand: 'product_transactions,quick_invoice_setting,product_file',
          product_transaction_active: '0,1'
        }).then(function(data) {
          models.locationinfo = data;
        })
      });
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var store = this.get('store');
    controller.set('contact', models.contact);
    controller.set('locationinfo', models.locationinfo);
    var quickinvoicesettings = models.locationinfo.get('quick_invoice_setting');

    controller.set('isEditing', true);
    var quickinvoice = store.createRecord('quickinvoice', {
      allow_overpayment: false,
      allow_partial_pay: quickinvoicesettings.default_quick_invoice_allow_partial_pay ? true : false,
      attach_files_to_email: false,
      cc_product_transaction_id: null,
      contact: models.contact,
      contact_id: models.contact.get('id'),
      email: models.contact.get('email'),
      item_list: {},
      location_id: models.locationinfo.get('id'),
      notification_days_after_due_date: quickinvoicesettings.default_quick_invoice_notification_days_after_due_date,
      notification_days_before_due_date: quickinvoicesettings.default_quick_invoice_notification_days_before_due_date,
      notification_on_due_date: quickinvoicesettings.default_quick_invoice_notification_on_due_date ? true : false,
      send_email: true,
      single_payment_min_amount: 0
    });
    controller.set('quickinvoice', quickinvoice);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Add Quick Invoice',
      route_name: 'contacts.quickinvoices-add',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Quick Invoices',
      link: 'contacts.quickinvoices-index',
      record: models.contact.get('id')
    }, {
      title: 'Add Quick Invoice',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
