import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      productbillinggroup: store.createRecord('productbillinggroup', {
        location_id: null
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('productbillinggroup', models.productbillinggroup);
    controller.set('isEditing', true);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Product Billing Group ● Add',
      route_name: 'admin.productbillinggroups.add'
    });
    controller.set('pageTitle', 'Add Product Billing Group');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Product Billing Groups',
      link: 'admin.productbillinggroups.index'
    }, {
      title: 'Add',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.productbillinggroup, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
