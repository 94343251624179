import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('file.errors.messages', function() {
    this.set('errorMessages', this.get('file.errors.messages'));
  }),

  // Events
  didInsertElement: function() {
    this.setupFile();
  },

  // Observers
  locationChanged: Ember.observer('file.location_id', function() {
    var self = this;

    if (this.get('file')) {
      // Location changed, make everything else null
      this.set('visibility_groups', null);
      this.set('file.visibility_group_id', null);
      this.set('product_files', null);
      this.set('file.product_file_id', null);
      this.set('file_categories', null);
      this.set('file.file_category_id', null);

      var location_id = this.get('file.location_id');
      if (location_id) {
        var curLocation = this.get('belongsToLocations').filter(function(data) {
          return data.id === location_id;
        })[0];

        this.get('store').query('visibilitygroup', {
          location_id: location_id,
          page_size: '100',
          expand: 'file_categories'
        }).then(function(data) {
          self.set('visibility_groups', data);
        });

        this.set('product_files', null);
        this.set('file.product_file_id', curLocation.get('product_file.id'));
      }
    }
  }),
  visibilityGroupChanged: Ember.observer('file.visibility_group_id', function() {
    if (this.get('file')) {
      var visibility_group_id = this.get('file.visibility_group_id');
      if (visibility_group_id) {
        var curVisibilityGroup = this.get('visibility_groups').filter(function(item) {
          return item.id === visibility_group_id;
        })[0];
        this.set('file_categories', curVisibilityGroup.get('file_categories'));
      }
    }
  }),

  // Functions
  setupFile: function() {
    var file_resource = this.get('file_resource');
    var file = this.get('store').createRecord('file', {
      resource_id: file_resource.resource_id,
      resource: file_resource.resource,
      location_id: null
    });
    this.set('file', file);
  },

  // Actions
  actions: {
    addFile: function(addFile) {
      this.set('file.file', addFile.blob);
    },
    clearFile: function() {
      this.set('file.file', null);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoadingFile')) {
        return;
      }
      this.set('isLoadingFile', true);

      var self = this;
      var file = this.get('file');

      if (!file.get('location_id')) {
        file.get('errors').add('location_id', 'Belong to field must be set!');
      }
      if (!file.get('file')) {
        file.get('errors').add('file', 'Must select a file to upload!');
      }
      if (file.get('errors.length') > 0) {
        self.set('isLoadingFile', false);
        return;
      }

      file.save().then(function() {
        file.unloadRecord();
        noty({
          text: 'File added successfully!'
        });
        self.setupFile();
        self.set('isLoadingFile', false);
        self.get('parent').send('gridNeedsRefresh', self.get('parent'));
      }, function() {
        self.set('isLoadingFile', false);
      });
    }
  }
});
