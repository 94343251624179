import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  active: DS.attr('boolean'),
  byte_increment: DS.attr('string'),
  created_ts: DS.attr('ts'),
  file_ext_allowed: DS.attr('string'),
  free_bytes: DS.attr('string'),
  increment_cost: DS.attr('string'),
  location_id: DS.attr('string'),
  max_file_size_bytes: DS.attr('string'),
  monthly_fee: DS.attr('string'),
  product_file_credential_id: DS.attr('string'),
  title: DS.attr('string'),
  account_number: DS.attr('string'),
  agent_code: DS.attr('string'),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
  locations: DS.hasMany('location'),
  product_file_credential: DS.belongsTo('productfilecredential'),
});
