import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct',
				product_transaction_active: '1,0'
      }),
      users: store.query('user', {
        sort: 'username',
        page_size: '500'
      }),
      tagList: store.query('tag', {
        page_size: '500',
        relationship: 'direct',
        sort: 'title'
      })
    });
  },

  setupController: function(controller, models) {
		// Set Controller Properties
    var product_transactions = [];
    var noRecurringProduct = true;
    models.locations.forEach(function(loc) {
      var prodtrans = loc.get('product_transactions');
      var prodrecur = loc.get('product_recurring.content');
      if (prodtrans) {
        prodtrans.forEach(function(product_transaction) {
          product_transactions.push({
            'id': product_transaction.get('id'),
            'title': product_transaction.get('title')
          });
        });
      }
      if (prodrecur) {
        noRecurringProduct = false;
      }
    });
    controller.set('noProducts', noRecurringProduct);
    controller.set('product_transactions', product_transactions);

    var pagerParams = {
      pageRoute: 'reports.recurrings',
      pageModelId: null,
      modelName: 'recurring',
      showExport: this.get('session.authenticated.resources.v2-recurrings-get-export')
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      expand: 'location.id,location.name,account_vault.id,account_vault.account_holder_name,account_vault.last_four,account_vault.exp_date,account_vault.title,account_vault.account_type,created_user.id,created_user.username,contact.id,contact.first_name,contact.last_name,tags,product_transaction.id,product_transaction.title',
      relationship: 'direct'
    };
    controller.set('gridParams', gridParams);
    if (models.locations.get('content').get('length') > 1) {
      controller.set('showLocations', true);
    } else {
      controller.set('showLocations', false);
    }
    controller.set('locations', models.locations);
    controller.set('users', models.users);
    controller.set('tagList', models.tagList);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Recurring Billings',
      route_name: 'reports.recurrings',
      route_id: null
    });
    controller.set('pageTitle', 'Recurring Billings');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'Recurring Billings',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
