import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (value === null || value === '') {
      return '0000-00-00';
    } else {
      var newValue = moment(value, 'MM/DD/YYYY');
      return newValue.isValid() ? newValue.format('YYYY-MM-DD') : null;
    }
  },
  deserialize: function(value) {
    if (value === '0000-00-00') {
      return null;
    } else {
      var newValue = moment(value, 'YYYY-MM-DD');
      return newValue.isValid() ? newValue.format('MM/DD/YYYY') : null;
    }
  }
});