import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  account_vault_id: DS.attr('string'),
  action: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  product_transaction_id: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  account_vault: DS.belongsTo('accountvault'),
  created_user: DS.belongsTo('user'),
  product_transaction: DS.belongsTo('producttransaction'),
});
