import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsTags: true,

  // Set controller params
  sort: 'name',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'title',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(tag) {
          self.send('edit', tag);
        },
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(tag) {
          self.send('delete', tag);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'created_ts', 'delete', 'edit'];
    var listOfMobileCols = ['title', 'created_ts', 'delete', 'edit'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (!session.get('authenticated.resources.v2-tags-delete')) {
        colsArray.removeObject(columns.delete);
      }
      if (!session.get('authenticated.resources.v2-tags-put')) {
        colsArray.removeObject(columns.edit);
      }
    }
  }),

  // Controller actions
  actions: {
    edit: function(tag) {
      var location = this.get('location');
      this.transitionToRoute('locations.edittag', location.get('id'), tag.get('id'));
    },
    delete: function(tag) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Tag?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        tag.deleteRecord();
        tag.save().then(function() {
          noty({
            text: 'Tag deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          tag.rollbackAttributes();
          noty({
            text: 'Error deleting Tag!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
