import DS from "ember-data";
import Application from "./application";

export default Application.extend({
  contact_name: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  email: DS.attr('string'),
  location_id: DS.attr('string'),
  misc: DS.attr('string'),
  phone: DS.attr('string'),
  title: DS.attr('string'),

  /* Computed Properties */

  // Defined model relationships
  location: DS.belongsTo('location')
});
