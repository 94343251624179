import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        id: params.location_id,
        page_size: '500',
        product_transaction_active: '0,1',
        expand: 'product_transactions,branding_domain,tags',
      }),
    });
  },
  afterModel: function(models, transition) {
    var contact_id = transition.to.queryParams.contact_id;
    var store = this.get('store');
    if(contact_id) {
      return Ember.RSVP.hash({
        initialContact: store.queryRecord('contact', {
          id: contact_id,
          expand: 'location'
        }).then(function(data){
          models.initialContact = data.get('titleWithAccountNumberAndLocation');
        }),
      });
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var location = models.locationinfos.get('firstObject');
    controller.set('locationinfos', models.locationinfos);
    controller.set('location', location);
    controller.set('tagList', location.get('tags'));
    controller.set('initialContact', models.initialContact);

    var product_transactions = [];
    models.locationinfos.forEach(function(loc) {
      if(loc.get('hasProductRecurring')) {
        var prods = loc.get('product_transactions');
        if (prods) {
          prods.forEach(function(product_transaction) {
            product_transactions.push({
              'id': product_transaction.get('id'),
              'title': product_transaction.get('title'),
              'processor': product_transaction.get('processor'),
              'receipt_add_recurring_above_signature': product_transaction.get('receipt_add_recurring_above_signature'),
              'surcharge': product_transaction.get('surcharge')
            });
          });
        }
      }
    });
    controller.set('product_transactions', product_transactions);

    var pagerParams = {
      pageRoute: 'locations.recurrings-index',
      pageModelId: null,
      modelName: 'recurring',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      location_id: location.get('id'),
      expand: 'created_user,account_vault,transactions,payment_schedule,signature,product_transaction,tags,contact,location'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● Recurring Billings',
      route_name: 'locations.recurrings-index',
      route_id: location.get('id')
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageSubtitle', {
      title: 'Add Recurring Billing',
      link: 'locations.recurrings-add',
      record: location.get('id'),
      resource: this.get('session.authenticated.resources.v2-recurrings-post')
    });
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Recurring Billings',
      link: ''
    }]);
  },

  // Actions
  actions: {},

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
