import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      url: {
        'name': 'url',
        'title': 'Url',
        'type': 'string'
      },
      resource: {
        'name': 'resource',
        'title': 'Resource',
        'type': 'string'
      },
      expands: {
        'name': 'expands',
        'title': 'Expands',
        'type': 'string'
      },
      on_create: {
        'name': 'on_create',
        'title': 'On Create',
        'type': 'check',
        'align': 'text-center'
      },
      on_update: {
        'name': 'on_update',
        'title': 'On Update',
        'type': 'check',
        'align': 'text-center'
      },
      on_delete: {
        'name': 'on_delete',
        'title': 'On Delete',
        'type': 'check',
        'align': 'text-center'
      },
      is_active: {
        'name': 'is_active',
        'title': 'Is Active',
        'type': 'check',
        'align': 'text-center'
      },
      number_of_attempts: {
        'name': 'number_of_attempts',
        'title': 'Number of Attempts',
        'type': 'number'
      },
      attempt_interval: {
        'name': 'attempt_interval',
        'title': 'Attempt Interval',
        'type': 'number'
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(postbackconfig) {
          self.send('edit', postbackconfig);
        },
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(postbackconfig) {
          self.send('delete', postbackconfig);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['url', 'resource', 'expands', 'on_create', 'on_update', 'on_delete', 'is_active', 'number_of_attempts', 'attempt_interval', 'delete', 'edit'];
    var listOfMobileCols = ['url', 'resource', 'expands', 'on_create', 'on_update', 'on_delete', 'is_active', 'number_of_attempts', 'attempt_interval', 'delete', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-postbackconfigs-put')) {
        colsArray.removeObject(columns.edit);
      }
      if (!session.get('authenticated.resources.v2-postbackconfigs-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),

  // Functions

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    edit: function(postbackconfig) {
      var route = this.get('route');
      if (route === 'admin.postbackconfigs') {
        this.get('parent').transitionToRoute('admin.postbackconfigs.view', postbackconfig.get('id'));
      } else if (route === 'locations.postbackconfigs-index') {
        var location = this.get('location');
        this.get('parent').transitionToRoute('locations.postbackconfigs-edit', location.get('id'), postbackconfig.get('id'));
      } else {
        return;
      }
    },
    delete: function(postbackconfig) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Postback Config?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        postbackconfig.deleteRecord();
        postbackconfig.save().then(function() {
          noty({
            text: 'Postback Config deleted successfully!'
          });
          self.get('parent').send('gridNeedsRefresh', self.get('parent'));
          self.set('isLoading', false);
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          postbackconfig.rollbackAttributes();
          noty({
            text: 'Error deleting Postback Config!' + error,
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  },

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  }
});
