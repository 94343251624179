import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('sharinggroup.errors.messages', function () {
    this.set('errorMessages', this.get('sharinggroup.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,
  isNew: true,

  // Actions
  actions: {
    cancel: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    delete: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    edit: function() {
      return; // sharing template. need blank action so ember doesn't throw error about missing action.
    },
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var sharinggroup = this.get('sharinggroup');
      sharinggroup.save().then(function () {
        noty({
          text: 'Sharing group added successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('admin.sharinggroups.index');
      }, function () {
        self.set('isLoading', false);
      });
    },
    back: function () {
      this.transitionToRoute('admin.sharinggroups.index');
    },
    updateLocation: function (data) {
      var self = this;
      var locationinfos = this.get('store').query('locationinfo', {
        name: data,
        page_size: '10'
      });
      locationinfos.then(function (result) {
        self.set('locationinfos', result);
      });
    },
    addLocation: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var locationsharinggroup = this.get('locationsharinggroup');
      var sharinggroup = this.get('sharinggroup');

      locationsharinggroup.save().then(function () {
        noty({
          text: 'Sharing Group Location added successfully!'
        });

        locationsharinggroup.unloadRecord();
        var sgroup = self.get('store').createRecord('locationsharinggroup', {
          sharing_group_id: sharinggroup.get('id'),
          location_id: null
        });

        self.set('locationsharinggroup', sgroup);
        self.init();
        self.send('refreshData');
        self.set('isLoading', false);
      }, function () {
        self.set('isLoading', false);
        noty({
          text: 'Error creating Sharing Group Location!',
          type: 'error'
        });
      });
    },
    deleteLocation: function (hpl) {
      var self = this;
      pretty_confirm('Are you sure want to delete the Sharing Group Location?', deletelocationsharinggroup);

      function deletelocationsharinggroup() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        hpl.deleteRecord();
        hpl.save().then(function () {
          noty({
            text: 'Sharing Group Location deleted successfully!'
          });
          self.set('isLoading', false);
        }, function () {
          hpl.rollbackAttributes();
          noty({
            text: 'Error deleting Sharing Group Location!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      }
    },
  }
});
