import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  file: DS.attr(),
  file_category_id: DS.attr('string'),
  file_description: DS.attr('string'),
  file_extension: DS.attr('string'),
  file_id: DS.attr('string'),
  file_name: DS.attr('string'),
  location_id: DS.attr('string'),
  product_file_id: DS.attr('string'),
  resource: DS.attr('string'),
  resource_id: DS.attr('string'),
  visibility_group_id: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  file_category: DS.belongsTo('filecategory'),
  product_file: DS.belongsTo('productfile'),
  visibility_group: DS.belongsTo('visibilitygroup'),
});
