import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

const validColumnOptionsForRoute = [
  // transaction fields
  'account_holder_name',
  'account_type',
  'ach_sec_code',
  'auth_amount',
  'auth_code',
  'batch',
  'billing_city',
  'billing_phone',
  'billing_state',
  'billing_street',
  'billing_zip',
  'charge_back_date',
  'clerk_number',
  'created_ts',
  'custom_data',
  'customer_ip',
  'description',
  'effective_date',
  'entry_mode_id',
  'first_six',
  'id',
  'is_accountvault',
  'is_recurring',
  'last_four',
  'order_num',
  'payment_method',
  'reason_code_id',
  'room_num',
  'room_rate',
  'settle_date',
  'tax',
  'tip_amount',
  'settle_date',
  'transaction_amount',
  'transaction_api_id',
  'transaction_batch_id',
  'transaction_c1',
  'transaction_c2',
  'transaction_c3',
  'trx_source_id',
  'transaction_level3_id',

  //related model fields
  'account_vault.id',
  'account_vault.title',
  'contact.account_number',
  'contact.address',
  'contact.cell_phone',
  'contact.city',
  'contact.company_name',
  'contact.email',
  'contact.first_name',
  'contact.home_phone',
  'contact.id',
  'contact.last_name',
  'contact.office_phone',
  'created_user.id',
  'created_user.username',
  'hosted_payment_page.id',
  'hosted_payment_page.title',
  'location.account_number',
  'location.address1',
  'location.city',
  'location.id',
  'location.name',
  'location.office_ext_phone',
  'location.office_phone',
  'location.state',
  'location.zip_code',
  'product_transaction.id',
  'product_transaction.title',
  'quick_invoice.id',
  'quick_invoice.title',
  'reason_code.id',
  'reason_code.title',
  'recurring.id',
  'recurring.description',
  'status.id',
  'status.title',
  'surcharge.surcharge_amount',
  'tags',
  'terminal.id',
  'terminal.serial_number',
  'terminal.title',
  'transaction_level3.id',
  'type.id',
  'type.title',
];

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  availableGridColumns: Ember.inject.service('available-grid-columns'),
  isRefreshing:false,

  model: function() {
    var self = this;
    self.set('isRefreshing',true);
    var store = this.get('store');
    var session = this.get('session');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        sort: 'name',
        page_size: '500',
        relationship: 'direct'
      }),
      users: store.query('user', {
        relationship: 'direct',
        page_size: '50',
        user_type_id: '200,250,300,400,500,600,700,800,900,1000',
        status_id: 1
      }).then(function(data) {
        return data.toArray();
      }),
      tagList: store.query('tag', {
        page_size: '500',
        relationship: 'direct',
        sort: 'title'
      }),
      types: store.findAll('type'),
      statuses: store.findAll('status'),
      defaultReport: {
        isSystemDefault: true,
        title: 'Default',
        url: 'reports-transactions',
        field_data: 'created_ts,account_holder_name,contact.first_name,contact.last_name,auth_amount,transaction_amount,billing_zip,description,tags,batch,payment_method,account_type,created_user.username,type.title,status.title,auth_code,last_four,first_six,settle_date,charge_back_date,is_recurring,is_accountvault,location.name', // fallback default...userreport will overwrite, if available
        filter_data: {
          page_size: session.get('authenticated.ui_prefs.page_size') || '15',
          sort: '-created_ts',
          created_ts: 'today',
        }
      },
      userreports: store.query('userreport', {
        created_user_id: session.get('authenticated.user_id'),
        url: 'reports-transactions',
        page_size: '500',
        sort: 'title'
      }).then(function(data) {
        return data;
      }, function() {
        return null;
      }),
      userreportshares: store.query('sharedreport', {
        user_id: session.get('authenticated.user_id'),
        url: 'reports-transactions',
        page_size: '500',
        expand: 'user_report',
        sort: 'user_report.title'
      }).then(function(data) {
        return data;
      }, function() {
        return null;
      }),

    });
  },
  afterModel: function(models) {
    var self = this;
    var store = this.get('store');
    var locations = models.locations;
    locations = locations.map(function(loc){
      return loc.get('id');
    }).join();
    if(this.get('session.authenticated.resources.v2-hostedpaymentpages-get')) {
      return Ember.RSVP.hash({
        hostedpaymentpages: store.query('hostedpaymentpage', {
          location_id: locations,
          sort: 'title',
          expand: 'created_user'
        }).then(function(data) {
          models.hostedpaymentpages = data;
          self.set('isRefreshing',false);
        }, function() {
          models.hostedpaymentpages = [];
        })
      });
    }
  },

  resetController: function(controller) {
    controller.get('queryParams').map(function(param) {
      controller.set(param, '');
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var product_transactions = [];
    models.locations.forEach(function(loc) {
      var prods = loc.get('product_transactions');
      if (prods) {
        prods.forEach(function(product_transaction) {
          product_transactions.push({
            'id': product_transaction.get('id'),
            'title': product_transaction.get('title'),
            'processor': product_transaction.get('processor')
          });
        });
      }
    });
    controller.set('product_transactions', product_transactions);
    controller.set('hostedpaymentpages', models.hostedpaymentpages);
    var pagerParams = {
      pageRoute: 'reports.transactions',
      pageModelId: null,
      modelName: 'transaction',
      showExport: this.get('session.authenticated.resources.v2-transactions-get-export')
    };
    controller.set('pagerParams', pagerParams);
    var gridParams = {
      expand: 'created_user,contact,surcharge,location,tags,status,type,all_tags,product_transaction,terminal,quick_invoice,account_vault,recurring,hosted_payment_page,reason_code,transaction_level3,card_type,refunded_amount',
      relationship: 'direct',
      show_totals: true
    };
    controller.set('gridParams', gridParams);
    models.users.pushObjects([{
      id: 'terminal',
      title: 'terminal',
      username: 'terminal'
    }, {
      id: 'system',
      title: 'system',
      username: 'system'
    }]);
    controller.set('users', models.users);
    controller.set('locations', models.locations);
    controller.set('types', models.types);
    controller.set('statuses', models.statuses);
    controller.set('tagList', models.tagList);

    if((controller.get('created_ts_from') || controller.get('created_ts_to')) && controller.get('created_ts') !== 'custom') {
      controller.set('created_ts_from', '');
      controller.set('created_ts_to', '');
    }
    if(controller.get('created_ts') === 'custom' && !controller.get('created_ts_from') && !controller.get('created_ts_to')) {
      controller.set('created_ts', 'today');
    }

    // Custom columns
    var AccountVault = this.get('availableGridColumns').getForModel('accountvault');
    var Contact = this.get('availableGridColumns').getForModel('contact');
    var HostedPaymentPage = this.get('availableGridColumns').getForModel('hostedpaymentpage');
    var LocationModel = this.get('availableGridColumns').getForModel('location');
    var ProductTransaction = this.get('availableGridColumns').getForModel('producttransaction');
    var QuickInvoice = this.get('availableGridColumns').getForModel('quickinvoice');
    var ReasonCode = this.get('availableGridColumns').getForModel('reason_code');
    var Recurring = this.get('availableGridColumns').getForModel('recurring');
    var Status = this.get('availableGridColumns').getForModel('status');
    var Surcharge = this.get('availableGridColumns').getForModel('surcharge');
    var Tag = this.get('availableGridColumns').getForModel('tag');
    var Terminal = this.get('availableGridColumns').getForModel('terminal');
    var Transaction = this.get('availableGridColumns').getForModel('transaction');
    var Type = this.get('availableGridColumns').getForModel('type');
    var User = this.get('availableGridColumns').getForModel('user');

    var gridViewColumnOptions = {
      addToModel: [Surcharge, Tag],
      gridColumnOptions: [{
        model: AccountVault,
        modelName: 'account_vault',
        header: 'Account Vault',
      }, {
        model: Contact,
        modelName: 'contact',
        header: 'Contact',
      }, {
        model: HostedPaymentPage,
        modelName: 'hosted_payment_page',
        header: 'Hosted Payment Page'
      }, {
        model: LocationModel,
        modelName: 'location',
        header: 'Location',
      }, {
        model: ProductTransaction,
        modelName: 'product_transaction',
        header: 'Deposit Account',
      }, {
        model: QuickInvoice,
        modelName: 'quick_invoice',
        header: 'Quick Invoice',
      }, {
        model: ReasonCode,
        modelName: 'reason_code',
        header: 'Reason Code',
      },  {
        model: Recurring,
        modelName: 'recurring',
        header: 'Recurring Billing',
      }, {
        model: Status,
        modelName: 'status',
        header: 'Status',
      }, {
        model: Terminal,
        modelName: 'terminal',
        header: 'Terminal',
      }, {
        model: Transaction,
        modelName: 'transaction',
        header: 'Transaction',
      }, {
        model: Type,
        modelName: 'type',
        header: 'Type',
      }, {
        model: User,
        modelName: 'created_user',
        header: 'Created By',
      }],
      mainModel: 'Transaction',
      url: 'reports-transactions',
      validForRoute: validColumnOptionsForRoute,
    };
    controller.set('defaultReport', Ember.Object.create(models.defaultReport));
    controller.set('userreportshares', models.userreportshares);
    controller.set('gridViewColumnOptions', gridViewColumnOptions);

    var userreports = models.userreports;
    controller.set('userreports', userreports);

    var userHasSavedOwnDefault;
    var usersDefault;
    var filterData;

    userreports.map(function(report) {
      if (report.get('title') === 'Default' && !report.isSystemDefault) {
        userHasSavedOwnDefault = true;
        usersDefault = report;
      }
    });
    if(controller.get('userreport.id')) {
      filterData = controller.get('userreport.filter_data');
    } else {
      filterData = userHasSavedOwnDefault ? usersDefault.get('filter_data') : models.defaultReport.filter_data;
    }
    for(var prop in filterData) {
      var filterDataProp = prop.replace('-', '.');
      if(filterDataProp.indexOf('.') !== -1) {
        let tempFilterDataProp = filterDataProp.split('.');
        tempFilterDataProp = tempFilterDataProp[0];
        if(!filterData[tempFilterDataProp]) {
          controller.set(tempFilterDataProp, {});
        }
      }
      if (filterData.hasOwnProperty(filterDataProp)) {
        controller.set(filterDataProp, filterData[filterDataProp]);
      } else {
        controller.set(filterDataProp, '');
      }
    }

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Reports ● Transactions',
      route_name: 'reports.transactions',
      route_id: null
    });
    controller.set('pageTitle', 'Transactions Report');
    controller.set('pageCrumbs', [{
      title: 'Reports',
      link: ''
    }, {
      title: 'Transactions',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
