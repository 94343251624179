import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  developer_id: DS.attr('string'),
  endpoint: DS.attr('string'),
  log_api_body: DS.attr('array'),
  microseconds: DS.attr('number'),
  namespace: DS.attr('string'),
  remote_ip: DS.attr('string'),
  request_body: DS.attr('array'),
  request_header: DS.attr('array'),
  request_method: DS.attr('string'),
  resource: DS.attr('string'),
  response_code: DS.attr('number'),
  response_header: DS.attr('array'),
  url: DS.attr('string'),
  user_id: DS.attr('string'),
});
