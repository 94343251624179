import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),


  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('productach.errors.messages', function() {
    this.set('errorMessages', this.get('productach.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: true,

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var location = this.get('location');
      var productach = this.get('productach');

      productach.save().then(function() {
        noty({
          text: 'Product saved successfully!'
        });
        self.set('isLoading', false);
        self.transitionToRoute('locations.products', location.id);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.products', location.id);
    },
    back: function() {
      var location = this.get('location');
      this.transitionToRoute('locations.products', location.id);
    }
  }
});
