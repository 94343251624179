import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    var isCensored = false;
    var numbersAtFirstTwoAndLastTwo = true;
    if(value) {
      var validIndexes = [0, 1, value.length - 2, value.length - 1];
      if(value.indexOf('****************************') !== -1) {
        isCensored = true;
      }
      validIndexes.forEach(function(indexValue) {
        if(typeof Number(value.charAt(indexValue)) !== 'number') {
          numbersAtFirstTwoAndLastTwo = false;
        }
      });
    }
    if (typeof value === 'undefined' || (isCensored && numbersAtFirstTwoAndLastTwo)) {
      return;
    } else {
      return value;
    }
  },
  deserialize: function(value) {
    if(value) {
      var validIndexes = [0, 1, value.length - 2, value.length - 1];
      return value.split('').map(function(char, index) {
        return (validIndexes.indexOf(index) >= 0) ? char : '*';
      }).join('');
    } else {
      return value;
    }
  }
});
