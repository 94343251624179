import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'created_user,parent,branding_domain,product_transactions',
      }),
      terminal: store.queryRecord('terminal', {
        id: params.terminal_id,
        expand: 'location,created_user,terminal_application,terminal_cvm,terminal_manufacturer',
      }),
      terminalapplications: store.findAll('terminalapplication'),
      terminalcvms: store.query('terminalcvm', {
        expand: 'terminal_manufacturer',
        page_size: '500'
      }),
      terminalmanufacturers: store.findAll('terminalmanufacturer')
    });
  },
  afterModel: function(models, transition) {
    return this.get('notFound').check(models.terminal, transition, 'Terminal', this);
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('location', models.location);
    controller.set('terminal', models.terminal);
    controller.set('terminalapplications', models.terminalapplications);
    controller.set('terminalcvms', models.terminalcvms);
    controller.set('terminalmanufacturers', models.terminalmanufacturers);
    controller.set('page_size', this.get('applicationData.page_size'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● View Terminal ● ' + models.terminal.get('title'),
      route_name: 'locations.terminals-view',
      route_id: models.location.get('id'),
      route_id_plus: models.terminal.get('id')
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Terminals',
      link: 'locations.terminals',
      record: models.location.get('id')
    }, {
      title: models.terminal.get('title'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
