import Ember from "ember";

export default Ember.Controller.extend({
  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsProducts: true,
  isLocationsProductTransactionReceipts: true,
  isLocationsGear: true,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('producttransaction.errors.messages', function() {
    this.set('errorMessages', this.get('producttransaction.errors.messages'));
  }),

  // Set controller params
  isLoading: false,
  isEditing: false,
  notEditing: Ember.computed('isEditing', function() {
    return !this.get('isEditing');
  }),
  isActive: Ember.computed('producttransaction.active', function() {
    return this.get('producttransaction.active');
  }),
  notActive: Ember.computed('producttransaction.active', function() {
    return !this.get('producttransaction.active');
  }),

  // All form actions
  actions: {
    edit: function() {
      this.set('isEditing', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }

      this.set('isLoading', true);
      var self = this;
      var producttransaction = this.get('producttransaction');

      producttransaction.save().then(function() {
        noty({
          text: 'Deposit Account Service saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function() {
        window.scrollTo(0, 0);
        self.set('isLoading', false);
      });
    },
    reset: function() {
      this.set('isEditing', false);
      this.send('refreshData');
    },
  }
});
