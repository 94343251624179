import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  action: DS.attr('string'),
  body: DS.attr('string'),
  class: DS.attr('string'),
  created_ts: DS.attr('ts'),
  domain_id: DS.attr('string'),
  provider_id: DS.attr('string'),
  reason_model: DS.attr('string'),
  reason_model_id: DS.attr('string'),
  reason_sent: DS.attr('string'),
  reply_to: DS.attr('string'),
  subject: DS.attr('string'),

  // Computed Properties
  toEmails: Ember.computed('log_email_recipients.@each.email', function() {
    var recipient_list = '';
    var recipients = this.get('log_email_recipients');
    recipients.forEach(function(recip) {
      recipient_list += recip.get('email') + ',';
    });
    return recipient_list.slice(0, -1);
  }),
  toIds: Ember.computed('log_email_recipients.@each.log_email_id', function() {
    var log_email_id_list = '';
    var log_email_ids = this.get('log_email_recipients');
    log_email_ids.forEach(function(id) {
      log_email_id_list += id.get('log_email_id') + ',';
    });
    return log_email_id_list.slice(0, -1);
  }),

  // Defined model relationships
  log_email_recipients: DS.hasMany('logemailrecipient')
});
