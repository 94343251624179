import Ember from "ember";
import Resolver from "./resolver";
import loadInitializers from "ember-load-initializers";
import config from "./config/environment";
import jQuery from "jquery";

const App = Ember.Application.extend( {
	modulePrefix:    config.modulePrefix,
	podModulePrefix: config.podModulePrefix,
	Resolver: Resolver,
} );

loadInitializers( App, config.modulePrefix );

window.UiENV = config;

Ember.deprecate = function () {};
Ember.warn = function () {};

Ember.onerror = function ( error ) {
	console.log( error );
	const { errors } = error,
		is401Error = false;
	if ( errors )
		errors.forEach( ( err ) => {
			if (err.status === `401`) is401Error = true;
		} );
	else if ( error && error.status && Number( error.status ) === 401 ) is401Error = true;

	const isInvalidDomainError = jQuery(`#invaliddomain`).css(`display`) === `block`;
	if ( !is401Error && !isInvalidDomainError ) {
		const currentTS = moment().unix();
		noty( {
			text: `${`<div` + ` id="app-error-alert-`}${currentTS}" onclick="window.location.reload(true)">An error occurred on this page. If you are having trouble, click here to reload.</div>`,
			timeout: null,
			type: `error`,
			layout: `top`,
		} );
	}
};

// Enable CORS support for IE10
jQuery.support.cors = true;

// Set global error catching
// window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
// / 	if (errorMsg.indexOf('Script error.') > -1) {

/*
 *  		return;
 *  	}
 *
 *  	// Log errors to logging server
 *  	//console.log({ 'logbrowser': {'message': errorMsg, 'url': url, 'line': lineNumber, 'column': column, 'trace': errorObj }});
 *  	jQuery.ajax({
 *  		url: 'https://api.jlogger.com/api/v1/errors',
 *  		type: 'POST',
 *  		data: JSON.stringify({
 *  			'key': 'HAbejrXX4tPJh3t8',
 *  			'message': errorMsg,
 *  			'url': url,
 *  			'line': lineNumber,
 *  			'column': column,
 *  			'trace': JSON.stringify(errorObj)
 *  		}),
 *  		contentType: 'application/json'
 *  	});
 * };
 */

export default App;
