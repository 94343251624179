import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  router: Ember.inject.service(),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Events
  didInsertElement: function() {
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-productbillinggroups-delete'),
      canPost: this.get('session.authenticated.resources.v2-productbillinggroups-post'),
      canPut: this.get('session.authenticated.resources.v2-productbillinggroups-put'),
    });
  },

  // Properties

  // Computed Properties
  isNoFees: Ember.computed('productbillinggroup.id', function() {
    return this.get('productbillinggroup.id') === 'nofees';
  }),

  // Observers

  // Functions

  // Actions
  actions: {
    back: function() {
      this.get('router').transitionTo('admin.productbillinggroups.index');
    },
    cancel: function() {
      var productbillinggroup = this.get('productbillinggroup');
      if (productbillinggroup.get('isNew')) {
        productbillinggroup.deleteRecord();
      } else {
        productbillinggroup.rollbackAttributes();
        this.set('isEditing', false);
      }
      this.send('back');
    },
    cancelBillingItem: function() {
      var productbillinggroupitems = this.get('productbillinggroupitems');
      productbillinggroupitems.forEach(function(item) {
        if (item.get('hasDirtyAttributes')) {
          item.rollbackAttributes();
        }
      });
      this.set('isEditingBillingItem', false);
    },
    delete: function() {
      var self = this;
      var productbillinggroup = this.get('productbillinggroup');
      pretty_confirm("Are you sure want to delete this Product Billing Group?", function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);

        productbillinggroup.deleteRecord();
        productbillinggroup.save().then(function() {
          productbillinggroup.unloadRecord();
          noty({
            text: 'Product Billing Group deleted successfully!'
          });
          self.set('isLoading', false);
          self.send('back');
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail;
            });
          }
          productbillinggroup.rollbackAttributes();
          noty({
            text: 'Error deleting Product Billing Group!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function() {
      this.set('isEditing', true);
    },
    editBillingItem: function() {
      this.set('isEditingBillingItem', true);
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var productbillinggroup = this.get('productbillinggroup');
      productbillinggroup.save().then(function() {
        noty({
          text: 'Product Billing Group saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
        self.send('back');
      }, function() {
        self.set('isLoading', false);
        self.set('isEditing', true);
      });
    },
    saveBillingItem: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var productbillinggroupitems = this.get('productbillinggroupitems');
      var dirtyItems = productbillinggroupitems.filter(function(item) {
        return item.get('hasDirtyAttributes');
      });

      Promise.all(
        dirtyItems.map(function(item) {
          return item.save();
        })
      ).then(function() {
        noty({
          text: 'Product Billing Items saved successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditingBillingItem', false);
        self.set('productbillinggroupitems', null);
        self.refresh();
      }, function() {
        self.set('isLoading', false);
      });
    },
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10',
        fields: 'id,name'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
  }

});
