import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      postback_config_id: {
        'name': 'postback_config_id',
        'title': 'Postback Config Id',
        'type': 'string',
        'sortable': true,
        'action': function(postbacklog) {
          self.send('viewPostbackConfig', postbacklog);
        },
        'filter': {
          type: 'text'
        }
      },
      resource: {
        'name': 'model',
        'title': 'Resource',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'postback_resources'
        }
      },
      resource_id: {
        'name': 'model_id',
        'title': 'Resource Id',
        'type': 'string',
        'sortable': true,
        'action': function(postbacklog) {
          self.send('viewResource', postbacklog);
        },
        'filter': {
          type: 'text'
        }
      },
      http_verb: {
        'name': 'http_verb',
        'title': 'Http Verb',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      postback_status_id: {
        'name': 'postback_status_id',
        'title': 'Status',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'postback_statuses'
        }
      },
      next_run_ts: {
        'name': 'next_run_ts',
        'title': 'Next Run',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersFuture',
          multi: false
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          style: 'min-width: 200px',
          items: 'dateFiltersPast',
          multi: false
        }
      },
      resend: {
        'name': 'id',
        'title': '',
        'action': function(postbacklog) {
          self.send('resend', postbacklog);
        },
        'actionId': 'postbacklog_resend_link',
        'type': 'resend',
        'isActionColumn': true
      },
      show_detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(postbacklog) {
          self.send('showDetail', postbacklog);
        },
        'actionId': 'postbacklog_detail_link',
        'isActionColumn': true
      }
    };

    var listOfCols = ['id', 'postback_config_id', 'resource', 'resource_id', 'http_verb', 'postback_status_id', 'next_run_ts', 'created_ts', 'resend', 'show_detail'];
    var listOfMobileCols = ['id', 'postback_config_id', 'resource', 'resource_id', 'http_verb', 'postback_status_id', 'next_run_ts', 'created_ts', 'resend', 'show_detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),

  // Functions

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    showDetail: function(postbacklog) {
      var self = this;
      var postbacklogController = self.get('postbacklogController');
      postbacklogController.set('postback_log_id', null);
      postbacklogController.set('postbacklogs_modal', null);
      postbacklogController.set('postback_log_id', postbacklog.get('id'));
      postbacklogController.set('postbacklog', postbacklog);
      self.get('parent').send('openModal', 'components/modals/postbacklog');
    },
    resend: function(postbacklog) {
      var self = this;
      self.set('isLocationsPostbacklogs', true);
      var session = this.get('session');

      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/postbacklogs/' + postbacklog.id + '/retry',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'developer-id': window.UiENV.APP.DEVELOPER_ID,
          'access-token': session.get('authenticated.token')
        }
      }).then(function(data) {
        var result = data.postbacklog.postback_status_id;
        if (result === 3) {
          postbacklog.set('postback_status_id', 3);
          noty({
            text: 'Postback retry successful!'
          });
        } else {
          noty({
            text: 'Postback retry failed!',
            type: 'error'
          });
        }
      }, function() {
        noty({
          text: 'Postback retry failed!',
          type: 'error'
        });
      });
    },
    viewPostbackConfig: function(postbacklog) {
      var session = this.get('session');
      if (!session.get('authenticated.resources.v2-postbackconfigs-get')) {
        noty({
          text: 'You do not have access to Postback Configs!',
          type: 'error'
        });
        return;
      }
      if (this.get('route') === 'locations.postbacklogs') {
        var location = this.get('location');
        this.get('parent').transitionToRoute('locations.postbackconfigs-edit', location.get('id'), postbacklog.get('postback_config_id'));
      } else if (this.get('route') === 'admin.postbacklogs') {
        this.get('parent').transitionToRoute('admin.postbackconfigs.view', postbacklog.get('postback_config_id'));
      } else {
        return;
      }
    },
    viewResource: function(postbacklog) {
      var session = this.get('session');
      var resource = postbacklog.get('model').toLowerCase();
      var id = postbacklog.get('model_id');
      var route = '';
      if (resource === 'contact') {
        route = 'contacts.dashboard';
        if (!session.get('authenticated.resources.v2-contacts-get')) {
          noty({
            text: 'Error getting Resource!',
            type: 'error'
          });
          return;
        }
      } else if (resource === 'transaction') {
        route = 'transactiondetailfull';
        if (!session.get('authenticated.resources.v2-transactions-get')) {
          noty({
            text: 'Error getting Resource!',
            type: 'error'
          });
          return;
        }
      } else {
        noty({
          text: 'Error getting Resource!',
          type: 'error'
        });
        return;
      }
      this.get('parent').transitionToRoute(route, id);
    }
  },

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  }
});
