import Ember from "ember";
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  findTransaction: function(params) {
    return this.get('store').queryRecord('transaction', {
      id: params.id,
      expand: 'created_user,contact,surcharge,location,tags,status,type,all_tags,transaction_histories,product_transaction,terminal,log_emails,log_email_recipients,is_refundable,is_voidable,quick_invoice,transaction_level3,refunded_amount',
    }).then(function(data) {
      return data;
    }, function(err) {
      console.log(err);
      return null;
    });
  },

  model: function(params) {
    var self = this;
    return Ember.RSVP.hash({
      transaction: self.findTransaction(params),
    });
  },
  afterModel: function(models) {
    if (typeof models.transaction === 'undefined' || models.transaction.get('length') === 0) {
      this.transitionTo('fourohfour');
      return;
    }
    var store = this.get('store');
    if (typeof models.transaction !== 'undefined') {
      return Ember.RSVP.hash({
        locationinfo: store.queryRecord('locationinfo', {
          id: models.transaction.get('location_id'),
          product_transaction_active: '1,0',
        }).then(function(data) {
          models.locationinfo = data;
        }),
        transactionlevel3: store.queryRecord('transactionlevel3', {
          transaction_id: models.transaction.get('id'),
        }).then(function(data) {
          return models.transactionlevel3 = data;
        })
      });
    } else {
      this.transitionTo('fourohfour');
    }
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('transaction', models.transaction);
    var products = models.locationinfo.get('product_transactions');
    var product = products.filter(function(prod) {
      return prod.get('id') === models.transaction.get('product_transaction_id');
    }).get('firstObject');
    controller.set('product', product);
    controller.set('location', models.locationinfo);

    var transactionlevel3 = models.transactionlevel3;
    if (transactionlevel3 && transactionlevel3.get('level3_data')) {
      controller.set('transactionlevel3', transactionlevel3);
    } else {
      var transactionlevel3Obj = {
        transaction_id: models.transaction.get('id'),
        level3_data: {
          destination_country_code: product.get('level3_default.level3_data.destination_country_code') || '1',
          duty_amount: product.get('level3_default.level3_data.duty_amount') || 0.00,
          freight_amount: product.get('level3_default.level3_data.freight_amount') || 0.00,
          line_items: product.get('level3_default.level3_data.line_items') || [],
          national_tax: product.get('level3_default.level3_data.national_tax') || 0.00,
          sales_tax: product.get('level3_default.level3_data.sales_tax') || 0.00,
          shipfrom_zip_code: product.get('level3_default.level3_data.shipfrom_zip_code') || models.locationinfo.get('zip') || null,
          shipto_zip_code: product.get('level3_default.level3_data.shipto_zip_code') || models.locationinfo.get('zip') || null,
          tax_amount: product.get('level3_default.level3_data.tax_amount') || 0.00,
          tax_exempt: product.get('level3_default.level3_data.tax_exempt') || '0',
        }
      };
      if (models.transaction.get('account_type') === 'visa') {
        transactionlevel3Obj.level3_data.customer_vat_registration = product.get('level3_default.level3_data.customer_vat_registration') || 'xxxxxxxxxxxxx';
        transactionlevel3Obj.level3_data.merchant_vat_registration = product.get('level3_default.level3_data.merchant_vat_registration') || 'xxxxxxxxxxxxxxxxxxxx';
        transactionlevel3Obj.level3_data.order_date = product.get('level3_default.level3_data.order_date') || null;
        transactionlevel3Obj.level3_data.summary_commodity_code = product.get('level3_default.level3_data.summary_commodity_code') || 'xxxx';
        transactionlevel3Obj.level3_data.tax_rate = product.get('level3_default.level3_data.tax_rate') || 0.00;
        transactionlevel3Obj.level3_data.unique_vat_ref_number = product.get('level3_default.level3_data.unique_vat_ref_number') || 'xxxxxxxxxxxxxxx';
      }
      var newtransactionlevel3 = this.get('store').createRecord('transactionlevel3', transactionlevel3Obj);
      models.transactionlevel3 = newtransactionlevel3;
      controller.set('transactionlevel3', newtransactionlevel3);
    }

    controller.setActiveItem({}, -2);
    controller.set('isEditing', false);
    controller.set('isViewingItem', false);
    controller.set('showActiveItemForm', false);
    controller.set('transactionlevel3.errorsMessages', []);
    controller.set('lineItemErrors', []);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Transaction Detail - Level 3 Data',
      route_name: 'transactiondetaillevelthree',
      route_id: models.transaction.get('id')
    });
    controller.set('pageTitle', 'Transaction Detail - Level 3 Data');
    controller.set('pageCrumbs', [{
      title: 'Transaction Detail',
      link: 'transactiondetailfull',
      record: models.transaction.get('id')
    }, {
      title: 'Level 3 Data',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    updateTransaction: function(params) {
      return this.findTransaction(params);
    },
    willTransition: function(transition) {
      var model = this.currentModel;
      var self = this;
      if (model.transactionlevel3.get('hasDirtyAttributes') && this.get('session.isAuthenticated')) {
        transition.abort();
        pretty_confirm("You have unsaved information. Are you sure you want to leave this page?", function() {
          self.controller.set('isEditing', false);
          model.transactionlevel3.rollbackAttributes();
          transition.retry();
        });
      } else {
        self.controller.set('isEditing', false);
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
