import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  sharing_group_id: DS.attr('string'),
  location_id: DS.attr('string'),

  // Defined model relationships
  sharinggroup: DS.belongsTo('admin_sharinggroup'),
  location: DS.belongsTo('location'),
});
