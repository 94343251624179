import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  allSelected: false,
  columnSearchKeyword: '',
  itemOptions: [],

  // Computed Properties
  fieldId: Ember.computed(function() {
    return Ember.get(this, 'name') + '_id';
  }),

  // Actions
  actions: {
    enterAction: function() {
      return;
    },
    selectAll: function() {
      var items = this.get('itemOptions');
      var tempItems = [];
      this.toggleProperty('allSelected');
      if (this.get('allSelected')) {
        tempItems = items.map(function(item) {
          item.options.map(function(option) {
            Ember.set(option, 'selected', true);
            return option;
          });
          return item;
        });
      } else {
        tempItems = items.map(function(item) {
          item.options.map(function(option) {
            Ember.set(option, 'selected', false);
            return option;
          });
          return item;
        });
        this.set('selectedItems', []);
      }
      this.set('itemOptions', tempItems);
    },
    toggleItemTypeCollapsed: function(type) {
      Ember.set(type, 'collapsed', !type.collapsed);
    },
    updateSelectedItems: function(item) {
      var selectedItems = this.get('selectedItems');
      if (item.selected) {
        var foundItem;
        selectedItems.map(function(i) {
          if (item.name === i.name) {
            foundItem = true;
          }
        });
        if (!foundItem) {
          selectedItems.pushObject(item);
        }
      } else {
        var foundIndex;
        selectedItems.map(function(i, index) {
          if (item.name === i.name) {
            foundIndex = index;
          }
        });
        if (foundIndex > -1) {
          selectedItems.removeAt(foundIndex);
        }
      }
      this.set('selectedItems', selectedItems);

      this.itemOptionsChanged();
    },
    fieldDataOptionsSearch: function() {
      this.fieldDataOptionsSearch(this.get('columnSearchKeyword'));
    },
    clearColumnSearch: function() {
      this.set('columnSearchKeyword', '');
    },
    applyChanges: function() {
      jQuery('#manageColumns_id').dropdown('toggle');
    }
  },

  itemOptionsLength: Ember.computed(function() {
    var items = this.get('itemOptions');
    var itemsLength = 0;
    items.map(function(item) {
      item.options.map(function() {
        itemsLength++;
      });
    });
    return itemsLength;
  }),

  setFilteredFieldData: Ember.observer('columnSearchKeyword', function() {
    this.fieldDataOptionsSearch(this.get('columnSearchKeyword'));
  }),

  itemOptionsChanged: function() {
    var selectedItems = this.get('selectedItems');
    var selectAll = jQuery('#' + this.get('fieldId')).parent().find('.selectall');
    if (this.get('itemOptionsLength') === selectedItems.length) {
      Ember.set(selectAll[0], 'checked', true);
    } else {
      Ember.set(selectAll[0], 'checked', false);
    }
  },

  didInsertElement: function() {
    this._super();
    var self = this;

    // Send the new values to the controller upon closing the drop down
    jQuery(document).on('hidden.bs.dropdown', '#' + this.$().attr('id'), function() {
      self.setColumns();
    });
    jQuery(document).on('shown.bs.dropdown', '#' + this.$().attr('id'), function() {
      jQuery('#columnSearchKeyword').focus();
      self.set('columnSearchKeyword', '');
      self.get('itemOptions').map(function(item) {
        $('#' + item.id).on('hide.bs.collapse', function() {
          Ember.set(item, 'collapsed', true);
        });
        $('#' + item.id).on('show.bs.collapse', function() {
          Ember.set(item, 'collapsed', false);
        });
      });
    });

  },

  willDestroyElement: function() {
    jQuery(document).off('hidden.bs.dropdown', '#' + this.$().attr('id'));
    jQuery(document).off('shown.bs.dropdown', '#' + this.$().attr('id'));
    this.get('itemOptions').map(function(item) {
      $('#' + item.id).off('hide.bs.collapse');
      $('#' + item.id).off('show.bs.collapse');
    });
  },
});
