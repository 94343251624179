import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      schedule: store.queryRecord('schedule', {
        id: params.view_id
      })
    });
  },

  setupController: function(controller, models, transition) {
    // Set Controller Properties
    var schedule = models.schedule;
    controller.set('schedule', schedule);

    if (transition.to.queryParams.edit) {
      controller.set('isEditing', true);
    } else {
      controller.set('isEditing', false);
    }

    controller.set('formPrivs', {
      canPut: this.get('session.authenticated.resources.jobs-schedules-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Job Schedule ' + schedule.get('name'),
      route_name: 'admin.schedules.view',
      route_id: schedule.get('id')
    });
    controller.set('pageTitle', 'View Job Schedule');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Schedule Jobs',
      link: 'admin.schedules.index'
    }, {
      title: 'View Job Schedule',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.schedule, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
