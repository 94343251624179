import DS from 'ember-data';
import Application from './application';
import Ember from 'ember';

export default Application.extend({
  account_holder_name: DS.attr('string'),
  account_number: DS.attr('string'),
  account_type: DS.attr('string'),
  billing_descriptor: DS.attr('string'),
  billing_product_transaction_id: DS.attr('string'),
  billing_zip: DS.attr('string'),
  exp_date: DS.attr('string'),
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  is_company: DS.attr('boolean'),
  is_recurring: DS.attr('boolean'),
  last_four: DS.attr('string'),
  location_id: DS.attr('string'),
  payment_method: DS.attr('string'),
  portfolio_id: DS.attr('string'),
  routing: DS.attr('string'),
  title: DS.attr('string'),

  isACH: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'ach';
  }),
  isCC: Ember.computed('payment_method', function() {
    return this.get('payment_method') === 'cc';
  }),

  // Defined model relationships
  changelogs: DS.hasMany('changelog'),
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  portfolio: DS.belongsTo('portfolio'),
});
