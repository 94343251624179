import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  feature_flag_id: DS.attr('string'),
  user_criteria: DS.attr('array'),
  user_id: DS.attr('string'),

  // Computed Properties

  // Defined model relationships
  user: DS.belongsTo('user'),
});
