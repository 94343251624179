import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  dynamicController: Ember.inject.controller('components/modals/dynamic'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isContacts: true,
  isContactsEmails: true,

  // Set controller params
  isRefreshing: true,
  page: 1,
  page_size: '15',
  sort: '-created_ts',
  created_ts: 'today',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'subject'
  ],
  columns: Ember.computed(function() {
    var self = this;
    var columns = {
      subject: {
        'name': 'subject',
        'title': 'Subject',
        'type': 'string',
        'action': function(email) {
          self.send('showDetail', email);
        },
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Date',
        'type': 'shortdate',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersPast',
          multi: false,
          showTimeFilter: true
        }
      },
      log_email_recipients: {
        'name': 'log_email_recipients',
        'title': 'Recipient',
        'type': 'array',
        'sortable': true
      },
      resendEmail: {
        'name': 'id',
        'title': '',
        'action': function(email) {
          self.send('resendEmail', email);
        },
        'type': 'resend',
        'isActionColumn': true
      }
    };

    var listOfCols = ['subject', 'created_ts', 'log_email_recipients', 'resendEmail'];
    var listOfMobileCols = ['subject', 'created_ts', 'resendEmail'];

    if(this.get('isMobileSize')){
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      return cols;
    }
  }),
  actions: {
    showDetail: function(logemail) {
      var self = this;
      // var session = this.get('session');
      var dynamicController = this.get('dynamicController');

      // Set initial content
      dynamicController.set('title', 'Subject: ' + logemail.get('subject'));
      dynamicController.set('className', 'eightinch');
      dynamicController.set('content', logemail.get('body'));
      self.send('openModal', 'components/modals/dynamic');
    },
    resendEmail: function(log_email) {
      var self = this;
      var session = self.get('session');
      pretty_confirm('Are you sure you want to re-send this email?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/logemails/' + log_email.id + '/resend',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          if (data.logemail.id) {
            self.send('refreshData');
            noty({
              text: 'Resend email successful!'
            });
          } else {
            noty({
              text: 'Send email failed!',
              type: 'error'
            });
          }
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Send email failed!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
