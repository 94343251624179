import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      developercompany: store.queryRecord('developercompany', {
        id: params.view_id,
        active: '0,1'
      }),
      developers: store.query('developer', {
        developer_company_id: params.view_id,
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var developercompany = models.developercompany;
    controller.set('developercompany', developercompany);
    controller.set('developers', models.developers);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-developercompanies-delete'),
      canPost: this.get('session.authenticated.resources.v2-developercompanies-post'),
      canPut: this.get('session.authenticated.resources.v2-developercompanies-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Developer Company ● ' +  developercompany.get('title'),
      route_name: 'admin.developercompanies.view',
      route_id: models.developercompany.get('id')
    });
    controller.set('pageTitle', 'View Developer Company');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Developer Companies',
      link: 'admin.developercompanies.index'
    }, {
      title: 'View Developer Company',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.get('unsavedInfo').warning(this.currentModel.developercompany, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
