import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  note: DS.attr('string'),
  note_id: DS.attr('string'),
  resource: DS.attr('string'),
  resource_id: DS.attr('string'),
  visibility_group_id: DS.attr('string'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  visibility_group: DS.belongsTo('visibilitygroup')
});
