import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  thisRoute: Ember.computed('route', function() {
    return 'virtualterminalcontact_' + this.get('route');
  }),

  pagerParams: {
    pageRoute: 'components.modals.virtualterminalcontact'
  },

  // Computed Properties
  disabled_transaction_action: Ember.computed('action', function() {
    return this.get('action') !== 'run';
  }),

  // Actions
  actions: {
    editAccount: function(contact_id, account_id) {
      this.send('closeModal');
      var session = this.get('session');
      var route = 'contacts.accounts-view';
      if (session.get('authenticated.isContact')) {
        route = 'contactuser.accounts-view';
      }
      this.transitionToRoute(route, contact_id, account_id);
    },
  }
});
