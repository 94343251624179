import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  hppAddController: Ember.inject.controller('locations/hpp-add'),
  hppViewController: Ember.inject.controller('locations/hpp-view'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  optionAdd: '',
  checkboxDefaultValues: [{
      id: 'false',
      title: 'False'
    },
    {
      id: 'true',
      title: 'True'
    }
  ],
  errorMessages: null,

  // Computed Properties

  canEditShortName: Ember.computed('field.id', function() {
    var field = this.get('field');
    return field.id.indexOf('custom_data-') > -1;
  }),
  hasOptions: Ember.computed('field.{id,field_type}', function() {
    var field = this.get('field');
    return field.id !== 'exp_date' && field.field_type === 'inputSelect';
  }),
  isCheckbox: Ember.computed('field', function() {
    return this.get('field.field_type') === 'inputCheckbox';
  }),
  isDisplayOnlyField: Ember.computed('field', function() {
    var displayOnlyFieldTypes = ['heading', 'text'];
    return displayOnlyFieldTypes.indexOf(this.get('field.field_type')) !== -1;
  }),
  isDonationTransactionAmount: Ember.computed('field', function() {
    return this.get('field.field_type') === 'inputSelect' && this.get('field.id') === 'transaction_amount';
  }),
  isImage: Ember.computed('field', function() {
    return this.get('field.field_type') === 'image';
  }),
  noSettingsEditable: Ember.computed('notEditableRequired', 'notEditableReadonly', 'notEditableVisible', function() {
    var notEditableRequired = this.get('notEditableRequired');
    var notEditableReadonly = this.get('notEditableReadonly');
    var notEditableVisible = this.get('notEditableVisible');
    return notEditableRequired && notEditableReadonly && notEditableVisible;
  }),
  notEditablePlaceholder: Ember.computed('field', function() {
    var uneditable = ['exp_date', 'captcha', 'account_type', 'is_company'];
    var uneditableFieldTypes = ['inputCheckbox', 'image', 'heading', 'text', 'button'];
    var fieldId = this.get('field.id');
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldId) !== -1 || uneditableFieldTypes.indexOf(fieldType) !== -1 || (fieldType === 'inputSelect' && fieldId === 'transaction_amount');
  }),
  notEditableReadonly: Ember.computed('field', function() {
    var uneditable = ['account_number', 'exp_date', 'captcha', 'submit_button', 'image', 'routing', 'account_type', 'is_company'];
    var uneditableFieldTypes = ['image', 'heading', 'text'];
    var product = this.get('product');
    if (product.get('vt_require_street')) {
      uneditable.push('billing_street');
    }
    if (product.get('vt_require_zip')) {
      uneditable.push('billing_zip');
    }
    if (product.get('auto_decline_cvv')) {
      uneditable.push('cvv');
    }
    if (product.get('payment_method') === 'ach') {
      uneditable.push('account_holder_name');
    }
    var fieldId = this.get('field.id');
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldId) !== -1 || uneditableFieldTypes.indexOf(fieldType) !== -1 || (fieldType === 'inputSelect' && fieldId === 'transaction_amount');
  }),
  notEditableRequired: Ember.computed('field', function() {
    var uneditable = ['transaction_amount', 'account_number', 'exp_date', 'captcha', 'submit_button', 'routing', 'account_type', 'is_company'];
    var uneditableFieldTypes = ['image', 'heading', 'text'];
    var product = this.get('product');
    var processorData =  product.get('processor_data').terminals;
    if(processorData.length){
      processorData.forEach(item => {
        var terminalData = item.required;
        terminalData.forEach(requiredItem => {
          if(requiredItem === 'billing_street'){
            uneditable.push('billing_street');
          }
          if(requiredItem === 'billing_city'){
            uneditable.push('billing_city');
          }
          if(requiredItem === 'billing_state'){
            uneditable.push('billing_state');
          }
          if(requiredItem === 'billing_zip'){
            uneditable.push('billing_zip');
          }
          if(requiredItem === 'billing_phone'){
            uneditable.push('billing_phone');
          }
          if(requiredItem === 'dl_state'){
            uneditable.push('dl_state');
          }
          if(requiredItem === 'dl_number'){
            uneditable.push('dl_number');
          }
          if(requiredItem === 'ssn4'){
            uneditable.push('ssn4');
          }
          if(requiredItem === 'dob_year'){
            uneditable.push('dob_year');
          }
          if(requiredItem === 'check_number'){
            uneditable.push('check_number');
          }
        })
      })

    }
    if (product.get('vt_require_street')) {
      uneditable.push('billing_street');
    }
    if (product.get('vt_require_zip')) {
      uneditable.push('billing_zip');
    }
    if (product.get('auto_decline_cvv')) {
      uneditable.push('cvv');
    }
    if (product.get('payment_method') === 'ach') {
      uneditable.push('account_holder_name');
    }
    var fieldId = this.get('field.id');
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldId) !== -1 || uneditableFieldTypes.indexOf(fieldType) !== -1;
  }),
  notEditableValue: Ember.computed('field', function() {
    var uneditable = ['account_holder_name', 'account_number', 'exp_date', 'cvv', 'captcha', 'routing', 'account_type', 'is_company'];
    var uneditableFieldTypes = ['image', 'heading', 'text', 'button', 'inputDate'];
    var fieldId = this.get('field.id');
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldId) !== -1 || uneditableFieldTypes.indexOf(fieldType) !== -1;
  }),
  notEditableVisible: Ember.computed('field', function() {
    var uneditable = ['account_number', 'exp_date', 'captcha', 'submit_button', 'routing', 'account_type', 'is_company'];
    var uneditableFieldTypes = ['image', 'heading', 'text'];
    var product = this.get('product');
    if (product.get('vt_require_street')) {
      uneditable.push('billing_street');
    }
    if (product.get('vt_require_zip')) {
      uneditable.push('billing_zip');
    }
    if (product.get('auto_decline_cvv')) {
      uneditable.push('cvv');
    }
    if (product.get('payment_method') === 'ach') {
      uneditable.push('account_holder_name');
      if (product.get('isSubProcessorPaya')) {
        uneditable.push('billing_street');
        uneditable.push('billing_city');
        uneditable.push('billing_state');
        uneditable.push('billing_zip');
        uneditable.push('billing_phone');
        uneditable.push('dl_state');
        uneditable.push('dl_number');
        uneditable.push('ssn4');
        uneditable.push('dob_year');
        uneditable.push('check_number');
        uneditable.push('transaction_c1');
        uneditable.push('transaction_c2');
        uneditable.push('transaction_c3');
      }
    }
    var fieldId = this.get('field.id');
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldId) !== -1 || uneditableFieldTypes.indexOf(fieldType) !== -1;
  }),
  notTextOrHeading: Ember.computed('field', function() {
    var uneditable = ['heading', 'text'];
    var fieldType = this.get('field.field_type');
    return uneditable.indexOf(fieldType) !== -1;
  }),
  title: Ember.computed('field', function() {
    return this.get('field.id');
  }),

  // Observers

  temp_shortname_observer: Ember.observer('field.{id,temp_shortname}', 'field.temp_shortname.length', function() {
    var temp_shortname = this.get('field.temp_shortname');
    var self = this;
    if (temp_shortname) {
      Ember.run.later(function() {
        self.set('field.temp_shortname', temp_shortname.replace(' ', '_').replace(/[^a-z0-9_]/gi, ''));
        self.set('field.shortname', 'custom_data-' + temp_shortname.replace(' ', '_').replace(/[^a-z0-9_]/gi, ''));
      }, 1);
    }
  }),

  // Functions

  checkErrors: function(field) {
    var errors = [];
    if (field.required && !field.visible && !field.value) {
      errors.push('Cannot set a field as Required and not Visible without a Default Value.');
    }
    if (field.required && field.readonly && !field.value) {
      errors.push('Cannot set a field as Required and Read Only without a Default Value.');
    } else if (field.readonly && !field.value) {
      errors.push('Cannot set a field as Read Only without a Default Value.');
    }
    if (!field.label) {
      errors.push('Label cannot be blank.');
    }
    if (this.get('hasOptions') && field.options.length < 1) {
      errors.push('Must include at least one option.');
    }
    if(typeof field.temp_shortname !== 'undefined') {
      if(!field.temp_shortname) {
        errors.push('Shortname cannot be blank.');
      }
    }
    var fields = this.get('fields');
    var fieldIndex = this.get('fieldIndex');
    fields.forEach(function(f, index) {
      if (f.id === field.shortname && index !== fieldIndex) {
        errors.push('Shortname already being used for another field. Must be unique.');
      }
    });
    return errors;
  },

  // Actions

  actions: {
    addOption: function() {
      var errors = [];
      var option = this.get('optionAdd');
      var options = this.get('field.options');
      if (options.indexOf(option) !== -1) {
        errors.push('Option already exists.');
        this.set('errorMessages', errors);
      } else if (!option && option !== 0) {
        errors.push('No value give for option.');
        this.set('errorMessages', errors);
      } else if(option.indexOf('\"') >= 0) {
        errors.push('Options cannot include double quotes.');
        this.set('errorMessages', errors);
      } else {
        if (this.get('isDonationTransactionAmount')) {
          if (isNaN(option)) {
            errors.push('Option must be a number.');
          } else {
            var optionDecimals = option.split('.')[1];
            if (optionDecimals && optionDecimals.length > 3) {
              errors.push('Option cannot have more than 3 decimals.');
            }
          }
          this.set('errorMessages', errors);
        }
        if (errors.length === 0) {
          options.pushObject(option);
          this.set('field.options', options);
          this.set('errorMessages', errors);
          this.set('optionAdd', '');
        }
      }
      jQuery('#edit-field-option-add').focus();
    },
    ignore: function() {
      return false;
    },
    removeOption: function(index) {
      var options = this.get('field.options');
      options.removeAt(index);
    },
    save: function() {
      var editField = this.get('field');
      if (this.get('canEditShortName')) {
        Ember.set(editField, 'shortname', 'custom_data-' + editField.temp_shortname);
      }
      if (this.get('hasOptions')) {
        var options = editField.options;
        if (this.get('includeOther')) {
          options.pushObject('other');
        }
        options = options.filter(function(option) {
          return option !== '' && option !== 'null';
        });
      }
      var errors = this.checkErrors(editField);
      if (errors.length > 0) {
        this.set('errorMessages', errors);
      } else {
        this.set('errorMessages', errors);
        var context = this.get('context');
        var sender = this.get('sender');
        var hppController;
        if (sender === 'add') {
          hppController = this.get('hppAddController');
        } else if (sender === 'view') {
          hppController = this.get('hppViewController');
        }
        this.send('closeModal');
        context.updateEditField(editField);
        this.set('field', null);
      }
    },
    sortEndAction: function() {
      return;
    }
  }
});
