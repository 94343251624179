import Ember from 'ember';

export default Ember.Component.extend({
  tagName: 'tr',
  classNameBindings: ['hasError'],

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Events
  didInsertElement: function() {
    this.setFieldValue();
  },
  didRender: function() {
    var errors = this.get('model.errors');
    var prop = this.get('prop').replace('_id', '');
    this.set('hasError', errors.get(prop) ? true : false);
  },

  // field value binding to model
  setFieldValue: function() {
    var prop = this.get('prop');
    var model = this.get('model');
    var selectFieldContent = this.get('selectFieldContent');
    var selectFieldValue = model.get(prop.replace('_id', '.id'));
    var value = this.get('value');
    var fieldValue = '';
    if (value || value === 0 || value === false) {
      this.set('fieldValue', value);
    } else {
      if (this.get('isSelectField')) {
        if (this.get('ableToEditField')) {
          if (selectFieldContent) {
            selectFieldContent.forEach(function(content) {
              if (content.id) {
                if (content.id === selectFieldValue) {
                  fieldValue = content.id;
                }
              } else {
                if (content.get('id') === selectFieldValue) {
                  fieldValue = content.get('id');
                }
              }
            });
          }
        } else {
          fieldValue = model.get(prop);
        }
      } else {
        fieldValue = model.get(prop);
      }
    }
    this.set('fieldValue', fieldValue);
  },
  showFieldValue: Ember.computed('fieldValue', function() {
    var prop = this.get('prop');
    var model = this.get('model');
    var selectFieldContent = this.get('selectFieldContent');
    var selectFieldValue = '';
    if (prop === 'user_type_id') {
      selectFieldValue = model.get(prop);
    } else {
      selectFieldValue = model.get(prop.replace('_id', '.id'));
    }
    var fieldValue;
    if (this.get('isSelectField')) {
      selectFieldContent.forEach(function(content) {
        if (content.get('id') === selectFieldValue) {
          fieldValue = content.get('title');
        }
      });
    } else {
      fieldValue = model.get(prop.replace('_id', '.id'));
    }
    return fieldValue;
  }),
  prop: Ember.computed('field', function() {
    return this.get('field.prop');
  }),
  crudModelBindingObserver: Ember.observer('fieldValue', function() {
    this.updateModel(this.get('prop'), this.get('fieldValue'));
  }),

  // display characteristics of field

  label: Ember.computed('field', function() {
    return this.get('field.label');
  }),
  isRequired: Ember.computed('field', function() {
    return this.get('field.required');
  }),
  ableToEditField: Ember.computed('model', 'field', 'isEditing', function() {
    var isModelNew = this.get('model.isNew');
    var isEditing = this.get('isEditing');
    var isEditableOnAdd = this.get('field.editOnAdd');
    var isEditableOnEdit = this.get('field.editOnEdit');
    if (isEditing && isModelNew && isEditableOnAdd) {
      return true;
    } else if (isEditing && !isModelNew && isEditableOnEdit) {
      return true;
    } else {
      return false;
    }
  }),
  showRequired: Ember.computed('ableToEditField', function() {
    return this.get('ableToEditField');
  }),
  fieldHeight: Ember.computed('field', function() {
    var fieldHeight = this.get('field.height');
    return fieldHeight ? fieldHeight : '500px';
  }),
  fieldRows: Ember.computed('field', function() {
    var fieldRows = this.get('field.rows');
    return fieldRows ? fieldRows : 3;
  }),
  // checkboxes need work still
  checkboxFields: Ember.computed('field.checkboxFields', function() {
    var self = this;
    var model = this.get('model');
    var fields = this.get('field.checkboxFields');
    fields.forEach(function(field) {
      self.set(field.prop, model.get(field.prop));
      self.addObserver(field.prop, self, 'sendUpdateModelAction');
    });
    return fields;
  }),

  // field type computed properties
  isCheckboxField: Ember.computed('field', function() {
    return this.get('field.type') === 'checkboxfield';
  }),
  isEditorField: Ember.computed('field', function() {
    return this.get('field.type') === 'editorfield';
  }),
  isSelectField: Ember.computed('field', function() {
    return this.get('field.type') === 'selectfield';
  }),
  isTextArea: Ember.computed('field', function() {
    return this.get('field.type') === 'textarea';
  }),
  isTextField: Ember.computed('field', function() {
    return this.get('field.type') === 'textfield';
  }),

  // field type special cases
  selectFieldContent: Ember.computed('isSelectField', function() {
    if (this.get('isSelectField')) {
      var selectFieldContent = this.get('field.select.content');
      return this.get(selectFieldContent);
    }
  }),
  showFieldValueAsCode: Ember.computed('field', function() {
    return this.get('field.viewAsCode');
  }),


  yesNoBoolean: Ember.computed(function() {
    return this.get('applicationData.yesNoBoolean');
  }),

});
