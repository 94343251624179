import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var store = this.get('store');
    var session = this.get('session');

    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
      accounts: store.query('accountvault', {
        contact_id: session.get('authenticated.user.contact_id'),
        page_size: '100',
        fields: 'id,title,last_four,payment_method,account_type,expiring_in_months,billing_zip,billing_address'
      })
    });
  },
  afterModel: function(models) {
    return Ember.RSVP.hash({
      locationinfo: this.get('store').queryRecord('locationinfo', {
        id: models.contact.get('location_id'),
        expand: 'product_transactions'
      }).then(function(data) {
        models.locationinfo = data;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('contact', models.contact);
    var contact_balance = models.contact.get('contact_balance');
    var display_balance = '';
    if (contact_balance && contact_balance !== null) {
      display_balance = ' <small>(balance: $' + contact_balance.currency(2) + ')</small>';
    }

    controller.set('accounts', models.accounts);
    var locations = Ember.A();
    locations.pushObject(models.locationinfo);
    controller.set('locations', locations);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Make Payment',
      route_name: 'contactuser.makepayment'
    });
    controller.set('pageTitle', 'Make Payment' + display_balance);
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Make a Payment',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function() {}
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
