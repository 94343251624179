import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),
  usersMethods: Ember.inject.service('sharedmethods-users'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      user: store.queryRecord('admin_user', {
        id: params.view_id,
        expand: 'location_users,domain,primary_location,created_user,email_blacklist'
      })
    });
  },

  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      authroles: store.query('authrole', {
        user_type_id: models.user.get('user_type_id'),
        page_size: 500,
        sort: 'title'
      }).then(function(data) {
        models.authroles = data;
      }),
      authroleusers: store.query('authroleuser', {
        user_id: models.user.get('id'),
        page_size: 500
      }).then(function(data) {
        models.authroleusers = data;
      }),
      terminals: store.query('terminal', {
        location_id: models.user.get('primary_location_id'),
        page_size: 100,
      }).then(function(data) {
        models.terminals = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var user = models.user;
    controller.set('user', user);
    controller.set('authroleusers', models.authroleusers); // Set the user auth roles with the current auth roles
    var default_terminal = user.get('ui_prefs.default_terminal'); // Set a default terminal
    if (models.terminals.get('length') > 0 && !default_terminal) {
      user.set('ui_prefs.default_terminal', models.terminals.get('firstObject').get('id'));
    }
    controller.set('terminals', models.terminals);
    controller.notifyPropertyChange('userTypes');

    var loggedInUserCanEditViewedUser = this.get('usersMethods').loggedInUserCanEditViewedUser(user);
    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2admin-users-delete') && loggedInUserCanEditViewedUser,
      canPost: this.get('session.authenticated.resources.v2admin-users-post') && loggedInUserCanEditViewedUser,
      canPut: this.get('session.authenticated.resources.v2admin-users-put') && loggedInUserCanEditViewedUser
    });
    controller.set('loggedInUserCanEditViewedUser', loggedInUserCanEditViewedUser);
    controller.set('userIsActive',  user.get('isActive'));

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View User ● ' + user.get('username'),
      route_name: 'admin.users.view',
      route_id: user.get('id')
    });
    controller.set('pageTitle', user.get('username'));
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Users',
      link: 'admin.users.index'
    }, {
      title: user.get('username'),
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      var model = this.currentModel.user;
      model.set('keyUrl', null);
      this.get('unsavedInfo').warning(model, transition, this);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
