import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  default_quick_invoice_allow_partial_pay: DS.attr('boolean'),
  default_quick_invoice_notification_days_after_due_date: DS.attr('number'),
  default_quick_invoice_notification_days_before_due_date: DS.attr('number'),
  default_quick_invoice_notification_on_due_date: DS.attr('boolean'),
  location_id: DS.attr('string'),
  quick_invoice_template: DS.attr('string'),

  // Computed Properties

  // Model Relations
  location: DS.belongsTo('location'),

});
