import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_ts: DS.attr('ts'),
  created_user_id: DS.attr('string'),
  encryption_key: DS.attr('string'),
  field_configuration: DS.attr('array'),
  location_id: DS.attr('string'),
  max_payment_amount: DS.attr('number'),
  min_payment_amount: DS.attr('number'),
  parent_send_message: DS.attr('boolean'),
  product_transaction_id: DS.attr('string'),
  redirect_url_on_approve: DS.attr('string'),
  redirect_url_on_decline: DS.attr('string'),
  redirect_url_delay: DS.attr('string'),
  stylesheet_url: DS.attr('string'),
  title: DS.attr('string'),
  recaptcha_score_threshold: DS.attr('number'),

  // Defined model relationships
  created_user: DS.belongsTo('user'),
  location: DS.belongsTo('location'),
  product_transaction: DS.belongsTo('producttransaction'),
});
