import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        id: params.id,
        page_size: '500',
        product_transaction_active: '0,1',
        expand: 'product_transactions,branding_domain,tags',
      }),
    });
  },

  afterModel: function(models) {
    var self = this;
    var store = self.get('store');
    var locations = models.locations.map(function(location){
      return location.get('id');
    }).join();
    var session = this.get('session');
    var getTerminals = function() {
      if (session.get('authenticated.resources.v2-terminals-get')) {
        return store.query('terminal', {
          page_size: '500',
          location_id: locations
        }).then(function(data) {
          models.terminals = data;
        });
      } else {
        models.terminals = [];
      }
    };
    return Ember.RSVP.hash({
      terminals: getTerminals()
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties

    var location = models.locations.get('firstObject');
    controller.set('location', location);
    controller.set('locations', models.locations);
    controller.set('terminals', models.terminals);
    controller.set('isEditing', true);


    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: location.get('name') + ' ● Add Account Vault',
      route_name: 'locations.accounts-add',
      route_id: location.get('id'),
    });
    controller.set('pageTitle', location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: location.get('name'),
      link: 'locations.view',
      record: location.get('id')
    }, {
      title: 'Account Vaults',
      link: 'locations.accounts-index',
      record: location.get('id')
    }, {
      title: 'Add Account Vault',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
