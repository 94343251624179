import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  // Set Template
  renderTemplate: function() {
    this.render('admin/portfolios/view', {
      controller: 'admin/portfolios/add'
    });
  },

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      portfolio: store.createRecord('portfolio', {})
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('isEditing', true);
    controller.set('portfolio', models.portfolio);
    controller.set('parents', []);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-portfolios-delete'),
      canPost: this.get('session.authenticated.resources.v2-portfolios-post'),
      canPut: this.get('session.authenticated.resources.v2-portfolios-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Portfolio',
      route_name: 'admin.portfolios.add',
      route_id: null
    });
    controller.set('pageTitle', 'Add Portfolio');
    controller.set('pageCrumbs', [{
      title: 'Admin',
      link: ''
    }, {
      title: 'Portfolios',
      link: 'admin.portfolios.index'
    }, {
      title: 'Add Portfolio',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function() {
      var model = this.currentModel;
      if (model.portfolio.get('hasDirtyAttributes')) {
        model.portfolio.rollbackAttributes();
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
