import Ember from "ember";
import jQuery from 'jquery';

export default Ember.Controller.extend({
  // Actions
  actions: {
    qboConnect() {
      console.log('actions:qboConnect');

      this.set('isRefreshTokenValidMessage','After completing the Quickbooks Online login flow, click the message at the top of the page to reload and view your access status.');

      noty( {
        text: `${`<div` + ` id="app-error-alert-`}qbo" onclick="window.location.reload(true)">After completing the Quickbooks Online login flow, click here to reload the page to view your access status.</div>`,
        timeout: null,
        type: `warning`,
        layout: `top`,
      });

      const parameters = "location=1,width=800,height=650,left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

      const win = window.open(ENV.APP.QBO_CONNECT, 'qboConnect', parameters);
    },
    qboDisconnect() {
      console.log('actions:qboDisconnect');

      const self = this;

      const location = this.get("location");
    
      const locationDisconnectUrl = ENV.APP.QBO_DISCONNECT.replace('{{location-id}}', location.id);

      jQuery.ajax({
        url: locationDisconnectUrl,
        type: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        console.log('addons.actions.qboDisconnect.then => response', response);

        if (response.status === 200) {

          self.set('isRefreshTokenValidMessage','Quickbooks Online access has been revoked. Please <a onclick="window.location.reload(true)>reload this page</a> if you\'d like to reconnect.');
          self.set('isRefreshTokenValidBoolean', false);
    
        } else {
    
          noty( {
            text: `${`<div` + ` id="app-error-alert-`}qbo" onclick="window.location.reload(true)">An error occurred when trying to invalidate the Quickbooks access token. Click here to reload the page and try again.</div>`,
            timeout: 10000,
            type: `error`,
            layout: `top`,
          });
        }

      });

    },
  },

  // Dependency Injections
  applicationData: Ember.inject.service("application-data"),

  isMobileSize: Ember.computed(
    "applicationData.isMobileSize",
    function () {
      return this.get("applicationData.isMobileSize");
    }
  ),

  // Set the booleans for navigation classes
  isLocations: true,

  isLocationsAddOns: true,

  isRefreshTokenValid: Ember.computed(
    "location",
    function () {
      console.log('addons.isRefreshTokenValid');

      const self = this;

      const location = this.get("location");
    
      const locationCheckUrl = ENV.APP.QBO_VERIFY.replace('{{location-id}}', location.id);

      jQuery.ajax({
        url: locationCheckUrl,
        type: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {

        self.set('isRefreshTokenValidMessage', "This location currently has an active connection to Quickbooks Online!");
        self.set('isRefreshTokenValidBoolean', true);

      })
      .catch( function(error) {
        console.log('addons.isRefreshTokenValid.catch =>', error);

        if (error.status === 404) {

          self.set('isRefreshTokenValidMessage', "This location has not been boarded into Quickbooks Online.");
          self.set('isRefreshTokenValidBoolean', false);

        } else if (error.status === 401) {
    
          self.set('isRefreshTokenValidMessage',"This location has been boarded into Quickbooks Online, but the refresh token has expired. Please click the button to reconnect.");
          self.set('isRefreshTokenValidBoolean', false);

        } else {
    
          self.set('isRefreshTokenValidMessage', "An unknown error occured. If this persists, please contact customer support.");
          self.set('isRefreshTokenValidBoolean', false);
        }

      });

      return null;
    }
  ),

  isRefreshTokenValidBoolean: false,

  isRefreshTokenValidMessage: "",

  // Restrict Access to the Add Ons Page by User Type
  isQuickBooksButtonAllowed: Ember.computed(
    "user",
    function () {
      var user = this.get("user");
      var user_type = parseInt(user.user_type_id);
      if (user_type >= 200) {
        return true;
      } else {
        return false;
      }
    }
  ),

  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),

  sessionService: Ember.inject.service("session"),

  store: Ember.inject.service(),
});
