import Ember from 'ember';
/* global REDIPS: true */
export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  tagname: '',
  grid: null,

  isHeaderOrFooter: Ember.computed('fields', function() {
    var area = this.get('fields');
    return area === 'header' || area === 'footer';
  }),
  isOptions: Ember.computed('fields', function() {
    var area = this.get('fields');
    return area === 'field' || area === 'custom';
  }),
  timesArray: Ember.computed('times', function() {
    const times = this.get('times');
    let timesArray = Ember.A();

    for (let i = 0; i < times; i++) {
      timesArray.push(i);
    }

    return timesArray;
  }),
  numOfRows: Ember.computed('rows', function() {
    var rows = this.get('rows');
    let rowsArray = Ember.A();
    for (let i = 0; i < rows; i++) {
      rowsArray.push({
        index: i,
        isLast: i === rows - 1
      });
    }
    return rowsArray;
  }),
  numOfCols: Ember.computed('cols', function() {
    var cols = this.get('cols') + 1;
    let colsArray = Ember.A();
    for (let i = 0; i < cols; i++) {
      colsArray.push({
        index: i,
        isLast: i === cols - 1
      });
    }
    return colsArray;
  }),
  onlyColumn: Ember.computed('numOfCols', function() {
    return this.get('numOfCols.length') === 2;
  }),
  onlyRow: Ember.computed('numOfRows', function() {
    return this.get('numOfRows.length') === 1;
  }),

  didInsertElement: function() {
    var self = this;
    var area = this.get('fields');
    if (area !== 'field' && !this.get('returningFromPreview')) {
      this.loadForm(area);
    }
    var rd = REDIPS.drag;
    rd.dropMode = 'single';
    rd.hover = {
      colorTd: 'rgba(92,184,92,0.25)',
      borderTd: '2px dashed rgba(92,184,92,0.9)'
    };
    rd.shift = {
      animation: true,
      after: 'always'
    };
    rd.style = {
      borderEnabled: 'solid',
      borderDisabled: 'solid',
      opacityDisabled: 50
    };
    rd.event.dropped = function(cell) {
      var cellId = cell.childNodes[0].id;
      var pos = rd.getPosition();
      if (pos[0] > 1) {
        if (cellId.indexOf('option_') !== -1 || cellId.indexOf('customfield_') !== -1) {
          rd.deleteObject(cellId);
        }
        self.fieldDropped(cellId, pos);
      }
    };
    REDIPS.divDelete = function(fieldId) {
      rd.deleteObject(fieldId);
      self.deleteField(fieldId);
    };
    REDIPS.editField = function(fieldId) {
      self.editField(fieldId);
    };
    REDIPS.rowDelete = function(el) {
      var row = REDIPS.drag.findParent('TR', el);
      var area = row.id.split('-');
      area = area[0];
      var tableId = 'table-' + area + '-table';
      self.deleteRow(row.rowIndex, tableId);
    };
    REDIPS.columnDelete = function(el) {
      var col = REDIPS.drag.findParent('TD', el);
      var coLEl = col.id.split('-');
      var area = coLEl[0];
      var index = coLEl[2];
      var tableId = 'table-' + area + '-table';
      self.deleteColumn(index, tableId);
    };
  },

  actions: {
    deleteColumn: function(index) {
      var tableId = 'table-' + this.get('fields') + '-table';
      this.deleteColumn(index, tableId);
    },
    deleteRow: function(index) {
      var tableId = 'table-' + this.get('fields') + '-table';
      this.deleteRow(index, tableId);
    },
  }

});
