import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  selected: '',

  selectedChanged: Ember.observer('selected', function() {
    var item = this.get('item');
    Ember.set(item, 'selected', this.get('selected'));
    if (!this.get('selected')) {
      this.set('allSelected', false);
    }
    this.updateSelectedItems(item);
  }),

});
