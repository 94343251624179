import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function() {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locations: store.query('locationinfo', {
        page_size: '500',
        relationship: 'direct',
        product_transaction_active: '0,1'
      }),
    });
  },

  afterModel: function(models) {
    var self = this;
    var store = self.get('store');
    var locations = models.locations.map(function(location){
      return location.get('id');
    }).join();
    var session = this.get('session');
    var getTerminals = function() {
      if (session.get('authenticated.resources.v2-terminals-get')) {
        return store.query('terminal', {
          page_size: '500',
          location_id: locations
        }).then(function(data) {
          models.terminals = data;
        });
      } else {
        models.terminals = [];
      }
    };
    return Ember.RSVP.hash({
      terminals: getTerminals()
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties

    controller.set('locations', models.locations);
    controller.set('terminals', models.terminals);
    controller.set('isEditing', true);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'Add Account Vault',
      route_name: 'accounts.add'
    });
    controller.set('pageTitle', 'Add Account Vault');
    controller.set('pageCrumbs', [{
      title: 'Account Vaults',
      link: 'accounts.index'
    }, {
      title: 'Add Account Vault',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
