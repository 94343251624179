import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function() {
    var store = this.get('store');
    var session = this.get('session');
    return Ember.RSVP.hash({
      user: store.findRecord('user', session.get('authenticated.user_id')),
      terminals: store.query('terminal', {
        page_size: 100,
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var default_terminal = models.user.get('ui_prefs.default_terminal'); // Set a default terminal
    if (models.terminals.get('length') > 0 && !default_terminal) {
      models.user.set('ui_prefs.default_terminal', models.terminals.get('firstObject').get('id'));
    }
    controller.set('user', models.user);
    controller.set('terminals', models.terminals);

    // Set: Page Title - Crumbs - History
    controller.set('pageTitle', 'Profile Settings');
    controller.set('pageCrumbs', [{
      title: 'Profile Settings',
      link: ''
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      var model = this.controller.get('user');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
