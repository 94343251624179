import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),

  // Various controller properties

  // Actions
  actions: {
    resendEmail: function(log_email) {
      var self = this;
      var session = self.get('session');
      pretty_confirm('Are you sure you want to re-send this email?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/logemails/' + log_email.id + '/resend',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          if (data.logemail.id) {
            self.send('refreshData');
            noty({
              text: 'Resend email successful!'
            });
          } else {
            noty({
              text: 'Send email failed!',
              type: 'error'
            });
          }
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Send email failed!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
  }
});
