import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function () {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function () {
    return this.get('applicationData.isMobileSize');
  }),
  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('domain.errors.messages', function () {
    this.set('errorMessages', this.get('domain.errors.messages'));
  }),

  // Set the booleans for navigation classes
  isAdminDomains: true,
  isLoading: false,
  isEditing: false,

  // This variable is used so that we can disable all the checkboxes when the page reloads or if edit mode is disabled
  allRegistrationFields: ['id', 'first_name', 'last_name', 'account_number', 'email', 'company_name', 'address', 'city', 'state', 'zip', 'home_phone', 'office_phone', 'office_ext_phone', 'cell_phone', 'contact_api_id'],

  // Computed Properties
  domainRegistrationFields: Ember.computed('domain.registration_fields', function () {
    var registration_fields = this.get('domain.registration_fields') || '';
    return registration_fields.replace(/,/g, ', ');
  }),
  panelTitle: Ember.computed('domain.title', 'domain.isNew', function () {
    var isNew = this.get('domain.isNew');
    var title = this.get('domain.title');
    return isNew ? 'Add Domain' : title;
  }),

  // Actions
  actions: {
    contactConfigHandler: function (key, data) {
      // Use a single switch case to handle contact, login, registration radio-buttons
      switch (key) {
        case 'LOGIN':
          this.get('domain').set('allow_contact_login', data);
          break;
        case 'REGISTRATION':
          if (data) {
            this.get('domain').set('allow_contact_signup', false);
          }
          this.get('domain').set('allow_contact_registration', data);
          break;
        case 'SIGNUP':
          if (data) {
            this.get('domain').set('allow_contact_registration', false);
          }
          this.get('domain').set('allow_contact_signup', data);
          break;
        case 'HIDE_BLIND_PAYMENT':
          this.get('domain').set('hide_blind_payment', data);
          break;
      }
    },
    selectValue: function (data) {
      const fields = this.get('domain.registration_fields');
      const fieldsArray = fields.split(',');
      const array = [];
      if (fields) {
        fieldsArray.forEach((item) => {
          array.push(item.replace(/['"]+/g, '').trim());
        });
      }
      if (array.length >= 1) {
        const index = array.findIndex((item) => item === data);
        if (index === -1) {
          array.push(data);
        } else {
          array.splice(index, 1);
        }
      } else {
        array.push(data);
      }
      this.get('domain').set('registration_fields', array.join(','));
    },
    back: function () {
      this.transitionToRoute('admin.domains.index');
    },
    cancel: function () {
      var domain = this.get('domain');
      domain.rollbackAttributes();
      this.set('isEditing', false);
    },
    delete: function () {
      var self = this;
      var domain = this.get('domain');
      pretty_confirm('Are you sure you want to delete this Domain?', function () {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        domain.deleteRecord();
        domain.save().then(function () {
          domain.unloadRecord();
          noty({
            text: 'Domain deleted successfully!'
          });
          self.set('isLoading', false);
          self.transitionToRoute('admin.domains.index');
        }, function (data) {
          var error = '';
          if (data.errors) {
            data.errors.forEach(function (err) {
              error += '<br>' + err.detail + '.';
            });
          }
          domain.rollbackAttributes();
          noty({
            text: 'Error deleting Domain!' + error,
            type: 'error',
            timeout: 10000
          });
          self.set('isLoading', false);
        });
      });
    },
    edit: function () {
      this.set('isEditing', true);
    },
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);
      var self = this;
      var domain = this.get('domain');
      domain.save().then(function () {
        noty({
          text: 'Domain updated successfully!'
        });
        self.set('isLoading', false);
        self.set('isEditing', false);
      }, function () {
        self.set('isLoading', false);
      });
    },
  }
});
