import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Computed Properties

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(terminal) {
          self.send('view', terminal);
        },
        'actionId': 'terminal_title_view_link',
        'filter': {
          type: 'text'
        }
      },
      id: {
        'name': 'id',
        'title': 'Id',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      username: {
        'name': 'username',
        'title': 'Username',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      password: {
        'name': 'password',
        'title': 'Password',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': false,
        'action': function(terminal) {
          self.get('parent').transitionToRoute('locations.view', terminal.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      serial_number: {
        'name': 'serial_number',
        'title': 'Serial #',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      mac_address: {
        'name': 'mac_address',
        'title': 'MAC Address',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      terminal_number: {
        'name': 'terminal_number',
        'title': 'Terminal #',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      cashback_enable: {
        'name': 'cashback_enable',
        'title': 'Cashback Enabled',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      print_enable: {
        'name': 'print_enable',
        'title': 'Print Enabled',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      sig_capture_enable: {
        'name': 'sig_capture_enable',
        'title': 'Signature Capture Enabled',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      tip_enable: {
        'name': 'tip_enable',
        'title': 'Tip Enabled',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false
        }
      },
      terminal_manufacturer_id: {
        'name': 'terminal_manufacturer_id',
        'title': 'Terminal Manufacturer',
        'type': 'string',
        'sortable': true,
        'action': function(terminal) {
          self.get('parent').transitionToRoute('admin.terminalmanufacturers.crud', terminal.get('terminal_manufacturer_id'));
        },
        'actionpriv': 'v2-terminalmanufacturers-get',
        'filter': {
          type: 'dropdown',
          items: 'terminalmanufacturers',
          multi: true,
          showAllSelect: true
        }
      },
      local_ip_address: {
        'name': 'local_ip_address',
        'title': 'Local IP',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      last_registration_ts: {
        'name': 'last_registration_ts',
        'title': 'Last Registration',
        'type': 'shortdate',
        'sortable': true,
      },
      active: {
        'name': 'active',
        'title': 'Active',
        'type': 'check',
        'align': 'text-center',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'yesNoAny',
          multi: false,
        }
      },
      view: {
        'name': 'id',
        'title': '',
        'action': function(terminal) {
          self.send('view', terminal);
        },
        'actionId': 'terminal_view_link',
        'type': 'view',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(terminal) {
          self.send('delete', terminal);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'id', 'username', 'password', 'location_id', 'serial_number', 'mac_address', 'terminal_number', 'cashback_enable', 'print_enable', 'sig_capture_enable', 'tip_enable', 'terminal_manufacturer_id', 'local_ip_address', 'last_registration_ts', 'active', 'view', 'delete'];
    var listOfMobileCols = ['title', 'id', 'username', 'password', 'location_id', 'serial_number', 'mac_address', 'terminal_number', 'cashback_enable', 'print_enable', 'sig_capture_enable', 'tip_enable', 'terminal_manufacturer_id', 'local_ip_address', 'last_registration_ts', 'active', 'view', 'delete'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      var route = context.get('route');
      if(route === 'admin.terminals.index'){
        if (!session.get('authenticated.resources.v2admin-terminals-get')) {
          colsArray.removeObject(columns.view);
        }
        if (!session.get('authenticated.resources.v2admin-terminals-delete')) {
          colsArray.removeObject(columns.delete);
        }
      } else if(route === 'locations.terminals.index'){
        if (!session.get('authenticated.resources.v2-terminals-get')) {
          colsArray.removeObject(columns.view);
        }
        if(!session.get('authenticated.resources.v2-terminals-delete')){
          cols.removeObject(columns.delete);
        }
        colsArray.removeObject(columns.username);
        colsArray.removeObject(columns.password);
        colsArray.removeObject(columns.location_id);
        colsArray.removeObject(columns.terminal_manufacturer_id);
      }
    }
  }),

  // Functions

  updateQueryParamsOnParent: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = this.get('parent');

    queryParams.forEach(function(param) {
      parent.set(param, self.get(param));
    });
  },

  // Actions
  actions: {
    delete: function(terminal) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Terminal?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        terminal.deleteRecord();
        terminal.save().then(function() {
          terminal.unloadRecord();
          noty({
            text: 'Terminal deleted successfully!'
          });
          self.get('parent').send('refreshData');
          self.get('parent').send('gridNeedsRefresh', self);
          self.set('isLoading', false);
        }, function() {
          terminal.rollbackAttributes();
          noty({
            text: 'Error deleting Terminal!',
            type: 'error'
          });
          self.get('parent').send('refreshData');
          self.set('isLoading', false);
        });
      });
    },
    view: function(terminal) {
      var route = this.get('route');
      if(route === 'admin.terminals.index'){
        this.get('parent').transitionToRoute('admin.terminals.view', terminal.get('id'));
      } else if(route === 'locations.terminals.index'){
        this.get('parent').transitionToRoute('locations.terminals-view', this.get('location.id'), terminal.get('id'));
      } else {
        return;
      }
    }
  },

  // Events
  init: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    var parent = self.get('parent');
    queryParams.forEach(function(param) {
      self.set(param, parent.get(param));
      self.addObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
    self.updateQueryParamsOnParent();
    this._super(...arguments);
  },
  willDestroyElement: function() {
    var self = this;
    var queryParams = this.get('queryParams');
    queryParams.forEach(function(param) {
      self.removeObserver(param.replace('.', '-'), self, 'updateQueryParamsOnParent');
    });
  }
});
