import Ember from "ember";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";

export default Ember.Route.extend(AuthenticatedRouteMixin, {
  // Dependency Injections
  sessionService: Ember.inject.service("session"),

  // Set Template
  renderTemplate: function () {
    this.render("admin/domains/view", {
      controller: "admin/domains/add",
    });
  },

  model: function () {
    var store = this.get("store");
    return Ember.RSVP.hash({
      domain: store.createRecord("domain", {
        allow_contact_login: false,
        allow_contact_signup: false,
        allow_contact_registration: false,
        allow_qbo: false, 
        hide_blind_payment: null,
        custom_theme: "default",
      }),
    });
  },

  setupController: function (controller, models) {
    // Set Controller Properties
    controller.set("isEditing", true);
    controller.set("domain", models.domain);

    controller.set("formPrivs", {
      canDelete: this.get("session.authenticated.resources.v2-domains-delete"),
      canPost: this.get("session.authenticated.resources.v2-domains-post"),
      canPut: this.get("session.authenticated.resources.v2-domains-put"),
    });

    // Set: Page Title - Crumbs - History
    this.send("addHistory", {
      title: "Add Domain",
      route_name: "admin.domains.add",
      route_id: null,
    });
    controller.set("pageTitle", "Add Domain");
    controller.set("pageCrumbs", [
      {
        title: "Admin",
        link: "",
      },
      {
        title: "Domains",
        link: "admin.domains.index",
      },
      {
        title: "Add Domain",
        link: "",
      },
    ]);
  },

  // Actions
  actions: {
    willTransition: function () {
      var model = this.currentModel;
      if (model.domain.get("hasDirtyAttributes")) {
        model.domain.rollbackAttributes();
      }
      return true;
    },
  },

  // Computed Properties
  session: Ember.computed(
    "sessionService.session.authenticated.token",
    function () {
      return this.get("sessionService.session");
    }
  ),
});
