import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      location: store.queryRecord('location', {
        id: params.id,
        expand: 'product_transactions,branding_domain'
      }),
      quickinvoicesettings: store.query('quickinvoicesetting', {
        location_id: params.id,
      }).then(function(data) {
        return data.get('firstObject');
      }, function() {
        return null;
      }),
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('quickinvoicesettings', models.quickinvoicesettings);
    controller.set('location', models.location);
    controller.set('isEditing', false);

    controller.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-quickinvoicesettings-delete'),
      canPost: this.get('session.authenticated.resources.v2-quickinvoicesettings-post'),
      canPut: this.get('session.authenticated.resources.v2-quickinvoicesettings-put')
    });

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.location.get('name') + ' ● Quick Invoice Default Settings',
      route_name: 'locations.quickinvoicesettings',
      route_id: models.location.get('id'),
    });
    controller.set('pageTitle', models.location.get('location_header_title'));
    controller.set('additionalBread', 'Domain:&nbsp;&nbsp;' + models.location.get('branding_domain.url'));
    controller.set('pageCrumbs', [{
      title: 'Locations',
      link: 'locations'
    }, {
      title: models.location.get('name'),
      link: 'locations.view',
      record: models.location.get('id')
    }, {
      title: 'Quick Invoice Default Settings',
      link: '',
    }]);
  },

  // Actions
  actions: {
    willTransition: function(transition) {
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
