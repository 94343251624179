import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set controller params
  isRefreshing: true,
  sort: 'exp_date',
  page: 1,
  page_size: '15',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'expiring_in_months',
    'has_recurring',
    'location_id',
  ],
  showLocations: Ember.computed('locations.[]', function() {
    return this.get('locations.length') > 1;
  }),
  expiringInMonths: [{
    id: '',
    title: 'Any Time'
  }, {
    id: '0',
    title: 'This Month'
  }, {
    id: '1',
    title: 'One Month'
  }, {
    id: '2',
    title: 'Two Months'
  }, {
    id: '3',
    title: 'Three Months'
  }, {
    id: '4',
    title: 'Four Months'
  }, {
    id: '5',
    title: 'Five Months'
  }, {
    id: '6',
    title: 'Six Months'
  }],

  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var session = this.get('session');
    var columns = {
      title: {
        'name': 'title',
        'title': 'Title',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.send('view', account);
        },
        'actionId': 'account_title_view_link',
      },
      account_holder_name: {
        'name': 'account_holder_name',
        'title': 'Account<br>Holder Name',
        'type': 'string',
        'sortable': true
      },
      contact_id: {
        'name': 'contact_id',
        'title': 'Contact<br>Name',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.transitionToRoute('contacts.dashboard', account.get('contact_id'));
        },
        'actionpriv': 'v2-contacts-get',
      },
      exp_date: {
        'name': 'exp_date',
        'title': 'Expiration<br>Date',
        'type': 'string',
        'sortable': true
      },
      account_type: {
        'name': 'account_type',
        'title': 'Account<br>Type',
        'type': 'image',
        'sortable': true
      },
      first_six: {
        'name': 'first_six',
        'title': 'First Six',
        'type': 'string',
        'sortable': true
      },
      last_four: {
        'name': 'last_four',
        'title': 'Last Four',
        'type': 'string',
        'sortable': true
      },
      has_recurring: {
        'name': 'has_recurring',
        'title': 'Has Recurring<br>Billing',
        'type': 'check',
        'sortable': true,
        'align': 'text-center'
      },
      location_id: {
        'name': 'location_id',
        'title': 'Location',
        'type': 'string',
        'sortable': true,
        'action': function(account) {
          self.transitionToRoute('locations.view', account.get('location_id'));
        },
        'actionpriv': session.get('authenticated.isSupport') ? 'v2admin-locations-get' : 'v2-locations-get',
      },
      view: {
        'name': 'id',
        'title': '',
        'type': 'view',
        'action': function(account) {
          self.send('view', account);
        },
        'actionId': 'account_view_link',
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['title', 'account_holder_name', 'contact_id', 'exp_date', 'account_type', 'first_six', 'last_four', 'has_recurring', 'location_id'];
    var listOfMobileCols = ['title', 'exp_date', 'account_type', 'last_four', 'location_id', 'view'];
    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      if (!context.get('showLocations')) {
        colsArray.removeObject(columns.location_id);
      }
    }
  }),

  // Actions
  actions: {
    view: function(account) {
      this.transitionToRoute('accounts.view', account.get('id'));
    }
  }
});
