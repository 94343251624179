import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  authagreementController: Ember.inject.controller('components/modals/authagreement'), // needed by this controller/modal
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('account.errors.messages', function() {
    this.set('errorMessages', this.get('account.errors.messages'));
  }),

  // Observers

  changedContactId: Ember.observer('account.contact_id', function() {
    var account = this.get('account');
    if(account) {
      var contact_id = account.get('contact_id');
      var contacts = this.get('contactsArray');
      if (contact_id && contacts && contacts.get('length')) {
        var contact = contacts.filter(function(c) {
          return contact_id === c.get('id');
        }).get('firstObject');
        if (contact) {
          account.set('account_holder_name', contact.get('full_name') ? contact.get('full_name').trim() : '');
          account.set('billing_address', contact.get('address'));
          account.set('billing_city', contact.get('city'));
          account.set('billing_state', contact.get('state'));
          account.set('billing_zip', contact.get('zip'));
          account.set('billing_phone', contact.get('phone') || contact.get('office_phone'));
        }
      }
    }
  }),

  // Actions
  actions: {
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var account = this.get('account');
      var locationinfo = this.get('locationinfo');
      var routings = this.get('routingNumbers');

      if (account.get('payment_method') === 'cc') {
        if (account.get('exp_month') && account.get('exp_year')) {
          account.set('exp_date', account.get('exp_month') + account.get('exp_year'));
        } else {
          account.set('exp_date', null);
        }
      } else {
        account.set('exp_date', null);
      }

      account.save().then(function() {
        noty({
          text: 'Account Vault saved successfully!'
        });

        // Show popup if there are legal notices set in config
        var payment_method = account.get('payment_method');
        var product = payment_method === 'ach' ? locationinfo.get('defaultProductACH') : locationinfo.get('defaultProductCC');
        var controller = self.get('authagreementController');
        if (product && product.get('receipt_add_account_above_signature')) {
          controller.set('authagreementType', null);
          controller.set('model', null);
          controller.set('location', null);
          controller.set('contact', null);
          controller.set('routing', null);
          controller.set('authagreementType', 'account');
          controller.set('model', account);
          controller.set('location', locationinfo);
          if(account.get('contact') && !self.get('contact.id')) {
            var getContact = self.get('store').queryRecord('contact', {
              id: account.get('contact_id'),
              page_size: 1
            });
            getContact.then(function(result) {
              controller.set('contact', result);
            });
          } else if(account.get('contact') && self.get('contact.id')) {
            controller.set('contact', self.get('contact'));
          }
          if (account.get('payment_method') === 'ach') {
            if (routings) {
              controller.set('routing', routings.get('firstObject'));
            } else {
              var getRoutings = self.get('store').query('routingnumber', {
                routing: account.get('routing'),
                page_size: 1
              });
              getRoutings.then(function(result) {
                controller.set('routing', result.get('firstObject'));
              });
            }
          }
          Ember.run.later(function() {
            self.send('openModal', 'components/modals/authagreement');
          },1000);
        }
        self.set('isLoading', false);
        var returnRoute = self.get('returnRoute');
        if (returnRoute) {
          self.transitionToRoute(returnRoute, account.get('contact_id'));
        }
        self.send('closeModal');
      }, function() {
        self.set('isLoading', false);
      });
    },
  }
});
