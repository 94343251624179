import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Route.extend({

  model: function(params) {
    return params;
  },
  setupController: function(controller, model) {
    new Ember.RSVP.Promise(function() {
      jQuery.ajax({
        url: window.UiENV.APP.FULL_URL + '/public/termsconditions/latest',
        headers: {
          'developer-id': window.UiENV.APP.DEVELOPER_ID
        },
        type: 'GET',
        contentType: 'application/json'
      }).then(function(data) {
        controller.set('termscondition', data.termscondition);
        if (controller.get('termscondition')) {
          controller.set('terms_condition_acceptance', false);
        }
      }, function() {
        noty({
          text: 'Error getting terms and conditions!',
          type: 'error'
        });
      });
    });

    controller.set('password_reset_id', model.id);
  }
});
