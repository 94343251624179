import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),
  unsavedInfo: Ember.inject.service('unsaved-info'),

  model: function(params) {
    var store = this.get('store');
    var session = this.get('session');
    return Ember.RSVP.hash({
      contact: store.findRecord('contact', session.get('authenticated.user.contact_id')),
      account: store.queryRecord('accountvault', {
        id: params.accountvault_id,
        active: '0,1'
      })
    });
  },
  afterModel: function(models) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      locationinfos: store.query('locationinfo', {
        id: models.contact.get('location_id')
      }).then(function(data) {
        models.locationinfos = data;
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    var exp_date = models.account.get('exp_date');
    var title = models.account.get('title');
    if (exp_date) {
      models.account.set('exp_month', exp_date.substr(0, 2));
      models.account.set('exp_year', exp_date.substr(2, 2));
    }
    this.get('store').push({ // need this for accurate dirty checking in willTransition
      data: {
        id: models.account.get('id'),
        type: 'accountvault',
        attributes: {
          title: title ? title : '',
          exp_month: exp_date ? exp_date.substr(0, 2) : '',
          exp_year: exp_date ? exp_date.substr(2, 2) : ''
        }
      }
    });
    controller.set('locationinfos', models.locationinfos);
    controller.set('contact', models.contact);
    controller.set('account', models.account);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: 'View Account Vault',
      route_name: 'contactuser.accounts-view',
      route_id: models.contact.get('id'),
      route_id_plus: models.account.get('id')
    });
    controller.set('pageTitle', 'View Account Vault');
    controller.set('pageCrumbs', [{
      title: 'Dashboard',
      link: 'contactuser.dashboard'
    }, {
      title: 'Account Vaults',
      link: 'contactuser.accounts-index'
    }, {
      title: 'View Account Vault',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
      this.controller.set('needsRefresh', new moment().unix());
    },
    willTransition: function(transition) {
      var model = this.controller.get('account');
      if (model.get('hasDirtyAttributes') && model.get('isNew')) {
        model.unloadRecord();
      } else if (model.get('hasDirtyAttributes')) {
        this.get('unsavedInfo').warning(model, transition, this);
      }
      this.controller.set('isEditing', false);
      this.controller.set('isTransitioning', true);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
