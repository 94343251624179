import DS from 'ember-data';
import Application from './application';

export default Application.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    branding_domain: {
      serialize: false,
      deserialize: 'records'
    },
    created_user: {
      serialize: false,
      deserialize: 'records'
    },
    developer_company: {
      serialize: false,
      deserialize: 'records'
    },
    parent: {
      serialize: false,
      deserialize: 'records'
    },
    product_accountvault: {
      serialize: false,
      deserialize: 'records'
    },
    product_file: {
      serialize: false,
      deserialize: 'records'
    },
    product_recurring: {
      serialize: false,
      deserialize: 'records'
    },
    product_transactions: {
      serialize: false,
      deserialize: 'records'
    },
    tags: {
      serialize: false,
      deserialize: 'records'
    },
    terminals: {
      serialize: false,
      deserialize: 'records'
    },
    location_billing_accounts: {
      serialize: false,
      deserialize: 'records'
    }
  }
});
