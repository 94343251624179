import Ember from 'ember';

export default Ember.Component.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),
  store: Ember.inject.service(),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Events
  didInsertElement: function() {
    this.set('showAdvanced', false);
    this.set('isLoading', false);
    this.set('formPrivs', {
      canDelete: this.get('session.authenticated.resources.v2-postbackconfigs-delete'),
      canPost: this.get('session.authenticated.resources.v2-postbackconfigs-post'),
      canPut: this.get('session.authenticated.resources.v2-postbackconfigs-put'),
    });
    if(this.get('route') === 'admin.postbackconfigs.view' && !this.get('postbackconfig.isNew')) {
      var self = this;
      var location = this.get('store').queryRecord('locationinfo', {
        id: self.get('postbackconfig.location_id'),
        page_size: '1'
      });
      location.then(function(result) {
        if(!self.get('isDestroyed') && !self.get('isDestroying')) {
          self.set('location', result);
        }
      });
    }
  },

  // Properties
  partnerFormats: [{
    id: 'api-default',
    title: 'Default'
  }, {
    id: 'partner',
    title: 'Partner'
  }, {
    id: 'authnet',
    title: 'Authorize.net'
  }],

  // Computed Properties
  postback_products_available: Ember.computed('products.[]', 'postbackconfig.{resource,location_id}', 'location.id', function() {
    var resource = this.get('postbackconfig.resource');
    var products = this.get('products');
    var tempProducts = [];
    switch (resource) {
      case 'transaction':
        tempProducts = products;
        break;
      case 'transactionbatch':
        tempProducts = products.filter(function(p) {
          return p.get('payment_method') === 'cc';
        });
        break;
        case 'quickinvoice':
        tempProducts = products.filter(function(p) {
          return p.get('quick_invoice_allow') === true;
        });
        break;
      default:
        break;
    }
    return tempProducts;
  }),
  products: Ember.computed('location.id', 'location.product_transactions.[]', function() {
    if (this.get('location.id')) {
      return this.get('location.product_transactions').filter(function(product) {
        return product.get('active');
      });
    } else {
      return [];
    }
  }),
  showLocation: Ember.computed('route', 'location.id', function() {
    var route = this.get('route');
    return route !== 'locations.postbackconfigs-edit' && route !== 'locations.postbackconfigs-add';
  }),
  showProducts: Ember.computed('products.[]', 'postbackconfig.resource', 'route', function() {
    var route = this.get('route');
    return this.get('postbackconfig.resource') !== 'contact' && this.get('postbackconfig.resource') !== 'accountvault' && (this.get('products.length') > 1 || (route === 'admin.postbackconfigs.view' || route === 'admin.postbackconfigs.add'));
  }),

  // Observers
  location_id_observer: Ember.observer('route', 'location.id', 'postbackconfig.location_id', function() {
    var self = this;
    var route = this.get('roue');
    if (route !== 'locations.postbackconfigs-edit' && route !== 'locations.postbackconfigs-add' && (this.get('postbackconfig.location_id') && this.get('postbackconfig.location_id') !== this.get('location.id'))) {
      this.get('store').queryRecord('location', {
        id: self.get('postbackconfig.location_id'),
        expand: 'product_transactions'
      }).then(function(data) {
        self.set('location', data);
      });
    }
    if (!this.get('postbackconfig.location_id')) {
      this.set('location', null);
    }
  }),

  product_transaction_id_observer: Ember.observer('postbackconfig.{location_id,product_transaction_id,resource}', 'postback_products_available.[]', function() {
    var product_transaction_id = this.get('postbackconfig.product_transaction_id');
    var resource = this.get('postbackconfig.resource');
    var postback_products_available = this.get('postback_products_available');
    var selected_product_available = false;
    postback_products_available.forEach(function(product) {
      if (product.get('id') === product_transaction_id) {
        selected_product_available = true;
      }
    });
    if (!selected_product_available) {
      this.set('postbackconfig.product_transaction_id', null);
    }
    if (this.get('postbackconfig.location_id') && !Ember.isEmpty(postback_products_available) && (postback_products_available.get('length') === 1 || !product_transaction_id)) {
      this.set('postbackconfig.product_transaction_id', postback_products_available.get('firstObject').get('id'));
    }
    if(resource === 'contact') {
      this.set('postbackconfig.product_transaction_id', null);
    }
  }),

  // Functions


  // Actions
  actions: {
    changeAdvanced: function() {
      this.set('showAdvanced', !this.get('showAdvanced'));
    },
    delete: function() {
      var self = this;
      var postbackconfig = this.get('postbackconfig');
      pretty_confirm('Are you sure you want to delete this Postback Config?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        postbackconfig.deleteRecord();
        postbackconfig.save().then(function() {
          postbackconfig.unloadRecord();
          noty({
            text: 'Postback Config deleted successfully!'
          });
          self.set('isLoading', false);
          self.send('reset');
        }, function(data) {
          var error = '';
          if(data.errors){
            data.errors.forEach(function(err){
              error += '<br>' + err.detail + '.';
            });
          }
          postbackconfig.rollbackAttributes();
          noty({
            text: 'Error deleting Postback Config!' + error,
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
    save: function() {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var postbackconfig = this.get('postbackconfig');

      postbackconfig.save().then(function() {
        noty({
          text: 'Postback saved successfully!'
        });
        self.set('isLoading', false);
        self.send('reset');
      }, function() {
        self.set('isLoading', false);
      });
    },
    reset: function() {
      var route = this.get('route');
      if (route === 'locations.postbackconfigs-edit' || route === 'locations.postbackconfigs-add') {
        var location = this.get('location');
        this.get('parent').transitionToRoute('locations.postbackconfigs-index', location.get('id'));
      } else if (route === 'admin.postbackconfigs.view' || route === 'admin.postbackconfigs.add') {
        this.get('parent').transitionToRoute('admin.postbackconfigs.index');
      } else {
        return;
      }
    },
    updateLocation: function(data) {
      var self = this;
      var locations = this.get('store').query('location', {
        name: data,
        sort: 'name',
        page_size: '10',
        fields: 'id,name'
      });
      locations.then(function(result) {
        self.set('locations', result);
      });
    },
  }

});
