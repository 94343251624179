import DS from 'ember-data';

export default DS.Transform.extend({
  serialize: function(value) {
    if (!value) {
      return null;
    } else {
      try {
        JSON.parse(value);
      } catch (e) {
          return value;
      }
      return JSON.parse(value);
    }
  },
  deserialize: function(value) {
    if (!value) {
      return null;
    } else {
      return JSON.stringify(value);
    }
  }
});
