import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  // Set the booleans for navigation classes
  isLocations: true,
  isLocationsNotificationAlerts: true,

  // Set controller params
  sort: 'name',
  page: 1,
  page_size: '15',
  isRefreshing: true,
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'alert_type_id',
    'created_ts',
    'created_ts_from',
    'created_ts_to',
    'date_start',
    'date_end',
    'description',
  ],
  columns: Ember.computed('isMobileSize', function() {
    var self = this;
    var columns = {
      description: {
        'name': 'description',
        'title': 'Description',
        'type': 'string',
        'sortable': true,
        'action': function(notificationalert) {
          self.send('edit', notificationalert);
        },
      },
      alert_type_title: {
        'name': 'alert_type_title',
        'title': 'Alert Type',
        'type': 'string',
        'sortable': true
      },
      date_start: {
        'name': 'date_start',
        'title': 'Start Date',
        'type': 'shortdate',
        'dateFormat': 'MM/DD/YYYY',
        'sortable': true
      },
      date_end: {
        'name': 'date_end',
        'title': 'End Date',
        'type': 'shortdate',
        'dateFormat': 'MM/DD/YYYY',
        'sortable': true
      },
      created_ts: {
        'name': 'created_ts',
        'title': 'Created',
        'type': 'shortdate',
        'sortable': true
      },
      edit: {
        'name': 'id',
        'title': '',
        'action': function(notificationalert) {
          self.send('edit', notificationalert);
        },
        'type': 'edit',
        'isActionColumn': true
      },
      delete: {
        'name': 'id',
        'title': '',
        'action': function(notificationalert) {
          self.send('delete', notificationalert);
        },
        'type': 'delete',
        'isActionColumn': true
      }
    };

    var listOfCols = ['description', 'alert_type_title', 'date_start', 'date_end', 'created_ts', 'delete', 'edit'];
    var listOfMobileCols = ['description', 'alert_type_title', 'date_start', 'date_end', 'created_ts', 'delete', 'edit'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col) {
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col) {
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }

    function removeCols(context, colsArray) {
      var session = context.get('session');
      if (!session.get('authenticated.resources.v2-helppages-put')) {
        colsArray.removeObject(columns.view);
      }
      if (!session.get('authenticated.resources.v2-helppages-delete')) {
        colsArray.removeObject(columns.delete);
      }
    }
  }),
  // Actions
  actions: {
    edit: function(notificationalert) {
      var location = this.get('location');
      this.transitionToRoute('locations.editnotificationalert', location.get('id'), notificationalert.get('id'));
    },
    delete: function(notificationalert) {
      var self = this;
      pretty_confirm('Are you sure you want to delete this Notification Alert?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        notificationalert.deleteRecord();
        notificationalert.save().then(function() {
          noty({
            text: 'Notification Alert deleted successfully!'
          });
          self.send('refreshData');
          self.set('isLoading', false);
        }, function() {
          notificationalert.rollbackAttributes();
          noty({
            text: 'Error deleting Notification Alert!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    }
  }
});
