import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  notFound: Ember.inject.service('notfound'),
  sessionService: Ember.inject.service('session'),

  model: function(params) {
    var store = this.get('store');
    return Ember.RSVP.hash({
      contact: store.queryRecord('contact', {
        id: params.id,
        expand: 'user,email_blacklist'
      })
    });
  },

  afterModel: function(models, transition) {
    var self = this;
    const contact = models.contact;

    return this.get('notFound').check(contact, transition, 'Contact', this).then(function() {
      return Ember.RSVP.hash({
        locationinfos: self.get('store').query('locationinfo', {
          id: contact.get('location_id'),
          product_transaction_active: '0,1'
        })
        .then(function(data) {
          models.locationinfos = data;
        }),
        accounts: self.get('store').query('accountvault', {
          contact_id: contact.get('id'),
          page_size: '100',
          expand: 'created_user,signature,contact'
        })
        .then(function (data) {
          models.accounts = data;
        })
      });
    });
  },

  setupController: function(controller, models) {
    const contact = models.contact;

    // Set Controller Properties
    var locationinfo = models.locationinfos.get('firstObject');
    controller.set('contact', models.contact);
    controller.set('accounts', models.accounts);
    controller.set('locationinfos', models.locationinfos);
    controller.set('locationinfo', locationinfo);

    var pagerParams = {
      pageRoute: 'contacts.accounts-index',
      pageModelId: null,
      modelName: 'accountvault',
      showExport: false
    };
    controller.set('pagerParams', pagerParams);
    controller.set('page_size', this.get('applicationData.page_size'));

    var gridParams = {
      contact_id: contact.get('id'),
      expand: 'created_user,contact,location,signature'
    };
    controller.set('gridParams', gridParams);

    // Set: Page Title - Crumbs - History
    this.send('addHistory', {
      title: models.contact.get('full_name') + ' ● Account Vaults',
      route_name: 'contacts.accounts-index',
      route_id: models.contact.get('id')
    });
    controller.set('pageTitle', models.contact.get('contact_header_title'));
    if (locationinfo.get('hasProductAccountVault')) {
      controller.set('pageSubtitle', {
        title: 'Add Account Vault',
        link: 'contacts.accounts-add',
        record: models.contact.id,
        resource: this.get('session.authenticated.resources.v2-accountvaults-post')
      });
    }
    controller.set('pageCrumbs', [{
      title: 'Contacts',
      link: 'contacts'
    }, {
      title: models.contact.get('full_name'),
      link: 'contacts.dashboard',
      record: models.contact.get('id')
    }, {
      title: 'Account Vaults',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    },
    willTransition: function(transition) {
      this.send('leavingContacts', transition.targetName);
      return true;
    }
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
