import Ember from 'ember';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  payquickinvoiceController: Ember.inject.controller('components/modals/payquickinvoice'), // needed by this grid-quickinvoice component to payquickinvoice
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sort: '-due_date',
  page: 1,
  page_size: '15',
  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'amount_due',
    'due_date',
    'invoice_number',
    'payment_status_id',
    'remaining_balance',
    'status_id',
    'title'
  ],

  // Actions
  actions: {}
});
