import DS from 'ember-data';
import Application from './application';

export default Application.extend({
  created_user_id: DS.attr('string'),
  created_ts: DS.attr('ts'),
  location_user_id: DS.attr('string'),
  location_id: DS.attr('string'),
  user_id: DS.attr('string'),
  visibility_group_id: DS.attr('string'),
});
