import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  isLocations: true,
  isLocationsTransactionachsettlements: true,

  // Set controller params
  sort: '-event_date',
  page: 1,
  page_size: '15',
  export_version: 'v1',
  isRefreshing: true,
  needsRefresh: '',
  event_date: 'today',

  queryParams: [
    // pagination params
    'page',
    'page_size',
    'sort',

    // filter params
    'event_date',
    'event_date_from',
    'event_date_to',
    'location_id',
    'needsRefresh',
    'product_transaction_id',
    'transaction_id',
  ],
  columns: Ember.computed('product_transactions', 'isMobileSize', function() {
    var self = this;
    var columns = {
      event_date: {
        'name': 'event_date',
        'title': 'Settle Date',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'dropdown',
          items: 'dateFiltersTransaction',
          multi: false
        }
      },
      transaction_id: {
        'name': 'transaction_id',
        'title': 'Transaction Id',
        'type': 'string',
        'sortable': true,
        'filter': {
          type: 'text'
        }
      },
      transaction_amount: {
        'name': 'transaction.transaction_amount',
        'title': 'Transaction<br>Amount',
        'type': 'currency',
        'sortable': false,
        'align': 'text-right'
      },
      product_transaction_id: {
        'name': 'product_transaction_id',
        'title': 'Deposit<br>Account',
        'type': 'string',
        'sortable': true,
        'action': function(transactionachsettlement) {
          self.transitionToRoute('locations.viewproducttransaction', transactionachsettlement.get('location_id'), transactionachsettlement.get('product_transaction_id'));
        },
        'actionpriv': ['v2-locations-get', 'v2-producttransactions-get'],
        'filter': {
          type: 'dropdown',
          items: 'product_transactions',
          multi: false,
          showAllSelect: false,
          notuniqbytitle: true
        }
      },
      detail: {
        'name': 'id',
        'title': '',
        'type': 'detail',
        'action': function(transactionachsettlement) {
          self.send('showDetail', transactionachsettlement);
        },
        'sortable': false,
        'isActionColumn': true
      },
      receipt:  {
        'name': 'id',
        'title': '',
        'type': 'receipt',
        'action': function(transactionachsettlement) {
          self.send('showTrans', transactionachsettlement);
        },
        'sortable': false,
        'isActionColumn': true
      }
    };

    var listOfCols = ['receipt', 'detail', 'event_date', 'transaction_id', 'transaction_amount', 'product_transaction_id'];
    var listOfMobileCols = ['event_date', 'transaction_id', 'transaction_amount', 'product_transaction_id', 'receipt', 'detail'];

    if (this.get('isMobileSize')) {
      var mobileCols = [];
      listOfMobileCols.forEach(function(col){
        mobileCols.pushObject(columns[col]);
      });
      removeCols(this, mobileCols);
      return mobileCols;
    } else {
      var cols = [];
      listOfCols.forEach(function(col){
        cols.pushObject(columns[col]);
      });
      removeCols(this, cols);
      return cols;
    }
    function removeCols(context, colsArray){
      if (context.get('product_transactions.length').length <= 1) {
        colsArray.removeObject(columns.product_transaction_id);
      }
    }
  }),

  // Actions
  actions: {
    showDetail: function(transactionachsettlement) {
      this.transitionToRoute('transactiondetailfull', transactionachsettlement.get('transaction_id'));
    },
    showTrans: function(transactionachsettlement) {
      var self = this;
      var store = this.get('store');
      return Ember.RSVP.hash({
        locationinfo: store.findRecord('locationinfo', transactionachsettlement.get('location_id'))
      }).then(function(hash) {
        self.send('showTransDetail', transactionachsettlement.get('transaction'), hash.locationinfo);
      }, function() {
        noty({
          text: 'Error getting transaction detail!',
          type: 'error',
          timeout: '5000'
        });
      });
    },
  }
});
