import Ember from 'ember';

export default Ember.Route.extend({

  // Set Template
  renderTemplate: function() {
    this.render('fourohfour');
  },

  setupController: function(controller) {
    controller.set('pageTitle', 'Not Found');
    controller.set('pageCrumbs', [{
      title: 'Page Not Found',
      link: ''
    }]);
  },

  // Actions
  actions: {
    refreshData: function() {
      this.refresh();
    }
  },
});