import Ember from 'ember';
import jQuery from 'jquery';

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  postbacklogController: Ember.inject.controller('components/modals/postbacklog'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  title: 'Postback log',
  content: null,
  isLoading: false,
  updateAction: Ember.observer('postback_log_id', function() {
    var store = this.get('store');
    var self = this;
    var postback_log_id = this.get('postback_log_id');
    if (!postback_log_id) {
      return;
    }
    return Ember.RSVP.hash({
      postbacklogs: store.queryRecord('postbacklog', {
        id: self.get('postback_log_id'),
        expand: 'postbacklogattempts',
      }).then(function(data) {
        self.set('postbacklogs_modal', data);
      }, function() {
        noty({
          text: 'Error getting postback log!',
          type: 'error',
          timeout: '5000'
        });
      })
    });
  }),

  // Actions
  actions: {
    resendPostback: function(postbacklog) {
      var self = this;
      self.set('isLocationsPostbacklogs', true);
      var session = this.get('session');
      pretty_confirm('Are you sure you want to retry this Postback?', function() {
        // Bail if we are already loading
        if (self.get('isLoading')) {
          return;
        }
        self.set('isLoading', true);
        jQuery.ajax({
          url: window.UiENV.APP.FULL_URL + '/postbacklogs/' + postbacklog.id + '/retry',
          type: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'developer-id': window.UiENV.APP.DEVELOPER_ID,
            'access-token': session.get('authenticated.token')
          }
        }).then(function(data) {
          self.updateAction();
          var result = data.postbacklog.postback_status_id;
          if (result === 3) {
            postbacklog.set('postback_status_id', 3);
            noty({
              text: 'Postback retry successful!'
            });
          } else {
            noty({
              text: 'Postback retry failed!',
              type: 'error'
            });
          }
          self.set('isLoading', false);
        }, function() {
          noty({
            text: 'Postback retry failed!',
            type: 'error'
          });
          self.set('isLoading', false);
        });
      });
    },
  }
});
