import Ember from "ember";

export default Ember.Controller.extend({

  // Dependency Injections
  applicationData: Ember.inject.service('application-data'),
  addProductTransactionController: Ember.inject.controller('locations/addproducttransaction'),
  migrateProductTransactionController: Ember.inject.controller('locations/migrateproducttransaction'),
  productTransactionController: Ember.inject.controller('locations/viewproducttransaction'),
  sessionService: Ember.inject.service('session'),

  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
  isMobileSize: Ember.computed('applicationData.isMobileSize', function() {
    return this.get('applicationData.isMobileSize');
  }),

  sender: '',

  // Boolean for loading spinner
  isLoading: false,

  // Set the model for validation errors
  errorMessages: null,
  updateErrorMessages: Ember.observer('salesoffice.errors.messages', function () {
    this.set('errorMessages', this.get('salesoffice.errors.messages'));
  }),

  // All form actions
  actions: {
    save: function () {
      // Bail if we are already loading
      if (this.get('isLoading')) {
        return;
      }
      this.set('isLoading', true);

      var self = this;
      var salesoffice = this.get('salesoffice');

      salesoffice.save().then(
        function (resp) {

          var senderController = {};
          if(self.get('sender') === 'viewproducttransaction') {
            senderController = self.get('productTransactionController');
          } else if(self.get('sender') === 'addproducttransaction') {
            senderController = self.get('addProductTransactionController');
          } else if(self.get('sender') === 'migrateproducttransaction') {
            senderController = self.get('migrateProductTransactionController');
          } else {
            self.set('isLoading', false);
            self.send('closeModal');
            return;
          }
          senderController.set('new_sales_office_id', resp.get('id'));
          senderController.send('updateSalesOffice', resp.get('title'));
          senderController.set('isLoadingSalesOffice', true);
          self.set('isLoading', false);
          self.send('closeModal');
        },
        function () {
          noty({
            text: 'Error creating a Sales Office!',
            type: 'error',
            timeout: '5000'
          });
          self.set('isLoading', false);
        }
      );
    }
  }
});
