import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Ember.Route.extend(AuthenticatedRouteMixin, {

  // Dependency Injections
  sessionService: Ember.inject.service('session'),

  model: function() {
    var session = this.get('session');
    var store = this.get('store');
    return Ember.RSVP.hash({
      user: store.queryRecord('user', {
        id: session.get('authenticated.user_id')
      })
    });
  },

  setupController: function(controller, models) {
    // Set Controller Properties
    controller.set('user', models.user);

    // Set: Page Title - Crumbs - History
    controller.set('pageTitle', 'Change Password');
    controller.set('pageCrumbs', [{
      title: 'Change Password',
      link: ''
    }]);
  },

  // Computed Properties
  session: Ember.computed('sessionService.session.authenticated.token', function() {
    return this.get('sessionService.session');
  }),
});
