import Ember from "ember";
import DS from "ember-data";
import Application from "./application";

export default Application.extend({
  account_number: DS.attr("string"),
  address1: DS.attr("string"),
  address2: DS.attr("string"),
  allowed_expired_token: DS.attr("boolnum"),
  branding_domain_allow_contact_login: DS.attr("boolean"),
  branding_domain_allow_contact_registration: DS.attr("boolean"),
  branding_domain_allow_contact_signup: DS.attr("boolean"),
  branding_domain_custom_javascript: DS.attr("string"),
  branding_domain_id: DS.attr("string"),
  branding_domain_registration_fields: DS.attr("fakearray"),
  branding_domain_title: DS.attr("string"),
  branding_domain_url: DS.attr("string"),
  city: DS.attr("string"),
  contact_email_trx_receipt_default: DS.attr("boolean"),
  created_ts: DS.attr("ts"),
  default_ach: DS.attr("string"),
  default_cc: DS.attr("string"),
  developer_company_id: DS.attr("string"),
  email_reply_to: DS.attr("string"),
  fax: DS.attr("string"),
  hide_blind_payment: DS.attr("boolnum"),
  is_new_domain: DS.attr("boolean"),
  location_api_id: DS.attr("string"),
  location_api_key: DS.attr("string"),
  name: DS.attr("string"),
  office_ext_phone: DS.attr("string"),
  office_phone: DS.attr("string"),
  parent_id: DS.attr("string"),
  quick_invoice_setting: DS.attr("array"),
  receipt_logo: DS.attr("string"),
  recurring_notification_days_default: DS.attr("string"),
  show_contact_files: DS.attr("boolnum"),
  show_contact_notes: DS.attr("boolnum"),
  state: DS.attr("string"),
  ticket_hash_key: DS.attr("string"),
  tz: DS.attr("string"),
  zip_code: DS.attr("string"),

  // Custom fields
  location_c1: DS.attr("string"),
  location_c2: DS.attr("string"),
  location_c3: DS.attr("string"),

  // Computed Properties
  defaultProductACH: Ember.computed(
    "product_transactions",
    "default_ach",
    function () {
      var default_ach = this.get("default_ach");
      var product_transactions = this.get("product_transactions");
      if (!product_transactions || !default_ach) {
        return null;
      }
      var products = product_transactions.filter(function (p) {
        return p.get("id") === default_ach;
      });
      if (!products) {
        return null;
      } else {
        return products.get("firstObject");
      }
    }
  ),
  defaultProductCC: Ember.computed(
    "product_transactions",
    "default_cc",
    function () {
      var default_cc = this.get("default_cc");
      var product_transactions = this.get("product_transactions");
      if (!product_transactions || !default_cc) {
        return null;
      }
      var products = product_transactions.filter(function (p) {
        return p.get("id") === default_cc;
      });
      if (!products) {
        return null;
      } else {
        return products.get("firstObject");
      }
    }
  ),
  full_address: Ember.computed(
    "name",
    "address1",
    "address2",
    "city",
    "state",
    "zip_code",
    "office_phone",
    function () {
      var address;
      var name = this.get("name");
      var address1 = this.get("address1");
      var address2 = this.get("address2");
      var city = this.get("city");
      var state = this.get("state");
      var zip_code = this.get("zip_code");
      var phone = this.get("office_phone");

      address = "<h4>" + name + "</h4>";
      if (address1) {
        address += address1 + "<br>";
      }
      if (address2) {
        address += address2 + "<br>";
      }
      if (city) {
        address += city + ", ";
      }
      if (state) {
        address += state + " ";
      }
      if (zip_code) {
        address += zip_code + "<br>";
      }
      if (phone) {
        address += phone
          .replace(/[^0-9]/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      return Ember.String.htmlSafe(address);
    }
  ),
  hasBillingPhone: Ember.computed("product_transactions", function () {
    var product_transactions = this.get("product_transactions");
    return (
      product_transactions.filter(function (i) {
        return i.get("vt_billing_phone");
      }).length > 0
    );
  }),
  hasClerkNumber: Ember.computed("product_transactions", function () {
    var product_transactions = this.get("product_transactions");
    return (
      product_transactions.filter(function (i) {
        return i.get("vt_clerk_number");
      }).length > 0
    );
  }),
  hasDrChrono: Ember.computed(
    "product_transactions.@each.partner",
    "product_transactions.[]",
    "product_transactions.@each.active",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("partner") === "drchrono" && i.get("active");
        }).length > 0
      );
    }
  ),
  hasLodging: Ember.computed(
    "product_transactions.@each.industry_type",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("industry_type") === "lodging";
        }).length > 0
      );
    }
  ),
  hasProductAccountVault: Ember.computed("product_accountvault", function () {
    return this.get("product_accountvault") &&
      this.get("product_accountvault.active")
      ? true
      : false;
  }),
  hasProductRecurring: Ember.computed("product_recurring", function () {
    return this.get("product_recurring") && this.get("product_recurring.active")
      ? true
      : false;
  }),
  hasProductACH: Ember.computed(
    "product_transactions.@each.payment_method",
    "product_transactions.@each.active",
    "product_transactions.[]",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("payment_method") === "ach" && i.get("active");
        }).length > 0
      );
    }
  ),
  hasProductCC: Ember.computed(
    "product_transactions.@each.payment_method",
    "product_transactions.@each.active",
    "product_transactions.[]",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("payment_method") === "cc" && i.get("active");
        }).length > 0
      );
    }
  ),
  hasProductsActive: Ember.computed(
    "hasProductCC",
    "hasProductACH",
    function () {
      return this.get("hasProductCC") || this.get("hasProductACH");
    }
  ),
  hasProductPayaWithC21: Ember.computed("product_transactions.[]", function () {
    var product_transactions = this.get("product_transactions");
    var products = product_transactions.filter(function (i) {
      return i.get("isSubProcessorPaya");
    });
    if (products.length === 0) {
      return false;
    } else {
      return (
        products.filter(function (prod) {
          var hasC21 = false;
          prod.get("processor_data.terminals").map(function (t) {
            if (t.sec_code === "C21") {
              hasC21 = true;
            }
          });
          return hasC21;
        }).length > 0
      );
    }
  }),
  hasProductTip: Ember.computed(
    "product_transactions.@each.hasTip",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("hasTip");
        }).length > 0
      );
    }
  ),
  hasQuickInvoice: Ember.computed(
    "product_transactions.@each.quick_invoice_allow",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("quick_invoice_allow") && i.get("active");
        }).length > 0
      );
    }
  ),
  hasSurcharge: Ember.computed(
    "product_transactions.@each.surcharge_id",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("surcharge.id");
        }).length > 0
      );
    }
  ),
  hasTax: Ember.computed(
    "product_transactions.@each.vt_enable_sales_tax",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("vt_enable_sales_tax");
        }).length > 0
      );
    }
  ),
  hasVTOrderNum: Ember.computed(
    "product_transactions.@each.vt_order_num",
    function () {
      var product_transactions = this.get("product_transactions");
      return (
        product_transactions.filter(function (i) {
          return i.get("vt_order_num");
        }).length > 0
      );
    }
  ),
  location_header_title: Ember.computed("name", "account_number", function () {
    return (
      this.get("name") +
      " " +
      (this.get("account_number") !== null &&
      this.get("account_number") !== undefined
        ? "<small>(account: " + this.get("account_number") + ")</small>"
        : "")
    );
  }),
  title: Ember.computed("name", function () {
    return this.get("name");
  }),
  titleWithAccountNumber: Ember.computed(
    "title",
    "account_number",
    function () {
      var account_number = this.get("account_number");
      return account_number
        ? (this.get("title") + " - " + account_number).trim()
        : this.get("title").trim();
    }
  ),

  // Defined model relationships
  branding_domain: DS.belongsTo("domain"),
  created_user: DS.belongsTo("user"),
  developer_company: DS.belongsTo("developercompany"),
  location_billing_accounts: DS.hasMany("locationbillingaccount"),
  parent: DS.belongsTo("location"),
  product_accountvault: DS.belongsTo("productaccountvault"),
  product_file: DS.belongsTo("productfile"),
  product_recurring: DS.belongsTo("productrecurring"),
  product_transactions: DS.hasMany("producttransaction"),
  tags: DS.hasMany("tag"),
  terminals: DS.hasMany("terminal"),
});
